import { alpha, Box } from '@mui/material';
import React from 'react';

function SeparatorItem() {
    return (
        <Box
            sx={{
                height: 16,
                width: '1px',
                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.24),
            }}
        />
    );
}

export default SeparatorItem;
