import useBundleTranslation from 'i18n';
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
} from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import StaticInfo from 'components/common/StaticInfo';
import { AlertColor } from '@mui/material/Alert';

export interface TriggerEventMessagesProps extends FormControlProps {
    sourceLabel?: string;
    distLabel?: string;
    data?: Array<FormComponentValue>;
}

interface messageInt {
    type?: AlertColor;
    text: string;
}
export default function FormTriggerEventMessages({
    controlProps: props,
}: FormElementControlPropsType<TriggerEventMessagesProps>) {
    const { t } = useBundleTranslation(['app/editor/trigger']);
    const eventMessageData = props.form.hookFormWatch('event_message_data');
    const [message, setMessage] = useState<messageInt>({
        type: undefined,
        text: '',
    });

    useEffect(() => {
        if (eventMessageData) {
            const newMessage = { ...message };

            switch (eventMessageData.active_event_type) {
                case 'aborted':
                    newMessage.type = 'error';
                    newMessage.text = t('event_messages.aborted');
                    break;
                case 'queued':
                    newMessage.type = 'success';
                    newMessage.text = t('event_messages.queued', {
                        requestTime: eventMessageData['run_request_time'],
                    });
                    break;
                case 'runned':
                    newMessage.type = 'success';
                    newMessage.text = t('event_messages.runned', {
                        startTime: eventMessageData['run_start_time'],
                    });
                    break;
                case 'finished':
                    newMessage.type = 'success';
                    newMessage.text = t('event_messages.finished', {
                        finishTime: eventMessageData['run_finish_time'],
                        successTiles: eventMessageData['success_number'],
                        failedTiles: eventMessageData['failed_number'],
                    });
                    break;
                default:
                    newMessage.type = undefined;
                    newMessage.text = '';
            }
            setMessage(newMessage);
        }
    }, [eventMessageData]);

    return (
        <Stack spacing={2}>
            {eventMessageData?.is_active_no_runs_msg && (
                <StaticInfo align={'center'} type="info" label={t('event_messages.no_runs')} />
            )}
            {message.text && <StaticInfo align={'center'} type={message.type} label={message.text} />}
        </Stack>
    );
}

export class FormTriggerEventMessagesBuilder extends FormComponentBuilder {
    prepareProps(): TriggerEventMessagesProps {
        return {
            ...this.controlProps,
            sourceLabel: this.elementProps.componentProps?.sourceLabel,
            distLabel: this.elementProps.componentProps?.distLabel,
            data: this.componentValues,
        };
    }
}
