import { components } from 'react-select';
import React from 'react';
import { Box } from '@mui/material';

// Default template for Option, override with props.optionComponent
export const ReactSelectOption = (props: any) => {
    const dataTest = `${props.selectProps?.['data-test']}_option`;
    return (
        <Box data-test={dataTest}>
            <components.Option {...props}>
                {props.data.icon}
                {props.children}
            </components.Option>
        </Box>
    );
};
