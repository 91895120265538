import { createSlice } from '@reduxjs/toolkit';
import { SearchRow, GlobalSearchFilterValues } from 'api/search';

export interface SearchFilters {
    page?: number;
    owner_id?: string;
    topic_id?: string;
    category_id?: string;
    is_certified?: string;
    activeTab?: string;
    ignore_columns?: string;
    all_terms?: string;
    type?: string;
    highly_rated?: string;
    created_in_last?: string;
    suggest?: string;
    pattern?: string;
    engagement?: string;
}

interface GlobalSearchSliceState {
    activeSearchTab: string;
    activePage: number;
    searchList: SearchRow[];
    filters: SearchFilters;
    updateSearchList: boolean;
    filterValues: Partial<GlobalSearchFilterValues>;
}

const initialState = {
    searchList: [],
    activePage: 0,
    filters: {
        ignore_columns: 'Y',
    },
    activeSearchTab: 'all',
    filterValues: {},
    updateSearchList: false,
} as GlobalSearchSliceState;

export const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        setActiveSearchTab: (state, action) => {
            state.updateSearchList = true;
            state.activePage = initialState.activePage;
            state.activeSearchTab = action.payload;
        },
        setSearchFilters: (state, action) => {
            state.updateSearchList = true;
            state.activePage = initialState.activePage;
            state.filters = action.payload as Partial<SearchFilters>;
        },
        setFilterValues: (state, action) => {
            state.filterValues = action.payload;
        },
        setSearchList: (state, action) => {
            state.searchList = state.updateSearchList ? [...action.payload] : [...state.searchList, ...action.payload];
            state.updateSearchList = false;
        },
        clearSearchList: (state) => {
            state.searchList = initialState.searchList as SearchRow[];
        },
        setActivePaginationPage: (state, action) => {
            state.activePage = action.payload;
        },
    },
});

export const {
    setActiveSearchTab,
    setFilterValues,
    setSearchFilters,
    setActivePaginationPage,
    clearSearchList,
    setSearchList,
} = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
