import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import { FormComponentValue } from 'components/common/form/layout/control';

export const editorUserMapAPI = {
    async getUserMapColumnsAsValues(userMapId: number): Promise<Array<FormComponentValue>> {
        const url = `/data/editor/usermap/${userMapId}/columns`;
        const response = await instance.get<APIResponse<Array<{ column_id: number; name: string }>>>(url);
        if (response.data.status == 'OK') {
            return response.data.data.map((row) => ({
                value: String(row.column_id),
                label: row.name,
            }));
        }
        return [];
    },
};
