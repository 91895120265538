import { ReportContentNS } from 'components/report-content';
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import React, { useEffect, useState } from 'react';
import { Box, Button, FormLabel, IconButton, Stack } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { fieldToSortField } from 'components/report-content/components/jct/index';
import ComponentSettingsJctField = ReportContentNS.ComponentSettingsJctField;

export default function FieldsSortOrder({
    fields,
    sortFields,
    onChangeColumnSortSettings,
}: {
    fields: Array<ComponentSettingsJctField>;
    sortFields: Array<ComponentSettingsDatasetSortField>;
    onChangeColumnSortSettings: (newSortFieldsSettings: Array<ComponentSettingsDatasetSortField>) => void;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const [data, setData] = useState<Array<any>>([]);
    useEffect(() => {
        setData(
            fields
                .filter((f) => sortFields.findIndex((s) => s.reference_name == f.reference_name) == -1)
                .map((f) => {
                    return {
                        value: f.reference_name,
                        label: f.column_name,
                    };
                }),
        );
    }, [fields, sortFields]);

    const handleAddSortField = () => {
        const list = sortFields.slice();
        const fieldsToAdd = fields.filter((f) => !list.find((l) => f.reference_name == l.reference_name));
        if (fieldsToAdd.length) {
            list.push(fieldToSortField(fieldsToAdd[0], 'ASC'));
        }
        onChangeColumnSortSettings(list);
    };

    const handleRemoveSortField = (index: number) => {
        const list = sortFields.slice();
        list.splice(index, 1);
        onChangeColumnSortSettings(list);
    };

    const handleSelectUpdate = (index: number, type: 'reference_name' | 'dir', referenceName: string, value: any) => {
        const list = sortFields.slice();

        if (type == 'dir') {
            list[index].dir = value;
        } else {
            const field = fields.find((f) => f.reference_name == value);
            if (!field) {
                return;
            }
            list[index] = fieldToSortField(field, list[index].dir);
        }

        onChangeColumnSortSettings(list);
    };

    return (
        <Box>
            <FormLabel>{t('sort_order.label')}</FormLabel>
            <Stack spacing={1}>
                {sortFields.map((sort, index) => {
                    const fullData = data.slice();
                    fullData.push({
                        value: sort.reference_name,
                        label: sort.column_name,
                    });
                    return (
                        <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <ReactSelect
                                    data={fullData}
                                    value={[sort.reference_name]}
                                    update={(value) =>
                                        handleSelectUpdate(index, 'reference_name', sort.reference_name, value)
                                    }
                                />
                            </Box>
                            <Box sx={{ width: '128px', flexShrink: 0 }}>
                                <ReactSelect
                                    data={[
                                        {
                                            value: 'ASC',
                                            label:
                                                sort.value_type == 'text'
                                                    ? 'A to Z'
                                                    : t('manage_filters.label_ascending'),
                                        },
                                        {
                                            value: 'DESC',
                                            label:
                                                sort.value_type == 'text'
                                                    ? 'Z to A'
                                                    : t('manage_filters.label_descending'),
                                        },
                                    ]}
                                    value={[sort.dir]}
                                    update={(value) => handleSelectUpdate(index, 'dir', sort.reference_name, value)}
                                />
                            </Box>
                            <Box sx={{ flexShrink: 0 }}>
                                <IconButton onClick={() => handleRemoveSortField(index)}>
                                    <IconMi icon="times" fontSize="16" />
                                </IconButton>
                            </Box>
                        </Stack>
                    );
                })}
            </Stack>

            <Box sx={{ mt: 2 }}>
                <Button onClick={() => handleAddSortField()} startIcon={<IconMi icon={'new'} />} variant="light">
                    {t('sort_order.add_new_field')}
                </Button>
            </Box>
        </Box>
    );
}
