import React, { useEffect, useRef } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { getElementFieldValue } from 'components/common/form/formTools';
import { FormRendererAPIType } from 'components/common/form';
import { useFormState } from 'react-hook-form';

const MainSourceLabel = 'Dashboard DB';

export default function SegmentForm(props: IFormLayoutProps) {
    const primary = props.hookForm.form.hookFormWatch('primary_segment_id');
    const secondary = props.hookForm.form.hookFormWatch('secondary_segment_id');
    const sql = props.hookForm.form.hookFormWatch('data_fetch_command');
    const dataSource = props.hookForm.form.hookFormWatch('data_source');
    const allCombinations = props.hookForm.form.hookFormWatch('all_combinations') == 'Y';

    const { isDirty } = useFormState({ control: props.hookForm.form.hookFormControl });
    const isDirtyRef = useRef(isDirty);
    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    useEffect(() => {
        if (!allCombinations) {
            return;
        }

        const list = props.hookForm.form.getElementValuesRegister('data_source');
        if (list) {
            const option = list.valuesRef.current.find((item) => item.label == MainSourceLabel);
            if (option) {
                props.hookForm.form.hookFormSetValue('data_source', option.value);
                generateAllCombinationsCommand(props.hookForm.form, primary, secondary);
            }
        }
    }, [allCombinations]);

    useEffect(() => {
        if (!allCombinations) {
            return;
        }

        generateAllCombinationsCommand(props.hookForm.form, primary, secondary);
    }, [primary, secondary, allCombinations]);

    useEffect(() => {
        if (MainSourceLabel != getElementFieldValue(props.hookForm.form, 'data_source.$option.label', {})) {
            props.hookForm.form.hookFormSetValue('all_combinations', 'N');
        }
    }, [dataSource]);

    useEffect(() => {
        if (sql != generateAllCombinationsCommand(props.hookForm.form, primary, secondary, true)) {
            props.hookForm.form.hookFormSetValue('all_combinations', 'N');
        }
    }, [sql]);

    useEffect(() => {
        if (!props.hookForm.form.formDidMount) {
            return;
        }
        setTimeout(() => {
            if (
                MainSourceLabel == getElementFieldValue(props.hookForm.form, 'data_source.$option.label', {}) &&
                sql == generateAllCombinationsCommand(props.hookForm.form, primary, secondary, true)
            ) {
                props.hookForm.form.hookFormSetValue('all_combinations', 'Y');
            }
        }, 1000);
    }, [props.hookForm.form.formDidMount]);

    return <FormLayoutComposer props={props} />;
}

function generateAllCombinationsCommand(
    form: FormRendererAPIType,
    primary: string,
    secondary: string,
    asString: boolean = false,
) {
    const primaryValueType = getElementFieldValue(form, 'primary_segment_id.value_type', {});
    const secondaryValueType = getElementFieldValue(form, 'secondary_segment_id.value_type', {});

    let sql = '';

    if (Number(primary) > 0 && Number(secondary) > 0) {
        sql = `SELECT sv_p.value_${primaryValueType}, sv_s.value_${secondaryValueType} FROM segment_value sv_p, segment_value sv_s WHERE sv_p.segment_id = ${primary} AND sv_s.segment_id = ${secondary} AND sv_p.total_ind = "N" AND sv_s.total_ind = "N"`;
    }
    if (asString) {
        return sql;
    }
    if (form.hookFormGetValues('data_fetch_command') != sql) {
        form.hookFormSetValue('data_fetch_command', sql);
    }
}
