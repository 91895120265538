import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import { QBReportField } from 'components/plugin-query-builder/data/ReportFieldClass';
import Alert from '@mui/material/Alert';
import QueryBuilderFieldsGrid from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGrid';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

export interface QueryBuilderSummaryPreviewProps {
    isShowQueryBuilderPopup: boolean;
    needLoadInitialQueryBuilderData: boolean;
    setNeedLoadInitialQueryBuilderData: (value: boolean) => void;
    formInitialValues?: any;
}
export default function QueryBuilderSummaryPreview(props: QueryBuilderSummaryPreviewProps) {
    const {
        isShowQueryBuilderPopup,
        needLoadInitialQueryBuilderData,
        setNeedLoadInitialQueryBuilderData,
        formInitialValues,
    } = props;
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);

    const { reportData, reportAct, queryData, helperAct, configData, pluginConfig } = useContext(PluginQBContext);
    const [error, setError] = useState<string>('');
    const [rowsList, setRowsList] = useState<any[]>([]);
    const prevNeedLoadInitialQueryBuilderDataRef = useRef<boolean>(needLoadInitialQueryBuilderData);
    const [isShowLoading, setIsShowLoading] = useState<boolean>(false);

    const prepareData = () => {
        setError('');
        const fieldsCount = Object.keys(reportData.fields).length;
        let dimensionItems: any[] = [];

        if (!fieldsCount) {
            //setError('Could not get details of selected report');
            return dimensionItems;
        }

        Object.keys(queryData.dates).forEach((dateKey) => {
            let dateItemData;
            if (!reportAct.hasDate(dateKey)) {
                const dateComponents = {
                    value: queryData.dates[dateKey].value,
                    year: queryData.dates[dateKey].year,
                    month: queryData.dates[dateKey].month,
                    day: queryData.dates[dateKey].day,
                    hour: queryData.dates[dateKey].hour,
                    format: queryData.dates[dateKey].format,
                };
                dateItemData = reportAct.addDate(dateComponents, queryData.dates[dateKey].name);
            } else {
                dateItemData = reportAct.getDate(dateKey);
            }
            if (
                dateItemData &&
                reportData.overrides.hasOwnProperty(dateItemData.name) &&
                reportData.overrides[dateItemData.name].toUpperCase() !==
                    reportData.fields[dateItemData.name].type.toUpperCase()
            ) {
                reportAct.setTypeOverride(dateItemData.name, reportData.overrides[dateItemData.name]);
            }
        });

        Object.keys(queryData.exprs).forEach((exprsKey) => {
            let exprsItemData;
            if (!reportAct.hasExpression(exprsKey)) {
                exprsItemData = reportAct.addExpression(queryData.exprs[exprsKey].expr, queryData.exprs[exprsKey].name);
            } else {
                exprsItemData = reportAct.getExpression(exprsKey);
            }

            if (
                exprsItemData &&
                reportData.overrides.hasOwnProperty(exprsItemData.name) &&
                reportData.overrides[exprsItemData.name].toUpperCase() !==
                    reportData.fields[exprsItemData.name].type.toUpperCase()
            ) {
                reportAct.setTypeOverride(exprsItemData.name, reportData.overrides[exprsItemData.name]);
            }
        });

        // Add field which were defined in query and exists in report
        Object.keys(queryData.dimensions).forEach((fieldKey) => {
            if (reportAct.hasField(fieldKey)) {
                const fieldData = reportAct.getField(fieldKey);

                if (fieldData.isConstructedDate && reportAct.isValidDate(fieldData.date)) {
                    dimensionItems.push({
                        component: 'ConstructedDateItem',
                        field: fieldData,
                        isChecked: true,
                        metric: null,
                    });
                } else if (fieldData.isExpression && reportAct.isValidExpression(fieldData.expr)) {
                    dimensionItems.push({
                        component: 'ExpressionItem',
                        field: fieldData,
                        isChecked: true,
                        metric: null,
                    });
                } else {
                    dimensionItems.push({
                        component: 'DimensionItem',
                        field: fieldData,
                        isChecked: true,
                        metric: null,
                    });
                }
            } else if (reportData.prompts.hasOwnProperty(fieldKey)) {
                if (reportData.prompts[fieldKey].values !== 'none') {
                    dimensionItems.push({
                        component: 'PromptItem',
                        field: reportData.prompts[fieldKey],
                        isChecked: true,
                    });
                }
            }
        });

        // Add metrics to dimensions list which were defined in query and exists in report
        Object.keys(queryData.metrics).forEach((metricKey) => {
            const metric = queryData.metrics[metricKey];

            if (metric.field == '*' && metric.operation == 'count') {
                dimensionItems.push({
                    component: 'DimensionItem',
                    field: new QBReportField({ name: 'COUNT(*)', type: 'INTEGER' }),
                    isChecked: true,
                    metric: metric,
                });
            } else if (reportAct.hasField(metric.field)) {
                const field = reportAct.getField(metric.field);
                if (field.isDimension) {
                    dimensionItems.push({
                        component: 'DimensionItem',
                        field: field,
                        isChecked: true,
                        metric: metric,
                    });
                } else if (field.isConstructedDate && reportAct.isValidDate(field.date)) {
                    dimensionItems.push({
                        component: 'ConstructedDateItem',
                        field: field,
                        isChecked: true,
                        metric: metric,
                    });
                } else if (field.isExpression && reportAct.isValidExpression(field.expr)) {
                    dimensionItems.push({
                        component: 'ExpressionItem',
                        field: field,
                        isChecked: true,
                        metric: metric,
                    });
                }
            }
        });

        return dimensionItems;
    };

    useEffect(() => {
        if (!isShowQueryBuilderPopup) {
            if (needLoadInitialQueryBuilderData) {
                if (
                    formInitialValues.plugin_connection_profile_id > 0 &&
                    (!pluginConfig.isRequiredDatasourceObject || formInitialValues.external_report_external_id > 0)
                ) {
                    setIsShowLoading(true);
                    const fetchData = async () => {
                        await reportAct.initData();
                    };
                    fetchData().then(() => {
                        setNeedLoadInitialQueryBuilderData(false);
                        setIsShowLoading(false);
                        setRowsList(prepareData());
                    });
                } else {
                    setNeedLoadInitialQueryBuilderData(false);
                }
            } else {
                setRowsList(prepareData());
            }
        }
    }, []);

    useEffect(() => {
        if (needLoadInitialQueryBuilderData === false && prevNeedLoadInitialQueryBuilderDataRef.current == true) {
            setRowsList(prepareData());
        }
    }, [needLoadInitialQueryBuilderData]);

    useEffect(() => {
        if (!isShowQueryBuilderPopup) {
            setRowsList(prepareData());
        }
    }, [queryData]);

    if (error)
        return (
            <Box>
                <Alert severity={'error'} variant={'standard'}>
                    {error}
                </Alert>
            </Box>
        );

    return (
        <>
            {isShowLoading && (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ opacity: 0.7, position: 'absolute', top: '34px', px: 1, zIndex: 1 }}
                >
                    <IconMi icon={'loader'} className={'anim-spin'} sx={{ mr: 1 }} />
                    {t('summary.loading')}
                </Stack>
            )}
            <QueryBuilderFieldsGrid data={rowsList} readonly />
        </>
    );
}
