import { ChartingIntervalUnitType, MetricChartOptionsType } from './index';
import { dateFormat } from 'highcharts/highstock';

export namespace MetricChartTools {
    export const formatXAxisLabel = function (chart: any, chartOptions: MetricChartOptionsType) {
        let dateTimeLabelFormat = chart.dateTimeLabelFormat;
        const intervalUnit = chartOptions.measurementIntervalUnit;

        if (
            !chartOptions.measurementIntervalXtdIntervalInd &&
            chartOptions.measurementIntervalFiscalPeriodType != null &&
            chartOptions.measurementIntervalFiscalPeriodType != 'weekday'
        ) {
            return toFiscalPeriod(chart, chartOptions);
        } else {
            if ((intervalUnit == 'day' || intervalUnit == 'week') && dateTimeLabelFormat == '%e. %b') {
                dateTimeLabelFormat = '%b %e';
            } else if (intervalUnit == 'month') {
                dateTimeLabelFormat = "%b '%y";
            } else if (intervalUnit == 'quarter') {
                dateTimeLabelFormat = 'Q%q %Y';
            }
        }

        return dateFormat(dateTimeLabelFormat, chart.value);
    };

    const toFiscalPeriod = function (chart: any, chartOptions: MetricChartOptionsType) {
        if (chartOptions.fiscalPeriods.length == 0) {
            return chart.value;
        }

        var dateTimeLabelFormat = chart.dateTimeLabelFormat,
            date = new Date(chart.value);

        for (var i = 0; i < chartOptions.fiscalPeriods.length; i++) {
            var period = chartOptions.fiscalPeriods[i];

            var startDate = new Date(period.first_day),
                endDate = new Date(period.last_day);

            endDate.setDate(endDate.getDate() + 1);

            if (startDate <= date && endDate > date) {
                if ('%Y' == chart.dateTimeLabelFormat) {
                    return period.fiscal_year_id;
                }
                return period.name;
            }
        }
        return dateFormat(dateTimeLabelFormat, chart.value);
    };

    export function calcChartingIntervalRange(startPoint: number, unit: ChartingIntervalUnitType, value: number) {
        if ('minute' == unit) {
            return startPoint + 60000 * value;
        }
        if ('hour' == unit) {
            return startPoint + 3600000 * value;
        }
        if ('day' == unit) {
            return startPoint + 24 * 3600000 * value;
        }
        if ('week' == unit) {
            return startPoint + 7 * 24 * 3600000 * value;
        }

        const dt = new Date(startPoint);
        if ('month' == unit) {
            dt.setMonth(dt.getMonth() + value);
        } else if ('quarter' == unit) {
            dt.setMonth(dt.getMonth() + value * 3);
        } else if ('year' == unit) {
            dt.setFullYear(dt.getFullYear() + value);
        }
        return dt.getTime();
    }

    export function getRangeBorders(
        min: number,
        max: number,
        extremesMin: number,
        unit: ChartingIntervalUnitType,
        value: number,
    ) {
        min = calcChartingIntervalRange(min, unit, -value);
        if (min < extremesMin) {
            min = extremesMin;
            max = calcChartingIntervalRange(extremesMin, unit, value);
        }

        return { min: min, max: max };
    }
}
