export const MuiFormLabelConfig = {
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                color: theme.palette.text.primary,
                lineHeight: theme.size.lineHeight,
                marginBottom: theme.spacing(0.5),

                '&.Mui-error': {
                    color: theme.palette.text.primary,
                    '&.Mui-focused': {
                        color: theme.palette.error.main,
                    },
                },
            };
        },
    },
};
