import LinkCell from './LinkCell';
import BurstFilterCell from './BurstFilterCell';
import DeleteCell from './DeleteCell';
import CopyCell from './CopyCell';
import EditCell from './EditCell';
import TextCell from './TextCell';
import PopupCell from './PopupCell';
import ToggleCell from './ToggleCell';
import LineageCell from './LineageCell';
import SelectedCell from './SelectedCell';
import HistoryCell from './HistoryCell';
import LoginAsCell from './LoginAsCell';
import AsyncActionCell from './AsyncActionCell';
import PreCell from './PreCell';
import TranslationCell from './TranslationCell';
import FontPreviewCell from './FontPreviewCell';
import AsyncTooltipCell from './AsyncTooltipCell';
import TagCell from './TagCell';

import TestValueCell from 'app/extreport/grid/TestValueCell';
import OverlayMetricsGridDataCell from 'components/metric/collaboration-controls/overlay-metrics/OverlayMetricsGridDataCell';
import CompareLinesGridDataCell from 'components/metric/collaboration-controls/overlay-metrics/CompareLinesGridDataCell';
import ToggleEventCalendarCell from 'app/chart/grid/ToggleEventCalendarCell';
import UnlinkCell from 'app/ext-content/grid/UnlinkCell';
import CopyTextCell from 'components/common/grid/cell/CopyTextCell';
import CategoryNameCell from 'app/editor/category/CategoryNameCell';
import ManualRunRequestCell from 'app/editor/schedule/ManualRunRequestCell';
import PreviewCell from 'components/metric/collaboration-controls/PreviewCell';
import ErrorMessageCell from 'app/monitor/grid/ErrorMessageCell';
import Refetch from 'components/metric/last-metric-values/Refetch';
import CodeHistoryCell from 'app/editor/page/grid/CodeHistoryCell';
import ContentCell from 'app/announcement/ContentCell';

export const Components: { [key: string]: any } = {
    link: LinkCell,
    burstFilter: BurstFilterCell,
    delete: DeleteCell,
    copy: CopyCell,
    edit: EditCell,
    text: TextCell,
    popup: PopupCell,
    toggle: ToggleCell,
    lineage: LineageCell,
    selected: SelectedCell,
    history: HistoryCell,
    codeHistory: CodeHistoryCell,
    loginAs: LoginAsCell,
    asyncAction: AsyncActionCell,
    preCell: PreCell,
    translationCell: TranslationCell,
    fontPreviewCell: FontPreviewCell,
    overlayMetricsGridData: OverlayMetricsGridDataCell,
    compareLinesGridData: CompareLinesGridDataCell,
    asyncTooltipCell: AsyncTooltipCell,
    testValueCell: TestValueCell,
    toggleEventCalendar: ToggleEventCalendarCell,
    tag: TagCell,
    unlink: UnlinkCell,
    copyText: CopyTextCell,
    categoryName: CategoryNameCell,
    manualRunRequestCell: ManualRunRequestCell,
    overlayPreview: PreviewCell,
    refetchData: Refetch,
    errorMessageCell: ErrorMessageCell,
    contentCell: ContentCell,
};
