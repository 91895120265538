import { TFunction } from 'i18next';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettings = ReportContentNS.ComponentSettings;

export default function validateHeight(newSettings: ComponentSettings, t: TFunction) {
    let message = '';

    if (newSettings.height < 30) {
        message = t('line_chart.validation.error_height_should_be_greater_than_or_equal_30px');
    }

    if (newSettings.height > 1200) {
        message = t('line_chart.validation.error_height_should_be_less_than_or_equal_1200px');
    }

    return message;
}
