import React from 'react';
import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiRadioConfig = {
    defaultProps: {
        disableRipple: true,
        size: 'medium',
        icon: React.createElement('span', { className: 'CustomIconRadio CustomIconRadio--notChecked' }, null),
        checkedIcon: React.createElement('span', { className: 'CustomIconRadio CustomIconRadio--checked' }, null),
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                padding: '6px 8px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '& .CustomIconRadio': {
                    display: 'inline-flex',
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
                    backgroundColor: theme.palette.background.default,
                },
                '&.Mui-disabled .CustomIconRadio': {
                    backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                    '&.CustomIconRadio--checked:before': {
                        backgroundColor: alpha(theme.palette.text.primary, 0.24),
                    },
                },
                '&:hover .CustomIconRadio--notChecked, .MuiFormControlLabel-root:hover > &:not(.Mui-disabled) .CustomIconRadio--notChecked':
                    {
                        borderColor: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                        backgroundColor: useBlendColors(alpha(theme.palette.primary.main, 0.04)),
                    },
                '.MuiFormControlLabel-root:hover > &:not(.Mui-disabled) ~ .MuiFormControlLabel-label': {
                    color: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                },
                '&.Mui-disabled .CustomIconRadio--notChecked': {
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                },
                '& .CustomIconRadio--checked': {
                    borderColor: theme.palette.primary.main,
                    '&:before': {
                        backgroundColor: theme.palette.primary.main,
                        content: '""',
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                    },
                },
                '&:hover .CustomIconRadio--checked, .MuiFormControlLabel-root:hover > &:not(.Mui-disabled) .CustomIconRadio--checked':
                    {
                        borderColor: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                        backgroundColor: useBlendColors(alpha(theme.palette.primary.main, 0.04)),
                        '&:before': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.8),
                        },
                    },
                '&.Mui-disabled ~ .MuiFormControlLabel-label.Mui-disabled': {
                    color: useBlendColors(alpha(theme.palette.text.primary, 0.64)),
                },
            };
        },
    },
};
