import { RawFormComponentType, FormDataAPIType } from 'components/common/form';
import React, { useContext, useMemo, useState } from 'react';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import useBundleTranslation from 'i18n';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box, Divider, FormLabel, Link, Stack, Typography } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import AggregationMethods = ReportContentNS.AggregationMethods;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import Expression from 'components/dataset-viewer/query-builder/derived-field/Expression';
import Alert from '@mui/material/Alert';
import useHandleEditPanelError from 'components/report-content/hooks/useHandleEditPanelError';

export default function FieldForm({
    field,
    fields,
    onApply,
    onCancel,
    title,
}: {
    field: ComponentSettingsDatasetField;
    fields: Array<ComponentSettingsDatasetField>;
    onApply: (field: ComponentSettingsDatasetField) => void;
    onCancel: () => void;
    title?: string;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const { editPanel, setErrorMessage, errorBlock } = useHandleEditPanelError();
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        show_column_in_table_display_ind: field.show_column_in_table_display_ind,
        override_header_ind: field.override_header_ind,
        aggregation_method: field.aggregation_method,
        column_name: field.column_name,
        display_mask_id: field.display_mask_id ?? 0,
        compute_result_total_ind: field.compute_result_total_ind,
    });

    const checkBoxControl = {
        component: 'FormCheckbox',
    } as RawFormComponentType;

    const [formattingState, setFormattingState] = useState<FieldTextFormatting>({
        bgColor: field.bgColor ?? '',
        textColor: field.textColor ?? '',
        textWrap: field.textWrap ?? false,
        textBold: field.textBold ?? false,
        textItalic: field.textItalic ?? false,
        textAlign: field.textAlign ?? 'left',
        applyTo: field.applyTo ?? '-1',
    });

    const handleFieldTextChange = (fieldFormatting: FieldTextFormatting) => {
        if (JSON.stringify(formattingState) != JSON.stringify(fieldFormatting)) {
            setFormattingState(fieldFormatting);
        }
    };

    const editPanelContext = useContext(EditPanelContext);
    const displayMasks = editPanelContext?.displayMasks ?? [];

    const [expressionError, setExpressionError] = useState(false);
    const [expression, setExpression] = useState(field.transform_label ?? '');

    const allowedFields = useMemo(() => {
        return fields.map((f) => `[${f.column_name}]`).concat('[Hostname]');
    }, [fields]);

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={title}
                    onCancel={() => onCancel()}
                    item={field.label ?? field.reference_name}
                />
            </Box>
            {/*@ts-ignore*/}
            <Stack ref={editPanel} sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                {errorBlock}
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormText',
                                    name: 'column_name',
                                    label: 'display_name',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                {(field.value_type == 'numeric' || field.value_type == 'datetime') && (
                    <Box>
                        <FormLabel>{t('display_mask')}</FormLabel>
                        <ReactHookFormController
                            componentValues={[{ label: 'Default', value: '0' }].concat(
                                displayMasks
                                    .filter((d) =>
                                        field.value_type == 'numeric'
                                            ? d.mask_type == 'numeric'
                                            : d.mask_type == 'date',
                                    )
                                    .map((d) => ({
                                        label: d.display_example,
                                        value: d.display_mask_id,
                                    })),
                            )}
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    name: 'display_mask_id',
                                    component: 'FormSelect',
                                },
                            })}
                        />
                    </Box>
                )}
                {field.value_type == 'numeric' && (
                    <Box>
                        <FormLabel>{t('aggregation')}</FormLabel>
                        <ReactHookFormController
                            componentValues={[{ value: '', label: 'none' }]
                                .concat(AggregationMethods)
                                .map((r) => ({ ...r, label: t(r.label) }))}
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                ...{
                                    component: {
                                        component: 'FormSelect',
                                        name: 'aggregation_method',
                                    } as RawFormComponentType,
                                },
                            })}
                        />
                    </Box>
                )}
                <Stack direction={'column'} spacing={2}>
                    <Expression
                        label={t('dataset.hyperlinking')}
                        expression={expression}
                        setExpression={setExpression}
                        expressionError={expressionError}
                        setExpressionError={setExpressionError}
                        availableOptions={allowedFields}
                    />
                    <Alert variant="outlined" severity="info">
                        <Typography>{t('dataset.hyper_link')}</Typography>
                        <Typography>
                            {t('dataset.hyper_link_example')}
                            <br />
                            <Link target="_blank" href={'http://help.metricinsights.com/m/67081/l/722259'}>
                                {t('dataset.hyper_link_learn')}
                            </Link>
                        </Typography>
                    </Alert>
                </Stack>

                <Box>
                    <FormLabel>Column Format</FormLabel>
                    <FieldTextFormattingLine
                        field={formattingState}
                        onChange={(fieldFormatting) => handleFieldTextChange(fieldFormatting)}
                        hasAlign
                        hasWrap
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'override_header_ind'}
                        label={t('dataset.override_header_ind')}
                    />
                </Box>
                <Divider />
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                ...checkBoxControl,
                                label: t('dataset.show_column'),
                                name: 'show_column_in_table_display_ind',
                                props: {
                                    disabled: field.break_column == 'Y' || field.group_column == 'Y',
                                },
                            },
                        })}
                    />
                </Box>
                {field.value_type == 'numeric' && (
                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    ...checkBoxControl,
                                    label: t('dataset.compute_result_total_ind'),
                                    name: 'compute_result_total_ind',
                                    props: {
                                        disabled: field.break_column == 'Y' || field.group_column == 'Y',
                                    },
                                },
                            })}
                        />
                    </Box>
                )}
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        const label: string = elementProps.form.hookFormGetValues('column_name') ?? '';
                        if (label.trim() == '') {
                            setErrorMessage(t('fn_empty'));
                            return;
                        }
                        onApply({
                            ...field,
                            ...formattingState,
                            show_column_in_table_display_ind: elementProps.form.hookFormGetValues(
                                'show_column_in_table_display_ind',
                            ),
                            override_header_ind: elementProps.form.hookFormGetValues('override_header_ind'),
                            aggregation_method: elementProps.form.hookFormGetValues('aggregation_method'),
                            column_name: label ? label : (field.label ?? field.column_name),
                            display_mask_id: elementProps.form.hookFormGetValues('display_mask_id'),
                            compute_result_total_ind: elementProps.form.hookFormGetValues('compute_result_total_ind'),
                            transform_label: expression,
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
