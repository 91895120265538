import { DisplayMask } from 'components/dataset-viewer';

function number_format(number: number | string, decimals: number, dec_point?: string, thousands_sep?: string) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = [],
        toFixedFix = function (n: number, prec: number) {
            const k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export function formatValue(value: string | number, prefix?: string, suffix?: string) {
    value = parseFloat(String(value));
    suffix = suffix || '';
    prefix = prefix || '';

    const origValue = value;

    if (isNaN(value)) {
        return '';
    }

    let decimals = 0;
    if (Math.abs(value) >= 1000000) {
        value = value / 1000000;
        suffix = 'M';
    }

    if (Math.abs(value - Math.floor(value)) > 0.00001) {
        return prefix + value.toFixed(2) + suffix;
    }

    if (Math.abs(value - Math.floor(value)) < 0.00001) {
        decimals = 0;
    } else {
        if (Math.abs(origValue) > 1000000) {
            if (Math.abs(value) >= 10) {
                decimals = 1;
            } else {
                decimals = 2;
            }
        } else {
            const absValue = Math.abs(value);

            if (absValue < 0.0001) decimals = 5;
            else if (absValue < 0.001) decimals = 4;
            else if (absValue < 0.01) decimals = 3;
            else if (absValue < 1) decimals = 2;
            else if (absValue < 10) decimals = 1;
            else decimals = 0;
        }
    }

    let formatted = number_format(value, decimals, '.', ',');
    formatted = formatted.replace(/\.$/, '');

    return prefix + formatted + suffix;
}

export function formatValueFull(value: number, dm: DisplayMask | false) {
    if (!dm) {
        return formatValue(value, '');
    }

    switch (dm.show_amount_as) {
        case 'Thousands':
            value /= 1000;
            break;
        case 'Millions':
            value /= 1000000;
            break;
        case 'Billions':
            value /= 1000000000;
            break;
        case 'Hundredths':
            value *= 100;
            break;
        case 'Percent':
            value *= 100;
            break;
    }

    let digits = dm.display_precision_digits ? dm.display_precision_digits : 0,
        formattedValue = value.toFixed(digits);

    if (dm.decimal_point_delimiter == '' || dm.decimal_point_delimiter == null || 0 == digits) {
        var reg = new RegExp('(\\B(?=(\\d{3})+(?!\\d)))', 'g');
    } else {
        var reg = new RegExp('(\\B(?=(\\d{3})+(?=\\' + dm.decimal_point_delimiter + ')(?!\\d)))', 'g');
    }

    if (dm.thousands_delimiter) {
        formattedValue = formattedValue.replace(reg, dm.thousands_delimiter);
    }

    if (dm.prefix) formattedValue = dm.prefix + formattedValue;
    if (dm.suffix) formattedValue += dm.suffix;

    return formattedValue;
}
