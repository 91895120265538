import { ReportContentNS } from 'components/report-content/index';
import ExternalVisualization from 'components/report-content/components/external-visualization/ExternalVisualization';
import ExternalVisualizationEditPanel from 'components/report-content/components/external-visualization/ExternalVisualizationEditPanel';
import React from 'react';
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;

export default class ExternalVisualizationComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsExternalVisualization> {
    getComponent() {
        return (
            <ExternalVisualization
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <ExternalVisualizationEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
