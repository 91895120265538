import { FormPanelButtonProps, FormPanelButtonType } from 'components/common/form';
import React from 'react';
import Components from 'components/common/ComponentIndex';
import { processingPanelButton } from 'components/common/form/top-control-panel/FormTopControlPanel';
import ActionMenu from 'components/common/action-menu/ActionMenu';

interface ButtonProps {
    components: Array<FormPanelButtonType<any>>;
}

export default function FormPanelButtonMore(props: FormPanelButtonProps<ButtonProps>) {
    const itemsData = props.button.props?.components
        .filter((button) => button.show)
        .map((button, index) => {
            const buttonObj = button.defaultButtonKey ? processingPanelButton(button.defaultButtonKey, button) : button;

            // @ts-ignore
            const component = React.createElement(Components[buttonObj.component] as React.FC, {
                // @ts-ignore
                button: buttonObj,
                formActions: props.formActions,
                isSubMenuItem: true,
            });
            return {
                key: button.defaultButtonKey ?? button.component,
                component: component,
                separatorBefore: buttonObj.separatorBefore,
            };
        });

    return <ActionMenu items={itemsData} />;
}
