import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import { AssocArray } from 'tools/types';

export default function getFilterCondition(
    component: BlockComponent<ComponentSettingsManageFilters>,
    newFilterColumns: Array<IFilterColumn>,
) {
    const rules: Array<any> = [];

    if (typeof component.settings.requestParams != 'undefined') {
        component.settings.fields
            .filter((field) => field.filter_column == 'Y')
            .forEach((field) => {
                for (const [key, value] of Object.entries(component.settings.requestParams as AssocArray<string>)) {
                    if (
                        key.toLowerCase() === field.reference_name.toLowerCase() ||
                        ('undefined' !== typeof field.column_name &&
                            field.column_name > '' &&
                            key.replace(/\s/g, '_').toLowerCase() ===
                                field.column_name.replace(/\s/g, '_').toLowerCase()) ||
                        ('undefined' !== typeof field.filter_label &&
                            field.filter_label > '' &&
                            key.replace(/\s/g, '_').toLowerCase() ===
                                field.filter_label.replace(/\s/g, '_').toLowerCase())
                    ) {
                        if ('range' === field.filterType) {
                            const tmp = value.split(',');

                            if (tmp[0] > '') {
                                rules.push({ field: field.reference_name, condition: '>=', data: tmp[0] });
                            }

                            if (tmp.length > 1 && tmp[1] > '') {
                                rules.push({ field: field.reference_name, condition: '<=', data: tmp[1] });
                            }
                        } else {
                            let condition = '=';
                            if ('multiselect' === field.filterType) {
                                condition = 'is in list';
                                try {
                                    const tmp = JSON.parse(value);
                                    // @ts-ignore
                                    component.settings.requestParams[key] = tmp;
                                } catch (e) {}
                            }

                            rules.push({
                                field: field.reference_name,
                                condition: condition,
                                data: value,
                            });
                        }
                    }
                }
            });
    } else {
        newFilterColumns.forEach((field) => {
            let val = field.savedValues;

            if (typeof val == 'undefined' || val == null || val.length == 0) {
                return;
            }
            if ('multiselect' === field.filterType || field.filterType == 'dropdown') {
                if (val?.length == 1 && val[0] == '') {
                    return;
                }
                rules.push({
                    field: field.reference_name,
                    skip_trim_value: 'Y',
                    condition: 'is in list',
                    data: val,
                });
            } else if ('range' === field.filterType) {
                if (val[0] != '' && val[0] != field.data?.from) {
                    rules.push({
                        field: field.reference_name,
                        condition: '>=',
                        data: val[0],
                    });
                }
                if (val[1] != '' && val[1] != field.data?.to) {
                    rules.push({
                        field: field.reference_name,
                        condition: '<=',
                        data: val[1],
                    });
                }
            } else if ('charting_interval' === field.filterType) {
                if (val?.length == 1 && val[0] == '') {
                    return;
                }

                rules.push({
                    field: field.reference_name,
                    condition: '>=',
                    data: val[0],
                });
            } else {
                // val = $('.ds_filters_select[uid="' + field.reference_name_escaped + '"]').val();
                // if ('undefined' !== typeof val && ' ' !== val && '' !== val && null !== val)
                //     rules.push({
                //         field: field.reference_name,
                //         condition: '=',
                //         data: val,
                //     });
                // else if (
                //     $('.ds_filters_select[uid="' + field.reference_name_escaped + '"] > option:selected').length &&
                //     'Y' ===
                //         $('.ds_filters_select[uid="' + field.reference_name_escaped + '"] > option:selected').data(
                //             'is_empty'
                //         )
                // )
                //     rules.push({ field: field.reference_name, condition: 'is empty' });
            }
        });
    }

    return rules.length > 0 ? { operator: 'AND', rules: rules } : null;
}
