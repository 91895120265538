import { useQuery, useQueryClient } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import React, { useEffect, useState } from 'react';
import { Computation } from 'components/report-content/components/dataset/CellStyler';
import { escapeHtml } from 'components/report-content/utils/tools';
import applyFormatting from 'components/report-content/components/text-block/helpers/applyFormatting';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;
import ContentSettings = ReportContentNS.ContentSettings;
import TextBlockInitialDataResponse = ReportContentNS.TextBlockInitialDataResponse;
import ComponentSettingsTextBlockFormulaField = ReportContentNS.ComponentSettingsTextBlockFormulaField;
import ComponentSettingsTextBlockMeasureField = ReportContentNS.ComponentSettingsTextBlockMeasureField;
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';
import { getDataQueries } from 'components/report-content/components/text-block/helpers/getDataQueries';
import useOnSVChange from 'components/report-content/hooks/useOnSVChange';
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';

export default function TextBlockView({
    contentSettings,
    component,
}: {
    component: BlockComponent<ComponentSettingsTextBlock>;
    contentSettings: ContentSettings;
}) {
    const { filtersString } = useReportContentContext(() => removeInitialData());
    const queryClient = useQueryClient();
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}`;
    const removeInitialData = () => queryClient.removeQueries({ queryKey: [initialQueryKey] });
    const { data: initialData } = useQuery<TextBlockInitialDataResponse, Error>({
        queryKey: [initialQueryKey],
        queryFn: () => {
            component.settings.dataQueries = getDataQueries(component.settings);
            return reportAPI.getComponentData(component.settings, contentSettings, filtersString);
        },
    });

    const [htmlToShow, setHtmlToShow] = useState(component.settings.html);

    useEffect(() => {
        if (!initialData) {
            return;
        }
        let outputHtml = component.settings.html;

        if (
            initialData.dataResults.values &&
            (component.settings?.measureFields?.length > 0 || component.settings?.formulaFields?.length > 0)
        ) {
            const matches = component.settings.html.match(/\[(.+?)\]/gi);
            let mFields: Array<ComponentSettingsTextBlockMeasureField | ComponentSettingsTextBlockFormulaField> = [];

            if (component.settings.measureFields.length > 0 && component.settings.formulaFields.length > 0) {
                //@ts-ignore
                mFields = component.settings.measureFields.concat(component.settings.formulaFields);
            } else if (component.settings?.formulaFields?.length > 0) {
                mFields = component.settings.formulaFields;
            } else {
                mFields = component.settings.measureFields;
            }

            const computation = new Computation();

            if (matches?.length) {
                matches.forEach((match: string) => {
                    mFields.forEach((field) => {
                        const name = field.column_name;
                        const reference_name = field.reference_name;

                        if ('[' + escapeHtml(name.toLowerCase()) + ']' === match.toLowerCase()) {
                            if (initialData.dataResults?.values?.[0]?.[reference_name]) {
                                const search = match;
                                const replace = applyFormatting(
                                    initialData,
                                    field as ComponentSettingsTextBlockFormulaField,
                                    computation,
                                );
                                outputHtml = outputHtml.split(search).join(false !== replace ? replace : '0');
                            } else {
                                outputHtml = outputHtml.split(match).join('');
                            }
                        }
                    });
                });
            }
        }

        if (initialData.dataResults?.measurement_time?.length > 0) {
            outputHtml = outputHtml.replace(
                /\[Snapshot\sDate\]/gi,
                String(initialData.dataResults.measurement_time[0].measurement_time),
            );
        }

        if (initialData.dataResults?.row_count?.length > 0) {
            outputHtml = outputHtml.replace(/\[Row\sCount\]/gi, String(initialData.dataResults.row_count[0].cnt));
        }

        setHtmlToShow(outputHtml);
    }, [initialData]);

    // Reset all data on segment value change
    useOnSVChange(contentSettings.segmentValueId, removeInitialData);
    // Full reset on Measurement Interval Change
    useOnMeasurementTime(contentSettings.measurement_time, removeInitialData);

    useComponentReady(initialData);
    return initialData ? (
        <span
            dangerouslySetInnerHTML={{
                __html: htmlToShow,
            }}
        ></span>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
