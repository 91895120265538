import { Grid, Stack, Theme } from '@mui/material';
import React from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { alpha } from '@mui/material/styles';

export default function FormPageHeader({
    tabsHeader,
    panelBackButton,
    formTopControlPanel,
}: {
    tabsHeader: ReactJSXElement;
    panelBackButton: ReactJSXElement;
    formTopControlPanel: ReactJSXElement;
}) {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
                pl: 2,
                pr: 2,
                width: 'auto',
                borderBottom: 1,
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                overflow: 'visible',
                backgroundColor: 'background.default',
                zIndex: (theme) => theme.zIndex.tabsPanel,
                flexWrap: 'nowrap',
            }}
        >
            <Grid item sx={{ overflow: 'hidden' }} flexGrow={1}>
                <Stack direction={'row'} alignItems={'center'}>
                    {panelBackButton}
                    {tabsHeader}
                </Stack>
            </Grid>
            <Grid item flexShrink={0}>
                {formTopControlPanel}
            </Grid>
        </Grid>
    );
}
