import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import { YNValues } from 'tools/types';
import { FormControl, FormLabel } from '@mui/material';
import React from 'react';
import useBundleTranslation from 'i18n';

export interface FormControlCollectionTriggerProps extends FormControlProps {
    triggerData: {
        id: number;
        allow_edit_element_ind: YNValues;
        label: string;
    };
}
export default function CollectionTrigger({
    controlProps: props,
}: FormElementControlPropsType<FormControlCollectionTriggerProps>) {
    const { t } = useBundleTranslation('app/content-workflow/editor');

    const triggerData = props.triggerData;

    const triggerLabel =
        triggerData.allow_edit_element_ind === 'Y' ? (
            <a href={`/editor/trigger/${triggerData.id}`}>{triggerData.label}</a>
        ) : (
            <>{triggerData.label}</>
        );

    return (
        <FormControl fullWidth>
            <FormLabel>{t('collection_trigger')}</FormLabel>
            {triggerLabel}
        </FormControl>
    );
}

export class FormCollectionTriggerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlCollectionTriggerProps {
        return {
            ...this.controlProps,
            triggerData: this.elementProps.componentProps?.triggerData,
        };
    }
}
