import React, { useLayoutEffect, useState } from 'react';
import { BookmarkType } from './BookmarkDropdown';
import { FilterType } from 'components/external-reference/Filter';
import { buildBookmarkName, getNewBookmarkObj, processFiltersForBookmark, setBookmarkReferenceId } from './Bookmark';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Box, Button, IconButton, Chip, Tooltip } from '@mui/material';
import BookmarkForm from './BookmarkForm';
import useBundleTranslation from 'i18n';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { Popup } from 'components/common/popup/Popup';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import IconMi from 'components/common/icon/IconMi';

export type ManagerContextType = 'bookmark' | 'combination' | 'reference';

function BookmarkListItem({
    onDeleteClick,
    onDuplicateClick,
    bookmark,
    filters,
    siblingTabs,
    onEditClick,
    hasCheckbox,
    onCheck,
    isChecked,
    link,
    isFirst,
    managerContextType,
}: {
    onDeleteClick: (id: number) => void;
    onDuplicateClick: (id: number) => void;
    bookmark: BookmarkType;
    filters: Array<FilterType>;
    siblingTabs: Array<SiblingTabType>;
    onEditClick: any;
    hasCheckbox: boolean;
    onCheck: (isChecked: boolean) => void;
    isChecked: boolean;
    link: string;
    isFirst: boolean;
    managerContextType: ManagerContextType;
}) {
    const handleDeleteClick = () => onDeleteClick(bookmark.id);
    const handleDuplicateClick = () => onDuplicateClick(bookmark.id);
    const handleEditClick = () => onEditClick(bookmark);

    const name = buildBookmarkName(bookmark, filters, siblingTabs);
    const { t } = useBundleTranslation(['components/external-reference/bookmark']);

    return (
        <Stack
            direction="row"
            sx={{
                padding: '4px 10px 5px',
                borderTop: '1px solid rgba(204, 204, 204, 0.4)',
                '&:first-of-type': { borderTop: 0 },
            }}
        >
            {hasCheckbox && (
                <Box>
                    <Checkbox
                        sx={{ py: 0, pl: 0 }}
                        onChange={(value: any, checked: any) => {
                            onCheck(checked);
                        }}
                        checked={isChecked}
                    />
                </Box>
            )}
            <DragHandle />
            <Box flexGrow={1}>
                {link.length ? (
                    <a href={link} target="_blank">
                        {name}
                    </a>
                ) : (
                    name
                )}
            </Box>
            <Stack flexShrink={0} spacing={1} direction="row">
                {isFirst && !hasCheckbox && managerContextType == 'bookmark' && (
                    <Chip
                        sx={{ marginLeft: '12px', borderRadius: '6px' }}
                        label="default"
                        color="success"
                        variant="outlined"
                    />
                )}
                {managerContextType != 'reference' && (
                    <Tooltip title={t('edit_bookmark', { type: t(managerContextType) })}>
                        <IconButton onClick={handleEditClick} sx={{ padding: 0 }}>
                            <FontAwesomeIcon icon={faCog} fontSize={14} />
                        </IconButton>
                    </Tooltip>
                )}
                {!hasCheckbox && (
                    <>
                        {managerContextType != 'reference' && (
                            <Tooltip title={t('duplicate_bookmark', { type: t(managerContextType) })}>
                                <IconButton onClick={handleDuplicateClick} sx={{ padding: 0 }}>
                                    <FontAwesomeIcon icon={faCopy} fontSize={14} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={t('delete_bookmark', { type: t(managerContextType) })}>
                            <IconButton onClick={handleDeleteClick} sx={{ padding: 0 }}>
                                <FontAwesomeIcon icon={faTrashCan} fontSize={14} />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </Stack>
        </Stack>
    );
}
const DragHandle = SortableHandle(() => <IconMi icon={'drag-and-drop-grid'} fontSize="16" sx={{ mr: 2 }} />);

const SortableItem = SortableElement(
    ({
        filters,
        siblingTabs,
        onDeleteClick,
        onDuplicateClick,
        selectedBookmarks,
        hasCheckbox,
        bookmark,
        handleCheck,
        handleEditClick,
        isFirst,
        link,
        managerContextType,
    }: {
        filters: Array<FilterType>;
        siblingTabs: Array<SiblingTabType>;
        onDeleteClick: (id: number) => void;
        onDuplicateClick: (id: number) => void;
        selectedBookmarks?: Array<number>;
        hasCheckbox: boolean;
        bookmark: BookmarkType;
        handleCheck: any;
        handleEditClick: any;
        link: string;
        isFirst: boolean;
        managerContextType: ManagerContextType;
    }) => {
        return (
            <Box
                sx={{
                    borderTop: '1px solid rgba(204, 204, 204, 0.4)',
                    '&:first-of-type': { borderTop: 0 },
                }}
            >
                <BookmarkListItem
                    managerContextType={managerContextType}
                    link={link}
                    hasCheckbox={hasCheckbox}
                    isChecked={selectedBookmarks?.includes(bookmark.id) ?? false}
                    onCheck={(isChecked: boolean) => handleCheck(bookmark.id, isChecked)}
                    key={bookmark.id}
                    onDeleteClick={onDeleteClick}
                    onDuplicateClick={onDuplicateClick}
                    onEditClick={handleEditClick}
                    bookmark={bookmark}
                    filters={filters}
                    siblingTabs={siblingTabs}
                    isFirst={isFirst}
                />
            </Box>
        );
    },
);

const SortableList = SortableContainer(
    ({
        elementId,
        segmentValueId,
        bookmarks,
        filters,
        siblingTabs,
        onDeleteClick,
        onDuplicateClick,
        selectedBookmarks,
        provideLink,
        hasCheckbox,
        handleCheck,
        handleEditClick,
        managerContextType,
    }: {
        elementId: number;
        segmentValueId: number;
        bookmarks: Array<BookmarkType>;
        filters: Array<FilterType>;
        siblingTabs: Array<SiblingTabType>;
        onDeleteClick: (id: number) => void;
        onDuplicateClick: (id: number) => void;
        selectedBookmarks?: Array<number>;
        provideLink?: boolean;
        hasCheckbox: boolean;
        handleCheck: any;
        handleEditClick: any;
        managerContextType: ManagerContextType;
    }) => {
        return (
            <Stack sx={{ mb: 2, border: '1px solid rgba(204, 204, 204, 0.4)', borderRadius: 1 }}>
                {bookmarks.map((bookmark, index) => {
                    const segment = segmentValueId > 0 ? `/segment/${segmentValueId}` : '';
                    const link = provideLink ? `/extreport/${elementId}${segment}?bookmark=${bookmark.id}` : '';
                    return (
                        <SortableItem
                            key={`item-${bookmark.id}`}
                            index={index}
                            //@ts-ignore
                            isFirst={index == 0}
                            //@ts-ignore
                            handleEditClick={handleEditClick}
                            handleCheck={handleCheck}
                            items={bookmarks}
                            bookmark={bookmark}
                            link={link}
                            filters={filters}
                            siblingTabs={siblingTabs}
                            onDeleteClick={onDeleteClick}
                            onDuplicateClick={onDuplicateClick}
                            selectedBookmarks={selectedBookmarks}
                            hasCheckbox={hasCheckbox}
                            managerContextType={managerContextType}
                        />
                    );
                })}
            </Stack>
        );
    },
);

export default function BookmarkManager({
    elementId,
    segmentValueId,
    bookmarks,
    filters,
    siblingTabs,
    onDeleteClick,
    onDuplicateClick,
    onUpdateClick,
    onSortEnd,
    selectedBookmarks,
    onSelectedBookmarksChange,
    provideLink,
    bookmarkForEdit,
    setOnEditMode,
    managerContextType,
    referenceId,
}: {
    elementId: number;
    segmentValueId: number;
    bookmarks: Array<BookmarkType>;
    filters: Array<FilterType>;
    siblingTabs: Array<SiblingTabType>;
    onDeleteClick: (id: number) => void;
    onDuplicateClick: (id: number) => void;
    onUpdateClick: any;
    onSortEnd: (oldIndex: number, newIndex: number, managerContextType: ManagerContextType) => void;
    selectedBookmarks?: Array<number>;
    onSelectedBookmarksChange?: (list: Array<number>) => void;
    provideLink?: boolean;
    bookmarkForEdit?: BookmarkType;
    setOnEditMode?: (onEditMode: boolean) => void;
    managerContextType: ManagerContextType;
    referenceId?: number;
}) {
    const { t } = useBundleTranslation(['components/external-reference/bookmark']);
    const [selectedBookmark, setSelectedBookmark] = useState<BookmarkType | undefined>(bookmarkForEdit);

    const handleEditClick = function (bookmark: BookmarkType) {
        setSelectedBookmark(bookmark);
    };

    useLayoutEffect(() => {
        if (!setOnEditMode) {
            return;
        }
        setOnEditMode(selectedBookmark != undefined);
    }, [selectedBookmark]);

    const handleNewClick = function () {
        const bookmark = getNewBookmarkObj();
        bookmark.filtersList = processFiltersForBookmark(filters);
        bookmark.bookmarkType = managerContextType;
        if (referenceId && siblingTabs.length) {
            let siblingTab = siblingTabs.find((t) => t.referenceId == Number(referenceId));
            if (!siblingTab) {
                siblingTab = siblingTabs[0];
            }
            setBookmarkReferenceId(bookmark, siblingTab);
        }
        setSelectedBookmark(bookmark);
    };

    const handleSaveBookmark = function (bookmark: BookmarkType) {
        onUpdateClick(bookmark);
        setSelectedBookmark(undefined);
    };

    const handleCancelEditClick = function () {
        setSelectedBookmark(undefined);
    };

    const hasCheckbox = Array.isArray(selectedBookmarks);
    const formInPopup = hasCheckbox;

    const handleCheck = (id: number, isChecked: boolean) => {
        if (!Array.isArray(selectedBookmarks) || !onSelectedBookmarksChange) {
            return;
        }
        const list = selectedBookmarks.slice();
        if (isChecked && !list.includes(id)) {
            list.push(id);
        } else {
            const index = list.findIndex((el) => el == id);
            list.splice(index, 1);
        }
        onSelectedBookmarksChange(list);
    };

    const handleFieldsSort = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        onSortEnd(oldIndex, newIndex, managerContextType);
    };

    return (
        <span>
            {(!selectedBookmark || formInPopup) && (
                <div>
                    {bookmarks.length > 0 && (
                        <SortableList
                            //@ts-ignore
                            handleEditClick={handleEditClick}
                            handleCheck={handleCheck}
                            useDragHandle
                            items={bookmarks}
                            onSortEnd={handleFieldsSort}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            bookmarks={bookmarks}
                            filters={filters}
                            siblingTabs={siblingTabs}
                            onDeleteClick={onDeleteClick}
                            onDuplicateClick={onDuplicateClick}
                            selectedBookmarks={selectedBookmarks}
                            provideLink={provideLink}
                            hasCheckbox={hasCheckbox}
                            managerContextType={managerContextType}
                        />
                    )}

                    {bookmarks.length == 0 && (
                        <Box
                            sx={{
                                border: '1px solid rgba(204, 204, 204, 0.4)',
                                borderRadius: 1,
                                textAlign: 'center',
                                lineHeight: '30px',
                                mb: 2,
                            }}
                        >
                            {t('empty_list', { type: t(managerContextType) })}
                        </Box>
                    )}
                    <Button variant="outlined" onClick={handleNewClick} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                        {t(managerContextType)}
                    </Button>
                </div>
            )}
            {selectedBookmark &&
                (formInPopup ? (
                    <Popup
                        settings={{ noButtons: true }}
                        open={Boolean(selectedBookmark)}
                        onHide={handleCancelEditClick}
                        maxWidth="popupXl"
                    >
                        <BookmarkForm
                            elementId={elementId}
                            managerContextType={managerContextType}
                            bookmark={selectedBookmark}
                            siblingTabs={siblingTabs}
                            filters={filters}
                            onSave={handleSaveBookmark}
                            onCancel={handleCancelEditClick}
                        />
                    </Popup>
                ) : (
                    <BookmarkForm
                        elementId={elementId}
                        managerContextType={managerContextType}
                        bookmark={selectedBookmark}
                        siblingTabs={siblingTabs}
                        filters={filters}
                        onSave={handleSaveBookmark}
                        onCancel={handleCancelEditClick}
                    />
                ))}
        </span>
    );
}
