import { Box, Typography } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

export default function Category({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const { t } = useBundleTranslation(['app/home', 'main']);

    return datasetInfo.category_name ? (
        <Box sx={styles.flexStartWrapper}>
            <IconMi icon={'categories'} fontSize="16" />
            <Typography>{`${t('category')}: ${datasetInfo.category_name}`}</Typography>
        </Box>
    ) : null;
}
