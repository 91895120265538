import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, IconButton, Link, Tooltip } from '@mui/material';
import PartItemWallThread from 'components/wall/parts/PartItemWallThread';
import PartItemWallSharedFiles from 'components/wall/parts/PartItemWallSharedFiles';
import { wallProcessMarkdownText, wallProcessText } from 'components/wall/index';
import IconMi from 'components/common/icon/IconMi';
import { getInsightfulUsers, InsightfulUsersTooltip } from 'components/wall/Wall';
import GroupAnnotationDeletePopup from 'components/wall/parts/GroupAnnotationDeletePopup';
import useWallContext, { wallDeleteAnnotation } from 'components/wall/hooks/useWallContext';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ItemWallUserAnnotationProps {
    data: any;
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    notifyOther?: (type: string, text: any, itemData: any) => void;
    imageAntiCache?: string;
}

export default function ItemWallUserAnnotation(props: ItemWallUserAnnotationProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        uid,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        notifyOther = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const [showGroupDeletePopup, setShowGroupDeletePopup] = useState<any>(false);
    const [insightfulUsersTooltipData, setInsightfulUsersTooltipData] = useState<any>(null);

    const getTextTitle = () => {
        const value = data.measurement_value_formatted != '' ? data.measurement_value_formatted : undefined;
        const time_formatted = data.annotation_measurement_time_formatted
            ? data.annotation_measurement_time_formatted.split(',')
            : undefined;
        const time = time_formatted
            ? `${time_formatted[0]}${time_formatted[1] ? ' - ' + time_formatted[1] : ''}`
            : undefined;

        if (value && time) return t('user_annotation.item_title', { value: value, time: time });

        if (value) return value;
        if (time) return time;
    };

    const followTooltip =
        data.user_follower_id > 0
            ? t('user_annotation.unfollow_tooltip')
            : t('user_annotation.follow_tooltip', {
                  name: data.u_first_name ?? data.username,
              });

    const disableDelete = Object.keys(data.comments).some((key) => {
        return data.comments[key].comment_user_id != params.user_id;
    });

    const { triggerOnUpdate, setEditAnnotationId, wallActions } = useWallContext(false);

    const editAnnotation = (annotationId: number) => {
        setEditAnnotationId(annotationId);
    };

    const deleteAnnotation = () => {
        if (!confirm(t('user_annotation.delete_warn'))) return false;

        const element = params.element_id,
            segment = params.segment_value_id,
            instance = params.user_dashboard_element_instance_id,
            group_id = data.annotation_group_id,
            ann_id = data.user_annotation_id;
        let url = '';

        const isMetric = params.element_type == 'metric' || params.element_type == 'multi-metric chart';

        if (isMetric && group_id > 0) {
            //show group popup delete
            ///Users/stanislav/Data/Projects/metric-insights/engine/application/chart/views/scripts/annotation/groupdeletepopup.phtml
            setShowGroupDeletePopup({
                element: element,
                segment: segment,
                instance: instance,
                ann_id: ann_id,
                group_id: group_id,
            });
            return false;
        }

        wallDeleteAnnotation(element, segment, instance, ann_id, isMetric)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    wallActions.onDeleteAnnotation(ann_id);
                }
            })
            .finally(() => {
                triggerOnUpdate({});
            });
    };

    return (
        <Box
            className={`wall_node_OLD wall_annotation_node_OLD ${
                data.is_future_annotation ? 'future' : ''
            } wall__item wall__item--annotation`}
        >
            <Box id={`wall_annotation_${uid}_${data.user_annotation_id}`} className="wall_annotation wall__item-body">
                <Box className="wall__item-icon-block wall_annotation_number_image_td_OLD">
                    <Box
                        className="wall__item-icon-holder"
                        sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08) }}
                    >
                        <IconMi icon="annotation" sx={{ fontSize: '24px', color: 'primary.main' }} />
                        {params.ann_index ? (
                            <>
                                {data.is_future_annotation && (
                                    <Box
                                        component="span"
                                        sx={{
                                            position: 'absolute',
                                            color: 'text.opposite',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faThumbTack} />
                                    </Box>
                                )}
                                <Box
                                    component="span"
                                    className="annotation_number_span"
                                    sx={{ position: 'absolute', color: 'text.opposite', fontSize: '9px', mt: '-4px' }}
                                >
                                    {params.ann_index[data.user_annotation_id]}
                                </Box>
                            </>
                        ) : (
                            <img
                                alt=""
                                className="wall_annotation_image"
                                src="<?=Devx_Url::site('/img/annotationn.png')?>"
                            />
                        )}
                    </Box>
                </Box>
                <Box className="wall__item-content-block wall_annot clear_after">
                    {(data.measurement_value_formatted != '' || data.annotation_measurement_time_formatted) && (
                        <Box className="wall_annotation_text_title">
                            <Box component="b" sx={{ color: 'primary.main' }}>
                                {getTextTitle()}
                            </Box>
                        </Box>
                    )}

                    <Box className="wall_comment_user_el_OLD wall__item wall__item--comment">
                        <Box className="wall__item-body">
                            <Box className="wall__item-icon-block wall_comment_user_image_td">
                                <Box className="wall__item-icon-holder">
                                    <img
                                        alt=""
                                        className={`wall_comment_user_image wall_user_image_${data.author_user_id} ${
                                            data.user_follower_id > 0 ? 'wall_rounded_image_OLD' : ''
                                        }`}
                                        src={`/data/dashboard/wall/image?user=${data.author_user_id}&thumb=comment${
                                            imageAntiCache ? `&r=${imageAntiCache}` : ''
                                        }`}
                                    />
                                </Box>

                                {data.author_user_id != params.user_id && (
                                    <div className="wall__item-follow follow_td">
                                        <div className="follow_td_div_OLD">
                                            <Tooltip title={followTooltip}>
                                                <Box
                                                    component="a"
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        followUser({
                                                            user_follower_id: data.user_follower_id,
                                                            author_user_id: data.author_user_id,
                                                        });
                                                    }}
                                                    className={`wall_follow_with_name wall_user_${
                                                        data.user_follower_id > 0
                                                            ? `unfollow wall_user_${data.user_follower_id}`
                                                            : 'follow'
                                                    }`}
                                                    data-user_follower_id={
                                                        data.user_follower_id > 0 ? data.user_follower_id : undefined
                                                    }
                                                    data-user_id={data.author_user_id}
                                                >
                                                    {data.user_follower_id > 0
                                                        ? t('user_annotation.unfollow_link')
                                                        : t('user_annotation.follow_link')}
                                                </Box>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </Box>
                            <Box className="wall__item-content-block wall_annotation_text markdown">
                                <Box
                                    component="b"
                                    dangerouslySetInnerHTML={{
                                        __html: `${wallProcessText(data.username, params)}:`,
                                    }}
                                />
                                <Box
                                    className="wall__item-text markdown-holder"
                                    dangerouslySetInnerHTML={{
                                        __html: wallProcessMarkdownText(data.text, params),
                                    }}
                                />
                                <Box className="wall__item-content-footer wall_node_footer_OLD">
                                    <Box className="wall__item-content-footer-left">
                                        {data.is_future_annotation != '1' && (
                                            <>
                                                <Tooltip title={t('user_annotation.insightful_link')}>
                                                    <Box
                                                        component="span"
                                                        className="wall_insightful_annotation wall__item-content-footer-link"
                                                        data-user_annotation_id={data.user_annotation_id}
                                                        onClick={() => {
                                                            insightful('annotation', data.user_annotation_id);
                                                        }}
                                                    >
                                                        <IconMi icon="light" fontSize="16" />
                                                        {t('user_annotation.insightful_link')}
                                                    </Box>
                                                </Tooltip>

                                                {data.insightful_count > 0 && (
                                                    <Tooltip
                                                        title={insightfulUsersTooltipData}
                                                        disableInteractive
                                                        enterDelay={500}
                                                        onOpen={async () => {
                                                            const users = await getInsightfulUsers({
                                                                user_annotation_id: data.user_annotation_id,
                                                            });
                                                            if (users) {
                                                                setInsightfulUsersTooltipData(
                                                                    <InsightfulUsersTooltip users={users} />,
                                                                );
                                                            }
                                                        }}
                                                        onClose={() => setInsightfulUsersTooltipData(null)}
                                                    >
                                                        <Box
                                                            component="span"
                                                            sx={{ ml: -0.75, mr: 1, fontSize: '11px' }}
                                                            className="wall_insightful_users"
                                                            data-user_annotation_id={data.user_annotation_id}
                                                        >
                                                            ({data.insightful_count})
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={t('user_annotation.share_link')}>
                                                    <Box
                                                        component="span"
                                                        className="wall_annotation_notify_other wall__item-content-footer-link"
                                                        data-user_annotation_id={data.user_annotation_id}
                                                        onClick={() => {
                                                            notifyOther(
                                                                'annotation',
                                                                wallProcessMarkdownText(data.text, params),
                                                                data,
                                                            );
                                                        }}
                                                    >
                                                        <IconMi icon="share" fontSize="16" />
                                                        {t('user_annotation.share_link')}{' '}
                                                    </Box>
                                                </Tooltip>
                                            </>
                                        )}

                                        {params.ann_index &&
                                            (data.author_user_id == params.user_id || wallData.settings.isAdmin) && (
                                                <Tooltip
                                                    title={t('user_annotation.edit_annotation_icon')}
                                                    disableInteractive
                                                >
                                                    <IconButton
                                                        sx={{ p: '0', color: 'inherit' }}
                                                        className="wall__item-content-footer-icon-button wall_edit_annotation"
                                                        data-user_annotation_id={data.user_annotation_id}
                                                        data-annotation_group_id={data.annotation_group_id}
                                                        onClick={() => editAnnotation(data.user_annotation_id)}
                                                    >
                                                        <IconMi icon="edit-pencil" fontSize="16" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                        {((data.author_user_id == params.user_id && !disableDelete) ||
                                            wallData.settings.isAdmin) && (
                                            <Tooltip
                                                title={t('user_annotation.delete_annotation_icon')}
                                                disableInteractive
                                            >
                                                <IconButton
                                                    sx={{ p: '0', color: 'inherit' }}
                                                    className="wall__item-content-footer-icon-button wall_delete_annotation"
                                                    data-user_annotation_id={data.user_annotation_id}
                                                    data-annotation_group_id={data.annotation_group_id}
                                                    onClick={deleteAnnotation}
                                                >
                                                    <IconMi icon="delete" fontSize="16" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                                <PartItemWallSharedFiles data={data} elementType={'annotation'} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {data.is_future_annotation != '1' && (
                <Box className="wall__item-thread-block">
                    <PartItemWallThread
                        {...props}
                        elementType={'user_annotation'}
                        updateWallData={(newData) => {
                            updateWallData(newData);
                        }}
                        followUser={followUser}
                        insightful={insightful}
                        deleteAnnotation={deleteAnnotation}
                        notifyOther={notifyOther}
                        imageAntiCache={imageAntiCache}
                    />
                </Box>
            )}

            {showGroupDeletePopup && (
                <GroupAnnotationDeletePopup
                    data={showGroupDeletePopup}
                    onDeleteAll={() => {
                        setShowGroupDeletePopup(false);
                        //ToDo ci.deleteGroupAnnot(data.user_annotation_id)
                    }}
                    onOnlyThisChart={() => {
                        setShowGroupDeletePopup(false);
                        //ToDo ci.hideGroupAnnot(params.element_id, data.annotation_group_id)
                    }}
                    onClose={() => {
                        setShowGroupDeletePopup(false);
                    }}
                />
            )}
        </Box>
    );
}
