import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { useFormState } from 'react-hook-form';
import { getElementFieldValue } from 'components/common/form/formTools';
import DataFetchCommandSubstitution from 'components/common/form/data-fetch-command/substitution/DataFetchCommandSubstitution';
import { AssocArray } from 'tools/types';
import { DataFetchCommandEntityType } from 'components/common/form/DataFetchCommand';

export interface EditorTestUrlProps extends FormControlProps {
    inputElement: string;
    type: DataFetchCommandEntityType;
    dataSource: string;
    subst: AssocArray<string>;
    segmentId: number;
}

import { DataFetchCommandNS } from 'components/common/form/data-fetch-command';
import patternsMap = DataFetchCommandNS.patternsMap;
import { useRequiredFields } from 'components/common/form/data-fetch-command/substitution/hooks/useRequiredFields';
import { editorAPI } from 'api/editor';

const SegmentPattern = ':segment';

export default function EditorTestUrl({ controlProps }: FormElementControlPropsType<EditorTestUrlProps>) {
    const { t } = useBundleTranslation(['components/common/editor_test_url']);

    const [lastCollectionData, setLastCollectionData] = useState<AssocArray<string>>({});

    const { isDirty } = useFormState({ control: controlProps.form.hookFormControl });
    const handleClick = (event: React.MouseEvent, ignoreLastValidationData: boolean) => {
        controlProps.form.hookFormClearErrors(controlProps.inputElement);
        event.stopPropagation();
        if (isDirty) {
            controlProps.form.formSave().then((response) => {
                if (response?.data?.status == 'OK') {
                    testUrl(ignoreLastValidationData);
                }
            });
        } else {
            testUrl(ignoreLastValidationData);
        }
    };

    const testUrl = (ignoreLastValidationData: boolean) => {
        setAlwaysShowForm(ignoreLastValidationData);
        const res = actualValue.match(/(:[a-zA-Z0-9_]+)/gm);
        if (actualValue.length == 0) {
            controlProps.form.hookFormSetError(controlProps.inputElement, {
                type: 'server',
                message: t('empty_statement'),
            });
            return false;
        }

        if (res && res.length) {
            let bindParam = ':' + getElementFieldValue(controlProps.form, `segment_id.bind_param`);
            const ignored = res.filter((m: string) => m != bindParam && !matchedPatterns.includes(m));
            const replace = res.filter((m: string) => !ignored.includes(m));

            if (ignored.length) {
                let text = t('is_not_valid_parameter', { wrongParam: ignored.join(', ') });
                if (replace.length) {
                    text += t('params_can_be_included', { params: replace.join(', '), tag: '\n\n' });
                }
                if (!confirm(text)) {
                    return false;
                }
            }
        }

        if (ignoreLastValidationData || Object.values(lastCollectionData).length == 0) {
            setIsOpen(true);
        } else {
            handleStartValidation(lastCollectionData);
        }
    };

    const actualValue = controlProps.form.hookFormWatch(controlProps.inputElement);

    const [isOpen, setIsOpen] = useState(false);

    const handleStartValidation = async (args: any) => {
        setLastCollectionData(args);
        setIsOpen(false);
        setAlwaysShowForm(false);
        const paramsList: Array<string> = [];
        const segmentValuesList = await editorAPI.getSegmentValuesList(Number(args?.segment_id ?? 0));
        const res = actualValue.match(/(:[a-zA-Z0-9_]+)/gm);
        let resultUrl = actualValue;
        if (res && res.length) {
            let bindParam = ':' + getElementFieldValue(controlProps.form, `segment_id.bind_param`);
            const ignored = res.filter((m: string) => m != bindParam && !matchedPatterns.includes(m));
            const replace = res.filter((m: string) => !ignored.includes(m));
            ignored.forEach((s: string) => (resultUrl = resultUrl.replace(s, '')));
            replace.forEach((s: string) => {
                if (args[s]) {
                    resultUrl = resultUrl.replace(s, args[s]);
                    paramsList.push(args[s]);
                } else if (s == bindParam && args?.segment_value_id) {
                    const sv = segmentValuesList.find((s) => s.value == args.segment_value_id);
                    if (sv) {
                        resultUrl = resultUrl.replace(s, sv.label);
                        paramsList.push(sv.label);
                    }
                }
            });
        }
        setLastParamsString(paramsList.join(', '));
        const newWindow = window.open(resultUrl, '_blank', 'resizable=yes');
    };

    // Statement
    const [hasSegmentPattern, setHasSegmentPattern] = useState<boolean>(false);
    const [matchedPatterns, setMatchedPatterns] = useState<Array<string>>([]);

    const [lastParamsString, setLastParamsString] = useState('');

    useEffect(() => {
        if (hasSegmentPattern) {
            setMatchedPatterns((prevState) => {
                return [...prevState, SegmentPattern];
            });
        } else {
            setMatchedPatterns((prevState) => prevState.filter((pattern) => pattern != SegmentPattern));
        }
    }, [hasSegmentPattern]);

    const [alwaysShowForm, setAlwaysShowForm] = useState(false);
    const [isDsLoadInd, setIsDsLoadInd] = useState<boolean>(false);
    const [isDataSource, setIsDataSource] = useState<boolean>(false);
    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'ds_incremental_load_ind') {
                setIsDsLoadInd(value['ds_incremental_load_ind'] == 'Y');
            }
            if (name == 'data_source') {
                setIsDataSource(value['data_source'] == 'dataset');
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    useEffect(() => {
        if (!controlProps.form.formDidMount) {
            return;
        }

        const list = patternsMap[controlProps.type].filter((s) => actualValue.includes(s));
        if (hasSegmentPattern) {
            list.push(SegmentPattern);
        }

        if (isDataSource && controlProps.type == 'metric') {
            if (isDsLoadInd) {
                if (!list.includes(':last_measurement_time')) {
                    list.push(':last_measurement_time');
                }
            } else {
                const index = list.findIndex((s) => s == ':last_measurement_time');
                if (index != -1) {
                    list.splice(index, 1);
                }
            }
        }

        setMatchedPatterns(list);

        if (controlProps.type == 'calendar' || controlProps.type == 'metric' || controlProps.type == 'dataset') {
            const fieldName = controlProps.type == 'calendar' ? 'scope_limited_by_segment_id' : 'segment_id';
            let bindParam = getElementFieldValue(controlProps.form, `${fieldName}.bind_param`);
            if (bindParam == null) {
                setTimeout(() => {
                    bindParam = getElementFieldValue(controlProps.form, `${fieldName}.bind_param`);
                    setHasSegmentPattern(actualValue.includes(':' + bindParam));
                }, 1000);
            } else {
                setHasSegmentPattern(actualValue.includes(':' + bindParam));
            }
        }
    }, [actualValue, controlProps.segmentId, controlProps.form.formDidMount, isDataSource, isDsLoadInd]);

    const requiredFields = useRequiredFields(matchedPatterns, lastCollectionData);

    return (
        <span>
            <a href="#" onClick={(event) => handleClick(event, false)}>
                {t('test_url')}
            </a>
            {lastParamsString.length > 0 && requiredFields.length > 0 && (
                <div>
                    {t('last_performed_for')}: {lastParamsString} <br />
                    <a href="#" onClick={(event) => handleClick(event, true)}>
                        {t('change_value')}
                    </a>
                </div>
            )}
            {isOpen && (
                <DataFetchCommandSubstitution
                    mode={'validate'}
                    matchedPatterns={matchedPatterns}
                    requiredFields={requiredFields}
                    defaults={lastCollectionData}
                    start={handleStartValidation}
                    cancel={() => {
                        setIsOpen(false);
                        setAlwaysShowForm(false);
                    }}
                    type={controlProps.type}
                    editorForm={controlProps.form}
                    dataSource={controlProps.dataSource}
                    alwaysShowForm={alwaysShowForm}
                />
            )}
        </span>
    );
}

function Form() {}

export class EditorTestUrlBuilder extends FormComponentBuilder {
    prepareProps(): EditorTestUrlProps {
        const subst: AssocArray<string> = {};
        for (const [key, value] of Object.entries(this.elementProps.componentProps.subst ?? {})) {
            if (String(value).length > 0) {
                subst[key] = String(value);
            }
        }

        return {
            ...this.controlProps,
            inputElement: this.elementProps.componentProps?.inputElement ?? '',
            type: this.elementProps.componentProps.type ?? 'dataset',
            dataSource: this.elementProps.componentProps.dataSource ?? '',
            segmentId: this.elementProps.componentProps.segmentId ?? 0,
            subst: subst,
        };
    }
}
