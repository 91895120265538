import { TreeNode } from './index';
import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button } from '@mui/material';
import Tree, { FlattenedNode, selectors } from 'react-virtualized-tree';
import ReferenceSelectionTreeNode from './ReferenceSelectionTreeNode';
import { useTheme } from '@mui/material/styles';

const { updateNode } = selectors;

function NodeRenderer({
    node,
    onChange,
    onNodeClick,
    searchPattern,
    referenceId,
}: {
    node: TreeNode;
    onChange: any;
    onNodeClick: any;
    searchPattern?: string;
    referenceId: number;
}) {
    const onExpandIconClick = (node: TreeNode) => {
        onChange(updateNode(node as FlattenedNode, { expanded: !Boolean(node?.state?.expanded ?? false) }));
    };

    return (
        <ReferenceSelectionTreeNode
            searchPattern={searchPattern}
            onNodeClick={onNodeClick}
            onExpandIconClick={onExpandIconClick}
            node={node}
            isSelected={referenceId > 0 && (node.id == referenceId || node.data?.linked_ref_id == referenceId)}
        />
    );
}

// Apply text filter to nodes
function applyVisibleStatus(node: TreeNode) {
    node.isVisible = true;
    if (node?.children) {
        node.children.forEach((child) => {
            applyVisibleStatus(child);
        });
    }
}

function isNodeVisible(node: TreeNode, filterPattern?: string) {
    if (typeof filterPattern == 'undefined') {
        return true;
    }
    const name = node.name.toLowerCase();
    return name.includes(filterPattern.trim());
}

function filterNode(node: TreeNode, filterPattern?: string): boolean {
    let isVisible = isNodeVisible(node, filterPattern);
    if (isVisible) {
        applyVisibleStatus(node);
        return true;
    }

    node.children?.forEach((child) => {
        isVisible = filterNode(child, filterPattern) || isVisible;
    });

    return (node.isVisible = isVisible);
}
function findNodeByReference(filteredState: Array<TreeNode>, referenceId: number): null | TreeNode {
    let result: null | TreeNode = null;
    filteredState.forEach((node) => {
        if (node.data?.linked_ref_id == referenceId || node.id == referenceId) {
            result = node;
        }
        const childFound = findNodeByReference(node.children as Array<TreeNode>, referenceId);
        if (childFound) {
            result = childFound;
        }
    });
    return result;
}

export default function ReferenceTreeWrapper({
    nodes,
    pattern,
    onNodeClick,
    referenceId,
}: {
    nodes: Array<TreeNode>;
    pattern?: string;
    onNodeClick: any;
    referenceId: number;
}) {
    const [state, setState] = useState<Array<TreeNode>>(nodes);
    const [filteredState, setFilteredState] = useState<Array<TreeNode>>(nodes);
    useEffect(() => {
        setState(nodes);
        setFilteredState(applyVisibleFilter(nodes));
    }, [nodes]);
    const filterPattern = pattern?.toLowerCase();

    // Used to apply changes (expanded state of node) to full nodes list
    // Only actual when text filter applied
    const mergeState = (prevState: Array<TreeNode>, newState: Array<TreeNode>) => {
        newState.forEach((newNode) => {
            const prevNodeIndex = prevState.findIndex((prevState) => prevState.id == newNode.id);
            if (prevNodeIndex != -1) {
                const prevNode = prevState[prevNodeIndex];
                prevNode.state = newNode.state;

                if (prevNode.children && newNode.children) {
                    mergeState(prevNode.children, newNode.children);
                }
            }
        });
        return prevState;
    };

    // On node expanded state change
    const handleChange = (newState: any) => {
        if (filterPattern && filterPattern.length > 0) {
            setState((prevState) => mergeState(prevState, newState).slice());
        } else {
            setState(newState);
        }
        setScrollTo(undefined);
    };

    useEffect(() => {
        // TODO: find better way ?
        setFilteredState(applyVisibleFilter(JSON.parse(JSON.stringify(state))));
    }, [state]);

    // Apply text filter
    useEffect(() => {
        const list: Array<TreeNode> = state.slice();
        list.forEach((node) => {
            filterNode(node, filterPattern);
        });
        setState(list);
    }, [pattern]);

    // Last applied pattern (used to open tree on pattern change)
    const [patternToExpand, setPatternToExpand] = useState<string>('');
    const applyVisibleFilter = (list: Array<TreeNode>) => {
        const result = list
            .filter(
                (node) => (typeof node.isVisible == 'undefined' || node.isVisible) && node.data?.fakeRollupId != 'Y',
            )
            .map((node) => {
                if (node.children) {
                    node.children = applyVisibleFilter(node.children);
                }
                if (filterPattern?.length && patternToExpand != filterPattern) {
                    if (!node.state) {
                        node.state = {};
                    }
                    node.state.expanded = true;
                }

                return node;
            });
        setPatternToExpand(filterPattern ?? '');
        return result;
    };

    const applyExpandStatus = (node: TreeNode, expanded: boolean) => {
        if (!node.state) {
            node.state = {};
        }
        node.state.expanded = expanded;
        if (node?.children) {
            node.children.forEach((child) => {
                applyExpandStatus(child, expanded);
            });
        }
        return node;
    };

    const handleExpandAll = () => {
        setState(state.map((node) => applyExpandStatus(node, true)));
    };

    const handleCollapseAll = () => {
        setState(state.map((node) => applyExpandStatus(node, false)));
    };

    const { t } = useBundleTranslation(['components/common/reference_selection']);
    const appTheme = useTheme();

    const scrollToNode = findNodeByReference(filteredState, referenceId);
    const [scrollToId, setScrollTo] = useState<number | undefined>(
        scrollToNode ? Number(scrollToNode.id) : referenceId,
    );
    useEffect(() => {
        const newScrollToId = scrollToNode ? Number(scrollToNode.id) : referenceId;
        if (newScrollToId != scrollToId) {
            setScrollTo(newScrollToId);
        }
    }, [referenceId, JSON.stringify(scrollToNode)]);

    return (
        <>
            <Box>
                <Button
                    data-test={'tree_expand_all'}
                    onClick={handleExpandAll}
                    color="neutral"
                    variant="outlined"
                    sx={{ my: 2 }}
                >
                    {t('expand_all')}
                </Button>
                <Button
                    data-test={'tree_collapse_all'}
                    onClick={handleCollapseAll}
                    color="neutral"
                    variant="outlined"
                    sx={{ my: 2, ml: 1 }}
                >
                    {t('collapse_all')}
                </Button>
            </Box>
            <Box
                sx={{
                    height: '500px',
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.16),
                    borderRadius: 1,
                    overflow: 'hidden',
                }}
            >
                <Tree
                    nodes={filteredState}
                    onChange={handleChange}
                    scrollToAlignment="center"
                    nodeMarginLeft={0}
                    scrollToId={scrollToId}
                >
                    {({ style, ...p }) => {
                        const levelIndent = p.node.deepness > 0 ? 24 * p.node.deepness + 'px' : 0;
                        return (
                            <div
                                style={{
                                    ...style,
                                    marginLeft: '8px',
                                    cursor: 'default',
                                    paddingLeft: levelIndent,
                                    backgroundSize: '24px 24px',
                                    backgroundImage: `linear-gradient(to right, ${alpha(
                                        appTheme.palette.text.primary,
                                        0.08,
                                    )} 1px, transparent 1px)`,
                                    backgroundPosition: '8px 0px',
                                    userSelect: 'auto',
                                }}
                            >
                                <NodeRenderer
                                    searchPattern={filterPattern}
                                    onNodeClick={onNodeClick}
                                    {...p}
                                    referenceId={referenceId}
                                />
                            </div>
                        );
                    }}
                </Tree>
            </Box>
        </>
    );
}
