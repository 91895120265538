import useBundleTranslation from 'i18n';
import React, { useState } from 'react';
import { alpha, IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import Lineage from './Lineage';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

function LineageIconButton({
    elementId,
    elementType,
    isActionMenuItem,
    isPanelIcon,
}: {
    elementId: string;
    elementType: string;
    isActionMenuItem?: boolean;
    isPanelIcon?: boolean;
}) {
    const { t } = useBundleTranslation(['components/lineage/lineage']);
    const [showLineage, setShowLineage] = useState(false);

    const showButton = useIsAdminOrHasPrivilege('PRIV_ACCESS_LINEAGE_DIAGRAM');

    const onLineageClick = () => {
        setShowLineage(true);
    };

    const hideLineage = () => {
        setShowLineage(false);
    };

    return showButton ? (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={t('panel_btn_lineage_label')}
                    desc={t('panel_btn_lineage_desc')}
                    icon="lineage-diagram"
                    onClick={onLineageClick}
                />
            ) : (
                <Tooltip title={t('lineage')}>
                    <IconButton
                        sx={
                            isPanelIcon
                                ? {
                                      p: '6px',
                                      fontSize: 12,
                                  }
                                : {
                                      p: '6px',
                                      border: '1px solid',
                                      borderRadius: '3px',
                                      borderColor: (theme) => alpha(theme.palette.text.primary, 0.4),
                                  }
                        }
                        onClick={onLineageClick}
                    >
                        <IconMi icon={'lineage-diagram'} fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}

            {showLineage && (
                <Lineage showLineage={showLineage} onHide={hideLineage} id={elementId} type={elementType} />
            )}
        </>
    ) : null;
}

export default LineageIconButton;
