import { Box, Button, DialogActions, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TransformedComponentConfig } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import styles from './UnlinkCell.styles';
import { gridAPI } from 'api/grid';

interface UnlinkCellProps extends TransformedComponentConfig {
    unlinkHref: string;
    deleteHref: string;
    isDelete: boolean;
    reloadGridData?: () => void;
}

export default function UnlinkCell({ unlinkHref, deleteHref, isDelete, reloadGridData }: UnlinkCellProps) {
    const { t } = useBundleTranslation(['app/editor/element/ext-content']);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const onUnlinkClick = () => {
        gridAPI
            .delete(unlinkHref)
            .then(() => {
                reloadGridData && reloadGridData();
            })
            .finally(() => {
                setShowConfirmation(false);
            });
    };

    const onDeleteClick = () => {
        gridAPI
            .delete(deleteHref)
            .then(() => {
                reloadGridData && reloadGridData();
            })
            .finally(() => {
                setShowConfirmation(false);
            });
    };

    const onCancelClick = () => {
        setShowConfirmation(false);
    };

    const dialogActions = isDelete ? (
        <DialogActions sx={styles.dialogContainer}>
            <Stack direction={'row'} spacing={1}>
                <Button data-test={'create-data-source-create-button'} variant={'contained'} onClick={onUnlinkClick}>
                    {t('unlink.button_no')}
                </Button>
                <Button data-test={'create-data-source-back-button'} variant={'contained'} onClick={onDeleteClick}>
                    {t('unlink.button_yes')}
                </Button>
                <Box sx={styles.divider} />
                <Button
                    data-test={'create-data-source-cancel-button'}
                    variant={'outlined'}
                    onClick={onCancelClick}
                    color={'neutral'}
                >
                    {t('unlink.button_cancel')}
                </Button>
            </Stack>
        </DialogActions>
    ) : (
        <DialogActions sx={styles.dialogContainer}>
            <Stack direction={'row'} spacing={1}>
                <Button data-test={'create-data-source-create-button'} variant={'contained'} onClick={onCancelClick}>
                    {t('unlink.button_no')}
                </Button>
                <Button data-test={'create-data-source-back-button'} variant={'contained'} onClick={onUnlinkClick}>
                    {t('unlink.button_yes')}
                </Button>
            </Stack>
        </DialogActions>
    );

    const popupSettings: PopupSettings = {
        title: t('unlink.popup_title'),
        customActionPanel: dialogActions,
    };

    const onClick = () => {
        setShowConfirmation(true);
    };

    return (
        <>
            <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'end' }}>
                <Tooltip title={t('unlink.popup_title')}>
                    <IconButton aria-label="unlink" size={'small'} onClick={onClick} sx={{ color: 'text.primary' }}>
                        <IconMi icon={'times'} fontSize={'16'} />
                    </IconButton>
                </Tooltip>
            </Grid>
            {showConfirmation && (
                <Popup settings={popupSettings} open={showConfirmation} maxWidth={'popupSm'} onHide={onCancelClick}>
                    <Typography>
                        {isDelete ? t('unlink.confirmation_delete') : t('unlink.confirmation_unlink')}
                    </Typography>
                </Popup>
            )}
        </>
    );
}
