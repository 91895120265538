import ReactSelect from 'components/common/react-select/ReactSelect';
import React, { useEffect } from 'react';
import { Input, InputAdornment, TextField } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import IconMi from 'components/common/icon/IconMi';
import moment from 'moment';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';

export interface FilterValue {
    value_id: string | number;
    value: string;
    display_value?: string;
    map?: any;
    isVisible: boolean;
}

export interface FilterProps {
    filter: FilterType;
    onFilterChange: (filterId: number, newValue: number | string) => void;
    context: 'bookmark' | 'filter';
    elementId: number;
}

export interface FilterType {
    filterId: number;
    filterInput: string; // single, multi, text, date
    filterType: string; // ?
    replacedBySegment: boolean;
    canBeCached: boolean;
    isSegment: boolean;
    required: boolean;
    visibleInViewer: boolean;
    values: Array<FilterValue>;
    name: string;
    parentFilterId: number;
    selectedValues: Array<string | number>;
    defaultValues: Array<string | number>;
    title: string;
    parentsMap: ExternalFilterParentMap;
    format?: string; // Format for Date filter
    showTimepicker?: boolean; // TimeSelection for Date filter
}

export class ExternalFilterParentMap {
    filterId: number;
    mapData: any = {};

    constructor(filterId: number) {
        this.filterId = filterId;
    }

    addMap(valueId: any, mapData: any) {
        this.mapData[valueId] = mapData;
    }

    isAvailable(filtersState: Array<FilterType>, valueId: any) {
        var map = this.mapData[valueId];
        if (!map) {
            return true;
        }

        const getPath: any = function (mapData: any, filterId: any) {
            const filter = filtersState.find((f) => f.filterId == filterId);
            if (!filter) {
                return true;
            }
            const parentFilter = filtersState.find((f) => f.filterId == filter.parentFilterId);
            if (!parentFilter) {
                return true;
            }
            let parentValue: any = '';
            // Parent filter can be only Single Value Filter
            if (Array.isArray(parentFilter.selectedValues)) {
                if (parentFilter.selectedValues.length) {
                    parentValue = parentFilter.selectedValues[0];
                }
            } else {
                parentValue = parentFilter.selectedValues;
            }

            // console.log([filterId, parentValue]);
            if (parentValue == -1 || parentValue == '') {
                // If all values selected in parent filter, check all available ways
                var allowed = false;
                for (var i in mapData) {
                    if (!mapData.hasOwnProperty(i)) {
                        continue;
                    }
                    if (typeof mapData[i] == 'object') {
                        allowed = getPath(mapData[i], parentFilter.filterId);
                    } else {
                        if (mapData[i]) {
                            allowed = true;
                        }
                    }
                    if (allowed) {
                        return true;
                    }
                }
                return allowed;
            }

            if (typeof mapData[parentValue] == 'object') {
                return getPath(mapData[parentValue], parentFilter.filterId);
            }
            if (mapData[parentValue]) {
                return true;
            }
            return false;
        };

        return getPath(map, this.filterId);
    }
}

export function useApplyFilterValueMap(filters: Array<FilterType>): Array<FilterType> {
    const list = filters.slice();
    list.forEach((filter) => {
        filter.values.forEach((v) => {
            v.isVisible = filter.parentsMap.isAvailable(filters, v.value_id);
        });
        filter.selectedValues = filter.selectedValues.filter((sv) => {
            const value = filter.values.find((value) => value.value_id == sv);
            return !value || value.isVisible;
        });
        if (filter.selectedValues.length == 0 && filter.required && filter.filterInput != 'text') {
            const allowedValue = filter.values.find((v) => v.isVisible);
            if (allowedValue) {
                filter.selectedValues = [allowedValue.value_id];
            }
        }
    });
    return list;
}

export function getFiltersActiveValuesWithData(filtersList: Array<FilterType>) {
    const result: any = [];
    filtersList.forEach((filter) => {
        if (filter.filterId < 0 || filter.replacedBySegment) {
            return;
        }

        const valuesList: Array<FilterValue> = [];
        filter.selectedValues.forEach((sv) => {
            const value = filter.values.find((v) => sv == v.value_id);
            if (value) {
                valuesList.push(value);
            }
        });

        result.push({
            id: filter.filterId,
            name: filter.name,
            title: filter.title,
            values: valuesList,
            replacedBySegment: filter.replacedBySegment,
            canBeCached: true,
            filterInput: filter.filterInput,
        });
    });
    return result;
}

export function getFiltersStateBuilder(filters: Array<FilterType>, useDefaultValues: boolean = false): any {
    const newState: any = {};
    const valuesToUse = useDefaultValues ? 'defaultValues' : 'selectedValues';
    filters.forEach((filter) => {
        if (filter[valuesToUse].length == 0 || filter.replacedBySegment) {
            return;
        }
        if (filter.filterInput == 'date') {
            const dateValue = getDateFilterValue(filter);
            if (dateValue != null) {
                if (typeof newState[filter.filterId] == 'undefined') {
                    newState[filter.filterId] = {};
                }
                const strValue = moment(dateValue).format('YYYY-MM-DD H:mm:ss');
                //@ts-ignore
                if (typeof filter.selectedValues[0]?.value != 'undefined') {
                    //@ts-ignore
                    newState[filter.filterId][filter.selectedValues[0].value] = strValue;
                } else {
                    newState[filter.filterId][strValue] = strValue;
                }
            }
        } else if (filter.filterInput == 'text') {
            if (typeof newState[filter.filterId] == 'undefined') {
                newState[filter.filterId] = {};
            }
            newState[filter.filterId]['live_' + filter.filterId] = filter[valuesToUse].join();
        } else {
            filter.values.forEach((v) => {
                if (filter[valuesToUse].includes(v.value_id)) {
                    if (typeof newState[filter.filterId] == 'undefined') {
                        newState[filter.filterId] = {};
                    }
                    newState[filter.filterId][v.value_id] = v.value;
                }
            });
        }
    });
    return newState;
}

function getDateFilterValue(filter: FilterType) {
    if (filter.filterInput != 'date' || !filter.selectedValues.length) {
        return null;
    }
    const value = filter.selectedValues[0];
    if (typeof value == 'object') {
        //@ts-ignore
        if (typeof value.value != 'undefined') {
            //@ts-ignore
            const v = filter.values.find((v) => v.value_id == value.value);
            if (v) {
                //@ts-ignore
                return new Date(v.raw_value);
            }
        }
        return value as Date;
    }

    if (typeof value == 'string') {
        return new Date(value);
    }

    const v = filter.values.find((v) => v.value_id == value);
    //@ts-ignore
    return v ? new Date(v.raw_value) : null;
}

export default function Filter({ filter, onFilterChange, context, elementId }: FilterProps) {
    const handleUpdate = function (newValue: any) {
        onFilterChange(filter.filterId, newValue);
    };

    const data = filter.values
        .filter((v) => v.isVisible)
        .map((v: any) => ({
            label: typeof v.display_value == 'undefined' ? v.value : v.display_value,
            value: v.value_id,
            disabled: false,
            hidden: false,
            props: [],
        }));

    const tooltip = filter.selectedValues
        .map((sv) => {
            const val = filter.values.find((v) => v.value_id == sv);
            return val ? (val.display_value ?? val.value) : null;
        })
        .filter((v) => v != null)
        .join(', ');

    // Setup value for date filter
    const dateFilterName = `date_${filter.filterId}`;
    //@ts-ignore
    let dateValue = filter.selectedValues?.[0]?.value ?? undefined;
    if (!dateValue && Number(filter.selectedValues?.[0])) {
        dateValue = filter.selectedValues?.[0];
    }
    const componentForm = useCustomSimplifiedForm({
        [dateFilterName]: dateValue,
    });

    const dateFilter = componentForm.form.hookFormWatch(dateFilterName);
    useEffect(() => {
        if (context != 'bookmark' || filter.filterInput != 'date') {
            return;
        }
        const label = componentForm.form.hookFormGetValues(dateFilterName + '_label');
        if (dateFilter == 0) {
            handleUpdate('');
            return;
        }
        handleUpdate({ value: dateFilter, label: label });
    }, [dateFilter]);

    if (filter.filterInput == 'date') {
        if (context == 'bookmark') {
            return (
                <ReactHookFormController
                    elementProps={prepareFormElementProps({
                        ...componentForm,
                        ...{
                            component: {
                                component: 'FilterDateDefault',
                                name: dateFilterName,
                                props: {
                                    hasEmpty: true,
                                    itemId: elementId,
                                },
                            } as RawFormComponentType,
                        },
                    })}
                />
            );
        }

        const inputProps = {
            startAdornment: (
                <InputAdornment position="start">
                    <IconMi icon="calendar" fontSize="16" />
                </InputAdornment>
            ),
        };

        let startDate = getDateFilterValue(filter);
        if (startDate == null || isNaN(startDate.getTime())) {
            startDate = null;
        }
        return (
            <ReactDatePicker
                selected={startDate}
                onChange={handleUpdate}
                showTimeSelect={filter.showTimepicker}
                fixedHeight
                dateFormat={filter.format ?? 'MM/dd/yy'}
                showPopperArrow={false}
                customInput={<TextField fullWidth InputProps={inputProps} />}
                popperClassName={'custom-datepicker__react-datepicker-popper'}
            />
        );
    } else if (filter.filterInput == 'text') {
        const handleTextChange = function (event: any) {
            handleUpdate(event.target.value);
        };
        return <Input type="text" onChange={handleTextChange} value={filter.selectedValues.join()} fullWidth />;
    } else {
        return (
            <ReactSelect
                tooltip={filter.filterInput == 'multi' ? tooltip : undefined}
                isMulti={filter.filterInput == 'multi'}
                isMultiOneRowMode
                value={filter.selectedValues}
                update={handleUpdate}
                data={data}
                customPortalTarget={null}
                isClearable={filter.filterInput == 'multi'}
                applyVirtualList
            />
        );
    }
}
