import { createSlice } from '@reduxjs/toolkit';

interface themeEditorSliceState {
    overrides: any;
    menu: any;
}

export const themeEditorSlice = createSlice({
    name: 'themeEditor',
    initialState: {
        overrides: {},
        menu: {},
    } as themeEditorSliceState,

    reducers: {
        setThemeOverrides: (state, action) => {
            state.overrides = action.payload;
        },
        setThemeMenuOverrides: (state, action) => {
            state.menu = action.payload;
        },
        resetThemeEditor: (state) => {
            state.overrides = {};
            state.menu = {};
        },
    },
});

export const { setThemeOverrides, setThemeMenuOverrides, resetThemeEditor } = themeEditorSlice.actions;

export default themeEditorSlice.reducer;
