import qs from 'query-string';
import { instance } from 'api/api';

export const licenseKeyAPI = {
    checkLicense() {
        const url = `/auth/get-license-info`;
        return instance.get(url);
    },
    updateLicense(licenseKey: string) {
        const url = `/auth/set-license-key`;
        return instance.post(url, { license: licenseKey });
    },
};
