import { deepmerge } from '@mui/utils';

import { designOptions } from './core/design';
import { designComponents } from './core/components';

import { themeOptions as defaultThemeOptions } from './variant/default';
import { themeOptions as darkThemeOptions } from './variant/dark';
import { themeOptions as orangeThemeOptions } from './variant/orange';
import { themeOptions as greenThemeOptions } from './variant/green';

import { ThemeOptions, Theme } from '@mui/material/styles';

export type ThemeName = 'default' | 'dark' | 'orange' | 'green';

export function getThemeStylesByName(themeName: ThemeName): ThemeOptions {
    const design = designOptions as ThemeOptions;
    design.components = designComponents;

    const theme = themes[themeName] as ThemeOptions;

    return deepmerge(design, theme);
}

const themes = {
    default: defaultThemeOptions,
    dark: darkThemeOptions,
    orange: orangeThemeOptions,
    green: greenThemeOptions,
};
