import { createSlice } from '@reduxjs/toolkit';

export interface popupSliceState {
    popupCounter: 0;
}

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        popupCounter: 0,
    } as popupSliceState,
    reducers: {
        add: (state) => {
            state.popupCounter++;
        },
        remove: (state) => {
            state.popupCounter--;
        },
    },
});

export const { add, remove } = popupSlice.actions;

export default popupSlice.reducer;
