import FormComponent from 'components/common/form/Form';
import React, { ReactNode, useEffect, useState } from 'react';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { FormDataAPIType } from 'components/common/form';

export default function CollaborationEvent({
    eventId,
    children,
    startTime,
    elementId,
    segmentValueId,
    afterSave,
}: {
    elementId: number;
    segmentValueId: number;
    eventId: number;
    children: ReactNode;
    startTime?: string;
    afterSave: () => any;
}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div>
            {isOpen && (
                <CollaborationEventForm
                    elementId={elementId}
                    segmentValueId={segmentValueId}
                    startTime={startTime}
                    onHide={() => setIsOpen(false)}
                    eventId={eventId}
                    afterSave={() => afterSave()}
                />
            )}
            <span onClick={() => setIsOpen(true)} className="event-btn-wrap">
                {children}
            </span>
        </div>
    );
}

export function CollaborationEventForm({
    eventId,
    onHide,
    startTime,
    elementId,
    segmentValueId,
    afterSave,
}: {
    eventId: number;
    onHide: any;
    startTime?: string;
    elementId: number;
    segmentValueId: number;
    afterSave: () => any;
}) {
    const { t } = useBundleTranslation();
    const popupSettings: PopupSettings = { title: t('add_event') };

    const [form, setForm] = useState<FormDataAPIType | null>(null);
    useEffect(() => {
        if (form == null || typeof startTime == 'undefined') {
            return;
        }
        form.form.hookFormSetValue('event_start_time', startTime);
    }, [form]);

    return (
        <FormComponent
            setFormRef={(f) => {
                if (form == null) {
                    setForm(f);
                }
            }}
            pk={'element-event'}
            uid={'element-event'}
            settingsUrl={`/data/chart/event/${eventId}/form?element=${elementId}&segment=${segmentValueId}`}
            popupType={'new'}
            popupSettings={popupSettings}
            isPopup
            onHide={onHide}
            afterSave={() => afterSave()}
        />
    );
}
