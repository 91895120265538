import * as React from 'react';
import { useState } from 'react';
import { Popup, PopupSettings } from './Popup';
import { AsyncPage } from 'components/common/Page';
import { settingsUrlToTranslationNS } from 'tools/tools';

export default function AsyncPopup({
    settingsUrl,
    onHide,
    onConfirm,
    popupSettings,
}: {
    settingsUrl: string;
    onHide: any;
    onConfirm: any;
    popupSettings: PopupSettings;
}) {
    const [open, setOpen] = useState<boolean>(true);

    const handleOnHide = function () {
        setOpen(false);
        setTimeout(() => onHide(), 0);
    };

    const handleOnConfirm = () => {
        setOpen(false);
        setTimeout(() => onConfirm(), 0);
    };

    let translationNS = settingsUrlToTranslationNS(settingsUrl);

    return (
        <Popup
            settings={popupSettings}
            onHide={handleOnHide}
            onConfirm={handleOnConfirm}
            open={open}
            maxWidth={popupSettings.maxWidth}
            translationNS={translationNS}
        >
            <AsyncPage isPopup settingsUrl={settingsUrl ?? ''} pk={popupSettings.uid} />
        </Popup>
    );
}
