import { DocumentEntityType, ElementViewerDataResponse, ElementInfoType } from 'components/element-viewer';
import { APIResponse } from 'tools/types';
import { instance } from 'api/api';
import { MetricChartDataType } from 'components/metric/chart';

export const viewerAPI = {
    async loadElementData(
        elementId: number,
        segmentValueId: number,
        uco?: number,
        forScreenShot?: boolean,
        isPreview?: boolean,
        bookmarkId?: number,
        searchParams?: URLSearchParams,
        checkToken?: boolean,
    ): Promise<ElementViewerDataResponse> {
        segmentValueId = segmentValueId || 0;

        const userParams: any = {};
        if (searchParams) {
            searchParams.forEach((v, k) => {
                if (typeof userParams[k] != 'undefined') {
                    if (!Array.isArray(userParams[k])) {
                        userParams[k] = [userParams[k]];
                    }
                    userParams[k].push(v);
                } else {
                    userParams[k] = v;
                }
            });
            for (const [k, v] of Object.entries(userParams)) {
                if (Array.isArray(v) && k.endsWith('[]')) {
                    userParams[k.substring(0, k.length - 2)] = v;
                    delete userParams[k];
                }
            }
        }
        let url = `data/viewer/element/${elementId}/segment/${segmentValueId}`;
        if (uco) {
            url += `/uco/${uco}`;
        }

        try {
            if (window.self !== window.top) userParams.isIframe = 'Y';
        } catch (e) {}

        const response = await instance.get<APIResponse<ElementViewerDataResponse>>(url, {
            params: {
                ...userParams,
                forScreenShot: forScreenShot ? 'Y' : 'N',
                isPreview: isPreview ? 'Y' : 'N',
                checkToken: checkToken ? 'Y' : 'N',
                isThumbnail: forScreenShot && window.location.href.includes('thumbnail') ? 'Y' : 'N',
                bookmark: bookmarkId ?? 0,
            },
        });
        return response.data.data;
    },
    async loadChartData(
        elementId: number,
        segmentValueId: number,
        uco?: number,
        simulationId?: number,
    ): Promise<MetricChartDataType> {
        segmentValueId = segmentValueId || 0;

        let url = `data/viewer/element/chart-data/${elementId}/segment/${segmentValueId}`;
        if (uco) {
            url += `/uco/${uco}`;
        }
        if (simulationId) {
            url += `?simulation=${simulationId}`;
        }
        const response = await instance.get<APIResponse<MetricChartDataType>>(url);
        return response.data.data;
    },

    async loadDocumentInfo(documentId: number): Promise<DocumentEntityType> {
        let url = `data/content/document/${documentId}/info`;
        const response = await instance.get<APIResponse<DocumentEntityType>>(url);
        return response.data.data;
    },

    async loadElementInfoType(elementId: number, segmentValueId: number): Promise<ElementInfoType> {
        let url = `data/content/index/index/element/${elementId}/segment/${segmentValueId}/info`;
        const response = await instance.get<APIResponse<ElementInfoType>>(url);
        return response.data.data;
    },

    buildExternalUrl(elementId: number, segmentValueId: number, data: any) {
        return instance.post(`data/reference/element/${elementId}/${segmentValueId}/get-embed-url`, data);
    },
};
