import { FieldConditionRuleNS } from 'components/common/field-condition-rule/index';
import Rule = FieldConditionRuleNS.Rule;
import { ReportContentNS } from 'components/report-content/index';
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import LegacyRuleFieldSuffix = FieldConditionRuleNS.LegacyRuleFieldSuffix;
import React from 'react';

export interface ConditionFormattingContextType {
    fields: Array<ComponentSettingsDatasetField>;
    selectFormatting: (formatting: ConditionFormatting) => void;
    deleteFormatting: (formatting: ConditionFormatting) => void;
    onSortEnd: (oldIndex: number, newIndex: number) => void;
}
export const ConditionFormattingContext = React.createContext<ConditionFormattingContextType | null>(null);

export interface ConditionFormatting {
    id: string;
    rules: Array<Rule>;
    format: FieldTextFormatting;
}

export function findFieldByRuleField(
    fields: Array<ComponentSettingsDatasetField>,
    ruleField: string,
): ComponentSettingsDatasetField | false {
    return fields.find((f) => f.reference_name + LegacyRuleFieldSuffix == ruleField) ?? false;
}

export function ruleConditionToShortForm(field: ComponentSettingsDatasetField, rule: Rule) {
    if (field.value_type != 'numeric') {
        return rule.condition;
    }

    switch (rule.condition) {
        case 'equals':
            return '=';
        case 'is greater than':
            return '>';
        case 'is greater than or equal to':
            return '>=';
        case 'is less than':
            return '<';
        case 'is less than or equal to':
            return '<=';
        case 'does not equal':
            return '!=';
    }
    return rule.condition;
}

export function getCompareConditionLabel(
    rule: Rule,
    field: ComponentSettingsDatasetField,
    secondField: ComponentSettingsDatasetField | false,
): string {
    if (field == null) {
        return '';
    }
    if (field.value_type == 'numeric' && rule.field_second != 'a value' && rule.field_second != '') {
        if (
            rule.compare_condition == 'any amount' ||
            rule.data == 0 ||
            rule.data == '' ||
            rule.condition == 'equals' ||
            rule.condition == 'does not equal'
        ) {
            return '';
        }
        if (rule.compare_condition == 'a value') {
            return 'by ' + rule.data;
        }
        if (rule.compare_condition == 'a percent') {
            return 'by ' + rule.data + '%';
        }
    } else if (field.value_type == 'datetime' && rule.data != 0 && secondField) {
        const unit = typeof rule.compare_date_shift == 'undefined' ? 'day' : rule.compare_date_shift;
        return 'by ' + rule.data + ' ' + unit + (Math.abs(Number(rule.data)) > 1 ? 's' : '');
    }
    return '';
}

export function getFormatSampleStyleFromFormat(format: FieldTextFormatting): React.CSSProperties {
    return {
        backgroundColor: format.bgColor == null ? undefined : format.bgColor,
        padding: format.bgColor ? '2px 5px 3px' : undefined,
        color: format.textColor == null ? undefined : format.textColor,
        fontStyle: format.textItalic ? 'italic' : undefined,
        fontWeight: format.textBold ? 'bold' : undefined,
    };
}

export function getFormatFieldLabel(
    fields: Array<ComponentSettingsDatasetField>,
    formatting: ConditionFormatting,
): string {
    const format = formatting.format;
    if (format.applyTo == -1) {
        return 'entire row';
    }
    const field = fields.find((f) => f.reference_name_escaped == format.applyTo);
    return field?.column_name ?? '';
}
