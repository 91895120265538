import React from 'react';
import {
    alpha,
    Button,
    ClickAwayListener,
    Grow,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme,
    Tooltip,
} from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { SxProps } from '@mui/system';

export default function DropdownIcon({
    children,
    icon,
    label,
    isButtonView,
    tooltip,
    menuSx,
    popperSx,
    placement,
    onChangeState = () => {},
    onClickInside,
}: {
    children: any;
    icon: string;
    label?: string;
    isButtonView?: boolean;
    tooltip?: string;
    menuSx?: SxProps;
    popperSx?: SxProps;
    placement?: any;
    onChangeState?: (isOpened: boolean) => void;
    onClickInside?: (event: any, handleClose: (event: any) => void) => void;
}) {
    //const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = React.useState(false);
    const handleClick = (event: any) => {
        setOpen(true);
        onChangeState(true);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
        onChangeState(false);
    };

    return (
        <>
            <Tooltip title={tooltip ?? ''}>
                {isButtonView ? (
                    <Button
                        ref={anchorRef}
                        variant="outlined"
                        color="neutral"
                        endIcon={<IconMi icon={icon} fontSize="16" />}
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={open ? 'active-state' : ''}
                    >
                        {label ? label : ''}
                    </Button>
                ) : (
                    <IconButton
                        ref={anchorRef}
                        sx={{ p: '6px', fontSize: 12 }}
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={open ? 'active-state' : ''}
                    >
                        {label ? label : ''}
                        <IconMi icon={icon} fontSize="16" />
                    </IconButton>
                )}
            </Tooltip>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={placement ?? 'bottom-start'}
                transition
                keepMounted
                sx={{
                    ...{
                        zIndex: 1491,
                    },
                    ...(popperSx ?? {}),
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'left top',
                        }}
                    >
                        <Paper
                            sx={{
                                borderRadius: '4px',
                                boxShadow: (theme) => `0px 4px 16px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
                                overflow: 'auto',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    sx={{ ...{ py: 0 }, ...(menuSx ?? {}) }}
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onClick={onClickInside ? (event) => onClickInside(event, handleClose) : handleClose}
                                >
                                    {children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
