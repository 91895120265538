import { Popup } from 'components/common/popup/Popup';
import { alpha, Grid, Tab, Tabs } from '@mui/material';
import { FormTabType } from 'components/common/form/tab';
import React, { useEffect, useRef, useState } from 'react';
import { IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { FormLayoutContext } from 'components/common/form/layout/tab';
import FormTabsContent from 'components/common/form/layout/tab/FormTabsContent';
import { getFormId } from 'components/common/form/formTools';

interface IFormAsidePanelLayoutProps
    extends Omit<IFormLayoutProps, 'confirmPopupProps' | 'subFormPopup' | 'panelSettings' | 'sharedComponents'> {}

export default function FormAsidePanelLayout({
    elementProps,
    tabs,
    t,
    componentsMap,
    asPopupProps,
    hookForm,
    customTabs,
    uid,
    isNew,
}: IFormAsidePanelLayoutProps) {
    const [activeTab, setActiveTab] = useState(0);
    const onTabChange = (event: React.SyntheticEvent, value: number) => {
        setActiveTab(value);
    };

    useEffect(() => {
        tabs.setFormTabs(
            tabs.formTabs.map((tab, index) => {
                tab.isActive = activeTab === index;
                return tab;
            }),
        );
    }, [activeTab]);

    const id = getFormId(uid, isNew);
    const submit = useRef(
        hookForm.form.hookFormHandleSubmit(function (data) {
            return hookForm.form.formAct.act.submit.exec(data);
        }),
    );

    return tabs.formTabs.length > 0 ? (
        <Popup
            settings={asPopupProps.popupSettings}
            onHide={asPopupProps.onHide}
            onConfirm={submit.current}
            open={asPopupProps.isOpen}
            maxWidth={asPopupProps.popupSettings.maxWidth}
            translationNS={elementProps.translationNS}
        >
            <form id={id} onSubmit={submit.current}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        borderBottom: 1,
                        borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        overflow: 'visible',
                        position: 'sticky',
                    }}
                >
                    <Grid item sx={{ padding: '0px 24px' }}>
                        <Tabs value={activeTab} onChange={onTabChange}>
                            {tabs.formTabs.map((tab: FormTabType) => (
                                <Tab label={t(tab.title)} key={tab.name} />
                            ))}
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid item sx={{ padding: '24px' }}>
                    <FormLayoutContext.Provider value={{ formLayout: 'sidePanel', formType: 'edit' }}>
                        <FormTabsContent
                            componentsMap={componentsMap}
                            formTabs={tabs.formTabs}
                            elementProps={elementProps}
                            customTabs={customTabs}
                        />
                    </FormLayoutContext.Provider>
                </Grid>
            </form>
        </Popup>
    ) : null;
}
