import React, { Fragment, useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import { FormComponentValue } from 'components/common/form/layout/control';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { FormDataAPIType, RawFormComponentType, RawFormElementProps } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { Container, FormControl, FormLabel } from '@mui/material';
import useBundleTranslation from 'i18n';

export default function DashboardPreferenceForm(props: IFormLayoutProps) {
    const tabProps = customTabProps(props, 'info');
    return (
        <FormLayoutComposer
            props={{
                ...props,
                customTabs: {
                    info: <InfoTab {...tabProps} elementProps={props.elementProps} hookForm={props.hookForm} />,
                },
            }}
        />
    );
}

function InfoTab(
    tabProps: ITabProps & {
        elementProps: RawFormElementProps;
        hookForm: FormDataAPIType;
    },
) {
    const { getElementProps, getElementPropsByIndex } = useTemplateFormTools(tabProps);
    const selectProps = getElementProps('session_start_category_id');
    const { t } = useBundleTranslation(selectProps.elementProps.translationNS ?? 'main');

    const showCategories = tabProps.hookForm.form.hookFormWatch('show_categories_on_homepage_ind') == 'Y';
    if (!showCategories && selectProps.record?.props.component.data) {
        const currentValue = tabProps.hookForm.form.hookFormGetValues('session_start_category_id');
        if (currentValue != 'category_0' && currentValue.startsWith('category_')) {
            tabProps.hookForm.form.hookFormSetValue('session_start_category_id', '');
        }
    }

    const [dataList, setDataList] = useState(selectProps.record?.props?.component?.data ?? []);
    useEffect(() => {
        if (!selectProps.record?.props?.component?.data) {
            setDataList([]);
            return;
        }

        if (showCategories) {
            setDataList(selectProps.record.props.component.data.slice());
            return;
        }

        setDataList(
            selectProps.record.props.component.data.filter(
                (row: FormComponentValue) => !row.value.startsWith('category_') || row.value == 'category_0',
            ),
        );
    }, [selectProps.record?.props?.component?.data, showCategories]);

    const categorySelect = (
        <Container className={'mi-container-3'} maxWidth={'sm'} component="main" disableGutters={true}>
            <FormControl fullWidth>
                <FormLabel>{t('session_start_category_id')}</FormLabel>
                <ReactHookFormController
                    componentValues={dataList}
                    elementProps={prepareFormElementProps({
                        ...tabProps.elementProps,
                        ...{
                            component: {
                                component: 'FormSelect',
                                name: 'session_start_category_id',
                                label: 'Category',
                            } as RawFormComponentType,
                        },
                    })}
                />
            </FormControl>
        </Container>
    );

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <>
                {tabProps.componentsMap
                    .map((component, index) =>
                        component.isNested || !component.props?.component?.isPinned ? null : (
                            <FormElementComponent key={index} {...getElementPropsByIndex(index)} />
                        ),
                    )
                    .filter((component) => component != null)}
            </>
            <>
                {tabProps.componentsMap
                    .map((component, index) =>
                        component.isNested || component.props?.component?.isPinned ? null : component.componentName ==
                          'session_start_category_id' ? (
                            <Fragment key={index}>{categorySelect}</Fragment>
                        ) : (
                            <FormElementComponent key={index} {...getElementPropsByIndex(index)} />
                        ),
                    )
                    .filter((component) => component != null)}
            </>
        </FormTabContentDefaultStacks>
    );
}
