import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import Alert from '@mui/material/Alert';
import styles from './SelectedItemsLabel.styles';

interface IProps {
    allItems?: number;
    show?: boolean;
    selectedItems?: number;
}
const SelectedItemsLabel: React.FC<IProps> = ({ allItems = 0, selectedItems = 0, show = true }) => {
    const { t } = useBundleTranslation(['components/common/grid/grid']);
    const [selectedAll, setSelectedAll] = useState(false);

    const action = selectedAll ? (
        <Typography onClick={() => setSelectedAll(false)} sx={styles.link}>
            {t('clear_selection')}
        </Typography>
    ) : (
        <Typography onClick={() => setSelectedAll(true)} sx={styles.link}>
            {t('selected_items', { count: allItems })}
        </Typography>
    );

    return (
        <>
            {show && (
                <Box sx={styles.wrapper}>
                    <Alert action={action} icon={false} severity="info" variant="standard" sx={styles.alert}>
                        {t('all_selected_items', { count: selectedItems })}
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default SelectedItemsLabel;
