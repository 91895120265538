import { Theme, alpha } from '@mui/material';

export default {
    mainHolder: {
        '.custom-datepicker__calendar': {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            fontFamily: (theme: Theme) => theme.font.default,
            color: 'text.primary',
            fontSize: '12px',
            borderRadius: '3px',
            backgroundColor: 'background.default',
            boxShadow: (theme: Theme) => `0px 2px 10px 0px ${alpha(theme.palette.text.primary, 0.3)}`,
            border: 0,
        },
        '.react-datepicker__month-container': {
            gridRowStart: 1,
            gridRowEnd: 2,
        },
        '.react-datepicker__day-names': {
            mt: '7px',
            mb: 0,
        },
        '.react-datepicker__month': {
            margin: '0 14px 12px',
        },
        '.react-datepicker__time-container': {
            border: 0,
            borderLeft: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        '.react-datepicker__time-container--with-today-button': {
            gridRowStart: 1,
            gridRowEnd: 3,
            position: 'relative',
            top: 'auto',
            right: 'auto',
        },
        '.react-datepicker__header--time': {
            flexShrink: 0,
        },
        '.react-datepicker-time__header': {
            fontWeight: 500,
            lineHeight: '32px',
            borderBottom: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
            color: 'inherit',
            fontSize: 'inherit',
        },
        '.react-datepicker__time-container .react-datepicker__time': {
            backgroundColor: 'transparent',
            flexGrow: 1,
            overflow: 'hidden',
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list':
            {
                pt: '7px',
                position: 'absolute',
                inset: '0 0 0 0',
                height: 'auto !important',
            },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
            {
                py: 0,
                lineHeight: '30px',
            },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
            {
                color: 'text.opposite',
                backgroundColor: 'primary.main',
                fontWeight: 'normal',
            },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:not(.react-datepicker__time-list-item--selected):hover':
            {
                color: 'primary.main',
                backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.04),
            },
        '.react-datepicker__header': {
            backgroundColor: 'transparent',
            p: 0,
            borderBottom: 0,
        },
        '.react-datepicker__day-name': {
            fontWeight: 600,
            color: 'inherit',
        },
        '.react-datepicker__day': {
            color: 'inherit',
        },
        '.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):hover': {
            borderRadius: '3px',
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: 'primary.main',
            lineHeight: '22px',
            color: 'primary.main',
        },
        '.react-datepicker__day-name, .react-datepicker__day': {
            width: '24px',
            lineHeight: '24px',
            margin: '3px 6px',
        },
        '.react-datepicker__day--today': {
            fontWeight: 600,
        },
        '.react-datepicker__day--outside-month': {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
        },
        '.react-datepicker__day--disabled': {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.15),
        },
        '.react-datepicker__day--selected': {
            color: 'text.opposite',
            backgroundColor: 'primary.main',
            borderRadius: '3px',
        },
        '.react-datepicker__today-button': {
            gridRowStart: 2,
            gridRowEnd: 3,
            width: '100%',
            backgroundColor: 'background.default',
            borderTop: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
            fontWeight: 500,
            pt: '6px',
            pb: '8px',
            px: 0,
            color: 'primary.main',
            '&:hover': {
                backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.04),
            },
        },
    },
    customHeader: {
        px: 1,
        borderBottom: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        lineHeight: '32px',
        fontWeight: 500,
    },
    headerIconBtn: {
        p: 0,
    },
    headerIcon: {
        fontSize: '16px',
    },
};
