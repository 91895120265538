import { alpha, Theme } from '@mui/material';

export default {
    expandBtnHolder: {
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1,
        backgroundColor: 'background.default',
        flexShrink: 0,
        border: '1px solid',
        borderColor: 'primary.main',
        color: 'primary.main',
        fontSize: '16px',
        borderBottomRightRadius: '4px',
    },
    expandBtn: {
        minWidth: '24px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
            cursor: 'pointer',
        },
    },
    openedContainer: {
        backgroundColor: 'background.default',
        borderRight: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
        flexShrink: 0,
    },
    listHolder: {
        width: '218px',
    },
    itemList: {
        px: 2,
        py: 1,
        borderBottom: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        color: 'primary.main',
        '&.active': {
            color: 'text.opposite',
            backgroundColor: 'primary.main',
        },
        '&:not(.active):hover': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
            cursor: 'pointer',
        },
    },
    collapseIcon: {
        fontSize: '16px',
        transform: 'scale(-1,1)',
    },
};
