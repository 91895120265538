import React from 'react';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import styles from './styles';
import IconMi from 'components/common/icon/IconMi';
import { TileInfo } from 'app/home/index.functions';
import { wallFormatDateShort } from 'components/wall';
import useBundleTranslation from 'i18n';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import isOwnerVisible from 'tools/owners';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';

function Owner({ type, name, email }: { type: string; name?: string; email?: string }) {
    if (!name || name === '') {
        return null;
    }

    return (
        <Stack direction={'row'} sx={styles.fixedValueRow}>
            <Box component={'span'} className={'fixed-label'}>{`${type}:`}</Box>
            <Box component={'span'} className={'fixed-value'} sx={{ wordWrap: 'break-word', overflow: 'hidden' }}>
                <a href={`mailto:${email}`}>{name}</a>
            </Box>
        </Stack>
    );
}

export default function InfoOwners({ tile, showDivider }: { tile: TileInfo; showDivider?: boolean }) {
    const { t } = useBundleTranslation(['app/home', 'main']);
    const { configuration }: UserAuth = useAppSelector((state) => state.auth);

    const isOwnersPresent = tile.business_owner || tile.technical_owner || tile.data_steward;

    const showOwners =
        isOwnersPresent &&
        ((isOwnerVisible(configuration.owners.business_owner, 'elements') &&
            isOwnerVisible(configuration.owners.business_owner, 'tile_preview')) ||
            (isOwnerVisible(configuration.owners.technical_owner, 'elements') &&
                isOwnerVisible(configuration.owners.technical_owner, 'tile_preview')) ||
            (isOwnerVisible(configuration.owners.data_steward, 'elements') &&
                isOwnerVisible(configuration.owners.data_steward, 'tile_preview')));

    const owners = showOwners ? (
        <Stack direction="row" width={1}>
            <Box sx={styles.iconHolder}>
                <IconMi icon="user" sx={{ fontSize: '16px' }} />
            </Box>
            <Stack spacing={0.25} sx={{ overflow: 'hidden' }}>
                {isOwnerVisible(configuration.owners.business_owner, 'elements') &&
                    isOwnerVisible(configuration.owners.business_owner, 'tile_preview') && (
                        <Owner
                            type={configuration.owners.business_owner.label}
                            name={tile.business_owner}
                            email={tile.business_owner_email}
                        />
                    )}
                {isOwnerVisible(configuration.owners.technical_owner, 'elements') &&
                    isOwnerVisible(configuration.owners.technical_owner, 'tile_preview') && (
                        <Owner
                            type={configuration.owners.technical_owner.label}
                            name={tile.technical_owner}
                            email={tile.technical_owner_email}
                        />
                    )}
                {isOwnerVisible(configuration.owners.data_steward, 'elements') &&
                    isOwnerVisible(configuration.owners.data_steward, 'tile_preview') && (
                        <Owner
                            type={configuration.owners.data_steward.label}
                            name={tile.data_steward}
                            email={tile.data_steward_email}
                        />
                    )}
            </Stack>
        </Stack>
    ) : null;

    return (
        <Stack sx={styles.sectionWrapper} spacing={0.25}>
            {owners}
            {tile.total_forecast_amount_formatted && (
                <Stack direction="row" width={1}>
                    <Box sx={styles.iconHolder} />
                    <Stack direction={'row'} sx={styles.fixedValueRow}>
                        <Box className={'fixed-label'}>{t('info_panel.projected')}</Box>
                        <Box className={'fixed-value'}>{tile.total_forecast_amount_formatted}</Box>
                    </Stack>
                </Stack>
            )}
            {tile.data_source_name && (
                <Stack direction="row" width={1}>
                    <Box sx={styles.iconHolder} />
                    <Stack direction={'row'} sx={styles.fixedValueRow}>
                        <Box className={'fixed-label'}>{t('info_panel.source')}</Box>
                        <Box className={'fixed-value'}>{tile.data_source_name}</Box>
                    </Stack>
                </Stack>
            )}
            {tile.last_display_generation_time && 'other external content' != tile.element_type && (
                <Stack direction="row" width={1}>
                    <Box sx={styles.iconHolder} />
                    <Stack direction={'row'} sx={styles.fixedValueRow}>
                        <Box className={'fixed-label'}>
                            {tile.element_type === 'external report'
                                ? t('info_panel.last_taken')
                                : t('info_panel.last_updated')}
                        </Box>
                        <Box className={'fixed-value'}>{wallFormatDateShort(tile.last_display_generation_time)}</Box>
                    </Stack>
                </Stack>
            )}
            {tile.last_file_updated_time && tile.element_type === 'other external content' && (
                <Stack direction="row" width={1}>
                    <Box sx={styles.iconHolder} />
                    <Stack direction={'row'} sx={styles.fixedValueRow}>
                        <Box className={'fixed-label'}>{t('info_panel.last_modified')}</Box>
                        <Box className={'fixed-value'}>{wallFormatDateShort(tile.last_file_updated_time)}</Box>
                    </Stack>
                </Stack>
            )}
            {tile.hide_mtf !== 'Y' &&
                tile.element_type === 'external report' &&
                tile.supports_last_refreshed_check === 'level 1' && (
                    <Stack direction="row" width={1}>
                        <Box sx={styles.iconHolder} />
                        <Stack direction={'row'}>{t('info_panel.updating')}</Stack>
                    </Stack>
                )}
            {tile.has_access !== 'N' && tile?.global_total_view_count !== undefined && (
                <Stack direction="row" width={1}>
                    <Box sx={styles.iconHolder}>
                        <IconMi icon="engagement" sx={{ fontSize: '16px' }} />
                    </Box>
                    <Stack direction={'row'} sx={styles.fixedValueRow}>
                        <Box className={'fixed-label'}>{t('info_panel.engagement')}</Box>
                        <Box className={'fixed-value'}>{tile.global_total_view_count ?? 0}</Box>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
