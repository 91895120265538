import { CustomCellProps } from 'components/common/grid/';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Popup } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';

function ErrorMessageCell({ text, subText, icon, rawHtml, align }: CustomCellProps) {
    const { t } = useBundleTranslation();

    const [showPopup, setShowPopup] = useState(false);

    const onClickAction = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setShowPopup(true);
    };

    return (
        <>
            <Box component={'a'} onClick={onClickAction} sx={{ cursor: 'pointer' }}>
                <Typography noWrap align={'left'} sx={{ py: 1 }}>
                    {text}
                </Typography>
            </Box>
            {showPopup && (
                <Popup
                    settings={{
                        noOk: true,
                        textCancel: t('close'),
                    }}
                    open={showPopup}
                    onHide={() => setShowPopup(false)}
                    maxWidth={'popupSm'}
                >
                    <Typography align={'left'} sx={{ py: 1, wordBreak: 'break-word' }}>
                        {text}
                    </Typography>
                </Popup>
            )}
        </>
    );
}

export default ErrorMessageCell;
