import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import PieChart from 'components/report-content/components/pie-chart/PieChart';
import PieChartEditPanel from 'components/report-content/components/pie-chart/PieChartEditPanel';

export default class PieChartComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsPieChart> {
    getComponent() {
        return (
            <PieChart
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <PieChartEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
