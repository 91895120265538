export default {
    mainWrapper: {
        display: 'flex',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflow: 'auto',
        minWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        p: 6,
    },
    container: {},
    dataWrapper: {},
    title: {
        fontSize: '20px',
        fontWeight: 700,
    },
    message: {
        fontSize: '14px',
    },
};
