import useBundleTranslation from 'i18n';
import { FormControlTextProps } from 'components/common/form/layout/control';
import { FormRendererAPIType } from 'components/common/form';
import { InputAdornment } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import FormText from 'components/common/form/layout/control/FormText';
import React from 'react';

export default function ReferenceSelectionFormSearch({
    onChange,
    value,
}: {
    onChange: (event: any) => void;
    value: string;
}) {
    const { t } = useBundleTranslation();
    const searchControlProps: FormControlTextProps = {
        form: {} as FormRendererAPIType,
        name: 'reference_form_search',
        onChange: onChange,
        disabled: false,
        placeholder: t('search'),
        value: value,
        startAdornment: (
            <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                <IconMi icon="search" fontSize="16" />
            </InputAdornment>
        ),
    };
    return <FormText controlProps={searchControlProps} />;
}
