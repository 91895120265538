import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssocArray } from 'tools/types';

interface debugSliceState {
    status: boolean;
    formElementsExpandedProps: AssocArray<Array<string>>;
}

export const debugSlice = createSlice({
    name: 'debug',
    initialState: {
        status: localStorage.getItem('debug') == 'Y',
        formElementsExpandedProps: JSON.parse(localStorage.getItem('debug_formElementsExpandedProps') ?? '{}'),
    } as debugSliceState,
    reducers: {
        toggleDebugStatus: (state) => {
            state.status = !state.status;
            localStorage.setItem('debug', state.status ? 'Y' : 'N');
        },
        formElementExpandProp: (state, action: PayloadAction<[string, string]>) => {
            const formId = action.payload[0];
            const elementName = action.payload[1];
            if (!Array.isArray(state.formElementsExpandedProps[formId])) {
                state.formElementsExpandedProps[formId] = [];
            }
            state.formElementsExpandedProps[formId].push(elementName);
            localStorage.setItem('debug_formElementsExpandedProps', JSON.stringify(state.formElementsExpandedProps));
        },
        formElementCollapseProp: (state, action: PayloadAction<[string, string]>) => {
            const formId = action.payload[0];
            const elementName = action.payload[1];
            if (!Array.isArray(state.formElementsExpandedProps[formId])) {
                state.formElementsExpandedProps[formId] = [];
            }
            const index = state.formElementsExpandedProps[formId].findIndex((f) => f == elementName);
            if (index != -1) {
                state.formElementsExpandedProps[formId].splice(index, 1);
            }
            localStorage.setItem('debug_formElementsExpandedProps', JSON.stringify(state.formElementsExpandedProps));
        },
    },
});

export const { toggleDebugStatus, formElementExpandProp, formElementCollapseProp } = debugSlice.actions;

export default debugSlice.reducer;
