import IconMi from 'components/common/icon/IconMi';
import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import { HtmlTooltip } from 'app/home/tile/components/HtmlTooltip';
import Certified from './parts/Certified';
import Owners from './parts/Owners';
import Description from './parts/Description';
import Category from './parts/Category';
import Tags from './parts/Tags';
import LastUpdated from './parts/LastUpdated';
import CustomFields from './parts/CustomFields';

export default function InfoIconButton({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const isCertified = datasetInfo.certified === 'Y';

    return (
        <HtmlTooltip
            placement={'bottom'}
            arrow={false}
            certified={isCertified}
            title={
                <Stack direction={'column'} spacing={1} sx={{ p: 1 }}>
                    <Description datasetInfo={datasetInfo} />
                    <Certified datasetInfo={datasetInfo} />
                    <Owners datasetInfo={datasetInfo} />
                    <Category datasetInfo={datasetInfo} />
                    <Tags datasetInfo={datasetInfo} />
                    <LastUpdated datasetInfo={datasetInfo} />
                    <CustomFields datasetInfo={datasetInfo} />
                </Stack>
            }
        >
            <IconButton sx={{ p: '6px' }}>
                {isCertified ? (
                    <Box
                        sx={{
                            display: 'flex',
                            color: 'success.main',
                            fontSize: '16px',
                        }}
                    >
                        <IconMi icon={'certified'} />
                    </Box>
                ) : (
                    <IconMi icon={'info-circle'} fontSize="16" />
                )}
            </IconButton>
        </HtmlTooltip>
    );
}
