import { Box, Button, Stack, Theme, Tooltip, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React, { useEffect, useRef, useState } from 'react';
import { announcementAPI, AnnouncementData } from 'api/announcement';
import { useNavigate } from 'react-router-dom';
import useBundleTranslation from 'i18n';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

function Announcement({
    announcement,
    elementId,
    loadAnnouncements,
}: {
    announcement: AnnouncementData;
    elementId: number;
    loadAnnouncements: () => void;
}) {
    const { t } = useBundleTranslation(['components/announcements/announcements']);

    const [showConfirm, setShowConfirm] = useState(false);
    const navigate = useNavigate();

    const showEditButton = announcement.allow_edit_ind === 'Y';
    const showSuppressButton = elementId > 0 && useIsAdminOrHasPrivilege('PRIV_DELETE_COLLABORATIVE_ITEMS');
    const showAcceptButton = announcement.requires_approval_ind === 'Y';
    const showDismissButton = announcement.allow_to_dismiss_ind === 'Y' && announcement.requires_approval_ind === 'N';
    const isSetMaxHeight = announcement.set_max_height_ind === 'Y';
    const announcementContainerRef = useRef<any | null>(null);
    const contentContainerRef = useRef<any | null>(null);
    const contentRef = useRef<any | null>(null);

    //Collapsed or expanded by user
    const [showMoreStateActive, setShowMoreStateActive] = useState(false);
    const showMoreStateActiveRef = useRef<boolean>(false);

    //show collapsed/expanded control by content height (on window resize)
    const [showCollapseControl, setShowCollapseControl] = useState(false);
    const showCollapseControlRef = useRef<boolean>(false);

    const confirmPopupSettings: PopupSettings = {
        title: t('confirm_popup_header'),
        textOK: t('confirm_popup_ok_button'),
    };

    const onEditClick = (announcementId: number) => {
        navigate(`/editor/announcement/${announcementId}`);
    };

    const onDismissClick = (announcementId: number) => {
        announcementAPI.dismiss(announcementId).then((response) => {
            if (response.status === 'OK') {
                loadAnnouncements();
            }
        });
    };

    const onAcceptClick = (announcementId: number) => {
        announcementAPI.accept(announcementId).then((response) => {
            if (response.status === 'OK') {
                loadAnnouncements();
            }
        });
    };

    const onSuppressClick = () => {
        setShowConfirm(true);
    };

    const onSuppressConfirmed = (announcementId: number) => {
        announcementAPI.suppress(announcementId, elementId).then((response) => {
            if (response.status === 'OK') {
                loadAnnouncements();
            }
        });
    };

    const showCollapseControlCheck = () => {
        if (showMoreStateActiveRef.current) {
            const container = announcementContainerRef.current;
            if (container) {
                const showMoreBthHeight = showCollapseControlRef.current ? 10 : 0; //18px height - 8px negative margin;
                setShowCollapseControl(container.offsetHeight - showMoreBthHeight > announcement.max_height);
            }
        } else {
            const contentContainer = contentContainerRef.current;
            const contentEl = contentRef.current;
            if (contentContainer && contentEl) {
                setShowCollapseControl(contentEl.offsetHeight > contentContainer.offsetHeight);
            }
        }
    };

    useEffect(() => {
        if (isSetMaxHeight) {
            showCollapseControlCheck();
            window.addEventListener('resize', showCollapseControlCheck);
        }

        return () => {
            if (isSetMaxHeight) window.removeEventListener('resize', showCollapseControlCheck);
        };
    }, []);

    useEffect(() => {
        //set data for EventListener
        showMoreStateActiveRef.current = showMoreStateActive;
    }, [showMoreStateActive]);

    useEffect(() => {
        //set data for EventListener
        showCollapseControlRef.current = showCollapseControl;
    }, [showCollapseControl]);

    return (
        <Stack
            ref={announcementContainerRef}
            sx={{
                border: '1px solid',
                borderColor: announcement.border_color > '' ? announcement.border_color : 'text.secondary',
                backgroundColor: 'background.default',
                borderRadius: '3px',
                px: 2,
                py: '15px',
                position: 'relative',
            }}
            key={`announcement-${announcement.announcement_id}`}
            maxHeight={isSetMaxHeight && !showMoreStateActive ? announcement.max_height : undefined}
            className={
                isSetMaxHeight && showCollapseControl && !showMoreStateActive ? 'announcement--collapsed' : undefined
            }
        >
            <Box sx={{ overflow: 'hidden', maxHeight: '100%' }} ref={contentContainerRef}>
                <Stack direction={'row'} ref={contentRef}>
                    <Stack direction={'row'} flexGrow={1} alignItems={'center'}>
                        <Box
                            className={'richtext-holder'}
                            sx={{ width: '100%' }}
                            dangerouslySetInnerHTML={{ __html: announcement.html_code }}
                        />
                    </Stack>

                    {(showSuppressButton || showAcceptButton || showEditButton || showDismissButton) && (
                        <Stack
                            direction={'row'}
                            spacing={1.25}
                            alignSelf={'flex-start'}
                            flexShrink={0}
                            sx={{ pl: 1.25, position: 'relative', zIndex: 1, backgroundColor: 'background.default' }}
                        >
                            {showSuppressButton && (
                                <>
                                    <Tooltip title={t('suppress_tooltip')}>
                                        <Button
                                            color="warning"
                                            variant={'outlined'}
                                            onClick={onSuppressClick}
                                            data-test={`announcement-suppress-${announcement.announcement_id}`}
                                        >
                                            {t('suppress_button')}
                                        </Button>
                                    </Tooltip>
                                    <Popup
                                        settings={confirmPopupSettings}
                                        open={showConfirm}
                                        onHide={() => {
                                            setShowConfirm(false);
                                        }}
                                        onConfirm={() => {
                                            onSuppressConfirmed(announcement.announcement_id);
                                        }}
                                        maxWidth={'popupSm'}
                                    >
                                        <Typography>{t('confirm_popup_text')}</Typography>
                                    </Popup>
                                </>
                            )}
                            {showAcceptButton && (
                                <Button
                                    data-test={`announcement-accept-${announcement.announcement_id}`}
                                    color="success"
                                    variant={'outlined'}
                                    onClick={() => {
                                        onAcceptClick(announcement.announcement_id);
                                    }}
                                >
                                    {t('accept_button')}
                                </Button>
                            )}
                            {showEditButton && (
                                <Button
                                    data-test={`announcement-edit-${announcement.announcement_id}`}
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => {
                                        onEditClick(announcement.announcement_id);
                                    }}
                                    className={'min-width--icon'}
                                >
                                    <IconMi icon={'gear'} sx={{ fontSize: 16 }} />
                                </Button>
                            )}
                            {showDismissButton && (
                                <Button
                                    data-test={`announcement-dismiss-${announcement.announcement_id}`}
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => {
                                        onDismissClick(announcement.announcement_id);
                                    }}
                                    className={'min-width--icon'}
                                >
                                    <IconMi icon={'times'} sx={{ fontSize: 16 }} />
                                </Button>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Box>
            {isSetMaxHeight && showCollapseControl && (
                <Box
                    sx={{
                        mb: -1,
                        '.announcement--collapsed &': {
                            position: 'absolute',
                            px: 2,
                            bottom: '8px',
                            left: 0,
                            right: 0,
                            mb: 0,
                            backgroundImage: (theme: Theme) =>
                                `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${theme.palette.background.default} 56%, ${theme.palette.background.default} 100%)`,
                            pt: '17px',
                        },
                    }}
                >
                    <Box
                        component={'span'}
                        sx={{
                            position: 'relative',
                            display: 'inline-block',
                            color: 'primary.main',
                            '&:hover': {
                                cursor: 'pointer',
                                opacity: 0.85,
                            },
                        }}
                        onClick={() => {
                            setShowMoreStateActive(!showMoreStateActive);
                        }}
                    >
                        {showMoreStateActive ? t('less_button') : t('more_button')}
                    </Box>
                </Box>
            )}
        </Stack>
    );
}

export default Announcement;
