import React, { lazy } from 'react';
import { createBrowserRouter, redirect, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'routes/ProtectedRoute';
import indexRoutes, { layoutRoutes } from 'routes/index';
import ErrorBoundaryPage from 'app/error/ErrorBoundaryPage';

export const getRoutes = () => {
    const routerArr: RouteObject[] = [
        {
            path: '/ticket/*',
            loader: () => {
                let tmp = window.location.pathname.split('/');
                tmp.splice(0, 3);
                return redirect('/' + tmp.join('/'));
            },
        },
    ];
    const root: RouteObject = {
        path: '/',
        element: <ProtectedRoute />,
        handle: { type: 'root', name: 'root' },
        errorElement: <ErrorBoundaryPage />,
    };

    root.children = layoutRoutes.map((layout) => {
        const children: RouteObject[] = [];
        const layoutCond = layout.name == 'default' ? undefined : layout.name;

        indexRoutes
            .filter((route) => route.layout === layoutCond)
            .forEach((route) => {
                route.path.forEach((path) => {
                    //fallback to old interface structure;
                    const props = { ...route.props, name: route.name, title: route.name };
                    const handle = {
                        ...{},
                        type: 'route',
                        name: route.name,
                        isPublic: route.isPublic,
                        isPortalPageMenu: route.isPortalPageMenu ?? false,
                        pageMenu: route.props?.pageMenu,
                        pageTitle: route.props?.pageTitle,
                        breadcrumbs: route.props?.breadcrumbs,
                        helpLink: route.props?.helpLink,
                    };
                    children.push({
                        path: path,
                        element: <route.component {...props} />,
                        handle: handle,
                    });
                });
            });

        return {
            element: <layout.component />,
            handle: { type: 'layout', name: layout.name },
            children: children,
        };
    });

    routerArr.push(root);

    return createBrowserRouter(routerArr);
};
