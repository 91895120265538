import { createSlice } from '@reduxjs/toolkit';

export interface conciergeSliceState {
    visible: boolean;
    width: number;
}

export const conciergeSlice = createSlice({
    name: 'concierge',
    initialState: {
        visible: false,
        width: 420,
    } as conciergeSliceState,
    reducers: {
        show: (state) => {
            state.visible = true;
        },
        hide: (state) => {
            state.visible = false;
        },
        toggleVisibility: (state) => {
            state.visible = !state.visible;
        },
        setConciergeWidgetWidth: (state, action) => {
            state.width = action.payload;
        },
    },
});

export const { show, hide, toggleVisibility, setConciergeWidgetWidth } = conciergeSlice.actions;

export default conciergeSlice.reducer;
