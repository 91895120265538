import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiDividerConfig = {
    styleOverrides: {
        root: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
            };
        },
    },
};
