import { createSlice } from '@reduxjs/toolkit';

import { ExternalReportTypeResponse } from 'api/editor/external-report';

interface externalReportState {
    reportTypes: {
        [key: string]: ExternalReportTypeResponse;
    };
}

export const externalReportSlice = createSlice({
    name: 'externalReport',
    initialState: {
        reportTypes: {},
    } as externalReportState,
    reducers: {
        setReportTypesData(state, action) {
            if (action.payload?.reportTypeId) {
                const copyData = { ...state.reportTypes };
                copyData[action.payload.reportTypeId] = action.payload?.data ?? {};
                state.reportTypes = copyData;
            }
        },
    },
});

export const { setReportTypesData } = externalReportSlice.actions;

export default externalReportSlice.reducer;
