import {
    ChartDashStyleType,
    ChartOverlaySeriesTypeRaw,
    ChartSeriesType,
    DATA_X_MULTIPLIER,
    MetricChartOverlayType,
    MetricChartStateLegend,
} from 'components/metric/chart/index';
import { AssocArray } from 'tools/types';
import { PlotSeriesPointOptions } from 'highcharts';

export function getOverlaySeries(
    overlaySeries: Array<ChartOverlaySeriesTypeRaw>,
    overlaysState: Array<MetricChartOverlayType>,
    legendState: AssocArray<MetricChartStateLegend>,
    getSeriesPointOptions: (series: ChartSeriesType) => PlotSeriesPointOptions,
): Array<ChartSeriesType> {
    return overlaysState
        .map((state) => {
            const seriesData = overlaySeries.find((s) => {
                const tmp = s.id.split('_');
                return tmp[0] == String(state.element_id) && tmp[1] == String(state.segment_value_id);
            });
            if (!seriesData) {
                return null;
            }

            const id = 'overlay_' + seriesData.id;
            const series: ChartSeriesType = {
                seriesType: 'overlay',
                // data: seriesData.data.map((point) => ({ y: point.y, x: point.x * DATA_X_MULTIPLIER })),
                data: seriesData.data
                    // .sort((a, b) => a.x - b.x)
                    .map((point) => {
                        return {
                            x: point.x * DATA_X_MULTIPLIER,
                            y: point.y,
                            metadata: point.mydata ?? null,
                        };
                    }),
                type: state.metric_chart_display_type,
                id: id,
                name: seriesData.name,
                dashStyle: state.dashStyle as ChartDashStyleType,
                color: state.color,
                //@ts-ignore
                yAxis: state.axis == 'right' ? 1 : 0,
                // zIndex: state.zIndex,
                marker: state.marker,
                showInNavigator: true,
                metricUnusualValueStdDev: seriesData.metricUnusualValueStdDev,
                // states: {
                //     hover: {
                //         lineWidthPlus: 0,
                //         enabled: false,
                //     },
                // },v
                visible: legendState ? (legendState[id]?.visible ?? true) : true,
            };
            series.point = getSeriesPointOptions(series);
            return series;
        })
        .filter((series) => series != null) as Array<ChartSeriesType>;

    // return overlaySeries.map((series) => {
    //     console.log(series);
    //     return {
    //         seriesType: 'overlay',
    //         data: series.data.map((point) => ({ y: point.y, x: point.x * DATA_X_MULTIPLIER })),
    //         type: 'line',
    //         id: 'overlay_' + series.id,
    //         name: series.name ?? 'overlay_' + series.id,
    //         showInNavigator: true,
    //         dashStyle: series.dashType,
    //         color: series.color,
    //         // zIndex: series.zIndex,
    //         // marker: series.marker,
    //         // zIndex: state.zIndex,
    //         // marker: state.marker,
    //         // states: {
    //         //     hover: {
    //         //         lineWidthPlus: 0,
    //         //         enabled: false,
    //         //     },
    //         // },
    //     };
    // });
}
