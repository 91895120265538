import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { processUrlBackwardCompatibility } from 'components/backward-compatibility-router/index';
import Error404Page from 'app/error/Error404Page';

export default function RedirectCustomPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();
    const [newUrl, setNewUrl] = useState<string | null>(null);

    useEffect(() => {
        let entityKey = params.entityType ?? '';
        if (!entityKey) {
            if (location.pathname.includes('/search/external-tool')) {
                entityKey = 'search-external-tool';
            }
            if (location.pathname.includes('/editor/sourceplugin/index/reference')) {
                entityKey = 'metadata-reference';
            }
            if (location.pathname.includes('/editor/sourcedataset/index/id')) {
                entityKey = 'metadata-dataset';
            }
            if (location.pathname.includes('/editor/sourceconnection/index/id')) {
                entityKey = 'metadata-external';
            }
            if (location.pathname.includes('/editor/extreportmass')) {
                entityKey = 'extreportmass';
            }
            if (location.pathname.includes('/editor/extcontentmass')) {
                entityKey = 'extcontentmass';
            }
        }

        const processedUrl = processUrlBackwardCompatibility('custom', entityKey, location, params);
        setNewUrl(processedUrl);
        if (processedUrl) {
            navigate(processedUrl);
            return;
        }
    }, []);

    if (newUrl === null) return null;

    return <Error404Page />;
}
