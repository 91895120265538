import { useQuery } from '@tanstack/react-query';
import { referenceAPI } from 'api/reference';
import React, { useEffect, useMemo, useState } from 'react';
import { ReferenceHierarchyResponse, ReferenceRow, RollupRow, TreeNode } from './index';
import ReferenceSelectionFormSearch from './ReferenceSelectionFormSearch';
import 'react-virtualized/styles.css';
import 'material-icons/css/material-icons.css';
import ReferenceTreeWrapper from './ReferenceTreeWrapper';
import { Box, Button, ButtonGroup, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import FormComponent from 'components/common/form/Form';
import pollingService, { PoolingRequestData } from 'tools/pollingService';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

// Convert backend raw data into TreeNode object
function convertReferenceRowIntoTreeData(row: ReferenceRow): TreeNode {
    return {
        id: row.external_report_reference_id,
        children: [],
        name: row.name,
        data: {
            linked_ref_id: 0,
            collection_id: row?.collection_id ?? 0,
            parent: row.external_report_rollup_id ?? 0,
            type: 'reference',
            level: 0,
            searchField: row.name.toLowerCase(),
            fakeRollupId: (row?.linked_parent_id ?? 0 > 0) ? 'Y' : 'N',
        },
    };
}
function convertRollupRowIntoTreeData(row: RollupRow): TreeNode {
    return {
        id: row.external_report_rollup_id,
        children: [],
        name: row.name,
        data: {
            collection_id: 0,
            linked_ref_id: row.linked_ref_id ?? 0,
            parent: row.parent_external_report_rollup_id ?? 0,
            type: 'rollup',
            level: row.level,
            searchField: row.name.toLowerCase(),
            fakeRollupId: row.fake_rollup_id ?? 'N',
        },
    };
}

export default function ReferenceSelectionForm({
    profileId,
    referenceId,
    onSelectReference,
    capability,
}: {
    profileId: number;
    referenceId: number;
    onSelectReference: (referenceId: number, collectionId: number) => void;
    capability: string;
}) {
    let queryKey = ['reference_data', profileId];

    const {
        data: infoRequestData,
        isSuccess: isInfoRequestSuccess,
        refetch,
    } = useQuery<ReferenceHierarchyResponse, Error>({
        queryKey: queryKey,
        queryFn: () => referenceAPI.getReferenceHierarchy(profileId, capability),
    });

    // Tree data
    const nodesList = useMemo(() => {
        const referencesList = infoRequestData
            ? infoRequestData.references.map((row) => convertReferenceRowIntoTreeData(row))
            : [];
        const rollupsList = infoRequestData
            ? infoRequestData.rollups.map((row) => convertRollupRowIntoTreeData(row))
            : [];

        // Build hash map for Nodes
        const listMap: Record<string, TreeNode> = {};
        rollupsList.forEach((node) => {
            listMap['rollup_' + node.id] = node;
        });
        referencesList.forEach((node) => {
            if (referenceId == node.id) {
                // Mark as open
                node.state = { expanded: true };
            }
            listMap['ref_' + node.id] = node;
        });

        // Build tree of Nodes from list
        // @ts-ignore
        for (const [index, node] of Object.entries(listMap)) {
            if (node?.data?.parent) {
                const key = 'rollup_' + node.data.parent;
                if (typeof listMap[key]?.children == 'undefined') {
                    continue;
                }
                // @ts-ignore
                listMap[key].children.push(node);
                // // If child is open, also open parent
                // if (node?.state?.expanded) {
                //     listMap[key].state = { expanded: true };
                // }
            }
        }

        const expandParent = (node: TreeNode) => {
            node.state = { expanded: true };
            if (node.data?.parent && listMap['rollup_' + node.data.parent]) {
                expandParent(listMap['rollup_' + node.data.parent]);
            }
        };
        if (listMap['ref_' + referenceId]) {
            expandParent(listMap['ref_' + referenceId]);
        }

        return Object.values(listMap).filter((node: TreeNode) => node?.data?.parent == 0);
    }, [infoRequestData, referenceId]);

    const [hasRefreshIcon, setHasRefreshIcon] = useState(false);
    const [hasPlusIcon, setHasPlusIcon] = useState(false);
    useEffect(() => {
        setHasRefreshIcon(infoRequestData?.isRefresh ?? false);
        setHasPlusIcon(infoRequestData?.isPlus ?? false);
    }, [infoRequestData]);

    const handleNodeClick = function (node: TreeNode) {
        if (node?.data?.type == 'reference') {
            // Select reference;
            onSelectReference(Number(node.id), Number(node.data.collection_id));
        } else if (node?.data?.linked_ref_id ?? 0 > 0) {
            const refNode = nodesList.find((n) => n.data?.type == 'reference' && n.id == node.data?.linked_ref_id);
            onSelectReference(Number(node.data?.linked_ref_id), Number(refNode?.id ?? 0));
        }
    };

    // Search field
    const handleSearchFilterChange = function (event: any): void {
        setSearchFilterValue(event);
    };
    const [searchFilterValue, setSearchFilterValue] = useState<string>('');

    const [isPooling, setIsPooling] = useState(false);
    const handleRefreshClick = () => {
        const { create } = pollingService<any, PoolingRequestData>({
            onSuccess: () =>
                refetch().then((response) => {
                    if (response.status != 'success') {
                        //@ts-ignore
                        alert(response?.message ?? 'error');
                    }
                    setIsPooling(false);
                }),
            onError: (response) => {
                //@ts-ignore
                alert(response?.message ?? 'error');
                setIsPooling(false);
            },
            requestData: {
                poolingCreateURL: `/data/editor/plugin-source/${profileId}/external-report-list/refresh`,
                poolingCreateMethod: 'GET',
            },
        });
        setIsPooling(true);
        create();
    };

    const [showAddPopup, setShowAddPopup] = React.useState<boolean>(false);
    const afterAdd = (response?: any) => {
        handleRefreshClick();
    };

    return (
        <>
            {(isPooling || !isInfoRequestSuccess) && <LoadingPlaceholder />}
            <Stack direction={'row'}>
                <Box flexGrow={1}>
                    <ReferenceSelectionFormSearch onChange={handleSearchFilterChange} value={searchFilterValue} />
                </Box>
                {(hasPlusIcon || hasRefreshIcon) && (
                    <Box sx={{ ml: 1, flexShrink: 0 }}>
                        <ButtonGroup aria-label="outlined primary button group">
                            {hasPlusIcon && (
                                <Button onClick={() => setShowAddPopup(true)}>
                                    <IconMi icon={'new'} fontSize="16" />
                                </Button>
                            )}
                            {hasRefreshIcon && (
                                <Button onClick={handleRefreshClick}>
                                    <IconMi icon={'refresh-cw'} fontSize="16" />
                                </Button>
                            )}
                        </ButtonGroup>
                    </Box>
                )}
            </Stack>

            <ReferenceTreeWrapper
                onNodeClick={handleNodeClick}
                pattern={searchFilterValue}
                nodes={nodesList}
                referenceId={referenceId}
            />

            {showAddPopup && (
                <FormComponent
                    popupSettings={{}}
                    isPopup={true}
                    popupType={'new'}
                    pk={''}
                    uid={'reference-selection-add-form'}
                    settingsUrl={`/data/editor/external-report-reference-on-fly/${profileId}/form`}
                    onHide={() => setShowAddPopup(false)}
                    afterSave={afterAdd}
                />
            )}
        </>
    );
}
