import { FormDataAPIType } from 'components/common/form';
import React, { useState } from 'react';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box, Button, FormLabel, IconButton, Input, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import ComponentSettingsJctField = ReportContentNS.ComponentSettingsJctField;
import { addToStateArray, removeFromStateArray, replaceInStateArray } from 'tools/tools';
import IconMi from 'components/common/icon/IconMi';

export default function FieldForm({
    field,
    onApply,
    onCancel,
    title,
}: {
    field: ComponentSettingsJctField;
    onApply: (field: ComponentSettingsJctField) => void;
    onCancel: () => void;
    title?: string;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        show_column_in_table_display_ind: field.show_column_in_table_display_ind,
    });

    const handleAddSetting = () => {
        addToStateArray(setSettingsList, { name: '', value: '' });
    };

    const [settingsList, setSettingsList] = useState(field.fieldSettings);

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={title}
                    onCancel={() => onCancel()}
                    item={field.column_name ?? field.reference_name}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', p: 3 }} spacing={2}>
                <Box>
                    <FormLabel>{t('jct.column_settings')}</FormLabel>

                    {settingsList.length > 0 && (
                        <Stack direction={'row'} spacing={1}>
                            <Box flexGrow={1}>
                                <FormLabel>{t('name')}</FormLabel>
                            </Box>
                            <Box flexGrow={1}>
                                <FormLabel>{t('value')}</FormLabel>
                            </Box>
                            <Box sx={{ flexShrink: 0 }}>
                                <IconButton sx={{ visibility: 'hidden' }}>
                                    <IconMi icon="times" fontSize="16" />
                                </IconButton>
                            </Box>
                        </Stack>
                    )}

                    {settingsList.map((s, i) => {
                        return (
                            <Stack direction={'row'} spacing={1}>
                                <Box flexGrow={1}>
                                    <Input
                                        sx={{ width: '100%' }}
                                        onChange={(event) =>
                                            replaceInStateArray(setSettingsList, i, {
                                                name: event.target.value,
                                                value: s.value,
                                            })
                                        }
                                        value={s.name}
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Input
                                        sx={{ width: '100%' }}
                                        onChange={(event) =>
                                            replaceInStateArray(setSettingsList, i, {
                                                name: s.name,
                                                value: event.target.value,
                                            })
                                        }
                                        value={s.value}
                                    />
                                </Box>
                                <Box sx={{ flexShrink: 0 }}>
                                    <IconButton onClick={() => removeFromStateArray(setSettingsList, i)}>
                                        <IconMi icon="times" fontSize="16" />
                                    </IconButton>
                                </Box>
                            </Stack>
                        );
                    })}
                    <Box sx={{ mt: 2 }}>
                        <Button onClick={handleAddSetting} startIcon={<IconMi icon={'new'} />} variant="light">
                            {t('setting')}
                        </Button>
                    </Box>
                </Box>
            </Stack>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, pl: 3 }}>
                <EditPanelCheckBox
                    elementProps={elementProps}
                    name={'show_column_in_table_display_ind'}
                    label={t('dataset.show_column')}
                />
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        onApply({
                            ...field,
                            fieldSettings: settingsList.slice(),
                            show_column_in_table_display_ind: elementProps.form.hookFormGetValues(
                                'show_column_in_table_display_ind',
                            ),
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
