import { RouteItemType } from 'routes';
import { lazy } from 'react';
import BlankRouter from 'components/backward-compatibility-router/BlankRouter';
const FormPage = lazy(() => import('components/common/FormPage'));
const Home = lazy(() => import('app/home'));

export const dashboardRoutes: Array<RouteItemType> = [
    {
        path: [''],
        name: 'home',
        component: BlankRouter,
        isPortalPageMenu: true,
        index: true,
        props: { breadcrumbs: false, pageTitle: { value: false } },
    },
    {
        path: ['/home'],
        name: 'home',
        component: Home,
        isPortalPageMenu: true,
        props: {
            pageTitle: { value: false },
            breadcrumbs: false,
            helpLink: 'https://help.metricinsights.com/m/Jump_Start_Guide_for__Regular_Users/l/494421-homepage-basics',
        },
    },
    {
        path: ['/home/preference-mobile'],
        name: 'preference.mobile',
        component: FormPage,
        props: {
            uid: 'preferenceMobile',
            settingsUrl: '/data/dashboard/preference-mobile/form',
            pageMenu: 'notification',
        },
    },
    {
        path: ['/home/preference'],
        name: 'preference.settings',
        component: FormPage,
        props: {
            uid: 'preference',
            settingsUrl: '/data/dashboard/preference/form',
            pageMenu: 'notification',
            helpLink:
                'https://help.metricinsights.com/m/Jump_Start_Guide_for__Regular_Users/l/104357-the-preference-editor',
        },
    },
];
