import { GridPopupProps } from './index';
import React from 'react';
import AsyncPopup from 'components/common/popup/AsyncPopup';
import FormComponent from 'components/common/form/Form';
import Components, { ComponentKey } from 'components/common/ComponentIndex';

export default function GridPopup({
    popupConfig,
    popupUrl,
    handleHidePopup,
    handleConfirmPopup,
    popupType,
    popupMode,
    reloadGridData,
    formPostData,
    getMethod,
    component,
    componentProps,
    setCheckedKeys,
    gridData,
    uid,
    afterSave,
}: GridPopupProps) {
    if (popupUrl === '') {
        return null;
    }

    if (popupType === 'component' && component) {
        const componentName = Components[component as ComponentKey];

        if (componentName) {
            return React.createElement(componentName, {
                reloadGridData: reloadGridData,
                data: formPostData,
                handleHidePopup: handleHidePopup,
                url: popupUrl,
                settingsUrl: popupUrl,
                setCheckedKeys: setCheckedKeys,
                componentProps: componentProps,
                gridData: gridData,
                popupConfig: popupConfig,
            });
        }
    }

    if (popupType === 'page') {
        return (
            <AsyncPopup
                popupSettings={popupConfig ?? {}}
                settingsUrl={popupUrl}
                onConfirm={handleConfirmPopup}
                onHide={handleHidePopup}
            />
        );
    }

    if (popupType === 'form') {
        return (
            <FormComponent
                isPopup
                popupType={popupMode}
                pk={popupUrl}
                uid={uid ?? popupUrl}
                settingsUrl={popupUrl}
                onHide={handleHidePopup}
                popupSettings={popupConfig}
                afterSave={afterSave}
                postData={formPostData}
                getMethod={getMethod}
            />
        );
    }

    return null;
}
