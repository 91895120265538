import { Theme, alpha } from '@mui/material';

export default {
    dialogContainer: { justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' },
    divider: {
        width: '1px',
        height: '16px',
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        mx: 1.5,
        alignSelf: 'center',
    },
    loading: {
        position: 'absolute',
        backgroundColor: (theme: Theme) => alpha(theme.palette.background.default, 0.6),
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    },
};
