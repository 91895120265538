import React, { useContext, useEffect } from 'react';
import useBundleTranslation from 'i18n';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import IconMi from 'components/common/icon/IconMi';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';

const SortItemTypes = {
    SORT: 'sort',
};

export interface QueryBuilderSortGridRowProps {
    data: any;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    dropCard: () => void;
    editItem: () => void;
    index: number;
}

export default function QueryBuilderSortGridRow(props: QueryBuilderSortGridRowProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { data, index, moveCard, dropCard, editItem = () => {} } = props;

    const { queryAct } = useContext(PluginQBContext);

    const dragRef = useRef<HTMLElement>(null);
    const previewRef = useRef<HTMLElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: SortItemTypes.SORT,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!previewRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            if (!clientOffset) {
                return;
            }

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop(item: any, monitor) {
            dropCard();
        },
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: SortItemTypes.SORT,
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    //drag(drop(ref));

    useEffect(() => {
        drag(dragRef);
        drop(preview(previewRef));
    });

    return (
        <Box data-handler-id={handlerId} ref={previewRef} sx={styles.rowWrapper}>
            <Box ref={dragRef} className={'cell cell--drag'}>
                <IconMi icon={'drag-and-drop-grid'} />
            </Box>
            <Box className={'cell cell--text cell--main'}>{data.column}</Box>
            <Box className={'cell cell--text data--direction'}>{data.direction}</Box>
            <Box className={'cell cell--actions'}>
                <Tooltip title={t('sort_popup.edit_icon_tooltip')}>
                    <IconButton onClick={editItem}>
                        <IconMi icon="gear" fontSize="16" />
                    </IconButton>
                </Tooltip>

                <Tooltip title={t('sort_popup.remove_icon_tooltip')}>
                    <IconButton
                        onClick={() => {
                            queryAct.removeSort(data.column);
                        }}
                    >
                        <IconMi icon="times" fontSize="16" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
}
