import React, { useState } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import useBundleTranslation from 'i18n';
import IconHandler from 'components/common/icon/IconHandler';
import Components, { ComponentKey } from 'components/common/ComponentIndex';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';

export default function FormPanelButtonComponent({ button, formActions, isSubMenuItem }: any) {
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const icon = button.props.icon ? <IconHandler icon={button.props.icon} /> : null;
    const iconButton = button.props.iconButton ? button.props.iconButton : false;
    const componentType = Components[button.props.component as ComponentKey];

    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const component = React.createElement(componentType as React.FC, {
        ...button.props,
        popupOpen: popupOpen,
        setPopupOpen: setPopupOpen,
    });

    if (isSubMenuItem)
        return (
            <>
                <ActionMenuItem
                    label={t(button.label)}
                    desc={button.menuItemDesc ? t(button.menuItemDesc) : ''}
                    icon={button.props.icon?.type === 'mi' ? button.props.icon.value : undefined}
                    onClick={() => setPopupOpen(true)}
                />
                {component}
            </>
        );

    if (iconButton && icon) {
        return (
            <>
                <Tooltip title={t(button.label)}>
                    <IconButton sx={{ p: '6px' }} onClick={() => setPopupOpen(true)}>
                        {icon}
                    </IconButton>
                </Tooltip>
                {component}
            </>
        );
    }

    return (
        <>
            <Button
                sx={{ margin: '0 6px ' }}
                onClick={() => setPopupOpen(true)}
                variant={button.props.variant}
                color={button.props.color}
                type={button.props.type ?? 'button'}
                startIcon={icon}
                disabled={button.props.disabled}
            >
                {t(button.label)}
            </Button>
            {component}
        </>
    );
}
