import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button, FormControl, FormLabel, Stack, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormElementControlPropsType, FormControlTextProps } from 'components/common/form/layout/control';
import { FormComponentBuilder } from '../common/form/layout';
import FormText from 'components/common/form/layout/control/FormText';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { YNValues } from 'tools/types';
import JDBCString from './JDBCString';
import { instance } from 'api/api';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

export interface JDBSDrivers {
    value: string;
    label: string;
    props: {
        adsp_auth: YNValues;
        has_test: YNValues;
        key_auth: YNValues;
        port: string;
        template: string;
        tz: string | null;
        related_dbms: string;
    };
}

export interface FormControlJDBCControlProps extends FormControlTextProps {
    jdbcDrivers: JDBSDrivers[];
    timezones: {
        value: string;
        label: string;
    }[];
    isDefault: boolean;
    jdbcDriversUrl?: string;
}

const JDBCControl = ({ controlProps, elementProps }: FormElementControlPropsType<FormControlJDBCControlProps>) => {
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);
    const values = controlProps.form.hookFormGetValues();

    const [prevJDBCString, setPrevJDBCString] = useState(controlProps.value ?? '');
    const [currentJDBCString, setCurrentJDBCString] = useState(controlProps.value ?? '');
    const [prevStorageType, setPrevStorageType] = useState(values.storage_type);
    const [jdbcDrivers, setJdbcDrivers] = useState<JDBSDrivers[]>([]);
    const [showPingResult, setShowPingResult] = useState(false);
    const [pingLoading, setPingLoading] = useState(false);
    const [pingResultMessage, setPingResultMessage] = useState('');

    const allowedPingHost = useConfigConstantValue('ALLOWED_PINGHOST') === 'Y';
    const form = controlProps.form;

    useEffect(() => {
        if (currentJDBCString !== controlProps.value) {
            controlProps.form.hookFormSetValue(controlProps.name, currentJDBCString, {
                shouldDirty: true,
            });
        }
    }, [currentJDBCString]);

    useEffect(() => {
        if (!controlProps.jdbcDriversUrl) {
            setJdbcDrivers(controlProps.jdbcDrivers);
        }
    }, []);

    const authType = useWatch({
        name: 'auth_type',
        defaultValue: values.auth_type,
        control: controlProps.form.hookFormControl,
    });

    const host = useWatch({
        name: 'host_name',
        defaultValue: values.host_name,
        control: controlProps.form.hookFormControl,
    });

    const databaseName = useWatch({
        name: 'database_name',
        defaultValue: values.database_name,
        control: controlProps.form.hookFormControl,
    });

    const jdbcDriver = useWatch({
        name: 'jdbc_driver_id',
        defaultValue: values.jdbc_driver_id,
        control: controlProps.form.hookFormControl,
    });

    const port = useWatch({
        name: 'port',
        defaultValue: values.port,
        control: controlProps.form.hookFormControl,
    });

    const timezone_id = useWatch({
        name: 'timezone_id',
        defaultValue: values.timezone_id,
        control: controlProps.form.hookFormControl,
    });

    const hostNameControlProps: FormControlTextProps = {
        form,
        name: 'host_name',
        label: t('host_name'),
        value: host,
        disabled: controlProps.isDefault,
        onChange: (value) => {
            controlProps.form.hookFormSetValue('host_name', value, {
                shouldDirty: true,
            });
        },
    };

    const databaseNameControlProps: FormControlTextProps = {
        form,
        name: 'database_name',
        label: t('database_name'),
        value: databaseName,
        disabled: controlProps.isDefault,
        onChange: (value) => {
            controlProps.form.hookFormSetValue('database_name', value, {
                shouldDirty: true,
            });
        },
    };

    const portControlProps: FormControlTextProps = {
        form,
        name: 'port',
        label: t('port'),
        value: port,
        disabled: controlProps.isDefault,
        onChange: (value) => {
            controlProps.form.hookFormSetValue('port', value, {
                shouldDirty: true,
            });
        },
    };

    const storageType =
        useWatch({
            name: 'storage_type',
            defaultValue: values.storage_type,
            control: controlProps.form.hookFormControl,
        }) ?? '';

    useEffect(() => {
        if (controlProps.jdbcDriversUrl && controlProps.jdbcDriversUrl > '') {
            if (storageType !== prevStorageType || !jdbcDrivers.length) {
                instance.get(controlProps.jdbcDriversUrl + `/${storageType}`).then((response) => {
                    setJdbcDrivers(response.data.data);

                    if (storageType !== prevStorageType) {
                        setPrevStorageType(storageType);
                        const selectedJdbcDriver = response.data.data[0];

                        if (selectedJdbcDriver) {
                            controlProps.form.hookFormSetValue('jdbc_driver_id', selectedJdbcDriver.value);
                            controlProps.form.hookFormSetValue('port', selectedJdbcDriver.props.port);
                        }
                    }
                });
            }
        }
    }, [storageType]);

    useEffect(() => {
        if (jdbcDriver && jdbcDrivers.length > 0) {
            const selectedJdbcDriver = jdbcDrivers.find((item) => item.value === jdbcDriver);
            if (selectedJdbcDriver) {
                controlProps.form.hookFormSetValue('related_dbms', selectedJdbcDriver.props.related_dbms ?? '-');
            }
        }
    }, [jdbcDriver, jdbcDrivers]);

    const onJdbcDriverChange = (value: string) => {
        controlProps.form.hookFormSetValue('jdbc_driver_id', value, {
            shouldDirty: true,
        });
        const selectedJdbcDriver = jdbcDrivers.find((item) => item.value === value);
        if (selectedJdbcDriver) {
            controlProps.form.hookFormSetValue('port', selectedJdbcDriver.props.port, {
                shouldDirty: true,
            });
        }
    };

    const onTimezoneChange = (value: string) => {
        controlProps.form.hookFormSetValue('timezone_id', value, {
            shouldDirty: true,
        });
    };

    const selectedJdbcDriver = jdbcDrivers.find((item) => item.value === jdbcDriver);
    const selectedTimezone = controlProps.timezones.find((item) => item.value === timezone_id);
    const isSQLStorageType = storageType ? ['MYSQL', 'MSSQL', 'TERADATA'].includes(storageType) : true;

    const pingResultSettings: PopupSettings = {
        title: t('ping.popup_title'),
        noCancel: true,
        textOK: t('ok'),
    };

    const onPingClick = () => {
        if (!host || host.length === 0) {
            alert(t('ping.define_host'));
            return;
        }

        if (!port || port.length === 0) {
            alert(t('ping.define_port'));
            return;
        }

        setPingLoading(true);
        instance
            .post('data/editor/data-source/ping-host', {
                host,
                port,
            })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setPingResultMessage(t('ping.success', { host, port }));
                }
                if (response.data.status === 'ERROR') {
                    const errorMessage = response.data.message ?? t('ping.generic_error_message');
                    setPingResultMessage(t('ping.error', { message: errorMessage }));
                }
                setShowPingResult(true);
            })
            .finally(() => {
                setPingLoading(false);
            });
    };

    const onPingResultHide = () => {
        setShowPingResult(false);
        setPingLoading(false);
        setPingResultMessage('');
    };

    return (
        <Stack direction={'column'} spacing={2}>
            <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
                <FormControl fullWidth>
                    <FormText controlProps={hostNameControlProps} />
                </FormControl>
                {allowedPingHost && (
                    <Button variant={'outlined'} color={'neutral'} sx={{ height: '16px' }} onClick={onPingClick}>
                        {t('ping.label')}
                    </Button>
                )}
            </Stack>
            <Box flexDirection="column">
                <FormControl fullWidth>
                    <FormText controlProps={databaseNameControlProps} />
                </FormControl>
            </Box>
            {authType !== 'adsp' && (
                <Box flexDirection="column">
                    <FormControl fullWidth>
                        <FormText controlProps={portControlProps} />
                    </FormControl>
                </Box>
            )}
            {isSQLStorageType && (
                <Box sx={{ flexGrow: 1 }}>
                    <FormControl fullWidth>
                        <FormLabel>{t('jdbc_driver_id')}</FormLabel>
                        <ReactSelect
                            data={jdbcDrivers}
                            value={jdbcDriver}
                            update={onJdbcDriverChange}
                            name={'jdbc_driver_id'}
                            data-test={'jdbc_driver_id'}
                            disabled={controlProps.isDefault}
                        />
                    </FormControl>
                </Box>
            )}
            {selectedJdbcDriver?.props.tz && (
                <Box sx={{ flexGrow: 1 }}>
                    <FormControl fullWidth>
                        <FormLabel>{t('timezone_id')}</FormLabel>
                        <ReactSelect
                            data={controlProps.timezones}
                            value={timezone_id}
                            update={onTimezoneChange}
                            name={'timezone_id'}
                            data-test={'timezone_id'}
                        />
                    </FormControl>
                </Box>
            )}
            {selectedJdbcDriver && authType !== 'adsp' && isSQLStorageType && (
                <JDBCString
                    currentJDBCString={currentJDBCString}
                    setCurrentJDBCString={setCurrentJDBCString}
                    prevJDBCString={prevJDBCString}
                    setPrevJDBCString={setPrevJDBCString}
                    t={t}
                    isDefault={controlProps.isDefault}
                    host={host}
                    port={port}
                    db={databaseName}
                    template={selectedJdbcDriver.props.template}
                    timezone={selectedTimezone && selectedTimezone.value > '' ? selectedTimezone.label : ''}
                    tzTemplate={selectedJdbcDriver.props.tz}
                />
            )}
            {pingLoading && (
                <LoadingPlaceholder>
                    <Typography>{t('ping.label')}</Typography>
                </LoadingPlaceholder>
            )}
            {showPingResult && (
                <Popup
                    settings={pingResultSettings}
                    open={showPingResult}
                    onHide={onPingResultHide}
                    maxWidth={'popupSm'}
                    onConfirm={onPingResultHide}
                >
                    <Typography>{pingResultMessage}</Typography>
                </Popup>
            )}
        </Stack>
    );
};

export class JDBCControlBuilder extends FormComponentBuilder {
    prepareProps(): FormControlJDBCControlProps {
        return {
            ...this.controlProps,
            jdbcDrivers: this.elementProps.componentProps?.jdbcDrivers ?? [],
            timezones: this.elementProps.componentProps?.timezones ?? [],
            isDefault: this.elementProps.componentProps?.isDefault ?? false,
            jdbcDriversUrl: this.elementProps.componentProps?.jdbcDriversUrl ?? '',
        };
    }
}

export default JDBCControl;
