import React from 'react';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import { Stack, Typography } from '@mui/material';
import styles from './NoSearchResult.styles';

export default function NoSearchResult({ emptyText, icon }: { emptyText: string; icon?: string }) {
    const { t } = useBundleTranslation(['components/common/grid/grid']);

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={styles.wrapper}>
            <IconMi icon={icon ?? 'search'} fontSize={'16'} />
            <Typography>{emptyText > '' ? emptyText : t('no_data_found_placeholder')}</Typography>
        </Stack>
    );
}
