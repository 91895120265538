import React, { useEffect } from 'react';
import Moment from 'moment';
import { FormControl, FormLabel, Grid } from '@mui/material';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { ISubstitutionFormProps } from 'components/common/form/data-fetch-command/substitution/index';
import useBundleTranslation from 'i18n';

export default function SubstitutionDateTimeValueField({
    formApi,
    type,
    showTimeSelect,
}: ISubstitutionFormProps & { showTimeSelect: boolean }) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);

    useEffect(() => {
        const defaultValue = formApi.form.hookFormGetValues('pattern_subst');
        if (typeof defaultValue == 'undefined' || defaultValue == '') {
            formApi.form.hookFormSetValue(
                'pattern_subst',
                Moment(new Date()).format(showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD'),
            );
        }
    }, []);

    const pattern = ['metric', 'calendar', 'report'].includes(type) ? type : 'default';

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <FormLabel>{t(`subs.${pattern}.measured_time`)}</FormLabel>
                <TemplateFormComponent
                    component={'FormDatePicker'}
                    elementProps={formApi}
                    name={'pattern_subst'}
                    props={{ showTimeSelect: showTimeSelect, noSeconds: true }}
                />
            </FormControl>
        </Grid>
    );
}
