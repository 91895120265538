import { Box, Grid, Stack, Typography } from '@mui/material';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import isOwnerVisible from 'tools/owners';

function Owner({ type, name }: { type: string; name?: string }) {
    if (!name || name === '') {
        return null;
    }

    return (
        <Grid container direction={'row'} minWidth={'225px'}>
            <Grid item xs={6}>
                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'} display="inline" minWidth={'92px'}>
                    {`${type}:`}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={'18px'}
                    display="inline"
                    color={'primary.main'}
                    minWidth={'120px'}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default function Owners({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const { configuration }: UserAuth = useAppSelector((state) => state.auth);

    const isOwnersPresent =
        (isOwnerVisible(configuration.owners.business_owner, 'datasets') && datasetInfo.business_owner_dn) ||
        (isOwnerVisible(configuration.owners.technical_owner, 'datasets') && datasetInfo.technical_owner_dn) ||
        (isOwnerVisible(configuration.owners.data_steward, 'datasets') && datasetInfo.data_steward_dn);

    return isOwnersPresent ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography fontSize={14} width={'16px'}>
                <IconMi icon={'user'} />
            </Typography>
            <Stack direction={'column'} spacing={0.5}>
                <Owner type={configuration.owners.business_owner.label} name={datasetInfo.business_owner_dn} />
                <Owner type={configuration.owners.technical_owner.label} name={datasetInfo.technical_owner_dn} />
                <Owner type={configuration.owners.data_steward.label} name={datasetInfo.data_steward_dn} />
            </Stack>
        </Box>
    ) : null;
}
