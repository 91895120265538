import React, { useEffect, useRef, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useBundleTranslation from 'i18n';
import { instance } from 'api/api';
import { createPortal } from 'react-dom';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { ActSubmit } from 'components/common/form/act';
import {
    DataValidateCollectContext,
    useDataValidateCollect,
} from 'components/common/form/data-validate-collect/useDataValidateCollect';
import { useQueryClient } from '@tanstack/react-query';
import { useFormState } from 'react-hook-form';
import { useContentWorkflowFieldsValidation } from 'form-templates/hooks/useContentWorkflow';

export default function MetricForm(props: IFormLayoutProps) {
    const { t } = useBundleTranslation('app/editor/element/metric');
    const fieldsList = [
        'additive_ind',
        'aggregate_if_missing_measurements_ind',
        'aggregate_source_element_id',
        'metric_aggregate_function',
        'metric_value_type',
    ];

    const fieldsForPoints = ['metric_moving_average_period', 'metric_moving_average_unit', 'metric_value_type'];
    const [isLoading, setIsLoading] = useState(false);
    const [showViewChangedPopup, setShowViewChangedPopup] = useState(false);

    const elementId = props.hookForm.form.hookFormGetValues('element_id');

    const metricDefaultViewValue = props.hookForm.form.hookFormWatch('metric_default_view');
    const queryClient = useQueryClient();

    const { dirtyFields } = useFormState({ control: props.hookForm.form.hookFormControl });
    const dirtyFieldsRef = useRef(dirtyFields);
    useEffect(() => {
        dirtyFieldsRef.current = dirtyFields;
    }, [dirtyFields]);

    const { workflowFieldsValidation } = useContentWorkflowFieldsValidation(props.hookForm.form, t);
    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                let viewChanged = false;

                const preSubmit = async () => {
                    const changedFields = fieldsList.filter((f) => Object.keys(dirtyFieldsRef.current).includes(f));
                    if (Object.keys(dirtyFieldsRef.current).includes('metric_default_view')) {
                        viewChanged = true;
                    }

                    if (changedFields.length && props.hookForm.form.hookFormGetValues('data_source') == 'aggregate') {
                        const labels = changedFields.map((f) => t(`caf_${f}`)).join(', ');

                        if (confirm(t('change_aggregate_fields', { labels: labels }))) {
                            return super.exec(data);
                        } else {
                            return Promise.resolve({ data: { status: 'CANCELED' } });
                        }
                    } else {
                        const changedPointsFields = fieldsForPoints.filter((f) =>
                            Object.keys(dirtyFieldsRef.current).includes(f),
                        );
                        if (changedPointsFields.length) {
                            setIsLoading(true);
                            return instance
                                .get(`/data/editor/element/metric/${elementId}/have-values`)
                                .then((response) => {
                                    const labels = changedPointsFields.map((f) => t(`caf_${f}`)).join(', ');
                                    const count = response.data.count ?? 0;
                                    setIsLoading(false);
                                    if (count > 0) {
                                        if (confirm(t('change_aggregate_fields', { labels: labels, count: count }))) {
                                            return super.exec(data);
                                        } else {
                                            return Promise.resolve({ data: { status: 'CANCELED' } });
                                        }
                                    } else {
                                        return super.exec(data);
                                    }
                                });
                        }
                    }
                    return super.exec(data);
                };

                const workflowValidation = await workflowFieldsValidation();
                if (!workflowValidation) {
                    return Promise.resolve({ data: { status: 'CANCELED' } });
                }
                return preSubmit().then((response) => {
                    if (viewChanged) {
                        setShowViewChangedPopup(true);
                    }
                    if (response.data?.updatedFields?.renewValuesNumber) {
                        queryClient.resetQueries({
                            predicate: (query) => query.queryKey[0] == 'dataFetchCommandHasData',
                        });
                    }
                    return response;
                });
            }
        },
    );

    const onViewChangeConfirm = () => {
        const url = `/data/editor/element/metric/${elementId}/apply-view-to-users`;
        setIsLoading(true);

        instance
            .post(url)
            .then(() => {
                setShowViewChangedPopup(false);
            })
            .finally(() => setIsLoading(false));
    };

    const viewChangedPopupSettings: PopupSettings = {
        textOK: t('view_changed_popup.ok_button'),
        textCancel: t('view_changed_popup.cancel_button'),
        title: t('view_changed_popup.title'),
    };
    const dataValidateCollect = useDataValidateCollect(props.hookForm.form);
    return (
        <>
            {isLoading &&
                createPortal(
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: (theme) => 9999,
                        }}
                    />,
                    document.body,
                )}
            <DataValidateCollectContext.Provider value={dataValidateCollect}>
                <FormLayoutComposer props={props} />
                {showViewChangedPopup && (
                    <Popup
                        settings={viewChangedPopupSettings}
                        open={showViewChangedPopup}
                        onHide={() => setShowViewChangedPopup(false)}
                        onConfirm={onViewChangeConfirm}
                        maxWidth={'popupSm'}
                    >
                        {t('view_changed_popup.confirm_message', { view_name: t(metricDefaultViewValue) })}
                    </Popup>
                )}
            </DataValidateCollectContext.Provider>
        </>
    );
}
