import { Grid } from '@mui/material';
import { Cell } from 'rsuite-table';
import React, { useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import styles from './SortCell.styles';
import Tooltip from '@mui/material/Tooltip';
import useBundleTranslation from 'i18n';

export default function SortCell(props: any) {
    const { t } = useBundleTranslation();

    const { tooltip, isDragging } = props;

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const cellContent = (
        <Cell {...props} className="rs-table-cell--sort">
            <Grid
                container
                className={'sort-holder'}
                sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={() => setShowTooltip(false)}
            >
                <IconMi icon={'drag-and-drop-grid'} sx={styles.iconStyle} />
            </Grid>
        </Cell>
    );

    if (tooltip) {
        return (
            <Tooltip title={t(tooltip)} open={showTooltip && !isDragging}>
                {cellContent}
            </Tooltip>
        );
    }

    return cellContent;
}
