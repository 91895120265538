import useBundleTranslation from 'i18n';
import React, { useEffect, useState } from 'react';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Popup } from 'components/common/popup/Popup';
import { DataFetchCommandEntityType } from 'components/common/form/DataFetchCommand';
import { AssocArray } from 'tools/types';
import { FormRendererAPIType } from 'components/common/form/index';
import { getElementFieldValue } from 'components/common/form/formTools';
import SubstitutionSegmentValueField from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionSegmentValueField';
import SubstitutionDateTimeValueField from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionDateTimeValueField';
import {
    getPostArgs,
    ISubstitutionFormProps,
    ModeType,
} from 'components/common/form/data-fetch-command/substitution/index';
import SubstitutionDeleteValueField from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionDeleteValueField';
import SubstitutionDatasetForm from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionDatasetForm';
import useDatasetHasBackfield from 'components/common/form/data-fetch-command/substitution/hooks/useDatasetHasBackfield';

export default function DataFetchCommandSubstitution({
    mode,
    start,
    cancel,
    type,
    defaults,
    requiredFields,
    editorForm,
    dataSource,
    hasData,
    alwaysShowForm,
}: {
    mode: ModeType;
    start: (args: any) => void;
    cancel: () => void;
    type: DataFetchCommandEntityType;
    defaults: AssocArray<string>;
    matchedPatterns: Array<string>;
    requiredFields: Array<string>;
    editorForm: FormRendererAPIType;
    dataSource: string;
    hasData?: boolean;
    alwaysShowForm?: boolean;
}) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);

    const [formActive, setFormActive] = useState<boolean>(false);
    useEffect(() => {
        if (
            mode == 'collect' ||
            requiredFields.length ||
            alwaysShowForm ||
            (type == 'dataset' && useDatasetHasBackfield(mode, editorForm, dataSource == 'manual'))
        ) {
            setFormActive(true);
        } else {
            start(getPostArgs(type, dataSource, editorForm));
            setFormActive(false);
        }
    }, [requiredFields, alwaysShowForm]);

    const formApi = useCustomSimplifiedForm(defaults);

    if (!formActive) {
        return null;
    }

    let formComponent = null;

    const formProps: ISubstitutionFormProps = {
        formApi: formApi,
        editorForm: editorForm,
        mode: mode,
        hasData: hasData,
        requiredFields: requiredFields,
        type: type,
        dataSource: dataSource,
    };

    if (type == 'dependency' || type == 'command') {
        formComponent = <RegularForm {...formProps} />;
    } else if (type == 'calendar') {
        formComponent = <CalendarForm {...formProps} />;
    } else if (type == 'metric') {
        formComponent = <MetricForm {...formProps} />;
    } else if (type == 'dataset') {
        formComponent = <SubstitutionDatasetForm {...formProps} />;
    } else if (type == 'report') {
        formComponent = <ReportForm {...formProps} />;
    }
    const handleApply = () => {
        const data: any = {
            pattern_subst: formApi.form.hookFormGetValues('pattern_subst'),
        };
        if (type != 'segment') {
            data.segment_value_id = formApi.form.hookFormGetValues('segment_value_id') ?? 0;
            data.segment_id = formApi.form.hookFormGetValues('segment_id') ?? 0;
        }
        if (type == 'dataset' && mode == 'collect') {
            data.update_instances = formApi.form.hookFormGetValues('update_instances');
            data.update_mode = formApi.form.hookFormGetValues('update_mode');
            data.effective_date = formApi.form.hookFormGetValues('effective_date');
            data.effective_date_from = formApi.form.hookFormGetValues('effective_date_from');
            data.effective_date_to = formApi.form.hookFormGetValues('effective_date_to');
        }
        if (type == 'metric' && mode == 'collect' && hasData) {
            data.delete_data = formApi.form.hookFormGetValues('delete_data') ?? 'N';
        }
        start({ ...data, ...getPostArgs(type, dataSource, editorForm) });
        setFormActive(false);
    };

    return (
        <Popup
            settings={{ title: t('parameters_substitution'), textOK: t(`${mode}_ok`) }}
            open={formActive}
            onHide={() => {
                setFormActive(false);
                cancel();
            }}
            onConfirm={handleApply}
            maxWidth={'popupSm'}
        >
            {formComponent}
        </Popup>
    );
}

function RegularForm(props: ISubstitutionFormProps) {
    return <SubstitutionDateTimeValueField showTimeSelect={true} {...props} />;
}

function CalendarForm(props: ISubstitutionFormProps) {
    const segmentId = props.editorForm.hookFormGetValues('scope_limited_by_segment_id');
    const isBulk = props.editorForm.hookFormGetValues('bulk_mode_ind') == 'Y';
    //@ts-ignore
    const isBindParam = getElementFieldValue(props.editorForm, `scope_limited_by_segment_id.bind_param`) > '';

    return (
        <div>
            {segmentId > 0 && isBindParam && !isBulk && (
                <SubstitutionSegmentValueField {...props} segmentId={segmentId} />
            )}
            <SubstitutionDateTimeValueField showTimeSelect={true} {...props} />
        </div>
    );
}

function ReportForm(props: ISubstitutionFormProps) {
    const segmentId = props.editorForm.hookFormGetValues('segment_id');
    return (
        <div>
            {segmentId > 0 && <SubstitutionSegmentValueField {...props} segmentId={segmentId} />}
            {props.requiredFields.includes(':last_event_time') && (
                <SubstitutionDateTimeValueField showTimeSelect={false} {...props} />
            )}
        </div>
    );
}

function MetricForm(props: ISubstitutionFormProps) {
    const segmentId = props.editorForm.hookFormGetValues('segment_id');

    //@ts-ignore
    const isBindParam = getElementFieldValue(props.editorForm, `segment_id.bind_param`) > '';
    const measurementTime = props.formApi.form.hookFormWatch('pattern_subst');
    const hasMeasurementTime = props.requiredFields.includes(':last_measurement_time');
    const hasSegment = props.requiredFields.includes(':segment');
    return (
        <div>
            {segmentId > 0 && isBindParam && hasSegment && (
                <SubstitutionSegmentValueField {...props} segmentId={segmentId} />
            )}
            {hasMeasurementTime && <SubstitutionDateTimeValueField showTimeSelect={true} {...props} />}
            <br />
            {props.mode == 'collect' && props.hasData && (
                <SubstitutionDeleteValueField
                    hasMeasurementTime={hasMeasurementTime}
                    measurementTime={measurementTime}
                    {...props}
                />
            )}
        </div>
    );
}
