import { alpha, Theme } from '@mui/material';

export default {
    tooltipContainer: {
        width: '280px',
        lineHeight: '16px',
    },
    sectionWrapper: {
        borderTop: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        p: 1,
        alignItems: 'flex-start',
        '&:first-of-type': {
            borderTop: 'none',
        },
    },
    iconHolder: {
        height: '16px',
        width: '16px',
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    fixedValueRow: {
        '.fixed-label': {
            width: '100px',
            flexShrink: 0,
            mr: 1,
        },
        '.fixed-value': {
            flexGrow: 1,
            overflow: 'hidden',
            wordWrap: 'break-word',
        },
    },
    breakWordValue: {
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    descriptionItem: {
        flexGrow: 1,
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    termSection: {
        '&.expand': {
            '.term-section__header': {
                color: 'primary.main',
                fontWeight: 600,
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                },
            },
        },
        '&.collapse': {
            '.term-section__header': {
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                    color: 'primary.main',
                },
            },
            '& .term-section__items': {
                overflow: 'hidden',
                height: 0,
            },
        },
    },
    descriptionHolder: {
        overflow: 'hidden',
    },
    descriptionExpandControl: {
        lineHeight: 1,
        mt: 1,
        pr: '24px',
        textAlign: 'center',
        color: 'primary.main',
        '&:hover': { opacity: 0.75, cursor: 'pointer' },
    },
};
