import IconMi from './IconMi';
import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
const IconFAUniversal = lazy(() => import('components/common/icon/IconFAUniversal'));

export interface IconType {
    type: 'fa' | 'mi' | 'upload';
    value: string | string[];
}

//ToDo [Stas]: assign right interface for props.
export default function IconHandler(props: any) {
    if (!props?.icon?.type || !props?.icon?.value) return null;

    const colorProp = props.color ? { color: props.color } : {};
    const sxProp = { ...(props.sx ?? {}), ...colorProp };

    if (props.icon.type === 'fa') {
        return (
            <Suspense>
                <IconFAUniversal {...props} iconRaw={props.icon.value} sx={sxProp} />
            </Suspense>
        );
    }
    if (props.icon.type === 'mi') {
        return <IconMi {...props} icon={props.icon.value} sx={sxProp} />;
    }

    if (props.icon.type == 'upload' || props.icon.type == 'uploaded') {
        return <Box component="img" src={props.icon.value} alt={props.alt ?? ''} sx={sxProp} />;
    }

    return null;
}
