import { Box, Typography } from '@mui/material';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import CertificationData from 'components/common/CertificationData';

export default function Certified({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const isCertified = datasetInfo.certified === 'Y';

    return isCertified ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography color={'success.main'} fontSize={14} width={'16px'}>
                <IconMi icon={'certified'} />
            </Typography>
            <Box sx={styles.certifiedMainText}>
                <CertificationData
                    certificationData={{
                        color: 'success.main',
                        lastUpdatedBy: datasetInfo.certified_by_dn ?? '',
                        lastUpdatedTime: datasetInfo.certified_date ?? '',
                    }}
                    markUserName={true}
                />
            </Box>
        </Box>
    ) : null;
}
