import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';
import styles from 'components/common/static-addon/StaticAddon.styles';

//ToDo:  Consider component for form
//ToDo: Consider wrapper with field
export const StaticAddon: React.FC<PropsWithChildren<{ sx?: SxProps }>> = ({ children, sx }) => {
    return (
        <Box sx={{ ...styles.component, ...sx }} className="static-addon">
            <div>{children}</div>
        </Box>
    );
};
