import { alpha, Theme } from '@mui/material';

export default {
    slWrapper: { border: '1px solid', borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08) },
    slLine: {
        padding: 0.5,
        minHeight: '66px',
        display: 'flex',
        alignItems: 'center',
    },
    slLineInfo: {
        width: '100%',
    },
    slLineInfoDimLinks: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    slLineOptions: {},
    slLineOptionsNotLink: {},
    slLineDimensionCell: {
        pr: 2,
        width: '92px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    slLineDimension: {
        display: 'inline-flex',
        alignItems: 'center',
        color: 'text.main',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    slLineEnvelopeCell: {
        pr: 2,
        width: '18px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    slLineEnvelope: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: 'primary.main',
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    slLineEnvelopeActive: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: 'primary.main',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'background.default',
            left: '-8px',
            right: '-8px',
            top: '-8px',
            bottom: '-8px',
            boxShadow: (theme: Theme) => `2px 0px 8px ${alpha(theme.palette.text.primary, 0.15)}`,
        },
        '.MuiSvgIcon-root': {
            position: 'relative',
        },
    },
    slBookmarkBlock: { px: 3, py: 2 },
    slBookmarkDragNote: {
        textAlign: 'right',
        color: 'text.secondary',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },
    slBookmarkEmpty: {
        py: 1,
        border: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        textAlign: 'center',
    },
};
