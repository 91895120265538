import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useEffect } from 'react';

export default function ExportForm(props: IFormLayoutProps) {
    const infoTab = <InfoTab {...customTabProps(props, 'info')} />;

    const customProps = {
        ...props,
        customTabs: { info: infoTab },
    };

    return <FormLayoutComposer props={customProps} />;
}

function InfoTab(tabProps: ITabProps) {
    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);
    const gridFolder: Array<{}> = tabProps.elementProps.form?.hookFormWatch('gridFolder');
    const exportGrid: Array<{}> = tabProps.elementProps.form?.hookFormWatch('exportGrid');
    const gridPortalPage: Array<{}> = tabProps.elementProps.form?.hookFormWatch('gridPortalPage');
    const gridCustomField: Array<{}> = tabProps.elementProps.form?.hookFormWatch('gridCustomField');

    useEffect(() => {
        if (exportGrid) {
            hookForm.hookFormSetValue('elements_included', exportGrid.length > 0 ? 'Y' : 'N', {
                shouldDirty: false,
            });
        }
        if (gridPortalPage) {
            hookForm.hookFormSetValue('portal_pages_included', gridPortalPage.length > 0 ? 'Y' : 'N', {
                shouldDirty: false,
            });
        }
        if (gridCustomField) {
            hookForm.hookFormSetValue('custom_field_included', gridCustomField.length > 0 ? 'Y' : 'N', {
                shouldDirty: false,
            });
        }
    }, [exportGrid, gridPortalPage, gridCustomField]);

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <></>
            <>
                <FormElementComponent {...getElementProps('exportGrid')} />
                <FormElementComponent {...getElementProps('gridDataset')} />
                <FormElementComponent {...getElementProps('gridAccessMap')} />
                <FormElementComponent {...getElementProps('gridSegment')} />
                <FormElementComponent {...getElementProps('gridPortalPage')} />
                <FormElementComponent {...getElementProps('gridPortalLayoutTemplate')} />
                <FormElementComponent {...getElementProps('gridAssets')} />
                <FormElementComponent {...getElementProps('gridBurst')} />
                <FormElementComponent {...getElementProps('gridDigestTemplate')} />
                <FormElementComponent {...getElementProps('gridFolder')} />
                {gridFolder && gridFolder.length > 0 && (
                    <FormElementComponent {...getElementProps('gridFolderIgnoredEl')} />
                )}
                <FormElementComponent {...getElementProps('gridGroup')} />
                <FormElementComponent {...getElementProps('gridBusinessUnit')} />
                <FormElementComponent {...getElementProps('gridPrivilegeSet')} />
                <FormElementComponent {...getElementProps('gridCustomField')} />
                <FormElementComponent {...getElementProps('gridBrand')} />
                <FormElementComponent {...getElementProps('checkboxWrapContainer')} />
            </>
        </FormTabContentDefaultStacks>
    );
}
