import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { Stack } from '@mui/material';
import { FormTabContent } from 'components/common/form/layout/tab/FormTabContent';

export default function PortalPageAssetForm(props: IFormLayoutProps) {
    const codeTab = <CodeTab {...customTabProps(props, 'code')} />;

    const customProps = {
        ...props,
        customTabs: { code: codeTab },
    };

    return <FormLayoutComposer props={customProps} />;
}

function CodeTab(tabProps: ITabProps) {
    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);

    return (
        <FormTabContent {...tabProps}>
            <Stack
                alignItems="center"
                className={
                    'default-components form-components-indent scroll-content-container scroll-content-container--form'
                }
                sx={{
                    overflow: 'none',
                    flexGrow: 1,
                    width: '100%',
                    pb: 3,
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                }}
            >
                <FormElementComponent {...getElementProps('file_content')} />
            </Stack>
        </FormTabContent>
    );
}
