import { instance } from 'api/api';
import { APIResponse } from 'tools/types';

export interface RunScriptResponse {
    csrl_id: number;
    output: string;
}

export const customScriptAPI = {
    async runCustomScript(url: string, selectedParamSet: string): Promise<APIResponse<RunScriptResponse>> {
        const response = await instance.post(url, { csps: selectedParamSet });

        return response.data;
    },
};
