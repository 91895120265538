import { Chart as HChart } from 'highcharts';
import { AdditionalToDrawType } from 'components/metric/chart/draw/drawRanges';

let changeBuff: any = undefined;

export default function adjustCanvas(chart: HChart, additionalIcons: AdditionalToDrawType) {
    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];
    const xExt = xAxis.getExtremes();
    const yExt = yAxis.getExtremes();

    let alertMaxY = 0;
    additionalIcons.ranges.forEach((p) => {
        alertMaxY = p.y > alertMaxY ? p.y : alertMaxY;
    });

    additionalIcons.points.forEach((p) => {
        if (p.x >= (xExt?.userMin ?? 0) && p.x <= (xExt?.userMax ?? 0)) {
            alertMaxY = p.y >= alertMaxY ? p.y : alertMaxY;
        }
    });

    if (alertMaxY == 0) {
        if (yExt.userMax != undefined) {
            changeBuff = undefined;
            chart.yAxis[0].setExtremes(undefined, undefined);
        }
        return;
    }

    // console.log(alertMaxY, yExt.dataMax);

    // TODO: fix
    // return;

    // setTimeout(() => {
    if (yExt.dataMax * 1.3 < alertMaxY && alertMaxY > 0) {
        const maxK = yExt.dataMax / alertMaxY;
        if (maxK < 0.1 && (yExt?.userMax ?? 0) < alertMaxY) {
            return;
        }

        if (changeBuff != alertMaxY) {
            changeBuff = alertMaxY;
            yAxis.setExtremes(undefined, alertMaxY);
        }
    } else {
        if (yExt.userMax != undefined) {
            changeBuff = undefined;
            yAxis.setExtremes(undefined, undefined);
        }
    }
    // }, 10);
}
