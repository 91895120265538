import { useEffect, useState } from 'react';

export default function useOnSVChange(segmentValueId: number, resetData: (resetInitialData: boolean) => void) {
    const [prevSegmentValue, setPrevSegmentValue] = useState(segmentValueId);
    useEffect(() => {
        if (segmentValueId == prevSegmentValue) {
            return;
        }
        setPrevSegmentValue(segmentValueId);
        resetData(true);
    }, [segmentValueId]);
}
