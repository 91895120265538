import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import GridComponent from 'components/common/grid/GridComponent';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { editorExternalReferenceAPI } from 'api/editor/external-reference';
import { APIResponse, YNValues } from 'tools/types';
import { ReferenceInfoResponse } from 'components/external-reference-selection';
import IconMi from 'components/common/icon/IconMi';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useFormState } from 'react-hook-form';
import { FormComponentBuilder } from 'components/common/form/layout';
import Alert from '@mui/material/Alert';

export interface FormControlReferenceExternalFiltersManagerProps extends FormControlProps {
    reference: number;
    referenceTitle: string;
    pluginName: string;
    pluginConnectionProfile: number;
    refreshGridId: string;
    isRollup?: boolean;
    ef_auto?: boolean;
}

export default function ReferenceExternalFiltersManager({
    controlProps,
}: FormElementControlPropsType<FormControlReferenceExternalFiltersManagerProps>) {
    const pluginName = controlProps.pluginName;
    const { t } = useBundleTranslation(['components/external-reference/editor/reference_external_filters_manager']);
    const { t: gridT } = useBundleTranslation(['app/editor/external-reference/filter']);
    const popupSettings: PopupSettings = {
        title: t('plugin_name_filters', { plugin_name: pluginName }),
        textOK: t('button_done'),
        needTranslation: false,
        noCancel: true,
    };

    const [managerPopup, setManagerPopup] = useState<boolean>(false);

    const { isDirty } = useFormState({ control: controlProps.form.hookFormControl });

    const handleHideManager = function () {
        setManagerPopup(false);
        setGridRefetch((prevState) => prevState + 1);
        setTimeout(() => queryClient.removeQueries({ queryKey: ['external_filter_autoload_ind'] }));
    };
    const handleShowManager = function () {
        if (isDirty) {
            controlProps.form.formSave().then(() => {
                setManagerPopup(true);
            });
        } else {
            setManagerPopup(true);
        }
    };

    const elementProps = useCustomSimplifiedForm();

    const switchProps = prepareFormElementProps({
        ...elementProps,
        component: {
            uid: 'external_filter_autoload_ind',
            name: 'external_filter_autoload_ind',
            component: 'FormSwitch',
            label: t('alert_retrieve_filter_names_from') + ' ' + pluginName,
        },
    });

    const dispatch = useDispatch();
    const [gridRefetch, setGridRefetch] = useState<number>(0);

    const queryClient = useQueryClient();
    useEffect(() => {
        // Update component Shared State
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { gridRefetch: gridRefetch },
            }),
        );
        if (controlProps.refreshGridId) {
            queryClient.invalidateQueries({ queryKey: [controlProps.refreshGridId] });
        }
    }, [gridRefetch]);

    // Display Loading Mask
    const [isCollectionRunning, setIsCollectionRunning] = useState<boolean>(false);

    const handleAutoCollectChange = (value: boolean) => {
        if (value) {
            // TODO: get grid rows amount;
            const n = 0;

            const mask_text = t('mask_loading_filters');
            const text =
                t(n > 0 ? 'alert_all_filters_will_be_deleted' : 'alert_filters_will_begin_loading_immediately') +
                ' ' +
                t('alert_this_could_take_a_while');
            if (!confirm(text)) {
                elementProps.form.hookFormSetValue('external_filter_autoload_ind', 'N');
                return false;
            }
        } else {
            const text = t('alert_object_filter_list', {
                tool: pluginName > '' ? pluginName : t('element_external_tool', 'External Tool'),
            });
            if (!confirm(text)) {
                elementProps.form.hookFormSetValue('external_filter_autoload_ind', 'Y');
                return false;
            }
        }

        setIsCollectionRunning(true);
        editorExternalReferenceAPI
            .setAutoLoad(controlProps.pluginConnectionProfile, controlProps.reference, value)
            .then((response: APIResponse<ReferenceInfoResponse>) => {
                if (response.status == 'ERROR') {
                    alert(response.data.message ?? '');
                    elementProps.form.hookFormSetValue('external_filter_autoload_ind', 'N');
                    return false;
                } else {
                    setGridRefetch((prevState) => prevState + 1);
                }
            })
            .finally(() => {
                setIsCollectionRunning(false);
            });
    };

    const handleRefreshAutoFilters = () => {
        setIsCollectionRunning(true);
        editorExternalReferenceAPI
            .refreshFilters(controlProps.pluginConnectionProfile, controlProps.reference)
            .then((response: APIResponse<ReferenceInfoResponse>) => {
                if (response.status == 'ERROR') {
                    return false;
                } else {
                    setGridRefetch((prevState) => prevState + 1);
                }
            })
            .finally(() => {
                setIsCollectionRunning(false);
            });
    };

    const autoLoad = elementProps.form.hookFormWatch('external_filter_autoload_ind');
    const { data: defaultExternalFilterAutoloadInd, status: getDefaultsStatus } = useQuery<YNValues, Error>({
        queryKey: ['external_filter_autoload_ind'],
        queryFn: () => {
            return editorExternalReferenceAPI.getExternalAutoloadInd(
                controlProps.pluginConnectionProfile,
                controlProps.reference,
            );
        },
        enabled: managerPopup,
    });

    useEffect(() => {
        if (getDefaultsStatus == 'success') {
            elementProps.form.hookFormSetValue('external_filter_autoload_ind', defaultExternalFilterAutoloadInd);
        }
    }, [defaultExternalFilterAutoloadInd, getDefaultsStatus]);

    const hasLabel = Boolean(controlProps.label);

    const buttonComponent = (
        <Button
            data-test="manage_filters_button"
            startIcon={<IconMi icon="filter" />}
            variant={'light'}
            onClick={handleShowManager}
        >
            {t('manage_filters')}
        </Button>
    );

    return (
        <>
            {hasLabel ? (
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="heading">{t(controlProps.label as string)}</Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right' }} xs={3}>
                        {buttonComponent}
                    </Grid>
                </Grid>
            ) : (
                buttonComponent
            )}

            <Popup settings={popupSettings} open={managerPopup} onHide={handleHideManager}>
                <Box>
                    {isCollectionRunning && <LoadingPlaceholder />}
                    <Box>
                        <Alert severity={'info'} variant={'standard'}>
                            {t('filter_description', { name: controlProps.referenceTitle })}
                        </Alert>
                        <br />
                    </Box>
                    <Box>
                        {controlProps.ef_auto && (
                            <ReactHookFormController
                                afterChange={(event) => {
                                    handleAutoCollectChange(event == 'Y');
                                }}
                                elementProps={switchProps}
                            />
                        )}
                        {autoLoad == 'Y' && (
                            <Button
                                startIcon={<IconMi icon={'refresh-cw'} />}
                                variant={'outlined'}
                                onClick={() => {
                                    handleRefreshAutoFilters();
                                }}
                            >
                                {t('refresh_filters')}
                            </Button>
                        )}
                    </Box>

                    {controlProps.reference > 0 && (
                        <Box sx={{ mt: (theme) => theme.size.pxValue(theme.size.componentFormIndent) }}>
                            <GridComponent
                                t={gridT}
                                hideButtons={autoLoad == 'Y'}
                                reloadTrigger={gridRefetch}
                                uid={`reference_editor`}
                                settingsUrl={`/data/editor/external-reference/${controlProps.reference}/filter/grid`}
                            />
                        </Box>
                    )}
                </Box>
            </Popup>
        </>
    );
}

export class ReferenceExternalFiltersManagerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlReferenceExternalFiltersManagerProps {
        return {
            ...this.controlProps,
            reference: this.elementProps.componentProps?.reference,
            referenceTitle: this.elementProps.componentProps?.referenceTitle ?? '',
            pluginName: this.elementProps.componentProps?.pluginName ?? '',
            pluginConnectionProfile: this.elementProps.componentProps?.pluginConnectionProfile ?? 0,
            refreshGridId: this.elementProps.componentProps?.refreshGridId ?? '',
            isRollup: false,
            ef_auto: this.elementProps.componentProps?.ef_auto == 'Y',
        };
    }
}
