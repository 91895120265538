import { useQuery } from '@tanstack/react-query';
import { instance } from 'api/api';
import { APIResponse, DashboardElementType } from 'tools/types';

interface ElementToDrill {
    label: string;
    value: number;
    type: DashboardElementType;
    type_label: string;
}

export default function useDrillToElements(elementId: number) {
    const { data } = useQuery<ElementToDrill[], Error>({
        queryKey: ['report_drill_to_elements'],
        queryFn: async () => {
            try {
                const response = await instance.get<APIResponse<ElementToDrill[]>>(
                    `/data/report/get-elements-to-drill/${elementId}`,
                );
                return response.data.status == 'OK' ? response.data.data : [];
            } catch (e) {
                return [];
            }
        },
    });

    return data;
}
