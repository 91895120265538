import { Cell, Column, HeaderCell } from 'rsuite-table';
import React, { RefObject } from 'react';
import { TFunction } from 'i18next';
import {
    CellIconType,
    ColumnType,
    FilterValue,
    getColumnLabel,
    GridDataRow,
    PopupMode,
    PopupType,
    TextAlign,
} from 'components/common/grid/';
import { Box, Tooltip, Typography } from '@mui/material';
import { PopupSettings } from 'components/common/popup/Popup';
import IconHandler from 'components/common/icon/IconHandler';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { SortType } from 'rsuite-table/lib/@types/common';
import IconMi from 'components/common/icon/IconMi';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import LightTooltip from 'components/common/tooltip/LightTooltip';
import { FormRendererAPIType } from 'components/common/form';
import parse from 'html-react-parser';
import { Components } from './ComponentsRegistry';

const buildColumn = (
    column: ColumnType,
    index: number,
    t: TFunction,
    gridName: string,
    dataKey: string,
    width?: number,
    reloadGridData?: () => void,
    onResize?: (columnWidth?: number, dataKey?: string) => void,
    setPopupUrl?: (url: string) => void,
    setPopupConfig?: (popupConfig: PopupSettings) => void,
    setPopupType?: (type: PopupType) => void,
    setPopupMode?: (type: PopupMode) => void,
    customCells?: { [key: string]: any },
    setShowLoader?: (value: boolean) => void,
    order?: SortType,
    orderBy?: string,
    setPopupComponent?: (popupComponent: string) => void,
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void,
    setErrorMessage?: (message: string) => void,
    form?: FormRendererAPIType,
    setGridDataChanged?: (state: boolean) => void,
    filtersValue?: FilterValue,
    headerRef?: RefObject<HTMLDivElement>,
) => {
    const sortable = column.sortable ?? false;

    const widthParams = width
        ? { width: width, resizable: column.resizable ?? true }
        : { flexGrow: 100, resizable: column.resizable ?? true };

    const tooltip = column.tooltip && column.tooltip > '' ? <Typography>{t(column.tooltip) as string}</Typography> : '';

    const columnFixed = column.fixed ?? false;

    return (
        <Column {...widthParams} sortable={sortable} key={index} align="left" onResize={onResize} fixed={columnFixed}>
            <HeaderCell
                ref={headerRef}
                align={'left'}
                data-test={`grid_${gridName}_${column.name}_column_header`}
                renderSortIcon={(sortType) => {
                    return (
                        <IconMi
                            icon={'arrow-right'}
                            fontSize={'16'}
                            sx={{ transform: sortType === 'desc' ? 'rotate(90deg)' : 'rotate(-90deg)' }}
                        />
                    );
                }}
                className={`${orderBy === column.name ? 'sortable-active' : ''} test123 `}
            >
                <Tooltip title={tooltip} placement="bottom">
                    <Typography component={'span'} className={'rs-table-cell-header-title'}>
                        {getColumnLabel(column, t)}
                    </Typography>
                </Tooltip>
            </HeaderCell>
            {getCellComponent(
                column,
                t,
                gridName,
                dataKey,
                reloadGridData,
                setPopupUrl,
                setPopupConfig,
                setPopupType,
                setPopupMode,
                customCells,
                setShowLoader,
                setPopupComponent,
                setPopupComponentProps,
                setErrorMessage,
                form,
                setGridDataChanged,
                filtersValue,
                headerRef,
            )}
        </Column>
    );
};

const getComponent = (componentName: string, customCells?: { [key: string]: any }) => {
    if (customCells && customCells.hasOwnProperty(componentName)) {
        return customCells[componentName];
    }
    if (Components.hasOwnProperty(componentName)) {
        return Components[componentName];
    }

    return null;
};

const getCellComponent = (
    column: ColumnType,
    t: TFunction,
    gridName: string,
    dataKey: string,
    reloadGridData?: () => void,
    setPopupUrl?: (url: string) => void,
    setPopupConfig?: (popupConfig: PopupSettings) => void,
    setPopupType?: (type: PopupType) => void,
    setPopupMode?: (mode: PopupMode) => void,
    customCells?: { [key: string]: any },
    setShowLoader?: (value: boolean) => void,
    setPopupComponent?: (popupComponent: string) => void,
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void,
    setErrorMessage?: (message: string) => void,
    form?: FormRendererAPIType,
    setGridDataChanged?: (state: boolean) => void,
    filtersValue?: FilterValue,
    headerRef?: React.RefObject<HTMLDivElement>,
) => {
    const drawCell = (rowData?: GridDataRow, rowIndex?: number) => {
        if (rowData) {
            const columnData = rowData[column.name];
            let processedCellComponent = null;

            if (columnData === undefined || columnData === null) {
                return;
            }

            if (typeof columnData === 'object') {
                const cellComponent = getComponent(columnData.component, customCells);

                if (cellComponent) {
                    processedCellComponent = React.createElement(cellComponent, {
                        ...columnData.config,
                        data: rowData,
                        reloadGridData,
                        setPopupUrl,
                        setPopupConfig,
                        setPopupType,
                        setPopupMode,
                        t,
                        setShowLoader,
                        setPopupComponent,
                        setPopupComponentProps,
                        setErrorMessage,
                        form,
                        setGridDataChanged,
                        dataTestCell: `grid_${gridName}_${column.name}_column_${rowData[dataKey]}_cell_action`,
                        filtersValue,
                        headerRef,
                    });
                } else {
                    processedCellComponent = (
                        <Typography noWrap align={'left'} sx={{ py: 1 }}>
                            {columnData.component}
                        </Typography>
                    );
                }
            } else {
                processedCellComponent = (
                    <Typography noWrap align={'left'} sx={{ py: 1 }}>
                        {String(columnData)}
                    </Typography>
                );
            }

            return (
                <Box data-test={`grid_${gridName}_${column.name}_column_${rowData[dataKey]}_cell`} sx={{ height: 1 }}>
                    {processedCellComponent}
                </Box>
            );
        }
    };

    return <Cell align={'left'} dataKey={column.name} children={drawCell} />;
};

export const getIcon = (icon?: CellIconType, sx?: SxProps<Theme>) => {
    if (icon) {
        const extraProps = icon.type === 'upload' ? { width: 14, height: 14 } : undefined;
        return (
            <IconHandler
                {...extraProps}
                icon={{ type: icon.type, value: icon.value }}
                className={icon.class ?? undefined}
                sx={{ ...{}, fontSize: icon.size ? `${icon.size}px` : undefined, ...(sx ?? {}) }}
                color={icon.color}
            />
        );
    }

    return null;
};

export const getCellContent = (
    text?: string,
    icon?: CellIconType,
    rawHtml?: boolean,
    align?: TextAlign,
    subText?: string,
    color?: string,
    multilines?: number,
) => {
    if (rawHtml) {
        const sxConfig: any = {
            py: 1,
            color: color,
            whiteSpace: 'normal',
        };

        if (!multilines) {
            sxConfig['br'] = { display: 'none' };
        }

        return (
            <>
                <Typography align={align} sx={sxConfig}>
                    {parse(text ?? '')}
                </Typography>
                {subText && <Typography>{subText}</Typography>}
            </>
        );
    }

    return (
        <>
            <Typography noWrap align={align} sx={{ py: 1, color: color }}>
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }} component={'span'}>
                    {getIcon(icon, text ? { mr: 0.5 } : undefined)}
                    {text}
                </Box>
            </Typography>
            {subText && <Typography>{subText}</Typography>}
        </>
    );
};

export const getCellWithTooltip = (
    content: ReactJSXElement,
    tooltip: string = '',
    rawTooltipHtml: boolean = false,
    t?: TFunction,
) => {
    if (rawTooltipHtml) {
        return <LightTooltip title={<span dangerouslySetInnerHTML={{ __html: tooltip }} />}>{content}</LightTooltip>;
    }

    return <Tooltip title={t ? t(tooltip) : (tooltip as string)}>{content}</Tooltip>;
};

export default buildColumn;
