import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';

import { FormComponentBuilder } from 'components/common/form/layout';
import FormText from 'components/common/form/layout/control/FormText';
import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { getElementFieldValue } from 'components/common/form/formTools';
export interface ExternalReportTableauNameProps extends FormControlProps {}

export default function ExternalReportTableauName({
    controlProps,
    elementProps,
}: FormElementControlPropsType<ExternalReportTableauNameProps>) {
    const { t } = useBundleTranslation(['app/editor/element/extreport']);
    const defaultValue = ':workbook_name / :view_name';
    const resetToDefault = () => {
        controlProps.form.hookFormSetValue(controlProps.name, defaultValue);
    };

    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'external_report_list') {
                const a = getElementFieldValue(controlProps.form, 'external_report_list.selectedOption');
                //@ts-ignore
                if (a?.[0]?.label) {
                    //@ts-ignore
                    setSelectedValue(a[0].label);
                } else {
                    setSelectedValue('');
                }
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    const showExample = () => {
        if (selectedValue == '') {
            alert(t('select_view'));
            return;
        }
        const namePattern = controlProps.form.hookFormGetValues(controlProps.name);
        const parts = selectedValue.split('/');
        const workbook = parts.shift()?.trim();
        const view = parts.join('/').trim();
        const result = namePattern
            .replace(new RegExp(':workbook_name', 'g'), workbook)
            .replace(new RegExp(':view_name', 'g'), view);

        alert(result);
    };
    useEffect(() => {
        if (!controlProps?.form.formDidMount) {
            return;
        }
        resetToDefault();
    }, [controlProps.form.formDidMount]);

    // const v = controlProps.form.hookFormWatch('external_report_list');

    return (
        <>
            <FormText controlProps={controlProps} elementProps={elementProps} />
            <Stack spacing={2}>
                <Box sx={{ paddingTop: '8px' }}>
                    <Button
                        sx={{ marginRight: '8px' }}
                        variant={'outlined'}
                        startIcon={<IconMi icon="history" />}
                        onClick={() => resetToDefault()}
                    >
                        {t('revert_to_default')}
                    </Button>
                    <Button variant={'outlined'} onClick={() => showExample()}>
                        {t('show_example')}
                    </Button>
                </Box>
            </Stack>
        </>
    );
}

export class ExternalReportTableauNameBuilder extends FormComponentBuilder {
    prepareProps(): ExternalReportTableauNameProps {
        return this.controlProps;
    }
}
