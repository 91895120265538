import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import Jct from 'components/report-content/components/jct/Jct';
import JctEditPanel from 'components/report-content/components/jct/JctEditPanel';
import ComponentSettingsJct = ReportContentNS.ComponentSettingsJct;

export default class JctComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsJct> {
    getComponent(editPanel?: any) {
        return (
            <Jct
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
                editPanel={editPanel}
                blockId={this.block.uid}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <JctEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
