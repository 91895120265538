import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useContentWorkflowFieldsValidation } from 'form-templates/hooks/useContentWorkflow';
import { ActSubmit } from 'components/common/form/act';
import useBundleTranslation from 'i18n';

export default function ExtContent(props: IFormLayoutProps) {
    const [defaultValue] = useState(props.hookForm.data.external_report_display);
    const { t } = useBundleTranslation('app/editor/element/ext-content');
    const { workflowFieldsValidation } = useContentWorkflowFieldsValidation(props.hookForm.form, t);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                const workflowValidation = await workflowFieldsValidation();
                if (!workflowValidation) {
                    return Promise.resolve({ data: { status: 'CANCELED' } });
                }
                return super.exec(data);
            }
        },
    );

    useEffect(() => {
        if (!props.hookForm.form.formDidMount) {
            return;
        }
        const newValue = props.hookForm.form.hookFormGetValues('external_report_display');
        if (newValue != defaultValue) {
            props.hookForm.form.hookFormSetValue('external_report_display', newValue, { shouldDirty: true });
        }
    }, [props.hookForm.form.formDidMount]);

    return <FormLayoutComposer props={props} />;
}
