import { alpha, Theme } from '@mui/material';

export default {
    holder: {
        '.tree-mi__list': {
            p: 0,
            m: 0,
            listStyleType: 'none',
            position: 'relative',
        },
        '.tree-mi__list .tree-mi__list': {
            ml: 1,
            pl: 2,
            '&:before': {
                content: '""',
                width: '1px',
                position: 'absolute',
                top: 0.5,
                bottom: 0.5,
                left: 0,
                borderLeft: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            },
        },
        '.tree-mi__item': {
            p: 0,
            m: '0 0 1px 0',
        },
        '.tree-mi__drop-target': {
            '&> *:not(.tree-mi__list)': {
                color: 'primary.main',
            },
            '& > .tree-mi__list': {
                backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
                '&:before': {
                    borderLeftColor: 'primary.main',
                },
            },
        },
    },
};
