import { alpha, Theme } from '@mui/material';

export default {
    arlWrapper: { border: '1px solid', borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08) },
    arlItemsWrapper: {
        maxHeight: '315px',
        overflow: 'auto',
        '.alert-rule-list-item': {
            borderTop: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        },
        '.alert-rule-list-item:first-of-type': {
            borderTop: 0,
        },
    },
    arlControlLine: {
        textAlign: 'center',
        py: 1,
        borderTop: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
    },
};
