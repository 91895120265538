import {
    FormComponentValueSelectable,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { CheckBoxListData, FormControlCheckBoxListProps, RawCheckBoxListData } from './index';
import ControlDataWrapper from 'components/common/form/ControlDataWrapper';
import { FormComponentBuilder } from 'components/common/form/layout';
import { Params as UrlParams } from '@remix-run/router/dist/utils';

export default function CheckBoxList({ controlProps }: FormElementControlPropsType<FormControlCheckBoxListProps>) {
    const prepareControlProps = function (data: RawCheckBoxListData): FormControlCheckBoxListProps {
        let values: string | Array<FormComponentValueSelectable>;
        let isAsyncData = false;
        if (controlProps.dataUrl) {
            values = String(controlProps.dataUrl);
            isAsyncData = true;
        } else {
            values = prepareFormComponentValues(data.values).map((v) => ({
                ...v,
                selected: false,
            }));
        }

        let defaultValue: any = controlProps.value;
        if (typeof defaultValue == 'string') {
            try {
                defaultValue = JSON.parse(defaultValue);
            } catch (e) {
                defaultValue = [];
            }
        }
        if (!isAsyncData && Array.isArray(defaultValue) && defaultValue.length) {
            defaultValue.forEach((dv) => {
                //@ts-ignore
                const value = values.find((v) => v.value == dv);
                if (value) {
                    value.selected = true;
                }
            });
        }

        const filters = data?.filters == undefined ? [] : data.filters;
        const columns = data?.columns == undefined ? [] : data.columns;

        return {
            ...controlProps,
            async: false,
            controlComplexData: {
                ...data,
                values: values,
                filters: filters,
                columns: columns,
            } as CheckBoxListData,
        };
    };

    return ControlDataWrapper<FormControlCheckBoxListProps, CheckBoxListData>({
        componentKey: 'CheckBoxListControl',
        controlProps: controlProps,
        prepareControlProps: prepareControlProps,
    });
}

export class CheckBoxListBuilder extends FormComponentBuilder {
    prepareProps(): FormControlCheckBoxListProps {
        return {
            ...this.controlProps,
            async: this.elementProps.componentProps.async,
            controlComplexData: this.elementProps.componentProps.data,
            settingsUrl: this.elementProps.componentProps.settingsUrl,
            dataUrl: this.elementProps.componentProps?.dataUrl ?? false,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            height: this.elementProps.componentProps?.height ?? 0,
            maxHeightElementsCount: this.elementProps.componentProps?.maxHeightElementsCount ?? undefined,
            showCounter: this.elementProps.componentProps?.showCounter ?? true,
            showSearch: this.elementProps.componentProps?.showSearch ?? true,
            searchWidth: this.elementProps.componentProps?.searchWidth ?? undefined,
            selectAllLabel: this.elementProps.componentProps?.selectAllLabel ?? 'select_all',
            showOnlyUniqueLabels: this.elementProps.componentProps?.showOnlyUniqueLabels ?? false,
            dataPageSize: this.elementProps.componentProps?.dataPageSize ?? 100,
            selectByClickWholeRow: this.elementProps.componentProps?.selectByClickWholeRow ?? true,
            emptyDataPlaceholder: this.elementProps.componentProps?.emptyDataPlaceholder,
            searchPlaceholder: this.elementProps.componentProps?.searchPlaceholder,
            showWarnHiddenElementsByFilter: this.elementProps.componentProps?.showWarnHiddenElementsByFilter ?? false,
            isLoadingTableData: this.elementProps.componentProps?.isLoadingTableData ?? false,
        };
    }

    processFormData(formData: FormData, key: string, data: any) {
        const values = JSON.parse(data);
        if (Array.isArray(values)) {
            values.forEach((v) => {
                formData.append(key + '[]', v);
            });
        }
    }
}
