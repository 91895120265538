import React, { useContext, useEffect, useState } from 'react';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box, Button, Divider, FormControl, FormLabel, Stack, Typography } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsTextBlockFormulaField = ReportContentNS.ComponentSettingsTextBlockFormulaField;
import SimpleFormattingRulesList from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/SimpleFormattingRulesList';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { reportAPI } from 'api/report';
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;
import ContentSettings = ReportContentNS.ContentSettings;
import AdditionalContentSettingsTextBlock = ReportContentNS.AdditionalContentSettingsTextBlock;
import applyFormatting from 'components/report-content/components/text-block/helpers/applyFormatting';
import { Computation } from 'components/report-content/components/dataset/CellStyler';
import ComponentSettingsTextBlockMeasureField = ReportContentNS.ComponentSettingsTextBlockMeasureField;
import { instance } from 'api/api';
import EditPanelWarningMessage from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelWarningMessage';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { getDataQueries } from 'components/report-content/components/text-block/helpers/getDataQueries';
import { BasicAutocomplete, useBasicAutocomplete } from 'components/common/basic-autocomplite/BasicAutocomplite';

export default function FormulaForm({
    formula,
    formulasList,
    measuresList,
    onApply,
    onClose,
    title,
    settings,
    contentSettings,
}: {
    formula: ComponentSettingsTextBlockFormulaField;
    formulasList: Array<ComponentSettingsTextBlockFormulaField>;
    measuresList: Array<ComponentSettingsTextBlockMeasureField>;
    onApply: (formula: ComponentSettingsTextBlockFormulaField) => void;
    onClose: (isCancel: boolean) => void;
    title: string;
    settings: ComponentSettingsTextBlock;
    contentSettings: ContentSettings;
}) {
    const { t } = useBundleTranslation(['components/report-content']);

    const editPanelContext = useContext(EditPanelContext);
    const displayMasks = editPanelContext?.displayMasks ?? [];

    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        column_name: formula.column_name,
        expression: formula.expression,
        display_mask_id: formula.display_mask_id,
    });

    const actualName = elementProps.form.hookFormWatch('column_name');

    const handleApply = async () => {
        setWarningMessage(false);
        setIsValidating(true);
        const result = await validateFormula();
        if (result != true) {
            setWarningMessage(result);
            setIsValidating(false);
            return;
        }

        onApply({
            ...formula,
            ...elementProps.form.hookFormGetValues(),
            formattingRules: structuredClone(formattingRules),
            name: actualName,
        });
        onClose(false);
    };

    const [formattingRules, setFormattingRules] = useState(formula.formattingRules ?? []);
    const [validationResult, setValidationResult] = useState<string | false>(false);
    const [warningMessage, setWarningMessage] = useState<string | false>(false);
    const [isValidating, setIsValidating] = useState(false);

    const validateFormula = async function () {
        const actualFormula = {
            ...formula,
            ...elementProps.form.hookFormGetValues(),
            formattingRules: structuredClone(formattingRules),
            name: actualName,
        };

        if ('' == actualFormula.column_name) {
            return t('text_block.error_display_name_is_required');
        }

        let index = measuresList.findIndex((m) => m.column_name == actualFormula.column_name);
        if (index == -1) {
            let index = formulasList.findIndex((f) => f.column_name == actualFormula.column_name);
        }

        if (index != -1) {
            return t('text_block.error_display_name_is_not_unique');
        }

        if (actualFormula.expression == '') {
            return t('text_block.error_formula_is_required');
        }

        return instance
            .post('data/report/validate-formula-field/' + contentSettings.elementId, {
                field: actualFormula,
            })
            .then((response) => {
                if (response.data.status == 'OK') {
                    return true;
                } else {
                    return response.data.message;
                }
            });
    };

    const handleValidateFormula = async () => {
        setWarningMessage(false);
        setIsValidating(true);
        const result = await validateFormula();
        if (result != true) {
            setWarningMessage(result);
            setIsValidating(false);
        } else {
            const actualFormula = {
                ...formula,
                ...elementProps.form.hookFormGetValues(),
                formattingRules: structuredClone(formattingRules),
                name: actualName,
            };

            const newList = formulasList.slice();
            const index = newList.findIndex((f) => f.reference_name == actualFormula.reference_name);
            if (index == -1) {
                newList.push(actualFormula);
            } else {
                newList[index] = actualFormula;
            }

            reportAPI
                .getComponentData<AdditionalContentSettingsTextBlock>(
                    {
                        ...settings,
                        measureFields: measuresList.slice(),
                        formulaFields: newList,
                        dataQueries: getDataQueries(settings),
                    } as ComponentSettingsTextBlock,
                    { ...contentSettings, validation: 'Y' },
                    null,
                )
                .then((response) => {
                    if (response) {
                        const computation = new Computation();
                        setValidationResult(applyFormatting(response, formula, computation));
                    }
                })
                .finally(() => setIsValidating(false));
        }
    };

    const basicAutocomplite = useBasicAutocomplete(formula.expression);
    useEffect(() => {
        elementProps.form.hookFormSetValue('expression', basicAutocomplite.expression);
    }, [basicAutocomplite.expression]);
    return (
        <Stack height={1}>
            {isValidating && (
                <LoadingPlaceholder>
                    <Typography>{t('text_block.validating')}</Typography>
                </LoadingPlaceholder>
            )}
            <Box flexShrink={0}>
                <BlockEditPanelHeader title={title} onCancel={() => onClose(true)} item={actualName} />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <EditPanelWarningMessage message={warningMessage} />
                <TemplateFormComponent
                    component={'FormText'}
                    elementProps={elementProps}
                    name={'column_name'}
                    props={{ label: 'display_name' }}
                />
                <FormControl>
                    <FormLabel>{t('text_block.formula')}</FormLabel>
                    <BasicAutocomplete
                        {...basicAutocomplite}
                        options={settings.measureFields.map((v) => ({
                            value: `[${v.column_name}]`,
                            label: `[${v.column_name}]`,
                        }))}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{t('display_mask')}</FormLabel>
                    <TemplateFormComponent
                        elementProps={elementProps}
                        componentValues={[{ label: 'Default', value: '' }].concat(
                            displayMasks
                                .filter((d) => d.mask_type == 'numeric')
                                .map((d) => ({
                                    label: d.display_example,
                                    value: d.display_mask_id,
                                })),
                        )}
                        component={'FormSelect'}
                        name={'display_mask_id'}
                    />
                </FormControl>
                <Divider />
                <FormLabel>{t('text_block.formatting_for', { name: actualName })}</FormLabel>
                <SimpleFormattingRulesList
                    fieldValueType={'numeric'}
                    rules={formattingRules}
                    onUpdate={(newRules) => {
                        if (JSON.stringify(formattingRules) != JSON.stringify(newRules)) {
                            setFormattingRules(newRules);
                        }
                    }}
                />
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <Button
                        onClick={() => handleValidateFormula()}
                        variant={'light'}
                        startIcon={<IconMi icon={'check'} />}
                    >
                        {t('text_block.validate_formula')}
                    </Button>
                </Box>
                {validationResult !== false && <Box dangerouslySetInnerHTML={{ __html: validationResult }}></Box>}
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls onApply={() => handleApply()} onCancel={() => onClose(true)} isTypeSave />
            </Box>
        </Stack>
    );
}
