import React, { useMemo } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotCell from 'components/report-content/components/pivot/parts/PivotCell';
import PivotDataCell = ReportContentNS.PivotDataCell;
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import { pivotDefaultColumnSize } from 'components/report-content/components/pivot/Pivot';

export default function PivotLine({
    firstRow,
    contentSettings,
    component,
    row,
    cellStyler,
    isEven,
    actions,
    maxColumns,
}: ComponentProps<ComponentSettingsPivot> & {
    firstRow: Array<PivotDataCell>;
    row: Array<PivotDataCell>;
    cellStyler: PivotCellStyler;
    isEven: boolean;
    maxColumns: number;
}) {
    if (!component.settings) {
        return null;
    }

    const line = useMemo(() => {
        let realIndex = 0;
        const result: any = [];
        row.forEach((cell, j) => {
            while (cell.shift - realIndex >= 1) {
                const mainRowCell = firstRow[realIndex];
                // Skipped cells
                const field: ComponentSettingsPivotField = getFieldByTypeAndReference(
                    mainRowCell.type,
                    mainRowCell.reference_name,
                    realIndex,
                    component.settings,
                );
                result.push(
                    <CellWrapper
                        key={realIndex}
                        component={component}
                        contentSettings={contentSettings}
                        cell={{ ...mainRowCell, value: '' }}
                        cellStyler={cellStyler}
                        isEven={isEven}
                        actions={actions}
                        realIndex={realIndex}
                        field={field}
                    />,
                );
                realIndex++;
            }
            const field: ComponentSettingsPivotField = getFieldByTypeAndReference(
                cell.type,
                cell.reference_name,
                realIndex,
                component.settings,
            );
            result.push(
                <CellWrapper
                    key={String(cell.uid) + String(realIndex)}
                    component={component}
                    contentSettings={contentSettings}
                    cell={cell}
                    cellStyler={cellStyler}
                    isEven={isEven}
                    actions={actions}
                    realIndex={realIndex}
                    field={field}
                />,
            );
            realIndex++;
        });

        return result;
    }, [
        row,
        maxColumns,
        JSON.stringify([
            component.settings.uiFields,
            component.settings.measureFields,
            component.settings.rowFields,
            component.settings.columnFields,
            component.settings.rowTotalsSettings,
            component.settings.columnTotalsSettings,
            component.settings.zebra_striping,
        ]),
    ]);

    return <span style={{ display: 'flex', width: '100%', whiteSpace: 'nowrap' }}>{line}</span>;
}

function CellWrapper({
    component,
    cell,
    cellStyler,
    isEven,
    realIndex,
    field,
    contentSettings,
    actions,
}: ComponentProps<ComponentSettingsPivot> & {
    cell: PivotDataCell;
    cellStyler: PivotCellStyler;
    isEven: boolean;
    realIndex: number;
    field: ComponentSettingsPivotField;
}) {
    let width = 0;
    const uiField = component.settings.uiFields.find((f) => String(f.column_sequence) == String(realIndex));
    if (uiField) {
        width = uiField.columnSize ?? width;
    }
    width = width < pivotDefaultColumnSize ? pivotDefaultColumnSize : width;

    const isSticky = component.settings.rowFields.length > cell.shift;
    let left = 0;
    if (isSticky) {
        component.settings.uiFields
            .filter((f) => Number(f.column_sequence) < realIndex && Number(f.column_sequence) != -1)
            .forEach((f) => {
                const widthToAdd = f.columnSize ?? pivotDefaultColumnSize;
                left += widthToAdd < pivotDefaultColumnSize ? pivotDefaultColumnSize : widthToAdd;
            });
    }

    return useMemo(
        () => (
            <PivotCell
                width={width}
                left={left}
                component={component}
                contentSettings={contentSettings}
                cell={cell}
                cellStyler={cellStyler}
                isEven={isEven}
                actions={actions}
                cellIndex={realIndex}
                field={field}
            />
        ),
        [
            width,
            left,
            JSON.stringify([
                uiField,
                component.settings.measureFields,
                component.settings.rowFields,
                component.settings.columnFields,
                component.settings.rowTotalsSettings,
                component.settings.columnTotalsSettings,
                component.settings.zebra_striping,
            ]),
        ],
    );
}

function getUiFieldByReferenceName(referenceName: string, colIndex: number, settings: ComponentSettingsPivot) {
    var field = settings.uiFields.find(function (field) {
        return field.reference_name == referenceName;
    });
    return field;
}

function getFieldByTypeAndReference(
    type: string,
    reference: string,
    colIndex: number,
    settings: ComponentSettingsPivot,
) {
    var field = null;

    if (type == 'total') {
        //@ts-ignore
        field = settings['rowFields'][reference];
        if (!field) {
            type = 'ui';
        }
    }

    if (type == 'row_subtotal') {
        type = 'row';
    }

    if (type == 'column_subtotal') {
        type = 'column';
    }

    if (type == 'column_total' || type == 'row_total') type = 'ui';

    if (type == 'ui') {
        field = getUiFieldByReferenceName(reference, colIndex, settings);
    } else {
        //@ts-ignore
        if (typeof settings[type + 'Fields'] != 'undefined')
            //@ts-ignore
            field = settings[type + 'Fields'].find(function (field) {
                return field.reference_name == reference;
            });
    }
    return field;
}
