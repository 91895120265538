import { FilterElementProps } from '../index';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import AsyncReactSelect from 'components/common/react-select/AsyncReactSelect';
import useBundleTranslation from 'i18n';

export default function AsyncDataSelectFilter({
    name,
    id,
    value,
    onChange,
    label,
    dataUrl,
    isMulti,
    preloadOptions,
}: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);
    const handleSelectUpdate = (newValue: any) => {
        onChange(name, newValue);
    };

    useEffect(() => {
        if (value) {
            onChange(name, value);
        }
    }, []);

    return (
        <Grid wrap="nowrap" item container xs={'auto'} key={`grid-filter-${id}-${name}`}>
            <Box flexGrow="1" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {label && <StaticAddon>{t(label)}</StaticAddon>}
                <Box sx={{ width: '144px' }}>
                    <AsyncReactSelect
                        value={Array.isArray(value) ? value : [value]}
                        update={handleSelectUpdate}
                        dataUrl={dataUrl}
                        preloadOptions={preloadOptions}
                        isMulti={isMulti}
                        name={name}
                        data-test={id}
                    />
                </Box>
            </Box>
        </Grid>
    );
}
