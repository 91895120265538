import { Box, InputAdornment, TextField } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FilterElementProps } from '../index';
import { useDebounce } from 'use-debounce';

function SearchFilter({ name, id, value, onChange, setPageIndex }: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);
    const [search, setSearch] = useState('');
    const [initState, setInitState] = useState(true);
    const [debouncedSearchValue] = useDebounce(search, 300);

    const handleInputUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setInitState(false);
    };

    const handleInputClear = () => {
        setSearch('');
        onChange(name, '');
        if (setPageIndex) {
            setPageIndex(0);
        }
    };

    useEffect(() => {
        if (value) {
            setSearch(value);
        }
    }, []);

    useEffect(() => {
        if (!initState) {
            onChange(name, debouncedSearchValue);
            if (setPageIndex) {
                setPageIndex(0);
            }
        }
    }, [debouncedSearchValue]);

    return (
        <Box key={`grid-filter-${id}-${name}`}>
            <TextField
                placeholder={t('search')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                            <IconMi icon="search" fontSize="16" />
                        </InputAdornment>
                    ),
                    endAdornment:
                        search > '' ? (
                            <InputAdornment
                                onMouseDown={handleInputClear}
                                position="end"
                                sx={{ color: 'primary.main', cursor: 'pointer', '&:hover': { opacity: 0.75 } }}
                            >
                                <IconMi icon="times" fontSize="16" />
                            </InputAdornment>
                        ) : undefined,
                }}
                fullWidth
                value={search}
                onChange={handleInputUpdate}
                data-test={`grid-filter-${id}-${name}_search`}
            />
        </Box>
    );
}

export default SearchFilter;
