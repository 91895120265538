export const MuiToolbarConfig = {
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                [theme.breakpoints.up('xs')]: {
                    minHeight: theme.size.navbarHeight,
                },
            };
        },
    },
};
