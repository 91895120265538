import { RouteItemType } from 'routes';
import RedirectIndexPage from 'components/backward-compatibility-router/RedirectIndexPage';
import RedirectEditPage from 'components/backward-compatibility-router/RedirectEditPage';
import RedirectCustomPage from 'components/backward-compatibility-router/RedirectCustomPage';
import RedirectViewerPage from 'components/backward-compatibility-router/RedirectViewerPage';
import RedirectPublicPage from 'components/backward-compatibility-router/RedirectPublicPage';

export const redirectRoutes: Array<RouteItemType> = [
    {
        path: [
            '/auth/index/login',
            '/auth/index/signed-out',
            '/auth/index/change',
            '/auth/index/resetpass',
            '/auth/index/deny/type?/:denyType?/*',
        ],
        name: 'public_redirect',
        layout: 'blank',
        isPublic: true,
        props: {
            pageTitle: false,
            breadcrumbs: {
                list: [],
            },
        },
        component: RedirectPublicPage,
    },
    {
        path: [
            '/editor/:entityType/index/*', //index page with action
            '/admin/:entityType/index/*', //index page with action
            '/notification/:entityType/index/*', //index page with action
            '/editor/:entityType', // /editor/index, /editor/documenttype, /editor/datasetfilter end etc
            '/admin/:entityType', // /admin/page, /admin/user, /admin/customfield end etc
            '/notification/:entityType', // /notification/dl, /notification/ns end etc
            '/monitor/variable',
            '/home/preference/edit',
            '/home/preferencemobile/edit',
            '/home/preferencemobile',
        ],
        name: 'index_redirect',
        props: {
            pageTitle: false,
            breadcrumbs: {
                list: [],
            },
        },
        component: RedirectIndexPage,
    },
    {
        path: [
            '/:controllerType/:entityType/edit/:entityIdType/:entityId/*', //controllerType: editor | admin | notification;
            '/editor/index/index/element/:entityId/*', //element editor common
        ],
        name: 'edit_redirect',
        props: {
            pageTitle: false,
            breadcrumbs: {
                list: [],
            },
        },
        component: RedirectEditPage,
    },
    {
        path: [
            '/search/external-tool/index/id/:entityId',
            '/editor/sourceplugin/index/reference/:entityId',
            '/editor/sourcedataset/index/id/:entityId',
            '/editor/sourceconnection/index/id/:entityId',
            '/editor/extreportmass/draw/plugin/:pluginAlias',
            '/editor/extcontentmass/draw/plugin/:pluginAlias',
        ],
        name: 'custom_redirect',
        props: {
            pageTitle: false,
            breadcrumbs: {
                list: [],
            },
        },
        component: RedirectCustomPage,
    },
    {
        path: [
            '/:entityType/index/preview/segment?/:segmentValueId?/element/:entityId/*', //preview / screenshot
            '/:entityType/index/customview/element/:entityId/*', //customview / screeshot
            '/:entityType/index/index/element/:entityId/*', //default element viewer link (like: chart, report and etc)
            '/:entityType/index/index/ds/:entityId/*', // Dataset viewer: /dataset/index/index/ds/
            '/contentworkflow/*', // Content Workflow viewer: /contentworkflow/index/index/cwf/
            '/:elementIdOnly', // open viewer by element id only
        ],
        name: 'viewer_redirect',
        props: {
            pageTitle: false,
            breadcrumbs: {
                list: [],
            },
        },
        component: RedirectViewerPage,
    },
];
