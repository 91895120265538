import { useContext } from 'react';
import { DownloadManagerContext, DownloadManagerHandlerProps } from 'services/DownloadManager';
import { Stack } from '@mui/material';
import { Highcharts } from 'components/chart/Chart';

export type DownloadFormatType = 'csv' | 'csvf' | 'xlsx' | 'pdf' | 'pptx';
function DownloadLink({
    children,
    downloadOptions,
    options,
}: {
    children: any;
    downloadOptions: DownloadManagerHandlerProps;
    options?: any;
}) {
    const downloadHandler = useContext(DownloadManagerContext);
    const callDownloadManager = function () {
        if (Array.isArray(Highcharts?.charts)) {
            const chart = Highcharts?.charts.filter((c) => c != undefined)?.[0];
            if (chart) {
                const extremes = chart.xAxis[0].getExtremes();
                if (extremes?.userMin && extremes?.userMax) {
                    if (options == undefined) {
                        options = {};
                    }
                    options.range_start = extremes.userMin;
                    options.range_end = extremes.userMax;
                }
            }
        }

        downloadHandler(downloadOptions, options);
    };

    return (
        <Stack direction={'row'} alignItems={'center'} onClick={callDownloadManager}>
            {children}
        </Stack>
    );
}

export function DownloadLinkElement({
    children,
    elementId,
    segmentValueId,
    format,
    uco,
    filters,
    useNativeFilters,
}: {
    children: any;
    elementId: number;
    segmentValueId: number;
    format: string;
    uco?: number;
    filters?: string;
    useNativeFilters?: boolean;
}) {
    const props = {
        elementId: elementId,
        segmentId: segmentValueId,
        format: format,
    };

    let options: any = {};
    if (uco || filters) {
        if (uco) {
            options.uco = uco;
        }
        if (filters) {
            options.filters = filters;
            options.use_native_filters = useNativeFilters ? 'Y' : 'N';
        }
    } else {
        options = undefined;
    }

    return (
        <DownloadLink options={options} downloadOptions={props}>
            {children}
        </DownloadLink>
    );
}
