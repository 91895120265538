import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { alpha, Tooltip } from '@mui/material';
import React from 'react';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.text.opposite,
        color: theme.palette.text.primary,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid',
        borderColor: alpha(theme.palette.text.primary, 0.08),
        borderRadius: '5px',
    },
}));

export default LightTooltip;
