import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Link, Tooltip } from '@mui/material';
import ItemWallUserAnnotation from 'components/wall/parts/ItemWallUserAnnotation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons/faCircleChevronDown';
import { faCircleChevronUp } from '@fortawesome/free-solid-svg-icons/faCircleChevronUp';

export interface WallFutureAnnotationsProps {
    items: any[];
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    imageAntiCache?: string;
}

export default function PartWallWallFutureAnnotations(props: WallFutureAnnotationsProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        items,
        uid,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        imageAntiCache,
    } = props;
    const [show, setShow] = useState<boolean>(false);
    const countItems = items.length;

    return (
        <Box>
            {!show && (
                <Box
                    className="message-bar"
                    sx={{
                        textAlign: 'center',
                        py: 0.5,
                        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.06),
                    }}
                >
                    {t('future_annotations.show_pre_text', { count: countItems })}{' '}
                    <Tooltip
                        title={t('future_annotations.show_link_tooltip', { count: countItems })}
                        placement="bottom-start"
                        arrow
                    >
                        <Link
                            onClick={() => {
                                setShow(true);
                            }}
                        >
                            <Box component={FontAwesomeIcon} icon={faCircleChevronDown} sx={{ mr: 0.5 }} />
                            {t('future_annotations.show_link', { count: countItems })}
                        </Link>
                    </Tooltip>
                </Box>
            )}
            <Box sx={{ overflow: 'hidden', maxHeight: !show ? 0 : '5000px' /*ToDo: make some approach to show*/ }}>
                {items.map((item) => {
                    return (
                        <ItemWallUserAnnotation
                            data={item}
                            uid={uid}
                            key={item.user_annotation_id}
                            wallData={wallData}
                            updateWallData={updateWallData}
                            followUser={followUser}
                            insightful={insightful}
                            imageAntiCache={imageAntiCache}
                        />
                    );
                })}
            </Box>
            {show && (
                <Box
                    className="message-bar"
                    sx={{
                        textAlign: 'center',
                        py: 0.5,
                        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.06),
                    }}
                >
                    <Link
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <Box component={FontAwesomeIcon} icon={faCircleChevronUp} sx={{ mr: 0.5 }} />
                        {t('future_annotations.hide_link', { count: countItems })}
                    </Link>
                </Box>
            )}
        </Box>
    );
}
