export const MuiIconButtonConfig = {
    defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                color: theme.palette.text.primary,
                '&:hover': {
                    color: theme.palette.primary.main,
                },
                '&:focus, &.Mui-focusVisible': {
                    outline: 'none',
                },
            };
        },
        sizeMedium: {
            '& .svg-icon-mi': {
                fontSize: 16,
            },
        },
    },
};
