import { DataFetchCommandEntityType } from 'components/common/form/DataFetchCommand';
import { TFunction } from 'i18next';
import {
    IValidationResponse,
    IValidationResponseDataDependencySuccess,
    IValidationResponseSegmentSuccess,
} from 'components/common/form/data-fetch-command/validation/index';
import { ModeType } from 'components/common/form/data-fetch-command/substitution';

function getSegmentCollectionMessage(typeData: IValidationResponseSegmentSuccess, t: TFunction) {
    if (typeData.rows > 0) {
        let txt = t('collection.segment.command_generated', {
            rows: typeData.rows,
            n: typeData.inserted + typeData.updated,
        });
        if ('undefined' != typeof typeData.rejected_by_parent && typeData.rejected_by_parent > 0) {
            const n = typeData.rejected_by_parent > 30 ? 30 : typeData.rejected_by_parent;
            txt +=
                '<br/><br/>' +
                t('collection.segment.not_be_processed', { rejected: typeData.rejected_by_parent, n: n });
            if ('object' == typeof typeData.rejected_by_parent_rows) {
                let index = 0;
                for (let i in typeData.rejected_by_parent_rows) {
                    if (index++ >= n) break;
                    txt +=
                        typeData.rejected_by_parent_rows[i][0] +
                        ' - ' +
                        typeData.rejected_by_parent_rows[i][1] +
                        ('undefined' != typeof typeData.rejected_by_parent_rows[i][2]
                            ? ' - ' + typeData.rejected_by_parent_rows[i][2]
                            : '') +
                        '<br/>';
                }
            }
        }

        if ('undefined' != typeof typeData.rejected && typeData.rejected > 0) {
            const n = typeData.rejected > 30 ? 30 : typeData.rejected;
            txt +=
                '<br/><br/>' +
                t('collection.segment.below_list', { partial: typeData.rejected == n ? '' : 'partial', n: n }) +
                '<br/><br/>';
            if ('object' == typeof typeData.rejected_rows) {
                let index = 0;
                for (let i in typeData.rejected_rows) {
                    if (index++ >= n) break;
                    txt +=
                        typeData.rejected_rows[i][0] +
                        ' - ' +
                        typeData.rejected_rows[i][1] +
                        ('undefined' != typeof typeData.rejected_rows[i][2]
                            ? ' - ' + typeData.rejected_rows[i][2]
                            : '') +
                        '<br/>';
                }
            }
        }
        return txt;
    }
    return t('collection.segment.no_rows');
}

export default function getSuccessMessage<T extends IValidationResponse>(
    type: DataFetchCommandEntityType,
    data: T,
    t: TFunction,
    mode: ModeType,
) {
    let message = '';
    if (type == 'dependency') {
        //@ts-ignore
        let typeData = data as IValidationResponseDataDependencySuccess;
        message = '**' + t('record_in_total', { count: typeData.total }) + '** \n\n';
        if (typeof typeData.prev_satisfied_date_value != 'undefined') {
            message +=
                typeData.prev_satisfied_date_value > ''
                    ? t('validation.dependency.satisfies_date', {
                          value: typeData.value,
                          date: typeData.prev_satisfied_date_value,
                      })
                    : t('validation.dependency.satisfies_date_empty', { value: typeData.value });
        } else {
            message += t('validation.dependency.satisfies_number', { value: typeData.value });
        }
    } else if (type == 'segment' && mode == 'collect') {
        //@ts-ignore
        let typeData = data as IValidationResponseSegmentSuccess;
        message = getSegmentCollectionMessage(typeData, t);
    } else if (type != 'dataset' && type != 'report' && type != 'segment') {
        message =
            '**' +
            t('record_in_total', { count: data.total }) +
            '** \n\n' +
            t('this_is_the_first_record') +
            '\n' +
            '**' +
            data.message +
            '**';
    }
    return message;
}
