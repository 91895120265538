import { FormPanelButtonProps } from 'components/common/form';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import FormComponent from 'components/common/form/Form';
import { processSettingsUrl } from 'components/common/form/formTools';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { useNavigate, useParams } from 'react-router-dom';

export default function FormPanelButtonDelete({ button, formActions, isSubMenuItem }: FormPanelButtonProps<any>) {
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const label = button?.label ? t(button.label) : '';
    const [showPopup, setShowPopup] = React.useState(false);

    const urlParams: UrlParams = useParams();
    const navigate = useNavigate();

    const handleClick = async () => {
        if (button.props.type === 'form') {
            setShowPopup(true);
        } else {
            formActions.onDelete();
        }
    };

    const handleHidePopup = () => {
        setShowPopup(false);
    };

    const afterSave = (response: any) => {
        const isOk = response.data?.status === 'OK';

        if (isOk && response.data.redirectUrl) {
            navigate(response.data.redirectUrl);
        }
    };

    const buttonComponent = isSubMenuItem ? (
        <Box sx={{ color: 'error.main', width: '100%' }}>
            <ActionMenuItem
                label={label}
                desc={button.menuItemDesc ? t(button.menuItemDesc) : ''}
                icon="delete"
                onClick={handleClick}
            />
        </Box>
    ) : (
        <Tooltip title={label}>
            <IconButton sx={{ p: '6px', fontSize: 12 }} onClick={handleClick}>
                <IconMi icon="delete" fontSize="16" />
            </IconButton>
        </Tooltip>
    );

    const url = processSettingsUrl(button.props.url ?? '', Object.keys(urlParams), urlParams);
    const popupUrl = button.props.ignoreCache ? `${url}?v=${Date.now()}` : url;

    return (
        <>
            {buttonComponent}
            {showPopup && (
                <FormComponent
                    isPopup
                    popupType={button.props.popupConfig?.mode ?? 'new'}
                    pk={popupUrl}
                    uid={popupUrl}
                    settingsUrl={popupUrl}
                    onHide={handleHidePopup}
                    popupSettings={button.props.popupConfig}
                    afterSave={afterSave}
                />
            )}
        </>
    );
}
