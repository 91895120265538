import React, { useContext, useEffect, useState } from 'react';
import {
    MetricOverlaysContext,
    MetricOverlaysContextType,
} from 'components/metric/collaboration-controls/overlay-metrics/OverlayMetrics';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import useBundleTranslation from 'i18n';

export default function AddOverlayList(props: any) {
    const popupContext = useContext<MetricOverlaysContextType | null>(MetricOverlaysContext);

    const { t } = useBundleTranslation('components/metric/chart');
    const elementProps = useCustomSimplifiedForm();
    const list = elementProps.form.hookFormWatch('addOverlayListCheckBox');
    useEffect(() => {
        if (popupContext && popupContext?.onChange && list?.length > 0) {
            popupContext.onChange('overlayMetricsGrid', JSON.parse(list));
        }
    }, [list]);

    return (
        <TemplateFormComponent
            component={'CheckBoxList'}
            elementProps={elementProps}
            name={'addOverlayListCheckBox'}
            props={{
                height: 400,
                showCounter: false,
                showSearch: true,
                selectAllLabel: t('overlay_metrics'),
                urlParams: {},
                settingsUrl: props.settingsUrl,
                async: true,
                controlComplexData: {
                    values: [],
                    filters: [],
                    columns: [],
                },
            }}
        />
    );
}
