import { createSlice } from '@reduxjs/toolkit';

interface pageTitleSliceState {
    title: false | string; //'' = set default portalName if exist else empty ''; false - remove title
    instanceDataKey: string | null;
}

export const pageTitleSlice = createSlice({
    name: 'pageTitle',
    initialState: {
        title: '',
        instanceDataKey: null,
    } as pageTitleSliceState,
    reducers: {
        setPageTitle: (state, action) => {
            state.title = action.payload;
        },
        setPageTitleInstanceKey: (state, action) => {
            state.instanceDataKey = action.payload ?? null;
        },
        removePageTitle: (state) => {
            state.title = false;
            state.instanceDataKey = 'noInstanceDataKey';
        },
    },
});

export const { setPageTitle, setPageTitleInstanceKey, removePageTitle } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
