import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import { instance } from 'api/api';
import { AxiosResponse } from 'axios';
import { APIResponse } from 'tools/types';
import { FilterType } from 'components/external-reference/Filter';

export const externalReportAPI = {
    bookmark: {
        updateBookmark(
            elementId: number,
            segmentValueId: number,
            bookmark: BookmarkType,
            filtersJSON: string,
            distributionItemId?: number,
        ): Promise<AxiosResponse<APIResponse<BookmarkType>>> {
            const params: any = {
                bookmark: bookmark.id,
                name: bookmark.name,
                filters: filtersJSON,
                bookmarkType: bookmark.bookmarkType,
                bookmarkData: JSON.stringify(bookmark.bookmarkData),
            };
            if (distributionItemId) {
                params.distributionItemId = distributionItemId;
            }
            return instance.post(`data/reference/element/${elementId}/${segmentValueId}/save-bookmark`, params);
        },
        deleteBookmark(elementId: number, bookmarkId: number) {
            return instance.post(`data/reference/element/${elementId}/0/delete-bookmark`, {
                bookmark: bookmarkId,
            });
        },
        duplicateBookmark(elementId: number, bookmarkId: number): Promise<AxiosResponse<APIResponse<BookmarkType>>> {
            return instance.post(`data/reference/element/${elementId}/0/copy-bookmark`, {
                bookmark: bookmarkId,
            });
        },
        sortBookmarks(elementId: number, bookmarks: Array<BookmarkType>, distributionItemId?: number) {
            const order: any = {};
            bookmarks.forEach((b, i) => (order[b.id] = i));
            const params: any = {
                order: JSON.stringify(order),
            };
            if (distributionItemId) {
                params.item_id = distributionItemId;
            }
            return instance.post(`data/reference/element/${elementId}/0/update-bookmark-order`, params);
        },
        saveFilters(elementId: number, segmentValueId: number, filters: Array<FilterType>) {
            const activeFilters = filters.filter((f) => f.canBeCached);

            if (activeFilters.length < 1 && segmentValueId <= 0) {
                return;
            }
            return instance
                .post(`data/viewer/element/${elementId}/segment/${segmentValueId}/save-filters`, {
                    filters: JSON.stringify(activeFilters),
                    type: 'element',
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
