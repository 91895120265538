import React, { useState } from 'react';
import Lineage from 'components/lineage/Lineage';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { AppWrapper } from 'App';
import AccessDeniedPopup from 'components/common/popup/AccessDeniedPopup';
import { AccessDeniedTileInfo } from 'app/home/index.functions';
import { Popup } from 'components/common/popup/Popup';
import { Typography } from '@mui/material';
import EmbedPreview from 'app/home/tile/preview/EmbedPreview';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RatingComponentPageWrapper from 'components/rating/RatingComponentPageWrapper';

function LineageWrapper({ props }: { props: any }) {
    const [showLineage, setShowLineage] = useState(true);

    const hideLineage = () => {
        setShowLineage(false);
    };

    return <Lineage showLineage={showLineage} onHide={hideLineage} id={String(props.id)} type={String(props.type)} />;
}

function AccessDeniedPopupWrapper({ props }: { props: any }) {
    const setAccessDeniedPopupTile = (tile: AccessDeniedTileInfo | null) => {};

    const accessDeniedTile: AccessDeniedTileInfo = {
        element_id: 'string' === typeof props.element_id ? parseInt(props.element_id, 10) : props.element_id,
        segment_value_id: props.segment_value_id ?? 0,
    };

    return (
        <AccessDeniedPopup accessDeniedTile={accessDeniedTile} setAccessDeniedPopupTile={setAccessDeniedPopupTile} />
    );
}

function PopupWrapper({ props }: { props: any }) {
    const [open, setOpen] = useState(true);

    return (
        <Popup
            settings={{
                title: 'string' === typeof props.title ? props.title : 'Info',
                needTranslation: false,
                textOK: 'string' === typeof props.textOK ? props.textOK : 'OK',
                colorOk: 'string' === typeof props.colorOk ? props.colorOk : undefined,
                iconOK: 'string' === typeof props.iconOK ? props.iconOK : undefined,
                textCancel: 'string' === typeof props.textCancel ? props.textCancel : undefined,
                noCancel: 'boolean' === typeof props.noCancel ? props.noCancel : false,
                noOk: 'boolean' === typeof props.noOk ? props.noOk : false,
                noButtons: 'boolean' === typeof props.noButtons ? props.noButtons : false,
            }}
            open={open}
            onHide={() => setOpen(false)}
            onConfirm={() => {
                setOpen(false);
            }}
            maxWidth={'popupSm'}
        >
            {true === props.html && <div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
            {true !== props.html && <Typography>{props.text}</Typography>}
        </Popup>
    );
}

function RatingComponentWrapper({ props }: { props: any }) {
    const itemType = String(props.itemType) === 'page' ? 'page' : 'element';

    return <RatingComponentPageWrapper itemId={String(props.itemId)} itemType={itemType} />;
}

function SharedWrapper({ e }: { e: any }) {
    return (
        <>
            {'popup' === e.detail.component && <PopupWrapper props={e.detail.props} />}
            {'lineage' === e.detail.component && <LineageWrapper props={e.detail.props} />}
            {'preview' === e.detail.component && <EmbedPreview dataParams={e.detail.props} />}
            {'access_denied_popup' === e.detail.component && <AccessDeniedPopupWrapper props={e.detail.props} />}
            {'feedback_prompt' === e.detail.component && <RatingComponentWrapper props={e.detail.props} />}
        </>
    );
}

declare global {
    interface Window {
        MiNamespace: any;
    }
}

window.MiNamespace = {
    render: (params: any) => {
        document.dispatchEvent(
            new CustomEvent('mi-render', {
                bubbles: false,
                detail: params,
            }),
        );
    },
};

function initShared() {
    document.addEventListener('mi-render', (e: any) => {
        if (e instanceof CustomEvent && e.detail) {
            let containerId = '';
            if (e.detail.containerId && document.getElementById(e.detail.containerId))
                containerId = e.detail.containerId;
            else {
                containerId = 'miContainer_' + new Date().valueOf() + Math.floor(Math.random() * 1000);
                const container = document.createElement(e.detail.containerType ? e.detail.containerType : 'div');
                container.id = containerId;
                document.body.appendChild(container);
            }

            //@ts-ignore
            const root = createRoot(document.getElementById(containerId));
            const router = createBrowserRouter([
                {
                    path: '*',
                    element: <SharedWrapper e={e} />,
                    loader: (args) => {
                        if (
                            args &&
                            args.params &&
                            args.params['*'] &&
                            document.location.pathname != '/' + args.params['*']
                        ) {
                            document.location.href = document.location.origin + '/' + args.params['*'];
                        }

                        return args;
                    },
                },
            ]);
            root.render(
                <Provider store={store}>
                    <AppWrapper>
                        <RouterProvider router={router} />
                    </AppWrapper>
                </Provider>,
            );
        }
    });
}

export default initShared;
