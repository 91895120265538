export const MuiFormHelperText = {
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                fontStyle: 'italic',
                fontSize: '12px',
                lineHeight: theme.size.lineHeight,
            };
        },
    },
};
