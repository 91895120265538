import { getArrowImage, getIcon } from './Icons';
import { useBlendColors } from 'hooks/useDesign';
import { alpha } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const getLineageDiagramConfig = (themeObj: Theme) => {
    const styles = {
        nodes: {
            // color: {
            //     background: themeObj.palette.background.default,
            //     border: themeObj.palette.primary.main,
            //     highlight: {
            //         border: themeObj.palette.primary.main,
            //         background: useBlendColors(alpha(themeObj.palette.primary.main, 0.15)),
            //     },
            //     hover: {
            //         border: themeObj.palette.primary.main,
            //         background: useBlendColors(alpha(themeObj.palette.primary.main, 0.15)),
            //     },
            // },
            // icon: themeObj.palette.primary.main,
            font: {
                background: themeObj.palette.background.default,
                color: themeObj.palette.text.primary,
            },
        },
        edges: {
            color: useBlendColors(alpha(themeObj.palette.text.primary, 0.4)),
        },
    };

    return {
        layout: {
            improvedLayout: true,
            hierarchical: {
                direction: 'LR',
                sortMethod: 'directed',
            },
        },
        nodes: {
            image: {
                unselected: getIcon(themeObj, 'element'),
                selected: getIcon(themeObj, 'element', undefined, undefined, false, false, false, 'clicked'),
            },
            shape: 'image',
            font: styles.nodes.font,
        },
        edges: {
            arrows: {
                to: {
                    enabled: true,
                    type: 'image',
                    imageHeight: 17,
                    imageWidth: 14,
                    src: getArrowImage(styles.edges.color),
                },
            },
            chosen: false,
            color: styles.edges.color,
            arrowStrikethrough: false,
        },
        physics: {
            enabled: true,
            solver: 'hierarchicalRepulsion',
            hierarchicalRepulsion: {
                nodeDistance: 175,
                damping: 0.2,
                springLength: 75,
            },
            timestep: 0.8,
        },
        interaction: {
            dragNodes: false,
            dragView: true,
            selectConnectedEdges: false,
            zoomView: true,
            navigationButtons: true,
            keyboard: true,
            hover: true,
        },
        groups: {
            report: {
                image: {
                    unselected: getIcon(themeObj, 'report'),
                    selected: getIcon(
                        themeObj,
                        'report',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            metric: {
                image: {
                    unselected: getIcon(themeObj, 'metric'),
                    selected: getIcon(
                        themeObj,
                        'metric',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-metric': {
                image: {
                    unselected: getIcon(themeObj, 'external-metric'),
                    selected: getIcon(
                        themeObj,
                        'external-metric',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'manual-metric': {
                image: {
                    unselected: getIcon(themeObj, 'metric'),
                    selected: getIcon(
                        themeObj,
                        'metric',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-report': {
                image: {
                    unselected: getIcon(themeObj, 'external-report'),
                    selected: getIcon(
                        themeObj,
                        'external-report',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-content': {
                image: {
                    unselected: getIcon(themeObj, 'external-content'),
                    selected: getIcon(
                        themeObj,
                        'external-content',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            burst: {
                image: {
                    unselected: getIcon(themeObj, 'burst'),
                    selected: getIcon(
                        themeObj,
                        'burst',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'dataset-view': {
                image: {
                    unselected: getIcon(themeObj, 'dataset-view'),
                    selected: getIcon(
                        themeObj,
                        'dataset-view',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            dataset: {
                image: {
                    unselected: getIcon(themeObj, 'dataset'),
                    selected: getIcon(
                        themeObj,
                        'dataset',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            plugin: {
                image: {
                    unselected: getIcon(themeObj, 'plugin'),
                    selected: getIcon(
                        themeObj,
                        'plugin',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'deleted-dataset': {
                image: {
                    unselected: getIcon(themeObj, 'deleted-dataset'),
                    selected: getIcon(
                        themeObj,
                        'deleted-dataset',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            source: {
                image: {
                    unselected: getIcon(themeObj, 'sql-data-source', 'generic', 'db'),
                    selected: getIcon(
                        themeObj,
                        'sql-data-source',
                        'generic',
                        'db',
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            element: {
                image: {
                    unselected: getIcon(themeObj, 'element'),
                    selected: getIcon(
                        themeObj,
                        'element',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'multi-metric': {
                image: {
                    unselected: getIcon(themeObj, 'multi-metric'),
                    selected: getIcon(
                        themeObj,
                        'multi-metric',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'legacy-report': {
                image: {
                    unselected: getIcon(themeObj, 'legacy-report'),
                    selected: getIcon(
                        themeObj,
                        'legacy-report',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-dataset': {
                image: {
                    unselected: getIcon(themeObj, 'external-dataset'),
                    selected: getIcon(
                        themeObj,
                        'external-dataset',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-metadata': {
                image: {
                    unselected: getIcon(themeObj, 'external-metadata', 'generic', 'db'),
                    selected: getIcon(
                        themeObj,
                        'external-metadata',
                        'generic',
                        'db',
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-metadata-view': {
                image: {
                    unselected: getIcon(themeObj, 'external-metadata-view'),
                    selected: getIcon(
                        themeObj,
                        'external-metadata-view',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
            'external-source': {
                image: {
                    unselected: getIcon(themeObj, 'external-source', 'generic', 'db'),
                    selected: getIcon(
                        themeObj,
                        'external-source',
                        'generic',
                        'db',
                        undefined,
                        undefined,
                        undefined,
                        'clicked',
                    ),
                },
            },
        },
    };
};
