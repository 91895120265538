import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React from 'react';
import { FilterElementProps } from '../index';
import useBundleTranslation from 'i18n';

function CheckboxFilter({ name, id, value, onChange, label }: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);

    const handleCheckboxUpdate = (event: React.SyntheticEvent, checked: boolean) => {
        onChange(name, checked);
    };

    return (
        <Grid item xs={'auto'} key={`grid-filter-${id}-${name}`}>
            <FormControlLabel
                control={<Checkbox checked={Boolean(value)} />}
                label={t(label)}
                labelPlacement="end"
                onChange={handleCheckboxUpdate}
                sx={{ mb: 0, mr: 0 }}
                data-test={id}
            />
        </Grid>
    );
}

export default CheckboxFilter;
