import { instance } from './api';
import { APIResponse } from 'tools/types';
import { LineageData, LineageDiagramConfig, LineageDrawActionResponse } from 'components/lineage';

const BaseUrl = 'data/lineage';

export const lineageAPI = {
    async drawLineageDiagram(elementType: string, elementId: string): Promise<APIResponse<LineageDrawActionResponse>> {
        const response = await instance.get(`${BaseUrl}/draw-lineage-diagram/${elementId}/${elementType}`);

        return response.data;
    },
    async getDiagramConfig(elementType: string, elementId: string): Promise<APIResponse<LineageData>> {
        const response = await instance.get(`${BaseUrl}/diagram-config/${elementId}/${elementType}`);

        return response.data;
    },
    async getViewSql(viewId: string): Promise<any> {
        const response = await instance.get(`${BaseUrl}/draw-view-sql/${viewId}`);

        return response.data;
    },
    async saveDiagramConfig(diagramConfig: LineageDiagramConfig) {
        const requestData = {
            key: 'lineage_diagram',
            state: diagramConfig,
        };
        const response = await instance.post(`${BaseUrl}/save-state`, requestData);

        return response.data;
    },
};
