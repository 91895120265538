const numberFormat = (number: any, decimals: number, dec_point: string, thousands_sep: string) => {
    // Format a number with grouped thousands
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function (n: any, prec: any) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    // @ts-ignore
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        // @ts-ignore
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        // @ts-ignore
        s[1] = s[1] || '';
        // @ts-ignore
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    // @ts-ignore
    return s.join(dec);
};

export default function formatValue(value: any, prefix?: any, suffix?: any) {
    value = parseFloat(value);
    suffix = suffix || '';
    prefix = prefix || '';

    var origValue = value;

    if (!isNaN(value)) {
        var decimals = 0;
        if (Math.abs(value) >= 1000000) {
            value = value / 1000000;
            suffix = 'M';
        }

        if (Math.abs(value - Math.floor(value)) > 0.00001) {
            return prefix + value.toFixed(2) + suffix;
        }

        if (Math.abs(value - Math.floor(value)) < 0.00001) {
            decimals = 0;
        } else {
            if (Math.abs(origValue) > 1000000) {
                if (Math.abs(value) >= 10) {
                    decimals = 1;
                } else {
                    decimals = 2;
                }
            } else {
                var absValue = Math.abs(value);

                if (absValue < 0.0001) decimals = 5;
                else if (absValue < 0.001) decimals = 4;
                else if (absValue < 0.01) decimals = 3;
                else if (absValue < 1) decimals = 2;
                else if (absValue < 10) decimals = 1;
                else decimals = 0;
            }
        }

        var formatted = numberFormat(value, decimals, '.', ',');
        formatted = formatted.replace(/\.$/, '');

        return prefix + formatted + suffix;
    }
    return '';
}
