import { ColumnData, RulesConfig } from 'components/dataset-viewer';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ReportContentActions } from 'app/editor/report';
import { ReportContentNS } from 'components/report-content/index';
import BlockType = ReportContentNS.BlockType;
import ComponentSettings = ReportContentNS.ComponentSettings;
import DatasetField = ReportContentNS.DatasetField;
import ConditionBlock from 'components/dataset-viewer/query-builder/ConditionBlock';
import { getInitialFilterState } from 'components/dataset-viewer/DatasetViewer';

export default function ComponentFilter({
    actions,
    block,
    datasetFields,
}: {
    actions: ReportContentActions;
    block: BlockType<ComponentSettings>;
    datasetFields: Array<DatasetField>;
}) {
    const initFilterState = getInitialFilterState();
    initFilterState.group = block.component.settings.groupFilter ?? { operator: 'AND', rules: [] };

    const [lastStateFilter, setLastStateFilter] = useState(JSON.stringify(initFilterState));
    const [filter, setFilter] = useState<RulesConfig>(cloneDeep(initFilterState));
    const [isDirty, setIsDirty] = useState(false);
    const onCurrentFilterUpdate = (newFilter: RulesConfig) => {
        setFilter(newFilter);
    };

    useEffect(() => {
        setIsDirty(JSON.stringify(filter) != lastStateFilter);
    }, [JSON.stringify(filter), lastStateFilter]);

    const [fields, setFields] = useState<Array<ColumnData>>([]);
    useEffect(() => {
        setFields(
            datasetFields.map(
                (f): ColumnData => ({
                    id: f.reference_name_escaped,
                    field: f.reference_name_escaped,
                    name: f.column_name,
                    column_name: f.column_name,
                    ref_column_name: f.reference_name,
                    label: f.label ?? f.column_name,
                    value_type: f.value_type,
                    numeric_type: 'float',
                    sortable: true,
                    current: true,
                    aggregation: false,
                    derived: false,
                    key: f.reference_name_escaped + '-current',
                }),
            ),
        );
    }, [datasetFields]);

    return (
        <ConditionBlock
            compactView
            filter={filter}
            fields={fields}
            builderDirty={isDirty}
            applyChanges={() => {
                setLastStateFilter(JSON.stringify(filter));
                actions.updateBlockSettings(block.uid, {
                    ...block.component.settings,
                    groupFilter: structuredClone(filter.group),
                });
            }}
            onCurrentFilterUpdate={onCurrentFilterUpdate}
            showGroupApply
        />
    );
}
