import React from 'react';
import useBundleTranslation from 'i18n';
import Wall from 'components/wall/Wall';

export interface ExpertCommentaryWallProps {
    data: any;
    elementId: number;
    segmentValueId: number;
    userId: number;
    viewScope?: string;
    elementType: string;
    reqData?: any;
    overlayElements?: any;
    allowEdit?: boolean;
}

export default function ExpertCommentaryWall(props: ExpertCommentaryWallProps) {
    const { data, elementId, segmentValueId, userId, viewScope, elementType, reqData, overlayElements, allowEdit } =
        props;

    const { t } = useBundleTranslation(['components/wall/wall']);

    const uid = `${elementId}_${segmentValueId}_expert_note`;
    const el = `#expertCommentary_OLD`;
    const wall = null;
    const template = null;
    const viewScopeType = viewScope ?? 'expert_note';

    const prepareCollaborativeData = () => {
        let data: any = {
            user_annotation_ids: [],
            alert_event_ids: undefined,
            ann_index: {},
            notable_event_ids: [],
            event_index: {},
        };
        return data;
    };

    const parameters = {
        ...{
            uid: uid,
            view_scope: viewScopeType,
            element_id: elementId,
            segment_value_id: segmentValueId,
            user_id: userId,
            element_type: elementType,
            allow_edit: allowEdit,
            reqData: reqData ?? undefined,
            overlayElements: overlayElements ?? undefined,
            showAddNoteField: false,
            isExpertCommentaryWall: true,
            isExpertCommentaryAllowEdit: allowEdit,
            is_expert_commentary_ind: 'Y',
            checkUpdate: true, //ToDo WALL_CHECK_UPDATE && 'N' == WALL_CHECK_UPDATE ? 'N' : 'Y'
        },
        ...prepareCollaborativeData(),
    };

    //ToDo GotoAlert, GotoAnnotation, GotoEvent (add events or store actions)

    const params = props;

    //ToDo  ratingComponent.check('element', elementId);
    //Branch for integration

    return <Wall params={parameters} />;
}
