import { FormPanelButtonProps } from 'components/common/form';
import React, { useContext } from 'react';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import useBundleTranslation from 'i18n';
import { DataValidateCollectContext } from 'components/common/form/data-validate-collect/useDataValidateCollect';

export default function FormPanelButtonBuildReport(props: FormPanelButtonProps<any>) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const dataValidateCollectContext = useContext(DataValidateCollectContext);

    if (!dataValidateCollectContext) {
        return null;
    }
    return (
        <ActionMenuItem
            onClick={async () => {
                if (dataValidateCollectContext?.legacyHandleCollect?.current) {
                    try {
                        return await dataValidateCollectContext.legacyHandleCollect.current(true, true, true);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }}
            label={t('build_report')}
            desc={t('panel_btn_build_report_desc')}
            icon={'datasets'}
        />
    );
}
