import { Stack, Box, Button, Input, Tooltip, alpha } from '@mui/material';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { uploadFileAPI } from 'api/api';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { SxProps } from '@mui/system';
import { RawFormElementProps } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { useFormState } from 'react-hook-form';
import IconHandler, { IconType } from 'components/common/icon/IconHandler';
import IconMi from 'components/common/icon/IconMi';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { TOptionsBase } from 'i18next';
import { $Dictionary } from 'i18next/typescript/helpers';
import { RawTranslatedText } from 'tools/types';
import { prepareTranslatedText } from 'tools/tools';
import { useBlendColors } from 'hooks/useDesign';

export interface FormControlUploadFileProps extends FormControlProps {
    view?: 'button' | 'field';
    id?: string;
    accept?: string;
    buttonText?: string;
    inputPlaceholder?: string;
    buttonTextFilled?: string;
    buttonStartIcon?: IconType | boolean;
    buttonFilledStartIcon?: IconType | boolean;
    removeActionText?: string;
    removeActionView?: string;
    removeButtonColor?: string;
    emptyStateTooltip?: RawTranslatedText;
    multiple?: boolean;
    maxFileSizeMB?: number;
    showRemoveAction?: boolean;
    ajaxApiUrl?: string;
    ajaxButtonText?: string;
    ajaxApiRemoveUrl?: string;
    onFilesChange?: (files: FileList) => void;
    onChange: (...event: any[]) => void;
    sx?: SxProps;
    previewsList?: string[];
    previewType?: 'text' | 'image' | 'field';
    previewsPlaceholder?: string;
    hidePreview?: boolean;
    resetAfterOnChange?: boolean;
    ignoreStateField?: boolean;
    onResize?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAjaxUploadResult?: (response: any) => void;
    onAjaxRemoveResult?: (response: any) => void;
    formData?: FormData;
}

interface FormUploadFileViewProps extends FormControlUploadFileProps {
    isEmpty?: boolean;
    selectedValue: string[];
    downloadLinkValue?: string[];
}

interface FormUploadFileAjaxProps extends FormControlUploadFileProps {
    inProcess?: boolean;
    onClick?(): void;
}

type FormUploadFileErrorsType = 'MAX_SIZE' | 'NOT_ALL_FILES_UPLOADED';

function FormUploadFileButton(props: FormUploadFileViewProps) {
    const { t } = useBundleTranslation(['components/common/upload-control/upload_file']);
    const {
        buttonText = 'button_text',
        buttonStartIcon,
        buttonTextFilled,
        buttonFilledStartIcon,
        isEmpty,
        selectedValue,
        downloadLinkValue = [],
        previewType = 'text',
        hidePreview = true,
        emptyStateTooltip,
    } = props;

    const buttonIcon = buttonStartIcon === false ? false : (buttonStartIcon ?? { type: 'mi', value: 'upload' });
    const buttonIconFilled = buttonFilledStartIcon === false ? false : (buttonFilledStartIcon ?? buttonStartIcon);
    const { text: emptyTooltipText, needTranslation } = prepareTranslatedText(emptyStateTooltip);
    return (
        <Box
            display="inline-flex"
            alignItems="center"
            overflow="hidden"
            sx={{ flexGrow: previewType === 'field' ? 1 : undefined }}
        >
            {!hidePreview && previewType === 'field' && selectedValue?.length > 0 && (
                <Input
                    sx={{ pointerEvents: 'none', mr: 1, flexGrow: 1 }}
                    disabled={true}
                    value={selectedValue.join(', ')}
                />
            )}

            <Tooltip title={isEmpty ? (needTranslation === false ? emptyTooltipText : t(emptyTooltipText)) : ''}>
                {buttonIcon && !buttonTextFilled && !buttonText ? (
                    <Button
                        className="min-width--icon"
                        data-test={props.name + '-upload-button'}
                        component="span"
                        variant="light"
                        sx={{ flexShrink: 0 }}
                    >
                        <IconHandler sx={{ fontSize: 16 }} icon={buttonIcon} />
                    </Button>
                ) : (
                    <Button
                        data-test={props.name + '-upload-button'}
                        component="span"
                        variant="light"
                        startIcon={<IconHandler icon={buttonIcon} />}
                        sx={{ flexShrink: 0 }}
                    >
                        {!isEmpty && buttonTextFilled ? t(buttonTextFilled) : t(buttonText)}
                    </Button>
                )}
            </Tooltip>

            {!hidePreview && previewType === 'text' && (
                <Box sx={{ pl: 1, overflow: 'hidden' }}>
                    <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden' }}>
                        {selectedValue.map((item, index) => {
                            if (downloadLinkValue[index]) {
                                return (
                                    <Fragment key={index}>
                                        <Box
                                            component="a"
                                            href={downloadLinkValue[index]}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            download
                                        >
                                            {selectedValue}
                                        </Box>
                                        {index != selectedValue.length - 1 ? ', ' : ''}
                                    </Fragment>
                                );
                            } else {
                                return (
                                    <Fragment key={index}>
                                        <Box component="span">{selectedValue}</Box>
                                        {index != selectedValue.length - 1 ? ', ' : ''}
                                    </Fragment>
                                );
                            }
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function FormUploadFileField(props: FormUploadFileViewProps) {
    const { t } = useBundleTranslation(['components/common/upload-control/upload_file']);
    const {
        buttonText = 'field_button_text',
        buttonStartIcon = false,
        buttonTextFilled,
        isEmpty,
        selectedValue,
        inputPlaceholder = '',
        emptyStateTooltip,
    } = props;

    const { text: emptyTooltipText, needTranslation } = prepareTranslatedText(emptyStateTooltip);
    return (
        <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
            <Tooltip title={isEmpty ? (needTranslation === false ? emptyTooltipText : t(emptyTooltipText)) : ''}>
                <Box
                    flexGrow={1}
                    sx={{
                        cursor: 'pointer',
                        '&:hover .MuiInput-root': {
                            borderColor: (theme) => alpha(theme.palette.primary.main, 0.8),
                        },
                        '&:hover ~ .MuiButton-root': {
                            backgroundColor: (theme) =>
                                useBlendColors(alpha(theme.componentSettings.primaryButton.primaryColor, 0.04)),
                            borderColor: (theme) =>
                                useBlendColors(alpha(theme.componentSettings.primaryButton.primaryColor, 0.8)),
                            color: (theme) => alpha(theme.componentSettings.primaryButton.primaryColor, 0.8),
                        },
                    }}
                >
                    <Input
                        fullWidth
                        placeholder={t(inputPlaceholder)}
                        sx={{ pointerEvents: 'none' }}
                        value={selectedValue.join(', ')}
                    />
                </Box>
            </Tooltip>
            <Tooltip title={isEmpty ? (needTranslation === false ? emptyTooltipText : t(emptyTooltipText)) : ''}>
                <Button
                    sx={{ ml: 1, flexShrink: 0 }}
                    variant={'outlined'}
                    component="span"
                    startIcon={<IconHandler icon={buttonStartIcon} />}
                >
                    {!isEmpty && buttonTextFilled ? t(buttonTextFilled) : t(buttonText)}
                </Button>
            </Tooltip>
        </Stack>
    );
}

export default function UploadFile({ controlProps: props }: FormElementControlPropsType<FormControlUploadFileProps>) {
    const { t } = useBundleTranslation(['components/common/upload-control/upload_file']);
    const { isDirty } = useFormState({ control: props.form.hookFormControl });
    const {
        sx,
        name,
        id = '',
        accept,
        view = 'button',
        multiple = false,
        maxFileSizeMB = 512,
        removeActionText = 'remove_action_text',
        removeActionView = 'link',
        removeButtonColor,
        onChange = () => {},
        ajaxApiUrl = '',
        ajaxApiRemoveUrl = '',
        previewsList = [],
        onFilesChange,
        previewType,
        ignoreStateField = false,
        onResize = () => {},
        onAjaxUploadResult = () => {},
        onAjaxRemoveResult = () => {},
        formData,
    } = props;

    const showRemoveAction = props.showRemoveAction ?? view === 'button';

    const [isEmpty, setIsEmpty] = useState<boolean>();
    const [errors, setErrors] = useState<FormUploadFileErrorsType[]>([]);
    const fileFieldRef = useRef<HTMLInputElement>();
    const [listOfNames, setListOfNames] = useState<string[]>([]);
    const [listOfDownloadLinks, setListOfDownloadLinks] = useState<string[]>([]);
    const [isAjaxUploadingInProcess, setIsAjaxUploadingInProcess] = useState<boolean>(false);
    const [fieldState, setFieldState] = useState<string>();
    const FIELD_STATE_NAME = `${name}_state`;

    useEffect(() => {
        // Setup default value
        let defaultValue = props.form.hookFormGetValues(name);
        if (
            !defaultValue ||
            !Array.isArray(defaultValue) ||
            (Array.isArray(defaultValue) && defaultValue.length === 0)
        ) {
            defaultValue = previewsList;
        }
        setIsEmpty(defaultValue.length === 0);
        setListOfNames(defaultValue);

        let defaultDownloadValue = props.form.hookFormGetValues(name + '_download_link');
        if (Array.isArray(defaultDownloadValue)) {
            setListOfDownloadLinks(defaultDownloadValue);
        }
    }, []);

    const getErrorText = (errorType: FormUploadFileErrorsType): string => {
        let preparedData: object;
        switch (errorType) {
            case 'MAX_SIZE':
                preparedData = { maxFileSizeMB: maxFileSizeMB };
                break;
            default:
                preparedData = {};
        }
        return t(`error_${errorType}`, preparedData as TOptionsBase & $Dictionary<string | string>);
    };

    const getArrayOfFiles = (files: FileList | null) => {
        const filesArray = [];
        if (files) {
            for (let i = 0; i < files.length; i++) {
                filesArray.push(files[i]);
            }
        }
        return filesArray;
    };

    useEffect(() => {
        if (onFilesChange && fileFieldRef?.current?.files?.length) {
            onFilesChange(fileFieldRef.current.files);
        }
    }, [fileFieldRef.current?.files]);

    useEffect(() => {
        if (formData) {
            ajaxSubmit(undefined, formData);
        }
    }, [formData]);

    const ajaxSubmit = (event?: React.ChangeEvent<HTMLInputElement>, data?: FormData) => {
        const amountOfFiles: number = fileFieldRef?.current?.files?.length ?? 0;
        let successUploaded: number = 0;

        setIsAjaxUploadingInProcess(true);

        Promise.all(
            getArrayOfFiles(fileFieldRef?.current?.files ?? null).map(async (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return uploadFileAPI
                    .sendFile(ajaxApiUrl, data || formData)
                    .then((response: any) => {
                        if (response.data.status === 'OK') {
                            if (event) {
                                handleChange(event);
                            } else {
                                setIsEmpty(false);
                            }
                        } else if (response.data.status === 'RESIZE') {
                            if (event) {
                                onResize(event);
                            }
                        }
                        onAjaxUploadResult(response);
                    })
                    .catch((err) => {
                        // console.log('upload file error', err);
                    })
                    .finally(() => {
                        successUploaded++;
                    });
            }),
        )
            .then(() => {
                // if not all files uploaded successfully
                if (successUploaded !== amountOfFiles) {
                    setErrors(['NOT_ALL_FILES_UPLOADED']);
                }
                setIsAjaxUploadingInProcess(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors([]);
        const filesCount = event.target.files?.length ?? 0;
        setIsEmpty(!filesCount);
        setListOfDownloadLinks([]);

        onChange(event);

        if (props.resetAfterOnChange) {
            resetField();
            return;
        }

        if (!filesCount || !event.target.files) {
            setListOfNames([]);
            updateFieldState('removed');
            return;
        }

        let names = [];
        for (let i = 0; i < filesCount; i++) {
            if (event.target.files[i].size > maxFileSizeMB * 1024 * 1024) {
                setErrors(['MAX_SIZE']);
                resetField();
                break;
            }
            names.push(event.target.files[i].name);
        }
        setListOfNames(names);
        if (names.length > 0) updateFieldState('changed_' + Date.now());
    };

    const resetField = () => {
        // @ts-ignore
        fileFieldRef.current.value = '';
        setIsEmpty(true);
        setListOfNames([]);
        setListOfDownloadLinks([]);
        updateFieldState('removed');

        onChange([]);
    };

    const clearAction = () => {
        if (ajaxApiRemoveUrl) {
            uploadFileAPI
                .removeFile(ajaxApiRemoveUrl)
                .then((response: any) => {
                    resetField();
                    onAjaxRemoveResult(response);
                })
                .catch(() => {});
        } else {
            resetField();
        }
    };

    const updateFieldState = (action: string) => {
        setFieldState(action);
    };

    const elementProps = {
        data: {},
        form: props.form,
    } as RawFormElementProps;

    useEffect(() => {
        if (fieldState && !isDirty) {
            updateFieldState('initial');
        }
    }, [isDirty]);

    useEffect(() => {
        if (!ignoreStateField) {
            if (fieldState === 'initial') {
                props.form.hookFormSetValue(FIELD_STATE_NAME, fieldState, { shouldDirty: false });
            } else {
                props.form.hookFormSetValue(FIELD_STATE_NAME, fieldState, { shouldDirty: true });
            }
        }
    }, [fieldState]);

    return (
        <Box sx={sx} className="upload-file" width={previewType === 'field' || view === 'field' ? '100%' : undefined}>
            <ReactHookFormController
                elementProps={prepareFormElementProps({
                    ...elementProps,
                    component: { uid: FIELD_STATE_NAME, name: FIELD_STATE_NAME, component: 'FormHidden' },
                })}
            />
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    pointerEvents: isAjaxUploadingInProcess ? 'none' : 'auto',
                }}
            >
                <Box
                    component="label"
                    sx={{ margin: 0, flexGrow: previewType === 'field' || view === 'field' ? 1 : undefined }}
                    display={'flex'}
                >
                    <Box
                        component="input"
                        ref={fileFieldRef}
                        onChange={ajaxApiUrl ? ajaxSubmit : handleChange}
                        accept={accept}
                        multiple={multiple}
                        type="file"
                        name={name}
                        id={id}
                        sx={{
                            pointerEvents: 'none',
                            opacity: 0,
                            position: 'absolute',
                            display: 'none',
                        }}
                    />
                    {view === 'button' && (
                        <FormUploadFileButton
                            {...props}
                            isEmpty={isEmpty}
                            selectedValue={listOfNames}
                            downloadLinkValue={listOfDownloadLinks}
                        />
                    )}
                    {view === 'field' && (
                        <FormUploadFileField {...props} isEmpty={isEmpty} selectedValue={listOfNames} />
                    )}
                </Box>
                {!isEmpty && showRemoveAction && (
                    <Box flexShrink={0}>
                        {removeActionView === 'button' ? (
                            <Button
                                variant="outlined"
                                sx={{ ml: 1 }}
                                onClick={clearAction}
                                startIcon={removeActionText ? <IconMi icon="delete" /> : undefined}
                                className={'min-width--icon'}
                                color={removeButtonColor as any}
                            >
                                {!removeActionText && <IconMi icon="delete" fontSize={'16'} />}
                                {t(removeActionText)}
                            </Button>
                        ) : (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}
                                onClick={clearAction}
                                sx={{
                                    ml: 1,
                                    color: 'text.secondary',
                                    '&:hover': { color: 'primary.main', cursor: 'pointer' },
                                }}
                            >
                                <IconMi icon="delete" fontSize={'15'} />
                                <Box component="span">{t(removeActionText)}</Box>
                            </Stack>
                        )}
                    </Box>
                )}
            </Stack>
            {errors.length > 0 && (
                <Box sx={{ color: 'error.main' }}>
                    {errors.map((item, index) => {
                        return <Box key={index}>{getErrorText(item)}</Box>;
                    })}
                </Box>
            )}
        </Box>
    );
}
