import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useBlendColors } from 'hooks/useDesign';

// ToDo [Stas] Look at https://rsuitejs.com/guide/customization/
export default {
    wrapper: {
        position: 'relative',
        '.rs-table': {
            border: 0,
            paddingBottom: '10px', //reserve place for scroll
            boxSizing: 'content-box', //reserve place for scroll
        },
        '.rs-table-header-row-wrapper': {
            boxSizing: 'border-box', //Restore default value after "reserve place for scroll"
        },
        '.rs-table-body-row-wrapper .rs-table-row': {
            boxSizing: 'border-box', //Restore default value after "reserve place for scroll"
        },
        '.rs-table-cell': {
            backgroundColor: (theme: Theme) => theme.palette.background.default,
            borderBottomColor: (theme: Theme) => `${alpha(theme.palette.text.primary, 0.08)}`,
        },
        '.rs-table-row-header': {
            background: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
            borderRadius: 1,
        },
        '.rs-table-hover .rs-table-row-header:hover': {
            background: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
        },
        '.rs-table-row-header .rs-table-cell, .rs-table-row-header .rs-table-cell-group-fixed-left': {
            background: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
            borderBottomColor: 'transparent',
        },
        '.rs-table-hover .rs-table-body-row-wrapper .rs-table-row:hover': {
            background: (theme: Theme) => theme.palette.background.default,
        },
        '.rs-table-hover .rs-table-body-row-wrapper .rs-table-row:hover .rs-table-cell-group': {
            background: (theme: Theme) => theme.palette.background.default,
        },
        '.rs-table-hover .rs-table-body-row-wrapper .rs-table-row:hover .rs-table-cell': {
            background: (theme: Theme) => `${alpha(theme.palette.primary.main, 0.04)}`,
        },
        '.rs-table:not(.rs-table--draggable) .rs-table-row--selected-row .rs-table-cell--checkbox .checkbox-holder, .rs-table .rs-table-row--selected-row .rs-table-cell--sort .sort-holder':
            {
                position: 'relative',
                '&:before': {
                    content: '""',
                    width: '1px',
                    height: '100%',
                    backgroundColor: (theme: Theme) => theme.palette.primary.main,
                    position: 'absolute',
                    left: 0,
                },
            },
        '.rs-table-row--selected-row .rs-table-cell': {
            backgroundColor: (theme: Theme) => `${useBlendColors(alpha(theme.palette.primary.main, 0.08))}`,
            //need to fix case with fixed checkbox cell and scroll
        },
        '.rs-table-cell--checkbox .rs-table-cell-content, .rs-table-cell--sort .rs-table-cell-content': {
            padding: '0 !important',
            border: '0 !important',
        },
        '.rs-table-cell-group-left-shadow .rs-table-cell--checkbox .checkbox-holder': {
            justifyContent: 'center',
        },
        '.rs-table-body-row-wrapper .rs-table-cell-content': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '.rs-table-scrollbar-horizontal': {
            bottom: 0,
        },
        '.rs-table-body-row-wrapper': {
            paddingBottom: '10px', //reserve place for scroll
        },
        '.rs-table-cell-header': {
            '.rs-table-cell': {
                //px: 1,
            },
            '.rs-table-cell-content': {
                padding: '0 4px',
                display: 'flex',
                alignItems: 'center',
                borderLeft: '4px solid',
                borderRight: '4px solid',
                borderTop: '4px solid',
                borderBottom: '4px solid',
                borderColor: 'transparent',
                backgroundClip: 'padding-box',
                borderRadius: '6px',
            },
            '.rs-table-cell-header-sort-wrapper': {
                flexShrink: 0,
                display: 'inline-flex',
                color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
            },
            '.rs-table-cell-header-title': {
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '&.rs-table-cell-header-sortable:hover': {
                '.rs-table-cell-content': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                },
                '.rs-table-cell-header-title': {
                    color: (theme: Theme) => theme.palette.text.primary,
                },
            },
            '&.sortable-active .rs-table-cell-header-sort-wrapper': {
                color: (theme: Theme) => theme.palette.text.primary,
            },
        },
        //Hide sort icon for non-sortable columns
        '.rs-table-cell-header-sortable:not(.sortable-active):not(:hover) .rs-table-cell-header-sort-wrapper': {
            display: 'none !important',
        },
        '.rs-table-row-expanded': {
            backgroundColor: (theme: Theme) => theme.palette.background.default,
            color: (theme: Theme) => theme.palette.error.main,
            borderTop: 'none !important',
            borderBottom: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
            padding: '0',
        },
        '.rs-table-loader-wrapper': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.background.default, 0.9),
        },
    },
};
