import { OrderDirection, ReportContentNS } from 'components/report-content/index';
import ComponentSettingsJctField = ReportContentNS.ComponentSettingsJctField;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;

export function fieldToSortField(
    field: ComponentSettingsJctField,
    direction: OrderDirection,
): ComponentSettingsDatasetSortField {
    return {
        column_name: field.column_name,
        dir: direction,
        reference_name: field.reference_name,
        reference_name_escaped: field.reference_name_escaped,
        value_type: field.value_type,
    };
}
