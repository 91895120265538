import { ReportContentNS } from 'components/report-content/index';
import ChartComponentSettings = ReportContentNS.ChartComponentSettings;
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { getViewUrl } from 'tools/tools';
import useDrillToElements from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/useDrillToElements';
import ContentSettings = ReportContentNS.ContentSettings;
// TODO: Move styles \ create separate component
import styles from 'app/home/tile/components/info-panel/styles';
import IconMi from 'components/common/icon/IconMi';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';

export interface TooltipDrillData {
    field: string;
    value: string;
}

export default function DrillToTooltipPart({
    data,
    settings,
    contentSettings,
}: {
    data: Array<TooltipDrillData>;
    settings: ChartComponentSettings;
    contentSettings: ContentSettings;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const drillElements = useDrillToElements(contentSettings.elementId);
    const [open, setOpen] = useState(false);

    if (!settings.drillTo || settings.drillTo.length == 0 || !drillElements) {
        return null;
    }

    return (
        <Stack direction="row" sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0 }}>
            <Box width={1} sx={styles.termSection} className={open ? 'expand' : 'collapse'}>
                <Stack
                    direction="row"
                    className={'term-section__header'}
                    onClick={() => {
                        setOpen((prevState) => !prevState);
                    }}
                >
                    <Box sx={styles.iconHolder}>
                        <IconMi icon="elements-2" sx={{ fontSize: '16px', color: 'success.main' }} />
                    </Box>

                    <Box flexGrow={1}>{t('drill.drill_to')}</Box>
                    <Box sx={{ ...styles.iconHolder, ...{ mr: 0, ml: 1 } }}>
                        <IconMi icon={open ? 'chevron-down' : 'chevron-right'} sx={{ fontSize: 16 }} />
                    </Box>
                </Stack>
                <Stack
                    direction="column"
                    flexWrap={'wrap'}
                    sx={{ ml: -0.5, pl: '24px' }}
                    className={'term-section__items'}
                >
                    {settings.drillTo.map((row) => {
                        const element = drillElements.find((e) => e.value == row.elementId);
                        if (!element) {
                            return null;
                        }

                        let params = data
                            .map((d) => {
                                const alias = row.columnsMap.find((alias) => alias.field == d.field);
                                const title = alias?.alias ? alias.alias : d.field;
                                return `${title}=${d.value}`;
                            })
                            .filter((r) => r != null)
                            .join('&');

                        if (params.length) {
                            params = `?${params}`;
                        }
                        return (
                            <Box sx={{ mt: 0.5, ml: 0.5 }} key={row.elementId}>
                                <Link to={getViewUrl(element.type) + element.value + params} target={'_blank'}>
                                    {row.elementTitle ? row.elementTitle : element.label}
                                </Link>
                            </Box>
                        );
                    })}
                </Stack>
            </Box>
        </Stack>
    );
}
