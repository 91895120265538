import { lazy } from 'react';

import { RouteItemType } from 'routes';
const Page = lazy(() => import('components/common/Page'));
const Monitor = lazy(() => import('app/monitor'));
const Pipeline = lazy(() => import('app/monitor/pipeline'));

export const monitorRoutes: Array<RouteItemType> = [
    {
        path: ['/monitor'],
        name: 'monitor.name',
        component: Monitor,
        props: {
            pageTitle: { value: 'monitor.title' },
        },
    },
    /*{
        path: ['/monitor/pipeline'],
        name: 'monitor.pipeline.name',
        component: Pipeline,
    },*/
];
