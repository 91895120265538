import { Stack, Typography } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid';

import parse from 'html-react-parser';

export default function CodeHistoryCell({ text, icon, align }: CustomCellProps) {
    return (
        <Typography noWrap align={align} sx={{ py: 1 }}>
            <Stack
                direction={'row'}
                spacing={1}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    maxWidth: '100%',
                    '.change_log_ins': {
                        background: 'yellow',
                    },
                    '.change_log_del': {
                        textDecoration: 'line-through',
                        color: '#999',
                    },
                }}
            >
                <Typography textOverflow={'ellipsis'} overflow={'hidden'} className={'history-value'}>
                    {parse(text ?? '')}
                </Typography>
            </Stack>
        </Typography>
    );
}
