import { FormDataAPIType } from 'components/common/form';
import { ExternalFilterEntityType } from 'app/editor/external-filters';
import { RowValues } from 'components/common/select-map';
import useBundleTranslation from 'i18n';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import DatasetField = ReportContentNS.DatasetField;
import ComponentSettingsExternalVisualizationMapColumnFilter = ReportContentNS.ComponentSettingsExternalVisualizationMapColumnFilter;

export default function ColumnFilterMap({
    elementProps,
    datasetFields,
    vizFieldsList,
    columnFilterMap,
    onChange,
}: {
    elementProps: FormDataAPIType;
    datasetFields: Array<DatasetField>;
    vizFieldsList: Array<ExternalFilterEntityType>;
    columnFilterMap: Array<RowValues>;
    onChange: (filters: Array<ComponentSettingsExternalVisualizationMapColumnFilter>) => void;
}) {
    const { t } = useBundleTranslation(['components/report-content']);

    const handleChange = (value: Array<RowValues>) => {
        onChange(
            value
                .map((v): ComponentSettingsExternalVisualizationMapColumnFilter | null => {
                    const datasetField = datasetFields.find((f) => String(f.reference_name) == v.firstColumn);
                    const vizField = vizFieldsList.find((f) => String(f.external_filter_id) == v.secondColumn);
                    if (!datasetField || !vizField) {
                        return null;
                    }
                    return {
                        dataset_column_id: datasetField.dataset_column_id,
                        external_filter_id: vizField.external_filter_id,
                        reference_name: datasetField.reference_name,
                        column_name: datasetField.column_name,
                        dataset_id: datasetField.dataset_id,
                        dataset_filter_column_id: datasetField.dataset_filter_column_id,
                        dataset_filter_id: datasetField.dataset_filter_id,
                        value_type: datasetField.value_type,
                        display_name_label: vizField.name,
                        name: vizField.name,
                    };
                })
                .filter((v): v is ComponentSettingsExternalVisualizationMapColumnFilter => v != null),
        );
    };

    return (
        <div>
            {t('map_report_columns_to_filters')}
            <ReactHookFormController
                afterChange={(value) => {
                    handleChange(JSON.parse(value));
                }}
                elementProps={prepareFormElementProps({
                    ...elementProps,
                    component: {
                        component: 'SelectMap',
                        name: 'viz_to_data_table',
                        label: t('map_report_columns_to_filters'),
                        props: {
                            data: {
                                // hasEmptyValue: false,
                                autoAddFirstRow: true,
                                uniqueRule: 'both',
                                firstColumn: {
                                    label: t('report_column'),
                                    source: {
                                        type: 'manual',
                                        manualData: datasetFields.map((f) => ({
                                            value: String(f.reference_name),
                                            label: f.column_name,
                                        })),
                                    },
                                },
                                secondColumn: {
                                    label: t('filter'),
                                    source: {
                                        type: 'manual',
                                        manualData: vizFieldsList.map((f) => ({
                                            value: String(f.external_filter_id),
                                            label: f.name,
                                        })),
                                    },
                                },
                                actualValues: columnFilterMap,
                            },
                        },
                    },
                })}
            />
        </div>
    );
}
