import { useEffect, useState } from 'react';
import { YNValues } from 'tools/types';

export default function useOnApplyAccessMapChange(
    applyAccessMap: YNValues,
    resetData: (resetInitialData: boolean) => void,
) {
    const [prevValue, setPrevValue] = useState(applyAccessMap);
    useEffect(() => {
        if (applyAccessMap == prevValue) {
            return;
        }
        setPrevValue(applyAccessMap);
        resetData(true);
    }, [applyAccessMap]);
}
