import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';

export default {
    wrapper: {
        borderTop: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.32),
        overflow: 'hidden',
        flexShrink: 0,
        backgroundColor: 'background.default',
    },
    closeBtn: {
        color: 'primary.main',
        py: 0,
        ml: 0,
        position: 'absolute',
        right: 10,
        zIndex: 2,
        top: 0,
        '&:hover': { opacity: 0.85 },
    },
    contentHolder: {
        height: '100%',
    },
    headerWrap: {
        py: 0.75,
        px: 3,
        mt: 1.5,
        position: 'relative',
    },
    showAmountHolder: {
        flexShrink: 0,
        alignItems: 'center',
    },
    userMapRestrictionHolder: {
        alignItems: 'center',
        border: '1px solid',
        borderRadius: '3px',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        paddingRight: '8px',
        cursor: 'pointer',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    resizeHandle: {
        //style props for Resizable
        top: 0,
        width: '100%',
        height: '12px',
        zIndex: 1,
    },
    resizeHandleHolder: {
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        flexShrink: 0,
        cursor: 'ns-resize',
        backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
    },
    resizeHandleDots: {
        display: 'flex',
        color: 'primary.main',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
};
