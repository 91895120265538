import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        width: '100%',
    },
    field: {
        borderRadius: 1,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: '1px solid',
        borderRight: 0,
        borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.4),
        flexGrow: 1,
        overflow: 'hidden',
        pl: 0.75,
        pr: 1.5,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.04),
        color: 'primary.main',
        cursor: 'pointer',
        '&:hover .pathHolder': {
            opacity: 0.75,
        },
    },
    itemIcon: {
        mr: 0.75,
        fontSize: '16px',
    },
    pathHolder: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        direction: 'rtl', //... at left side
    },
    btn: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        flexShrink: 0,
    },
};
