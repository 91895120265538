import { alpha } from '@mui/material';

export const MuiTableCellConfig = {
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.16)}`,
            };
        },
    },
};
