import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import BubbleChart from 'components/report-content/components/bubble-chart/BubbleChart';
import BubbleChartEditPanel from 'components/report-content/components/bubble-chart/BubbleChartEditPanel';

export default class BubbleChartComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsBubbleChart> {
    getComponent() {
        return (
            <BubbleChart
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <BubbleChartEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
