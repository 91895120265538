import { FormPanelButtonProps } from 'components/common/form';
import React, { useContext } from 'react';
import IconMi from 'components/common/icon/IconMi';
import { Button } from '@mui/material';
import useBundleTranslation from 'i18n';
import { DataValidateCollectContext } from 'components/common/form/data-validate-collect/useDataValidateCollect';

export default function FormPanelButtonCollectData(props: FormPanelButtonProps<any>) {
    const updateTime = props.formActions.hookFormWatch('last_column_metadata_update_time');
    const isEnabled = Boolean(updateTime) && updateTime != '0000-00-00 00:00:00';
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const dataValidateCollectContext = useContext(DataValidateCollectContext);

    if (!dataValidateCollectContext) {
        return null;
    }

    return (
        <Button
            data-test={'data_fetch_command_collect_button'}
            startIcon={<IconMi icon="check-square" />}
            variant={'light'}
            color={'success'}
            sx={{ mr: 1 }}
            onClick={async () => {
                if (!isEnabled && props.formActions.hookFormGetValues('enabled_ind') != 'Y') {
                    props.formActions.hookFormSetValue('enabled_ind', 'Y', { shouldDirty: true });
                }
                if (dataValidateCollectContext?.legacyHandleCollect?.current) {
                    try {
                        return await dataValidateCollectContext.legacyHandleCollect.current(true, true, false);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }}
        >
            {t(isEnabled ? 'update_top_bar' : 'enable_view')}
        </Button>
    );
}
