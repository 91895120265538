import { components } from 'react-select';
import IconHandler from 'components/common/icon/IconHandler';
import { Tooltip } from '@mui/material';

// Default template for MultiValue, override with props.optionComponent
export const ReactSelectMultiValue = (props: any) => {
    return (
        <>
            <components.MultiValue {...props}>
                <>
                    {<IconHandler icon={props.data.props?.icon} />}
                    {props.data.props?.tooltip ? (
                        <Tooltip title={props.data.props?.tooltip}>{props.children}</Tooltip>
                    ) : (
                        props.children
                    )}
                </>
            </components.MultiValue>
        </>
    );
};
