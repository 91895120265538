import { decomposeColor, recomposeColor } from '@mui/system/colorManipulator';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const useBlendColors = (color: any, backdropColor?: any) => {
    const theme = useTheme();
    return getBlendColors(theme, color, backdropColor);
};

//Simple alpha compositing: Based on formulas from (w3.org)
//https://www.w3.org/TR/compositing-1/#simplealphacompositing
export function getBlendColors(theme: Theme, color: any, backdropColor?: any) {
    const mainColor = decomposeColor(color).values;
    const alphaColor = mainColor[3] ?? 1;

    const bgColor = backdropColor
        ? decomposeColor(backdropColor).values
        : decomposeColor(theme.palette.background.default).values;
    const alphaBg = bgColor[3] ?? 1;

    const alpha = alphaColor + alphaBg * (1 - alphaColor);
    const r = mainColor[0] * alphaColor + bgColor[0] * alphaBg * (1 - alphaColor);
    const g = mainColor[1] * alphaColor + bgColor[1] * alphaBg * (1 - alphaColor);
    const b = mainColor[2] * alphaColor + bgColor[2] * alphaBg * (1 - alphaColor);

    return recomposeColor({
        type: 'rgba',
        values: [r, g, b, alpha],
    });
}
