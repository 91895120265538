import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        py: '5px',
        px: 3,
        backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
        color: (theme: Theme) => theme.palette.primary.main,
        flexShrink: 0,
    },
};
