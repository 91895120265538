import { Typography } from '@mui/material';
import { FormElementProps } from 'components/common/form';
import useBundleTranslation from 'i18n';
import { useFormState } from 'react-hook-form';
import IconMi from 'components/common/icon/IconMi';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function FormStaticLabel({ elementProps }: { elementProps: FormElementProps }) {
    const { t } = useBundleTranslation([elementProps.translationNS ?? 'main']);
    const { dirtyFields } = useFormState({ control: elementProps.form.hookFormControl });

    const isSection = elementProps.componentProps?.isSectionLabel;
    const name = elementProps.componentProps?.showNotesOnChange;
    const align = elementProps.componentProps?.align;
    const color = elementProps.componentProps?.color;
    const label = elementProps.componentProps.translate
        ? t(elementProps.componentProps.label)
        : elementProps.componentProps.label;
    const notes = elementProps.componentProps.translate
        ? t(elementProps.componentProps.notes)
        : elementProps.componentProps.notes;
    const showNotes = name ? notes && dirtyFields[name] : !!notes;
    const removeHeaderIndent = elementProps.componentProps?.removeHeaderIndent;
    const icon = elementProps.componentProps?.icon;

    return (
        <>
            {isSection ? (
                <Typography variant="heading" sx={{ pt: !removeHeaderIndent ? 2 : undefined }}>
                    {icon && <IconMi className={'heading__icon'} icon={icon} />}
                    {label}
                </Typography>
            ) : (
                <Typography
                    component="span"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(label) }}
                    className={'form-static-label'}
                    variant={'body1'}
                    align={align}
                    sx={{ color, '& p': { m: 0 } }}
                ></Typography>
            )}
            {showNotes && (
                <Typography className="form-static-label-notes" variant={'body1'} align={align} sx={{ color }}>
                    {notes}
                </Typography>
            )}
        </>
    );
}
