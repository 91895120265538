export const MuiToggleButtonGroupConfig = {
    defaultProps: {
        size: 'medium',
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                alignSelf: 'baseline',
                whiteSpace: 'nowrap',
            };
        },
        groupedHorizontal: ({ theme }: { theme: any }) => {
            return {
                width: '100%',
            };
        },
    },
};
