import { ElementType } from 'components/element-viewer/index';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';

export default function EditButton({ element }: { element: ElementType }) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);

    const navigate = useNavigate();
    let url = '';
    switch (element.row.type) {
        case 'metric':
        case 'multi-metric chart':
            url = 'metric';
            break;
        case 'external report':
            url = 'extreport';
            break;
        case 'internal report':
            url = 'report';
            break;
        case 'other external content':
            url = 'ext-content';
    }

    const getElementTypeLabel = () => {
        let elTypeTranslation = '';
        switch (element.row.type) {
            case 'metric':
                elTypeTranslation = t('metric');
                break;
            case 'multi-metric chart':
                elTypeTranslation = t('multi_metric');
                break;
            case 'internal report':
                elTypeTranslation = t('internal_report');
                break;
            case 'external report':
                elTypeTranslation = t('external_report');
                break;
            case 'other external content':
                elTypeTranslation = t('external_content');
                break;
            default:
                elTypeTranslation = element.row.type;
                break;
        }
        return t('edit_btn_tooltip', {
            elementType: elTypeTranslation,
        });
    };

    return (
        <span data-test={'top-edit-icon'}>
            <Tooltip title={getElementTypeLabel()}>
                <IconButton
                    sx={{ p: '6px' }}
                    onClick={() => {
                        navigate(`/editor/element/${url}/${element.row.elementId}`);
                    }}
                >
                    <IconMi icon="gear" fontSize="16" />
                </IconButton>
            </Tooltip>
        </span>
    );
}
