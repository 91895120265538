import { getElementFieldValue } from 'components/common/form/formTools';
import { useEffect, useState } from 'react';
import { getDFCommandLabel, HintComponentProps, useMethod } from 'components/common/form/data-fetch-command/hint/index';
import { Box } from '@mui/material';
import styles from '../DataFetchCommandHint.styles';

export default function CalendarHint({ controlProps, t }: HintComponentProps) {
    const segment = controlProps.form.hookFormWatch('scope_limited_by_segment_id');

    const getSegmentAttr = (attr: string) => {
        return getElementFieldValue(controlProps.form, `scope_limited_by_segment_id.${attr}`);
    };

    const [hintText, setHintText] = useState('');
    const method = useMethod(controlProps.form);

    useEffect(() => {
        if (method != 'sql' && method != 'plugin') {
            setHintText('');
            return;
        }

        const segmentType = getSegmentAttr('segment_type');
        const isBulk = controlProps.form.hookFormGetValues('bulk_mode_ind') == 'Y';
        let segmentText = '';
        if (segmentType == 'compound') {
            const p_segment_id = getSegmentAttr('primary_segment_id');
            const s_segment_id = getSegmentAttr('secondary_segment_id');
            segmentText +=
                Number(p_segment_id) > 0 ? getSegmentAttr('primary_label') + ' value' : t('main_hint.primary');
            segmentText += ', ';
            segmentText +=
                Number(s_segment_id) > 0 ? getSegmentAttr('secondary_label') + ' value' : t('main_hint.secondary');
        } else if (isBulk) {
            segmentText += (getSegmentAttr('bind_param') || getSegmentAttr('$option.label')) + ' value';
        }

        if (segmentText > '') {
            segmentText += ', ';
        }

        const isPoint = controlProps.form.hookFormGetValues('event_interval') == 'point';

        const html = [];
        const label = getDFCommandLabel(method, controlProps.form, t);
        if (isPoint) {
            html.push(t('main_hint.calendar.is_point', { label: label, segmentText: segmentText }));
        } else {
            html.push(t('main_hint.calendar.is_range', { label: label, segmentText: segmentText }));
        }

        const bindParam = getSegmentAttr('bind_param');
        //@ts-ignore
        if (segment > 0 && bindParam > '' && !isBulk) {
            html.push(t('main_hint.calendar.mast_include', { label: label, bindParam: bindParam }));
        }

        html.push(t('main_hint.calendar.may_include', { label: label, segmentText: segmentText }));
        html.push(t('main_hint.calendar.datetime'));
        html.push(t('main_hint.calendar.key_event'));

        setHintText(html.join(''));
    }, [segment, method, controlProps.form.formDidMount]);

    if (!hintText) return null;

    return <Box sx={styles.hintWrapper} dangerouslySetInnerHTML={{ __html: hintText }} />;
}
