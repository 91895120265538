import { FormDataAPIType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import React from 'react';
import { ColorPickerIconType } from 'components/common/color-picker/ColorPicker';
import { prepareFormElementProps } from 'components/common/form/formTools';

export default function EditPanelColorPicker({
    elementProps,
    name,
    icon,
    view,
}: {
    elementProps: FormDataAPIType;
    name: string;
    icon?: ColorPickerIconType;
    view?: string;
}) {
    return (
        <ReactHookFormController
            elementProps={prepareFormElementProps({
                ...elementProps,
                component: {
                    component: 'ColorPicker',
                    props: {
                        canReset: true,
                        view: view ?? 'icon-button',
                        icon: icon,
                    },
                    name: name,
                },
            })}
        />
    );
}
