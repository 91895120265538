import { instance } from 'api/api';
import { APIResponse, YNValues } from 'tools/types';

export interface ExternalReportBuildUrlResponse {
    file_name: string;
    has_tail: 'N';
    status: YNValues;
    uri: string;
}

export interface ExternalReportTypeResponse {
    allow_notification_subscriptions_ind: YNValues;
    authentication_method: string;
    auto_generate_url_ind: YNValues;
    calc_iframe_height_on_image_aspect_ratio_ind: YNValues;
    can_be_created_from_drill_down_ind: YNValues;
    can_be_ext_vis_ind: YNValues;
    compact_mode_ind: YNValues;
    created_by_id: null;
    crop_image_ind: YNValues;
    default_icon: string;
    display_action_bar_ind: YNValues;
    display_personal_bookmarks_ind: YNValues;
    display_bi_link_in_mobile_ind: YNValues;
    display_filter_pane_ind: YNValues;
    display_in_mobile_ind: YNValues;
    display_share_link_ind: YNValues;
    display_tabs_ind: YNValues;
    display_tabs_pane_ind: YNValues;
    download_image_as_pdf_ind: YNValues;
    download_image_as_ppt_ind: YNValues;
    drill_through_link_display_ind: YNValues;
    drill_through_link_name: string;
    drill_through_link_override_ind: YNValues;
    dynamic_parameter1_field_label: string;
    dynamic_parameter1_name: string;
    enable_click_in_mobile_ind: YNValues;
    external_report_type_id: 3;
    external_url_params_type: string;
    generic_icon: string;
    icon_id: null;
    icon_url: null;
    image_source_plugin_internal_name: string;
    intermediate_page_id: null;
    plugin_version: null;
    preverify_user_access_ind: YNValues;
    remove_preview_link_ind: YNValues;
    report_url_download_ind: YNValues;
    report_url_download_label: null;
    report_url_external_page_append: string;
    report_url_field_label: string;
    report_url_viewer_append: string;
    reporting_tool_name: string;
    segment_bind_pattern: null;
    tabs_pane_position: string;
}

export interface BuildUrlRequestDataType {
    profile: number;
    reference: number;
    template: number;
}

export const editorExternalReportAPI = {
    async buildUrl(url: string, data: BuildUrlRequestDataType): Promise<ExternalReportBuildUrlResponse | false> {
        if (data.profile == 0 || data.reference == 0 || data.template == 0) {
            return false;
        }

        const response = await instance.post<ExternalReportBuildUrlResponse>(url, data);
        return response.data;
    },

    async getReportTypeData(typeId: string): Promise<ExternalReportTypeResponse | false> {
        if (!typeId) {
            return false;
        }

        const response = await instance.get<APIResponse<ExternalReportTypeResponse>>(
            `/data/editor/report-type/${typeId}/data`,
        );
        return response.data.data;
    },

    async buildViewerUrl(elementId: number, segmentValueId?: number): Promise<string> {
        const params: any = {
            element: elementId,
        };
        if (segmentValueId) {
            params.segment = segmentValueId;
        }
        const response = await instance.post<APIResponse<any>>(`/data/home/index/get-external-url`, params);
        return response.data.data.url;
    },
};
