import { GridDataRow } from 'components/common/grid';
import React from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';

function AggregationLabel({ row, selected }: { row: GridDataRow; selected: boolean }) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const aggregation = row.aggregation ? String(row.aggregation) : '';
    const label = String(row['name']);

    return (
        <Stack direction={'row'} spacing={0.75}>
            {selected && aggregation && aggregation > '' && (
                <>
                    <Typography noWrap sx={{ py: 0.75 }} component={'span'}>
                        <Chip label={aggregation} variant="outlined" />
                    </Typography>
                    <Typography noWrap sx={{ py: 1 }}>
                        {t('fields_selector.of')}
                    </Typography>
                </>
            )}
            <Typography noWrap sx={{ py: 1 }}>
                {label}
            </Typography>
        </Stack>
    );
}

export default AggregationLabel;
