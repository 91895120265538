import { Box, Button, Divider, FormLabel, IconButton, Input, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import { ReportContentNS } from 'components/report-content';
import DrillTo = ReportContentNS.DrillTo;
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import React, { useMemo, useState } from 'react';
import ContentSettings = ReportContentNS.ContentSettings;
import useDrillToElements from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/useDrillToElements';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import DrillToField = ReportContentNS.DrillToField;
import IconMi from 'components/common/icon/IconMi';
import { addToStateArray, removeFromStateArray, replaceInStateArray } from 'tools/tools';
import ReactSelect from 'components/common/react-select/ReactSelect';

export default function DrillToForm({
    drillTo,
    drillToList,
    onApply,
    onCancel,
    contentSettings,
    allowedFields,
}: {
    drillTo: DrillTo; // Selected for edit DrillTo
    drillToList: Array<DrillTo>; // All existing DrillToElements
    onApply: (drillTo: DrillTo) => void;
    onCancel: () => void;
    contentSettings: ContentSettings;
    allowedFields: Array<DrillToField>;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const drillElements = useDrillToElements(contentSettings.elementId);
    // Alias Fields
    const aliasFieldsList = allowedFields.map((f) => f.reference_name);
    const [alias, setAlias] = useState(drillTo.columnsMap.slice().filter((f) => aliasFieldsList.includes(f.field)));
    const aliasFieldsUsed = alias.map((f) => f.field);
    const aliasFieldsLeft = aliasFieldsList.filter((f) => !aliasFieldsUsed.includes(f));

    const allowedDrillElements = useMemo(() => {
        if (!drillElements) {
            return [];
        }
        const used = drillToList.map((row) => row.elementId).filter((id) => id != drillTo.elementId);
        return drillElements.filter((row) => !used.includes(row.value));
    }, [drillElements, drillToList]);

    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        drillTo: String(drillTo.elementId),
        elementTitle: drillTo.elementTitle,
    });

    const actualDrillToElement = Number(elementProps.form.hookFormWatch('drillTo'));
    const title = useMemo(() => {
        if (!allowedDrillElements) {
            return '';
        }
        const row = allowedDrillElements.find((row) => row.value == actualDrillToElement);
        return row ? row.label : '';
    }, [allowedDrillElements, actualDrillToElement]);

    const handleAddAlias = () => {
        addToStateArray(setAlias, {
            field: aliasFieldsLeft[0],
            alias: '',
        });
    };

    if (!allowedDrillElements) {
        return null;
    }

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('settings')}
                    onCancel={() => onCancel()}
                    item={t('drill.drill_to') + `: ${title}`}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <FormLabel>{t('drill.drill_to')}</FormLabel>
                    <ReactHookFormController
                        componentValues={allowedDrillElements.map((row) => ({
                            label: row.label,
                            value: String(row.value),
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'drillTo',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormText',
                                    name: 'elementTitle',
                                    label: t('override_display_name'),
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Divider />
                <Box>
                    <FormLabel>{t('drill.column_aliases')}</FormLabel>
                    {alias.map((a, index) => {
                        const options = [a.field].concat(aliasFieldsLeft);
                        return (
                            <Stack key={index} direction={'row'} alignItems={'center'} spacing={1}>
                                <Box sx={{ width: '350px', flexShrink: 0 }}>
                                    <ReactSelect
                                        data={options.map((opt) => {
                                            const field = allowedFields.find((f) => f.reference_name == opt);
                                            return {
                                                value: field?.reference_name ?? '',
                                                label: field?.column_name ?? '',
                                            };
                                        })}
                                        value={a.field}
                                        update={(value) => {
                                            const current = alias.find((el) => el.field == a.field);
                                            if (!current) {
                                                return;
                                            }
                                            replaceInStateArray(setAlias, (el) => el.field == a.field, {
                                                field: value,
                                                alias: current.alias,
                                            });
                                        }}
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Input
                                        onChange={(event) =>
                                            replaceInStateArray(setAlias, (el) => el.field == a.field, {
                                                field: a.field,
                                                alias: event.target.value,
                                            })
                                        }
                                        value={a.alias}
                                    />
                                </Box>
                                <Box sx={{ flexShrink: 0 }}>
                                    <IconButton
                                        onClick={() => removeFromStateArray(setAlias, (el) => el.field == a.field)}
                                    >
                                        <IconMi icon="times" fontSize="16" />
                                    </IconButton>
                                </Box>
                            </Stack>
                        );
                    })}
                    {aliasFieldsLeft.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Button onClick={handleAddAlias} startIcon={<IconMi icon={'new'} />} variant="light">
                                {t('drill.alias')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        onApply({
                            elementId: Number(elementProps.form.hookFormGetValues('drillTo')),
                            elementTitle: elementProps.form.hookFormGetValues('elementTitle'),
                            columnsMap: alias.slice(),
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
