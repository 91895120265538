import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Chart from 'components/chart/Chart';
import { merge } from 'ts-deepmerge';
import useBundleTranslation from 'i18n';
import { Chart as HChart } from 'highcharts';
import { alpha } from '@mui/material/styles';
import { prepareFormElementProps } from 'components/common/form/formTools';
import FormStaticLabel from 'components/common/form/layout/static/FormStaticLabel';

function generatePoint(prevPoint: number) {
    const rand = Math.random() * 100;
    let s = 0;
    if (rand < 5) {
        s++;
    }
    if (rand < 10) {
        s++;
    }
    if (rand < 25) {
        s++;
    }
    if (rand < 50) {
        s++;
    }
    return prevPoint + (Math.random() * 100 > 50 ? -s : s);
}

interface ChartSeriesData {
    averageData: Array<Point>;
    seriesData: Array<Array<Point>>;
    middleData: Array<Point>;
    movingAverageY: number;
}

interface Point {
    x: number;
    y: number;
}

export default function ThemeChartExample({ hookForm }: { hookForm: FormDataAPIType }) {
    const pointsAmount = 40;
    const firstMiddleValue = 10;
    const ySeriesStep = 10;

    const maxDate = new Date();
    maxDate.setHours(0);
    maxDate.setMinutes(0);
    maxDate.setSeconds(0);
    const minDate = new Date();
    minDate.setDate(new Date().getDate() - pointsAmount);
    minDate.setHours(0);
    minDate.setMinutes(0);
    minDate.setSeconds(0);

    const options = {
        chart: {
            type: 'line',
            style: {
                fontFamily: 'Arial, helvetica, sans-serif',
            },
            height: 600,
            zooming: {
                mouseWheel: {
                    enabled: false,
                },
            },
        },
        plotOptions: {
            area: {
                fillOpacity: 0.2,
            },
            series: {
                borderRadiusTopLeft: 4,
                borderRadiusTopRight: 4,
            },
            column: {
                animation: false,
            },
        },
        legend: {
            enabled: true,
            events: {
                itemClick: () => false,
            },
            labelFormatter: function (): string {
                //@ts-ignore
                return t(this.name);
            },
        },
        rangeSelector: { enabled: false },
        credits: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: true },
        exporting: { enabled: false },
        tooltip: { enabled: false },
        title: { text: '' },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%b %d, %Y}',
            },
            max: Math.floor(maxDate.getTime()),
            min: Math.floor(minDate.getTime()),
        },
        yAxis: {
            title: false,
            offset: 0,
            opposite: false,
            min: 0,
            labels: {
                //@ts-ignore
                formatter: function () {
                    //@ts-ignore
                    const v = this.value * 10;
                    return '$' + (v + (v == 0 ? '' : ',000'));
                },
            },
        },
    };

    const { t } = useBundleTranslation(['app/editor/brand-theme', 'components/metric/chart']);
    const [chart, setChart] = useState<HChart>();
    const [view, setView] = useState<'standard' | 'stoplight' | 'statistical'>('standard');
    const movingAverageColor: string = hookForm.form.hookFormWatch('brand_theme_chart_moving_average_color');
    const maximumColor: string = hookForm.form.hookFormWatch('brand_theme_chart_max_min_ever_color');
    const stoplightGoodColor: string = hookForm.form.hookFormWatch('brand_theme_chart_stoplight_good_color');
    const stoplightBadColor: string = hookForm.form.hookFormWatch('brand_theme_chart_stoplight_bad_color');
    const statisticalColor: string = hookForm.form.hookFormWatch('brand_theme_chart_standard_deviation_color');
    const colorsGridData: Array<any> = hookForm.form.hookFormWatch('brandThemeChartingColorGrid');
    const getMaximumPlotLine = (seriesList: ChartSeriesData) => {
        let max = 0;
        const setMax = (p: Point) => (max = p.y > max ? p.y : max);
        if (seriesList.seriesData.length) {
            const s = seriesList.seriesData[seriesList.seriesData.length - 1];
            s.forEach(setMax);
        }
        seriesList.averageData.forEach(setMax);
        return {
            id: 'chart_label_maximum_ever',
            value: max,
            width: 1,
            color: maximumColor,
            dashStyle: 'Dash',
            zIndex: 1,
            label: {
                text: t('chart_label_maximum_ever'),
                y: -5,
                x: -1,
                style: {
                    color: maximumColor,
                },
            },
        };
    };

    const [chartDataChanged, setChartDataChanged] = useState(0);
    const chartData: ChartSeriesData = useMemo(() => {
        let linesN = colorsGridData?.length ?? 0;
        const middleData: Array<Point> = [];
        const seriesData: Array<Array<Point>> = [];
        const averageData: Array<Point> = [];
        const getX = (i: number) => {
            const xDate = new Date();
            xDate.setDate(new Date().getDate() - i);
            xDate.setHours(0);
            xDate.setMinutes(0);
            xDate.setSeconds(0);
            return Math.floor(xDate.getTime());
        };

        // Generate Moving Average Series and Middle Data Points
        const fY = Math.floor(firstMiddleValue * 0.5) + ySeriesStep * Math.floor(linesN / 2);
        for (let i = 0; i < pointsAmount; i++) {
            const y = generatePoint(fY);
            middleData.push({
                x: getX(i),
                y: y,
            });
            averageData.push({
                x: getX(i),
                y: y,
            });
        }
        middleData.reverse();
        averageData.reverse();

        // Generate regular Line Series
        for (let l = 0; l < linesN; l++) {
            seriesData[l] = [];
            for (let i = 0; i < pointsAmount; i++) {
                const y = generatePoint(firstMiddleValue + ySeriesStep * l);
                middleData[i].y += y;
                seriesData[l].push({
                    x: getX(i),
                    y: y,
                });
            }
            seriesData[l].reverse();
        }

        const middleDataAvr = middleData.map((d) => ({ x: d.x, y: Math.floor(d.y / (linesN + 1)) }));
        setChartDataChanged((prevState) => prevState + 1);
        return { averageData: averageData, seriesData: seriesData, middleData: middleDataAvr, movingAverageY: fY };
    }, [JSON.stringify(colorsGridData)]);

    const chartOptions: any = useMemo(() => {
        const series: Array<any> = [];
        // Add Lines
        chartData.seriesData.forEach((s, i) => {
            series.push({
                name: 'Line ' + (1 + +i),
                type: 'line',
                data: s,
                color: colorsGridData[i].line_color.config.color,
                fillColor: undefined,
                lineWidth: 2,
                showInLegend: true,
                visible: true,
            });
        });

        //Add PlotLine (Maximum Ever)
        const plotLine = getMaximumPlotLine(chartData);
        //@ts-ignore
        options.yAxis.plotLines = [plotLine];

        // Add Moving Average
        series.push({
            type: 'line',
            name: 'brand_theme_chart_moving_average_color',
            data: chartData.averageData,
            color: movingAverageColor,
            fillColor: undefined,
            enableMouseTracking: true,
            lineWidth: 3,
            showInLegend: true,
            visible: true,
        });

        // Add Red StopLight
        series.push({
            name: 'brand_theme_chart_stoplight_bad_color',
            type: 'area',
            threshold: null,
            data: chartData.middleData,
            enableMouseTracking: true,
            color: stoplightBadColor,
            fillColor: undefined,
            zIndex: -1,
            showInLegend: view == 'stoplight',
            visible: view == 'stoplight',
            lineWidth: 0,
        });
        // Add Green StopLight
        series.push({
            name: 'brand_theme_chart_stoplight_good_color',
            type: 'area',
            threshold: plotLine.value,
            data: chartData.middleData,
            enableMouseTracking: false,
            color: stoplightGoodColor,
            fillColor: undefined,
            zIndex: -1,
            showInLegend: view == 'stoplight',
            visible: view == 'stoplight',
            lineWidth: 0,
        });
        // Add Statistical

        series.push({
            name: 'brand_theme_chart_standard_deviation_color',
            color: statisticalColor,
            fillColor: alpha(statisticalColor, 0.4),
            type: 'arearange',
            enableMouseTracking: false,
            zIndex: -1,
            data: chartData.middleData.map((p) => {
                return {
                    x: p.x,
                    low: chartData.movingAverageY - Math.abs(generatePoint(0)) - 2,
                    high: chartData.movingAverageY + Math.abs(generatePoint(0)) + 2,
                };
            }),
            showInLegend: view == 'statistical',
            visible: view == 'statistical',
            lineWidth: 1,
        });

        return merge(options, {
            series: series,
        });
    }, [chartDataChanged]);

    useEffect(() => {
        const index = chart?.series.findIndex((s) => s.name == 'brand_theme_chart_moving_average_color') ?? -1;
        if (index != -1) {
            //@ts-ignore
            chart.series[index].update({ color: movingAverageColor });
        }
    }, [movingAverageColor]);
    useEffect(() => {
        const index = chart?.series.findIndex((s) => s.name == 'brand_theme_chart_stoplight_bad_color') ?? -1;
        if (index != -1) {
            //@ts-ignore
            chart.series[index].update({ color: stoplightBadColor });
        }
    }, [stoplightBadColor]);
    useEffect(() => {
        const index = chart?.series.findIndex((s) => s.name == 'brand_theme_chart_stoplight_good_color') ?? -1;
        if (index != -1) {
            //@ts-ignore
            chart.series[index].update({ color: stoplightGoodColor });
        }
    }, [stoplightGoodColor]);
    useEffect(() => {
        const index = chart?.series.findIndex((s) => s.name == 'brand_theme_chart_standard_deviation_color') ?? -1;
        if (index != -1) {
            //@ts-ignore
            chart.series[index].update({ color: statisticalColor, fillColor: alpha(statisticalColor, 0.4) });
        }
    }, [statisticalColor]);
    useEffect(() => {
        if (!chart) {
            return;
        }
        chart.yAxis[0].removePlotLine('chart_label_maximum_ever');
        const plotLine = getMaximumPlotLine(chartData);
        //@ts-ignore
        chart.yAxis[0].addPlotLine(plotLine);
        const index = chart.series.findIndex((s) => s.name == 'brand_theme_chart_stoplight_good_color');
        if (index != -1) {
            //@ts-ignore
            chart.series[index].update({ threshold: plotLine.value });
        }
    }, [maximumColor, chartData.seriesData.length]);

    useEffect(() => {
        if (!chart) {
            return;
        }
        const indexRed = chart.series.findIndex((s) => s.name == 'brand_theme_chart_stoplight_bad_color');
        if (indexRed != -1) {
            //@ts-ignore
            chart.series[indexRed].update({ visible: view == 'stoplight', showInLegend: view == 'stoplight' });
        }
        const indexGreen = chart.series.findIndex((s) => s.name == 'brand_theme_chart_stoplight_good_color');
        if (indexGreen != -1) {
            //@ts-ignore
            chart.series[indexGreen].update({ visible: view == 'stoplight', showInLegend: view == 'stoplight' });
        }
        const indexStatistical = chart.series.findIndex((s) => s.name == 'brand_theme_chart_standard_deviation_color');
        if (indexGreen != -1) {
            //@ts-ignore
            chart.series[indexStatistical].update({
                visible: view == 'statistical',
                showInLegend: view == 'statistical',
            });
        }
    }, [view]);

    return (
        <Box className={colorsGridData == undefined ? 'd-none' : ''}>
            <Stack direction={'row'} sx={{ alignItems: 'flex-end' }}>
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <FormStaticLabel
                        elementProps={prepareFormElementProps({
                            form: hookForm.form,
                            component: {
                                label: 'example',
                                props: {
                                    isSectionLabel: true,
                                },
                            } as RawFormComponentType,
                        })}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={(event, v) => {
                            if (v !== null) {
                                setView(v);
                            }
                        }}
                    >
                        <ToggleButton value={'standard'}>{t('view.standard')}</ToggleButton>
                        <ToggleButton value={'stoplight'}>{t('view.stoplight')}</ToggleButton>
                        <ToggleButton value={'statistical'}>{t('view.statistical')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Stack>
            <Box>
                {useMemo(
                    () => (
                        <Chart chartOptions={chartOptions} afterChartCreated={(chart: HChart) => setChart(chart)} />
                    ),
                    [chartOptions],
                )}
            </Box>
        </Box>
    );
}
