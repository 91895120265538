import { downloadFile, instance } from 'api/api';
import { APIResponse } from 'tools/types';

export interface ImportDataType {
    delimiter: string;
    date_format: string;
    import_file: File;
    segment_value_id: string;
    segment_id: number;
    use_sv_field: string;
}

export interface ManualImportResponse {
    status: 'OK' | 'ERROR';
    error?: string;
    error_report?: [];
    inserted?: number;
    total?: number;
    updated?: number;
}

export const editorMetricAPI = {
    async resetForAllUsers(elementId: number): Promise<string> {
        const response = await instance.post<APIResponse<any>>(`/data/editor/metric/apply-view-to-users/${elementId}`);
        return response.data.data;
    },
    downloadAssetsFile(templateUrl: string, useSvField: string, dateFormat: string) {
        downloadFile(templateUrl + '?use_sv_field=' + useSvField + '&date_format=' + dateFormat);
    },
    downloadLogFile(logFileUrl: string) {
        downloadFile(logFileUrl);
    },
    async importManualData(url: string, data: ImportDataType): Promise<ManualImportResponse> {
        const formData = new FormData();
        formData.append('delimiter', data.delimiter);
        formData.append('date_format', data.date_format);
        formData.append('segment_value_id', data.segment_value_id);
        formData.append('segment_id', String(data.segment_id));
        formData.append('import_file', data.import_file);
        formData.append('use_sv_field', data.use_sv_field);

        const response = await instance.post(url, formData);
        return response.data;
    },
};
