import React, { useLayoutEffect, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';
import DatasetField = ReportContentNS.DatasetField;
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import ComponentSettingsTextBlockMeasureField = ReportContentNS.ComponentSettingsTextBlockMeasureField;
import fieldToTextBlockMeasuredField = ReportContentNS.fieldToTextBlockMeasuredField;
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

function Item({
    field,
    fields,
    datasetFields,
    onChange,
    onSelectField,
}: {
    field: ComponentSettingsTextBlockMeasureField;
    fields: Array<ComponentSettingsTextBlockMeasureField>;
    datasetFields: Array<DatasetField>;
    onChange: (newField: ComponentSettingsTextBlockMeasureField | false) => void;
    onSelectField: (field: ComponentSettingsTextBlockMeasureField) => void;
}) {
    const fieldsListForSelect = datasetFields
        .filter((datasetFilter) => {
            const fieldFount = fields.find((f) => f.reference_name == datasetFilter.reference_name);
            if (!fieldFount) {
                return true;
            }
            return fieldFount.reference_name == field.reference_name;
        })
        .map((datasetFilter) => {
            const field = fields.find((f) => f.reference_name == datasetFilter.reference_name);
            if (!field) {
                return datasetFilter;
            }
            return { ...datasetFilter, column_name: field.column_name };
        });

    const [measuredField, setMeasuredField] = useState<ComponentSettingsTextBlockMeasureField>(field);
    useLayoutEffect(() => {
        onChange(measuredField);
    }, [measuredField]);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
                zIndex: 1205, //above backdrop
            }}
            spacing={1}
        >
            {measuredField && (
                <AggregationField
                    allowedFields={fieldsListForSelect}
                    measuredField={measuredField}
                    setMeasuredField={setMeasuredField}
                    fieldToMeasuredField={fieldToTextBlockMeasuredField}
                />
            )}
            <Box flexShrink={0}>
                <IconButton onClick={() => onSelectField(field)}>
                    <IconMi icon="gear" fontSize="16" />
                </IconButton>

                <IconButton onClick={() => onChange(false)}>
                    <IconMi icon="times" fontSize="16" />
                </IconButton>
            </Box>
        </Stack>
    );
}

export default function MeasureFieldsList({
    fields,
    setFields,
    datasetFields,
    onSelectField,
}: {
    fields: Array<ComponentSettingsTextBlockMeasureField>;
    setFields: React.Dispatch<React.SetStateAction<Array<ComponentSettingsTextBlockMeasureField>>>;
    datasetFields: Array<DatasetField>;
    onSelectField: (field: ComponentSettingsTextBlockMeasureField) => void;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const handleChange = (newField: ComponentSettingsTextBlockMeasureField | false, index: number) => {
        setFields((prevState) => {
            const list = prevState.slice();
            if (newField == false) {
                list.splice(index, 1);
            } else {
                list[index] = newField;
            }
            return list;
        });
    };

    return (
        <Box>
            <FormLabel>{t('text_block.variables')}</FormLabel>
            {fields.map((field, index) => (
                <Item
                    key={field.reference_name}
                    onChange={(newField: ComponentSettingsTextBlockMeasureField | false) =>
                        handleChange(newField, index)
                    }
                    onSelectField={onSelectField}
                    field={field}
                    fields={fields}
                    datasetFields={datasetFields}
                />
            ))}
        </Box>
    );
}
