import React, { Component } from 'react';
import styles from 'components/common/form/data-fetch-command/SegmentDataPreview.styles';

export default class SegmentDataPreview extends Component<any, any> {
    validationData: any;
    constructor(props: { validationData: any }) {
        super(props);
        this.state = { hasError: false };
        this.validationData = props.validationData;
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <div>Wrong validationData Structure </div>;
        }
        return <SegmentDataPreviewComponent validationData={this.validationData} />;
    }
}

function SegmentDataPreviewComponent({ validationData }: { validationData: any }) {
    return (
        <table>
            <tr>
                <td style={styles.table.header.td as React.CSSProperties}>Display Key Value</td>
                <td style={styles.table.header.td as React.CSSProperties}>Display Value</td>
            </tr>
            {validationData.data.map((row: any) => {
                return (
                    <tr>
                        <td style={styles.table.body.td as React.CSSProperties}>{row[0]}</td>
                        <td style={styles.table.body.td as React.CSSProperties}>{row[1]}</td>
                    </tr>
                );
            })}
        </table>
    );
}
