import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import { FormComponentBuilder } from 'components/common/form/layout';
import React, { useState } from 'react';
import { Box, Link, Stack, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import GridComponent from 'components/common/grid/GridComponent';

export interface FormTriggerCompletedProps extends FormControlProps {
    lastCompleted: string;
    runHistoryUrl: string;
}

export default function TriggerCompleted({ controlProps }: FormElementControlPropsType<FormTriggerCompletedProps>) {
    const { t } = useBundleTranslation(['app/editor/schedule']);
    const { t: gridTrans } = useBundleTranslation(['app/notification/history']);

    const [showPopup, setShowPopup] = useState(false);

    const popupSettings: PopupSettings = {
        title: t('run_history'),
        noCancel: true,
        textOK: 'Close',
    };

    const onHistoryClick = () => {
        setShowPopup(true);
    };

    return (
        <>
            <Box
                sx={{
                    padding: '8px',
                    borderRadius: '4px',
                    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.02),
                    border: 'solid 1px',
                    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                }}
            >
                {`${t('completed_msg')} ${controlProps.lastCompleted} • `}
                <Link sx={{ cursor: 'pointer' }} onClick={onHistoryClick}>
                    {t('run_history')}
                </Link>
            </Box>
            {showPopup && (
                <Popup
                    settings={popupSettings}
                    open={showPopup}
                    onHide={() => {
                        setShowPopup(false);
                    }}
                >
                    <Stack direction={'column'} spacing={2}>
                        <GridComponent
                            uid={`editor-history-${controlProps.runHistoryUrl}`}
                            settingsUrl={controlProps.runHistoryUrl}
                            formGrid
                            t={gridTrans}
                        />
                    </Stack>
                </Popup>
            )}
        </>
    );
}

export class FormTriggerCompletedBuilder extends FormComponentBuilder {
    prepareProps(): FormTriggerCompletedProps {
        return {
            ...this.controlProps,
            lastCompleted: this.elementProps.componentProps?.lastCompleted ?? '',
            runHistoryUrl: this.elementProps.componentProps?.runHistoryUrl ?? [],
        };
    }
}
