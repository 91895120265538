import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import TextBlockEditPanel from 'components/report-content/components/text-block/TextBlockEditPanel';
import TextBlock from 'components/report-content/components/text-block/TextBlock';
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;

export default class TextBlockComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsTextBlock> {
    getComponent(editPanel?: any) {
        return (
            <TextBlock
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
                editPanel={editPanel}
                blockId={this.block.uid}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <TextBlockEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
