import { FormComponentValue } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import React, { useState } from 'react';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';

export default function SelectSegmentValuePopup({
    onHide,
    onConfirm,
    segmentValuesList,
}: {
    onHide: () => void;
    onConfirm: (segmentValueId: number) => void;
    segmentValuesList: Array<FormComponentValue>;
}) {
    const { t } = useBundleTranslation(['components/common/element_image']);
    const [segmentValueId, setSegmentValueId] = useState<number>(0);
    return (
        <Popup
            settings={{
                title: t('Data Collection Parameters'),
                textOK: t('select'),
            }}
            onHide={onHide}
            open={true}
            onConfirm={() => onConfirm(segmentValueId)}
        >
            <div>
                <ReactSelect
                    data={[{ value: '0', label: 'All Values' }].concat(segmentValuesList)}
                    addonLabel={t('dimension')}
                    value={segmentValueId}
                    update={(value) => setSegmentValueId(Number(value))}
                    name="segmentValueIdForCollect"
                />
            </div>
        </Popup>
    );
}
