import { FormElementProps } from 'components/common/form/index';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { AssocArray } from 'tools/types';
import { DependencyRule, FieldPropsPreg } from 'components/common/form/dependency/index';
import { getElementFieldValue } from 'components/common/form/formTools';
import checkRule from 'components/common/form/dependency/checkRule';
import { logWarning } from 'tools/tools';

type fArgs = [string, string, string, AssocArray<DependencyRule>];

export default function logicGate(elementProps: FormElementProps, formsContext: FormsContextType, args: fArgs): string {
    const patterns: AssocArray<string> = {};
    const onTrue = args[1];
    const onFalse = args[2];
    for (const [key, rule] of Object.entries<DependencyRule>(args[3])) {
        let fieldValue = getElementFieldValue(elementProps.form, rule.field, formsContext);
        patterns[key] = checkRule(rule, fieldValue, elementProps.form, formsContext) ? '1' : '0';
    }

    const regExp = new RegExp(Object.keys(patterns).join('|'), 'gi');
    const booleanExpression = args[0].replace(regExp, (matched) => patterns[matched]);

    let final = onFalse;
    if (booleanExpression.match(/^[10&\(\)\|\!\s\^]*$/)) {
        try {
            final = eval(booleanExpression) ? onTrue : onFalse;
        } catch (e) {
            logWarning('Form: Deps Transform Function LogicGate: invalid Expression: ' + booleanExpression);
        }
    } else {
        logWarning('Form: Deps Transform Function LogicGate: invalid symbols Expression: ' + booleanExpression);
    }

    final = String(final).replace(FieldPropsPreg, (match: string, group: string) => {
        const result = getElementFieldValue(elementProps.form, group, formsContext);
        return result == null ? final : result;
    });
    return final;
}
