import { alpha, Theme } from '@mui/material';

export default {
    thumbnail: {
        maxHeight: '75px',
    },
    flexStartWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
    },
    tags: {
        chip: {
            borderRadius: '3px',
            margin: '3px',
            cursor: 'pointer',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    custom_fields: {
        item: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '& > p': {
                margin: 0,
            },
        },
        container: {
            width: '500px',
        },
    },
    sectionDivider: {
        borderBottom: 'solid 1px',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        width: '100%',
        marginTop: '4px',
        marginBottom: '8px',
    },
};
