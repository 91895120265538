import { RawFormComponentType, FormDataAPIType } from 'components/common/form';
import { IconType } from 'components/common/icon/IconHandler';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import React from 'react';
import { prepareFormElementProps } from 'components/common/form/formTools';

export default function EditPanelCheckBox({
    elementProps,
    name,
    label,
    asBoolean = false,
    icon,
    YValue,
    NValue,
    disabled = false,
}: {
    elementProps: FormDataAPIType;
    name: string;
    asBoolean?: boolean;
    label?: string;
    icon?: IconType;
    YValue?: string;
    NValue?: string;
    disabled?: boolean;
}) {
    const checkBoxControl = {
        component: 'FormCheckbox',
        name: name,
        label: label,
        props: {
            asBoolean: asBoolean,
            icon: icon,
            checked: YValue,
            unchecked: NValue,
            disabled: disabled,
        },
    } as RawFormComponentType;

    return (
        <ReactHookFormController
            elementProps={prepareFormElementProps({
                ...elementProps,
                component: checkBoxControl,
            })}
        />
    );
}
