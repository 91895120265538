import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/jetbrains-mono';

export const MuiCssBaseLine = {
    styleOverrides: {
        body: {
            webkitFontSmoothing: 'antialiased', //moved from index css
            MozOsxFontSmoothing: 'grayscale', //moved from index css
        },
        pre: {
            margin: 0,
            overflow: 'auto',
        },
    },
};
