import { FormDataAPIType, FormRendererProps } from 'components/common/form/index';
import usePageForm from 'components/common/form/hooks/usePageForm';
import { useEffect } from 'react';
import useBundleTranslation from 'i18n';

export default function useCustomSimplifiedForm(
    defaultValues: any = {},
    formRendererProps?: FormRendererProps,
): FormDataAPIType {
    const { t } = useBundleTranslation();
    const hookForm = usePageForm(defaultValues, t, formRendererProps);
    useEffect(() => {
        hookForm.form.setFormDidMount(true);
        return () => {
            hookForm.form.setFormDidMount(false);
        };
    }, []);
    return hookForm;
}
