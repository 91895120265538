import { Theme } from '@mui/material';

export default {
    wrapper: {
        mb: 1,
        mt: -1,
    },
    link: {
        color: (theme: Theme) => theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    alert: {
        justifyContent: 'center',
        '.MuiAlert-action': { ml: 0, pt: 0, pl: '4px' },
    },
};
