import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';

export default function CustomFields({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const customFields =
        datasetInfo.custom_fields && datasetInfo.custom_fields.length > 0 ? datasetInfo.custom_fields : null;

    if (!customFields) {
        return null;
    }

    return (
        <>
            {customFields.map((section) => (
                <Box sx={styles.flexStartWrapper}>
                    <IconMi icon={'custom-field'} fontSize="16" />
                    <Stack direction={'column'} spacing={[0.5]}>
                        <Typography fontWeight={600}>{section.label}</Typography>
                        {section.fields.map((field) => {
                            const value = Array.isArray(field.values) ? field.values.join(', ') : field.values;
                            const isLink = value.startsWith('<a');
                            return (
                                <Grid
                                    container
                                    direction={'row'}
                                    key={`custom-field-${field.name}`}
                                    sx={styles.custom_fields.container}
                                >
                                    <Grid item xs={6}>
                                        <Tooltip title={field.name}>
                                            <Typography sx={styles.custom_fields.item}>{`${field.name}:`}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {isLink ? (
                                            <Box
                                                className={'fixed-value markdown-holder'}
                                                sx={styles.custom_fields.item}
                                                dangerouslySetInnerHTML={{
                                                    __html: value,
                                                }}
                                            />
                                        ) : (
                                            <Tooltip title={value}>
                                                <Typography sx={styles.custom_fields.item}>{value}</Typography>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Stack>
                </Box>
            ))}
        </>
    );
}
