import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Chip, IconButton, Tooltip, useTheme } from '@mui/material';
import { wallFormatDateExt, wallProcessMarkdownText, wallProcessText } from 'components/wall/index';
import PartItemWallSharedFiles from 'components/wall/parts/PartItemWallSharedFiles';
import IconMi from 'components/common/icon/IconMi';
import ExpertNoteHistoryPopup from 'components/wall/parts/ExpertNoteHistoryPopup';
import { ReactComponent as expertIconTmpImage } from './brain-icon.svg';
import { UseSvgDataUri } from 'hooks/useSvg';

export interface ItemWallExpertNoteProps {
    data: any;
    uid: string;
    wallData: any;
    deleteNote: () => void;
    editNote: () => void;
    addNote: () => void;
}

export default function ItemWallExpertNote(props: ItemWallExpertNoteProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { data, uid, wallData, deleteNote = () => {}, editNote = () => {}, addNote = () => {} } = props;
    const params = wallData.params;
    const [showHistoryPopup, setShowHistoryPopup] = useState<boolean>(false);
    const appTheme = useTheme();

    const displayNone =
        !(params.view_scope || params.view_scope != 'collaborative') &&
        wallData.viewFilter &&
        wallData.viewFilter != 'user_note';

    const utf8_to_b64 = (text: string) => {
        //ToDo Implement utf8_to_b64
        return text;
    };

    return (
        <Box
            className="wall_node_OLD wall_note_node wall__item wall__item--note"
            id={`wall_user_note_${uid}_${data.user_note_id}`}
            data-element_id={data.element_id}
            data-segment_value_id={data.segment_value_id}
            sx={{ display: displayNone ? 'none' : undefined }}
        >
            <Box className="wall_note wall__item-body">
                <Box
                    className="wall__item-icon-block wall_expert_note_image_OLD"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px',
                    }}
                >
                    <img
                        alt=""
                        className={`wall__item-avatar-img`}
                        src={UseSvgDataUri(expertIconTmpImage, appTheme.palette.primary.main)}
                    />
                </Box>
                <Box
                    className="wall__item-content-block wall_note_text_OLD wall_expert_note_text_OLD markdown"
                    data-text={utf8_to_b64(data.raw_text)}
                    data-user_note_id={data.user_note_id}
                >
                    <Box
                        className="wall__item-text markdown-holder"
                        dangerouslySetInnerHTML={{
                            __html: wallProcessMarkdownText(data.text, params),
                        }}
                    />

                    <Box className="mod_attach_list_OLD">
                        <PartItemWallSharedFiles data={data} elementType={'note'} listMode />
                    </Box>
                    <Box component="span" className="clear" />
                    <Box className="wall__item-content-footer wall_node_footer_OLD" sx={{ mx: '0 !important' }}>
                        <Box className="wall__item-content-footer-left">
                            <Box
                                component="i"
                                sx={{
                                    mr: 0.5,
                                }}
                            >
                                {t('expert_note.item_title', {
                                    datetime: wallFormatDateExt(data.ts),
                                    name: wallProcessText(data.username, params),
                                })}
                            </Box>

                            {(!params.view_scope || params.view_scope != 'collaborative') && (
                                <Chip
                                    sx={{
                                        mr: 0.5,
                                    }}
                                    label={t('expert_note.history_link')}
                                    className="wall_note_expert_link wall_note_expert_history"
                                    onClick={(e) => {
                                        setShowHistoryPopup(true);
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {params.isExpertCommentaryAllowEdit && (
                                <Chip
                                    sx={{
                                        mr: 0.5,
                                    }}
                                    label={t('expert_note.label_new')}
                                    className="wall_note_expert_link wall_note_expert_add"
                                    onClick={addNote}
                                    variant="outlined"
                                />
                            )}
                            {params.isExpertCommentaryAllowEdit &&
                                (data.author_user_id == params.user_id ||
                                    wallData.settings.privDeleteCollaborativeItems) && (
                                    <Tooltip title={t('expert_note.edit_tooltip')} disableInteractive>
                                        <IconButton
                                            sx={{ p: '0', color: 'inherit' }}
                                            className="wall__item-content-footer-icon-button wall_note_expert_link wall_note_expert_edit"
                                            onClick={editNote}
                                        >
                                            <IconMi icon="edit-pencil" fontSize="16" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            {(data.author_user_id == params.user_id ||
                                wallData.settings.privDeleteCollaborativeItems) && (
                                <Tooltip title={t('expert_note.delete_tooltip')} disableInteractive>
                                    <IconButton
                                        sx={{ p: '0', color: 'inherit' }}
                                        className="wall__item-content-footer-icon-button wall_note_expert_link wall_delete_note"
                                        data-user_note_id={data.user_note_id}
                                        onClick={deleteNote}
                                    >
                                        <IconMi icon="delete" fontSize="16" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {showHistoryPopup && (
                <ExpertNoteHistoryPopup
                    reqData={{
                        element: params.element_id,
                        sv_id: params.segment_value_id,
                    }}
                    params={params}
                    onClose={() => {
                        setShowHistoryPopup(false);
                    }}
                />
            )}
        </Box>
    );
}
