import { FormRendererAPIType } from 'components/common/form';
import { useFormState } from 'react-hook-form';
import { TFunction } from 'i18next';

export function useContentWorkflowFieldsValidation(form: FormRendererAPIType, t: TFunction) {
    const fieldsList = [
        'name',
        'description',
        'category_id',
        'business_owner_id',
        'technical_owner_id',
        'data_steward_id',
    ];

    const { dirtyFields } = useFormState({ control: form.hookFormControl });

    const workflowFieldsValidation = (): Promise<boolean> => {
        return new Promise((resolve) => {
            const elementId = Number(form.hookFormGetValues('element_id') ?? 0);
            const cwf = form.hookFormGetValues('content_workflow_id') ?? '';
            const hasSameWorkflow =
                elementId > 0 &&
                cwf != '0' &&
                cwf != '' &&
                form.hookFormGetValues('force_content_workflow_reset_ind') != 'Y' &&
                typeof dirtyFields['content_workflow_id'] == 'undefined';

            if (hasSameWorkflow) {
                const changedFields = fieldsList.filter((f) => typeof dirtyFields[f] != 'undefined');
                if (changedFields.length) {
                    if (
                        confirm(
                            t('wfc_modified_fields', { fields: changedFields.map((f) => t(f)).join(', ') }) +
                                `\r\n${t('proceed')}?`,
                        )
                    ) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                }
            }
            return resolve(true);
        });
    };

    return { workflowFieldsValidation };
}
