import { alpha } from '@mui/material';

export const MuiDialogContentConfig = {
    defaultProps: {},
    styleOverrides: {
        dividers: ({ theme }: { theme: any }) => {
            return {
                borderTopColor: alpha(theme.palette.text.primary, 0.08),
                borderBottomColor: alpha(theme.palette.text.primary, 0.08),
            };
        },
    },
};
