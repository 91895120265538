import { alpha, Stack, Theme } from '@mui/material';

export default function TopControlsWrapper({ children }: { children: any }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
                px: 2,
                py: 1,
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                backgroundColor: 'background.default',
                zIndex: 2,
            }}
        >
            {children}
        </Stack>
    );
}
