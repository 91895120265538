import { alpha, Button, Menu, Typography } from '@mui/material';
import React, { useState, lazy, useEffect } from 'react';
import IconMi from 'components/common/icon/IconMi';
import ActionMenuItem from './ActionMenuItem';
import useBundleTranslation from 'i18n';
import { FilterData } from '../index';
import { datasetViewAPI } from 'api/viewer/dataset';
import { openInNewTab } from 'tools/tools';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { downloadFile } from 'api/api';
import { now } from 'lodash';
import FormComponent from 'components/common/form/Form';
import AddViewPopup from './AddViewPopup';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';
import { useConfigConstantValue } from 'hooks/useConfigConstant';

const ViewSql = lazy(() => import('./action/ViewSql'));

function ActionsDropdown({
    datasetId,
    filterId,
    instanceId,
    json,
    selectedFilterData,
    filterDirty,
    saveFilter,
    switchFilterVisibility,
    setShowLoader,
    reloadDatasetView,
    setShowMetricBuilder,
    hasNumericAndDateFields,
    reloadElementsAmount,
}: {
    datasetId: number;
    filterId: number;
    instanceId: number;
    json: string;
    selectedFilterData: FilterData | null;
    filterDirty: boolean;
    saveFilter: () => Promise<boolean>;
    switchFilterVisibility: () => Promise<boolean>;
    setShowLoader: (value: boolean) => void;
    setShowMetricBuilder: (value: boolean) => void;
    reloadDatasetView: (withFilter?: boolean, newFilterId?: number) => void;
    hasNumericAndDateFields: boolean;
    reloadElementsAmount: () => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [anchorEl, setAnchorEl] = useState(null);
    const [showViewSql, setShowViewSql] = useState(false);
    const [showBuildDimension, setShowBuildDimension] = useState(false);
    const [showSwitchToPublic, setShowSwitchToPublic] = useState(false);
    const [showSwitched, setShowSwitched] = useState(false);
    const [action, setAction] = useState<'report' | 'dimension' | 'metric' | null>(null);
    const open = Boolean(anchorEl);
    const [showAddPopup, setShowAddPopup] = useState(false);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (action) {
            initAction(false);
        }
    }, [action]);

    const initAction = (skipPublicCheck: boolean = false) => {
        if (!skipPublicCheck && filterId > 0 && selectedFilterData && selectedFilterData.public_ind === 'N') {
            setShowSwitchToPublic(true);
            return false;
        }

        if (filterDirty) {
            if (filterId > 0) {
                saveFilter().then((result) => {
                    if (result) {
                        doAction();
                    }
                });
            } else {
                setShowAddPopup(true);
            }

            return false;
        }

        doAction();
        handleClose();
    };

    const doAction = (newFilterId: number = filterId) => {
        if (action === 'report') {
            createReport(newFilterId);
        } else if (action === 'dimension') {
            setShowBuildDimension(true);
        } else if (action === 'metric') {
            setShowMetricBuilder(true);
        }

        setAction(null);
    };

    const createReport = (newFilterId: number) => {
        datasetViewAPI.createReport(datasetId, newFilterId, json).then((response) => {
            if (response.status === 'OK') {
                reloadElementsAmount();
                openInNewTab(`/editor/element/report/${response.id}`);
            }
        });
    };

    const switchToPublicSettings: PopupSettings = {
        title: t('build_report.switch_to_public'),
        textOK: t('build_report.switch'),
    };

    const switchedPopupSettings: PopupSettings = {
        title: t('visibility.title'),
        textOK: t('visibility.button'),
        noCancel: true,
    };

    const onVisibilitySwitchConfirm = () => {
        switchFilterVisibility().then((result) => {
            setShowSwitchToPublic(false);
            if (result) {
                initAction(true);
            }
            handleClose();
        });
    };

    const downloadAction = () => {
        setShowLoader(true);
        runDownload(datasetId, instanceId, filterId);
    };

    const viewSqlAction = () => {
        setShowViewSql(true);
    };
    const switchFilterVisibilityAction = () => {
        switchFilterVisibility().then(() => {
            setShowSwitched(true);
        });
    };
    const materializeAction = () => {};

    const onShowDimensionHide = () => {
        setShowBuildDimension(false);
    };

    const buildDimensionFormUrl = `/data/dataset/${datasetId}/filter/${filterId}/build-dimension/form`;

    const buildDimensionPopupSettings: PopupSettings = {
        title: t('build_dimension.title'),
    };

    const onViewSqlHide = () => {
        setShowViewSql(false);
    };

    const runDownload = function (datasetId: number, instanceId: number, filterId: number) {
        const checker = function () {
            setTimeout(() => {
                datasetViewAPI.checkDownload(datasetId, instanceId, filterId).then((response) => {
                    if (response.data.ready) {
                        setShowLoader(false);
                        downloadFile(
                            `data/dataset/${datasetId}/instance/${instanceId}/filter/${filterId}/state/get/download`,
                        );
                    } else {
                        checker();
                    }
                });
            }, 1000);
        };

        datasetViewAPI.runDownload(datasetId, instanceId, filterId).then(() => {
            setTimeout(() => checker(), 100);
        });
    };

    const afterCreateDimension = (response: any) => {
        if (response.data && response.data.id > '') {
            openInNewTab(`/editor/segment/${response.data.id}`);
        }
    };

    const afterViewCreate = (newFilterId: number) => {
        reloadDatasetView(true, newFilterId);
        doAction(newFilterId);
    };

    const allowedCreatePublic = useIsAdminOrHasPrivilege('PRIV_CREATE_PUBLIC_VIEW');
    const downloadDisabled = useConfigConstantValue('DISABLE_DOWNLOADS');
    const allowedDownload = useIsAdminOrHasPrivilege('PRIV_ALLOW_DOWNLOAD');

    return (
        <>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant={open ? 'outlined' : 'contained'}
                color={'primary'}
                endIcon={<IconMi icon={'chevron-down'} />}
                sx={{
                    border: !open ? '1px solid' : undefined,
                    borderColor: (theme) =>
                        open ? `${alpha(theme.palette.primary.main, 0.4)} !important` : 'transparent',
                    borderBottomColor: open ? 'transparent !important' : undefined,
                    borderBottomLeftRadius: open ? 0 : undefined,
                    borderBottomRightRadius: open ? 0 : undefined,
                }}
                data-test={'actions-menu-button'}
            >
                <Typography>{t('actions_menu.actions_label')}</Typography>
            </Button>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiMenu-list': {
                        padding: 0,
                    },
                    '.MuiPaper-root': {
                        border: '1px solid',
                        borderColor: (theme) => alpha(theme.palette.primary.main, 0.4),
                        borderTopRightRadius: 0,
                        boxShadow: 'none',
                    },
                    marginTop: '-1px',
                }}
            >
                {allowedCreatePublic && hasNumericAndDateFields && (
                    <ActionMenuItem
                        action={() => setAction('metric')}
                        itemKey={'metric'}
                        icon={'metric'}
                        text={t('actions_menu.build_metric_label')}
                    />
                )}
                {allowedCreatePublic && (
                    <ActionMenuItem
                        action={() => setAction('report')}
                        itemKey={'report'}
                        icon={'report'}
                        text={t('actions_menu.build_report_label')}
                    />
                )}
                {allowedCreatePublic && (
                    <ActionMenuItem
                        action={() => setAction('dimension')}
                        itemKey={'dimension'}
                        icon={'dimension'}
                        text={t('actions_menu.new_dimension_label')}
                    />
                )}
                {(allowedDownload || !downloadDisabled) && (
                    <ActionMenuItem
                        action={downloadAction}
                        itemKey={'download'}
                        icon={'download'}
                        text={t('actions_menu.download_label')}
                    />
                )}
                <ActionMenuItem
                    action={viewSqlAction}
                    itemKey={'view-sql'}
                    icon={'sql-data-source'}
                    text={t('actions_menu.view_sql_label')}
                />
                {allowedCreatePublic && selectedFilterData && selectedFilterData.public_ind === 'Y' && (
                    <ActionMenuItem
                        action={switchFilterVisibilityAction}
                        itemKey={'private'}
                        icon={'requests'}
                        text={t('actions_menu.make_private_label')}
                    />
                )}
                {allowedCreatePublic && selectedFilterData && selectedFilterData.public_ind === 'N' && (
                    <ActionMenuItem
                        action={switchFilterVisibilityAction}
                        itemKey={'shared'}
                        icon={'share'}
                        text={'Make Shared'}
                    />
                )}
                {/*<ActionMenuItem*/}
                {/*    action={materializeAction}*/}
                {/*    itemKey={'materialize'}*/}
                {/*    icon={'datasets'}*/}
                {/*    text={t('actions_menu.materialise_as_label')}*/}
                {/*/>*/}
            </Menu>
            {showViewSql && (
                <ViewSql
                    show={showViewSql}
                    onHide={onViewSqlHide}
                    datasetId={datasetId}
                    filterId={filterId}
                    json={json}
                />
            )}
            {showBuildDimension && (
                <FormComponent
                    isPopup
                    popupType={'edit'}
                    pk={buildDimensionFormUrl}
                    uid={`${buildDimensionFormUrl}-${now()}`}
                    settingsUrl={buildDimensionFormUrl}
                    onHide={onShowDimensionHide}
                    popupSettings={buildDimensionPopupSettings}
                    afterSave={afterCreateDimension}
                />
            )}
            {showSwitchToPublic && (
                <Popup
                    settings={switchToPublicSettings}
                    open={showSwitchToPublic}
                    onHide={() => setShowSwitchToPublic(false)}
                    maxWidth={'popupSm'}
                    onConfirm={onVisibilitySwitchConfirm}
                >
                    <Typography>{t('build_report.switch_warning')}</Typography>
                </Popup>
            )}
            {selectedFilterData && showSwitched && (
                <Popup
                    settings={switchedPopupSettings}
                    open={showSwitched}
                    onHide={() => setShowSwitched(false)}
                    maxWidth={'popupSm'}
                >
                    <Typography>{`${t('visibility.switched')} ${selectedFilterData.name} ${t(
                        'visibility.switched_now',
                    )} ${
                        selectedFilterData.public_ind === 'N' ? t('visibility.private') : t('visibility.shared')
                    }`}</Typography>
                </Popup>
            )}
            {showAddPopup && (
                <AddViewPopup
                    showAddPopup={showAddPopup}
                    setShowAddPopup={setShowAddPopup}
                    datasetId={datasetId}
                    json={json}
                    afterSave={afterViewCreate}
                    onClose={() => {
                        setAction(null);
                    }}
                />
            )}
        </>
    );
}

export default ActionsDropdown;
