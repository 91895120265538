import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Link, Tooltip } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import { Popup } from 'components/common/popup/Popup';

export interface PreviewAttachImagePopupProps {
    imageUrl: string;
    onClose: () => void;
}

export default function PreviewAttachImagePopup(props: PreviewAttachImagePopupProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { imageUrl, onClose = () => {} } = props;

    return (
        <Popup
            settings={{
                title: t(`preview_attach_image.title`),
                textOK: t(`preview_attach_image.ok_btn`),
                noCancel: true,
            }}
            maxWidth="popupLg"
            open={true}
            onHide={onClose}
            onConfirm={onClose}
        >
            <Tooltip title={t(`preview_attach_image.full_image_tooltip`)}>
                <Box component="a" href={imageUrl} target="_blank">
                    <Box component="img" alt="" src={imageUrl} sx={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
            </Tooltip>
        </Popup>
    );
}
