import React, { RefAttributes, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Select, { createFilter, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Box, Tooltip } from '@mui/material';
import { FormComponentValue, prepareFormComponentValues } from 'components/common/form/layout/control';
import { ReactSelectProps, useReactSelectStyles } from './index';
import ReactSelectVirtualMenuList from 'components/common/react-select/parts/ReactSelectVirtualMenuList';
import { ReactSelectOption } from './parts/ReactSelectOption';
import { ReactSelectMultiValue } from './parts/ReactSelectMultiValue';
import IconHandler from 'components/common/icon/IconHandler';
import { ReactSelectDropdownIndicator } from './parts/ReactSelectDropdownIndicator';
import { ReactSelectMultiValueRemove } from 'components/common/react-select/parts/ReactSelectMultiValueRemove';
import { ReactSelectSingleValue } from './parts/ReactSelectSingleValue';
import { ReactSelectSelectContainer } from 'components/common/react-select/parts/ReactSelectSelectContainer';
import { ReactSelectClearIndicator } from 'components/common/react-select/parts/ReactSelectClearIndicator';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { instance } from 'api/api';
import useBundleTranslation from 'i18n';

const optionsBlockSize = 1000;

let lastInputTimeout: ReturnType<typeof setTimeout>;

function useTimeOut(delay: number, callback: Function): Function {
    const [timer, setTimer] = useState<number>(0);
    return function () {
        if (timer) {
            window.clearTimeout(timer);
        }
        const args = arguments;
        setTimer(
            window.setTimeout(() => {
                callback(...args);
            }, delay),
        );
    };
}

function ReactSelect(props: ReactSelectProps) {
    const { t } = useBundleTranslation('main');
    const viewSettings = useReactSelectStyles();

    const getComponent = (props: ReactSelectProps) => {
        if (props.async) {
            return props.creatable ? AsyncCreatableSelect : AsyncSelect;
        }
        return props.creatable ? CreatableSelect : Select;
    };

    const SelectComponent = getComponent(props);
    const optionComponent = typeof props.optionComponent != 'undefined' ? props.optionComponent : ReactSelectOption;

    const [currentOptionsBlock, setCurrentOptionsBlock] = useState(1);
    const [currentFilterValue, setCurrentFilterValue] = useState('');
    // Check if Select contains a big amount of options
    const [isHuge] = useState(props.data.length > optionsBlockSize * 3);
    const [optionsList, setOptionsList] = useState<FormComponentValue[]>([]);

    const [currentSelectValue, setCurrentSelectValue] = useState<any>();

    useLayoutEffect(() => {
        const wrappedValue = Array.isArray(props.value) ? props.value : [props.value];
        const defaultData = props.creatable || props.async ? optionsList : props.data;
        let defaultValue: any = [];

        const addSelectedValue = (element: any) => {
            if (wrappedValue.includes(Number(element.value)) || wrappedValue.includes(String(element.value))) {
                defaultValue.push(element);
            }
        };

        //process also grouped  format data
        defaultData.forEach((el) => {
            if (Array.isArray(el.options) && el.options.length) {
                el.options.forEach((groupEl) => {
                    addSelectedValue(groupEl);
                });
            } else {
                addSelectedValue(el);
            }
        });

        setCurrentSelectValue(defaultValue);
    }, [props.value, props.data, props.creatable, optionsList]);

    useLayoutEffect(() => {
        const visibleOptions = props.data.filter((el) => !el.hidden);

        const options = visibleOptions.map((item) => {
            const customItem = { ...item };

            if (item.props?.icon) {
                customItem.icon = <IconHandler icon={item.props?.icon} />;
            }

            if (item.props?.tooltip) {
                customItem.tooltip = item.props?.tooltip;
            }

            return { ...customItem };
        });
        setOptionsList(options);
    }, [props.data]);

    useEffect(() => {
        if (!isHuge) {
            return;
        }
        // Pass part of options to the Select (one block at the beginning)
        setOptionsList(filterOptionsList(currentFilterValue).slice(0, currentOptionsBlock * optionsBlockSize));
        if (selectRef != null && selectRef.current != null) {
            setTimeout(() => {
                // Select will be rendered after new block added
                // Focus on input element after render
                selectRef.current.inputRef.focus();
            }, 0);
        }
    }, [currentOptionsBlock, currentFilterValue]);

    const filterOptionsList = (inputValue: string) => {
        return props.data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };

    const handleScroll = function (currentIndex: any) {
        if (!isHuge) {
            return;
        }
        // If user scrolled more than 90% of Select options, add one more block to the Select
        if (currentIndex / (currentOptionsBlock * optionsBlockSize) > 0.9) {
            setTimeout(() => setCurrentOptionsBlock(currentOptionsBlock + 1), 0);
        }
    };

    const handleInputChange = function (newValue: string) {
        if (props.onInputChange) props.onInputChange(newValue);
        if (!isHuge) {
            return;
        }
        // Add small delay to input before apply new filter value
        if (lastInputTimeout != null) {
            clearTimeout(lastInputTimeout);
        }
        lastInputTimeout = setTimeout(() => setCurrentFilterValue(newValue), 500);
    };

    const updateOptionsList = useCallback(
        (nextOptions: FormComponentValue[] = []) => {
            const newOptions: FormComponentValue[] = nextOptions
                .filter((item: FormComponentValue) => item.__isNew__)
                .map((item: FormComponentValue) => ({
                    label: item.label,
                    value: item.value,
                }));

            if (newOptions.length) {
                setOptionsList([...optionsList, ...newOptions]);
            }
        },
        [setOptionsList, optionsList],
    );

    const handleChange = function (newValue: any, actionMeta: ActionMeta<FormComponentValue>) {
        if (props.creatable && Array.isArray(newValue)) {
            updateOptionsList(newValue);
        }

        const value = props.isMulti
            ? newValue.map((el: FormComponentValue) => el.value)
            : newValue == null
              ? ''
              : newValue.value;
        props.update(value, newValue, actionMeta);
    };

    // @ts-ignore
    const selectRef = useRef<RefAttributes>(null);
    // Set direction of dropdown
    let menuPlacement = 'bottom';
    if (selectRef.current) {
        const offsetTop = selectRef.current.controlRef.getBoundingClientRect().top;
        const totalHeight = document.body.scrollHeight;
        if (totalHeight - offsetTop < 200) {
            menuPlacement = 'top';
        }
    }

    useEffect(() => {
        if (selectRef.current && props.dataTest) {
            selectRef.current.inputRef.setAttribute('data-test', `${props?.dataTest}_select`);
        }
    }, [props.dataTest]);

    let components: any = {
        SelectContainer: ReactSelectSelectContainer,
        Option: optionComponent,
        SingleValue: ReactSelectSingleValue,
        MultiValue: ReactSelectMultiValue,
        DropdownIndicator: ReactSelectDropdownIndicator,
        MultiValueRemove: ReactSelectMultiValueRemove,
        ClearIndicator: ReactSelectClearIndicator,
    };

    let groupedOptionsList: Array<any> = [];
    let valueGroups: Array<string> = [];
    optionsList.forEach((option) => {
        if (option?.valueGroup) {
            const groupName = String(option.valueGroup);
            if (!valueGroups.includes(groupName)) {
                valueGroups.push(groupName);
            }
        }
    });
    if (valueGroups.length) {
        groupedOptionsList = valueGroups.map((group) => ({
            label: group,
            options: optionsList.filter((opt) => opt.valueGroup == group),
        }));
        groupedOptionsList = optionsList.filter((option) => !option?.valueGroup).concat(groupedOptionsList);
    } else {
        if (props.applyVirtualList)
            components.MenuList = (props: any) => <ReactSelectVirtualMenuList {...props} viewSettings={viewSettings} />;
    }

    const [isOpenWatcher, setIsOpenWatcher] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
        if (!props.tooltip) {
            return;
        }

        setIsOpenWatcher(
            window.setInterval(() => {
                setShowTooltip(!selectRef.current?.props?.menuIsOpen);
            }, 100),
        );
        return () => window.clearInterval(isOpenWatcher);
    }, []);

    components = { ...components, ...(props.overrideComponents ?? {}) };
    const containerRef = useRef();
    let selectParams: any = {
        ref: selectRef,
        isDisabled: props.disabled,
        value: currentSelectValue,
        filterOption: createFilter({ ignoreAccents: false }),
        options: valueGroups.length ? groupedOptionsList : optionsList,
        isLoading: props.isLoading,
        onMenuScrollToBottom: handleScroll,
        onInputChange: handleInputChange,
        captureMenuScroll: false,
        classNamePrefix: 'custom-select',
        onChange: handleChange,
        components: components,
        isMulti: props.isMulti,
        isOptionDisabled: (option: FormComponentValue) => Boolean(option.disabled),
        menuPortalTarget: props.customPortalTarget ?? (props.customPortalTarget === null ? undefined : document.body),
        // menuPortalTarget:{containerRef.current}
        theme: viewSettings.theme,
        styles: viewSettings.styles,
        instanceId: props.name,
        placeholder: props.placeholder ?? t('select___'),
        onMenuOpen: props.onMenuOpen,
        onMenuClose: props.onMenuClose,
        'data-test': props.id ?? props.name ?? props.dataTest,
        className: props.className,
        isClearable: props.isClearable,
        name: props.name,
        // @ts-ignore
        isMultiOneRowMode: props.isMultiOneRowMode,
        getOptionLabel: props.getOptionLabel,
        closeMenuOnSelect: props.closeMenuOnSelect !== false,
        noOptionsMessage: () => t('select_not_options'),
    };

    const asyncCall = (callback: Function, inputValue: string) => {
        instance
            //@ts-ignore
            .get(props.async, { params: { ...props.urlParams, pattern: inputValue } })
            .then((response) => {
                if (response.data.data) {
                    const values = prepareFormComponentValues(response.data.data);
                    callback(values);

                    const valuesToAdd: Array<FormComponentValue> = [];
                    values.forEach((v) => {
                        if (optionsList.findIndex((o) => o.value == v.value) == -1) {
                            valuesToAdd.push(v);
                        }
                    });
                    // Add new values to existing list
                    if (valuesToAdd.length) {
                        setOptionsList(optionsList.concat(valuesToAdd));
                    }
                }
            });
    };
    const asyncInputChange = useTimeOut(500, asyncCall);

    const [asyncList, setAsyncList] = useState(valueGroups.length ? groupedOptionsList : optionsList);
    if (props.async) {
        selectParams = {
            ...selectParams,
            // cacheOptions: true,
            loadOptions: (inputValue: string, callback: (options: Array<FormComponentValue>) => void) => {
                asyncInputChange(callback, inputValue);
            },
            defaultOptions: optionsList,
        };
    }

    return (
        <Box ref={containerRef} sx={{ display: 'flex', flexWrap: 'nowrap' }} data-test={props.id ?? props.name}>
            <Tooltip title={showTooltip ? props.tooltip : ''} enterDelay={1000}>
                <div style={{ width: '100%' }}>
                    <SelectComponent
                        // menuPlacement={menuPlacement as MenuPlacement}
                        // ToDo check menuPlacement="auto" and after this remove calc func above
                        menuPlacement={'auto'}
                        menuPosition={'fixed'}
                        {...selectParams}
                        closeMenuOnScroll={(event: any) => {
                            return !event.target.classList?.contains('custom-select__menu-list');
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.stopPropagation();
                            }
                        }}
                    />
                </div>
            </Tooltip>
        </Box>
    );
}

export default ReactSelect;
