import { Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FontPreviewCellProps } from 'components/common/grid/';
import { useDispatch, useSelector } from 'react-redux';
import { useInstallFont } from 'hooks/useFont';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

export default function FontPreviewCell({ value, text, t, file }: FontPreviewCellProps) {
    const fontList = useSelector((state: any) => state.font.list);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    if (undefined === t) t = useBundleTranslation('main')['t'];

    useEffect(() => {
        useInstallFont(value, file, fontList, dispatch).then(() => {
            setLoading(false);
        });
    }, []);

    return (
        <Grid
            container
            sx={{
                height: 1,
                alignItems: 'center',
                justifyContent: 'start',
                fontFamily: (theme) => `CustomFont_${value}, ${theme.font.default}`,
            }}
        >
            {loading ? (
                <>
                    <IconMi icon={'loader'} className={'anim-spin'} /> <Box sx={{ pl: 0.5 }}>{t('loading___')}</Box>
                </>
            ) : (
                <>{text}</>
            )}
        </Grid>
    );
}
