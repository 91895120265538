import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsMapChart = ReportContentNS.ComponentSettingsMapChart;
import MapChart from 'components/report-content/components/map-chart/MapChart';
import MapChartEditPanel from 'components/report-content/components/map-chart/MapChartEditPanel';

export default class MapChartComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsMapChart> {
    getComponent() {
        return (
            <MapChart
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <MapChartEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
