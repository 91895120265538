import { CustomCellProps } from 'components/common/grid/';
import { Box, Tooltip } from '@mui/material';
import { getCellContent } from 'components/common/grid/cell';
import React, { useContext } from 'react';
import { AsyncActionData, gridAPI } from 'api/grid';
import { APIResponse } from 'tools/types';
import { openInNewTab } from 'tools/tools';
import { FormsContext } from 'components/common/form/hooks/useFormContext';

function TestValueCell({ text, tooltip, subText, icon, href, setShowLoader, rawHtml, align }: CustomCellProps) {
    const formsContext = useContext(FormsContext);

    const buildHref = () => {
        if (href) {
            let filterValue = '',
                elementId = 0;

            if (formsContext['externalfilter']) {
                filterValue = formsContext['externalfilter'].hookFormGetValues('name');
            }

            if (formsContext['extreport']) {
                elementId = formsContext['extreport'].hookFormGetValues('element_id');
            }

            return `${href}&ef_name=${filterValue}&element_id=${elementId}`;
        }

        return '';
    };

    const onClickAction = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (href && setShowLoader) {
            const builtHref = buildHref();

            if (builtHref > '') {
                setShowLoader(true);

                gridAPI
                    .asyncAction(builtHref)
                    .then((response: APIResponse<AsyncActionData>) => {
                        if (response.status === 'OK') {
                            if (response.data.href) {
                                openInNewTab(response.data.href);
                            }
                        }
                    })
                    .finally(() => {
                        setShowLoader(false);
                    });
            }
        }
    };

    const mainText = (
        <Box component={'a'} onClick={onClickAction} sx={{ cursor: 'pointer' }}>
            {getCellContent(text, icon, rawHtml, align, subText)}
        </Box>
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{mainText}</Tooltip>;
    }

    return mainText;
}

export default TestValueCell;
