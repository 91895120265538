import React from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Tooltip } from '@mui/material';
import PartItemWallThread from 'components/wall/parts/PartItemWallThread';
import { wallProcessText } from 'components/wall/index';
import IconMi from 'components/common/icon/IconMi';

export interface ItemWallNotableEventProps {
    data: any;
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    notifyOther: (type: string, text: any, itemData: any) => void;
    imageAntiCache?: string;
}

export default function ItemWallNotableEvent(props: ItemWallNotableEventProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        uid,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        notifyOther = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const displayNone = wallData.viewFilter && wallData.viewFilter != 'notable_event';
    return (
        <Box
            className="wall_node_OLD wall_notable_event_node wall__item wall__item--event"
            sx={{ display: displayNone ? 'none' : undefined }}
            data-element_id={data.element_id}
            data-segment_value_id={data.segment_value_id}
        >
            <Box
                id={`wall_notable_event_${uid}_{data.notable_event_id}`}
                className=" wall__item-body wall_notable_event"
            >
                <Box className="wall__item-icon-block wall_notable_event_image_td_OLD" sx={{ color: data.color }}>
                    <Box className="wall__item-icon-holder" sx={{ backgroundColor: alpha(data.color, 0.08) }}>
                        <IconMi icon="event" sx={{ fontSize: '24px' }} />
                        {params.event_index ? (
                            <Box
                                component="span"
                                className="event_number_span"
                                sx={{ position: 'absolute', color: 'text.opposite', fontSize: '9px', mt: '-4px' }}
                            >
                                {params.event_index[data.notable_event_id]}
                            </Box>
                        ) : (
                            <Box component="span" className="notable_event_calendar_image" />
                        )}
                    </Box>
                </Box>

                <Box className="wall__item-content-block wall_notable_event_text_td markdown">
                    <Box component="span" className="wall_notable_event_text_span_OLD">
                        <Box
                            component="b"
                            sx={{ color: data.color }}
                            dangerouslySetInnerHTML={{
                                __html: `${wallProcessText(data.text, params)} ${
                                    data.event_end_time_formatted
                                        ? t('notable_event.period_title', {
                                              start: data.event_start_time_formatted,
                                              end: data.event_end_time_formatted,
                                          })
                                        : t('notable_event.start_title', { start: data.event_start_time_formatted })
                                }`,
                            }}
                        />
                        {data.description && (
                            <Box
                                className="wall__item-text markdown-holder"
                                dangerouslySetInnerHTML={{ __html: data.description }}
                            ></Box>
                        )}
                    </Box>
                    <Box className="wall__item-content-footer wall_node_footer_OLD">
                        <Box className="wall__item-content-footer-left">
                            <Tooltip title={t('notable_event.share_link')} disableInteractive>
                                <Box
                                    component="span"
                                    className="wall_event_notify_other wall__item-content-footer-link"
                                    data-notable_event_id={data.notable_event_id}
                                    onClick={() => {
                                        notifyOther('event', wallProcessText(data.text, params), data);
                                    }}
                                >
                                    <IconMi icon="share" fontSize="16" />
                                    {t('notable_event.share_link')}{' '}
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>

                <Box component="span" className="clear" />
            </Box>

            <Box className="wall__item-thread-block">
                <PartItemWallThread
                    {...props}
                    elementType={'notable_event'}
                    followUser={followUser}
                    insightful={insightful}
                    notifyOther={notifyOther}
                    imageAntiCache={imageAntiCache}
                />
            </Box>
        </Box>
    );
}
