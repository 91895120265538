export default {
    flexStartWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
        width: '244px',
    },
};
