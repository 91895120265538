import React, { useEffect, useState, useCallback, useContext } from 'react';
import { PluginQBContext } from '../PluginQueryBuilder';
import useBundleTranslation from 'i18n';
import { Box, Stack, TextField } from '@mui/material';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import ReactSelect from 'components/common/react-select/ReactSelect';

export default function QueryBuilderFieldsGABlock(props: any) {
    const { reportData, reportAct, queryData, queryAct, helperAct, configData, pluginConfig } =
        useContext(PluginQBContext);

    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const [segmentOptions, setSegmentOptions] = useState<any[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<any[]>([]);
    const [filtersValue, setFiltersValue] = useState<string>(
        queryData.params['filters'] ? helperAct.escapeHtml(queryData.params['filters']) : '',
    );
    const [limitValue, setLimitValue] = useState<string>(queryData.params['limit'] ?? '');

    const elementProps = useCustomSimplifiedForm({
        startDateCalendar: '',
        endDateCalendar: '',
    });

    useEffect(() => {
        elementProps.form.hookFormSetValue(
            'startDateCalendar',
            queryData.params['startDate'] ? helperAct.escapeHtml(queryData.params['startDate']) : '',
        );
        elementProps.form.hookFormSetValue(
            'endDateCalendar',
            queryData.params['endDate'] ? helperAct.escapeHtml(queryData.params['endDate']) : '',
        );

        const segmentGroups: any = {};

        const sortByLabel = (a: any, b: any) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        };
        //maybe use option with valueGroup;
        reportData.segments.forEach((segment: any) => {
            const groupName = segment.type || 'Other';
            if (!segmentGroups[groupName]) {
                segmentGroups[groupName] = { label: helperAct.ucwords(groupName.toLowerCase()), options: [] };
            }
            segmentGroups[groupName]['options'].push({
                label: helperAct.escapeHtml(segment.name),
                value: helperAct.escapeHtml(segment.segmentId),
            });
        });
        Object.keys(segmentGroups).forEach((groupKey) => {
            if (segmentGroups[groupKey].options?.length) {
                segmentGroups[groupKey].options.sort(sortByLabel);
            }
        });
        setSegmentOptions(
            Object.values(segmentGroups)
                .map((group) => group)
                .sort(sortByLabel),
        );
        setSelectedSegments(queryData.params['segments']);
    }, []);

    useEffect(() => {
        const newValue = elementProps.form.hookFormGetValues('startDateCalendar');
        queryAct.addParam('startDate', newValue);
    }, [elementProps.form.hookFormWatch('startDateCalendar')]);

    useEffect(() => {
        const newValue = elementProps.form.hookFormGetValues('endDateCalendar');
        queryAct.addParam('endDate', newValue);
    }, [elementProps.form.hookFormWatch('endDateCalendar')]);
    return (
        <Stack spacing={2} sx={{ pt: 2 }}>
            <Stack direction={'row'} spacing={2}>
                <Stack>
                    <Box>{t('metadata_components.param_start_date_label')}:</Box>
                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    component: 'FormDatePicker',
                                    name: 'startDateCalendar',
                                    props: {
                                        placeholder: t('query_builder_popup.date_placeholder'),
                                        readOnly: true,
                                    },
                                },
                            })}
                        />
                    </Box>
                </Stack>
                <Stack>
                    <Box>{t('metadata_components.param_end_date_label')}:</Box>
                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    component: 'FormDatePicker',
                                    name: 'endDateCalendar',
                                    props: {
                                        placeholder: t('query_builder_popup.date_placeholder'),
                                        readOnly: true,
                                    },
                                },
                            })}
                        />
                    </Box>
                </Stack>
                <Stack>
                    <Box>{t('metadata_components.param_limit_label')}:</Box>
                    <Box>
                        <TextField
                            onBlur={() => {
                                queryAct.addParam('limit', limitValue);
                            }}
                            value={limitValue}
                            onChange={(event) => {
                                setLimitValue(event.target.value);
                            }}
                        />
                    </Box>
                </Stack>
            </Stack>
            <Box>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Box flexShrink={0}>{t('metadata_components.param_segments_label')}:</Box>
                    <Box flexGrow={1}>
                        <ReactSelect
                            data={segmentOptions}
                            value={selectedSegments}
                            update={(segments) => {
                                setSelectedSegments(segments);
                                queryAct.addParam('segments', segments);
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                        />
                    </Box>
                </Stack>
            </Box>
            <Box>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Box flexShrink={0}>{t('metadata_components.param_filters_label')}:</Box>
                    <Box flexGrow={1}>
                        <TextField
                            onBlur={() => {
                                queryAct.addParam('filters', filtersValue);
                            }}
                            fullWidth
                            value={filtersValue}
                            onChange={(event) => {
                                setFiltersValue(event.target.value);
                            }}
                        />
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
}
