import React, { useState } from 'react';
import { FormComponentValue } from 'components/common/form/layout/control';
import { Autocomplete, TextField } from '@mui/material';

export function useBasicAutocomplete(defaultValue?: string) {
    const [expression, setExpression] = useState<string>(defaultValue ?? '');
    const [expressionError, setExpressionError] = useState(false);
    return { expression, setExpression, expressionError, setExpressionError };
}

export function BasicAutocomplete({
    options,
    wrapValue,
    multiline,
    expression,
    setExpression,
    expressionError,
    setExpressionError,
}: {
    options: Array<FormComponentValue>;
    wrapValue?: boolean;
    multiline?: boolean;
    expression: string;
    setExpression: React.Dispatch<string>;
    expressionError: boolean;
    setExpressionError: React.Dispatch<boolean>;
}) {
    const [autocompleteValue, setAutocompleteValue] = useState(''); //do not change. Workaround to use as add tags
    const [openSuggestion, setOpenSuggestion] = useState(false);
    //const [autocompleteFieldVal, setAutocompleteFieldVal] = useState<string>('start');
    const [suggestionState, setSuggestionState] = useState({
        startPos: 0,
        endPos: 0,
        search: '',
    });

    const suggestionStateReset = () => {
        setSuggestionState({
            startPos: 0,
            endPos: 0,
            search: '',
        });
    };

    return (
        <Autocomplete
            sx={{
                'textarea.MuiAutocomplete-input': { paddingTop: '6px !important', paddingBottom: '6px !important' },
                '.MuiInput-root .MuiAutocomplete-input': { px: 1.5, py: 0 },
            }}
            fullWidth
            freeSolo
            openOnFocus={false}
            disableClearable
            open={openSuggestion}
            value={autocompleteValue}
            inputValue={expression}
            filterOptions={(x: Array<FormComponentValue>, { inputValue }) => {
                if (!suggestionState.search) {
                    return x;
                }
                return x.filter((item) => {
                    return item.label.toLowerCase().includes(suggestionState.search);
                });
            }}
            onChange={(event, selectedItem: FormComponentValue | string, reason) => {
                const label = (selectedItem as FormComponentValue).value;
                if (reason === 'selectOption') {
                    const newValue =
                        expression.substring(0, suggestionState.startPos) +
                        (wrapValue ? `[${label}]` : label) +
                        expression.substring(suggestionState.endPos);
                    setExpression(newValue);
                    setOpenSuggestion(false);
                    setExpressionError(false);
                }
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    setExpression(value);
                    if (openSuggestion) {
                        let start = (event.target as HTMLInputElement).selectionStart || 0;
                        let end = (event.target as HTMLInputElement).selectionEnd || 0;

                        //ToDo change to substring or slice from depricated substr
                        let leftPartSearch = value.substr(0, start); //nearest text from left side of cursor to '[' without ']'
                        let rightPartReplace = value.substr(end); //nearest text from right side of cursor to ']' without '['

                        if (leftPartSearch.indexOf('[') > -1) {
                            leftPartSearch = leftPartSearch.split('[').pop() ?? '';
                            if (leftPartSearch.indexOf(']') !== -1) {
                                leftPartSearch = '';
                            } else {
                                start -= 1;
                            }
                        } else {
                            leftPartSearch = '';
                        }

                        if (rightPartReplace.indexOf(']') > -1) {
                            rightPartReplace = rightPartReplace.split(']').shift() ?? '';
                            if (rightPartReplace.indexOf('[') !== -1) {
                                rightPartReplace = '';
                            } else {
                                end += 1;
                            }
                        } else {
                            rightPartReplace = '';
                        }

                        setSuggestionState({
                            startPos: start - leftPartSearch.length,
                            endPos: end + rightPartReplace.length,
                            search: leftPartSearch,
                        });
                    }
                }
            }}
            onClose={(event, reason) => {
                if (reason === 'blur') {
                    setOpenSuggestion(false);
                }
            }}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    multiline={multiline}
                    rows={2}
                    onKeyDown={(event) => {
                        if (!openSuggestion) {
                            //if suggestion is not active - then clear data
                            suggestionStateReset();
                        }

                        if (
                            event.code === 'Escape' ||
                            (event.code === 'Backspace' && !suggestionState.search) ||
                            event.key === ']'
                        ) {
                            //close Suggestions
                            setOpenSuggestion(false);
                        } else if (event.key === '[' || event.key === 'BracketLeft') {
                            //Open Suggestions
                            setOpenSuggestion(true);
                        }
                    }}
                    error={expressionError}
                />
            )}
        />
    );
}
