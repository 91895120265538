import { FormElementControlPropsType, FormControlTemplateFiledProps, FormControlTextProps } from './index';
import { useForm } from 'react-hook-form';
import { FormRendererAPIType } from 'components/common/form/index';
import React, { useRef, useState } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import FormText from 'components/common/form/layout/control/FormText';
import FormTextarea from 'components/common/form/layout/control/FormTextarea';
import { FormComponentBuilder } from 'components/common/form/layout/index';

//ToDo Merge with component (unify)
//frontend/src/app/notification/burst/EmailSubjectLine.tsx
export default function FormTemplateField({
    controlProps,
}: FormElementControlPropsType<FormControlTemplateFiledProps>) {
    const [caretPos, setCaretPos] = useState<number>(0);
    const wrapperRef = useRef<HTMLDivElement>();
    const tagsWrapper = useRef<HTMLDivElement>();

    const addTag = (pattern: string) => {
        //ToDo maybe need to check left symbol if exist maxLength and show error or don't add
        const currentVal = controlProps.form.hookFormGetValues(controlProps.name) || '';
        const leftPart = currentVal.substring(caretPos, -1);
        const rightPart = currentVal.substring(caretPos);
        let field: HTMLTextAreaElement | HTMLInputElement | null | undefined;

        if (controlProps.multiline) {
            field = wrapperRef?.current?.querySelector('textarea');
        } else {
            field = wrapperRef?.current?.querySelector('input');
        }

        //Insert pattern
        controlProps.form.hookFormSetValue(controlProps.name, leftPart + pattern + rightPart);

        //return focus to field after insert pattern
        if (field) field.focus();

        setTimeout(() => {
            //set caret after pattern
            if (field) {
                field.selectionStart = leftPart.length + pattern.length;
                field.selectionEnd = leftPart.length + pattern.length;
            }
        }, 10);
    };

    const { setValue: hookFormSetValue, control, getValues: hookFromGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFromGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    const patternFieldProps: FormControlTextProps = {
        form: formActions,
        name: controlProps.name,
        label: controlProps.label,
        value: controlProps.value,
        onBlur: (e: any) => {
            //if lost blur by clicking on add pattern then remember caret pos, else reset to 0
            setCaretPos(tagsWrapper?.current?.contains(e.relatedTarget) ? e.target.selectionStart : 0);
        },
        onChange: controlProps.onChange,
    };

    const filed = controlProps.multiline ? (
        <FormTextarea
            controlProps={{
                ...patternFieldProps,
                rows: controlProps.rows,
                maxLength: controlProps.maxLength,
                charactersLeftLabel: controlProps.charactersLeftLabel,
            }}
        />
    ) : (
        <FormText controlProps={patternFieldProps} />
    );

    return (
        <Box ref={wrapperRef}>
            {filed}
            {controlProps.patterns && (
                <Stack direction="row" flexWrap="wrap" sx={{ m: '0 -3px 0' }}>
                    {controlProps.patterns.map((pattern) => {
                        return (
                            <Chip
                                key={pattern}
                                label={pattern}
                                color="primary"
                                sx={{ m: '6px 3px 0' }}
                                onClick={() => {
                                    addTag(pattern);
                                }}
                            />
                        );
                    })}
                </Stack>
            )}
        </Box>
    );
}

export class FormTemplateFieldBuilder extends FormComponentBuilder {
    prepareProps(): FormControlTemplateFiledProps {
        return {
            ...this.controlProps,
            placeholder: this.elementProps.componentProps?.placeholder ?? '',
            rows: this.elementProps.componentProps?.rows ?? 4,
            asLabel: this.elementProps.componentProps?.asLabel ?? false,
            maxLength: this.elementProps.componentProps?.maxLength,
            fieldType: this.elementProps.componentProps?.fieldType ?? 'text',
            charactersLeftLabel: this.elementProps.componentProps?.charactersLeftLabel,
            patterns: this.elementProps.componentProps?.patterns ?? [],
            multiline: this.elementProps.componentProps?.multiline ?? false,
        };
    }
}
