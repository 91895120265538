import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import {
    HeaderDropDownFieldType,
    HeaderDropDownTriggerClassName,
    HeaderResizableClassName,
} from 'components/report-content/components/dataset/index';
import IconMi from 'components/common/icon/IconMi';
import { alpha, Box } from '@mui/material';

export default function DatasetHeaderDropDownIcon({
    field,
    onClick,
}: {
    field: ComponentSettingsDatasetField;
    onClick: (headerDropDown: HeaderDropDownFieldType<ComponentSettingsDatasetField>) => void;
}) {
    return (
        <Box
            sx={{
                position: 'absolute',
                right: 4,
                top: 3,
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                backgroundColor: 'background.default',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
            className={HeaderDropDownTriggerClassName}
            onClick={(event) => {
                onClick({
                    field: field,
                    anchorEl: event.currentTarget,
                });
            }}
        >
            <IconMi icon={'chevron-down'} sx={{ fontSize: '15px', pointerEvents: 'none', color: 'primary.main' }} />
        </Box>
    );
}
