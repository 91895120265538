import React, { useContext, useRef, useState } from 'react';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button, Input, Stack, Typography } from '@mui/material';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import IconMi from 'components/common/icon/IconMi';
import { customRequestAPI } from 'api/api';
import StaticInfo from 'components/common/StaticInfo';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getAsFieldValue } from 'components/common/form/dependency/processEffect';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';

export interface IFormEmailTemplateCustomizationProps extends FormControlProps {
    emailType: string;
    blockType: string;
    variablesList: any[];
    resetValue: string;
}

export default function FormEmailTemplateCustomization({
    controlProps: props,
    elementProps: elProps,
}: FormElementControlPropsType<IFormEmailTemplateCustomizationProps>) {
    const { t } = useBundleTranslation(['app/admin/email-template/email-template-customization']);
    const formsContext = useContext<FormsContextType>(FormsContext);
    const { emailType, blockType, variablesList, resetValue } = props;
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const elementProps = useCustomSimplifiedForm();

    const previewIframe = useRef<HTMLFormElement>();

    const clickToShowPreview = () => {
        setIsLoadingPreview(true);
        setErrorMsg('');

        customRequestAPI
            .post(`/data/editor/hf-template/${props.form.hookFormGetValues('email_template_id')}/preview`, {
                html: props.value,
                type: 'header',
            })
            .then((response: any) => {
                if (response?.data?.status === 'OK') {
                    if (previewIframe?.current) {
                        previewIframe.current.contentDocument.open();
                        previewIframe.current.contentDocument.write(response.data.html);
                        previewIframe.current.contentDocument.close();
                        setShowPreview(true);
                    }
                } else if (response?.data?.status === 'ERROR') {
                    setErrorMsg(response.data.message);
                }
            })
            .catch((error: any) => {
                setErrorMsg(error.message);
            })
            .finally(() => {
                setIsLoadingPreview(false);
            });
    };

    props.form.hookFormWatch(emailType);
    props.form.hookFormWatch(blockType);

    const emailTypeLabelKey = `$${emailType}.$option.label`;
    const blockTypeLabelKey = `$${blockType}.$option.label`;

    const emailTypeLabel = elProps ? getAsFieldValue(emailTypeLabelKey, elProps, formsContext) : '';
    const blockTypeLabel = elProps ? getAsFieldValue(blockTypeLabelKey, elProps, formsContext) : '';

    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 2, flexGrow: 1 }}>
                    <Stack direction="row" spacing={2}>
                        <Box flexGrow="1" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <StaticAddon>{t('email_type')}</StaticAddon>
                            <Box sx={{ width: '144px' }}>
                                <Input
                                    fullWidth
                                    name={'email_type_val'}
                                    type={'text'}
                                    value={emailTypeLabel != emailTypeLabelKey ? emailTypeLabel : ''}
                                    readOnly
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        input: {
                                            color: (theme) => alpha(theme.palette.text.primary, 0.64),
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box flexGrow="1" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <StaticAddon>{t('block_type')}</StaticAddon>
                            <Box sx={{ width: '144px' }}>
                                <Input
                                    fullWidth
                                    name={'email_type_val'}
                                    type={'text'}
                                    value={blockTypeLabel != blockTypeLabelKey ? blockTypeLabel : ''}
                                    readOnly
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        input: {
                                            color: (theme) => alpha(theme.palette.text.primary, 0.64),
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                    <Box>
                        <Button
                            variant={'outlined'}
                            startIcon={<IconMi icon="rotate-ccw" />}
                            onClick={() => {
                                setShowPreview(false);
                                const value2reset = elProps?.data[props.name + '_default'] ?? resetValue;
                                props.form.hookFormSetValue(props.name, value2reset, { shouldDirty: true });
                            }}
                        >
                            {t('revert_btn')}
                        </Button>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ flexGrow: 1 }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    name: props.name,
                                    component: 'CodeEditor',

                                    props: {
                                        height: '560px',
                                    },
                                },
                                form: props.form,
                            })}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: 264,
                            flexShrink: 0,
                            p: 2,
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            borderRadius: 1,
                        }}
                    >
                        <Box sx={{ color: 'primary.main', fontWeight: 600, pb: 1, lineHeight: 1.15 }}>
                            {t('desc_block.title')}
                        </Box>
                        {variablesList.length > 0 && (
                            <Box sx={{ pb: 1 }}>
                                {variablesList.map((variable) => {
                                    return (
                                        <Box key={variable.key}>
                                            <Box component="strong">{variable.key}</Box>
                                            {variable.value && <Box component="span"> - {variable.value}</Box>}
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                        <Box>{t('desc_block.html_tags_note')}</Box>
                        <Box sx={{ pt: 2 }}>
                            <Box
                                component={'a'}
                                sx={{ display: 'flex' }}
                                href="http://help.metricinsights.com/m/Other_Topics/l/221466-customizing-header-and-footers-in-emails"
                                target="_blank"
                                className={'invert-link'}
                            >
                                <IconMi icon="training-and-docs" sx={{ mr: 0.5, fontSize: '16px' }} />
                                {t('desc_block.help_note')}
                            </Box>
                        </Box>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ flexGrow: 1, pt: 3 }}>
                        <Box
                            component="iframe"
                            ref={previewIframe}
                            name="previewIframe"
                            sx={{
                                width: 1,
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                borderRadius: 1,
                                mb: 2,
                                display: !showPreview ? 'none' : undefined,
                            }}
                        />
                        {showPreview ? (
                            <Box>
                                <Button
                                    variant={'light'}
                                    startIcon={<IconMi icon="times" />}
                                    onClick={() => {
                                        setShowPreview(false);
                                    }}
                                >
                                    {t('close_preview_btn')}
                                </Button>
                            </Box>
                        ) : (
                            <>
                                {errorMsg && (
                                    <Box sx={{ mb: 2 }}>
                                        <StaticInfo type={'error'} label={errorMsg} />
                                    </Box>
                                )}

                                <Button
                                    variant={'light'}
                                    startIcon={<IconMi icon="view" />}
                                    disabled={isLoadingPreview}
                                    onClick={clickToShowPreview}
                                >
                                    {t('preview_btn')}
                                </Button>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: 264,
                            flexShrink: 0,
                        }}
                    >
                        {''}
                    </Box>
                </Stack>
            </Box>
            {isLoadingPreview && (
                <LoadingPlaceholder>
                    <Typography>{t('generating_preview')}</Typography>
                </LoadingPlaceholder>
            )}
        </>
    );
}

export class FormEmailTemplateCustomizationBuilder extends FormComponentBuilder {
    prepareProps(): IFormEmailTemplateCustomizationProps {
        return {
            ...this.controlProps,
            emailType: this.elementProps.componentProps?.emailType,
            blockType: this.elementProps.componentProps?.blockType,
            variablesList: this.elementProps.componentProps?.variablesList ?? [],
            resetValue: this.elementProps.componentProps?.resetValue,
        };
    }
}
