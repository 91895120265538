import { alpha, Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useMemo, useState } from 'react';
import { MetricTemplate } from '../index';
import { Popup } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';

interface ITemplateSelector {
    selectedTemplate: string;
    templates: MetricTemplate[];
    onChangeTemplate: (template: MetricTemplate) => void;
}

export default function TemplateSelector({ selectedTemplate, templates, onChangeTemplate }: ITemplateSelector) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [showTemplateSelector, setShowTemplateSelector] = useState(false);
    const [search, setSearch] = useState('');

    const onClick = () => {
        setShowTemplateSelector(true);
    };

    const onClose = () => {
        setShowTemplateSelector(false);
        setSearch('');
    };

    const filteredTemplates = useMemo(() => {
        return templates.filter((template) => {
            const text =
                template.name + ' ' + template.description + ' ' + template.category_name + ' ' + template.topic_labels;
            return text.toLowerCase().includes(search.toLowerCase());
        });
    }, [search, templates]);

    if (templates.length < 2) {
        return null;
    }

    return (
        <>
            <Stack
                direction={'row'}
                sx={{
                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.04),
                    borderRadius: '3px',
                    height: '32px',
                    padding: '8px',
                }}
                justifyContent={'start'}
                alignItems={'center'}
                spacing={0.75}
            >
                <IconMi
                    icon="template"
                    fontSize="16"
                    sx={{
                        color: 'text.secondary',
                    }}
                />
                <Typography>{`${t('add_metric.template')}:`}</Typography>
                <Typography
                    sx={{
                        cursor: 'pointer',
                        color: (theme) => theme.palette.primary.main,
                    }}
                    onClick={onClick}
                    data-test={`metric-add-template-selector`}
                >
                    {selectedTemplate}
                </Typography>
            </Stack>
            {showTemplateSelector && (
                <Popup
                    settings={{
                        title: t('add_metric.template_selector.header'),
                        noButtons: true,
                    }}
                    open={true}
                    onHide={onClose}
                    maxWidth={'popupSm'}
                >
                    <TextField
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                        value={search}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconMi icon="search" fontSize={'16'} sx={{ color: 'primary.main' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Stack sx={{ padding: '8px 0px' }}>
                        {filteredTemplates.map((template, i) => (
                            <Stack
                                sx={{
                                    border: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    padding: '8px',
                                }}
                                spacing={0.5}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            cursor: 'pointer',
                                            color: (theme) => theme.palette.primary.main,
                                            fontWeight: 600,
                                            fontSize: '16',
                                        }}
                                        onClick={() => {
                                            onChangeTemplate(template);
                                            onClose();
                                        }}
                                    >
                                        {template.name}
                                    </Typography>
                                    <Typography variant={'caption'}>{template.description}</Typography>
                                </Box>
                                {template.category_name && (
                                    <Typography>{`${t('add_metric.template_selector.category')}: ${
                                        template.category_name
                                    }`}</Typography>
                                )}
                                {template.topic_labels && (
                                    <Typography>{`${t('add_metric.template_selector.tags')}: ${
                                        template.category_name
                                    }`}</Typography>
                                )}
                                {template.metric_chart_display_type && (
                                    <Typography>{`${t('add_metric.template_selector.show_as')}: ${
                                        template.metric_chart_display_type === 'bar' ? 'Bars' : 'Line'
                                    }`}</Typography>
                                )}
                                {template.additive_ind && template.additive_ind === 'Y' && (
                                    <Typography>{t('add_metric.template_selector.additive')}</Typography>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Popup>
            )}
        </>
    );
}
