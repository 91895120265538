import { alpha, Stack, Box } from '@mui/material';
import React from 'react';
import { MetricItem } from './index';
import Header from './preview/Header';
import Body from './preview/Body';
import SourcedContainer from './preview/SourcedContainer';
import { ColumnType, GridData } from 'components/common/grid';

interface IMetricPreviewProps {
    metrics: MetricItem[];
    activeMetricUid: string;
    onBuildMetrics: () => void;
    onMetricChange: (metricUid: string) => void;
    onMetricRemove: (metricUid: string) => void;
    gridData: GridData;
    columns: ColumnType[];
    reloadGridData: () => void;
    buildInProgress: boolean;
    filterId: number;
    datasetId: number;
}

export default function MetricPreview({
    metrics,
    activeMetricUid,
    onBuildMetrics,
    onMetricChange,
    onMetricRemove,
    gridData,
    columns,
    reloadGridData,
    buildInProgress,
    filterId,
    datasetId,
}: IMetricPreviewProps) {
    return (
        <Stack
            sx={{
                width: '80%',
                p: 3,
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    backgroundColor: (theme) => theme.palette.background.default,
                    boxShadow: (theme) => `0px 2px 8px 0px ${alpha(theme.palette.text.primary, 0.08)}`,
                    borderRadius: '3px',
                }}
            >
                <Header count={metrics.length} onBuildMetrics={onBuildMetrics} buildInProgress={buildInProgress} />
                <Body
                    metrics={metrics}
                    onMetricChange={onMetricChange}
                    activeMetricUid={activeMetricUid}
                    onMetricRemove={onMetricRemove}
                    filterId={filterId}
                    datasetId={datasetId}
                />
                <SourcedContainer gridData={gridData} columns={columns} reloadGridData={reloadGridData} />
            </Box>
        </Stack>
    );
}
