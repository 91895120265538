import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController, { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import DatasetField = ReportContentNS.DatasetField;
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import ComponentSettingsFunnelChart = ReportContentNS.ComponentSettingsFunnelChart;
import { sortOrderDirections } from 'components/report-content/components/manage-filters/edit-panel/FieldForm';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import DrillTo = ReportContentNS.DrillTo;
import DrillToField = ReportContentNS.DrillToField;
import DrillToForm from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/DrillToForm';
import { addOrReplaceInStateArray, removeFromStateArray } from 'tools/tools';
import DrillToList from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/DrillToList';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsFunnelChart> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'display_value',
];
const componentNumericFormField: Array<keyof ComponentSettingsFunnelChart> = ['height'];

export default function FunnelChartEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsFunnelChart> & { block: BlockType<ComponentSettingsFunnelChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        x_field: component.settings.x_field.reference_name,
        sort_field_by_field: component.settings.sort_field.by_field,
        sort_field_dir: component.settings.sort_field.dir,
    };

    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsFunnelChart = {
            ...component.settings,
            // measureFields: [measuredField],
        } as ComponentSettingsFunnelChart;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const sortDir = elementProps.form.hookFormGetValues('sort_field_dir');
        const sortField = elementProps.form.hookFormGetValues('sort_field_by_field');

        const newXField = datasetFields.find(
            (f) => f.reference_name == elementProps.form.hookFormGetValues('x_field'),
        ) as DatasetField;

        const resultFieldAggregations: any = {};
        resultFieldAggregations[measuredField.reference_name] = measuredField.aggregation_function;

        const dataQueries: ComponentSettingsChartDataQueries = {
            table: {
                resultFields: [newXField.reference_name, measuredField.reference_name],
                resultFieldAggregations: resultFieldAggregations,
                sort: [
                    {
                        field: sortField == 'value' ? measuredField.reference_name : newXField.reference_name,
                        dir: sortDir,
                    },
                ],
            },
        };

        const resultSettings = {
            ...newSettings,
            value_field: { ...measuredField },
            x_field: fieldToChartMeasuredField(newXField),
            sort_field: { by_field: sortField, dir: sortDir, value_type: sortFieldValueType },
            // localPalette: newLocalPalette,
            dataQueries: dataQueries,
            drillTo: drillToList.slice(),
        } as ComponentSettingsFunnelChart;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };

    const [measuredField, setMeasuredField] = useState(component.settings.value_field);
    const xFieldReferenceName = elementProps.form.hookFormWatch('x_field');
    const [xFieldLabel, setXFieldLabel] = useState('');
    useEffect(() => {
        const field = datasetFields.find((f) => f.reference_name == xFieldReferenceName);
        if (field) {
            setXFieldLabel(field.column_name);
        }
    }, [xFieldReferenceName]);

    const sortField = elementProps.form.hookFormWatch('sort_field_by_field');
    const [sortFieldValueType, setSortFieldValueType] = useState(component.settings.sort_field.value_type);
    useEffect(() => {
        const field = datasetFields.find(
            (f) => f.reference_name == (sortField == 'label' ? xFieldReferenceName : measuredField.reference_name),
        );

        if (field) {
            setSortFieldValueType(field.value_type);
        }
    }, [sortField, xFieldReferenceName, measuredField]);

    // Drill To Params
    const xField = elementProps.form.hookFormWatch('x_field');
    const [drillToList, setDrillToList] = useState(component.settings.drillTo ?? []);
    const [selectedDrillTo, setSelectedDrillTo] = useState<DrillTo | null>(null);
    const drillToFields: Array<DrillToField> = useMemo(() => {
        const x = datasetFields.find((f) => f.reference_name_escaped == xField);
        return [
            {
                reference_name: x?.reference_name_escaped ?? '',
                column_name: x?.column_name ?? '',
            },
            {
                reference_name: 'snapshot_date',
                column_name: t('snapshot_date'),
            },
        ].filter((row) => row.reference_name != '');
    }, [xField]);

    const actualHeight = elementProps.form.hookFormWatch('height');
    return selectedDrillTo ? (
        <DrillToForm
            allowedFields={drillToFields}
            contentSettings={contentSettings}
            drillTo={selectedDrillTo}
            drillToList={drillToList}
            onApply={(drillTo: DrillTo) => {
                addOrReplaceInStateArray(setDrillToList, (f) => f.elementId == selectedDrillTo.elementId, drillTo);
                setSelectedDrillTo(null);
            }}
            onCancel={() => setSelectedDrillTo(null)}
        />
    ) : (
        <Stack height={1}>
            {/*Main Panel*/}
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('funnel_chart.edit_title')}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: { component: 'FormText', name: 'title', label: t('title') },
                        })}
                    />
                </Box>
                <Divider />

                <Box>
                    <FormLabel>{t('labels')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields.map((field) => ({
                            label: field.column_name,
                            value: field.reference_name,
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'x_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>

                <AggregationField
                    label={t('value')}
                    allowedFields={datasetFields}
                    measuredField={measuredField}
                    setMeasuredField={setMeasuredField}
                    fieldToMeasuredField={fieldToChartMeasuredField}
                />

                <Box>
                    <FormLabel>{t('sort_order.label')}</FormLabel>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Box sx={{ width: '305px', flexShrink: 0 }}>
                            <TemplateFormComponent
                                elementProps={elementProps}
                                componentValues={[
                                    { label: xFieldLabel, value: 'label' },
                                    { label: measuredField.column_name, value: 'value' },
                                ]}
                                component={'FormSelect'}
                                name={'sort_field_by_field'}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <TemplateFormComponent
                                elementProps={elementProps}
                                componentValues={sortOrderDirections
                                    .filter((f) => f.valueType == sortFieldValueType)
                                    .map((f) => ({ label: t('manage_filters.' + f.label), value: f.order }))}
                                component={'FormSelect'}
                                name={'sort_field_dir'}
                            />
                        </Box>
                    </Stack>
                </Box>
                <Divider />
                <DrillToList
                    onSelectRow={(drillTo) => setSelectedDrillTo(drillTo)}
                    onRemoveRow={(elementId: number) =>
                        removeFromStateArray(setDrillToList, (f) => f.elementId == elementId)
                    }
                    contentSettings={contentSettings}
                    drillTo={drillToList}
                />
                <Divider />
                <Box>
                    <EditPanelCheckBox elementProps={elementProps} name={'display_value'} label={t('display_value')} />
                </Box>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />

                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_in_viewer'}
                        label={t('show_in_viewer')}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email'}
                        label={t('include_in_email')}
                    />
                </Box>
                <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                <Box>
                    <GetEmbedCode contentSettings={contentSettings} height={actualHeight} blockUID={block.uid} t={t} />
                </Box>
                <Divider />
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={handleApply}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
        </Stack>
    );
}
