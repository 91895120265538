import React, { useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import styles from './FolderSelect.styles';
import { TreeNodeProps } from 'components/common/tree/DefaultNode';
import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';
import { FolderData } from 'api/editor/element';

export const Node = ({
    node,
    params,
    additional,
}: TreeNodeProps<NodeModel, { selectedFolders: FolderData[]; onFolderSelect: (folder: NodeModel) => {} }>) => {
    const [hover, setHover] = useState(false);
    const nodeSelected =
        additional.selectedFolders && additional.selectedFolders.find((selected) => node.id === selected.folderId);

    const getClassName = () => {
        if (nodeSelected) {
            return `treeElementSelected`;
        }

        if (hover) {
            return `treeElementHover`;
        }

        return '';
    };

    const getFolderIcon = () => {
        if (params.hasChild) {
            return <IconMi icon={`folder-${params.isOpen ? 'minus' : 'plus'}`} fontSize="16" />;
        }

        return <IconMi icon={`folder`} fontSize="16" />;
    };

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={styles.treeElement}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={getClassName()}
        >
            <Stack sx={styles.treeElementName} direction="row" alignItems="center" spacing="6px" flexGrow={1}>
                <IconButton sx={{ p: 0, color: 'inherit' }} onClick={params.onToggle}>
                    {getFolderIcon()}
                </IconButton>
                {nodeSelected ? (
                    <Box component="span">{node.text}</Box>
                ) : (
                    <Box
                        component="span"
                        onClick={() => {
                            additional.onFolderSelect(node);
                        }}
                        sx={{ cursor: 'pointer' }}
                    >
                        {node.text}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export default Node;
