import { instance } from 'api/api';
import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';

export interface FolderData {
    folderPathParts: string[];
    folderName: string;
    folderId: number;
}

export const elementAPI = {
    async getFoldersData(baseUrl: string): Promise<FolderData[]> {
        const response = await instance.post(baseUrl + `load`);

        return response.data.data;
    },
    async getAvailableFolders(baseUrl: string): Promise<NodeModel[]> {
        const response = await instance.get(baseUrl + `load-folders`);

        return response.data.data;
    },
    async addFolder(baseUrl: string, folderId: string | number): Promise<{ status: string }> {
        const response = await instance.post(baseUrl + `${folderId}/add`);

        return response.data;
    },
    async deleteFolder(baseUrl: string, folderId: string | number): Promise<{ status: string }> {
        const response = await instance.delete(baseUrl + `${folderId}/delete`);

        return response.data;
    },
    async checkCategoryAccess(): Promise<{ status: string; message: string }> {
        const response = await instance.get(`/data/editor/element/check-category-access`);

        return response.data;
    },
};
