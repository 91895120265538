import {
    FormComponentValue,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { controlDataAPI } from 'api/api';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import {
    ColumnName,
    FormControlSelectMapProps,
    getSMFilterColumnValues,
    RowList,
    RowValues,
    SelectMapColumn,
    UniqueRule,
} from './index';
import SelectMapRow from './SelectMapRow';
import { alpha } from '@mui/material/styles';
import IconMi from 'components/common/icon/IconMi';
import { processSettingsUrl } from 'components/common/form/formTools';
import useBundleTranslation from 'i18n';
import { useBlendColors } from 'hooks/useDesign';

const emptySelectValue: FormComponentValue = { value: '', label: '--', hidden: false, disabled: false, props: {} };

export default function SelectMapControl({ controlProps }: FormElementControlPropsType<FormControlSelectMapProps>) {
    let queryKey = '';
    for (const [k, v] of Object.entries(controlProps.urlParams)) {
        queryKey += `${k}=${v}`;
    }
    const { t } = useBundleTranslation();
    const queryClient = useQueryClient();

    const firstColumnType =
        controlProps.overrideFirstColumnType ?? controlProps.controlComplexData.firstColumn.source.type;
    const secondColumnType =
        controlProps.overrideSecondColumnType ?? controlProps.controlComplexData.secondColumn.source.type;

    useEffect(() => {
        return () => queryClient.removeQueries({ queryKey: [queryKey + '_first'] });
    }, []);

    const { data: firstRequestData, isSuccess: isFirstRequestSuccess } = useQuery<any, Error>({
        queryKey: [queryKey + '_first'],
        queryFn: () => {
            if (controlProps.controlComplexData.firstColumn.source.type == 'request') {
                const settingsUrl = processSettingsUrl(
                    controlProps.controlComplexData.firstColumn.source.url,
                    Object.keys(controlProps.urlParams),
                    controlProps.urlParams,
                );
                return controlDataAPI.getAsyncData(settingsUrl);
            }
            return false;
        },
    });

    const { data: secondRequestData, isSuccess: isSecondRequestSuccess } = useQuery<any, Error>({
        queryKey: [queryKey + '_second'],
        queryFn: () => {
            if (controlProps.controlComplexData.secondColumn.source.type == 'request') {
                const settingsUrl = processSettingsUrl(
                    controlProps.controlComplexData.secondColumn.source.url,
                    Object.keys(controlProps.urlParams),
                    controlProps.urlParams,
                );
                return controlDataAPI.getAsyncData(settingsUrl);
            }
            return false;
        },
    });

    const prepareDataForColumn = function (column: SelectMapColumn, data: any) {
        let columnValuesData = [];
        if (column.source.type == 'request' && typeof data != 'undefined') {
            columnValuesData = data.data;
        }

        if (column.source.type == 'manual') {
            columnValuesData = column.source.manualData;
        }
        return columnValuesData;
    };

    const [firstColumnValues, setFirstColumnValues] = useState<Array<FormComponentValue> | false>(false);
    const [secondColumnValues, setSecondColumnValues] = useState<Array<FormComponentValue> | false>(false);
    const [columnsReady, setColumnsReady] = useState<boolean>(false);

    const [trackChanges, setTrackChanges] = useState(false);

    const hasSecondNested = useMemo(() => {
        if (!secondColumnValues) {
            return false;
        }
        return secondColumnValues.findIndex((v) => Object.entries(v?.props?.nestedValues ?? {}).length) != -1;
    }, [secondColumnValues]);

    useLayoutEffect(() => {
        if (!isFirstRequestSuccess) {
            return;
        }

        const data = prepareFormComponentValues(
            prepareDataForColumn(controlProps.controlComplexData.firstColumn, firstRequestData),
        );

        setFirstColumnValues(
            controlProps.controlComplexData.hasEmptyValue === false ? data : [emptySelectValue].concat(data),
        );
    }, [firstRequestData, isFirstRequestSuccess, controlProps.controlComplexData.firstColumn.source?.manualData]);

    useLayoutEffect(() => {
        if (!isSecondRequestSuccess) {
            return;
        }
        const data = prepareFormComponentValues(
            prepareFormComponentValues(
                prepareDataForColumn(controlProps.controlComplexData.secondColumn, secondRequestData),
            ),
        );
        setSecondColumnValues(
            controlProps.controlComplexData.hasEmptyValue === false ? data : [emptySelectValue].concat(data),
        );
    }, [secondRequestData, isSecondRequestSuccess, controlProps.controlComplexData.secondColumn.source?.manualData]);

    useLayoutEffect(() => {
        setColumnsReady(Boolean(firstColumnValues) && Boolean(secondColumnValues));
    }, [firstColumnValues, secondColumnValues]);

    const handleAddRow = function () {
        const newList = actualValues.slice();
        setTrackChanges(true);

        const firstLeft = getSMFilterColumnValues(
            firstColumnValues ? firstColumnValues : [],
            actualValues,
            controlProps.controlComplexData.uniqueRule,
            'first',
            false,
        );
        const secondLeft = getSMFilterColumnValues(
            secondColumnValues ? secondColumnValues : [],
            actualValues,
            controlProps.controlComplexData.uniqueRule,
            'second',
            false,
        );

        if (controlProps.controlComplexData.hasEmptyValue === false) {
            if (firstLeft.length === 0 || secondLeft.length === 0) {
                return;
            }
        }

        newList.push({
            firstColumn: firstColumnType != 'text' && firstLeft.length ? firstLeft[0].value : '',
            secondColumn: secondColumnType != 'text' && secondLeft.length ? secondLeft[0].value : '',
            data: null,
        });
        setActualValues(newList);
    };

    const [actualValues, setActualValues] = useState<Array<RowValues>>(controlProps.controlComplexData.actualValues);

    useLayoutEffect(() => {
        const processedActualValues: RowValues[] = controlProps.controlComplexData.actualValues.map((item) => {
            return {
                firstColumn: String(item.firstColumn),
                secondColumn: String(item.secondColumn),
                data: item.data,
                secondColumnNested: item.secondColumnNested ? String(item.secondColumnNested) : undefined,
            };
        });
        setActualValues(processedActualValues);
    }, [controlProps.controlComplexData.actualValues]);

    const handleSelectValueChange = function (value: any, column: ColumnName, rowIndex: number) {
        const list = actualValues.slice();
        switch (column) {
            case 'first':
                list[rowIndex].firstColumn = String(value);
                break;
            case 'second':
                list[rowIndex].secondColumn = String(value);
                list[rowIndex].secondColumnNested = undefined;
                break;
            case 'secondNested':
                list[rowIndex].secondColumnNested = String(value);
                break;
        }
        setTrackChanges(true);
        setActualValues(list);
    };

    const handleDataValueChange = (data: any, rowIndex: number) => {
        const list = actualValues.slice();
        if (JSON.stringify(list[rowIndex].data) == JSON.stringify(data)) {
            return;
        }
        list[rowIndex].data = data;
        setTrackChanges(true);
        setActualValues(list);
    };

    const handleRemoveRow = function (index: number) {
        const list = actualValues.slice();
        list.splice(index, 1);
        setTrackChanges(true);
        setActualValues(list);
    };

    useEffect(() => {
        const value = JSON.stringify(actualValues.filter((v) => v.secondColumn != '' && v.firstColumn != ''));

        controlProps.form.hookFormSetValue(controlProps.name, value, { shouldDirty: trackChanges });
        if (controlProps.afterChange) {
            controlProps.afterChange(value);
        }
    }, [actualValues]);

    const [rowsList, setRowsList] = useState<Array<RowList>>([]);
    const getSelectRowsData = (): Array<RowList> =>
        actualValues.map(
            (rowValues: RowValues): RowList => ({
                rowValues: rowValues,
                firstColumn: {
                    type: firstColumnType,
                    values:
                        firstColumnValues == false
                            ? []
                            : getSMFilterColumnValues(
                                  firstColumnValues,
                                  actualValues,
                                  controlProps.controlComplexData.uniqueRule,
                                  'first',
                                  rowValues.firstColumn,
                              ),
                },
                secondColumn: {
                    type: secondColumnType,
                    values:
                        secondColumnValues == false
                            ? []
                            : getSMFilterColumnValues(
                                  secondColumnValues,
                                  actualValues,
                                  controlProps.controlComplexData.uniqueRule,
                                  'second',
                                  rowValues.secondColumn,
                              ),
                },
            }),
        );
    useLayoutEffect(() => {
        setRowsList(getSelectRowsData());
    }, [
        actualValues,
        firstColumnValues,
        secondColumnValues,
        controlProps.overrideFirstColumnType,
        controlProps.overrideSecondColumnType,
    ]);

    useEffect(() => {
        if (!controlProps.controlComplexData.autoAddFirstRow || actualValues.length) {
            return;
        }
        setTimeout(() => handleAddRow(), 10);
    }, [JSON.stringify(firstColumnValues), JSON.stringify(secondColumnValues)]);

    if (!columnsReady) {
        return <div> Loading columns </div>;
    }

    return (
        <Box>
            <input
                name={controlProps.name}
                type={'hidden'}
                value={controlProps.value}
                onChange={controlProps.onChange}
            />
            <Stack
                direction="row"
                sx={{
                    backgroundColor: (theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                    color: (theme) => alpha(theme.palette.text.primary, 0.8),
                    py: '7px',
                    borderRadius: 1,
                }}
            >
                <Box sx={{ pl: 1.5, width: 1 }}>{t(controlProps.controlComplexData.firstColumn.label)}</Box>
                <Stack direction="row" alignItems="center" flexShrink={0} sx={{ px: 1, opacity: 0 }}>
                    <Box sx={{ width: 16 }}></Box>
                </Stack>
                <Box sx={{ pr: 1.5, width: 1 }}>{t(controlProps.controlComplexData.secondColumn.label)}</Box>
                {hasSecondNested && <Box sx={{ pl: 1.5, pr: 1.5, width: 1 }}></Box>}
                <Box flexShrink={0} sx={{ pr: 4.5 }} />
            </Stack>

            {rowsList.map((row, index) => (
                <SelectMapRow
                    key={index}
                    onSelectChange={(value, column) => handleSelectValueChange(value, column, index)}
                    onDataChange={(data: any) => handleDataValueChange(data, index)}
                    onRemoveRow={() => handleRemoveRow(index)}
                    rowList={row}
                    handleGearClick={controlProps.controlComplexData.handleGearClick}
                    actualValues={actualValues}
                    hasSecondNested={hasSecondNested}
                />
            ))}
            <Box sx={{ pt: 2 }}>
                <Button
                    data-test={'add-column-mapping'}
                    variant={'light'}
                    color={'success'}
                    startIcon={<IconMi icon="new" />}
                    onClick={handleAddRow}
                >
                    {t('add')}
                </Button>
            </Box>
        </Box>
    );
}
