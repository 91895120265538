import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        border: '1px solid',
        borderRadius: '3px',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        padding: '5px',
        minHeight: '150px',
    },
    noResultsWrapper: {
        fontSize: '13px',
        textAlign: 'center',
        margin: '5px 10px 5px 0px',
    },
    treeElement: {
        ml: -1,
        pl: 1,
        minHeight: '28px',
        '&.treeElementHover': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '&.treeElementSelected': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.04),
        },
    },
    treeElementName: {
        '.state-edit &': {
            color: (theme: Theme) => theme.palette.primary.main,
        },
        '.state-delete &': {
            color: (theme: Theme) => theme.palette.error.main,
        },
    },
};
