import { useQuery } from '@tanstack/react-query';
import { ExternalIcons, searchAPI } from 'api/search';
import React from 'react';
import IconMi from './IconMi';
import { Box } from '@mui/material';

const simpleIcons = ['elements', 'datasets', 'burst', 'all-items', 'charting-options'];

export default function DatabaseIcon({
    name,
    type,
    placeholderIconName,
}: {
    name: string;
    type?: string;
    placeholderIconName?: string;
}) {
    if (name === '') {
        return null;
    }

    const iconWrapper = (children: any) => {
        return (
            <Box
                sx={{
                    width: '16px',
                    height: '16px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    img: { maxWidth: '100%', maxHeight: '100%' },
                }}
            >
                {children}
            </Box>
        );
    };

    if (simpleIcons.includes(name)) {
        return iconWrapper(<IconMi icon={name} sx={{ fontSize: '16px' }} />);
    }

    const { data: externalIcons } = useQuery<ExternalIcons, Error>({
        queryKey: ['fetchGlobalSearchIcons'],
        queryFn: () => searchAPI.getExternalIcons(),
    });

    if (externalIcons && externalIcons[name]) {
        return iconWrapper(<Box component={'img'} src={externalIcons[name]} />);
    }

    if (type == 'dataset_data') {
        return iconWrapper(<Box component={'img'} src={`data:image/png;base64,${name}`} />);
    }

    if (placeholderIconName) {
        return iconWrapper(<IconMi icon={placeholderIconName} sx={{ fontSize: '16px' }} />);
    }

    return null;
}
