import useBundleTranslation from 'i18n';
import { FilterType, getFiltersStateBuilder } from './Filter';
import BookmarkDropdown, { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import FilterBlock from 'components/external-reference/FilterBlock';
import BookmarkManager from 'components/external-reference/bookmark/BookmarManager';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { Box, Stack, Button, Grid, Tooltip } from '@mui/material';
import { alpha } from '@mui/material';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import IconMi from 'components/common/icon/IconMi';
import {
    getNewBookmarkObj,
    processFiltersForBookmark,
    setBookmarkReferenceId,
} from 'components/external-reference/bookmark/Bookmark';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';

export default function ReferenceManager({
    elementId,
    segmentValueId,
    filters,
    bookmarks,
    siblingTabs,
    selectedReferenceId,
    appliedBookmarkId,
    onFilterChange,
    onApplyFilterChanges,
    onRevertFilterChanges,
    onApplyBookmark,
    onBookmarksListUpdate,
    fullscreenMode = false,
    fullScreenFadeOut = false,
    isFiltersChanged = false,
    isFiltersReversible = false,
    fullscreenModePanelPos,
    isExpandedFilters,
    toggleExpandedState = () => {},
    APIUpdateBookmark,
    APISaveAsNewBookmark,
    APIDeleteBookmark,
    APIDuplicateBookmark,
    APISortBookmarks,
}: {
    elementId: number;
    segmentValueId: number;
    filters: Array<FilterType>;
    bookmarks: Array<BookmarkType>;
    siblingTabs: Array<SiblingTabType>;
    selectedReferenceId: number;
    appliedBookmarkId: number;
    onFilterChange: any;
    onApplyFilterChanges: any;
    onRevertFilterChanges: any;
    onApplyBookmark: any;
    onBookmarksListUpdate: any;
    fullscreenMode?: boolean;
    fullScreenFadeOut?: boolean;
    isFiltersChanged?: boolean;
    isFiltersReversible?: boolean;
    fullscreenModePanelPos?: string;
    isExpandedFilters: boolean;
    toggleExpandedState: () => void;
    APIUpdateBookmark: (bookmark: BookmarkType | null, selectedReferenceId: number) => void;
    APISaveAsNewBookmark: (bookmark: BookmarkType | null, selectedReferenceId: number) => void;
    APIDeleteBookmark: (bookmarkId: number) => void;
    APIDuplicateBookmark: (bookmarkId: number) => void;
    APISortBookmarks: (oldIndex: number, newIndex: number) => void;
}) {
    const { t } = useBundleTranslation([
        'components/external-reference/bookmark',
        'extreport',
        'components/external-reference/filter_block',
    ]);
    // Last applied filters state
    // API: viewerAPI.buildExternalUrl uses this object to build new embedUrl
    // Updated on handleApplyFilterChanges

    const [actualFiltersState, setActualFiltersState] = useState(getFiltersStateBuilder(filters.slice()));

    const updateActualFiltersState = function () {
        const newActualState = getFiltersStateBuilder(filters);
        if (JSON.stringify(newActualState) == JSON.stringify(actualFiltersState)) {
            return false;
        }
        setActualFiltersState(newActualState);
        return true;
    };

    const handleApplyFilterChanges = function (filters: Array<FilterType>) {
        if (updateActualFiltersState()) {
            return onApplyFilterChanges(filters);
        }
    };

    const handleRevertFilterChanges = function () {
        onRevertFilterChanges();
        setTimeout(() => updateActualFiltersState(), 0);
    };

    //Bookmarks section

    const [managerPopup, setManagerPopup] = useState<boolean>(false);

    const handleHideManager = function () {
        setSelectedBookmark(undefined);
        setManagerPopup(false);
    };
    const handleShowManager = function () {
        setManagerPopup(true);
    };

    const filtersBlock = (
        <FilterBlock
            onApplyFilterChanges={handleApplyFilterChanges}
            onFilterChange={onFilterChange}
            filters={filters}
            isFiltersChanged={isFiltersChanged}
            fullScreenMode={fullscreenMode}
            fullscreenModePanelPos={fullscreenModePanelPos}
            isExpandedFilters={isExpandedFilters}
            toggleExpandedState={toggleExpandedState}
            elementId={elementId}
        />
    );

    const [selectedBookmark, setSelectedBookmark] = useState<BookmarkType | undefined>();
    const isVisibleFilters = useEmbeddingContextVisible('eFilters');
    const isBookmarkDropdownVisible = useEmbeddingContextVisible('bookmark');
    const bookmarkDropdown = useMemo(() => {
        if (!isBookmarkDropdownVisible) {
            return null;
        }
        if (
            (filters.filter((f) => !f.replacedBySegment && f.filterId > 0).length == 0 || !isVisibleFilters) &&
            siblingTabs.length < 2
        ) {
            return null;
        }
        return (
            <BookmarkDropdown
                segmentValueId={segmentValueId}
                showManagerButton={!fullscreenMode}
                appliedBookmarkId={appliedBookmarkId}
                onUpdateClick={() => APIUpdateBookmark(null, selectedReferenceId)}
                onSaveAsNewClick={() => {
                    const bookmark = getNewBookmarkObj();
                    if (selectedReferenceId && siblingTabs.length) {
                        let siblingTab = siblingTabs.find((t) => t.referenceId == Number(selectedReferenceId));
                        if (!siblingTab) {
                            siblingTab = siblingTabs[0];
                        }
                        setBookmarkReferenceId(bookmark, siblingTab);
                    }
                    bookmark.filtersList = processFiltersForBookmark(filters);
                    setSelectedBookmark(bookmark);
                    handleShowManager();
                }}
                onApplyBookmark={onApplyBookmark}
                onShowManager={handleShowManager}
                bookmarks={bookmarks}
                filters={filters}
                siblingTabs={siblingTabs}
                fullScreenMode={fullscreenMode}
                fullscreenModePanelPos={fullscreenModePanelPos}
            />
        );
    }, [JSON.stringify(filters), JSON.stringify(bookmarks), segmentValueId, elementId, isBookmarkDropdownVisible]);

    const resetButton = (
        <Tooltip title={t('filter_block_reset_changes')}>
            <Box component={'span'}>
                <Button
                    data-test={'reference-filter-reset'}
                    disabled={!isFiltersReversible}
                    onClick={handleRevertFilterChanges}
                    variant="outlined"
                    className={'min-width--icon'}
                >
                    <IconMi icon="rotate-ccw" fontSize="16" />
                </Button>
            </Box>
        </Tooltip>
    );
    const separatorItem = (
        <Stack justifyContent="center" sx={{ height: '28px', mx: 0.5 }}>
            <Box
                sx={{
                    borderLeft: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    height: '16px',
                }}
            />
        </Stack>
    );

    const defaultPopupSettings: PopupSettings = {
        title: t('bookmarks'),
        textOK: t('form:button_done'),
        noCancel: true,
    };
    const [popupSettings, setPopupSettings] = useState(defaultPopupSettings);
    const handleManagerOnEditMode = (onEditMode: boolean) => {
        if (onEditMode) {
            setPopupSettings({ ...defaultPopupSettings, noButtons: true });
        } else {
            setPopupSettings(defaultPopupSettings);
        }
    };
    const visibleInViewerFilters = filters.filter((f) => f.visibleInViewer && !f.replacedBySegment).length;

    return (
        <>
            {fullscreenMode ? (
                <FullscreenLayout
                    fullScreenFadeOut={fullScreenFadeOut}
                    filterBlock={filtersBlock}
                    bookmarkDropdown={bookmarkDropdown}
                    resetButton={resetButton}
                    separatorItem={separatorItem}
                    isExpandedFilters={isExpandedFilters}
                />
            ) : (
                <PageLayout
                    visibleInViewerFilters={visibleInViewerFilters}
                    filterBlock={filtersBlock}
                    bookmarkDropdown={bookmarkDropdown}
                    resetButton={resetButton}
                    separatorItem={separatorItem}
                    isExpandedFilters={isExpandedFilters}
                />
            )}
            <Popup maxWidth="popupMd" settings={popupSettings} onHide={handleHideManager} open={managerPopup}>
                <BookmarkManager
                    managerContextType={'bookmark'}
                    setOnEditMode={handleManagerOnEditMode}
                    bookmarkForEdit={selectedBookmark}
                    elementId={elementId}
                    segmentValueId={segmentValueId}
                    bookmarks={bookmarks}
                    filters={filters}
                    siblingTabs={siblingTabs}
                    onDeleteClick={APIDeleteBookmark}
                    onDuplicateClick={APIDuplicateBookmark}
                    onSortEnd={APISortBookmarks}
                    referenceId={selectedReferenceId}
                    onUpdateClick={(bookmark: BookmarkType) => {
                        APIUpdateBookmark(bookmark, selectedReferenceId);
                        if (selectedBookmark) {
                            handleHideManager();
                        }
                    }}
                />
            </Popup>
        </>
    );
}

function PageLayout({
    filterBlock,
    bookmarkDropdown,
    resetButton,
    separatorItem,
    isExpandedFilters,
    visibleInViewerFilters,
}: {
    filterBlock: any;
    bookmarkDropdown: any;
    resetButton: any;
    separatorItem: any;
    isExpandedFilters: boolean;
    visibleInViewerFilters: number;
}) {
    return (
        <Box
            sx={{
                width: isExpandedFilters ? '100%' : undefined,
            }}
        >
            <Grid container item alignItems={'flex-end'} rowSpacing={2} columnSpacing={isExpandedFilters ? 3 : 1}>
                {filterBlock}
                {bookmarkDropdown != null && <Box sx={{ ml: 1 }}>{bookmarkDropdown}</Box>}
                {visibleInViewerFilters > 0 && (
                    <>
                        <Box sx={{ mx: 1 }}>{separatorItem}</Box>
                        {resetButton}
                    </>
                )}
            </Grid>
        </Box>
    );
}

function FullscreenLayout({
    fullScreenFadeOut,
    filterBlock,
    bookmarkDropdown,
    resetButton,
    separatorItem,
    isExpandedFilters,
}: {
    fullScreenFadeOut: boolean;
    filterBlock: any;
    bookmarkDropdown: any;
    resetButton: any;
    separatorItem: any;
    isExpandedFilters: boolean;
}) {
    // FadeOut Filters Blocks in FullScreen mode
    const [fadeOut, setFadeOut] = useState<boolean>(false);
    const [fadeOutTimeout, setFadeOutTimeout] = useState<any>(null);

    const removeTimer = () => {
        if (fadeOutTimeout != null) {
            window.clearTimeout(fadeOutTimeout);
        }
    };
    const runFadeOutTimeOut = function () {
        if (!fullScreenFadeOut) {
            return false;
        }
        removeTimer();
        setFadeOutTimeout(
            window.setTimeout(() => {
                setFadeOut(true);
            }, 2000),
        );
    };

    const clearRunFadeTimeOut = function () {
        if (fadeOutTimeout != null) {
            clearTimeout(fadeOutTimeout);
            setFadeOutTimeout(null);
        }
    };

    const handleMouseEnter = function () {
        removeTimer();
        setFadeOut(false);
    };
    const handleMouseLeave = function () {
        runFadeOutTimeOut();
    };

    useEffect(() => {
        runFadeOutTimeOut();
        return function () {
            clearRunFadeTimeOut();
        };
    }, []);

    return (
        <Box
            className={fadeOut ? 'fade-out' : 'fade-in'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                width: isExpandedFilters ? '100%' : undefined,
                boxShadow: (theme) => `0px 8px 20px  ${alpha(theme.palette.text.primary, 0.4)}`,
                borderRadius: 1,
                backgroundColor: 'background.default',
                px: isExpandedFilters ? 2 : 1.5,
                py: isExpandedFilters ? 3 : 1.5,
                my: isExpandedFilters ? 0 : 3,
            }}
        >
            <Grid container item alignItems={'flex-end'} rowSpacing={2} columnSpacing={isExpandedFilters ? 3 : 1}>
                {filterBlock}
                <Box sx={{ ml: 1 }}>{bookmarkDropdown}</Box>
                <Box sx={{ mx: 1 }}>{separatorItem}</Box>
                {resetButton}
            </Grid>
        </Box>
    );
}
