import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import useBundleTranslation from 'i18n';
import { ActSubmit } from 'components/common/form/act';
import React, { useEffect, useRef, useState } from 'react';
import { FormRendererProps } from 'components/common/form';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { createPortal } from 'react-dom';
import { Popup, PopupSettings } from 'components/common/popup/Popup';

export default function ShareElementForm({
    elementId,
    segmentValueId,
    filters,
    isOpen,
    setIsOpen,
}: {
    elementId: number;
    segmentValueId: number;
    filters?: string;
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}) {
    const hookForm = useCustomSimplifiedForm(
        {
            filters: filters,
        },
        {
            uid: 'shareElementForm',
            isPopup: true,
            settingsUrl: `/data/element/share/${elementId}/${segmentValueId}/form`,
        } as FormRendererProps,
    );

    useEffect(() => {
        hookForm.form.hookFormSetValue('filters', filters);
    }, [filters]);

    const { t } = useBundleTranslation();
    hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            exec = async (data: any) => {
                return super.exec(data).then((response) => {
                    if (response?.data?.status == 'OK') {
                        alert(t('your_content_shared'));
                        setIsOpen(false);
                    }
                    return response;
                });
            };
        },
    );

    const submit = useRef(
        hookForm.form.hookFormHandleSubmit(function (data) {
            return hookForm.form.formAct.act.submit.exec(data);
        }),
    );

    const popupSettings: PopupSettings = { title: t('share_now'), textOK: t('share') };
    return (
        <Popup
            settings={popupSettings}
            open={isOpen}
            onConfirm={() => {
                hookForm.form.formAct.act.submit.exec(hookForm.form.hookFormGetValues());
            }}
            onHide={() => setIsOpen(false)}
        >
            <form onSubmit={submit.current}>
                {createPortal(
                    <Backdrop
                        sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.modal + 1 }}
                        open={hookForm.state.loadingMaskShow}
                    >
                        {hookForm.state.loadingMaskText}
                        <CircularProgress color="inherit" />
                    </Backdrop>,
                    document.body,
                )}
                <Stack className={'form-components-indent'}>
                    <TemplateFormComponent
                        wrapper
                        elementProps={hookForm}
                        component={'CheckBoxList'}
                        name={'emails'}
                        props={{
                            async: true,
                            label: 'share_with',
                            settingsUrl:
                                '/data/notification/favorites/get-users-settings?elementId=:elementId&segmentId=:segmentId',
                            dataUrl:
                                '/data/notification/favorites/get-users-data?elementId=:elementId&segmentId=:segmentId',
                            dataPageSize: 100,
                            showCounter: true,
                            selectAllLabel: 'users',
                            urlParams: {
                                elementId: elementId,
                                segmentId: segmentValueId,
                            },
                        }}
                    />
                    <TemplateFormComponent
                        wrapper
                        elementProps={hookForm}
                        component={'FormTextarea'}
                        name={'message'}
                        props={{
                            label: 'add_a_comment',
                        }}
                    />
                    <TemplateFormComponent
                        wrapper
                        elementProps={hookForm}
                        component={'EmbedCodePopup'}
                        name={'embed'}
                        props={{
                            type: 'element',
                            id: elementId,
                            elementType: 'element',
                        }}
                    />
                </Stack>
            </form>
        </Popup>
    );
}
