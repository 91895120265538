import React from 'react';
import { Box, Stack } from '@mui/material';
import styles from './styles';
import IconMi from 'components/common/icon/IconMi';
import { format } from 'date-fns/format';
import { TileInfo } from 'app/home/index.functions';
import CertificationData from 'components/common/CertificationData';

export default function InfoCertification({ tile, showDivider = true }: { tile: TileInfo; showDivider?: boolean }) {
    if (tile.certified_ind === 'N') {
        return null;
    }

    return (
        <Stack direction="row" sx={styles.sectionWrapper}>
            <Box sx={styles.iconHolder}>
                <IconMi
                    icon="certified"
                    sx={{ fontSize: '16px', color: tile.certification_level_color || 'success.main' }}
                />
            </Box>
            <Box sx={styles.breakWordValue}>
                <Box
                    component="span"
                    sx={{
                        '.certification_by_name': {
                            cursor: 'pointer',
                        },
                    }}
                >
                    <CertificationData
                        certificationData={{
                            color: tile.certification_level_color || 'success.main',
                            name: tile.certification_level_name,
                            lastUpdatedBy: tile.last_certified_by_name ?? '',
                            lastUpdatedTime:
                                tile.last_certified_time && '0000-00-00 00:00:00' != tile.last_certified_time
                                    ? format(new Date(tile.last_certified_time), 'M/dd/yyyy')
                                    : '',
                        }}
                        onUserNameClick={() => {
                            window.location.href = `mailto:${tile.last_certified_by_email}`;
                        }}
                        markUserName={true}
                    />
                </Box>
            </Box>
        </Stack>
    );
}
