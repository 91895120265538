import { alpha, Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { Link } from 'react-router-dom';
import useBundleTranslation from 'i18n';
import styles from 'components/common/page-menu/PageMenu.styles';

export default function PanelBackButton({
    settings,
}: {
    settings?: {
        href: string;
    };
}) {
    const { t } = useBundleTranslation();
    if (!settings?.href) return null;
    return (
        <>
            <Tabs value={false} onChange={() => {}} sx={{ ...styles.tabs, mb: 0, flexShrink: 0, flexGrow: 0 }}>
                <Tab
                    icon={<IconMi icon={'chevron-left'} sx={styles.tabItem} />}
                    iconPosition="start"
                    component={Link}
                    to={settings.href}
                    label={<Box sx={styles.labelHolder}>{t('back')}</Box>}
                />
            </Tabs>
            <Box
                sx={{
                    width: '1px',
                    height: '16px',
                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    flexShrink: 0,
                }}
            />
        </>
    );
}
