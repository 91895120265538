import React from 'react';
import { ReportTooltipProps } from 'components/report-content/tooltip';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsFunnelChart = ReportContentNS.ComponentSettingsFunnelChart;
import DrillToTooltipPart, { TooltipDrillData } from 'components/report-content/tooltip/DrillToTooltipPart';
import { Box, Stack } from '@mui/material';
import styles from 'app/home/tile/components/info-panel/styles';
import ReportChartPoint = ReportContentNS.ReportChartPoint;

interface FunnelChartPoint extends ReportChartPoint {
    y_f?: string;
}

export default function FunnelChartTooltip({
    point,
    settings,
    contentSettings,
    closeComponent,
}: ReportTooltipProps<ComponentSettingsFunnelChart>) {
    const funnelPoint: FunnelChartPoint = point as FunnelChartPoint;

    const drillData: Array<TooltipDrillData> = [
        {
            field: settings.x_field.reference_name_escaped,
            value: point.name,
        },
        {
            field: 'snapshot_date',
            value: contentSettings.measurement_time ?? '',
        },
    ];

    if (!funnelPoint.y_f) {
        return null;
    }

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                justifyContent={'space-between'}
                sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0, paddingTop: 0, height: 29 }}
            >
                <Stack direction={'row'}>
                    <Box
                        style={{ color: String(point.color) }}
                        dangerouslySetInnerHTML={{ __html: funnelPoint.name + ':&nbsp;' }}
                    />
                    <Box>{funnelPoint.y_f}</Box>
                </Stack>
                <Box>{closeComponent}</Box>
            </Stack>
            <DrillToTooltipPart contentSettings={contentSettings} data={drillData} settings={settings} />
        </Stack>
    );
}
