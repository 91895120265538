import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default {
    infoWrapper: {
        '.c_dependentOfSetColors': {
            color: 'primary.main',
        },
    },
    infoTable: {
        display: 'table',
        borderCollapse: 'separate',
        borderSpacing: '0 16px',
        width: '100%',
    },
    infoRow: {
        display: 'table-row',
        width: '100%',
    },
    infoRowLabel: {
        display: 'table-cell',
        whiteSpace: 'nowrap',
        backgroundColor: (theme: Theme) => `${alpha(theme.palette.text.primary, 0.04)}`,
        px: 2,
        py: 1.5,
        border: '1px solid',
        borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
        color: (theme: Theme) => `${alpha(theme.palette.text.primary, 0.8)}`,
        borderRadius: '4px 0 0 4px',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: '148px',
    },
    infoRowValue: {
        display: 'table-cell',
        py: 1.5,
        px: 2,
        border: '1px solid',
        borderLeft: 0,
        borderRight: 0,
        borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
        verticalAlign: 'middle',
        fontWeight: 600,
        '.actionRow &:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    infoRowAction: {
        display: 'table-cell',
        py: 1.5,
        pr: 2,
        border: '1px solid',
        borderLeft: 0,
        borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
        borderRadius: '0 4px 4px 0',
        textAlign: 'right',
        verticalAlign: 'middle',
    },
    infoRowActionBtn: {
        whiteSpace: 'nowrap',
        flexShrink: 0,
    },
    elementHolder: {
        fontWeight: 600,
        fontSize: '14px',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
};
