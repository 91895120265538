import React, { useMemo, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useBundleTranslation from 'i18n';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { Box, Button } from '@mui/material';
import { getElementFieldValue } from 'components/common/form/formTools';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useFormSlot } from 'components/common/form/layout/control/FormComponentSlot';
import pollingService from 'tools/pollingService';
import { instance } from 'api/api';
import { useQueryClient } from '@tanstack/react-query';
import { FormDataAPIType } from 'components/common/form';
import IconMi from 'components/common/icon/IconMi';
import { ActAfterSaveSuccess } from 'components/common/form/act';

interface ResponseRecord {
    status: 'OK' | 'ERROR';
    name: string;
    external_report_id: string;
}

interface ResponseData {
    [key: string]: ResponseRecord;
}

function RefreshList({ hookForm }: { hookForm: FormDataAPIType }) {
    const { t } = useBundleTranslation(['app/editor/ext-content-mass']);
    const [showLoader, setShowLoader] = useState(false);
    const queryClient = useQueryClient();
    const onSuccess = (response: any) => {
        setShowLoader(false);
        hookForm.form.hookFormSetValue('external_report_list', '[]', { shouldDirty: false });
        queryClient
            .resetQueries({
                predicate: (query) => query.queryKey[0] == 'external_report_list',
            })
            .then(() => {
                setTimeout(() => hookForm.form.hookFormReset(undefined, { keepValues: true }), 500);
            });
    };
    const onError = (response: any) => {
        setShowLoader(false);

        if (response?.message) {
            alert(response.message);
        }
    };

    const handleClick = () => {
        setShowLoader(true);
        const profile = hookForm.form.hookFormGetValues('plugin_connection_profile_id');
        instance
            .get(`/data/editor/plugin-source/${profile}/external-report-list/refresh`)
            .then((response) => {
                const { run } = pollingService({
                    onSuccess,
                    onError,
                    requestData: {},
                });
                run(response.data.data.ppl_id);
            })
            .catch(() => {
                setShowLoader(false);
            });
    };

    return (
        <div>
            {showLoader && <LoadingPlaceholder />}
            <Button variant="outlined" startIcon={<IconMi icon="refresh-cw" />} onClick={() => handleClick()}>
                {t('refresh_list')}
            </Button>
        </div>
    );
}

export default function ExtContentReportMass(props: IFormLayoutProps) {
    const queryClient = useQueryClient();
    const { t } = useBundleTranslation(['app/editor/ext-content-mass']);
    const navigate = useNavigate();
    const isReport = props.uid == 'extReportMassForm';

    props.hookForm.form.formAct.overrideAct(
        'afterSaveSuccess',
        class extends ActAfterSaveSuccess {
            exec = async (response: any, data: any) => {
                return super.exec(response, data).then((response) => {
                    setCategoryName(getElementFieldValue(props.hookForm.form, 'category_id.$option.label') ?? '');
                    setTemplateValue(getElementFieldValue(props.hookForm.form, 'dashboard_element_template_id') ?? '');
                    setResultPopupOpen(true);

                    const resultList: Array<ResponseRecord> = [];
                    for (const [k, v] of Object.entries(response.data.data)) {
                        resultList.push(v as ResponseRecord);
                    }

                    setSuccessList(resultList.filter((r) => r.status == 'OK'));
                    setFailedList(resultList.filter((r) => r.status != 'OK'));
                    props.hookForm.form.hookFormSetValue('external_report_list', '[]', { shouldDirty: false });
                    queryClient
                        .resetQueries({
                            predicate: (query) => query.queryKey[0] == 'external_report_list',
                        })
                        .then(() => {
                            setTimeout(() => props.hookForm.form.hookFormReset(undefined, { keepValues: true }), 500);
                        });
                    return response;
                });
            };
        },
    );

    const [resultPopupOpen, setResultPopupOpen] = useState(false);
    const [successList, setSuccessList] = useState<Array<ResponseRecord>>([]);
    const [failedList, setFailedList] = useState<Array<ResponseRecord>>([]);
    const [categoryName, setCategoryName] = useState('');
    const [templateValue, setTemplateValue] = useState('');
    const popupSettings: PopupSettings = {
        title: t('results'),
        noOk: true,
        noCancel: true,
    };

    const onPopupHide = () => {
        setTemplateValue('');
        setCategoryName('');
        setResultPopupOpen(false);
    };

    const buttons = useMemo(() => {
        const list = [
            <Button variant="outlined" onClick={onPopupHide}>
                {t('add_more_ext_' + (isReport ? 'report' : 'content'))}
            </Button>,
        ];

        if (successList.length) {
            return [
                <Button
                    onClick={() => {
                        onPopupHide();
                        navigate('/home/#/category/' + props.hookForm.form.hookFormGetValues('category_id'));
                    }}
                >
                    {t('view_on_home_page')}
                </Button>,
                <Button
                    variant="outlined"
                    onClick={() => {
                        onPopupHide();
                        navigate('/editor/#/category/' + props.hookForm.form.hookFormGetValues('category_id'));
                    }}
                >
                    {t('elements_list')}
                </Button>,
            ].concat(list);
        }
        return list;
    }, [successList]);

    const slot = useFormSlot('dRefreshSlot');

    const isTemplate = Number(templateValue) > 0;

    return (
        <>
            <FormLayoutComposer props={props} />
            {slot && createPortal(<RefreshList hookForm={props.hookForm} />, slot)}
            <Popup additionalButtons={buttons} settings={popupSettings} open={resultPopupOpen} onHide={onPopupHide}>
                {successList.length > 0 ? (
                    <>
                        <span>
                            {isTemplate
                                ? t('successfully_added_template')
                                : t('successfully_added', { categoryName: categoryName })}
                            :
                        </span>
                        <Box>
                            {successList.map((r) => (
                                <div key={r.external_report_id}>{r.name}</div>
                            ))}
                        </Box>
                    </>
                ) : (
                    <span>
                        {t('no_successfully_added')}
                        <br />
                        <br />
                    </span>
                )}
                {failedList.length > 0 && (
                    <>
                        <span>{t('failed')}</span>
                        <Box>
                            {failedList.map((r) => (
                                <div key={r.external_report_id}>{r.name}</div>
                            ))}
                        </Box>
                    </>
                )}
            </Popup>
        </>
    );
}
