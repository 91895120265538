import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button, Link, Tooltip } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import { Popup } from 'components/common/popup/Popup';
import { wallAPI } from 'api/wall';

export interface GroupAnnotationDeletePopupProps {
    data: {
        element: number;
        segment: number;
        instance: number;
        ann_id: number;
        group_id: number;
    };
    onClose: () => void;
    onDeleteAll: () => void;
    onOnlyThisChart: () => void;
}

export default function GroupAnnotationDeletePopup(props: GroupAnnotationDeletePopupProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { data, onClose = () => {}, onDeleteAll = () => {}, onOnlyThisChart = () => {} } = props;

    const [mainData, setMainData] = useState<any>(null);

    useEffect(() => {
        wallAPI
            .groupDeleteAnnotationData(data)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    setMainData(response.data.data);
                }
            })
            .catch(() => {
                setMainData({
                    charts: [
                        { element_id: 10, name: 'Daily Sales (Tableau)', segment_name: 'Country' },
                        { element_id: 53, name: 'Daily Sales (QlikView)', segment_name: 'Country' },
                        { element_id: 97, name: 'Daily Peak Website Visits', segment_name: 'Country' },
                    ],
                });
            });
    }, []);

    if (!mainData) return null;

    const isLastVisible = mainData.charts.length == 1;

    return (
        <Popup
            settings={{
                title: t(`group_annotation_delete.title`),
                textOK: t(`group_annotation_delete.delete_all`, { count: mainData.charts.length }),
                noCancel: true,
            }}
            maxWidth="popupLg"
            additionalButtons={[
                <Button variant="contained" onClick={onOnlyThisChart}>
                    {t(`group_annotation_delete.only_this_chart`)}
                </Button>,
            ]}
            open={true}
            onHide={onClose}
            onConfirm={onDeleteAll}
        >
            {isLastVisible ? (
                <Box>{t('group_annotation_delete.delete_last_msg')}</Box>
            ) : (
                <Box>
                    <Box>{t('group_annotation_delete.list_annot_text')}</Box>
                    <Box sx={{ py: 1 }}>
                        {Array.isArray(mainData.charts) && mainData.charts.length > 0 ? (
                            <Box>
                                {mainData.charts.map((chart: any) => {
                                    return (
                                        <Box key={chart.element_id}>{`#${chart.element_id} - ${chart.name}${
                                            chart.segment_name != ''
                                                ? ` ${t('group_annotation_delete.chart_dimension_by', {
                                                      name: chart.segment_name,
                                                  })}`
                                                : ''
                                        }`}</Box>
                                    );
                                })}
                            </Box>
                        ) : (
                            <Box>{t('group_annotation_delete.no_charts_msg')}</Box>
                        )}
                    </Box>
                    <Box>{t('group_annotation_delete.delete_list_msg')}</Box>
                </Box>
            )}
        </Popup>
    );
}
