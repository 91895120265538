import { components } from 'react-select';
import React from 'react';
import IconHandler from 'components/common/icon/IconHandler';
import { Box, Stack } from '@mui/material';

export const ReactSelectSingleValue = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <Stack direction="row" spacing={0.5} justifyContent={'flex-start'} alignItems={'center'}>
                <IconHandler icon={props.data.props?.icon} />
                <Box>{props.children}</Box>
            </Stack>
        </components.SingleValue>
    );
};
