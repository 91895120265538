import { instance } from 'api/api';
import qs from 'query-string';
import { ColumnsWidth, FilterValue, VisibleColumns } from 'components/common/grid';
import { APIResponse } from 'tools/types';

const GRID_SETTINGS_URL = '/data/grid/settings/';

export interface AsyncTooltipData {
    tooltip: string;
    rawHtml: boolean;
}

export interface AsyncActionData {
    href?: string;
    newTab?: boolean;
    reloadGridData?: boolean;
    showConfirmation?: boolean;
    message?: string;
}

export const gridAPI = {
    load(url: string, params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        return instance.post(url, params);
    },
    loadFilter(url: string, params: any = {}): any {
        const urlParams = qs.stringify(params);
        return instance.get(url + (urlParams ? '?' + urlParams : ''));
    },
    loadData(url: string, params: any = {}) {
        if ('undefined' === typeof params) params = {};
        params['data'] = 'Y';
        const urlParams = qs.stringify(params);
        return instance.get(url + (urlParams ? '?' + urlParams : ''));
    },
    updateGridOrder(url: string, params: any = {}) {
        if ('undefined' === typeof params) params = {};
        return instance.post(url, params);
    },
    delete(url: string) {
        return instance.delete(url);
    },
    massDelete(url: string, params: any = {}) {
        return instance.post(url, params);
    },
    saveGridSettings(params: {
        gridName: string;
        colWidth: ColumnsWidth;
        colVisible: VisibleColumns;
        fullwidthMode: string;
        gridView: string;
        version: number;
    }) {
        const url = GRID_SETTINGS_URL + 'save';

        return instance.post(url, params);
    },
    resetGridSettings(params: { gridName: string }) {
        const url = GRID_SETTINGS_URL + 'reset';

        return instance.post(url, params);
    },
    saveGridFilters(params: { gridName: string; filters: string[] }) {
        const url = GRID_SETTINGS_URL + 'save-filters';

        return instance.post(url, params);
    },
    saveGridFiltersValues(params: { gridName: string; filtersValues: FilterValue }) {
        const url = GRID_SETTINGS_URL + 'save-filters-values';

        return instance.post(url, params);
    },
    saveGridOrder(params: { gridName: string; order: { order: string; orderBy: string } }) {
        const url = GRID_SETTINGS_URL + 'save-order';

        return instance.post(url, params);
    },
    async asyncAction(url: string) {
        const response = await instance.get<APIResponse<AsyncActionData>>(url);

        return response.data;
    },
    async loadAsyncTooltip(url: string) {
        const response = await instance.get<APIResponse<AsyncTooltipData>>(url);

        return response.data.data;
    },
    async buttonAsyncAction(url: string, selectedItems?: (string | number)[]) {
        if (selectedItems && selectedItems.length > 0) {
            const response = await instance.post<
                { status: 'QUEUE'; data: { ppl_id: number } } | APIResponse<AsyncActionData>
            >(url, { ids: selectedItems });

            return response.data;
        } else {
            const response = await instance.get<
                { status: 'QUEUE'; data: { ppl_id: number } } | APIResponse<AsyncActionData>
            >(url);

            return response.data;
        }
    },
};
