import { ElementType } from 'components/element-viewer/index';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';

export default function EditManualData({ element }: { element: ElementType }) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);
    const navigate = useNavigate();

    return (
        <Tooltip title={t('label_click_to_add_or_modify_values_for_this_metric')}>
            <IconButton
                sx={{ p: '6px' }}
                onClick={() => {
                    navigate(`/editor/element/metric/${element.row.elementId}/manual#data`);
                }}
            >
                <IconMi icon="sql-data-source" fontSize="16" />
            </IconButton>
        </Tooltip>
    );
}
