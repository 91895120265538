import { useEffect, useState } from 'react';
import { getElementFieldValue } from 'components/common/form/formTools';
import { HintComponentProps, useMethod } from 'components/common/form/data-fetch-command/hint/index';
import styles from '../DataFetchCommandHint.styles';
import { Box } from '@mui/material';
export default function DatasetHint({ controlProps, t }: HintComponentProps) {
    const [hintText, setHintText] = useState('');
    const method = useMethod(controlProps.form);

    const command = controlProps.form.hookFormWatch('data_fetch_command');
    const html: Array<string> = [];

    useEffect(() => {
        if (method == 'sql' || method == 'plugin' || method == 'existing datasets') {
            html.push(t('main_hint.dataset.you_may_use', { pattern: ':measurement_time' }));

            if (method == 'plugin') {
                const additional_hint =
                    getElementFieldValue(controlProps.form, `plugin_connection_profile_id.add_hint`) ?? '';
                if (additional_hint > '') {
                    html.push('<br />' + atob(additional_hint));
                }
            }
        }

        setHintText(html.join(''));
    }, [method, command]);

    if (!hintText) return null;

    return <Box sx={styles.hintWrapper} dangerouslySetInnerHTML={{ __html: hintText }} />;
}
