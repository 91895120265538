//MI icons
import { ReactComponent as MIBurst } from 'assets/icons/lineage/mi/burst.svg';
import { ReactComponent as MIDataset } from 'assets/icons/lineage/mi/dataset.svg';
import { ReactComponent as MIDatasetView } from 'assets/icons/lineage/mi/dataset-view.svg';
import { ReactComponent as MIDeletedDataset } from 'assets/icons/lineage/mi/deleted-dataset.svg';
import { ReactComponent as MIElement } from 'assets/icons/lineage/mi/element.svg';
import { ReactComponent as MIExternalContent } from 'assets/icons/lineage/mi/external-content.svg';
import { ReactComponent as MIExternalDataset } from 'assets/icons/lineage/mi/external-dataset.svg';
import { ReactComponent as MIExternalDatasetView } from 'assets/icons/lineage/mi/external-dataset-view.svg';
import { ReactComponent as MIExternalReport } from 'assets/icons/lineage/mi/external-report.svg';
import { ReactComponent as MILegacyReport } from 'assets/icons/lineage/mi/legacy-report.svg';
import { ReactComponent as MIMetric } from 'assets/icons/lineage/mi/metric.svg';
import { ReactComponent as MIMultiMetric } from 'assets/icons/lineage/mi/multi-metric.svg';
import { ReactComponent as MIPlugin } from 'assets/icons/lineage/mi/plugin.svg';
import { ReactComponent as MIPluginDataSource } from 'assets/icons/lineage/mi/plugin-data-source.svg';
import { ReactComponent as MIReport } from 'assets/icons/lineage/mi/report.svg';
import { ReactComponent as MISqlDataSource } from 'assets/icons/lineage/mi/sql-data-source.svg';
import { ReactComponent as MITable } from 'assets/icons/lineage/mi/table.svg';

//DB icons
import { ReactComponent as DBDatabricks } from 'assets/icons/lineage/db/databricks.svg';
import { ReactComponent as DBDb2 } from 'assets/icons/lineage/db/db2.svg';
import { ReactComponent as DBGeneric } from 'assets/icons/lineage/db/db-generic-icon.svg';
import { ReactComponent as DBGoogleBigquery } from 'assets/icons/lineage/db/google-bigquery.svg';
import { ReactComponent as DBIbm } from 'assets/icons/lineage/db/ibm.svg';
import { ReactComponent as DBMySql } from 'assets/icons/lineage/db/mysql.svg';
import { ReactComponent as DBOracle } from 'assets/icons/lineage/db/oracle.svg';
import { ReactComponent as DBPostgres } from 'assets/icons/lineage/db/postgres.svg';
import { ReactComponent as DBSap } from 'assets/icons/lineage/db/sap.svg';
import { ReactComponent as DBSnowflake } from 'assets/icons/lineage/db/snowflake.svg';
import { ReactComponent as DBFile } from 'assets/icons/lineage/db/file.svg';
import { ReactComponent as DBCSVFile } from 'assets/icons/lineage/db/file-csv.svg';
import { ReactComponent as DBExcelFile } from 'assets/icons/lineage/db/file-excel.svg';

//Plugin icons
import { ReactComponent as PluginAtlan } from 'assets/icons/lineage/plugin/atlan.svg';
import { ReactComponent as PluginCollibra } from 'assets/icons/lineage/plugin/collibra.svg';
import { ReactComponent as PluginDomo } from 'assets/icons/lineage/plugin/domo.svg';
import { ReactComponent as PluginEpic } from 'assets/icons/lineage/plugin/epic.svg';
import { ReactComponent as PluginGoogleLooker } from 'assets/icons/lineage/plugin/google-looker.svg';
import { ReactComponent as PluginIbmCognos } from 'assets/icons/lineage/plugin/ibm-cognos.svg';
import { ReactComponent as PluginMetricInsights } from 'assets/icons/lineage/plugin/metric-insights.svg';
import { ReactComponent as PluginMicrosoftSharepoint } from 'assets/icons/lineage/plugin/microsoft-sharepoint.svg';
import { ReactComponent as PluginMicrostrategy } from 'assets/icons/lineage/plugin/microstrategy.svg';
import { ReactComponent as PluginPowerBi } from 'assets/icons/lineage/plugin/power-bi.svg';
import { ReactComponent as PluginPurview } from 'assets/icons/lineage/plugin/purview.svg';
import { ReactComponent as PluginQlikSense } from 'assets/icons/lineage/plugin/qlik-sense.svg';
import { ReactComponent as PluginQlikView } from 'assets/icons/lineage/plugin/qlik-view.svg';
import { ReactComponent as PluginSalesforce } from 'assets/icons/lineage/plugin/salesforce.svg';
import { ReactComponent as PluginSalesforceSoql } from 'assets/icons/lineage/plugin/salesforce-soql.svg';
import { ReactComponent as PluginSap } from 'assets/icons/lineage/plugin/sap.svg';
import { ReactComponent as PluginSigma } from 'assets/icons/lineage/plugin/sigma.svg';
import { ReactComponent as PluginTableau } from 'assets/icons/lineage/plugin/tableau.svg';
import { ReactComponent as PluginTibco } from 'assets/icons/lineage/plugin/tibco.svg';
import { ReactComponent as PluginTibcoSpotfire } from 'assets/icons/lineage/plugin/tibco-spotfire.svg';

//common parts
import { ReactComponent as Arrow } from 'assets/icons/lineage/parts/arrow.svg';

import React from 'react';
import { UseSvgDataUri } from 'hooks/useSvg';
import { NodeState } from './index';
import { Theme } from '@mui/material/styles/createTheme';
import ReactDOMServer from 'react-dom/server';

export const iconsMIByType: { [key: string]: any } = {
    burst: MIBurst,
    dataset: MIDataset,
    'dataset-view': MIDatasetView,
    'deleted-dataset': MIDeletedDataset,
    element: MIElement,
    'external-content': MIExternalContent,
    //'external-dataset': MIExternalDataset,
    'external-dataset': MIPluginDataSource,
    'external-metadata': MITable,
    'external-source': MIPlugin,
    'external-dataset-view': MIExternalDatasetView,
    'external-report': MIExternalReport,
    'legacy-report': MILegacyReport,
    metric: MIMetric,
    'multi-metric': MIMultiMetric,
    plugin: MIPluginDataSource,
    'plugin-data-source': MIPluginDataSource,
    report: MIReport,
    'sql-data-source': MISqlDataSource,
    source: MISqlDataSource,
    table: MITable,
    file: MIPlugin,
};

export const iconsDBByType: { [key: string]: any } = {
    databricks: DBDatabricks,
    db2: DBDb2,
    generic: DBGeneric,
    'google-bigquery': DBGoogleBigquery,
    ibm: DBIbm,
    mysql: DBMySql,
    oracle: DBOracle,
    postgres: DBPostgres,
    sap: DBSap,
    snowflake: DBSnowflake,
    sqlserver: DBGeneric,
    textscan: DBCSVFile,
    'excel-direct': DBExcelFile,
    file: DBFile,
};

export const iconsPluginByType: { [key: string]: any } = {
    atlan: PluginAtlan,
    collibra: PluginCollibra,
    domo: PluginDomo,
    epic: PluginEpic,
    'google-looker': PluginGoogleLooker,
    ibmcognos: PluginIbmCognos,
    'metric-insights': PluginMetricInsights,
    'microsoft-sharepoint': PluginMicrosoftSharepoint,
    microstrategy: PluginMicrostrategy,
    'power-bi': PluginPowerBi,
    purview: PluginPurview,
    qliksense: PluginQlikSense,
    qlikview: PluginQlikView,
    salesforce: PluginSalesforce,
    'salesforce-soql': PluginSalesforceSoql,
    sapanalyticscloud: PluginSap,
    sigma: PluginSigma,
    tableau: PluginTableau,
    tibco: PluginTibco,
    'tibco-spotfire': PluginTibcoSpotfire,
};

export const getArrowImage = (color?: string) => {
    return UseSvgDataUri(Arrow, color);
};

export const getIconComponentByType = (type: string = 'element', color?: string) => {
    return (
        <img
            width="100%"
            height="100%"
            style={{ maxWidth: '16px', maxHeight: '16px' }}
            src={UseSvgDataUri(iconsMIByType[type] ?? MIElement, color)}
            alt={type}
        />
    );
};

export const getIconComponent = (pluginName: string, type: 'plugin' | 'db' = 'plugin') => {
    const icon = type === 'plugin' ? getPluginIcon(pluginName) : getSimpleSourceIcon(pluginName);

    return (
        <img
            width="100%"
            height="100%"
            style={{ maxWidth: '16px', maxHeight: '16px' }}
            src={UseSvgDataUri(icon)}
            alt={pluginName}
        />
    );
};

export const getPluginIcon = (pluginName: string) => {
    let convertedPluginName = pluginName.toLowerCase().replace(/\s/g, '');

    if (convertedPluginName.includes('powerbi')) {
        convertedPluginName = 'power-bi';
    }

    return iconsPluginByType[convertedPluginName] ?? PluginMetricInsights;
};

export const getSimpleSourceIcon = (name: string) => {
    let convertedName = name.toLowerCase().replace(/\s/g, '');

    return iconsDBByType[convertedName] ?? DBGeneric;
};

export const getIcon = (
    themeObj: Theme,
    type: string = 'element',
    source: string = 'metric-insights',
    sourceType: 'plugin' | 'db' = 'plugin',
    root: boolean = false,
    certified: boolean = false,
    collapsed: boolean = false,
    state: NodeState = 'default',
) => {
    const baseColor = certified
        ? themeObj.palette.success.main
        : root
          ? themeObj.palette.primary.main
          : themeObj.palette.text.primary;

    const svgString = `<svg fill="${baseColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
<g>
    ${getBase()}
    ${getBorder(baseColor, root, certified, collapsed, state)}
    ${getMainIcon(baseColor, type)}
    ${getSourceIcon(source, sourceType)}
</g>
</svg>`;

    return 'data:image/svg+xml,' + encodeURIComponent(svgString);
};

const getBase = () => {
    return `<svg viewBox="0 0 48 48" fill="white" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="46" height="46" rx="23.5" />
        </svg>`;
};

const getBorder = (baseColor: string, root: boolean, certified: boolean, collapsed: boolean, state: NodeState) => {
    const fill = state === 'clicked' || 'hover' ? baseColor : 'white';
    const fillOpacity = state === 'clicked' ? '0.16' : state === 'hover' ? '0.08' : '0';

    const strokeWidth = root || state === 'clicked' ? (!collapsed ? '2' : '1') : '1';
    const strokeDasharray = root ? '4' : '0';
    const strokeOpacity = root ? '1' : '0.4';

    return `<svg viewBox="0 0 48 48" fill="${fill}" fill-opacity="${fillOpacity}" xmlns="http://www.w3.org/2000/svg" stroke="${baseColor}" opacity="${strokeOpacity}" stroke-width="${strokeWidth}" stroke-dasharray="${strokeDasharray}" stroke-opacity="${strokeOpacity}">
            <rect x="1" y="1" width="46" height="46" rx="23.5" />
            ${collapsed ? `<rect x="3.5" y="3.5" width="41" height="41" rx="20.5" opacity="1" fill-opacity="0" />` : ''}
        </svg>`;
};

const getMainIcon = (baseColor: string, type: string) => {
    const icon = iconsMIByType[type] ?? MIElement;

    let svgString = ReactDOMServer.renderToStaticMarkup(React.createElement(icon));
    svgString = svgString.replaceAll('<svg', `<svg fill="${baseColor}" width="16" height="16"`);

    return `<g transform="translate(18 18)">
        ${svgString}
</g>`;
};

const getSourceIcon = (source: string, sourceType: 'plugin' | 'db') => {
    let convertedPluginName = source.toLowerCase().replace(/\s/g, '');

    if (convertedPluginName.includes('powerbi')) {
        convertedPluginName = 'power-bi';
    }

    const icon =
        sourceType === 'plugin'
            ? (iconsPluginByType[convertedPluginName] ?? PluginMetricInsights)
            : (iconsDBByType[convertedPluginName] ?? DBGeneric);
    let svgString = ReactDOMServer.renderToStaticMarkup(React.createElement(icon));
    svgString = svgString.replaceAll('<svg', `<svg width="16" height="16"`);

    return `<g transform="translate(34 34)">
    <rect width="20" height="20" rx="4" fill="white"/>
    <g transform="translate(2 2)">
        ${svgString}
    </g>
</g>`;
};
