import { HintComponentProps } from 'components/common/form/data-fetch-command/hint/index';
import styles from '../DataFetchCommandHint.styles';
import { Box } from '@mui/material';

export default function DependencyHint({ controlProps, t }: HintComponentProps) {
    const dependencyMode = controlProps.form.hookFormWatch('dependency_mode');
    const hintText = t(`main_hint.dependency.mode_${dependencyMode}`);

    if (!hintText) return null;

    return <Box sx={styles.hintWrapper} dangerouslySetInnerHTML={{ __html: hintText }} />;
}
