import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { FormLayoutContext, ITabProps } from 'components/common/form/layout/tab/index';
import { FormTabContent } from 'components/common/form/layout/tab/FormTabContent';

export default function FormTabContentDefaultStacks({
    isTabActive,
    tabName,
    elementProps,
    componentsMap,
    children,
}: ITabProps & { children: [JSX.Element, JSX.Element] }) {
    const layoutContext = useContext(FormLayoutContext);

    if (layoutContext.formLayout == 'popup') {
        return (
            <FormTabContent
                isTabActive={isTabActive}
                tabName={tabName}
                elementProps={elementProps}
                componentsMap={componentsMap}
            >
                {children}
            </FormTabContent>
        );
    }

    return (
        <FormTabContent
            isTabActive={isTabActive}
            tabName={tabName}
            elementProps={elementProps}
            componentsMap={componentsMap}
        >
            <Stack
                alignItems="center"
                className={`pinned-components form-components-indent`}
                sx={{ width: '100%', px: (theme) => theme.size.pxValue(theme.size.containerIndentX) }}
            >
                {children[0]}
            </Stack>
            <Stack
                alignItems="center"
                className={
                    'default-components form-components-indent scroll-content-container scroll-content-container--form'
                }
                sx={{
                    overflow: 'auto',
                    flexGrow: 1,
                    width: '100%',
                    pt: 4,
                    pb: 3,
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                }}
            >
                {children[1]}
            </Stack>
        </FormTabContent>
    );
}
