import { Box, Button, FormLabel, IconButton, Stack } from '@mui/material';
import useDrillToElements from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/useDrillToElements';
import { ReportContentNS } from 'components/report-content';
import DrillTo = ReportContentNS.DrillTo;
import ContentSettings = ReportContentNS.ContentSettings;
import { Link } from 'react-router-dom';
import { getViewUrl } from 'tools/tools';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

export default function DrillToList({
    contentSettings,
    drillTo,
    onSelectRow,
    onRemoveRow,
}: {
    contentSettings: ContentSettings;
    drillTo: Array<DrillTo>;
    onSelectRow: (drillTo: DrillTo) => void;
    onRemoveRow: (elementId: number) => void;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const drillElements = useDrillToElements(contentSettings.elementId);

    if (!drillElements || drillElements.length == 0) {
        return null;
    }

    const handleAdd = () => {
        const exists = drillTo.map((row) => row.elementId);
        const nextElement = drillElements.find((row) => !exists.includes(row.value));
        if (!nextElement) {
            return;
        }
        onSelectRow({ elementTitle: '', elementId: nextElement.value, columnsMap: [] });
    };

    return (
        <Box>
            <FormLabel>{t('drill.link_to_element')}</FormLabel>
            <Box sx={{ mt: 2 }}>
                {drillTo.map((row) => {
                    const element = drillElements.find((e) => e.value == row.elementId);
                    if (!element) {
                        return null;
                    }
                    return (
                        <Stack key={row.elementId} direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Box>
                                <Link to={getViewUrl(element.type) + element.value} target={'_blank'}>
                                    {row.elementTitle ? row.elementTitle : element.label}
                                </Link>
                            </Box>
                            <Stack direction={'row'} spacing={0}>
                                <Box sx={{ flexShrink: 0 }}>
                                    <IconButton onClick={() => onSelectRow(row)}>
                                        <IconMi icon="gear" fontSize="16" />
                                    </IconButton>
                                </Box>
                                <Box sx={{ flexShrink: 0 }}>
                                    <IconButton onClick={() => onRemoveRow(row.elementId)}>
                                        <IconMi icon="times" fontSize="16" />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Stack>
                    );
                })}
            </Box>
            <Box sx={{ mt: 2 }}>
                <Button onClick={handleAdd} startIcon={<IconMi icon={'new'} />} variant="light">
                    {t('drill.drill_to')}
                </Button>
            </Box>
        </Box>
    );
}
