import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import { formAPI } from 'api/form';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import IconMi from 'components/common/icon/IconMi';
import { FormComponentBuilder } from 'components/common/form/layout';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useQueryClient } from '@tanstack/react-query';

interface SyncNowFormButtonProps extends FormControlProps {
    url: string;
    reloadGrids: string[];
}

interface SyncResponse {
    inProgress: 'Y' | 'N';
    status: 'OK' | 'ERROR';
    message: string;
}

export default function SyncNowFormButton({
    controlProps,
    elementProps,
}: FormElementControlPropsType<SyncNowFormButtonProps>) {
    const { t } = useBundleTranslation(elementProps?.translationNS ?? 'app/editor/segment');
    const [isLoading, toggleLoading] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [runningMessage, setRunningMessage] = useState('');

    const queryClient = useQueryClient();

    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name === 'security_type') {
                setShowButton(value['security_type'] === 'user_map');
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    const runSync = () => {
        formAPI.save(controlProps.url).then((response: { data: SyncResponse }) => {
            if (response.data.status === 'OK') {
                if (response.data.inProgress === 'Y') {
                    setRunningMessage(response.data.message);
                    setTimeout(() => {
                        runSync();
                    }, 30000);
                } else {
                    setRunningMessage('');
                    toggleLoading(false);
                    if (controlProps.reloadGrids.length > 0) {
                        controlProps.reloadGrids.forEach((gridName) => {
                            queryClient.invalidateQueries({ queryKey: [gridName] });
                        });
                    }
                }
            } else {
                setRunningMessage('');
                toggleLoading(false);
            }
        });
    };

    const handleClick = async () => {
        try {
            toggleLoading(true);
            runSync();
        } catch (error) {
            setRunningMessage('');
            toggleLoading(false);
        }
    };

    return showButton ? (
        <>
            <Button
                data-test={controlProps.uid}
                onClick={handleClick}
                variant={'outlined'}
                startIcon={<IconMi icon={'refresh-cw'} />}
                sx={{ maxHeight: '28px' }}
            >
                {t(controlProps.label as string)}
            </Button>
            {isLoading && (
                <LoadingPlaceholder>
                    <Typography>{runningMessage}</Typography>
                </LoadingPlaceholder>
            )}
        </>
    ) : null;
}

export class SyncNowFormButtonBuilder extends FormComponentBuilder {
    prepareProps(): SyncNowFormButtonProps {
        return {
            ...this.controlProps,
            url: this.elementProps.componentProps?.url ?? '',
            reloadGrids: this.elementProps.componentProps?.reloadGrids ?? [],
        };
    }
}
