import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { Button } from '@mui/material';

export default function DeleteItemWall({ onClick }: { onClick: Function }) {
    return (
        <Button variant="text" sx={{ p: '6px' }} onClick={() => onClick()} className={'min-width--icon'}>
            <IconMi icon="trash" fontSize="16" />
        </Button>
    );
}
