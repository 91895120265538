import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import { getDatasetBreakLine } from 'components/report-content/components/dataset/parts/DatasetBody';
import DatasetBreakLine from 'components/report-content/components/dataset/parts/DatasetBreakLine';
import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import DataRowType = ReportContentNS.DataRowType;

export default function StaticBreakLine({
    component,
    lineIndex,
    rows,
    cellStyler,
}: {
    component: BlockComponent<ComponentSettingsDataset>;
    lineIndex: number;
    lineOffset: number;
    rows: Array<DataRowType>;
    cellStyler: CellStyler;
}) {
    if (!component.settings.breakArea) {
        return null;
    }

    const line = getDatasetBreakLine(component.settings, rows, lineIndex);
    const text = lineIndex == 0 ? line.currentBreakValue : line.prevBreakValue;

    return (
        <DatasetBreakLine
            text={text}
            index={lineIndex}
            component={component}
            row={rows[lineIndex]}
            cellStyler={cellStyler}
        />
    );
}
