import React from 'react';
import Components from 'components/common/ComponentIndex';
import { getComponentWrapperKey, WrapFormElementProps } from 'components/common/form/layout/wrapper';

export function FormElementWrapper({ elementProps, children, componentValues }: WrapFormElementProps) {
    const componentKey = getComponentWrapperKey(elementProps.component);
    if (componentKey === false) {
        return <>{children}</>;
    }
    return React.createElement(Components[componentKey] as React.FC, {
        // @ts-ignore
        elementProps: elementProps,
        children: children,
        componentValues: componentValues,
    });
}
