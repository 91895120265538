import { alpha, Box, Button, Stack, Tab, Tabs, Theme } from '@mui/material';
import useBundleTranslation from 'i18n';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import QueryBuilderPopup from 'components/plugin-query-builder/query-builder/QueryBuilderPopup';
import { pluginQueryBuilderAPI } from 'api/api';
import {
    FieldDate,
    FilterElement,
    isPlainObject,
    isValidField,
    QBHelperActList,
    QBQueryActList,
    QBQueryData,
    QBReportActList,
    QBReportData,
} from 'components/plugin-query-builder/index';
import unescape from 'lodash/unescape';
import {
    QBReportField,
    QBReportFieldData,
    setQBReportFieldAlias,
    setQBReportFieldType,
} from 'components/plugin-query-builder/data/ReportFieldClass';
import { getQBQueryMetricExpression, QBQueryMetric } from 'components/plugin-query-builder/data/QueryMetricClass';
import QueryBuilderFieldsGrid from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGrid';
import styles from './PluginQueryBuilder.styles';
import { Resizable } from 're-resizable';
import Editor from 'react-simple-code-editor';
import { useTheme } from '@mui/material/styles';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import QueryBuilderSummaryPreview from 'components/plugin-query-builder/query-builder/QueryBuilderSummaryPreview';
import { FormRendererAPIType } from 'components/common/form';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { getQueryBuilderPluginConfig, QBPluginConfig } from 'components/plugin-query-builder/pluginConfig';

interface PromptData {
    name: string;
    values: string;
}

export interface pluginQueryBuilderConfigObject {
    name?: string; //??? actual
    title: string;
    profileId?: number;
    reportID?: number;
    extReportID?: string;
    extReportName?: string;
    extReportLinkedRefId?: number;
    itemId?: number | string;
    itemType?: string;
    pattern?: string;
    ve_display_method?: string; //Is usefull
    interval?: string | null;
    fiscal?: string;
    query?: string;
    plugin_alias?: string;
    has_ve?: string;
    has_summary?: string;
    prefiltering?: string;
}

type Required<T> = {
    [P in keyof T]-?: T[P];
};
type Modify<T, R> = Omit<T, keyof R> & R;

export type configData = Modify<
    Required<pluginQueryBuilderConfigObject>,
    {
        fiscal: boolean;
        has_ve: boolean;
        has_summary: boolean;
        prefiltering: boolean;
        itemId: number;
    }
>;

interface PluginQueryBuilderProps {
    parent: any;
    config: pluginQueryBuilderConfigObject;
    validationSuccess?: boolean;
    onChangedQueryValue?: (value: string) => void;
    editorHighlightWithLineNumbers?: (code: any) => any;
    editorTextareaId: string;
    actionButtonsBlock?: (visualEditorBtn?: ReactJSXElement | null) => ReactElement | null;
    form?: FormRendererAPIType;
    onClickSaveQueryBuilderPopup?: () => void;
    needLoadInitialQueryBuilderData: boolean;
    setNeedLoadInitialQueryBuilderData: (value: boolean) => void;
    formInitialValues?: any;
}

export const PluginQBContext = React.createContext<any>(null);

export const getFilterConditionVariants = (pluginConfig: QBPluginConfig) => {
    const conditionVariants: any = {
        equal: {
            value: '==',
        },
        not_equal: {
            value: '!=',
        },
        greater: {
            value: '>',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        less: {
            value: '<',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        greater_or_equal: {
            value: '>=',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        less_or_equal: {
            value: '<=',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        in_list: {
            value: 'in',
            label: 'filter_condition_label.in_list',
            filedType: ['INTEGER', 'DECIMAL', 'TEXT', 'EXPR'],
        },
        contains: {
            value: 'contains',
            label: 'filter_condition_label.contains',
            filedType: ['DATE', 'TEXT'],
        },
        empty: {
            value: 'is null',
            label: 'filter_condition_label.empty',
            filterStringValue: 'is',
        },
        not_empty: {
            value: 'is not null',
            label: 'filter_condition_label.not_empty',
            filterStringValue: 'is not',
        },
    };

    if (pluginConfig.removeFilterConditionIn) {
        delete conditionVariants.in_list;
    }
    if (pluginConfig.removeFilterConditionEmptyAndNotEmpty) {
        delete conditionVariants.empty;
        delete conditionVariants.not_empty;
    }
    if (pluginConfig.removeFilterConditionContains) {
        delete conditionVariants.contains;
    }

    return conditionVariants;
};

export const getFieldOptionsForSelect = (
    fieldsObject: any,
    helperAct: QBHelperActList,
    pluginConfig: QBPluginConfig,
) => {
    if (pluginConfig.useMetadataFlow) {
        const groups: any = {};
        const sortByLabel = (a: any, b: any) => {
            if (a.options && (a.label === 'Derived Fields' || b.label === 'Other')) return -1;
            if (a.options && (b.label === 'Derived Fields' || a.label === 'Other')) return 1;
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        };
        //maybe use option with valueGroup;
        Object.values(fieldsObject).forEach((field: any) => {
            const groupName = helperAct.ucwords(field.group);
            if (!groups[groupName]) {
                groups[groupName] = { label: groupName, options: [] };
            }
            groups[groupName]['options'].push({
                label: helperAct.escapeHtml(field.name),
                value: helperAct.escapeHtml(field.name),
                description: field.description,
            });
        });
        Object.keys(groups).forEach((groupKey) => {
            if (groups[groupKey].options?.length) {
                groups[groupKey].options.sort(sortByLabel);
            }
        });
        return Object.values(groups)
            .map((group) => group)
            .sort(sortByLabel);
    } else {
        return Object.values(fieldsObject).map((field: any) => {
            return {
                value: helperAct.escapeHtml(field.name),
                label: helperAct.escapeHtml(field.name),
            };
        });
    }
};

export default function PluginQueryBuilder(props: PluginQueryBuilderProps) {
    const {
        config,
        validationSuccess,
        editorTextareaId,
        onChangedQueryValue = () => {},
        editorHighlightWithLineNumbers = () => {},
        actionButtonsBlock = (visualEditorBtn) => {
            return visualEditorBtn ?? null;
        },
        form,
        onClickSaveQueryBuilderPopup = () => {},
        needLoadInitialQueryBuilderData,
        setNeedLoadInitialQueryBuilderData,
        formInitialValues,
    } = props;
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const appTheme = useTheme();
    const [showQueryBuilderPopup, setShowQueryBuilderPopup] = useState<boolean>(false);
    const [isSummaryTabVisible, setIsSummaryTabVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pluginConfig, setPluginConfig] = useState<QBPluginConfig>(getQueryBuilderPluginConfig());

    const configData = {
        name: config.name ?? '', //Is Actual???
        title: config.title, //parent.find('#plugin_connection_profile_id option:selected').attr('plugin_name')
        pattern: config.pattern ?? '', //parent.find('#plugin_connection_profile_id').attr('pattern')
        ve_display_method: config.ve_display_method ?? '', //t.attr('ve_display_method'), #plugin_connection_profile_id
        interval: config.interval ?? null, //parent.find('#measurement_interval_id option[interval_unit]:selected').attr('interval_unit')
        fiscal: config.interval ? !!config.fiscal : false, //!!(parent.find('#measurement_interval_id option[interval_unit]:selected').attr('fiscal'))
        profileId: config.profileId ?? 0, //parent.find('#plugin_connection_profile_id').val(), /* Connection profile ID  *
        reportID: config.plugin_alias == 'ga_new' || !config.reportID ? 0 : config.reportID, //parent.find('#external_report_external_id').val(), /* Report ID */
        extReportID: config.extReportID ?? '', //parent.find('#external_report_external_id').attr('external_id'), /* External Report ID */
        extReportName: config.extReportName ?? '', //parent.find('#external_report_external_id').attr('label'), /* External Report Name */
        extReportLinkedRefId: config.extReportLinkedRefId ?? 0, //for powerbi with extReportLinkedRefId workaround
        itemId: Number(config.itemId) ?? 0, //ed.externalReferenceFilterUsage.item_id
        itemType: config.itemType ?? '', //ed.externalReferenceFilterUsage.item_type
        query: config.query ?? '', //parent.find('#data_fetch_command').val()  = dfc.val()
        plugin_alias: config.plugin_alias ?? '', //parent.find('#plugin_connection_profile_id option:selected').attr('plugin');
        has_ve: config.has_ve == 'Y',
        has_summary: config.has_summary == 'Y',
        prefiltering: config.prefiltering == 'Y',
        customTabLabel: '',
    };

    if (configData.plugin_alias == 'powerbi' && configData.extReportLinkedRefId > 0) {
        configData.has_ve = false;
        configData.has_summary = false;
        configData.customTabLabel = 'mdx_mda_query';
    }

    const resetReportDataObj = {
        connection: {}, //typeof connection == 'object' ? connection : new qlik.Connection(connection);
        //reportID      configData.reportID,
        //extReportID  configData.extReportID,
        //extReportName configData.extReportName,
        //itemID    configData.itemID,
        //itemType configData.itemType,
        fields: {},
        aliases: {},
        segments: [],
        overrides: {},
        prompts: {},
        dateSequence: 1,
        exprSequence: 1,
        iterations: {},
        usedColumns: [],
    };

    const [reportDataState, setReportDataState] = useState<QBReportData>(resetReportDataObj);
    let reportData = reportDataState;

    const [queryDataState, setQueryDataState] = useState<QBQueryData>({
        separator: '\n',
        trimRegExp: new RegExp('(^\\s+)|(\\s+$)', 'g'),
        bracketRegExp: new RegExp('(^(\\(|\\s)+)|((\\)|\\s)+$)', 'g'),
        quoteRegExp: new RegExp("(^'+)|('+$)", 'g'),
        dblquoteRegExp: new RegExp('(^"+)|("+$)', 'g'),
        numericRegExp: new RegExp('^-?[0-9]+((.|,)[0-9]+)?$', 'g'),
        variableRegExp:
            configData.plugin_alias == 'ssrs' ? new RegExp('^:[a-z_]+$', 'ig') : new RegExp('^:[a-z0-9_]+$', 'ig'),
        command: '',
        dimensions: {},
        metrics: {},
        dates: {},
        exprs: {},
        filters: [],
        sort: [],
        params: {},
        tableauFilter: null,
    });
    let queryData = queryDataState;

    const tabs = ['visual', 'command'];
    const [activeTab, setActiveTab] = useState<string>('visual');
    const [queryVal, setQueryVal] = useState<string>(configData.query);

    const updateReportData = (newData: any) => {
        setReportDataState((previousReportDataState) => ({ ...previousReportDataState, ...newData }));
        //setReportData({ ...reportData, ...newData });
        reportData = { ...reportData, ...newData };
    };
    const updateQueryData = (newData: any) => {
        setQueryDataState((previousQueryDataState) => ({ ...previousQueryDataState, ...newData }));
        //setQueryData({ ...reportData, ...newData });
        queryData = { ...queryData, ...newData };
    };

    const FIELD_TYPE = {
        EXPR: 'EXPR',
        INTEGER: 'INTEGER',
        DATE: 'DATE',
        TEXT: 'TEXT',
    };

    const helperAct: QBHelperActList = {
        isValidTimestamps: (value: string) => {
            const validTimestamps = [
                'Current Time',
                'Today',
                'Yesterday',
                'LastMinute',
                'CurrentMinute',
                'LastHour',
                'CurrentHour',
                'LastWeek',
                'CurrentWeek',
                'LastMonth',
                'CurrentMonth',
                'LastQuarter',
                'CurrentQuarter',
                'LastYear',
                'CurrentYear',
            ];

            return validTimestamps.includes(value);
        },
        escapeHtml: (string: string) => {
            const escapeHtmlEntityMap: any = {
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
            };
            const escapeHtmlCallback = (c: string) => {
                return escapeHtmlEntityMap[c];
            };
            return String(string).replace(/[<>"]/g, escapeHtmlCallback);
        },
        ucfirst: (string: string) => {
            return string.charAt(0).toUpperCase() + string.substr(1, string.length - 1).toLowerCase();
        },
        plural: (count: number, singular: string, plural: string) => {
            return count == 1 ? singular : plural;
        },
        escapeSelector: (string: string) => {
            return string.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
        },
        ucwords: (string: string) => {
            return string.replace(/_+/g, ' ').replace(/^(.)|\s+(.)/g, function ($1) {
                return $1.toUpperCase();
            });
        },
    };

    const reportAct: QBReportActList = {
        updateData: updateReportData,
        initData: async (refresh?: boolean) => {
            // const isEmptyFieldsList = !Object.keys(reportData.fields).length;
            const isEmptyFieldsList = true; //TODO check preview Data and loading behavior
            const result = { error: '' };

            if (isEmptyFieldsList || refresh) {
                let dates: QBReportFieldData[] = [];
                let exprs: QBReportFieldData[] = [];
                let types: any = {}; //todo interface

                if (!isEmptyFieldsList) {
                    for (let name in reportData.fields) {
                        const field = reportData.fields[name];
                        if (field.isConstructedDate) {
                            dates.push(field);
                        } else if (field.isExpression) {
                            exprs.push(field);
                        }

                        if (field.typeIsChanged) {
                            types[name] = field.type; //Is used???
                        }
                    }
                }

                let profileId = configData.profileId;
                let reportID = configData.reportID;
                if (needLoadInitialQueryBuilderData) {
                    profileId = formInitialValues.plugin_connection_profile_id;
                    reportID = config.plugin_alias == 'ga_new' ? 0 : formInitialValues.external_report_external_id;
                }

                await pluginQueryBuilderAPI
                    .getQueryBuilderData(profileId, reportID, configData.itemId, configData.itemType, refresh)
                    .then((response) => {
                        if (response.data.status == 'OK') {
                            const responseData = response.data;
                            let fields: any[] = [];
                            if (Array.isArray(responseData.data)) {
                                fields = responseData.data.filter((item: any) => isValidField(item));
                                if (isPlainObject(responseData.aliases)) {
                                    updateReportData({ aliases: responseData.aliases });
                                }
                                if (isPlainObject(responseData.overrides)) {
                                    updateReportData({ overrides: responseData.overrides });
                                }
                                if (isPlainObject(responseData.iterations)) {
                                    updateReportData({ iterations: responseData.iterations });
                                }
                                if (Array.isArray(responseData.used_columns)) {
                                    updateReportData({ usedColumns: responseData.used_columns });
                                }
                            } else if (pluginConfig.useMetadataFlow) {
                                let metadata = isPlainObject(responseData.metadata) ? responseData.metadata : false;
                                if (!metadata && isPlainObject(responseData.data.metadata))
                                    metadata = responseData.data.metadata;

                                if (metadata) {
                                    if (typeof metadata.fields !== 'undefined') {
                                        fields = metadata.fields;
                                    }
                                    if (Array.isArray(metadata.segments)) {
                                        updateReportData({ segments: metadata.segments });
                                    }
                                }
                            }

                            if (fields.length) {
                                updateReportData({ dateSequence: 1, exprSequence: 1, fields: {}, prompts: {} });

                                dates.forEach((item) => {
                                    reportAct.addDate(item.date, item.name);
                                });
                                exprs.forEach((item) => {
                                    reportAct.addExpression(item.expr, item.name);
                                });
                                fields.forEach((item) => {
                                    const itemName = pluginConfig.useMetadataFlow ? item.id : item.name;
                                    if (!reportData.fields.hasOwnProperty(itemName)) {
                                        const fieldData = new QBReportField(item);

                                        fieldData.isUsed = reportData.usedColumns.includes(itemName);

                                        if (
                                            reportData.overrides.hasOwnProperty(itemName) &&
                                            reportData.overrides[itemName].toUpperCase() !== item.type.toUpperCase()
                                        ) {
                                            fieldData.setType(reportData.overrides[itemName]);
                                        }

                                        if (reportData.aliases.hasOwnProperty(itemName)) {
                                            fieldData.alias = reportData.aliases[itemName];
                                        }

                                        updateReportData({
                                            fields: { ...reportData.fields, [itemName]: fieldData },
                                        });
                                    }
                                });

                                if (
                                    pluginConfig.processPrompts &&
                                    Array.isArray(responseData.prompts) &&
                                    responseData.prompts.length
                                ) {
                                    responseData.prompts.forEach((item: any) => {
                                        const promptName = unescape(item.name);
                                        if (!reportAct.hasField(promptName, true)) {
                                            updateReportData({
                                                prompts: {
                                                    ...reportData.prompts,
                                                    [promptName]: {
                                                        name: item.name,
                                                        values: item.values,
                                                    } as PromptData,
                                                },
                                            });
                                        }
                                    });
                                }
                            } else {
                                const pluginObjectName = `${pluginConfig.elementName}${
                                    pluginConfig.elementType == 'none'
                                        ? ''
                                        : ' ' + t('plugin_element_type.' + pluginConfig.elementType)
                                }`;
                                result.error = t('error_msg.no_metadata', { objectName: pluginObjectName });
                            }
                        } else if (response.data.status == 'ERROR') {
                            if (refresh) {
                                result.error = t('error_msg.metadata_refresh_failed') + ':\n\n' + response.data.message;
                            } else {
                                result.error = t('error_msg.plugin_call_error') + ':\n\n' + response.data.message;
                            }
                        }
                    })
                    .catch(() => {});
            }
            return result;
        },
        hasField: (field: string, ci?: boolean) => {
            if (ci) {
                field = field.toLowerCase();

                for (let name in reportData.fields) {
                    if (reportData.fields[name] && name.toLowerCase() === field) return true;
                }

                return false;
            }

            return reportData.fields.hasOwnProperty(field);
        },
        getField: (field: string) => {
            return reportAct.hasField(field) ? reportData.fields[field] : undefined;
        },
        hasAlias: (alias: string, field?: string) => {
            if (typeof alias === 'string') {
                if (reportAct.hasField(alias)) {
                    return true;
                }

                for (var name in reportData.fields) {
                    if (
                        reportData.fields.hasOwnProperty(name) &&
                        reportData.fields[name].alias === alias &&
                        (!field || field !== name)
                    ) {
                        return true;
                    }
                }
            }

            return false;
        },
        setAliasValue: (field: string, alias: string) => {
            if (!reportAct.hasField(field)) {
                return false;
            }

            const newFieldData = { ...reportData.fields[field] };
            setQBReportFieldAlias(newFieldData, alias);
            updateReportData({ fields: { ...reportData.fields, [field]: newFieldData } });

            return true;
        },
        addDate: (dateData: FieldDate, name: string) => {
            const { value, year, month, day, hour, format } = dateData;

            if (!reportAct.isValidDate(dateData, name)) {
                //false
                return undefined;
            }

            if (!name.length) {
                do {
                    name = 'date' + reportData.dateSequence;
                    updateReportData({ dateSequence: reportData.dateSequence + 1 });
                } while (reportAct.hasField(name));
            }

            const fieldData = new QBReportField({
                name: name,
                type: FIELD_TYPE.DATE,
                date: {
                    value: value,
                    format: format ?? '',
                    year: year,
                    month: month,
                    day: day,
                    hour: hour,
                },
            });

            if (reportData.overrides.hasOwnProperty(name) && reportData.overrides[name].toUpperCase() !== 'DATE') {
                fieldData.setType(reportData.overrides[name]);
            }

            updateReportData({
                fields: { ...reportData.fields, [name]: fieldData },
            });

            return fieldData;
        },
        hasDate: (name: string) => {
            return (
                reportData.fields && reportData.fields.hasOwnProperty(name) && reportData.fields[name].isConstructedDate
            );
        },
        getDate: (name: string) => {
            return reportAct.hasDate(name) ? reportData.fields[name] : undefined;
        },
        isValidDate: (dateData: FieldDate, name?: string) => {
            const { value, year, month, day, hour, format } = dateData;
            if (
                !(
                    value === 'Date' ||
                    helperAct.isValidTimestamps(value) ||
                    (pluginConfig.derivedFieldFormatDateTypeIsAvailable &&
                        format &&
                        reportAct.hasField(value) &&
                        reportData.fields[value].isDimension)
                )
            ) {
                return false;
            }

            if (value !== 'Date' && (year || month || day || hour)) {
                return false;
            }

            if (value === 'Date') {
                const isValid = (value: string, required: boolean, types: string[], regex: any) => {
                    if (required && !value) {
                        return false;
                    }

                    if (pluginConfig.derivedFieldAutofillEmptyDateValues) {
                        if (typeof value === 'string' && value.match(regex) !== null) {
                            return true;
                        }
                    }

                    if (
                        value &&
                        (!reportAct.hasField(value) ||
                            !reportData.fields[value].isDimension ||
                            !types.includes(reportData.fields[value].type))
                    ) {
                        return false;
                    }

                    return true;
                };

                if (!isValid(year, true, ['INTEGER', 'DATE'], /^\d+$/g)) {
                    return false;
                }

                if (!isValid(month, false, ['INTEGER', 'TEXT', 'DATE'], /^(0[1-9]|1[0-2])$/g)) {
                    return false;
                }

                if (!isValid(day, false, ['INTEGER', 'DATE'], /^(0[1-9]|[12]\d|3[01])$/g)) {
                    return false;
                }

                if (!isValid(hour, false, ['INTEGER'], /^([01]\d|2[0-3])$/g)) {
                    return false;
                }

                if ((!month && day) || (!day && hour)) {
                    return false;
                }
            }

            if (name && name.length && reportAct.hasField(name)) {
                return false;
            }

            return true;
        },
        removeDate: (name: string) => {
            if (reportAct.hasDate(name)) {
                const fieldsData = { ...reportData.fields };
                delete fieldsData[name];
                updateReportData({ fields: fieldsData });
            }
        },
        addExpression: (expression: string, name: string) => {
            if (reportAct.hasField(name) || !reportAct.isValidExpression(expression)) return undefined;

            if (!name.length) {
                do {
                    name = 'expr' + reportData.exprSequence;
                    updateReportData({ exprSequence: reportData.exprSequence + 1 });
                } while (reportAct.hasField(name));
            }

            const fieldData = new QBReportField({
                name: name,
                type: FIELD_TYPE.EXPR,
                expr: expression,
            });

            if (reportData.overrides.hasOwnProperty(name) && reportData.overrides[name].toUpperCase() !== 'EXPR') {
                fieldData.setType(reportData.overrides[name]);
            }

            updateReportData({
                fields: { ...reportData.fields, [name]: fieldData },
            });

            return fieldData;
        },
        hasExpression: (name: string) => {
            return reportData.fields && reportData.fields.hasOwnProperty(name) && reportData.fields[name].isExpression;
        },
        getExpression: (name: string) => {
            return reportAct.hasExpression(name) ? reportData.fields[name] : undefined;
        },
        isValidExpression: (expression: string) => {
            for (let field in reportData.fields) {
                if (
                    reportAct.hasField(field) &&
                    reportData.fields[field].isDimension &&
                    ['INTEGER', 'DECIMAL'].includes(reportData.fields[field].type)
                ) {
                    expression = expression.split('[' + field + ']').join('');
                    if (pluginConfig.useDoubleQuoteForDerivedFieldExpressionFieldName) {
                        expression = expression.split('["' + field + '"]').join('');
                    }
                }
            }

            return expression.match(/\[[^\]]+\]/) === null;
        },
        removeExpression: (name: string) => {
            if (reportAct.hasExpression(name)) {
                const fieldsData = { ...reportData.fields };
                delete fieldsData[name];
                updateReportData({ fields: fieldsData });
            }
        },
        setTypeOverride: (field: string, type: string) => {
            if (!reportAct.hasField(field)) {
                return false;
            }

            const newFieldData = { ...reportData.fields[field] };
            setQBReportFieldType(newFieldData, type);
            updateReportData({ fields: { ...reportData.fields, [field]: newFieldData } });

            return true;
        },
    };

    const queryAct: QBQueryActList = {
        updateData: updateQueryData,
        hasDimensions: () => {
            return Object.keys(queryData.dimensions).some((dimensionKey) => queryAct.hasDimension(dimensionKey));
        },
        addDimension: (dimension: string, index: number) => {
            updateQueryData({
                dimensions: { ...queryData.dimensions, [dimension]: { name: dimension, index: index } },
            });
        },
        hasDimension: (dimension: string) => {
            return queryData.dimensions.hasOwnProperty(dimension);
        },
        getDimension: (dimension: string) => {
            return queryAct.hasDimension(dimension) ? queryData.dimensions[dimension] : undefined;
        },
        removeDimension: (dimension: string) => {
            if (queryAct.hasDimension(dimension)) {
                const dimensionsData = { ...queryData.dimensions };
                delete dimensionsData[dimension];
                updateQueryData({ dimensions: dimensionsData });
            }
        },
        createMetric: (field: string, operation: string) => {
            return new QBQueryMetric(
                typeof operation == 'string' && operation.length ? operation + '(' + field + ')' : field,
            );
        },
        hasMetrics: () => {
            return Object.keys(queryData.metrics).some(
                (metricKey) => queryAct.hasMetric(metricKey) && queryData.metrics[metricKey].isValid,
            );
        },
        addMetric: (metric: any) => {
            if (typeof metric != 'object') {
                metric = new QBQueryMetric(metric);
            }
            if (metric.isValid) {
                updateQueryData({ metrics: { ...queryData.metrics, [getQBQueryMetricExpression(metric)]: metric } });
            }
        },
        hasMetric: (metric: any) => {
            if (typeof metric != 'object') {
                metric = new QBQueryMetric(metric);
            }
            return queryData.metrics.hasOwnProperty(getQBQueryMetricExpression(metric));
        },
        getMetric: (metric: any) => {
            if (typeof metric != 'object') {
                metric = new QBQueryMetric(metric);
            }
            return queryAct.hasMetric(metric) ? queryData.metrics[getQBQueryMetricExpression(metric)] : undefined;
        },
        removeMetric: (metric: any) => {
            if (typeof metric != 'object') {
                metric = new QBQueryMetric(metric);
            }
            if (queryAct.hasMetric(metric)) {
                const metricsData = { ...queryData.metrics };
                delete metricsData[getQBQueryMetricExpression(metric)];
                updateQueryData({ metrics: metricsData });
            }
        },
        hasMetricByFieldName: (fieldName: string) => {
            return Object.keys(queryData.metrics).some((metricKey) => queryData.metrics[metricKey].field === fieldName);
        },
        removeMetricByFieldName: (fieldName: string) => {
            const removeElementKey = Object.keys(queryData.metrics).find(
                (metricKey) => queryData.metrics[metricKey].field === fieldName,
            );
            if (removeElementKey) {
                const metricsData = { ...queryData.metrics };
                delete metricsData[removeElementKey];
                updateQueryData({ metrics: metricsData });
                return true;
            } else {
                return false;
            }
        },
        addConstructedDate: (
            value: string,
            year: string,
            month: string,
            day: string,
            hour: string,
            name: string,
            format?: string,
        ) => {
            updateQueryData({
                dates: {
                    ...queryData.dates,
                    [name]: {
                        name: name,
                        value: value,
                        format: format ?? '',
                        year: year,
                        month: month,
                        day: day,
                        hour: hour,
                    },
                },
            });
        },
        hasConstructedDate: (name: string) => {
            return queryData.dates.hasOwnProperty(name);
        },
        getConstructedDate: (name: string) => {
            return queryData.dates.hasOwnProperty(name) ? queryData.dates[name] : undefined;
        },
        removeConstructedDate: (name: string) => {
            if (queryAct.hasConstructedDate(name)) {
                const datesData = { ...queryData.dates };
                delete datesData[name];
                updateQueryData({ dates: datesData });
            }
        },
        addExpression: (expression: string, name: string) => {
            updateQueryData({ exprs: { ...queryData.exprs, [name]: { name: name, expr: expression } } });
        },
        hasExpression: (name: string) => {
            return queryData.exprs.hasOwnProperty(name);
        },
        getExpression: (name: string) => {
            return queryData.exprs.hasOwnProperty(name) ? queryData.exprs[name] : undefined;
        },
        removeExpression: (name: string) => {
            if (queryAct.hasExpression(name)) {
                const exprsData = { ...queryData.exprs };
                delete exprsData[name];
                updateQueryData({ exprs: exprsData });
            }
        },
        addParam: (name: string, value: any) => {
            updateQueryData({ params: { ...queryData.params, [name]: value } });
        },
        addFilter: (filterData: FilterElement) => {
            updateQueryData({
                filters: [
                    ...queryData.filters,
                    {
                        type: filterData.type ?? 'after fetch',
                        column: filterData.column,
                        condition: filterData.condition,
                        value: filterData.value,
                    },
                ],
            });
        },
        removeFilter: (filter: any) => {
            const index = queryData.filters.findIndex((filterItem) => {
                return (
                    filterItem.column === filter.column &&
                    filterItem.condition === filter.condition &&
                    filterItem.value === filter.value
                );
            });

            if (index !== -1) {
                const newFiltersData = [...queryData.filters];
                newFiltersData.splice(index, 1);

                updateQueryData({
                    filters: newFiltersData,
                });

                return newFiltersData;
            }

            return queryData.filters;
        },
        setTableauFilter: (column: string, value: string) => {
            updateQueryData({
                tableauFilter: {
                    column: column,
                    value: value,
                },
            });
        },
        addSort: (column: string, direction?: string) => {
            if (typeof direction === 'undefined' || direction !== 'desc') {
                direction = 'asc';
            }

            updateQueryData({
                sort: [
                    ...queryData.sort,
                    {
                        column: column,
                        direction: direction,
                    },
                ],
            });
        },
        removeSort: (column: string) => {
            const index = queryData.sort.findIndex((sortItem) => sortItem.column == column);

            if (index !== -1) {
                const newSortData = [...queryData.sort];
                newSortData.splice(index, 1);

                updateQueryData({
                    sort: newSortData,
                });
            }
        },
        removeReportFieldData: (name: string) => {
            if (queryAct.hasDimension(name)) {
                queryAct.removeDimension(name);
            }
            if (queryAct.hasMetricByFieldName(name)) {
                queryAct.removeMetricByFieldName(name);
            }
            if (queryAct.hasConstructedDate(name)) {
                queryAct.removeConstructedDate(name);
            }
            if (queryAct.hasExpression(name)) {
                queryAct.removeExpression(name);
            }
        },
        parseFields: (spec: string) => {
            const defaultBehavior = (spec: string) => {
                let isQuotedField = false,
                    fieldParts: any[] = [],
                    fields = spec.split(','),
                    result: any[] = [];

                fields.forEach((field, index) => {
                    const isLast = fields.length === index + 1;
                    const withQuote = field.indexOf('"') !== -1;

                    if (!isQuotedField) {
                        if (!withQuote) {
                            result.push(field.replace(queryData.trimRegExp, ''));
                        } else {
                            isQuotedField = true;
                            fieldParts.push(field);
                        }
                    } else if (!withQuote && !isLast) {
                        fieldParts.push(field);
                    } else if (withQuote || isLast) {
                        fieldParts.push(field);
                        field = fieldParts
                            .join(',')
                            .replace(queryData.trimRegExp, '')
                            .replace(queryData.dblquoteRegExp, '');
                        if (field.length) {
                            result.push(field);
                        }

                        // Reset
                        fieldParts = [];
                        isQuotedField = false;
                    }
                });

                return result;
            };
            const doubleQuoteBehavior = (spec: string) => {
                let isQuotedField = false,
                    fieldParts: any[] = [],
                    fields = spec.split(','),
                    result: any[] = [];

                fields.forEach((field, index) => {
                    const isLast = fields.length === index + 1;
                    const startWithQuote = field.replace(queryData.trimRegExp, '').indexOf('"') === 0;
                    const endWithQuote =
                        field.replace(queryData.trimRegExp, '').lastIndexOf('"') ===
                        field.replace(queryData.trimRegExp, '').length - 1;

                    if (startWithQuote && endWithQuote) {
                        // field in quotas, but without comma (delimiter)
                        result.push(field.replace(queryData.trimRegExp, '').replace(queryData.dblquoteRegExp, ''));
                    } else if (startWithQuote && !endWithQuote) {
                        // start of field in quotas and with comma (delimiter)
                        fieldParts.push(field);
                        isQuotedField = true;
                    } else if (!startWithQuote && !endWithQuote && isQuotedField) {
                        // middle of field in quotas and with comma (delimiter)
                        fieldParts.push(field);
                    } else if (!startWithQuote && (endWithQuote || isLast)) {
                        // end of field in quotas and with comma (delimiter)
                        fieldParts.push(field);
                        result.push(
                            fieldParts
                                .join(',')
                                .replace(queryData.trimRegExp, '')
                                .replace(queryData.dblquoteRegExp, ''),
                        );
                        fieldParts = [];
                        isQuotedField = false;
                    } else if (!startWithQuote && !endWithQuote && !isQuotedField) {
                        // field without quotes: derived field or backward compatibility for regular fields or aggregation (metric)
                        result.push(field.replace(queryData.trimRegExp, ''));
                    }
                });

                return result;
            };

            if (pluginConfig.queryQuoteFiledOnlyWithComma) {
                return defaultBehavior(spec);
            } else {
                return doubleQuoteBehavior(spec);
            }
        },
        parseSorting: (spec: string) => {
            const defaultBehavior = (spec: string) => {
                const sorts = spec.split(',');
                let isQuotedField = false,
                    fieldParts: any[] = [],
                    result: any[] = [];

                sorts.forEach((sortItem, index) => {
                    const isLast = sorts.length === index + 1;
                    const withQuote = sortItem.indexOf('"') !== -1;

                    if (!isQuotedField && withQuote) {
                        isQuotedField = true;
                        fieldParts.push(sortItem);
                        return false; //continue
                    } else if (isQuotedField && !withQuote && !isLast) {
                        fieldParts.push(sortItem);
                    } else if (isQuotedField && (withQuote || isLast)) {
                        isQuotedField = false;
                        fieldParts.push(sortItem);
                        sortItem = fieldParts.join(',');
                        fieldParts = [];
                    }

                    sortItem = sortItem.replace(queryData.trimRegExp, '').replace(queryData.dblquoteRegExp, '');
                    if (sortItem.length) {
                        const pattern = new RegExp('^(.+){1,1}? (asc|desc){1,1}$', 'g');
                        const matches = pattern.exec(sortItem);

                        if (matches != null) {
                            result.push({
                                column: matches[1]
                                    .replace(queryData.trimRegExp, '')
                                    .replace(queryData.dblquoteRegExp, ''),
                                order: matches[2],
                            });
                        } else {
                            result.push({
                                column: sortItem,
                            });
                        }
                    }
                });

                return result;
            };
            const doubleQuoteBehavior = (spec: string) => {
                const sorts = spec.split(',');
                let isQuotedField = false,
                    fieldParts: any[] = [],
                    result: any[] = [];

                sorts.forEach((sortItem, index) => {
                    const isLast = sorts.length === index + 1;
                    const startWithQuote = sortItem.replace(queryData.trimRegExp, '').indexOf('"') === 0;
                    const endWithQuote = sortItem.replace(queryData.trimRegExp, '').lastIndexOf('"') > 0;

                    if (startWithQuote && !endWithQuote) {
                        // start of field in quotas and with comma (delimiter)
                        isQuotedField = true;
                        fieldParts.push(sortItem);
                        return false; //continue
                    } else if (!startWithQuote && !endWithQuote && isQuotedField) {
                        // middle of field in quotas and with comma (delimiter)
                        fieldParts.push(sortItem);
                        return false; //continue
                    } else if (!startWithQuote && (endWithQuote || isLast)) {
                        // end of field in quotas and with comma (delimiter)
                        isQuotedField = false;
                        fieldParts.push(sortItem);
                        sortItem = fieldParts.join(',');
                        fieldParts = [];
                    }

                    const pattern = new RegExp('^(.+){1,1}? (asc|desc){1,1}$', 'g');
                    const matches = pattern.exec(sortItem);

                    if (matches != null) {
                        result.push({
                            column: matches[1].replace(queryData.trimRegExp, '').replace(queryData.dblquoteRegExp, ''),
                            order: matches[2],
                        });
                    } else {
                        result.push({
                            column: sortItem.replace(queryData.trimRegExp, '').replace(queryData.dblquoteRegExp, ''),
                        });
                    }
                });

                return result;
            };

            if (pluginConfig.queryQuoteFiledOnlyWithComma) {
                return defaultBehavior(spec);
            } else {
                return doubleQuoteBehavior(spec);
            }
        },
        setQuery: (query: string) => {
            // Reset data
            updateQueryData({
                command: '',
                dimensions: {},
                metrics: {},
                dates: {},
                exprs: {},
                filters: [],
                sort: [],
                params: {},
                tableauFilter: null,
            });

            if (!(typeof query == 'string' && query)) return false;

            // Parse query and set new data
            const parts = query.split('\n');

            parts.forEach((partItem) => {
                // Skip comments
                if (partItem.charAt(0) == '#') return false; //continue

                const predicate = partItem.split('=');
                if (predicate.length >= 1) {
                    let varNameMatches,
                        varName = null;
                    //ToDo check, typescript warn
                    let name = (predicate.shift() ?? '').replace(queryData.trimRegExp, '');
                    let value = predicate.join('=').replace(queryData.trimRegExp, '');

                    if ((varNameMatches = name.match(/^var\s+(.+)$/i)) !== null) {
                        name = 'VAR';
                        varName = varNameMatches[1];
                    }
                    switch (name.toUpperCase()) {
                        case 'COMMAND':
                            updateQueryData({ command: value });
                            break;

                        case 'DIMENSIONS':
                        case 'FIELDS':
                            //Skip for GA -> it doesn't support 'DIMENSIONS'
                            if (!(pluginConfig.useMetadataFlow && name.toUpperCase() == 'DIMENSIONS')) {
                                const dimensions = queryAct.parseFields(value);
                                dimensions.forEach((dimension, index) => {
                                    queryAct.addDimension(dimension, index);
                                });
                            }
                            break;

                        case 'METRICS':
                        case 'AGGREGATES':
                            //Skip for GA -> it doesn't support 'METRICS' && 'AGGREGATES'
                            if (!pluginConfig.useMetadataFlow) {
                                const metrics = value.split(/\)\s*,/);
                                metrics.forEach((metric) => {
                                    if (metric.indexOf('(') !== -1 && metric.indexOf(')') === -1) {
                                        metric = metric + ')';
                                    }

                                    queryAct.addMetric(
                                        metric.replace(queryData.trimRegExp, '').replace(queryData.dblquoteRegExp, ''),
                                    );
                                });
                            }
                            break;

                        case 'FILTER':
                            const filters = value.split(/\s+(AND|OR)\s+/);

                            filters.forEach((filterItem) => {
                                if (
                                    typeof filterItem === 'string' &&
                                    filterItem.length &&
                                    filterItem !== 'AND' &&
                                    filterItem !== 'OR'
                                ) {
                                    const filter = filterItem
                                        .replace(queryData.bracketRegExp, '')
                                        .replace(queryData.trimRegExp, '');

                                    const conditionVariants = getFilterConditionVariants(pluginConfig);
                                    const patternParts = Object.values(conditionVariants).map(
                                        (conditionSettings: any) => {
                                            return conditionSettings.filterStringValue ?? conditionSettings.value;
                                        },
                                    );
                                    const pattern = new RegExp(
                                        '^(.+){1,1}? (' + patternParts.join('|') + ') (.+){1,1}?$',
                                        'g',
                                    );

                                    const matches = pattern.exec(filter);
                                    if (matches != null) {
                                        let column = matches[1].replace(queryData.trimRegExp, '');
                                        if (pluginConfig.setQueryFilterColumnRemoveDoubleQuote) {
                                            column = column.replace(queryData.dblquoteRegExp, '');
                                        }
                                        const condition = matches[2];
                                        const val = matches[3].replace(queryData.trimRegExp, '');

                                        if (
                                            ['is', 'is not'].includes(condition) &&
                                            !pluginConfig.removeFilterConditionEmptyAndNotEmpty
                                        ) {
                                            if (val === 'null') {
                                                queryAct.addFilter({
                                                    column: column,
                                                    condition: condition + ' null',
                                                    value: null,
                                                });
                                            }
                                        } else if (condition === 'in' && !pluginConfig.removeFilterConditionIn) {
                                            queryAct.addFilter({
                                                column: column,
                                                condition: condition,
                                                value: val
                                                    .replace(queryData.bracketRegExp, '')
                                                    .replace(queryData.trimRegExp, '')
                                                    .split(/'{0,1}\s*,\s*'{0,1}/)
                                                    .map(function (value) {
                                                        return value.replace(queryData.quoteRegExp, '');
                                                    })
                                                    .join(', '),
                                            });
                                        } else {
                                            queryAct.addFilter({
                                                column: column,
                                                condition: condition,
                                                value: val.replace(queryData.quoteRegExp, ''),
                                            });
                                        }
                                    }
                                }
                            });
                            break;

                        case 'SSRS-FILTER':
                            const spec = value.split('==');
                            if (!queryData.tableauFilter && spec.length >= 2) {
                                queryAct.setTableauFilter(
                                    (spec.shift() ?? '').replace(queryData.trimRegExp, ''),
                                    spec
                                        .join('==')
                                        .replace(queryData.trimRegExp, '')
                                        .replace(queryData.quoteRegExp, ''),
                                );
                            }
                            break;

                        case 'SORT':
                            const sorts = queryAct.parseSorting(value);
                            sorts.forEach((item) => {
                                queryAct.addSort(item.column, item.order);
                            });
                            break;

                        case 'VAR':
                            const pattern = new RegExp('^(date|formatDate)\\((.+)\\)$', '');
                            const matches = value.match(pattern);

                            if (!varName) return false;

                            // Date / formatDate functions
                            if (matches !== null) {
                                const func = matches[1];
                                const spec = queryAct.parseFields(matches[2]);
                                if ('date' === func && spec.length >= 1 && spec.length <= 4) {
                                    queryAct.addConstructedDate(
                                        'Date',
                                        spec[0],
                                        spec.length >= 2 ? spec[1] : '',
                                        spec.length >= 3 ? spec[2] : '',
                                        spec.length == 4 ? spec[3] : '',
                                        varName,
                                    );
                                } else if (
                                    pluginConfig.derivedFieldFormatDateTypeIsAvailable &&
                                    'formatDate' === func &&
                                    spec.length == 2
                                ) {
                                    queryAct.addConstructedDate(spec[0], '', '', '', '', varName, spec[1]);
                                }
                            }
                            // Timestamp
                            else if (helperAct.isValidTimestamps(value)) {
                                queryAct.addConstructedDate(value, '', '', '', '', varName);
                            }
                            // Expression
                            else {
                                queryAct.addExpression(value.replace(queryData.trimRegExp, ''), varName);
                            }
                            break;

                        case 'PARAM STARTDATE':
                        case 'PARAM ENDDATE':
                        case 'PARAM SEGMENTS':
                        case 'PARAM FILTERS':
                            //Only for GA
                            if (pluginConfig.useMetadataFlow) {
                                const paramType = name.toUpperCase().split(' ').pop();
                                if (typeof value === 'string' && value.length && paramType) {
                                    const paramValue = value.replace(queryData.trimRegExp, '');
                                    switch (paramType) {
                                        case 'FILTERS':
                                            queryAct.addParam('filters', paramValue);
                                            break;
                                        case 'STARTDATE':
                                            queryAct.addParam('startDate', paramValue);
                                            break;
                                        case 'ENDDATE':
                                            queryAct.addParam('endDate', paramValue);
                                            break;
                                        case 'SEGMENTS':
                                            queryAct.addParam('segments', paramValue.split(/\s*,\s*/));
                                            break;
                                    }
                                }
                            }
                            break;

                        case 'LIMIT':
                            //Only for GA
                            if (pluginConfig.useMetadataFlow) {
                                const limit = parseInt(value);
                                if (!isNaN(limit) && limit > 0) {
                                    queryAct.addParam('limit', limit);
                                }
                            }
                            break;
                    }
                }
            });
        },
        quoteField: (field: string) => {
            if (pluginConfig.queryQuoteFiledOnlyWithComma) {
                return field.indexOf(',') === -1 ? field : '"' + field + '"';
            } else {
                return '"' + field + '"';
            }
        },
        quoteFilterValue: (value: any) => {
            if (null === value) {
                value = 'null';
            } else if ('string' === typeof value && value.length) {
                if (value.toLowerCase() !== 'null') {
                    queryData.numericRegExp.lastIndex = 0;
                    if (queryData.numericRegExp.exec(value) === null) {
                        queryData.variableRegExp.lastIndex = 0;
                        if (queryData.variableRegExp.exec(value) === null) {
                            value = "'" + value + "'";
                        }
                    }
                }
            } else if ('number' !== typeof value) {
                value = "''";
            }

            return value;
        },
        getQuery: () => {
            const query = [];
            const ending = queryData.separator;

            if (queryData.command.length) {
                query.push('command = ' + queryData.command);
            } else {
                // Constructed dates
                Object.keys(queryData.dates).forEach((dateKey) => {
                    const dateItem = queryData.dates[dateKey];
                    if (pluginConfig.derivedFieldFormatDateTypeIsAvailable && dateItem.value && dateItem.format) {
                        query.push(
                            'var ' +
                                dateItem.name +
                                ' = formatDate(' +
                                queryAct.quoteField(dateItem.value) +
                                ', ' +
                                queryAct.quoteField(dateItem.format) +
                                ')',
                        );
                    } else if (helperAct.isValidTimestamps(dateItem.value)) {
                        query.push('var ' + dateItem.name + ' = ' + dateItem.value);
                    } else {
                        query.push(
                            'var ' +
                                dateItem.name +
                                ' = date(' +
                                queryAct.quoteField(dateItem.year) +
                                (dateItem.month
                                    ? ', ' +
                                      queryAct.quoteField(dateItem.month) +
                                      (dateItem.day
                                          ? ', ' +
                                            queryAct.quoteField(dateItem.day) +
                                            (dateItem.hour ? ', ' + queryAct.quoteField(dateItem.hour) : '')
                                          : '')
                                    : '') +
                                ')',
                        );
                    }
                });

                // Expressions
                Object.keys(queryData.exprs).forEach((exprKey) => {
                    const exprItem = queryData.exprs[exprKey];
                    query.push('var ' + exprItem.name + ' = ' + exprItem.expr);
                });

                // Dimensions
                const dimensionsList = Object.keys(queryData.dimensions)
                    .sort((a, b) => {
                        return queryData.dimensions[a].index - queryData.dimensions[b].index;
                    })
                    .map((dimensionKey) => {
                        let columnName = dimensionKey;
                        //Quite filed for old plugin or ew not Expression Or ConstructedDate
                        if (
                            !pluginConfig.queryQuoteFiledExceptExpressionOrConstructedDate ||
                            (!queryAct.hasExpression(columnName) && !queryAct.hasConstructedDate(columnName))
                        ) {
                            columnName = queryAct.quoteField(columnName);
                        }
                        return columnName;
                    });

                if (dimensionsList.length) {
                    query.push('fields = ' + dimensionsList.join(', '));
                }

                // Metrics
                const metricsList = Object.keys(queryData.metrics)
                    .filter(
                        (metricKey) =>
                            queryData.metrics.hasOwnProperty(metricKey) && queryData.metrics[metricKey].isValid,
                    )
                    .map((metricKey) => getQBQueryMetricExpression(queryData.metrics[metricKey]));

                if (metricsList.length) {
                    query.push('aggregates = ' + metricsList.join(', '));
                }

                // Filters
                const filters: any[] = [];
                queryData.filters.forEach((filter) => {
                    let condition = filter.condition;
                    let columnName = filter.column;

                    if (condition === '=') {
                        condition = '==';
                    }

                    //Quite filed for old plugin or ew not Expression Or ConstructedDate
                    if (
                        !pluginConfig.queryQuoteFiledExceptExpressionOrConstructedDate ||
                        (!queryAct.hasExpression(columnName) && !queryAct.hasConstructedDate(columnName))
                    ) {
                        columnName = queryAct.quoteField(columnName);
                    }

                    if (
                        filter.value === null &&
                        ['is null', 'is not null'].includes(condition) &&
                        !pluginConfig.removeFilterConditionEmptyAndNotEmpty
                    ) {
                        filters.push(columnName + ' ' + condition);
                    } else if (condition === 'in' && !pluginConfig.removeFilterConditionIn) {
                        const values = filter.value
                            .split(/\s*,\s*/)
                            .map(function (value: string) {
                                return queryAct.quoteFilterValue(value);
                            })
                            .join(', ');

                        filters.push(columnName + ' ' + condition + ' (' + values + ')');
                    } else {
                        filters.push(columnName + ' ' + condition + ' ' + queryAct.quoteFilterValue(filter.value));
                    }
                });

                if (filters.length) {
                    query.push('filter = ' + filters.join(' AND '));
                }

                // Tableau Filters
                if (queryData.tableauFilter) {
                    query.push(
                        'ssrs-filter = ' +
                            queryData.tableauFilter.column +
                            ' == ' +
                            (queryData.tableauFilter.value ?? "''"), //quoteTableauFilterValue
                    );
                }

                // Sort
                const sortList: any[] = [];
                queryData.sort.forEach((sort) => {
                    let columnName = sort.column;

                    //Quite filed for old plugin or ew not Expression Or ConstructedDate
                    if (
                        !pluginConfig.queryQuoteFiledExceptExpressionOrConstructedDate ||
                        (!queryAct.hasExpression(columnName) && !queryAct.hasConstructedDate(columnName))
                    ) {
                        columnName = queryAct.quoteField(columnName);
                    }
                    sortList.push(columnName + ' ' + sort.direction);
                });

                if (sortList.length) {
                    query.push('sort = ' + sortList.join(', '));
                }

                ['filters', 'startDate', 'endDate', 'segments'].forEach((name) => {
                    if (typeof queryData.params[name] === 'string' && queryData.params[name].length) {
                        query.push('param ' + name + ' = ' + queryData.params[name]);
                    } else if (Array.isArray(queryData.params[name]) && queryData.params[name].length) {
                        query.push('param ' + name + ' = ' + queryData.params[name].join(', '));
                    }
                });

                const limit = parseInt(queryData.params['limit']);
                if (!isNaN(limit) && limit > 0) {
                    query.push('limit = ' + queryData.params['limit']);
                }
            }

            return query.join(queryData.separator) + ending;
        },
    };

    useEffect(() => {
        //rebuildCommand(); //move to popup load
        queryAct.setQuery(configData.query);
    }, []);

    useEffect(() => {
        if (configData.profileId) {
            //ToDo Editor.prototype.isSummaryTabVisible ref_has_tab
            setIsSummaryTabVisible(configData.has_summary);
            setActiveTab(!configData.has_summary ? 'command' : 'visual');
        }
    }, [configData.profileId]);

    useEffect(() => {
        if (configData.plugin_alias) {
            setPluginConfig(getQueryBuilderPluginConfig(configData.plugin_alias));
        }
    }, [configData.plugin_alias]);

    useEffect(() => {
        //console.log(77, reportDataState);
    }, [reportDataState]);

    useEffect(() => {
        //console.log(88, queryDataState);
    }, [queryDataState]);

    const rebuildCommand = async () => {
        if (
            configData.profileId &&
            configData.reportID &&
            configData.itemId &&
            configData.itemType &&
            configData.query.length
        ) {
            queryAct.setQuery(queryVal);
            const result = await reportAct.initData();
            if (result?.error) {
                alert(result?.error);
            } else {
                const fields: string[] = [];

                Object.keys(queryData.dates).forEach((dateKey) => {
                    const dateComponents = {
                        value: queryData.dates[dateKey].value,
                        year: queryData.dates[dateKey].year,
                        month: queryData.dates[dateKey].month,
                        day: queryData.dates[dateKey].day,
                        hour: queryData.dates[dateKey].hour,
                        format: queryData.dates[dateKey].format,
                    };
                    reportAct.addDate(dateComponents, queryData.dates[dateKey].name);
                });

                Object.keys(queryData.exprs).forEach((exprsKey) => {
                    const item = queryData.exprs[exprsKey];
                    reportAct.addExpression(item.expr, item.name);
                });

                Object.keys(queryData.dimensions).forEach((filedKey) => {
                    if (
                        !reportAct.hasField(filedKey) &&
                        (!reportData.prompts.hasOwnProperty(filedKey) || reportData.prompts[filedKey].values === 'none')
                    ) {
                        queryAct.removeDimension(filedKey);
                        fields.push(filedKey);
                    }
                });

                if (fields.length) {
                    setQueryVal(queryAct.getQuery());
                    alert(
                        'Command was rebuilt and the following ' +
                            (fields.length === 1 ? 'field was' : 'fields were') +
                            ' removed: ' +
                            fields.join(', '),
                    );
                }
            }
        } else {
            console.log('Required options are not valid');
        }
    };

    const showViewer = async () => {
        if (configData.profileId && configData.reportID) {
            //logic must be here
        } else {
            console.log('Required options are not valid');
        }

        const result = await reportAct.initData();
        if (result?.error) {
            alert(result?.error);
        } else {
            //new qlik.VisualEditor
        }
    };

    const showVisualBuilder = async () => {
        if (pluginConfig.isRequiredDatasourceObject && !configData.extReportID) {
            const pluginObjectName = `${pluginConfig.elementName}${
                pluginConfig.elementType == 'none' ? '' : ' ' + t('plugin_element_type.' + pluginConfig.elementType)
            }`;
            if (form) {
                form.hookFormSetError('external_report_external_id', {
                    type: 'server',
                    message: t('empty_ext_report_id_msg', { objectName: pluginObjectName }),
                });
            } else {
                alert(t('empty_ext_report_id_msg', { objectName: pluginObjectName }));
            }
        } else {
            setIsLoading(true);
            //var mask = new Ext.DevxBodyMask({msg: 'Loading query builder...'});
            const result = await reportAct.initData();
            setIsLoading(false);
            if (result?.error) {
                alert(result?.error);
            } else {
                setShowQueryBuilderPopup(true);
            }
        }
    };

    const visualEditorButton =
        configData.has_ve && activeTab !== 'visual' ? (
            <Button variant={'contained'} startIcon={<IconMi icon="columns" />} onClick={showVisualBuilder}>
                {t('visual_editor_btn')}
            </Button>
        ) : undefined;

    useEffect(() => {
        if (form) form.hookFormClearErrors('external_report_external_id');
    }, [configData.extReportID]);

    const saveFieldTypeOverrides = () => {
        let typeOverridesData: any = {};
        let aliasesData: any = {};
        let saveOverrides = false;

        if (pluginConfig.useMetadataFlow) return false;

        Object.keys(reportData.fields).forEach((fieldKey: string) => {
            if (
                !saveOverrides &&
                ((reportData.fields[fieldKey].typeIsChanged && !reportData.fields[fieldKey].isUsed) ||
                    reportData.fields[fieldKey].aliasIsChanged)
            ) {
                saveOverrides = true;
            }
            if (
                reportData.fields[fieldKey].typeOverriden ||
                reportData.fields[fieldKey].type != reportData.fields[fieldKey].typeOriginal
            ) {
                typeOverridesData[fieldKey] = reportData.fields[fieldKey].type;
            }

            if (typeof reportData.fields[fieldKey].alias === 'string' && reportData.fields[fieldKey].alias.length) {
                aliasesData[fieldKey] = reportData.fields[fieldKey].alias;
            }
        });
        if (saveOverrides) {
            pluginQueryBuilderAPI
                .saveFieldTypeOverrides(configData.reportID, typeOverridesData, aliasesData)
                .then((response) => {});
        }
    };

    return (
        <PluginQBContext.Provider
            value={{ queryAct, queryData, reportAct, reportData, configData, helperAct, pluginConfig }}
        >
            <Box>
                {isLoading && (
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                            color: (theme) => alpha(theme.palette.text.primary, 0.4),
                        }}
                    />
                )}
                {isSummaryTabVisible && (
                    <Stack direction={'row'} sx={styles.commandHeader}>
                        <Tabs
                            value={activeTab}
                            onChange={(event: React.SyntheticEvent, newValue: string) => {
                                setActiveTab(newValue);
                            }}
                        >
                            {tabs.map((tab) => {
                                return (
                                    <Tab
                                        label={t(`mode.${tab}`)}
                                        key={tab}
                                        value={tab}
                                        icon={<IconMi icon={tab == 'visual' ? 'columns' : 'command'} fontSize={'16'} />}
                                        iconPosition="start"
                                    />
                                );
                            })}
                        </Tabs>
                    </Stack>
                )}
                {!isSummaryTabVisible && configData.customTabLabel && (
                    <Stack direction={'row'} sx={styles.commandHeader}>
                        <Tabs value={'custom'}>
                            <Tab
                                label={t(`custom_tab_label.${configData.customTabLabel}`)}
                                value={'custom'}
                                icon={<IconMi icon={'command'} fontSize={'16'} />}
                                iconPosition="start"
                            />
                        </Tabs>
                    </Stack>
                )}

                {activeTab === 'visual' && (
                    <Box>
                        <Box sx={styles.visualPreviewWrapper}>
                            <Box sx={styles.visualPreviewHandler}>
                                <QueryBuilderSummaryPreview
                                    isShowQueryBuilderPopup={showQueryBuilderPopup}
                                    needLoadInitialQueryBuilderData={needLoadInitialQueryBuilderData}
                                    setNeedLoadInitialQueryBuilderData={setNeedLoadInitialQueryBuilderData}
                                    formInitialValues={formInitialValues}
                                />
                            </Box>
                            <Box sx={styles.visualPreviewCover} onClick={showVisualBuilder}>
                                <Button sx={styles.visualPreviewCoverBtn} startIcon={<IconMi icon="columns" />}>
                                    {t('visual_editor_btn')}
                                </Button>
                            </Box>
                        </Box>
                        <Stack sx={styles.commandFooter} direction={'row'} alignItems={'center'}>
                            {actionButtonsBlock(visualEditorButton)}
                        </Stack>
                    </Box>
                )}
                {activeTab !== 'visual' && (
                    <Box sx={styles.container}>
                        <Resizable
                            minHeight={325}
                            defaultSize={{
                                height: 320, //275 editor + 45 bottom panel
                            }}
                            enable={{
                                bottom: true,
                            }}
                            style={styles.resizableComponent as React.CSSProperties}
                            handleStyles={{
                                bottom: {
                                    bottom: 0,
                                    right: 0,
                                    left: 'auto',
                                    width: 'auto',
                                    height: 'auto',
                                },
                            }}
                            handleComponent={{
                                bottom: (
                                    <Box sx={{ pr: 1.5, pb: '14px' }}>
                                        <IconMi
                                            icon={'resize'}
                                            sx={{
                                                fontSize: '16px',
                                                color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
                                            }}
                                        />
                                    </Box>
                                ),
                            }}
                        >
                            <Box sx={styles.commandContainer}>
                                <Box
                                    sx={{
                                        ...styles.commandContent,
                                        borderColor: (theme) =>
                                            validationSuccess
                                                ? alpha(theme.palette.success.main, 0.64)
                                                : alpha(theme.palette.error.main, 0.64),
                                    }}
                                >
                                    <Editor
                                        data-test={'plugin_query_builder_editor_field'}
                                        onValueChange={(newVal) => {
                                            setQueryVal(newVal);
                                            configData.query = queryVal;
                                            onChangedQueryValue(newVal);
                                        }}
                                        highlight={(code) => editorHighlightWithLineNumbers(code)}
                                        value={queryVal}
                                        onBlur={() => {
                                            queryAct.setQuery(queryVal);
                                        }}
                                        textareaId={editorTextareaId}
                                        className="editor"
                                        style={
                                            {
                                                ...styles.editorComponent,
                                                fontFamily: appTheme.font.monospace,
                                                fontSize: '11px',
                                            } as CSSProperties
                                        }
                                    />
                                </Box>
                            </Box>
                            <Stack sx={styles.commandFooter} direction={'row'} alignItems={'center'} spacing={1}>
                                {actionButtonsBlock(visualEditorButton)}
                            </Stack>
                        </Resizable>
                    </Box>
                )}

                {showQueryBuilderPopup && (
                    <QueryBuilderPopup
                        config={configData}
                        onClose={() => {
                            setShowQueryBuilderPopup(false);
                            //reportAct.updateData(resetReportDataObj); //reset obj
                            queryAct.setQuery(queryVal); //reset obj
                        }}
                        onApply={() => {
                            setShowQueryBuilderPopup(false);
                            const newQuery = queryAct.getQuery();
                            setQueryVal(newQuery);
                            onChangedQueryValue(newQuery);
                            saveFieldTypeOverrides();

                            onClickSaveQueryBuilderPopup();
                        }}
                    />
                )}
            </Box>
        </PluginQBContext.Provider>
    );
}
