import { useDispatch, useSelector } from 'react-redux';
import { reportPaletteState, setPaletteColor } from 'store/reportPaletteSlice';
import { AssocArray } from 'tools/types';
import { ReportContentNS } from 'components/report-content/index';
import ContentMode = ReportContentNS.ContentMode;
import { useEffect, useState } from 'react';

export default function usePalette(
    context: ContentMode,
    elementId: number,
    defaultPalette: Array<string>,
    localPaletteDefault?: AssocArray<string>,
) {
    const dispatch = useDispatch();

    const globalPalette: any = useSelector((state: any) => {
        const palette = (state?.reportPalette as reportPaletteState) ?? {};
        return palette;
    });

    const [localPalette, setLocalPalette] = useState(localPaletteDefault ?? {});
    useEffect(() => {
        if (!localPaletteDefault) {
            return;
        }
        setLocalPalette(localPaletteDefault);
    }, [localPaletteDefault]);

    const getPaletteColor = (name: string, index: number, getDefaultColor: boolean = false) => {
        const defaultColor = defaultPalette[index % defaultPalette.length];
        if (getDefaultColor) {
            return defaultColor;
        }

        if (typeof localPalette[name] != 'undefined') {
            return localPalette[name];
        }

        if (context == 'edit') {
            const key = elementId + '_' + name;
            let result = '';
            if (typeof globalPalette[key] == 'undefined') {
                //@ts-ignore
                if (isNaN(name)) {
                    setTimeout(
                        () => dispatch(setPaletteColor({ elementId: elementId, name: name, color: defaultColor })),
                        10,
                    );
                }
                result = defaultColor;
            } else {
                result = globalPalette[key];
            }

            setLocalPalette((prevState) => {
                const list = { ...prevState };
                list[name] = result;
                return list;
            });
            return result;
        }
    };

    const resetPaletteColor = (name: string) => {
        setLocalPalette((prevState) => {
            const list = { ...prevState };
            delete list[name];
            return list;
        });
    };

    return { getPaletteColor, localPalette, setLocalPalette, resetPaletteColor };
}
