import React from 'react';
import { Box } from '@mui/material';
import useBundleTranslation from 'i18n';

export default function CertificationData({
    certificationData,
    onUserNameClick,
    markUserName,
}: {
    certificationData: {
        color?: string;
        name?: string;
        lastUpdatedBy?: string;
        lastUpdatedTime?: string;
    };
    onUserNameClick?: (e: any) => void;
    markUserName?: boolean;
}) {
    const { t } = useBundleTranslation('components/common/certification_data');

    return (
        <>
            <Box
                className={'certification_title'}
                component={'span'}
                sx={{ color: certificationData.color, fontWeight: 600 }}
            >
                {certificationData?.name !== '' && (
                    <Box component={'span'} className={'certification_name'}>
                        {certificationData.name}{' '}
                    </Box>
                )}
                <Box component={'span'} className={'certification_label'}>
                    {t('certification.certified')}
                </Box>
            </Box>
            {certificationData?.lastUpdatedBy !== '' && (
                <Box className={'certification_by'} component={'span'}>
                    {' '}
                    {t('certification.by')}{' '}
                    <Box
                        component={'span'}
                        className={'certification_by_name'}
                        onClick={(e) => {
                            if (onUserNameClick) onUserNameClick(e);
                        }}
                        sx={{ color: markUserName ? 'primary.main' : undefined }}
                    >
                        {certificationData.lastUpdatedBy}
                    </Box>
                </Box>
            )}
            {certificationData?.lastUpdatedTime !== '' && (
                <Box className={'certification_on'} component={'span'}>
                    {' '}
                    {t('certification.on')}{' '}
                    <Box className={'certification_on_datetime'} component={'span'}>
                        {certificationData.lastUpdatedTime}
                    </Box>
                </Box>
            )}
        </>
    );
}
