import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { Popup } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { Box, Container, FormControl, FormLabel } from '@mui/material';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import Moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { paramsUrl } from 'components/common/form/formTools';
import GridComponent from 'components/common/grid/GridComponent';
import { instance } from 'api/api';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { APIResponse } from 'tools/types';
import IconMi from 'components/common/icon/IconMi';

export interface DatasetDeleteDataProps extends FormControlProps {
    gridURL: string;
    submitURL: string;
    rangeURL: string;
    checkURL: string;
}

export default function DatasetDeleteData({ controlProps }: FormElementControlPropsType<DatasetDeleteDataProps>) {
    const { t } = useBundleTranslation(['components/common/dataset_delete_data']);
    const urlParams = useParams();
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);
    const defaultDate = Moment(new Date()).format('yyyy-MM-DD');
    const form = useCustomSimplifiedForm({
        delete_options: 'range',
        start_date: defaultDate,
        end_date: defaultDate,
    });

    const afterDelete = () => {
        queryClient
            .invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'dataFetchCommandHasData',
            })
            .finally(() => {
                setIsLoading(false);
                setIsOpen(false);
            });
    };

    const gridURL = paramsUrl(controlProps.gridURL, urlParams);
    const deleteOptions = form.form.hookFormWatch('delete_options');

    const handlePopupApply = () => {
        if (deleteOptions == 'selected') {
            setIsOpen(false);
            return;
        }

        setIsLoading(true);
        const data = form.form.hookFormGetValues();

        const submit = () => {
            instance.post(paramsUrl(controlProps.submitURL, urlParams), data).finally(() => {
                afterDelete();
            });
        };

        instance
            .post(paramsUrl(controlProps.checkURL, urlParams), data)
            .then((response: AxiosResponse<APIResponse<{ status: string; instances?: number; message?: string }>>) => {
                if (response.data.data.status == 'ERROR') {
                    alert(response.data.data?.message);
                    setIsLoading(false);
                } else {
                    if (confirm(t('confirm_delete', { count: response.data.data?.instances }))) {
                        submit();
                    } else {
                        setIsLoading(false);
                    }
                }
            });
        //
    };

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        setIsLoading(true);
        instance
            .get(paramsUrl(controlProps.rangeURL, urlParams))
            .then((response: AxiosResponse<APIResponse<{ range: { start: string; end: string } }>>) => {
                form.form.hookFormSetValue('start_date', Moment(response.data.data.range.start).format('yyyy-MM-DD'));
                form.form.hookFormSetValue('end_date', Moment(response.data.data.range.end).format('yyyy-MM-DD'));
                setIsLoading(false);
            });
    }, [isOpen]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t('hint_1') + ' '}
            <Box
                component={'a'}
                href="#"
                onClick={(event) => {
                    event.preventDefault();
                    setIsOpen(true);
                }}
                className={'invert-link'}
                sx={{ display: 'inline-flex', alignItems: 'center', mx: 0.5 }}
            >
                <IconMi icon={'trash'} sx={{ fontSize: '14px', mr: 0.5 }} />
                {t('delete')}
            </Box>
            {' ' + t('hint_2')}
            <Popup
                settings={{
                    title: t('title'),
                    textOK: t(deleteOptions == 'selected' ? 'done' : 'delete'),
                    needTranslation: false,
                }}
                open={isOpen}
                onHide={() => setIsOpen(false)}
                onConfirm={handlePopupApply}
                maxWidth={'popupSm'}
            >
                <LoadingPlaceholder open={isLoading} />
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    {t('delete')}
                    <TemplateFormComponent
                        elementProps={form}
                        component={'FormRadioGroup'}
                        componentValues={[
                            { label: t('all'), value: 'all' },
                            { label: t('selected'), value: 'selected' },
                            { label: t('range'), value: 'range' },
                        ]}
                        name={'delete_options'}
                    />
                    <br />
                </Container>
                <Container
                    className={deleteOptions == 'selected' ? '' : 'd-none'}
                    maxWidth={'sm'}
                    component="main"
                    disableGutters={true}
                >
                    <GridComponent uid={`dataset-delete-data-${gridURL}`} settingsUrl={gridURL} formGrid />
                </Container>
                <>
                    <Container
                        className={deleteOptions == 'range' ? '' : 'd-none'}
                        maxWidth={'sm'}
                        component="main"
                        disableGutters={true}
                    >
                        <FormControl fullWidth>
                            <FormLabel>{t('delete_from')}</FormLabel>
                            <TemplateFormComponent
                                component={'FormDatePicker'}
                                elementProps={form}
                                name={'start_date'}
                            />
                        </FormControl>
                        <br />
                    </Container>
                    <Container
                        className={deleteOptions == 'range' ? '' : 'd-none'}
                        maxWidth={'sm'}
                        component="main"
                        disableGutters={true}
                    >
                        <FormControl fullWidth>
                            <FormLabel>{t('delete_to')}</FormLabel>
                            <TemplateFormComponent component={'FormDatePicker'} elementProps={form} name={'end_date'} />
                            <br />
                        </FormControl>
                    </Container>
                </>
            </Popup>
        </Box>
    );
}

export class DatasetDeleteDataBuilder extends FormComponentBuilder {
    prepareProps(): DatasetDeleteDataProps {
        return {
            ...this.controlProps,
            gridURL: this.elementProps.componentProps?.gridURL,
            submitURL: this.elementProps.componentProps?.submitURL,
            rangeURL: this.elementProps.componentProps?.rangeURL,
            checkURL: this.elementProps.componentProps?.checkURL,
        };
    }
}
