import React, { useContext, useMemo, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentSettingsRangeChart = ReportContentNS.ComponentSettingsRangeChart;
import DatasetField = ReportContentNS.DatasetField;
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import DrillTo = ReportContentNS.DrillTo;
import DrillToField = ReportContentNS.DrillToField;
import DrillToForm from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/DrillToForm';
import { addOrReplaceInStateArray, removeFromStateArray } from 'tools/tools';
import DrillToList from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/drill-to/DrillToList';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsRangeChart> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'display_value',
    'value_axis_label',
];
const componentNumericFormField: Array<keyof ComponentSettingsRangeChart> = ['height'];

export default function RangeChartEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsRangeChart> & { block: BlockType<ComponentSettingsRangeChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];

    const [measuredFieldStart, setMeasuredFieldStart] = useState(component.settings.range_start);
    const [measuredFieldEnd, setMeasuredFieldEnd] = useState(component.settings.range_end);

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        x_field: component.settings.x_field.reference_name,
    };

    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsRangeChart = {
            ...component.settings,
            // measureFields: [measuredField],
        } as ComponentSettingsRangeChart;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const newXField = datasetFields.find(
            (f) => f.reference_name == elementProps.form.hookFormGetValues('x_field'),
        ) as DatasetField;

        // const resultFieldAggregations: any = {};
        // resultFieldAggregations[measuredField.reference_name] = measuredField.aggregation_function;

        const dataQueries: ComponentSettingsChartDataQueries = {
            table: {
                resultFields: [],
                resultFieldAggregations: {},
            },
        };

        if (measuredFieldStart.reference_name != measuredFieldEnd.reference_name) {
            dataQueries.table = {
                resultFields: [
                    newXField.reference_name,
                    measuredFieldStart.reference_name,
                    measuredFieldEnd.reference_name,
                ],
                resultFieldAggregations: {
                    [measuredFieldStart.reference_name]: measuredFieldStart.aggregation_function,
                    [measuredFieldEnd.reference_name]: measuredFieldEnd.aggregation_function,
                },
                sort: [{ field: newXField.reference_name, dir: 'ASC' }],
                group: {
                    operator: 'AND',
                    rules: [
                        {
                            field: newXField.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                        {
                            field: measuredFieldStart.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                        {
                            field: measuredFieldEnd.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                    ],
                },
            };
        } else {
            dataQueries.table = {
                resultFields: [newXField.reference_name, measuredFieldStart.reference_name],
                resultFieldAggregations: {
                    [measuredFieldStart.reference_name]: measuredFieldStart.aggregation_function,
                },
                sort: [{ field: newXField.reference_name, dir: 'ASC' }],
                group: {
                    operator: 'AND',
                    rules: [
                        {
                            field: newXField.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                        {
                            field: measuredFieldStart.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                    ],
                },
            };
            dataQueries['table-' + measuredFieldEnd.aggregation_function] = {
                resultFields: [newXField.reference_name, measuredFieldEnd.reference_name],
                resultFieldAggregations: {
                    [measuredFieldEnd.reference_name]: measuredFieldEnd.aggregation_function,
                },
                sort: [{ field: newXField.reference_name, dir: 'ASC' }],
                group: {
                    operator: 'AND',
                    rules: [
                        {
                            field: newXField.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                        {
                            field: measuredFieldEnd.reference_name,
                            condition: 'is not empty',
                            data: '',
                        },
                    ],
                },
            };
        }

        const resultSettings = {
            ...newSettings,
            range_start: { ...measuredFieldStart },
            range_end: { ...measuredFieldEnd },
            x_field: fieldToChartMeasuredField(newXField),
            dataQueries: dataQueries,
            drillTo: drillToList.slice(),
        } as ComponentSettingsRangeChart;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };
    // Drill To Params
    const xField = elementProps.form.hookFormWatch('x_field');
    const [drillToList, setDrillToList] = useState(component.settings.drillTo ?? []);
    const [selectedDrillTo, setSelectedDrillTo] = useState<DrillTo | null>(null);
    const drillToFields: Array<DrillToField> = useMemo(() => {
        const x = datasetFields.find((f) => f.reference_name == xField);
        return [
            {
                reference_name: x?.reference_name_escaped ?? '',
                column_name: x?.column_name ?? '',
            },
            {
                reference_name: 'snapshot_date',
                column_name: t('snapshot_date'),
            },
        ].filter((row) => row.reference_name != '');
    }, [xField]);

    const actualHeight = elementProps.form.hookFormWatch('height');
    return selectedDrillTo ? (
        <DrillToForm
            allowedFields={drillToFields}
            contentSettings={contentSettings}
            drillTo={selectedDrillTo}
            drillToList={drillToList}
            onApply={(drillTo: DrillTo) => {
                addOrReplaceInStateArray(setDrillToList, (f) => f.elementId == selectedDrillTo.elementId, drillTo);
                setSelectedDrillTo(null);
            }}
            onCancel={() => setSelectedDrillTo(null)}
        />
    ) : (
        <Stack height={1}>
            {/*Main Panel*/}
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('range_chart.edit_title')}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: { component: 'FormText', name: 'title', label: t('title') },
                        })}
                    />
                </Box>
                <Divider />
                <Box>
                    <FormLabel>{t('range_chart.one_per_bar')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields.map((field) => ({
                            label: field.column_name,
                            value: field.reference_name,
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'x_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <AggregationField
                    label={t('range_chart.range_start')}
                    allowedFields={datasetFields}
                    measuredField={measuredFieldStart}
                    setMeasuredField={setMeasuredFieldStart}
                    fieldToMeasuredField={fieldToChartMeasuredField}
                />
                <AggregationField
                    label={t('range_chart.range_end')}
                    allowedFields={datasetFields}
                    measuredField={measuredFieldEnd}
                    setMeasuredField={setMeasuredFieldEnd}
                    fieldToMeasuredField={fieldToChartMeasuredField}
                />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Box flexShrink={0}>{t('range_chart.value_axis_label')}</Box>
                    <Box flexGrow={1}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    component: 'FormText',
                                    name: 'value_axis_label',
                                    label: '',
                                },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />
                <DrillToList
                    onSelectRow={(drillTo) => setSelectedDrillTo(drillTo)}
                    onRemoveRow={(elementId: number) =>
                        removeFromStateArray(setDrillToList, (f) => f.elementId == elementId)
                    }
                    contentSettings={contentSettings}
                    drillTo={drillToList}
                />
                <Divider />
                <Box>
                    <EditPanelCheckBox elementProps={elementProps} name={'display_value'} label={t('display_value')} />
                </Box>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />

                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_in_viewer'}
                        label={t('show_in_viewer')}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email'}
                        label={t('include_in_email')}
                    />
                </Box>
                <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                <Box>
                    <GetEmbedCode contentSettings={contentSettings} height={actualHeight} blockUID={block.uid} t={t} />
                </Box>
                <Divider />
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={handleApply}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
        </Stack>
    );
}
