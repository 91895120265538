import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import FunnelChart from 'components/report-content/components/funnel-chart/FunnelChart';
import ComponentSettingsFunnelChart = ReportContentNS.ComponentSettingsFunnelChart;
import FunnelChartEditPanel from 'components/report-content/components/funnel-chart/FunnelChartEditPanel';

export default class FunnelChartComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsFunnelChart> {
    getComponent() {
        return (
            <FunnelChart
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <FunnelChartEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
