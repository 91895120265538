import React, { useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import { Link, useLocation } from 'react-router-dom';
import showdown from 'showdown';
import useBundleTranslation from 'i18n';
import parse, { domToReact } from 'html-react-parser';
import qs from 'query-string';

import isSnakeCase from 'tools/isSnakeCase';
import GridComponent from 'components/common/grid/GridComponent';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import IconHandler, { IconType } from 'components/common/icon/IconHandler';

const converter = new showdown.Converter();

interface IProps {
    type?: AlertColor;
    icon?: IconType | boolean;
    align?: string;
    label: string;
    show?: boolean;
    translationNS?: string;
    link?: {
        label?: string;
        href?: string;
    };
    variant?: any;
    translationParams?: {
        [key: string]: string | number;
    };
    gridUrl?: string;
    popupConfig?: PopupSettings;
    planeText?: boolean;
    useMarkdown?: boolean;
}

type Params = {
    [key: string]: any;
};

const GRID_URL_PREFIX = 'gridURL';

const StaticInfo: React.FC<IProps> = ({
    icon,
    type = 'info',
    label,
    align,
    translationNS,
    link,
    show = true,
    variant = 'standard',
    translationParams = {},
    gridUrl,
    popupConfig = {},
    planeText = false,
    useMarkdown = true,
}) => {
    const trimLabel = label?.trim();
    const location = useLocation();
    const shouldTranslate = isSnakeCase(trimLabel);
    const [activeGridUrl, setActiveGridUrl] = useState('');
    const [isGridPopupOpen, toggleGridPopupOpen] = useState(false);
    const [urlParams, setUrlParams] = useState<Params | null>(null);
    const { t } = useBundleTranslation(translationNS ?? 'components/common/form/form');
    const text = shouldTranslate ? t(trimLabel, { ...translationParams }) : trimLabel;
    const html = useMarkdown ? converter.makeHtml(text) : text;
    const currentLink = location.pathname + location.hash;
    let iconElement = icon === false ? false : icon ? <IconHandler icon={icon} /> : undefined;
    if (variant === 'blank' && typeof icon == 'undefined') {
        //remove default icon for blank variant
        iconElement = false;
    }

    const openGridPopup = (parseUrl: string) => {
        const { url, query } = qs.parseUrl(parseUrl);
        setUrlParams(query);
        setActiveGridUrl(url);
        toggleGridPopupOpen(true);
    };

    const closeGridPopup = () => {
        setActiveGridUrl('');
        toggleGridPopupOpen(false);
    };

    const options: any = {
        replace: ({ attribs, children }: any) => {
            if (!attribs) {
                return;
            }

            if (attribs?.href?.includes(GRID_URL_PREFIX) && gridUrl) {
                return (
                    <Link to={currentLink} onClick={() => openGridPopup(gridUrl)}>
                        {domToReact(children, options)}
                    </Link>
                );
            }
        },
    };
    const reactElement = parse(html, options);

    const popupSettings: PopupSettings = {
        noCancel: true,
        title: urlParams?.title || '',
        textOK: urlParams?.textOK || t('close'),
        ...popupConfig,
    };

    const action = link?.href && link?.label ? <Link to={link.href}>{link.label}</Link> : null;

    return show ? (
        <>
            <Alert
                action={action}
                severity={type}
                icon={iconElement}
                variant={variant}
                sx={{ justifyContent: align == 'center' ? 'center' : undefined }}
            >
                {planeText ? trimLabel : reactElement}
            </Alert>
            {isGridPopupOpen && activeGridUrl && (
                <Popup
                    open
                    key="static-info-list"
                    translationNS={translationNS}
                    settings={popupSettings}
                    onHide={closeGridPopup}
                >
                    <GridComponent t={t} uid={activeGridUrl} settingsUrl={activeGridUrl} />
                </Popup>
            )}
        </>
    ) : null;
};

export default StaticInfo;
