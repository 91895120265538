import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import { Highcharts } from 'components/chart/Chart';
import { Chart as HChart } from 'highcharts';
import PieChartTooltip from 'components/report-content/components/pie-chart/PieChartTooltip';
import Chart from 'components/chart/Chart';
import prepareDataForSeries from 'components/report-content/components/pie-chart/helpers/prepareDataForSeries';
import usePalette from 'components/report-content/hooks/usePalette';
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { applyChartOptionsForThumbnail } from 'components/report-content/utils/tools';
import TooltipContainer from 'components/chart/TooltipContainer';
import { useDatasetReportTooltip } from 'components/report-content/tooltip/useDatasetReportTooltip';

export default function PieChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsPieChart>) {
    const { initialData, data } = useRCChartData(component, contentSettings, updateBlockSettings, true);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const getFormat = () => {
        const result = ['{point.key.name_f}'];
        if (component.settings.display_value == 'Y') {
            result.push('<br/>{point.key.y_f}');
        }
        if (component.settings.display_value_total == 'Y') {
            if (component.settings.display_value == 'Y') {
                result.push(' ({point.percentage:.1f}%)');
            } else {
                result.push('<br/>{point.percentage:.1f}%');
            }
        }
        return result.join('');
    };

    const options = {
        chart: {
            animation: false,
            // plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: 600,
            style: {
                fontFamily: 'Arial, helvetica, sans-serif',
            },
        },
        legend: { enabled: false },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        exporting: { enabled: false },
        title: { text: '' },
        tooltip: { enabled: false },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                dataLabels: {
                    enabled: !isThumbnail,
                    //distance: distance,
                    format: getFormat(),
                    useHTML: true,
                    style: {
                        // color: colorsFT.mainText,
                        fontSize: '14px',
                        fontWeight: 'normal',
                    },
                },
                startAngle: 0,
                endAngle: 360,
            },
            series: {
                animation: !contentSettings.forScreenShot,
                turboThreshold: 0,
                point: {
                    events: {
                        click: function () {
                            // self.drawDrillToTooltip(this);
                        },
                    },
                },
            },
        },
        series: [
            {
                type: 'pie',
                name: '',
                innerSize: '50%',
                data: [] as Array<any>,
            },
        ],
    };

    // contentSettings.context

    const { getPaletteColor, localPalette, setLocalPalette } = usePalette(
        contentSettings.context,
        contentSettings.elementId,
        Highcharts.getOptions().colors as Array<string>,
        component.settings.localPalette,
    );

    const { pointToShow, getTooltipPointEvents, closePointComponent, isPointSelect, setIsHoveredOnTooltip } =
        useDatasetReportTooltip();

    const [chartOptions, setChartOptions] = useState(options);
    useEffect(() => {
        if (isThumbnail) {
            //@ts-ignore
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }

        // if('undefined'!==typeof(self.data) && 'undefined'!==typeof(self.data.dataResults) && 'undefined'!==typeof(self.data.dataResults.table) && 'undefined'!==typeof(self.data.dataResults.table.length) && 0===self.data.dataResults.table.length) {
        //     if(!isPreview) self.triggerAfterDataLoaded(false);
        //     self.el.find('.block-wrapper-chart-container:first').html('<img style="width:'+(self.settings.width>0?self.settings.width+'px':'100%')+';height:'+(self.settings.height>0?self.settings.height+'px':'100%')+';" src="'+globalConstants.homeSite+'img/default_preview_no_data.png'+'"/>');
        // } else {
        //     if(!isPreview) self.triggerAfterDataLoaded(true);

        // if(self.settings.colorFormationRulesData)
        //     self.settings.colorFormationRulesData.forEach(function(rule){
        //         self.settings.localPalette[_.escape(rule.field_value)] = rule.color;
        //     });

        options.series[0].data = prepareDataForSeries(component.settings, data, getPaletteColor);
        //@ts-ignore
        options.series[0].point = getTooltipPointEvents();
        const index = options.series[0].data.findIndex(
            (row: any) => row?.name == component.settings.system_other_grouping_label,
        );

        if (index != -1) {
            // @ts-ignore
            options.series[0].data[index].key.name_f = component.settings.merge_small_label;
            // @ts-ignore
            options.series[0].data[index].color =
                // @ts-ignore
                component.merge_small_color == '' ? null : component.settings.merge_small_color;
        }

        // if(isPreview) setTimeout(function(){ alert('generated'); },200);

        setChartOptions(options);
    }, [data, component.settings, localPalette]);

    const [chart, setChart] = useState<HChart>();
    useComponentReady(chart);

    return initialData ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px', position: 'relative' }}>
            {/*@ts-ignore*/}
            <Chart chartOptions={chartOptions} afterChartCreated={(chart: HChart) => setChart(chart)} />
            {chart && pointToShow && (
                <TooltipContainer
                    onMouseEnter={() => setIsHoveredOnTooltip()}
                    closeComponent={closePointComponent}
                    point={pointToShow}
                    chart={chart}
                >
                    {(closeComponent) => (
                        <PieChartTooltip
                            point={pointToShow}
                            settings={component.settings}
                            contentSettings={contentSettings}
                            closeComponent={isPointSelect ? closeComponent : null}
                        />
                    )}
                </TooltipContainer>
            )}
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
