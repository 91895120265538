import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import RangeChart from 'components/report-content/components/range-chart/RangeChart';
import RangeChartEditPanel from 'components/report-content/components/range-chart/RangeChartEditPanel';
import ComponentSettingsRangeChart = ReportContentNS.ComponentSettingsRangeChart;

export default class RangeChartComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsRangeChart> {
    getComponent() {
        return (
            <RangeChart
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <RangeChartEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
