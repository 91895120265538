import React from 'react';
import IconMi from 'components/common/icon/IconMi';

export const ReactSelectDropdownIndicator = (props: any) => {
    return (
        <IconMi
            icon="chevron-down"
            className={'custom-select__dropdown-icon'}
            fontSize={'16'}
            sx={{ color: 'primary.main', mx: 0.75 }}
        />
    );
};
