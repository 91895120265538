export const MapsList = [
    {
        name: 'Africa',
        parent: 'World',
        filename: 'custom/africa.js',
    },
    {
        name: 'Asia',
        parent: 'World',
        filename: 'custom/asia.js',
    },
    {
        name: 'Canada and United States of America',
        parent: 'World',
        filename: 'custom/usa-and-canada.js',
    },
    {
        name: 'Central America',
        parent: 'World',
        filename: 'custom/central-america.js',
    },
    {
        name: 'Europe',
        parent: 'World',
        filename: 'custom/europe.js',
    },
    {
        name: 'European Union',
        parent: 'World',
        filename: 'custom/european-union.js',
    },
    {
        name: 'Middle East',
        parent: 'World',
        filename: 'custom/middle-east.js',
    },
    {
        name: 'North America without central',
        parent: 'World',
        filename: 'custom/north-america-no-central.js',
    },
    {
        name: 'North America',
        parent: 'World',
        filename: 'custom/north-america.js',
    },
    {
        name: 'Oceania',
        parent: 'World',
        filename: 'custom/oceania.js',
    },
    {
        name: 'Scandinavia',
        parent: 'World',
        filename: 'custom/scandinavia.js',
    },
    {
        name: 'South America',
        parent: 'World',
        filename: 'custom/south-america.js',
    },
    {
        name: 'World',
        parent: 'World',
        filename: 'custom/world.js',
    },
    {
        name: 'Afghanistan',
        parent: 'Countries',
        filename: 'countries/af/af-all.js',
    },
    {
        name: 'Albania',
        parent: 'Countries',
        filename: 'countries/al/al-all.js',
    },
    {
        name: 'Algeria',
        parent: 'Countries',
        filename: 'countries/dz/dz-all.js',
    },
    {
        name: 'American Samoa',
        parent: 'Countries',
        filename: 'countries/as/as-all.js',
    },
    {
        name: 'Andorra',
        parent: 'Countries',
        filename: 'countries/ad/ad-all.js',
    },
    {
        name: 'Angola',
        parent: 'Countries',
        filename: 'countries/ao/ao-all.js',
    },
    {
        name: 'Antigua and Barbuda',
        parent: 'Countries',
        filename: 'countries/ag/ag-all.js',
    },
    {
        name: 'Argentina',
        parent: 'Countries',
        filename: 'countries/ar/ar-all.js',
    },
    {
        name: 'Armenia',
        parent: 'Countries',
        filename: 'countries/am/am-all.js',
    },
    {
        name: 'Australia',
        parent: 'Countries',
        filename: 'countries/au/au-all.js',
    },
    {
        name: 'Austria',
        parent: 'Countries',
        filename: 'countries/at/at-all.js',
    },
    {
        name: 'Azerbaijan',
        parent: 'Countries',
        filename: 'countries/az/az-all.js',
    },
    {
        name: 'Bahrain',
        parent: 'Countries',
        filename: 'countries/bh/bh-all.js',
    },
    {
        name: 'Bangladesh',
        parent: 'Countries',
        filename: 'countries/bd/bd-all.js',
    },
    {
        name: 'Barbados',
        parent: 'Countries',
        filename: 'countries/bb/bb-all.js',
    },
    {
        name: 'Belarus',
        parent: 'Countries',
        filename: 'countries/by/by-all.js',
    },
    {
        name: 'Belgium',
        parent: 'Countries',
        filename: 'countries/be/be-all.js',
    },
    {
        name: 'Belize',
        parent: 'Countries',
        filename: 'countries/bz/bz-all.js',
    },
    {
        name: 'Benin',
        parent: 'Countries',
        filename: 'countries/bj/bj-all.js',
    },
    {
        name: 'Bhutan',
        parent: 'Countries',
        filename: 'countries/bt/bt-all.js',
    },
    {
        name: 'Bolivia',
        parent: 'Countries',
        filename: 'countries/bo/bo-all.js',
    },
    {
        name: 'Bosnia and Herzegovina',
        parent: 'Countries',
        filename: 'countries/ba/ba-all.js',
    },
    {
        name: 'Botswana',
        parent: 'Countries',
        filename: 'countries/bw/bw-all.js',
    },
    {
        name: 'Brazil',
        parent: 'Countries',
        filename: 'countries/br/br-all.js',
    },
    {
        name: 'Brunei',
        parent: 'Countries',
        filename: 'countries/bn/bn-all.js',
    },
    {
        name: 'Bulgaria',
        parent: 'Countries',
        filename: 'countries/bg/bg-all.js',
    },
    {
        name: 'Burkina Faso',
        parent: 'Countries',
        filename: 'countries/bf/bf-all.js',
    },
    {
        name: 'Burundi',
        parent: 'Countries',
        filename: 'countries/bi/bi-all.js',
    },
    {
        name: 'Cambodia',
        parent: 'Countries',
        filename: 'countries/kh/kh-all.js',
    },
    {
        name: 'Cameroon',
        parent: 'Countries',
        filename: 'countries/cm/cm-all.js',
    },
    {
        name: 'Canada',
        parent: 'Countries',
        filename: 'countries/ca/ca-all.js',
    },
    {
        name: 'Canada',
        parent: 'Countries',
        filename: 'countries/ca/ca-all-all.js',
    },
    {
        name: 'Cape Verde',
        parent: 'Countries',
        filename: 'countries/cv/cv-all.js',
    },
    {
        name: 'Central African Republic',
        parent: 'Countries',
        filename: 'countries/cf/cf-all.js',
    },
    {
        name: 'Chad',
        parent: 'Countries',
        filename: 'countries/td/td-all.js',
    },
    {
        name: 'Chile',
        parent: 'Countries',
        filename: 'countries/cl/cl-all.js',
    },
    {
        name: 'China with Hong Kong and Macau',
        parent: 'Countries',
        filename: 'countries/cn/custom/cn-all-sar.js',
    },
    {
        name: 'China',
        parent: 'Countries',
        filename: 'countries/cn/cn-all.js',
    },
    {
        name: 'Colombia',
        parent: 'Countries',
        filename: 'countries/co/co-all.js',
    },
    {
        name: 'Comoros',
        parent: 'Countries',
        filename: 'countries/km/km-all.js',
    },
    {
        name: 'Cook Islands',
        parent: 'Countries',
        filename: 'countries/ck/ck-all.js',
    },
    {
        name: 'Costa Rica',
        parent: 'Countries',
        filename: 'countries/cr/cr-all.js',
    },
    {
        name: 'Croatia',
        parent: 'Countries',
        filename: 'countries/hr/hr-all.js',
    },
    {
        name: 'Cuba',
        parent: 'Countries',
        filename: 'countries/cu/cu-all.js',
    },
    {
        name: 'Cyprus',
        parent: 'Countries',
        filename: 'countries/cy/cy-all.js',
    },
    {
        name: 'Czech Republic',
        parent: 'Countries',
        filename: 'countries/cz/cz-all.js',
    },
    {
        name: 'Democratic Republic of the Congo',
        parent: 'Countries',
        filename: 'countries/cd/cd-all.js',
    },
    {
        name: 'Denmark',
        parent: 'Countries',
        filename: 'countries/dk/dk-all.js',
    },
    {
        name: 'Djibouti',
        parent: 'Countries',
        filename: 'countries/dj/dj-all.js',
    },
    {
        name: 'Dominica',
        parent: 'Countries',
        filename: 'countries/dm/dm-all.js',
    },
    {
        name: 'Dominican Republic',
        parent: 'Countries',
        filename: 'countries/do/do-all.js',
    },
    {
        name: 'East Timor',
        parent: 'Countries',
        filename: 'countries/tl/tl-all.js',
    },
    {
        name: 'Ecuador',
        parent: 'Countries',
        filename: 'countries/ec/ec-all.js',
    },
    {
        name: 'Egypt',
        parent: 'Countries',
        filename: 'countries/eg/eg-all.js',
    },
    {
        name: 'El Salvador',
        parent: 'Countries',
        filename: 'countries/sv/sv-all.js',
    },
    {
        name: 'Equatorial Guinea',
        parent: 'Countries',
        filename: 'countries/gq/gq-all.js',
    },
    {
        name: 'Eritrea',
        parent: 'Countries',
        filename: 'countries/er/er-all.js',
    },
    {
        name: 'Estonia',
        parent: 'Countries',
        filename: 'countries/ee/ee-all.js',
    },
    {
        name: 'Ethiopia',
        parent: 'Countries',
        filename: 'countries/et/et-all.js',
    },
    {
        name: 'Faroe Islands',
        parent: 'Countries',
        filename: 'countries/fo/fo-all.js',
    },
    {
        name: 'Fiji',
        parent: 'Countries',
        filename: 'countries/fj/fj-all.js',
    },
    {
        name: 'Finland',
        parent: 'Countries',
        filename: 'countries/fi/fi-all.js',
    },
    {
        name: 'France',
        parent: 'Countries',
        filename: 'countries/fr/fr-all.js',
    },
    {
        name: 'France',
        parent: 'Countries',
        filename: 'countries/fr/fr-all-all.js',
    },
    {
        name: 'France, mainland admin2',
        parent: 'Countries',
        filename: 'countries/fr/custom/fr-all-all-mainland.js',
    },
    {
        name: 'France, mainland',
        parent: 'Countries',
        filename: 'countries/fr/custom/fr-all-mainland.js',
    },
    {
        name: 'French Southern and Antarctic Lands',
        parent: 'Countries',
        filename: 'countries/tf/tf-all.js',
    },
    {
        name: 'Gabon',
        parent: 'Countries',
        filename: 'countries/ga/ga-all.js',
    },
    {
        name: 'Gambia',
        parent: 'Countries',
        filename: 'countries/gm/gm-all.js',
    },
    {
        name: 'Georgia',
        parent: 'Countries',
        filename: 'countries/ge/ge-all.js',
    },
    {
        name: 'Germany',
        parent: 'Countries',
        filename: 'countries/de/de-all.js',
    },
    {
        name: 'Germany',
        parent: 'Countries',
        filename: 'countries/de/de-all-all.js',
    },
    {
        name: 'Ghana',
        parent: 'Countries',
        filename: 'countries/gh/gh-all.js',
    },
    {
        name: 'Greece',
        parent: 'Countries',
        filename: 'countries/gr/gr-all.js',
    },
    {
        name: 'Greenland',
        parent: 'Countries',
        filename: 'countries/gl/gl-all.js',
    },
    {
        name: 'Grenada',
        parent: 'Countries',
        filename: 'countries/gd/gd-all.js',
    },
    {
        name: 'Guam',
        parent: 'Countries',
        filename: 'countries/gu/gu-all.js',
    },
    {
        name: 'Guatemala',
        parent: 'Countries',
        filename: 'countries/gt/gt-all.js',
    },
    {
        name: 'Guinea Bissau',
        parent: 'Countries',
        filename: 'countries/gw/gw-all.js',
    },
    {
        name: 'Guinea',
        parent: 'Countries',
        filename: 'countries/gn/gn-all.js',
    },
    {
        name: 'Guyana',
        parent: 'Countries',
        filename: 'countries/gy/gy-all.js',
    },
    {
        name: 'Haiti',
        parent: 'Countries',
        filename: 'countries/ht/ht-all.js',
    },
    {
        name: 'Honduras',
        parent: 'Countries',
        filename: 'countries/hn/hn-all.js',
    },
    {
        name: 'Hungary',
        parent: 'Countries',
        filename: 'countries/hu/hu-all.js',
    },
    {
        name: 'Iceland',
        parent: 'Countries',
        filename: 'countries/is/is-all.js',
    },
    {
        name: 'India with Andaman and Nicobar',
        parent: 'Countries',
        filename: 'countries/in/custom/in-all-andaman-and-nicobar.js',
    },
    {
        name: 'India with disputed territories',
        parent: 'Countries',
        filename: 'countries/in/custom/in-all-disputed.js',
    },
    {
        name: 'India',
        parent: 'Countries',
        filename: 'countries/in/in-all.js',
    },
    {
        name: 'Indonesia',
        parent: 'Countries',
        filename: 'countries/id/id-all.js',
    },
    {
        name: 'Iran',
        parent: 'Countries',
        filename: 'countries/ir/ir-all.js',
    },
    {
        name: 'Iraq',
        parent: 'Countries',
        filename: 'countries/iq/iq-all.js',
    },
    {
        name: 'Ireland',
        parent: 'Countries',
        filename: 'countries/ie/ie-all.js',
    },
    {
        name: 'Israel',
        parent: 'Countries',
        filename: 'countries/il/il-all.js',
    },
    {
        name: 'Italy',
        parent: 'Countries',
        filename: 'countries/it/it-all.js',
    },
    {
        name: 'Ivory Coast',
        parent: 'Countries',
        filename: 'countries/ci/ci-all.js',
    },
    {
        name: 'Jamaica',
        parent: 'Countries',
        filename: 'countries/jm/jm-all.js',
    },
    {
        name: 'Japan',
        parent: 'Countries',
        filename: 'countries/jp/jp-all.js',
    },
    {
        name: 'Jordan',
        parent: 'Countries',
        filename: 'countries/jo/jo-all.js',
    },
    {
        name: 'Kazakhstan',
        parent: 'Countries',
        filename: 'countries/kz/kz-all.js',
    },
    {
        name: 'Kenya',
        parent: 'Countries',
        filename: 'countries/ke/ke-all.js',
    },
    {
        name: 'Kosovo',
        parent: 'Countries',
        filename: 'countries/kv/kv-all.js',
    },
    {
        name: 'Kuwait',
        parent: 'Countries',
        filename: 'countries/kw/kw-all.js',
    },
    {
        name: 'Kyrgyzstan',
        parent: 'Countries',
        filename: 'countries/kg/kg-all.js',
    },
    {
        name: 'Laos',
        parent: 'Countries',
        filename: 'countries/la/la-all.js',
    },
    {
        name: 'Latvia',
        parent: 'Countries',
        filename: 'countries/lv/lv-all.js',
    },
    {
        name: 'Lebanon',
        parent: 'Countries',
        filename: 'countries/lb/lb-all.js',
    },
    {
        name: 'Lesotho',
        parent: 'Countries',
        filename: 'countries/ls/ls-all.js',
    },
    {
        name: 'Liberia',
        parent: 'Countries',
        filename: 'countries/lr/lr-all.js',
    },
    {
        name: 'Libya',
        parent: 'Countries',
        filename: 'countries/ly/ly-all.js',
    },
    {
        name: 'Liechtenstein',
        parent: 'Countries',
        filename: 'countries/li/li-all.js',
    },
    {
        name: 'Lithuania',
        parent: 'Countries',
        filename: 'countries/lt/lt-all.js',
    },
    {
        name: 'Luxembourg',
        parent: 'Countries',
        filename: 'countries/lu/lu-all.js',
    },
    {
        name: 'Macedonia',
        parent: 'Countries',
        filename: 'countries/mk/mk-all.js',
    },
    {
        name: 'Madagascar',
        parent: 'Countries',
        filename: 'countries/mg/mg-all.js',
    },
    {
        name: 'Malawi',
        parent: 'Countries',
        filename: 'countries/mw/mw-all.js',
    },
    {
        name: 'Malaysia',
        parent: 'Countries',
        filename: 'countries/my/my-all.js',
    },
    {
        name: 'Mali',
        parent: 'Countries',
        filename: 'countries/ml/ml-all.js',
    },
    {
        name: 'Malta',
        parent: 'Countries',
        filename: 'countries/mt/mt-all.js',
    },
    {
        name: 'Mauritania',
        parent: 'Countries',
        filename: 'countries/mr/mr-all.js',
    },
    {
        name: 'Mauritius',
        parent: 'Countries',
        filename: 'countries/mu/mu-all.js',
    },
    {
        name: 'Mexico',
        parent: 'Countries',
        filename: 'countries/mx/mx-all.js',
    },
    {
        name: 'Moldova',
        parent: 'Countries',
        filename: 'countries/md/md-all.js',
    },
    {
        name: 'Monaco',
        parent: 'Countries',
        filename: 'countries/mc/mc-all.js',
    },
    {
        name: 'Mongolia',
        parent: 'Countries',
        filename: 'countries/mn/mn-all.js',
    },
    {
        name: 'Montenegro',
        parent: 'Countries',
        filename: 'countries/me/me-all.js',
    },
    {
        name: 'Morocco',
        parent: 'Countries',
        filename: 'countries/ma/ma-all.js',
    },
    {
        name: 'Mozambique',
        parent: 'Countries',
        filename: 'countries/mz/mz-all.js',
    },
    {
        name: 'Myanmar',
        parent: 'Countries',
        filename: 'countries/mm/mm-all.js',
    },
    {
        name: 'Namibia',
        parent: 'Countries',
        filename: 'countries/na/na-all.js',
    },
    {
        name: 'Nauru',
        parent: 'Countries',
        filename: 'countries/nr/nr-all.js',
    },
    {
        name: 'Nepal',
        parent: 'Countries',
        filename: 'countries/np/np-all.js',
    },
    {
        name: 'New Caledonia',
        parent: 'Countries',
        filename: 'countries/nc/nc-all.js',
    },
    {
        name: 'New Zealand',
        parent: 'Countries',
        filename: 'countries/nz/nz-all.js',
    },
    {
        name: 'Nicaragua',
        parent: 'Countries',
        filename: 'countries/ni/ni-all.js',
    },
    {
        name: 'Niger',
        parent: 'Countries',
        filename: 'countries/ne/ne-all.js',
    },
    {
        name: 'Nigeria',
        parent: 'Countries',
        filename: 'countries/ng/ng-all.js',
    },
    {
        name: 'North Korea',
        parent: 'Countries',
        filename: 'countries/kp/kp-all.js',
    },
    {
        name: 'Northern Mariana Islands',
        parent: 'Countries',
        filename: 'countries/mp/mp-all.js',
    },
    {
        name: 'Norway with Svalbard and Jan Mayen',
        parent: 'Countries',
        filename: 'countries/no/custom/no-all-svalbard-and-jan-mayen.js',
    },
    {
        name: 'Norway',
        parent: 'Countries',
        filename: 'countries/no/no-all.js',
    },
    {
        name: 'Norway',
        parent: 'Countries',
        filename: 'countries/no/no-all-all.js',
    },
    {
        name: 'Oman',
        parent: 'Countries',
        filename: 'countries/om/om-all.js',
    },
    {
        name: 'Pakistan',
        parent: 'Countries',
        filename: 'countries/pk/pk-all.js',
    },
    {
        name: 'Palau',
        parent: 'Countries',
        filename: 'countries/pw/pw-all.js',
    },
    {
        name: 'Panama',
        parent: 'Countries',
        filename: 'countries/pa/pa-all.js',
    },
    {
        name: 'Papua New Guinea',
        parent: 'Countries',
        filename: 'countries/pg/pg-all.js',
    },
    {
        name: 'Paraguay',
        parent: 'Countries',
        filename: 'countries/py/py-all.js',
    },
    {
        name: 'Peru',
        parent: 'Countries',
        filename: 'countries/pe/pe-all.js',
    },
    {
        name: 'Philippines',
        parent: 'Countries',
        filename: 'countries/ph/ph-all.js',
    },
    {
        name: 'Poland',
        parent: 'Countries',
        filename: 'countries/pl/pl-all.js',
    },
    {
        name: 'Portugal',
        parent: 'Countries',
        filename: 'countries/pt/pt-all.js',
    },
    {
        name: 'Puerto Rico',
        parent: 'Countries',
        filename: 'countries/pr/pr-all.js',
    },
    {
        name: 'Qatar',
        parent: 'Countries',
        filename: 'countries/qa/qa-all.js',
    },
    {
        name: 'Republic of Serbia',
        parent: 'Countries',
        filename: 'countries/rs/rs-all.js',
    },
    {
        name: 'Republic of the Congo',
        parent: 'Countries',
        filename: 'countries/cg/cg-all.js',
    },
    {
        name: 'Romania',
        parent: 'Countries',
        filename: 'countries/ro/ro-all.js',
    },
    {
        name: 'Russia',
        parent: 'Countries',
        filename: 'countries/ru/ru-all.js',
    },
    {
        name: 'Rwanda',
        parent: 'Countries',
        filename: 'countries/rw/rw-all.js',
    },
    {
        name: 'Saint Kitts and Nevis',
        parent: 'Countries',
        filename: 'countries/kn/kn-all.js',
    },
    {
        name: 'Saint Lucia',
        parent: 'Countries',
        filename: 'countries/lc/lc-all.js',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        parent: 'Countries',
        filename: 'countries/vc/vc-all.js',
    },
    {
        name: 'Samoa',
        parent: 'Countries',
        filename: 'countries/ws/ws-all.js',
    },
    {
        name: 'San Marino',
        parent: 'Countries',
        filename: 'countries/sm/sm-all.js',
    },
    {
        name: 'Sao Tome and Principe',
        parent: 'Countries',
        filename: 'countries/st/st-all.js',
    },
    {
        name: 'Saudi Arabia',
        parent: 'Countries',
        filename: 'countries/sa/sa-all.js',
    },
    {
        name: 'Senegal',
        parent: 'Countries',
        filename: 'countries/sn/sn-all.js',
    },
    {
        name: 'Seychelles',
        parent: 'Countries',
        filename: 'countries/sc/sc-all.js',
    },
    {
        name: 'Sierra Leone',
        parent: 'Countries',
        filename: 'countries/sl/sl-all.js',
    },
    {
        name: 'Singapore',
        parent: 'Countries',
        filename: 'countries/sg/sg-all.js',
    },
    {
        name: 'Slovakia',
        parent: 'Countries',
        filename: 'countries/sk/sk-all.js',
    },
    {
        name: 'Slovenia',
        parent: 'Countries',
        filename: 'countries/si/si-all.js',
    },
    {
        name: 'Solomon Islands',
        parent: 'Countries',
        filename: 'countries/sb/sb-all.js',
    },
    {
        name: 'Somalia',
        parent: 'Countries',
        filename: 'countries/so/so-all.js',
    },
    {
        name: 'Somaliland',
        parent: 'Countries',
        filename: 'countries/sx/sx-all.js',
    },
    {
        name: 'South Africa',
        parent: 'Countries',
        filename: 'countries/za/za-all.js',
    },
    {
        name: 'South Korea',
        parent: 'Countries',
        filename: 'countries/kr/kr-all.js',
    },
    {
        name: 'South Sudan',
        parent: 'Countries',
        filename: 'countries/ss/ss-all.js',
    },
    {
        name: 'Spain',
        parent: 'Countries',
        filename: 'countries/es/es-all.js',
    },
    {
        name: 'Sri Lanka',
        parent: 'Countries',
        filename: 'countries/lk/lk-all.js',
    },
    {
        name: 'Sudan',
        parent: 'Countries',
        filename: 'countries/sd/sd-all.js',
    },
    {
        name: 'Suriname',
        parent: 'Countries',
        filename: 'countries/sr/sr-all.js',
    },
    {
        name: 'Swaziland',
        parent: 'Countries',
        filename: 'countries/sz/sz-all.js',
    },
    {
        name: 'Sweden',
        parent: 'Countries',
        filename: 'countries/se/se-all.js',
    },
    {
        name: 'Switzerland',
        parent: 'Countries',
        filename: 'countries/ch/ch-all.js',
    },
    {
        name: 'Syria',
        parent: 'Countries',
        filename: 'countries/sy/sy-all.js',
    },
    {
        name: 'Taiwan',
        parent: 'Countries',
        filename: 'countries/tw/tw-all.js',
    },
    {
        name: 'Tajikistan',
        parent: 'Countries',
        filename: 'countries/tj/tj-all.js',
    },
    {
        name: 'Thailand',
        parent: 'Countries',
        filename: 'countries/th/th-all.js',
    },
    {
        name: 'The Bahamas',
        parent: 'Countries',
        filename: 'countries/bs/bs-all.js',
    },
    {
        name: 'The Netherlands',
        parent: 'Countries',
        filename: 'countries/nl/nl-all.js',
    },
    {
        name: 'The Netherlands',
        parent: 'Countries',
        filename: 'countries/nl/nl-all-all.js',
    },
    {
        name: 'Togo',
        parent: 'Countries',
        filename: 'countries/tg/tg-all.js',
    },
    {
        name: 'Trinidad and Tobago',
        parent: 'Countries',
        filename: 'countries/tt/tt-all.js',
    },
    {
        name: 'Tunisia',
        parent: 'Countries',
        filename: 'countries/tn/tn-all.js',
    },
    {
        name: 'Turkey',
        parent: 'Countries',
        filename: 'countries/tr/tr-all.js',
    },
    {
        name: 'Turkmenistan',
        parent: 'Countries',
        filename: 'countries/tm/tm-all.js',
    },
    {
        name: 'Uganda',
        parent: 'Countries',
        filename: 'countries/ug/ug-all.js',
    },
    {
        name: 'Ukraine',
        parent: 'Countries',
        filename: 'countries/ua/ua-all.js',
    },
    {
        name: 'United Arab Emirates',
        parent: 'Countries',
        filename: 'countries/ae/ae-all.js',
    },
    {
        name: 'United Kingdom countries',
        parent: 'Countries',
        filename: 'countries/gb/custom/gb-countries.js',
    },
    {
        name: 'United Kingdom',
        parent: 'Countries',
        filename: 'countries/gb/gb-all.js',
    },
    {
        name: 'United Republic of Tanzania',
        parent: 'Countries',
        filename: 'countries/tz/tz-all.js',
    },
    {
        name: 'United States Virgin Islands',
        parent: 'Countries',
        filename: 'countries/vi/vi-all.js',
    },
    {
        name: 'United States of America with Territories',
        parent: 'Countries',
        filename: 'countries/us/custom/us-all-territories.js',
    },
    {
        name: 'United States of America',
        parent: 'Countries',
        filename: 'countries/us/us-all.js',
    },
    {
        name: 'United States of America',
        parent: 'Countries',
        filename: 'countries/us/us-all-all.js',
    },
    {
        name: 'United States of America, highres',
        parent: 'Countries',
        filename: 'countries/us/us-all-all-highres.js',
    },
    {
        name: 'United States of America, congressional districts (113th)',
        parent: 'Countries',
        filename: 'countries/us/custom/us-113-congress.js',
    },
    {
        name: 'United States of America, mainland',
        parent: 'Countries',
        filename: 'countries/us/custom/us-all-mainland.js',
    },
    {
        name: 'United States of America, small',
        parent: 'Countries',
        filename: 'countries/us/custom/us-small.js',
    },
    {
        name: 'Uruguay',
        parent: 'Countries',
        filename: 'countries/uy/uy-all.js',
    },
    {
        name: 'Uzbekistan',
        parent: 'Countries',
        filename: 'countries/uz/uz-all.js',
    },
    {
        name: 'Vanuatu',
        parent: 'Countries',
        filename: 'countries/vu/vu-all.js',
    },
    {
        name: 'Venezuela',
        parent: 'Countries',
        filename: 'countries/ve/ve-all.js',
    },
    {
        name: 'Vietnam',
        parent: 'Countries',
        filename: 'countries/vn/vn-all.js',
    },
    {
        name: 'Wallis and Futuna',
        parent: 'Countries',
        filename: 'countries/wf/wf-all.js',
    },
    {
        name: 'Western Sahara',
        parent: 'Countries',
        filename: 'countries/eh/eh-all.js',
    },
    {
        name: 'Yemen',
        parent: 'Countries',
        filename: 'countries/ye/ye-all.js',
    },
    {
        name: 'Zambia',
        parent: 'Countries',
        filename: 'countries/zm/zm-all.js',
    },
    {
        name: 'Zimbabwe',
        parent: 'Countries',
        filename: 'countries/zw/zw-all.js',
    },
    {
        name: 'Alabama',
        parent: 'USA',
        filename: 'countries/us/us-al-all.js',
    },
    {
        name: 'Alaska',
        parent: 'USA',
        filename: 'countries/us/us-ak-all.js',
    },
    {
        name: 'Arizona',
        parent: 'USA',
        filename: 'countries/us/us-az-all.js',
    },
    {
        name: 'Arkansas',
        parent: 'USA',
        filename: 'countries/us/us-ar-all.js',
    },
    {
        name: 'California',
        parent: 'USA',
        filename: 'countries/us/us-ca-all.js',
    },
    {
        name: 'Colorado',
        parent: 'USA',
        filename: 'countries/us/us-co-all.js',
    },
    {
        name: 'Connecticut',
        parent: 'USA',
        filename: 'countries/us/us-ct-all.js',
    },
    {
        name: 'Delaware',
        parent: 'USA',
        filename: 'countries/us/us-de-all.js',
    },
    {
        name: 'District of Columbia',
        parent: 'USA',
        filename: 'countries/us/us-dc-all.js',
    },
    {
        name: 'Florida',
        parent: 'USA',
        filename: 'countries/us/us-fl-all.js',
    },
    {
        name: 'Georgia',
        parent: 'USA',
        filename: 'countries/us/us-ga-all.js',
    },
    {
        name: 'Hawaii',
        parent: 'USA',
        filename: 'countries/us/us-hi-all.js',
    },
    {
        name: 'Idaho',
        parent: 'USA',
        filename: 'countries/us/us-id-all.js',
    },
    {
        name: 'Illinois',
        parent: 'USA',
        filename: 'countries/us/us-il-all.js',
    },
    {
        name: 'Indiana',
        parent: 'USA',
        filename: 'countries/us/us-in-all.js',
    },
    {
        name: 'Iowa',
        parent: 'USA',
        filename: 'countries/us/us-ia-all.js',
    },
    {
        name: 'Kansas',
        parent: 'USA',
        filename: 'countries/us/us-ks-all.js',
    },
    {
        name: 'Kentucky',
        parent: 'USA',
        filename: 'countries/us/us-ky-all.js',
    },
    {
        name: 'Louisiana',
        parent: 'USA',
        filename: 'countries/us/us-la-all.js',
    },
    {
        name: 'Maine',
        parent: 'USA',
        filename: 'countries/us/us-me-all.js',
    },
    {
        name: 'Maryland',
        parent: 'USA',
        filename: 'countries/us/us-md-all.js',
    },
    {
        name: 'Massachusetts',
        parent: 'USA',
        filename: 'countries/us/us-ma-all.js',
    },
    {
        name: 'Michigan',
        parent: 'USA',
        filename: 'countries/us/us-mi-all.js',
    },
    {
        name: 'Minnesota',
        parent: 'USA',
        filename: 'countries/us/us-mn-all.js',
    },
    {
        name: 'Mississippi',
        parent: 'USA',
        filename: 'countries/us/us-ms-all.js',
    },
    {
        name: 'Missouri',
        parent: 'USA',
        filename: 'countries/us/us-mo-all.js',
    },
    {
        name: 'Montana',
        parent: 'USA',
        filename: 'countries/us/us-mt-all.js',
    },
    {
        name: 'Nebraska',
        parent: 'USA',
        filename: 'countries/us/us-ne-all.js',
    },
    {
        name: 'Nevada',
        parent: 'USA',
        filename: 'countries/us/us-nv-all.js',
    },
    {
        name: 'New Hampshire',
        parent: 'USA',
        filename: 'countries/us/us-nh-all.js',
    },
    {
        name: 'New Jersey',
        parent: 'USA',
        filename: 'countries/us/us-nj-all.js',
    },
    {
        name: 'New Mexico',
        parent: 'USA',
        filename: 'countries/us/us-nm-all.js',
    },
    {
        name: 'New York',
        parent: 'USA',
        filename: 'countries/us/us-ny-all.js',
    },
    {
        name: 'North Carolina',
        parent: 'USA',
        filename: 'countries/us/us-nc-all.js',
    },
    {
        name: 'North Dakota',
        parent: 'USA',
        filename: 'countries/us/us-nd-all.js',
    },
    {
        name: 'Ohio',
        parent: 'USA',
        filename: 'countries/us/us-oh-all.js',
    },
    {
        name: 'Oklahoma',
        parent: 'USA',
        filename: 'countries/us/us-ok-all.js',
    },
    {
        name: 'Oregon',
        parent: 'USA',
        filename: 'countries/us/us-or-all.js',
    },
    {
        name: 'Pennsylvania',
        parent: 'USA',
        filename: 'countries/us/us-pa-all.js',
    },
    {
        name: 'Rhode Island',
        parent: 'USA',
        filename: 'countries/us/us-ri-all.js',
    },
    {
        name: 'South Carolina',
        parent: 'USA',
        filename: 'countries/us/us-sc-all.js',
    },
    {
        name: 'South Dakota',
        parent: 'USA',
        filename: 'countries/us/us-sd-all.js',
    },
    {
        name: 'Tennessee',
        parent: 'USA',
        filename: 'countries/us/us-tn-all.js',
    },
    {
        name: 'Texas',
        parent: 'USA',
        filename: 'countries/us/us-tx-all.js',
    },
    {
        name: 'Utah',
        parent: 'USA',
        filename: 'countries/us/us-ut-all.js',
    },
    {
        name: 'Vermont',
        parent: 'USA',
        filename: 'countries/us/us-vt-all.js',
    },
    {
        name: 'Virginia',
        parent: 'USA',
        filename: 'countries/us/us-va-all.js',
    },
    {
        name: 'Washington',
        parent: 'USA',
        filename: 'countries/us/us-wa-all.js',
    },
    {
        name: 'West Virginia',
        parent: 'USA',
        filename: 'countries/us/us-wv-all.js',
    },
    {
        name: 'Wisconsin',
        parent: 'USA',
        filename: 'countries/us/us-wi-all.js',
    },
    {
        name: 'Wyoming',
        parent: 'USA',
        filename: 'countries/us/us-wy-all.js',
    },
];
