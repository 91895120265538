import { alpha, Theme } from '@mui/material';

export default {
    hintWrapper: {
        padding: 1.5,
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderRadius: '3px',
        mt: 1,
    },
};
