import { RawFormComponentType, RawFormElementProps } from '../index';
import React from 'react';
import { prepareFormElementProps } from 'components/common/form/formTools';
import FormElement from 'components/common/form/element/FormElement';
import { Stack } from '@mui/material';

export default function SharedControls({
    elementProps,
    components,
}: {
    elementProps: RawFormElementProps;
    components: Array<RawFormComponentType>;
}) {
    return (
        <Stack
            className="form-components-indent"
            alignItems="center"
            sx={{ px: (theme) => theme.size.pxValue(theme.size.containerIndentX) }}
        >
            {components.map((component: RawFormComponentType, i: number) => {
                const fullProps = prepareFormElementProps({
                    ...elementProps,
                    component: component,
                });
                return (
                    fullProps.component.props.onEdit && (
                        <FormElement
                            key={i}
                            {...fullProps}
                            wrapWithContainer={true}
                            containerMaxWidth={fullProps.containerMaxWidth}
                        />
                    )
                );
            })}
        </Stack>
    );
}
