import ReactSelect from 'components/common/react-select/ReactSelect';
import { Box, Button, IconButton, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;

export default function RowColumnFieldsList({
    fields,
    unusedFields,
    onChange,
    onEditClick,
    type,
}: {
    fields: Array<ComponentSettingsPivotField>;
    unusedFields: Array<ComponentSettingsPivotField>;
    onChange: (newFieldsList: Array<ComponentSettingsPivotField>) => void;
    onEditClick: (fieldIndex: number) => void;
    type: 'row' | 'column';
}) {
    const handleFieldsChange = (index: number, newName: string) => {
        const list = fields.slice();
        const selectedField = unusedFields.find((f) => f.reference_name_escaped == newName);
        if (!selectedField) {
            return;
        }
        selectedField.textAlign = list[index].textAlign;
        selectedField.textBold = list[index].textBold;
        selectedField.textItalic = list[index].textItalic;
        selectedField.textColor = list[index].textColor;
        selectedField.bgColor = list[index].bgColor;
        if (type == 'row') {
            selectedField.headerSettings = structuredClone(list[index].headerSettings);
        } else {
            selectedField.subtotalSettings = structuredClone(list[index].subtotalSettings);
        }

        list[index] = selectedField;
        onChange(list);
    };

    const handleAddField = () => {
        const list = fields.slice();
        const f: ComponentSettingsPivotField = structuredClone(unusedFields[0]);
        if (type == 'row') {
            f.textAlign = 'left';
            f.textBold = false;
            f.textItalic = false;
            f.headerSettings = {
                textBold: true,
            };
        } else {
            f.textAlign = 'right';
            f.textBold = true;
            f.subtotalSettings = {
                textBold: true,
                textAlign: 'right',
            };
        }

        list.push(f);
        onChange(list);
    };

    const handleRemoveSortField = (index: number) => {
        const list = fields.slice();
        list.splice(index, 1);
        onChange(list);
    };

    return (
        <>
            <Stack spacing={1}>
                {fields.map((field, index) => {
                    const allowedList = unusedFields.map((f) => ({
                        label: f.column_name,
                        value: f.reference_name_escaped,
                    }));
                    allowedList.push({
                        label: field.column_name,
                        value: field.reference_name_escaped,
                    });

                    return (
                        <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                            <Box sx={{ flexGrow: 1 }}>
                                <ReactSelect
                                    data={allowedList.sort((a, b) => (a.value > b.value ? 1 : -1))}
                                    value={field.reference_name_escaped}
                                    update={(value) => handleFieldsChange(index, value)}
                                />
                            </Box>

                            <Box sx={{ flexShrink: 0 }}>
                                <IconButton onClick={() => onEditClick(index)}>
                                    <IconMi icon="gear" fontSize="16" />
                                </IconButton>
                                <IconButton onClick={() => handleRemoveSortField(index)}>
                                    <IconMi icon="times" fontSize="16" />
                                </IconButton>
                            </Box>
                        </Stack>
                    );
                })}
            </Stack>
            {unusedFields.length > 0 && (
                <Box sx={{ mt: fields.length > 0 ? 2 : '2px' }}>
                    <Button onClick={() => handleAddField()} startIcon={<IconMi icon={'new'} />} variant="light">
                        Field
                    </Button>
                </Box>
            )}
        </>
    );
}
