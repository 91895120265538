import {
    HeaderDropDownFieldType,
    HeaderDropDownTriggerClassName,
} from 'components/report-content/components/dataset/index';
import React, { useState } from 'react';
import { YNValues } from 'tools/types';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import DatasetHeaderCell from 'components/report-content/components/dataset/parts/header/DatasetHeaderCell';
import DatasetHeaderColumnDropDown from 'components/report-content/components/dataset/parts/header/DatasetHeaderColumnDropDown';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import IconMi from 'components/common/icon/IconMi';
import { Box } from '@mui/material';
import { getFontSizeStyle } from 'components/report-content/utils/tools';

export default function DatasetHeaderLine({
    component,
    onChangeColumnSettings,
    onChangeColumnSortSettings,
    cellStyler,
    scrollLeft,
    contentSettings,
    actions,
    editPanel,
    blockId,
}: ComponentProps<ComponentSettingsDataset> & {
    onChangeColumnSettings: (
        fieldId: string,
        newSettings: ComponentSettingsDatasetField,
        unsetAutoStretch?: boolean,
    ) => void;
    onChangeColumnSortSettings: (newSortFieldsSettings: Array<ComponentSettingsDatasetSortField>) => void;
    cellStyler: CellStyler;
    scrollLeft: number;
}) {
    const [headerDropDownField, setHeaderDropDownField] = useState<
        HeaderDropDownFieldType<ComponentSettingsDatasetField>
    >({
        field: null,
        anchorEl: null,
    });

    const handleShowColumn = (fieldIndex: number) => {
        for (let i = fieldIndex; i < component.settings.fields.length; i++) {
            const field = component.settings.fields[i];
            if (field.show_column_in_table_display_ind == 'Y') {
                break;
            }
            const newSettings = { ...field, show_column_in_table_display_ind: 'Y' as YNValues };
            onChangeColumnSettings(field.id, newSettings);
        }
    };

    const fontStyle = getFontSizeStyle(component.settings);

    let previousColumnIsHidden = false;
    const result = component.settings.fields
        .filter((field) => field.break_column != 'Y')
        .map((field, index: number) => {
            if (field.show_column_in_table_display_ind != 'Y') {
                if (previousColumnIsHidden) {
                    return null;
                }
                previousColumnIsHidden = true;
                return (
                    <div key={field.id} style={{ position: 'relative' }}>
                        {contentSettings.context == 'edit' && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    zIndex: 6,
                                    display: 'flex',
                                    height: '10px',
                                    alignItems: 'center',
                                    border: '1px solid',
                                    px: '2px',
                                    borderColor: 'transparent',
                                    color: 'text.primary',
                                    left: '-7px',
                                    '&:hover': {
                                        color: 'primary.main',
                                        backgroundColor: 'background.default',
                                        borderColor: (theme) => theme.palette.primary.main,
                                    },
                                }}
                                onClick={() => handleShowColumn(index)}
                            >
                                <IconMi icon={'arrows-alt-h'} sx={{ fontSize: '18px', pointerEvents: 'none' }} />
                            </Box>
                        )}
                    </div>
                );
            }
            previousColumnIsHidden = false;

            return (
                <DatasetHeaderCell
                    scrollLeft={scrollLeft}
                    key={field.id}
                    field={field}
                    headerDropDownField={headerDropDownField}
                    setHeaderDropDownField={setHeaderDropDownField}
                    component={component}
                    cellStyler={cellStyler}
                    onChangeColumnSettings={onChangeColumnSettings}
                    context={contentSettings.context}
                    index={index}
                />
            );
        });

    return (
        <span style={{ position: 'relative' }}>
            <DatasetHeaderColumnDropDown
                key={headerDropDownField.field?.id}
                blockId={blockId}
                actions={actions}
                editPanel={editPanel}
                component={component}
                onChangeColumnSettings={onChangeColumnSettings}
                onChangeColumnSortSettings={onChangeColumnSortSettings}
                anchorEl={headerDropDownField.anchorEl}
                field={headerDropDownField.field}
                onClickAway={(event: PointerEvent) => {
                    if ((event.target as HTMLElement).classList.contains(HeaderDropDownTriggerClassName)) {
                        return;
                    }
                    setHeaderDropDownField({ field: null, anchorEl: null });
                }}
            />

            <Box
                sx={{ fontStyle }}
                style={{ display: 'flex', overflow: 'hidden', marginLeft: '-' + scrollLeft + 'px' }}
            >
                {result}
            </Box>
        </span>
    );
}
