import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default {
    emptyGridPlaceholder: {
        border: 1,
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        borderRadius: 1,
        textAlign: 'center',
        p: 2,
    },
    gridBody: {
        width: '100%',
        maxHeight: '330px', //10 rows
        overflow: 'auto',
    },
    rowWrapper: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        opacity: 1,
        '&:not(.header)': {
            backgroundColor: 'background.default',
            borderBottom: '1px solid',
            borderBottomColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
        '&.header': {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
            backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
        '&.selected': {
            backgroundColor: (theme: Theme) => useBlendColors(alpha(theme.palette.primary.main, 0.08)),
            '&:before': {
                content: '""',
                width: '1px',
                height: '100%',
                backgroundColor: (theme: Theme) => theme.palette.primary.main,
                position: 'absolute',
                left: 0,
            },
        },
        '.cell': {
            flexShrink: 0,

            '&--text': {
                py: '7px',
                px: 1,
            },

            '&--select, &--text-and-action': {
                px: 1,
            },

            '&--main': {
                flexGrow: 1,
                flexShrink: 1,
            },

            '&--drag': {
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '.svg-icon-mi': {
                    fontSize: '16px',
                    cursor: 'row-resize',
                },
            },
            '&--checkbox': {
                width: '24px',
                textAlign: 'right',
            },
            '&--actions': {
                width: '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
            },
        },
    },
    fieldsGrid: {
        '.data': {
            '&--alias': { width: '200px' },
            '&--type': { width: '100px' },
            '&--override': { width: '120px' },
            '&--aggregation': { width: '110px' },
        },
    },
    sortGrid: {
        '.data': {
            '&--direction': { width: '100px' },
        },
    },
    filterGrid: {
        '.data': {
            '&--fetch-type': { width: '100px' },
            '&--operator': { width: '100px' },
            '&--value': { width: '200px' },
        },
    },
};
