import { lazy } from 'react';

const LazyFormComponent = lazy(() => import('./form/Form'));
const LazyApplyButton = lazy(() => import('./form/layout/helper/ApplyButton'));
const LazyButtonEditWithAdd = lazy(() => import('./form/layout/helper/ButtonEditWithAdd'));
const LazyAddCompareLineList = lazy(() => import('components/metric/collaboration-controls/AddCompareLineList'));
const LazyDataFetchCommand = lazy(() => import('components/common/form/DataFetchCommand'));
const LazyShowData = lazy(() => import('components/common/form/data-fetch-command/ShowData'));
const LazyCSVUploader = lazy(() => import('app/editor/dataset/CSVUploader'));
const LazyCommandDetailsDownload = lazy(() => import('app/editor/dataset/CommandDetailsDownload'));
const LazyIndexManager = lazy(() => import('app/editor/dataset/IndexManager'));
const LazyFolderContent = lazy(() => import('app/editor/folder/FolderContent'));
const LazyReferenceSelection = lazy(() => import('components/external-reference-selection/ReferenceSelection'));
const LazyNotificationFrequency = lazy(() => import('app/distribution/notification-schedules/NotificationFrequency'));
const LazyReplaceTopics = lazy(() => import('app/editor/topic/ReplaceTopics'));
const LazyMoveTopics = lazy(() => import('app/editor/topic/MoveTopics'));
const LazyAutoSyncInfo = lazy(() => import('app/extreport/AutoSyncInfo'));
const LazyBurstFolderSelect = lazy(() => import('app/notification/folder-select/BurstFolderSelect'));
const LazyTokenlessUsers = lazy(() => import('app/notification/tokenless-users/TokenlessUsers'));
const LazyCreateDatasource = lazy(() => import('app/editor/datasource/CreateDatasource'));
const LazyNewElementForm = lazy(() => import('app/editor/element/NewElementForm'));
const LazyBulkChange = lazy(() => import('app/bulk-change/BulkChangePopup'));
const LazyAssociateRule = lazy(() => import('app/editor/element/metric/AssociateRule'));
const LazyCreateDatasourceFormButton = lazy(() => import('app/editor/datasource/CreateDatasourceFormButton'));
const LazySystemStatus = lazy(() => import('app/monitor/SystemStatus'));
const LazyGlobalVariables = lazy(() => import('app/editor/page-template/GlobalVariables'));
const LazyVariablesGrid = lazy(() => import('app/editor/page-template/VariablesGrid'));
const LazySyncWithGit = lazy(() => import('app/editor/page-template/SyncWithGit'));
const LazyAssetsTree = lazy(() => import('app/editor/page-template/AssetsTree'));
const LazyPageVariables = lazy(() => import('app/editor/page/variables/PageVariables'));
const LazyPageEntityFsAccess = lazy(() => import('app/editor/page/entity/FsAccess'));
const LazyChangeTemplate = lazy(() => import('app/editor/page/ChangeTemplate'));
const LazyDropFileUpload = lazy(() => import('app/editor/asset/DropFileUpload'));
const LazyUnpublishTablesPopup = lazy(() => import('app/editor/source/UnpublishTablesPopup'));
const LazyReferenceExternalFiltersManager = lazy(
    () => import('components/external-reference/editor/ReferenceExternalFiltersManager'),
);
const LazySelectMap = lazy(() => import('components/common/select-map/SelectMap'));
const LazySelectMapGrid = lazy(() => import('components/common/select-map-grid/SelectMapGrid'));
const LazyBrandingLogoControl = lazy(() => import('components/branding/logo-upload/BrandingLogoControl'));
const LazyElementEditorFeedback = lazy(() => import('app/editor/element/feedback/index'));
const LazyTermsControl = lazy(() => import('components/common/terms-control'));
const LazyUserSelection = lazy(() => import('components/common/user-selection'));
const LazyImportErrorStack = lazy(() => import('components/common/import-error-stack/ImportErrorStack'));
const LazyRatingEngagement = lazy(() => import('app/editor/element/feedback/RatingEngagement'));
const LazyBurstControl = lazy(() => import('app/notification/burst/BurstControl'));
const LazyBurstSharingAlert = lazy(() => import('app/notification/sharing-alert/index'));
const LazyEmbedCodePopup = lazy(() => import('app/notification/embed-code-popup/index'));
const LazySendToGroup = lazy(() => import('app/notification/send-now/SendToGroup'));
const LazyMobilePreference = lazy(() => import('app/preferences/mobile-preference'));
const LazyPipelineList = lazy(() => import('app/monitor/pipeline/PipelineList'));
const LazyPublicImagePreview = lazy(() => import('components/element-image/public-image-preview/PublicImagePreview'));
const LazyChooseIcon = lazy(() => import('components/common/choose-icon/ChooseIcon'));
const LazyColorPicker = lazy(() => import('components/common/color-picker/ColorPicker'));
const LazyCodeEditor = lazy(() => import('components/common/code-editor/CodeEditor'));
const LazySecureJsonEditor = lazy(() => import('components/common/secure-json-editor/index'));
const LazyElementInfo = lazy(() => import('components/common/element-info/ElementInfo'));
const LazyImageListHandler = lazy(() => import('components/common/image-list-handler/ImageListHandler'));
const LazySendToList = lazy(() => import('components/common/send-to-list/SendToList'));
const LazyFoldersTree = lazy(() => import('app/content/FoldersTree'));
const LazyReportContentEditor = lazy(() => import('app/editor/report/ReportContentEditor'));
const LazyUserAlert = lazy(() => import('app/editor/user-alerts/UserAlerts'));
const LazyRuleCondition = lazy(() => import('app/editor/content-workflow/RuleCondition'));
const LazyInvolvedFields = lazy(() => import('app/editor/content-workflow/InvolvedFields'));
const LazyEmailSubjectLine = lazy(() => import('app/notification/burst/EmailSubjectLine'));
const LazySimpleSchedule = lazy(() => import('app/notification/simple-schedule/index'));
const LazyTriggerCompleted = lazy(() => import('app/notification/trigger-completed/index'));
const LazyEditorExternalFiltersGrid = lazy(() => import('app/editor/external-filters/EditorExternalFiltersGrid'));
const LazyFilterUsageRefreshButton = lazy(() => import('app/editor/external-filters/FilterUsageRefreshButton'));
const LazyFormTriggerEventMessages = lazy(() => import('app/editor/trigger/TriggerEventMessages'));
const LazyMetricResetForAllUsers = lazy(() => import('app/editor/element/metric/MetricResetForAllUsers'));
const LazyDisplayMaskExample = lazy(() => import('components/common/display-mask-example/DisplayMaskExample'));
const LazyKpiRuleInfo = lazy(() => import('app/editor/kpi-alert-rule/KpiRuleInfo'));
const LazyKpiRuleAssignWorkflowLink = lazy(() => import('app/editor/kpi-alert-rule/KpiRuleAssignWorkflowLink'));
const LazyKpiRuleUserMapAccess = lazy(() => import('app/editor/kpi-alert-rule/KpiRuleUserMapAccess'));
const LazyThemeExample = lazy(() => import('app/editor/brand-theme/theme-example/ThemeExample'));
const LazyThemeUsagePreview = lazy(() => import('app/editor/brand-theme/ThemeUsagePreview'));

const LazyExternalReportCustomUrl = lazy(() => import('app/editor/element/extreport/ExternalReportCustomUrl'));
const LazyExternalContentCustomUrl = lazy(() => import('app/editor/element/ext-content/ExternalContentCustomUrl'));
const LazyUserActionLinks = lazy(() => import('app/editor/user/ActionLinks'));
const LazyLineageFormButton = lazy(() => import('components/lineage/LineageFormButton'));
const LazyElementSource = lazy(() => import('app/content-workflow/element-editor/ElementSource'));
const LazyContentFolderSelect = lazy(() => import('app/content-workflow/element-editor/ContentFolderSelect'));
const LazyCollectionTrigger = lazy(() => import('app/content-workflow/element-editor/CollectionTrigger'));
const LazyFormHidden = lazy(() => import('./form/layout/control/FormHidden'));
const LazyFormUniquenessText = lazy(() => import('./form/layout/control/FormUniquenessText'));
const LazyFormSwitch = lazy(() => import('./form/layout/control/FormSwitch'));
const LazyFormTinyMCE = lazy(() => import('./form/layout/control/FormTinyMCE'));
const LazyJDBCControl = lazy(() => import('components/storage/JDBCControl'));
const LazyFormCheckbox = lazy(() => import('./form/layout/control/FormCheckbox'));
const LazyFormGenericYNControl = lazy(() => import('./form/layout/control/FormGenericYNControl'));
const LazyFormTimeField = lazy(() => import('./form/layout/control/FormTimeField'));
const LazyFormDateRange = lazy(() => import('./form/layout/control/FormDateRange'));
const LazyFormCompareField = lazy(() => import('./form/layout/control/FormCompareField'));
const LazyFormCompareFieldBetween = lazy(() => import('./form/layout/control/FormCompareFieldBetween'));
const LazyFormCompareInput = lazy(() => import('./form/layout/control/FormCompareInput'));
const LazyFormTimeSelect = lazy(() => import('./form/layout/control/FormTimeSelect'));
const LazyFormDateSelect = lazy(() => import('./form/layout/control/FormDateSelect'));
const LazyFormPeriodField = lazy(() => import('./form/layout/control/FormPeriodField'));
const LazyFormSlider = lazy(() => import('./form/layout/control/FormSlider'));
const LazyFormStateButton = lazy(() => import('./form/layout/control/FormStateButton'));
const LazyFormButton = lazy(() => import('./form/layout/control/FormButton'));
const LazyFormDataSource = lazy(() => import('./form/layout/control/FormDataSource'));
const LazyFormTags = lazy(() => import('./form/layout/control/FormTags'));
// const LazyFormDatePicker = lazy(() => import('./form/layout/control/FormDatePicker'));
const LazyFormCheckboxMigration = lazy(() => import('./form/layout/control/FormCheckboxMigration'));
const LazyFormTransferList = lazy(() => import('components/common/transfer-list/TransferList'));
const LazyFormEmailTemplateCustomization = lazy(() => import('app/admin/email-template/EmailTemplateCustomization'));
const LazyFormStaticIconLabel = lazy(() => import('./form/layout/static/FormStaticIconLabel'));
const LazyFormStaticDivider = lazy(() => import('./form/layout/static/FormStaticDivider'));
const LazyFormStaticInfo = lazy(() => import('./form/layout/static/FormStaticInfo'));
const LazyStaticInfo = lazy(() => import('components/common/StaticInfo'));
const LazyFormStaticCodeBlock = lazy(() => import('./form/layout/static/FormStaticCodeBlock'));
const LazyFormStaticLink = lazy(() => import('./form/layout/static/FormStaticLink'));
const LazyFormPanelButtonSave = lazy(() => import('./form/top-control-panel/button-components/FormPanelButtonSave'));
const LazyFormPanelButtonEnableSave = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonEnableSave'),
);
const LazyFormTemplateField = lazy(() => import('./form/layout/control/FormTemplateField'));
const LazySendBurstNow = lazy(() => import('app/notification/send-burst-now/index'));
const LazyFormPanelButtonStartExporting = lazy(() => import('app/content/export/form/FormPanelButtonStartExporting'));

const LazyFormPanelButtonDownload = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonDownload'),
);
const LazyFormPanelButtonAdd = lazy(() => import('./form/top-control-panel/button-components/FormPanelButtonAdd'));
const LazyFormPanelButtonSearch = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonSearch'),
);
const LazyFormPanelGenericButton = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelGenericButton'),
);
const LazyFormPanelExternalReportViewButton = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelExternalReportViewButton'),
);
const LazyFormPanelButtonText = lazy(() => import('./form/top-control-panel/button-components/FormPanelButtonText'));
const LazyFormPanelButtonUpdateIndexes = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonUpdateIndexes'),
);
const LazyFormPanelButtonExample = lazy(() => import('app/devui/form/FormPanelButtonExample'));
const LazyFormPanelButtonTriggerControls = lazy(() => import('app/editor/trigger/panel-buttons'));
const LazyFormFormPanelButtonPreviewChanges = lazy(() => import('app/editor/metadata-prop/PreviewChanges'));
const LazyFormPanelButtonBurstPreviewAndEnable = lazy(
    () => import('app/notification/burst/panel-buttons/FormPanelButtonBurstPreviewAndEnable'),
);
const LazyFormPanelButtonLineage = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonLineage'),
);
const LazyFormPanelButtonLoadFromFile = lazy(
    () => import('app/editor/element/metric/panel-buttons/FormPanelButtonLoadFromFile'),
);
const LazyFormPanelButtonKpiSendTest = lazy(
    () => import('app/editor/kpi-alert-rule/panel-buttons/FormPanelButtonKpiSendTest'),
);
const LazyFormPanelButtonHtmlPreview = lazy(
    () => import('./form/top-control-panel/button-components/FormPanelButtonHtmlPreview'),
);

const LazyFormPanelButtonScheduleSendNow = lazy(
    () => import('app/editor/schedule/panel-button/FormPanelButtonScheduleSendNow'),
);

const LazyPreviewFavorite = lazy(() => import('app/notification/favorite-notification/PreviewFavorite'));
const LazyRunCustomScript = lazy(() => import('app/admin/custom-script/RunCustomScript'));
const LazyFolderSelection = lazy(() => import('app/editor/element/FolderSelection'));
const LazyAddNewAlertPopup = lazy(() => import('components/alert-rule-popup/AddNewAlertPopup'));
const LazyAlertGridDimensionPopup = lazy(() => import('app/editor/kpi-alert-rule/AlertGridDimensionPopup'));
const LazyAddEntityExportGridPopup = lazy(() => import('app/admin/content/AddEntityExportGridPopup'));
const LazyChangeVisibilityPopup = lazy(() => import('app/editor/category/element/ChangeVisibilityPopup'));
const LazyProfilePicture = lazy(() => import('app/preferences/settings/profile-picture/index'));
const LazyLoadFromFilePopup = lazy(() => import('app/editor/glossary/LoadFromFilePopup'));
const LazySyncContentPopup = lazy(() => import('app/editor/plugin-source/element/SyncContentPopup'));
const LazyUploadFontPopup = lazy(() => import('app/editor/brand-theme/UploadFontPopup'));

const Components = {
    GridComponent: require('./grid/GridComponent').default,
    FormComponent: LazyFormComponent,
    ApplyButton: LazyApplyButton,
    ButtonEditWithAdd: LazyButtonEditWithAdd,
    CustomFieldEdit: require('./form/layout/helper/CustomFieldEdit').default,

    UncommittedChangesAlert: require('app/admin/system-variables/UncommittedChangesAlert').default,
    MyNotificationInfo: require('app/notification/MyNotificationInfo').default,
    SystemNotificationInfo: require('app/notification/SystemNotificationInfo').default,

    AddCompareLineList: LazyAddCompareLineList,
    AddOverlayList: require('components/metric/collaboration-controls/AddOverlayList').default,
    DataFetchCommand: LazyDataFetchCommand,
    ShowData: LazyShowData,
    CSVUploader: LazyCSVUploader,
    CommandDetailsDownload: LazyCommandDetailsDownload,
    IndexManager: LazyIndexManager,
    FolderContent: LazyFolderContent,
    ReferenceSelection: LazyReferenceSelection,
    NotificationFrequency: LazyNotificationFrequency,
    ReplaceTopics: LazyReplaceTopics,
    ChangeVisibility: require('app/editor/segment/ChangeVisibility').default,
    MoveTopics: LazyMoveTopics,
    AutoSyncInfo: LazyAutoSyncInfo,
    BurstFolderSelect: LazyBurstFolderSelect,
    TokenlessUsers: LazyTokenlessUsers,
    AddNewAlertPopup: LazyAddNewAlertPopup,
    AlertGridDimensionPopup: LazyAlertGridDimensionPopup,
    AddEntityExportGridPopup: LazyAddEntityExportGridPopup,
    ChangeVisibilityPopup: LazyChangeVisibilityPopup,
    LoadFromFilePopup: LazyLoadFromFilePopup,
    SyncContentPopup: LazySyncContentPopup,

    CreateDatasource: LazyCreateDatasource,
    NewElementForm: LazyNewElementForm,
    CreateDatasourceFormButton: LazyCreateDatasourceFormButton,
    BulkChangePopup: LazyBulkChange,
    AssociateRule: LazyAssociateRule,

    UnpublishTablesPopup: LazyUnpublishTablesPopup,

    SystemStatus: LazySystemStatus,

    SendBurstNow: LazySendBurstNow,

    //Page And PageTemplate Components
    GlobalVariables: LazyGlobalVariables,
    VariablesGrid: LazyVariablesGrid,
    SyncWithGit: LazySyncWithGit,
    AssetsTree: LazyAssetsTree,
    PageVariables: LazyPageVariables,
    PageEntityFsAccess: LazyPageEntityFsAccess,
    ChangeTemplate: LazyChangeTemplate,
    DropFileUpload: LazyDropFileUpload,
    ExportResultPopup: require('app/content/export/ExportResultPopup').default,
    CreateTemplatePopup: require('app/editor/element/CreateTemplatePopup').default,
    CodeEditorHistoryPopup: require('components/common/code-editor/CodeEditorHistoryPopup').default,
    UserMassDeletePopup: require('app/editor/user/MassDeletePopup').default,
    ImportUsersPopup: require('app/editor/user/ImportUsersPopup').default,

    // Editor Complex Components
    ReferenceExternalFiltersManager: LazyReferenceExternalFiltersManager,
    SelectMap: LazySelectMap,
    SelectMapGrid: LazySelectMapGrid,
    ElementImageControl: require('components/element-image/editor/ElementImageControl').default,
    BrandingLogoControl: LazyBrandingLogoControl,
    ElementEditorFeedback: LazyElementEditorFeedback,
    TermsControl: LazyTermsControl,
    UserSelection: LazyUserSelection,
    ImportErrorStack: LazyImportErrorStack,
    RatingEngagement: LazyRatingEngagement,
    BurstControl: LazyBurstControl,
    BurstSharingAlert: LazyBurstSharingAlert,
    EmbedCodePopup: LazyEmbedCodePopup,
    SendToGroup: LazySendToGroup,
    MobilePreference: LazyMobilePreference,
    PipelineList: LazyPipelineList,
    UploadFile: require('components/common/upload-control/UploadFile').default,
    UploadImage: require('components/common/upload-control/UploadImage').default,
    ProfilePicture: LazyProfilePicture,
    PublicImagePreview: LazyPublicImagePreview,
    CheckBoxList: require('components/common/check-box-list/CheckBoxList').default,
    ChooseIcon: LazyChooseIcon,
    ColorPicker: LazyColorPicker,
    CodeEditor: LazyCodeEditor,
    SecureJsonEditor: LazySecureJsonEditor,
    ElementInfo: LazyElementInfo,
    ImageListHandler: LazyImageListHandler,
    SendToList: LazySendToList,
    FoldersTree: LazyFoldersTree,
    ReportContentEditor: LazyReportContentEditor,
    UserAlert: LazyUserAlert,
    RuleCondition: LazyRuleCondition,
    InvolvedFields: LazyInvolvedFields,
    EmailSubjectLine: LazyEmailSubjectLine,
    SimpleSchedule: LazySimpleSchedule,
    TriggerCompleted: LazyTriggerCompleted,
    EditorExternalFiltersGrid: LazyEditorExternalFiltersGrid,
    FilterUsageRefreshButton: LazyFilterUsageRefreshButton,
    FormTriggerEventMessages: LazyFormTriggerEventMessages,
    MetricResetForAllUsers: LazyMetricResetForAllUsers,
    DisplayMaskExample: LazyDisplayMaskExample,
    KpiRuleInfo: LazyKpiRuleInfo,
    KpiRuleAssignWorkflowLink: LazyKpiRuleAssignWorkflowLink,
    KpiRuleUserMapAccess: LazyKpiRuleUserMapAccess,
    ThemeExample: LazyThemeExample,
    ThemeUsagePreview: LazyThemeUsagePreview,
    PluginGetToken: require('components/common/plugin_get_token/PluginGetToken.tsx').default,
    EditorTestUrl: require('components/common/editor-test-url/EditorTestUrl').default,
    DatasetDeleteData: require('components/common/dataset-delete-data/DatasetDeleteData').default,
    DatasetDataStorage: require('components/common/dataset-data-storage/DatasetDataStorage').default,
    DatasetBackFill: require('components/common/dataset-data-storage/DatasetBackFill').default,
    ExternalReportTableauName: require('app/editor/element/extreport/ExternalReportTableauName').default,
    FilterDateDefault: require('app/editor/element/extreport/FilterDateDefault').default,
    VersionIdentifier: require('app/editor/page/VersionIdentifier').default,
    InternalName: require('app/editor/page/InternalName').default,
    CertificationControl: require('app/editor/element/CertificationControl.tsx').default,
    FormBurstStatus: require('components/common/form/layout/control/FormBurstStatus.tsx').default,

    ExternalReportCustomUrl: LazyExternalReportCustomUrl,
    ExternalContentCustomUrl: LazyExternalContentCustomUrl,
    UserActionLinks: LazyUserActionLinks,

    LineageFormButton: LazyLineageFormButton,

    ElementSource: LazyElementSource,
    ContentFolderSelect: LazyContentFolderSelect,
    CollectionTrigger: LazyCollectionTrigger,

    PreviewFavorite: LazyPreviewFavorite,
    RunCustomScript: LazyRunCustomScript,
    FolderSelection: LazyFolderSelection,

    // Form Wrapper Components
    FormWrapperEmpty: require('./form/layout/wrapper/FormWrapperEmpty').default,
    FormWrapperElementLabel: require('./form/layout/wrapper/FormWrapperElementLabel').default,
    FormWrapperGroup: require('./form/layout/wrapper/FormWrapperGroup').default,
    FormWrapperRow: require('./form/layout/wrapper/FormWrapperRow').default,
    // Form Elements
    FormHidden: LazyFormHidden,
    FormText: require('./form/layout/control/FormText').default,
    FormComponentSlot: require('./form/layout/control/FormComponentSlot').default,
    FormDescription: require('./form/layout/control/FormDescription').default,
    FormUniquenessText: LazyFormUniquenessText,
    FormUrlField: require('./form/layout/control/FormUrlField').default,
    FormSwitch: LazyFormSwitch,
    FormTinyMCE: LazyFormTinyMCE,
    FormTextarea: require('./form/layout/control/FormTextarea').default,
    FormRichText: require('./form/layout/control/FormRichText').default,
    FormImagePreview: require('./form/layout/control/FormImagePreview').default,
    JDBCControl: LazyJDBCControl,
    FormCheckbox: LazyFormCheckbox,
    FormGenericYNControl: LazyFormGenericYNControl,
    FormTimeField: LazyFormTimeField,
    FormDateRange: LazyFormDateRange,
    FormCompareField: LazyFormCompareField,
    FormCompareFieldBetween: LazyFormCompareFieldBetween,
    FormCompareInput: LazyFormCompareInput,
    FormTimeSelect: LazyFormTimeSelect,
    FormDateSelect: LazyFormDateSelect,
    FormPeriodField: LazyFormPeriodField,
    FormSlider: LazyFormSlider,
    FormStateButton: LazyFormStateButton,
    FormButton: LazyFormButton,
    FormRadioGroup: require('./form/layout/control/FormRadioGroup').default,
    FormToggleButtonGroup: require('./form/layout/control/FormToggleButtonGroup').default,
    FormSelect: require('./form/layout/control/FormSelect').default,
    FormDataSource: LazyFormDataSource,
    FormTags: LazyFormTags,
    FormDatePicker: require('./form/layout/control/FormDatePicker').default,
    FormCheckboxMigration: LazyFormCheckboxMigration,
    FormTransferList: LazyFormTransferList,
    FormEmailTemplateCustomization: LazyFormEmailTemplateCustomization,
    FormTemplateField: LazyFormTemplateField,

    // Form Static Elements
    FormStaticLabel: require('./form/layout/static/FormStaticLabel').default,
    FormStaticIconLabel: LazyFormStaticIconLabel,
    FormStaticDivider: LazyFormStaticDivider,
    FormStaticInfo: LazyFormStaticInfo,
    StaticInfo: LazyStaticInfo,
    FormStaticCodeBlock: LazyFormStaticCodeBlock,
    FormStaticLink: LazyFormStaticLink,
    // Form ToolBar Buttons
    FormPanelButtonSave: LazyFormPanelButtonSave,
    FormPanelButtonEnableSave: LazyFormPanelButtonEnableSave,
    FormPanelButtonDownload: LazyFormPanelButtonDownload,
    FormPanelButtonDelete: require('./form/top-control-panel/button-components/FormPanelButtonDelete').default,
    FormPanelButtonAdd: LazyFormPanelButtonAdd,
    FormPanelButtonSearch: LazyFormPanelButtonSearch,
    FormPanelButtonDuplicate: require('./form/top-control-panel/button-components/FormPanelButtonDuplicate').default,
    FormPanelButtonMore: require('./form/top-control-panel/button-components/FormPanelButtonMore').default,
    FormPanelButtonCollectData: require('./form/data-fetch-command/top-panel/FormPanelButtonCollectData').default,
    FormPanelButtonBuildReport: require('./form/data-fetch-command/top-panel/FormPanelButtonBuildReport').default,
    FormPanelGenericButton: LazyFormPanelGenericButton,
    FormPanelSendTestEmailButton: require('./form/top-control-panel/button-components/FormPanelSendTestEmailButton')
        .default,
    FormPanelExternalReportViewButton: LazyFormPanelExternalReportViewButton,
    FormPanelMetricViewButton: require('./form/top-control-panel/button-components/FormPanelMetricViewButton').default,
    FormPanelExternalContentViewButton:
        require('./form/top-control-panel/button-components/FormPanelExternalContentViewButton').default,
    FormPanelButtonUpdateLiveChart: require('./form/top-control-panel/button-components/FormPanelButtonUpdateLiveChart')
        .default,
    FormPanelButtonText: LazyFormPanelButtonText,
    FormPanelButtonUpdateIndexes: LazyFormPanelButtonUpdateIndexes,
    FormPanelButtonExample: LazyFormPanelButtonExample,
    FormPanelButtonTriggerControls: LazyFormPanelButtonTriggerControls,
    FormPanelButtonBurstPreviewAndEnable: LazyFormPanelButtonBurstPreviewAndEnable,
    FormPanelButtonLineage: LazyFormPanelButtonLineage,
    FormPanelButtonLoadFromFile: LazyFormPanelButtonLoadFromFile,
    FormPanelButtonComponent: require('./form/top-control-panel/button-components/FormPanelButtonComponent').default,
    FormPanelButtonKpiSendTest: LazyFormPanelButtonKpiSendTest,
    FormPanelButtonHtmlPreview: LazyFormPanelButtonHtmlPreview,
    FormPanelButtonStartExporting: LazyFormPanelButtonStartExporting,
    FormPanelButtonScheduleSendNow: LazyFormPanelButtonScheduleSendNow,
    FormPanelButtonPreviewChanges: LazyFormFormPanelButtonPreviewChanges,
    SegmentSyncButton: require('app/editor/segment/SyncNowFormButton').default,
    UploadFontPopup: LazyUploadFontPopup,
};

export type ComponentKey = keyof typeof Components;

export default Components;

export type FormComponentFieldType = 'control' | 'wrapper' | 'static' | 'other';
export function getFormComponentFieldType(componentKey: ComponentKey): FormComponentFieldType {
    if (['FormWrapperEmpty', 'FormWrapperGroup', 'FormWrapperRow'].includes(componentKey)) {
        return 'wrapper';
    }
    if (
        [
            'FormText',
            'FormDescription',
            'FormTextarea',
            'FormCheckbox',
            'FormRadioGroup',
            'FormToggleButtonGroup',
            'FormSelect',
            'FormDataSource',
            'FormDatePicker',
            'SelectMap',
            'SelectMapGrid',
            'UploadFile',
            'UploadImage',
            'ChooseIcon',
            'ColorPicker',
            'ImageListHandler',
            'ReferenceExternalFiltersManager',
            'CodeEditor',
            'FormSlider',
            'FormRichText',
        ].includes(componentKey)
    ) {
        return 'control';
    }
    if (
        [
            'FormStaticLabel',
            'FormStaticDivider',
            'FormStaticInfo',
            'FormStaticCodeBlock',
            'FormStaticLink',
            'FormStaticIconLabel',
        ].includes(componentKey)
    ) {
        return 'static';
    }
    return 'other';
}
