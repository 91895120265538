import { FormTabType, TabContext } from 'components/common/form/tab';
import { IFormTabComponentRecord } from 'components/common/form/formTools';
import { AssocArray } from 'tools/types';
import React, { useContext, useMemo } from 'react';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import { FormLayoutContext, ITabContextProps, ITabProps } from 'components/common/form/layout/tab/index';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';
import { Stack } from '@mui/material';

function FormTabsContentWrapper({ children }: { children: any }) {
    const layoutContext = useContext(FormLayoutContext);

    if (layoutContext.formLayout == 'popup') {
        return (
            <Stack
                alignItems="center"
                className={
                    'default-components form-components-indent scroll-content-container scroll-content-container--form-popup'
                }
                sx={{
                    width: '100%',
                }}
            >
                {children}
            </Stack>
        );
    }

    return <>{children}</>;
}

export default function FormTabsContent({
    componentsMap,
    formTabs,
    elementProps,
    customTabs = {},
}: ITabContextProps & {
    formTabs: Array<FormTabType>;
    componentsMap: Array<IFormTabComponentRecord>;
    customTabs?: AssocArray<JSX.Element>;
}) {
    const layoutContext = useContext(FormLayoutContext);
    return (
        <FormTabsContentWrapper>
            {formTabs.map((tab: FormTabType, i: number) => (
                <TabContext.Provider
                    key={i}
                    value={{ ...tab, isActive: layoutContext.formLayout == 'popup' ? true : tab.isActive }}
                >
                    {useMemo(() => {
                        if (customTabs[tab.name]) {
                            return customTabs[tab.name];
                        }
                        return (
                            <RegularTabContent
                                tabName={tab.name}
                                isTabActive={tab.isActive}
                                elementProps={elementProps}
                                componentsMap={componentsMap}
                            />
                        );
                    }, [tab.isActive, elementProps.form?.formDidMount])}
                </TabContext.Provider>
            ))}
        </FormTabsContentWrapper>
    );
}

function RegularTabContent(tabProps: ITabProps & { componentsMap: Array<IFormTabComponentRecord> }) {
    const layoutContext = useContext(FormLayoutContext);
    const tabComponents = tabProps.componentsMap.filter(
        (record) => (tabProps.tabName == '' || record.tabName == tabProps.tabName) && !record.isNested,
    );

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <>
                {tabComponents
                    .filter((record) => {
                        return record.props.component.isPinned;
                    })
                    .map((record, index) => {
                        return (
                            <FormElementComponent
                                elementProps={tabProps.elementProps}
                                record={record}
                                formLayout={layoutContext.formLayout}
                                formType={layoutContext.formType}
                                key={record.props.component.uid + '_' + index}
                            />
                        );
                    })}
            </>
            <>
                {tabComponents
                    .filter((record) => {
                        return !record.props.component.isPinned;
                    })
                    .map((record, index) => {
                        return (
                            <FormElementComponent
                                elementProps={tabProps.elementProps}
                                record={record}
                                formLayout={layoutContext.formLayout}
                                formType={layoutContext.formType}
                                key={record.props.component.uid + '_' + index}
                            />
                        );
                    })}
            </>
        </FormTabContentDefaultStacks>
    );
}
