export type filterOnKeyDownType =
    | 'custom'
    | 'integerPositive'
    | 'integerNegative'
    | 'integer'
    | 'floatPositive'
    | 'floatNegative'
    | 'float';
interface configConditions {
    keys?: {
        delete?: boolean;
        backspace?: boolean;
        tab?: boolean;
        escape?: boolean;
        enter?: boolean;
        end?: boolean;
        home?: boolean;
        left?: boolean;
        right?: boolean;
        up?: boolean;
        down?: boolean;
    } | null;
    combinations?: {
        ctrlA?: boolean;
    } | null;
    modifications?: {
        numbers?: boolean;
        negative?: boolean;
        floatDot?: boolean;
    } | null;
}

const conditionsKeyAndCombination = {
    keys: {
        delete: (e: any) => e.keyCode === 46,
        backspace: (e: any) => e.keyCode === 8,
        tab: (e: any) => e.keyCode === 9,
        escape: (e: any) => e.keyCode === 27,
        enter: (e: any) => e.keyCode === 13,
        end: (e: any) => e.keyCode === 35,
        home: (e: any) => e.keyCode === 36,
        left: (e: any) => e.keyCode === 37,
        up: (e: any) => e.keyCode === 38,
        right: (e: any) => e.keyCode === 39,
        down: (e: any) => e.keyCode === 40,
    },
    combinations: {
        ctrlA: (e: any) => e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true),
    },
    modifications: {
        numbers: (e: any) => {
            //numbers without shift | numbers digital keyboard
            return (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || (e.keyCode >= 96 && e.keyCode <= 105);
        },
        negative: (e: any) => {
            //minus without shift | numbers digital keyboard
            const isMinusSymbol = e.keyCode == 108 || (e.keyCode == 189 && !e.shiftKey);
            const cursorPosition = e.target.selectionStart;
            const prevVal = e.target.value;
            return isMinusSymbol && cursorPosition == 0 && prevVal.charAt(0) != '-';
        },
        floatDot: (e: any) => {
            const isDotSymbol = e.key === '.';
            const cursorPosition = e.target.selectionStart;
            const prevVal = e.target.value;
            return isDotSymbol && cursorPosition > 0 && !prevVal.includes('.');
        },
    },
};

const prepareFilterConfig = (type: filterOnKeyDownType, overrideConfig?: configConditions) => {
    if (type == 'custom') {
        return overrideConfig ?? {};
    }

    let configCombinations: configConditions = {};

    configCombinations.keys = {
        delete: true,
        backspace: true,
        tab: true,
        escape: true,
        enter: true,
        end: true,
        home: true,
        left: true,
        right: true,
        up: true,
        down: true,
    };
    configCombinations.combinations = {
        ctrlA: true,
    };
    configCombinations.modifications = {};

    switch (type) {
        case 'integerPositive':
            configCombinations.modifications.numbers = true;
            break;
        case 'integerNegative':
            //TODO only negatives
            configCombinations.modifications.numbers = true;
            configCombinations.modifications.negative = true;
            break;
        case 'integer':
            configCombinations.modifications.numbers = true;
            configCombinations.modifications.negative = true;
            break;
        case 'floatPositive':
            configCombinations.modifications.numbers = true;
            configCombinations.modifications.floatDot = true;
            break;
        case 'floatNegative':
            //TODO only negatives
            configCombinations.modifications.numbers = true;
            configCombinations.modifications.floatDot = true;
            configCombinations.modifications.negative = true;
            break;
        case 'float':
            configCombinations.modifications.numbers = true;
            configCombinations.modifications.floatDot = true;
            configCombinations.modifications.negative = true;
            break;
    }

    //override by user config
    return {
        keys: overrideConfig?.keys === null ? {} : { ...configCombinations.keys, ...(overrideConfig?.keys ?? {}) },
        combinations:
            overrideConfig?.combinations === null
                ? {}
                : { ...configCombinations.combinations, ...(overrideConfig?.combinations ?? {}) },
        modifications:
            overrideConfig?.modifications === null
                ? {}
                : { ...configCombinations.modifications, ...(overrideConfig?.modifications ?? {}) },
    };
};
export default function filterOnKeyDown(event: any, type: filterOnKeyDownType, overrideConfig?: configConditions) {
    const prevValue = event.target.value;
    const cursorPosition = event.target.selectionStart;
    const configCombinations = prepareFilterConfig(type, overrideConfig);

    //console.log(prevValue, cursorPosition, configCombinations);
    //console.log(event);

    const isAllow = Object.keys(configCombinations).some((conditionTypeKey) => {
        const conditionTypeBlock = (configCombinations as any)[conditionTypeKey];
        return Object.keys(conditionTypeBlock).some((conditionKey) => {
            if ((configCombinations as any)[conditionTypeKey][conditionKey] == true) {
                return (conditionsKeyAndCombination as any)[conditionTypeKey][conditionKey](event);
            } else {
                return false;
            }
        });
    });

    if (!isAllow) {
        event.preventDefault();
    }
}
