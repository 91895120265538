import { alpha } from '@mui/material';

export const MuiChipConfig = {
    defaultProps: {},
    variants: [
        //Filled
        {
            props: { variant: 'filled', color: 'primary' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    color: theme.palette.primary.main,
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    },
                };
            },
        },
        {
            props: { variant: 'filled', color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: alpha(theme.palette.success.main, 0.08),
                    color: theme.palette.success.main,
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: alpha(theme.palette.success.main, 0.04),
                    },
                };
            },
        },
        {
            props: { variant: 'filled', color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: alpha(theme.palette.error.main, 0.08),
                    color: theme.palette.error.main,
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: alpha(theme.palette.error.main, 0.04),
                    },
                };
            },
        },
        {
            props: { variant: 'filled', color: 'default' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: alpha(theme.palette.text.secondary, 0.08),
                    color: theme.palette.text.primary,
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: alpha(theme.palette.text.secondary, 0.04),
                    },
                };
            },
        },
    ],
    styleOverrides: {
        root: {
            height: 20,
            borderRadius: 0,
        },
        label: {
            padding: '0 7px',
        },
        icon: {
            margin: '0 0 0 7px',
        },
    },
};
