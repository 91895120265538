import { ReportContentActions } from 'app/editor/report';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button, Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useState } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import BlockType = ReportContentNS.BlockType;
import ComponentSettings = ReportContentNS.ComponentSettings;
import ComponentFilter from 'components/report-content/block-top-controls/ComponentFilter';
import DatasetField = ReportContentNS.DatasetField;
import ComponentSettingsJct = ReportContentNS.ComponentSettingsJct;

export default function BlockTopControls({
    add,
    actions,
    block,
    editPanel,
    datasetFields,
}: {
    add: any;
    editPanel: any;
    actions: ReportContentActions;
    block: BlockType<ComponentSettings>;
    datasetFields: Array<DatasetField>;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const changeCollapsed = (block: BlockType<ComponentSettings>) => {
        const oldState = block.component.settings.is_collapsed_ind;
        actions.updateBlockSettings(block.uid, {
            ...block.component.settings,
            is_collapsed_ind: oldState == 'Y' ? 'N' : 'Y',
        });
    };

    let dTitle = block.component.settings.internal_name.match(/jct_[0-9]+/)
        ? (block.component.settings as ComponentSettingsJct).component_name
        : t(`titles.${block.component.settings.internal_name}`);

    const title = block.component.settings.title?.trim()?.length == 0 ? dTitle : block.component.settings.title;

    const isCollapsed = block.component.settings.is_collapsed_ind == 'Y';
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const hasFilters = [
        'manage_filters',
        'bubble_chart',
        'funnel_chart',
        'dataset',
        'chart',
        'line',
        'map_chart',
        'pivot',
        'range_chart',
    ].includes(block.component.internalName);

    return (
        <div style={{ overflow: 'hidden' }}>
            <Stack
                sx={{
                    borderBottom: isCollapsed ? 'none' : '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: '380px' }}>
                    <Box
                        sx={{
                            borderRight: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        }}
                    >
                        <IconButton onClick={() => changeCollapsed(block)}>
                            <IconMi icon={isCollapsed ? 'chevron-right' : 'chevron-down'} fontSize="16" />
                        </IconButton>
                    </Box>
                    {!isCollapsed && hasFilters && (
                        <Box
                            sx={{
                                borderRight: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            }}
                        >
                            <IconButton onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                                <IconMi icon={'filter'} fontSize="16" />
                                <Box
                                    sx={{
                                        padding: '0 4px',
                                        fontSize: '12px',
                                        fontFamily: (theme) => theme.font.default,
                                    }}
                                >
                                    {t('filter') +
                                        ' ' +
                                        t(
                                            block.component.internalName == 'dataset' ||
                                                block.component.internalName == 'pivot'
                                                ? 'table'
                                                : 'chart',
                                        )}
                                </Box>
                                <IconMi icon={isFilterOpen ? 'chevron-down' : 'chevron-right'} fontSize="16" />
                            </IconButton>
                        </Box>
                    )}
                </Stack>

                {isCollapsed && (
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {title}
                    </Stack>
                )}
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} sx={{ minWidth: '380px' }}>
                    <>{add}</>
                    {add && (
                        <Box
                            sx={{
                                borderLeft: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                alignSelf: 'stretch',
                            }}
                        />
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-test={`report-show-on-tiles-${block.component.internalName}_checkbox`}
                                disabled={block.component.settings.is_preview_ind == 'Y'}
                                checked={block.component.settings.is_preview_ind == 'Y'}
                            />
                        }
                        onClick={() => {
                            actions.setBlockPreviewInd(block.uid);
                        }}
                        label="Show on Tile"
                        sx={{ mr: 0, ml: 0 }}
                    />
                    <Box
                        sx={{
                            borderLeft: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                            width: 0,
                            height: '16px',
                            mx: '12px',
                        }}
                    />
                    <Button
                        variant="text"
                        startIcon={<IconMi icon="gear" />}
                        onClick={() => actions.openBlockEditor(block.uid, editPanel)}
                    >
                        <Box sx={{ pl: 0.75 }}>Edit</Box>
                    </Button>
                    {block.component.internalName != 'manage_filters' && (
                        <Box
                            sx={{
                                borderLeft: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            }}
                        >
                            <IconButton
                                onClick={(event) => {
                                    actions.removeBlock(block.uid, !event.altKey);
                                }}
                            >
                                <IconMi icon="trash" fontSize="16" />
                            </IconButton>
                        </Box>
                    )}
                </Stack>
            </Stack>
            {isFilterOpen && !isCollapsed && (
                <ComponentFilter block={block} actions={actions} datasetFields={datasetFields} />
            )}
        </div>
    );
}
