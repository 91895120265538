import { FormElementProps } from 'components/common/form/index';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getElementFieldValue } from 'components/common/form/formTools';
import { mergeObjectDeep } from 'tools/tools';
import { DependencyRule, FormComponentDependency } from 'components/common/form/dependency/index';
import { processEffect } from 'components/common/form/dependency/processEffect';
import checkRule from 'components/common/form/dependency/checkRule';

export default function applyComponentCondition(
    elementProps: FormElementProps,
    formsContext: FormsContextType,
    contextProps?: any,
    formTriggerSave?: number,
) {
    let resultProps = {};
    if (typeof contextProps != 'undefined') {
        resultProps = { ...contextProps };
    }
    if (typeof elementProps.component.props != 'undefined') {
        resultProps = { ...resultProps, ...elementProps.component.props };
    }

    if (!elementProps.component.deps?.length || !elementProps.form.formDidMount) {
        return resultProps;
    }

    elementProps.component.deps
        .filter((dep) => dep.scope == 'control')
        .forEach((dep: FormComponentDependency) => {
            let rulesValid = true;
            dep.rules.forEach((rule: DependencyRule) => {
                if (rule.field == '$form.save' && typeof formTriggerSave != 'undefined') {
                    return (rulesValid = elementProps.form.triggerSave?.current != formTriggerSave);
                }
                let fieldValue = getElementFieldValue(elementProps.form, rule.field, formsContext);
                rulesValid = rulesValid && checkRule(rule, fieldValue, elementProps.form, formsContext);
            });
            if (rulesValid) {
                resultProps = mergeObjectDeep(resultProps, processEffect(elementProps, dep, formsContext));
            }
        });
    return resultProps;
}
