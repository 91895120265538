import { IFormTabComponentRecord, prepareFormElementProps } from 'components/common/form/formTools';
import { useContext } from 'react';
import { FormLayoutContext, ITabProps } from 'components/common/form/layout/tab';
import { IFormElementComponentProps } from 'components/common/form/element/FormElementComponent';

function getComponentRecord(
    map: Array<IFormTabComponentRecord>,
    tabName: string,
    componentName: string,
): IFormTabComponentRecord | undefined {
    return map.find((record) => record.tabName == tabName && record.componentName == componentName);
}

export default function useTemplateFormTools({ componentsMap, tabName, elementProps }: ITabProps) {
    const layoutContext = useContext(FormLayoutContext);
    const preparedElementProps = prepareFormElementProps(elementProps);
    return {
        getRecord(componentName: string) {
            return getComponentRecord(componentsMap, tabName, componentName);
        },
        getElementProps(componentName: string): IFormElementComponentProps {
            return {
                formLayout: layoutContext.formLayout,
                formType: layoutContext.formType,
                elementProps: elementProps,
                record: getComponentRecord(componentsMap, tabName, componentName),
            };
        },
        getElementPropsByIndex(index: number): IFormElementComponentProps {
            return {
                formLayout: layoutContext.formLayout,
                formType: layoutContext.formType,
                elementProps: elementProps,
                record: componentsMap[index],
            };
        },
        hookForm: preparedElementProps.form,
    };
}
