import ReactSelect from 'components/common/react-select/ReactSelect';
import React from 'react';
import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import { Box } from '@mui/material';

export default function FilterControlChartingInterval({
    filter,
    onChange,
}: {
    filter: IFilterColumn;
    onChange: (newValue: any) => void;
}) {
    return (
        <Box flexGrow={1}>
            <ReactSelect
                placeholder={'All'}
                data={filter.chartingIntervals}
                update={(value) => {
                    onChange(value);
                }}
                value={filter.savedValues}
            />{' '}
        </Box>
    );
}
