import { Chart as HChart, PointOptionsObject } from 'highcharts';
import { SeriesOptionsType } from 'highcharts/highstock';
import { ChartSeriesType } from 'components/metric/chart/index';
import { findPointsInPeriod } from 'components/metric/chart/seriesBuilder';
import {
    AdditionalToDrawType,
    getSVGIconsGroup,
    PointToDrawType,
    RangeToDrawType,
} from 'components/metric/chart/draw/drawRanges';

import { ChartPointAlert } from 'components/metric/chart/series/getAlertSeries';
import { ChartSVGPointEvents } from 'components/metric/chart/MetricChartWrapper';

export const SVG_GROUP_ALERT_ICONS = 'dGroupAlertIcons';

export default function chartRedrawAlerts(
    chart: HChart,
    mainSeriesRef: any,
    additional: AdditionalToDrawType,
    svgIconsEvents?: ChartSVGPointEvents,
): AdditionalToDrawType {
    const alertSeries = chart.series.find((s) => {
        return (s.userOptions as SeriesOptionsType & ChartSeriesType).seriesType == 'alert';
    });

    if (!alertSeries || !mainSeriesRef.current) {
        return additional;
    }

    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_ALERT_ICONS);
    if (!alertSeries.visible) {
        //@ts-ignore
        iconsGroup.classList.add('d-none');
        return additional;
    } else {
        //@ts-ignore
        iconsGroup.classList.remove('d-none');
    }

    const rangeAlerts: Array<ChartPointAlert> = [];
    const regularAlerts: Array<PointToDrawType<ChartPointAlert>> = [];
    alertSeries.data.forEach((point) => {
        const options = point.options as PointOptionsObject & ChartPointAlert;

        const alertPoint = {
            ...point,
            metadata: options?.metadata ?? null,
            iconSvg: options.iconSvg,
            iconNumber: options.iconNumber,
        } as ChartPointAlert;

        let isRangeAlert = false;
        const rangeAlert = getAlertRangePoints(chart, alertPoint, []);
        if (rangeAlert) {
            const firstPoint = rangeAlert.firstPoint;
            const lastPoint = rangeAlert.lastPoint;

            firstPoint.plotX = chart.xAxis[0].toPixels(firstPoint.x, false);
            lastPoint.plotX = chart.xAxis[0].toPixels(lastPoint.x, false);

            if (!(lastPoint.plotX < chart.plotLeft || firstPoint.plotX > chart.plotLeft + chart.plotWidth)) {
                isRangeAlert = true;
            }
        }
        if (isRangeAlert) {
            rangeAlerts.push(alertPoint);
            return;
        }

        regularAlerts.push({
            type: 'alert',
            x: alertPoint.x,
            y: chart.yAxis[0].toValue((alertPoint?.plotY ?? 0) - alertPoint.iconNumber * 18),
            point: alertPoint,
        });
    });

    const addedRanges: Array<RangeToDrawType<ChartPointAlert>> = [];
    rangeAlerts.forEach((alertPoint) => {
        // TODO:
        //@ts-ignore
        const dataPoints = mainSeriesRef.current[0].data.concat(regularAlerts).map((d) => {
            let y = d.y;
            addedRanges.forEach((range) => {
                if (range.x <= d.x && range.x1 >= d.x) {
                    y = range.y > y ? range.y : y;
                }
            });
            return { ...d, y: y };
        });
        const rangeAlert = getAlertRangePoints(chart, alertPoint, dataPoints);
        if (!rangeAlert) {
            return;
        }
        const newY = chart.yAxis[0].toValue(chart.yAxis[0].toPixels(rangeAlert.maxY, false) - 12);

        addedRanges.push({
            type: 'alert',
            x: rangeAlert.firstPoint.x,
            x1: rangeAlert.lastPoint.x,
            y: newY,
            point: alertPoint,
            leftBorder: rangeAlert.drawLeftBorder,
            rightBorder: rangeAlert.drawRightBorder,
        });
    });

    return {
        points: regularAlerts,
        ranges: addedRanges,
    };
}

export const manageAlertSVG = (
    chart: HChart,
    alertPoint: ChartPointAlert,
    plotX: number,
    plotY: number,
    svgIconsEvents?: ChartSVGPointEvents,
) => {
    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_ALERT_ICONS);
    const pointId = `alert_${alertPoint.x}_${alertPoint.iconNumber}`;
    let svg = document.getElementById(pointId);

    const getEventPoint = (svg: HTMLElement): ChartPointAlert => {
        const x = Number(svg.getAttribute('x'));
        const y = Number(svg.getAttribute('y')) - 18;
        return { ...alertPoint, plotX: x, plotY: y } as ChartPointAlert;
    };

    if (!svg) {
        svg = alertPoint.iconSvg.cloneNode(true) as HTMLElement;
        //@ts-ignore
        iconsGroup.append(svg);
        svg.setAttribute('id', pointId);
        if (svgIconsEvents) {
            svg.addEventListener('mouseover', function (event: any) {
                svgIconsEvents.mouseOver('alert', getEventPoint(this));
            });
            svg.addEventListener('click', function () {
                svgIconsEvents.click('alert', getEventPoint(this));
            });
        }
    }

    if (isNaN(plotY)) {
        plotY = 0;
    }
    svg.setAttribute('x', String(plotX));
    svg.setAttribute('y', String(plotY));
};

function getAlertRangePoints(
    chart: HChart,
    alertPoint: ChartPointAlert,
    dataSeriesPoints: Array<{ x: number; y?: number }>,
) {
    let drawLeftBorder = true;
    let drawRightBorder = true;

    if (alertPoint.metadata?.range_start_time != null) {
        let points: any = [];
        let startPoint = alertPoint.metadata.range_start_time * 1000;
        const ext = chart.xAxis[0].getExtremes();

        if (!ext.userMin) {
            ext.userMin = 0;
        }
        if (!ext.userMax) {
            ext.userMax = 0;
        }

        if (ext.userMin > startPoint) {
            drawLeftBorder = false;
        }
        startPoint = ext.userMin > startPoint ? ext.userMin : startPoint;

        let endPoint = alertPoint.x;
        if (alertPoint.metadata.workflow_status_state != null) {
            if (alertPoint.metadata.workflow_status_state == 'closed' || alertPoint.metadata.issue_end_time != null) {
                if (alertPoint.metadata.issue_end_time != null) {
                    endPoint = parseInt(alertPoint.metadata.issue_end_time) * 1000;
                    if (ext.dataMax < endPoint || ext.userMax < endPoint) {
                        drawRightBorder = false;
                    }
                    endPoint = ext.dataMax < endPoint ? ext.dataMax : endPoint;
                    endPoint = ext.userMax < endPoint ? ext.userMax : endPoint;
                }
                if (alertPoint.metadata.range_start_time * 1000 != endPoint) {
                    points = findPointsInPeriod(chart, dataSeriesPoints, startPoint, endPoint);
                }
            } else if (alertPoint.metadata.workflow_status_state == 'open') {
                drawRightBorder = false;
                endPoint = ext.userMax < ext.dataMax ? ext.userMax : ext.dataMax;
                if (alertPoint.metadata.range_start_time * 1000 != endPoint) {
                    points = findPointsInPeriod(chart, dataSeriesPoints, startPoint, endPoint);
                }
            }
        } else {
            if (ext.userMax < endPoint) {
                drawRightBorder = false;
            }
            endPoint = ext.userMax < endPoint ? ext.userMax : endPoint;

            if (endPoint != startPoint) {
                points = findPointsInPeriod(chart, dataSeriesPoints, startPoint, endPoint);
            }
        }

        if (points.length) {
            const firstPoint = points[0];
            const lastPoint = points[points.length - 1];

            const maxY = chart.yAxis[0].toValue(
                Math.max(chart.yAxis[0].toPixels(firstPoint.y, false), chart.yAxis[0].toPixels(lastPoint.y, false)),
            );

            return { firstPoint, lastPoint, drawLeftBorder, drawRightBorder, maxY };
        }
    }

    return false;
}
