import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import { CustomFieldSection } from '../home-page';

export interface TopicsSelectValue {
    label: string;
    value: string;
}

export interface ReplaceTopicsData {
    topics_to_replace: Array<string | number>;
    repl_topic: string | number;
    delete_replaced: boolean;
}

export interface ReplaceTopicsResponse {
    status: 'OK' | 'ERROR';
}

export type TopicAssociations = {
    elements: {
        [key: string]: number;
    };
    entities: {
        [key: string]: number;
    };
    plugin: {
        [key: number]: number;
    };
    plugin_name: {
        [key: number]: string;
    };
};

export type TopicsInfoTopic = {
    topic_id: number;
    name: string;
    business_owner_dn: string | null;
    business_owner_email: string | null;
    technical_owner_dn: string | null;
    technical_owner_email: string | null;
    data_steward_dn: string | null;
    data_steward_email: string | null;
    associations: TopicAssociations;
    definition?: string;
    description: string;
    customFields: CustomFieldSection[];
};

export type TopicsInfo = {
    [key: string]: {
        topic_type_name: string;
        use_icon: string;
        icon_type: string | null;
        icon: string | null;
        fa_icon: string | null;
        topics: TopicsInfoTopic[];
    };
};

export interface TopicsAssociationsResponse {
    topicAssociations: TopicAssociations;
}

export const editorTopicsAPI = {
    async getTopicsData(url: string): Promise<Array<TopicsSelectValue>> {
        const response = await instance.get(url + `/topics`);

        return response.data;
    },
    async getTermsData(url: string): Promise<Array<TopicsSelectValue>> {
        const response = await instance.get(url + `/terms`);

        return response.data;
    },
    async replaceTopics(url: string, data: ReplaceTopicsData): Promise<ReplaceTopicsResponse> {
        const response = await instance.post(url + `/replace`, data);

        return response.data;
    },
    async getTopicsInfo(ids: number[] | string[]): Promise<APIResponse<TopicsInfo>> {
        const response = await instance.post('/data/editor/topic/get-info', { ids });

        return response.data;
    },
    async getTopicsAssociations(id: number): Promise<APIResponse<TopicsAssociationsResponse>> {
        const response = await instance.post('/data/editor/topic/get-topics-associations', { topicId: id });

        return response.data;
    },
};
