import useBundleTranslation from 'i18n';
import { DatasetInstance, InstanceMode } from '../index';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import React, { useEffect, useState } from 'react';
import { FormComponentValue } from 'components/common/form/layout/control';

function InstanceSelector({
    datasetInstances,
    currentInstance,
    setCurrentInstance,
    priorInstance,
    setPriorInstance,
    setInstanceMode,
    instanceMode,
}: {
    datasetInstances: DatasetInstance[];
    currentInstance: string;
    setCurrentInstance: (value: string) => void;
    priorInstance: string;
    setPriorInstance: (value: string) => void;
    instanceMode: InstanceMode;
    setInstanceMode: (value: InstanceMode) => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const [instanceSelectData, setInstanceSelectData] = useState<FormComponentValue[]>([]);
    const [currentInstanceData, setCurrentInstanceData] = useState<DatasetInstance | null>(null);
    const [priorInstanceData, setPriorInstanceData] = useState<DatasetInstance | null>(null);

    useEffect(() => {
        setInstanceSelectData(
            datasetInstances.map((instance) => {
                const value: FormComponentValue = {
                    value: instance.dataset_instance_id,
                    label: instance.measurement_time,
                };

                return value;
            }),
        );
    }, []);

    useEffect(() => {
        if (currentInstance > '') {
            const currentData = datasetInstances.find((item) => {
                return item.dataset_instance_id == currentInstance;
            });

            if (currentData) {
                setCurrentInstanceData(currentData);
            }
        } else {
            setCurrentInstanceData(null);
        }

        if (priorInstance > '') {
            const priorData = datasetInstances.find((item) => {
                return item.dataset_instance_id == priorInstance;
            });

            if (priorData) {
                setPriorInstanceData(priorData);
            }
        } else {
            setPriorInstanceData(null);
        }
    }, [currentInstance, priorInstance]);

    const onInstanceUpdate = (newValue: string) => {
        setCurrentInstance(newValue);
        const currentIndex = datasetInstances.findIndex((item) => {
            return item.dataset_instance_id === newValue;
        });

        if (currentIndex > -1) {
            if (currentIndex < datasetInstances.length) {
                const priorInstanceData = datasetInstances[currentIndex + 1];
                if (priorInstanceData) {
                    setPriorInstance(priorInstanceData.dataset_instance_id);
                } else {
                    setPriorInstance('');
                }
            }
        }
    };

    return currentInstance > '' ? (
        <Stack direction={'row'} sx={{ minHeight: '40px', maxHeight: '40px' }}>
            <RadioGroup
                value={instanceMode}
                name="instance-mode"
                row
                onChange={(event) => {
                    setInstanceMode(event.target.value as InstanceMode);
                }}
                sx={{ margin: 0 }}
            >
                <Stack direction={'row'} justifyContent="center" alignItems="center">
                    <FormControlLabel
                        value="current"
                        control={<Radio />}
                        label={t('instance_info.single')}
                        data-test={`single-instance-option`}
                    />
                    <Box sx={{ width: '250px', paddingRight: '10px' }} data-test={`instance-select`}>
                        <ReactSelect
                            data={instanceSelectData}
                            isLoading={false}
                            value={currentInstance}
                            update={onInstanceUpdate}
                        />
                    </Box>
                    <FormControlLabel
                        value="lastTwo"
                        control={<Radio />}
                        label={t('instance_info.last_two')}
                        data-test={`last-two-instances-option`}
                    />
                </Stack>
            </RadioGroup>
            <Stack direction={'column'} justifyContent="center" alignItems="center">
                {currentInstanceData && (
                    <Typography>{`${t('instance_info.current')} ${currentInstanceData.measurement_time}`}</Typography>
                )}
                {priorInstanceData && (
                    <Typography>{`${t('instance_info.prior')} ${priorInstanceData.measurement_time}`}</Typography>
                )}
            </Stack>
        </Stack>
    ) : null;
}

export default InstanceSelector;
