import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, IconButton, Snackbar, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import RatingComponent from './RatingComponent';
import { RatingComponentData, ratingPromptAPI } from 'api/rating';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';

function RatingIconButton({
    elementId,
    itemType,
    isActionMenuItem,
}: {
    elementId: string;
    itemType: 'page' | 'element';
    isActionMenuItem?: boolean;
}) {
    const { t } = useBundleTranslation('components/rating/rating');

    const [showPrompt, setShowPrompt] = useState(false);
    const [promptWasShown, setPromptWasShown] = useState(false);
    const [promptData, setPromptData] = useState<RatingComponentData>();
    const [expanded, setExpanded] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showButton, setShowButton] = useState(false);

    const hidePrompt = () => {
        setShowPrompt(false);
    };

    useEffect(() => {
        ratingPromptAPI.showGiveFeedbackButton(itemType, elementId).then((response) => {
            setShowButton(response.data);
        });
    }, []);

    const onShowRatingClick = () => {
        if (!showPrompt && !promptWasShown) {
            const anotherPrompt = document.getElementById(`rating-prompt-${itemType}-${elementId}`);

            if (anotherPrompt) {
                return;
            }
        }

        if (showPrompt) {
            setExpanded(true);
            return;
        }

        if (promptWasShown) {
            setShowInfoMessage(true);
            return;
        }

        ratingPromptAPI.getRatingComponentData(itemType, elementId, true).then((response) => {
            if (response.status === 'OK') {
                setShowPrompt(true);
                setPromptData(response.data);
                setPromptWasShown(true);
            }

            if (response.status === 'SKIP') {
                setShowPrompt(false);
                setPromptWasShown(true);
            }
        });
    };
    return showButton ? (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={t('sub_menu_label')}
                    desc={t('sub_menu_desc')}
                    icon="comment"
                    onClick={onShowRatingClick}
                />
            ) : (
                <Tooltip title={t('button_tooltip')}>
                    <IconButton sx={{ p: '6px' }} onClick={onShowRatingClick}>
                        <IconMi icon={'comment'} fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}
            {showPrompt && promptData && (
                <RatingComponent
                    itemType={itemType}
                    itemId={elementId}
                    showPrompt={showPrompt}
                    promptData={promptData}
                    hidePrompt={hidePrompt}
                    expanded={expanded}
                    setExpanded={setExpanded}
                />
            )}
            <Snackbar
                open={showInfoMessage}
                autoHideDuration={3000}
                onClose={() => {
                    setShowInfoMessage(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Alert severity="info">
                    <AlertTitle>{t('info_header')}</AlertTitle>
                    {t('info_submitted')}
                </Alert>
            </Snackbar>
        </>
    ) : null;
}

export default RatingIconButton;
