import React, { useContext, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, TextField } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import { Trans } from 'react-i18next';

export interface QueryBuilderAliasPopupProps {
    itemIndex: number;
    onClose: () => void;
    data: any; //strict
}

export default function QueryBuilderAliasPopup(props: QueryBuilderAliasPopupProps) {
    const { itemIndex, onClose = () => {}, data } = props;
    const { reportData, reportAct, queryData, configData, helperAct, pluginConfig } = useContext(PluginQBContext);

    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);

    const field = data[itemIndex].field;
    const isAdd = !field.alias;

    const [aliasValue, setAliasValue] = useState<string>(isAdd ? '' : helperAct.escapeHtml(field.alias));

    return (
        <Popup
            settings={{
                title: t('alias_popup.title'),
                textOK: t('alias_popup.save_btn'),
            }}
            maxWidth={'popupMd'}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                if (aliasValue.length && !/^[a-zA-Z0-9_]+$/.test(aliasValue)) {
                    alert(t('alias_popup.alert_invalid_alias'));
                    return false;
                }

                /*if (page.editor.report.hasAlias(alias, page.data.field.name)) {
                    alert('Alias "' + escapeHtml(alias) + '" already exists. Please choose another alias.');
                    return false;
                }*/
                reportAct.setAliasValue(field.name, aliasValue);
                onClose();
            }}
        >
            <Box>
                <Box>
                    <Trans
                        t={t}
                        i18nKey="alias_popup.note"
                        values={{
                            fieldName: helperAct.escapeHtml(field.name),
                        }}
                    >
                        placeholder_prefix
                        <Box component="span" sx={{ fontWeight: 600 }}>
                            placeholder_field_name
                        </Box>
                        placeholder_suffix
                    </Trans>
                </Box>
                <Box>
                    <TextField
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        autoFocus
                        value={aliasValue}
                        onChange={(event) => {
                            setAliasValue(event.target.value);
                        }}
                    />
                </Box>
            </Box>
        </Popup>
    );
}
