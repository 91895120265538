import { useQuery } from '@tanstack/react-query';
import { formAPI } from 'api/form';
import { useEffect, useState } from 'react';

export default function useMapData(externalId: number, elementId: number, mapId: number) {
    // Map Data
    const { data: firstColumnData } = useQuery<any | void, Error>({
        queryKey: ['firstColumn'],
        queryFn: () => {
            return formAPI
                .load(`/data/editor/external-reference-filter/get-filters/reference/${externalId}/element/${elementId}`)
                .then((response: any) => {
                    return response?.data?.data?.filters;
                });
        },
    });
    const [firstColumn, setFirstColumn] = useState([]);
    useEffect(() => {
        if (!firstColumnData) {
            return;
        }
        const result = firstColumnData.map((row: any) => ({
            label: row.display_name ?? row.name,
            value: row.external_filter_id,
        }));
        setFirstColumn(result);
    }, [firstColumnData]);

    const { data: secondColumnData } = useQuery<Array<any> | void, Error>({
        queryKey: ['secondColumn', mapId],
        queryFn: () => {
            //@ts-ignore
            if (mapId == '' || mapId == 0) {
                return [];
            }
            return formAPI.load(`/data/notification/filters-map/get-map-columns/${mapId}`).then((response: any) => {
                return response?.data?.data;
            });
        },
    });
    const [secondColumn, setSecondColumn] = useState([]);
    useEffect(() => {
        if (!secondColumnData) {
            return;
        }
        const result = secondColumnData.map((row: any) => ({ label: row.label, value: row.value }));
        //@ts-ignore
        setSecondColumn(result);
    }, [secondColumnData]);

    const isLoaded = firstColumnData && secondColumnData;
    return { firstColumn, secondColumn, isLoaded };
}
