import { Box, Typography } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import styles from '../styles';

export default function Description({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    return datasetInfo.description ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography>{datasetInfo.description}</Typography>
        </Box>
    ) : null;
}
