import { ColumnValueType, JoinFieldConfig, RulesConfig } from '../index';
import { FormComponentValue } from 'components/common/form/layout/control';
import { TFunction } from 'i18next';

export const stringifyFilter = function (filter: RulesConfig): string {
    const obj = { ...filter };

    return JSON.stringify(obj, null, 2);
};

export const getDefaultCondition = (fieldType: ColumnValueType) => {
    if (fieldType === 'text') {
        return textFieldConditions[0].value;
    }
    if (fieldType === 'numeric') {
        return numericFieldConditions[0].value;
    }
    if (fieldType === 'datetime') {
        return datetimeFieldConditions[0].value;
    }

    return 'equals';
};

export const textSecondFieldConditions: string[] = [
    'exactly matches',
    'contains',
    'does not contain',
    'does not equal',
    'starts with',
];

export const datetimeSecondFieldConditions: string[] = [
    'equals',
    'is greater than',
    'is greater than or equal to',
    'is less than',
    'is less than or equal to',
    'does not equal',
];

export const textLongDataConditions: string[] = ['is not in list', 'is in list'];

export const numericLongDataConditions: string[] = [
    'equals',
    'is greater than',
    'is greater than or equal to',
    'is less than',
    'is less than or equal to',
    'does not equal',
];

export const compareConditions: FormComponentValue[] = [
    {
        label: 'rule.percent',
        value: 'a percent',
    },
    {
        label: 'rule.value',
        value: 'a value',
    },
    {
        label: 'rule.any_amount',
        value: 'any amount',
    },
];

export function getTranslatedArray(values: FormComponentValue[], t: TFunction) {
    return values.map((item) => {
        return {
            label: t(item.label),
            value: item.value,
        };
    });
}

export const compareDateShifts: FormComponentValue[] = [
    {
        label: 'rule.hours',
        value: 'hour',
    },
    {
        label: 'rule.days',
        value: 'day',
    },
    {
        label: 'rule.weeks',
        value: 'week',
    },
    {
        label: 'rule.months',
        value: 'month',
    },
    {
        label: 'rule.years',
        value: 'year',
    },
];

export const textFieldConditions: FormComponentValue[] = [
    {
        label: 'rule.exactly_matches',
        value: 'exactly matches',
    },
    {
        label: 'rule.contains',
        value: 'contains',
    },
    {
        label: 'rule.not_contain',
        value: 'does not contain',
    },
    {
        label: 'rule.in_list',
        value: 'is in list',
    },
    {
        label: 'rule.not_in_list',
        value: 'is not in list',
    },
    {
        label: 'rule.not_equal',
        value: 'does not equal',
    },
    {
        label: 'rule.starts_with',
        value: 'starts with',
    },
    {
        label: 'rule.empty',
        value: 'is empty',
    },
    {
        label: 'rule.not_empty',
        value: 'is not empty',
    },
];

export const numericFieldConditions: FormComponentValue[] = [
    {
        label: 'rule.equals',
        value: 'equals',
    },
    {
        label: 'rule.greater_than',
        value: 'is greater than',
    },
    {
        label: 'rule.greater_than_or_equal',
        value: 'is greater than or equal to',
    },
    {
        label: 'rule.less_than',
        value: 'is less than',
    },
    {
        label: 'rule.less_than_or_equal',
        value: 'is less than or equal to',
    },
    {
        label: 'rule.not_equal',
        value: 'does not equal',
    },
    {
        label: 'rule.empty',
        value: 'is empty',
    },
    {
        label: 'rule.not_empty',
        value: 'is not empty',
    },
];

export const datetimeFieldConditions: FormComponentValue[] = [
    {
        label: 'rule.equals',
        value: 'equals',
    },
    {
        label: 'rule.greater_than',
        value: 'is greater than',
    },
    {
        label: 'rule.greater_than_or_equal',
        value: 'is greater than or equal to',
    },
    {
        label: 'rule.less_than',
        value: 'is less than',
    },
    {
        label: 'rule.less_than_or_equal',
        value: 'is less than or equal to',
    },
    {
        label: 'rule.not_equal',
        value: 'does not equal',
    },
    {
        label: 'rule.empty',
        value: 'is empty',
    },
    {
        label: 'rule.not_empty',
        value: 'is not empty',
    },
];

export interface TrackTabUpdateDTO {
    selectedTrackOptions: string[];
    joinFields: JoinFieldConfig[];
}
