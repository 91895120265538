import IconMi from 'components/common/icon/IconMi';
import { FormLabel, IconButton, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import Tooltip from '@mui/material/Tooltip';
import { ElementTarget } from '../index';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';

export default function TargetIcon({ targets }: { targets: ElementTarget[] | undefined }) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);
    const navigate = useNavigate();
    const [openPopup, setOpenPopup] = useState(false);
    const [targetId, setTargetId] = useState(targets?.[0]?.target_id);

    if (!targets || targets.length === 0) {
        return null;
    }

    const redirect = () => {
        if (targets.length === 1) {
            openTarget(targets[0].target_id);
        } else {
            setOpenPopup(true);
        }
    };

    const targetsData: FormComponentValue[] = useMemo(() => {
        return targets.map((target) => {
            return {
                label: target.name,
                value: String(target.target_id),
            };
        });
    }, [targets]);

    const openTarget = (id: number | undefined) => {
        navigate(`/editor/target/${id}`);
    };

    return (
        <>
            <Tooltip title={t('label_click_to_add_or_modify_target_values')}>
                <IconButton sx={{ p: '6px' }} onClick={redirect}>
                    <IconMi icon="target" fontSize="16" />
                </IconButton>
            </Tooltip>
            <Popup
                settings={{
                    title: t('open_target'),
                    textOK: t('open'),
                }}
                maxWidth="popupMd"
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                onConfirm={() => openTarget(targetId)}
            >
                <Stack direction={'column'}>
                    <FormLabel>{t('choose_target')}</FormLabel>
                    <ReactSelect
                        data={targetsData}
                        value={targetId}
                        update={(value) => {
                            setTargetId(value);
                        }}
                    />
                </Stack>
            </Popup>
        </>
    );
}
