import { instance } from './api';
import { APIResponse, YNValues } from 'tools/types';

export interface AnnouncementData {
    allow_edit_ind: YNValues;
    allow_to_dismiss_ind: YNValues;
    announcement_id: number;
    border_color: string;
    created_by_user_id: number;
    created_time: string;
    enabled_ind: YNValues;
    end_time: string;
    html_code: string;
    max_height: number;
    requires_approval_ind: YNValues;
    set_max_height_ind: YNValues;
    show_on_homepage_ind: YNValues;
    show_on_viewer_ind: YNValues;
    show_on_viewer_scope: string;
    start_time: string;
    subject: string;
    time_window_ind: YNValues;
    updated_time: string;
    visible_to: string;
}

export interface AnnouncementsResponse {
    status: 'OK' | 'ERROR';
    announcements: AnnouncementData[];
}
const GET_ANNOUNCEMENTS_URL = `data/content/index/announcements/element/{elementId}`;
const DISMISS_ANNOUNCEMENT_URL = `/data/element/announcement/{announcementId}/dismiss`;
const ACCEPT_ANNOUNCEMENT_URL = `/data/element/announcement/{announcementId}/accept`;
const SUPPRESS_ANNOUNCEMENT_URL = `/data/editor/announcement/{announcementId}/suppress/{elementId}`;

export const announcementAPI = {
    async getAnnouncementsData(announcementId: number): Promise<AnnouncementsResponse> {
        const url = GET_ANNOUNCEMENTS_URL.replace(`{elementId}`, announcementId.toString());
        const response = await instance.get(url);

        return response.data;
    },
    async dismiss(announcementId: number): Promise<APIResponse<any>> {
        const url = DISMISS_ANNOUNCEMENT_URL.replace(`{announcementId}`, announcementId.toString());
        const response = await instance.post(url);

        return response.data;
    },
    async accept(announcementId: number): Promise<APIResponse<any>> {
        const url = ACCEPT_ANNOUNCEMENT_URL.replace(`{announcementId}`, announcementId.toString());
        const response = await instance.post(url);

        return response.data;
    },
    async suppress(announcementId: number, elementId: number): Promise<APIResponse<any>> {
        const url = SUPPRESS_ANNOUNCEMENT_URL.replace(`{announcementId}`, announcementId.toString()).replace(
            `{elementId}`,
            elementId.toString(),
        );
        const response = await instance.post(url);

        return response.data;
    },
};
