import { FormControlProps, FormElementControlPropsType } from './index';
import { FormComponentBuilder } from 'components/common/form/layout/index';
import { useState } from 'react';

export default function FormComponentSlot({ controlProps }: FormElementControlPropsType<FormControlProps>) {
    return <div id={controlProps.name}></div>;
}

export class FormComponentSlotBuilder extends FormComponentBuilder {
    prepareProps(): FormControlProps {
        return this.controlProps;
    }
}

export function useFormSlot(id: string) {
    const [slot, setSlot] = useState<HTMLElement>();
    const el = document.getElementById(id);
    if (el && !slot) {
        setSlot(el);
    }
    return slot;
}
