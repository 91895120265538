import { TFunction } from 'i18next';
import { Box, Button } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import React, { useState } from 'react';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { ReportContentNS } from 'components/report-content';
import ContentSettings = ReportContentNS.ContentSettings;
import IconMi from 'components/common/icon/IconMi';

const DefaultWidth = 900;
const DefaultHeight = 600;

export default function GetEmbedCode({
    blockUID,
    t,
    contentSettings,
    height,
}: {
    blockUID: string;
    t: TFunction;
    contentSettings: ContentSettings;
    height?: number;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const segmentId = contentSettings.segmentValueId > 0 ? `/segment/${contentSettings.segmentValueId}` : '';
    const actualHeight = height ?? DefaultHeight;

    const url =
        window.location.origin +
        `/report/${contentSettings.elementId}${segmentId}/preview?view_type=tile&block_uid=${blockUID}&width=${DefaultWidth}&height=${actualHeight}`;
    const code = `<iframe style="width:${DefaultWidth}px; height:${actualHeight}px; border: 0px none;" frameBorder="0" src="${url}"></iframe>`;
    const elementProps = useCustomSimplifiedForm({ code: code });

    return (
        <Box>
            <Button
                startIcon={<IconMi icon="code" />}
                onClick={() => {
                    elementProps.form.hookFormSetValue('code', code);
                    setIsOpen(true);
                }}
                variant={'outlined'}
                data-test={`get-embed-code-button`}
            >
                {t('get_embed_code')}
            </Button>
            <Popup
                settings={{ title: t('embed_code'), textOK: t('copy_code') }}
                open={isOpen}
                onHide={() => setIsOpen(false)}
                onConfirm={() => {
                    navigator.clipboard.writeText(elementProps.form.hookFormGetValues('code'));
                    setIsOpen(false);
                }}
                maxWidth="popupMd"
            >
                <TemplateFormComponent
                    elementProps={elementProps}
                    component={'FormTextarea'}
                    name={'code'}
                    props={{ label: '' }}
                />
            </Popup>
        </Box>
    );
}
