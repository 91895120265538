import { FormElementControlPropsType, FormControlProps } from './index';
import React from 'react';
import { FormComponentBuilder } from 'components/common/form/layout/index';

export interface FormControlImagePreviewProps extends FormControlProps {
    source?: string;
}

export default function FormImagePreview({ controlProps }: FormElementControlPropsType<FormControlImagePreviewProps>) {
    const source =
        controlProps.source && controlProps.source !== '$portal_page_template_id.$option.src'
            ? controlProps.source
            : '';

    return source > '' ? (
        <img style={{ maxWidth: '500px' }} id="preview_template_image" src={source} alt={'template'} />
    ) : null;
}

export class FormImagePreviewBuilder extends FormComponentBuilder {
    prepareProps(): FormControlImagePreviewProps {
        return {
            ...this.controlProps,
            source: this.elementProps.componentProps?.source,
        };
    }
}
