import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { editorAPI } from 'api/editor';
import { FormControl, FormLabel, Grid } from '@mui/material';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { FormComponentValue, prepareFormComponentValues } from 'components/common/form/layout/control';
import { ISubstitutionFormProps } from 'components/common/form/data-fetch-command/substitution/index';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

export default function SubstitutionSegmentValueField({
    formApi,
    segmentId,
    mode,
}: ISubstitutionFormProps & { segmentId: number }) {
    const { t } = useBundleTranslation();
    useEffect(() => {
        const defaultValue = formApi.form.hookFormGetValues('segment_value_id');
        if (typeof defaultValue == 'undefined' || defaultValue == '') {
            formApi.form.hookFormSetValue('segment_value_id', mode == 'collect' ? '-1' : '0');
        }
        formApi.form.hookFormSetValue('segment_id', segmentId);
    }, []);

    const { data: segmentValuesListRaw, isLoading } = useQuery<Array<any>, Error>({
        queryKey: ['getSegmentValues_substitution', segmentId],
        queryFn: () => editorAPI.getSegmentValuesList(segmentId),
    });

    const [values, setValues] = useState<Array<FormComponentValue>>([]);
    useEffect(() => {
        const result = prepareFormComponentValues(segmentValuesListRaw);
        if (mode == 'collect') {
            result.unshift({ label: t('all_values'), value: '-1' });
        }
        setValues(result);
    }, [segmentValuesListRaw]);

    useEffect(() => {
        if (mode == 'validate' && formApi.form.hookFormGetValues('segment_value_id') == '0' && values.length) {
            formApi.form.hookFormSetValue('segment_value_id', values[0].value);
        }
    }, [values]);

    return (
        <Grid item xs={12}>
            {isLoading && <LoadingPlaceholder />}
            <FormControl fullWidth>
                <FormLabel>
                    {t('dimension')} {t('value')}
                </FormLabel>
                <TemplateFormComponent
                    componentValues={values}
                    component={'FormSelect'}
                    elementProps={formApi}
                    name={'segment_value_id'}
                />
            </FormControl>
        </Grid>
    );
}
