import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { CustomCellProps, DataChange, HistoryChangeName } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import { Link } from 'react-router-dom';
import { getIcon } from './index';

export default function HistoryCell({ changes, icon, align }: CustomCellProps) {
    const getName = (name: string | HistoryChangeName) => {
        if (typeof name === 'string') {
            return name;
        }

        if (name.href) {
            return name.newTab ? (
                <Box
                    component="a"
                    href={name.href}
                    target={'_blank'}
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                    {getIcon(icon, name.text ? { mr: 0.5 } : undefined)} {name.text}
                </Box>
            ) : (
                <Box component={Link} to={name.href} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    {getIcon(icon, name.text ? { mr: 0.5 } : undefined)} {name.text}
                </Box>
            );
        }

        return name.text;
    };

    const getChangeContent = (change: DataChange, index: number) => {
        return (
            <Stack
                direction={'row'}
                spacing={1}
                key={`change-cell-row-${index}`}
                sx={{ display: 'inline-flex', alignItems: 'center', maxWidth: '100%' }}
            >
                {change.name > '' ? (
                    <Typography textOverflow={'ellipsis'} overflow={'hidden'} flexShrink={0}>
                        <b>
                            {getName(change.name)}
                            {change.name === '' ? '' : ':'}
                        </b>
                    </Typography>
                ) : null}
                {change.old > '' ? (
                    <Typography
                        sx={{ textDecoration: 'line-through' }}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        className={'history-value'}
                    >
                        {change.old}
                    </Typography>
                ) : null}
                {change.old && change.new && <IconMi icon={'replace'} />}
                <Typography textOverflow={'ellipsis'} overflow={'hidden'} className={'history-value'}>
                    {change.new}
                </Typography>
            </Stack>
        );
    };

    return (
        changes && (
            <Typography noWrap align={align} sx={{ py: 1 }}>
                <Stack>
                    {changes.map((change, index) => {
                        const content = getChangeContent(change, index);
                        return (
                            <Tooltip
                                title={content}
                                slotProps={{
                                    tooltip: {
                                        sx: { maxWidth: '600px', '& .history-value': { wordWrap: 'break-word' } },
                                    },
                                }}
                            >
                                {content}
                            </Tooltip>
                        );
                    })}
                </Stack>
            </Typography>
        )
    );
}
