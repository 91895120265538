import { FormRendererAPIType } from 'components/common/form';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import React, { useEffect, useRef, useState } from 'react';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import useBookmarks from 'components/external-reference/hooks/useBookmarks';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import { ElementViewerDataType, prepareSegments } from 'components/element-viewer';
import { FilterType, useApplyFilterValueMap } from 'components/external-reference/Filter';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { Box, FormControl, FormLabel, Grid, Stack } from '@mui/material';
import ReactHookFormController, { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import BookmarkManager from 'components/external-reference/bookmark/BookmarManager';
import { prepareFormElementProps } from 'components/common/form/formTools';
import MapDataForm from 'components/common/grid/cell/burst-filter-cell/MapDataForm';
import useMapData from 'components/common/grid/cell/burst-filter-cell/useMapData';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';

export default function FiltersForm({
    defaultData,
    formValues,
    elementId,
    segmentValueId,
    externalId,
    filtersRequestData,
    segmentData,
    setForm,
    itemId,
    hasFilters,
    multipleRefsLabel,
    tabsData,
}: {
    defaultData: any;
    formValues: any;
    elementId: number;
    segmentValueId: number;
    externalId: number;
    filtersRequestData: any;
    segmentData: Array<any>;
    setForm: (form: FormRendererAPIType) => void;
    itemId: number;
    hasFilters: boolean;
    multipleRefsLabel?: string;
    tabsData: Array<SiblingTabType>;
}) {
    const { t } = useBundleTranslation(['components/notification/burst']);
    const elementProps = useCustomSimplifiedForm(defaultData);

    const overrideInd = elementProps.form.hookFormWatch('override_external_filters_ind') == 'Y';
    const worksheetsInd = elementProps.form.hookFormWatch('override_external_filters_ind') == 'Refs';
    const externalMethod = elementProps.form.hookFormWatch('external_filters_usage_method');
    const mapId = elementProps.form.hookFormWatch('external_filters_user_map_id');

    const [selectedBookmark, setSelectedBookmark] = useState<BookmarkType | undefined>();
    const {
        bookmarks,
        APIUpdateBookmark,
        APIDeleteBookmark,
        APIDuplicateBookmark,
        APISortBookmarks,
        filtersData,
        APIIsLoading: isLoading,
    } = useBookmarks(
        { filtersData: filtersRequestData } as ExternalReportViewerDataType,
        { elementData: { row: { elementId: elementId } }, segmentData: segmentData } as ElementViewerDataType,
        useApplyFilterValueMap(prepareSegments(filtersRequestData.data.filtersList.slice(), segmentData).filtersList),
        [],
        undefined,
        defaultData['notification_schedule_distribution_item_id'],
    );

    const [filters, setFilters] = useState<Array<FilterType>>([]);
    useEffect(() => {
        const { filtersList } = prepareSegments(filtersData?.filtersList ?? [], segmentData);
        setFilters(useApplyFilterValueMap(filtersList.slice()));
    }, [filtersData?.filtersList]);

    const { firstColumn, secondColumn, isLoaded } = useMapData(externalId, elementId, mapId);
    const [mapActualState, setMapActualState] = useState(
        formValues?.map_data?.map((r: any) => ({
            firstColumn: r.filterColumn,
            secondColumn: r.mapColumn,
            data: r?.data ?? null,
        })) ?? [],
    );

    const [formReady, setFormReady] = useState(false);
    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                setForm(elementProps.form);
                setFormReady(true);
            }, 100);
        }
    }, [isLoaded]);

    const [mapData, setMapData] = useState<any>(null);
    const mapDataRef = useRef<(data: any) => void>();
    const [mapFormOpen, setMapFormOpen] = useState(false);
    const handleGearClick = (data: any, setData: (data: any) => void) => {
        setMapData(data);
        mapDataRef.current = setData;
        setMapFormOpen(true);
    };

    return (
        <>
            {!formReady && <LoadingPlaceholder />}
            {mapFormOpen && (
                <MapDataForm
                    isOpen={mapFormOpen}
                    mapData={mapData}
                    mapDataCallback={(data) => {
                        //@ts-ignore
                        mapDataRef.current(data);
                        setMapFormOpen(false);
                    }}
                />
            )}
            <Stack spacing={2}>
                <Box sx={{ marginTop: '17px;' }}>
                    <FormControl focused={false}>
                        <FormLabel>{t('filter.filter_settings')}</FormLabel>
                        <TemplateFormComponent
                            props={{ row: false }}
                            elementProps={elementProps}
                            component={'FormRadioGroup'}
                            componentValues={[
                                { label: t('filter.use_viewing'), value: 'N' },
                                { label: t('filter.custom_burst'), value: 'Y', hidden: !hasFilters },
                                {
                                    label: multipleRefsLabel
                                        ? t('filter.selected_refs', { param: multipleRefsLabel })
                                        : '',
                                    value: 'Refs',
                                    hidden: !multipleRefsLabel,
                                },
                            ]}
                            name={'override_external_filters_ind'}
                        />
                    </FormControl>
                </Box>

                <Stack className={overrideInd ? '' : 'd-none'}>
                    <Grid container>
                        <Grid xs={3}>
                            <FormControl focused={false}>
                                <FormLabel>{t('filter.filters_are')}</FormLabel>
                                <TemplateFormComponent
                                    elementProps={elementProps}
                                    component={'FormRadioGroup'}
                                    componentValues={[
                                        { label: t('manual'), value: 'standard' },
                                        { label: t('user_map'), value: 'user map' },
                                    ]}
                                    name={'external_filters_usage_method'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6} className={externalMethod == 'user map' ? '' : 'd-none'}>
                            <FormControl fullWidth>
                                <FormLabel>{t('user_map')}</FormLabel>
                                <TemplateFormComponent
                                    elementProps={elementProps}
                                    component={'FormSelect'}
                                    componentValues={formValues.external_filters_user_map_id}
                                    name={'external_filters_user_map_id'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'user map' ? '' : 'd-none'}>
                        <>
                            {isLoaded ? (
                                <TemplateFormComponent
                                    name={'map-data'}
                                    elementProps={elementProps}
                                    component={'SelectMap'}
                                    props={{
                                        data: {
                                            handleGearClick: handleGearClick,
                                            uniqueRule: 'both',
                                            firstColumn: {
                                                label: t('filter.filter'),
                                                source: {
                                                    type: 'manual',
                                                    manualData: firstColumn,
                                                },
                                            },
                                            secondColumn: {
                                                label: t('filter.user_map_column'),
                                                source: {
                                                    type: 'manual',
                                                    manualData: secondColumn,
                                                },
                                            },
                                            actualValues: mapActualState,
                                        },
                                    }}
                                />
                            ) : (
                                <LoadingPlaceholder />
                            )}
                        </>
                    </Box>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'standard' ? '' : 'd-none'}>
                        <BookmarkManager
                            managerContextType={'combination'}
                            setOnEditMode={() => {}}
                            bookmarkForEdit={selectedBookmark}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            bookmarks={bookmarks.filter((b) => b.bookmarkType == 'combination')}
                            filters={filters}
                            siblingTabs={[]}
                            onDeleteClick={APIDeleteBookmark}
                            onDuplicateClick={APIDuplicateBookmark}
                            onSortEnd={APISortBookmarks}
                            onUpdateClick={(bookmark: BookmarkType) => {
                                APIUpdateBookmark(bookmark, 0);
                                if (selectedBookmark) {
                                    //handleHideManager();
                                }
                            }}
                        />
                    </Box>

                    <Box sx={{ marginTop: '17px;' }}>
                        <FormControl focused={false}>
                            <FormLabel>{t('filter.images')}</FormLabel>
                            <TemplateFormComponent
                                elementProps={elementProps}
                                component={'FormRadioGroup'}
                                componentValues={[
                                    { label: t('filter.viewer'), value: 'viewer' },
                                    { label: t('filter.external'), value: 'external' },
                                ]}
                                name={'image_link_to'}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'user map' ? '' : 'd-none'}>
                        <FormControl>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    inputFilter: 'int',
                                    component: {
                                        uid: 'max_images_in_email',
                                        name: 'max_images_in_email',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.max_inst'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Box>
                </Stack>

                <Stack className={worksheetsInd ? '' : 'd-none'}>
                    <Box sx={{ marginTop: '17px;' }}>
                        <BookmarkManager
                            managerContextType={'reference'}
                            setOnEditMode={() => {}}
                            bookmarkForEdit={selectedBookmark}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            bookmarks={bookmarks.filter((b) => b.bookmarkType == 'reference')}
                            filters={[]}
                            siblingTabs={tabsData}
                            onDeleteClick={APIDeleteBookmark}
                            onDuplicateClick={APIDuplicateBookmark}
                            onSortEnd={APISortBookmarks}
                            onUpdateClick={(bookmark: BookmarkType) => {
                                if (bookmark.bookmarkData.length != 0) {
                                    APIUpdateBookmark(bookmark, 0);
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </Stack>
        </>
    );
}
