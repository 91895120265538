import { FormDataAPIType } from 'components/common/form/index';
import { useFormState } from 'react-hook-form';
import { Button, Container } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';

export default function FormOnPageSaveButton({ hookForm }: { hookForm: FormDataAPIType }) {
    const { isDirty } = useFormState({ control: hookForm.form.hookFormControl });
    const label = (hookForm.form.formTranslation && hookForm.form.formTranslation('next_define_details', {})) ?? '';
    return (
        <Container maxWidth={'sm'} disableGutters={true}>
            <Button type="submit" variant={isDirty ? 'contained' : 'outlined'} endIcon={<IconMi icon="arrow-right" />}>
                {label}
            </Button>
        </Container>
    );
}
