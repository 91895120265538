import { createSlice } from '@reduxjs/toolkit';

interface rebuildUserAccessSliceState {
    showPopup: boolean;
}

export const rebuildUserAccessSlice = createSlice({
    name: 'rebuildUA',
    initialState: {
        showPopup: false,
    } as rebuildUserAccessSliceState,
    reducers: {
        show: (state) => {
            state.showPopup = true;
        },
        hide: (state) => {
            state.showPopup = false;
        },
    },
});

export const { show, hide } = rebuildUserAccessSlice.actions;

export default rebuildUserAccessSlice.reducer;
