import { DashboardElementType } from 'tools/types';

export namespace ElementTypeNS {
    export function getIconNameByElementType(type: DashboardElementType): string {
        let icon = 'multi-metric';
        switch (type) {
            case 'external report':
                icon = 'external-report';
                break;
            case 'internal report':
                icon = 'report';
                break;
            case 'metric':
                icon = 'metric';
                break;
            case 'multi-metric chart':
                icon = 'multi-metric';
                break;
            case 'other external content':
                icon = 'external-content';
                break;
        }
        return icon;
    }

    export function getPathByElementType(type: DashboardElementType): string {
        switch (type) {
            case 'external report':
                return 'extreport';
            case 'internal report':
                return 'report';
            case 'metric':
            case 'multi-metric chart':
                return 'chart';
        }
        return 'extcontent';
    }

    export function getElementTypeLabel(type: DashboardElementType): string {
        switch (type) {
            case 'metric':
                return 'metric';
            case 'multi-metric chart':
                return 'multi_metric';
            case 'internal report':
                return 'internal_report';
            case 'external report':
                return 'external_report';
            case 'other external content':
                return 'external_content';
            default:
                return type;
        }
    }
}
