import { alpha, Box, FormControl, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { AlertRuleScreenProps } from 'components/alert-rule-popup/index';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Trans, useTranslation } from 'react-i18next';
import formatValue from 'tools/formatValue';
import filterOnKeyDown, { filterOnKeyDownType } from 'tools/filterInputValue';

interface AlertRuleCriteriaProps extends AlertRuleScreenProps {
    onChange?: (text: any) => void;
}

export const getAlertConditionText = (params: any, t: any) => {
    let html = [];

    if ('fixed value' == params.alertType && 'fixed' == params.comparisonType) {
        let key = 'default';
        if (['above', 'above or equal', 'below or equal', 'below', 'equal'].includes(params.pctComparisonType)) {
            key = params.pctComparisonType.replaceAll(' ', '_');
        }
        html.push(
            <>
                {' '}
                <Box component="span" color="error.main">
                    {t('alert_criteria_text.pct_comparison_type.' + key)} {formatValue(params.fixedVal)}
                </Box>
            </>,
        );
    } else if ('stoplight' == params.alertType) {
        html.push(
            <>
                {' '}
                <Box component="span" color="error.main">
                    {t('alert_criteria_text.stoplight.')}
                </Box>
            </>,
        );
    } else if ('compound' == params.alertType) {
        const arr = [];
        if (typeof params.selected_alerts_for_compound != 'undefined') {
            for (let i in params.selected_alerts_for_compound) {
                if (params.selected_alerts_for_compound.hasOwnProperty(i))
                    arr.push(params.selected_alerts_for_compound[i]);
            }
        }
        if (arr.length > 0) {
            html.push(
                <>
                    {': '}
                    <Box component="span" color="error.main">
                        {arr.join(', ')}
                    </Box>
                </>,
            );
        }
    } else {
        let key = 'another_variant';
        if (['within', 'between'].includes(params.pctComparisonType)) {
            key = params.pctComparisonType.replaceAll(' ', '_');
        } else if (['less fixed', 'less pct variance'].includes(params.comparisonType)) {
            key = params.comparisonType.replaceAll(' ', '_');
        }
        const part_1 = t('alert_criteria_text.alert_type_other.' + key);

        let part_2 = '';
        if (['fixed', 'less fixed'].includes(params.comparisonType)) {
            part_2 = ' ' + formatValue(params.fixedVal);
            part_2 +=
                'between' == params.pctComparisonType
                    ? t('alert_criteria_text.part_2', { fixedVal2: formatValue(params.fixedVal2) })
                    : '';

            const isPluration =
                (params.fixedVal == 1 && 'between' != params.pctComparisonType) ||
                ('between' == params.pctComparisonType && params.fixedVal2 == 1)
                    ? false
                    : true;
            part_2 +=
                'volatility band' == params.alertType && formatValue(params.fixedVal)
                    ? ('mr' == params.unusualValueDetectionMethod
                          ? t('alert_criteria_text.part_2.moving_range', { count: isPluration ? 2 : 1 })
                          : t('alert_criteria_text.part_2.standard_deviation', { count: isPluration ? 2 : 1 })) +
                      ' ' +
                      ('within' == params.pctComparisonType ? t('alert_criteria_text.part_2.of_part') : '')
                    : '';
        } else {
            part_2 = ' ' + params.pctVal + '%';
        }

        const part_3 =
            'within' == params.pctComparisonType || 'between' == params.pctComparisonType
                ? ''
                : ' ' +
                  t('form.criteria.field_value.pct_comparison_type.' + params.pctComparisonType.replaceAll(' ', '_'));

        html.push(
            <>
                {' '}
                <Box component="span" color="error.main">
                    {part_1}
                    {part_2}
                    {part_3}
                </Box>
            </>,
        );

        if ('fixed value' == params.alertType) {
            html.push(
                <>
                    {' '}
                    <Box component="span" color="error.main">
                        {formatValue(params.fixedVal)}
                    </Box>
                </>,
            );
        } else if ('prior value' == params.alertType) {
            html.push(
                <Trans
                    t={t}
                    i18nKey="alert_criteria_text.prior_value"
                    values={{
                        compareLineText: params.compareLineText,
                    }}
                >
                    placeholder_prefix
                    <Box component="span" color="error.main">
                        placeholder_red
                    </Box>
                </Trans>,
            );
        } else if ('average' == params.alertType || 'volatility band' == params.alertType) {
            if (
                (params.mi_interval_unit == 'hour' && params.comparisonIntervalCompareToPeriod != 'hour') ||
                (params.mi_interval_unit == 'day' && params.comparisonIntervalCompareToPeriod != 'day')
            ) {
                html.push(
                    <Trans
                        t={t}
                        i18nKey="alert_criteria_text.volatility_band"
                        values={{
                            comparisonIntervalCompareTo: params.comparisonIntervalCompareTo,
                            comparisonIntervalCompareToPeriod: params.comparisonIntervalCompareToPeriod,
                        }}
                    >
                        placeholder_prefix
                        <Box component="span" color="error.main">
                            placeholder_red
                        </Box>
                        placeholder_middle
                        <Box component="span" color="error.main">
                            placeholder_red
                        </Box>
                        placeholder_suffix
                    </Trans>,
                );
            } else {
                html.push(<>{t('alert_criteria_text.average_else')}</>);
            }
            html.push(
                <>
                    <Box component="span" color="error.main">
                        {t('alert_criteria_text.comparison_interval', {
                            comparisonInterval: parseInt(
                                params.comparison_interval != '' ? params.comparison_interval : 0,
                                10,
                            ),
                            interval: t('form.criteria.field_value.mi_intervals.' + params.comparison_interval_period, {
                                count: params.comparison_interval == 1 ? 1 : 2,
                            }),
                        })}
                    </Box>
                </>,
            );
        } else if ('target' == params.alertType) {
            html.push(
                <>
                    {' '}
                    <Box component="span" color="error.main">
                        {params.targetText}
                    </Box>
                </>,
            );
        }
    }

    if (parseInt(params.frequently_condition_values, 10) == parseInt(params.frequently_condition_satisfied, 10)) {
        if (parseInt(params.frequently_condition_satisfied, 10) > 1) {
            html.push(
                <Trans
                    t={t}
                    i18nKey="alert_criteria_text.condition_values_equal_satisfied"
                    values={{
                        frequentlyConditionSatisfied: params.frequently_condition_satisfied,
                    }}
                >
                    placeholder_prefix
                    <Box component="span" color="error.main">
                        placeholder_red
                    </Box>
                    placeholder_suffix
                </Trans>,
            );
        }
    } else if (params.frequently_condition_satisfied > 1) {
        html.push(
            <Trans
                t={t}
                i18nKey="alert_criteria_text.condition_satisfied"
                count={params.frequently_condition_values == 1 ? 1 : 2}
                values={{
                    frequentlyConditionSatisfied: params.frequently_condition_satisfied,
                    frequentlyConditionValues: params.frequently_condition_values,
                }}
            >
                placeholder_prefix
                <Box component="span" color="error.main">
                    placeholder_red
                </Box>
                placeholder_suffix
            </Trans>,
        );
    }

    return html.map((item, index) => {
        return <React.Fragment key={index}>{item}</React.Fragment>;
    });
};

export default function AlertRuleCriteria(props: AlertRuleCriteriaProps) {
    const {
        alertRuleData,
        wizardData,
        updateAlertRuleData = () => {},
        updateWizardData = () => {},
        onChange = () => {},
    } = props;

    const { t } = useTranslation(['components/alert-rule-popup/alert_rule_popup']);
    const elementProps: FormDataAPIType = useCustomSimplifiedForm(alertRuleData);
    const [miIntervals, setMiIntervals] = useState<string[]>([]);

    const [watchTextFields, setWatchTextFields] = useState<string[]>([]);
    const [subscription, setSubscription] = useState<any>();

    const format_float = (val: any) => {
        //ToDo Migrate to Js or get One more prop for data with format
        // Custom_Model_Functions::format_float($this->form->comparison_fixed_value->getValue())
        return val;
    };

    useEffect(() => {
        //Set comparison_fixed_value
        const cfVal = alertRuleData.comparison_fixed_value;
        let newVal = wizardData.isNew && !alertRuleData.comparison_fixed_value ? '' : format_float(cfVal);
        updateAlertRuleData({ comparison_fixed_value: newVal });

        //Set comparison_fixed_value_2
        const cfVal2 = alertRuleData.comparison_fixed_value_2;
        let newVal2 = wizardData.isNew && !alertRuleData.comparison_fixed_value_2 ? '' : format_float(cfVal2);
        updateAlertRuleData({ comparison_fixed_value_2: newVal2 });

        //Set $mi (miIntervals {})
        if (!wizardData.dashboardElement.mi_interval_unit) {
            updateWizardData({ dashboardElement: { ...wizardData.dashboardElement, mi_interval_unit: 'hour' } });
            if (wizardData.isGlobal) {
                setMiIntervals([...miIntervals, 'hour', 'day', 'week', 'month', 'quarter', 'year']);
            }
        }

        const tmpIntervals = ['day', 'week', 'month', 'quarter', 'year'];
        if (tmpIntervals.includes(wizardData.dashboardElement.mi_interval_unit)) {
            const indexFrom = tmpIntervals.indexOf(wizardData.dashboardElement.mi_interval_unit);
            setMiIntervals(tmpIntervals.slice(indexFrom));
        } else {
            if (wizardData.dashboardElement.mi_interval_unit == 'hour') {
                setMiIntervals(['day']);
            } else {
                setMiIntervals(['hour', ...miIntervals]);
            }
        }

        //set formEl.find('#comparison_type_fixed')
        //set formEl.find('#comparison_type_fixed').val(
        if ('fixed value' == alertRuleData.alert_type) {
            if (alertRuleData.comparison_type == 'fixed' && alertRuleData.pct_comparison_type == 'above') {
                updateAlertRuleData({ comparison_type_fixed: 'more than a value' });
            } else if (
                alertRuleData.comparison_type == 'fixed' &&
                alertRuleData.pct_comparison_type == 'above or equal'
            ) {
                updateAlertRuleData({ comparison_type_fixed: 'more than or equal to a value' });
            } else if (alertRuleData.comparison_type == 'fixed' && alertRuleData.pct_comparison_type == 'below') {
                updateAlertRuleData({ comparison_type_fixed: 'less than a value' });
            } else if (
                alertRuleData.comparison_type == 'fixed' &&
                alertRuleData.pct_comparison_type == 'below or equal'
            ) {
                updateAlertRuleData({ comparison_type_fixed: 'less than or equal to a value' });
            } else if (
                alertRuleData.comparison_type == 'fixed' &&
                alertRuleData.pct_comparison_type == 'above or below'
            ) {
                updateAlertRuleData({ comparison_type_fixed: 'not equal to a value' });
            } else if (alertRuleData.comparison_type == 'fixed' && alertRuleData.pct_comparison_type == 'equal') {
                updateAlertRuleData({ comparison_type_fixed: 'equal' });
            } else if (alertRuleData.comparison_type == 'pct variance') {
                updateAlertRuleData({ comparison_type_fixed: 'percentage of a value' });
            }
        } else if ('volatility band' == alertRuleData.alert_type) {
            updateAlertRuleData({ comparison_type: 'fixed' });
        } else if ('compound' == alertRuleData.alert_type) {
            updateAlertRuleData({ comparison_type: 'fixed', pct_comparison_type: 'above' });
        }

        //set comparison_fixed_value, comparison_fixed_value_2, comparison_interval, comparison_pct_tolerance
        let fixedVal: any = String(alertRuleData.comparison_fixed_value);
        fixedVal = isNaN(parseFloat(fixedVal)) ? 0 : parseFloat(fixedVal);

        let fixedVal2: any = String(alertRuleData.comparison_fixed_value_2);
        fixedVal2 = isNaN(parseFloat(fixedVal2)) ? 0 : parseFloat(fixedVal2);

        let comparison_interval: any = String(alertRuleData.comparison_interval);
        comparison_interval = isNaN(parseFloat(comparison_interval)) ? 0 : parseFloat(comparison_interval);

        let comparison_pct: any = String(alertRuleData.comparison_pct_tolerance);
        comparison_pct = isNaN(parseFloat(comparison_pct)) ? 0 : parseFloat(comparison_pct);

        updateAlertRuleData({
            comparison_fixed_value:
                fixedVal == 0 ? ('between' == alertRuleData.pct_comparison_type ? 0 : '0') : fixedVal,
            comparison_fixed_value_2: fixedVal2 == 0 ? '0' : fixedVal2,
            comparison_interval: comparison_interval == 0 ? '0' : comparison_interval,
            comparison_pct_tolerance: comparison_pct,
        });

        //Stoplight set values
        if ('stoplight' == alertRuleData.alert_type) {
            const red_val = parseInt(String(alertRuleData.comparison_fixed_value), 10);
            var green_val = parseInt(String(alertRuleData.comparison_fixed_value_2), 10);
            if (red_val < 1) {
                updateAlertRuleData({ comparison_fixed_value: 1 });
            }
            if (green_val < 0) {
                updateAlertRuleData({ comparison_fixed_value_2: 0 });
            }
        }

        //'average','volatility band'
        if (['average', 'volatility band'].includes(alertRuleData.alert_type)) {
            const c_interval = parseInt(String(alertRuleData.comparison_interval), 10);
            if (c_interval < 1) {
                updateAlertRuleData({ comparison_interval: 1 });
            }
        }
    }, []);

    const getAlertConditionTextForCriteria = (params: any, t: any) => {
        let html = [];
        html.push(
            <Trans
                t={t}
                i18nKey="alert_criteria_text.alert_if_value"
                values={{
                    simulationSegmentName: wizardData.simulationSegmentName,
                    simulationSegmentNamePrefix: wizardData.simulationSegmentName
                        ? t('alert_criteria_text.for_prefix') + ' '
                        : '',
                }}
            >
                placeholder_prefix
                <Box component={'span'} id="dSimulationSegmentPrefix">
                    placeholder_simulationSegmentNamePrefix
                </Box>
                <Box component={'span'} id="dSimulationSegment" color="error.main">
                    placeholder_simulationSegmentName
                </Box>
                placeholder_suffix
            </Trans>,
        );
        const conditionText = getAlertConditionText(params, t);

        html = [...html, ...conditionText];

        html.push(<>.</>);

        if (alertRuleData.exclude_anomaly_points_ind == 'Y') {
            html.push(
                <>
                    <Box component="span" color="error.main">
                        {t('alert_criteria_text.exclude_anomalies')}
                    </Box>{' '}
                    ({t('alert_criteria_text.outliers_in_std_calculation')}
                </>,
            );
        }

        return html.map((item, index) => {
            return <React.Fragment key={index}>{item}</React.Fragment>;
        });
    };

    useEffect(() => {
        if (alertRuleData.alert_type == 'stoplight') {
            let html = [];
            const red_val = parseInt(String(alertRuleData.comparison_fixed_value), 10);
            const green_val = parseInt(String(alertRuleData.comparison_fixed_value_2), 10);
            html.push(
                <>
                    {t('alert_criteria_text.stoplight_msg', {
                        red_val: red_val,
                        green_val: green_val,
                    })}
                </>,
            );
            onChange(html);
        } else {
            const compareLineIdEl = wizardData.form.compare_line_id.data.find((item: any) => {
                return item.value == alertRuleData.compare_line_id;
            });
            const comparisonIntervalCompareTo = Object.keys(wizardData.form.comparison_interval_compare_to.data).find(
                (item) => {
                    return item == alertRuleData.comparison_interval_compare_to;
                },
            );
            const comparisonIntervalCompareToPeriod = Object.keys(
                wizardData.form.comparison_interval_compare_to_period.data,
            ).find((item) => {
                return item == alertRuleData.comparison_interval_compare_to_period;
            });
            const targetText = wizardData.form.target_id.data.find((item: any) => {
                return item.value == alertRuleData.target_id;
            });

            const params = {
                fixedVal: alertRuleData.comparison_fixed_value,
                fixedVal2: alertRuleData.comparison_fixed_value_2,
                pctVal: alertRuleData.comparison_pct_tolerance,
                alertType: alertRuleData.alert_type,
                comparisonIntervalCompareTo: comparisonIntervalCompareTo
                    ? t(
                          `form.criteria.field_value.comparison_interval_compare_to_custom.${comparisonIntervalCompareTo}`,
                          { interval: wizardData.dashboardElement.mi_interval_unit },
                      )
                    : '',
                comparisonIntervalCompareToPeriod: comparisonIntervalCompareToPeriod
                    ? t(`form.criteria.field_value.mi_intervals.${comparisonIntervalCompareToPeriod}`, { count: 1 })
                    : '',
                comparisonType: alertRuleData.comparison_type,
                pctComparisonType: alertRuleData.pct_comparison_type,
                unusualValueDetectionMethod: alertRuleData.unusual_value_detection_method,
                compareLineText: compareLineIdEl ? compareLineIdEl.label : '',
                rangeOfValuesScope: alertRuleData.range_of_values_scope,
                targetText: targetText ? targetText.label : '',
                mi_interval_unit: wizardData.dashboardElement.mi_interval_unit,
                comparison_interval: parseInt(String(alertRuleData.comparison_interval), 10),
                comparison_interval_period: alertRuleData.comparison_interval_period,
                frequently_condition_satisfied: alertRuleData.frequently_condition_satisfied,
                frequently_condition_values: alertRuleData.frequently_condition_values,
                trigger_alert_when_frequently: alertRuleData.trigger_alert_when_frequently,
            };

            const html = getAlertConditionTextForCriteria(params, t);

            onChange(html);
        }
    }, [
        alertRuleData.comparison_type,
        alertRuleData.exclude_anomaly_points_ind,
        alertRuleData.comparison_fixed_value,
        alertRuleData.frequently_condition_satisfied,
        alertRuleData.frequently_condition_values,
        alertRuleData.comparison_interval,
        alertRuleData.comparison_interval_compare_to,
        alertRuleData.unusual_value_detection_method,
        alertRuleData.trigger_alert_when_frequently,
        alertRuleData.pct_comparison_type,
        alertRuleData.range_of_values_scope,
        alertRuleData.target_id,
        alertRuleData.compare_line_id,
        alertRuleData.comparison_interval_compare_to_period,
        alertRuleData.comparison_interval_period,
        alertRuleData.user_defined_name,
        alertRuleData.alert_rule_count_type,
        alertRuleData.alert_rule_count,
        alertRuleData.compound_selected_alert_rule,
        wizardData.simulationSegmentName,
    ]);

    const compoundSelectedAlertRuleControl = elementProps.form.hookFormWatch('compound_selected_alert_rule');

    useEffect(() => {
        if (compoundSelectedAlertRuleControl && typeof compoundSelectedAlertRuleControl === 'string') {
            const newVal = JSON.parse(elementProps.form.hookFormGetValues('compound_selected_alert_rule'));
            updateAlertRuleData({
                compound_selected_alert_rule: newVal,
            });
            if (alertRuleData.alert_rule_count_type == 'all') {
                updateAlertRuleData({
                    alert_rule_count: newVal.length,
                });
            }
        }
    }, [compoundSelectedAlertRuleControl]);

    const excludeAnomalyPointsInd = elementProps.form.hookFormWatch('exclude_anomaly_points_ind');

    useEffect(() => {
        if (alertRuleData.unusual_value_detection_method == 'mr' && excludeAnomalyPointsInd == 'Y') {
            updateAlertRuleData({ exclude_anomaly_points_ind: 'N' });
        }
    }, [alertRuleData.unusual_value_detection_method]);

    useEffect(() => {
        updateAlertRuleData({ exclude_anomaly_points_ind: excludeAnomalyPointsInd });
    }, [excludeAnomalyPointsInd]);

    const excludeAnomaliesControl = () => {
        if (
            !['average', 'volatility band'].includes(alertRuleData.alert_type) ||
            alertRuleData.unusual_value_detection_method == 'mr'
        )
            return null;

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    '.MuiFormControlLabel-root': {
                        color: alertRuleData.exclude_anomaly_points_ind == 'Y' ? 'primary.main' : undefined,
                    },
                }}
            >
                <Box component={'span'} sx={{ mr: 1 }}>
                    {t('form.criteria.common_label.and')}
                </Box>
                <ReactHookFormController
                    elementProps={prepareFormElementProps({
                        ...elementProps,
                        component: {
                            component: 'FormCheckbox',
                            name: 'exclude_anomaly_points_ind',
                            label: t('form.criteria.field_label.exclude_anomaly_points_ind'),
                            props: {},
                        },
                    })}
                />
            </Box>
        );
    };

    const comparisonTypeControl = () => {
        return (
            <>
                {simpleLabel(t('form.criteria.common_label.alert_if'))}
                {alertRuleData.alert_type == 'fixed value'
                    ? getSelectField('comparison_type_fixed')
                    : getSelectField('comparison_type')}
            </>
        );
    };
    const comparisonPctToleranceControl = () => {
        if (['fixed', 'less fixed'].includes(alertRuleData.comparison_type)) return null;

        return (
            <>
                {simpleLabel(t('form.criteria.common_label.what_percentage'))}
                {getTextField('comparison_pct_tolerance', 'floatPositive')}
                {simpleLabel('%')}
            </>
        );
    };
    const comparisonFixedValueControl = () => {
        if (
            !(
                ['fixed', 'less fixed'].includes(alertRuleData.comparison_type) ||
                alertRuleData.alert_type == 'fixed value'
            )
        )
            return null;

        return (
            <>
                {simpleLabel(t('form.criteria.common_label.what_value'))}
                {getTextField('comparison_fixed_value', 'float')}
            </>
        );
    };

    const compareLineIdControl = () => {
        return (
            <>
                {simpleLabel(t('form.criteria.common_label.the_value_from'))}
                {getSelectField('compare_line_id')}
            </>
        );
    };

    const simpleLabel = (label: string) => {
        return (
            <Box component={'span'} sx={{ my: 0.5 }}>
                {label}
            </Box>
        );
    };

    const getTextField = (key: string, keyDownFilterType?: filterOnKeyDownType) => {
        const width = key === 'user_defined_name' ? '100%' : '30px';

        if (!watchTextFields.includes(key)) {
            setWatchTextFields([...watchTextFields, key]);
        }

        return (
            <FormControl
                sx={{
                    display: 'inline-flex',
                    my: '4px !important',
                    width: width,
                    input: { px: 0.5, textAlign: key !== 'user_defined_name' ? 'center' : undefined },
                    '.MuiFormLabel-root': { display: 'none' },
                }}
            >
                <ReactHookFormController
                    elementProps={prepareFormElementProps({
                        ...elementProps,
                        component: {
                            component: 'FormText',
                            name: key,
                            label: '',
                            props: {
                                onKeyDown: (e: any) => {
                                    if (keyDownFilterType) filterOnKeyDown(e, keyDownFilterType);
                                },
                            },
                        },
                    })}
                />
            </FormControl>
        );
    };

    const getSelectField = (key: string) => {
        let changeFunc: any = null;
        let select_options: FormComponentValue[] = Object.keys(wizardData.form[key].data).map((optionKey: string) => {
            return {
                label: t(`form.criteria.field_value.${key}.` + optionKey.replaceAll(' ', '_').toLowerCase()),
                value: optionKey,
            };
        });

        switch (key) {
            case 'comparison_type_fixed':
                changeFunc = (val: any, fullVal: any) => {
                    if (val == 'percentage of a value') {
                        updateAlertRuleData({
                            comparison_type: 'pct variance',
                            pct_comparison_type: 'above',
                        });
                    }
                    if (val == 'more than a value') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'above',
                        });
                    } else if (val == 'more than or equal to a value') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'above or equal',
                        });
                    } else if (val == 'less than a value') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'below',
                        });
                    } else if (val == 'less than or equal to a value') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'below or equal',
                        });
                    } else if (val == 'not equal to a value') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'above or below',
                        });
                    } else if (val == 'equal') {
                        updateAlertRuleData({
                            comparison_type: 'fixed',
                            pct_comparison_type: 'equal',
                        });
                    }

                    updateAlertRuleData({
                        [key]: val,
                    });
                };
                break;
            case 'pct_comparison_type':
                select_options = select_options.filter((item) => {
                    const ruleToRemove_1 =
                        alertRuleData.alert_type !== 'fixed value' &&
                        (item.value == 'above or equal' || item.value == 'below or equal');
                    const ruleToRemove_2 =
                        alertRuleData.comparison_type_fixed == 'percentage of a value' && item.value == 'equal';

                    const ruleToRemove_3 =
                        ['fixed value', 'prior value', 'average', 'target'].includes(alertRuleData.alert_type) &&
                        (item.value == 'within' || item.value == 'between');
                    const ruleToRemove_4 = !['fixed value'].includes(alertRuleData.alert_type) && item.value == 'equal';

                    return !(ruleToRemove_1 || ruleToRemove_2 || ruleToRemove_3 || ruleToRemove_4);
                });
                break;
            case 'compare_line_id':
            case 'target_id':
                select_options = wizardData.form[key].data;
                break;
            case 'comparison_interval_period':
                select_options = miIntervals.map((item: any) => {
                    return {
                        label: t(`form.criteria.field_value.mi_intervals.${item}`, { count: 2 }),
                        value: item,
                    };
                });
                break;
            case 'comparison_interval_compare_to_period':
                if (['hour', 'day'].includes(wizardData.dashboardElement.mi_interval_unit)) {
                    select_options = miIntervals.map((item: any) => {
                        return {
                            label: t(`form.criteria.field_value.mi_intervals.${item}`, { count: 1 }),
                            value: item,
                        };
                    });
                }
                break;
            case 'comparison_interval_compare_to':
                if (['hour', 'day'].includes(wizardData.dashboardElement.mi_interval_unit)) {
                    select_options = ['all', 'same'].map((item: any) => {
                        return {
                            label: t(`form.criteria.field_value.comparison_interval_compare_to_custom.${item}`, {
                                interval: wizardData.dashboardElement.mi_interval_unit,
                            }),
                            value: item,
                        };
                    });
                }
                break;
        }

        return (
            <Box sx={{ display: 'inline-flex', my: '4px !important' }}>
                <ReactSelect
                    dataTest={`criteria_${key}`}
                    data={select_options}
                    value={[alertRuleData?.[key as keyof typeof alertRuleData]]}
                    update={(val, fullValue) => {
                        if (changeFunc) {
                            changeFunc(val, fullValue);
                        } else {
                            updateAlertRuleData({
                                [key]: val,
                            });
                        }
                    }}
                />
            </Box>
        );
    };

    // update text field value from form to alertRuleData object
    useEffect(() => {
        if (subscription?.unsubscribe) subscription.unsubscribe();
        const subscriptionData = elementProps.form.hookFormWatch((value, { name, type }) => {
            const fieldKey = String(name);
            if (watchTextFields.includes(fieldKey)) {
                updateAlertRuleData({
                    [fieldKey]: value[fieldKey],
                });
            }
        });
        setSubscription(subscriptionData);
        return () => {
            if (subscription?.unsubscribe) subscription.unsubscribe();
        };
    }, [watchTextFields]);

    return (
        <>
            {alertRuleData.alert_type == 'fixed value' && (
                <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                    {comparisonTypeControl()}
                    {comparisonPctToleranceControl()}
                    {alertRuleData.comparison_type == 'pct variance' && getSelectField('pct_comparison_type')}
                    {comparisonFixedValueControl()}
                </Stack>
            )}

            {alertRuleData.alert_type == 'prior value' && (
                <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                    {comparisonTypeControl()}
                    {comparisonPctToleranceControl()}
                    {comparisonFixedValueControl()}
                    {getSelectField('pct_comparison_type')}
                    {compareLineIdControl()}
                </Stack>
            )}
            {alertRuleData.alert_type == 'average' && (
                <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                    {comparisonTypeControl()}
                    {comparisonPctToleranceControl()}
                    {comparisonFixedValueControl()}
                    {getSelectField('pct_comparison_type')}
                    {['hour', 'day'].includes(wizardData.dashboardElement.mi_interval_unit) ? (
                        <>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.for_avg_of'))}
                                {getSelectField('comparison_interval_compare_to')}
                            </Stack>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.of_the'))}
                                {getSelectField('comparison_interval_compare_to_period')}
                            </Stack>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.for_the_last'))}
                                {getTextField('comparison_interval', 'integerPositive')}
                            </Stack>
                        </>
                    ) : (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {simpleLabel(t('form.criteria.common_label.for_avg_of_the_last_complete'))}
                            {getTextField('comparison_interval', 'integerPositive')}
                        </Stack>
                    )}

                    {getSelectField('comparison_interval_period')}
                    {excludeAnomaliesControl()}
                </Stack>
            )}

            {alertRuleData.alert_type == 'volatility band' && (
                <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                    {simpleLabel(t('form.criteria.common_label.alert_if'))}
                    {getSelectField('pct_comparison_type')}
                    {getTextField('comparison_fixed_value', 'floatPositive')}

                    {alertRuleData.pct_comparison_type == 'between' && (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {simpleLabel(t('form.criteria.common_label.and'))}
                            {getTextField('comparison_fixed_value_2', 'floatPositive')}
                        </Stack>
                    )}

                    {wizardData.isShowMovingRangeAlertCriteria ? (
                        <>{getSelectField('unusual_value_detection_method')}</>
                    ) : (
                        <Box component={'span'}>
                            {t('form.criteria.field_value.unusual_value_detection_method.std')}
                        </Box>
                    )}
                    {alertRuleData.pct_comparison_type == 'between' && getSelectField('range_of_values_scope')}

                    {['hour', 'day'].includes(wizardData.dashboardElement.mi_interval_unit) ? (
                        <>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.for_avg_of'))}
                                {getSelectField('comparison_interval_compare_to')}
                            </Stack>

                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.of_the'))}
                                {getSelectField('comparison_interval_compare_to_period')}
                            </Stack>

                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {simpleLabel(t('form.criteria.common_label.for_the_last'))}
                                {getTextField('comparison_interval', 'integerPositive')}
                            </Stack>
                        </>
                    ) : (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {simpleLabel(t('form.criteria.common_label.for_avg_of_the_last_complete'))}
                            {getTextField('comparison_interval', 'integerPositive')}
                        </Stack>
                    )}

                    {getSelectField('comparison_interval_period')}
                    {excludeAnomaliesControl()}
                </Stack>
            )}

            {alertRuleData.alert_type == 'target' && (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    {comparisonTypeControl()}
                    {comparisonPctToleranceControl()}
                    {comparisonFixedValueControl()}
                    {getSelectField('pct_comparison_type')}
                    {getSelectField('target_id')}
                </Stack>
            )}

            {alertRuleData.alert_type == 'compound' && (
                <>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Box flexShrink={0}>{simpleLabel(t('form.criteria.common_label.create_alert_with_name'))}</Box>
                        <Box flexGrow={1}>{getTextField('user_defined_name')}</Box>
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                        {simpleLabel(t('form.criteria.common_label.if'))}
                        {getSelectField('alert_rule_count_type')}

                        {alertRuleData.alert_rule_count_type != 'all' && (
                            <>{getTextField('alert_rule_count', 'integerPositive')}</>
                        )}
                        {simpleLabel(t('form.criteria.common_label.of_the_following_selected_alert_rules_fire'))}
                    </Stack>

                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            my: 0.5,
                        }}
                    >
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    component: 'CheckBoxList',
                                    name: 'compound_selected_alert_rule',
                                    label: '',
                                    props: {
                                        height: 158,
                                        data: {
                                            values: wizardData.alertSubscriptions.map((item) => {
                                                return {
                                                    label: item.alert_name,
                                                    value: item.alert_rule_id,
                                                };
                                            }),
                                        },
                                        showCounter: false,
                                        async: false,
                                        showSearch: false,
                                    },
                                },
                            })}
                        />
                    </Box>
                </>
            )}

            {alertRuleData.alert_type == 'stoplight' && (
                <>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                        <Box>
                            <Trans t={t} i18nKey="form.criteria.common_label.stoplight_is_red_for">
                                placeholder_before_underline
                                <Box component={'span'} sx={{ textDecoration: 'underline' }}>
                                    placeholder_underline_text
                                </Box>
                                placeholder_after_underline
                            </Trans>
                        </Box>
                        {getTextField('comparison_fixed_value', 'integerPositive')}
                        {simpleLabel(t('form.criteria.common_label.values_in_a_row'))}
                    </Stack>
                    <Box>{simpleLabel(t('form.criteria.common_label.and'))}</Box>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                        <Box>
                            <Trans t={t} i18nKey="form.criteria.common_label.stoplight_was_not_red_for">
                                placeholder_before_underline
                                <Box component={'span'} sx={{ textDecoration: 'underline' }}>
                                    placeholder_underline_text
                                </Box>
                                placeholder_after_underline
                            </Trans>
                        </Box>
                        {getTextField('comparison_fixed_value_2', 'integerPositive')}
                        {simpleLabel(t('form.criteria.common_label.or_more_prior_values'))}
                    </Stack>
                </>
            )}

            {alertRuleData.alert_type == 'unusual value' && <Box></Box>}
            {alertRuleData.alert_type == 'best ever' && <Box></Box>}
            {alertRuleData.alert_type == 'worst ever' && <Box></Box>}

            {alertRuleData.alert_type != 'compound' && (
                <Stack direction={'row'} spacing={1} flexWrap={'wrap'} alignItems={'center'}>
                    {simpleLabel(t('form.criteria.common_label.must_happen_to'))}
                    {getTextField('frequently_condition_satisfied', 'floatPositive')}
                    {simpleLabel(t('form.criteria.common_label.of_the_last'))}
                    {getTextField('frequently_condition_values', 'floatPositive')}
                    {simpleLabel(t('form.criteria.common_label.data_points'))}
                </Stack>
            )}
        </>
    );
}
