import { Typography } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import IconHandler from 'components/common/icon/IconHandler';

export default function SelectedCell({ text, icon, subText }: CustomCellProps) {
    const getIcon = () => {
        return icon ? (
            <IconHandler
                sx={{ verticalAlign: 'middle', color: 'primary.main', mr: 0.5, fontSize: 14 }}
                icon={{ type: icon.type, value: icon.value }}
            />
        ) : undefined;
    };

    return (
        <Typography noWrap sx={{ py: 1, pl: !icon ? '18px' : undefined }}>
            {getIcon()}
            {text} {subText && subText}
        </Typography>
    );
}
