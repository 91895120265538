import { alpha } from '@mui/material';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import React from 'react';

export default function ReportContentLoadingPlaceholder() {
    return (
        <LoadingPlaceholder
            sx={{
                zIndex: 100,
                position: 'absolute',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                color: (theme) => alpha(theme.palette.text.primary, 0.4),
            }}
        />
    );
}
