import { FormComponentBuilder } from 'components/common/form/layout';

import { FormControlProps } from 'components/common/form/layout/control';

export interface FormControlSecureJsonEditorProps extends FormControlProps {
    fetchOnTab?: string;
}

export class FormSecureJsonEditorBuilder extends FormComponentBuilder {
    prepareProps(): FormControlSecureJsonEditorProps {
        return {
            ...this.controlProps,
            fetchOnTab: this.elementProps.componentProps?.fetchOnTab,
        };
    }
}
