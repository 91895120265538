import React, { useState } from 'react';
import { Box } from '@mui/material';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import GridPopup from 'components/common/grid/GridPopup';

function RunHistory({ type, itemId }: { type: string; itemId: string }) {
    const [showPopup, setShowPopup] = useState(false);
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);

    const popupUrl =
        'dataset' == type
            ? '/data/editor/dataset/' + itemId + '/run-history/index'
            : '/data/editor/run-history/' + itemId + '/index';

    const runHistoryPopupSettings: PopupSettings = {
        title: t('run_history'),
        textOK: t('close'),
        maxWidth: 'popupXl',
        noCancel: true,
    };

    const hideGridPopup = () => setShowPopup(false);

    return (
        <>
            <Box className={'invert-link'} component={'span'} onClick={() => setShowPopup(true)}>
                {t('run_history')}
            </Box>
            {showPopup && (
                <GridPopup
                    component="GridComponent"
                    popupUrl={popupUrl}
                    handleHidePopup={hideGridPopup}
                    handleConfirmPopup={hideGridPopup}
                    popupConfig={runHistoryPopupSettings}
                    popupType="page"
                    popupMode="new"
                />
            )}
        </>
    );
}

export default RunHistory;
