import { FormDataAPIType } from 'components/common/form';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import FormToggleButton from 'components/common/form/layout/control/FormToggleButton';
import { alpha } from '@mui/material';

export default function EditPanelToggleButton({
    elementProps,
    name,
    icon,
}: {
    elementProps: FormDataAPIType;
    name: string;
    icon?: string;
}) {
    return (
        <FormToggleButton
            value={elementProps.form.hookFormGetValues(name) ?? false}
            sx={{
                px: 0,
                minWidth: (theme) => theme.size.defaultHeight,
                '&.Mui-selected': {
                    borderColor: 'text.primary',
                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                },
            }}
            selected={elementProps.form.hookFormGetValues(name)}
            onChange={() => {
                elementProps.form.hookFormSetValue(name, !elementProps.form.hookFormGetValues(name));
            }}
        >
            <IconMi icon={icon} fontSize={'16'} sx={{ color: 'text.primary' }} />
        </FormToggleButton>
    );
}
