import { alpha, Box, Stack } from '@mui/material';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import React from 'react';
import { EditPanelComponentType } from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/index';

export default function ShowInViewerHeight({ elementProps, t }: EditPanelComponentType) {
    const fShowInViewer = elementProps.form.hookFormWatch('show_in_viewer') == 'Y';

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Box
                sx={{
                    '.MuiFormControlLabel-root': {
                        mr: 0,
                    },
                }}
            >
                <EditPanelCheckBox elementProps={elementProps} name={'show_in_viewer'} label={t('show_in_viewer')} />
            </Box>

            {fShowInViewer && (
                <>
                    <Box
                        sx={{
                            width: '1px',
                            height: '16px',
                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        }}
                    />
                    <Box>{t('show_in_viewer_max_height')}</Box>

                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: {
                                    component: 'FormText',
                                    name: 'height',
                                    label: '',
                                },
                            })}
                        />
                    </Box>
                </>
            )}
        </Stack>
    );
}
