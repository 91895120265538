import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function Owner({ type, name, email }: { type: string; name?: string; email?: string }) {
    if (!name || name === '') {
        return null;
    }

    return (
        <Grid container direction={'row'} minWidth={'250px'}>
            <Grid item xs={5}>
                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'} display="inline" minWidth={'92px'}>
                    {`${type}:`}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={'18px'}
                    display="inline"
                    color={'primary.main'}
                    minWidth={'120px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        window.location.href = `mailto:${email}`;
                    }}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
}
