import { FormControlAsyncProps } from './layout/control';
import useBundleTranslation from 'i18n';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { controlDataAPI } from 'api/api';
import React, { useEffect } from 'react';
import SelectMapControl from 'components/common/select-map/SelectMapControl';
import { CheckBoxListControl } from 'components/common/check-box-list/CheckBoxListControl';
import { processSettingsUrl } from 'components/common/form/formTools';

const Components = {
    CheckBoxListControl: CheckBoxListControl,
    SelectMapControl: SelectMapControl,
};

type ComponentKey = keyof typeof Components;

interface ControlDataWrapperProps<T extends FormControlAsyncProps<D>, D> {
    componentKey: ComponentKey;
    controlProps: T;
    prepareControlProps: (data: any) => T;
}

export default function ControlDataWrapper<T extends FormControlAsyncProps<D>, D>({
    componentKey,
    controlProps,
    prepareControlProps,
}: ControlDataWrapperProps<T, D>) {
    const { t } = useBundleTranslation();
    const settingsUrl = processSettingsUrl(
        controlProps.settingsUrl,
        Object.keys(controlProps.urlParams),
        controlProps.urlParams,
    );

    const queryClient = useQueryClient();

    const key = [controlProps.name, controlProps.form.formDidMount, controlProps.async, settingsUrl];
    const { data, isLoading } = useQuery<any, Error>({
        queryKey: key,
        queryFn: () => {
            if (controlProps.async && controlProps.form.formDidMount) {
                return controlDataAPI.getAsyncData(settingsUrl);
            }
            return false;
        },
    });

    useEffect(() => {
        // Reset query on component Unmount
        return () => {
            queryClient.resetQueries({
                predicate: (query) => query.queryKey[0] == controlProps.name,
            });
        };
    }, []);

    const buildComponent = function (props: T) {
        // @ts-ignore
        return React.createElement(Components[componentKey] as React.FC<T>, {
            controlProps: props,
        });
    };

    let component = <div>{t('loading___')}</div>;
    let newControlProps;
    if (controlProps.async) {
        if (typeof data != 'undefined' && data !== false && !isLoading) {
            newControlProps = prepareControlProps(data.data);
            component = buildComponent(newControlProps);
        }
    } else {
        if (controlProps.form.formDidMount) {
            newControlProps = prepareControlProps(controlProps.controlComplexData);
            component = buildComponent(newControlProps);
        }
    }

    return component;
}
