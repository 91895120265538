export default {
    table: {
        header: {
            td: {
                textAlign: 'left',
                fontWeight: 'bold',
                paddingRight: '20px',
            },
        },
        body: {
            td: {
                textAlign: 'left',
                paddingRight: '20px',
            },
        },
    },
};
