import React, { useEffect, useState, useCallback, useContext } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button, Checkbox } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import QueryBuilderFieldsGridRow from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGridRow';
import IconMi from 'components/common/icon/IconMi';
import QueryBuilderFieldsPopup from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsPopup';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';
import QueryBuilderAliasPopup from 'components/plugin-query-builder/query-builder/QueryBuilderAliasPopup';
import QueryBuilderFieldsGABlock from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGABlock';
import QueryBuilderDimensionMetricGAPopup from 'components/plugin-query-builder/query-builder/QueryBuilderDimensionMetricGAPopup';

export interface QueryBuilderFieldsGridProps {
    data: any[];
    onClose?: (event: any) => void;
    onApply?: (data: any) => void;
    elementId?: number; //strict
    pluginName?: string;
    pluginTitle?: string;
    editorData?: any;
    editorAct?: any;
    updateResult?: (data: any) => void;
    readonly?: boolean;
}

export const ItemTypes = {
    CARD: 'card',
};

export default function QueryBuilderFieldsGrid(props: QueryBuilderFieldsGridProps) {
    const {
        onClose = () => {},
        onApply = () => {},
        elementId,
        data = [],
        editorData,
        editorAct,
        updateResult = () => {},
        readonly = false,
    } = props;
    const { reportData, reportAct, queryData, queryAct, helperAct, configData, pluginConfig } =
        useContext(PluginQBContext);

    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const [fieldsList, setFieldsList] = useState<any[]>(data);
    const [fieldsListToShow, setFieldsListToShow] = useState<any[]>([]);
    const [addDimensionMetricPopup, setAddDimensionMetricPopup] = useState<string>('');
    const [showFieldPopup, setShowFieldPopup] = useState<boolean>(false);
    const [fieldEditIndex, setFieldEditIndex] = useState<number | null>(null);
    const [fieldAliasIndex, setFieldAliasIndex] = useState<number | null>(null);

    const processFieldsListToShow = () => {
        let result: any = [];
        if (!readonly && pluginConfig.useMetadataFlow) {
            //sort  by columnsOrder and add new to the end
            const fields = fieldsList.filter((item: any) => {
                return editorAct.isAddedToShowField(item.field.name);
            });
            const orderedFields = fields
                .filter((item) => editorData.columnsOrder.hasOwnProperty(item.field.name))
                .sort((a, b) => {
                    if (editorData.columnsOrder[a.field.name] < editorData.columnsOrder[b.field.name]) return -1;
                    if (editorData.columnsOrder[a.field.name] > editorData.columnsOrder[b.field.name]) return 1;
                    return 0;
                })
                .map((item, index) => {
                    return { ...item, index: index };
                });

            const orderedLength = orderedFields.length;
            const notOrderedFields = fields
                .filter((item) => !editorData.columnsOrder.hasOwnProperty(item.field.name))
                .map((item, index) => {
                    return { ...item, index: orderedLength + index };
                });
            result = [...orderedFields, ...notOrderedFields];
        } else {
            result = fieldsList;
        }
        setFieldsListToShow(result);
    };
    useEffect(() => {
        setFieldsList(data);
    }, [data]);

    useEffect(() => {
        processFieldsListToShow();
    }, [editorData?.addedToShowFieldList, fieldsList]);

    useEffect(() => {
        if (!readonly) {
            if (fieldsListToShow.length) {
                let columnsOrderObj: any = {};
                fieldsListToShow.forEach((item, index) => {
                    columnsOrderObj[item.field.name] = index;
                });
                editorAct.updateData({
                    columnsOrder: columnsOrderObj,
                    dimensionIndex: fieldsListToShow.length - 1,
                });
            }
        }
        updateResult(fieldsList);
    }, [fieldsListToShow]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setFieldsListToShow((prevFieldsList) =>
            update(prevFieldsList, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevFieldsList[dragIndex]],
                ],
            }),
        );
    }, []);

    const dropCard = (index: number) => {
        let columnsOrderObj: any = {};
        fieldsListToShow.forEach((item, index) => {
            columnsOrderObj[item.field.name] = index;
        });
        editorAct.updateData({
            columnsOrder: columnsOrderObj,
            dimensionIndex: fieldsListToShow.length - 1,
        });
    };

    const isCheckedAll = !readonly ? editorAct?.isCheckedAll() : false;

    const ignoredPrompts = Object.keys(reportData.prompts).filter(
        (promptName) => reportData.prompts[promptName].values == 'none',
    );

    //Set prompts Hint
    let prompts_hint = null;
    if (ignoredPrompts.length) {
        prompts_hint = (
            <Box sx={{ mt: 2 }}>
                {ignoredPrompts.join(', ')}{' '}
                {t('fields_popup.add_filter_note_if_choose', { count: ignoredPrompts.length, title: configData.title })}{' '}
                <a href={pluginConfig.addFilterHelpLik} target="_blank">
                    {t('fields_popup.add_filter_note_link', { title: configData.title })}
                </a>
            </Box>
        );
    }

    const countIsAdded =
        fieldsListToShow.findIndex(
            (item: any) => item.field.name == 'COUNT(*)' && item.field.typeOriginal == 'INTEGER',
        ) !== -1;

    return (
        <Box>
            <Box sx={styles.fieldsGrid}>
                <DndProvider backend={HTML5Backend}>
                    <Box sx={styles.rowWrapper} className={'header'}>
                        {!readonly && <Box className={'cell cell--drag'} />}
                        {!readonly && (
                            <Box className={'cell cell--checkbox'}>
                                <Checkbox
                                    sx={{ p: 0 }}
                                    checked={isCheckedAll}
                                    onChange={(event) => {
                                        editorAct.toggleCheckAll(event.target.checked);
                                    }}
                                />
                            </Box>
                        )}
                        <Box className={'cell cell--text cell--main'}>{t('fields_popup.grid_label_field')}</Box>
                        {!readonly && pluginConfig.availableAliases && (
                            <Box className={'cell cell--text data--alias'}>{t('fields_popup.grid_label_alias')}</Box>
                        )}
                        <Box className={'cell cell--text data--type'}>{t('fields_popup.grid_label_type')}</Box>
                        {!pluginConfig.useMetadataFlow && (
                            <>
                                <Box className={'cell cell--text data--override'}>
                                    {t('fields_popup.grid_label_override')}
                                </Box>
                                <Box className={'cell cell--text data--aggregation'}>
                                    {t('fields_popup.grid_label_aggregation')}
                                </Box>
                            </>
                        )}
                        {!readonly && <Box className={'cell cell--actions'} />}
                    </Box>
                    <Box sx={styles.gridBody}>
                        {!readonly && fieldsListToShow.length == 0 ? (
                            <Box sx={{ p: 1, textAlign: 'center' }}>{t('fields.empty_fields_msg')}</Box>
                        ) : (
                            <>
                                {fieldsListToShow.map((item: any, index: number) => {
                                    return (
                                        <QueryBuilderFieldsGridRow
                                            key={item.field.name}
                                            data={item}
                                            index={index}
                                            moveCard={moveCard}
                                            dropCard={dropCard}
                                            deleteItem={() => {
                                                if (item.field.typeOriginal === 'EXPR') {
                                                    reportAct.removeExpression(item.field.name);
                                                    queryAct.removeReportFieldData(item.field.name);
                                                } else if (item.field.typeOriginal === 'DATE') {
                                                    reportAct.removeDate(item.field.name);
                                                    queryAct.removeReportFieldData(item.field.name);
                                                } else if (
                                                    item.field.name == 'COUNT(*)' &&
                                                    item.field.typeOriginal == 'INTEGER'
                                                ) {
                                                    queryAct.removeMetric('COUNT(*)');
                                                } else if (pluginConfig.useMetadataFlow) {
                                                    editorAct.removeAddedToShowField(item.field.name);
                                                }
                                            }}
                                            editItem={() => {
                                                /*
                                                setCards((prevCards) => {
                                                   const newVal = [...prevCards];
                                                   newVal.splice(index, 1, item);
                                                   return newVal;
                                               });
                                               */

                                                setFieldEditIndex(index);
                                                setShowFieldPopup(true);
                                            }}
                                            onShowAliasPopup={() => {
                                                setFieldAliasIndex(index);
                                            }}
                                            readonly={readonly}
                                            editorData={editorData}
                                            editorAct={editorAct}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </Box>
                </DndProvider>
            </Box>

            {!readonly && (
                <>
                    {prompts_hint}

                    <Box sx={{ pt: 2 }}>
                        {pluginConfig.useMetadataFlow && (
                            <>
                                <Button
                                    sx={{ mr: 1 }}
                                    startIcon={<IconMi icon="new" />}
                                    variant={'light'}
                                    onClick={() => {
                                        setAddDimensionMetricPopup('dimension');
                                    }}
                                >
                                    {t('query_builder_popup.field_tab.dimension')}
                                </Button>
                                <Button
                                    sx={{ mr: 1 }}
                                    startIcon={<IconMi icon="new" />}
                                    variant={'light'}
                                    onClick={() => {
                                        setAddDimensionMetricPopup('metric');
                                    }}
                                >
                                    {t('query_builder_popup.field_tab.metric')}
                                </Button>
                            </>
                        )}
                        <Button
                            startIcon={<IconMi icon="new" />}
                            variant={'light'}
                            onClick={() => {
                                setShowFieldPopup(true);
                            }}
                        >
                            {t('query_builder_popup.field_tab.derived_field_btn')}
                        </Button>
                        {pluginConfig.availableAddCount && (
                            <Button
                                sx={{ ml: 1 }}
                                startIcon={<IconMi icon="new" />}
                                variant={'light'}
                                disabled={countIsAdded}
                                onClick={() => {
                                    queryAct.addMetric('COUNT(*)');
                                }}
                            >
                                {t('query_builder_popup.field_tab.count_btn')}
                            </Button>
                        )}
                    </Box>

                    {showFieldPopup && (
                        <QueryBuilderFieldsPopup
                            data={fieldsList}
                            onClose={() => {
                                setShowFieldPopup(false);
                                setFieldEditIndex(null);
                            }}
                            onApply={(data) => {
                                if (data.type == 'formula') {
                                } else {
                                }
                                setShowFieldPopup(false);
                                setFieldEditIndex(null);
                            }}
                            editIndex={fieldEditIndex}
                            editorData={editorData}
                            editorAct={editorAct}
                        />
                    )}
                    {fieldAliasIndex !== null && (
                        <QueryBuilderAliasPopup
                            itemIndex={fieldAliasIndex}
                            onClose={() => {
                                setFieldAliasIndex(null);
                            }}
                            data={fieldsList}
                        />
                    )}
                    {pluginConfig.useMetadataFlow && addDimensionMetricPopup && (
                        <QueryBuilderDimensionMetricGAPopup
                            type={addDimensionMetricPopup}
                            onClose={() => {
                                setAddDimensionMetricPopup('');
                            }}
                            onApply={(selectedColumns) => {
                                if (selectedColumns.length) {
                                    editorAct.setListAsAddedToShowField(selectedColumns);
                                    editorAct.setListCheckedState(selectedColumns);
                                }
                                setAddDimensionMetricPopup('');
                            }}
                            fieldsListToShow={fieldsListToShow}
                        />
                    )}
                </>
            )}
            {!readonly && pluginConfig.useMetadataFlow && <QueryBuilderFieldsGABlock />}
        </Box>
    );
}
