import qs from 'query-string';
import { FormRendererAPIType } from 'components/common/form';
import { prepareFormData } from 'tools/tools';
import { TabPrefix } from 'components/common/form/hooks/useFormTabs';
import { instance } from 'api/api';

export const formAPI = {
    load(url: string, params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        const urlParams = qs.stringify(params);
        return instance.get(url + (urlParams ? '?' + urlParams : ''));
    },
    loadByPost(url: string, data: any = {}, getMethod: boolean = false): any {
        if (getMethod) {
            url = url + '?form-method=GET';
        }
        return instance.post(url, data);
    },
    save(url: string, data: any = {}): any {
        return instance.post(url, data);
    },
    saveFormData(url: string, data: any = {}, formActions?: FormRendererAPIType): Promise<any> {
        let headers = {};

        let property: keyof typeof data;
        for (property in data)
            if (
                'object' === typeof data[property] &&
                (data[property] instanceof File || data[property] instanceof FileList)
            ) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
                break;
            }

        return instance.post(url, prepareFormData(data, formActions), {
            headers: headers,
        });
    },
    delete(url: string): any {
        return instance.delete(url);
    },
    loadData(url: string, params: any = {}) {
        if ('undefined' === typeof params) params = {};
        params['data'] = 'Y';
        return instance.get(url + '?' + qs.stringify(params));
    },
    duplicate(url: string, data: any = {}, formActions?: FormRendererAPIType): any {
        return instance.post(url, data);
    },
    saveTab(key: string, tabName: string) {
        const data = {
            key: key,
            tab: TabPrefix + tabName,
        };
        return instance.post('data/service/form/save-state', data);
    },
};
