import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        width: '360px',
        ml: 3,
        flexShrink: 0,
        position: 'relative',
    },
    scrollContainer: {
        '.summary--default &': {
            maxHeight: '100%',
            overflow: 'auto',
            position: 'absolute',
            width: '100%',
        },
    },
    contentHolder: {
        '.summary--default &': {
            mb: 2,
            borderLeft: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
    },
    section: {
        width: '100%',
    },
    closeIconHandler: {
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        mr: '-6px !important',
    },
    closeIcon: {
        '&:hover': {
            opacity: 0.65,
            cursor: 'pointer',
        },
    },
    row: {
        flexDirection: 'row',
        borderBottom: ' 1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        px: 1.5,
        py: '5px',
        '&:not(.default-link-behavior) a': {
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '&.border-top': {
            borderTop: ' 1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
    },
    heading: {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
        px: 1.5,
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        py: '5px',
    },
    label: {
        pr: 1,
        width: 156,
        flexShrink: 0,
        '.summary--default &': {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
        },
    },
    value: {
        flexGrow: 1,
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    icon: {
        mr: 1,
        verticalAlign: 'top',
        fontSize: '16px',
    },
    descriptionHolder: {
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    descriptionExpandControl: {
        lineHeight: 1,
        mt: '10px',
        textAlign: 'center',
        color: 'primary.main',
        '&:hover': { opacity: 0.75, cursor: 'pointer' },
    },
    termSection: {
        '&.expand': {
            '.term-section__header': {
                color: 'primary.main',
                fontWeight: 600,
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                },
            },
        },
        '&.collapse': {
            '.term-section__header': {
                '.summary--default &': {
                    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
                },
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                    color: 'primary.main',
                },
            },
            '& .term-section__items': {
                overflow: 'hidden',
                height: 0,
            },
        },
    },
    iconHolder: {
        height: '16px',
        width: '16px',
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
};
