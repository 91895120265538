import React, { ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { alpha, Box, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default function DraggableRow({
    children,
    onDrop,
    idColumn,
    rowData,
    rowIndex,
    setIsDragging,
}: {
    children: ReactNode;
    onDrop: (sourceId: string, targetId: string) => void;
    idColumn: string;
    rowData: any;
    rowIndex: number;
    setIsDragging?: (isDragging: boolean) => void;
}) {
    const ref = useRef<HTMLDivElement>(null);

    const [{ canDrop, isOver, isItemAbove }, drop] = useDrop<{ id: string; index: number }, any, any>({
        accept: 'row',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            isItemAbove: monitor.getItem() && monitor.getItem().index >= rowIndex,
        }),
        drop(item, monitor) {
            if (item.id === rowData[idColumn]) {
                return;
            }

            onDrop && onDrop(item.id, rowData[idColumn]);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'row',
        item: {
            id: rowData[idColumn],
            type: 'row',
            index: rowIndex,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const isActive = canDrop && isOver;

    useEffect(() => {
        setIsDragging && setIsDragging(isDragging);
    }, [isDragging]);

    drag(drop(ref));

    const isItemBefore: boolean = isActive && isItemAbove;
    const isItemAfter: boolean = isActive && !isItemAbove;

    const styles = {
        opacity: isDragging ? 0.5 : 1,
        background: (theme: Theme) => (isActive ? useBlendColors(alpha(theme.palette.text.primary, 0.08)) : undefined),
        width: '100%',
        height: '100%',
        borderTop: (theme: Theme) => (isItemBefore ? `2px solid ${theme.palette.primary.main}` : undefined),
    };

    return (
        <>
            <Box ref={ref} sx={styles}>
                {children}
            </Box>
            {isItemAfter && (
                <Box
                    sx={{
                        border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
                        position: 'absolute',
                        width: '100%',
                        bottom: 0,
                    }}
                />
            )}
        </>
    );
}
