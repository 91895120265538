import { useEffect, useState } from 'react';
import { CustomFieldsRestrictions } from 'components/element-viewer/index';
import useBundleTranslation from 'i18n';

export default function usePrintRestriction(restrictions?: CustomFieldsRestrictions) {
    const { t } = useBundleTranslation();
    const printRestriction = (e: any) => {
        if (e.ctrlKey && (e.key == 'p' || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
            alert(t('cf_restriction_print'));
            e.preventDefault();
        }
    };
    const [isPrintRestriction, setIsPrintRestriction] = useState(false);
    useEffect(() => {
        if (!restrictions?.downloadPrint || isPrintRestriction) {
            return;
        }
        setIsPrintRestriction(true);
        document.addEventListener('keydown', printRestriction);
        document.body.classList.add('print-restriction');
    }, [restrictions]);

    useEffect(() => {
        return () => {
            document.removeEventListener('keydown', printRestriction);
            document.body.classList.remove('print-restriction');
        };
    }, []);
}
