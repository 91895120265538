import { useQuery, useQueryClient } from '@tanstack/react-query';
import MD5 from 'crypto-js/md5';
import { gridAPI } from 'api/grid';
import { useEffect, useState } from 'react';
import { SortType } from 'rsuite-table/lib/@types/common';
import {
    ButtonConfig,
    ColumnsWidth,
    ColumnType,
    DNDConfig,
    FilterValue,
    GridChangeViewConfig,
    GridData,
    GridParams,
    InfoButtonConfig,
    LegendConfig,
    MassActionsConfig,
    NoSearchResultGridConfig,
    PagerOptions,
    RelatesFields,
    RowExtraInfoConfig,
    VisibleColumns,
} from 'components/common/grid/';
import update from 'immutability-helper';
import { getSlugByUrl, prepareTranslatedText } from 'tools/tools';
import { isEqual } from 'lodash';
import { TranslatedText } from 'tools/types';

const EMPTY_TITLE_DEFAULT = 'empty_grid_placeholder';

export const useFetchGridData = (uid: string, settingsUrl: string, relatedFields: RelatesFields = {}) => {
    const [filtersList, setFiltersList] = useState([]);
    const [searchField, setSearchField] = useState({});
    const [resetFiltersButton, setResetFiltersButton] = useState(false);
    const [columns, setColumns] = useState<ColumnType[]>([]);
    const [gridData, setGridData] = useState<GridData>([]);
    const [buttons, setButtons] = useState<Array<ButtonConfig>>([]);
    const [controllerUrl, setControllerUrl] = useState('');
    const [legendConfig, setLegendConfig] = useState<Array<LegendConfig>>([]);
    const [rowExtraInfoConfig, setRowExtraInfoConfig] = useState<RowExtraInfoConfig>();
    const [filtersValue, setFiltersValue] = useState<FilterValue>({});
    const [pageIndex, setPageIndex] = useState(0);
    const [order, setOrder] = useState<SortType>('asc');
    const [orderBy, setOrderBy] = useState<string>('');
    const [pagerOptions, setPagerOptions] = useState<PagerOptions>({ pageSize: 0, visible: false });
    const [totalCount, setTotalCount] = useState(0);
    const [gridTitle, setGridTitle] = useState<TranslatedText>(prepareTranslatedText());
    const [gridDescription, setGridDescription] = useState<any>(null);
    const [dragAndDrop, setDragAndDrop] = useState<DNDConfig>({ enabled: false, url: '' });
    const [massActions, setMassActions] = useState<MassActionsConfig>({ enabled: false });
    const [gridName, setGridName] = useState('');
    const [emptyTitle, setEmptyTitle] = useState<TranslatedText>(prepareTranslatedText(EMPTY_TITLE_DEFAULT));
    const [emptyIcon, setEmptyIcon] = useState('');
    const [hideEmpty, setHideEmpty] = useState(false);
    const [customLegend, setCustomLegend] = useState(false);
    const [resetColumns, setResetColumns] = useState(true);
    const [switchColumns, setSwitchColumns] = useState(true);
    const [widthToggle, setWidthToggle] = useState(false);
    const [saveFiltersValues, setSaveFiltersValues] = useState(false);
    const [dependentGrids, setDependentGrids] = useState<string[]>([]);
    const [noSearchResult, setNoSearchResult] = useState<NoSearchResultGridConfig>();
    const [infoButton, setInfoButton] = useState<InfoButtonConfig>();
    const [visibleFilters, setVisibleFilters] = useState<string[]>([]);
    const [multilines, setMultilines] = useState(false);
    const [autoRowHeight, setAutoRowHeight] = useState(false);
    const [gridReorderLoading, setGridReorderLoading] = useState(false);
    const [gridParams, setGridParams] = useState<GridParams | null>(null);
    const [changeViewConfig, setChangeViewConfig] = useState<GridChangeViewConfig[] | null>(null);
    const [dataLoadingDone, setDataLoadingDone] = useState(false);

    const queryClient = useQueryClient();

    const buildParams = () => {
        return {
            page: pageIndex,
            order: orderBy,
            order_type: order,
            filters: filtersValue,
            related_fields: relatedFields,
        };
    };

    const requestParams = buildParams();

    useEffect(() => {
        if (filtersList && filtersList.length > 0 && Object.keys(filtersValue).length === 0) {
            const newFiltersValue: FilterValue = {};
            filtersList.forEach((filterArray: { name: string; defaultValue: string | number }[]) => {
                filterArray.forEach((filter) => {
                    if (filter.defaultValue && filtersValue[filter.name] === undefined) {
                        newFiltersValue[filter.name] = filter.defaultValue;
                    }
                });
            });
            setFiltersValue(newFiltersValue);
        }
    }, [filtersList]);

    const { data, error, isError, isLoading, refetch } = useQuery<any, Error>({
        queryKey: [uid, MD5(JSON.stringify(requestParams)).toString(), settingsUrl],
        queryFn: () => {
            setDataLoadingDone(false);
            return gridAPI.load(settingsUrl, requestParams);
        },
        gcTime: 0,
    });

    const onSortColumn = (dataKey: string, sortType?: SortType) => {
        let newOrder: SortType = 'asc';

        if (order === 'desc' && sortType === 'asc') {
            setOrder(newOrder);
            setOrderBy('');

            gridAPI.saveGridOrder({ gridName: gridName, order: { order: newOrder, orderBy: '' } });
            return;
        }

        if (dataKey !== orderBy) {
            newOrder = 'asc';
        } else {
            if (sortType) {
                newOrder = sortType;
            }
        }

        setOrder(newOrder);
        setOrderBy(dataKey);
        gridAPI.saveGridOrder({ gridName: gridName, order: { order: newOrder, orderBy: dataKey } });
    };

    const handleFilterValueChange = function (filterName: string, value: any) {
        const newFiltersValue = { ...filtersValue };
        newFiltersValue[filterName] = value;

        setFiltersValue(newFiltersValue);
        setPageIndex(0);

        if (saveFiltersValues && !isEqual(newFiltersValue, filtersValue)) {
            gridAPI.saveGridFiltersValues({ gridName: gridName, filtersValues: newFiltersValue });
        }
    };

    const resetFilters = () => {
        setFiltersValue({});
        setPageIndex(0);
        gridAPI.saveGridFiltersValues({ gridName: gridName, filtersValues: {} });
    };

    const reloadGridData = async () => {
        if (dependentGrids.length > 0) {
            dependentGrids.forEach((gridName) => {
                queryClient.invalidateQueries({ queryKey: [gridName] });
            });
        }
        await refetch().then((response) => {
            if (response.data?.data?.data?.rows && response.data?.data?.data?.results) {
                if (
                    response.data?.data?.data?.rows.length === 0 &&
                    response.data?.data?.data?.results > 0 &&
                    pageIndex > 0
                ) {
                    setPageIndex(pageIndex - 1);
                }
            }
        });
    };

    const handleDropRow = (sourceId: string, targetId: string) => {
        const dragItem = gridData.find((item) => item.id === sourceId);
        const targetItem = gridData.find((item) => item.id === targetId);

        if (dragItem && targetItem) {
            const dragItemIndex = gridData.indexOf(dragItem);
            const targetItemIndex = gridData.indexOf(targetItem);
            setGridReorderLoading(true);

            const newGridData = update(gridData, {
                $splice: [
                    [dragItemIndex, 1],
                    [targetItemIndex, 0, dragItem],
                ],
            });

            gridAPI
                .updateGridOrder(dragAndDrop.url, {
                    new_order: newGridData.map((item) => {
                        return item.id;
                    }),
                })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        refetch().then(() => {
                            setGridReorderLoading(false);
                        });
                    } else {
                        setGridReorderLoading(false);
                    }
                });
        }
    };

    const saveColumns = (
        columnsWidth: ColumnsWidth,
        visibleColumns: VisibleColumns,
        fullwidthMode: string,
        gridView: string,
        version: number,
    ) => {
        return gridAPI.saveGridSettings({
            gridName: gridName,
            colWidth: columnsWidth,
            colVisible: visibleColumns,
            fullwidthMode: fullwidthMode,
            gridView: gridView,
            version: version,
        });
    };

    useEffect(() => {
        if (data && data.data?.settings) {
            setResetFiltersButton(data.data.settings?.resetFilters === 'Y');
            setFiltersList(data.data.settings.filters);
            setSearchField(data.data.settings.searchField);
            setColumns(data.data.settings.columns);
            setGridData(data.data.data.rows);
            setLegendConfig(data.data.settings.legend);
            setRowExtraInfoConfig(data.data.settings.rowExtraInfo);
            setPagerOptions(data.data.settings.pagerOptions);
            setTotalCount(data.data.data.results);
            setGridTitle(prepareTranslatedText(data.data.settings.title));
            setGridDescription(data.data.settings.gridDescription);
            setDragAndDrop(data.data.settings.dragAndDrop);
            if (data.data.settings.massActions) setMassActions(data.data.settings.massActions);
            setButtons(data.data.settings.customButtons);
            setControllerUrl(data.data.settings.controllerUrl);
            setGridName(getSlugByUrl(settingsUrl));
            setEmptyTitle(prepareTranslatedText(data.data.settings.emptyTitle ?? EMPTY_TITLE_DEFAULT));
            setCustomLegend(data.data.settings.customLegend ?? false);
            setEmptyIcon(data.data.settings.emptyIcon);
            setHideEmpty(data.data.settings.hideEmpty ?? false);
            setResetColumns(data.data.settings.resetColumns ?? true);
            setSwitchColumns(data.data.settings.switchColumns ?? true);
            setWidthToggle(data.data.settings.widthToggle ?? false);
            setSaveFiltersValues(data.data.settings.saveFiltersValues ?? false);
            if (data.data.settings.saveFiltersValues) {
                setFiltersValue(data.data.settings.filtersValues ?? {});
            }
            if (data.data.settings.order) {
                setOrder(data.data.settings.order.order ?? 'asc');
                setOrderBy(data.data.settings.order.orderBy ?? '');
            }
            setDependentGrids(data.data.settings.dependentGrids ?? []);
            setNoSearchResult(data.data.settings.noSearchResult);
            setInfoButton(data.data.settings.infoButton);
            setVisibleFilters(data.data.settings.visibleFilters);
            setMultilines(data.data.settings.multilines);
            setAutoRowHeight(data.data.settings.autoRowHeight);
            setGridParams(data.data.settings.gridParams ?? null);
            setChangeViewConfig(data.data.settings.viewConfig ?? null);
            setDataLoadingDone(true);
        }
    }, [data]);

    return {
        isLoading,
        filtersList,
        searchField,
        resetFiltersButton,
        error,
        isError,
        gridData,
        columns,
        filtersValue,
        order,
        orderBy,
        legendConfig,
        rowExtraInfoConfig,
        pagerOptions,
        totalCount,
        pageIndex,
        gridTitle,
        gridDescription,
        dragAndDrop,
        massActions,
        buttons,
        controllerUrl,
        emptyTitle,
        emptyIcon,
        hideEmpty,
        resetColumns,
        switchColumns,
        widthToggle,
        noSearchResult,
        infoButton,
        gridName,
        visibleFilters,
        multilines,
        autoRowHeight,
        gridParams,
        handleDropRow,
        setPageIndex,
        onSortColumn,
        handleFilterValueChange,
        resetFilters,
        reloadGridData,
        saveColumns,
        changeViewConfig,
        gridReorderLoading,
        customLegend,
        dataLoadingDone,
    };
};
