import { instance } from 'api/api';

export type UserItem = {
    user_id: string;
    display_name: string;
    groups: string | null;
    is_power_user_ind: 'N' | 'Y';
    is_administrator_ind: 'N' | 'Y';
    username: string;
};

export const editorUsersAPI = {
    async getUsersSelection(url: string): Promise<UserItem[]> {
        if (url === '') {
            return [];
        }

        const response = await instance.post(url);
        return response.data.data;
    },
};
