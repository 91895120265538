import { FormRendererAPIType } from 'components/common/form/index';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getElementFieldValue } from 'components/common/form/formTools';
import { DependencyRule, FieldPropsPreg } from 'components/common/form/dependency/index';

export default function checkRule(
    rule: DependencyRule,
    fieldValue: string | null,
    formAPI: FormRendererAPIType,
    formsContext: FormsContextType,
) {
    let cond = String(rule.cond);
    if (fieldValue == null) {
        return cond == '';
    }
    let rulesValid = false;
    let isNegative = false;

    if (cond == '') {
        return true;
    }
    if (cond.substring(0, 1) == '!') {
        isNegative = true;
        cond = cond.substring(1);
    }
    let ruleData = Array.isArray(rule.data) ? rule.data : [rule.data];

    ruleData = ruleData.map((value) => {
        // Replace $fieldName.prop?.prop? to its value
        return String(value).replace(FieldPropsPreg, (match: string, group: string) => {
            const result = getElementFieldValue(formAPI, group, formsContext);
            return result == null ? value : result;
        });
    });

    switch (cond) {
        case '=':
            rulesValid = ruleData.includes(String(fieldValue));
            break;
        case '>':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue > data));
            break;
        case '>=':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue >= data));
            break;
        case '<':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue < data));
            break;
        case '<=':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue <= data));
            break;
        case 'grid empty':
            const gridEmpty = fieldValue.length === 0 ? 'Y' : 'N';
            rulesValid = ruleData.includes(gridEmpty);
            break;
        case 'ends with':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue?.endsWith(data)));
            break;
        case 'starts with':
            ruleData.forEach((data) => (rulesValid = rulesValid || fieldValue?.startsWith(data)));
            break;
    }

    if (isNegative) {
        rulesValid = !rulesValid;
    }
    return rulesValid;
}
