import { FormDataAPIType } from 'components/common/form';
import React from 'react';
import { ReportContentNS } from 'components/report-content';
import ComponentSettingsChartMeasureField = ReportContentNS.ComponentSettingsChartMeasureField;
import DatasetField = ReportContentNS.DatasetField;

const formFieldPrefix = '__measure_field__name_';

export function getMeasureFieldOptionForFormUId(field: ComponentSettingsChartMeasureField, option: string) {
    return formFieldPrefix + field.uId + '_' + option;
}

export interface MeasureFieldSortingContextType {
    elementProps: FormDataAPIType;
    selectField: (field: ComponentSettingsChartMeasureField) => void;
    removeField: (field: ComponentSettingsChartMeasureField) => void;
    onSortEnd: (oldIndex: number, newIndex: number) => void;
    onUpdateMeasureField: (newField: ComponentSettingsChartMeasureField) => void;
    datasetFields: Array<DatasetField>;
}
export const MeasureFieldSortingContext = React.createContext<MeasureFieldSortingContextType | null>(null);
