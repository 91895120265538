import { createSlice } from '@reduxjs/toolkit';

interface fontSliceState {
    list: string[];
}

export const fontSlice = createSlice({
    name: 'font',
    initialState: {
        list: [],
    } as fontSliceState,
    reducers: {
        setInstalledFont: (state, action) => {
            state.list.push(action.payload);
        },
    },
});

export const { setInstalledFont } = fontSlice.actions;

export default fontSlice.reducer;
