import { APIResponse } from 'tools/types';
import { instance } from 'api/api';

export const viewerExternalReportAPI = {
    async setSiblingTabsState(isOpen: boolean): Promise<any> {
        const response = await instance.post<APIResponse<any>>('data/viewer/external-report/set-sibling-tabs-state', {
            state: isOpen ? 'Y' : 'N',
        });
        return response.data.data;
    },
};
