import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import React, { useEffect } from 'react';
import { Box, Button, ButtonGroup, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { instance } from 'api/api';
import { AxiosResponse } from 'axios';
import { APIResponse } from 'tools/types';

export interface DisplayMaskExampleProps extends FormControlProps {
    url: string;
    urlParams?: UrlParams;
}

export default function DisplayMaskExample({ controlProps }: FormElementControlPropsType<DisplayMaskExampleProps>) {
    const handleRefreshClick = () => {
        instance.post(controlProps.url, controlProps.urlParams).then((response: AxiosResponse<APIResponse<string>>) => {
            if (response.data.status == 'OK') {
                controlProps.form.hookFormSetValue(controlProps.name, response.data.data);
            }
        });
    };

    useEffect(() => {
        if (controlProps.form.formDidMount) {
            handleRefreshClick();
        }
    }, [controlProps.form.formDidMount]);

    return (
        <Stack direction="row" width="100%">
            <Box sx={{ flexGrow: 1 }}>
                <ReactHookFormController
                    elementProps={prepareFormElementProps({
                        ...controlProps,
                        component: {
                            name: controlProps.name,
                            label: String(controlProps.label),
                            component: 'FormText',
                            props: { readOnly: true },
                        },
                    })}
                />
            </Box>
            <Box sx={{ ml: 1, flexShrink: 0, paddingTop: '21px' }}>
                <ButtonGroup aria-label="outlined primary button group">
                    <Button onClick={handleRefreshClick}>
                        <IconMi icon="refresh-cw" fontSize="16" />
                    </Button>
                </ButtonGroup>
            </Box>
        </Stack>
    );
}

export class DisplayMaskExampleBuilder extends FormComponentBuilder {
    prepareProps(): DisplayMaskExampleProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            url: this.elementProps.componentProps?.url,
        };
    }
}
