import { TreeProps } from './index';
import { NodeModel, Tree as MinoruTree } from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React, { useEffect, useState } from 'react';
import { DefaultNode } from './DefaultNode';
import styles from 'components/common/tree/Tree.styles';
import { Box } from '@mui/material';

function Tree<T>(props: TreeProps<T>) {
    const nodeComponent = props.nodeComponent ?? DefaultNode;
    const canDrop = props.canDrop ?? (() => false);
    const canDrag = props.canDrag ?? (() => false);
    const handleDrop = props.onDrop ?? (() => {});
    const dragPreview = props.dragPreview ?? undefined;
    const initialOpen = props.openState ? Array.from(props.openState) : undefined;
    const [context, setContext] = useState<HTMLElement | null>(null);
    const treeContainerId = `tree-dnd-container`;

    useEffect(() => {
        setContext(document.getElementById(treeContainerId));

        return () => {
            setContext(null);
        };
    }, []);

    return (
        <Box sx={styles.holder} id={treeContainerId}>
            {context && (
                <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
                    <MinoruTree
                        tree={props.treeData}
                        rootId={0}
                        onDrop={handleDrop}
                        canDrag={canDrag}
                        canDrop={canDrop}
                        initialOpen={initialOpen}
                        render={(node: NodeModel<T>, params) => {
                            // @ts-ignore
                            return React.createElement(nodeComponent, {
                                node,
                                params,
                                additional: props.additionalNodeProps,
                            });
                        }}
                        dragPreviewRender={dragPreview}
                        classes={{
                            root: 'tree-mi',
                            container: 'tree-mi__list',
                            listItem: 'tree-mi__item',
                            dropTarget: 'tree-mi__drop-target',
                            draggingSource: 'tree-mi__dragging-source',
                            placeholder: 'tree-mi__placeholder',
                        }}
                    />
                </DndProvider>
            )}
        </Box>
    );
}

export default Tree;
