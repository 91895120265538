import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { Button, DialogActions, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { editorAPI } from 'api/editor';
import useBundleTranslation from 'i18n';

interface ChangeVisibilityPopupProps {
    reloadGridData: () => void;
    data: {
        ids: Array<string | number>;
    };
    handleHidePopup: () => void;
    url: string;
    setCheckedKeys: (ids: (string | number)[]) => void;
    componentProps?: any;
}

type ChangeVariant = 'all' | 'selected' | 'total';

export default function ChangeVisibility({
    reloadGridData,
    data,
    handleHidePopup,
    url,
    setCheckedKeys,
    componentProps,
}: ChangeVisibilityPopupProps) {
    const { t } = useBundleTranslation(['app/editor/segment/change-visibility']);

    const [popupOpen, setPopupOpen] = useState<boolean>(true);

    const totalCount = componentProps['totalValuesCount'] ?? 0;
    const selectedCount = data['ids'].length ?? 0;

    const onPopupHide = () => {
        setPopupOpen(false);
        handleHidePopup();
    };

    const onChangeButtonClick = (variant: ChangeVariant) => {
        const formData = new FormData();
        formData.append('grid', 'segmentvalue');
        formData.append('type', variant);

        if (variant === 'selected') {
            data['ids'].forEach((id) => {
                formData.append('ids[]', String(id));
            });
        }

        editorAPI.changeSegmentVisibility(url, formData).then(() => {
            setCheckedKeys([]);
            reloadGridData();
            onPopupHide();
        });
    };

    const dialogActions = (
        <DialogActions sx={{ justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' }}>
            <Stack direction={'row'} sx={{ width: '100%' }} spacing={1.5}>
                <Button variant={'contained'} onClick={() => onChangeButtonClick('all')}>
                    {t('button_all', { totalCount: totalCount })}
                </Button>
                {selectedCount > 0 && (
                    <Button variant={'contained'} onClick={() => onChangeButtonClick('selected')}>
                        {t('button_selected', { selectedCount: selectedCount })}
                    </Button>
                )}
                <Button variant={'contained'} onClick={() => onChangeButtonClick('total')}>
                    {t('button_totals')}
                </Button>
                <Button variant={'outlined'} color={'neutral'} onClick={onPopupHide}>
                    {t('button_cancel')}
                </Button>
            </Stack>
        </DialogActions>
    );

    const popupSettings: PopupSettings = {
        title: t('popup_title'),
        customActionPanel: dialogActions,
    };

    return (
        <Popup settings={popupSettings} open={popupOpen} onHide={onPopupHide} maxWidth={'popupSm'}>
            <Typography>{t('popup_text')}</Typography>
        </Popup>
    );
}
