import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import Alert from '@mui/material/Alert';
import IconMi from 'components/common/icon/IconMi';
import { useQueryClient } from '@tanstack/react-query';
import { customRequestAPI } from 'api/api';

export default function UncommittedChangesAlert() {
    const { t } = useBundleTranslation(['app/admin/system-variables']);
    const queryClient = useQueryClient();
    const [isShowWarn, setIsShowWarn] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [timeoutIdImmediate, setTimeoutIdImmediate] = useState<NodeJS.Timeout | null>(null);

    const checkIsActualState = () => {
        customRequestAPI
            .get(`data/admin/system-variables/is-actual`)
            .then((response: any) => {
                if (response.data.status == 'OK') {
                    setIsShowWarn(!response.data.data.isActual);
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        const data = queryClient.getQueryCache();
        const unsubscribe = data.subscribe((result) => {
            if (
                result.type == 'updated' &&
                result?.action?.type == 'success' &&
                result.query.queryKey.includes('variableGrid')
            ) {
                if (timeoutIdImmediate) clearTimeout(timeoutIdImmediate);
                let t0 = setTimeout(() => {
                    checkIsActualState();
                }, 1000);
                setTimeoutIdImmediate(t0);

                if (timeoutId) clearTimeout(timeoutId);
                let t = setTimeout(() => {
                    checkIsActualState();
                }, 60000);
                setTimeoutId(t);
            }
        });
        return () => {
            if (timeoutIdImmediate) clearTimeout(timeoutIdImmediate);
            if (timeoutId) clearTimeout(timeoutId);
            unsubscribe();
        };
    }, []);

    if (!isShowWarn) return null;

    return (
        <Alert
            sx={{ justifyContent: 'center' }}
            severity={'error'}
            variant={'standard'}
            icon={false}
            className={'uncommitted-changes-alert'}
        >
            <Stack
                direction={'row'}
                className={'anim-pulsing'}
                sx={{
                    '.uncommitted-changes-alert:hover &': {
                        animation: 'none',
                    },
                }}
            >
                <IconMi icon={'alert'} sx={{ fontSize: 16, mr: 1 }} />
                <Box dangerouslySetInnerHTML={{ __html: t('uncommitted_changes_msg') }} />
            </Stack>
        </Alert>
    );
}

export class UncommittedChangesAlertBuilder extends FormComponentBuilder {
    prepareProps(): FormControlProps {
        return {
            ...this.controlProps,
        };
    }
}
