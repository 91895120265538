import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Components from 'components/common/ComponentIndex';
import { FormElementProps } from 'components/common/form';
import { useFormState } from 'react-hook-form';
import { Container, FormHelperText, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { formSliceState } from 'store/formSlice';
import { FormTabType, TabContext } from 'components/common/form/tab';
import useBundleTranslation from 'i18n';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { applyComponentValuesCondition } from 'components/common/form/dependency/applyComponentValuesCondition';
import applyComponentCondition from 'components/common/form/dependency/applyComponentCondition';
import { DependencyRule, FormComponentDependency, getRuleFieldName } from 'components/common/form/dependency';
import {
    FormComponentValue,
    FormStaticControlPropsType,
    FormWrapperControlPropsType,
    getFromComponentPropsClass,
} from 'components/common/form/layout/control';
import ComponentDebugInfo from 'components/common/form/element/FormElementDebugInfo';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { FormElementWrapper } from 'components/common/form/element/FormElementWrapper';
import FormElementErrorBoundary from 'components/common/form/element/FormElementErrorBoundry';
import type { ContainerMaxWidthType } from 'components/common/form/formTools';
import CollapseText from 'components/common/collapseText/CollapseText';
import { TFunction } from 'i18next';

export default function FormElement(props: FormElementProps) {
    // TabContext can hold 'global' props for all components on tab
    const tabContext = useContext<FormTabType | null>(TabContext);
    const contextProps: FormTabType = tabContext?.componentsProps ?? {};
    const { errors } = useFormState({ control: props.form.hookFormControl });
    const formKey = props.form.formKey;
    const formsContext = useContext<FormsContextType>(FormsContext);

    const [tabWasActive, setTabWasActive] = useState(tabContext?.isActive);
    useEffect(() => {
        if (tabContext?.isActive) {
            setTabWasActive(true);
        }
    }, [tabContext?.isActive]);

    // Get list of allowed values, can be overrided by Deps Rules
    const emptyTmpValues = useRef([]);
    const tmpValues: any = useSelector((state: any) => {
        const form = (state?.form as formSliceState).formElementsValues[formKey];
        if (form && typeof form[props.component.name] != 'undefined') {
            return form[props.component.name];
        }
        return emptyTmpValues.current;
    });
    const [componentStartValues, setComponentStartValues] = useState(tmpValues);
    useEffect(() => {
        if (JSON.stringify(componentStartValues) == JSON.stringify(tmpValues)) {
            return;
        }
        setComponentStartValues(tmpValues);
    }, [tmpValues]);

    // Element initial value (Used to restore Select value after values list reload)
    const [initialValue] = useState(props.form.hookFormGetValues(props.component.name));
    // Calculate Values 'props' depending on other fields values
    const [componentValues, setComponentValues] = useState<Array<FormComponentValue>>([]);
    const componentValuesRef = useRef<any>(componentValues);
    useEffect(() => {
        if (JSON.stringify(componentValuesRef.current) == JSON.stringify(componentValues)) {
            return;
        }
        componentValuesRef.current = componentValues;

        // After values list update, reset current value if it not in new list
        let currentValue = props.form.hookFormGetValues(props.component.name);
        // TODO: change FormSelect...
        if (props.form.formDidMount && props.component.component == 'FormSelect' && componentProps?.isMulti != true) {
            const valueFound = componentValues.find((v) => v.value == currentValue);
            const prevValueFound = componentValues.find((v) => v.value == initialValue);
            // If current value not present in list try to restore initialValue
            if (!valueFound || currentValue == '' || currentValue == '0') {
                if (!prevValueFound) {
                    // If both current and initial values are missing then set first available value
                    currentValue = componentValues[0]?.value ?? '';
                } else {
                    // Restore initial value
                    currentValue = initialValue;
                }
            }
        }
        // Update value and/or (even if values was not changed) trigger 'hookFormWatch' in depending fields
        props.form.hookFormSetValue(props.component.name, currentValue);
    }, [componentValues]);

    useEffect(() => {
        setComponentValues(applyComponentValuesCondition(props, componentStartValues, formsContext));
    }, [componentStartValues]);

    // Calculate component 'props' depending on other fields values
    const [componentProps, setComponentProps] = useState<any>(
        applyComponentCondition(props, formsContext, contextProps),
    );
    // const [componentProps, setComponentProps] = useState<any>({});
    useEffect(() => {
        // Should fire only ones, after form mount.
        // If field has some deps, will call updateComponentProps for update
        if (props.form.formDidMount) {
            setComponentProps(applyComponentCondition(props, formsContext, contextProps));
        }
    }, [props.form.formDidMount]);

    // Notify other fields about 'props' change
    const componentPropsRef = useRef<any>(componentProps);
    useEffect(() => {
        if (JSON.stringify(componentPropsRef.current) == JSON.stringify(componentProps)) {
            return;
        }
        componentPropsRef.current = componentProps;
        // This will not change current value, but trigger 'hookFormWatch' in depending fields
        props.form.hookFormSetValue(props.component.name, props.form.hookFormGetValues(props.component.name));
    }, [componentProps]);

    useEffect(() => {
        props.form.addElementValuesRegister(props, componentValuesRef, componentPropsRef, componentStatePropsRef);
    }, []);

    const updateComponentProps = function (newProps: any) {
        // Prop with name '$value' act as real field value
        if (typeof newProps.$value != 'undefined') {
            if (props.form.hookFormGetValues(props.component.name) != newProps.$value) {
                props.form.hookFormSetValue(props.component.name, newProps.$value);
                if (errors[props.component.uid]) {
                    elementProps.form.hookFormClearErrors(props.component.name);
                }
            }
            delete newProps.$value;
        }
        setComponentProps(newProps);
    };

    const emptySharedState = useRef({});
    const sharedState: any = useSelector((state: any) => {
        const formData = (state?.form as formSliceState).formElementsSharedState[props.form.formKey];
        if (formData && typeof formData[props.component.name] != 'undefined') {
            if (Object.keys(formData[props.component.name]).length === 0) {
                return emptySharedState.current;
            }
            return formData[props.component.name];
        }
        return emptySharedState.current;
    });
    const componentStatePropsRef = useRef<any>(sharedState);

    useEffect(() => {
        if (JSON.stringify(componentStatePropsRef.current) == JSON.stringify(sharedState)) {
            return;
        }
        componentStatePropsRef.current = sharedState;
        // This will not change current value, but trigger 'hookFormWatch' in depending fields
        props.form.hookFormSetValue(props.component.name, props.form.hookFormGetValues(props.component.name));
    }, [sharedState]);

    // TODO: Doesn't look very reliable
    // let isDirty = false;
    // props.component.deps.forEach((dep) => {
    //     dep.rules.forEach((rule) => {
    //         if (rule.field == '$form.isDirty') {
    //             isDirty = props.form.hookFormState.isDirty;
    //         }
    //     });
    // });

    const isDirty = props.form.hookFormState.isDirty;

    // Subscribe component to changes in other fields values
    useEffect(() => {
        if (!props.form.formDidMount || !props.component.deps.length) {
            return;
        }

        const fieldsToWatchControl: Array<string> = [];
        const fieldsToWatchValue: Array<string> = [];

        props.component.deps.forEach((dep: FormComponentDependency) => {
            dep.rules.forEach((rule: DependencyRule) => {
                switch (dep.scope) {
                    case 'control':
                        fieldsToWatchControl.push(getRuleFieldName(rule.field));
                        break;
                    case 'value':
                        fieldsToWatchValue.push(getRuleFieldName(rule.field));
                        break;
                }
            });
            // Process initial form field values
            if (fieldsToWatchControl.length) {
                updateComponentProps(applyComponentCondition(props, formsContext, contextProps));
            }
            if (fieldsToWatchValue.length) {
                setComponentValues(applyComponentValuesCondition(props, componentStartValues, formsContext));
            }
        });

        const checkDepsRules = function (name: string) {
            if (fieldsToWatchControl.includes(name)) {
                updateComponentProps(
                    applyComponentCondition(props, formsContext, contextProps, formTriggerSaveRef.current),
                );
            }
            if (fieldsToWatchValue.includes(name)) {
                setComponentValues(applyComponentValuesCondition(props, componentStartValues, formsContext));
            }
        };

        const subscription = props.form.hookFormWatch((value, { name, type }) => {
            checkDepsRules(String(name));
        });

        return () => subscription.unsubscribe();
    }, [props.form.hookFormWatch, props.form.formDidMount, componentStartValues, isDirty]);
    const elementProps = { ...props, componentProps: componentProps };

    // Check for events
    const [formTriggerSave, setFormTriggerSave] = useState<number>(0);
    const formTriggerSaveRef = useRef<number>(0);
    useEffect(() => {
        const triggerSave = props.form.triggerSave?.current ?? 0;
        if (triggerSave > 0) {
            updateComponentProps(
                applyComponentCondition(props, formsContext, contextProps, formTriggerSaveRef.current),
            );
            setFormTriggerSave(triggerSave);
        }
    }, [props.form.triggerSave?.current]);
    useEffect(() => {
        formTriggerSaveRef.current = formTriggerSave;
    }, [formTriggerSave]);

    // TODO: can affect wrapped component?
    return useMemo(
        () => (
            <FormElementErrorBoundary elementProps={elementProps}>
                <FormElementRender elementProps={elementProps} componentValues={componentValues} errors={errors} />
            </FormElementErrorBoundary>
        ),
        [
            JSON.stringify(elementProps.componentProps),
            JSON.stringify(componentValues),
            JSON.stringify(errors),
            tabWasActive,
            props.form.formDidMount,
        ],
    );
    // return <FormElementRender elementProps={elementProps} componentValues={componentValues} errors={errors} />;
}

function FormElementRender({
    elementProps,
    componentValues,
    errors,
}: {
    elementProps: FormElementProps;
    componentValues: Array<FormComponentValue>;
    errors: FieldErrors<any>;
}) {
    const innerElementProps = { ...elementProps, wrapWithContainer: false };
    const isDebugMode = useSelector((state: any) => state.debug.status);
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);

    // Static Components
    if (elementProps.component.fieldType == 'static') {
        return (
            <FormContainer elementProps={elementProps} containerMaxWidth={elementProps.containerMaxWidth ?? false}>
                <FormElementWrapper elementProps={innerElementProps}>
                    {React.createElement(
                        Components[elementProps.component.component] as React.FC<FormStaticControlPropsType>,
                        {
                            elementProps: innerElementProps,
                        },
                    )}
                </FormElementWrapper>
            </FormContainer>
        );
    }

    // Wrapper Components
    if (elementProps.component.fieldType == 'wrapper') {
        return (
            <FormContainer elementProps={elementProps} containerMaxWidth={elementProps.containerMaxWidth ?? false}>
                {React.createElement(
                    Components[elementProps.component.component] as React.FC<FormWrapperControlPropsType>,
                    {
                        elementProps: innerElementProps,
                        children: elementProps.component.components?.map((c: any, i: number) => {
                            return <FormElement {...innerElementProps} component={c} key={i} />;
                        }),
                    },
                )}
            </FormContainer>
        );
    }

    // Regular Form Components
    return (
        <FormContainer
            elementProps={elementProps}
            componentValues={componentValues}
            containerMaxWidth={elementProps.containerMaxWidth ?? false}
        >
            <FormElementWrapper elementProps={innerElementProps} componentValues={componentValues}>
                {isDebugMode && <ComponentDebugInfo elementProps={elementProps} />}
                <ReactHookFormController elementProps={innerElementProps} componentValues={componentValues} />
                {elementProps.componentProps && elementProps.componentProps.helperText && (
                    <>
                        {elementProps.componentProps.helperText && elementProps.componentProps.useCollapseText ? (
                            <CollapseText
                                text={
                                    false !== elementProps.componentProps.helperTextTranslate
                                        ? t(elementProps.componentProps.helperText)
                                        : elementProps.componentProps.helperText
                                }
                                maxLines={elementProps.componentProps?.maxLines}
                            ></CollapseText>
                        ) : (
                            <FormHelperText sx={{ marginLeft: 0 }}>
                                {false !== elementProps.componentProps.helperTextTranslate
                                    ? t(elementProps.componentProps.helperText)
                                    : elementProps.componentProps.helperText}
                            </FormHelperText>
                        )}
                    </>
                )}
                <FormElementErrorMessage errors={errors} elementProps={innerElementProps} t={t} />
            </FormElementWrapper>
        </FormContainer>
    );
}

export function FormElementErrorMessage({
    errors,
    elementProps,
    t,
}: {
    errors: FieldErrors<any>;
    elementProps: FormElementProps;
    t: TFunction;
}) {
    return errors[elementProps.component.uid] ? (
        <Box className={'container-remove-indent'} sx={{ color: 'error.main', pt: 0.25 }}>
            {!(errors[elementProps!.component!.uid]!.message as string).match(/^[a-zA-Z0-9\_\-\:\.\,\]\}\[\{]$/)
                ? (errors[elementProps!.component!.uid]!.message as string).replaceAll(
                      '{{label}}',
                      t(elementProps.component?.props?.label ?? ''),
                  )
                : t(errors[elementProps!.component!.uid]!.message as string, {
                      label: t(elementProps.component?.props?.label ?? ''),
                  })}
        </Box>
    ) : null;
}

function FormContainer({
    elementProps,
    containerMaxWidth,
    children,
    componentValues,
}: {
    elementProps: FormElementProps;
    containerMaxWidth: ContainerMaxWidthType;
    children: any;
    componentValues?: Array<FormComponentValue>;
}) {
    const classList = getFromComponentPropsClass(elementProps, componentValues).split(' ');
    const isHiddenField = elementProps?.component?.component === 'FormHidden';
    let className = 'mi-container-3 form-component-holder';
    if (classList.includes('d-none') || isHiddenField) {
        className += ' d-none';
    }
    if (classList.includes('container-remove-indent')) {
        className += '  container-remove-indent';
    }

    const isMaxFullWidth = containerMaxWidth === 'maxFull';
    const maxWidth = isMaxFullWidth ? false : containerMaxWidth;

    if (isMaxFullWidth) className += ' form-container--maxfull';

    if (elementProps?.component?.props?.containerCssClass)
        className += ` ${elementProps.component.props.containerCssClass}`;

    return elementProps?.wrapWithContainer ? (
        <Container className={className} maxWidth={maxWidth} component="main" disableGutters={true}>
            {children}
        </Container>
    ) : (
        children
    );
}
