import React, { useRef, useState } from 'react';
import { Alert } from '@mui/material';

export default function useHandleEditPanelError() {
    const setErrorMessage = (msg: string) => {
        setInnerMessage(msg);
        if (!msg) {
            return;
        }
        if (editPanel.current) {
            editPanel.current?.scrollTo({ top: 0 });
        }
    };

    const [errorMessage, setInnerMessage] = useState('');
    const editPanel = useRef<HTMLDivElement | null>(null);

    const errorBlock =
        errorMessage.length > 0 ? (
            <Alert severity={'error'} variant={'standard'}>
                {errorMessage}
            </Alert>
        ) : null;

    return { errorMessage, setErrorMessage, editPanel, errorBlock };
}
