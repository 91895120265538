import { ElementType } from 'components/element-viewer/index';
import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import ShareElementForm from 'components/element-share/ShareElementForm';

export default function ShareButton({
    element,
    segmentValueId,
    isActionMenuItem,
    filters,
}: {
    element: ElementType;
    segmentValueId: number;
    isActionMenuItem?: boolean;
    filters?: string;
}) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);

    const getElementTypeLabel = () => {
        let elTypeTranslation = '';
        switch (element.row.type) {
            case 'metric':
                elTypeTranslation = t('metric');
                break;
            case 'multi-metric chart':
                elTypeTranslation = t('multi_metric');
                break;
            case 'internal report':
                elTypeTranslation = t('internal_report');
                break;
            case 'external report':
                elTypeTranslation = t('external_report');
                break;
            case 'other external content':
                elTypeTranslation = t('external_content');
                break;
            default:
                elTypeTranslation = element.row.type;
                break;
        }
        return t('share_btn_tooltip', {
            elementType: elTypeTranslation,
        });
    };

    const [isShareForm, setIsShareForm] = useState<boolean>(false);
    const onClickShare = () => {
        setIsShareForm(true);
    };

    if (element.customFieldsRestrictions.downloadPrint) {
        const tooltipTitle = (
            <span
                dangerouslySetInnerHTML={{
                    __html: t('cf_restriction', {
                        type: t('email'),
                        name: element.customFieldsRestrictions.downloadPrint.name,
                        values: element.customFieldsRestrictions.downloadPrint.values.join(', '),
                    }),
                }}
            />
        );
        return isActionMenuItem ? (
            <ActionMenuItem
                disabled={true}
                label={getElementTypeLabel()}
                desc={tooltipTitle}
                icon="share-element"
                onClick={() => {}}
            />
        ) : (
            <Tooltip title={tooltipTitle}>
                <span>
                    <IconButton disabled sx={{ p: '6px' }}>
                        <IconMi icon="share-element" fontSize="16" />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={getElementTypeLabel()}
                    desc={t('share_btn_tooltip_menu_desc')}
                    icon="share-element"
                    onClick={onClickShare}
                />
            ) : (
                <Tooltip title={getElementTypeLabel()}>
                    <IconButton sx={{ p: '6px' }} onClick={onClickShare}>
                        <IconMi icon="share-element" fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}
            <ShareElementForm
                isOpen={isShareForm}
                setIsOpen={setIsShareForm}
                filters={filters}
                elementId={element.row.elementId}
                segmentValueId={segmentValueId}
            />
        </>
    );
}
