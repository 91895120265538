import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import { Box, Container, FormControl, FormLabel, Stack } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useBundleTranslation from 'i18n';
import { formAPI } from 'api/form';
import { FormComponentValue, prepareFormComponentValues } from 'components/common/form/layout/control';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';

export default function CertificationLevelSyncForm(props: IFormLayoutProps) {
    const configurationTab = <ConfigurationTab {...customTabProps(props, 'settings')} />;

    const customProps = {
        ...props,
        customTabs: { settings: configurationTab },
    };

    return <FormLayoutComposer props={customProps} />;
}

function ConfigurationTab(tabProps: ITabProps) {
    const [datasetColumns, setDatasetColumns] = useState<FormComponentValue[]>([]);
    const [columnName, setColumnName] = useState(tabProps.elementProps.form?.hookFormGetValues('ds_column_name_id'));
    const [levelName, setLevelName] = useState(tabProps.elementProps.form?.hookFormGetValues('ds_column_level_id'));

    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);
    const datasetSource = tabProps.elementProps.form?.hookFormWatch('dataset_source');
    const columnNameProps = getElementProps('ds_column_name_id');

    const [initDatasetSource, setInitDatasetSource] = useState(datasetSource);

    useEffect(() => {
        if (datasetSource) {
            const settingsUrl = columnNameProps.elementProps.formSettingsUrl;
            const urlParams = {
                ...columnNameProps.elementProps.urlParams,
                uid: 'ds_column_name_id',
                additionalParam: datasetSource,
            };

            const params = [];

            if (urlParams) {
                for (const [k, v] of Object.entries(urlParams)) {
                    params.push(`${k}=${v}`);
                }
            }

            const fullSettingsUrl = settingsUrl + '/refresh-component?' + params.join('&');

            formAPI.load(fullSettingsUrl).then((response: any) => {
                const data = prepareFormComponentValues(response.data.data);

                setDatasetColumns(data);
                if (initDatasetSource != datasetSource) {
                    setColumnName(data[0].value);
                    setLevelName(data[data.length - 1].value);
                    setInitDatasetSource('');
                }
            });
        }
    }, [datasetSource]);

    const { t } = useBundleTranslation([columnNameProps.elementProps.translationNS ?? 'main']);

    useEffect(() => {
        hookForm.hookFormSetValue('ds_column_name_id', columnName, { shouldDirty: true });
    }, [columnName]);

    useEffect(() => {
        hookForm.hookFormSetValue('ds_column_level_id', levelName, { shouldDirty: true });
    }, [levelName]);

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <></>
            <>
                <FormElementComponent {...getElementProps('enabled_ind')} />
                <FormElementComponent {...getElementProps('name')} />
                <FormElementComponent {...getElementProps('dataset_source')} />
                <FormElementComponent {...getElementProps('match_on')} />
                <Container className={'mi-container-3'} maxWidth={'sm'} component="main" disableGutters={true}>
                    <FormControl fullWidth>
                        <FormLabel>{t('ds_column_name_id')}</FormLabel>
                        <Box>
                            <ReactSelect
                                data={datasetColumns}
                                value={columnName}
                                update={setColumnName}
                                disabled={datasetSource === ''}
                            />
                        </Box>
                    </FormControl>
                </Container>
                <Container className={'mi-container-3'} maxWidth={'sm'} component="main" disableGutters={true}>
                    <FormControl fullWidth>
                        <FormLabel>{t('ds_column_level_id')}</FormLabel>
                        <Box>
                            <ReactSelect
                                data={datasetColumns}
                                value={levelName}
                                update={setLevelName}
                                disabled={datasetSource === ''}
                            />
                        </Box>
                    </FormControl>
                </Container>
            </>
        </FormTabContentDefaultStacks>
    );
}
