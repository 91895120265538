import React, { useContext } from 'react';
import { RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import IconMi from 'components/common/icon/IconMi';
import { FieldSortingContext, FieldSortingContextType, getFieldOptionForFormName } from './index';
import { ReportContentNS } from 'components/report-content';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import { prepareFormElementProps } from 'components/common/form/formTools';
import { alpha, Box, FormLabel, IconButton, Stack } from '@mui/material';
import AggregationMethods = ReportContentNS.AggregationMethods;
import useBundleTranslation from 'i18n';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;

export type SortListType = 'section' | 'grouping' | 'field';

function SortableItem({
    field,
    type,
    children,
}: {
    field: ComponentSettingsDatasetField;
    type: SortListType;
    children: any;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const checkBoxControl = {
        component: 'FormCheckbox',
    } as RawFormComponentType;
    const context = useContext<FieldSortingContextType | null>(FieldSortingContext);
    if (context == null) {
        return null;
    }

    const hide = context.elementProps.form.hookFormWatch('showHiddenFields') == 'Y';
    const fieldVisible = context.elementProps.form.hookFormWatch(
        getFieldOptionForFormName(field, 'show_column_in_table_display_ind'),
    );

    const aggregation = AggregationMethods.find((m) => m.value == field.aggregation_method)?.label;
    let title = field.column_name;
    if (aggregation) {
        title = `${t(aggregation)} of ${title}`;
    }

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
                zIndex: 1205, //above backdrop
                borderBottom: '1px solid',
                borderBottomColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
            className={hide && fieldVisible == 'N' ? 'd-none' : ''}
            key={field.reference_name_escaped}
        >
            {children}
            <Box flexGrow={1}>
                {type == 'field' ? (
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...context.elementProps,
                            component: {
                                ...checkBoxControl,
                                label: title,
                                name: getFieldOptionForFormName(field, 'show_column_in_table_display_ind'),
                            },
                        })}
                    />
                ) : (
                    <FormLabel>{title}</FormLabel>
                )}
            </Box>
            <IconButton onClick={() => context.selectField(field)}>
                <IconMi icon="gear" fontSize="16" />
            </IconButton>
        </Stack>
    );
}

export default function FieldsList({
    settings,
    fields,
    breakArea,
    groupingArea,
}: {
    settings: ComponentSettingsDataset;
    fields: Array<ComponentSettingsDatasetField>;
    breakArea: boolean;
    groupingArea: boolean;
}) {
    const breakFields = breakArea ? fields.filter((f) => f.break_column == 'Y') : [];
    const groupingFields = groupingArea ? fields.filter((f) => f.group_column == 'Y') : [];
    const columnFields = fields.filter((f) => {
        if ((breakArea && f.break_column == 'Y') || (groupingArea && f.group_column == 'Y')) {
            return false;
        }
        return true;
    });

    const context = useContext<FieldSortingContextType | null>(FieldSortingContext);
    const onDragEnd = (result: any) => {
        if (context == null || !result.destination) {
            return;
        }

        const getRealIndex = (index: number, type: SortListType) => {
            if (type == 'section') {
                return index;
            }
            if (type == 'grouping') {
                return index + breakFields.length;
            }
            return index + breakFields.length + groupingFields.length;
        };

        const oldIndex = getRealIndex(result.source.index, result.source.droppableId as SortListType);
        const newIndex = getRealIndex(result.destination.index, result.destination.droppableId as SortListType);
        context.onSortEnd(oldIndex, newIndex, result.draggableId, result.destination.droppableId as SortListType);
    };

    return (
        <div className="container py-5">
            <DragDropContext onDragEnd={onDragEnd}>
                <Stack spacing={2}>
                    {breakArea && <DropArea fieldList={breakFields} type={'section'} />}
                    {groupingArea && <DropArea fieldList={groupingFields} type={'grouping'} />}
                    <DropArea fieldList={columnFields} type={'field'} />
                </Stack>
            </DragDropContext>
        </div>
    );
}

function DropArea({ fieldList, type }: { fieldList: Array<ComponentSettingsDatasetField>; type: SortListType }) {
    const { t } = useBundleTranslation(['components/report-content']);
    return (
        <Droppable droppableId={type}>
            {(provided: any) => (
                <div ref={provided.innerRef}>
                    <Stack>
                        <Box
                            sx={{
                                color: (theme) => alpha(theme.palette.text.primary, 0.8),
                                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                px: 1,
                                py: '7px',
                            }}
                        >
                            {t(`dataset.sort_area_${type}`)}
                        </Box>

                        {fieldList.map((field, index) => (
                            <Draggable
                                draggableId={field.reference_name_escaped}
                                key={field.reference_name_escaped}
                                index={index}
                            >
                                {(provided: any) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                        <SortableItem field={field} type={type}>
                                            <div {...provided.dragHandleProps}>
                                                <IconMi icon={'drag-and-drop-grid'} fontSize="16" sx={{ mr: 2 }} />
                                            </div>
                                        </SortableItem>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {fieldList.length == 0 && type != 'field' && (
                            <Box
                                sx={{
                                    border: '1px dashed',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                                    textAlign: 'center',
                                    padding: 2,
                                    color: (theme) => alpha(theme.palette.text.primary, 0.64),
                                    borderRadius: '0 0 3px 3px',
                                }}
                            >
                                {t(`dataset.sort_area_empty_${type}`)}
                            </Box>
                        )}
                        {provided.placeholder}
                    </Stack>
                </div>
            )}
        </Droppable>
    );
}
