import { alpha, Grid, Typography } from '@mui/material';
import React from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useBundleTranslation from 'i18n';
import { LegendProps } from './index';

export default function Legend({ legendItems, gridName }: LegendProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_legend']);

    return legendItems.filter((item) => !item?.hidden).length > 0 ? (
        <Grid item data-test={`grid_${gridName}_legend_container`}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    height: '32px',
                }}
            >
                {legendItems
                    .filter((item) => !item?.hidden)
                    .map((item, index) => {
                        return (
                            <Grid item key={index} data-test={`grid_${gridName}_legend_item_${item.type}`}>
                                <Typography className={item.className} variant={'body2'}>
                                    <FontAwesomeIcon icon={faSquare} /> {t(item.message)}
                                </Typography>
                            </Grid>
                        );
                    })}
            </Grid>
        </Grid>
    ) : null;
}
