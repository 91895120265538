import React from 'react';
import { ChartTooltipProps } from 'components/metric/chart/tooltip/RegularSeriesTooltip';
import { alpha, Box, Divider } from '@mui/material';

export default function PreviewTooltip({ point, t }: ChartTooltipProps) {
    return (
        <Box
            sx={{
                py: 1,
                px: 1.5,
            }}
        >
            <Box sx={{ fontWeight: 600 }} dangerouslySetInnerHTML={{ __html: point.series.name }} />

            <Box>
                {t('label_value_on_time', {
                    value: point?.metadata?.value_formatted,
                    time: point?.metadata?.meas_time,
                })}
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.64) }}>{t('click_to_open')}</Box>
        </Box>
    );
}
