import { QBReportFieldData } from 'components/plugin-query-builder/data/ReportFieldClass';

export interface QBPluginConfig {
    elementName: string; //for error message
    elementType: 'report' | 'object' | 'view' | 'extreport' | 'visualization' | 'project' | 'none'; //for error message
    addFilterHelpLik: string; //for some alert message
    removeFilterConditionIn: boolean; //is remove filter condition 'in' (in list)
    removeFilterConditionEmptyAndNotEmpty: boolean; //is remove filter condition 'is empty' and 'is not empty'
    removeFilterConditionContains: boolean; //is remove filter condition 'contains'
    checkFilterConditionByFieldType: boolean; //filter available condition for filter depends on field type
    isNonStrictCheckFilterDuplicates: boolean; //filter is duplicate if has same next (column AND condition WITH ANY value).
    derivedFieldNameAllowCommaAndDoubleQuote: boolean; //derived field name can contain comma (,) and double quote (")
    queryQuoteFiledExceptExpressionOrConstructedDate: boolean; //Apply for column name quoteField() if it !hasExpression(dimension) and !hasConstructedDate(dimension)
    setQueryFilterColumnRemoveDoubleQuote: boolean; //setQuery() from  string: Filters => remove double quotes  from colum name
    useDoubleQuoteForDerivedFieldExpressionFieldName: boolean; //Derived Field Expression wrap  field with double quote like ["Test"]["Region Country"]
    queryQuoteFiledOnlyWithComma: boolean; //Apply for column name quoteField() and  wrap with double quote only if contain comma
    availableAliases: boolean; //Available aliases for fields
    derivedFieldFormatDateTypeIsAvailable: boolean; //Available aliases for fields
    derivedFieldAutofillEmptyDateValues: boolean; //When user live empty date component the autofill it
    availableIterationsHint: boolean; //Show iterations hint
    processPrompts: boolean; //Process prompts data
    availableAddCount: boolean; //add count COUNT(*)
    isRequiredDatasourceObject: boolean; //required select datasource object (report and etc)
    useMetadataFlow: boolean; //is GA, use another data format and some limits
}

export const getQueryBuilderPluginConfig = (pluginAlias?: string) => {
    const defaultConfig = pluginsConfigData.default;
    const pluginConfig = pluginAlias && pluginsConfigData[pluginAlias] ? pluginsConfigData[pluginAlias] : {};
    return { ...defaultConfig, ...pluginConfig } as QBPluginConfig;
};

const pluginsConfigData: any = {
    default: {
        elementName: 'Plugin',
        elementType: 'object',
        addFilterHelpLik: 'https://help.metricinsights.com/m/61790/l/666434',
        removeFilterConditionIn: false,
        removeFilterConditionEmptyAndNotEmpty: false,
        removeFilterConditionContains: true,
        checkFilterConditionByFieldType: true,
        isNonStrictCheckFilterDuplicates: true,
        derivedFieldNameAllowCommaAndDoubleQuote: false,
        queryQuoteFiledExceptExpressionOrConstructedDate: true,
        setQueryFilterColumnRemoveDoubleQuote: true,
        useDoubleQuoteForDerivedFieldExpressionFieldName: true,
        queryQuoteFiledOnlyWithComma: false,
        availableAliases: true,
        derivedFieldFormatDateTypeIsAvailable: false,
        derivedFieldAutofillEmptyDateValues: true,
        availableIterationsHint: true,
        processPrompts: true,
        availableAddCount: true,
        isRequiredDatasourceObject: true,
        useMetadataFlow: false,
    },
    //3647
    ssrs: {
        elementName: 'SSRS',
        elementType: 'object',
        removeFilterConditionIn: true,
        removeFilterConditionEmptyAndNotEmpty: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
        derivedFieldAutofillEmptyDateValues: false,
        availableIterationsHint: false,
        processPrompts: false,
    },
    //4059
    ga_new: {
        elementName: 'Google Analytics',
        elementType: 'view',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
        removeFilterConditionContains: false,
        availableIterationsHint: false,
        processPrompts: false,
        availableAddCount: false,
        isRequiredDatasourceObject: false,
        useMetadataFlow: true,
    },
    //4120
    qlik: {
        elementName: 'Qlik',
        elementType: 'object',
        addFilterHelpLik: 'https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/712738',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    powerbi: {
        elementName: 'PowerBI',
        elementType: 'report',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    sisense: {
        elementName: 'Sisense',
        elementType: 'report',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    spotfire: {
        elementName: 'Spotfire',
        elementType: 'report',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    mstrcube: {
        elementName: 'MicroStrategy Cube',
        elementType: 'none',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    mstr: {
        elementName: 'MicroStrategy',
        elementType: 'object',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    cognos: {
        elementName: 'Cognos',
        elementType: 'report',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    qliksense: {
        elementName: 'QlikSense',
        elementType: 'object',
        addFilterHelpLik: 'https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/712738',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    bo: {
        elementName: 'Business Objects Document',
        elementType: 'none',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    qliksense_cloud: {
        elementName: 'QlikSense Cloud',
        elementType: 'object',
        addFilterHelpLik: 'https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/712738',
        removeFilterConditionIn: true,
        checkFilterConditionByFieldType: false,
        isNonStrictCheckFilterDuplicates: false,
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    //4182
    zendesk_v2: {
        elementName: 'Zendesk',
        elementType: 'view',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    splunk_v2: {
        elementName: 'Splunk Search',
        elementType: 'none',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    salesforce_v2: {
        elementName: 'SalesForce',
        elementType: 'report',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    googlespreadsheet: {
        elementName: 'Spreadsheet',
        elementType: 'none',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    googleplay: {
        elementName: 'Google Play',
        elementType: 'report',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    confluence_v2: {
        elementName: 'Confluence',
        elementType: 'extreport',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    adwords_v2: {
        elementName: 'Google AdWords',
        elementType: 'report',
        derivedFieldNameAllowCommaAndDoubleQuote: true,
        queryQuoteFiledExceptExpressionOrConstructedDate: false,
        setQueryFilterColumnRemoveDoubleQuote: false,
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    //4243
    jira_v2: {
        elementName: 'Jira',
        elementType: 'project',
        useDoubleQuoteForDerivedFieldExpressionFieldName: false,
        queryQuoteFiledOnlyWithComma: true,
        availableAliases: false,
    },
    //4360
    thoughtspot: {
        elementName: 'ThoughtSpot',
        elementType: 'visualization',
    },
    servicenow: {
        elementName: 'ServcieNow',
        elementType: 'report',
    },
    looker: {
        elementName: 'Look',
        elementType: 'none',
    },
    obiee: {
        elementName: 'OBIEE',
        elementType: 'view',
    },
    powerbirs: {
        elementName: 'Power BI',
        elementType: 'report',
    },
    appstore: {
        elementName: 'Apple App Store',
        elementType: 'report',
    },
    //4576
    tableau: {
        elementName: 'Tableau',
        elementType: 'view',
        derivedFieldFormatDateTypeIsAvailable: true,
    },
};
