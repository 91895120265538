import useBundleTranslation from 'i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import TransferListSearch from 'components/common/transfer-list/TransferListSearch';
import TransferListSelect from 'components/common/transfer-list/TransferListSelect';
import IconMi from 'components/common/icon/IconMi';
import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { FormElementProps } from 'components/common/form';

interface initialDataItem {
    value: string | number;
    label: string;
    hidden?: false;
    disabled?: boolean;
}

export interface optionItem {
    value: string | number;
    label: string;
    hidden: false;
    disabled: boolean;
}

interface TransferListProps {
    sourceLabel?: string;
    distLabel?: string;
    onChange: (selectedValues: (string | number)[]) => void;
    data: initialDataItem[];
    value?: (string | number)[];
}

//www/js/simple_com_multi_select.js / SimpleComMultiSelect / ComMultiSelectEntity
export function TransferList(props: TransferListProps) {
    const { sourceLabel, distLabel, data, value, onChange = () => {} } = props;

    const { t } = useBundleTranslation(['components/common/transfer-list/transfer_list']);

    const [sourceList, setSourceList] = useState<optionItem[]>([]);
    const [sourceListWithSearch, setSourceListWithSearch] = useState<optionItem[]>([]);

    const [distList, setDistList] = useState<optionItem[]>([]);
    const [distListWithSearch, setDistListWithSearch] = useState<optionItem[]>([]);

    const [searchKeywordSource, setSearchKeywordSource] = useState('');
    const [searchKeywordDist, setSearchKeywordDist] = useState('');

    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectedItemsType, setSelectedItemsType] = useState<string>('');

    useEffect(() => {
        let selectedValues = value ?? [];
        if (!Array.isArray(selectedValues)) selectedValues = [selectedValues];
        selectedValues = selectedValues.map((item) => String(item));

        const options = data
            .sort(function (a, b) {
                const left = a.label.toLowerCase();
                const right = b.label.toLowerCase();
                if (left > right) return 1;
                if (left < right) return -1;
                return 0;
            })
            .map((item) => {
                return {
                    value: String(item.value),
                    label: item.label,
                    hidden: item.hidden ?? false,
                    disabled: item.disabled ?? false,
                };
            });

        setSourceList(options.filter((item) => !selectedValues.includes(item.value)));
        setDistList(options.filter((item) => selectedValues.includes(item.value)));
    }, [data, value]);

    useEffect(() => {
        setSourceListWithSearch(
            sourceList.filter((item) => {
                return item.label.toLowerCase().indexOf(searchKeywordSource) != -1;
            }),
        );
    }, [sourceList, searchKeywordSource]);

    useEffect(() => {
        setDistListWithSearch(
            distList.filter((item) => {
                return item.label.toLowerCase().indexOf(searchKeywordDist) != -1;
            }),
        );
    }, [distList, searchKeywordDist]);

    const handleChangeMultiple = (event: React.ChangeEvent<HTMLSelectElement>, type: string) => {
        const { options } = event.target;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectedItems(value);
        setSelectedItemsType(type);
    };

    const moveItemsTo = (from: 'source' | 'dist', type: 'all' | 'selected') => {
        const isAddAction = from === 'source';
        let ids: (string | number)[] = [];

        if (type === 'all') {
            ids = (from === 'source' ? sourceListWithSearch : distListWithSearch)
                .filter((item) => !item.disabled)
                .map((item) => item.value);
        }
        if (type === 'selected') {
            ids = selectedItems;
        }

        if (isAddAction) {
            onChange([...distList.map((item) => item.value), ...ids]);
        } else {
            onChange(distList.map((item) => item.value).filter((value) => !ids.includes(value)));
        }
        setSelectedItems([]);
    };

    const handleDoubleClick = (event: any, type: string) => {
        setSelectedItems([event.target.value]);
        setSelectedItemsType(type);
        moveItemsTo(type as 'source' | 'dist', 'selected');
    };
    return (
        <Stack direction="row">
            <Stack width={'100%'}>
                <Box sx={{ pb: 1, fontWeight: 600 }}>
                    {t(sourceLabel ?? 'label.source', { amount: sourceList.length })}
                </Box>
                <TransferListSearch
                    onChange={(event) => {
                        setSearchKeywordSource(event.target.value);
                    }}
                    onReset={() => {
                        setSearchKeywordSource('');
                    }}
                    value={searchKeywordSource}
                />
                <TransferListSelect
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChangeMultiple(event, 'source');
                    }}
                    onDoubleClick={(event: React.MouseEvent<HTMLOptionElement>) => {
                        handleDoubleClick(event, 'source');
                    }}
                    value={selectedItems}
                    options={sourceListWithSearch}
                />
            </Stack>
            <Stack justifyContent="flex-end" flexShrink={0}>
                <Stack justifyContent="center" spacing={1} sx={{ height: 176, px: 2 }}>
                    <Box>
                        <Button
                            disabled={!sourceListWithSearch.length}
                            onClick={() => {
                                moveItemsTo('source', 'all');
                            }}
                            className={'min-width--icon'}
                            variant={'light'}
                        >
                            <IconMi icon="chevrons-right" fontSize={'16'} />
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={!(selectedItemsType == 'source' && selectedItems.length)}
                            onClick={() => {
                                moveItemsTo('source', 'selected');
                            }}
                            className={'min-width--icon'}
                            variant={'light'}
                        >
                            <IconMi icon="chevron-right" fontSize={'16'} />
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={!(selectedItemsType == 'dist' && selectedItems.length)}
                            onClick={() => {
                                moveItemsTo('dist', 'selected');
                            }}
                            className={'min-width--icon'}
                            variant={'light'}
                        >
                            <IconMi icon="chevron-left" fontSize={'16'} />
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={!distListWithSearch.length}
                            onClick={() => {
                                moveItemsTo('dist', 'all');
                            }}
                            className={'min-width--icon'}
                            variant={'light'}
                        >
                            <IconMi icon="chevrons-left" fontSize={'16'} />
                        </Button>
                    </Box>
                </Stack>
            </Stack>
            <Stack width={'100%'}>
                <Box sx={{ pb: 1, fontWeight: 600 }}>{t(distLabel ?? 'label.dist', { amount: distList.length })}</Box>
                <TransferListSearch
                    onChange={(event) => {
                        setSearchKeywordDist(event.target.value);
                    }}
                    onReset={() => {
                        setSearchKeywordDist('');
                    }}
                    value={searchKeywordDist}
                />
                <TransferListSelect
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChangeMultiple(event, 'dist');
                    }}
                    onDoubleClick={(event: React.MouseEvent<HTMLOptionElement>) => {
                        handleDoubleClick(event, 'dist');
                    }}
                    value={selectedItems}
                    options={distListWithSearch}
                />
            </Stack>
        </Stack>
    );
}

export interface FormControlTransferListProps extends FormControlProps {
    sourceLabel?: string;
    distLabel?: string;
    data: Array<FormComponentValue>;
    urlParams: UrlParams;
    elementProps: FormElementProps;
}

function getRefreshKey(controlProps: FormControlTransferListProps) {
    let params = '';
    if (controlProps?.urlParams) {
        for (const [k, v] of Object.entries(controlProps.urlParams)) {
            params += `${k}=${v}`;
        }
    }
    return params;
}

export default function FormTransferList({
    controlProps: props,
}: FormElementControlPropsType<FormControlTransferListProps>) {
    const { sourceLabel, distLabel, data } = props;

    const [selectedVal, setSelectedVal] = useState<(string | number)[]>([]);
    // Update TransferList data on UrlParams Change
    const refreshKey = useMemo(() => {
        return getRefreshKey(props);
    }, [props.urlParams]);

    useEffect(() => {
        if (!props.form.formDidMount || refreshKey == '') {
            return;
        }
        refreshValues();
    }, [refreshKey]);

    let refreshValues = () => {
        if (typeof props.elementProps == 'undefined' || !props.elementProps.form.handleRefreshComponentData) {
            return;
        }

        //Make request and apply new component data
        props.elementProps.form
            .handleRefreshComponentData(props, (response: any) => {
                return prepareFormComponentValues(response.data.data);
            })
            .then((response: any) => {
                const selectedList = response.data.data
                    .filter((item: any) => item.selected)
                    .map((item: any) => item.value);
                setSelectedVal(selectedList);
            });
    };

    useEffect(() => {
        const selected = data.filter((item: any) => item.props.selected).map((item: any) => item.value);
        setSelectedVal(selected);
    }, [data]);

    useEffect(() => {
        props.form.hookFormSetValue(props.name, selectedVal);
    }, [selectedVal]);

    const options = data.map((item) => {
        return {
            value: item.value,
            label: item.label,
            hidden: !!item.hidden,
            disabled: !!item.disabled,
        } as initialDataItem;
    });

    return (
        <TransferList
            sourceLabel={sourceLabel}
            distLabel={distLabel}
            onChange={(data) => {
                setSelectedVal(data);
            }}
            data={options}
            value={selectedVal}
        />
    );
}

export class FormTransferListBuilder extends FormComponentBuilder {
    processFormData(formData: FormData, key: string, data: any) {
        if (!data.length) {
            formData.append(key, data);
        } else if (data.length == 1) {
            formData.append(key, data[0]);
        } else {
            data.forEach((v: string) => {
                formData.append(key + '[]', v);
            });
        }
    }

    prepareProps(): FormControlTransferListProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            elementProps: this.elementProps,
            sourceLabel: this.elementProps.componentProps?.sourceLabel,
            distLabel: this.elementProps.componentProps?.distLabel,
            data: this.componentValues,
        };
    }
}
