import { FormControlProps, FormElementControlPropsType } from './index';
import { useSelector } from 'react-redux';

export default function FormHidden({ controlProps }: FormElementControlPropsType<FormControlProps>) {
    const isDebugMode = useSelector((state: any) => state.debug.status);
    return (
        <input
            name={controlProps.name}
            type={isDebugMode ? 'text' : 'hidden'}
            value={controlProps.value}
            onChange={controlProps.onChange}
            data-test={controlProps.uid}
        />
    );
}
