import Components, { ComponentKey } from 'components/common/ComponentIndex';
import { logWarning } from 'tools/tools';
import { FormElementProps, FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import { ReactNode } from 'react';
import { Params as UrlParams } from 'react-router';
import { IconType } from 'components/common/icon/IconHandler';
import { PopupSettings } from 'components/common/popup/Popup';

// Use as value for RadioGroup and Select
export interface FormComponentValue {
    label: string;
    value: string;
    disabled?: boolean;
    __isNew__?: boolean;
    hidden?: boolean;
    props?: any;
    options?: FormComponentValue[];
    valueGroup?: string | false;
    icon?: JSX.Element;
    tooltip?: string;
}

// Contains 'selected' data about itself, used in CheckBoxList
export interface FormComponentValueSelectable extends FormComponentValue {
    selected: boolean;
}

export interface FormControlTooltip {
    input?: string;
    label?: string;
    icon?: string;
}

export interface FormControlProps {
    form: FormRendererAPIType;
    name: string;
    uid?: string;
    onChange: (...event: any[]) => void;
    value?: string | any;
    error?: boolean;
    size?: 'small' | 'medium';
    helper?: any;
    label?: string | JSX.Element;
    addonLabel?: string;
    translate?: boolean;
    helperText?: string;
    helperTextTranslate?: boolean;
    pkId?: string;
    defaultValue?: string;
    readOnly?: boolean;
    formIgnoreSubmit?: boolean; // If true data will not be sent during submit
    urlParams?: UrlParams;
    tooltip?: FormControlTooltip;
    controlClass?: string;
    labelClass?: string;
    width?: string;
}

export interface FormControlAsyncProps<T> extends FormControlProps {
    urlParams: UrlParams;
    settingsUrl: string;
    async: boolean;
    controlComplexData: T;
}

export interface FormControlDatePickerProps extends FormControlProps {
    placeholder?: string;
    showTimeSelect?: boolean;
    noSeconds?: boolean;
    timeIntervals?: number;
    disabled?: boolean;
    iconPosition?: 'start' | 'end';
    maxDate?: Date;
    minDate?: Date;
}

export interface FormControlTextProps extends FormControlProps {
    disabled?: boolean;
    placeholder?: string;
    fieldType?: string;
    rows?: number;
    asLabel?: boolean;
    maxLength?: number;
    charactersLeftLabel?: string;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    maxWidth?: number;
    onBlur?: (...event: any[]) => void;
    onKeyDown?: (...event: any[]) => void;
    autoComplete?: string;
}

export interface FormControlRichTextProps extends FormControlProps {}

export interface FormControlTemplateFiledProps extends FormControlTextProps {
    patterns: string[];
    multiline?: boolean;
}

export interface FormControlURLFieldProps extends FormControlProps {
    placeholder?: string;
}

export interface FormControlCheckboxProps extends FormControlProps {
    checked: boolean;
    disabled?: boolean;
    asBoolean?: boolean;
    icon?: IconType;
    checkedValue?: string;
    uncheckedValue?: string;
    desc?: string;
}

export interface FormControlMigrationCheckboxProps extends FormControlProps {
    type: string;
    checked: boolean;
    disabled?: boolean;
    lastExported: string;
}

export interface FormControlButtonProps extends FormControlProps {
    url: string;
    icon?: IconType;
    disabled?: boolean;
    urlParams?: UrlParams;
    save?: boolean;
    checkOnDirtyField?: string;
    variant: 'text' | 'outlined' | 'contained';
    action: 'redirect' | 'popup';
    popupConfig?: PopupSettings;
}

export interface FormControlTimeFieldProps extends FormControlProps {
    extraText: string;
}

export interface FormControlCompareInputFieldProps extends FormControlProps {
    extraText?: string;
    unitFieldName: string;
    periodFieldName: string;
}

export interface FormControlCompareFieldProps extends FormControlProps {
    type: string;
    extraText?: string;
    extraTextBadge?: string;
    rangeStartName?: string;
    rangeEndName?: string;
    betweenLabel?: string;
    variant: 'danger' | 'secure';
    color: string | false;
    data: Array<FormComponentValue>;
    urlParams: UrlParams;
}

export interface FormControlPeriodFieldProps extends FormControlProps {
    unitFieldName: string;
    periodFieldName: string;
    data: Array<FormComponentValue>;
}

export interface FormControlTimeSelectFieldProps extends FormControlProps {
    multiple: boolean;
    hoursFieldName: string;
    minutesFieldName: string;
}

export interface FormControlUniquenessTextProps extends FormControlTextProps {
    urlParams?: UrlParams;
    checkUrl: string;
}

export interface FormControlDateSelectFieldProps extends FormControlProps {
    multiple: boolean;
    fiscal: boolean;
}

export interface FormControlDateRangeProps extends FormControlProps {
    selectedDates: string;
}

export interface FormControlSliderProps extends FormControlProps {
    max?: number;
    min?: number;
    step?: number;
    disabled?: boolean;
    defaultValue?: string;
}

export interface complexLabel {
    main: string;
    secondary?: string;
}
export interface FormControlSwitchProps extends FormControlCheckboxProps {
    disabled: boolean;
    reversed: boolean;
    onLabel?: complexLabel | string;
    offLabel?: complexLabel | string;
}

export interface FormControlRadioProps extends FormControlProps {
    data: Array<FormComponentValue>;
    isRow: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    icons?: any;
}

export interface FormControlToggleButtonGroupProps extends FormControlRadioProps {
    data: Array<FormComponentValue>;
    isRow: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    icons?: any;
}

export interface FormControlTinyMCEProps extends FormControlProps {
    disabled: boolean;
}

export interface FormControlDataSourceProps extends FormControlProps {
    urlParams: UrlParams;
    editorType: 'metric' | 'dataset';
    data: Array<FormComponentValue>;
    elementProps: FormElementProps;
}

export interface FormControlTagsProps extends FormControlProps {
    dataUrl?: string;
    fetchOnTab?: string;
    labelClassName?: any;
    data: Array<FormComponentValue>;
}

// Any Form Element Props should implement this interface
export interface FormElementControlPropsType<T extends FormControlProps> {
    elementProps?: FormElementProps;
    controlProps: T;
}

export interface FormStaticControlPropsType {
    elementProps: FormElementProps;
}

export interface FormControlCommonLazyLoad extends FormControlProps {
    fetchOnTab: string;
}

export interface FormWrapperControlPropsType {
    elementProps: FormElementProps;
    children?: ReactNode | undefined;
}

const componentControlsMap = {
    hidden: 'FormHidden',
    text: 'FormText',
    textarea: 'FormTextarea',
    checkbox: 'FormCheckbox',
    radio: 'FormRadioGroup',
} as {
    [key: string]: ComponentKey;
};

export function prepareFormComponentValues(values: any): Array<FormComponentValue> {
    let result: Array<FormComponentValue> = [];
    if (typeof values == 'undefined') {
        return result;
    }
    if (Array.isArray(values)) {
        // Array format
        // [ ['value' => $value, 'label' => $label, ...], ...]
        result = values.map((row, index) => {
            if (typeof row != 'object') {
                // In some rare case, it can be a regular array of values
                // 0 => $value, 1 => $value2
                return {
                    label: row,
                    value: String(index),
                    disabled: false,
                    hidden: false,
                    props: {},
                } as FormComponentValue;
            }

            if (typeof row.label == 'undefined') {
                logWarning("'label' is empty for FormComponentValue");
                row.label = '';
            }
            if (typeof row.value == 'undefined') {
                logWarning("'value' is empty for FormComponentValue");
                row.value = '';
            }

            // Move all custom attr into props section
            const valueProps = row?.props ? row.props : {};
            for (const [k, v] of Object.entries(row)) {
                if (['value', 'label', 'disabled', 'hidden', 'props', 'valueGroup'].includes(k)) {
                    continue;
                }
                valueProps[k] = v;
            }

            return {
                label: row.label,
                value: String(row.value),
                disabled: row?.disabled ? row.disabled : false,
                hidden: row?.hidden ? row.hidden : false,
                props: valueProps,
                valueGroup: row?.valueGroup ? row.valueGroup : false,
            } as FormComponentValue;
        });
    } else {
        for (const [k, v] of Object.entries(values)) {
            // Object in 'Array Like' format
            let value = k;
            let label = '';
            let props = {};
            let disabled = false;
            if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
                // ArrayKey is $value and ArrayValue is an Object with options
                // [ {$value => {'label' => $label }}]
                const opts: any = v;
                label = opts?.label;
                props = opts?.props == undefined ? {} : opts.props;
                disabled = opts?.disabled ?? false;
            } else {
                // ArrayKey is $value and ArrayValue is $label
                // [ {$value => $label} ]
                label = String(v);
            }

            result.push({
                label: label,
                value: String(value),
                disabled: disabled,
                hidden: false,
                props: props,
                valueGroup: false,
            } as FormComponentValue);
        }
    }
    return result;
}

/**
 * Get Component based on Field Settings
 * Can return Wrapper or Control Form Component
 * @param componentType
 */
export function getComponentKey(componentType: RawFormComponentType): ComponentKey {
    let componentKey = componentType.component;

    if (typeof componentKey == 'undefined' || typeof Components[componentKey] == 'undefined') {
        if (componentKey) {
            logWarning('"component" property of Form Field contains wrong Control component: ' + componentKey);
        }
        // Set Default component based on control type
        const control = componentType.control ?? 'text';
        if (typeof componentControlsMap[control] == 'undefined') {
            logWarning('"control" property of Form Field contains wrong value: ' + componentKey);
        }
        componentKey = componentControlsMap[control];
    }
    return componentKey;
}

export function getFromComponentPropsClass(
    elementProps: FormElementProps,
    componentValues?: Array<FormComponentValue>,
) {
    const componentProps: any = elementProps.componentProps;

    let classToAdd: Array<string> = [];
    let classToRemove: Array<string> = [];
    for (const [key, value] of Object.entries(componentProps)) {
        if (key.startsWith('_class_') && value != false && value != 'false') {
            classToAdd.push(key.substring(7));
        } else if (key.startsWith('_r_class_') && value != false && value != 'false') {
            classToRemove.push(key.substring(9));
        }
    }

    // Hide FormSelect if only one(or 0) value are present
    if (componentProps?.hideSingle == true && componentValues && componentValues?.length <= 1) {
        classToAdd.push('d-none');
        classToRemove = classToRemove.filter((c) => c != 'd-none');
    }
    return classToAdd.filter((x) => !classToRemove.includes(x)).join(' ');
}

// Input Filters
export function inputIntegerFilter(value: any): number {
    return Number(String(value).replace(/[^0-9]/g, ''));
}
