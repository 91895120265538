import { FormPanelButtonProps } from 'components/common/form/index';
import FormPanelGenericButton from 'components/common/form/top-control-panel/button-components/FormPanelGenericButton';

export default function FormPanelSendTestEmailButton({
    button,
    formActions,
    isSubMenuItem,
    buttonName,
    submitId,
}: FormPanelButtonProps<any>) {
    const type = formActions.hookFormWatch('type');
    if (
        ![
            'Favorite',
            'Burst',
            'Recovery',
            'Permission',
            'OutSpace',
            'RemoveCollaborationIterm',
            'Examples',
            'Share',
            'Expired',
        ].includes(type)
    ) {
        return null;
    }

    return (
        <FormPanelGenericButton
            submitId={submitId}
            buttonName={buttonName}
            button={button}
            formActions={formActions}
            isSubMenuItem={isSubMenuItem}
        />
    );
}
