import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RatingProps } from './index';
import styles from './RatingComponent.styles';
import './rating.css';

function Rating({ promptData, rating, setRating }: RatingProps) {
    const [hoverButtonLevel, setHoverButtonLevel] = useState(0);

    const getButtonClass = (buttonLevel: number) => {
        if (hoverButtonLevel > 0) {
            if (buttonLevel <= hoverButtonLevel) {
                return getColorClass(hoverButtonLevel);
            }

            return '';
        }

        if (rating > 0 && buttonLevel <= rating) {
            return getColorClass(rating);
        }

        return '';
    };

    const getColorClass = (level: number) => {
        if (level < promptData.negativeRatingBelow) {
            return 'detractor-hover';
        }
        if (level >= promptData.negativeRatingBelow && level <= promptData.positiveRatingAbove) {
            return 'passive-hover';
        }
        if (level > promptData.positiveRatingAbove) {
            return 'promoter-hover';
        }
    };

    const getRatingButtons = () => {
        let components = [];

        for (let i = 1; i <= 10; i++) {
            const ratingButton = (
                <button
                    key={'rating-button-' + i}
                    onMouseEnter={() => {
                        setHoverButtonLevel(i);
                    }}
                    onMouseLeave={() => {
                        setHoverButtonLevel(0);
                    }}
                    onClick={() => {
                        setRating(i);
                    }}
                    className={'rating-component__button ' + getButtonClass(i)}
                >
                    {i}
                </button>
            );

            components.push(ratingButton);
        }

        return components;
    };

    return promptData.collectRatingsInd === 'Y' ? (
        <Box sx={styles.rating.wrapper}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ margin: '0 -2px' }}>
                {getRatingButtons()}
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>{promptData.negativeText}</Typography>
                <Typography>{promptData.positiveText}</Typography>
            </Stack>
        </Box>
    ) : null;
}

export default Rating;
