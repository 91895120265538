import { pollingAPI } from 'api/api';
import { APIResponse } from './types';

export interface APIResponsePooling<T> {
    status: 'OK' | 'ERROR' | 'FAILED' | 'QUEUE';
    data: T;
}

interface CreateResponse {
    ppl_id: number;
}

export interface PoolingRequestData {
    poolingCreateURL?: string;
    poolingCreateMethod?: 'GET' | 'POST';
}

export interface PoolingType<T, D> {
    onSuccess: (data: T, responsePoolingData: any) => any;
    onError: (data: T, responsePoolingData: any) => any;
    onPplIdChange?: (pplId: number) => any;
    requestData: D & PoolingRequestData;
}

export default function pollingService<T, D>({ onSuccess, onError, requestData, onPplIdChange }: PoolingType<T, D>) {
    let isActive = true;
    let timeOutId: number | null = null;
    let canceled = false;
    let responsePoolingData: any = null;

    const checker = function (pplId: number) {
        if (!isActive) return;

        timeOutId = window.setTimeout(() => {
            pollingAPI
                .check(pplId)
                .then(({ data }: { data: APIResponse<T> }) => {
                    if (data.status == 'OK') {
                        isActive = false;
                        if (onPplIdChange) {
                            onPplIdChange(0);
                        }
                        onSuccess(data.data, responsePoolingData);
                    } else if (data.status == 'ERROR' || data.status == 'FAILED') {
                        isActive = false;
                        if (onPplIdChange) {
                            onPplIdChange(0);
                        }
                        onError(data.data, responsePoolingData);
                    } else {
                        checker(pplId);
                    }
                })
                .catch(function (error) {
                    isActive = false;
                    if (onPplIdChange) {
                        onPplIdChange(0);
                    }
                    console.log('Error');
                });
        }, 2000);
    };

    const create = () => {
        canceled = false;
        pollingAPI.create(requestData).then(({ data }: { data: APIResponsePooling<CreateResponse> }) => {
            isActive = true;
            responsePoolingData = data?.data;
            if (onPplIdChange) {
                onPplIdChange(data.data.ppl_id);
            }
            run(data.data.ppl_id);
        });
    };

    const run = (pplId: number) => {
        pollingAPI.run(pplId);
        timeOutId = window.setTimeout(() => checker(pplId), 1000);
    };

    const cancel = (pplId: number) => {
        canceled = true;
        isActive = false;
        if (timeOutId) {
            window.clearTimeout(timeOutId);
        }
        if (pplId > 0) {
            pollingAPI.cancel(pplId);
        }
    };

    return {
        create,
        run,
        cancel,
    };
}
