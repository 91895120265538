import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from './index';
import { FormComponentBuilder } from 'components/common/form/layout/index';
import { instance } from 'api/api';
import React, { useEffect, useState } from 'react';
import { APIResponse } from 'tools/types';
import { AxiosResponse } from 'axios';
import Alert from '@mui/material/Alert';
import { openInNewTab } from 'tools/tools';
import { AlertColor, Box } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';

export interface FormBurstStatusProps extends FormControlProps {}

type queryStatusType = '' | 'Q' | 'D' | 'R' | 'A';

export default function FormBurstStatus({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormBurstStatusProps>) {
    const { t } = useBundleTranslation(elementProps?.translationNS ?? 'components/common/form/form');
    const [queryStatus, setQueryStatus] = useState('');

    const labelMap = {
        Q: { label: 'label_ns_status_queue', color: 'turquoise', severity: 'info', bgColor: null },
        R: {
            label: 'label_ns_status_running',
            color: null,
            severity: 'success',
            bgColor: null,
        },
        A: { label: 'label_ns_status_aborting', color: null, severity: 'error', bgColor: null },
        D: { label: 'label_ns_status_disabled', color: null, severity: 'info', bgColor: null },
    };
    const checkNSStatus = () => {
        const burstId = controlProps.form.hookFormGetValues('notification_schedule_distribution_id');
        instance
            .get(`/data/notification/burst/${burstId}/get-ns-status`)
            .then((response: AxiosResponse<APIResponse<{ ns_status: queryStatusType }>>) => {
                setQueryStatus(response.data.data.ns_status);
            });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            checkNSStatus();
        }, 30 * 1000);

        checkNSStatus();

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    //@ts-ignore
    const statusInfo = labelMap?.[queryStatus] ?? false;

    const handleClick = () => {
        const id = controlProps.value;
        const list = controlProps.form.getElementValuesRegister('notification_schedule_id')?.valuesRef.current;
        const noAccess = (msg: string) => {
            alert(t(msg));
            return false;
        };

        if (!list) {
            return noAccess('alert_could_not_identify_ns');
        }

        const row = list.find((v) => v.value == id);
        if (!row) {
            return noAccess('alert_could_not_identify_ns');
        }

        if (row.props.allow_edit_ind != 'Y') {
            return noAccess('alert_no_permission_ns_detail');
        }

        openInNewTab(`/editor/schedule/${id}`);
    };

    if (!statusInfo) {
        return null;
    }
    return (
        <Alert
            severity={statusInfo.severity as AlertColor}
            variant={'standard'}
            sx={{
                justifyContent: 'center',
                color: statusInfo.color ?? undefined,
                backgroundColor: statusInfo.bgColor ?? undefined,
            }}
            icon={queryStatus === 'R' ? <IconMi icon={'loader'} className={'anim-spin'} /> : undefined}
        >
            <Box
                component={'span'}
                onClick={() => handleClick()}
                sx={{ '&:hover': { cursor: 'pointer', opacity: '.75' } }}
            >
                {t(statusInfo.label)}
            </Box>
        </Alert>
    );
}

export class FormBurstStatusBuilder extends FormComponentBuilder {
    prepareProps(): FormBurstStatusProps {
        return {
            ...this.controlProps,
        };
    }
}
