import { useEffect, useState } from 'react';
import { Popup } from 'components/common/popup/Popup';
import { FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import useBundleTranslation from 'i18n';
import { Params as UrlParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { prepareFormElementProps } from 'components/common/form/formTools';

export interface ShareWithPopupProps {
    //ToDo settingsUrl  + settingsUrl for checkBoxProps
    //urlParams: UrlParams;
    //settingsUrl: string;
    data: any;
    onClose: () => void;
    onConfirm: (selectedValues: any) => void;
    linkData: {
        elementId: number;
        segmentValueId: number;
    };
}

//ToDo maybe extract part from SendToList and use it
export default function ShareWithPopup(props: ShareWithPopupProps) {
    const { data, linkData, onClose = () => {}, onConfirm = () => {} } = props;
    const { t } = useBundleTranslation();

    const popupSettings = {
        title: t('share_with'),
        textOK: t('add'),
    };

    const { setValue: hookFormSetValue, control, getValues: hookFromGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFromGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    const checkBoxListName = 'share_selected_emails_users';

    const checkBoxProps = {
        form: formActions,
        component: {
            component: 'CheckBoxList',
            name: checkBoxListName,
            props: {
                async: true,
                dataUrl: '/data/notification/favorites/get-users-data?elementId=:elementId&segmentId=:segmentId',
                dataPageSize: 100,
                settingsUrl: '/data/notification/favorites/users?elementId=:elementId&segmentId=:segmentId',
                height: 400,
                showCounter: false,
                urlParams: {
                    elementId: linkData.elementId,
                    segmentId: linkData.segmentValueId,
                },
            },
        } as RawFormComponentType,
    };

    useEffect(() => {
        hookFormSetValue(checkBoxListName, data);
    }, [data]);

    const handleApply = () => {
        const values = JSON.parse(hookFromGetValues(checkBoxListName));
        onConfirm(values);
    };

    return (
        <Popup onConfirm={handleApply} settings={popupSettings} open={true} onHide={onClose}>
            <ReactHookFormController elementProps={prepareFormElementProps(checkBoxProps)}></ReactHookFormController>
        </Popup>
    );
}
