import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { AssocArray } from 'tools/types';
import { OrderDirection, ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import AllowedFonts = ReportContentNS.AllowedFonts;
import AllowedFontSizes = ReportContentNS.AllowedFontSizes;
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import { alpha, Box, FormLabel, IconButton, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import ShowInViewerHeight from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/ShowInViewerHeight';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import RowColumnFieldsList from 'components/report-content/components/pivot/edit-panel/RowColumnFieldsList';
import FontSize from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FontSize';
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import ComponentSettingsPivotMeasureField = ReportContentNS.ComponentSettingsPivotMeasureField;
import fieldToPivotMeasuredField = ReportContentNS.fieldToPivotMeasuredField;
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import Divider from '@mui/material/Divider';
import PivotFieldForm from 'components/report-content/components/pivot/edit-panel/PivotFieldForm';
import IconMi from 'components/common/icon/IconMi';
import * as All from './PivotEditPanelIcons';
import SvgIcon from '@mui/material/SvgIcon';
import { useBlendColors } from 'hooks/useDesign';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import useHandleEditPanelError from 'components/report-content/hooks/useHandleEditPanelError';
import validateHeight from 'components/report-content/utils/validateHeight';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsPivot> = [
    'title',
    'mainFont',
    'zebra_striping',
    'include_in_email',
    'include_in_email_attachment',
    'show_in_viewer',
    'show_column_totals',
    'column_total_placement',
    'show_column_totals_as_percent',
    'column_total_label',
    'show_row_totals',
    'show_row_totals_as_percent',
    'row_total_label',
];
const componentNumericFormField: Array<keyof ComponentSettingsPivot> = ['mainFontSize', 'height'];

export default function PivotEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsPivot> & { block: BlockType<ComponentSettingsPivot> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const [fieldsState, setFieldsState] = useState<Array<ComponentSettingsDatasetField>>(component.settings.fields);
    const { editPanel, setErrorMessage, errorBlock } = useHandleEditPanelError();
    const datasetFields = editPanelContext?.datasetFields ?? [];
    const [measuredField, setMeasuredField] = useState<ComponentSettingsPivotMeasureField>(
        component.settings.measureFields.length == 0
            ? fieldToPivotMeasuredField(datasetFields[0])
            : component.settings.measureFields[0],
    );

    let defaultRowFields = component.settings.rowFields;
    let defaultColumnFields = component.settings.columnFields;
    if (component.settings.isOnCreation === true) {
        if (defaultRowFields.length == 0 && typeof component.settings.fields[1] != 'undefined') {
            defaultRowFields = [component.settings.fields[1] as ComponentSettingsPivotField];
            defaultRowFields[0].headerSettings = {
                textBold: true,
            };
        }
        if (defaultColumnFields.length == 0 && typeof component.settings.fields[2] != 'undefined') {
            defaultColumnFields = [component.settings.fields[2] as ComponentSettingsPivotField];
            defaultColumnFields[0].textAlign = 'right';
            defaultColumnFields[0].textBold = true;
            defaultColumnFields[0].subtotalSettings = {
                textBold: true,
                textAlign: 'right',
            };
        }
    }

    const [rowFields, setRowFields] = useState<Array<ComponentSettingsPivotField>>(defaultRowFields);
    const [columnFields, setColumnFields] = useState<Array<ComponentSettingsPivotField>>(defaultColumnFields);

    const [rowTotalsSettings, setRowTotalsSettings] = useState(component.settings.rowTotalsSettings ?? {});
    const [columnTotalsSettings, setColumnTotalsSettings] = useState(component.settings.columnTotalsSettings ?? {});

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        title: component.settings?.title ?? '',
        mainFont: component.settings?.mainFont ?? AllowedFonts[0].value,
        mainFontSize: String(component.settings?.mainFontSize ?? AllowedFontSizes[0]),
        height: component.settings?.height ?? '',

        zebra_striping: component.settings.zebra_striping ?? 'Y',
        include_in_email: component.settings.include_in_email ?? 'N',
        include_in_email_attachment: component.settings.include_in_email_attachment ?? 'N',
        show_in_viewer: component.settings.show_in_viewer ?? 'N',

        show_column_totals: component.settings.show_column_totals ?? 'N',
        column_total_placement: component.settings.column_total_placement ?? 'bottom',
        show_column_totals_as_percent: component.settings.show_column_totals_as_percent ?? 'N',
        column_total_label: component.settings.column_total_label ?? 'Overall Total',

        show_row_totals: component.settings.show_row_totals ?? 'N',
        show_row_totals_as_percent: component.settings.show_row_totals_as_percent ?? 'N',
        row_total_label: component.settings.row_total_label ?? 'Total',
    };

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process fields settings
        const prepareFields = (fields: Array<ComponentSettingsPivotField>) =>
            fields.map((f) => {
                if (typeof f.compute_result_total_ind == 'undefined') {
                    f.compute_result_total_ind = 'N';
                }
                if (typeof f.sub_total_placement == 'undefined') {
                    f.sub_total_placement = 'top';
                }
                if (typeof f.headerSettings == 'undefined') {
                    f.headerSettings = {};
                }
                if (typeof f.subtotalSettings == 'undefined') {
                    f.subtotalSettings = {};
                }
                return f;
            });

        const uiFields = component.settings.uiFields;
        rowFields.forEach((row, index) => {
            const uiField = uiFields.find((f) => f.column_sequence == index);
            if (uiField) {
                uiField.textAlign = row.textAlign ?? 'left';
            }
        });

        const newSettings: ComponentSettingsPivot = {
            ...component.settings,
            // formationRulesData: conditionFormattingState,
            dataQueries: {},
            rowTotalsSettings: { ...rowTotalsSettings },
            columnTotalsSettings: { ...columnTotalsSettings },
            rowFields: prepareFields(rowFields),
            columnFields: prepareFields(columnFields),
            measureFields: [{ ...measuredField, ...measureFieldSettings }],
            fields: fieldsState,
            uiFields: uiFields,
        } as ComponentSettingsPivot;

        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        var measureResultFields = [measuredField.reference_name],
            resultFieldAggregations = {
                [measuredField.reference_name]: measuredField.aggregation_function,
            };

        for (var c = -1; c < newSettings.columnFields.length; c++) {
            if (
                -1 == c ||
                (-1 !== c && 'Y' == newSettings.columnFields[c].compute_result_total_ind) ||
                c == newSettings.columnFields.length - 1
            ) {
                for (var r = -1; r < newSettings.rowFields.length; r++) {
                    if (
                        (-1 === r &&
                            -1 === c &&
                            (('Y' == newSettings.show_column_totals && 'Y' == newSettings.show_row_totals) ||
                                ('Y' == newSettings.show_column_totals &&
                                    'Y' == newSettings.show_column_totals_as_percent) ||
                                ('Y' == newSettings.show_row_totals &&
                                    'Y' == newSettings.show_row_totals_as_percent))) ||
                        (-1 === r && 'Y' == newSettings.show_column_totals) ||
                        (-1 === c && 'Y' == newSettings.show_row_totals) ||
                        (-1 !== c && 'Y' == newSettings.columnFields[c].compute_result_total_ind) ||
                        (-1 !== r && 'Y' == newSettings.rowFields[r].compute_result_total_ind) ||
                        (r == newSettings.rowFields.length - 1 && c == newSettings.columnFields.length - 1)
                    ) {
                        var resultFields = structuredClone(measureResultFields),
                            sortFields: Array<{ field: string; dir: OrderDirection }> = [];

                        for (var i = 0; i <= c; i++) {
                            resultFields.push(newSettings.columnFields[i].reference_name);
                            sortFields.push({
                                field: newSettings.columnFields[i].reference_name,
                                dir: newSettings.columnFields[i].sortDirection,
                            });
                        }

                        for (var i = 0; i <= r; i++) {
                            resultFields.push(newSettings.rowFields[i].reference_name);
                            sortFields.push({
                                field: newSettings.rowFields[i].reference_name,
                                dir: newSettings.rowFields[i].sortDirection,
                            });
                        }

                        newSettings.dataQueries[resultFields.join('-')] = {
                            resultFields: resultFields,
                            resultFieldAggregations: resultFieldAggregations,
                            sortFields: sortFields,
                        };
                    }
                }
            }
        }

        const validationMsg = validateHeight(newSettings, t);
        setErrorMessage(validationMsg);
        if (!validationMsg) {
            editPanelContext?.updateBlockSettings(block.uid, newSettings, true);
        }
    };

    const [unUsedFields, setUnusedFields] = useState<Array<ComponentSettingsPivotField>>([]);

    useEffect(() => {
        const usedFields = rowFields.concat(columnFields).map((field) => field.reference_name_escaped);
        // TODO: check if this is correct
        setUnusedFields(
            component.settings.fields.filter(
                (field) => !usedFields.includes(field.reference_name_escaped),
            ) as ComponentSettingsPivotField[],
        );

        // f.compute_result_total_ind = 'N';
        // f.sub_total_placement = 'top';
        // f.textAlign = 'left';
        // f.bgColor='';
        // f.textColor='';
        // f.alignTouched = false;
        // f.textBold = false;
        // f.textItalic = false;
        // f.textWrap = false;
        //
        // f.headerSettings = this.getDefaultHeaderSettings();
        // f.subtotalSettings = this.getDefaultHeaderSettings();
        // f.subtotalSettings.textAlign = 'right';
    }, [rowFields, columnFields, measuredField]);

    const showColumnTotals = elementProps.form.hookFormWatch('show_column_totals') == 'Y';
    const showRowTotals = elementProps.form.hookFormWatch('show_row_totals') == 'Y';

    const preSelectedField = rowFields.find((f) => f.reference_name == editPanelContext?.panelOptions?.editField);
    const preSelectedMeasure = editPanelContext?.panelOptions?.editMeasure == true;
    const [wasPreselectedField, setWasPreselectedField] = useState(preSelectedField != null || preSelectedMeasure);

    const [selectedRowField, setSelectedRowField] = useState<ComponentSettingsPivotField | null>(
        preSelectedField ?? null,
    );
    const [selectedColumnField, setSelectedColumnField] = useState<ComponentSettingsPivotField | null>(null);
    const [editingMeasureField, setEditingMeasureField] = useState(preSelectedMeasure);

    const handleRowApply = (field: ComponentSettingsPivotField) => {
        const list = rowFields.slice();
        const index = list.findIndex((f) => f.reference_name_escaped == field.reference_name_escaped);

        if (index == -1) {
            return;
        }
        list[index] = field;
        setRowFields(list);

        if (wasPreselectedField) {
            setWasPreselectedField(false);
        } else {
            setSelectedRowField(null);
        }
    };
    useLayoutEffect(() => {
        if ((preSelectedField != null || preSelectedMeasure) && !wasPreselectedField) {
            handleApply();
        }
    }, [wasPreselectedField]);

    const handleColumnApply = (field: ComponentSettingsPivotField) => {
        const list = columnFields.slice();
        const index = list.findIndex((f) => f.reference_name_escaped == field.reference_name_escaped);

        if (index == -1) {
            return;
        }
        list[index] = field;
        setColumnFields(list);
        setSelectedColumnField(null);
    };

    const [measureFieldSettings, setMeasureFieldSettings] = useState({
        bgColor: measuredField?.bgColor,
        textWrap: measuredField?.textWrap,
        textBold: measuredField?.textBold,
        textItalic: measuredField?.textItalic,
        textColor: measuredField?.textColor,
        textAlign: measuredField?.textAlign ?? 'right',
        formattingRules: measuredField?.formattingRules,
    });

    const getSectionIcon = (iconName: string) => {
        // @ts-ignore
        const componentSvg = All[iconName];

        return (
            <SvgIcon
                className={'svg-icon-mi'}
                component={componentSvg}
                inheritViewBox
                sx={{
                    fontSize: '40px',
                    fill: (theme: any) => useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    color: (theme: any) => theme.palette.primary.main,
                }}
            />
        );
    };
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            {selectedRowField == null && selectedColumnField == null && !editingMeasureField && (
                <div style={{ overflow: 'scroll' }}>
                    <Stack height={1}>
                        {/*Main Panel*/}
                        <Box flexShrink={0}>
                            <BlockEditPanelHeader
                                title={t('pivot.edit_title')}
                                onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                            />
                        </Box>
                        {/*@ts-ignore*/}
                        <Stack ref={editPanel} sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                            {errorBlock}
                            <Box>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        component: { component: 'FormText', name: 'title', label: t('title') },
                                    })}
                                />
                            </Box>
                            <FontSize elementProps={elementProps} t={t} />
                            <Stack direction={'row'}>
                                <Box sx={{ pr: 1, pt: 1.5, flexShrink: 0 }}>{getSectionIcon('Rows')}</Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <FormLabel>{t('rows')}</FormLabel>
                                    <RowColumnFieldsList
                                        onEditClick={(fieldIndex) => setSelectedRowField(rowFields[fieldIndex])}
                                        unusedFields={unUsedFields.filter(
                                            (f) => f.reference_name_escaped != measuredField.reference_name_escaped,
                                        )}
                                        fields={rowFields}
                                        onChange={setRowFields}
                                        type={'row'}
                                    />
                                </Box>
                            </Stack>
                            <Divider />
                            <Stack direction={'row'}>
                                <Box sx={{ pr: 1, pt: 1.5, flexShrink: 0 }}>{getSectionIcon('Columns')}</Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <FormLabel>{t('columns_header')}</FormLabel>
                                    <RowColumnFieldsList
                                        onEditClick={(fieldIndex) => setSelectedColumnField(columnFields[fieldIndex])}
                                        unusedFields={unUsedFields.filter(
                                            (f) => f.reference_name_escaped != measuredField.reference_name_escaped,
                                        )}
                                        fields={columnFields}
                                        onChange={setColumnFields}
                                        type={'column'}
                                    />
                                </Box>
                            </Stack>
                            <Divider />
                            <Stack direction={'row'}>
                                <Box sx={{ pr: 1, pt: 1.5, flexShrink: 0 }}>{getSectionIcon('Measure')}</Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <AggregationField
                                        label={t('measure')}
                                        allowedFields={datasetFields.filter(
                                            (d) =>
                                                unUsedFields.findIndex(
                                                    (u) => u.reference_name_escaped == d.reference_name_escaped,
                                                ) != -1,
                                        )}
                                        measuredField={{ ...measuredField, ...measureFieldSettings }}
                                        setMeasuredField={setMeasuredField}
                                        fieldToMeasuredField={fieldToPivotMeasuredField}
                                    >
                                        <Box sx={{ flexShrink: 0 }}>
                                            <IconButton onClick={() => setEditingMeasureField(true)}>
                                                <IconMi icon="gear" fontSize="16" />
                                            </IconButton>
                                        </Box>
                                    </AggregationField>
                                </Box>
                            </Stack>
                            <Divider />
                            <Box>
                                <Stack direction={'row'}>
                                    <Box sx={{ pr: 1, pt: 1.5, flexShrink: 0 }}>{getSectionIcon('TotalColumns')}</Box>
                                    <Stack sx={{ flexGrow: 1 }}>
                                        <FormLabel>{t('pivot.include_totals_for')}</FormLabel>
                                        <Stack sx={{ flexGrow: 1 }} spacing={2}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Box sx={{ width: '112px' }}>
                                                    <EditPanelCheckBox
                                                        elementProps={elementProps}
                                                        name={'show_column_totals'}
                                                        label={t('columns_header')}
                                                        disabled={columnFields.length == 0}
                                                    />
                                                </Box>
                                                <Box
                                                    className={!showColumnTotals ? 'd-none' : ''}
                                                    sx={{ width: '140px', flexShrink: 0 }}
                                                >
                                                    <ReactHookFormController
                                                        componentValues={[
                                                            {
                                                                label: 'bottom of table',
                                                                value: 'bottom',
                                                            },
                                                            {
                                                                label: 'top of table',
                                                                value: 'top',
                                                            },
                                                        ]}
                                                        elementProps={prepareFormElementProps({
                                                            ...elementProps,
                                                            ...{
                                                                component: {
                                                                    component: 'FormSelect',
                                                                    name: 'column_total_placement',
                                                                } as RawFormComponentType,
                                                            },
                                                        })}
                                                    />
                                                </Box>
                                                <Stack
                                                    className={!showColumnTotals ? 'd-none' : ''}
                                                    direction={'row'}
                                                    flexWrap={'nowrap'}
                                                >
                                                    <StaticAddon>{t('label')}</StaticAddon>
                                                    <Box flexGrow={1}>
                                                        <ReactHookFormController
                                                            elementProps={prepareFormElementProps({
                                                                ...elementProps,
                                                                component: {
                                                                    component: 'FormText',
                                                                    name: 'column_total_label',
                                                                    label: '',
                                                                },
                                                            })}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                            <Stack
                                                className={!showColumnTotals ? 'd-none' : ''}
                                                direction={'row'}
                                                alignItems={'center'}
                                                spacing={1}
                                            >
                                                <Box>{t('formatting')}</Box>
                                                <Box>
                                                    <FieldTextFormattingLine
                                                        field={columnTotalsSettings}
                                                        onChange={(fieldFormatting) =>
                                                            setColumnTotalsSettings(fieldFormatting)
                                                        }
                                                        hasAlign
                                                    />
                                                </Box>
                                            </Stack>
                                            <Box className={!showColumnTotals ? 'd-none' : ''}>
                                                <EditPanelCheckBox
                                                    elementProps={elementProps}
                                                    name={'show_column_totals_as_percent'}
                                                    label={t('pivot.show_column_totals_as_percent')}
                                                />
                                            </Box>
                                        </Stack>
                                        <Divider sx={{ my: 2 }} />
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'}>
                                    <Box sx={{ pr: 1, mt: '-5px', flexShrink: 0 }}>{getSectionIcon('TotalRows')}</Box>
                                    <Stack sx={{ flexGrow: 1 }} spacing={2}>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Box sx={{ width: '112px' }}>
                                                <EditPanelCheckBox
                                                    elementProps={elementProps}
                                                    name={'show_row_totals'}
                                                    label={t('rows')}
                                                    disabled={rowFields.length == 0}
                                                />
                                            </Box>
                                            <Box
                                                className={!showRowTotals ? 'd-none' : ''}
                                                sx={{ width: '140px', flexShrink: 0 }}
                                            />

                                            <Stack
                                                className={!showRowTotals ? 'd-none' : ''}
                                                direction={'row'}
                                                flexWrap={'nowrap'}
                                            >
                                                <StaticAddon>{t('label')}</StaticAddon>
                                                <Box flexGrow={1}>
                                                    <ReactHookFormController
                                                        elementProps={prepareFormElementProps({
                                                            ...elementProps,
                                                            component: {
                                                                component: 'FormText',
                                                                name: 'row_total_label',
                                                                label: '',
                                                            },
                                                        })}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            className={!showRowTotals ? 'd-none' : ''}
                                            direction={'row'}
                                            alignItems={'center'}
                                            spacing={1}
                                        >
                                            <Box>{t('formatting')}</Box>
                                            <Box>
                                                <FieldTextFormattingLine
                                                    field={rowTotalsSettings}
                                                    onChange={(fieldFormatting) =>
                                                        setRowTotalsSettings(fieldFormatting)
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                        <Box className={!showRowTotals ? 'd-none' : ''}>
                                            <EditPanelCheckBox
                                                elementProps={elementProps}
                                                name={'show_row_totals_as_percent'}
                                                label={t('pivot.show_column_totals_as_percent')}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Divider />
                            <ShowInViewerHeight elementProps={elementProps} t={t} />
                            <Box>
                                <EditPanelCheckBox
                                    elementProps={elementProps}
                                    name={'include_in_email'}
                                    label={t('include_in_email')}
                                />
                            </Box>
                            <IncludeAsAttachment
                                componentName={component.internalName}
                                elementProps={elementProps}
                                t={t}
                            />
                            <Box>
                                <EditPanelCheckBox
                                    elementProps={elementProps}
                                    name={'zebra_striping'}
                                    label={t('dataset.display_banded_rows')}
                                />
                            </Box>
                            <GetEmbedCode
                                contentSettings={contentSettings}
                                height={actualHeight}
                                blockUID={block.uid}
                                t={t}
                            />
                        </Stack>
                        <Box flexShrink={0}>
                            <BlockEditPanelControls
                                onApply={handleApply}
                                onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                            />
                        </Box>
                    </Stack>
                </div>
            )}
            {selectedRowField != null && (
                <PivotFieldForm
                    type="row"
                    isLast={
                        rowFields.findIndex(
                            (f) => f.reference_name_escaped == selectedRowField.reference_name_escaped,
                        ) ==
                        rowFields.length - 1
                    }
                    onApply={(field) => handleRowApply(field as ComponentSettingsPivotField)}
                    onCancel={() => {
                        if (wasPreselectedField) {
                            setWasPreselectedField(false);
                        } else {
                            setSelectedRowField(null);
                        }
                    }}
                    editField={selectedRowField}
                />
            )}
            {selectedColumnField != null && (
                <PivotFieldForm
                    type="column"
                    isLast={
                        columnFields.findIndex(
                            (f) => f.reference_name_escaped == selectedColumnField.reference_name_escaped,
                        ) ==
                        columnFields.length - 1
                    }
                    onApply={(field) => handleColumnApply(field as ComponentSettingsPivotField)}
                    onCancel={() => setSelectedColumnField(null)}
                    editField={selectedColumnField}
                />
            )}
            {editingMeasureField && (
                <PivotFieldForm
                    type="measure"
                    isLast
                    onApply={(field) => {
                        setMeasureFieldSettings({
                            bgColor: field?.bgColor,
                            textWrap: field?.textWrap,
                            textBold: field?.textBold,
                            textItalic: field?.textItalic,
                            textColor: field?.textColor,
                            textAlign: field?.textAlign ?? 'right',
                            formattingRules: field?.formattingRules,
                        });
                        // setMeasuredField(field as ComponentSettingsPivotMeasureField);
                        if (wasPreselectedField) {
                            setWasPreselectedField(false);
                        } else {
                            setEditingMeasureField(false);
                        }
                    }}
                    onCancel={() => {
                        if (wasPreselectedField) {
                            setWasPreselectedField(false);
                        } else {
                            setEditingMeasureField(false);
                        }
                    }}
                    editField={{ ...measuredField, ...measureFieldSettings }}
                />
            )}
        </>
    );
}
