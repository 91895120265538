import React from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useContentWorkflowFieldsValidation } from 'form-templates/hooks/useContentWorkflow';
import { ActSubmit } from 'components/common/form/act';
import useBundleTranslation from 'i18n';

export default function ExtReport(props: IFormLayoutProps) {
    const { t } = useBundleTranslation('app/editor/element/extreport');
    const { workflowFieldsValidation } = useContentWorkflowFieldsValidation(props.hookForm.form, t);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                const workflowValidation = await workflowFieldsValidation();
                if (!workflowValidation) {
                    return Promise.resolve({ data: { status: 'CANCELED' } });
                }
                return super.exec(data);
            }
        },
    );

    return <FormLayoutComposer props={props} />;
}
