import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import Infobar from './infobar';
import { datasetViewAPI } from '../../api/viewer/dataset';
import DatasetMainInfo from './dataset-main-info';
import DatasetTable from './dataset-table';
import FollowingTables from './following-tables';
import { VirtualDatasetColumns } from './index';
import { useQuery } from '@tanstack/react-query';
import { DatasetData, DatasetInfo } from '../dataset-viewer';
import { APIResponse } from 'tools/types';

function VirtualDatasetViewer({
    datasetInfoData,
    datasetData,
    filterId,
    datasetId,
}: {
    datasetInfoData: DatasetInfo;
    datasetData: DatasetData;
    filterId: number;
    datasetId: number;
}) {
    const { data: columnsData, refetch: fetchDatasetData } = useQuery<APIResponse<VirtualDatasetColumns[]>, Error>({
        queryKey: ['fetchVirtualDatasetColumns'],
        queryFn: () => datasetViewAPI.getVirtualDatasetColumns(datasetId),
    });

    useEffect(() => {
        fetchDatasetData();
    }, [datasetId]);

    return (
        <Stack>
            <Infobar filterId={filterId} label={datasetData?.name} datasetId={datasetData?.dataset_id}></Infobar>
            <Box justifyContent="space-betwen" display="flex">
                <DatasetMainInfo datasetData={datasetData} datasetInfo={datasetInfoData} />
                {/*TODO: Probably followingTables will be implement/change in 7.0.2*/}
                {/*<FollowingTables datasetViewerData={datasetData} />*/}
            </Box>
            <DatasetTable data={columnsData?.data || []} />
        </Stack>
    );
}

export default VirtualDatasetViewer;
