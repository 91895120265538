import { DatasetCellValue } from 'components/report-content/components/dataset/index';
import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import DataRowType = ReportContentNS.DataRowType;
import DatasetCell from 'components/report-content/components/dataset/parts/DatasetCell';
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentProps = ReportContentNS.ComponentProps;

export default function DatasetLine({
    isTotalLine,
    contentSettings,
    component,
    cellStyler,
    actions,
    row,
    isEven,
    cellList,
}: ComponentProps<ComponentSettingsDataset> & {
    isTotalLine: boolean;
    cellStyler: CellStyler;
    row: DataRowType;
    index: number;
    isEven: boolean;
    cellList: Array<DatasetCellValue>;
}) {
    let left = 0;
    return (
        <span style={{ display: 'flex', width: '100%', whiteSpace: 'nowrap' }}>
            {cellList.map((cell) => {
                const fieldIndex = component.settings.fields.findIndex(
                    (f) => cell.field.reference_name == f.reference_name,
                );

                const res = (
                    <DatasetCell
                        isEven={isEven}
                        isTotalLine={isTotalLine}
                        key={cell.field.reference_name}
                        component={component}
                        contentSettings={contentSettings}
                        cell={cell}
                        row={row}
                        cellStyler={cellStyler}
                        actions={actions}
                        left={left}
                        index={fieldIndex}
                    />
                );
                left += cell.field.columnSize ?? 0;
                return res;
            })}
        </span>
    );
}
