import { TopicType } from './index';
import { Button, Stack, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { openInNewTab } from 'tools/tools';

export default function ViewAll({ sectionName, topicData }: { sectionName: string; topicData: TopicType }) {
    const { t } = useBundleTranslation('components/glossary-term/expanded');

    if (
        topicData.dashboardElementTopicAmount === 0 &&
        topicData.datasetTopicAmount === 0 &&
        topicData.notificationScheduleDistributionTopicAmount === 0 &&
        topicData.portalPageTopicAmount === 0 &&
        topicData.pluginConnectionProfileTopicAmount === 0 &&
        topicData.sourceDatabaseConnectionTopicAmount === 0
    ) {
        return null;
    }

    return (
        <Stack spacing={1}>
            <Stack direction={'row'} spacing={0.5}>
                <Typography>{t('following_use')}</Typography>
                <Typography fontWeight={600}>{`${sectionName}:`}</Typography>
            </Stack>
            {topicData.dashboardElementTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-elements-topic-amount'} spacing={0.75}>
                    <IconMi icon={'report'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.dashboardElementTopicAmount} ${
                            topicData.dashboardElementTopicAmount > 1 ? t('element') : t('element_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            {topicData.datasetTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-datasets-topic-amount'} spacing={0.75}>
                    <IconMi icon={'datasets'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.datasetTopicAmount} ${
                            topicData.datasetTopicAmount > 1 ? t('dataset') : t('dataset_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            {topicData.notificationScheduleDistributionTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-bursts-topic-amount'} spacing={0.75}>
                    <IconMi icon={'burst'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.notificationScheduleDistributionTopicAmount} ${
                            topicData.notificationScheduleDistributionTopicAmount > 1 ? t('burst') : t('burst_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            {topicData.pluginConnectionProfileTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-profile-topic-amount'} spacing={0.75}>
                    <IconMi icon={'plugin'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.pluginConnectionProfileTopicAmount} ${
                            topicData.pluginConnectionProfileTopicAmount > 1 ? t('profile') : t('profile_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            {topicData.portalPageTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-page-topic-amount'} spacing={0.75}>
                    <IconMi icon={'portal-pages'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.portalPageTopicAmount} ${
                            topicData.portalPageTopicAmount > 1 ? t('page') : t('page_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            {topicData.sourceDatabaseConnectionTopicAmount > 0 && (
                <Stack direction={'row'} key={'dashboard-source-topic-amount'} spacing={0.75}>
                    <IconMi icon={'data-source'} fontSize={'16'} />
                    <Typography>
                        {` ${topicData.sourceDatabaseConnectionTopicAmount} ${
                            topicData.sourceDatabaseConnectionTopicAmount > 1 ? t('source') : t('source_singular')
                        }`}
                    </Typography>
                </Stack>
            )}
            <Button
                variant={'outlined'}
                color={'primary'}
                onClick={() => {
                    openInNewTab(`/search/global?tag=${topicData.topicId}`);
                }}
                sx={{ width: '50%' }}
            >
                {t('view_all')}
            </Button>
        </Stack>
    );
}
