export const MuiPaperConfig = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                backgroundColor: theme.palette.background.default,
            };
        },
    },
};
