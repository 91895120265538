import { Grid, Checkbox } from '@mui/material';
import React, { useContext } from 'react';
import { ToggleCellProps } from 'components/common/grid/';
import { getIcon } from 'components/common/grid/cell';
import { SetChartStateContext } from 'components/metric/MetricViewer';

export default function ToggleEventCalendarCell({
    enabledIcon,
    disabledIcon,
    checked,
    disabled,
    data,
}: ToggleCellProps) {
    const getToggleIcon = () => {
        const iconData = checked ? enabledIcon : disabledIcon;
        return getIcon(iconData);
    };

    const setState = useContext(SetChartStateContext);

    const onClick = () => {
        setState((prevState: any) => {
            const excludedEventCalendars = (prevState?.excludedEventCalendars ?? []).slice();
            const calendarId = data?.raw_id;
            const index = excludedEventCalendars.findIndex((el: string) => String(el) == calendarId);
            if (checked) {
                if (index == -1) {
                    excludedEventCalendars.push(calendarId);
                }
            } else {
                if (index != -1) {
                    excludedEventCalendars.splice(index, 1);
                }
            }
            return {
                ...prevState,
                excludedEventCalendars: excludedEventCalendars,
            };
        });
    };

    const iconComponent = (
        <Checkbox disabled={disabled ?? false} checked={Boolean(checked)} onClick={() => onClick()} />
    );
    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'start' }}>
            {iconComponent}
        </Grid>
    );
}
