import { Box, Chip, Tooltip } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';

export default function Tags({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    return datasetInfo.tags && datasetInfo.tags.length > 0 ? (
        <Box sx={styles.flexStartWrapper}>
            <IconMi icon={'tags'} fontSize="16" />
            <Box>
                {datasetInfo.tags.map((tag, index) => {
                    return (
                        <Tooltip title={tag.name} key={`info-tag-tooltip-${index}`}>
                            <Chip label={tag.name} key={`info-tag-${index}`} color="primary" sx={styles.tagsChip} />
                        </Tooltip>
                    );
                })}
            </Box>
        </Box>
    ) : null;
}
