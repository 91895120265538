import { alpha, Theme } from '@mui/material';

export default {
    wall_tmp: {
        '.alert_fix_size': {
            fontSize: 'inherit !important',
            lineHeight: 'inherit !important',
        },
        '.wall__area': {
            //my: -1.5,
            display: 'flex',
            flexDirection: 'column',
        },
        '.wall__item': {
            cursor: 'default',
            my: 1.5,
            position: 'relative',
            border: '1px solid',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
            borderRadius: 1,

            '&:hover': {
                zIndex: 1,
                '.wall__item-content-footer': {
                    //display: 'flex',
                },
                '.wall__item-thread-block': {
                    //display: 'block',
                },
            },
            '&:hover:before': {
                /*
                content: '""',
                position: 'absolute',
                inset: -10,
                bottom: -10 + -18 + -27, //indent + content-footer + thread
                backgroundColor: 'background.default',
                zIndex: -1,
                boxShadow: '0px 3px 16px rgba(34, 34, 34, 0.24)',
                borderRadius: 2,
                */
            },
            '&--comment': {
                border: 0,
                m: 0,
            },
        },
        '.wall__item-body': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            p: 0.5,
        },
        '.wall__item-icon-block': {
            flexShrink: 0,
            mr: 1.5,
            position: 'relative',
        },
        '.wall__item-icon-holder': {
            borderRadius: 1,
            width: '32px',
            height: '32px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '.wall_comment_user_image_a': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        '.wall__item-avatar-img, .wall_comment_user_image': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        '.wall__item-follow': {
            display: 'none',
            position: 'absolute',
            right: 0,
            top: '100%',
            fontSize: '10px',
            lineHeight: 1.2,
            textAlign: 'right',
        },
        '.wall__item-icon-block:hover .wall__item-follow': {
            display: 'block',
        },
        '.wall__item-content-block': {
            flexGrow: 1,
            position: 'relative',
            overflow: 'hidden',
        },
        '.wall__item-date': {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
            fontSize: (theme: Theme) => theme.typography.fontSize - 1,
        },
        '.wall__item-text': {},
        '.wall__item--note .wall__item-text, .wall__item--comment .wall__item-text': {
            pt: 0.25,
            fontSize: (theme: Theme) => theme.typography.fontSize + 1,
        },
        '.wall__item-content-footer': {
            mx: -1,
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            /*position: 'absolute',
            left: 0,
            right: 0,
            display: 'none',
            */
            py: 0.5,
        },
        '.wall__item-content-footer-left': { display: 'flex' },
        '.wall__item-content-footer-right': { display: 'flex' },
        '.wall__item-content-footer-link': {
            mx: 1,
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: (theme: Theme) => theme.typography.fontSize - 1,
            svg: {
                mr: 1,
            },
            '&:hover': {
                color: (theme: Theme) => theme.palette.primary.main,
                cursor: 'pointer',
            },
        },
        '.wall__item-content-footer-icon-button': {
            mx: 1,
        },
        '.wall__item-thread-block': {
            /* position: 'absolute',
            left: 0,
            right: 0,
            bottom: '-45px', //own height + content-footer
            display: 'none',
            */
        },
    },
};
