import { alpha } from '@mui/material';

export const MuiButtonGroupConfig = {
    defaultProps: {
        variant: 'outlined',
        orientation: 'horizontal',
        color: 'neutral',
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
    },
    styleOverrides: {
        grouped: ({ theme }: { theme: any }) => {
            return {
                minWidth: '28px',
            };
        },
        groupedOutlined: ({ theme }: { theme: any }) => {
            return {
                '&:hover svg': {
                    color: alpha(theme.palette.text.secondary, 0.64),
                },
                '&:active svg': {
                    color: theme.palette.text.secondary,
                },
                '&:not(:last-of-type):hover': {
                    borderRightColor: 'transparent',
                },
                '&:not(:last-of-type):active': {
                    borderColor: 'currentColor',
                    zIndex: 1,
                },
            };
        },
        groupedContained: {
            '&:not(:last-of-type)': {
                borderLeftColor: 'transparent',
                borderTopColor: 'transparent',
                borderBottomColor: 'transparent',
            },
        },
    },
};
