import { instance } from 'api/api';
import { APIResponse } from 'tools/types';

export type SendNowResponse = {
    status: 'OK' | 'ERROR';
    message?: string;
};

export type LoadPreviewResponse = {
    status: 'OK' | 'ERROR';
    html?: string | { [key: string]: string };
    mode?: 'simple' | 'multi';
    message?: string;
};

export type SendBurstNowData = {
    content_type: string;
    distribution_list: (string | number)[];
    recipient_type: string;
};

export const notificationAPI = {
    async getTokenlessStatus(url: string): Promise<{ number: number }> {
        const response = await instance.get(`${url}/status`);

        return response.data.data;
    },
    async sendFavoritesDigestNow(url: string): Promise<SendNowResponse> {
        const response = await instance.post(url);

        return response.data;
    },
    async loadPreview(url: string): Promise<LoadPreviewResponse> {
        const response = await instance.get(url);

        return response.data;
    },
    async sendBurstNow(url: string, data: SendBurstNowData): Promise<APIResponse<any>> {
        const formData = new FormData();

        formData.append('content_type', data.content_type);
        formData.append('recipient_type', data.recipient_type);
        data.distribution_list.forEach((item) => {
            formData.append('distribution_list[]', String(item));
        });

        const response = await instance.post(url, formData);

        return response.data;
    },
};
