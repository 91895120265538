import { FormControlLabel, RadioGroup } from '@mui/material';
import FormRadio from './FormRadio';
import { FormComponentValue, FormControlRadioProps, FormElementControlPropsType } from './index';

const disabledStyles = {
    pointerEvents: 'none',
    opacity: 0.5,
};

export default function FormRadioGroup({ controlProps }: FormElementControlPropsType<FormControlRadioProps>) {
    return (
        <RadioGroup
            sx={controlProps.disabled ? disabledStyles : {}}
            onChange={controlProps.onChange}
            value={controlProps.value}
            row={controlProps.isRow}
            data-test={controlProps.uid}
        >
            {controlProps.data
                .filter((v) => !v.hidden)
                .map((v: FormComponentValue) => (
                    <FormControlLabel
                        hidden={v.hidden}
                        disabled={v.disabled}
                        key={v.value}
                        value={v.value}
                        disableTypography={controlProps.disabled}
                        control={<FormRadio disabled={controlProps.disabled} />}
                        label={v.label}
                    />
                ))}
        </RadioGroup>
    );
}
