import { orange } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        primary: {
            main: orange[500],
        },
        error: {
            main: '#4c19cc',
        },
    },
} as ThemeOptions;
