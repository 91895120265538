import { viewerAPI } from 'api/viewer';
import { getViewUrl } from 'tools/tools';

export type BackwardCompatibilityPageType = 'index' | 'edit' | 'custom' | 'viewer' | 'public';
export const backwardCompatibilityRedirectAliases: any = {
    datasetaccessmap: {
        newPath: '/editor/dataset-access-map',
    },
    datasetfilter: {
        newPath: '/editor/dataset-filter',
    },
    extdataset: {
        newPath: '/editor/virtual-dataset',
    },
    useraccessrequest: {
        newPath: '/editor/user/access-request',
    },
    page: {
        newPath: '/editor/page',
    },
    pagetemplate: {
        newPath: '/editor/page/template',
    },
    pagelayout: {
        newPath: '/editor/page/layout',
    },
    javascriptcharttemplatefile: {
        newPath: '/editor/global-assets/file',
    },
    'page-global-tags': {
        newPath: '/editor/page/global-asset',
    },
    topictype: {
        newPath: '/editor/glossary/section',
    },
    glossaryimport: {
        newPath: '/editor/glossary/import',
    },
    topicsync: {
        newPath: '/editor/glossary/sync',
    },
    contentworkflow: {
        newPath: '/editor/content-workflow',
        viewerPath: '/content-workflow',
    },
    dl: {
        newPath: '/notification/burst',
    },
    distributionlist: {
        newPath: '/notification/burst',
    },
    recentalerts: {
        newPath: '/editor/kpi-alert-rule',
    },
    useralerts: {
        newPath: '/editor/user-alerts',
    },
    kpialertrule: {
        newPath: '/editor/kpi-alert-rule',
    },
    user: {
        newPath: '/editor/user',
    },
    group: {
        newPath: '/editor/group',
    },
    'business-unit': {
        newPath: '/editor/business-unit',
    },
    'privilege-set': {
        newPath: '/editor/privilege-set',
    },
    userdefaults: {
        newPath: '/editor/user/defaults',
    },
    datasource: {
        newPath: '/editor/data-source',
    },
    remotecollector: {
        newPath: '/editor/remote-data-processor',
    },
    ns: {
        newPath: '/editor/schedule',
    },
    digesttemplate: {
        newPath: '/editor/email-template',
    },
    shareddrive: {
        newPath: '/editor/shared-drive',
    },
    sharepoint: {
        newPath: '/editor/ms-sharepoint',
    },
    slack: {
        newPath: '/editor/slack',
    },
    slackchannel: {
        newPath: '/editor/slack/channel',
    },
    msteams: {
        newPath: '/editor/ms-teams',
    },
    customfield: {
        newPath: '/editor/custom-field',
    },
    customfieldsection: {
        newPath: '/editor/custom-field/section',
    },
    customfieldsync: {
        newPath: '/editor/custom-field/autosync',
    },
    template: {
        newPath: '/editor/template',
    },
    eventcalendar: {
        newPath: '/editor/event-calendar',
    },
    measurementinterval: {
        newPath: '/editor/measurement-interval',
    },
    documenttype: {
        newPath: '/editor/document-type',
    },
    purltemplate: {
        newPath: '/editor/plugin-url-template',
    },
    reporttype: {
        newPath: '/editor/ext-report-type',
    },
    contenttype: {
        newPath: '/editor/ext-content-type',
    },
    exportlocation: {
        newPath: '/admin/content/export/location',
    },
    variable: {
        newPath: '/admin/system-variables',
    },
    'branding-profile': {
        newPath: '/editor/brand',
    },
    customscript: {
        newPath: '/admin/custom-script',
    },
    'certification-level-sync': {
        newPath: '/admin/certification-level/sync',
    },
    privilege: {
        newPath: '/editor/privilege',
    },
    javascriptcharttemplate: {
        newPath: '/editor/javascript-chart-template',
    },
    javascriptchartengine: {
        newPath: '/editor/javascript-chart-engine',
    },
    elementbc: {
        newPath: '/monitor',
    },
    identityprofile: {
        newPath: '/editor/identity-profile',
    },
    externalapplication: {
        newPath: '/editor/external-application',
    },
    extcontent: {
        newPath: '/editor/element/ext-content',
        viewerPath: '/ext-content',
    },
    extreport: {
        newPath: '/editor/element/extreport',
        viewerPath: '/extreport',
    },
    report: {
        newPath: '/editor/element/report',
        viewerPath: '/report',
    },
    dsreport: {
        newPath: '/editor/element/report',
    },
    metric: {
        newPath: '/editor/element/metric',
    },
    multimetric: {
        newPath: '/editor/element/metric',
    },
    chart: {
        viewerPath: '/chart',
    },
    dataset: {
        newPath: '/editor/dataset',
        viewerPath: '/dataset',
    },
    segment: {
        newPath: '/editor/segment',
    },
    javascriptcharttemplatefolder: {
        newPath: '/editor/global-assets/folder',
    },
    folder: {
        newPath: '/notification/folder',
    },
    category: {
        newPath: '/editor/category',
    },
    glossary: {
        newPath: '/editor/glossary',
    },
    topic: {
        newPath: '/editor/topic',
    },
    document: {
        newPath: '/editor/document',
    },
    'rating-prompt': {
        newPath: '/admin/rating-prompt',
    },
    announcement: {
        newPath: '/editor/announcement',
    },
    userpreferencetemplate: {
        newPath: '/editor/user/defaults/user-template',
    },
    alertpreferencetemplate: {
        newPath: '/editor/user/defaults/alert-template',
    },
    notificationpreferencetemplate: {
        newPath: '/editor/user/defaults/email-template',
    },
    source: {
        newPath: '/editor/data-source',
    },
    pluginconnectionprofile: {
        newPath: '/editor/plugin-source',
    },
    trigger: {
        newPath: '/editor/trigger',
    },
    dependency: {
        newPath: '/editor/dependency',
    },
    storage: {
        newPath: '/admin/storage',
    },
    proxy: {
        newPath: '/editor/proxy',
    },
    emailtemplate: {
        newPath: '/editor/hf-template',
    },
    ftp: {
        newPath: '/editor/ftp',
    },
    target: {
        newPath: '/editor/target',
    },
    command: {
        newPath: '/editor/command',
    },
    searchdataset: {
        newPath: '/admin/search/dataset',
    },
    ppt: {
        newPath: '/editor/ppt',
    },
    mask: {
        newPath: '/editor/mask',
    },
    favoritenotification: {
        newPath: '/notification/favorite-notification',
    },
    alertsubscription: {
        newPath: '/notification/alert-subscription',
    },
    preference: {
        newPath: '/home/preference',
    },
    preferencemobile: {
        newPath: '/home/preference-mobile',
    },
    customattribute: {
        newPath: '/admin/custom-attribute',
    },
    foldertrash: {
        newPath: '/admin/folder-trash',
    },
    'search-external-tool': {
        newPath: '/search/external-tool',
    },
    login: {
        newPath: '/login',
    },
    'signed-out': {
        newPath: '/login',
    },
    'change-pass': {
        newPath: '/auth/change',
    },
    'reset-pass': {
        newPath: '/auth/resetpass',
    },
    deny: {
        newPath: '/auth/signed-out',
    },
    intermediatepage: {
        newPath: '/admin/intermediate-page',
    },
    dateformat: {
        newPath: '/editor/date-format',
    },
    elements: {
        newPath: '/editor',
    },
    manualmetric: {
        newPath: '/editor/element/metric',
    },
    extreportmass: {
        newPath: '/editor/ext-report-mass',
    },
    extcontentmass: {
        newPath: '/editor/ext-content-mass',
    },
    'metadata-reference': {
        newPath: '/editor/plugin-source/metadata/reference',
    },
    'metadata-dataset': {
        newPath: '/editor/plugin-source/metadata/source-dataset',
    },
    'metadata-external': {
        newPath: '/editor/plugin-source/metadata/external-source',
    },
    sintemplate: {
        newPath: '/editor/sintemplate',
    },
    import: {
        newPath: '/editor/user/import',
    },
};

export const getLegacyViewerTypeByElementType = (elementType: string) => {
    switch (elementType) {
        case 'legacy-report':
        case 'report':
        case 'internal report':
            return 'report';
        case 'metric':
        case 'external-metric':
        case 'manual-metric':
        case 'multi-metric':
            return 'chart';
        case 'external-report':
        case 'external report':
            return 'extreport';
        case 'external-content':
        case 'other external content':
            return 'extcontent';
    }
    return '';
};
export const redirectToViewerByElementType = (typePage: string, location: any, params: any) => {
    let elementId = 0;
    const elementPath = location.pathname.match(/(\/element\/([0-9]+))/);
    const segmentPath = location.pathname.match(/(\/segment\/([0-9]+))/);
    const segmentId = segmentPath?.[0] && segmentPath?.[2] ? Number(segmentPath[2]) : 0;
    switch (typePage) {
        case 'indexViewer':
            elementId = params.entityId;
            break;
        case 'elementIdViewer': {
            elementId = params.elementIdOnly;
            break;
        }
    }
    viewerAPI.loadElementInfoType(elementId, segmentId).then((data) => {
        const legacyViewerType = getLegacyViewerTypeByElementType(data.type);
        if (!legacyViewerType) {
            console.log(
                "Legacy Viewer: Don't find backward compatibility route settings for path: " + location.pathname,
            );
            document.location = '/auth/deny/type/not_found';
            return;
        } else {
            switch (typePage) {
                case 'indexViewer':
                    //Move by default legacy redirect flow
                    document.location =
                        location.pathname.replace(
                            '/index/index/index/element/',
                            `/${legacyViewerType}/index/index/element/`,
                        ) +
                        location.search +
                        location.hash;
                    return;
                    break;
                case 'elementIdViewer':
                    document.location = getViewUrl(data.type) + elementId;
                    return;
                    break;
            }
        }
    });
};

const getPageTypeState = (pageType: BackwardCompatibilityPageType) => {
    return {
        isIndex: pageType === 'index',
        isEdit: pageType === 'edit',
        isCustom: pageType === 'custom',
        isViewer: pageType === 'viewer',
        isPublic: pageType === 'public',
    };
};
export const processUrlBackwardCompatibility = (
    pageType: BackwardCompatibilityPageType,
    entityKey: string,
    location: any,
    params: any,
) => {
    let newUrl = '';
    if (entityKey) {
        const redirectSettings = backwardCompatibilityRedirectAliases[entityKey];
        if (redirectSettings) {
            newUrl = processMainPathBackwardCompatibility(pageType, entityKey, location, params);
            newUrl = processExtraPathBackwardCompatibility(pageType, newUrl, entityKey, location, params);
            newUrl = processSearchBackwardCompatibility(pageType, newUrl, entityKey, location, params);
            newUrl = processHashBackwardCompatibility(pageType, newUrl, entityKey, location, params);
        }
    }

    if (!newUrl) console.log("Don't find backward compatibility route settings for path: " + location.pathname);

    return newUrl;
};

export const processMainPathBackwardCompatibility = (
    pageType: BackwardCompatibilityPageType,
    entityKey: string,
    location: any,
    params: any,
) => {
    const redirectSettings = backwardCompatibilityRedirectAliases[entityKey];
    let newUrl = '';
    const page = getPageTypeState(pageType);

    if (!redirectSettings) return newUrl;

    switch (entityKey) {
        /*custom case*/
        case 'userpreferencetemplate':
        case 'alertpreferencetemplate':
        case 'notificationpreferencetemplate':
            if (page.isIndex) {
                newUrl = '/editor/user/defaults';
            }
            break;
        case 'source':
        case 'pluginconnectionprofile':
            if (page.isIndex) {
                newUrl = '/editor/data-source';
            }
            break;
        case 'emailtemplate':
            if (page.isIndex) {
                newUrl = '/editor/email-template';
            }
            break;
        case 'chart':
            if (page.isViewer && params?.elementViewTypeOriginal == 'preview') {
                const segmentPath = location.pathname.match(/(\/segment\/[0-9]+)/);
                const elementPath = location.pathname.match(/(\/element\/[0-9]+)/);
                const viewPath = location.pathname.match(/(\/view\/[a-zA-Z_-]+)/);
                newUrl = `/service/iframe/index/type/short${segmentPath?.[0] ?? ''}${elementPath[0]}${
                    viewPath?.[0] ?? ''
                }`;
            }
            break;
        case 'contentworkflow':
            if (page.isViewer) {
                const cwfPath = location.pathname.match(/(\/cwf\/([0-9]+))/);
                const entityIdPath = cwfPath?.[2] ? '/' + cwfPath[2] : '';
                newUrl = `${redirectSettings.viewerPath}${entityIdPath}`;
            }
            break;
        case 'dataset':
            if (page.isViewer) {
                const filterPath = location.pathname.match(/(\/filter\/[0-9]+)/);
                const instancePath = location.pathname.match(/(\/instance\/[0-9]+)/);

                newUrl = `${redirectSettings.viewerPath}/${params.entityId}${instancePath?.[0] ?? ''}${
                    filterPath?.[0] ?? ''
                }`;
            }
            break;
        case 'search-external-tool':
            if (page.isCustom) {
                newUrl = `${redirectSettings.newPath}/${params.entityId}`;
            }
            break;
        case 'extreportmass':
        case 'extcontentmass':
            if (page.isCustom) {
                const pluginPath = params.pluginAlias ? `/plugin/${params.pluginAlias}` : '';
                newUrl = `${redirectSettings.newPath}${pluginPath}`;
            }
            break;
        case 'deny':
            if (page.isPublic) {
                const typePath = params.denyType ? `/type/${params.denyType}` : '';
                newUrl = `${redirectSettings.newPath}${typePath}`;
            }
            break;
        case 'manualmetric':
            if (page.isEdit) {
                newUrl = redirectSettings.newPath + '/' + params.entityId + '/manual';
            }
            break;
        case 'metadata-reference':
        case 'metadata-dataset':
        case 'metadata-external':
            if (page.isCustom) {
                newUrl = `${redirectSettings.newPath}/${params.entityId}`;
            }
            break;
    }

    //Default behavior
    if (!newUrl) {
        if (page.isIndex) {
            newUrl = redirectSettings.newPath;
        }
        if (page.isEdit) {
            newUrl = redirectSettings.newPath + '/' + params.entityId;
        }
        if (page.isCustom) {
            newUrl = redirectSettings.newPath;
        }
        if (page.isPublic) {
            newUrl = redirectSettings.newPath;
        }
        if (page.isViewer) {
            const segmentPath = location.pathname.match(/(\/segment\/[0-9]+)/);
            const ucoPath = location.pathname.match(/(\/uco\/[0-9]+)/);
            const viewPath = location.pathname.match(/(\/view\/[a-zA-Z_-]+)/);
            const previewPath = params?.elementViewType == 'preview' ? '/preview' : '';
            if (params?.elementViewType) {
                newUrl = `${redirectSettings.viewerPath}/${params.entityId}${segmentPath?.[0] ?? ''}${
                    ucoPath?.[0] ?? ''
                }${viewPath?.[0] ?? ''}${previewPath}`;
            }
        }
    }

    return newUrl;
};

//By default ignore extra pathame parts from redirect link to new url
export const processExtraPathBackwardCompatibility = (
    pageType: BackwardCompatibilityPageType,
    url: string,
    entityKey: string,
    location: any,
    params: any,
) => {
    let newUrl = url;
    const extraPathParts = (params['*'] ?? '').split('/');
    const page = getPageTypeState(pageType);

    switch (entityKey) {
        case 'template':
            const typeIndex = extraPathParts.indexOf('type');
            if (typeIndex !== -1 && extraPathParts[typeIndex + 1]) {
                const typeValue = extraPathParts[typeIndex + 1];
                const typeAliases: any = {
                    metric: 'metric',
                    'external report': 'external-report',
                    'other external content': 'external-content',
                    segment: 'segment',
                    dataset: 'dataset',
                };
                if (typeValue && typeAliases[typeValue]) {
                    newUrl += '/' + typeAliases[typeValue];
                }
            }
            break;
        case 'useralerts':
            if (page.isIndex) {
                const userPath = location.pathname.match(/(\/user\/([0-9]+))/);
                const entityIdPath = userPath?.[2] ? '/' + userPath[2] : '';
                newUrl += entityIdPath;
            }
            break;
        case 'deny':
            if (page.isPublic) {
                newUrl += params['*'] ?? '';
            }
            break;
    }

    if (page.isViewer) {
        if (params?.elementViewType) {
            //We use these at main url or search => remove path parts like: /width/500 or /uco/234
            const removeParts: any = {
                segment: /(\/segment\/([0-9]+))/,
                uco: /(\/uco\/([0-9]+))/,
                view: /(\/view\/([a-zA-Z_-]+))/,
                width: /(\/height\/([0-9]+))/,
                height: /(\/height\/([0-9]+))/,
                block_uid: /(\/block_uid\/(block_uid_[0-9]+))/,
            };
            Object.keys(removeParts).forEach(function (paramKey: string) {
                const keyPath = location.pathname.match(removeParts[paramKey]);
                if (keyPath?.[0]) {
                    const keyIndex = extraPathParts.indexOf(paramKey);
                    if (keyIndex !== -1) extraPathParts.splice(keyIndex, 2);
                }
            });
            newUrl += '/' + extraPathParts.join('/');
        }
    }

    return newUrl;
};

//By default pass all search params from redirect link to new url
export const processSearchBackwardCompatibility = (
    pageType: BackwardCompatibilityPageType,
    url: string,
    entityKey: string,
    location: any,
    params: any,
) => {
    let newUrl = url;
    const searchParams = new URLSearchParams(location.search);
    const page = getPageTypeState(pageType);

    switch (entityKey) {
        case 'report':
            if (page.isViewer && location.pathname.includes('/index/customview/element/')) {
                if (searchParams.has('view_type')) searchParams.delete('view_type');
                searchParams.append('view_type', 'tile');
            }
            break;
    }

    if (page.isViewer) {
        if (params?.elementViewType) {
            //convert path parts to search params: /width/500 or /block_uid/block_uid_231 => &width=500 or block_uid=block_uid_231
            const addValueFromPath: any = {
                width: /(\/width\/([0-9]+))/,
                height: /(\/height\/([0-9]+))/,
                block_uid: /(\/block_uid\/(block_uid_[0-9]+))/,
            };
            Object.keys(addValueFromPath).forEach(function (paramKey: string) {
                const keyPath = location.pathname.match(addValueFromPath[paramKey]);
                if (keyPath?.[0]) {
                    const keyValue = keyPath?.[2];
                    if (searchParams.has(paramKey)) searchParams.delete(paramKey);
                    searchParams.append(paramKey, keyValue);
                }
            });

            //if iframe, remove type
            if (searchParams.has('element_type')) searchParams.delete('element_type');
        }
    }

    return newUrl + ((searchParams as any).size > 0 ? '?' + searchParams.toString() : '');
};

//By default pass all hash params from redirect link to new url
export const processHashBackwardCompatibility = (
    pageType: BackwardCompatibilityPageType,
    url: string,
    entityKey: string,
    location: any,
    params: any,
) => {
    let newUrl = url;
    let newHash = location.hash ?? '';
    const page = getPageTypeState(pageType);

    switch (entityKey) {
        case 'elementbc':
            newHash = '#bulk_changes';
            break;
    }

    return newUrl + newHash;
};
