import { Grid, Skeleton, Stack, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default function GridLoading() {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Stack
                direction={'row'}
                sx={{
                    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.06),
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    px: 1.5,
                    py: '7px',
                    borderRadius: 1,
                }}
                justifyContent={'space-between'}
                spacing={3}
            >
                <Skeleton width="30%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="60%" animation={'pulse'} />
                <Skeleton width="100%" animation={'pulse'} />
                <Skeleton width="50%" animation={'pulse'} />
                <Skeleton width="40%" animation={'pulse'} />
            </Stack>
        </Grid>
    );
}
