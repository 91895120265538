import React, { ReactElement, useLayoutEffect } from 'react';
import { Box, FormLabel, Stack } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { ReportContentNS } from 'components/report-content';
import DatasetField = ReportContentNS.DatasetField;
import AllowedMeasureFieldAggregations = ReportContentNS.AllowedMeasureFieldAggregations;
import MeasureField = ReportContentNS.MeasureField;

export default function AggregationField<T extends MeasureField>({
    measuredField,
    setMeasuredField,
    allowedFields,
    label,
    fieldToMeasuredField,
    children,
}: {
    measuredField: T;
    setMeasuredField: React.Dispatch<React.SetStateAction<T>>;
    allowedFields: Array<DatasetField>;
    label?: string;
    fieldToMeasuredField: (datasetField: DatasetField) => T;
    children?: ReactElement;
}) {
    const handleAggregationUpdate = (value: string) => {
        setMeasuredField((prevState) => {
            return { ...prevState, aggregation_function: value };
        });
    };
    const allowedAggregations = AllowedMeasureFieldAggregations.filter((f) => f.value_type == measuredField.value_type);
    const handleMeasuredFieldUpdate = (referenceName: string) => {
        const field = allowedFields.find((f) => f.reference_name == referenceName);
        if (!field) {
            return;
        }

        const mField = fieldToMeasuredField(field);
        const newAllowedList = AllowedMeasureFieldAggregations.filter((f) => f.value_type == mField.value_type);

        if (newAllowedList.map((f) => f.name).includes(measuredField.aggregation_function)) {
            mField.aggregation_function = measuredField.aggregation_function;
        }
        setMeasuredField(mField);
    };

    useLayoutEffect(() => {
        if (measuredField.aggregation_function) {
            return;
        }
        if (allowedAggregations.length) {
            handleAggregationUpdate(allowedAggregations[0].name);
        }
    }, []);

    return (
        <Box>
            <FormLabel>{label}</FormLabel>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{ width: '140px', flexShrink: 0 }} data-test={`aggregation-select`}>
                    <ReactSelect
                        data={allowedAggregations.map((f) => ({
                            label: f.label,
                            value: f.name,
                        }))}
                        value={measuredField.aggregation_function}
                        update={(value) => handleAggregationUpdate(value)}
                    />
                </Box>
                <Box>of</Box>
                <Box flexGrow={1} data-test={`reference-select`}>
                    <ReactSelect
                        data={allowedFields.map((f) => ({
                            label: f.column_name,
                            value: f.reference_name,
                        }))}
                        value={measuredField.reference_name}
                        update={(value) => handleMeasuredFieldUpdate(value)}
                    />
                </Box>
                {children}
            </Stack>
        </Box>
    );
}
