import { useHash } from 'hooks/useHash';
import React, { useEffect, useState } from 'react';
import { FormTabType } from 'components/common/form/tab';
import { formAPI } from 'api/form';
import { FormSettingsRaw, FormSettingsState } from 'components/common/form/index';

export const TabPrefix = '#top_tab_';

export interface FormUseTabs {
    formTabs: Array<FormTabType>;
    setFormTabs: (newState: Array<FormTabType>) => void;
    handleTabChange: (event: React.SyntheticEvent | null | true, newValue: number) => void;
}

export default function useFormTabs(applyHash: boolean, settings: FormSettingsRaw, entityId: string): FormUseTabs {
    const [hash, setHash] = useHash();

    // Setup tabs data from form.settings
    const [formTabs, setFormTabs] = useState<Array<FormTabType>>(
        settings.tabs.map((t: any) => ({ ...t, deps: t?.deps ?? [] })),
    );

    const setActiveTab = (newTabIndex: number) => {
        setFormTabs(
            settings.tabs.map((tab: FormTabType, index: number) => {
                tab.isActive = index == newTabIndex;
                return tab;
            }),
        );
    };

    const handleTabChange = (event: React.SyntheticEvent | null | true, newValue: number) => {
        const tab = formTabs[newValue];

        if (event != null) {
            let key = 'state_' + settings.uid;
            if (settings?.params?.state?.perElement !== false) {
                key += '_' + entityId;
            }
            // Save information about open tab
            formAPI.saveTab(key, tab.name);
        }

        if (event != null || '' == hash) {
            setActiveTab(newValue);
            if (hash == '') {
                // Need to 'replace' empty hash, to remove it from history.
                window.location.replace(window.location.href.split('#')[0] + '#' + tab.name);
            } else {
                setHash(tab.name);
            }
        }
    };

    // Process hash/active tab change
    useEffect(() => {
        if (!applyHash) {
            return;
        }

        let activeTabIndex = -1; // Tab from actual hash
        let restoredTabIndex = -1; // Saved Tab from previous visit
        let defaultTabIndex = 0; // Active Tab by default
        let restoredTabName = '';

        const formSettingsState: FormSettingsState = {
            tabs: settings?.params?.state?.tabs ?? true,
            perElement: settings?.params?.state?.perElement ?? true,
            defaultTab: settings?.params?.state?.defaultTab ?? '',
            restored: settings?.params?.state?.restored ?? false,
        };

        if (formSettingsState.restored) {
            const reg = new RegExp('^' + TabPrefix);
            restoredTabName = formSettingsState.restored.tab.replace(reg, '');
        }

        settings.tabs.forEach((tab: FormTabType, index: number) => {
            if ('#' + tab.name == hash) {
                activeTabIndex = index;
            }
            if (tab.name === restoredTabName) {
                restoredTabIndex = index;
            }
            if (tab.name == formSettingsState.defaultTab) {
                defaultTabIndex = index;
            }
        });

        if (activeTabIndex == -1 && restoredTabIndex != -1) {
            handleTabChange(null, restoredTabIndex);
        } else {
            if (activeTabIndex == -1) {
                handleTabChange(null, defaultTabIndex);
            } else {
                setActiveTab(activeTabIndex);
            }
        }
    }, [hash]);

    return { formTabs, setFormTabs, handleTabChange };
}
