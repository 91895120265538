import { FormTabType } from './index';
import { alpha, Box, Popper, Stack, Tab, Tabs, Theme } from '@mui/material';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormElementProps, FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import { getFromComponentPropsClass } from 'components/common/form/layout/control';
import { prepareFormComponentType } from 'components/common/form/formTools';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import applyComponentCondition from 'components/common/form/dependency/applyComponentCondition';
import IconMi from 'components/common/icon/IconMi';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function TabsHeader({
    tabs,
    onChange,
    formActions,
    t,
}: {
    tabs: Array<FormTabType>;
    onChange: (event: React.SyntheticEvent | null | true, newValue: number) => void;
    formActions: FormRendererAPIType;
    t: TFunction<string>;
}) {
    const activeTab = tabs.findIndex((tab) => tab.isActive);
    const activeTabRef = useRef<any>(activeTab);

    const [tabOptionsAnchor, setTabOptionsAnchor] = useState<any>(null);
    const [showMoreOption, setShowMoreOption] = useState<boolean>(false);
    const [tabsSettings, setTabsSettings] = useState<any[]>([]);
    const tabsWrapperRef = useRef<any | null>(null);

    const [depsHiddenItems, setDepsHiddenItems] = useState<number>(0);

    const getDimensionsOfTabsItems = () => {
        const dimensions: any = [];
        const wrapper = tabsWrapperRef.current;
        if (!wrapper) return dimensions;
        const items = wrapper.querySelectorAll('.MuiTab-root');
        if (items) {
            items.forEach((tab: any, index: number) => {
                dimensions.push({
                    width: tab.offsetWidth,
                    hidden: tab.classList.contains('d-none'),
                    more: false,
                    index: index,
                });
            });
        }
        return dimensions;
    };
    const adjustItemsToShow = () => {
        const wrapper = tabsWrapperRef.current;
        let dimensions = getDimensionsOfTabsItems();

        //not hidden by dependencies
        const visibleItems = dimensions.filter((item: any) => !item.hidden);

        if (wrapper && visibleItems.length) {
            const moreBtnIndex = visibleItems.length - 1;
            let itemsHolderWidth = 0; //allItems width
            visibleItems.forEach((item: any, index: number) => {
                if (index != moreBtnIndex) {
                    itemsHolderWidth += item.width;
                }
            });

            //all items fit or not
            const showMore = itemsHolderWidth > wrapper.offsetWidth;
            setShowMoreOption(showMore);

            //collect items to show and define items for more menu (if not fit)
            const activeItem = visibleItems.find((item: any) => item.index == activeTabRef.current);
            const activeItemWidth = activeItem ? activeItem.width : 0;
            let fitItemsWidth = activeItemWidth + (showMore ? dimensions[moreBtnIndex].width : 0);
            dimensions = dimensions.map((item: any, index: number) => {
                if (item.hidden || index == activeTabRef.current || index == moreBtnIndex) {
                    return item;
                } else {
                    if (fitItemsWidth + item.width < wrapper.offsetWidth) {
                        fitItemsWidth += item.width;
                        return item;
                    } else {
                        return { ...item, more: true };
                    }
                }
            });
            setTabsSettings(dimensions);
        }
    };

    const getHiddenItemsCount = () => {
        let hidden = 0;
        const wrapper = tabsWrapperRef.current;
        if (!wrapper) return;
        const items = wrapper.querySelectorAll('.MuiTab-root');
        if (items) {
            items.forEach((tab: any, index: number) => {
                if (tab.classList.contains('d-none')) hidden += 1;
            });
        }
        if (depsHiddenItems != hidden) {
            setDepsHiddenItems(hidden);
        }
    };

    // Subscribe tabs to deps fields.
    const fieldsList = useMemo(() => {
        const fieldsList: Array<string> = [];
        tabs.forEach((tab) => {
            tab.deps.forEach((dep) => {
                dep.rules.forEach((rule) => {
                    fieldsList.push(rule.field);
                });
            });
        });
        return fieldsList;
    }, []);
    formActions.hookFormWatch(fieldsList);

    const formsContext = useContext<FormsContextType>(FormsContext);

    setTimeout(() => {
        //watch change count of hidden items from deps
        getHiddenItemsCount();
    }, 200);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
        if (newValue == 'more') {
            setTabOptionsAnchor(event.currentTarget);
        } else {
            onChange(event, newValue);
        }
    };

    useEffect(() => {
        adjustItemsToShow();
        const resizeObserver = new ResizeObserver(adjustItemsToShow);
        if (tabsWrapperRef.current) {
            //adjust on resize tab container
            resizeObserver.observe(tabsWrapperRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        //set data for EventListener
        activeTabRef.current = activeTab;

        //adjust on show item from more menu
        if (!tabsSettings.length || tabsSettings.find((item) => item.more && item.index == activeTab)) {
            adjustItemsToShow();
        }
    }, [activeTab]);

    useEffect(() => {
        //adjust on deps hide/show behavior
        adjustItemsToShow();
    }, [depsHiddenItems]);

    return (
        <>
            <Box ref={tabsWrapperRef} flexGrow={1}>
                <Tabs
                    value={activeTab == -1 ? 0 : activeTab}
                    onChange={handleChangeTab}
                    className={`form-tabs ${tabs.length == 1 ? 'tabs--one-item' : undefined}`}
                >
                    {tabs.map((tab: FormTabType, index: number) => {
                        const props = {
                            form: formActions,
                            component: prepareFormComponentType({
                                name: tab.name,
                                deps: tab.deps,
                                props: tab.props,
                            } as RawFormComponentType),
                        } as FormElementProps;
                        props.componentProps = applyComponentCondition(props, formsContext);
                        const className =
                            getFromComponentPropsClass(props) +
                            ' form-tab' +
                            (tabsSettings?.[index]?.more ? ' form-tab--more-hide' : '');
                        return (
                            <Tab
                                className={className}
                                label={t(tab.title)}
                                key={tab.name}
                                data-test={'tab_' + tab.name}
                            />
                        );
                    })}
                    <Tab
                        className={
                            'form-tab ' +
                            (showMoreOption ? 'form-tab--more' : 'form-tab--more-hide') +
                            (tabOptionsAnchor ? ' more--active' : '')
                        }
                        value={'more'}
                        sx={{ px: 0.5, py: 0 }}
                        label={
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    py: '5px',
                                    px: 1,
                                    borderRadius: 1,
                                    lineHeight: '18px',
                                    '.more--active &': {
                                        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    },
                                }}
                            >
                                <Box>{t('more')}</Box>
                                <IconMi icon="more" fontSize={'16'} sx={{ ml: 1 }} />
                            </Stack>
                        }
                        key={'more'}
                    />
                </Tabs>
            </Box>
            {tabOptionsAnchor && (
                <ClickAwayListener
                    onClickAway={() => {
                        setTabOptionsAnchor(null);
                    }}
                >
                    <Popper
                        open={true}
                        anchorEl={tabOptionsAnchor}
                        placement="bottom-start"
                        sx={{
                            zIndex: (theme) => theme.zIndex.tabsPanel + 1,
                        }}
                    >
                        <Stack
                            sx={{
                                mt: -0.5,
                                ml: 0.5,
                                py: 0.5,
                                borderRadius: '4px',
                                backgroundColor: 'background.default',
                                boxShadow: (theme) => `0px 4px 16px 0px ${alpha(theme.palette.text.primary, 0.08)}`,
                            }}
                        >
                            {tabsSettings
                                .filter((item: any) => item.more)
                                .map((item, index) => {
                                    const tabData = tabs[item.index];
                                    return (
                                        <Box
                                            sx={{
                                                px: 1.5,
                                                py: '7px',
                                                '&:hover': {
                                                    color: 'primary.main',
                                                    cursor: 'pointer',
                                                    backgroundColor: (theme: Theme) =>
                                                        alpha(theme.palette.primary.main, 0.04),
                                                },
                                            }}
                                            key={tabData.name}
                                            onClick={() => {
                                                onChange(true, item.index);
                                                setTabOptionsAnchor(null);
                                            }}
                                        >
                                            {t(tabData.title)}
                                        </Box>
                                    );
                                })}
                        </Stack>
                    </Popper>
                </ClickAwayListener>
            )}
        </>
    );
}
