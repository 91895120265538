import React, { useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { formAPI } from 'api/form';
import { useQueryClient } from '@tanstack/react-query';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { createPortal } from 'react-dom';
import { ActSubmit } from 'components/common/form/act';
import { Stack, Typography } from '@mui/material';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';

export default function TargetDataImportForm(props: IFormLayoutProps) {
    const queryClient = useQueryClient();

    const { t } = useBundleTranslation('app/editor/target/data');

    const [showResults, setShowResults] = useState(false);
    const [loading, setLoading] = useState(false);

    const [importingResult, setImportingResult] = useState({
        total: 0,
        updated: 0,
        inserted: 0,
        invalid: 0,
    });

    const targetId = props.elementProps.urlParams['targetId'] ?? 0;

    const handleSubmit = async (data: any) => {
        setLoading(true);

        return formAPI
            .saveFormData(`data/editor/target/${targetId}/data/import/form`, data, props.hookForm.form)
            .catch((error) => {
                alert(error);
                closeFormPopup();
            })
            .finally(() => setLoading(false));
    };

    const closeFormPopup = () => {
        queryClient.invalidateQueries({ queryKey: ['targetDataGrid'] });
        props.asPopupProps.onHide();
    };

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                handleSubmit(data).then((response) => {
                    if (response.data.status === 'OK') {
                        setShowResults(true);
                        setImportingResult(response.data);
                    } else {
                        props.hookForm.form.formAct.act.afterSaveError.exec(response);
                    }
                });
            }
        },
    );

    const resultPopupSettings: PopupSettings = {
        title: t('importing_result.file_imported'),
        noCancel: true,
        textOK: t('importing_result.ok_button'),
    };

    const onResultHide = () => {
        setShowResults(false);
        closeFormPopup();
    };

    return (
        <>
            <FormLayoutComposer props={props} />
            {showResults && (
                <Popup
                    settings={resultPopupSettings}
                    open={showResults}
                    onHide={onResultHide}
                    onConfirm={onResultHide}
                    maxWidth="popupSm"
                >
                    <Stack direction={'column'} spacing={1}>
                        <Typography fontSize={14}>{`${t('importing_result.total')} ${
                            importingResult.total
                        }`}</Typography>
                        <Typography fontSize={14}>{`${t('importing_result.inserted')} ${
                            importingResult.inserted
                        }`}</Typography>
                        <Typography fontSize={14}>{`${t('importing_result.updated')} ${
                            importingResult.updated
                        }`}</Typography>
                        <Typography fontSize={14}>
                            {`${t('importing_result.no_processed')} ${importingResult.invalid}`}
                        </Typography>
                    </Stack>
                </Popup>
            )}
            {loading &&
                createPortal(
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: (theme) => 9999,
                        }}
                    />,
                    document.body,
                )}
        </>
    );
}
