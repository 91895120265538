import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import { Box, FormControl, FormLabel } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useBundleTranslation from 'i18n';
import { FormComponentValue } from 'components/common/form/layout/control';

export default function CompoundValueForm(props: IFormLayoutProps) {
    const infoTab = <InfoTab {...customTabProps(props, 'info')} />;

    const customProps = {
        ...props,
        customTabs: { info: infoTab },
    };

    return <FormLayoutComposer props={customProps} />;
}

function InfoTab(tabProps: ITabProps) {
    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);

    const [secondaryProcessedData, setSecondaryProcessedData] = useState<FormComponentValue[]>([]);

    const primaryValueId = hookForm.hookFormWatch('primary_segment_value_id');
    const secondaryValueId = hookForm.hookFormWatch('secondary_segment_value_id');

    const primaryValueProps = { ...getElementProps('primary_segment_value_id') };
    const secondaryValueProps = { ...getElementProps('secondary_segment_value_id') };

    const { t } = useBundleTranslation([secondaryValueProps.elementProps.translationNS ?? 'main']);

    const primaryFieldData = primaryValueProps.record?.props.component.data ?? [];
    const secondaryFieldData = secondaryValueProps.record?.props.component.data ?? [];

    useEffect(() => {
        if (primaryValueId) {
            const selectedPrimaryData = primaryFieldData.find(
                (field: { value: string; assigned_sv: string }) => field.value == primaryValueId,
            );

            if (selectedPrimaryData && selectedPrimaryData.assigned_sv) {
                const assignedSV = selectedPrimaryData.assigned_sv.split(',');

                if (assignedSV.length > 0) {
                    const newSecondaryData = secondaryFieldData.map((secondaryField: FormComponentValue) => {
                        return {
                            value: secondaryField.value,
                            label: secondaryField.label,
                            disabled: assignedSV.includes(String(secondaryField.value)),
                        };
                    });

                    if (assignedSV.includes(String(secondaryValueId))) {
                        const notAssignedSV = secondaryFieldData.filter(
                            (secondaryField: FormComponentValue) =>
                                !assignedSV.includes(String(secondaryField.value)) && secondaryField.value !== '',
                        );

                        if (notAssignedSV.length > 0) {
                            hookForm.hookFormSetValue('secondary_segment_value_id', notAssignedSV[0].value, {
                                shouldDirty: true,
                            });
                        } else {
                            hookForm.hookFormSetValue('secondary_segment_value_id', '', {
                                shouldDirty: true,
                            });
                        }
                    } else {
                        if (secondaryValueId == '') {
                            hookForm.hookFormSetValue('secondary_segment_value_id', secondaryFieldData[1].value, {
                                shouldDirty: true,
                            });
                        }
                    }

                    setSecondaryProcessedData(newSecondaryData);
                    return;
                }
            }

            if (secondaryValueId == '') {
                hookForm.hookFormSetValue('secondary_segment_value_id', secondaryFieldData[1].value, {
                    shouldDirty: true,
                });
            }
        }

        setSecondaryProcessedData(secondaryFieldData);
    }, [primaryValueId]);

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <></>
            <>
                <FormElementComponent {...primaryValueProps} />
                <FormControl fullWidth>
                    <FormLabel>{t('secondary_segment_value_id')}</FormLabel>
                    <Box>
                        <ReactSelect
                            data={secondaryProcessedData}
                            value={secondaryValueId}
                            update={(newValue) => {
                                hookForm.hookFormSetValue('secondary_segment_value_id', newValue, {
                                    shouldDirty: true,
                                });
                            }}
                        />
                    </Box>
                </FormControl>
                <FormElementComponent {...getElementProps('currency_id')} />
                <FormElementComponent {...getElementProps('visible_in_dashboard_ind')} />
            </>
        </FormTabContentDefaultStacks>
    );
}
