import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { getCachedData, getViewUrl, openInNewTab, setCachedData } from 'tools/tools';
import { Tile4FavoriteData } from 'app/home/index';
import React from 'react';
import { openExternalView } from 'app/element';

enum HierarchyFolderType {
    Category = 'Category',
    Folder = 'Folder',
    Favorite = 'Favorite',
}

export type HierarchyFolder = {
    k?: string;
    parent_k?: string;
    ancestors?: string;
    category_id?: string;
    copied_from_favorite_id?: string;
    description?: string;
    display_order?: number;
    favorite_content?: string;
    favorite_id?: number;
    folder_ancestors?: string;
    folder_id?: number;
    is_edit_access_ind?: string;
    name: string;
    order_by?: number;
    parent_category_id: number;
    parent_folder_id: number;
    shared_by?: string;
    sort_order_owner_id?: number;
    sort_order_modified_by_owner_ind?: string;
    source_propagate_display_order_changes_ind?: string;
    source_shared_favorite_id?: string;
    user_id: number;
    type: HierarchyFolderType;
    viewer_metadata: string;
    children?: HierarchyFolder[];
    childrenKeys?: string[];
};

export type TileInfo = {
    user_dashboard_element_instance_id?: string;
    user_id?: number;
    element_id?: number;
    segment_value_id?: number;
    section_type?: string;
    favorite_id?: string;
    category_id?: string;
    element_type?: string;
    in_dashboard_ind_flag?: string;
    last_stoplight_value?: string;
    is_expired?: string;
    is_owned?: string;
    topics?: number[];
    refresh_frequency_text?: string;
    refresh_frequency_sec?: string;
    description_markdown_ind?: string;
    element_info?: string;
    metric_unit_of_measure?: string;
    last_measurement_time?: string;
    external_report_display?: string;
    external_report_download_url_info?: string;
    certified_ind?: string;
    certification_level_id?: string;
    last_certified_time?: string;
    metric_display_on_tile?: string;
    metric_moving_average_interval?: string;
    metric_display_on_tile_prefix?: string;
    metric_home_page_compare_value_type?: string;
    metric_home_page_compare_line_id?: string;
    metric_home_page_compare_target_id?: string;
    report_single_unit_label?: string;
    report_multiple_units_label?: string;
    report_no_units_label?: string;
    report_on_demand_generation_ind?: string;
    certification_level_name?: string;
    certification_level_color?: string;
    last_certified_by_name?: string;
    last_certified_by_email?: string;
    business_owner?: string;
    business_owner_email?: string;
    data_steward?: string;
    data_steward_email?: string;
    technical_owner?: string;
    technical_owner_email?: string;
    data_source_name?: string;
    supports_last_refreshed_check?: string;
    display_order?: number;
    total_view_count?: string;
    in_favorites?: string;
    is_in_favorites?: string;
    is_in_folders?: string;
    mi_name?: string;
    mi_sequence?: string;
    pct_variance_text?: string;
    last_measurement_value_formatted?: string;
    last_measurement_time_formatted?: string;
    metric_last_moving_average_value_formatted?: string;
    last_updated_time?: string;
    total_forecast_amount_formatted?: string;
    last_activity_time?: string;
    last_annotation_text?: string;
    last_commentary_text?: string;
    element_dashboard_name?: string;
    element_dashboard_name_with_formatting?: string;
    is_empty_instance_ind?: string;
    metric_tile_display_pct_variance?: number;
    report_rows?: string;
    last_display_generation_time?: string;
    last_file_updated_time?: string;
    last_modified?: string;
    favorite_content?: string;
    alert_event_id?: string;
    last_alert_text?: string;
    last_alert_news_type?: string;
    is_alert_active?: string;
    is_collaborative_alert_active?: string;
    is_collaborative_annotation_active?: string;
    is_annotation_active?: string;
    is_commentary_active?: string;
    last_notable_event_activity_time?: string;
    last_user_annotation_activity_time?: string;
    last_user_note_activity_time?: string;
    last_alert_event_activity_time?: string;
    reporting_tool_name?: string;
    remove_preview_link_ind?: string;
    is_tech_editor?: string;
    external_content_type_name?: string;
    enable_click_in_mobile_ind?: string;
    content_type?: string;
    global_total_view_count?: number;
    has_access?: string;
    last_alert_text_news_type?: string;
    thumb_src?: string;
    folder_id?: number[];
    search_relevance?: number;
    hide_mtf?: string;
};

export type AccessDeniedTileInfo = {
    element_id?: number;
    segment_value_id?: number;
};

export type FolderItem = {
    element_id: number;
    segment_value_id: number;
    folder_id: number;
    folder_item_id?: number;
    display_order?: number;
};

export type TagItem = {
    topic_id: number;
    full_name: string;
};

export type DragItem = {
    tile: TileInfo;
    sectionKey: string;
    index: number;
};

export type ContentTypeItem = {
    content_type: string;
};

export type HomePageFilterState = {
    view?: 'tile' | 'list';
    groupBy?: string;
    folderSelected?: string;
    searchFilter?: string;
    tagFilter?: string[];
    segmentFilter?: string;
    keywordFilter?: string;
};

export type HomePageState = {
    expandedFolders?: { [key: string]: boolean };
    filterState?: HomePageFilterState;
    hierarchyPanelOpen?: boolean;
    hierarchyPanelWidth?: number;
};

export interface FolderMapInterface {
    [key: string]: HierarchyFolder;
}

export type HomePageCompressedData = {
    vocabulary: string[];
    rows: string[][];
};

export type HomePageDataParameters = { [key: string]: string | number | boolean };
export type HomePageDataTheme = {
    catalog_list_column_count?: number;
    catalog_list_maximum_tiles?: number;
    catalog_list_maximum_exceeded_message?: string;
    catalog_list_exceed_percent?: number;
    catalog_list_display_fav_fol_cat_icons?: string;
    catalog_list_display_lock_icon?: string;
    catalog_list_display_certification_icon?: string;
    catalog_list_display_content_type_icons?: string;
};

export type HomePageSettings = {
    tileTemplate: any;
    listTemplate: any;
    title?: string;
    parameters?: HomePageDataParameters;
    theme?: HomePageDataTheme;
};

export type HomePageData = {
    data: HomePageCompressedData;
    settings: HomePageSettings;
    hierarchyFolders: HierarchyFolder[];
    folderItems: FolderItem[];
    tags: TagItem[];
    logoImageUrl: string;
    tileIcons?: { [key: string]: string };
    contentTypes: ContentTypeItem[];
    customFieldsDisplayOnTileInfo: { [key: string]: string };
};

export type HomePageCachedData = {
    data: HomePageCompressedData;
    settings: HomePageSettings;
    hierarchyFolders: HierarchyFolder[];
    folderItems: FolderItem[];
    tags: TagItem[];
    logoImageUrl: string;
    state: HomePageState;
    tileIcons?: { [key: string]: string };
    contentTypes: ContentTypeItem[];
    customFieldsDisplayOnTileInfo: { [key: string]: string };
};

export type InfoPanelProps = {
    setMouseOverInfoIcon: (value: boolean) => void;
    updatePosition: (x: number, y: number) => void;
    setPanelContent: (content: ReactJSXElement | null) => void;
    setIsTileCertified: (value: boolean) => void;
};

export type TopicsPopupProps = {
    setShowTermsPopup: (value: boolean) => void;
    setTopicId: (value: number) => void;
};

export type CertificationLevelType = {
    certification_level_id: string;
    certification_level_name: string;
    certification_level_color: string;
};

export type HomeContextType = {
    homePageState: HomePageState;
    data: HomePageData;
    setHomePageState: (homePageState: HomePageState) => void;
    setData: (data: HomePageData) => void;
    refetchData: () => void;
    trashBucketRef: React.RefObject<HTMLDivElement> | null;
    tiles: TileInfo[];
    favorites: HierarchyFolder[];
    folders: HierarchyFolder[];
    categories: HierarchyFolder[];
    folderMap: FolderMapInterface;
    tilePreview: TileInfo | null;
    setTilePreview: (tile: TileInfo | null) => void;
    setAccessDeniedPopupTile: (tile: AccessDeniedTileInfo | null) => void;
    setVisibleTiles: (tiles: TileInfo[]) => void;
    handleContextMenu: (event: React.MouseEvent<HTMLElement>, tile: TileInfo) => void;
    setTile4Favorite: (data: Tile4FavoriteData | null) => void;
    groupByOptions: SelectOption[];
    segmentOptions: SelectOption[];
    tagOptions: SelectOption[];
    isEmbedded: boolean;
    navigationBack: boolean;
    newTab: boolean;
    topicPopupProps: TopicsPopupProps;
};

export type ContextMenuType = {
    mouseX: number;
    mouseY: number;
} | null;

export const homePageDefaultSettings = {
    tileTemplate: {},
    listTemplate: {},
};

export const getCachedDashboardData = function (projectVersion: string): HomePageCachedData | null {
    return getCachedData(projectVersion, 'dashboard_data');
};

export const getCachedDashboardStateData = function (projectVersion: string): HomePageState | null {
    return getCachedData(projectVersion, 'dashboard_state');
};

export const setCachedDashboardData = function (
    str: string,
    isCompress: string,
    projectVersion: string,
    itemId: string = 'dashboard_data',
): void {
    return setCachedData(str, isCompress, projectVersion, itemId);
};

export const setCachedDashboardStateData = function (
    str: string,
    isCompress: string,
    projectVersion: string,
    itemId: string = 'dashboard_state',
): void {
    return setCachedData(str, isCompress, projectVersion, itemId);
};

export const getHashFromState = function (state: HomePageState): string {
    if (
        (undefined === state.filterState?.folderSelected ||
            state.filterState?.folderSelected == '' ||
            state.filterState?.folderSelected == 'category_0') &&
        state.filterState?.view == 'tile'
    )
        return '';

    return undefined !== state.filterState?.folderSelected &&
        state.filterState?.folderSelected > '' &&
        state.filterState?.folderSelected != 'category_0'
        ? '/' + (state.filterState?.view ?? 'tile') + '/' + state.filterState?.folderSelected.replace('_', '/')
        : '';
};

export type SelectOption = {
    value: string;
    label: string;
};

export const extendStateFromHash = function (state: HomePageState, hash: string): HomePageState {
    let match = hash.match(/\/(tile|list)?\/?(category|favorite|folder)?\/?([^\/]+)?/);

    if (match && match.length > 3) {
        let newState = { ...state };
        if (undefined === newState.filterState) newState.filterState = {};

        newState.filterState.view = 'tile' === match[1] ? 'tile' : 'list';
        if (match[2] && match[3]) newState.filterState.folderSelected = match[2] + '_' + match[3];
        return newState;
    }

    return state;
};

export const buildFolderMap = function (
    hierarchyFolders: HierarchyFolder[],
): [HierarchyFolder[], HierarchyFolder[], HierarchyFolder[], FolderMapInterface] {
    let favorites: HierarchyFolder[] = [];
    let folders: HierarchyFolder[] = [];
    let categories: HierarchyFolder[] = [];

    let folderMap: FolderMapInterface = {};
    for (let h of hierarchyFolders) {
        h.children = [];
    }

    for (let h of hierarchyFolders) {
        if ('Favorite' == h.type) {
            h.k = 'favorite_' + h.favorite_id;
            favorites.push(h);
            folderMap[h.k] = favorites[favorites.length - 1];
        } else if ('Folder' == h.type) {
            h.k = 'folder_' + h.folder_id;
            if (h.parent_folder_id == null || h.parent_folder_id == 0) {
                folders.push(h);
                folderMap[h.k] = folders[folders.length - 1];
            } else {
                h.parent_k = 'folder_' + h.parent_folder_id;
                let parentFolder = folderMap[h.parent_k];
                if (parentFolder) {
                    parentFolder.children = parentFolder.children || [];
                    parentFolder.children.push(h);

                    folderMap[h.k] = parentFolder.children[parentFolder.children.length - 1];

                    parentFolder.childrenKeys = parentFolder.childrenKeys || [];
                    parentFolder.childrenKeys.push(h.k);
                    while (parentFolder.parent_k && undefined !== folderMap[parentFolder.parent_k]) {
                        parentFolder = folderMap[parentFolder.parent_k];
                        parentFolder.childrenKeys = parentFolder.childrenKeys || [];
                        parentFolder.childrenKeys.push(h.k);
                    }
                }
            }
        } else if ('Category' == h.type) {
            h.k = 'category_' + h.category_id;
            if (h.parent_category_id == null || h.parent_category_id == 0) {
                categories.push(h);
                folderMap[h.k] = categories[categories.length - 1];
            } else {
                h.parent_k = 'category_' + h.parent_category_id;
                let parentFolder = folderMap[h.parent_k];
                if (parentFolder) {
                    parentFolder.children = parentFolder.children || [];
                    parentFolder.children.push(h);

                    folderMap[h.k] = parentFolder.children[parentFolder.children.length - 1];

                    parentFolder.childrenKeys = parentFolder.childrenKeys || [];
                    parentFolder.childrenKeys.push(h.k);
                    while (parentFolder.parent_k && undefined !== folderMap[parentFolder.parent_k]) {
                        parentFolder = folderMap[parentFolder.parent_k];
                        parentFolder.childrenKeys = parentFolder.childrenKeys || [];
                        parentFolder.childrenKeys.push(h.k);
                    }
                }
            }
        }
    }

    return [favorites, folders, categories, folderMap];
};

export const decompressTiles = function (data: any): TileInfo[] {
    let folderItemsMap: { [key: string]: number[] } = {};
    if (data && data.folderItems) {
        for (const fi of data.folderItems) {
            if (undefined === folderItemsMap[fi.element_id + '_' + fi.segment_value_id])
                folderItemsMap[fi.element_id + '_' + fi.segment_value_id] = [];

            folderItemsMap[fi.element_id + '_' + fi.segment_value_id].push(fi.folder_id);
        }
    }

    let tiles: TileInfo[] = [];
    if (data && data.data && data.data.rows) {
        for (let row of data.data.rows) {
            let tile: TileInfo = {};
            for (let i = 0; i < data.data.vocabulary!.length; i++) {
                //@ts-ignore
                tile[data.data.vocabulary[i]] = row[i];
            }

            tile.folder_id =
                undefined !== folderItemsMap[tile.element_id + '_' + tile.segment_value_id]
                    ? folderItemsMap[tile.element_id + '_' + tile.segment_value_id]
                    : [];
            tiles.push(tile);
        }
    }

    return tiles;
};

export const compressTiles = function (tiles: TileInfo[], vocabulary: any[]): any[] {
    let rows = [];
    for (let r of tiles) {
        let row = [];
        for (let i = 0; i < vocabulary.length; i++) {
            //@ts-ignore
            row.push(r[vocabulary[i]]);
        }

        rows.push(row);
    }

    return rows;
};

export const openTileUrl = async function (
    tile: TileInfo,
    navigate: NavigateFunction,
    e?: React.MouseEvent,
    newTab?: boolean,
): Promise<any> {
    if ('external' == tile.external_report_display) {
        await openExternalView(tile.element_id ?? 0, tile.segment_value_id ?? 0);
    } else {
        let url =
            getViewUrl(tile.element_type || '') +
            tile.element_id +
            (tile.segment_value_id ? '/segment/' + tile.segment_value_id : '');

        if ((undefined !== e && e.ctrlKey) || (undefined !== newTab && true === newTab)) openInNewTab(url);
        else return navigate(url);
    }
};

export const getEmptyMessage = function (
    homePageState: HomePageState,
    t: TFunction,
    folderMap: FolderMapInterface,
): string {
    let emptyText = '';
    let categorySelected = '';

    let hasFilter =
        undefined != homePageState.filterState &&
        ((undefined != homePageState.filterState?.segmentFilter && homePageState.filterState?.segmentFilter > '') ||
            (undefined != homePageState.filterState?.tagFilter && homePageState.filterState?.tagFilter?.length > 0));
    let criteriaText = hasFilter ? t('empty_text.criteria') : '';

    if (
        undefined !== homePageState.filterState &&
        undefined !== homePageState.filterState?.folderSelected &&
        homePageState.filterState?.folderSelected > ''
    ) {
        if (undefined !== folderMap[homePageState.filterState?.folderSelected])
            categorySelected = folderMap[homePageState.filterState?.folderSelected].name;

        if (
            homePageState.filterState?.folderSelected?.startsWith('favorite_') ||
            homePageState.filterState?.folderSelected?.startsWith('folder_')
        )
            emptyText = t('empty_text.alert_dashboard_empty_text_1', {
                categoryName: categorySelected,
                criteria: criteriaText,
            });
        else if (homePageState.filterState?.folderSelected?.startsWith('category_'))
            emptyText = t('empty_text.alert_dashboard_empty_text_2', {
                categoryName: categorySelected,
                criteria: criteriaText,
            });
        else if (!hasFilter && 'elements_i_own' == homePageState.filterState?.segmentFilter)
            emptyText = t('empty_text.alert_dashboard_empty_text_3');
    }

    if ('' == emptyText) {
        if (hasFilter) emptyText = t('empty_text.alert_dashboard_empty_text_4');
        else emptyText = t('empty_text.alert_dashboard_empty_text_5');
    }

    return emptyText;
};

export const getGroupByOptions = function (data: HomePageData, t: TFunction): { value: string; label: string }[] {
    let groupByOptions = [
        { value: '', label: t('group_by_label', { label: t('folders') }) },
        { value: 'Content', label: t('group_by_label', { label: t('content_type') }) },
        { value: 'MI', label: t('group_by_mi_name', { label: t('mi_name') }) },
        { value: 'Tag', label: t('group_by_label', { label: t('tag') }) },
        { value: 'Freq', label: t('group_by_label', { label: t('update_frequency') }) },
        { value: 'Stoplight', label: t('group_by_label', { label: t('stoplight_performance') }) },
        { value: 'LastModified', label: t('group_by_label', { label: t('last_content_modified') }) },
        { value: 'None', label: t('group_by_none', { label: t('none') }) },
    ];

    if ('Y' == data?.settings?.parameters?.ENABLE_CERTIFICATION_FILTERING) {
        groupByOptions.push({ value: 'Certified', label: t('group_by_label', { label: t('certification') }) });
    }

    return groupByOptions;
};
export const getSegmentOptions = function (
    data: HomePageData,
    tiles: TileInfo[],
    t: TFunction,
): { value: string; label: string }[] {
    let stoplight: { [key: string]: number } = {};
    let certified: { [key: string]: number } = {};
    let contentTypes: { [key: string]: number } = {};
    let own = 0;
    let cl: { [key: string]: CertificationLevelType } = {};

    for (let i = 0; i < tiles.length; i++) {
        if (tiles[i].last_stoplight_value ?? 0 > 0) stoplight[tiles[i].last_stoplight_value ?? 0] = 1;
        if (tiles[i].certified_ind) certified[tiles[i].certified_ind ?? ''] = 1;

        if (
            undefined !== tiles[i].certification_level_id &&
            null !== tiles[i].certification_level_id &&
            undefined === cl[tiles[i].certification_level_id ?? '']
        )
            cl[String(tiles[i].certification_level_id)] = {
                certification_level_id: tiles[i].certification_level_id ?? '',
                certification_level_name: tiles[i].certification_level_name ?? '',
                certification_level_color: tiles[i].certification_level_color ?? '',
            };

        if (tiles[i].content_type) contentTypes[tiles[i].content_type ?? ''] = 1;
        if (0 == own && '1' == (tiles[i].is_owned ?? '0')) own = 1;
    }

    const certificationLevels: CertificationLevelType[] = Object.values(cl);
    let segmentOptions: { value: string; label: string; valueGroup?: any }[] = [];

    if (data?.contentTypes && data?.contentTypes.length > 0)
        for (const cti of data?.contentTypes)
            if (undefined !== contentTypes[cti.content_type])
                segmentOptions.push({
                    value: 'content_' + cti.content_type,
                    label: cti.content_type,
                    valueGroup: t('content'),
                });

    if (1 == own) segmentOptions.push({ value: 'owned', label: t('elements_i_own') });

    if ('Y' == data?.settings?.parameters?.ENABLE_CERTIFICATION_FILTERING) {
        if (undefined !== certified['Y'])
            segmentOptions.push({ value: 'certified_Y', label: t('certified'), valueGroup: t('certification') });

        if ('Y' == data?.settings?.parameters?.ENABLE_CERTIFICATION_LEVELS)
            for (const cl of certificationLevels)
                segmentOptions.push({
                    value: 'certification_level_' + cl.certification_level_id,
                    label: cl.certification_level_name,
                    valueGroup: t('certification'),
                });

        if (undefined !== certified['N'])
            segmentOptions.push({ value: 'certified_N', label: t('uncertified'), valueGroup: t('certification') });
    }

    return segmentOptions;
};
