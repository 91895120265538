import { useBlendColors } from 'hooks/useDesign';
import { alpha, Theme } from '@mui/material';

export default {
    paddingLeft: '8px',
    ml: -1,
    backgroundColor: 'background.default',
    '&.tree-mi__item-selected': {
        backgroundColor: (theme: Theme) => useBlendColors(alpha(theme.palette.primary.main, 0.08)),
        color: 'primary.main',
    },
    '&.tree-mi__item-content-element:hover': {
        backgroundColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.04)),
        cursor: 'pointer',
    },
};
