import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssocArray } from 'tools/types';

export interface reportPaletteState {
    palette: AssocArray<string>;
}

export const reportPaletteSlice = createSlice({
    name: 'form',
    initialState: {
        palette: {},
    } as reportPaletteState,
    reducers: {
        setPaletteColor: (state, action: PayloadAction<{ elementId: number; name: string; color: string }>) => {
            const key = action.payload.elementId + '_' + action.payload.name;
            state.palette[key] = action.payload.color;
        },
    },
});

export const { setPaletteColor } = reportPaletteSlice.actions;

export default reportPaletteSlice.reducer;
