import { ReportContentNS } from 'components/report-content/index';
import React from 'react';
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotEditPanel from 'components/report-content/components/pivot/edit-panel/PivotEditPanel';
import Pivot from 'components/report-content/components/pivot/Pivot';
import ResetPivotColumnsSize from 'components/report-content/components/pivot/controls/ResetPivotColumnsSize';

export default class PivotComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsPivot> {
    getComponent(editPanel?: any) {
        return (
            <Pivot
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
                blockId={this.block.uid}
                editPanel={editPanel}
            />
        );
    }

    getComponentControls() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <ResetPivotColumnsSize
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <PivotEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
