import { FormDataAPIType } from 'components/common/form';
import React from 'react';
import { ReportContentNS } from 'components/report-content';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import { SortListType } from 'components/report-content/components/dataset/edit-panel/FieldsList';

const formFieldPrefix = '__field__name_';

export function getFieldOptionForFormName(field: ComponentSettingsDatasetField, option: string) {
    return formFieldPrefix + field.column_name + '_' + option;
}

export interface FieldSortingContextType {
    elementProps: FormDataAPIType;
    selectField: (field: ComponentSettingsDatasetField) => void;
    onSortEnd: (oldIndex: number, newIndex: number, reference?: string, listType?: SortListType) => void;
}
export const FieldSortingContext = React.createContext<FieldSortingContextType | null>(null);
