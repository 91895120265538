import { alpha } from '@mui/material';
import { lighten } from '@mui/system/colorManipulator';

export const MuiSwitchConfig = {
    defaultProps: {
        disableRipple: true,
    },
    variants: [
        {
            props: { color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        '.MuiSwitch-switchBase:not(.Mui-disabled):not(.Mui-checked) > .MuiSwitch-thumb': {
                            backgroundColor: theme.palette.success.main,
                        },
                        '.MuiSwitch-switchBase:not(.Mui-disabled) + .MuiSwitch-track': {
                            backgroundColor: alpha(theme.palette.success.main, 0.02),
                            borderColor: theme.palette.success.main,
                        },
                        '.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
                            backgroundColor: lighten(theme.palette.success.main, 0.24),
                            borderColor: lighten(theme.palette.success.main, 0.24),
                        },
                    },
                    '.MuiFormControlLabel-root:hover > & + .MuiFormControlLabel-label': {
                        color: theme.palette.success.main,
                    },
                    '.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
                        backgroundColor: theme.palette.success.main,
                        borderColor: theme.palette.success.main,
                    },
                };
            },
        },
        {
            props: { color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        '.MuiSwitch-switchBase:not(.Mui-disabled):not(.Mui-checked) > .MuiSwitch-thumb': {
                            backgroundColor: theme.palette.error.main,
                        },
                        '.MuiSwitch-switchBase:not(.Mui-disabled) + .MuiSwitch-track': {
                            backgroundColor: alpha(theme.palette.error.main, 0.02),
                            borderColor: theme.palette.error.main,
                        },
                        '.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
                            backgroundColor: lighten(theme.palette.error.main, 0.24),
                            borderColor: lighten(theme.palette.error.main, 0.24),
                        },
                    },
                    '.MuiFormControlLabel-root:hover > & + .MuiFormControlLabel-label': {
                        color: theme.palette.error.main,
                    },
                    '.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
                        backgroundColor: theme.palette.error.main,
                        borderColor: theme.palette.error.main,
                    },
                };
            },
        },
    ],
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                padding: '6px 8px',
                height: theme.size.defaultHeight,
                width: 48,
                '&:hover': {
                    '.MuiSwitch-switchBase:not(.Mui-disabled):not(.Mui-checked) > .MuiSwitch-thumb': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    '.MuiSwitch-switchBase:not(.Mui-disabled) + .MuiSwitch-track': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.02),
                        borderColor: theme.palette.primary.main,
                    },
                    '.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
                        backgroundColor: lighten(theme.palette.primary.main, 0.24),
                        borderColor: lighten(theme.palette.primary.main, 0.24),
                    },
                },
                '.MuiFormControlLabel-root:hover > & + .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                },
            };
        },
        switchBase: ({ theme }: { theme: any }) => {
            return {
                padding: '9px 11px',
                '&:hover, &.Mui-checked:hover': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        opacity: 1,
                    },
                },
                '&.Mui-disabled': {
                    '& + .MuiSwitch-track': {
                        backgroundColor: alpha(theme.palette.text.primary, 0.04),
                        borderColor: alpha(theme.palette.text.primary, 0.04),
                        opacity: 1,
                        backgroundClip: 'content-box',
                    },
                    '& > .MuiSwitch-thumb': {
                        backgroundColor: alpha(theme.palette.text.primary, 0.16),
                    },
                },
            };
        },
        thumb: ({ theme }: { theme: any }) => {
            return {
                width: 10,
                height: 10,
                backgroundColor: alpha(theme.palette.text.primary, 0.16),
                transition: `background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut} 0s`,
                opacity: 1,
                boxShadow: 'none',
                '.Mui-checked > &': {
                    backgroundColor: theme.palette.background.default,
                },
            };
        },
        track: ({ theme }: { theme: any }) => {
            return {
                border: `1px solid ${alpha(theme.palette.text.primary, 0.16)}`,
                borderRadius: 8,
                backgroundColor: alpha(theme.palette.text.primary, 0.02),
                transition: `background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut} 0s, border-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut} 0s`,
            };
        },
    },
};
