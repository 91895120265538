import { Button, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import useBundleTranslation from 'i18n';
import { useClipboard } from 'use-clipboard-copy';
import { getIcon } from 'components/common/grid/cell/index';

export default function CopyCell({ t, text, icon }: CustomCellProps) {
    if (undefined === t) t = useBundleTranslation('main')['t'];
    const clipboard = useClipboard();
    const [isCopied, setIsCopied] = useState(false);

    const onClick = () => {
        if (text) {
            clipboard.copy(text);
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };

    if (icon) {
        return (
            <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title={t('copy_to_clipboard')}>
                    <IconButton aria-label="edit" size={'small'} onClick={onClick} sx={{ color: 'text.primary' }}>
                        {getIcon(icon, { fontSize: '16px' })}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'end' }}>
            <Button aria-label="copy" variant="text" size={'small'} onClick={onClick}>
                <Typography>{t(isCopied ? 'copied_to_clipboard' : 'copy_to_clipboard')}</Typography>
            </Button>
        </Grid>
    );
}
