import React, { ReactElement } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { createPortal } from 'react-dom';

interface LoadingPlaceholderProps {
    sx?: SxProps<Theme>;
    children?: ReactElement;
    open?: boolean;
    dataTest?: string;
}

export default function LoadingPlaceholder({ sx, children, open = true, dataTest }: LoadingPlaceholderProps) {
    return (
        <Backdrop
            data-test={dataTest}
            sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }}
            open={open}
        >
            <Stack spacing={2} direction={'column'} alignItems={'center'} className={'LoadingPlaceholderContainer'}>
                <CircularProgress color="inherit" className={'LoadingPlaceholderIcon'} />
                {children}
            </Stack>
        </Backdrop>
    );
}

export function BodyLoadingPlaceholder(props: LoadingPlaceholderProps) {
    return createPortal(
        <LoadingPlaceholder {...props} sx={{ ...props.sx, position: 'absolute', zIndex: 99999 }}>
            {props.children}
        </LoadingPlaceholder>,
        document.body,
    );
}
