import { IconButton, Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { CustomCellProps } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import { MetricChartOverlayType } from 'components/metric/chart';
import { prepareFormComponentValues } from 'components/common/form/layout/control';
import { RawFormComponentType, FormDataAPIType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';

import {
    MetricOverlaysContext,
    MetricOverlaysContextType,
} from 'components/metric/collaboration-controls/overlay-metrics/OverlayMetrics';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { FormElementWrapper } from 'components/common/form/element/FormElementWrapper';

function FormDashSelect({ elementProps }: { elementProps: FormDataAPIType }) {
    const selectData = prepareFormComponentValues([
        { value: 'line', label: 'Line' },
        { value: 'shortdash', label: 'Short dash' },
        { value: 'shortdashdot', label: 'Short dash dot' },
        { value: 'shortdashdotdot', label: 'Short dash dot dot' },
        { value: 'dot', label: 'Dot' },
        { value: 'dash', label: 'Dash' },
        { value: 'dashdot', label: 'Dash dot' },
        { value: 'longdash', label: 'Long dash' },
        { value: 'longdashdot', label: 'Long dash dot' },
        { value: 'longdashdotdot', label: 'Long dash dot dot' },
    ]);

    const selectProps = prepareFormElementProps({
        ...elementProps,
        ...{
            component: {
                component: 'FormSelect',
                name: 'dashStyle',
                label: 'Line type',
            } as RawFormComponentType,
        },
    });

    const actualChartType = elementProps.form.hookFormGetValues('metricChartDisplayType');

    return (
        <span className={actualChartType == 'column' ? 'd-none' : ''}>
            <FormElementWrapper elementProps={selectProps}>
                <ReactHookFormController componentValues={selectData} elementProps={selectProps} />
            </FormElementWrapper>
        </span>
    );
}

function FormColorPicker({ elementProps }: { elementProps: FormDataAPIType }) {
    const colorPickerProps = prepareFormElementProps({
        ...elementProps,
        ...{
            component: {
                component: 'ColorPicker',
                name: 'color',
                label: 'Color',
            } as RawFormComponentType,
        },
    });

    return (
        <FormElementWrapper elementProps={colorPickerProps}>
            <ReactHookFormController elementProps={colorPickerProps} />
        </FormElementWrapper>
    );
}

function FormAxis({ elementProps }: { elementProps: FormDataAPIType }) {
    const data = prepareFormComponentValues({
        left: 'Left',
        right: 'Right',
    });

    const colorPickerProps = prepareFormElementProps({
        ...elementProps,
        ...{
            component: {
                component: 'FormRadioGroup',
                name: 'axis',
                label: 'Axis',
                props: {
                    row: true,
                },
            } as RawFormComponentType,
        },
    });

    return (
        <FormElementWrapper elementProps={colorPickerProps}>
            <ReactHookFormController componentValues={data} elementProps={colorPickerProps} />
        </FormElementWrapper>
    );
}

function FormChartType({ elementProps }: { elementProps: FormDataAPIType }) {
    const data = prepareFormComponentValues({
        line: 'Line',
        column: 'Bar',
    });

    const colorPickerProps = prepareFormElementProps({
        ...elementProps,
        ...{
            component: {
                component: 'FormRadioGroup',
                name: 'metricChartDisplayType',
                label: 'Type',
                props: {
                    row: true,
                },
            } as RawFormComponentType,
        },
    });

    return (
        <FormElementWrapper elementProps={colorPickerProps}>
            <ReactHookFormController componentValues={data} elementProps={colorPickerProps} />
        </FormElementWrapper>
    );
}

export default function OverlayMetricsGridDataCell({ data }: CustomCellProps) {
    const context = useContext<MetricOverlaysContextType | null>(MetricOverlaysContext);

    const lineData: MetricChartOverlayType = {
        ...data,
        //@ts-ignore
        dashStyle: data.dash_style_raw.toLowerCase(),
        marker: { symbol: 'circle' },
        metric_chart_display_type:
            //@ts-ignore
            data?.metric_chart_display_type?.length > 0 ? data?.metric_chart_display_type.toLowerCase() : 'line',
    } as MetricChartOverlayType;
    //@ts-ignore
    if (lineData.metric_chart_display_type == 'bar') {
        lineData.metric_chart_display_type = 'column';
    }

    const elementProps = useCustomSimplifiedForm({
        axis: lineData.axis,
        dashStyle: lineData.dashStyle,
        color: lineData.color,
        metricChartDisplayType: lineData.metric_chart_display_type,
    });

    const onDeleteClick = () => {
        if (!context?.onDelete) {
            return;
        }
        context.onDelete('overlayMetricsGrid', {
            elementId: lineData.element_id,
            segmentValueId: lineData.segment_value_id,
        });
    };

    const popupSettings: PopupSettings = {
        title: 'overlay_options',
    };

    const onEditClick = () => {
        setIsEdit(true);
    };

    const handleSaveForm = () => {
        if (!context?.onUpdate) {
            return;
        }
        const values = elementProps.form.hookFormGetValues();
        context.onUpdate('overlayMetricsGrid', {
            ...lineData,
            axis: values.axis,
            dashStyle: values.dashStyle,
            color: values.color,
            metric_chart_display_type:
                values.metricChartDisplayType == 'Bar' ? 'column' : values.metricChartDisplayType,
        });
        setIsEdit(false);
    };

    const [isEdit, setIsEdit] = useState<boolean>(false);

    elementProps.form.hookFormWatch(['metricChartDisplayType']);

    return (
        <Stack direction={'row'} sx={{ height: 1, alignItems: 'center', justifyContent: 'end' }}>
            {isEdit && (
                <Popup
                    settings={popupSettings}
                    open={isEdit}
                    onHide={() => setIsEdit(false)}
                    onConfirm={() => handleSaveForm()}
                >
                    <Stack spacing={2}>
                        <FormColorPicker elementProps={elementProps} />
                        <FormChartType elementProps={elementProps} />
                        <FormDashSelect elementProps={elementProps} />
                        <FormAxis elementProps={elementProps} />
                    </Stack>
                </Popup>
            )}

            <IconButton aria-label="edit" size={'small'} onClick={onEditClick}>
                <IconMi icon="gear" fontSize={'16'} />
            </IconButton>
            <IconButton aria-label="delete" size={'small'} onClick={onDeleteClick}>
                <IconMi icon="delete" fontSize={'16'} />
            </IconButton>
        </Stack>
    );
}
