import { alpha, FormControl, FormLabel, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { datasetViewAPI } from 'api/viewer/dataset';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { useState } from 'react';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

interface IProps {
    showAddPopup: boolean;
    setShowAddPopup: (value: boolean) => void;
    datasetId: number;
    json: string;
    afterSave?: (newFilterId: number) => void;
    onClose?: () => void;
}

export default function AddViewPopup({ showAddPopup, setShowAddPopup, datasetId, json, afterSave, onClose }: IProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [visibility, setVisibility] = useState<'Y' | 'N'>('Y');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    if (!showAddPopup) {
        return null;
    }

    const addViewPopupSettings: PopupSettings = {
        title: t('view.create_title'),
        textOK: t('view.create_button'),
    };

    const onCloseAddViewPopup = () => {
        setShowAddPopup(false);
        setName('');
        setNameErrorMessage('');
        if (onClose) {
            onClose();
        }
    };

    const onAddViewConfirm = () => {
        setShowLoader(true);
        datasetViewAPI.createFilter(datasetId, name, visibility).then((response) => {
            if (response.status === 'OK') {
                const newFilterId = response.id;

                datasetViewAPI.saveFilter(datasetId, newFilterId, json).then(() => {
                    onCloseAddViewPopup();
                    setShowLoader(false);
                    if (afterSave) {
                        afterSave(newFilterId);
                    }
                });
            }

            if (response.status === 'ERROR') {
                setShowLoader(false);
                if (response.messages) {
                    const nameError = response.messages.name.error ?? '';
                    setNameErrorMessage(nameError > '' ? nameError : t('name_required'));
                }
            }
        });
    };

    const showVisibilitySwitch = useIsAdminOrHasPrivilege('PRIV_CREATE_PUBLIC_VIEW');

    return (
        <Popup
            settings={addViewPopupSettings}
            open={showAddPopup}
            onHide={onCloseAddViewPopup}
            onConfirm={onAddViewConfirm}
            maxWidth={'popupSm'}
        >
            <Stack direction={'column'} spacing={2}>
                <FormControl>
                    <FormLabel>{t('name')}</FormLabel>
                    <TextField
                        name={'view_name'}
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                            setNameErrorMessage('');
                        }}
                        fullWidth
                        error={nameErrorMessage > ''}
                        helperText={nameErrorMessage}
                    />
                </FormControl>
                {showVisibilitySwitch && (
                    <FormControl>
                        <FormLabel>{t('visibility.visibility')}</FormLabel>
                        <ToggleButtonGroup
                            color="primary"
                            value={visibility}
                            exclusive
                            onChange={(event, newVal) => {
                                if (newVal > '') {
                                    setVisibility(newVal);
                                }
                            }}
                        >
                            <ToggleButton value="Y" data-test={'visibility-shared'}>
                                {t('visibility.shared')}
                            </ToggleButton>
                            <ToggleButton value="N" data-test={'visibility-private'}>
                                {t('visibility.private')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>
                )}
            </Stack>
            {showLoader && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                        color: (theme) => alpha(theme.palette.text.primary, 0.5),
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                />
            )}
        </Popup>
    );
}
