import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, IconButton, Tooltip } from '@mui/material';
import PartItemWallThread from 'components/wall/parts/PartItemWallThread';
import PartItemWallSharedFiles from 'components/wall/parts/PartItemWallSharedFiles';
import { wallFormatDateExt, wallProcessMarkdownText, wallProcessText } from 'components/wall/index';

import IconMi from 'components/common/icon/IconMi';
import { getInsightfulUsers, InsightfulUsersTooltip } from 'components/wall/Wall';

export interface ItemWallUserNoteProps {
    data: any;
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    notifyOther: (type: string, text: any, itemData: any) => void;
    deleteNote: () => void;
    editNote: () => void;
    imageAntiCache?: string;
}

export default function ItemWallUserNote(props: ItemWallUserNoteProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        uid,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        notifyOther = () => {},
        deleteNote = () => {},
        editNote = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const displayNone = wallData.viewFilter && wallData.viewFilter != 'user_note';
    const [insightfulUsersTooltipData, setInsightfulUsersTooltipData] = useState<any>(null);

    return (
        <Box
            className="wall_node_OLD wall_note_node wall__item wall__item--note"
            id={`wall_user_note_${uid}_${data.user_note_id}`}
            sx={{ display: displayNone ? 'none' : undefined }}
            data-element_id={data.element_id}
            data-segment_value_id={data.segment_value_id}
        >
            <Box className="wall__item-body wall_note">
                <Box className="wall__item-icon-block wall_note_image_icon_td_OLD wall_note_user_image_td_OLD">
                    <Box className="wall__item-icon-holder">
                        <img
                            alt=""
                            className={`wall__item-avatar-img wall_comment_user_image wall_user_image_${
                                data.author_user_id
                            }${data.user_follower_id > 0 ? ' wall_rounded_image_OLD' : ''}`}
                            src={`/data/dashboard/wall/image?user=${data.author_user_id}&thumb=note${
                                imageAntiCache ? `&r=${imageAntiCache}` : ''
                            }`}
                        />
                    </Box>
                    <Box className="wall__item-follow follow_td first">
                        <Box className="follow_td_div_OLD">
                            {data.author_user_id != params.user_id && (
                                <Tooltip
                                    title={t(
                                        `user_note.${
                                            data.user_follower_id > 0 ? 'unfollow_tooltip' : 'follow_tooltip'
                                        }`,
                                        { name: data.u_first_name ?? data.username },
                                    )}
                                    disableInteractive
                                >
                                    <Box
                                        component="a"
                                        href={'#'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            followUser({
                                                user_follower_id: data.user_follower_id,
                                                author_user_id: data.author_user_id,
                                            });
                                        }}
                                        className={`wall_follow_with_name wall_user_${
                                            data.user_follower_id > 0 ? 'unfollow' : 'follow'
                                        }${data.user_follower_id > 0 ? `wall_user_${data.user_follower_id}` : ''}`}
                                        data-user_follower_id={data.user_follower_id > 0 ? data.user_follower_id : 0}
                                        data-user_id={data.author_user_id}
                                    >
                                        {t(`user_note.${data.user_follower_id > 0 ? 'unfollow_link' : 'follow_link'}`)}
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className="wall__item-content-block wall_note_text_OLD markdown">
                    <Box
                        component="b"
                        sx={{ mr: 0.75 }}
                        dangerouslySetInnerHTML={{
                            __html: wallProcessText(data.username, params),
                        }}
                    />
                    <Box component="span" className="wall__item-date">
                        {wallFormatDateExt(data.ts)}
                    </Box>
                    <Box
                        className="wall__item-text markdown-holder"
                        dangerouslySetInnerHTML={{
                            __html: wallProcessMarkdownText(data.text, params),
                        }}
                    />
                    <Box className="wall__item-content-footer wall_node_footer_OLD">
                        <Box className="wall__item-content-footer-left">
                            <Tooltip title={t('user_note.insightful_link')} disableInteractive>
                                <Box
                                    component="span"
                                    className="wall_insightful_note wall__item-content-footer-link"
                                    data-user_note_id={data.user_note_id}
                                    onClick={() => {
                                        insightful('note', data.user_note_id);
                                    }}
                                >
                                    <IconMi icon="light" fontSize="16" />
                                    {t('user_note.insightful_link')}
                                </Box>
                            </Tooltip>
                            {data.insightful_count > 0 && (
                                <Tooltip
                                    title={insightfulUsersTooltipData}
                                    disableInteractive
                                    enterDelay={500}
                                    onOpen={async () => {
                                        const users = await getInsightfulUsers({ user_note_id: data.user_note_id });
                                        if (users) {
                                            setInsightfulUsersTooltipData(<InsightfulUsersTooltip users={users} />);
                                        }
                                    }}
                                    onClose={() => setInsightfulUsersTooltipData(null)}
                                >
                                    <Box
                                        component="span"
                                        sx={{ ml: -0.75, mr: 1, fontSize: '11px' }}
                                        className="wall_insightful_users"
                                        data-user_note_id={data.user_note_id}
                                    >
                                        ({data.insightful_count})
                                    </Box>
                                </Tooltip>
                            )}
                            <Tooltip title={t('user_note.share_link')} disableInteractive>
                                <Box
                                    component="span"
                                    className="wall_note_notify_other wall__item-content-footer-link"
                                    data-user_note_id={data.user_note_id}
                                    onClick={() => {
                                        notifyOther('note', wallProcessMarkdownText(data.text, params), data);
                                    }}
                                >
                                    <IconMi icon="share" fontSize="16" />
                                    {t('user_note.share_link')}{' '}
                                </Box>
                            </Tooltip>
                            {((data.author_user_id == params.user_id && wallData.settings.privAddComments) ||
                                wallData.settings.isAdmin) && (
                                <Tooltip title={t('user_note.edit_icon')} disableInteractive>
                                    <IconButton
                                        sx={{ p: '0', color: 'inherit' }}
                                        className="wall__item-content-footer-icon-button wall_edit_note"
                                        data-user_note_id={data.user_note_id}
                                        onClick={editNote}
                                    >
                                        <IconMi icon="edit-pencil" fontSize="16" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    <PartItemWallSharedFiles data={data} elementType={'note'} />
                    <Box component="span" className="clear" />
                </Box>
            </Box>
            <Box className="wall__item-thread-block">
                <PartItemWallThread
                    {...props}
                    elementType={'user_note'}
                    updateWallData={(newData) => {
                        updateWallData(newData);
                    }}
                    followUser={followUser}
                    insightful={insightful}
                    deleteNote={deleteNote}
                    notifyOther={notifyOther}
                    imageAntiCache={imageAntiCache}
                />
            </Box>
        </Box>
    );
}
