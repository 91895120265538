import { FormComponentValue, FormControlProps } from 'components/common/form/layout/control';
import { Params as UrlParams } from 'react-router';
import { YNValues } from 'tools/types';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormElementProps } from 'components/common/form';

export interface FormControlElementImageProps extends FormControlProps {
    urlParams: UrlParams;
    imgUrl: string;
    previewUrl: string;
    thumbnailUrl: string;
    originalUrl: string;
    uploadUrl: string;
    hasImageUrl: string;
    deleteImageUrl: string;
    collectImgEnabled: boolean;
    uploadImgEnabled: boolean;
    setupImgEnabled: boolean;
    setupImgUrl: string;
    segmentId: number;
    segmentValueId: number;
    generic: boolean;
}

export interface ImageDataType {
    width: number;
    height: number;
    crop: YNValues;
    cropX: number;
    cropY: number;
    cropWidth: number;
    cropHeight: number;
    scale: YNValues;
    scaleWidth: number;
    scaleHeight: number;
}

export class ElementImageControlBuilder extends FormComponentBuilder {
    prepareProps(): FormControlElementImageProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            imgUrl: this.elementProps.componentProps?.imgUrl ?? '',
            previewUrl: this.elementProps.componentProps?.previewUrl ?? '',
            thumbnailUrl: this.elementProps.componentProps?.thumbnailUrl ?? '',
            uploadUrl: this.elementProps.componentProps?.uploadUrl ?? '',
            originalUrl: this.elementProps.componentProps?.originalUrl ?? '',
            hasImageUrl: this.elementProps.componentProps?.hasImageUrl ?? '',
            deleteImageUrl: this.elementProps.componentProps?.deleteImageUrl ?? '',
            collectImgEnabled: this.elementProps.componentProps?.collectImgEnabled ?? false,
            uploadImgEnabled: this.elementProps.componentProps?.uploadImgEnabled ?? false,
            setupImgEnabled: this.elementProps.componentProps?.setupImgEnabled ?? false,
            setupImgUrl: this.elementProps.componentProps?.setupImgUrl ?? '',
            segmentId: this.elementProps.componentProps?.segmentId ?? 0,
            segmentValueId: this.elementProps.componentProps?.segmentValueId ?? 0,
            generic: this.elementProps.componentProps?.generic ?? false,
        };
    }
}
