import React from 'react';
import { alpha } from '@mui/material';
import { darken, lighten } from '@mui/system/colorManipulator';

export const MuiSliderConfig = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                padding: '12px 0',
            };
        },
        track: ({ theme }: { theme: any }) => {
            return {
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.default,
                borderColor: alpha(theme.palette.text.primary, 0.16),
            };
        },
        rail: ({ theme }: { theme: any }) => {
            return {
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.default,
                border: '1px solid',
                opacity: 1,
                borderColor: alpha(theme.palette.text.primary, 0.16),
            };
        },
        thumb: ({ theme }: { theme: any }) => {
            return {
                height: 24,
                width: 24,
                border: `2px solid ${theme.palette.background.default}`,
                '&:hover, &.Mui-focusVisible': {
                    boxShadow: 'none',
                    backgroundColor: lighten(theme.palette.primary.main, 0.24),
                },
                '&.Mui-active': {
                    backgroundColor: darken(theme.palette.primary.main, 0.24),
                },
            };
        },
    },
};
