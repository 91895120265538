import React, { useContext, useEffect, useState } from 'react';

import { FormElementProps, FormPanelButtonProps } from 'components/common/form';
import Components from 'components/common/ComponentIndex';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { DependencyRule, FormComponentDependency, getRuleFieldName } from 'components/common/form/dependency';
import applyComponentCondition from 'components/common/form/dependency/applyComponentCondition';
import { Box } from '@mui/material';

export default function FormPanelButton(props: FormPanelButtonProps<any>) {
    const elementProps: FormElementProps = {
        pk: '',
        data: '',
        urlParams: '',
        formSettingsUrl: '',
        componentProps: {},
        componentValues: [],
        // @ts-ignore
        component: props.button,
        form: props.formActions,
        submitId: props.submitId,
    };

    const formsContext = useContext<FormsContextType>(FormsContext);
    // Calculate component 'props' depending on other fields values
    const [componentProps, setComponentProps] = useState<any>(applyComponentCondition(elementProps, formsContext));

    useEffect(() => {
        if (!props.formActions.formDidMount || !props.button.deps?.length) {
            return;
        }
        const fieldsToWatchControl: Array<string> = [];
        props.button.deps.forEach((dep: FormComponentDependency) => {
            dep.rules.forEach((rule: DependencyRule) => {
                switch (dep.scope) {
                    case 'control':
                        fieldsToWatchControl.push(getRuleFieldName(rule.field));
                        break;
                }
            });
            // Process initial form field values
            if (fieldsToWatchControl.length) {
                setComponentProps(applyComponentCondition(elementProps, formsContext));
            }
        });

        const checkDepsRules = function (name: string) {
            if (fieldsToWatchControl.includes(String(name))) {
                const result = applyComponentCondition(elementProps, formsContext);
                setComponentProps(result);
            }
        };

        const subscription = props.formActions.hookFormWatch((value, { name }) => {
            checkDepsRules(String(name));
        });

        return () => subscription.unsubscribe();
    }, [props.formActions.hookFormWatch, props.formActions.formDidMount]);

    props.button.props = { ...props.button.props, ...componentProps };
    // @ts-ignore
    const component = React.createElement(Components[props.button.component] as React.FC, props);
    return (
        <Box data-test={'form_button_' + props.buttonName} sx={{ alignItems: 'center', display: 'inline-flex' }}>
            {component}
        </Box>
    );
}
