import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        pl: 2,
        pr: 2,
        width: 'auto',
        borderBottom: 1,
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        overflow: 'visible',
        backgroundColor: 'background.default',
        zIndex: (theme: Theme) => theme.zIndex.tabsPanel + 1,

        '&.mod-sub-tabs': {
            boxShadow: (theme: Theme) => `0px 4px 4px 0px ${alpha(theme.palette.text.primary, 0.04)}`,

            '.MuiTabs-root': {
                minHeight: '28px',
            },
            '.MuiTab-root': {
                minHeight: '28px',
                lineHeight: '18px',
                pt: '5px',
                pb: '5px',
            },
            '.Mui-selected': {
                backgroundColor: 'transparent !important',
            },
            '.MuiTab-root:not(.Mui-selected)': {
                fontWeight: 400,
            },
        },
    },
    tabs: {
        flexGrow: 1,
        mb: '-1px',
    },
    tabItem: {
        fontSize: 16,
    },
    labelHolder: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    subItemSeparator: {
        mx: 0.4,
    },
    subItem: {
        fontWeight: 400,
    },
    optionsIcon: {
        ml: 1,
        fontSize: '16px',
    },
    optionsContent: {
        width: '250px',
        maxHeight: '250px',
        overflow: 'auto',
        cursor: 'pointer',
    },
    optionsItem: {
        px: 1,
        py: 0.5,
        borderTop: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        '&:hover': {
            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
        },
    },
};
