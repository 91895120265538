import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { components } from 'react-select';

export const ReactSelectMultiValueRemove = (props: any) => {
    const isHide = props.selectProps.isMulti && props.selectProps.isMultiOneRowMode && !props.selectProps.menuIsOpen; //MultiOneRowMode

    if (isHide) return null;
    return (
        <components.MultiValueRemove {...props}>
            <IconMi icon="times" fontSize={'16'} sx={{ color: 'primary.main' }} />
        </components.MultiValueRemove>
    );
};
