import { Stack } from '@mui/material';
import ViewSelection from './ViewSelection';
import ControlsPanel from './ControlsPanel';
import React from 'react';
import { FormComponentValue } from 'components/common/form/layout/control';
import { DatasetInfo, ElementsAmount, FilterData } from '../index';
import { alpha } from '@mui/material/styles';

function Infobar({
    selectedFilter,
    filterSelectData,
    onFilterSelect,
    filterDirty,
    selectedFilterData,
    isAdmin,
    isCreateAllowed,
    isPermitted4EditDataset,
    userId,
    elementsAmount,
    datasetId,
    originalDatasetId,
    json,
    saveFilter,
    switchFilterVisibility,
    reloadDatasetView,
    instanceId,
    setShowLoader,
    datasetInfo,
    setShowMetricBuilder,
    hasNumericAndDateFields,
    reloadElementsAmount,
}: {
    selectedFilter: string;
    filterSelectData: FormComponentValue[];
    onFilterSelect: (value: string) => void;
    filterDirty: boolean;
    selectedFilterData: FilterData | null;
    isAdmin: boolean;
    isCreateAllowed: boolean;
    isPermitted4EditDataset: boolean;
    userId: string;
    elementsAmount: ElementsAmount;
    datasetId: number;
    originalDatasetId: number;
    json: string;
    saveFilter: () => Promise<boolean>;
    switchFilterVisibility: () => Promise<boolean>;
    reloadDatasetView: (withFilter?: boolean, newFilterId?: number) => void;
    instanceId: number;
    setShowLoader: (value: boolean) => void;
    setShowMetricBuilder: (value: boolean) => void;
    datasetInfo: DatasetInfo;
    hasNumericAndDateFields: boolean;
    reloadElementsAmount: () => void;
}) {
    return (
        <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
            sx={{
                p: 1,
                borderBottom: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                pl: 3,
                flexShrink: 0,
            }}
        >
            <ViewSelection
                selectedFilter={selectedFilter}
                filterSelectData={filterSelectData}
                onFilterSelect={onFilterSelect}
                filterDirty={filterDirty}
                selectedFilterData={selectedFilterData}
                isCreateAllowed={isCreateAllowed}
                isPermitted4EditDataset={isPermitted4EditDataset}
                userId={userId}
                isAdmin={isAdmin}
                viewCanBeDeleted={elementsAmount.can_be_deleted === 'Y'}
                datasetId={datasetId}
                originalDatasetId={originalDatasetId}
                reloadDatasetView={reloadDatasetView}
                saveFilter={saveFilter}
                json={json}
            />
            <ControlsPanel
                datasetId={datasetId}
                elementsAmount={elementsAmount}
                filterId={selectedFilter}
                instanceId={instanceId}
                json={json}
                selectedFilterData={selectedFilterData}
                filterDirty={filterDirty}
                saveFilter={saveFilter}
                switchFilterVisibility={switchFilterVisibility}
                setShowLoader={setShowLoader}
                datasetInfo={datasetInfo}
                reloadDatasetView={reloadDatasetView}
                setShowMetricBuilder={setShowMetricBuilder}
                hasNumericAndDateFields={hasNumericAndDateFields}
                reloadElementsAmount={reloadElementsAmount}
                isPermitted4EditDataset={isPermitted4EditDataset}
            />
        </Stack>
    );
}

export default Infobar;
