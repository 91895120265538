import { ElementType } from 'components/element-viewer/index';
import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { favoritesAPI } from 'api/favorites';
import AddToFavoritePopup from 'components/common/favorite/AddToFavoritePopup';

const NamesToDisplay = 2;
export default function FavoriteButton({
    element,
    segmentValueId,
    onChange,
}: {
    element: ElementType;
    segmentValueId: number;
    onChange: () => void;
}) {
    const { t } = useBundleTranslation('app/favorites');
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

    const n = element.inFavorites.length;
    const list =
        element.inFavorites
            .slice(0, NamesToDisplay)
            .map((f) => f.displayName)
            .join(', ') + (n > NamesToDisplay ? ', ...' : '');

    const toolTip = t('addFavorites', { count: n, list: list, entity: element.row.type });

    const handleSave = () => {
        setIsFormVisible(false);
        onChange();
    };

    const handleClose = () => {
        setIsFormVisible(false);
    };

    return (
        <span data-test={'top-favorite-icon'}>
            <Tooltip title={toolTip}>
                <IconButton sx={{ p: '6px' }} onClick={() => setIsFormVisible(true)}>
                    {n == 0 ? (
                        <IconMi icon="favorite" fontSize="16" />
                    ) : (
                        <IconMi sx={{ color: 'warning.main' }} icon="favorite-solid" fontSize="16" />
                    )}
                </IconButton>
            </Tooltip>

            {isFormVisible && (
                <AddToFavoritePopup
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    isOpen={isFormVisible}
                    onApply={handleSave}
                    onClose={handleClose}
                    elementName={element.row.name}
                />
            )}
        </span>
    );
}
