import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Popup } from 'components/common/popup/Popup';
import { Box, FormControl, Grid, Stack } from '@mui/material';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';

export default function MapDataForm({
    mapData,
    mapDataCallback,
    isOpen,
}: {
    mapData: any;
    mapDataCallback: (data: any) => void;
    isOpen: boolean;
}) {
    const { t } = useBundleTranslation(['components/notification/burst']);
    const elementProps = useCustomSimplifiedForm({
        all_values: mapData?.all_values ?? 'All',
        all_values_ext: mapData?.all_values_ext ?? null,
        null_values: mapData?.null_values ?? null,
        null_values_ext: mapData?.null_values_ext ?? null,
        use_formatted_value_ind: mapData?.use_formatted_value_ind ?? 'N',
    });

    return (
        <Popup
            settings={{
                noCancel: true,
                title: t('filter.map_label'),
            }}
            open={isOpen}
            onHide={() => {
                mapDataCallback(mapData);
            }}
            onConfirm={() => {
                mapDataCallback(elementProps.form.hookFormGetValues());
            }}
        >
            <Stack spacing={2}>
                <Grid container>
                    <Grid xs={5}>
                        <FormControl fullWidth>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        uid: 'all_values_ext',
                                        name: 'all_values_ext',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.all_values_ext'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={2} sx={{ fontSize: '16px', paddingTop: '24px', textAlign: 'center' }}>
                        <IconMi icon={'arrow-right'} />
                    </Grid>
                    <Grid xs={5}>
                        <FormControl fullWidth>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        uid: 'all_values',
                                        name: 'all_values',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.all_values'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '17px;' }}>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormCheckbox',
                                name: 'use_formatted_value_ind',
                                label: t('filter.use_formatted_value_ind'),
                            },
                        })}
                    />
                </Box>
            </Stack>
        </Popup>
    );
}
