import { ReportContentNS } from 'components/report-content/index';
import ManageFilters from 'components/report-content/components/manage-filters/ManageFilters';
import ManageFiltersEditPanel from 'components/report-content/components/manage-filters/ManageFiltersEditPanel';
import React from 'react';
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;

export default class ManageFiltersComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsManageFilters> {
    getComponent(editPanel?: any) {
        return (
            <ManageFilters
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
                editPanel={editPanel}
                blockId={this.block.uid}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <ManageFiltersEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
