import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import Wall from 'components/wall/Wall';

export interface WallProps {
    wallType: 'chart' | 'report'; //chart_wall.js || report_wall.js
    data: any;
    elementId: number;
    segmentValueId: number;
    userId: number;
    viewScope?: string;

    elementType: string;

    //chart
    reqData?: any;
    overlayElements?: any;
    collaborativeData?: any;

    //report
    seq?: any;
}

export default function ViewerWall(props: WallProps) {
    const {
        wallType,
        data,
        elementId,
        segmentValueId,
        userId,
        seq,
        viewScope,
        elementType,
        reqData,
        overlayElements,
        collaborativeData,
    } = props;

    const { t } = useBundleTranslation(['components/wall/wall']);

    const isChartType = wallType == 'chart';
    const isReportType = wallType == 'report';

    const uid = `${elementId}_${segmentValueId}${isReportType ? `_${seq}` : ''}`;
    const el = `#wallView${isReportType ? `_${uid}` : ''}`;
    const filters = `#wall_view_filters${isReportType ? `_${uid}` : ''}`;
    //ToDo: .wall_view_filter click ViewFilterClick($(this))
    //ApplyViewFilter
    const wall = null;
    const template = null;
    const viewScopeType = viewScope ?? (isReportType ? 'report' : 'chart');

    const prepareCollaborativeData = () => {
        let data: any = {
            user_annotation_ids: [],
            alert_event_ids: [],
            ann_index: {},
            notable_event_ids: [],
            event_index: {},
        };
        if (collaborativeData) {
            if (collaborativeData.annotationSeriesData && collaborativeData.annotationSeriesData.length) {
                for (var i = 0; i < collaborativeData.annotationSeriesData.length; i++) {
                    for (var k = 0; k < collaborativeData.annotationSeriesData[i].data.point.length; k++) {
                        var $v = collaborativeData.annotationSeriesData[i].data.point[k];
                        data.ann_index[$v.mydata.key] = $v.mydata.index;
                        data.user_annotation_ids.push($v.mydata.key);
                    }

                    for (var k = 0; k < collaborativeData.annotationSeriesData[i].data.range.length; k++) {
                        var $v = collaborativeData.annotationSeriesData[i].data.range[k];
                        data.ann_index[$v.mydata.key] = $v.mydata.index;
                        data.user_annotation_ids.push($v.mydata.key);
                    }
                }
            }

            if (collaborativeData.alertSeriesData && collaborativeData.alertSeriesData.length) {
                for (var i = 0; i < collaborativeData.alertSeriesData.length; i++) {
                    for (var k = 0; k < collaborativeData.alertSeriesData[i].data.length; k++) {
                        data.alert_event_ids.push(collaborativeData.alertSeriesData[i].data[k].mydata.key);
                    }
                }
            }

            if (collaborativeData.eventData && collaborativeData.eventData.length) {
                for (var i = 0; i < collaborativeData.eventData.length; i++) {
                    if (collaborativeData.eventData[i].data.point)
                        for (var k = 0; k < collaborativeData.eventData[i].data.point.length; k++) {
                            var $v = collaborativeData.eventData[i].data.point[k];
                            data.event_index[$v.mydata.key] = $v.mydata.index;
                            data.notable_event_ids.push($v.mydata.key);
                        }

                    if (collaborativeData.eventData[i].data.range)
                        for (var k = 0; k < collaborativeData.eventData[i].data.range.length; k++) {
                            var $v = collaborativeData.eventData[i].data.range[k];
                            data.event_index[$v.mydata.key] = $v.mydata.index;
                            data.notable_event_ids.push($v.mydata.key);
                        }
                }
            }
        }

        if (data.alert_event_ids.length == 0 && isReportType) data.alert_event_ids = undefined;

        return data;
    };

    const viewFilter = isReportType && viewScopeType != 'report_note' ? 'user_annotation' : null;

    const parameters = {
        ...{
            uid: uid,
            view_scope: viewScopeType,
            element_id: elementId,
            segment_value_id: segmentValueId,
            user_id: userId,
            element_type: elementType,
            wallAddNote: true,
            viewFilter: viewFilter,
            reqData: reqData ?? undefined,
            overlayElements: overlayElements ?? undefined,
            checkUpdate: true, //ToDo WALL_CHECK_UPDATE && 'N' == WALL_CHECK_UPDATE ? 'N' : 'Y'
        },
        ...prepareCollaborativeData(),
    };

    //ToDo GotoAlert, GotoAnnotation, GotoEvent (add events or store actions)

    const params = props;

    //ToDo  ratingComponent.check('element', elementId);

    return (
        <Wall
            params={parameters}
            actions={{
                processResponseData: (data) => {
                    return data;
                },
            }}
        />
    );
}
