import { createContext, useContext } from 'react';
import { GlobalContextType } from 'tools/types';
export const GlobalContext = createContext<GlobalContextType | undefined>({} as GlobalContextType);

export function useGlobalContext() {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useHomeContext must be used within a HomeContextProvider');
    }
    return context;
}
