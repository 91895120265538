import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import {
    HeaderDropDownTriggerClassName,
    PivotHeaderDropDownFieldType,
} from 'components/report-content/components/dataset/index';
import IconMi from 'components/common/icon/IconMi';
import { alpha, Box } from '@mui/material';
import PivotDataCell = ReportContentNS.PivotDataCell;

export default function PivotHeaderDropDownIcon({
    cell,
    uiFieldIndex,
    onClick,
    realIndex,
}: {
    cell: PivotDataCell;
    uiFieldIndex: number;
    realIndex: number;
    onClick: (field: PivotHeaderDropDownFieldType) => void;
}) {
    return (
        <Box
            id={`dDropIcon_${realIndex}`}
            sx={{
                position: 'absolute',
                right: 4,
                bottom: 3,
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                backgroundColor: 'background.default',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
            className={HeaderDropDownTriggerClassName}
            onClick={(event) => {
                onClick({
                    field: cell,
                    uiFieldIndex: uiFieldIndex,
                    anchorEl: event.currentTarget,
                    realIndex: realIndex,
                });
            }}
        >
            <IconMi icon={'chevron-down'} sx={{ fontSize: '15px', pointerEvents: 'none', color: 'primary.main' }} />
        </Box>
    );
}
