import TextFilter from './TextFilter';
import SelectFilter from './SelectFilter';
import AsyncDataSelectFilter from './AsyncDataSelectFilter';
import CheckboxFilter from './CheckboxFilter';
import SearchFilter from './SearchFilter';
import { FilterElementProps } from '../index';
import React from 'react';
import DateFilter from './DateFilter';

const Filters: { [key: string]: any } = {
    text: TextFilter,
    select: SelectFilter,
    checkbox: CheckboxFilter,
    search: SearchFilter,
    date: DateFilter,
    asyncDataSelect: AsyncDataSelectFilter,
};

const getComponent = (filterName: string) => {
    if (Filters.hasOwnProperty(filterName)) {
        return Filters[filterName];
    }

    return TextFilter;
};

export const getFilterComponent = (filterProps: FilterElementProps) => {
    const filterComponent = getComponent(filterProps.component);

    return React.createElement(filterComponent, filterProps);
};
