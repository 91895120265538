import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEmbeddingContext } from 'hooks/useEmbeddingContext';

export const embeddingConfSlice = createSlice({
    name: 'form',
    initialState: {
        data: {
            wall: {},
            actionsBar: {},
        } as IEmbeddingContext,
    },
    reducers: {
        setEmbeddedConfig: (state, action: PayloadAction<{ newState: IEmbeddingContext }>) => {
            state.data = action.payload.newState;
        },
    },
});

export const { setEmbeddedConfig } = embeddingConfSlice.actions;

export default embeddingConfSlice.reducer;
