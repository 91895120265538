import {
    ConditionFormatting,
    ConditionFormattingContext,
    ConditionFormattingContextType,
    findFieldByRuleField,
    getCompareConditionLabel,
    getFormatFieldLabel,
    getFormatSampleStyleFromFormat,
    ruleConditionToShortForm,
} from './index';

import { FieldConditionRuleNS } from 'components/common/field-condition-rule/index';
import SecondFieldConstValue = FieldConditionRuleNS.SecondFieldConstValue;
import IconMi from 'components/common/icon/IconMi';
import React, { useContext } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import { alpha, Box, FormLabel, IconButton, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';

const DragHandle = SortableHandle(() => <IconMi icon={'drag-and-drop-grid'} fontSize="16" sx={{ mr: 2 }} />);

const SortableItem = SortableElement(({ value: formatting }: { value: ConditionFormatting }) => {
    const context = useContext<ConditionFormattingContextType | null>(ConditionFormattingContext);
    if (context == null) {
        return null;
    }

    // Only single Rule is allowed for Condition Formatting
    const rule = formatting.rules[0];

    const mainField = findFieldByRuleField(context.fields, rule.field);
    if (!mainField) {
        return null;
    }

    const cond = ruleConditionToShortForm(mainField, rule);
    let secondValue = rule.data;
    let secondField: ComponentSettingsDatasetField | false = false;
    if (rule.field_second != SecondFieldConstValue && rule.field_second != '') {
        secondField = findFieldByRuleField(context.fields, rule.field_second);
        if (secondField) {
            secondValue = secondField.column_name;
        }
    }

    return (
        <Stack
            key={formatting.id}
            direction={'row'}
            alignItems={'center'}
            sx={{
                zIndex: 2205, //above backdrop
                borderBottom: '1px solid',
                borderBottomColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                pl: 1,
                py: 0.5,
                '&:nth-last-child(1)': {
                    mb: '-1px',
                },
            }}
        >
            <DragHandle />
            <Box>
                When <strong>{mainField.column_name}</strong> <i>{cond}</i> <strong>{secondValue}</strong>
                <i> {getCompareConditionLabel(rule, mainField, secondField)} </i>
                apply
                <span style={getFormatSampleStyleFromFormat(formatting.format)}>additional formatting</span> to
                <i> {getFormatFieldLabel(context.fields, formatting)}</i>.
            </Box>

            <IconButton
                onClick={() => {
                    context.selectFormatting(formatting);
                }}
            >
                <IconMi icon="gear" fontSize="16" />
            </IconButton>
            <IconButton
                onClick={() => {
                    context.deleteFormatting(formatting);
                }}
            >
                <IconMi icon="times" fontSize="16" />
            </IconButton>
        </Stack>
    );
});

const SortableList = SortableContainer(({ items }: { items: Array<ConditionFormatting> }) => {
    const { t } = useBundleTranslation(['components/report-content']);

    return (
        <Box>
            <FormLabel>{t('conditional_formatting.label')}</FormLabel>

            {items.length > 0 && (
                <Stack
                    sx={{
                        border: '1px solid',
                        borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        borderRadius: 1,
                    }}
                >
                    {items.map((formatting, index) => {
                        // @ts-ignore
                        return <SortableItem key={`item-${formatting.id}`} index={index} value={formatting} />;
                    })}
                </Stack>
            )}
        </Box>
    );
});

export default function ConditionalFormattingList({ formatting }: { formatting: Array<ConditionFormatting> }) {
    const context = useContext<ConditionFormattingContextType | null>(ConditionFormattingContext);
    if (context == null) {
        return null;
    }

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        context.onSortEnd(oldIndex, newIndex);
    };

    // @ts-ignore
    return <SortableList axis={'xy'} lockAxis={'y'} useDragHandle items={formatting} onSortEnd={onSortEnd} />;
}
