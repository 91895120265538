import React, { useState, useCallback, useContext, useEffect } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import QueryBuilderSortGridRow from 'components/plugin-query-builder/query-builder/QueryBuilderSortGridRow';
import IconMi from 'components/common/icon/IconMi';
import QueryBuilderSortPopup from 'components/plugin-query-builder/query-builder/QueryBuilderSortPopup';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';

export interface QueryBuilderSortGridProps {}

export default function QueryBuilderSortGrid(props: QueryBuilderSortGridProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { queryAct, queryData, reportAct } = useContext(PluginQBContext);

    const [cards, setCards] = useState<any[]>([]);

    const [showSortPopup, setShowSortPopup] = useState<boolean>(false);
    const [sortEditIndex, setSortEditIndex] = useState<number | null>(null);

    const isEmptySorts = !cards.length;

    useEffect(() => {
        const sortData = queryData.sort.filter((sortItem: any) => sortItem && reportAct.hasField(sortItem.column));
        setCards(sortData);
    }, []);

    useEffect(() => {
        const sortData = queryData.sort.filter((sortItem: any) => sortItem && reportAct.hasField(sortItem.column));
        setCards(sortData);
    }, [queryData.sort]);

    const dropCard = () => {
        queryAct.updateData({ sort: cards });
    };

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }),
        );
    }, []);

    return (
        <Box sx={{ mt: 4 }}>
            {isEmptySorts ? (
                <Box sx={styles.emptyGridPlaceholder}>
                    <Box>{t('sort_popup.empty_sort_msg')}</Box>
                    <Button
                        startIcon={<IconMi icon="new" />}
                        variant={'light'}
                        onClick={() => {
                            setShowSortPopup(true);
                        }}
                        sx={{ mt: 1 }}
                    >
                        {t('sort_popup.add_sort_btn')}
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Box sx={styles.sortGrid}>
                        <DndProvider backend={HTML5Backend}>
                            <Box sx={styles.rowWrapper} className={'header'}>
                                <Box className={'cell cell--drag'}></Box>
                                <Box className={'cell cell--text cell--main'}>{t('sort_popup.grid_label_field')}</Box>
                                <Box className={'cell cell--text data--direction'}>
                                    {t('sort_popup.grid_label_direction')}
                                </Box>
                                <Box className={'cell cell--actions'}></Box>
                            </Box>
                            <Box sx={styles.gridBody}>
                                {cards.map((item, index) => {
                                    return (
                                        <QueryBuilderSortGridRow
                                            key={item.column}
                                            data={item}
                                            index={index}
                                            moveCard={moveCard}
                                            dropCard={dropCard}
                                            editItem={() => {
                                                setSortEditIndex(index);
                                                setShowSortPopup(true);
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        </DndProvider>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Button
                            startIcon={<IconMi icon="new" />}
                            variant={'light'}
                            onClick={() => {
                                setShowSortPopup(true);
                            }}
                        >
                            {t('sort_popup.add_sort_btn')}
                        </Button>
                    </Box>
                </Box>
            )}
            {showSortPopup && (
                <QueryBuilderSortPopup
                    onClose={() => {
                        setShowSortPopup(false);
                        setSortEditIndex(null);
                    }}
                    onApply={(data) => {
                        if (sortEditIndex !== null) {
                            const newSort = [...queryData.sort];
                            newSort.splice(sortEditIndex, 1, data);
                            queryAct.updateData({ sort: newSort });
                        } else {
                            queryAct.addSort(data.column, data.direction);
                        }
                        setShowSortPopup(false);
                        setSortEditIndex(null);
                    }}
                    editElement={sortEditIndex !== null ? cards[sortEditIndex] : undefined}
                />
            )}
        </Box>
    );
}
