import { alpha, Box, Stack, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps } from 'components/common/form/layout/control';
import { monitorAPI } from 'api/api';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

interface SystemNotificationStatusData {
    by: string;
    status: boolean;
    time: string;
    date: string;
}

export default function SystemNotificationInfo() {
    const { t } = useBundleTranslation(['app/notification/my-notification']);

    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [notificationStatus, setNotificationStatus] = useState<SystemNotificationStatusData>({
        status: true,
        by: '',
        time: '',
        date: '',
    });

    const systemDateTimeTick = () => {
        monitorAPI.getSystemNotificationsState().then((response) => {
            if (response.data.status === 'OK') {
                setNotificationStatus(response.data.data);
            }
        });
    };

    useEffect(() => {
        const newTimerId = setInterval(() => systemDateTimeTick(), 30000);
        systemDateTimeTick();
        setTimerId(newTimerId);

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, []);

    if (notificationStatus.status) {
        return null;
    }

    return (
        <Stack
            direction="row"
            alignItems={'center'}
            justifyContent="center"
            sx={{
                backgroundColor: (theme: Theme) => alpha(theme.palette.error.main, 0.1),
                margin: '0 -16px',
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                width: '100%',
                height: '28px',
            }}
            spacing={0.5}
        >
            <Typography>{t('all_notifications')}</Typography>
            <Typography fontWeight={600} color={'error'}>
                {t('off')}
            </Typography>
            <Typography>{t('by')}</Typography>
            <Typography fontWeight={600}>{notificationStatus.by}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {t('as_of')} {notificationStatus.date} {t('at')}{' '}
                <IconMi icon={'clock'} fontSize={'16'} sx={{ mx: 1 }} />
                {notificationStatus.time}.
            </Box>
            <Typography>{t('until')}</Typography>
        </Stack>
    );
}

export class SystemNotificationInfoBuilder extends FormComponentBuilder {
    prepareProps(): FormControlProps {
        return {
            ...this.controlProps,
        };
    }
}
