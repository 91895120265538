import { ReportContentNS } from 'components/report-content/index';
import { AssocArray } from 'tools/types';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import { CellStyler, Computation } from 'components/report-content/components/dataset/CellStyler';
import PivotDataCell = ReportContentNS.PivotDataCell;
import { FieldConditionRuleNS } from 'components/common/field-condition-rule';
import Rule = FieldConditionRuleNS.Rule;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;

export class PivotCellStyler {
    component: BlockComponent<ComponentSettingsPivot>;
    zebraColors: AssocArray<string>;
    defaultCellStyles: CellStyler;

    constructor(component: BlockComponent<ComponentSettingsPivot>) {
        this.component = component as BlockComponent<ComponentSettingsPivot>;
        // this.zebraMask     = {r: 141, g:141, b:141};
        this.zebraColors = {};
        //@ts-ignore
        this.defaultCellStyles = new CellStyler(this.component);
    }

    calculateCellDecoration(cell: PivotDataCell, field: ComponentSettingsPivotField) {
        let result: any = false;
        if (cell.type) {
            if (['column_total', 'total', 'row_subtotal', 'row_total', 'column_subtotal'].indexOf(cell.type) != -1) {
                return result;
            }
        }

        const computation = new Computation();

        if (!field || !field.formattingRules) {
            return result;
        }
        field.formattingRules.forEach(function (rule) {
            var valueType = cell.type == 'measure' ? 'numeric' : field.value_type;
            if (
                computation.calculate(
                    rule as Rule,
                    { value_type: valueType } as ComponentSettingsDatasetField,
                    cell['value-raw'],
                )
            ) {
                if (result == false) {
                    result = {};
                }
                if (rule.textColor) {
                    result.textColor = rule.textColor;
                }
                if (rule.bgColor) {
                    result.bgColor = rule.bgColor;
                }
                if (typeof rule.textBold != 'undefined') {
                    result.textBold = rule.textBold;
                }
                if (typeof rule.textItalic != 'undefined') {
                    result.textItalic = rule.textItalic;
                }
            }
            if (result) return;
        });
        return result;
    }

    getCellStyle(
        index: number,
        colSpanIndex: number,
        field: ComponentSettingsPivotField,
        cell: PivotDataCell,
        odd: boolean,
        isHeader: boolean = false,
    ) {
        let uiSettings: any = structuredClone(field),
            classRules = {},
            styleRules = {},
            decoration: any = {};
        if (cell) {
            decoration = this.calculateCellDecoration(cell, field);
            if (cell.type) {
                const uiData: any = this.component.settings.uiFields.find(function (field) {
                    return field.reference_name == index;
                });
                // Alignment can be override by 'ui' field settings
                if (['measure', 'column'].indexOf(cell.type) != -1 && uiSettings) {
                    if (uiData) {
                        for (let i in uiData) {
                            if (uiData.hasOwnProperty(i)) {
                                if (uiData[i] != '') {
                                    if (
                                        (cell.type == 'column' && i != 'textColor' && i != 'bgColor') ||
                                        cell.type == 'measure'
                                    ) {
                                        uiSettings[i] = uiData[i];
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (['column_total', 'total'].indexOf(cell.type) != -1) {
                        uiSettings = structuredClone(this.component.settings.columnTotalsSettings);
                        if ('undefined' !== typeof uiSettings) {
                            for (let i in uiData) {
                                if (uiData.hasOwnProperty(i)) {
                                    if (
                                        !((i == 'bgColor' || i == 'textColor') && uiData[i] == '') &&
                                        (uiSettings[i] == '' || uiSettings[i] == null)
                                    ) {
                                        uiSettings[i] = uiData[i];
                                    }
                                }
                            }
                        }
                    } else if (['row_total'].indexOf(cell.type) != -1) {
                        uiSettings = structuredClone(this.component.settings.rowTotalsSettings);
                    } else if (['row_subtotal'].indexOf(cell.type) != -1) {
                        uiSettings = structuredClone(uiSettings.subtotalSettings);
                    } else if (['column_subtotal'].indexOf(cell.type) != -1) {
                        uiSettings = structuredClone(uiSettings.subtotalSettings);
                        if (uiData && 'undefined' !== typeof uiSettings) {
                            if (uiData?.bgColor != '') {
                                uiSettings.bgColor = uiData.bgColor;
                            }
                            if (uiData?.textColor != '') {
                                uiSettings.textColor = uiData.textColor;
                            }
                        }
                    } else if (['row'].indexOf(cell.type) != -1) {
                        if (isHeader) {
                            uiSettings = structuredClone(field.headerSettings);
                        } else {
                            if (uiData && uiSettings) {
                                for (let i in uiData) {
                                    if (uiData.hasOwnProperty(i)) {
                                        if (i != 'textAlign') {
                                            uiSettings[i] = uiData[i];
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (cell.type != 'row' && uiData && uiData.hasOwnProperty('textAlign') && uiSettings) {
                        uiSettings.textAlign = uiData.textAlign;
                    }
                    if (uiSettings) {
                        field = uiSettings;
                    }
                }
            }
            classRules = {
                'block-wrapper-fixed-column':
                    //@ts-ignore
                    this.component.settings.fixedColumns - 1 >= index && colSpanIndex === false,
                //@ts-ignore
                'colspan-child': cell.type == 'c',
                //@ts-ignore
                'rowspan-child': cell.type == 'r',
                'calculated-color': decoration > '',
                'wrapper-cell': field && field.textWrap,
                'bold-cell': typeof decoration.textBold == 'undefined' ? field && field.textBold : decoration.textBold,
                'italic-cell':
                    typeof decoration.textItalic == 'undefined' ? field && field.textItalic : decoration.textItalic,
            };
        } else {
            classRules = {
                'wrapper-cell': field && field.textWrap,
                'bold-cell': typeof decoration.textBold == 'undefined' ? field && field.textBold : decoration.textBold,
                'italic-cell':
                    typeof decoration.textItalic == 'undefined' ? field && field.textItalic : decoration.textItalic,
            };
        }

        let bgColor =
            typeof decoration.bgColor == 'undefined'
                ? uiSettings && uiSettings?.bgColor != null
                    ? uiSettings.bgColor
                    : false
                : decoration.bgColor;
        if (!odd && this.component.settings.zebra_striping == 'Y') {
            bgColor = this.defaultCellStyles.getZebraColor(bgColor);
        }

        styleRules = {
            backgroundColor: bgColor,
            color:
                typeof decoration.textColor == 'undefined'
                    ? uiSettings && typeof uiSettings.textColor != 'undefined'
                        ? uiSettings.textColor
                        : false
                    : decoration.textColor,
            textAlign: !uiSettings || typeof uiSettings.textAlign == 'undefined' ? false : uiSettings.textAlign,
        };

        return {
            classRules: classRules,
            styleRules: styleRules,
        };
    }
}
