import React, { createContext, useEffect, useState, lazy } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import DownloadManager from './services/DownloadManager';
import { getThemeStylesByName } from './theme/index';
import { createTheme, CssBaseline, GlobalStyles, Theme, ThemeProvider } from '@mui/material';
import { globalStylesData } from 'theme/core/globalStylesData';

import { useDispatch, useSelector } from 'react-redux';
import { deepmerge } from '@mui/utils';
import { SnackbarProvider } from 'notistack';
import { getMuiThemeOverrideObject } from 'app/editor/brand-theme/form-template/BrandThemeForm';
import { getRoutes } from 'routes/RouteData';
import { useInstallFont } from 'hooks/useFont';
import { EmbeddingContext, useSetupEmbeddingContext } from 'hooks/useEmbeddingContext';

export const ThemeContext = createContext<Theme | null>(null);

export function AppWrapper({ children }: { children: any }) {
    const userBrandThemeProperties = useSelector((state: any) => state.auth?.userInfo?.brandThemeProperties);
    const themeOverrides = useSelector((state: any) => state.themeEditor.overrides);
    const fontList = useSelector((state: any) => state.font.list);
    const dispatch = useDispatch();

    const getThemeWithOverrides = (theme: any) => {
        const userBrandTheme = getMuiThemeOverrideObject(userBrandThemeProperties);
        const override = deepmerge(userBrandTheme, themeOverrides);
        const styles = getThemeStylesByName(theme);
        return createTheme(deepmerge(styles, override));
    };
    const [theme, setTheme] = useState(getThemeWithOverrides('default'));

    /* Example of  change theme  presset
    const handlerChangeTheme = (theme: ThemeName) => {
        setTheme(getThemeWithOverrides(theme));
    };
    */

    useEffect(() => {
        const usages = userBrandThemeProperties?.usages ?? {};
        Object.values(usages).forEach((usage: any) => {
            if (usage.font) {
                const fontData = (userBrandThemeProperties?.fonts ?? []).find((item: any) => {
                    return item.brand_theme_font_id == usage.font;
                });
                if (fontData) useInstallFont(usage.font, fontData.file_name, fontList, dispatch);
            }
        });
    }, []);

    useEffect(() => {
        setTheme(getThemeWithOverrides('default'));
    }, [themeOverrides, userBrandThemeProperties]);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                retry: false,
            },
        },
    });

    //Note: It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering.
    //This will ensure that the <style> tag generated would not recalculate on each render.
    // @ts-ignore
    const inputGlobalStyles = <GlobalStyles styles={globalStylesData} />;
    //Allow to configure UI components based on the embedding context
    //For Example: Hide the Wall or ActionsBar
    const embeddingState = useSetupEmbeddingContext();
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeContext.Provider value={theme}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    autoHideDuration={1000}
                >
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {inputGlobalStyles}
                        <DownloadManager>
                            <EmbeddingContext.Provider value={embeddingState}>
                                {children}
                                <ReactQueryDevtools initialIsOpen={false} />
                            </EmbeddingContext.Provider>
                        </DownloadManager>
                    </ThemeProvider>
                </SnackbarProvider>
            </ThemeContext.Provider>
        </QueryClientProvider>
    );
}

function App() {
    const routerData = getRoutes();
    return (
        <AppWrapper>
            <RouterProvider router={routerData} />
        </AppWrapper>
    );
}

export default App;
