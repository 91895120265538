import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';
import { Button } from '@mui/material';

export default function AddComment({ onClick }: { onClick: Function }) {
    const { t } = useBundleTranslation(['components/metric/chart']);
    return (
        <Button
            variant="text"
            startIcon={<IconMi icon={'comment'} />}
            onClick={() => onClick()}
            sx={{
                px: 1,
            }}
        >
            {t('add_post')}
        </Button>
    );
}
