import { Theme, alpha } from '@mui/material';

export const MuiTooltipConfig = {
    defaultProps: {
        arrow: true,
    },
    styleOverrides: {
        tooltip: ({ theme }: { theme: Theme }) => {
            return {
                backgroundColor: theme.palette.background.default,
                borderRadius: '3px',
                fontWeight: 400,
                fontSize: '12px',
                color: theme.palette.text.primary,
                boxShadow: `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                padding: '5px 12px',
            };
        },
        arrow: ({ theme }: { theme: Theme }) => {
            return {
                color: theme.palette.background.default,
                '&:before': {
                    boxShadow: `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                },
            };
        },
    },
};
