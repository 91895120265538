import { PopupSettings } from 'components/common/popup/Popup';
import FormComponent from 'components/common/form/Form';
import React from 'react';

export interface ISubFormPopupProps {
    popupSettings: PopupSettings;
    onHide: () => void;
    afterSave: (response: any) => void;
    showType: 'new' | 'duplicate' | false;
    popupType: 'new' | 'duplicate';
    settingsUrl: string;
    pk: string;
    uid: string;
}

export default function FormSubFormPopup(props: ISubFormPopupProps) {
    return props.showType ? <FormComponent isPopup {...props} /> : null;
}
