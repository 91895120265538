import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI } from 'api/api';
import { redirect } from 'react-router-dom';

export interface UserPrivileges {
    PRIV_DELETE_COLLABORATIVE_ITEMS: boolean;
    PRIV_ADD_ANNOTATION: boolean;
    PRIV_ADD_COMMENTS: boolean;
    PRIV_ADD_FOLDERS: boolean;
    PRIV_ADD_SLIDESHOW: boolean;
    PRIV_CREATE_ALERT_RULE: boolean;
    PRIV_CREATE_BUSINESS_UNITS: boolean;
    PRIV_CREATE_COLLECTION_TRIGGERS: boolean;
    PRIV_CREATE_CONTENT_WORKFLOWS: boolean;
    PRIV_CREATE_CUSTOM_FIELD_SECTIONS: boolean;
    PRIV_CREATE_DATASET: boolean;
    PRIV_CREATE_DATA_DEPENDENCIES: boolean;
    PRIV_CREATE_DATA_SOURCE: boolean;
    PRIV_CREATE_DIGEST_TEMPLATE: boolean;
    PRIV_CREATE_EDIT_ANNOUNCEMENTS: boolean;
    PRIV_CREATE_EDIT_BURSTS: boolean;
    PRIV_CREATE_EXTERNAL_REPORT_TYPE: boolean;
    PRIV_CREATE_FTP_CONNECTION: boolean;
    PRIV_CREATE_GOVERNED_TAGS: boolean;
    PRIV_CREATE_JS_CHART_TEMPLATE: boolean;
    PRIV_CREATE_MANAGE_GROUPS: boolean;
    PRIV_CREATE_PORTAL_PAGES: boolean;
    PRIV_CREATE_RATING_PROMPTS: boolean;
    PRIV_CREATE_TAGS: boolean;
    PRIV_CREATE_WORKFLOW: boolean;
    PRIV_DESIGNATE_SLACK_CHANNEL: boolean;
    PRIV_DISPLAY_VIEWS_MENU: boolean;
    PRIV_FULL_NS_EDITOR: boolean;
    PRIV_LOGIN_AS_MEMBER_OF_GROUP: boolean;
    PRIV_MANAGE_ACCESS_REQUESTS: boolean;
    PRIV_PORTAL_CSS_IMAGES: boolean;
    PRIV_PORTAL_PAGES_LAYOUTS: boolean;
    PRIV_PORTAL_PAGES_TEMPLATES: boolean;
    PRIV_PREFERENCES: boolean;
    PRIV_USE_API_TOOLKIT: boolean;
    PRIV_USE_CONTENT_CENTER: boolean;
}

export interface UserInfo {
    user_id: number;
    username: string;
    first_name: string;
    last_name: string;
    display_name: string;
    language: string;
    hijack_username?: string;
    homeUrl?: string;
    portalName: string;
    faviconsList: any[];
    logo: string | boolean;
    customIcon: string;
    privileges: UserPrivileges;
    preferences?: {
        navigate_away_unsaved_changes_handling: 'prompt' | 'discard';
    };
    isAdmin: boolean;
    isPowerUser: boolean;
    isSamlUser: boolean;
    isSystemAdmin: boolean;
    brandThemeProperties?: any;
    isChangePasswordRequired?: boolean;
    mobileBannerPage?: string;
    supportAdmins: any[];
}

const blankUserInfo = {
    user_id: 0,
    username: '',
    first_name: '',
    last_name: '',
    display_name: '',
    language: 'en',
    privileges: {
        PRIV_DELETE_COLLABORATIVE_ITEMS: false,
        PRIV_ADD_ANNOTATION: false,
        PRIV_ADD_COMMENTS: false,
        PRIV_ADD_FOLDERS: false,
        PRIV_ADD_SLIDESHOW: false,
        PRIV_CREATE_ALERT_RULE: false,
        PRIV_CREATE_BUSINESS_UNITS: false,
        PRIV_CREATE_COLLECTION_TRIGGERS: false,
        PRIV_CREATE_CONTENT_WORKFLOWS: false,
        PRIV_CREATE_CUSTOM_FIELD_SECTIONS: false,
        PRIV_CREATE_DATASET: false,
        PRIV_CREATE_DATA_DEPENDENCIES: false,
        PRIV_CREATE_DATA_SOURCE: false,
        PRIV_CREATE_DIGEST_TEMPLATE: false,
        PRIV_CREATE_EDIT_ANNOUNCEMENTS: false,
        PRIV_CREATE_EDIT_BURSTS: false,
        PRIV_CREATE_EXTERNAL_REPORT_TYPE: false,
        PRIV_CREATE_FTP_CONNECTION: false,
        PRIV_CREATE_GOVERNED_TAGS: false,
        PRIV_CREATE_JS_CHART_TEMPLATE: false,
        PRIV_CREATE_MANAGE_GROUPS: false,
        PRIV_CREATE_PORTAL_PAGES: false,
        PRIV_CREATE_RATING_PROMPTS: false,
        PRIV_CREATE_TAGS: false,
        PRIV_CREATE_WORKFLOW: false,
        PRIV_DESIGNATE_SLACK_CHANNEL: false,
        PRIV_DISPLAY_VIEWS_MENU: false,
        PRIV_FULL_NS_EDITOR: false,
        PRIV_LOGIN_AS_MEMBER_OF_GROUP: false,
        PRIV_MANAGE_ACCESS_REQUESTS: false,
        PRIV_PORTAL_CSS_IMAGES: false,
        PRIV_PORTAL_PAGES_LAYOUTS: false,
        PRIV_PORTAL_PAGES_TEMPLATES: false,
        PRIV_PREFERENCES: false,
        PRIV_USE_API_TOOLKIT: false,
        PRIV_USE_CONTENT_CENTER: false,
    },
    portalName: '',
    faviconsList: [],
    homeUrl: '/',
    logo: false,
    customIcon: '',
    isAdmin: false,
    isPowerUser: false,
    isSamlUser: false,
    isSystemAdmin: false,
    brandThemeProperties: null,
    isChangePasswordRequired: false,
    mobileBannerPage: '',
    supportAdmins: [],
};

export interface OwnerConfig {
    label: string;
    enabled: boolean;
    entities: {
        name: string;
        enabled: boolean;
    }[];
}

export interface GlobalConfiguration {
    constants: {
        UI_POLLING_INTERVAL_SEC: boolean;
        ENGAGEMENT_COUNT_TIME_WINDOW: number;
        ENABLE_SMART_FOLDERS: boolean;
        DISPLAY_ELEMENT_VIEWER_OBJECT_HIERARCHY: boolean;
        ACCESS_REQUEST_BUTTON_TEXT: string;
        ACCESS_REQUEST_URL: string;
        ACCESS_REQUEST_VIA_WEBPAGE: boolean;
        DISPLAY_MENU_ITEM_FOR_MY_MOBILE: boolean;
        PASSWORDS_MIN_LENGTH: number;
        PASSWORDS_REQUIRE_NUMERIC_CHARS: boolean;
        PASSWORDS_REQUIRE_VARIOUS_REGISTER_CHARS: boolean;
        SAML_ENABLED: boolean;
        GOOGLE_OAUTH2_LOGIN: boolean;
        PROJECT_VERSION: string;
        DISPLAY_SYSTEM_BACKUP: boolean;
        ENABLE_GLOBAL_SEARCH: boolean;
        ENABLE_METADATA_COLLECTION: boolean;
        ENABLE_VOICE_SEARCH: boolean;
        DISCOVERABLE_CONTENT_DISPLAYS_DOCUMENTS: boolean;
        DISABLE_DOWNLOADS: boolean;
        MOBILE_APP_APPLE_ID: string;
    };
    owners: {
        business_owner: OwnerConfig;
        technical_owner: OwnerConfig;
        data_steward: OwnerConfig;
    };
}

const blankConfiguration = {
    constants: {
        UI_POLLING_INTERVAL_SEC: false,
        ENGAGEMENT_COUNT_TIME_WINDOW: 0,
        ENABLE_SMART_FOLDERS: false,
        DISPLAY_ELEMENT_VIEWER_OBJECT_HIERARCHY: false,
        ACCESS_REQUEST_BUTTON_TEXT: '',
        ACCESS_REQUEST_URL: '',
        ACCESS_REQUEST_VIA_WEBPAGE: false,
        DISPLAY_MENU_ITEM_FOR_MY_MOBILE: false,
        PASSWORDS_MIN_LENGTH: 6,
        PASSWORDS_REQUIRE_NUMERIC_CHARS: true,
        PASSWORDS_REQUIRE_VARIOUS_REGISTER_CHARS: true,
        SAML_ENABLED: false,
        GOOGLE_OAUTH2_LOGIN: false,
        PROJECT_VERSION: '7.0',
        DISPLAY_SYSTEM_BACKUP: false,
        ENABLE_GLOBAL_SEARCH: false,
        ENABLE_METADATA_COLLECTION: false,
        ENABLE_VOICE_SEARCH: false,
        DISCOVERABLE_CONTENT_DISPLAYS_DOCUMENTS: false,
        DISABLE_DOWNLOADS: false,
        MOBILE_APP_APPLE_ID: '',
    },
    owners: {
        business_owner: {
            label: 'business_owner',
            enabled: false,
            entities: [],
        },
        technical_owner: {
            label: 'technical_owner',
            enabled: false,
            entities: [],
        },
        data_steward: {
            label: 'data_steward',
            enabled: false,
            entities: [],
        },
    },
};

export interface topMenu {
    biTools: any[];
    externalReportTypes: any[];
    extContentTools: any[];
    topMenuTemplates: any[];
}
const blankTopMenu = {
    biTools: [],
    externalReportTypes: [],
    extContentTools: [],
    topMenuTemplates: [],
};
export interface UserAuth {
    isLoggedIn: boolean;
    userInfo?: UserInfo;
    configuration: GlobalConfiguration;
    topMenu: topMenu;
}

export interface GlobalSettings {
    userAuth: UserAuth;
}

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password, remember }: { username: string; password: string; remember?: any }, thunkAPI) => {
        try {
            const response = await authAPI.login(username, password, remember);
            const data = response?.data;
            if ('OK' === data?.status)
                return {
                    userInfo: data.userInfo,
                    configuration: data.configuration,
                    topMenu: data.topMenu,
                    redirectUrl: data?.redirectUrl,
                };

            const errorMessage = data?.error?.message || (data?.errors && data?.message);
            return thunkAPI.rejectWithValue({
                error: errorMessage,
                accountLockInfo: data?.accountLockInfo,
            });
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const logout = createAsyncThunk('auth/logout', async (params, thunkAPI) => {
    try {
        const data = await authAPI.logout();
        if ('ERROR' === data.status) return thunkAPI.rejectWithValue(data.error.message);
        if (data.redirectUrl > '') document.location = data.redirectUrl;

        return { userInfo: blankUserInfo };
    } catch (error: any) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});
declare let globalSettings: GlobalSettings;
const initialState: UserAuth = globalSettings.userAuth;

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state) {},
        setUserInfo: (state, action) => {
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
        setBrandThemeProperties: (state, action) => {
            if (state.userInfo) {
                state.userInfo.brandThemeProperties = action.payload;
            }
        },
        setCustomIcon: (state, action) => {
            if (state.userInfo) {
                state.userInfo.customIcon = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.userInfo = (action as { payload: { userInfo: UserInfo } }).payload.userInfo;
                state.configuration = (
                    action as { payload: { configuration: GlobalConfiguration } }
                ).payload.configuration;
                state.topMenu = (action as { payload: { topMenu: topMenu } }).payload.topMenu;
            })
            .addCase(login.rejected, (state, action) => {})
            .addCase(logout.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.userInfo = blankUserInfo;
                state.configuration = blankConfiguration;
                state.topMenu = blankTopMenu;
            })
            .addCase(logout.rejected, (state, action) => {});
    },
});

export const { setAuth, setBrandThemeProperties, setCustomIcon, setUserInfo } = authSlice.actions;
export default authSlice.reducer;
