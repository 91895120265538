import React from 'react';
import useBundleTranslation from 'i18n';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import GridComponent from 'components/common/grid/GridComponent';

interface IProps {
    popupOpen: boolean;
    setPopupOpen: (state: boolean) => void;
    gridUrl: string;
    translationNS: string;
}

const CodeEditorHistoryPopup: React.FC<IProps> = ({ popupOpen, setPopupOpen, gridUrl, translationNS }) => {
    const { t } = useBundleTranslation(translationNS);

    const historyPopupSettings: PopupSettings = {
        title: t('change_log'),
        textOK: t('close'),
        noCancel: true,
    };

    const onHide = () => {
        setPopupOpen(false);
    };

    return popupOpen ? (
        <Popup
            open={popupOpen}
            maxWidth={'popupXl'}
            key={'code-editor-history'}
            settings={historyPopupSettings}
            onHide={onHide}
            onConfirm={onHide}
        >
            <GridComponent uid={gridUrl} settingsUrl={gridUrl} t={t} />
        </Popup>
    ) : null;
};

export default CodeEditorHistoryPopup;
