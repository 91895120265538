import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import useBundleTranslation from 'i18n';
import { useFormState } from 'react-hook-form';
import { instance } from 'api/api';
import { APIResponse, AssocArray } from 'tools/types';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

export interface PluginGetTokenProps extends FormControlProps {
    buttonText: string;
    infoMessage?: string;
    windowUrl: string;
    windowTitle: string;
    settingsUrl: string;
}

const tokenFormUpdatePropertyValue = function (
    elIds: Array<string>,
    value: string,
    fieldToSave: string | false,
    locationSearch: string,
) {
    elIds.forEach((elId) => {
        const element = document.querySelector('[data-test="' + elId + '"]') as HTMLElement | null;
        if (element == null) {
            return;
        }
        if (element.tagName == 'INPUT') {
            //@ts-ignore
            element.value = value;
        } else {
            element.innerText = value;
        }
    });

    const paramsList: AssocArray<string> = {};
    locationSearch
        .substring(1)
        .split('&')
        .forEach((param) => {
            const tmp = param.split('=');
            paramsList[tmp[0]] = tmp[1];
        });
    let profileId = paramsList?.plugin_connection_profile_id ?? 0;

    if (!profileId) {
        const profileIdField = document.querySelector(
            'input[name="plugin_connection_profile_id"]',
        ) as HTMLInputElement | null;
        if (profileIdField !== null) {
            profileId = profileIdField.value;
        }
    }

    let sourceId = paramsList?.source_database_connection_id ?? 0;
    if (!profileId && !sourceId) {
        const sourceIdField = document.querySelector(
            'input[name="source_database_connection_id"]',
        ) as HTMLInputElement | null;
        if (sourceIdField !== null) {
            sourceId = sourceIdField.value;
        }
    }

    if (fieldToSave !== false) {
        const fieldToSaveEl = document.querySelector('[data-test="' + fieldToSave + '"]') as HTMLElement | null;
        if (fieldToSaveEl == null) {
            return;
        }

        if (profileId) {
            const name = fieldToSaveEl.getAttribute('name') ?? '';
            let reg = /^parameter_([0-9]+)(_encrypted)?/;
            const res = reg.exec(name);
            if (res) {
                const pluginConnectionProfileParameterId = res[1];
                const value =
                    fieldToSaveEl.tagName == 'INPUT' ? fieldToSaveEl.getAttribute('value') : fieldToSaveEl.innerText;
                const fieldLabel = document.querySelector(
                    '[data-test="' + fieldToSave + '"]~div',
                ) as HTMLElement | null;
                if (fieldLabel !== null && value !== null) {
                    fieldLabel.innerText = value;
                }
                const encryptField = document.querySelector(
                    'input[name="parameter_' + pluginConnectionProfileParameterId + '_encrypt_ind"]',
                ) as HTMLInputElement | null;
                const isEncrypted = encryptField !== null && encryptField.value === 'Y' ? 'Y' : 'N';
                const valueKey = isEncrypted === 'Y' ? 'value_encrypted' : 'value';
                const data = {
                    [valueKey]: value,
                    encrypt_ind: isEncrypted,
                };

                instance
                    .post(
                        `/data/editor/plugin-source/${profileId}/parameter/${pluginConnectionProfileParameterId}/form`,
                        data,
                    )
                    .then((response) => {
                        fieldToSaveEl?.parentElement?.parentElement?.classList?.remove('d-none');
                        fieldToSaveEl?.parentElement?.parentElement?.parentElement?.classList?.remove('d-none');
                    });
            }
        } else if (sourceId) {
            const value =
                fieldToSaveEl.tagName == 'INPUT' ? fieldToSaveEl.getAttribute('value') : fieldToSaveEl.innerText;
            const fieldLabel = document.querySelector('[data-test="' + fieldToSave + '"]~div') as HTMLElement | null;
            if (fieldLabel !== null && value !== null) {
                fieldLabel.innerText = value;
                fieldToSaveEl?.parentElement?.parentElement?.classList?.remove('d-none');
                fieldToSaveEl?.parentElement?.parentElement?.parentElement?.classList?.remove('d-none');
            }
        }
    }
};

export default function PluginGetToken({ controlProps }: FormElementControlPropsType<PluginGetTokenProps>) {
    const { t } = useBundleTranslation(['components/common/get_plugin_token']);
    const [searchParams, setSearchParams] = useSearchParams();

    const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL?.length
        ? process.env.REACT_APP_BACKEND_API_BASE_URL + '/'
        : '/';

    const [windowTitle, setWindowTitle] = useState(controlProps.windowTitle);
    const [windowUrl, setWindowUrl] = useState(controlProps.windowUrl);
    const [buttonText, setButtonText] = useState(controlProps.buttonText);
    const [infoMessage, setInfoMessage] = useState(controlProps.infoMessage);

    const { isDirty } = useFormState({ control: controlProps.form.hookFormControl });
    const handleClick = () => {
        const openWindow = () => {
            window.open(baseUrl + windowUrl, windowTitle, 'width=530,height=530');
        };

        if (isDirty) {
            controlProps.form.formSave().then(() => openWindow());
        } else {
            openWindow();
        }
    };

    useEffect(() => {
        if (controlProps.settingsUrl && -1 !== controlProps.settingsUrl.indexOf('user_token=Y')) {
            //@ts-ignore
            window.tokenFormUpdatePropertyValue = () => {
                const redirect_url: string =
                    //@ts-ignore
                    searchParams.has('redirect_url') && searchParams.get('redirect_url') > ''
                        ? (searchParams.get('redirect_url') ?? '')
                        : '';

                if (redirect_url > '') document.location = redirect_url;
                else document.location.reload();
            };
        } else {
            //@ts-ignore
            window.tokenFormUpdatePropertyValue = tokenFormUpdatePropertyValue;
        }

        return () => {
            //@ts-ignore
            delete window.tokenFormUpdatePropertyValue;
        };
    }, []);

    const [isLoading, setIsLoading] = useState(controlProps.settingsUrl != '');
    useEffect(() => {
        if (!controlProps.settingsUrl) {
            return;
        }
        instance
            .get(controlProps.settingsUrl)
            .then((response: AxiosResponse<APIResponse<any>>) => {
                if (response.data.status == 'OK') {
                    setWindowTitle(response.data.data.windowTitle);
                    setWindowUrl(response.data.data.windowUrl);
                    setButtonText(response.data.data.buttonText);
                    setInfoMessage(response.data.data?.infoMessage ?? '');
                }
            })
            .finally(() => setIsLoading(false));
    }, [controlProps.settingsUrl]);

    if (isLoading) {
        return null;
    }

    return (
        <Box>
            {undefined !== infoMessage && infoMessage > '' && (
                <Box sx={{ pb: 3 }}>
                    {infoMessage.split('\\n').map(function (item: any, key: any) {
                        return <Box key={key}>{item}</Box>;
                    })}
                </Box>
            )}
            <Box>
                <Button type="button" onClick={handleClick}>
                    {t(buttonText)}
                </Button>
            </Box>
        </Box>
    );
}

export class PluginGetTokenBuilder extends FormComponentBuilder {
    prepareProps(): PluginGetTokenProps {
        return {
            ...this.controlProps,
            buttonText: this.elementProps.componentProps?.buttonText ?? '',
            infoMessage: this.elementProps.componentProps?.infoMessage ?? '',
            windowUrl: this.elementProps.componentProps?.windowUrl ?? '',
            windowTitle: this.elementProps.componentProps?.windowTitle ?? '',
            settingsUrl: this.elementProps.componentProps?.settingsUrl ?? '',
        };
    }
}
