import { DatasetFieldValueType, ReportContentNS } from 'components/report-content/index';
import { AssocArray } from 'tools/types';
import { DatasetCellValue } from 'components/report-content/components/dataset/index';
import { FieldConditionRuleNS } from 'components/common/field-condition-rule';
import Rule = FieldConditionRuleNS.Rule;
import CompareConditionType = FieldConditionRuleNS.CompareConditionType;
import DateTimeUnitType = FieldConditionRuleNS.DateTimeUnitType;
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import DataRowType = ReportContentNS.DataRowType;

type RGBType = { r: number; g: number; b: number };

export class CellStyler {
    component: BlockComponent<ComponentSettingsDataset>;
    zebraColors: AssocArray<string>;

    constructor(component: BlockComponent<ComponentSettingsDataset>) {
        this.component = component;
        // this.zebraMask     = {r: 141, g:141, b:141};
        this.zebraColors = {};
    }

    // TODO: decoration: any?
    getCellStyle(index: number, field: ComponentSettingsDatasetField, decoration: any, odd: boolean) {
        const bgColor =
            typeof decoration.bgColor == 'undefined'
                ? typeof field.bgColor == 'undefined'
                    ? '#fff'
                    : field.bgColor
                : decoration.bgColor;

        const bgColorEven = this.component.settings.zebra_striping == 'Y' ? this.getZebraColor(bgColor) : bgColor;
        const actualBg = odd ? bgColor : bgColorEven;

        return {
            classRules: {
                'block-wrapper-fixed-column': this.component.settings.fixedColumns - 1 >= index,
                'calculated-color': decoration != false,
                'wrapper-cell': typeof field != 'undefined' && field.textWrap == true,
                'bold-cell': decoration.textBold ?? (field && field.textBold),
                'italic-cell':
                    typeof decoration.textItalic == 'undefined' ? field && field.textItalic : decoration.textItalic,
            },
            styleRules: {
                backgroundColor: actualBg,
                color:
                    typeof decoration.textColor == 'undefined'
                        ? typeof field.textColor == 'undefined'
                            ? false
                            : field.textColor
                        : decoration.textColor,
                textAlign: typeof field.textAlign == 'undefined' ? false : field.textAlign,
                width:
                    typeof field.columnSize == 'undefined' || field.columnSize == null
                        ? false
                        : field.columnSize + 'px',
            },
            attrs: {
                'style-bgcolor-odd': bgColor,
                'style-bgcolor-even': bgColorEven,
            },
        };
    }

    buildClassStr(classList: AssocArray<boolean>) {
        const classStr = [];
        for (let c in classList) {
            if (classList.hasOwnProperty(c) && classList[c]) {
                classStr.push(c);
            }
        }
        return classStr.join(' ');
    }
    //
    // buildStyleStr(styleList, wrapWithAttr) {
    //     var styleStr = [];
    //     for (var c in styleList) {
    //         if (styleList.hasOwnProperty(c)) {
    //             if (styleList[c] !== false) {
    //                 styleStr.push(c + ':' + styleList[c]);
    //             }
    //         }
    //     }
    //     styleStr = styleStr.join(';');
    //     return wrapWithAttr ? "style='" + styleStr + "'" : styleStr;
    // }
    //
    // buildAttrsStr(attrsList) {
    //     var attrsStr = [];
    //     for (var c in attrsList) {
    //         if (attrsList.hasOwnProperty(c)) {
    //             if (attrsList[c] !== false) {
    //                 attrsStr.push(c + '="' + attrsList[c] + '"');
    //             }
    //         }
    //     }
    //     return attrsStr.join(' ');
    // }

    getZebraColor(bgcolor: string | false) {
        if (bgcolor === false) {
            return false;
        }

        if (typeof this.zebraColors[bgcolor] != 'undefined') {
            return this.zebraColors[bgcolor];
        }

        var hexToRgb = function (hex: string): RGBType | null {
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                      r: parseInt(result[1], 16),
                      g: parseInt(result[2], 16),
                      b: parseInt(result[3], 16),
                  }
                : null;
        };

        var componentToHex = function (c: number) {
            var hex = c.toString(16);
            return hex.length == 1 ? '0' + hex : hex;
        };

        var rgbToHex = function (color: RGBType) {
            return '#' + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
        };

        var tmp: RGBType | null = hexToRgb(bgcolor);

        if (!tmp) {
            return false;
        }

        var bgcolorAlpha = 1;

        const reportTableColors = {
            zebraMask: { r: 202, g: 202, b: 202, a: 0.09 },
        };

        var aRes = reportTableColors.zebraMask.a + bgcolorAlpha * (1 - reportTableColors.zebraMask.a);
        var c = bgcolorAlpha * (1 - reportTableColors.zebraMask.a);
        for (var i in tmp) {
            if (tmp.hasOwnProperty(i)) {
                //@ts-ignore
                tmp[i] = Math.round(reportTableColors.zebraMask[i] * reportTableColors.zebraMask.a + tmp[i] * c);
            }
        }

        this.zebraColors[bgcolor] = rgbToHex(tmp);
        return this.zebraColors[bgcolor];
    }

    calculateCellDecoration(cell: DatasetCellValue, dataRow: DataRowType): any {
        var self = this;
        var result: any = false;
        // cellName = typeof cellName == 'undefined' || null == cellName ? '' : cellName.toLowerCase();
        // var field = this.component.getColumnByUid(cellName);

        const computationExtended = new ComputationExtended();

        if (this.component.settings.formationRulesData)
            this.component.settings.formationRulesData.forEach(function (rule) {
                if (rule.format.applyTo == -1 || rule.format.applyTo == cell.field.reference_name_escaped) {
                    const mainField = self.component.settings.fields.find(function (el) {
                        return el.reference_name_escaped == rule.rules[0].field.replace('-current', '');
                    });

                    if (!mainField) {
                        return;
                    }

                    const mainValue = dataRow[mainField.reference_name];

                    let secondField = undefined;
                    let secondValue = undefined;

                    if (rule.rules[0].field_second != 'a value' && rule.rules[0].field_second != '') {
                        secondField = self.component.settings.fields.find(function (el) {
                            return el.reference_name_escaped == rule.rules[0].field_second.replace('-current', '');
                        });
                        if (secondField) {
                            secondValue = dataRow[secondField.reference_name];
                        }
                    }

                    if (computationExtended.calculate(rule.rules[0], mainField, mainValue, secondField, secondValue)) {
                        if (result == false) result = {};

                        if (rule.format.textColor) result.textColor = rule.format.textColor;
                        if (rule.format.bgColor) result.bgColor = rule.format.bgColor;
                        if (rule.format.textBold) result.textBold = rule.format.textBold;
                        if (rule.format.textItalic) result.textItalic = rule.format.textItalic;
                    }
                    if (result) return;
                }
            });
        return result;
    }
}

type CompareValueType = number | string | null;
type OperationFuncType = (cell: CompareValueType, data: CompareValueType, data2?: CompareValueType) => boolean;

export class Computation {
    calculate(rule: Rule, field: ComponentSettingsDatasetField, value: CompareValueType) {
        const funcMap = this.getFuncMap(field.value_type);
        if (typeof funcMap[rule.condition] == 'undefined') return false;

        return funcMap[rule.condition](
            this.prepareValue(field.value_type, value),
            this.prepareValue(field.value_type, rule.data),
            this.prepareValue(field.value_type, rule.data_second),
        );
    }

    getFuncMap(fieldType: DatasetFieldValueType): AssocArray<OperationFuncType> {
        var funcMap = {};

        switch (fieldType) {
            case 'numeric':
                funcMap = {
                    '>': (cell: CompareValueType, data: CompareValueType) =>
                        cell === '' ? false : Number(cell) > Number(data),
                    '>=': (cell: CompareValueType, data: CompareValueType) =>
                        cell === '' ? false : Number(cell) >= Number(data),
                    '=': (cell: CompareValueType, data: CompareValueType) => (cell === '' ? false : cell == data),
                    '!=': (cell: CompareValueType, data: CompareValueType) => (cell === '' ? false : cell != data),
                    '<': (cell: CompareValueType, data: CompareValueType) =>
                        cell === '' ? false : Number(cell) < Number(data),
                    '<=': (cell: CompareValueType, data: CompareValueType) =>
                        cell === '' ? false : Number(cell) <= Number(data),
                    between: function (cell: CompareValueType, data: CompareValueType, data2: CompareValueType) {
                        if (cell === '') return false;
                        cell = Number(cell);
                        data = Number(data);
                        data2 = Number(data2);
                        return (data <= cell && cell <= data2) || (data2 <= cell && cell <= data);
                    },
                };
                break;
            case 'text':
                funcMap = {
                    '=': (cell: CompareValueType, data: CompareValueType) =>
                        String(cell).toLowerCase() == String(data).toLowerCase(),
                    '!=': (cell: CompareValueType, data: CompareValueType) =>
                        String(cell).toLowerCase() != String(data).toLowerCase(),
                    contains: (cell: CompareValueType, data: CompareValueType) =>
                        String(cell).toLowerCase().indexOf(String(data).toLowerCase()) != -1,
                    'does not contain': (cell: CompareValueType, data: CompareValueType) =>
                        String(cell).toLowerCase().indexOf(String(data).toLowerCase()) == -1,
                };
                break;
            case 'datetime':
                break;
        }

        return funcMap;
    }

    prepareValue(fieldType: DatasetFieldValueType, value: CompareValueType) {
        if (fieldType == 'numeric') {
            if (typeof value == 'string') {
                value = parseFloat(value.replace(/\,/g, ''));
            }
            // @ts-ignore
            if (isNaN(value) || value == null || value === '') {
                value = 0;
            }
        } else if (fieldType == 'text') {
            if (typeof value == 'undefined' || value == null) value = '';
            value = String(value).toLowerCase();
        } else if (fieldType == 'datetime') {
            if (value == '' || value == null) {
                return '';
            }
            value = String(value).replace(/\-/g, '/');
            value = Date.parse(value + ' GMT') / 1000;
        }
        return value;
    }
}

interface DateTimeData {
    value: CompareValueType;
    units: DateTimeUnitType;
}
type DateTimeCompareValueType = CompareValueType | DateTimeData;

type ExtendedOperationFuncType = (
    mainValue: CompareValueType,
    secondValue: CompareValueType,
    conditionValue: DateTimeCompareValueType,
    compareCondition: CompareConditionType,
) => boolean;

class ComputationExtended extends Computation {
    calculate(
        rule: Rule,
        mainField: ComponentSettingsDatasetField,
        mainValue: CompareValueType,
        secondField?: ComponentSettingsDatasetField,
        secondValue?: CompareValueType,
    ) {
        const ruleCopy = { ...rule };

        let ruleData: DateTimeCompareValueType = ruleCopy.data;

        if (secondValue === null || typeof secondValue == 'undefined') {
            secondValue = ruleCopy.data;
            // @ts-ignore
            if (mainField.value_type == 'datetime' && parseFloat(ruleCopy.data) == ruleCopy.data) {
                secondValue = '';
            } else if (typeof secondField == 'object' && secondField && mainField.value_type == 'numeric') {
                secondValue = '';
            }
            ruleData = 0;
        } else {
            if (mainField.value_type == 'datetime') {
                let units: DateTimeUnitType = 'day';
                if (
                    typeof ruleCopy.compare_date_shift != 'undefined' &&
                    ruleCopy.compare_date_shift != null &&
                    //@ts-ignore
                    ruleCopy.compare_date_shift != ''
                ) {
                    units = ruleCopy.compare_date_shift;
                }
                ruleData = {
                    value: ruleCopy.data,
                    units: units,
                };
            } else {
                ruleData = this.prepareValue(mainField.value_type, ruleCopy.data);
            }
        }

        mainValue = this.prepareValue(mainField.value_type, mainValue);
        secondValue = this.prepareValue(mainField.value_type, secondValue);
        const funcMap = this.getExtendedFuncMap(mainField.value_type);
        if (typeof funcMap[ruleCopy.condition] == 'undefined') return false;

        return funcMap[ruleCopy.condition](mainValue, secondValue, ruleData, ruleCopy.compare_condition);
    }

    getExtendedFuncMap(fieldType: DatasetFieldValueType): AssocArray<ExtendedOperationFuncType> {
        let funcMap: AssocArray<ExtendedOperationFuncType> = {};

        const applyDateTimeConditionValue = (
            mainValue: CompareValueType,
            conditionValue: DateTimeCompareValueType,
        ): number => {
            //@ts-ignore
            if (conditionValue && typeof conditionValue.value != 'undefined' && conditionValue?.value != 0) {
                const date = new Date(Number(mainValue) * 1000);
                const val = Number((conditionValue as DateTimeData).value);
                switch ((conditionValue as DateTimeData).units) {
                    case 'hour':
                        date.setHours(date.getHours() - val);
                        break;
                    case 'day':
                        date.setDate(date.getDate() - val);
                        break;
                    case 'week':
                        date.setDate(date.getDate() - val * 7);
                        break;
                    case 'month':
                        date.setMonth(date.getMonth() - val);
                        break;
                    case 'year':
                        date.setFullYear(date.getFullYear() - val);
                        break;
                }
                return date.getTime() / 1000;
            }
            //@ts-ignore
            if (isNaN(conditionValue)) {
                conditionValue = 0;
            }
            //@ts-ignore
            return mainValue - conditionValue;
        };

        switch (fieldType) {
            case 'numeric':
                funcMap = {
                    equals: (mainValue: CompareValueType, secondValue: CompareValueType) => {
                        if (mainValue === secondValue) {
                            return true;
                        }
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        return mainValue === secondValue;
                    },
                    'is greater than': (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                        compareCondition: CompareConditionType,
                    ) => {
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        secondValue = Number(secondValue);
                        conditionValue = Number(conditionValue);
                        if (compareCondition == 'a value') {
                            secondValue += conditionValue;
                        }
                        if (compareCondition == 'a percent') {
                            secondValue *= (100 + conditionValue) / 100;
                        }

                        return Number(mainValue) > secondValue;
                    },
                    'is greater than or equal to': (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                        compareCondition: CompareConditionType,
                    ) => {
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        secondValue = Number(secondValue);
                        conditionValue = Number(conditionValue);
                        if (compareCondition == 'a value') {
                            secondValue += conditionValue;
                        }
                        if (compareCondition == 'a percent') {
                            secondValue *= (100 + conditionValue) / 100;
                        }

                        return Number(mainValue) >= secondValue;
                    },
                    'is less than': (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                        compareCondition: CompareConditionType,
                    ) => {
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        mainValue = Number(mainValue);
                        secondValue = Number(secondValue);
                        conditionValue = Number(conditionValue);
                        if (compareCondition == 'a value') {
                            mainValue += conditionValue;
                        }
                        if (compareCondition == 'a percent') {
                            secondValue *= (100 - conditionValue) / 100;
                        }

                        return mainValue < secondValue;
                    },
                    'is less than or equal to': (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                        compareCondition: CompareConditionType,
                    ) => {
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        mainValue = Number(mainValue);
                        secondValue = Number(secondValue);
                        conditionValue = Number(conditionValue);
                        if (compareCondition == 'a value') {
                            mainValue += conditionValue;
                        }
                        if (compareCondition == 'a percent') {
                            secondValue *= (100 - conditionValue) / 100;
                        }
                        return mainValue <= secondValue;
                    },
                    'does not equal': function (mainValue: CompareValueType, secondValue: CompareValueType) {
                        if (mainValue === '' || secondValue === '' || mainValue == null || secondValue == null) {
                            return false;
                        }
                        return mainValue !== secondValue;
                    },
                    'is empty': function (mainValue: CompareValueType) {
                        return mainValue === '' || mainValue == null || mainValue === 0;
                    },
                    'is not empty': function (mainValue: CompareValueType) {
                        return !(mainValue === '' || mainValue == null || mainValue === 0);
                    },
                };
                break;
            case 'text':
                funcMap = {
                    'exactly matches': (mainValue: CompareValueType, secondValue: CompareValueType) =>
                        mainValue === secondValue,
                    contains: (mainValue: CompareValueType, secondValue: CompareValueType) =>
                        String(mainValue).indexOf(String(secondValue)) != -1,
                    'does not contain': (mainValue: CompareValueType, secondValue: CompareValueType) =>
                        String(mainValue).indexOf(String(secondValue)) == -1,
                    'is in list': (mainValue: CompareValueType, secondValue: CompareValueType) => {
                        const list = String(secondValue)
                            .split(',')
                            .map((s) => s.trim());
                        return list.indexOf(String(mainValue)) != -1;
                    },
                    'is not in list': (mainValue: CompareValueType, secondValue: CompareValueType) => {
                        const list = String(secondValue)
                            .split(',')
                            .map((s) => s.trim());
                        return list.indexOf(String(mainValue)) == -1;
                    },
                    'does not equal': (mainValue: CompareValueType, secondValue: CompareValueType) =>
                        mainValue != secondValue,
                    'starts with': (mainValue: CompareValueType, secondValue: CompareValueType) =>
                        String(mainValue).indexOf(String(secondValue)) == 0,
                    'is empty': (mainValue: CompareValueType) => mainValue === '' || mainValue == null,
                    'is not empty': (mainValue: CompareValueType) => !(mainValue === '' || mainValue == null),
                };
                break;
            case 'datetime':
                funcMap = {
                    equals: function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }

                        return applyDateTimeConditionValue(mainValue, conditionValue) === secondValue;
                    },
                    'is greater than': function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }
                        return applyDateTimeConditionValue(mainValue, conditionValue) > Number(secondValue);
                    },
                    'is greater than or equal to': function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }
                        return applyDateTimeConditionValue(mainValue, conditionValue) >= Number(secondValue);
                    },
                    'is less than': function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }

                        return applyDateTimeConditionValue(mainValue, conditionValue) < Number(secondValue);
                    },
                    'is less than or equal to': function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }
                        return applyDateTimeConditionValue(mainValue, conditionValue) <= Number(secondValue);
                    },
                    'does not equal': function (
                        mainValue: CompareValueType,
                        secondValue: CompareValueType,
                        conditionValue: DateTimeCompareValueType,
                    ) {
                        if (mainValue === '' || secondValue === '') {
                            return false;
                        }
                        return applyDateTimeConditionValue(mainValue, conditionValue) !== secondValue;
                    },
                    'is empty': function (mainValue: CompareValueType) {
                        return mainValue === '';
                    },
                    'is not empty': function (mainValue: CompareValueType) {
                        return mainValue !== '';
                    },
                };
                break;
        }
        return funcMap;
    }
}
