import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default {
    sectionBlock: {
        border: '1px solid',
        borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
        borderRadius: '4px',
        padding: '16px',
    },
    popup: {
        width: '408px',
        padding: '32px 0 16px',
        boxShadow: (theme: Theme) => `0px 4px 24px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
    },
    popupIcon: {
        fontSize: '32px',
        color: 'primary.main',
    },
    popupTitle: {
        fontSize: '14px',
        fontWeight: 600,
    },
    popupText: {
        textAlign: 'center',
        padding: '0 24px',
    },
    gridHolder: {
        marginTop: '-24px',
    },
};
