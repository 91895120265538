import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { useParams } from 'react-router-dom';
import RatingComponentPageWrapper from 'components/rating/RatingComponentPageWrapper';

export default function PageRatingPrompt() {
    const urlParams: UrlParams = useParams();
    const portalPageName = urlParams.portalPageName ?? '';

    return <RatingComponentPageWrapper itemType="page" itemId={portalPageName} flexedPosition />;
}
