import { alpha, Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import IconMi from 'components/common/icon/IconMi';
import styles from 'components/common/page-menu/PageMenu.styles';
import useBundleTranslation from 'i18n';

export default function BlockEditPanelHeader({
    title,
    item,
    onCancel,
}: {
    title?: string;
    item?: string;
    onCancel: () => void;
}) {
    const { t } = useBundleTranslation();

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
                pl: 3,
                pr: 2,
                py: 1.25,
                borderBottom: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
        >
            {item ? (
                <>
                    <Tabs
                        value={1}
                        onChange={(value) => {
                            onCancel();
                        }}
                        sx={{
                            ...styles.tabs,
                            ...{
                                mb: '-11px',
                                ml: -1.5,
                                mt: '-10px',
                                minHeight: '49px',
                                '.Mui-selected': { backgroundColor: 'transparent' },
                            },
                        }}
                    >
                        <Tab
                            icon={<IconMi icon={'chevron-left'} sx={styles.tabItem} />}
                            iconPosition="start"
                            label={<Box sx={styles.labelHolder}>{t('back_to', { name: title })}</Box>}
                            value={'back'}
                            sx={{ minHeight: '49px' }}
                        />
                        <Box
                            sx={{
                                width: '1px',
                                height: '16px',
                                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                alignSelf: 'center',
                            }}
                        />
                        <Tab
                            sx={{ minHeight: '49px', backgroundColor: 'transparent !important' }}
                            value={1}
                            label={<Box sx={styles.labelHolder}>{item}</Box>}
                        />
                    </Tabs>
                </>
            ) : (
                <Box sx={{ flexGrow: 1, fontSize: '14px', fontWeight: '600', color: 'primary.main' }}>
                    {title ?? ''}
                </Box>
            )}

            <IconButton onClick={() => onCancel()} sx={{ flexShrink: 0 }} data-test={`close-button`}>
                <IconMi icon="times" fontSize={'12'} />
            </IconButton>
        </Stack>
    );
}
