import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import useBundleTranslation from 'i18n';
import { useQuery } from '@tanstack/react-query';
import { instance } from 'api/api';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, FormLabel, Stack } from '@mui/material';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
export interface FilterDateDefaultProps extends FormControlProps {
    referenceId: number;
    itemId: number;
    itemType: string;
    hasEmpty?: boolean;
}

interface MeasurementTime {
    id: number;
    internal_name: string;
    measurement_interval_button_name: string;
    name: string;
}

const weeklyFilters = {
    day: ['first', 'last'],
    week: ['this', 'last'],
};

export default function FilterDateDefault({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FilterDateDefaultProps>) {
    const { t } = useBundleTranslation(['app/editor/element/extreport']);

    let queryKey = ['measurement_time_filter_values'];

    const { data } = useQuery<Array<MeasurementTime>, Error>({
        queryKey: queryKey,
        queryFn: async () => {
            const response = await instance.get(
                `/data/editor/external-reference/usage/get-time-commands?item_id=${controlProps.itemId}&item_type=${controlProps.itemType}`,
            );
            return response.data.data;
        },
    });

    const [filters, setFilters] = useState<{
        [key: string]: Array<{ label: string; value: string; internalName: string }>;
    }>({});
    const [intervalOptions, setIntervalOptions] = useState<Array<string>>([]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const filterKeys: Array<string> = [];
        const result: any = {};
        if (controlProps.hasEmpty) {
            result['None'] = [
                {
                    label: 'None',
                    value: 0,
                    internalName: 'None',
                },
            ];
        }

        data.forEach((item) => {
            const key = item.measurement_interval_button_name;
            result[key] = result[key] || [];
            result[key].push({
                label: item.name,
                value: item.id,
                internalName: item.internal_name,
            });
        });

        Object.keys(result).forEach((key) => {
            if (result[key].length < 2 && key != 'None') {
                delete result[key];
            } else {
                filterKeys.push(key);
            }
        });
        setFilters(result);
        setIntervalOptions(filterKeys);
    }, [data]);

    const componentForm: FormDataAPIType = useCustomSimplifiedForm({
        weekly_day: 'first',
        weekly_week: 'this',
    });

    useEffect(() => {
        if (intervalOptions.length == 0) {
            return;
        }
        if (!controlProps.value) {
            // If value empty - select first available option
            const first = intervalOptions.find((f) => f != 'Weekly');
            if (first) {
                componentForm.form.hookFormSetValue('interval', first);
                componentForm.form.hookFormSetValue('interval_name', filters[first][0].value);
            }
            return;
        }
        // Setup default options
        intervalOptions.forEach((key) => {
            const filter = filters[key].find((f) => f.value == controlProps.value);
            if (filter) {
                componentForm.form.hookFormSetValue('interval', key);
                if (key === 'Weekly') {
                    const weekly = filters[key].find((f) => f.value == controlProps.value);
                    if (weekly) {
                        switch (weekly.internalName) {
                            case 'last week':
                                componentForm.form.hookFormSetValue('weekly_day', 'last');
                                componentForm.form.hookFormSetValue('weekly_week', 'last');
                                break;
                            case 'last week (start)':
                                componentForm.form.hookFormSetValue('weekly_week', 'last');
                                break;
                            case 'this week':
                                componentForm.form.hookFormSetValue('weekly_day', 'last');
                                break;
                        }
                    }
                } else {
                    componentForm.form.hookFormSetValue('interval_name', filter.value);
                }
            }
        });
    }, [filters, intervalOptions]);

    const interval = componentForm.form.hookFormWatch('interval');
    const intervalName = componentForm.form.hookFormWatch('interval_name');
    const weeklyDay = componentForm.form.hookFormWatch('weekly_day');
    const weeklyWeek = componentForm.form.hookFormWatch('weekly_week');

    useLayoutEffect(() => {
        if (!interval || !filters[interval]) {
            return;
        }
        setTimeout(() => {
            const intervalName = componentForm.form.hookFormGetValues('interval_name');
            if (filters[interval].findIndex((f) => f.value == intervalName) == -1) {
                componentForm.form.hookFormSetValue('interval_name', filters[interval][0].value);
            }
        }, 10);
    }, [interval]);

    // Update value
    useEffect(() => {
        if (!interval) {
            return;
        }

        if (interval != 'Weekly') {
            elementProps?.form?.hookFormSetValue(controlProps.name, intervalName);
            const v = filters[interval].find((f) => f.value == intervalName);
            if (v) {
                elementProps?.form.hookFormSetValue(controlProps.name + '_label', v.label);
            }
        } else {
            const week = weeklyWeek === 'last' ? 'last week' : 'this week';
            const day = weeklyDay === 'first' ? ' (start)' : '';
            const weekly = filters[interval].find((f) => f.internalName == week + day);
            if (weekly) {
                elementProps?.form?.hookFormSetValue(controlProps.name, weekly.value);
                elementProps?.form.hookFormSetValue(controlProps.name + '_label', week + day);
            }
        }
    }, [interval, intervalName, weeklyDay, weeklyWeek]);

    if (!data) {
        return null;
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <Box>
                <FormLabel>
                    {t('uses')} {t('interval')}
                </FormLabel>
                <Stack direction={'row'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <ReactHookFormController
                            componentValues={intervalOptions.map((f) => ({ label: f, value: f }))}
                            elementProps={prepareFormElementProps({
                                ...componentForm,
                                ...{
                                    component: {
                                        component: 'FormSelect',
                                        name: 'interval',
                                    } as RawFormComponentType,
                                },
                            })}
                        />
                    </Box>
                </Stack>
            </Box>
            {interval != 'None' && (
                <Box>
                    {typeof filters[interval] != 'undefined' && interval != 'Weekly' && (
                        <Box>
                            <FormLabel>{t('set_to')}</FormLabel>
                            <ReactHookFormController
                                componentValues={filters[interval].map((f) => ({ label: f.label, value: f.value }))}
                                elementProps={prepareFormElementProps({
                                    ...componentForm,
                                    ...{
                                        component: {
                                            component: 'FormRadioGroup',
                                            name: 'interval_name',
                                        } as RawFormComponentType,
                                    },
                                })}
                            />
                        </Box>
                    )}
                    {typeof filters[interval] != 'undefined' && interval == 'Weekly' && (
                        <Stack direction={'column'} spacing={1}>
                            {['day', 'week'].map((type) => (
                                <Box key={type}>
                                    <FormLabel>{t(type == 'day' ? 'set_to' : 'day_of')}</FormLabel>
                                    <ReactHookFormController
                                        //@ts-ignore
                                        componentValues={weeklyFilters[type].map((f) => ({
                                            label: t(`week.${type}.${f}`),
                                            value: f,
                                        }))}
                                        elementProps={prepareFormElementProps({
                                            ...componentForm,
                                            ...{
                                                component: {
                                                    component: 'FormSelect',
                                                    name: `weekly_${type}`,
                                                } as RawFormComponentType,
                                            },
                                        })}
                                    />
                                </Box>
                            ))}
                        </Stack>
                    )}
                </Box>
            )}
        </Stack>
    );
}

export class FilterDateDefaultBuilder extends FormComponentBuilder {
    prepareProps(): FilterDateDefaultProps {
        return {
            ...this.controlProps,
            referenceId: Number(this.elementProps.componentProps?.referenceId ?? 0),
            itemId: Number(this.elementProps.componentProps?.itemId ?? 0),
            itemType: this.elementProps.componentProps?.itemType ?? 'element',
            hasEmpty: this.elementProps.componentProps?.hasEmpty ?? false,
        };
    }
}
