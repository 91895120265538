import { useContext, useEffect, useState } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import IBlocksContext = ReportContentNS.IBlocksContext;
import BlocksContext = ReportContentNS.BlocksContext;
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import ComponentSettingsDatasetExtViz = ReportContentNS.ComponentSettingsDatasetExtViz;

export function getEmptyDatasetExtViz(): ComponentSettingsDatasetExtViz {
    return {
        link_to_dataset: 'N',
        filters: [],
        report_ext_vis_element_id: 0,
        report_ext_vis_applicable_scope: 'row',
        report_max_viz_number_to_display: 0,
        report_ext_vis_images_in_email: 0,
        report_refer_from_email_to: 'self',
        dataset_block_uid: '',
    };
}

function getExtViz(settings: ComponentSettingsExternalVisualization | null) {
    let newVizState = getEmptyDatasetExtViz();

    if (settings != null && settings.link_to_dataset != 'N') {
        newVizState = {
            link_to_dataset: settings.link_to_dataset,
            filters: structuredClone(settings.filters),
            report_ext_vis_element_id: settings.report_ext_vis_element_id,
            report_ext_vis_applicable_scope: settings.report_ext_vis_applicable_scope,
            report_max_viz_number_to_display: settings.report_max_viz_number_to_display,
            report_ext_vis_images_in_email: settings.report_ext_vis_images_in_email,
            report_refer_from_email_to: settings.report_refer_from_email_to,
            dataset_block_uid: settings.dataset_block_uid ?? '',
        };
    }
    return newVizState;
}

export default function useReportContentContext(onFiltersChange: () => void) {
    const blocksContext = useContext<IBlocksContext | null>(BlocksContext);
    const defaultFilters = blocksContext?.filters ? JSON.stringify(blocksContext.filters) : null;
    const [filtersString, setFiltersString] = useState<string | null>(defaultFilters);
    const [extViz, setExtViz] = useState<ComponentSettingsDatasetExtViz>(getExtViz(null));

    useEffect(() => {
        if (blocksContext != null && JSON.stringify(blocksContext.filters) != JSON.stringify(filtersString)) {
            setFiltersString(JSON.stringify(blocksContext.filters));
        }
    }, [blocksContext?.filters]);

    useEffect(() => {
        if (!blocksContext) {
            return;
        }

        const newExtViz = getExtViz(blocksContext.extViz);
        if (JSON.stringify(newExtViz) != JSON.stringify(extViz)) {
            setExtViz(newExtViz);
        }
    }, [blocksContext?.extViz]);

    const [lastFiltersString, setLastFiltersString] = useState<string | null>(null);
    useEffect(() => {
        if (lastFiltersString != filtersString) {
            setLastFiltersString(filtersString);
            onFiltersChange();
        }
    }, [filtersString]);

    return { filtersString: filtersString, extViz: extViz };
}
