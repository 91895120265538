export interface QBQueryMetricData {
    field: string;
    operation: string;
    isValid: boolean;
}

export interface QBQueryMetric extends QBQueryMetricData {}

export class QBQueryMetric {
    constructor(expression: string) {
        this.field = expression;
        this.operation = '';
        this.isValid = false;

        if (typeof expression == 'string') {
            expression = expression.replace(new RegExp('(^\\s+)|(\\s+$)', 'g'), '');
            if (expression.length) {
                const expressionsRegExp = new RegExp('^(COUNT|AVG|MIN|MAX|SUM)\\((.+)\\)$', 'gi');
                const expressionMatches = expressionsRegExp.exec(expression);
                if (expressionMatches != null) {
                    this.field = expressionMatches[2];
                    this.operation = expressionMatches[1].toLowerCase();
                    this.isValid = this.operation == 'count' && expressionMatches[2] != '*' ? false : true;
                }
            }
        }
    }

    getExpression() {
        return this.isValid ? this.operation + '(' + this.field + ')' : this.field;
    }
}

export const getQBQueryMetricExpression = (metric: QBQueryMetricData) => {
    return metric.isValid ? metric.operation + '(' + metric.field + ')' : metric.field;
};
