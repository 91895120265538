import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterElementProps } from '../index';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import Moment from 'moment/moment';
import DatePicker from 'components/common/date-picker/DatePicker';

function DateFilter({ name, id, value, onChange, label, showTimeSelect, placeholder }: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);
    const dateFormat = showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD';
    const [startDate, setStartDate] = useState<Date | null>(null);

    useEffect(() => {
        if (startDate) {
            onChange(name, Moment(startDate).format(dateFormat));
        }
    }, [startDate]);

    const inputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <IconMi icon="calendar" fontSize="16" />
            </InputAdornment>
        ),
    };

    return (
        <Grid
            wrap="nowrap"
            item
            container
            xs={'auto'}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}
            key={`grid-filter-${id}-${name}`}
        >
            {label && <StaticAddon>{t(label)}</StaticAddon>}
            <Box sx={{ width: showTimeSelect ? '150px' : '114px' }}>
                <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    showTimeSelect={showTimeSelect}
                    dateFormat={dateFormat}
                    customInput={<TextField fullWidth InputProps={inputProps} />}
                    value={value}
                    data-test={id}
                    placeholderText={t(placeholder ?? '')}
                />
            </Box>
        </Grid>
    );
}

export default DateFilter;
