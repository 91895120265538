import { FormRendererAPIType } from 'components/common/form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { formAPI } from 'api/form';
import React, { useEffect } from 'react';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import FiltersForm from 'components/common/grid/cell/burst-filter-cell/FiltersForm';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';

export default function FiltersFormWrapper({
    elementId,
    segmentValueId,
    externalId,
    listId,
    setForm,
    itemId,
    hasFilters,
    multipleRefsLabel,
}: {
    elementId: number;
    segmentValueId: number;
    externalId: number;
    listId: number;
    setForm: (form: FormRendererAPIType) => void;
    itemId: number;
    hasFilters: boolean;
    multipleRefsLabel?: string;
}) {
    const queryClient = useQueryClient();

    let burstQueryKey = ['burstFilter', elementId, segmentValueId, externalId];
    const { data } = useQuery<any | void, Error>({
        queryKey: burstQueryKey,
        queryFn: () =>
            formAPI
                .load(
                    `/data/notification/distribution-list-item/${elementId}/${segmentValueId}/${externalId}/${listId}/form`,
                )
                .then((response: any) => response?.data?.data),
    });

    let tabsDataQueryKey = ['burstFilterTabs', elementId, segmentValueId, externalId, itemId];
    const { data: tabsData } = useQuery<Array<SiblingTabType> | void, Error>({
        queryKey: tabsDataQueryKey,
        queryFn: () =>
            multipleRefsLabel
                ? formAPI
                      .load(`/data/notification/distribution-list-item/${listId}/${itemId}`)
                      .then((response: any) => response?.data?.data)
                : [],
    });

    useEffect(() => {
        return () => {
            queryClient.removeQueries({ queryKey: [burstQueryKey, tabsDataQueryKey] });
        };
    }, []);

    if (!data || !tabsData) {
        return <LoadingPlaceholder />;
    }

    return (
        <FiltersForm
            tabsData={tabsData}
            setForm={setForm}
            filtersRequestData={data.filtersData}
            segmentData={data.segmentData}
            elementId={elementId}
            segmentValueId={segmentValueId}
            externalId={externalId}
            defaultData={data.defaults}
            formValues={data.values}
            itemId={itemId}
            multipleRefsLabel={multipleRefsLabel}
            hasFilters={hasFilters}
        />
    );
}
