export default {
    filtersWrapper: {
        flexWrap: 'wrap',
        ml: -3,
    },
    filterItem: {
        width: '50%',
        flexGrow: 1,
        pl: 3,
        pb: 2,
        flexWrap: 'nowrap',
        '&.checkbox-mod': {
            width: 'auto',
            flexGrow: 0,
            '.MuiFormControlLabel-root ': {
                mr: 0,
            },
        },
        '&.label-above-mod': {
            flexDirection: 'column',
            justifyContent: 'flex-end',

            '& .label': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
        },
    },
};
