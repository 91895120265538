import React from 'react';
import { alpha, Theme, Box, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

interface NetworkErrorProps {
    view?: 'grid';
}

export default function NetworkError(props: NetworkErrorProps) {
    const { t } = useBundleTranslation(['components/error/network_error']);
    const { view = 'default' } = props;
    return (
        <Stack
            sx={
                view == 'grid'
                    ? {}
                    : {
                          height: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                      }
            }
            direction={view == 'grid' ? 'row' : 'column'}
            spacing={1.5}
        >
            <Box sx={{ display: 'inline-flex' }}>
                <IconMi
                    icon={'wireless-error'}
                    sx={{ fontSize: '32px', color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4) }}
                />
            </Box>
            <Box>
                <Box>{t('network_error_title')}</Box>
                <Box>{t('network_error_sub_title')}</Box>
            </Box>
        </Stack>
    );
}
