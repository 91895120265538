import React, { useMemo, useRef } from 'react';
import { FilterType, FilterValue } from 'components/external-reference/Filter';
import { buildBookmarkName } from './Bookmark';
import { Button, Menu, MenuItem, Box, Chip, Tooltip } from '@mui/material';
import useBundleTranslation from 'i18n';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import IconMi from 'components/common/icon/IconMi';
import { ManagerContextType } from 'components/external-reference/bookmark/BookmarManager';

type BookmarkDataClassType = 'TabReference';

export interface BookmarkDataType {
    data: {
        referenceId: number;
        title: string;
    };
    dataClass: BookmarkDataClassType;
}

export interface BookmarkFilterType {
    filterId: number;
    values: Array<FilterValue>;
    selectedValues: Array<string | number>;
    filterInput: string;
}

export interface BookmarkType {
    bookmarkData: Array<BookmarkDataType>;
    displayOrder: number;
    id: number;
    segmentValueId: number;
    parentSegmentValueId: number;
    segmentId: number;
    name: string;
    filtersList: Array<BookmarkFilterType>;
    bookmarkType?: ManagerContextType;
}

function BookmarkListItem({ isApplied, title, tooltip }: { isApplied: boolean; title: string; tooltip: string }) {
    const style = {
        fontWeight: isApplied ? 'bold' : 'normal',
    };

    return (
        <Tooltip title={tooltip}>
            <span style={style}>{title}</span>
        </Tooltip>
    );
}

export default function BookmarkDropdown({
    segmentValueId,
    bookmarks,
    filters,
    siblingTabs,
    onUpdateClick,
    onSaveAsNewClick,
    onApplyBookmark,
    onShowManager,
    appliedBookmarkId,
    showManagerButton = true,
    fullScreenMode,
    fullscreenModePanelPos,
}: {
    segmentValueId: number;
    bookmarks: Array<BookmarkType>;
    filters: Array<FilterType>;
    siblingTabs: Array<SiblingTabType>;
    onUpdateClick: any;
    onSaveAsNewClick: any;
    onApplyBookmark: any;
    onShowManager: any;
    appliedBookmarkId: number;
    showManagerButton: boolean;
    fullScreenMode: boolean;
    fullscreenModePanelPos?: string;
}) {
    const { t } = useBundleTranslation(['components/external-reference/bookmark']);

    const handleUpdateClick = function () {
        onUpdateClick();
        handleClose();
    };

    const handleSaveAsNewClick = function () {
        onSaveAsNewClick();
        handleClose();
    };

    const handleShowManager = function () {
        onShowManager();
        handleClose();
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const containerRef = useRef();
    const needShowAbove = fullScreenMode && fullscreenModePanelPos === 'bottom';

    const defaultBookmarkId = useMemo(() => {
        let id = 0;
        bookmarks.forEach((bookmark) => {
            if (bookmark.segmentValueId == segmentValueId && id == 0) {
                id = bookmark.id;
            }
        });
        return id;
    }, [segmentValueId, bookmarks]);

    return (
        <Box data-test={'reference-bookmark-dropdown'} ref={containerRef}>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                color="primary"
                sx={{
                    borderBottomColor: open && !needShowAbove ? 'transparent !important' : undefined,
                    borderTopColor: open && needShowAbove ? 'transparent !important' : undefined,
                }}
                className={'min-width--icon'}
            >
                <IconMi icon="bookmark" fontSize="16" />
            </Button>
            <Menu
                container={containerRef.current}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={needShowAbove ? 'show-above' : undefined}
                transformOrigin={{
                    vertical: needShowAbove ? 'bottom' : 'top',
                    horizontal: 'left',
                }}
                anchorOrigin={{
                    vertical: !needShowAbove ? 'bottom' : 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '.MuiMenu-list': {
                        padding: 0,
                    },
                    '.MuiPaper-root': {
                        border: '1px solid rgba(102, 102, 102, 0.4)',
                        boxShadow: 'none',
                    },
                    '&:not(.show-above)': {
                        marginTop: '-1px',
                    },
                    '&.show-above': {
                        marginTop: '1px',
                    },
                    '&:not(.show-above) .MuiPaper-root': {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    },
                    '&.show-above .MuiPaper-root': {
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                    },
                }}
            >
                {bookmarks.length == 0 ? (
                    <MenuItem onClick={handleSaveAsNewClick} sx={{ color: 'primary.main' }}>
                        {t('save_bookmark')}
                    </MenuItem>
                ) : (
                    <span>
                        <MenuItem onClick={handleUpdateClick} sx={{ color: 'primary.main' }}>
                            {t('update_bookmark')}
                        </MenuItem>
                        <MenuItem onClick={handleSaveAsNewClick} sx={{ color: 'primary.main' }}>
                            {t('save_as_new')}
                        </MenuItem>
                    </span>
                )}

                {bookmarks.map((bookmark, index) => (
                    <MenuItem
                        onClick={() => onApplyBookmark(bookmark.id)}
                        sx={{ color: 'primary.main' }}
                        key={bookmark.id}
                    >
                        <BookmarkListItem
                            isApplied={appliedBookmarkId == bookmark.id}
                            title={buildBookmarkName(bookmark, filters, siblingTabs)}
                            tooltip={buildBookmarkName(bookmark, filters, siblingTabs, true)}
                        />
                        {bookmark.id == defaultBookmarkId && (
                            <Chip
                                sx={{ marginLeft: '12px', borderRadius: '6px' }}
                                label="default"
                                color="success"
                                variant="outlined"
                            />
                        )}
                    </MenuItem>
                ))}
                {showManagerButton && (
                    <MenuItem
                        onClick={handleShowManager}
                        sx={{ color: 'primary.main', borderTop: '1px solid rgba(204, 204, 204, 0.5)' }}
                    >
                        {t('manage_bookmarks')}
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
}
