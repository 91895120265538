import React from 'react';
import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiCheckboxConfig = {
    defaultProps: {
        disableRipple: true,
        size: 'medium',
        icon: React.createElement('span', { className: 'CustomIconCheckbox CustomIconCheckbox--notChecked' }, null),
        checkedIcon: React.createElement('span', { className: 'CustomIconCheckbox CustomIconCheckbox--checked' }, null),
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                padding: '6px 8px',
                '& .CustomIconCheckbox': {
                    display: 'inline-block',
                    width: '16px',
                    height: '16px',
                    borderRadius: theme.shape.borderRadius,
                },
                '&.Mui-disabled .CustomIconCheckbox': {
                    backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.16)),
                },
                '& .CustomIconCheckbox--notChecked': {
                    border: `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
                    backgroundColor: theme.palette.background.default,
                },
                '&:not(.Mui-disabled):hover .CustomIconCheckbox--notChecked, .MuiFormControlLabel-root:hover > &:not(.Mui-disabled) .CustomIconCheckbox--notChecked':
                    {
                        borderColor: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                        backgroundColor: useBlendColors(alpha(theme.palette.primary.main, 0.04)),
                    },
                '.MuiFormControlLabel-root:hover > &:not(.Mui-disabled) ~ .MuiFormControlLabel-label': {
                    color: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                },
                '&.Mui-disabled .CustomIconCheckbox--notChecked': {
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.16)),
                },
                '& .CustomIconCheckbox--checked': {
                    backgroundColor: theme.palette.primary.main,
                    backgroundImage: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDYwNTkgMTEuODE4MkwzLjM0MDU4IDguNjk4MjRDMy4xNTMxNCA4LjUxMDc5IDMuMTUzMTQgOC4yMDY4NyAzLjM0MDU4IDguMDE5NDFMNC4wMTkzOSA3LjM0MDU4QzQuMjA2ODMgNy4xNTMxMiA0LjUxMDc3IDcuMTUzMTIgNC42OTgyMiA3LjM0MDU4TDYuOCA5LjQ0MjM1TDExLjMwMTggNC45NDA1OEMxMS40ODkyIDQuNzUzMTQgMTEuNzkzMiA0Ljc1MzE0IDExLjk4MDYgNC45NDA1OEwxMi42NTk0IDUuNjE5NDFDMTIuODQ2OSA1LjgwNjg1IDEyLjg0NjkgNi4xMTA3NyAxMi42NTk0IDYuMjk4MjNMNy4xMzk0MSAxMS44MTgzQzYuOTUxOTUgMTIuMDA1NyA2LjY0ODAzIDEyLjAwNTcgNi40NjA1OSAxMS44MTgyVjExLjgxODJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K)`,
                },
                '&:not(.Mui-disabled):hover .CustomIconCheckbox--checked, .MuiFormControlLabel-root:hover > &:not(.Mui-disabled) .CustomIconCheckbox--checked':
                    {
                        backgroundColor: useBlendColors(alpha(theme.palette.primary.main, 0.8)),
                    },
                '&.Mui-disabled': {
                    pointerEvents: 'auto',
                },
                '&.Mui-disabled input': {
                    pointerEvents: 'none',
                },
                '&.Mui-disabled ~ .MuiFormControlLabel-label.Mui-disabled': {
                    color: useBlendColors(alpha(theme.palette.text.primary, 0.8)),
                },
            };
        },
    },
};
