import MITable from 'components/common/grid/MITable';
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { VirtualDatasetColumns } from '../index';
import useBundleTranslation from 'i18n';
import { GridData } from 'components/common/grid';
import Collapse from 'components/common/collapse/Collapse';

function DatasetTable({ data }: { data: VirtualDatasetColumns[] }) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [gridData, setGridData] = useState<GridData>([]);
    const gridColumns = ['name', 'type', 'topic', 'description'].map((columnKey) => {
        return {
            name: columnKey,
            title: columnKey !== 'topic' ? columnKey : '',
            resizable: false,
        };
    });

    const getTopicColumn = (column: VirtualDatasetColumns) => {
        if (column.topics?.length > 0 && Array.isArray(column.topics)) {
            const topic = column.topics[0];
            const tags = column.topics.map((topic) => topic.name).join(', ');

            return {
                component: 'text',
                config: {
                    text: tags,
                    icon: {
                        type: topic.use_icon || 'mi',
                        value:
                            topic.use_icon === 'fa'
                                ? topic.fa_icon
                                : topic.use_icon === 'uploaded'
                                  ? topic.icon
                                  : 'tags',
                    },
                },
            };
        } else {
            return '';
        }
    };

    const generateGridData = (): any[] => {
        return data?.map((column: VirtualDatasetColumns) => {
            return {
                name: column.name,
                type: column.type_title,
                topic: getTopicColumn(column),
                description: column.description,
            };
        });
    };

    useEffect(() => {
        setGridData(generateGridData());
    }, [data]);

    return (
        <Box sx={{ mt: 2, pl: 3, pr: 3 }}>
            <Collapse title={t('grid.columns', { total: data.length })}>
                <MITable
                    autoHeight
                    data={gridData}
                    columns={gridColumns}
                    massActionsConfig={{ enabled: false }}
                    gridName={'virtual-dataset-table'}
                />
            </Collapse>
        </Box>
    );
}

export default DatasetTable;
