import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default {
    leftPanelWrapper: {
        borderRight: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        width: '383px',
        flexShrink: 0,
        height: '672px',
    },
    leftPanelControlsWrapper: {
        borderBottom: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        flexShrink: 0,
        py: 1.5,
        px: 2,
    },
    leftPanelItemsWrapper: {
        flexGrow: 1,
        overflow: 'auto',
        '&.view-mode--tree-view': {
            '.section': {
                py: 0.5,
                px: 2,
            },
            '.section__header': {
                alignItems: 'flex-start',
                py: 0.75,
                userSelect: 'none',
                '&.selected': {
                    color: 'primary.main',
                },
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                },
            },
            '.section__icon-wrapper, .section__toggle': {
                flexShrink: 0,
                width: '16px',
                height: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,
                mt: '1px',
            },
            '.section__icon-wrapper': {},
            '.section__toggle': {
                order: -1,
            },
            '.section__label': {
                fontWeight: 600,
                wordWrap: 'break-word',
                overflow: 'hidden',
            },
            '.section__items': {
                overflow: 'hidden',
                '&.collapsed': {
                    height: 0,
                },
            },
            '.item-wrapper': {
                ml: '7px',
                borderLeft: '1px solid',
                borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                pl: 4,
            },
            '.item-holder': {
                py: '1px',
                '&:not(.selected)': {
                    cursor: 'pointer',
                },
                '&:hover .item-element': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                },
                '&.selected .item-element': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
                    color: (theme: Theme) => theme.palette.primary.main,
                    pointerEvents: 'none',
                },
            },
            '.item-element': {
                py: '5px',
                px: 1,
                borderRadius: '3px',
                wordWrap: 'break-word',
                userSelect: 'none',
                '.highlight': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.24),
                },
            },
        },
        '&.view-mode--list-view': {
            '.section': {
                py: 1.5,
                px: 2,
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.text.primary, 0.08)),
            },
            '.section__header': {
                alignItems: 'flex-start',
                color: 'primary.main',
                userSelect: 'none',
                '&.selected': {},
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                },
            },
            '.section__icon-wrapper, .section__toggle': {
                flexShrink: 0,
                width: '16px',
                height: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '1px',
            },
            '.section__icon-wrapper': {
                mr: 1,
            },
            '.section__toggle': {
                ml: 1,
            },
            '.section__label': {
                fontWeight: 600,
                flexGrow: 1,
                wordWrap: 'break-word',
                overflow: 'hidden',
            },
            '.section__items': {
                pl: 2.5,
                flexDirection: 'row',
                flexWrap: 'wrap',
                overflow: 'hidden',
                '&.collapsed': {
                    height: 0,
                },
            },
            '.item-wrapper': {
                ml: 0.5,
                mt: 0.5,
                overflow: 'hidden',
            },
            '.item-holder': {
                '&:not(.selected)': {
                    cursor: 'pointer',
                },
                '&:hover .item-element': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.04),
                },
                '&.selected .item-element': {
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                    color: 'text.opposite',
                    pointerEvents: 'none',
                },
            },
            '.item-element': {
                backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.24),
                border: '1px solid',
                borderColor: (theme: Theme) => useBlendColors(alpha(theme.palette.primary.main, 0.24)),
                color: 'primary.main',
                px: '7px',
                borderRadius: '3px',
                wordWrap: 'break-word',
                userSelect: 'none',

                '.highlight': {
                    backgroundColor: (theme: Theme) => useBlendColors(alpha(theme.palette.primary.main, 0.35)),
                },
            },
        },
    },
};
