import { FormGroup, FormLabel, Grid, Typography } from '@mui/material';
import { WrapFormElementProps } from './index';
import { getFromComponentPropsClass } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';

export default function FormWrapperRow({ elementProps, children, componentValues }: WrapFormElementProps) {
    let className = 'form-wrapper-row ' + getFromComponentPropsClass(elementProps, componentValues);

    const { t } = useBundleTranslation([elementProps.translationNS ?? 'main']);
    const label = elementProps.componentProps.translate
        ? t(elementProps.componentProps.label as string)
        : elementProps.componentProps.label;

    const spacingValue =
        typeof elementProps.componentProps.spacing !== 'undefined' ? elementProps.componentProps.spacing : 2;

    if (label.length) {
        return (
            <Grid className={className} item xs={elementProps.componentProps.xs}>
                <FormGroup>
                    <FormLabel className={elementProps.componentProps.labelClass}>{label}</FormLabel>
                    <Grid container item columns={12} xs={12} spacing={spacingValue}>
                        {children}
                    </Grid>
                </FormGroup>
            </Grid>
        );
    }

    return (
        <Grid
            className={className}
            container
            item
            columns={12}
            xs={elementProps.componentProps.xs}
            spacing={spacingValue}
        >
            {children}
        </Grid>
    );
}
