import { Grid, Stack, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FilterElementProps } from '../index';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import useBundleTranslation from 'i18n';

function TextFilter({ name, id, value, onChange, label }: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);

    const handleInputUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(name, event.target.value);
    };

    return (
        <Grid wrap="nowrap" item xs={'auto'} key={`grid-filter-${id}-${name}`}>
            <Stack direction={'row'}>
                {label && <StaticAddon>{t(label)}</StaticAddon>}
                <TextField
                    fullWidth
                    value={value}
                    onChange={handleInputUpdate}
                    data-test={id}
                    sx={{ width: '144px' }}
                />
            </Stack>
        </Grid>
    );
}

export default TextFilter;
