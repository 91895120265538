import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { formAPI } from 'api/form';
import StaticInfo from 'components/common/StaticInfo';
import { processSettingsUrl } from 'components/common/form/formTools';
import IntervalStaticInfo from 'components/common/IntervalStaticInfo';

const FormStaticInfo = ({ elementProps, props }: any) => {
    const componentProps = elementProps?.component?.props || props || {};
    const async = componentProps.async === 'Y';
    const refreshUrl = componentProps?.refreshUrl;
    const refreshInterval = componentProps?.refreshInterval;

    const pks = Object.keys(componentProps?.urlParams ?? {});
    const urlParams = componentProps.urlParams ?? useParams();
    const fetchUrl = processSettingsUrl(componentProps.fetchUrl, pks, urlParams);
    const isAllParamsExists = Object.values(urlParams).every((item) => item !== '');
    const gridUrl = componentProps.gridUrl ? processSettingsUrl(componentProps.gridUrl, pks, urlParams) : '';

    const {
        data: response,
        isLoading,
        refetch,
    } = useQuery<any, Error>({
        queryKey: [`fetchStaticInfData_${componentProps.label || ''}`],
        queryFn: () => formAPI.load(fetchUrl),
        enabled: false,
    });

    useEffect(() => {
        if (fetchUrl && isAllParamsExists) {
            refetch();
        }
    }, []);

    useEffect(() => {
        const subscription = elementProps?.form?.hookFormWatch((_: string, { name }: any) => {
            if (isAllParamsExists && name && componentProps?.dependencies?.includes(name) && fetchUrl) {
                refetch();
            }
        });
        return () => subscription?.unsubscribe();
    }, [elementProps?.form?.hookFormWatch, isAllParamsExists, fetchUrl]);

    const showAsyncAlert = useMemo(() => {
        if (!async) {
            return true;
        }

        return isLoading ? false : Number(response?.data?.[componentProps?.fieldToCompare]) > 0;
    }, [async, isLoading, componentProps, response]);

    if (refreshUrl) {
        return (
            <IntervalStaticInfo
                type={componentProps.type}
                icon={componentProps.icon}
                variant={componentProps.variant}
                align={elementProps?.align}
                refreshUrl={refreshUrl}
                refreshInterval={refreshInterval}
            />
        );
    }

    return (
        <StaticInfo
            icon={componentProps.icon}
            type={componentProps.type}
            variant={componentProps.variant}
            show={showAsyncAlert}
            translationNS={elementProps?.translationNS}
            align={elementProps?.align}
            link={{
                href: elementProps?.componentProps?.linkHref,
                label: elementProps?.componentProps?.linkLabel,
            }}
            translationParams={{
                ...(urlParams || {}),
                [componentProps?.fieldToCompare]: response?.data?.[componentProps?.fieldToCompare],
                ...(elementProps?.componentProps?.translationParams || {}),
            }}
            {...componentProps}
            label={elementProps?.componentProps?.label ?? componentProps?.label}
            gridUrl={gridUrl}
            planeText={elementProps?.componentProps?.planeText ?? componentProps?.planeText}
            useMarkdown={elementProps?.componentProps?.useMarkdown ?? componentProps?.useMarkdown}
        />
    );
};

export default FormStaticInfo;
