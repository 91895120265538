import { AssocArray } from 'tools/types';
import { useEffect, useState } from 'react';

export function useRequiredFields(matchedPatterns: Array<string>, lastValidationData: AssocArray<string>) {
    const subsMap: AssocArray<string> = {
        ':last_event_time': 'pattern_subst',
        ':segment': 'sv_subst',
    };

    const [requiredFields, setRequiredFields] = useState<Array<string>>([]);
    useEffect(() => {
        const list = matchedPatterns.filter((pattern) => {
            const key = subsMap?.[pattern];
            // if (pattern == ':segment' && lastValidationData?.segment_value_id) {
            //     return false;
            // }
            if (!Object.keys(lastValidationData).includes(key)) {
                return true;
            }
            return lastValidationData[key] == '' || (key == 'sv_subst' && lastValidationData[key] == '0');
        });

        if (JSON.stringify(requiredFields) != JSON.stringify(list)) {
            setRequiredFields(list);
        }
    }, [matchedPatterns, lastValidationData]);

    return requiredFields;
}
