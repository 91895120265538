import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsLineChart = ReportContentNS.ComponentSettingsLineChart;
import { TFunction } from 'i18next';
import validateHeight from 'components/report-content/utils/validateHeight';

export default function validateLineChart(newSettings: ComponentSettingsLineChart, t: TFunction) {
    let message = validateHeight(newSettings, t);

    if (-1 != newSettings.measureFields.findIndex((b) => b.reference_name == newSettings.x_axis.field)) {
        message = t('line_chart.validation.error_x_axis_field_can_not_be_used_in_measure');
    }

    if (
        'multiple' === newSettings.series &&
        -1 != newSettings.measureFields.findIndex((b) => b.reference_name == newSettings.group_field)
    ) {
        message = t('line_chart.validation.error_group_field_can_not_be_used_in_measure');
    }

    if ('multiple' === newSettings.series && newSettings.x_axis.field == newSettings.group_field) {
        message = t('line_chart.validation.error_x_axis_field_can_not_be_used_as_group');
    }

    if (
        newSettings.customYAxis == 'Y' &&
        newSettings.minY != null &&
        newSettings.maxY != null &&
        newSettings.minY >= newSettings.maxY
    ) {
        message = t('line_chart.validation.error_custom_chart_range_for_y_axis');
    }

    return message;
}
