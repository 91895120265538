import React, { useState } from 'react';
import { Params as UrlParams } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

import { FormPanelButtonProps } from 'components/common/form';
import useBundleTranslation from 'i18n';
import { processSettingsUrl } from 'components/common/form/formTools';
import IconMi from 'components/common/icon/IconMi';
import { openExternalView } from 'app/element';

export default function FormPanelExternalContentViewButton({ button, formActions }: FormPanelButtonProps<any>) {
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const navigate = useNavigate();
    const urlParams: UrlParams = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const afterFormSubmit = () => {
        const reportDisplay = formActions.hookFormGetValues('external_report_display');
        if (reportDisplay == 'iframe') {
            const redirectTo = processSettingsUrl('/ext-content/:elementId', Object.keys(urlParams), urlParams);
            setTimeout(() => navigate(redirectTo), 100);
        } else {
            setIsLoading(true);

            openExternalView(Number(urlParams?.elementId ?? 0), 0).finally(() => {
                setIsLoading(false);
            });
        }
    };

    const handleClick = async () => {
        if (isLoading) {
            return;
        }
        if (formActions.hookFormState.isDirty) {
            formActions.formSave().then((response) => {
                if (response?.data?.status != 'OK') {
                    return;
                }
                afterFormSubmit();
            });
        } else {
            afterFormSubmit();
        }
    };

    return (
        <>
            <Button
                sx={{ mr: 1 }}
                onClick={handleClick}
                variant={button.props.variant}
                type={'button'}
                startIcon={!isLoading && <IconMi icon={'view'} />}
            >
                {isLoading && <CircularProgress sx={{ marginRight: '8px' }} color="inherit" size={12} />}
                {t(button.label)}
            </Button>
        </>
    );
}
