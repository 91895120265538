import { AsyncGridProps, ButtonConfig, ColumnsWidth, ColumnType, getColumnLabel, VisibleColumns } from './index';
import { useFetchGridData } from './hooks/useFetchGridData';
import { useGridPopup } from './hooks/useGridPopup';
import useBundleTranslation from 'i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGridMassActions } from './hooks/useGridMassActions';
import EmptyGrid from './EmptyGrid';
import { alpha, Container, Grid, TablePagination, Typography } from '@mui/material';
import TableFilters from './TableFilters';
import GridLoading from './GridLoading';
import MITable from './MITable';
import NoSearchResult from './NoSearchResult';
import TableButtons from './TableButtons';
import GridPopup from './GridPopup';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useNavigate } from 'react-router-dom';
import { simulateMouseClick } from 'tools/tools';
import { useInView } from 'react-intersection-observer';
import { GridViewComponents } from 'grid-views';
import { getAverageColumnDataLength, getColumnMultiplier } from './helper/columnWidth';
import { useSelector } from 'react-redux';
import NetworkError from 'components/common/error/NetworkError';

const SMART_CONFIG_VERSION = 7;

export default function AsyncGridComponent(props: AsyncGridProps) {
    const {
        error,
        resetFiltersButton,
        filtersList,
        searchField,
        gridData,
        isLoading,
        isError,
        columns,
        filtersValue,
        order,
        orderBy,
        legendConfig,
        pagerOptions,
        totalCount,
        pageIndex,
        gridTitle,
        gridDescription,
        dragAndDrop,
        massActions,
        buttons,
        controllerUrl,
        emptyTitle,
        emptyIcon,
        hideEmpty,
        resetColumns,
        switchColumns,
        widthToggle,
        infoButton,
        gridName,
        visibleFilters,
        multilines,
        autoRowHeight,
        handleDropRow,
        setPageIndex,
        onSortColumn,
        handleFilterValueChange,
        resetFilters,
        reloadGridData,
        saveColumns,
        gridParams,
        noSearchResult,
        rowExtraInfoConfig,
        changeViewConfig,
        gridReorderLoading,
        customLegend,
        dataLoadingDone,
    } = useFetchGridData(props.uid, props.settingsUrl, props.relatedFields);

    const {
        popupUrl,
        setPopupUrl,
        popupConfig,
        setPopupConfig,
        popupType,
        setPopupType,
        popupMode,
        setPopupMode,
        popupRedirectAfter,
        setPopupRedirectAfter,
        formPostData,
        setFormPostData,
        popupGetMethod,
        setPopupGetMethod,
        popupComponent,
        setPopupComponent,
        handleHidePopup,
        handleConfirmPopup,
        setPopupComponentProps,
        popupComponentProps,
    } = useGridPopup(props.uid);

    const navigate = useNavigate();

    const gridButtons: Array<ButtonConfig> = props.hideButtons ? [] : buttons;

    const t = undefined !== props.t ? props.t : useBundleTranslation(['components/common/grid/grid'])['t'];

    const boxTableRef = useRef<HTMLDivElement | null>(null);

    const [gridWidth, setGridWidth] = useState(0);
    const [columnsWidth, setColumnsWidth] = useState<ColumnsWidth>({});
    const [columnsWidthPixels, setColumnsWidthPixels] = useState<ColumnsWidth>({});
    const [readyToDraw, setReadyToDraw] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({});
    const [visibilityChanged, setVisibilityChanged] = useState(false);
    const [fullwidthMode, setFullwidthMode] = useState('fixed');
    const [gridDataChanged, setGridDataChanged] = useState(false);
    const [gridParamsApplied, setGridParamsApplied] = useState(false);
    const [showEditSelector, setShowEditSelector] = useState('');
    const [isStickyGridButtons, setIsStickyGridButtons] = useState(false);
    const [gridView, setGridView] = useState('default');
    const [columnsMultiplier, setColumnsMultiplier] = useState(0);
    const [gridWidthApplied, setGridWidthApplied] = useState(false);

    const conciergeWidgetWidth = useSelector((state: any) => state.concierge.width);
    const conciergePanelVisible = useSelector((state: any) => state.concierge.visible);

    const handleColumnSwitch = (columnName: string, visible: boolean) => {
        setVisibilityChanged(true);
        setVisibleColumns((prevState) => {
            const newState = { ...prevState };
            newState[columnName].visible = visible;

            return newState;
        });
    };

    const filtered = useMemo(() => {
        let result = false;
        const filters = Object.values(filtersValue);
        filters.forEach((filter) => {
            if (filter !== '') {
                result = true;
            }
        });

        return result;
    }, [filtersValue]);

    const emptyData = gridData.length === 0 && !filtered;
    const emptyPageGridData = !props.formGrid && emptyData && !isLoading;
    const massActionsProps = useGridMassActions(gridData, massActions);
    const isDraggable = dragAndDrop?.enabled ?? false;
    const showCheckboxColumn = massActions.enabled && massActionsProps !== undefined;

    useEffect(() => {
        if (columnsMultiplier > 0) {
            applyColumnWidth();
        }
    }, [columnsWidth, columnsMultiplier]);

    const applyColumnWidth = () => {
        const newColumnsWidthPx: ColumnsWidth = {};

        if (columnsMultiplier > 0) {
            columns.forEach((column: ColumnType) => {
                newColumnsWidthPx[column.name] = columnsMultiplier * columnsWidth[column.name];

                if (column.fixed || column.width) {
                    newColumnsWidthPx[column.name] = column.width ? Number(column.width) : 50;
                }
            });
            setColumnsWidthPixels(newColumnsWidthPx);
        }
    };

    const reCalcColumnsWidth = (newGridWidth: number = gridWidth, visColumns: VisibleColumns = visibleColumns) => {
        if (newGridWidth > 0) {
            const newColumnsWidth: ColumnsWidth = {};

            columns.forEach((column: ColumnType) => {
                if (!column.hidden && visColumns[column.name] && visColumns[column.name].visible) {
                    const title = getColumnLabel(column, t);
                    newColumnsWidth[column.name] = getAverageColumnDataLength(column, gridData, title);
                }
            });
            setColumnsWidth(newColumnsWidth);
            setColumnsMultiplier(
                getColumnMultiplier(
                    newColumnsWidth,
                    newGridWidth,
                    visColumns,
                    isDraggable,
                    showCheckboxColumn,
                    columns,
                ),
            );
            saveColumns(newColumnsWidth, visColumns, fullwidthMode, gridView, SMART_CONFIG_VERSION);
        }
    };

    const onContainerResize = () => {
        if (boxTableRef && boxTableRef.current && boxTableRef.current.offsetWidth > 0) {
            setGridWidth(boxTableRef.current.offsetWidth);
            setGridWidthApplied(true);
        }
    };

    const resizeObserver = new ResizeObserver(onContainerResize);

    const [refFooter, inView, entry] = useInView({
        /* Optional options */
        root: document.querySelector('#page-scroll-grid-sticky'),
        threshold: 0.1,
        initialInView: true,
    });

    useEffect(() => {
        setIsStickyGridButtons(
            !!(!inView && entry && entry.rootBounds && entry.rootBounds.top < entry?.boundingClientRect?.top),
        );
    }, [inView]);

    useEffect(() => {
        if (readyToDraw && boxTableRef && boxTableRef.current) {
            resizeObserver.observe(boxTableRef.current);
        }
    }, [readyToDraw]);

    useEffect(() => {
        if (!isLoading && columns.length > 0 && props.formGrid && props.onDataStoreChange) {
            if (!filtered || gridDataChanged) {
                props.onDataStoreChange(gridData);
                setGridDataChanged(false);
            }

            if (gridData.length === 0) {
                setReadyToDraw(true);
            }
        }
    }, [gridData, isLoading]);

    useEffect(() => {
        if (visibilityChanged) {
            reCalcColumnsWidth();
            setVisibilityChanged(false);
        }
    }, [visibilityChanged]);

    const onFullwidthChange = (mode: string) => {
        setFullwidthMode(mode);
        if (saveColumns) {
            saveColumns(columnsWidth, visibleColumns, mode, gridView, SMART_CONFIG_VERSION);
        }
    };

    useEffect(() => {
        if (!isLoading && columns.length > 0 && !gridParamsApplied) {
            const visColumns: VisibleColumns = {};
            columns.forEach((column) => {
                if (column.hidden) {
                    return;
                }

                const label = getColumnLabel(column, t);

                const isColumnVisible =
                    gridParams && gridParams.columns.hasOwnProperty(column.name)
                        ? !Boolean(gridParams.columns[column.name].hidden)
                        : (column.visible ?? true);

                visColumns[column.name] = {
                    label: label > '' ? label : column.name,
                    hidable: column.hidable ?? true,
                    visible: isColumnVisible,
                };
            });

            setVisibleColumns(visColumns);

            if (gridWidth > 0) {
                if (
                    gridParams &&
                    !gridParamsApplied &&
                    gridParams.version &&
                    gridParams.version == SMART_CONFIG_VERSION
                ) {
                    const gridParamsWidth: ColumnsWidth = {};
                    columns.forEach((column) => {
                        if (gridParams.columns.hasOwnProperty(column.name)) {
                            gridParamsWidth[column.name] = Number(gridParams.columns[column.name].width) ?? 5;
                        } else {
                            gridParamsWidth[column.name] = 5;
                        }
                    });
                    setColumnsWidth(gridParamsWidth);

                    setGridView(gridParams.gridView ?? 'default');

                    if (widthToggle && gridParams.fullwidthMode > '' && gridParams.fullwidthMode != fullwidthMode) {
                        setFullwidthMode(gridParams.fullwidthMode);
                        setGridWidthApplied(false);
                    }
                }

                setGridParamsApplied(true);
            }
            setReadyToDraw(true);
        }
    }, [gridWidth, isLoading, gridParams, columns]);

    useEffect(() => {
        if (
            gridWidth > 0 &&
            gridData.length > 0 &&
            (!gridParams || !gridParams.version || gridParams.version != SMART_CONFIG_VERSION)
        ) {
            reCalcColumnsWidth(gridWidth, visibleColumns);
        }
    }, [gridData, gridParams, gridWidth, visibleColumns]);

    useEffect(() => {
        if (gridParamsApplied && gridWidthApplied) {
            setColumnsMultiplier(
                getColumnMultiplier(columnsWidth, gridWidth, visibleColumns, isDraggable, showCheckboxColumn, columns),
            );
        }
    }, [gridParamsApplied, gridWidth, gridWidthApplied]);

    const onColumnResize = useCallback(
        (columnWidth?: number, dataKey?: string) => {
            if (dataKey && columnWidth) {
                const newColumnsWidth = { ...columnsWidth };

                if (columnsMultiplier > 0) {
                    newColumnsWidth[dataKey] = Math.round(columnWidth / columnsMultiplier);
                }

                setColumnsWidth(newColumnsWidth);
                if (saveColumns) {
                    saveColumns(newColumnsWidth, visibleColumns, fullwidthMode, gridView, SMART_CONFIG_VERSION);
                }
            }
        },
        [columnsWidth, columnsWidthPixels, saveColumns, visibleColumns, fullwidthMode, gridView, columnsMultiplier],
    );

    useEffect(() => {
        if (!isLoading && showEditSelector > '') {
            setTimeout(() => {
                const element = document.querySelector(showEditSelector);
                setShowEditSelector('');
                simulateMouseClick(element);
            }, 200);
        }
    }, [isLoading, showEditSelector]);

    const resetGridSettings = useCallback(() => {
        reCalcColumnsWidth(gridWidth);
    }, [saveColumns, visibleColumns, fullwidthMode, reloadGridData, gridView]);

    const afterDataSave = (response: any) => {
        if (response.data && response.data.redirectUrl > '' && response.data.forceRedirect === true) {
            window.location.href = response.data.redirectUrl;
        }

        if (response.data.status === 'WARNING' && response.data.message) {
            alert(response.data.message);
        }

        if (popupRedirectAfter) {
            const redirectUrl =
                response.data && response.data.redirectUrl ? response.data.redirectUrl : (response.redirectUrl ?? '');

            if (redirectUrl > '') {
                if (response.data.download || response.download) {
                    window.location.href = response.data.redirectUrl;
                    return;
                }

                setTimeout(() => navigate(redirectUrl), 300);
            }
        } else {
            if (setGridDataChanged) {
                setGridDataChanged(true);
            }
            if (reloadGridData) {
                if (response.data.showEditPopup === true) {
                    reloadGridData().then(() => {
                        const itemSelector = `[data-test='grid_${gridName}_edit_column_${response.data.id}_cell'] button`;
                        setShowEditSelector(itemSelector);
                    });
                } else {
                    reloadGridData();
                }
            }
            if (massActionsProps.setCheckedKeys) {
                massActionsProps.setCheckedKeys([]);
            }
        }
    };

    useEffect(() => {
        if (props.reloadTrigger > 0) {
            reloadGridData();
        }
    }, [props?.reloadTrigger]);

    const onChangeView = useCallback(
        (value: string) => {
            setGridView(value > '' ? value : 'default');
            if (saveColumns) {
                saveColumns(columnsWidth, visibleColumns, fullwidthMode, value, SMART_CONFIG_VERSION);
            }
        },
        [saveColumns, columnsWidth, visibleColumns, fullwidthMode],
    );

    const gridPopup = (
        <GridPopup
            uid={'grid_popup_' + props.uid}
            popupUrl={popupUrl}
            handleHidePopup={handleHidePopup}
            handleConfirmPopup={handleConfirmPopup}
            popupConfig={popupConfig}
            popupType={popupType}
            popupMode={popupMode}
            reloadGridData={reloadGridData}
            formPostData={formPostData}
            getMethod={popupGetMethod}
            component={popupComponent}
            componentProps={popupComponentProps}
            setCheckedKeys={massActionsProps.setCheckedKeys}
            gridData={gridData}
            afterSave={afterDataSave}
        />
    );

    if (emptyPageGridData) {
        return (
            <EmptyGrid
                emptyTitle={emptyTitle}
                emptyIcon={emptyIcon}
                createButtons={gridButtons ? gridButtons.filter((config) => config.type === 'create') : []}
                controllerUrl={controllerUrl}
                t={t}
                gridName={gridName}
                height={props.height}
                gridPopup={gridPopup}
                setPopupUrl={setPopupUrl}
                setPopupConfig={setPopupConfig}
                setPopupType={setPopupType}
                setPopupMode={setPopupMode}
                setPopupRedirectAfter={setPopupRedirectAfter}
                setFormPostData={setFormPostData}
                setPopupGetMethod={setPopupGetMethod}
                setPopupComponent={setPopupComponent}
                setPopupComponentProps={setPopupComponentProps}
                form={props.form}
            />
        );
    }

    const gridContainerXs = !(fullwidthMode === 'full' && widthToggle) ? 'lg' : false;

    const gridBodyComponentProps = {
        data: gridData,
        columns: columns,
        isLoading: isLoading,
        onSortColumn: onSortColumn,
        order: order,
        orderBy: orderBy,
        legendConfig: legendConfig,
        dragAndDrop: dragAndDrop,
        handleDropRow: handleDropRow,
        massActionsConfig: massActions,
        reloadGridData: reloadGridData,
        onColumnResize: onColumnResize,
        massActionsProps: massActionsProps,
        setPopupUrl: setPopupUrl,
        setPopupConfig: setPopupConfig,
        autoHeight: true,
        height: props.height,
        setPopupType: setPopupType,
        setPopupMode: setPopupMode,
        setPopupComponent: setPopupComponent,
        setPopupComponentProps: setPopupComponentProps,
        t: t,
        multilines: multilines,
        columnsWidth: columnsWidthPixels,
        setShowLoader: setShowLoader,
        gridName: gridName,
        visibleColumns: visibleColumns,
        rowExtraInfoConfig: rowExtraInfoConfig,
        gridReorderLoading,
        form: props.form,
        setGridDataChanged: setGridDataChanged,
        uid: props.uid,
        filtersValue: filtersValue,
        customLegend: customLegend,
        autoRowHeight: autoRowHeight,
    };

    const bodyComponent = GridViewComponents[gridView] ?? MITable;
    const gridBodyComponent = React.createElement(bodyComponent, gridBodyComponentProps);
    const isActiveStikyFooterBtn = gridButtons && gridButtons.length && isStickyGridButtons;
    return (
        <Container className={'mi-container-grid'} maxWidth={gridContainerXs} component="main" disableGutters={true}>
            <Grid item container direction="column" data-test={`grid_${props.uid}`} ref={boxTableRef}>
                <Grid item data-test={`grid_${props.uid}_filters_container`} xs={12}>
                    {!(emptyData && true === hideEmpty) && (
                        <TableFilters
                            uid={props.uid}
                            isEmptyState={emptyData}
                            filtersList={filtersList}
                            searchField={searchField}
                            filterValues={filtersValue}
                            setFiltersValue={handleFilterValueChange}
                            resetFilters={resetFilters}
                            resetFiltersButton={resetFiltersButton}
                            resetGridSettings={resetGridSettings}
                            resetColumns={resetColumns}
                            switchColumns={switchColumns}
                            gridTitle={gridTitle.needTranslation === false ? gridTitle.text : t(gridTitle.text)}
                            gridDescription={props.gridDescription ?? gridDescription}
                            gridTranslate={t}
                            infoButton={infoButton}
                            gridName={gridName}
                            initVisibleFilters={visibleFilters}
                            visibleColumns={visibleColumns}
                            handleColumnSwitch={handleColumnSwitch}
                            fullwidthMode={fullwidthMode}
                            onFullwidthChange={onFullwidthChange}
                            widthToggle={widthToggle}
                            setPageIndex={setPageIndex}
                            removeHeaderIndent={props.removeHeaderIndent}
                            isLoading={isLoading || !readyToDraw}
                            onChangeView={onChangeView}
                            changeViewConfig={changeViewConfig}
                            gridView={gridView}
                        />
                    )}
                </Grid>
                {isLoading || !readyToDraw || !dataLoadingDone ? (
                    <Grid item container justifyContent="center" data-test={`grid_${props.uid}_loading`}>
                        <GridLoading />
                    </Grid>
                ) : (
                    <Grid
                        item
                        data-test={`grid_${props.uid}_container`}
                        className={`grid-container ${
                            gridButtons && gridButtons.length > 0 && isStickyGridButtons
                                ? 'grid-container--sticky-mod'
                                : ''
                        }`}
                    >
                        {isError ? (
                            <>
                                {error!.message === 'Network Error' ? (
                                    <NetworkError view={'grid'} />
                                ) : (
                                    <span>Error: {error!.message}</span>
                                )}
                            </>
                        ) : gridData.length > 0 ? (
                            gridBodyComponent
                        ) : null}
                        {gridData.length === 0 && filtered && (
                            <NoSearchResult
                                emptyText={noSearchResult?.emptyText ? t(noSearchResult?.emptyText) : ''}
                                icon={noSearchResult?.icon}
                            />
                        )}
                        <Grid
                            item
                            container
                            className={`grid-footer ${
                                gridButtons && gridButtons.length > 0 ? 'grid-footer--buttons-exist' : ''
                            } ${isStickyGridButtons ? 'grid-footer--sticky-mod' : ''} ${
                                fullwidthMode === 'full' ? 'grid--full-mod' : ''
                            }`}
                            ref={refFooter}
                            sx={{
                                mt: emptyData && true === hideEmpty ? undefined : '6px',
                            }}
                        >
                            <Grid
                                item
                                container
                                className={`grid-footer-content-wrap`}
                                justifyContent="center"
                                sx={{
                                    right:
                                        conciergePanelVisible && isActiveStikyFooterBtn
                                            ? `${conciergeWidgetWidth}px`
                                            : 0,
                                    width: conciergePanelVisible && isActiveStikyFooterBtn ? 'auto' : '100%',
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={'grid-footer-content'}
                                >
                                    <Grid item>
                                        <Grid container direction="row" spacing={2} alignItems="center">
                                            {gridButtons && gridButtons.length > 0 && (
                                                <TableButtons
                                                    uid={props.uid}
                                                    controllerUrl={controllerUrl}
                                                    gridButtons={gridButtons}
                                                    massActionsProps={massActionsProps}
                                                    reloadGridData={reloadGridData}
                                                    setPopupUrl={setPopupUrl}
                                                    setPopupConfig={setPopupConfig}
                                                    setPopupType={setPopupType}
                                                    setPopupMode={setPopupMode}
                                                    setPopupRedirectAfter={setPopupRedirectAfter}
                                                    setFormPostData={setFormPostData}
                                                    setPopupGetMethod={setPopupGetMethod}
                                                    setPopupComponent={setPopupComponent}
                                                    setPopupComponentProps={setPopupComponentProps}
                                                    gridData={gridData}
                                                    massActionsConfig={massActions}
                                                    t={t}
                                                    setShowLoader={setShowLoader}
                                                    filtersValue={filtersValue}
                                                    form={props.form}
                                                />
                                            )}
                                            {gridData.length === 0 &&
                                            props.formGrid &&
                                            !filtered &&
                                            false === hideEmpty ? (
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {emptyTitle.needTranslation === false
                                                            ? emptyTitle.text
                                                            : t(emptyTitle.text)}
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    {pagerOptions && pagerOptions.visible && gridData.length > 0 && totalCount > 20 ? (
                                        <TablePagination
                                            data-test={`grid_${props.uid}_pagination`}
                                            rowsPerPage={pagerOptions.pageSize}
                                            rowsPerPageOptions={[pagerOptions.pageSize]}
                                            component="div"
                                            count={totalCount}
                                            page={pageIndex}
                                            showFirstButton
                                            showLastButton
                                            sx={{
                                                '.MuiToolbar-root .MuiTablePagination-actions .MuiIconButton-root': {
                                                    padding: '3px 10px',
                                                },
                                            }}
                                            onPageChange={(event, page) => setPageIndex(page)}
                                            labelDisplayedRows={({ from, to, count }) =>
                                                `${from}-${to} ${t('pagination_of')} ${count}`
                                            }
                                        />
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {gridPopup}
                {showLoader && (
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                            color: (theme) => alpha(theme.palette.text.primary, 0.5),
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                    />
                )}
            </Grid>
        </Container>
    );
}
