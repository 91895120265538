import React, { useEffect, useState } from 'react';
import { GridData, RowExtraInfoConfig } from '../index';
import { Box, Stack, Tooltip } from '@mui/material';

export const useGridExtraInfo = (gridData: GridData, rowExtraInfoConfig?: RowExtraInfoConfig) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    if (!rowExtraInfoConfig) {
        return {};
    }

    useEffect(() => {
        const itemKeys = gridData
            .filter((row: any) => {
                return row[rowExtraInfoConfig.messageColumn] > '';
            })
            .map((row: any) => row[rowExtraInfoConfig.idColumn]);

        setExpandedRowKeys(itemKeys);
    }, [gridData]);

    const renderRowExpanded = (rowData: any) => {
        const initErrorMessage = rowData[rowExtraInfoConfig.messageColumn];

        const brPosition = initErrorMessage.indexOf('<br');
        const cutIndex = brPosition > 0 && brPosition < 100 ? brPosition : 100;
        const showMore = initErrorMessage.length > cutIndex;

        const previewText = showMore ? initErrorMessage.substring(0, cutIndex) + '...' : initErrorMessage;

        return (
            <Stack
                direction={'row'}
                sx={{ height: '100%', padding: '4px' }}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
            >
                <Box
                    dangerouslySetInnerHTML={{ __html: previewText }}
                    sx={{ textOverflow: 'ellipsis', maxHeight: '16px' }}
                />
                {showMore && (
                    <Tooltip
                        title={
                            <Box
                                style={{ color: 'error.main' }}
                                dangerouslySetInnerHTML={{ __html: initErrorMessage }}
                            />
                        }
                        placement={'right-start'}
                        arrow
                    >
                        <Box sx={{ margin: '4px', maxHeight: '32px', color: 'primary.main' }}>Show more</Box>
                    </Tooltip>
                )}
            </Stack>
        );
    };

    const extraInfoConfig = {
        rowExpandedHeight: 32,
        expandedRowKeys: expandedRowKeys,
        renderRowExpanded: renderRowExpanded,
        rowKey: rowExtraInfoConfig.idColumn,
    };

    return { extraInfoConfig };
};
