import { alpha, Box, Stack, Theme, Tooltip, Typography } from '@mui/material';
import Switch from '@mui/material/Switch/Switch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps } from 'components/common/form/layout/control';
import { monitorAPI } from 'api/api';
import IconMi from 'components/common/icon/IconMi';
import { GetNotificationStatus, myNotificationAPI } from 'api/my-notification';
import useBundleTranslation from 'i18n';
import { useLocation } from 'react-router-dom';
import { lighten } from '@mui/system/colorManipulator';
import { useTheme } from '@mui/material/styles';

export default function MyNotificationInfo() {
    const { t } = useBundleTranslation(['app/notification/my-notification']);
    const location = useLocation();
    const appTheme = useTheme();

    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [initialState, setInitialState] = useState<boolean>(true);
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [systemDate, setSystemDate] = useState('');
    const [systemTime, setSystemTime] = useState('');
    const [myNotificationStatus, setMyNotificationStatus] = useState<GetNotificationStatus>({
        emailNotificationStatus: 'N',
        emailsPopupHintData: {
            default: null,
            digest: null,
            notification: null,
            expired: null,
            immediate: null,
            immediate_sms: null,
        },
    });

    const isReceivingPage =
        location.pathname == '/notification/receiving' || location.pathname == '/notification/receiving/';

    const gridOverrideCss = `
    .my-notification-off [data-test="grid_notificationSubscriptionGrid"] .Mui-checked+.MuiSwitch-track {
        background-color: ${alpha(appTheme.palette.text.primary, 0.64)};
        border-color: ${alpha(appTheme.palette.text.primary, 0.64)};
    }
    `;

    const onNotificationChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        myNotificationAPI.changeStatus(checked ? 'Y' : 'N').then((response) => {
            if (response.status === 'OK') {
                setInitialState(false);
                setNotificationStatus(checked);
            }
        });
    };

    const systemDateTimeTick = () => {
        monitorAPI.loadSystemDateTimeData().then((response) => {
            if (response.data.status === 'OK') {
                setSystemDate(response.data.data.systemDate);
                setSystemTime(response.data.data.systemTime);
            }
        });
    };

    useEffect(() => {
        const newTimerId = setInterval(() => systemDateTimeTick(), 30000);
        systemDateTimeTick();
        setTimerId(newTimerId);

        myNotificationAPI.getStatusInfo().then((response) => {
            setMyNotificationStatus(response.data);
            setNotificationStatus(response.data.emailNotificationStatus === 'Y');
        });

        return () => {
            document.body.classList.remove('my-notification-off');
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, []);

    useEffect(() => {
        if (notificationStatus) {
            document.body.classList.remove('my-notification-off');
        } else {
            document.body.classList.add('my-notification-off');
        }
    }, [notificationStatus]);

    const emailsPopupHint = () => {
        const items: string[] = [];
        const hintData = myNotificationStatus.emailsPopupHintData;

        if (hintData.digest != null) {
            items.push(t('init_new_msg_alert_digest_sent', { email: hintData.digest }));
        }

        if (hintData.immediate != null) {
            if (hintData.immediate_sms != null) {
                items.push(
                    t('init_new_msg_imdt_alert_sent_sms', { email: hintData.immediate, sms: hintData.immediate_sms }),
                );
            } else {
                items.push(t('init_new_msg_imdt_alert_sent', { email: hintData.immediate }));
            }
        } else {
            if (hintData.immediate_sms != null) {
                items.push(t('init_new_msg_imdt_alert_sms', { sms: hintData.immediate_sms }));
            }
        }

        if (hintData.expired != null) {
            items.push(t('init_new_msg_expire_sent', { email: hintData.expired }));
        }

        if (items.length === 0) {
            items.push(t('init_new_msg_all_email_sent', { email: hintData.notification }));
        } else {
            items.push(t('init_new_msg_all_other_email_sent', { email: hintData.notification }));
        }

        return (
            <ul>
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    };

    return (
        <Box
            sx={{
                backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.04),
                margin: '0 -16px',
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                width: '100%',
                height: '28px',
                '&:hover .anim-pulsing': {
                    animation: 'none',
                },
            }}
        >
            <Stack
                direction="row"
                alignItems={'center'}
                justifyContent="center"
                spacing={0.5}
                className={!notificationStatus && (!initialState || isReceivingPage) ? 'anim-pulsing' : ''}
            >
                <Switch
                    data-test={`my-notification-info-control`}
                    onChange={onNotificationChange}
                    checked={notificationStatus}
                    color={notificationStatus ? 'success' : 'error'}
                    sx={{
                        '.MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled) + .MuiSwitch-track': {
                            backgroundColor: (theme) => theme.palette.error.main,
                            borderColor: (theme) => theme.palette.error.main,
                            opacity: 1,
                        },
                        '.MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled):hover + .MuiSwitch-track': {
                            backgroundColor: (theme) => lighten(theme.palette.error.main, 0.24),
                            borderColor: (theme) => lighten(theme.palette.error.main, 0.24),
                        },
                        '.MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled):hover > .MuiSwitch-thumb, .MuiSwitch-thumb':
                            {
                                backgroundColor: (theme) => theme.palette.background.default,
                            },
                    }}
                />
                <Typography
                    sx={{
                        color: notificationStatus ? 'success.main' : 'error.main',
                    }}
                >{`${t('notification')} ${notificationStatus ? t('on') : t('off')}`}</Typography>
                <Tooltip title={emailsPopupHint()} placement={'bottom-start'}>
                    <span dangerouslySetInnerHTML={{ __html: t('for_emails') }} />
                </Tooltip>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {`${systemDate} ${t('system')}`}
                    <IconMi icon={'clock'} fontSize={'16'} sx={{ mx: 1 }} />
                    {`${systemTime}`}
                </Box>
            </Stack>
            {isReceivingPage && <style>{gridOverrideCss}</style>}
        </Box>
    );
}

export class MyNotificationInfoBuilder extends FormComponentBuilder {
    prepareProps(): FormControlProps {
        return {
            ...this.controlProps,
        };
    }
}
