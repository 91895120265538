export const MuiInputLabelConfig = {
    defaultProps: {
        shrink: true,
    },
    styleOverrides: {
        standard: ({ theme }: { theme: any }) => {
            return {
                position: 'static',
                maxWidth: 'none',
                transform: 'none',
                lineHeight: theme.size.lineHeight,
                marginBottom: theme.spacing(0.5),
                color: theme.palette.text.primary,
                '&.Mui-disabled': {
                    color: theme.palette.text.primary,
                },
            };
        },
    },
};
