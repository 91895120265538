import ReactSelect from 'components/common/react-select/ReactSelect';
import React from 'react';
import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import { Box } from '@mui/material';

export default function FilterControlSelect({
    filter,
    onChange,
}: {
    filter: IFilterColumn;
    onChange: (newValue: any) => void;
}) {
    // Prepare Data object for Select2

    if (!Array.isArray(filter.data)) {
        return null;
    }

    const data = filter.data.map((el: any) => ({
        value: el[filter.uid],
        label:
            'text' != filter.value_type && 'undefined' !== typeof el[filter.uid + '-formatted']
                ? el[filter.uid + '-formatted']
                : el[filter.uid],
    }));

    let values = filter.savedValues;

    if (filter.filterType == 'dropdown') {
        if (filter.include_all_value == 'Y') {
            data.unshift({ value: '', label: 'All' });
            if (filter.savedValues.length == 0) {
                values = [''];
            }
        } else {
            if (filter.savedValues.length == 0 && data.length) {
                onChange(data[0].value);
            }
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ReactSelect
                placeholder={'All'}
                isMulti={filter.filterType == 'multiselect'}
                data={data}
                update={(value) => {
                    onChange(value);
                }}
                value={values}
            />{' '}
        </Box>
    );
}
