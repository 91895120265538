import useBundleTranslation from 'i18n';
import { FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';
import DependencyHint from 'components/common/form/data-fetch-command/hint/entity/DependencyHint';
import CalendarHint from 'components/common/form/data-fetch-command/hint/entity/CalendarHint';
import MetricHint from 'components/common/form/data-fetch-command/hint/entity/MetricHint';
import SegmentHint from 'components/common/form/data-fetch-command/hint/entity/SegmentHint';
import DatasetHint from 'components/common/form/data-fetch-command/hint/entity/DatasetHint';

export default function DataFetchCommandHint({ controlProps }: { controlProps: FormControlDataFetchCommandProps }) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const props = {
        controlProps: controlProps,
        t: t,
    };

    if (controlProps.type == 'dependency') {
        return <DependencyHint {...props} />;
    } else if (controlProps.type == 'calendar') {
        return <CalendarHint {...props} />;
    } else if (controlProps.type == 'metric') {
        return <MetricHint {...props} />;
    } else if (controlProps.type == 'segment') {
        return <SegmentHint {...props} />;
    } else if (controlProps.type == 'dataset') {
        return <DatasetHint {...props} />;
    }
    return null;
}
