import React from 'react';
import VirtualList, { RenderedRows } from 'react-tiny-virtual-list';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ReactSelectVirtualMenuList(props: any) {
    const appTheme = useTheme();
    const DefaultItemHeight = 32;
    const DefaultSeparatorHeight = 16;
    const { options, children, maxHeight, getValue, viewSettings } = props;

    const [value] = getValue();
    const initialOffset = options.indexOf(value) !== -1 ? options.indexOf(value) * DefaultItemHeight : undefined;
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight =
        maxHeight < childrenOptions.length * DefaultItemHeight ? maxHeight : childrenOptions.length * DefaultItemHeight;

    const isScrollable = maxHeight < childrenOptions.length * DefaultItemHeight;

    const onItemsRendered = function ({ stopIndex }: RenderedRows) {
        props.selectProps.onMenuScrollToBottom(stopIndex);
    };

    const renderElementId = '__render_area' + props.selectProps.instanceId;

    //ToDo [Stas] wrapperHeight / scrollOffset / bug with hover
    return (
        <>
            <VirtualList
                className="react-virtualized-list"
                style={{
                    //Experimental for virtual list auto menu size
                    maxHeight: maxHeight,
                }}
                width="100%"
                height={maxHeight}
                scrollOffset={initialOffset}
                itemCount={childrenOptions.length}
                onItemsRendered={onItemsRendered}
                itemSize={(index) => {
                    const element = document.getElementById(renderElementId);
                    const textToRender = props.children[index]?.props?.data?.label ?? '';
                    const separator = props.children[index]?.props?.data?.value === 'sep';

                    if (separator) {
                        return DefaultSeparatorHeight;
                    }

                    if (!element || textToRender.length == 0) {
                        return DefaultItemHeight;
                    }
                    if (props.children[index]?.props?.data?.icon) {
                        // TODO: add space for icon
                    }
                    element.innerText = textToRender;
                    return element.clientHeight;
                }}
                renderItem={({ index, style }) => {
                    return (
                        <Box key={index} style={style} className="react-virtualized-list__item">
                            {props.children[index]}
                        </Box>
                    );
                }}
            />
            <div
                className="react-virtualized-list"
                style={{
                    overflowY: isScrollable ? 'scroll' : 'hidden',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    visibility: 'hidden',
                    pointerEvents: 'none',
                }}
            >
                <Box
                    id={renderElementId}
                    className="react-virtualized-list__item"
                    style={viewSettings.styles.option({}, {})}
                ></Box>
            </div>
        </>
    );
}
