import { lazy } from 'react';
import { RouteItemType } from 'routes/index';
const FormPage = lazy(() => import('components/common/FormPage'));
const Page = lazy(() => import('components/common/Page'));

export const userRoutes: Array<RouteItemType> = [
    {
        path: ['/editor/user/import'],
        name: 'user.import.name',
        component: FormPage,
        props: { uid: 'userimportForm', settingsUrl: '/data/editor/user/import/form' },
    },
    {
        path: ['/editor/user/access-request'],
        name: 'user.access_request.name',
        component: Page,
        props: { settingsUrl: '/data/editor/user/access-request/index', pageMenu: 'publishing' },
    },
    {
        path: ['/editor/user'],
        name: 'user.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/user/index',
            pageMenu: 'users',
            helpLink:
                'https://help.metricinsights.com/m/Controlling_Access_to_Metric_Insights/l/104512-create-a-new-user-account-and-assign-to-group',
        },
    },
    {
        path: ['/editor/user/:userId'],
        name: 'user.editor.name',
        component: FormPage,
        props: {
            uid: 'userForm',
            pk: 'userId',
            settingsUrl: '/data/editor/user/:userId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'user.name',
                        href: '/editor/user',
                    },
                ],
                instanceDataKey: 'username',
            },
            helpLink:
                'https://help.metricinsights.com/m/Controlling_Access_to_Metric_Insights/l/104512-create-a-new-user-account-and-assign-to-group',
        },
    },
];
