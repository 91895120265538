import React, { useEffect, useState } from 'react';
import { openInNewTab, processUrl } from 'tools/tools';
import { Box, Button, ButtonGroup } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { FormControlHelper } from 'components/common/form/layout/helper/index';
import { FormControlSelectProps } from 'components/common/form/layout/control/FormSelect';
import { FormHelperButtonEditWithAdd } from './ButtonEditWithAdd';

export default function CustomFieldEdit({
    value,
    helperProps,
    controlProps,
}: FormControlHelper<FormControlSelectProps, FormHelperButtonEditWithAdd>) {
    const props: FormHelperButtonEditWithAdd = controlProps?.elementProps?.componentProps?.helper
        ? { ...helperProps, ...controlProps.elementProps.componentProps.helper }
        : helperProps;

    const handleEditClick = () => {
        openInNewTab(editUrl);
    };

    const [editUrl, setEditUrl] = useState<string>(processUrl(props.editUrl, { [props.pk]: value }));

    useEffect(() => {
        setEditUrl(processUrl(props.editUrl, { [props.pk]: value }));
    }, [value, props.editUrl, props.pk]);

    return (
        <Box sx={{ ml: 1, flexShrink: 0 }}>
            <ButtonGroup aria-label="outlined primary button group">
                {!props.editHidden && (
                    <Button data-test={controlProps.name + '-helper-edit-button'} onClick={handleEditClick}>
                        <IconMi icon={props.editIcon ?? 'gear'} fontSize="16" />
                    </Button>
                )}
            </ButtonGroup>
        </Box>
    );
}
