import React, { useContext, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Stack, Tooltip } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { getFieldOptionsForSelect, PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import { components } from 'react-select';

export interface QueryBuilderDimensionMetricGAPopupProps {
    onClose: (event: any) => void;
    onApply: (data: any) => void;
    type: string;
    fieldsListToShow: any[];
}

const OptionComponent = (props: any) => {
    return (
        <Tooltip placement={'right'} title={props.data.description ?? ''}>
            <Box>
                <components.Option {...props}>{props.children}</components.Option>
            </Box>
        </Tooltip>
    );
};
const MultiValueLabelComponent = (props: any) => {
    return (
        <Tooltip placement={'top'} title={props.data.description ?? ''}>
            <Box>
                <components.MultiValueLabel {...props} />
            </Box>
        </Tooltip>
    );
};

export default function QueryBuilderDimensionMetricGAPopup(props: QueryBuilderDimensionMetricGAPopupProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { onClose = () => {}, onApply = () => {}, type, fieldsListToShow } = props;
    const { reportData, queryData, helperAct, pluginConfig } = useContext(PluginQBContext);
    const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
    const [columnOptions, setColumnOptions] = useState<any[]>([]);

    useEffect(() => {
        const availableFields: any = {};
        const fieldType = type.toUpperCase();
        Object.keys(reportData.fields).forEach((fieldName: any) => {
            const fieldData = reportData.fields[fieldName];
            if (fieldData.isDimension && fieldData.fieldType === fieldType) {
                availableFields[fieldName] = fieldData;
            }
        });

        const alreadyAddedFields: any = {};
        fieldsListToShow.forEach((item: any) => {
            alreadyAddedFields[item.field.name] = true;
        });
        const options: any = getFieldOptionsForSelect(availableFields, helperAct, pluginConfig)
            .filter((optionItem: any) => {
                if (optionItem.options) return true;

                return !alreadyAddedFields[optionItem.value];
            })
            .map((optionItem: any) => {
                if (!optionItem.options) {
                    return optionItem;
                } else {
                    return {
                        ...optionItem,
                        options: optionItem.options.filter((optionItem: any) => {
                            return !alreadyAddedFields[optionItem.value];
                        }),
                    };
                }
            });
        setColumnOptions(options);
    }, []);

    return (
        <Popup
            settings={{
                title: t(`dimension_metric_popup.${type}_title`),
                textOK: t(`dimension_metric_popup.${type}_ok`),
                maxWidth: 'popupLg',
            }}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                if (!selectedColumns.length) {
                    alert(
                        t('dimension_metric_popup.alert_empty_selected_columns', {
                            typeName: t(`dimension_metric_popup.${type}_type`),
                        }),
                    );
                } else {
                    onApply(selectedColumns);
                }
            }}
        >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box flexShrink={0}>{t(`dimension_metric_popup.${type}_field_label`)}</Box>
                <Box flexGrow={1}>
                    <ReactSelect
                        data={columnOptions}
                        value={selectedColumns}
                        update={(columns) => {
                            setSelectedColumns(columns);
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        overrideComponents={{
                            Option: OptionComponent,
                            MultiValueLabel: MultiValueLabelComponent,
                        }}
                    />
                </Box>
            </Stack>
        </Popup>
    );
}
