import React, { useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import {
    HeaderResizableClassName,
    PivotHeaderDropDownFieldType,
} from 'components/report-content/components/dataset/index';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import ContentMode = ReportContentNS.ContentMode;
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotDataCell = ReportContentNS.PivotDataCell;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import { pivotDefaultColumnSize } from 'components/report-content/components/pivot/Pivot';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { alpha } from '@mui/material/styles';
import PivotHeaderDropDownIcon from 'components/report-content/components/pivot/parts/header/PivotHeaderDropDownIcon';

const DefaultHeight = 11;

function ResizeHandle() {
    return (
        <Box
            component={'span'}
            className={'resize-handle'}
            sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                inset: 0,
                '&:hover': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.85),
                },
            }}
        />
    );
}

function PivotHeaderLineSortIcon({
    field,
    sortFields,
}: {
    field: ComponentSettingsPivotField;
    sortFields: Array<ComponentSettingsDatasetSortField>;
}) {
    const index = sortFields.findIndex((sort) => sort.reference_name == field.reference_name);
    if (index == -1) {
        return null;
    }

    return (
        <span>
            {index} {sortFields[index].dir}{' '}
        </span>
    );
}

export default function PivotHeaderCell({
    component,
    cell,
    cellStyler,
    setHeaderDropDownField,
    onChangeColumnSettings,
    headerDropDownField,
    context,
    mainRow,
    realIndex,
    row,
}: {
    component: BlockComponent<ComponentSettingsPivot>;
    cell: PivotDataCell;
    cellStyler: PivotCellStyler;
    setHeaderDropDownField: (field: PivotHeaderDropDownFieldType) => void;
    headerDropDownField: PivotHeaderDropDownFieldType;
    onChangeColumnSettings: any;
    context: ContentMode;
    mainRow: boolean;
    realIndex: number;
    row: Array<PivotDataCell>;
}) {
    const [mouseOnField, setMouseOnField] = useState<string>('');

    // if (field.override_header_ind == 'Y') {
    //     fieldObj.bgColor = field.bgColor ?? fieldObj.bgColor;
    //     fieldObj.textColor = field.textColor ?? fieldObj.textColor;
    // }

    const fullRowHeight = DefaultHeight + Number(component.settings.mainFontSize ?? 12);

    let fieldObj = component.settings.columnFields.find((f) => f.reference_name == cell.reference_name);

    if (!fieldObj) {
        fieldObj = component.settings.rowFields.find((f) => f.reference_name == cell.reference_name);
    }
    let styleData: any = {};
    if (fieldObj) {
        const isTotalColumn = cell.type == 'total';
        styleData = cellStyler.getCellStyle(
            realIndex,
            cell.colspan ?? 0,
            fieldObj,
            { ...cell, type: isTotalColumn ? 'column' : cell.type },
            false,
            true,
        );
    }

    const style = {
        ...styleData.styleRules,
        display: 'block',
        overflow: 'hidden',
        verticalAlign: 'top',
        minHeight: '1.92em',
        lineHeight: '1.25em',
        padding: '0.33em 5px',
    };

    let width = 0;
    let uiFieldIndex = -1;
    if (mainRow) {
        uiFieldIndex = component.settings.uiFields.findIndex((f) => String(f.column_sequence) == String(cell.shift));
        if (uiFieldIndex != -1) {
            const uiField = component.settings.uiFields[uiFieldIndex];
            width = uiField.columnSize;
        }
    } else {
        width = cell.style?.columnWidth ?? 0;
    }
    width = width < pivotDefaultColumnSize ? pivotDefaultColumnSize : width;

    const ref = useRef<Resizable>();
    if (ref.current && component.settings.autoStretch) {
        setTimeout(() => ref.current?.updateSize({ width: width, height: fullRowHeight }));
    }

    if (!mainRow) {
        style['width'] = width + 'px';
        style['minWidth'] = pivotDefaultColumnSize + 'px';
        style['height'] = fullRowHeight + 'px';
        style['flexShrink'] = 0;
        style['bolder'] = '1 px solid #ccc !important';
    }

    let left = 0;
    let className = cellStyler.defaultCellStyles.buildClassStr(styleData.classRules);
    let stickyStyle = {};
    if (component.settings.rowFields.length > cell.shift) {
        className += ' block-wrapper-fixed-column';
        if (mainRow) {
            component.settings.uiFields
                .filter((f) => Number(f.column_sequence) < realIndex && Number(f.column_sequence) != -1)
                .forEach((f) => {
                    const widthToAdd = f.columnSize ?? pivotDefaultColumnSize;
                    left += widthToAdd < pivotDefaultColumnSize ? pivotDefaultColumnSize : widthToAdd;
                });
        } else {
            for (let i = 0; i < realIndex; i++) {
                left += row[i].style.columnWidth ?? pivotDefaultColumnSize;
            }
        }

        stickyStyle = { position: 'sticky', left: left + 'px', zIndex: 99 };
    }
    // const className = component.settings.rowFields.length > cell.shift ? 'block-wrapper-fixed-column' : '';
    // console.log([styleData.classRules]);

    if (uiFieldIndex != -1) {
        className += ' dUiField';
    }

    const content = (
        <Box
            component="span"
            sx={{
                whiteSpace: 'nowrap',
                '&.block-wrapper-fixed-column': { position: 'sticky', left: 0, backgroundColor: '#fff' },
            }}
            style={{ ...(style as React.CSSProperties), ...(mainRow ? {} : stickyStyle) }}
            onMouseEnter={() => setMouseOnField(String(uiFieldIndex))}
            onMouseLeave={() => setMouseOnField('')}
            className={className}
            data-uiindex={uiFieldIndex}
        >
            {cell.value}
            {context == 'edit' &&
                (mouseOnField == String(uiFieldIndex) || headerDropDownField.uiFieldIndex == uiFieldIndex) && (
                    <PivotHeaderDropDownIcon
                        realIndex={realIndex}
                        cell={cell}
                        uiFieldIndex={uiFieldIndex}
                        onClick={setHeaderDropDownField}
                    />
                )}
        </Box>
    );

    if (!mainRow) {
        return content;
    }

    return (
        <Resizable
            // @ts-ignore
            ref={ref}
            className={HeaderResizableClassName}
            key={cell.uid}
            defaultSize={{
                width: width + 'px',
                height: fullRowHeight + 'px',
            }}
            minWidth={pivotDefaultColumnSize}
            // maxHeight="75vh"
            onResize={(e, dir, ref, delta) => {
                const newSettings = { ...cell.style, columnSize: ref.clientWidth };
                onChangeColumnSettings(cell, newSettings, true, realIndex);
            }}
            handleComponent={{ right: <ResizeHandle /> }}
            handleStyles={{ right: { right: 0, width: '5px' } }}
            enable={{ right: true }}
            style={{ ...stickyStyle, overflow: 'hidden' }}
        >
            {content}
        </Resizable>
    );
}
