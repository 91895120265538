import React, { useContext, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import IconMi from 'components/common/icon/IconMi';

import QueryBuilderFieldsGrid from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGrid';
import QueryBuilderSortGrid from 'components/plugin-query-builder/query-builder/QueryBuilderSortGrid';
import QueryBuilderFilterGrid from 'components/plugin-query-builder/query-builder/QueryBuilderFilterGrid';
import { configData, PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import {
    QBHelperActList,
    QBQueryActList,
    QBQueryData,
    QBReportActList,
    QBReportData,
} from 'components/plugin-query-builder/index';
import { QBReportField } from 'components/plugin-query-builder/data/ReportFieldClass';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { Trans } from 'react-i18next';

export interface QueryBuilderPopupProps {
    onClose: () => void;
    onApply: () => void;
    config: configData;
}

interface queryBuilderEditorData {
    defaults: any;
    dateColumns: any[];
    dateYearColumns: any[];
    dateMonthColumns: any[];
    dateDayColumns: any[];
    dateHourColumns: any[];
    expressionColumns: any[];
    columnsOrder: any;
    //ignoredIndexes: any[];
    dimensionIndex: number;
    autoChecked: any;
    checkedItems: any;
    addedToShowFieldList: any;
}

export default function QueryBuilderPopup(props: QueryBuilderPopupProps) {
    const { onClose = () => {}, onApply = () => {}, config } = props;
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);

    const { reportData, reportAct, queryData, queryAct, helperAct, configData, pluginConfig } =
        useContext(PluginQBContext);

    const tabs = ['fields', 'advanced'];
    const [activeTab, setActiveTab] = useState<string>('fields');
    const [resultFieldsTmp, setResultFieldsTmp] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [rowsList, setRowsList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const [tmpReportData, setTmpReportData] = useState<QBReportData>(reportData);
    const [editorDataState, setEditorDataState] = useState<queryBuilderEditorData>({
        dateColumns: [],
        dateYearColumns: [],
        dateMonthColumns: [],
        dateDayColumns: [],
        dateHourColumns: [],
        expressionColumns: [],
        defaults: {},
        columnsOrder: {},
        //ignoredIndexes: [],
        dimensionIndex: 0,
        autoChecked: {},
        checkedItems: {},
        addedToShowFieldList: {}, //for ga flow
    });

    let editorData = editorDataState;
    const updateEditorData = (newData: any) => {
        setEditorDataState((previousEditorDataState) => ({ ...previousEditorDataState, ...newData }));
        //setEditorData({ ...reportData, ...newData });
        editorData = { ...editorData, ...newData };
    };

    const editorAct = {
        updateData: updateEditorData,
        getColumnIndex: (column: string) => {
            let index;
            if (editorData.columnsOrder.hasOwnProperty(column)) {
                index = editorData.columnsOrder[column];
            } else {
                index = editorData.dimensionIndex + 1;
                updateEditorData({ dimensionIndex: index });
            }
            return index;
        },
        getCheckedState: (fieldName: string, initialChecked: boolean) => {
            if (editorData.checkedItems.hasOwnProperty(fieldName)) {
                return editorData.checkedItems[fieldName];
            } else {
                return initialChecked;
            }
        },
        setCheckedState: (fieldName: string, isChecked: boolean, autoChecked?: boolean) => {
            updateEditorData({ checkedItems: { ...editorData.checkedItems, [fieldName]: isChecked } });

            const autoCheckedVal = autoChecked ?? false;
            updateEditorData({ autoChecked: { ...editorData.autoChecked, [fieldName]: autoCheckedVal } });
        },
        setListCheckedState: (fieldNames: string[]) => {
            const showObject: any = {};
            fieldNames.forEach((fieldName: string) => {
                showObject[fieldName] = true;
            });
            updateEditorData({ checkedItems: { ...editorData.checkedItems, ...showObject } });
        },
        removeCheckedState: (fieldName: string) => {
            if (editorData.checkedItems.hasOwnProperty(fieldName)) {
                const checkedItemsData = { ...editorData.checkedItems };
                delete checkedItemsData[fieldName];
                updateEditorData({ checkedItems: checkedItemsData });
            }
            if (editorData.autoChecked.hasOwnProperty(fieldName)) {
                const autoCheckedItemsData = { ...editorData.autoChecked };
                delete autoCheckedItemsData[fieldName];
                updateEditorData({ autoChecked: autoCheckedItemsData });
            }
        },

        isCheckedAll: () => {
            if (!rowsList.length) return false;

            return !rowsList.some((item: any) => {
                return !editorAct.getCheckedState(item.field.name, item.isChecked);
            });
        },
        toggleCheckAll: (isChecked: boolean) => {
            const overrides: any = {};

            rowsList.forEach((item: any) => {
                overrides[item.field.name] = isChecked;
            });
            updateEditorData({ checkedItems: overrides });
        },
        setFieldAutoChecked: (fieldName: string, isAuto: boolean) => {
            updateEditorData({ autoChecked: { ...editorData.autoChecked, [fieldName]: isAuto } });
        },
        isFieldAutoChecked: (fieldName: string) => {
            if (editorData.autoChecked.hasOwnProperty(fieldName)) {
                return editorData.autoChecked[fieldName];
            }

            return false;
        },
        removeAddedToShowField: (fieldName: string) => {
            updateEditorData({ addedToShowFieldList: { ...editorData.addedToShowFieldList, [fieldName]: false } });
        },
        setAsAddedToShowField: (fieldName: string) => {
            updateEditorData({ addedToShowFieldList: { ...editorData.addedToShowFieldList, [fieldName]: true } });
        },
        setListAsAddedToShowField: (fieldNames: string[]) => {
            const showObject: any = {};
            fieldNames.forEach((fieldName: string) => {
                showObject[fieldName] = true;
            });
            updateEditorData({ addedToShowFieldList: { ...editorData.addedToShowFieldList, ...showObject } });
        },
        isAddedToShowField: (fieldName: string) => {
            if (editorData.addedToShowFieldList.hasOwnProperty(fieldName)) {
                return editorData.addedToShowFieldList[fieldName];
            }

            return false;
        },
    };

    const prepareData = () => {
        setError('');
        const fieldsCount = Object.keys(reportData.fields).length;
        const dimensionItems: any[] = [];
        const alreadyAddedDimensions: any[] = [];

        if (!fieldsCount) {
            setError('Could not get details of selected report');
            return dimensionItems;
        }

        //reset
        editorAct.updateData({
            dateColumns: [],
            dateYearColumns: [],
            dateMonthColumns: [],
            dateDayColumns: [],
            dateHourColumns: [],
            expressionColumns: [],
        });

        const addFieldRow = (type: string, field: any, isChecked: boolean, metric?: any) => {
            let componentName = '';
            switch (type) {
                case 'date':
                    componentName = 'ConstructedDateItem';
                    break;
                case 'expression':
                    componentName = 'ExpressionItem';
                    break;
                case 'dimension':
                    componentName = 'DimensionItem';
                    break;
                case 'prompt':
                    componentName = 'PromptItem';
                    break;
            }

            const dimensionIndex = editorAct.getColumnIndex(field.name);

            if (isChecked) editorAct.setAsAddedToShowField(field.name);
            dimensionItems.push({
                component: componentName,
                field: field,
                isChecked: isChecked,
                metric: metric ?? null,
                index: dimensionIndex,
            });
        };

        Object.keys(queryData.dates).forEach((dateKey) => {
            const dateComponents = {
                value: queryData.dates[dateKey].value,
                year: queryData.dates[dateKey].year,
                month: queryData.dates[dateKey].month,
                day: queryData.dates[dateKey].day,
                hour: queryData.dates[dateKey].hour,
                format: queryData.dates[dateKey].format,
            };
            const dateItemData = reportAct.addDate(dateComponents, queryData.dates[dateKey].name);
            if (
                dateItemData &&
                reportData.overrides.hasOwnProperty(dateItemData.name) &&
                reportData.overrides[dateItemData.name].toUpperCase() !==
                    reportData.fields[dateItemData.name].type.toUpperCase()
            ) {
                reportAct.setTypeOverride(dateItemData.name, reportData.overrides[dateItemData.name]);
            }
        });

        Object.keys(queryData.exprs).forEach((exprsKey) => {
            const exprsItemData = reportAct.addExpression(
                queryData.exprs[exprsKey].expr,
                queryData.exprs[exprsKey].name,
            );

            if (
                exprsItemData &&
                reportData.overrides.hasOwnProperty(exprsItemData.name) &&
                reportData.overrides[exprsItemData.name].toUpperCase() !==
                    reportData.fields[exprsItemData.name].type.toUpperCase()
            ) {
                reportAct.setTypeOverride(exprsItemData.name, reportData.overrides[exprsItemData.name]);
            }
        });

        // Add field which were defined in query and exists in report
        Object.keys(queryData.dimensions).forEach((fieldKey) => {
            if (reportAct.hasField(fieldKey)) {
                const fieldData = reportAct.getField(fieldKey);

                if (fieldData.isConstructedDate) {
                    if (!reportAct.isValidDate(fieldData.date)) return false; //continue
                    addFieldRow('date', fieldData, true);
                } else if (fieldData.isExpression) {
                    if (!reportAct.isValidExpression(fieldData.expr)) {
                        editorAct.setCheckedState(fieldData.name, false);
                        return false; //continue
                    }
                    addFieldRow('expression', fieldData, true);
                } else {
                    addFieldRow('dimension', fieldData, true);
                }
            } else if (reportData.prompts.hasOwnProperty(fieldKey)) {
                if (reportData.prompts[fieldKey].values !== 'none') {
                    const promptData = reportData.prompts[fieldKey];
                    addFieldRow('prompt', promptData, true);
                    alreadyAddedDimensions.push(fieldKey);
                }
            }
        });

        // Add metrics to dimensions list which were defined in query and exists in report
        Object.keys(queryData.metrics).forEach((metricKey) => {
            const metric = queryData.metrics[metricKey];

            if (metric.field == '*' && metric.operation == 'count') {
                const field = new QBReportField({ name: 'COUNT(*)', type: 'INTEGER' });
                addFieldRow('dimension', field, true, metric);
            } else if (reportAct.hasField(metric.field)) {
                const field = reportAct.getField(metric.field);
                if (field.isDimension) {
                    addFieldRow('dimension', field, true, metric);
                } else if (field.isConstructedDate) {
                    if (!reportAct.isValidDate(field.date)) return false; //continue
                    addFieldRow('date', field, true, metric);
                } else if (field.isExpression) {
                    if (!reportAct.isValidExpression(field.expr)) {
                        editorAct.setCheckedState(field.name, false);
                        return false; //continue
                    }
                    addFieldRow('expression', field, true, metric);
                } else {
                    return false; //continue
                }

                alreadyAddedDimensions.push(metric.field);
            }
        });

        // Add dimensions and metrics which were defined in report and not exists in query
        Object.keys(reportData.fields).forEach((fieldName) => {
            const fieldItem = reportData.fields[fieldName];
            if (fieldItem.isDimension && !queryAct.hasDimension(fieldName)) {
                if (!alreadyAddedDimensions.includes(fieldName)) {
                    addFieldRow('dimension', fieldItem, editorAct.getCheckedState(fieldName, false));
                }
            } else if (fieldItem.isConstructedDate && !queryAct.hasDimension(fieldName)) {
                if (!alreadyAddedDimensions.includes(fieldName) && reportAct.isValidDate(fieldItem.date)) {
                    addFieldRow('date', fieldItem, editorAct.getCheckedState(fieldName, false));
                }
            } else if (fieldItem.isExpression && !queryAct.hasDimension(fieldName)) {
                if (!alreadyAddedDimensions.includes(fieldName) && reportAct.isValidExpression(fieldItem.expr)) {
                    addFieldRow('expression', fieldItem, editorAct.getCheckedState(fieldName, false));
                }
            }

            const option = helperAct.escapeHtml(fieldItem.name);

            if (fieldItem.isDimension) {
                if (fieldItem.type == 'INTEGER' || fieldItem.type == 'DATE') {
                    editorAct.updateData({
                        dateYearColumns: [...editorData.dateYearColumns, option],
                        dateMonthColumns: [...editorData.dateMonthColumns, option],
                        dateDayColumns: [...editorData.dateDayColumns, option],
                    });

                    if (fieldItem.type == 'INTEGER') {
                        editorAct.updateData({
                            dateHourColumns: [...editorData.dateHourColumns, option],
                        });
                    }

                    if (fieldItem.type == 'DATE') {
                        editorAct.updateData({
                            dateColumns: [...editorData.dateColumns, option],
                        });
                    }
                } else if (fieldItem.type == 'TEXT') {
                    editorAct.updateData({
                        dateMonthColumns: [...editorData.dateMonthColumns, option],
                    });
                }

                if (fieldItem.type == 'INTEGER' || fieldItem.type == 'DECIMAL') {
                    const fieldNameValue = pluginConfig.useDoubleQuoteForDerivedFieldExpressionFieldName
                        ? '"' + fieldItem.name + '"'
                        : fieldItem.name;

                    editorAct.updateData({
                        expressionColumns: [
                            ...editorData.expressionColumns,
                            {
                                value: fieldNameValue,
                                label: fieldItem.name + ' (' + helperAct.ucfirst(fieldItem.type) + ')',
                            },
                        ],
                    });
                }
            }
        });

        // Add prompts
        //const ignoredPrompts: string[] = [];
        Object.keys(reportData.prompts).forEach((promptName) => {
            if (reportData.prompts[promptName].values !== 'none') {
                if (!alreadyAddedDimensions.includes(promptName)) {
                    const promptData = reportData.prompts[promptName];
                    addFieldRow('prompt', promptData, false);
                }
            } /*else {
                ignoredPrompts.push(promptName);
            }*/
        });

        // Sort columns by order
        dimensionItems.sort(function (a, b) {
            return a.index - b.index;
        });

        // Rebuild columns
        /*
        for (var i = 0, j = dimensionItems.length; i < j; i++) {
            dimensionItems[i] = dimensionItems[i].content;
        }
        */

        // Reset columns order
        //updateEditorData({ columnsOrder: {}, ignoredIndexes: [], dimensionIndex: 0 });

        return dimensionItems;
    };

    useEffect(() => {
        setRowsList(prepareData());
        queryAct.updateData({ dates: {}, exprs: {} });
    }, []);

    useEffect(() => {
        const prepData = prepareData();
        //console.log('prepData', prepData);
        setRowsList(prepData);
    }, [reportData, queryData]);

    useEffect(() => {
        //console.log('resultFieldsTmp', resultFieldsTmp);
    }, [resultFieldsTmp]);

    if (!Object.keys(reportData.fields).length) {
        alert(t('query_builder_popup.alert_could_not_get_details'));
        onClose();
        return null;
    }

    //Set iterations Hint
    let iterations_hint = null;

    if (pluginConfig.availableIterationsHint && reportData.iterations?.amount && reportData.iterations.amount > 1) {
        let iterations_limit = null;
        if (reportData.iterations?.limit && reportData.iterations.amount > reportData.iterations.limit) {
            iterations_limit = (
                <Trans
                    t={t}
                    i18nKey="query_builder_popup.iterations_limit"
                    values={{
                        limit: reportData.iterations.limit,
                        title: config.title,
                    }}
                >
                    placeholder_preffix
                    <Box component="strong">placeholder_amount</Box>
                    placeholder_suffixß
                </Trans>
            );
        }
        iterations_hint = (
            <Box id="iterations_hint" sx={{ mt: 2 }}>
                <Box
                    component="span"
                    className="iterations-amount exceed"
                    sx={{ color: iterations_limit ? 'error.main' : undefined }}
                >
                    <Trans
                        t={t}
                        i18nKey="query_builder_popup.iterations_hint"
                        values={{
                            amount: reportData.iterations.amount,
                            title: config.title,
                        }}
                    >
                        <Box component="strong">placeholder_amount</Box>
                        placeholder_suffix
                    </Trans>
                </Box>{' '}
                {iterations_limit}
            </Box>
        );
    }

    const buildQuery = () => {
        let dates: any[] = [];
        let exprs: any[] = [];

        //queryAct.updateData({ command: '', dimensions: {}, metrics: {}, dates: {}, exprs: {}, filters: [], sort: [] });

        const dataToProcess = resultFieldsTmp.map((rowData) => {
            const field = rowData.field;
            const isCheckedNew = editorAct.getCheckedState(field.name, rowData.isChecked);

            if (!isCheckedNew) {
                queryAct.removeReportFieldData(field.name);
            }

            return { ...rowData, isChecked: isCheckedNew };
        });

        // Add dimensions to query
        dataToProcess
            .filter((item) => item.isChecked)
            .forEach((rowData) => {
                const field = rowData.field;

                if (field.name.toUpperCase() == 'COUNT(*)') return true; // continue

                if (!queryAct.hasMetricByFieldName(field.name)) {
                    queryAct.addDimension(field.name, editorData.columnsOrder[field.name]);
                }

                if (reportAct.hasDate(field.name)) {
                    dates.push(field.name);
                }
                if (reportAct.hasExpression(field.name)) {
                    exprs.push(field.name);
                }
            });

        queryData.filters.forEach((filter: any) => {
            if (reportAct.hasDate(filter.column) && !dates.includes(filter.column)) dates.push(filter.column);

            if (reportAct.hasExpression(filter.column) && !exprs.includes(filter.column)) exprs.push(filter.column);
        });

        queryData.sort.forEach((sort: any) => {
            if (reportAct.hasDate(sort.column) && !dates.includes(sort.column)) dates.push(sort.column);

            if (reportAct.hasExpression(sort.column) && !exprs.includes(sort.column)) exprs.push(sort.column);
        });

        Object.keys(reportData.fields).forEach((fieldKey: any) => {
            const field = reportData.fields[fieldKey];
            if (field.isConstructedDate && dates.includes(field.name)) {
                queryAct.addConstructedDate(
                    field.date.value,
                    field.date.year,
                    field.date.month,
                    field.date.day,
                    field.date.hour,
                    field.name,
                    field.date.format,
                );
            } else if (field.isExpression && exprs.includes(field.name)) {
                queryAct.addExpression(field.expr, field.name);
            }
        });
    };

    return (
        <>
            <Popup
                settings={{
                    title: t('query_builder_popup.title', { name: config.title }),
                    textOK: t('query_builder_popup.ok_btn'),
                    customMaxWidth: pluginConfig.availableAliases ? '1000px' : undefined,
                }}
                open={true}
                onHide={() => {
                    onClose();
                }}
                onConfirm={() => {
                    buildQuery();
                    //console.log(queryAct.getQuery());
                    queryAct.setQuery(queryAct.getQuery()); //reset obj
                    onApply();
                }}
                scrollType={'body'}
            >
                <Box>
                    {isLoading && (
                        <LoadingPlaceholder
                            sx={{
                                position: 'absolute',
                                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                                color: (theme) => alpha(theme.palette.text.primary, 0.5),
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                        />
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'visible',
                            mb: 2,
                        }}
                    >
                        <ToggleButtonGroup
                            sx={{ mr: 'auto' }}
                            color="primary"
                            value={activeTab}
                            exclusive
                            onChange={(event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
                                if (newValue !== null) {
                                    setActiveTab(newValue);
                                }
                            }}
                        >
                            {tabs.map((tab) => {
                                return (
                                    <ToggleButton key={tab} value={tab}>
                                        {t(`query_builder_popup.tabs.${tab}`)}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>

                        <Button
                            onClick={async (e) => {
                                setIsLoading(true);
                                e.preventDefault();
                                //var mask = new Ext.DevxBodyMask({msg: 'Refreshing Metadata...'});
                                const result = await reportAct.initData(true);
                                setIsLoading(false);
                                if (result?.error) {
                                    alert(result?.error);
                                }
                                //Some page reload ???
                            }}
                            startIcon={<IconMi icon="refresh-cw" />}
                            variant={'outlined'}
                        >
                            {t(`query_builder_popup.refresh_list_link`)}
                        </Button>
                    </Box>

                    <Box sx={{ display: activeTab !== 'fields' ? 'none' : undefined }}>
                        <QueryBuilderFieldsGrid
                            data={rowsList}
                            editorAct={editorAct}
                            editorData={editorData}
                            updateResult={setResultFieldsTmp}
                        />
                    </Box>
                    <Box sx={{ display: activeTab !== 'advanced' ? 'none' : undefined }}>
                        <QueryBuilderFilterGrid />
                        <QueryBuilderSortGrid />
                    </Box>
                    {iterations_hint}
                </Box>
            </Popup>
        </>
    );
}
