import { alpha } from '@mui/material';
import React from 'react';

export const MuiTablePaginationConfig = {
    defaultProps: {
        children: React.createElement('span', { className: 'CustomIconCheckbox CustomIconCheckbox--notChecked' }, null),
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                color: theme.palette.text.main,
                fontSize: theme.typography.fontSize,
            };
        },
        toolbar: () => {
            return {
                minHeight: '0 !important',
            };
        },
        displayedRows: ({ theme }: { theme: any }) => {
            return {
                fontSize: theme.typography.fontSize,
                margin: 0,
            };
        },
        actions: ({ theme }: { theme: any }) => {
            return {
                marginLeft: '12px',
                '.MuiIconButton-root': {
                    padding: '3px',
                    minWidth: theme.size.defaultHeight,
                    minHeight: theme.size.defaultHeight,
                    color: theme.palette.text.primary,
                    '&.Mui-disabled': {
                        color: alpha(theme.palette.text.primary, 0.24),
                    },
                },
            };
        },
    },
};
