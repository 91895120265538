import React from 'react';
import { Box, Checkbox, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { useNavigate } from 'react-router-dom';
import styles from './AlertRuleListItem.styles';

export interface AlertRuleListLineItemProps {
    alertRule: any;
    segmentsInfo: any;
    maxSegmentsInSegmentLine?: number;
    notificationLineForRole: any;
    notificationLine: string;
    activeNotifyBox?: boolean | null;
    clickToShowSubscribed: (notificationLine: string) => void;
    clickToShowDimension: (event: any) => void;
    onChangeSubscribe: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clickToShowNotifyBox: (event: any) => void;
    clickGoToEdit: (event: any) => void;
    clickSubscribeToSegment: (event: any) => void;
}

//engine/layouts/components/alert_rule/alert_rule_list_template_line_.phtml
//AlertRuleList2 AlertRule
export default function AlertRuleListLineItem(props: AlertRuleListLineItemProps) {
    const {
        alertRule,
        segmentsInfo,
        maxSegmentsInSegmentLine = 3,
        notificationLineForRole = '',
        notificationLine = '',
        activeNotifyBox,
        clickToShowSubscribed = () => {},
        clickToShowDimension = () => {},
        onChangeSubscribe = () => {},
        clickToShowNotifyBox = () => {},
        clickGoToEdit = () => {},
        clickSubscribeToSegment = () => {},
    } = props;

    const { t } = useBundleTranslation(['components/alert-subscription/alert_rule_list']);
    const navigate = useNavigate();

    const privateMessage = t('row_item.private_msg', { ownerName: alertRule.owner_name });

    const getSelectedSegmentValues = alertRule.subscriptions
        .filter((item: any) => {
            return !(item.user_alert_rule == -1 && alertRule.subscribed == 'N');
        })
        .map((item: any) => Number(item.segment_value_id));

    const isSubscribedToSegment =
        getSelectedSegmentValues.includes(Number(segmentsInfo.segment_value_id)) ||
        getSelectedSegmentValues.includes(-1);
    const isSubscribed = alertRule.subscribed == 'Y';
    const isLocked = alertRule.locked == 'Y';
    const isAssignee = alertRule.is_assignee == 'Y';
    const getSubscribersAmount = alertRule.subscribers + isSubscribed;
    const isWorkflowRoleDependent = alertRule.workflow_id != 0;

    const getSubscriptionAmount = () => {
        const subscriptionsItems = alertRule.subscriptions.length;
        if (subscriptionsItems == 1 && alertRule.subscriptions[0].segment_value_id == -1) {
            // If this is -1 segment values, return TOTAL segments count
            return Number(segmentsInfo.amount);
        }
        return subscriptionsItems;
    };

    const clickOnDimensionLink = (event: any) => {
        event.preventDefault();
        clickToShowDimension(event);
    };

    const getSubscriptionLine = () => {
        let segments: any[] = [];
        let allSegments: any[] = [];

        // If this element hasn't segments
        if ('Y' != segmentsInfo.segments) {
            return (
                <Box>
                    {isSubscribed
                        ? t('row_item.has_not_segments_subscribed_y')
                        : t('row_item.has_not_segments_subscribed_n')}
                </Box>
            );
        }

        // If this element has segments
        alertRule.subscriptions.every((item: any) => {
            if (item.segment_value_id == -1) {
                allSegments.push(item);
                return false;
            } else {
                segments.push(item);
                if (segments.length >= maxSegmentsInSegmentLine) return false;
            }
            return true;
        });

        return (
            <Box>
                {isSubscribed ? t('row_item.has_segments_subscribed_y') : t('row_item.has_segments_subscribed_n')}{' '}
                <Tooltip title={isAssignee ? getAssigneeHint() : ''} placement="bottom-start" arrow>
                    <Box component={'span'}>
                        {allSegments.length > 0 ? (
                            <Box
                                component="a"
                                sx={isSubscribed ? styles.arliLineInfoDimLinks : styles.arliLineInfoDimText}
                                href="#"
                                onClick={clickOnDimensionLink}
                            >
                                {t('row_item.all_segment_values_link', {
                                    amount: segmentsInfo.amount,
                                })}
                            </Box>
                        ) : (
                            <>
                                {segments.map((item, index) => {
                                    return (
                                        <Box component={'span'} key={item.segment_value_id}>
                                            <Box
                                                component="a"
                                                sx={
                                                    isSubscribed
                                                        ? styles.arliLineInfoDimLinks
                                                        : styles.arliLineInfoDimText
                                                }
                                                href="#"
                                                onClick={clickOnDimensionLink}
                                            >
                                                {item.title}
                                            </Box>
                                            {index !== segments.length - 1 ? ', ' : ''}
                                        </Box>
                                    );
                                })}
                                {maxSegmentsInSegmentLine < alertRule.subscriptions.length && (
                                    <>
                                        {', '}
                                        {t('row_item.and_other')}{' '}
                                        <Box
                                            component="a"
                                            sx={isSubscribed ? styles.arliLineInfoDimLinks : styles.arliLineInfoDimText}
                                            href="#"
                                            onClick={clickOnDimensionLink}
                                        >
                                            {t('row_item.and_other_segments', {
                                                count: alertRule.subscriptions.length - maxSegmentsInSegmentLine,
                                            })}
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Tooltip>
            </Box>
        );
    };

    const getNotificationIcon = () => {
        if (isWorkflowRoleDependent) {
            if (alertRule.workflow_notif_i == 'immediate' || alertRule.workflow_notif_u == 'immediate') {
                switch (alertRule.notification.send_notification_via) {
                    case 'phone_alert':
                        return 'mobile';
                    case 'sms':
                        return 'comment';
                }
            }
            return 'mail';
        }

        if (alertRule.notification.immediate_notification_ind == 'None') {
            return 'calendar'; //ToDo use browser icon
        } else if (alertRule.notification.immediate_notification_ind == 'N') {
            return 'mail';
        } else {
            switch (alertRule.notification.send_notification_via) {
                case 'phone_alert':
                    return 'mobile';
                case 'sms':
                    return 'comment';
            }
            return 'mail';
        }
    };

    const getAssigneeHint = (type?: string) => {
        const suffix = type == 'checkbox' ? 'checkbox' : 'dimension_val';
        return (
            <Box
                sx={{
                    '.linkRedirect': {
                        color: 'primary.main',
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                    },
                }}
                onClick={(event) => {
                    const el = (event.target as HTMLElement).closest('.linkRedirect');
                    if (el && event.currentTarget.contains(el)) {
                        navigate(`/notification/issue/index/alert/${alertRule.id}`);
                    }
                    // ToDo: change link to new
                }}
                dangerouslySetInnerHTML={{
                    __html: `${t(`row_item.hints.is_assignee_${suffix}`)} ${t('row_item.hints.is_assignee_link', {
                        tagStart: '<span class="linkRedirect">',
                        tagEnd: '</span>',
                    })}`,
                }}
            />
        );
    };

    return (
        <Box className="alert-rule-list-item">
            <Box sx={styles.arlilLine}>
                <Tooltip
                    title={isLocked ? privateMessage : isAssignee ? getAssigneeHint('checkbox') : ''}
                    placement="bottom-start"
                >
                    <Checkbox
                        checked={isSubscribed}
                        onChange={(event) => {
                            if (isLocked || isAssignee) return false;

                            onChangeSubscribe(event);
                        }}
                    />
                </Tooltip>
                <Box sx={styles.arliLineInfo}>
                    <Box>
                        {alertRule.name}
                        {alertRule.type == 'global' && (
                            <Box component="span" sx={styles.arliLineInfoLabel}>
                                {' '}
                                {t('row_item.is_global_label')}
                            </Box>
                        )}
                    </Box>
                    {!isLocked && (
                        <Box component="span" sx={styles.arliLineOptions}>
                            {getSubscriptionLine()}
                            {isSubscribed && (
                                <Box sx={styles.arliLineOptionsNotLink} onClick={clickToShowNotifyBox}>
                                    {notificationLine}
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                <Box sx={styles.arliLineUsersCell}>
                    {!isLocked ? (
                        <>
                            {getSubscribersAmount == 0 ? (
                                <Box sx={styles.arliLineUsers}>
                                    <IconMi icon="user-groups" sx={{ fontSize: '16px', mr: 0.5 }} />
                                    <Box component="span">0</Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{ ...styles.arliLineUsers, ...styles.arliLineUsersActive }}
                                    onClick={() => {
                                        clickToShowSubscribed(notificationLine);
                                    }}
                                >
                                    <IconMi icon="user-groups" sx={{ fontSize: '16px', mr: 0.5 }} />
                                    <Box component="span">{getSubscribersAmount}</Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Tooltip title={privateMessage} placement="bottom-end">
                            <Box component="span" sx={{ color: 'primary.main' }}>
                                <IconMi icon={'hide'} />
                            </Box>
                        </Tooltip>
                    )}
                </Box>

                <Box sx={styles.arliLineEditCell}>
                    {alertRule.can_edit && (
                        <Box sx={styles.arliLineEdit} onClick={clickGoToEdit}>
                            <IconMi icon="gear" sx={{ fontSize: '16px' }} />
                        </Box>
                    )}
                </Box>

                {segmentsInfo.segments == 'Y' && (
                    <Box sx={styles.arliLineDimensionCell}>
                        {!isLocked && (
                            <Tooltip title={isAssignee ? getAssigneeHint() : ''} placement="bottom-end" arrow>
                                <Box sx={styles.arlilLineDimension} onClick={clickOnDimensionLink}>
                                    <IconMi icon="cube" sx={{ fontSize: '16px', mr: 0.5 }} />
                                    <Box component="span">
                                        {t('row_item.dimension_value', { count: getSubscriptionAmount() })}
                                    </Box>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
                )}

                <Box sx={styles.arliLineEnvelopeCell}>
                    {isSubscribed && !isLocked && (
                        <Tooltip title={notificationLineForRole} placement="bottom-end">
                            <Box
                                sx={activeNotifyBox ? styles.arliLineEnvelopeActive : styles.arliLineEnvelope}
                                onClick={clickToShowNotifyBox}
                                data-markicon
                            >
                                <IconMi icon={getNotificationIcon()} sx={{ fontSize: '16px' }} />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            {segmentsInfo.segments == 'Y' && alertRule.subscribed == 'Y' && !isSubscribedToSegment && (
                <Box sx={styles.arliNotReceiveMsg}>
                    <Box sx={styles.arliNotReceiveMsgInner}>
                        <IconMi icon={'error'} sx={{ color: 'error.main', mr: 0.5 }} />
                        {t('row_item.will_not_receive_alerts_msg', { title: segmentsInfo.title })}{' '}
                        <Box
                            component="a"
                            href="#"
                            sx={styles.arliNotReceiveMsgLink}
                            onClick={(event) => {
                                event.preventDefault();
                                clickSubscribeToSegment(event);
                            }}
                        >
                            {t('row_item.will_not_receive_alerts_msg_link', { title: segmentsInfo.title })}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
