import React, { useState } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import BlocksContext = ReportContentNS.BlocksContext;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;

export default function BlocksContextProvider({
    children,
    manageFiltersSettings,
}: {
    children: any;
    manageFiltersSettings: ComponentSettingsManageFilters | null;
}) {
    const [filterRules, setFilterRules] = useState<any>(null);
    const [extViz, setExtViz] = useState<ComponentSettingsExternalVisualization | null>(null);
    const [manageFiltersReady, setManageFiltersReady] = useState<boolean>(false);

    const handleSetFilterRules = (rules: any) => {
        if (JSON.stringify(rules) != JSON.stringify(filterRules)) {
            setFilterRules(rules);
        }
    };

    const handleSetExtViz = (newExtViz: ComponentSettingsExternalVisualization | null) => {
        setExtViz(newExtViz);
    };

    return (
        <BlocksContext.Provider
            value={{
                manageFiltersSettings: manageFiltersSettings,
                filters: filterRules,
                setFilters: handleSetFilterRules,
                extViz: extViz,
                manageFiltersReady: manageFiltersReady,
                setExtViz: handleSetExtViz,
                setManageFiltersReady: (status: boolean) => {
                    setManageFiltersReady(status);
                },
            }}
        >
            {children}
        </BlocksContext.Provider>
    );
}
