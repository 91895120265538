import { ElementDocumentType } from 'components/element-viewer/index';
import useBundleTranslation from 'i18n';
import React from 'react';
import DropdownIcon from 'components/element-viewer/controls-panel-icon/DropdownIcon';
import { Box, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { YNValues } from 'tools/types';
import { instance } from 'api/api';

export function MenuDocument({ doc }: { doc: ElementDocumentType & { isNew?: YNValues; isUpdated?: YNValues } }) {
    const { t } = useBundleTranslation(['components/common/element']);
    let url = '';

    if ('file' == doc.sourceType) {
        url = '/content/index/view-document/document/' + doc.documentId;
    } else if ('url' == doc.sourceType) {
        url = doc.externalUrl;
    }

    return (
        <Link to={url} target="_blank">
            {(doc.isNewInd || doc?.isNew == 'Y') && (
                <Chip label={t('topmenu_docs_new')} color="success" sx={{ mr: 0.5 }} />
            )}
            {(doc.isUpdatedInd || doc?.isUpdated == 'Y') && (
                <Chip label={t('topmenu_docs_updated')} color="primary" sx={{ mr: 0.5 }} />
            )}
            {doc.name}
        </Link>
    );
}

export default function DocumentsButton({
    docs,
    popperSx,
    onChangeState = () => {},
}: {
    docs: Array<ElementDocumentType & { isNew?: YNValues; isUpdated?: YNValues }>;
    popperSx?: SxProps;
    onChangeState?: (isOpened: boolean) => void;
}) {
    if (docs?.length <= 0) return null;

    const markDocumentAsViewed = (ids: Array<number>) => {
        instance.post('data/content/mark-docs-as-viewed', { docIds: ids });
    };

    const handleChangeStatus = (isOpened: boolean) => {
        onChangeState(isOpened);
        if (isOpened) {
            const ids = docs.map((doc) => doc.documentId);
            if (ids.length) {
                markDocumentAsViewed(ids);
            }
        }
    };

    let newDocuments = docs.filter(
        (doc) => doc.isNewInd || doc?.isNew == 'Y' || doc.isUpdatedInd || doc?.isUpdated == 'Y',
    ).length;

    return (
        <Box component={'span'} data-test={'top-documents-icon'} sx={{ svg: { ml: newDocuments ? 0.5 : 0 } }}>
            <DropdownIcon
                icon="paperclip"
                label={newDocuments ? `${newDocuments}` : ''}
                popperSx={popperSx}
                onChangeState={handleChangeStatus}
            >
                <Box sx={{ p: 1, maxWidth: '300px' }}>
                    {docs.map((doc, index) => {
                        return (
                            <Box key={index + '_doc'}>
                                {(index == 0 || docs[index].documentType != docs[index - 1].documentType) && (
                                    <Box sx={{ mt: index > 0 ? 1 : 0 }}>
                                        <strong>{doc.documentType}</strong>
                                    </Box>
                                )}
                                <MenuDocument doc={doc} key={doc.documentId} />
                            </Box>
                        );
                    })}
                </Box>
            </DropdownIcon>
        </Box>
    );
}
