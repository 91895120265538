import { ReportContentNS } from 'components/report-content/index';
import DrillToTooltipPart, { TooltipDrillData } from 'components/report-content/tooltip/DrillToTooltipPart';
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import { Box, Stack } from '@mui/material';
import styles from 'app/home/tile/components/info-panel/styles';
import { ReportTooltipProps } from 'components/report-content/tooltip';

export default function PieChartTooltip({
    point,
    settings,
    contentSettings,
    closeComponent,
}: ReportTooltipProps<ComponentSettingsPieChart>) {
    const drillData: Array<TooltipDrillData> = [
        {
            field: settings.group_field,
            value: point.name,
        },
        {
            field: 'snapshot_date',
            value: contentSettings.measurement_time ?? '',
        },
    ];

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                justifyContent={'space-between'}
                sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0, paddingTop: 0, height: 29 }}
            >
                <Stack direction={'row'}>
                    <Box
                        style={{ color: String(point.color) }}
                        dangerouslySetInnerHTML={{ __html: point.key.name_f + ':&nbsp;' }}
                    />
                    <Box>{point.key.y_f}</Box>
                </Stack>
                <Box>{closeComponent}</Box>
            </Stack>
            <DrillToTooltipPart contentSettings={contentSettings} data={drillData} settings={settings} />
        </Stack>
    );
}
