import { alpha, Theme } from '@mui/material';

export default {
    alsoSentBlock: {
        pt: 1,
    },
    addRuleBasedLink: {
        float: 'right',
        mt: 1,
        ml: 2,
        padding: '0 8px',
        minHeight: 0,
    },
    alsoSentItem: {},
    alsoSentItemLink: {},
};
