import { IconButton, Grid, Tooltip, alpha, Theme, Box } from '@mui/material';
import React, { useState } from 'react';
import { LoginAsCellProps } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import LoginAsPopup from 'components/common/login-as-popup/LoginAsPopup';
import useBundleTranslation from 'i18n';

export default function LoginAsCell({ userName, userId, loginAsView, disabled }: LoginAsCellProps) {
    const [showLoginAsPopup, setShowLoginAsPopup] = useState<boolean>(false);
    const { t } = useBundleTranslation(['components/common/login_as']);

    if (userId == undefined) return null;
    if (disabled) {
        return (
            <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title={String(t('cell_tooltip_disabled'))} placement={'left'}>
                    <Box>
                        <IconMi
                            icon="spy"
                            sx={{ fontSize: 16, color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4) }}
                        />
                    </Box>
                </Tooltip>
            </Grid>
        );
    }
    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title={String(t('cell_tooltip'))} placement={'left'}>
                <IconButton
                    onClick={() => {
                        setShowLoginAsPopup(true);
                    }}
                    sx={{ color: 'primary.main', '&:hover': { opacity: 0.75 } }}
                >
                    <IconMi icon="spy" fontSize={'16'} />
                </IconButton>
            </Tooltip>
            <LoginAsPopup
                show={showLoginAsPopup}
                userName={userName}
                userId={userId}
                loginAsView={loginAsView}
                onClose={() => {
                    setShowLoginAsPopup(false);
                }}
            />
        </Grid>
    );
}
