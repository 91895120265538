import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, IconButton, Tooltip } from '@mui/material';
import PartItemWallSharedFiles from 'components/wall/parts/PartItemWallSharedFiles';
import { wallFormatDateExt, wallProcessMarkdownText, wallProcessText } from 'components/wall/index';
import IconMi from 'components/common/icon/IconMi';
import { wallAPI } from 'api/wall';
import { getInsightfulUsers, InsightfulUsersTooltip } from 'components/wall/Wall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export interface PartItemWallCommentProps {
    data: any;
    comment: any;
    wallData: any;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    updateWallData: (newData: any) => void;
    notifyOther: (type: string, text: any, itemData: any) => void;
    imageAntiCache?: string;
}

export default function PartItemWallComment(props: PartItemWallCommentProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        comment,
        followUser = () => {},
        insightful = () => {},
        notifyOther = () => {},
        wallData,
        updateWallData = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const fullid = `rule_${data.alert_rule_id}_${data.range_start_time}`;
    const defaultUserAvatar = '/system_user_icon.png'; //<?=Devx_Url::image('system_user_icon.png')?>

    const [insightfulUsersTooltipData, setInsightfulUsersTooltipData] = useState<any>(null);

    if (
        //$skip = true;
        !(
            comment.text.trim().length > 0 ||
            (comment.text.trim().length == 0 &&
                comment?.issue?.from &&
                (comment.issue.from.workflow_status_id != comment.issue.to.workflow_status_id ||
                    comment.issue.from.assignee_id != comment.issue.to.assignee_id ||
                    comment.issue.from.workflow_priority_id != comment.issue.to.workflow_priority_id ||
                    (comment.issue.from.is_acknowledged_ind != comment.issue.to.is_acknowledged_ind &&
                        comment.issue.to.is_acknowledged_ind == 'Y') ||
                    comment.issue.from.is_notifications_snoozed_ind != comment.issue.to.is_notifications_snoozed_ind ||
                    comment.issue.from.notifications_snoozed_at != comment.issue.to.notifications_snoozed_at))
        )
    )
        return null;

    const statusIconVal = comment?.issue?.from?.status_icon ?? '';
    const isHasIcon = ['new', 'progress', 'closed'].includes(statusIconVal);
    const getIcon = () => {
        switch (statusIconVal) {
            case 'new':
                return faCircle;
            case 'progress':
                return faArrowAltCircleRight;
            case 'closed':
                return faCircleCheck;
            default:
                return faCircle;
        }
    };

    const deleteComment = () => {
        if (!confirm(t('comment.delete_warn'))) return false;

        /*
        if ('undefined' != typeof this.parent.loadingMask) {
            this.parent.loadingMask.msg = trsl.tt('mask_deleting', 'Deleting...');
            this.parent.loadingMask.show();
        }
        */

        const reqData: any = {
            element: wallData.params.element_id,
            sv_id: wallData.params.segment_value_id,
            user_comment_id: comment.user_comment_id,
            user_note_id: data.user_note_id,
            user_annotation_id: data.user_annotation_id,
            alert_event_id: data.alert_event_id,
            notable_event_id: data.notable_event_id,
            full_event_id: fullid,
        };

        wallAPI
            .deleteComment(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    const newData: any = { ...{}, ...wallData.data };

                    if (reqData.user_note_id > 0)
                        delete newData.user_note[reqData.user_note_id].comments[reqData.user_comment_id];
                    else if (reqData.user_annotation_id > 0)
                        delete newData.user_annotation[reqData.user_annotation_id].comments[reqData.user_comment_id];
                    else if (reqData.full_event_id && typeof newData.alert_event[reqData.full_event_id] != 'undefined')
                        delete newData.alert_event[reqData.full_event_id].comments[reqData.user_comment_id];
                    else if (reqData.notable_event_id > 0)
                        delete newData.notable_event[reqData.notable_event_id].comments[reqData.user_comment_id];

                    updateWallData({ data: newData });
                    /*
                        if (typeof(dash)!='undefined')
                        dash.collaborative.WallAfterDelete();
                    */
                }
            })
            .catch(() => {});
    };

    return (
        <Box
            className="wall_comment_box_OLD"
            id={`wall_comment_${comment.user_comment_id} wall__item wall__item--comment`}
        >
            <Box className="wall__item-body">
                <Box className="wall__item-icon-block wall_comment_user_image_td">
                    <Box className="wall__item-icon-holder">
                        {comment.comment_user_id != 0 ? (
                            <a
                                className="wall_comment_user_image_a"
                                data-user-comment-id={
                                    comment.user_comment_id && comment.comment_user_id != params.user_id
                                        ? comment.user_comment_id
                                        : 0
                                }
                            >
                                <Box
                                    component={'img'}
                                    alt=""
                                    className={`wall_comment_user_image wall_user_image_${comment.comment_user_id}${
                                        comment.comment_user_follower_id > 0 ? ' wall_rounded_image_OLD' : ''
                                    }`}
                                    src={`/data/dashboard/wall/image?user=${comment.comment_user_id}&thumb=comment${
                                        imageAntiCache ? `&r=${imageAntiCache}` : ''
                                    }`}
                                    sx={{
                                        borderRadius: comment.comment_user_follower_id > 0 ? '15px' : 0,
                                        verticalAlign: 'top',
                                    }}
                                />
                            </a>
                        ) : (
                            <img alt="" className="wall_comment_user_image" src={defaultUserAvatar} />
                        )}
                    </Box>
                    <Box
                        className="wall__item-follow follow_td"
                        data-user-comment-id={
                            comment.user_comment_id && comment.comment_user_id != params.user_id
                                ? comment.user_comment_id
                                : 0
                        }
                    >
                        <Box id={`follow_td_div_${comment.user_comment_id}`} className="follow_td_div_OLD">
                            {comment.user_comment_id && comment.comment_user_id != params.user_id && (
                                <>
                                    <Box
                                        className="wall_post_follow_user_following"
                                        component="span"
                                        sx={{ display: comment.comment_user_follower_id <= 0 ? 'none' : '' }}
                                    >
                                        {t('comment.following_label')}
                                    </Box>

                                    <Tooltip
                                        title={t(
                                            `comment.${
                                                comment.comment_user_follower_id > 0
                                                    ? 'unfollow_tooltip'
                                                    : 'follow_tooltip'
                                            }`,
                                            { name: comment.comment_first_name ?? comment.username },
                                        )}
                                    >
                                        <Box
                                            component="a"
                                            href={'#'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                followUser({
                                                    user_follower_id: comment.comment_user_follower_id,
                                                    author_user_id: comment.comment_user_id,
                                                });
                                            }}
                                            className={`wall_user_${
                                                comment.comment_user_follower_id > 0 ? 'unfollow' : 'follow'
                                            } ${
                                                comment.comment_user_follower_id > 0
                                                    ? `wall_user_${comment.comment_user_follower_id}`
                                                    : ''
                                            }`}
                                            data-user_follower_id={
                                                comment.comment_user_follower_id > 0
                                                    ? comment.comment_user_follower_id
                                                    : 0
                                            }
                                            data-user_id={comment.comment_user_id}
                                        >
                                            {t(
                                                `comment.${
                                                    comment.comment_user_follower_id > 0
                                                        ? 'unfollow_link'
                                                        : 'follow_link'
                                                }`,
                                            )}
                                        </Box>
                                    </Tooltip>
                                    <Box
                                        className="wall_post_follow_user_first_name"
                                        sx={{ display: comment.comment_user_follower_id > 0 ? 'none' : '' }}
                                    >
                                        {comment.comment_first_name ?? comment.username}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className="wall__item-content-block wall_comment markdown">
                    <Box
                        component="b"
                        sx={{ mr: 0.75 }}
                        className="wall-comment-username"
                        dangerouslySetInnerHTML={{
                            __html: wallProcessText(comment.username, params),
                        }}
                    />
                    <Box component="span" className="wall__item-date date-format">
                        {wallFormatDateExt(comment.ts)}
                    </Box>

                    {comment?.issue?.from && (
                        <>
                            {comment.issue.from.workflow_status_id != comment.issue.to.workflow_status_id && (
                                <Box className="wall_comment_issue_OLD">
                                    {isHasIcon && (
                                        <Box
                                            component={FontAwesomeIcon}
                                            icon={getIcon()}
                                            className="issue-info-ico_OLD status-issue-ico"
                                        />
                                    )}
                                    {comment.issue.from.status_name}
                                    <IconMi icon={'arrow-right'} className="issue-info-ico_OLD" />
                                    <strong>{comment.issue.to.status_name}</strong>
                                </Box>
                            )}
                            {comment.issue.from.assignee_id != comment.issue.to.assignee_id && (
                                <Box className="wall_comment_issue_OLD">
                                    <IconMi icon={'user'} className="issue-info-ico_OLD" />
                                    {comment.issue.from.assignee}
                                    <IconMi icon={'arrow-right'} className="issue-info-ico_OLD" />
                                    <strong>{comment.issue.to.assignee}</strong>
                                </Box>
                            )}
                            {comment.issue.from.workflow_priority_id != comment.issue.to.workflow_priority_id && (
                                <Box className="wall_comment_issue_OLD">
                                    <IconMi icon={'issue-priority'} className="issue-info-ico_OLD" />
                                    {comment.issue.from.priority_name}
                                    <IconMi icon={'arrow-right'} className="issue-info-ico_OLD" />
                                    <strong>{comment.issue.to.priority_name}</strong>
                                </Box>
                            )}
                            {comment.issue.from.is_acknowledged_ind != comment.issue.to.is_acknowledged_ind &&
                                comment.issue.to.is_acknowledged_ind == 'Y' && (
                                    <Box className="wall_comment_issue_OLD">{t('comment.acknowledged')}</Box>
                                )}
                            {(comment.issue.from.is_notifications_snoozed_ind !=
                                comment.issue.to.is_notifications_snoozed_ind ||
                                comment.issue.from.notifications_snoozed_at !=
                                    comment.issue.to.notifications_snoozed_at) &&
                                comment.issue.to.is_notifications_snoozed_ind == 'Y' && (
                                    <Box className="wall_comment_issue_OLD">
                                        {t('comment.not_turned_off', {
                                            time: comment.issue.to.notifications_snoozed_time,
                                        })}
                                    </Box>
                                )}
                            {comment.issue.from.is_notifications_snoozed_ind !=
                                comment.issue.to.is_notifications_snoozed_ind &&
                                comment.issue.to.is_notifications_snoozed_ind == 'N' && (
                                    <Box className="wall_comment_issue_OLD">
                                        {t('comment.not_turned_on', {
                                            time: comment.issue.to.notifications_snoozed_at,
                                        })}
                                    </Box>
                                )}
                        </>
                    )}
                    <Box
                        className="wall__item-text markdown-holder"
                        dangerouslySetInnerHTML={{
                            __html: wallProcessMarkdownText(comment.text, params),
                        }}
                    />
                    <Box className="wall__item-content-footer wall_comment_footer_OLD">
                        {!comment.issue && (
                            <Box className="wall__item-content-footer-left">
                                <Tooltip title={t('comment.insightful_link')}>
                                    <Box
                                        component="span"
                                        className="wall_insightful_comment wall__item-content-footer-link"
                                        data-user_comment_id={comment.user_comment_id}
                                        onClick={() => {
                                            insightful('comment', comment.user_comment_id);
                                        }}
                                    >
                                        <IconMi icon="light" fontSize="16" />
                                        {t('comment.insightful_link')}
                                    </Box>
                                </Tooltip>
                                {comment.insightful_count > 0 && (
                                    <Tooltip
                                        title={insightfulUsersTooltipData}
                                        disableInteractive
                                        enterDelay={500}
                                        onOpen={async () => {
                                            const users = await getInsightfulUsers({
                                                user_comment_id: comment.user_comment_id,
                                            });
                                            if (users) {
                                                setInsightfulUsersTooltipData(<InsightfulUsersTooltip users={users} />);
                                            }
                                        }}
                                        onClose={() => setInsightfulUsersTooltipData(null)}
                                    >
                                        <Box
                                            component="span"
                                            sx={{ ml: -0.75, mr: 1, fontSize: '11px' }}
                                            className="wall_insightful_users"
                                            data-user_comment_id={comment.user_comment_id}
                                        >
                                            ({comment.insightful_count})
                                        </Box>
                                    </Tooltip>
                                )}
                                <Tooltip title={t('comment.share_link')}>
                                    <Box
                                        component="span"
                                        className="wall_comment_notify_other wall__item-content-footer-link"
                                        data-user_comment_id={comment.user_comment_id}
                                        data-user_note_id={data.user_note_id}
                                        data-user_annotation_id={data.user_annotation_id}
                                        data-alert_event_id={data.alert_event_id}
                                        data-notable_event_id={data.notable_event_id}
                                        onClick={() => {
                                            notifyOther('comment', wallProcessMarkdownText(comment.text, params), {
                                                ...data,
                                                user_comment_id: comment.user_comment_id,
                                            });
                                        }}
                                    >
                                        <IconMi icon="share" fontSize="16" />
                                        {t('comment.share_link')}
                                    </Box>
                                </Tooltip>
                                {!comment.issue &&
                                    (wallData.settings.privDeleteCollaborativeItems ||
                                        comment.comment_user_id == params.user_id) && (
                                        <Tooltip title={t('comment.delete_icon')} disableInteractive>
                                            <IconButton
                                                sx={{ p: '0', color: 'inherit' }}
                                                className="wall__item-content-footer-icon-button wall_delete_comment"
                                                data-user_comment_id={comment.user_comment_id}
                                                data-user_note_id={data.user_note_id}
                                                data-user_annotation_id={data.user_annotation_id}
                                                data-alert_event_id={data.alert_event_id}
                                                data-notable_event_id={data.notable_event_id}
                                                data-full_event_id={fullid}
                                                onClick={deleteComment}
                                            >
                                                <IconMi icon="delete" fontSize="16" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </Box>
                        )}
                    </Box>
                    <PartItemWallSharedFiles data={comment} elementType={'comment'} />
                </Box>
            </Box>
            <Box component="span" className="clear" />
        </Box>
    );
}
