import { ReportContentNS } from 'components/report-content/index';
import Dataset from 'components/report-content/components/dataset/Dataset';
import DatasetEditPanel from 'components/report-content/components/dataset/DatasetEditPanel';
import React from 'react';
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ResetDatasetColumnsSize from 'components/report-content/components/dataset/controls/ResetDatasetColumnsSize';

export default class DatasetComponentBuilder extends ReportContentNS.AbstractComponentBuilder<ComponentSettingsDataset> {
    getComponent(editPanel?: any) {
        return (
            <Dataset
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
                blockId={this.block.uid}
                editPanel={editPanel}
            />
        );
    }

    getComponentControls() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <ResetDatasetColumnsSize
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }

    getEditPanel() {
        if (this.contentSettings.context == 'view') {
            return null;
        }
        return (
            <DatasetEditPanel
                block={this.block}
                updateBlockSettings={this.updateBlockSettings}
                contentSettings={this.contentSettings}
                component={this.block.component}
                actions={this.actions}
            />
        );
    }
}
