import useBundleTranslation from 'i18n';
import React, { useEffect, useState } from 'react';
import { addToStateArray, removeFromStateArray, replaceInStateArray, uid } from 'tools/tools';
import { Box, Button, IconButton, Input, Stack } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import IconMi from 'components/common/icon/IconMi';
import { DatasetFieldValueType, ReportContentNS } from 'components/report-content';
import SimpleFormattingRule = ReportContentNS.SimpleFormattingRule;
import FormattingRuleConditions = ReportContentNS.FormattingRuleConditions;

type SimpleFormattingRuleWithId = SimpleFormattingRule & { uid: string };
const inputNumRegExp = /^-?[0-9]*\.?[0-9]*$/;
export default function SimpleFormattingRulesList({
    rules,
    onUpdate,
    fieldValueType,
}: {
    rules: Array<SimpleFormattingRule>;
    onUpdate: (newRules: Array<SimpleFormattingRule>) => void;
    fieldValueType: DatasetFieldValueType;
}) {
    const { t } = useBundleTranslation(['components/report-content']);

    const [rulesList, setRulesList] = useState<Array<SimpleFormattingRuleWithId>>(
        rules.map((r) => ({ ...r, uid: uid() })),
    );

    const handleAddRule = () => {
        addToStateArray(setRulesList, {
            data: '',
            condition: fieldValueType == 'numeric' ? '>' : '=',
            uid: uid(),
        });
    };

    useEffect(() => {
        onUpdate(
            rulesList.map((r) => {
                const result = { ...r };
                //@ts-ignore
                delete result.uid;
                return result;
            }),
        );
    }, [rulesList]);

    return (
        <Box>
            {rulesList.map((rule) => {
                return (
                    <Stack key={rule.uid} direction={'row'} alignItems={'center'} spacing={1}>
                        <Box sx={{ width: '180px', flexShrink: 0 }}>
                            <ReactSelect
                                data={FormattingRuleConditions.filter((f) =>
                                    fieldValueType == 'numeric' ? f.value_type == 'numeric' : f.value_type == 'text',
                                ).map((f) => ({
                                    label: t(f.name),
                                    value: f.key,
                                }))}
                                value={rule.condition}
                                update={(value) => {
                                    replaceInStateArray(setRulesList, (r) => r.uid == rule.uid, {
                                        ...rule,
                                        condition: value,
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Input
                                value={rule.data}
                                placeholder={fieldValueType == 'numeric' ? '0' : ''}
                                onChange={(e) => {
                                    if (
                                        fieldValueType != 'numeric' ||
                                        e.target.value === '' ||
                                        inputNumRegExp.test(e.target.value)
                                    ) {
                                        replaceInStateArray(setRulesList, (r) => r.uid == rule.uid, {
                                            ...rule,
                                            data: e.target.value,
                                        });
                                    }
                                }}
                                fullWidth
                            />
                        </Box>
                        {rule.condition == 'between' && (
                            <>
                                <Box>and</Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Input
                                        value={rule.data_second}
                                        placeholder={'0'}
                                        onChange={(e) => {
                                            if (e.target.value === '' || inputNumRegExp.test(e.target.value)) {
                                                replaceInStateArray(setRulesList, (r) => r.uid == rule.uid, {
                                                    ...rule,
                                                    data_second: e.target.value,
                                                } as SimpleFormattingRuleWithId);
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </>
                        )}
                        <Box>
                            <FieldTextFormattingLine
                                field={rule}
                                onChange={(fieldFormatting) => {
                                    const newRule: SimpleFormattingRuleWithId = {
                                        ...rule,
                                        bgColor: fieldFormatting.bgColor,
                                        textColor: fieldFormatting.textColor,
                                        textBold: fieldFormatting.textBold,
                                        textItalic: fieldFormatting.textItalic,
                                    };
                                    if (JSON.stringify(rule) != JSON.stringify(newRule)) {
                                        replaceInStateArray(setRulesList, (r) => r.uid == rule.uid, newRule);
                                    }
                                }}
                            />
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    removeFromStateArray(setRulesList, (r) => r.uid == rule.uid);
                                }}
                            >
                                <IconMi icon="times" fontSize="16" />
                            </IconButton>
                        </Box>
                    </Stack>
                );
            })}
            <Box sx={{ mt: 2 }}>
                <Button onClick={() => handleAddRule()} variant={'light'} startIcon={<IconMi icon={'new'} />}>
                    {t('formatting_rule')}
                </Button>
            </Box>
        </Box>
    );
}
