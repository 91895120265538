import useBundleTranslation from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
    FilterType,
    useApplyFilterValueMap,
    getFiltersStateBuilder,
    getFiltersActiveValuesWithData,
} from 'components/external-reference/Filter';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import { applyBookmarkToFilters, getBookmarkReferenceId } from 'components/external-reference/bookmark/Bookmark';
import ReferenceManager from 'components/external-reference/ReferenceManager';
import { ElementViewerPropsType, prepareSegments } from 'components/element-viewer';
import { Box, Stack, Button, alpha, Theme } from '@mui/material';
import { viewerAPI } from 'api/viewer';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import SiblingTabsMenu, { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import { viewerExternalReportAPI } from 'api/viewer/external-report';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import IconMi from 'components/common/icon/IconMi';
import EmbedElement from 'components/common/external/EmbedElement';
import useBookmarks, { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import ExpertCommentaryWall from 'components/wall/ExpertCommentaryWall';
import ViewerWall from 'components/wall/ViewerWall';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import usePageTitleCrumbs from 'components/element-viewer/hooks/usePageTitleCrumbs';
import useWallContext, { WallContext } from 'components/wall/hooks/useWallContext';
import { externalReportAPI } from 'api/external-report';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import HeadingElementData from 'components/element-viewer/HeadingElementData';
import { useIsAdminOrHasPrivilege, useIsUserHasPrivilege } from 'hooks/useUserPrivilege';

interface ExternalReportElementType {
    drillThroughLinkName: string;
    drillThroughLinkOverrideInd: boolean;
    drillThroughLinkDisplayInd: boolean;
    dataSourceName: string;
    useNativeFiltersInd: boolean;
}

export function useGetSegmentValueIdFromFilters(filters: Array<FilterType>) {
    let segmentValueId = 0;
    filters.forEach((filter) => {
        if (!filter.isSegment) {
            return;
        }
        segmentValueId = Number(filter.selectedValues.join());
    });
    return segmentValueId;
}

interface TabsDataType {
    siblingTabs: Array<SiblingTabType>;
    selectedReferenceId: number;
    menuIsOpen: boolean;
}

export interface ExternalReportViewerDataType {
    additionalData: ExternalReportElementType;
    filtersData: any;
    tabsData: TabsDataType;
    tableauCrossSite: {
        enabled: boolean;
        pcpParams: {
            site: string;
            server: string;
        };
    };
}

export function prepareFiltersForSubmit(
    filtersJSON: string,
    referenceId?: number,
): { filters: string; reference?: number } {
    const data: any = {
        filters: '{}',
    };
    let filters = '';
    try {
        filters = JSON.parse(filtersJSON);
    } catch (e) {
        return data;
    }

    let resultFilters: any = {};
    for (const [key, value] of Object.entries(filters)) {
        if (Number(key) > 0) {
            resultFilters[key] = value;
        }
    }
    data.filters = JSON.stringify(resultFilters);

    if (referenceId) {
        data.reference = referenceId;
    }
    return data;
}

export default function ExternalReportViewer({
    elementInfo,
    viewerRequestData,
    viewerRequestExtraData,
    onSegmentValueChange,
    segmentValueId,
    filters,
    onFiltersChange,
    onFavoritesChange,
    alertStatus,
    embeddingType,
}: ElementViewerPropsType<ExternalReportViewerDataType>) {
    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const userId = userAuth?.userInfo?.user_id ?? 0;

    const urlParams = new URLSearchParams(window.location.search);
    const width = urlParams.get('width') ?? 0;

    const { t } = useBundleTranslation();
    const elementId = elementInfo.row.elementId;
    // Dashboard Element Info
    const [additionalInfo, setAdditionalInfo] = useState<ExternalReportElementType>();

    const [hasFilters, setHasFilters] = useState<Boolean>(false);

    // Sibling Tabs
    const [siblingTabs, setSiblingTabs] = useState<Array<SiblingTabType>>(viewerRequestExtraData.tabsData.siblingTabs);
    useEffect(() => {
        setSiblingTabs(viewerRequestExtraData.tabsData.siblingTabs);
    }, [JSON.stringify(viewerRequestExtraData.tabsData.siblingTabs)]);

    const [selectedReferenceId, setSelectedReferenceId] = useState<number>(
        viewerRequestExtraData.tabsData.selectedReferenceId,
    );
    const selectedReferenceIdRef = useRef(selectedReferenceId);
    useEffect(() => {
        selectedReferenceIdRef.current = selectedReferenceId;
    }, [selectedReferenceId]);

    const [tabsMenuIsOpen, setTabsMenuIsOpen] = useState<boolean>(viewerRequestExtraData.tabsData.menuIsOpen);

    // URL for iframe/image
    const [embedUrl, setEmbedUrl] = useState('');
    const [clearEmbedUrl, setClearEmbedUrl] = useState('');

    // Full Screen
    const fullScreenHandle = useFullScreenHandle();
    const handleKeyDown = function (event: any) {
        // F11
        if (event.which == 122) {
            // Suppress regular browser behavior
            event.preventDefault();
            fullScreenHandle.active ? fullScreenHandle.exit() : fullScreenHandle.enter();
        }
    };
    // Bind F11 for FullScreen mode
    useEffect(function () {
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Apply | Reset Buttons State
    const [lastAppliedState, setLastAppliedState] = useState('');
    const [currentFiltersState, setCurrentFiltersState] = useState('');
    const [defaultFiltersState, setDefaultFiltersState] = useState('');
    useEffect(() => {
        setCurrentFiltersState(JSON.stringify(getFiltersStateBuilder(filters)));
    }, [filters]);

    // Apply Bookmark filters
    const handleApplyBookmark = function (bookmarkId: number) {
        const bookmark = bookmarks[findBookmarkIndexById(bookmarkId)];
        if (!bookmark) {
            return;
        }

        const updatedFilters = useApplyFilterValueMap(applyBookmarkToFilters(filters, bookmark));
        onFiltersChange(updatedFilters);
        setAppliedBookmarkId(bookmarkId);

        const referenceId = getBookmarkReferenceId(bookmark);
        if (referenceId != selectedReferenceId) {
            setSelectedReferenceId(referenceId);
        }
        setTimeout(() => handleApplyFilterChanges(updatedFilters));
    };

    const {
        bookmarks,
        setBookmarks,
        filtersData,
        appliedBookmarkId,
        setAppliedBookmarkId,
        APIUpdateBookmark,
        APISaveAsNewBookmark,
        APIDeleteBookmark,
        APIDuplicateBookmark,
        APISortBookmarks,
        APIIsLoading,
    } = useBookmarks(viewerRequestExtraData, viewerRequestData, filters, siblingTabs, handleApplyBookmark);

    // Filter hidden
    const isVisibleFilters = useEmbeddingContextVisible('eFilters');
    const isVisibleSegment = useEmbeddingContextVisible('segment');
    // Process initial data
    useEffect(() => {
        if (!filtersData) {
            return;
        }

        setEmbedUrl(filtersData.embedUrl.url);
        setClearEmbedUrl(filtersData.embedUrl.clearUrl);

        let { filtersList } = prepareSegments(filtersData.filtersList, viewerRequestData.segmentData);

        const defaultBookmark = bookmarks.find(
            (bookmark: BookmarkType) => bookmark.id == filtersData.selectedBookmarkId,
        );
        if (defaultBookmark) {
            const referenceId = getBookmarkReferenceId(defaultBookmark);
            if (referenceId != selectedReferenceId) {
                setSelectedReferenceId(referenceId);
            }
            filtersList = applyBookmarkToFilters(filtersList, defaultBookmark);
            const newSegmentValueId = useGetSegmentValueIdFromFilters(filtersList);
            onSegmentValueChange(newSegmentValueId);
        }

        const defaultFilters = useApplyFilterValueMap(filtersList.slice());
        const visibleFilters = filtersList
            .filter((f) => f.visibleInViewer && !f.replacedBySegment)
            .filter((f) => (f.filterId > 0 ? isVisibleFilters : isVisibleSegment));
        setHasFilters(Boolean(visibleFilters.length));
        onFiltersChange(defaultFilters);
        setAppliedBookmarkId(filtersData.selectedBookmarkId);
        setAdditionalInfo(viewerRequestExtraData.additionalData);
        setLastAppliedState(JSON.stringify(getFiltersStateBuilder(defaultFilters)));
        setDefaultFiltersState(JSON.stringify(getFiltersStateBuilder(filters, true)));
    }, [filtersData]);

    const [isRequestRunning, setIsRequestRunning] = useState<boolean>(false);
    // Request for new embedUrl
    const getEmbedURL = function (actualFiltersState: any, segmentValueId: number) {
        const actualState = JSON.stringify(actualFiltersState);
        setLastAppliedState(actualState);
        setIsRequestRunning(true);
        viewerAPI
            .buildExternalUrl(
                elementId,
                segmentValueId,
                prepareFiltersForSubmit(actualState, selectedReferenceIdRef.current),
            )
            .then((response) => {
                if (response.data.status === 'OK') {
                    setEmbedUrl(response.data.data.url);
                    setClearEmbedUrl(response.data.data.clearUrl);
                }
            })
            .finally(() => setIsRequestRunning(false));
    };

    const handleFilterChange = function (filterId: number, newValue: any) {
        const list = filters.slice();
        // Convert string|number to array
        if (!Array.isArray(newValue)) {
            newValue = newValue == '' ? [] : [newValue];
        }
        list.find((f) => f.filterId == filterId)!.selectedValues = newValue;
        onFiltersChange(useApplyFilterValueMap(list));
    };

    const handleApplyFilterChanges = function (filters: Array<FilterType>) {
        // Update BrowserURL on segment Filter Change
        const newSegmentValueId = useGetSegmentValueIdFromFilters(filters);
        onSegmentValueChange(newSegmentValueId);

        // onApplyFiltersChange();
        externalReportAPI.bookmark.saveFilters(elementId, newSegmentValueId, getFiltersActiveValuesWithData(filters));
        getEmbedURL(getFiltersStateBuilder(filters), newSegmentValueId);
    };

    const handleRevertFilterChanges = function () {
        const list = useApplyFilterValueMap(
            filters.map((filter) => {
                filter.selectedValues = filter.defaultValues.slice();
                return filter;
            }),
        );

        onFiltersChange(list);
        handleApplyFilterChanges(list);
    };

    const handleBookmarksListUpdate = function (bookmarks: Array<BookmarkType>) {
        setBookmarks(bookmarks.slice());
    };

    //Bookmarks section
    const findBookmarkIndexById = function (bookmarkId: number): number {
        return bookmarks.findIndex((bookmark) => bookmark.id == bookmarkId);
    };

    const [isExpandedFilters, setIsExpandedFilters] = useState<boolean>(false);
    const toggleExpandedState = () => {
        setIsExpandedFilters((prevState) => !prevState);
    };

    const bookmarksContext: IBookmarksContext = {
        bookmarks: bookmarks,
        filters: filters,
        siblingTabs: siblingTabs,
        selectedReferenceId: selectedReferenceId,
        APIUpdateBookmark: APIUpdateBookmark,
        APISaveAsNewBookmark: APISaveAsNewBookmark,
        APIDeleteBookmark: APIDeleteBookmark,
        APIDuplicateBookmark: APIDuplicateBookmark,
        APISortBookmarks: APISortBookmarks,
        APIIsLoading: APIIsLoading,
    };

    const referenceManagerProps = {
        ...bookmarksContext,
        elementId: elementId,
        segmentValueId: segmentValueId,
        appliedBookmarkId: appliedBookmarkId,
        isFiltersChanged: lastAppliedState != currentFiltersState,
        isFiltersReversible: defaultFiltersState != currentFiltersState,
        fullScreenFadeOut: elementInfo?.row.externalReportFullScreenMouseStationaryInd,
        onFilterChange: handleFilterChange,
        onApplyFilterChanges: handleApplyFilterChanges,
        onRevertFilterChanges: handleRevertFilterChanges,
        onApplyBookmark: handleApplyBookmark,
        onBookmarksListUpdate: handleBookmarksListUpdate,
        isExpandedFilters: isExpandedFilters,
        toggleExpandedState: toggleExpandedState,
    };

    usePageTitleCrumbs(elementInfo);

    const handleTabChange = (referenceId: number) => {
        if (referenceId == selectedReferenceId) {
            return;
        }
        setSelectedReferenceId(referenceId);
        setTimeout(() => {
            handleApplyFilterChanges(filters);
        });
    };

    const handleTabMenuToggle = () => {
        setTabsMenuIsOpen((prevState) => {
            const newState = !prevState;
            viewerExternalReportAPI.setSiblingTabsState(newState);
            return newState;
        });
    };

    const { wallActions, setWallActions, addOnUpdateCallback, triggerOnUpdate } = useWallContext(true);

    const [editAnnotationId, setEditAnnotationId] = useState<number>(0);
    const [editEventId, setEditEventId] = useState<number>(0);

    const visibleInViewerFilters = referenceManagerProps.filters.filter(
        (f) => f.visibleInViewer && !f.replacedBySegment,
    ).length;

    useEffect(() => {
        const onVisibilityChange = () => {
            if (typeof window.localStorage == 'undefined' || document.hidden) {
                return;
            }
            const tmp = window.localStorage.getItem('lastPcpParams');
            if (tmp) {
                const prevPcpParams = JSON.parse(tmp);
                if (
                    prevPcpParams &&
                    prevPcpParams.server == viewerRequestExtraData.tableauCrossSite.pcpParams.server &&
                    prevPcpParams.site != viewerRequestExtraData.tableauCrossSite.pcpParams.site
                ) {
                    window.location.reload();
                }
            }
            window.localStorage.setItem(
                'lastPcpParams',
                JSON.stringify(viewerRequestExtraData.tableauCrossSite.pcpParams),
            );
        };
        document.addEventListener('visibilitychange', onVisibilityChange);
        return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    }, []);

    const hasWall = useEmbeddingContextVisible('wall');

    const embedElement = (
        <EmbedElement
            fullScreen={fullScreenHandle.active}
            embedUrl={embedUrl}
            displayType={elementInfo.row.externalContentDisplayInViewer}
            iframeHeight={
                elementInfo.row.calcIframeHeightOnImageAspectRatioInd || fullScreenHandle.active
                    ? 0
                    : elementInfo.row.externalReportIframeHeight
            }
        />
    );

    const canAddExpAnnotationOwner = useIsUserHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY_ONLY_IF_OWNER');
    const isOwner =
        userId > 0 &&
        ['businessOwnerId', 'dataStewardId', 'technicalOwnerId'].some(
            (type) => (elementInfo as any).row[type] == userId,
        );
    const canAddExpAnnotation = useIsAdminOrHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY');
    const expertCommentaryAllowEdit = (isOwner && canAddExpAnnotationOwner) || canAddExpAnnotation;

    if (embeddingType == 'short') {
        return embedElement;
    }

    return (
        <>
            {isRequestRunning && <LoadingPlaceholder />}
            {APIIsLoading && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        zIndex: 90000,
                    }}
                />
            )}
            <WallContext.Provider
                value={{
                    wallActions: wallActions,
                    setWallActions: (actions) => setWallActions(actions),
                    addOnUpdateCallback: addOnUpdateCallback,
                    triggerOnUpdate: triggerOnUpdate,
                    editAnnotationId: editAnnotationId,
                    setEditAnnotationId: setEditAnnotationId,
                    editEventId: editEventId,
                    setEditEventId: setEditEventId,
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        gridTemplateAreas: '"topLeft topRight" "bottomSecond bottomSecond"',
                        px: 2,
                        py: 1,
                        borderBottom: '1px solid',
                        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                        backgroundColor: 'background.default',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{ gridArea: 'topRight' }}>
                        <ControlsPanel
                            filters={prepareFiltersForSubmit(lastAppliedState).filters}
                            viewerRequestExtraData={viewerRequestExtraData}
                            externalLink={
                                additionalInfo?.drillThroughLinkName.length && additionalInfo.drillThroughLinkDisplayInd
                                    ? clearEmbedUrl
                                    : undefined
                            }
                            externalLinkTitle={
                                additionalInfo?.drillThroughLinkOverrideInd
                                    ? additionalInfo?.drillThroughLinkName
                                    : undefined
                            }
                            fullScreenHandle={fullScreenHandle}
                            element={elementInfo}
                            segmentValueId={segmentValueId}
                            onFavoritesChange={onFavoritesChange}
                            bookmarksContext={bookmarksContext}
                            alertStatus={alertStatus}
                        />
                    </Box>
                    <Box
                        sx={{
                            ...{
                                pb: isExpandedFilters ? 2 : 0,
                                width: '100%',
                                flexGrow: 1,
                                gridArea: isExpandedFilters ? 'bottomSecond' : 'topLeft',
                                minWidth: 0,
                                display: !isExpandedFilters ? 'flex' : undefined,
                                flexWrap: !isExpandedFilters ? 'wrap' : undefined,
                            },
                            ...(!isExpandedFilters
                                ? {
                                      '.heading-element-data': {
                                          position: 'relative',
                                          zIndex: 1,
                                          overflow: 'hidden',
                                          width: '100%',
                                      },
                                      '.heading-element-data > *:last-of-type': {
                                          mb: hasFilters || siblingTabs.length > 1 ? 1.5 : undefined,
                                      },
                                      '.heading-element-data--only-certification': {
                                          pr: 1,
                                          width: 'auto',
                                      },
                                  }
                                : {}),
                        }}
                    >
                        {!isExpandedFilters && <HeadingElementData element={elementInfo} />}
                        {(hasFilters || siblingTabs.length > 1) && <ReferenceManager {...referenceManagerProps} />}
                    </Box>
                    {isExpandedFilters && (
                        <Box
                            sx={{
                                gridArea: 'topLeft',
                                overflow: 'hidden',
                                '.heading-element-data > *:last-of-type': {
                                    mb: hasFilters || siblingTabs.length > 1 ? 1.5 : undefined,
                                },
                            }}
                        >
                            <HeadingElementData element={elementInfo} />
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        pt: 2,
                        pb: 5,
                        overflow: 'auto',
                        px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                    }}
                    className={'scroll-content-container'}
                >
                    <FullScreen handle={fullScreenHandle}>
                        <Box
                            sx={{
                                margin: '0 auto',
                                width: width ? width + 'px' : '100%',
                                height: '100%',
                                position: 'relative',
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.4),
                                backgroundColor: 'background.default',
                            }}
                        >
                            {fullScreenHandle.active && (
                                <Button
                                    sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 1 }}
                                    variant="light"
                                    onClick={fullScreenHandle.exit}
                                    className={'min-width--icon'}
                                >
                                    <IconMi icon="times" />
                                </Button>
                            )}
                            <Stack direction="row" sx={{ height: '100%' }}>
                                <SiblingTabsMenu
                                    tabs={siblingTabs}
                                    selectedReferenceId={selectedReferenceId}
                                    onTabSelect={handleTabChange}
                                    onMenuToggle={handleTabMenuToggle}
                                    isOpen={tabsMenuIsOpen}
                                />
                                {embedElement}
                            </Stack>

                            {hasFilters && fullScreenHandle.active && visibleInViewerFilters > 0 && (
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        left: 0,
                                        right: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top:
                                            elementInfo?.row.externalReportFullScreenMenuOnTop == 'Y' ? '0' : undefined,
                                        bottom:
                                            elementInfo?.row.externalReportFullScreenMenuOnTop != 'Y' ? '0' : undefined,
                                    }}
                                >
                                    <ReferenceManager
                                        fullscreenMode
                                        fullscreenModePanelPos={
                                            elementInfo?.row.externalReportFullScreenMenuOnTop == 'Y' ? 'top' : 'bottom'
                                        }
                                        {...referenceManagerProps}
                                    />
                                </Box>
                            )}
                        </Box>
                    </FullScreen>
                    {elementInfo.row.showCollaborationInd && hasWall && (
                        <Box sx={{ pt: 2, mx: 'auto', width: '552px' }}>
                            <Box sx={{ pb: 2 }}>
                                <ExpertCommentaryWall
                                    data={{}}
                                    elementId={elementId}
                                    segmentValueId={segmentValueId}
                                    userId={userId}
                                    elementType={'external report'}
                                    allowEdit={expertCommentaryAllowEdit}
                                />
                            </Box>
                            <ViewerWall
                                wallType="chart"
                                data={{}}
                                elementId={elementId}
                                segmentValueId={segmentValueId}
                                elementType={'external report'}
                                userId={userId}
                            />
                        </Box>
                    )}
                </Box>
            </WallContext.Provider>
        </>
    );
}
