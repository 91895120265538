import React, { useEffect, useState } from 'react';
import { ElementViewerPropsType } from 'components/element-viewer';
import { Box, Stack } from '@mui/material';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import { ReportContentNS } from 'components/report-content';
import ViewerData = ReportContentNS.ViewerData;
import ReportContent from 'components/report-content/ReportContent';
import ComponentSettings = ReportContentNS.ComponentSettings;
import BlockType = ReportContentNS.BlockType;
import { buildManageFiltersBlock, prepareDatasetFieldEscapedName, ReportContentActions } from 'app/editor/report';
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import DatasetField = ReportContentNS.DatasetField;
import BlocksContextProvider from 'components/report-content/BlocksContextProvider';
import useReportContentSettings from 'components/report-content/hooks/useReportContentSettings';
import TopControlsWrapper from 'components/element-viewer/common/TopControlsWrapper';
import ExpertCommentaryWall from 'components/wall/ExpertCommentaryWall';
import ViewerWall from 'components/wall/ViewerWall';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import usePageTitleCrumbs from 'components/element-viewer/hooks/usePageTitleCrumbs';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import HeadingElementData from 'components/element-viewer/HeadingElementData';
import DisplayMask = ReportContentNS.DisplayMask;
import { useIsAdminOrHasPrivilege, useIsUserHasPrivilege } from 'hooks/useUserPrivilege';

export interface ReportViewerDataType extends ViewerData {
    displayMasks: Array<DisplayMask>;
}

export default function ReportViewer({
    elementInfo,
    viewerRequestExtraData,
    segmentValueId,
    onFavoritesChange,
    alertStatus,
    related,
    embeddingType,
}: ElementViewerPropsType<ReportViewerDataType>) {
    const elementId = elementInfo.row.elementId;

    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const userId = userAuth?.userInfo?.user_id ?? 0;

    const [datasetFields] = useState<Array<DatasetField>>(
        prepareDatasetFieldEscapedName(viewerRequestExtraData.datasetFields),
    );

    const updateBlockSettings = (blockUID: string, newSettings: ComponentSettings) => {
        const list = rendererBlocks.slice();
        const blockIndex = rendererBlocks.findIndex((b) => b.uid == blockUID);
        if (blockIndex != -1) {
            // Update Settings
            list[blockIndex].component.settings = { ...newSettings };
            setRendererBlocks(list);
        } else {
            if (blockUID == 'manage_filters') {
                const newManageFiltersBlock: BlockType<ComponentSettingsManageFilters> | undefined =
                    structuredClone(manageFiltersBlock);
                if (undefined !== newManageFiltersBlock)
                    newManageFiltersBlock.component.settings = { ...(newSettings as ComponentSettingsManageFilters) };
                setManageFiltersBlock(newManageFiltersBlock);
            }
        }
    };

    const actions: ReportContentActions = {
        openBlockEditor: (blockUID: string, editPanel: any) => {},
        removeBlock: (blockUID: string) => {},
        sortBlocks: (sourceIndex: number, destinationIndex: number) => {},
        updateMeasurementTime: (measurementTime: string) => {
            setSettings((prevState) => ({ ...prevState, measurement_time: measurementTime }));
        },
        updateSegmentValue(segmentValueId: string) {
            setSettings((prevState) => ({
                ...prevState,
                segmentValueId: Number(segmentValueId),
            }));
        },
        updateBlockSettings: updateBlockSettings,
        setBlockPreviewInd: (blockUID?: string) => {},
        getBlocks: () => rendererBlocks,
    };

    const { settings, setSettings, instancesData } = useReportContentSettings(
        'view',
        elementId,
        segmentValueId,
        viewerRequestExtraData.displayMasks,
        '',
        elementInfo.row.segmentId,
        false,
        related,
    );

    const [rendererBlocks, setRendererBlocks] = useState<Array<BlockType<ComponentSettings>>>(
        viewerRequestExtraData.rendererBlocks,
    );

    const [allBlocksEmpty, setAllBlocksEmpty] = useState(false);
    useEffect(() => {
        const withDataIndex = rendererBlocks.findIndex((block) => !block.component.settings.hasNoData);
        setAllBlocksEmpty(withDataIndex == -1);
    }, [JSON.stringify(rendererBlocks)]);

    const [manageFiltersBlock, setManageFiltersBlock] = useState<BlockType<ComponentSettingsManageFilters>>();
    useEffect(() => {
        // if (!viewerRequestExtraData.rendererFilters) {
        //     return;
        // }
        setManageFiltersBlock(buildManageFiltersBlock(viewerRequestExtraData.rendererFilters, datasetFields));
    }, []);

    const canAddExpAnnotationOwner = useIsUserHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY_ONLY_IF_OWNER');
    const isOwner =
        userId > 0 &&
        ['businessOwnerId', 'dataStewardId', 'technicalOwnerId'].some(
            (type) => (elementInfo as any).row[type] == userId,
        );
    const canAddExpAnnotation = useIsAdminOrHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY');
    const expertCommentaryAllowEdit = (isOwner && canAddExpAnnotationOwner) || canAddExpAnnotation;

    usePageTitleCrumbs(elementInfo);
    const hasWall = useEmbeddingContextVisible('wall');
    if (!instancesData) {
        return <div>loading</div>;
    }

    const content = (
        <>
            <BlocksContextProvider manageFiltersSettings={manageFiltersBlock?.component.settings ?? null}>
                <ReportContent
                    manageFiltersBlock={manageFiltersBlock}
                    onAddNewBlock={() => {}}
                    contentSettings={settings}
                    blocks={rendererBlocks}
                    actions={actions}
                    datasetFields={datasetFields}
                />
            </BlocksContextProvider>
            {allBlocksEmpty && (
                <Box sx={{ pt: '30px', mx: 'auto', width: '552px', textAlign: 'center' }}>
                    <h2>{elementInfo.row.reportGenerateEmptyInstanceMessage}</h2>
                </Box>
            )}
        </>
    );

    if (embeddingType == 'short') {
        return content;
    }

    return (
        <>
            <TopControlsWrapper>
                <HeadingElementData element={elementInfo} />
                <ControlsPanel
                    element={elementInfo}
                    segmentValueId={segmentValueId}
                    onFavoritesChange={onFavoritesChange}
                    alertStatus={alertStatus}
                />
            </TopControlsWrapper>
            <Box
                sx={{
                    pt: 2,
                    pb: 5,
                    overflow: 'auto',
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                }}
                className={'scroll-content-container'}
            >
                {content}
                {hasWall && (
                    <Box sx={{ pt: 2, mx: 'auto', width: '552px' }}>
                        <Box sx={{ pb: 2 }}>
                            <ExpertCommentaryWall
                                data={{}}
                                elementId={elementId}
                                segmentValueId={segmentValueId}
                                userId={userId}
                                elementType={'internal report'}
                                allowEdit={expertCommentaryAllowEdit}
                            />
                        </Box>
                        <ViewerWall
                            wallType="chart"
                            data={{}}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            elementType={'internal report'}
                            userId={userId}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
}
