import { FieldConditionRuleNS } from './index';
import Field = FieldConditionRuleNS.Field;
import Conditions = FieldConditionRuleNS.Conditions;
import ReactSelect from 'components/common/react-select/ReactSelect';
import React, { useEffect, useState } from 'react';
import { FormDataAPIType } from '../form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import SecondFieldConstValue = FieldConditionRuleNS.SecondFieldConstValue;
import LegacyRuleFieldSuffix = FieldConditionRuleNS.LegacyRuleFieldSuffix;
import CompareConditionType = FieldConditionRuleNS.CompareConditionType;
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ConditionFormatting } from 'components/report-content/components/dataset/edit-panel/condition-formatting';
import CompareConditions = FieldConditionRuleNS.CompareConditions;
import Rule = FieldConditionRuleNS.Rule;
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import { ReportContentNS } from 'components/report-content';
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import { Box, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';

function getCalendarData(data: string): string {
    if (!data.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) {
        const now = new Date();
        return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    }
    return data;
}

export default function FieldConditionRule({
    formatting,
    fieldsList,
    onCancel,
    onApply,
    title,
}: {
    formatting: ConditionFormatting;
    fieldsList: Array<Field>;
    onCancel: () => void;
    onApply: (rule: Rule, fieldTextFormatting: FieldTextFormatting) => void;
    title?: string;
}) {
    const { t } = useBundleTranslation(['components/report-content']);

    const [fieldFormatting, setFieldFormatting] = useState<FieldTextFormatting>(formatting.format);

    const [selectedFieldRefName, setSelectedFieldRefName] = useState<string>(
        formatting.rules[0].field.replace(LegacyRuleFieldSuffix, ''),
    );
    const [selectedSecondFieldRefName, setSecondSelectedFieldRefName] = useState<string>(
        formatting.rules[0].field_second.replace(LegacyRuleFieldSuffix, ''),
    );

    const [selectedConditionName, setSelectedConditionName] = useState<string>(formatting.rules[0].condition);
    const [compareConditionName, setCompareConditionName] = useState<CompareConditionType>(
        formatting.rules[0].compare_condition,
    );

    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        ruleData: formatting.rules[0].data,
        ruleDataCalendar: getCalendarData(String(formatting.rules[0].data)),
    });

    const selectedField = fieldsList.find((f) => f.reference_name == selectedFieldRefName) ?? fieldsList[0];

    useEffect(() => {
        const currentValue = elementProps.form.hookFormGetValues('ruleData');
        if (selectedField.value_type == 'numeric') {
            elementProps.form.hookFormSetValue('ruleData', String(currentValue).replace(/\D/g, ''));
        }
    }, [selectedField]);

    const handleFieldChange = (value: string) => {
        const newIndex = fieldsList.findIndex((f) => f.reference_name == value);

        const field = fieldsList[newIndex];

        const actualCondition = Conditions.find((c) => c.name == selectedConditionName);

        if (!actualCondition?.applicableTo.includes(field.value_type)) {
            const newCondition = Conditions.find((c) => c.applicableTo.includes(field.value_type));
            if (newCondition) {
                setSelectedConditionName(newCondition.name);
            }
        }

        setSelectedFieldRefName(fieldsList[newIndex].reference_name);

        const secondSelectedField = fieldsList.find((f) => f.reference_name == selectedSecondFieldRefName);
        if (
            !secondSelectedField ||
            secondSelectedField.value_type != fieldsList[newIndex].value_type ||
            secondSelectedField.reference_name == fieldsList[newIndex].reference_name
        ) {
            setSecondSelectedFieldRefName(SecondFieldConstValue);
        }
    };

    //Data for Select controls
    const fieldsToSelect = fieldsList.map((f) => ({ label: f.column_name, value: f.reference_name }));
    const conditionsToSelect = Conditions.filter((c) => c.applicableTo.includes(selectedField.value_type)).map((c) => ({
        label: c.title,
        value: c.name,
    }));

    const selectedCondition = Conditions.find((c) => c.name == selectedConditionName) ?? Conditions[0];

    //@ts-ignore
    const secondFieldToSelect: Array<Field> = [
        {
            column_name: SecondFieldConstValue,
            reference_name: SecondFieldConstValue,
            value_type: 'text',
        },
    ].concat(
        fieldsList.filter(
            (f) => f.value_type == selectedField.value_type && f.reference_name != selectedField.reference_name,
        ),
    );

    const handleApply = () => {
        const result = {
            ...formatting.rules[0],
            field: selectedFieldRefName + LegacyRuleFieldSuffix,
            condition: selectedConditionName,
        };

        if (secondFieldVisible) {
            result.field_second =
                selectedSecondFieldRefName +
                (selectedSecondFieldRefName == SecondFieldConstValue ? '' : LegacyRuleFieldSuffix);
        } else {
            result.field_second = '';
        }

        if (conditionIsVisible) {
            result.compare_condition = compareConditionName;
        } else {
            result.compare_condition = 'a value';
        }

        if (dataFieldVisible) {
            result.data =
                selectedField.value_type == 'datetime'
                    ? elementProps.form.hookFormGetValues('ruleDataCalendar')
                    : elementProps.form.hookFormGetValues('ruleData');
        } else {
            result.data = '';
        }

        onApply(result, fieldFormatting);
    };

    const secondFieldVisible =
        selectedCondition.isComparable &&
        selectedField.value_type != 'datetime' &&
        selectedCondition.name != 'is in list' &&
        selectedCondition.name != 'is not in list';

    const conditionIsVisible =
        selectedCondition.isComparable &&
        selectedField.value_type == 'numeric' &&
        selectedSecondFieldRefName != SecondFieldConstValue &&
        !['equals', 'does not equal'].includes(selectedConditionName);

    const dataFieldVisible =
        selectedCondition.isComparable && !(conditionIsVisible && compareConditionName == 'any amount');

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={title}
                    onCancel={() => onCancel()}
                    item={t('conditional_formatting.label')}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Stack direction={'row'}>
                    <StaticAddon>When</StaticAddon>
                    <Box flexGrow={1}>
                        <ReactSelect
                            data={fieldsToSelect}
                            value={[selectedFieldRefName]}
                            update={(value) => handleFieldChange(value)}
                        />
                    </Box>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Box sx={{ width: '164px' }}>
                        <ReactSelect
                            data={conditionsToSelect}
                            value={[selectedConditionName]}
                            update={(value) => setSelectedConditionName(value)}
                        />
                    </Box>

                    {selectedCondition.isComparable && secondFieldVisible && (
                        <Box sx={{ flexGrow: 1 }}>
                            <ReactSelect
                                data={secondFieldToSelect.map((f) => ({
                                    label: f.column_name,
                                    value: f.reference_name,
                                }))}
                                value={[selectedSecondFieldRefName]}
                                update={(value) => setSecondSelectedFieldRefName(value)}
                            />
                        </Box>
                    )}
                </Stack>

                {selectedCondition.isComparable && (
                    <Stack direction={'row'} spacing={1}>
                        {conditionIsVisible && (
                            <Stack direction={'row'} sx={{ width: '164px' }}>
                                <StaticAddon>by</StaticAddon>
                                <Box flexGrow={1}>
                                    <ReactSelect
                                        data={CompareConditions.map((c) => ({
                                            label: c.title,
                                            value: c.name,
                                        }))}
                                        value={[compareConditionName]}
                                        update={(value) => setCompareConditionName(value)}
                                    />
                                </Box>
                            </Stack>
                        )}
                        {dataFieldVisible && (
                            <Box flexGrow={1}>
                                <Box className={selectedField.value_type == 'datetime' ? '' : 'd-none'}>
                                    <ReactHookFormController
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            component: {
                                                component: 'FormDatePicker',
                                                name: 'ruleDataCalendar',
                                            },
                                        })}
                                    />
                                </Box>
                                <Box className={selectedField.value_type != 'datetime' ? '' : 'd-none'}>
                                    <ReactHookFormController
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            inputFilter: selectedField.value_type == 'numeric' ? 'int' : undefined,
                                            component: {
                                                component: 'FormText',
                                                name: 'ruleData',
                                                label: '',
                                            },
                                        })}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Stack>
                )}
                <Box>
                    <FieldTextFormattingLine
                        field={fieldFormatting}
                        onChange={setFieldFormatting}
                        fieldsList={fieldsList.map((f) => ({
                            value: f.reference_name_escaped,
                            label: f.column_name,
                        }))}
                    />
                </Box>
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls onApply={handleApply} onCancel={onCancel} isTypeSave></BlockEditPanelControls>
            </Box>
        </Stack>
    );
}
