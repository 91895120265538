import React, { useState } from 'react';
import { Popup } from 'components/common/popup/Popup';
import { alpha, Box } from '@mui/material';
import FormStaticInfo from 'components/common/form/layout/static/FormStaticInfo';
import { FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import { useForm } from 'react-hook-form';
import { authAPI } from 'api/api';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { clearLocalStorage } from 'tools/tools';

interface LoginAsPopupProps {
    show: boolean;
    openUrl?: string;
    userName: string;
    userId: number;
    loginAsView?: boolean;
    onClose: () => void;
}

export default function LoginAsPopup(props: LoginAsPopupProps) {
    const { show, openUrl, userName, userId, loginAsView, onClose = () => {} } = props;
    const { t } = useBundleTranslation(['components/common/login_as']);
    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    //currentUserName = globalConstants.returnSettingUsername ?? globalConstants.username; ???Check -> returnSettingUsername
    const currentUserName = userAuth.userInfo?.username;
    const returnUrl = location.pathname + location.hash;

    const goLoginAs = () => {
        setErrorMessage('');
        setLoginInProgress(true);
        authAPI.loginAs(userId, returnUrl).then((data) => {
            if (data?.status === 'OK') {
                clearLocalStorage();

                const redirectTo = data.redirectUrl > '' && !openUrl ? data.redirectUrl : (openUrl ?? '/home');
                //reload page to reload info.js user info
                document.location = redirectTo;
            } else {
                const errorMessage = data?.status === 'ERROR' ? t(data.message) : t('unknown_error');
                setErrorMessage(errorMessage);
            }
            setLoginInProgress(false);
        });
    };

    const textData = { username: userName.trim(), currentUsername: (currentUserName ?? '').trim() };
    const confirmText = loginAsView ? t('popup_text_as_view', textData) : t('popup_text_default', textData);

    const { setValue: hookFormSetValue, control, getValues: hookFromGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFromGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    const FormStaticElementProps = prepareFormElementProps({
        form: formActions,
        component: {
            label: errorMessage,
            props: {
                type: 'error',
            },
        } as RawFormComponentType,
    });

    if (!show) return null;

    return (
        <Popup
            settings={{
                title: t('popup_title'),
                textOK: t('login_btn', { username: userName }),
            }}
            open={true}
            onHide={onClose}
            onConfirm={goLoginAs}
            maxWidth="popupSm"
            sx={{
                '.popup-button--ok': {
                    maxWidth: '220px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                },
                '.login-as-message-wrap': {
                    wordWrap: 'break-word',
                },
            }}
        >
            {loginInProgress && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                        color: (theme) => alpha(theme.palette.text.primary, 0.4),
                    }}
                />
            )}
            <Box className={'login-as-message-wrap'}>{confirmText}</Box>
            {errorMessage && (
                <Box sx={{ mt: 1 }}>
                    <FormStaticInfo elementProps={FormStaticElementProps} />
                </Box>
            )}
        </Popup>
    );
}
