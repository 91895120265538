import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';

export interface IInfoPopup {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    title: string;
    onHide?: () => void;
    content: any;
    setContent: (value: any) => void;
}

export function useInfoPopup(onHide?: () => void, defaultContent?: any): IInfoPopup {
    const { t } = useBundleTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(defaultContent);
    const title = t('info');
    return {
        isOpen,
        setIsOpen,
        title,
        onHide,
        content,
        setContent,
    };
}

export function InfoPopupWrapper({ infoPopup }: { infoPopup: IInfoPopup }) {
    const handleOnHide = () => {
        infoPopup.setIsOpen(false);
        if (infoPopup.onHide) {
            infoPopup.onHide();
        }
    };

    return (
        <InfoPopup title={infoPopup.title} onHide={handleOnHide} isOpen={infoPopup.isOpen}>
            {infoPopup.content}
        </InfoPopup>
    );
}

export function InfoPopup({
    title,
    isOpen,
    children,
    onHide,
}: {
    title: string;
    isOpen: boolean;
    children: any;
    onHide: () => void;
}) {
    const popupSettings: PopupSettings = {
        title: title,
        needTranslation: false,
        noCancel: true,
        textOK: 'OK',
    };

    return (
        <Popup onHide={onHide} settings={popupSettings} open={isOpen}>
            {children}
        </Popup>
    );
}
