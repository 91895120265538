import React from 'react';
import { alpha, Box, Divider, Grid, Stack } from '@mui/material';
import { ButtonConfig, GridButtonsConfig } from './index';
import MassDeleteButton from './buttons/MassDeleteButton';
import MassActionButton from './buttons/MassActionButton';
import UnselectAllButton from './buttons/UnselectAllButton';
import CustomButton from './buttons/CustomButton';
import CreateButton from './buttons/CreateButton';

// todo: [Anton] refactoring required
export default function TableButtons({
    gridButtons,
    controllerUrl,
    uid,
    massActionsProps,
    reloadGridData,
    setPopupConfig,
    setPopupUrl,
    setPopupType,
    setPopupMode,
    setPopupRedirectAfter,
    setFormPostData,
    setPopupGetMethod,
    gridData,
    massActionsConfig,
    t,
    setPopupComponent,
    setPopupComponentProps,
    setShowLoader,
    filtersValue,
    form,
}: GridButtonsConfig) {
    const createButtons: ButtonConfig[] = gridButtons ? gridButtons.filter((config) => config.type === 'create') : [];

    const getMassActionButtons = () => {
        const massActionButtons: ButtonConfig[] = gridButtons.filter((config) => config.type === 'mass');
        if (massActionButtons.length > 0 && massActionsProps && massActionsProps.checkedRowsKeys.length > 0) {
            return (
                <>
                    {createButtons && createButtons.length > 0 && (
                        <Grid item>
                            <Stack direction="row" alignItems="center" height="100%">
                                <Divider variant="fullWidth" orientation="vertical" sx={{ height: '16px' }} />
                            </Stack>
                        </Grid>
                    )}
                    <Grid item sx={{ lineHeight: '13px', display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                        <Box>
                            <Box sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.64), fontSize: '11px' }}>
                                {t('selected')}
                            </Box>
                            <Box>{t('items', { count: massActionsProps.checkedRowsKeys.length })}</Box>
                        </Box>
                    </Grid>
                    {massActionButtons.map((config: ButtonConfig, key: number) => {
                        if (config.handler === 'delete' || config.handler === 'remove') {
                            return (
                                <MassDeleteButton
                                    key={key}
                                    config={config}
                                    controllerUrl={controllerUrl}
                                    uid={uid}
                                    removeText={config?.removeText}
                                    selectedItems={massActionsProps.checkedRowsKeys}
                                    setCheckedKeys={massActionsProps.setCheckedKeys}
                                    selectedItemsNames={massActionsProps.checkedRowsNames}
                                    reloadGridData={reloadGridData}
                                    t={t}
                                    groupTitles={config.popupConfig?.groupTitles}
                                    id={`grid_${uid}_buttons_${config.name}_button`}
                                    isRemove={config.handler === 'remove'}
                                />
                            );
                        }
                        return (
                            <MassActionButton
                                key={config.name ?? key}
                                config={config}
                                controllerUrl={controllerUrl}
                                uid={uid}
                                selectedItems={massActionsProps.checkedRowsKeys}
                                setPopupConfig={setPopupConfig}
                                setPopupUrl={setPopupUrl}
                                setPopupRedirectAfter={setPopupRedirectAfter}
                                setPopupType={setPopupType}
                                setPopupMode={setPopupMode}
                                setFormPostData={setFormPostData}
                                setPopupGetMethod={setPopupGetMethod}
                                setPopupComponentProps={setPopupComponentProps}
                                gridData={gridData}
                                massActionsConfig={massActionsConfig}
                                t={t}
                                setPopupComponent={setPopupComponent}
                                reloadGridData={reloadGridData}
                                setShowLoader={setShowLoader}
                                id={`grid_${uid}_buttons_${config.name}_button`}
                                setCheckedKeys={massActionsProps.setCheckedKeys}
                            />
                        );
                    })}
                    <Grid item>
                        <Stack direction="row" alignItems="center" height="100%">
                            <Divider variant="fullWidth" orientation="vertical" sx={{ height: '16px' }} />
                        </Stack>
                    </Grid>
                    <UnselectAllButton
                        uid={uid + '-unselect-all'}
                        setCheckedKeys={massActionsProps.setCheckedKeys}
                        id={`grid_${uid}_buttons_unselect_all_button`}
                    />
                </>
            );
        }
    };

    const getCustomButtons = () => {
        const customButtons: ButtonConfig[] = gridButtons.filter((config) => config.type === 'custom');
        if (customButtons.length > 0 && gridData.length > 0) {
            return (
                <>
                    {createButtons && createButtons.length > 0 && (
                        <Grid item>
                            <Stack direction="row" alignItems="center" height="100%">
                                <Divider variant="fullWidth" orientation="vertical" sx={{ height: '16px' }} />
                            </Stack>
                        </Grid>
                    )}
                    {customButtons.map((config: ButtonConfig, key: number) => {
                        return (
                            <CustomButton
                                key={config.name ?? key}
                                config={config}
                                controllerUrl={controllerUrl}
                                uid={uid}
                                setPopupConfig={setPopupConfig}
                                setPopupUrl={setPopupUrl}
                                setPopupRedirectAfter={setPopupRedirectAfter}
                                setPopupType={setPopupType}
                                setPopupMode={setPopupMode}
                                t={t}
                                reloadGridData={reloadGridData}
                                setShowLoader={setShowLoader}
                                id={`grid_${uid}_buttons_${config.name}_button`}
                                filtersValue={filtersValue}
                                setPopupComponent={setPopupComponent}
                                setPopupComponentProps={setPopupComponentProps}
                            />
                        );
                    })}
                </>
            );
        }
    };

    const getCreateButtons = () => {
        if (createButtons.length > 0) {
            return createButtons.map((createButtonConfig) => {
                return (
                    <CreateButton
                        key={createButtonConfig.name}
                        config={createButtonConfig}
                        controllerUrl={controllerUrl}
                        setPopupConfig={setPopupConfig}
                        setPopupUrl={setPopupUrl}
                        setPopupType={setPopupType}
                        setPopupMode={setPopupMode}
                        setPopupRedirectAfter={setPopupRedirectAfter}
                        setFormPostData={setFormPostData}
                        setPopupGetMethod={setPopupGetMethod}
                        setPopupComponent={setPopupComponent}
                        setPopupComponentProps={setPopupComponentProps}
                        t={t}
                        id={`grid_${uid}_buttons_${createButtonConfig.name}_button`}
                        selectedItems={massActionsProps?.checkedRowsKeys}
                        reloadGridData={reloadGridData}
                        setShowLoader={setShowLoader}
                        form={form}
                    />
                );
            });
        }
    };

    if (gridButtons && (getCreateButtons() || getCustomButtons() || getMassActionButtons())) {
        return (
            <Grid item data-test={`grid_${uid}_buttons_container`}>
                <Grid container spacing={1} key={`table-buttons-${uid}`} className="btnbtntbntbtnt">
                    {gridButtons && getCreateButtons()}
                    {gridButtons && getCustomButtons()}
                    {gridButtons && getMassActionButtons()}
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
}
