import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { components } from 'react-select';
import { Box } from '@mui/material';

export const ReactSelectClearIndicator = (props: any) => {
    const filterSelectDataTest = props.selectProps['data-test'];

    return (
        <components.ClearIndicator {...props}>
            <IconMi
                icon="times"
                fontSize={'16'}
                sx={{ color: 'text.primary', '&:hover': { color: 'error.main' } }}
                data-test={filterSelectDataTest ? filterSelectDataTest + '-clear' : undefined}
            />
        </components.ClearIndicator>
    );
};
