import React, { useState } from 'react';
import { Box } from '@mui/material';
import useBundleTranslation from 'i18n';

interface ShowMoreLessTextProps {
    text: string;
    maxLines: number;
}

const CollapseText: React.FC<ShowMoreLessTextProps> = ({ text, maxLines }) => {
    const { t } = useBundleTranslation(['main']);
    const [showAll, setShowAll] = useState(false);
    const [isOverflown, setIsOverflown] = useState(false);

    const handleToggle = () => {
        setShowAll(!showAll);
    };

    return (
        <Box
            ref={(containerRef: HTMLDivElement) => {
                if (containerRef && !showAll) {
                    setIsOverflown(containerRef.scrollHeight > containerRef.clientHeight);
                }
            }}
            sx={{ maxHeight: showAll ? 'none' : `${maxLines * 18}px`, overflow: 'hidden', mt: 1 }}
        >
            <Box
                style={{
                    display: showAll ? 'block' : 'flex',
                    WebkitLineClamp: maxLines,
                    WebkitBoxOrient: 'vertical',
                }}
            >
                <Box className={'markdown-holder'} component="span" dangerouslySetInnerHTML={{ __html: text }}></Box>
                {isOverflown && (
                    <Box
                        sx={{
                            textWrap: 'nowrap',
                            fontWeight: '500',
                            cursor: 'pointer',
                            color: 'primary.main',
                            textDecoration: 'underline',
                            maxHeight: showAll ? 'none' : `${maxLines * 18}px`,
                            display: 'flex',
                            alignItems: 'end',
                        }}
                        component={'span'}
                        onClick={handleToggle}
                    >
                        {t(showAll ? 'show_less' : 'show_more')}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default CollapseText;
