import { alpha } from '@mui/material';

export const MuiTabsConfig = {
    styleOverrides: {
        root: () => {
            return {
                minHeight: 45,
            };
        },
        indicator: ({ theme }: { theme: any }) => {
            return {
                height: 1,
                backgroundColor: theme.componentSettings.tab.primaryColor,
            };
        },
    },
};
