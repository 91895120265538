import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Theme } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { wallAPI } from 'api/wall';
import { wallProcessMarkdownText } from 'components/wall/index';

export interface ExpertNoteHistoryPopupProps {
    reqData: any;
    params: any;
    onClose: () => void;
}

export default function ExpertNoteHistoryPopup(props: ExpertNoteHistoryPopupProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { reqData, params, onClose = () => {} } = props;

    const [mainData, setMainData] = useState<any>(null);

    useEffect(() => {
        wallAPI
            .expertNoteHistoryData(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    setMainData(response.data.data);
                }
            })
            .catch(() => {});
    }, []);

    if (!mainData) return null;

    return (
        <Popup
            settings={{
                title: t(`expert_note_history.title`),
                noButtons: true,
            }}
            open={true}
            onHide={onClose}
            maxWidth="popupLg"
        >
            <Box>
                {mainData.map((note: any, index: number) => {
                    return (
                        <Box
                            key={note.created_time}
                            sx={{
                                py: 0.5,
                                borderBottom: index + 1 == mainData.length ? 0 : '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            }}
                        >
                            <Box
                                sx={{ '& p': { m: 0 } }}
                                dangerouslySetInnerHTML={{
                                    __html: wallProcessMarkdownText(note.text, params),
                                }}
                            />
                            <Box sx={{ color: 'text.secondary' }}>
                                <i>
                                    - {note.display_name} {note.created_time}{' '}
                                </i>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Popup>
    );
}
