import { green } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        primary: {
            main: green[500],
        },
    },
} as ThemeOptions;
