import { alpha, Box, Button, Popover } from '@mui/material';
import useBundleTranslation from 'i18n';
import React, { useState } from 'react';
import ReactSelect from 'components/common/react-select/ReactSelect';

export interface SubscriptionNotificationChangeBoxProps {
    types: string[];
    value: string;
    mod?: string;
    anchorElement: HTMLElement | null;
    getLabelFunc: (type: string) => string;
    onApply: (selectedType: string) => void;
    onCancel: (event: any) => void;
}

//ElementSubscription.openNotificationBox
//engine/layouts/components/subscription/notification_box_.phtml
export default function SubscriptionNotificationChangeBox(props: SubscriptionNotificationChangeBoxProps) {
    const { t } = useBundleTranslation(['components/alert-subscription/subscription_list']);
    const {
        types,
        value,
        mod,
        anchorElement,
        onApply = () => {},
        onCancel = () => {},
        getLabelFunc = (type) => {
            return type;
        },
    } = props;

    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<string>(value);

    const isItemIcon = mod == 'itemIcon';
    const dataTestAttr = 'alert_subscription_popup';
    return (
        <Box>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isItemIcon ? 'right' : 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: isItemIcon ? 'right' : 'left',
                }}
                open={true}
                anchorEl={anchorElement}
                onClose={onCancel}
                sx={
                    isItemIcon
                        ? {
                              '.MuiPaper-root': {
                                  ml: '8px',
                                  boxShadow: (theme) => `5px 5px 4px ${alpha(theme.palette.text.primary, 0.15)}`,
                                  borderTopLeftRadius: 0,
                              },
                          }
                        : {
                              '.MuiPaper-root': {
                                  mt: 0.5,
                                  boxShadow: (theme) => `0px 8px 32px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
                              },
                          }
                }
            >
                <Box sx={{ p: 1.5, width: 250 }}>
                    <Box>
                        <ReactSelect
                            data={types.map((item) => {
                                return {
                                    value: item,
                                    label: getLabelFunc(item),
                                };
                            })}
                            value={selectedSubscriptionType}
                            update={(val) => {
                                setSelectedSubscriptionType(val);
                            }}
                            name={dataTestAttr + '_subscribe_settings_select_btn'}
                        />
                    </Box>
                    <Box>
                        <Button
                            sx={{ mt: 1, mr: 1 }}
                            onClick={() => {
                                onApply(selectedSubscriptionType);
                            }}
                            data-test={dataTestAttr + '_subscribe_settings_save_btn'}
                        >
                            {t('subscription_change_box.btn_save')}
                        </Button>
                        <Button
                            sx={{ mt: 1 }}
                            variant={'outlined'}
                            color={'neutral'}
                            onClick={onCancel}
                            data-test={dataTestAttr + '_subscribe_settings_cancel_btn'}
                        >
                            {t('subscription_change_box.btn_cancel')}
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
}
