export { ReactComponent as AddAnnotation } from 'assets/icons/add-annotation.svg';
export { ReactComponent as AddEvent } from 'assets/icons/add-event.svg';
export { ReactComponent as AdvancedConfiguration } from 'assets/icons/advanced-configuration.svg';
export { ReactComponent as Alert } from 'assets/icons/alert.svg';
export { ReactComponent as AlertSolid } from 'assets/icons/alert-solid.svg';
export { ReactComponent as AlertWorkflow } from 'assets/icons/alert-workflow.svg';
export { ReactComponent as Alerts } from 'assets/icons/alerts.svg';
export { ReactComponent as AlertsByUser } from 'assets/icons/alerts-by-user.svg';
export { ReactComponent as AlignmentCenter } from 'assets/icons/alignment-center.svg';
export { ReactComponent as AlignmentLeft } from 'assets/icons/alignment-left.svg';
export { ReactComponent as AlignmentRight } from 'assets/icons/alignment-right.svg';
export { ReactComponent as AllItems } from 'assets/icons/all-items.svg';
export { ReactComponent as Annotation } from 'assets/icons/annotation.svg';
export { ReactComponent as Announcements } from 'assets/icons/announcements.svg';
export { ReactComponent as ApiToolkit } from 'assets/icons/api-toolkit.svg';
export { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
export { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
export { ReactComponent as ArrowRightCircle } from 'assets/icons/arrow-right-circle.svg';
export { ReactComponent as ArrowsAltH } from 'assets/icons/arrows-alt-h.svg';
export { ReactComponent as ArrowsAltS } from 'assets/icons/arrows-alt-s.svg';
export { ReactComponent as ArrowsAltV } from 'assets/icons/arrows-alt-v.svg';
export { ReactComponent as ArrowsCollapse } from 'assets/icons/arrows-collapse.svg';
export { ReactComponent as ArrowsExpand } from 'assets/icons/arrows-expand.svg';
export { ReactComponent as ArrowsLeftAndRight } from 'assets/icons/arrows-left-and-right.svg';
export { ReactComponent as ArrowsTopAndBottom } from 'assets/icons/arrows-top-and-bottom.svg';
export { ReactComponent as Assets } from 'assets/icons/assets.svg';
export { ReactComponent as Autoexport } from 'assets/icons/autoexport.svg';
export { ReactComponent as Autoimport } from 'assets/icons/autoimport.svg';
export { ReactComponent as Avatar } from 'assets/icons/avatar.svg';
export { ReactComponent as BarChart } from 'assets/icons/bar-chart.svg';
export { ReactComponent as BiTools } from 'assets/icons/bi-tools.svg';
export { ReactComponent as Bold } from 'assets/icons/bold.svg';
export { ReactComponent as BoldFont } from 'assets/icons/bold-font.svg';
export { ReactComponent as Bolt } from 'assets/icons/bolt.svg';
export { ReactComponent as Bookmark } from 'assets/icons/bookmark.svg';
export { ReactComponent as Branding } from 'assets/icons/branding.svg';
export { ReactComponent as Briefcase } from 'assets/icons/briefcase.svg';
export { ReactComponent as BulkChangeHistory } from 'assets/icons/bulk-change-history.svg';
export { ReactComponent as BulkChanges } from 'assets/icons/bulk-changes.svg';
export { ReactComponent as BulkLoadExternalReport } from 'assets/icons/bulk-load-external-report.svg';
export { ReactComponent as Burst } from 'assets/icons/burst.svg';
export { ReactComponent as BusinessUnit } from 'assets/icons/business-unit.svg';
export { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
export { ReactComponent as Categories } from 'assets/icons/categories.svg';
export { ReactComponent as CertificationSetup } from 'assets/icons/certification-setup.svg';
export { ReactComponent as Certified } from 'assets/icons/certified.svg';
export { ReactComponent as ChartingOptions } from 'assets/icons/charting-options.svg';
export { ReactComponent as Chatbot } from 'assets/icons/chatbot.svg';
export { ReactComponent as Check } from 'assets/icons/check.svg';
export { ReactComponent as CheckSquare } from 'assets/icons/check-square.svg';
export { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
export { ReactComponent as ChevronFirst } from 'assets/icons/chevron-first.svg';
export { ReactComponent as ChevronLast } from 'assets/icons/chevron-last.svg';
export { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
export { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
export { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
export { ReactComponent as ChevronsLeft } from 'assets/icons/chevrons-left.svg';
export { ReactComponent as ChevronsRight } from 'assets/icons/chevrons-right.svg';
export { ReactComponent as Clock } from 'assets/icons/clock.svg';
export { ReactComponent as Code } from 'assets/icons/code.svg';
export { ReactComponent as Collapse } from 'assets/icons/collapse.svg';
export { ReactComponent as ColorFill } from 'assets/icons/color-fill.svg';
export { ReactComponent as ColorPicker } from 'assets/icons/color-picker.svg';
export { ReactComponent as Columns } from 'assets/icons/columns.svg';
export { ReactComponent as ColumnsDefault } from 'assets/icons/columns-default.svg';
export { ReactComponent as Command } from 'assets/icons/command.svg';
export { ReactComponent as Comment } from 'assets/icons/comment.svg';
export { ReactComponent as Comment2 } from 'assets/icons/comment-2.svg';
export { ReactComponent as CommentSolid } from 'assets/icons/comment-solid.svg';
export { ReactComponent as Content } from 'assets/icons/content.svg';
export { ReactComponent as ContentCenter } from 'assets/icons/content-center.svg';
export { ReactComponent as Copy } from 'assets/icons/copy.svg';
export { ReactComponent as Crop } from 'assets/icons/crop.svg';
export { ReactComponent as Cube } from 'assets/icons/cube.svg';
export { ReactComponent as CustomField } from 'assets/icons/custom-field.svg';
export { ReactComponent as CustomScripts } from 'assets/icons/custom-scripts.svg';
export { ReactComponent as DarkMode } from 'assets/icons/dark-mode.svg';
export { ReactComponent as DataDependencies } from 'assets/icons/data-dependencies.svg';
export { ReactComponent as DataSource } from 'assets/icons/data-source.svg';
export { ReactComponent as DataStorage } from 'assets/icons/data-storage.svg';
export { ReactComponent as DatasetView } from 'assets/icons/dataset-view.svg';
export { ReactComponent as Datasets } from 'assets/icons/datasets.svg';
export { ReactComponent as DecreasingUsage } from 'assets/icons/decreasing-usage.svg';
export { ReactComponent as Defaults } from 'assets/icons/defaults.svg';
export { ReactComponent as Delete } from 'assets/icons/delete.svg';
export { ReactComponent as Dimension } from 'assets/icons/dimension.svg';
export { ReactComponent as Distribution } from 'assets/icons/distribution.svg';
export { ReactComponent as DocumentType } from 'assets/icons/document-type.svg';
export { ReactComponent as Documents } from 'assets/icons/documents.svg';
export { ReactComponent as Download } from 'assets/icons/download.svg';
export { ReactComponent as DragAndDrop } from 'assets/icons/drag-and-drop.svg';
export { ReactComponent as DragAndDropGrid } from 'assets/icons/drag-and-drop-grid.svg';
export { ReactComponent as Drive } from 'assets/icons/drive.svg';
export { ReactComponent as EditPencil } from 'assets/icons/edit-pencil.svg';
export { ReactComponent as Elements } from 'assets/icons/elements.svg';
export { ReactComponent as Elements2 } from 'assets/icons/elements-2.svg';
export { ReactComponent as ElementsStack } from 'assets/icons/elements-stack.svg';
export { ReactComponent as EmailTemplate } from 'assets/icons/email-template.svg';
export { ReactComponent as Engagement } from 'assets/icons/engagement.svg';
export { ReactComponent as EngagementOval } from 'assets/icons/engagement-oval.svg';
export { ReactComponent as Enter } from 'assets/icons/enter.svg';
export { ReactComponent as Error } from 'assets/icons/error.svg';
export { ReactComponent as Event } from 'assets/icons/event.svg';
export { ReactComponent as EventCalendar } from 'assets/icons/event-calendar.svg';
export { ReactComponent as Example } from 'assets/icons/example.svg';
export { ReactComponent as Expand } from 'assets/icons/expand.svg';
export { ReactComponent as ExpandChildren } from 'assets/icons/expand-children.svg';
export { ReactComponent as ExpertAnalysis } from 'assets/icons/expert-analysis.svg';
export { ReactComponent as Export } from 'assets/icons/export.svg';
export { ReactComponent as ExportLocations } from 'assets/icons/export-locations.svg';
export { ReactComponent as ExternalApplications } from 'assets/icons/external-applications.svg';
export { ReactComponent as ExternalContent } from 'assets/icons/external-content.svg';
export { ReactComponent as ExternalDatasetView } from 'assets/icons/external-dataset-view.svg';
export { ReactComponent as ExternalDatasets } from 'assets/icons/external-datasets.svg';
export { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
export { ReactComponent as ExternalReport } from 'assets/icons/external-report.svg';
export { ReactComponent as Favorite } from 'assets/icons/favorite.svg';
export { ReactComponent as FavoriteSolid } from 'assets/icons/favorite-solid.svg';
export { ReactComponent as Filter } from 'assets/icons/filter.svg';
export { ReactComponent as Focus } from 'assets/icons/focus.svg';
export { ReactComponent as File } from 'assets/icons/file.svg';
export { ReactComponent as FileCss } from 'assets/icons/file-css.svg';
export { ReactComponent as FileCsv } from 'assets/icons/file-csv.svg';
export { ReactComponent as FileExcel } from 'assets/icons/file-excel.svg';
export { ReactComponent as FileHtml } from 'assets/icons/file-html.svg';
export { ReactComponent as FileImageO } from 'assets/icons/file-image-o.svg';
export { ReactComponent as FileJs } from 'assets/icons/file-js.svg';
export { ReactComponent as FileJson } from 'assets/icons/file-json.svg';
export { ReactComponent as FilePdf } from 'assets/icons/file-pdf.svg';
export { ReactComponent as FilePowerpoint } from 'assets/icons/file-powerpoint.svg';
export { ReactComponent as FileSql } from 'assets/icons/file-sql.svg';
export { ReactComponent as Folder } from 'assets/icons/folder.svg';
export { ReactComponent as FolderMinus } from 'assets/icons/folder-minus.svg';
export { ReactComponent as FolderPlus } from 'assets/icons/folder-plus.svg';
export { ReactComponent as FolderTree } from 'assets/icons/folder-tree.svg';
export { ReactComponent as Folders } from 'assets/icons/folders.svg';
export { ReactComponent as FoldersMenu } from 'assets/icons/folders-menu.svg';
export { ReactComponent as Format } from 'assets/icons/format.svg';
export { ReactComponent as FtpConnection } from 'assets/icons/ftp-connection.svg';
export { ReactComponent as FullScreen } from 'assets/icons/full-screen.svg';
export { ReactComponent as Gear } from 'assets/icons/gear.svg';
export { ReactComponent as GearAdmin } from 'assets/icons/gear-admin.svg';
export { ReactComponent as GlobalAlerts } from 'assets/icons/global-alerts.svg';
export { ReactComponent as Globe } from 'assets/icons/globe.svg';
export { ReactComponent as Glossary } from 'assets/icons/glossary.svg';
export { ReactComponent as Heart } from 'assets/icons/heart.svg';
export { ReactComponent as Hide } from 'assets/icons/hide.svg';
export { ReactComponent as History } from 'assets/icons/history.svg';
export { ReactComponent as Home } from 'assets/icons/home.svg';
export { ReactComponent as HowToGuide } from 'assets/icons/how-to-guide.svg';
export { ReactComponent as IdentityProfile } from 'assets/icons/identity-profile.svg';
export { ReactComponent as Image } from 'assets/icons/image.svg';
export { ReactComponent as Import } from 'assets/icons/import.svg';
export { ReactComponent as ImportExport } from 'assets/icons/import-export.svg';
export { ReactComponent as ImportExportHistory } from 'assets/icons/import-export-history.svg';
export { ReactComponent as IncreasingUsage } from 'assets/icons/increasing-usage.svg';
export { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
export { ReactComponent as InfoCircleSmall } from 'assets/icons/info-circle-small.svg';
export { ReactComponent as IssuePriority } from 'assets/icons/issue-priority.svg';
export { ReactComponent as Italic } from 'assets/icons/italic.svg';
export { ReactComponent as ItalicFont } from 'assets/icons/italic-font.svg';
export { ReactComponent as Key } from 'assets/icons/key.svg';
export { ReactComponent as KnowledgeBase } from 'assets/icons/knowledge-base.svg';
export { ReactComponent as KpiAlerts } from 'assets/icons/kpi-alerts.svg';
export { ReactComponent as KpiPanel } from 'assets/icons/kpi-panel.svg';
export { ReactComponent as Layers } from 'assets/icons/layers.svg';
export { ReactComponent as Layout } from 'assets/icons/layout.svg';
export { ReactComponent as LegacyReport } from 'assets/icons/legacy-report.svg';
export { ReactComponent as Legend } from 'assets/icons/legend.svg';
export { ReactComponent as LicenseRequest } from 'assets/icons/license-request.svg';
export { ReactComponent as Light } from 'assets/icons/light.svg';
export { ReactComponent as LightMode } from 'assets/icons/light-mode.svg';
export { ReactComponent as LightSolid } from 'assets/icons/light-solid.svg';
export { ReactComponent as LineChart } from 'assets/icons/line-chart.svg';
export { ReactComponent as LineChartWithArrow } from 'assets/icons/line-chart-with-arrow.svg';
export { ReactComponent as LineageDiagram } from 'assets/icons/lineage-diagram.svg';
export { ReactComponent as Link } from 'assets/icons/link.svg';
export { ReactComponent as ListCollapse } from 'assets/icons/list-collapse.svg';
export { ReactComponent as ListExpand } from 'assets/icons/list-expand.svg';
export { ReactComponent as ListItems } from 'assets/icons/list-items.svg';
export { ReactComponent as ListView } from 'assets/icons/list-view.svg';
export { ReactComponent as Loader } from 'assets/icons/loader.svg';
export { ReactComponent as Lock } from 'assets/icons/lock.svg';
export { ReactComponent as LogIn } from 'assets/icons/log-in.svg';
export { ReactComponent as LogOut } from 'assets/icons/log-out.svg';
export { ReactComponent as Mail } from 'assets/icons/mail.svg';
export { ReactComponent as Mask } from 'assets/icons/mask.svg';
export { ReactComponent as MeasTimeCalcs } from 'assets/icons/meas-time-calcs.svg';
export { ReactComponent as MeasurementInterval } from 'assets/icons/measurement-interval.svg';
export { ReactComponent as MenuClosed } from 'assets/icons/menu-closed.svg';
export { ReactComponent as MenuOpened } from 'assets/icons/menu-opened.svg';
export { ReactComponent as MetadataPropagation } from 'assets/icons/metadata-propagation.svg';
export { ReactComponent as Metric } from 'assets/icons/metric.svg';
export { ReactComponent as MetricImpact } from 'assets/icons/metric-impact.svg';
export { ReactComponent as Microphone } from 'assets/icons/microphone.svg';
export { ReactComponent as MicrosoftSharepoint } from 'assets/icons/microsoft-sharepoint.svg';
export { ReactComponent as MicrosoftTeams } from 'assets/icons/microsoft-teams.svg';
export { ReactComponent as MigrateFavoritesToFolders } from 'assets/icons/migrate-favorites-to-folders.svg';
export { ReactComponent as Minus } from 'assets/icons/minus.svg';
export { ReactComponent as Mobile } from 'assets/icons/mobile.svg';
export { ReactComponent as More } from 'assets/icons/more.svg';
export { ReactComponent as MostUsed } from 'assets/icons/most-used.svg';
export { ReactComponent as MultiMetric } from 'assets/icons/multi-metric.svg';
export { ReactComponent as MyKpi } from 'assets/icons/my-kpi.svg';
export { ReactComponent as MyNotifications } from 'assets/icons/my-notifications.svg';
export { ReactComponent as MySettings } from 'assets/icons/my-settings.svg';
export { ReactComponent as NavArrowsCollapse } from 'assets/icons/nav-arrows-collapse.svg';
export { ReactComponent as NavArrowsExpand } from 'assets/icons/nav-arrows-expand.svg';
export { ReactComponent as NavArrowsUp } from 'assets/icons/nav-arrows-up.svg';
export { ReactComponent as New } from 'assets/icons/new.svg';
export { ReactComponent as NewSquare } from 'assets/icons/new-square.svg';
export { ReactComponent as Note } from 'assets/icons/note.svg';
export { ReactComponent as NotificationActive } from 'assets/icons/notification-active.svg';
export { ReactComponent as NotificationSchedule } from 'assets/icons/notification-schedule.svg';
export { ReactComponent as ObjectTemplate } from 'assets/icons/object-template.svg';
export { ReactComponent as Operations } from 'assets/icons/operations.svg';
export { ReactComponent as Overview } from 'assets/icons/overview.svg';
export { ReactComponent as OwnerFieldSetup } from 'assets/icons/owner-field-setup.svg';
export { ReactComponent as Paperclip } from 'assets/icons/paperclip.svg';
export { ReactComponent as Pause } from 'assets/icons/pause.svg';
export { ReactComponent as Pipeline } from 'assets/icons/pipeline.svg';
export { ReactComponent as Play } from 'assets/icons/play.svg';
export { ReactComponent as Plugin } from 'assets/icons/plugin.svg';
export { ReactComponent as PluginDataSource } from 'assets/icons/plugin-data-source.svg';
export { ReactComponent as PluginUrls } from 'assets/icons/plugin-urls.svg';
export { ReactComponent as Popular } from 'assets/icons/popular.svg';
export { ReactComponent as PortalPageTemplate } from 'assets/icons/portal-page-template.svg';
export { ReactComponent as PortalPages } from 'assets/icons/portal-pages.svg';
export { ReactComponent as PowerpointTemplates } from 'assets/icons/powerpoint-templates.svg';
export { ReactComponent as PrivilegeSets } from 'assets/icons/privilege-sets.svg';
export { ReactComponent as Proxies } from 'assets/icons/proxies.svg';
export { ReactComponent as Publish } from 'assets/icons/publish.svg';
export { ReactComponent as PublishingWorkflows } from 'assets/icons/publishing-workflows.svg';
export { ReactComponent as RebuildUserAccess } from 'assets/icons/rebuild-user-access.svg';
export { ReactComponent as ReferenceObject } from 'assets/icons/reference-object.svg';
export { ReactComponent as RefreshCw } from 'assets/icons/refresh-cw.svg';
export { ReactComponent as Reject } from 'assets/icons/reject.svg';
export { ReactComponent as RemoteDataProcessor } from 'assets/icons/remote-data-processor.svg';
export { ReactComponent as Replace } from 'assets/icons/replace.svg';
export { ReactComponent as Reply } from 'assets/icons/reply.svg';
export { ReactComponent as Repo } from 'assets/icons/repo.svg';
export { ReactComponent as Report } from 'assets/icons/report.svg';
export { ReactComponent as Requests } from 'assets/icons/requests.svg';
export { ReactComponent as Resize } from 'assets/icons/resize.svg';
export { ReactComponent as RotateCcw } from 'assets/icons/rotate-ccw.svg';
export { ReactComponent as Save } from 'assets/icons/save.svg';
export { ReactComponent as Search } from 'assets/icons/search.svg';
export { ReactComponent as SearchMain } from 'assets/icons/search-main.svg';
export { ReactComponent as SearchSetup } from 'assets/icons/search-setup.svg';
export { ReactComponent as Sections } from 'assets/icons/sections.svg';
export { ReactComponent as Send } from 'assets/icons/send.svg';
export { ReactComponent as ServiceCredentials } from 'assets/icons/service-credentials.svg';
export { ReactComponent as Share } from 'assets/icons/share.svg';
export { ReactComponent as ShareElement } from 'assets/icons/share-element.svg';
export { ReactComponent as SharedDrive } from 'assets/icons/shared-drive.svg';
export { ReactComponent as ShowAll } from 'assets/icons/show-all.svg';
export { ReactComponent as Sidebar } from 'assets/icons/sidebar.svg';
export { ReactComponent as SidebarMenu } from 'assets/icons/sidebar-menu.svg';
export { ReactComponent as Slack } from 'assets/icons/slack.svg';
export { ReactComponent as Slash } from 'assets/icons/slash.svg';
export { ReactComponent as Slideshow } from 'assets/icons/slideshow.svg';
export { ReactComponent as SortList } from 'assets/icons/sort-list.svg';
export { ReactComponent as SortNumbers } from 'assets/icons/sort-numbers.svg';
export { ReactComponent as Spy } from 'assets/icons/spy.svg';
export { ReactComponent as SqlDataSource } from 'assets/icons/sql-data-source.svg';
export { ReactComponent as StatsModelTemplate } from 'assets/icons/stats-model-template.svg';
export { ReactComponent as StatusMonitor } from 'assets/icons/status-monitor.svg';
export { ReactComponent as Success } from 'assets/icons/success.svg';
export { ReactComponent as Summary } from 'assets/icons/summary.svg';
export { ReactComponent as System } from 'assets/icons/system.svg';
export { ReactComponent as TagListView } from 'assets/icons/tag-list-view.svg';
export { ReactComponent as Tags } from 'assets/icons/tags.svg';
export { ReactComponent as Target } from 'assets/icons/target.svg';
export { ReactComponent as Template } from 'assets/icons/template.svg';
export { ReactComponent as Test } from 'assets/icons/test.svg';
export { ReactComponent as Text } from 'assets/icons/text.svg';
export { ReactComponent as TextColor } from 'assets/icons/text-color.svg';
export { ReactComponent as TextControl } from 'assets/icons/text-control.svg';
export { ReactComponent as ThumbsDown } from 'assets/icons/thumbs-down.svg';
export { ReactComponent as ThumbsUp } from 'assets/icons/thumbs-up.svg';
export { ReactComponent as TilePanel } from 'assets/icons/tile-panel.svg';
export { ReactComponent as TilesLarge } from 'assets/icons/tiles-large.svg';
export { ReactComponent as TilesMedium } from 'assets/icons/tiles-medium.svg';
export { ReactComponent as TilesSmall } from 'assets/icons/tiles-small.svg';
export { ReactComponent as TilesView } from 'assets/icons/tiles-view.svg';
export { ReactComponent as Times } from 'assets/icons/times.svg';
export { ReactComponent as TopMenu } from 'assets/icons/top-menu.svg';
export { ReactComponent as TrainingAndDocs } from 'assets/icons/training-and-docs.svg';
export { ReactComponent as Trash } from 'assets/icons/trash.svg';
export { ReactComponent as TreeView } from 'assets/icons/tree-view.svg';
export { ReactComponent as Trigger } from 'assets/icons/trigger.svg';
export { ReactComponent as Uncheck } from 'assets/icons/uncheck.svg';
export { ReactComponent as Underline } from 'assets/icons/underline.svg';
export { ReactComponent as Undo } from 'assets/icons/undo.svg';
export { ReactComponent as Unlink } from 'assets/icons/unlink.svg';
export { ReactComponent as Upload } from 'assets/icons/upload.svg';
export { ReactComponent as User } from 'assets/icons/user.svg';
export { ReactComponent as UserGroups } from 'assets/icons/user-groups.svg';
export { ReactComponent as UserHelp } from 'assets/icons/user-help.svg';
export { ReactComponent as UserMaps } from 'assets/icons/user-maps.svg';
export { ReactComponent as UserProfile } from 'assets/icons/user-profile.svg';
export { ReactComponent as Utilities } from 'assets/icons/utilities.svg';
export { ReactComponent as View } from 'assets/icons/view.svg';
export { ReactComponent as Vocabulary } from 'assets/icons/vocabulary.svg';
export { ReactComponent as Volumes } from 'assets/icons/volumes.svg';
export { ReactComponent as Docs } from 'assets/icons/docs.svg';
export { ReactComponent as TileChart } from 'assets/icons/tile-chart.svg';
export { ReactComponent as Tile } from 'assets/icons/tile.svg';
export { ReactComponent as Viewer } from 'assets/icons/viewer.svg';
export { ReactComponent as LiveDashboard } from 'assets/icons/live-dashboard.svg';
export { ReactComponent as FullChart } from 'assets/icons/full-chart.svg';
export { ReactComponent as ElementPreview } from 'assets/icons/element-preview.svg';
export { ReactComponent as CleanupEvents } from 'assets/icons/cleanup-events.svg';
export { ReactComponent as Concierge } from 'assets/icons/concierge.svg';
export { ReactComponent as WirelessError } from 'assets/icons/wireless-error.svg';

export const IconMiNameList = [
    'add-annotation',
    'add-event',
    'advanced-configuration',
    'alert',
    'alert-solid',
    'alert-workflow',
    'alerts',
    'alerts-by-user',
    'alignment-center',
    'alignment-left',
    'alignment-right',
    'all-items',
    'annotation',
    'announcements',
    'api-toolkit',
    'arrow-left',
    'arrow-right',
    'arrow-right-circle',
    'arrows-alt-h',
    'arrows-alt-s',
    'arrows-alt-v',
    'arrows-collapse',
    'arrows-expand',
    'arrows-left-and-right',
    'arrows-top-and-bottom',
    'assets',
    'autoexport',
    'autoimport',
    'avatar',
    'bar-chart',
    'bi-tools',
    'bold',
    'bold-font',
    'bolt',
    'bookmark',
    'branding',
    'briefcase',
    'bulk-change-history',
    'bulk-changes',
    'bulk-load-external-report',
    'burst',
    'business-unit',
    'calendar',
    'categories',
    'certification-setup',
    'certified',
    'charting-options',
    'chatbot',
    'check',
    'check-square',
    'chevron-down',
    'chevron-first',
    'chevron-last',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevrons-left',
    'chevrons-right',
    'clock',
    'code',
    'collapse',
    'color-fill',
    'color-picker',
    'columns',
    'columns-default',
    'command',
    'comment',
    'comment-2',
    'comment-solid',
    'concierge',
    'content',
    'content-center',
    'copy',
    'crop',
    'cube',
    'custom-field',
    'custom-scripts',
    'dark-mode',
    'data-dependencies',
    'data-source',
    'data-storage',
    'dataset-view',
    'datasets',
    'decreasing-usage',
    'defaults',
    'delete',
    'dimension',
    'distribution',
    'document-type',
    'documents',
    'docs',
    'download',
    'drag-and-drop',
    'drag-and-drop-grid',
    'drive',
    'edit-pencil',
    'elements',
    'elements-2',
    'elements-stack',
    'email-template',
    'engagement',
    'engagement-oval',
    'enter',
    'error',
    'event',
    'event-calendar',
    'example',
    'expand',
    'expand-children',
    'expert-analysis',
    'export',
    'export-locations',
    'external-applications',
    'external-content',
    'external-dataset-view',
    'external-datasets',
    'external-link',
    'external-report',
    'favorite',
    'favorite-solid',
    'file',
    'file-css',
    'file-csv',
    'file-excel',
    'file-html',
    'file-image-o',
    'file-js',
    'file-json',
    'file-pdf',
    'file-powerpoint',
    'file-sql',
    'filter',
    'focus',
    'folder',
    'folder-minus',
    'folder-plus',
    'folder-tree',
    'folders',
    'folders-menu',
    'format',
    'ftp-connection',
    'full-screen',
    'gear',
    'gear-admin',
    'global-alerts',
    'globe',
    'glossary',
    'heart',
    'hide',
    'history',
    'home',
    'how-to-guide',
    'identity-profile',
    'image',
    'import',
    'import-export',
    'import-export-history',
    'increasing-usage',
    'info-circle',
    'info-circle-small',
    'key',
    'issue-priority',
    'italic',
    'italic-font',
    'knowledge-base',
    'kpi-alerts',
    'kpi-panel',
    'layers',
    'layout',
    'legacy-report',
    'legend',
    'license-request',
    'light',
    'light-mode',
    'light-solid',
    'line-chart',
    'line-chart-with-arrow',
    'lineage-diagram',
    'link',
    'list-collapse',
    'list-expand',
    'list-items',
    'list-view',
    'log-in',
    'log-out',
    'loader',
    'lock',
    'mail',
    'mask',
    'meas-time-calcs',
    'measurement-interval',
    'menu-closed',
    'menu-opened',
    'metadata-propagation',
    'metric',
    'metric-impact',
    'microphone',
    'microsoft-sharepoint',
    'microsoft-teams',
    'migrate-favorites-to-folders',
    'minus',
    'mobile',
    'more',
    'most-used',
    'multi-metric',
    'my-kpi',
    'my-notifications',
    'my-settings',
    'nav-arrows-collapse',
    'nav-arrows-expand',
    'nav-arrows-up',
    'new',
    'new-square',
    'note',
    'notification-active',
    'notification-schedule',
    'object-template',
    'operations',
    'overview',
    'owner-field-setup',
    'paperclip',
    'pause',
    'pipeline',
    'play',
    'plugin',
    'plugin-data-source',
    'plugin-urls',
    'popular',
    'portal-page-template',
    'portal-pages',
    'powerpoint-templates',
    'privilege-sets',
    'proxies',
    'publish',
    'publishing-workflows',
    'rebuild-user-access',
    'reference-object',
    'refresh-cw',
    'reject',
    'remote-data-processor',
    'replace',
    'reply',
    'repo',
    'report',
    'requests',
    'resize',
    'rotate-ccw',
    'save',
    'search',
    'search-main',
    'search-setup',
    'sections',
    'send',
    'service-credentials',
    'share',
    'share-element',
    'shared-drive',
    'show-all',
    'sidebar',
    'sidebar-menu',
    'slack',
    'slash',
    'slideshow',
    'sort-list',
    'sort-numbers',
    'spy',
    'sql-data-source',
    'stats-model-template',
    'status-monitor',
    'success',
    'summary',
    'system',
    'tag-list-view',
    'tags',
    'target',
    'template',
    'test',
    'text',
    'text-color',
    'text-control',
    'thumbs-down',
    'thumbs-up',
    'tile-panel',
    'tiles-large',
    'tiles-medium',
    'tiles-small',
    'tiles-view',
    'times',
    'top-menu',
    'training-and-docs',
    'trash',
    'tree-view',
    'trigger',
    'uncheck',
    'underline',
    'undo',
    'unlink',
    'upload',
    'user',
    'user-groups',
    'user-help',
    'user-maps',
    'user-profile',
    'utilities',
    'view',
    'vocabulary',
    'volumes',
    'tile-chart',
    'tile',
    'element-preview',
    'full-chart',
    'live-dashboard',
    'viewer',
    'cleanup-events',
    'wireless-error',
] as const;

//Make type IconMiName = 'align-justify' | 'arrow-right' | 'arrows-alt-h' ...
export type IconMiName = (typeof IconMiNameList)[number];
