import { useMemo, useRef, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Card, CardContent, CircularProgress, Typography, Tooltip, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ReactSelect from 'components/common/react-select/ReactSelect';
import { editorSourceReportAPI, ElementColumn } from 'api/editor/source-report';

import styles from 'components/common/form/data-fetch-command/CommandEditor.styles';

interface IProps {
    onChange: (command: string) => void;
    dataSource: string;
}

export default function CommandEditor({ onChange, dataSource }: IProps) {
    const [selectedTable, setSelectedTable] = useState('');
    const { t } = useBundleTranslation(['app/editor/dataset']);

    const { data: tablesList } = useQuery<[string, string][], Error>({
        queryKey: ['fetchDatasetEditorElements_' + dataSource],
        queryFn: () => {
            if (!dataSource) {
                return [];
            }
            return editorSourceReportAPI.getSourceReportElements(dataSource);
        },
    });

    const { data: columnsList, isFetching } = useQuery<Array<ElementColumn>, Error>({
        queryKey: [`fetchDatasetEditorElementsColumns_${selectedTable}_${dataSource}`],
        queryFn: () => {
            if (!selectedTable || !dataSource) {
                return [];
            }
            return editorSourceReportAPI.getSourceReportColumns(dataSource, selectedTable);
        },
    });

    const entries = useMemo(() => {
        setSelectedTable('');
        if (!tablesList) return [];

        // @ts-ignore
        return tablesList.map(([value, label]) => ({
            label,
            value,
        }));
    }, [tablesList]);

    const sourceType = useMemo(() => {
        const tmp = dataSource.split('_');
        return tmp?.[1] == 'sql' ? 'tables' : 'datasets';
    }, [dataSource]);

    return (
        <Card sx={styles.container}>
            <CardContent sx={styles.content}>
                <Box sx={styles.box}>
                    <Typography variant="body1" gutterBottom component="div">
                        {t(`available_${sourceType}`)}
                    </Typography>
                    <ReactSelect
                        placeholder={t(`${sourceType}_select_placeholder`)}
                        data={entries}
                        value={selectedTable}
                        update={setSelectedTable}
                    />
                </Box>

                {!isFetching ? (
                    <>
                        {selectedTable && (
                            <>
                                <Box sx={styles.box}>
                                    <>
                                        <Typography sx={styles.title} variant="body1" gutterBottom component="div">
                                            {t(`available_tables_title`)}
                                        </Typography>
                                        <ColumnsList list={[{ column_name: selectedTable }]} onChange={onChange} />
                                    </>
                                </Box>

                                <Box sx={{ ...styles.box, ...styles.scrollContainer }}>
                                    <>
                                        <Typography sx={styles.title} variant="body1" gutterBottom component="div">
                                            {t(`available_columns_title`)}
                                        </Typography>
                                        <ColumnsList list={columnsList ?? []} onChange={onChange} />
                                    </>
                                </Box>
                            </>
                        )}
                    </>
                ) : (
                    <Box sx={styles.loader}>
                        <CircularProgress size={20} />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

function ColumnsList({ list, onChange }: { list: Array<ElementColumn>; onChange: (command: string) => void }) {
    const { t } = useBundleTranslation(['app/editor/dataset']);

    const positionRef = useRef<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });
    const handleMouseOver = (event: React.MouseEvent) => {
        const elementCoor = (event.target as HTMLElement).getBoundingClientRect();
        positionRef.current = { x: elementCoor.left, y: elementCoor.bottom - 8 };
    };

    return (
        <>
            {list.map((item) => (
                <Box key={item.column_name} sx={{ display: 'flex', fontFamily: (theme) => theme.font.monospace }}>
                    <Box sx={styles.command}>
                        <Tooltip
                            title={`${t('click_to_add')}`}
                            disableInteractive
                            placement="bottom-start"
                            PopperProps={{
                                anchorEl: {
                                    getBoundingClientRect: () => {
                                        return new DOMRect(positionRef.current.x, positionRef.current.y, 0, 0);
                                    },
                                },
                            }}
                        >
                            <Box
                                onClick={() => onChange(item.column_name)}
                                sx={styles.commandLabel}
                                component="span"
                                onMouseOver={handleMouseOver}
                            >
                                {item.column_name}
                            </Box>
                        </Tooltip>
                    </Box>
                    {item.data_type && (
                        <Typography sx={styles.type} variant="body1" component="div">
                            {item.data_type}
                        </Typography>
                    )}
                </Box>
            ))}
        </>
    );
}
