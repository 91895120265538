import { DatasetFieldValueType } from 'components/report-content';

export namespace FieldConditionRuleNS {
    // Legacy suffix added to field and secondField in Rule
    export const LegacyRuleFieldSuffix = '-current';
    export const SecondFieldConstValue = 'a value';

    export interface Condition {
        title: string;
        name: string;
        applicableTo: Array<DatasetFieldValueType>;
        isComparable: boolean;
    }

    export const Conditions: Array<Condition> = [
        // String
        { name: 'exactly matches', title: 'exactly matches', applicableTo: ['text'], isComparable: true },
        { name: 'contains', title: 'contains', applicableTo: ['text'], isComparable: true },
        { name: 'does not contain', title: 'does not contain', applicableTo: ['text'], isComparable: true },
        { name: 'is in list', title: 'is in list', applicableTo: ['text'], isComparable: true },
        { name: 'is not in list', title: 'is not in list', applicableTo: ['text'], isComparable: true },
        { name: 'does not equal', title: 'does not equal', applicableTo: ['text'], isComparable: true },
        { name: 'starts with', title: 'starts with', applicableTo: ['text'], isComparable: true },
        // Numeric + DataTime
        { name: 'equals', title: 'equals', applicableTo: ['numeric', 'datetime'], isComparable: true },
        {
            name: 'is greater than',
            title: 'is greater than',
            applicableTo: ['numeric', 'datetime'],
            isComparable: true,
        },
        {
            name: 'is greater than or equal to',
            title: 'is greater than or equal to',
            applicableTo: ['numeric', 'datetime'],
            isComparable: true,
        },
        { name: 'is less than', title: 'is less than', applicableTo: ['numeric', 'datetime'], isComparable: true },
        {
            name: 'is less than or equal to',
            title: 'is less than or equal to',
            applicableTo: ['numeric', 'datetime'],
            isComparable: true,
        },
        { name: 'does not equal', title: 'does not equal', applicableTo: ['numeric', 'datetime'], isComparable: true },
        { name: 'is empty', title: 'is empty', applicableTo: ['text', 'numeric', 'datetime'], isComparable: false },
        {
            name: 'is not empty',
            title: 'is not empty',
            applicableTo: ['text', 'numeric', 'datetime'],
            isComparable: false,
        },
    ];

    export type CompareConditionType = 'a value' | 'a percent' | 'any amount';
    export interface CompareCondition {
        title: string;
        name: CompareConditionType;
    }

    export const CompareConditions: Array<CompareCondition> = [
        { name: 'a percent', title: 'a percent' },
        { name: 'a value', title: 'a value' },
        { name: 'any amount', title: 'any amount' },
    ];

    export type DateTimeUnitType = 'hour' | 'day' | 'week' | 'month' | 'year';

    export interface CompareDateShift {
        title: string;
        name: DateTimeUnitType;
    }

    export const CompareDateShifts: Array<CompareDateShift> = [
        { name: 'hour', title: 'hours' },
        { name: 'day', title: 'days' },
        { name: 'week', title: 'weeks' },
        { name: 'month', title: 'months' },
        { name: 'year', title: 'years' },
    ];
    export interface Field {
        column_name: string;
        reference_name: string;
        reference_name_escaped: string;
        value_type: DatasetFieldValueType;
    }

    export interface Rule {
        // aggregation: '';
        // aggregation_second: '';
        compare_condition: CompareConditionType;
        condition: string;
        data: number | string | null;
        data_second: number | string | null;
        compare_date_shift?: DateTimeUnitType;
        field: string; // reference_name + LegacyRuleFieldSuffix // legacy format
        field_second: string; // reference_name + LegacyRuleFieldSuffix OR SecondFieldConstValue // legacy format
        // instance: '';
        // instance_second: '';
        // key: 'sales-current';
        // key_second: 'a value';
        // label: 'channel';
        // label_new: 'channel';
        // label_second: 'a value';
    }
}
