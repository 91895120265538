import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { addVersionToUrl } from 'tools/tools';
import { GlobalSettings } from 'store/auth';
declare let globalSettings: GlobalSettings;

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng:
            undefined !== globalSettings?.userAuth?.userInfo?.language
                ? globalSettings.userAuth.userInfo.language
                : 'en',
        backend: {
            /* translation file path */
            loadPath: addVersionToUrl('/assets/i18n/{{lng}}/{{ns}}.json'),
        },
        fallbackLng: 'en',
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: [
            'bundle',
            'components/common/navbar/navbar_menu',
            'components/common/form/form',
            'components/common/grid/grid',
            'components/common/navbar/navbar',
        ],
        defaultNS: 'bundle',
        fallbackNS: ['bundle', 'components/common/form/form', 'components/common/grid/grid'],
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
