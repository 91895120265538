import { Stack, Typography } from '@mui/material';
import { DatasetData, DatasetInstance, InstanceMode } from '../index';
import InstanceSelector from './InstanceSelector';
import styles from './InstanceInfo.styles';
import useBundleTranslation from 'i18n';

function InstanceInfo({
    datasetData,
    datasetInstances,
    lastUpdate,
    currentInstance,
    setCurrentInstance,
    priorInstance,
    setPriorInstance,
    setInstanceMode,
    instanceMode,
}: {
    datasetData: DatasetData;
    datasetInstances: DatasetInstance[];
    lastUpdate: string;
    currentInstance: string;
    setCurrentInstance: (value: string) => void;
    priorInstance: string;
    setPriorInstance: (value: string) => void;
    instanceMode: InstanceMode;
    setInstanceMode: (value: InstanceMode) => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    if (datasetData.dataset_type === 'external_dataset') {
        return null;
    }

    const getInfoPanel = () => {
        if (datasetData.save_historical_instances_ind === 'Y') {
            return (
                <InstanceSelector
                    datasetInstances={datasetInstances}
                    currentInstance={currentInstance}
                    setCurrentInstance={setCurrentInstance}
                    priorInstance={priorInstance}
                    setPriorInstance={setPriorInstance}
                    instanceMode={instanceMode}
                    setInstanceMode={setInstanceMode}
                />
            );
        }

        return <Typography>{`${t('instance_info.dataset_collected')} ${lastUpdate}`}</Typography>;
    };

    return (
        <Stack sx={styles.wrapper} justifyContent="center" alignItems="center">
            {getInfoPanel()}
        </Stack>
    );
}

export default InstanceInfo;
