import { FormComponentBuilder } from 'components/common/form/layout';
import { Button } from '@mui/material';
import React from 'react';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { editorMetricAPI } from 'api/editor/metric';
import IconMi from 'components/common/icon/IconMi';

export interface MetricResetForAllUsersProps extends FormControlProps {
    view: string;
}

export default function MetricResetForAllUsers({
    controlProps,
}: FormElementControlPropsType<MetricResetForAllUsersProps>) {
    const { t } = useBundleTranslation(['app/editor/metric']);

    const handleClick = () => {
        if (!confirm(t('reset_for_all?', { view: controlProps.view }))) {
            return;
        }

        controlProps.form.formSave().then((response) => {
            if (response.data.status == 'OK') {
                editorMetricAPI.resetForAllUsers(Number(controlProps.form.hookFormGetValues('element_id')));
            }
        });
    };

    return (
        <Button onClick={handleClick} variant="light" startIcon={<IconMi icon={'rotate-ccw'} />}>
            {t('reset_for_all_users')}
        </Button>
    );
}

export class MetricResetForAllUsersBuilder extends FormComponentBuilder {
    prepareProps(): MetricResetForAllUsersProps {
        return {
            ...this.controlProps,
            view: this.elementProps.componentProps?.view,
        };
    }
}
