import { alpha } from '@mui/material';

export const MuiBreadcrumbsConfig = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                color: theme.componentSettings.topbar.textColor,
            };
        },
        li: {
            '& a:hover': {
                color: 'inherit',
                opacity: 0.85,
            },
        },
        separator: {
            margin: '0 10px',
        },
    },
};
