import { instance } from './api';

export interface GetRatingComponentResponse {
    status: 'OK' | 'SKIP';
    data: RatingComponentData;
}

export interface SaveRatingResponse {
    status: 'OK' | 'ERROR';
}

export interface ShowButtonResponse {
    status: 'OK' | 'ERROR';
    data: boolean;
}

export interface SaveRatingData {
    rating: number;
    comment: string;
    ratingPromptId: string;
    viewLogId: number;
}

export interface RatingComponentData {
    componentTitle: string;
    negativeText: string;
    positiveText: string;
    positiveRatingAbove: number;
    negativeRatingBelow: number;
    collectRatingsInd: 'Y' | 'N';
    defaultFeedbackText: string;
    ratingPromptId: string;
    viewLogId: number;
    isAdmin: boolean;
}

const RATING_PROMPT_URL = `/data/rating-component`;

export const ratingPromptAPI = {
    async getRatingComponentData(type: string, id: string, userCall: boolean): Promise<GetRatingComponentResponse> {
        const url = RATING_PROMPT_URL + '/type/' + type + '/id/' + id + `?user_call=${userCall ? 'Y' : 'N'}`;
        const response = await instance.get(url);

        return response.data;
    },
    async saveRating(type: string, id: string, data: SaveRatingData): Promise<SaveRatingResponse> {
        const url = RATING_PROMPT_URL + '/type/' + type + '/id/' + id;
        const response = await instance.post(url, data);

        return response.data;
    },
    async showGiveFeedbackButton(type: string, id: string): Promise<ShowButtonResponse> {
        const url = RATING_PROMPT_URL + '/show-button/type/' + type + '/id/' + id;
        const response = await instance.get(url);

        return response.data;
    },
};
