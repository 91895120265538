import { ColumnsWidth, ColumnType, GridData, VisibleColumns } from '../index';

export const getColumnMultiplier = (
    newColumnsWidth: ColumnsWidth,
    newGridWidth: number,
    visColumns: VisibleColumns,
    isDraggable: boolean,
    showCheckboxColumn: boolean,
    columns: ColumnType[],
) => {
    const fixedColumnsWidth = columns.reduce((acc, column) => {
        if (column.fixed || column.width) {
            const defaultWidth = column.width ? Number(column.width) : 50;
            return Number(acc) + defaultWidth;
        }
        return acc;
    }, 0);

    const clearGridWidth = newGridWidth - (isDraggable ? 50 : 0) - (showCheckboxColumn ? 24 : 0) - fixedColumnsWidth;
    let totalColumnsWidth = 0;

    columns.forEach((column: ColumnType) => {
        if (!column.hidden && visColumns[column.name] && visColumns[column.name].visible) {
            if (!column.fixed && !column.width) {
                const columnDataLength = newColumnsWidth[column.name];

                totalColumnsWidth += Number(columnDataLength);
            }
        }
    });

    if (totalColumnsWidth > 0) {
        return clearGridWidth / totalColumnsWidth;
    }

    return 0;
};

export const getAverageColumnDataLength = (column: ColumnType, gridData: GridData, title: string) => {
    let maxDataLength = 0;
    let minDataLength = 100;

    const totalDataLength = gridData.reduce((acc, row) => {
        const cellData = row[column.name];
        let contentLength = 5;

        if (cellData && typeof cellData === 'object') {
            if (cellData.config && cellData.config.text) {
                contentLength = String(cellData.config.text).length;
            }
            if (cellData.component === 'delete') {
                contentLength = 5;
            }
        }
        if (typeof cellData !== 'object') {
            contentLength = String(cellData).length;
        }

        if (contentLength > maxDataLength) {
            maxDataLength = contentLength;
        }

        if (contentLength < minDataLength) {
            minDataLength = contentLength;
        }

        if (column.fixed || column.width) {
            contentLength = 0;
        }

        return acc + contentLength;
    }, 0);

    const averageDataLength = Math.round(totalDataLength / gridData.length);

    let formulaResult =
        minDataLength === maxDataLength ? maxDataLength : Math.round((averageDataLength + maxDataLength) / 3);

    if (formulaResult < title.length) {
        formulaResult = title.length;
    }

    return formulaResult > 50 ? 50 : formulaResult < 7 ? 7 : formulaResult;
};
