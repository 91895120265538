import { FormElementProps } from 'components/common/form/index';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getAsFieldValue } from 'components/common/form/dependency/processEffect';
import { AssocArray } from 'tools/types';

type fArgs = [string, AssocArray<string> | undefined];

export default function translate(elementProps: FormElementProps, formsContext: FormsContextType, args: fArgs): string {
    if (!elementProps.form.formTranslation) {
        return '';
    }

    const patterns: AssocArray<string> = {};
    if (args[1]) {
        for (const [key, value] of Object.entries<string>(args[1])) {
            patterns[key] = getAsFieldValue(value, elementProps, formsContext);
        }
    }

    return elementProps.form.formTranslation(args[0], patterns);
}
