import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;
import { Editor } from '@tinymce/tinymce-react';
import { Editor as EditorType } from 'tinymce';
import TextBlockView from 'components/report-content/components/text-block/TextBlockView';
import useBundleTranslation from 'i18n';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { Box } from '@mui/material';
import { getDataQueries } from 'components/report-content/components/text-block/helpers/getDataQueries';
export default function TextBlock({
    contentSettings,
    component,
    updateBlockSettings,
    actions,
    editPanel,
    blockId,
}: ComponentUpdateProps<ComponentSettingsTextBlock>) {
    const editorRef = useRef<EditorType | null>(null);
    const componentReady = useComponentReady(editorRef.current);
    const { t } = useBundleTranslation(['components/report-content']);

    const saveFormText = () => {
        if (editorRef.current) {
            const value = editorRef.current.getContent();
            setEditorValue(value);
        }
    };

    const [editorValue, setEditorValue] = useState('');
    const updateTimeout = useRef<number | null>(null);
    useEffect(() => {
        if (editorValue == component.settings.html || !editorRef.current) {
            return;
        }

        if (updateTimeout.current) {
            window.clearTimeout(updateTimeout.current);
        }

        updateTimeout.current = window.setTimeout(() => {
            component.settings.html = editorValue;
            component.settings.dataQueries = getDataQueries(component.settings);
            updateBlockSettings({
                ...component.settings,
                html: editorValue,
            });
        }, 100);
    }, [editorValue]);

    useEffect(() => {
        return () => {
            if (updateTimeout.current) {
                window.clearTimeout(updateTimeout.current);
            }
        };
    }, []);

    const [reloadEditor, setReloadEditor] = useState(true);

    useLayoutEffect(() => {
        if (editorRef.current) {
            setReloadEditor(false);
            setTimeout(() => setReloadEditor(true));
        }
    }, [component.settings.measureFields, component.settings.formulaFields]);
    useComponentReady(editorRef.current);

    const onInit = (_: any, editor: any) => {
        editorRef.current = editor;
        setEditorValue(component.settings.html);
    };

    return (
        <Box
            sx={{ '.tox-toolbar__group': { padding: '0 5px 0 6px !important' } }}
            style={{
                overflow: 'hidden',
                maxHeight: contentSettings.context == 'edit' ? component.settings.height + 'px' : undefined,
            }}
        >
            {contentSettings.context == 'edit' ? (
                reloadEditor && (
                    <Editor
                        onEditorChange={saveFormText}
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        onInit={onInit}
                        value={editorValue}
                        init={{
                            branding: false,
                            width: '100%',
                            height: component.settings.height,
                            menubar: false,
                            line_height_formats: '9pt 10pt 11pt 12pt 14pt 16pt',
                            font_size_formats: '10pt 12pt 14pt 16pt 18pt 24pt 36pt',
                            toolbar:
                                'code | undo redo | bold italic | alignleft aligncenter alignright | bullist numlist | table | link | forecolor backcolor | fontselect | fontsizeselect | fontfamily fontsize | lineheightselect | substitutions | ',
                            plugins: ['link', 'lists', 'table', 'code', 'lineheight'],
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            setup: (ed) => {
                                ed.on('ExecCommand', function checkListNodes(evt) {
                                    const cmd = evt.command;
                                    if (cmd === 'FontSize' || cmd === 'FontName') {
                                        const val = evt.value;
                                        const node = evt.target.selection.getNode();
                                        const nodeParent = node.parentNode;
                                        if (node.nodeName === 'SPAN' && nodeParent.nodeName === 'LI') {
                                            if (cmd === 'FontSize') {
                                                ed.dom.setStyle(nodeParent, 'font-size', val);
                                            }
                                            if (cmd === 'FontName') {
                                                ed.dom.setStyle(nodeParent, 'font-family', val);
                                            }
                                        } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
                                            const li = ed.dom.select('li', node);
                                            if (cmd === 'FontSize') {
                                                ed.dom.setStyle(li, 'font-size', val);
                                            }
                                            if (cmd === 'FontName') {
                                                ed.dom.setStyle(li, 'font-family', val);
                                            }
                                        }
                                    }
                                });
                                const substitutionsButtons = [
                                    {
                                        type: 'menuitem',
                                        text: 'Snapshot Date',
                                        onAction: () => ed.insertContent('[Snapshot Date]'),
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Row Count',
                                        onAction: () => ed.insertContent('[Row Count]'),
                                    },
                                ];
                                component.settings.measureFields.forEach((column) => {
                                    substitutionsButtons.push({
                                        type: 'menuitem',
                                        text: column.aggregation_function + ' of ' + column.column_name,
                                        onAction: () => ed.insertContent('[' + column.column_name + ']'),
                                    });
                                });

                                component.settings.formulaFields.forEach((column) => {
                                    substitutionsButtons.push({
                                        type: 'menuitem',
                                        text: column.column_name,
                                        onAction: () => ed.insertContent('[' + column.column_name + ']'),
                                    });
                                });

                                substitutionsButtons.push({
                                    type: 'menuitem',
                                    text: t('text_block.manage_variables'),
                                    onAction: () => {
                                        if (blockId) {
                                            actions.openBlockEditor(blockId, editPanel);
                                        }
                                    },
                                });

                                ed.ui.registry.addMenuButton('substitutions', {
                                    text: t('text_block.variables'),
                                    // @ts-ignore
                                    fetch: (callback) => callback(substitutionsButtons),
                                });
                            },
                        }}
                    />
                )
            ) : (
                <TextBlockView contentSettings={contentSettings} component={component} />
            )}
        </Box>
    );
}
