import { RawFormComponentType } from 'components/common/form';
import React from 'react';
import { FormComponentDependency } from 'components/common/form/dependency';

export interface FormTabType {
    components: Array<RawFormComponentType>;
    name: string;
    title: string;
    componentsProps: any;
    isActive: boolean;
    deps: Array<FormComponentDependency>;
    props?: any;
}

export const TabContext = React.createContext<FormTabType | null>(null);
