import { alpha, Theme } from '@mui/material';

export default {
    tagSection: {
        py: 0.5,
    },
    tagIcon: {
        mr: 1,
        verticalAlign: 'middle',
        fontSize: '16px',
    },
    tagsItemsHolder: {
        ml: -0.25,
        mt: -0.5,
        display: 'flex',
        flexWrap: 'wrap',
    },
    tagLabel: {
        ml: 0.25,
        mt: 0.5,
        fontWeight: 600,
        pr: 1.75,
        display: 'inline-flex',
        lineHeight: '20px',
    },
    tagItem: {
        ml: 0.25,
        mt: 0.5,
        lineHeight: '20px',
        fontSize: '12px',
    },
    termSection: {
        flexDirection: 'column',
        borderBottom: ' 1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        py: 0.5,

        '.term-section__header': {
            fontWeight: 600,
        },
        '.term-section__items': {
            ml: -0.25,
            pl: '24px',
        },
        '&.expand': {
            '.term-section__header': {
                color: 'primary.main',
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                },
            },
            '& .term-section__items': {
                pb: 0.5,
            },
        },
        '&.collapse': {
            '.term-section__header': {
                '.summary--default &': {
                    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.8),
                },
                '&:hover': {
                    opacity: 0.75,
                    cursor: 'pointer',
                    color: 'primary.main',
                },
            },
            '& .term-section__items': {
                overflow: 'hidden',
                height: 0,
            },
        },
    },
    iconHolder: {
        height: '16px',
        width: '16px',
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
};
