import { useTranslation } from 'react-i18next';
import { translationAPI } from 'api/api';
import { DefaultNamespace, Namespace, TFunction, TOptionsBase } from 'i18next';
import { $Dictionary } from 'i18next/typescript/helpers';

export default function useBundleTranslation(translationNS: string | string[] = '') {
    if (!Array.isArray(translationNS)) {
        translationNS = [translationNS];
    }
    const prefixList: Array<string> = translationNS.map((ns) => ns.replace(/\//g, '.'));
    const { t, i18n, ready } = useTranslation();

    function createT<Ns extends Namespace = DefaultNamespace, KPrefix = undefined>(
        t: TFunction<Ns, KPrefix>,
    ): TFunction<Ns, KPrefix> {
        const tFunction = (...targs: Parameters<TFunction<Ns, KPrefix>>): ReturnType<TFunction<Ns, KPrefix>> => {
            const key = targs[0];
            const options:
                | string
                | (TOptionsBase & $Dictionary)
                | (TOptionsBase & $Dictionary & { defaultValue: string })
                | undefined = targs[1];

            let withPrefix: string = '';

            if ('' == key || undefined == key) return '';
            if (prefixList.length && prefixList[0].startsWith('app.dev')) return key;

            for (let i = 0; i < prefixList.length; i++)
                if (prefixList[i].length) {
                    if (withPrefix.includes('?')) withPrefix = withPrefix.split('?')[0];

                    withPrefix = prefixList[i] + '.' + key;
                    if (i18n.exists(withPrefix)) {
                        return t(withPrefix, options as (TOptionsBase & $Dictionary) | undefined);
                    } else if (
                        'object' === typeof options &&
                        (i18n.exists(withPrefix + '_one') || i18n.exists(withPrefix + '_other'))
                    ) {
                        return t(withPrefix, options);
                    }
                }

            if ('string' === typeof key && !i18n.exists(key) && prefixList.length && i18n.options.debug) {
                withPrefix = prefixList[0] + '.' + key;
                console.log(withPrefix, key, options);
                //translationAPI.post({ withPrefix, key, options });
            }

            return t(...targs);
        };
        tFunction.$TFunctionBrand = t.$TFunctionBrand;
        return tFunction;
    }

    return {
        t: createT(t),
        i18n,
        ready,
    };
}
