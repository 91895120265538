import ReactCrop, { Crop } from 'react-image-crop';
import React, { useEffect, useRef, useState } from 'react';

export default function ImageWrapper({
    src,
    isCropActive,
    cropData,
    onChange,
    onImageSizeChange,
    isBase64,
    aspect,
    minWidth,
    keepSelection,
}: {
    src: string;
    isCropActive: boolean;
    cropData: Crop;
    onChange: (cropData: Crop) => void;
    onImageSizeChange: (width: number, height: number) => void;
    isBase64?: boolean;
    aspect?: number;
    minWidth?: number;
    keepSelection?: boolean;
}) {
    const maxWidth = 740;
    const maxHeight = 600;

    const imgRef = useRef<HTMLImageElement | null>(null);

    const [imageLoaded, setImageLoaded] = useState(false);

    const [naturalImageSize, setNaturalImageSize] = useState<[number, number]>([0, 0]);
    const [KSize, setKSize] = useState<[number, number]>([0, 0]);
    const [renderedImageSize, setRenderedImageSize] = useState<[number, number]>([maxWidth, maxHeight]);

    useEffect(() => {
        if (imgRef.current == null || !imageLoaded) {
            return;
        }
        onImageSizeChange(imgRef.current?.naturalWidth ?? 0, imgRef.current?.naturalHeight ?? 0);
        setNaturalImageSize([imgRef.current?.naturalWidth ?? 0, imgRef.current?.naturalHeight ?? 0]);
    }, [imgRef.current?.naturalWidth, imgRef.current?.naturalHeight, imageLoaded]);

    useEffect(() => {
        if (imgRef.current == null || !imageLoaded) {
            return;
        }

        const img = document.getElementById('myImage') as HTMLImageElement;
        if (img) {
            setRenderedImageSize([img.width, img.height]);
        } else {
            setRenderedImageSize([
                imgRef.current?.width ?? renderedImageSize[0],
                imgRef.current?.height ?? renderedImageSize[1],
            ]);
        }
    }, [imgRef.current, imgRef.current?.width, imgRef.current?.height, imageLoaded]);

    const onImageLoad = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        setKSize([
            naturalImageSize[0] > renderedImageSize[0] ? naturalImageSize[0] / renderedImageSize[0] : 1,
            naturalImageSize[1] > renderedImageSize[1] ? naturalImageSize[1] / renderedImageSize[1] : 1,
        ]);
    }, [naturalImageSize, renderedImageSize]);

    // Set default crop for the first time
    useEffect(() => {
        if (KSize[0] == 0 || KSize[1] == 0 || naturalImageSize[0] == 0 || naturalImageSize[1] == 0 || !keepSelection) {
            return;
        }
        let cx = naturalImageSize[0] / 2;
        let cy = naturalImageSize[1] / 2;

        let x = cx / 2;
        let w = cx;

        let y = cy / 2;
        let h = cy;
        if (aspect) {
            h = w / aspect;
            y = cy - h / 2;
        }
        onChange({
            x: x,
            y: y,
            width: w,
            height: h,
            unit: 'px',
        });
    }, [KSize, naturalImageSize]);

    const img = (
        <img
            id={'myImage'}
            style={{
                maxHeight: maxHeight,
                maxWidth: maxWidth,
            }}
            onLoad={onImageLoad}
            ref={imgRef}
            src={(isBase64 ? 'data:image/jpeg;base64, ' : '') + src}
        />
    );

    return (
        <>
            <span className={!isCropActive ? 'd-none' : ''}>
                <ReactCrop
                    minWidth={minWidth}
                    aspect={aspect}
                    keepSelection={keepSelection}
                    crop={{
                        x: cropData.x / KSize[0],
                        y: cropData.y / KSize[1],
                        width: cropData.width / KSize[0],
                        height: cropData.height / KSize[1],
                        unit: 'px',
                    }}
                    onChange={(c, cp) =>
                        onChange({
                            x: c.x * KSize[0],
                            y: c.y * KSize[1],
                            width: c.width * KSize[0],
                            height: c.height * KSize[1],
                            unit: 'px',
                        })
                    }
                >
                    {img}
                </ReactCrop>
            </span>
            <span className={isCropActive ? 'd-none' : ''}>{img}</span>
        </>
    );
}
