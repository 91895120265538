import { alpha, Button, Stack } from '@mui/material';
import * as React from 'react';

export default function BlockEditPanelControls({
    onApply,
    onCancel,
    isTypeSave,
}: {
    onApply: () => void;
    onCancel: () => void;
    isTypeSave?: boolean;
}) {
    return (
        <Stack
            direction={'row'}
            spacing={1}
            sx={{
                borderTop: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                py: 2,
                px: 3,
            }}
        >
            {isTypeSave ? (
                <Button onClick={() => onApply()} sx={{ minWidth: 80 }} data-test={`save-button`}>
                    Save
                </Button>
            ) : (
                <Button onClick={() => onApply()} color="success" sx={{ minWidth: 80 }} data-test={`apply-button`}>
                    Apply
                </Button>
            )}

            <Button
                onClick={() => onCancel()}
                variant="outlined"
                color="neutral"
                sx={{ minWidth: 80 }}
                data-test={`cancel-button`}
            >
                Cancel
            </Button>
        </Stack>
    );
}
