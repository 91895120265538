import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';

export default function BlankRouter() {
    const navigate = useNavigate();
    const userAuth: UserAuth = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (userAuth)
            navigate((userAuth?.userInfo?.homeUrl ?? '/home') + window.location.search + window.location.hash);
    }, []);

    return <div></div>;
}
