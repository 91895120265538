import React, { useEffect } from 'react';
import { Box, Stack, Container } from '@mui/material';
import useBundleTranslation from 'i18n';
import styles from './ErrorBoundaryPage.styles';
import denyIcon from './error-ico.svg';
import { useRouteError } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/pageTitleSlice';
export default function ErrorBoundaryPage() {
    let error = useRouteError() as Error;
    const { t } = useBundleTranslation(['app/error_page']);
    const dispatch = useDispatch();

    dispatch(setPageTitle(t(`title`)));

    return (
        <Box sx={styles.mainWrapper}>
            <Container maxWidth="sm" component="main" disableGutters={true} sx={styles.container}>
                <Stack direction="row" justifyContent="space-between" sx={styles.dataWrapper}>
                    <Stack spacing={1}>
                        <Box sx={styles.title}>{t('title')}</Box>
                        <Box sx={styles.message}>
                            <Box>{t('text')}</Box>
                            <code>{error.message || JSON.stringify(error)}</code>
                        </Box>
                    </Stack>
                    <Box sx={{ flexShrink: 0, ml: 1.5 }}>
                        <Box component="img" src={denyIcon} />
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
}
