import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ButtonConfig, FilterValue, PopupMode, PopupType } from 'components/common/grid/';
import { logWarning, prepareTranslatedText } from 'tools/tools';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import IconHandler from 'components/common/icon/IconHandler';
import { onButtonClickAction } from './index';

export default function CustomButton({
    config,
    controllerUrl,
    uid,
    setPopupConfig,
    setPopupUrl,
    setPopupType,
    setPopupMode,
    setPopupRedirectAfter,
    t,
    reloadGridData,
    setShowLoader,
    id,
    setPopupComponent,
    setPopupComponentProps,
    filtersValue,
}: {
    config: ButtonConfig;
    controllerUrl: string;
    uid: string;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    setPopupRedirectAfter?: (redirect: boolean) => void;
    t: TFunction;
    reloadGridData?: () => void;
    setShowLoader?: (value: boolean) => void;
    id: string;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    filtersValue: FilterValue;
}) {
    const navigate = useNavigate();
    const startIcon = config.icon ?? null;
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const popupSettings: PopupSettings = {
        title: 'async_confirmation_popup_title',
        textOK: 'async_confirmation_popup_confirm',
    };

    const onConfirm = () => {
        setConfirmed(true);
        setShowConfirmationPopup(false);
    };

    useEffect(() => {
        if (confirmed) {
            onClick();
        }
    }, [confirmed]);

    const onClick = () => {
        try {
            onButtonClickAction(
                config,
                controllerUrl,
                'custom',
                navigate,
                {
                    setPopupUrl,
                    setPopupConfig,
                    setPopupType,
                    setPopupMode,
                    setPopupRedirectAfter,
                    setPopupComponent,
                    setPopupComponentProps,
                },
                undefined,
                setShowLoader,
                reloadGridData,
                config.confirmation
                    ? {
                          confirmed,
                          showConfirmation: () => {
                              setShowConfirmationPopup(true);
                          },
                          confirmation: config.confirmation,
                          setConfirmed,
                      }
                    : undefined,
                undefined,
                undefined,
                filtersValue,
            );
        } catch (error: any) {
            logWarning(error.message);
            return;
        }
    };

    const { text: btnText, needTranslation, translationNS } = prepareTranslatedText(config.text);

    return (
        <>
            <Grid item key={config.name} sx={{ flexShrink: 0 }}>
                <Button
                    onClick={onClick}
                    key={uid + config.name}
                    startIcon={<IconHandler icon={startIcon} />}
                    variant={config.variant ? (config.variant as any) : 'outlined'}
                    data-test={id}
                    color={config.color ? (config.color as any) : 'primary'}
                >
                    {needTranslation === false ? btnText : t(btnText, { ns: translationNS ?? '' })}
                </Button>
            </Grid>
            {showConfirmationPopup && config.confirmation && (
                <Popup
                    settings={config.popupConfig ? config.popupConfig : popupSettings}
                    t={t}
                    open={showConfirmationPopup}
                    onHide={() => setShowConfirmationPopup(false)}
                    onConfirm={onConfirm}
                    maxWidth={
                        config.popupConfig && config.popupConfig.maxWidth ? config.popupConfig.maxWidth : 'popupSm'
                    }
                >
                    <Typography>{t(config.confirmation)}</Typography>
                </Popup>
            )}
        </>
    );
}
