import { ReportContentNS } from 'components/report-content/index';
import { TFunction } from 'i18next';
import validateHeight from 'components/report-content/utils/validateHeight';
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;

export default function validatePieChart(newSettings: ComponentSettingsPieChart, t: TFunction) {
    let message = validateHeight(newSettings, t);

    if (-1 != newSettings.measureFields.findIndex((b) => b.reference_name == newSettings.group_field)) {
        message = t('pie_chart.validation.selector_labels_same');
    }

    return message;
}
