import { Box, FormLabel, Stack } from '@mui/material';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { RawFormComponentType } from 'components/common/form';
import React from 'react';
import { ReportContentNS } from 'components/report-content';
import AllowedFontSizes = ReportContentNS.AllowedFontSizes;
import AllowedFonts = ReportContentNS.AllowedFonts;
import { EditPanelComponentType } from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/index';

export default function FontSize({ elementProps, t }: EditPanelComponentType) {
    return (
        <Box>
            <FormLabel>{t('dataset.label_font_size')}</FormLabel>
            <Stack direction={'row'}>
                <Box sx={{ flexGrow: 1 }}>
                    <ReactHookFormController
                        componentValues={AllowedFonts}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'mainFont',
                                    props: {
                                        getOptionLabel: (option: any) => (
                                            <Box sx={{ fontFamily: option.label }}>{option.label}</Box>
                                        ),
                                    },
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Box sx={{ width: '72px', ml: 1 }}>
                    <ReactHookFormController
                        componentValues={AllowedFontSizes.map((s) => ({
                            label: String(s),
                            value: String(s),
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'mainFontSize',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
            </Stack>
        </Box>
    );
}
