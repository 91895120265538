import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { FormLayoutType } from 'components/common/form';
import { useBlocker } from 'react-router-dom';
import { Action as HistoryAction } from '@remix-run/router/dist/history';
import { useUserPreference } from 'hooks/useUserPrivilege';

function useConfirmExit(confirmExit: () => boolean, when: boolean) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;

        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit();
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}

export default function useOnLeavePrompt(message: string, when: boolean, formLayout: FormLayoutType) {
    const userPref = useUserPreference('navigate_away_unsaved_changes_handling');
    if (userPref == 'discard') {
        when = false;
    }

    const { navigator } = useContext(NavigationContext);

    const [historyAction, setHistoryAction] = useState<HistoryAction>();
    const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
        setHistoryAction(historyAction);
        if (!(currentLocation.pathname !== nextLocation.pathname && when && formLayout == 'page')) {
            return false;
        }
        if (historyAction == 'REPLACE') {
            return false;
        }
        if (historyAction == 'PUSH') {
            return true;
        } else {
            if (confirmExit()) {
                return false;
            } else {
                setTimeout(() => {
                    navigator.replace(currentLocation.pathname + currentLocation.hash);
                }, 100);

                return true;
            }
        }
    });
    const confirmExit = useCallback(() => window.confirm(message), [message]);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            if (historyAction == 'POP' || historyAction == 'REPLACE') {
                blocker.reset();
                return;
            }

            if (confirmExit()) {
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
    }, [blocker.state]);

    useEffect(() => {
        if (formLayout != 'page') {
            return;
        }
        const handleTabClose = (event: Event) => {
            if (when) {
                event.preventDefault();
                // @ts-ignore
                return (event.returnValue = message);
            }
        };
        window.addEventListener('beforeunload', handleTabClose);
        return () => window.removeEventListener('beforeunload', handleTabClose);
    }, []);

    useEffect(() => {
        if (formLayout != 'page') {
            return;
        }
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);

    useConfirmExit(confirmExit, when && formLayout == 'page');
}
