import { useSelector } from 'react-redux';
import { UserInfo } from 'store/auth';

export const useIsAdmin = () => {
    return useSelector((state: any) => state.auth?.userInfo?.isAdmin);
};

export const useIsPowerUser = () => {
    return useSelector((state: any) => state.auth?.userInfo?.isPowerUser);
};

export const useUserInfo: () => UserInfo = () => {
    return useSelector((state: any): UserInfo => state?.auth?.userInfo);
};

export const useIsSystemAdmin = () => {
    return useSelector((state: any) => state.auth?.userInfo?.isSystemAdmin);
};

export const useIsUserHasPrivilege = (privilegeName: string) => {
    const userPrivileges = useSelector((state: any) => state.auth?.userInfo?.privileges);
    return userPrivileges?.[privilegeName];
};

export const useIsAdminOrHasPrivilege = (privilegeName: string) => {
    return useIsAdmin() || useIsUserHasPrivilege(privilegeName);
};

export function useUserPreference(preference: string) {
    const userPrivileges = useSelector((state: any) => state.auth?.userInfo?.preferences);
    return userPrivileges?.[preference] ?? null;
}
