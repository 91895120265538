import { ReportContentNS } from 'components/report-content/index';
import BlockType = ReportContentNS.BlockType;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import BlockComponent = ReportContentNS.BlockComponent;
import ContentSettings = ReportContentNS.ContentSettings;
import ComponentSettings = ReportContentNS.ComponentSettings;
import { ReportContentActions } from 'app/editor/report';
import React, { Component, useEffect } from 'react';
import ComponentSettingsLineChart = ReportContentNS.ComponentSettingsLineChart;
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import DatasetComponentBuilder from 'components/report-content/components/dataset/DatasetComponentBuilder';
import PivotComponentBuilder from 'components/report-content/components/pivot/PivotComponentBuilder';
import LineChartComponentBuilder from 'components/report-content/components/line-chart/LineChartComponentBuilder';
import ExternalVisualizationComponentBuilder from 'components/report-content/components/external-visualization/ExternalVisualizationComponentBuilder';
import ManageFiltersComponentBuilder from 'components/report-content/components/manage-filters/ManageFiltersComponentBuilder';
import { alpha, Box, Stack } from '@mui/material';
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;

import PieChartComponentBuilder from 'components/report-content/components/pie-chart/PieChartComponentBuilder';
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import BubbleChartComponentBuilder from 'components/report-content/components/bubble-chart/BubbleChartComponentBuilder';
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import FunnelChartComponentBuilder from 'components/report-content/components/funnel-chart/FunnelChartComponentBuilder';
import ComponentSettingsFunnelChart = ReportContentNS.ComponentSettingsFunnelChart;
import RangeChartComponentBuilder from 'components/report-content/components/range-chart/RangeChartComponentBuilder';
import ComponentSettingsRangeChart = ReportContentNS.ComponentSettingsRangeChart;
import MapChartComponentBuilder from 'components/report-content/components/map-chart/MapChartComponentBuilder';
import ComponentSettingsMapChart = ReportContentNS.ComponentSettingsMapChart;
import TextBlockComponentBuilder from 'components/report-content/components/text-block/TextBlockComponentBuilder';
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;
import BlockTopControls from 'components/report-content/block-top-controls/BlockTopControls';
import DatasetField = ReportContentNS.DatasetField;
import ComponentSettingsJct = ReportContentNS.ComponentSettingsJct;
import JctComponentBuilder from 'components/report-content/components/jct/JctComponentBuilder';
export default function ReportContentBlock({
    contentSettings,
    block,
    actions,
    isFilterBlock,
    datasetFields,
}: {
    contentSettings: ContentSettings;
    block: BlockType<ComponentSettings>;
    actions: ReportContentActions;
    isFilterBlock?: boolean;
    datasetFields: Array<DatasetField>;
}) {
    let component: any = 'ComponentNotFound: ' + block.component.internalName;
    let editPanel: any = 'ComponentNotFound: ' + block.component.internalName;
    let title: string = block.component.settings?.title?.length ? block.component.settings.title : '';
    let componentControls = null;
    let componentSettings;

    const updateBlockSettings = (newSettings: any, options?: { shouldDirty?: boolean }) => {
        actions.updateBlockSettings(block.uid, newSettings, options);
    };

    let builder;
    switch (block.component.internalName) {
        case 'dataset':
            componentSettings = block.component as BlockComponent<ComponentSettingsDataset>;
            builder = new DatasetComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsDataset>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'pivot':
            componentSettings = block.component as BlockComponent<ComponentSettingsPivot>;
            builder = new PivotComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsPivot>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'line':
            builder = new LineChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsLineChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'chart':
            builder = new PieChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsPieChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'bubble_chart':
            builder = new BubbleChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsBubbleChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'funnel_chart':
            builder = new FunnelChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsFunnelChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'range_chart':
            builder = new RangeChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsRangeChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'map_chart':
            builder = new MapChartComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsMapChart>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'text_block':
            builder = new TextBlockComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsTextBlock>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'external_visualization':
            builder = new ExternalVisualizationComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsExternalVisualization>,
                updateBlockSettings,
                actions,
            );
            break;
        case 'manage_filters':
            builder = new ManageFiltersComponentBuilder(
                contentSettings,
                block as BlockType<ComponentSettingsManageFilters>,
                updateBlockSettings,
                actions,
            );
            break;
    }

    // Exception for Jct
    const jct = block.component.internalName.match(/jct_([0-9]+)/);
    if (jct) {
        builder = new JctComponentBuilder(
            contentSettings,
            block as BlockType<ComponentSettingsJct>,
            updateBlockSettings,
            actions,
        );
    }

    if (builder) {
        editPanel = builder.getEditPanel();
        componentControls = builder.getComponentControls();
        if (block.component.settings.isOnCreation === true) {
            component = <NotConfirmedComponent block={block} actions={actions} editPanel={editPanel} />;
        } else {
            component = builder.getComponent(editPanel);
        }
    }
    component = <ComponentErrorBoundary>{component}</ComponentErrorBoundary>;

    //ToDO Stas: css fixed/sticky style and add ability to set position
    if (isFilterBlock) {
        return (
            <span
                id={`report_block_${block.component.datasetRendererBlockComponentId}`}
                data-test={`report-block-filter${block.component.datasetRendererBlockComponentId}`}
            >
                {component}
                {contentSettings.context == 'edit' && <Box sx={{ flexShrink: 0, p: 0.75 }}>{componentControls}</Box>}
            </span>
        );
    }

    return (
        <Box id={`report_block_${block.component.datasetRendererBlockComponentId}`}>
            {title.length > 0 &&
                !contentSettings.forScreenShot &&
                (contentSettings.context != 'edit' || block.component.settings.is_collapsed_ind == 'N') && (
                    <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        sx={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, pb: 1 }}
                    >
                        {title}
                    </Stack>
                )}
            <Box
                sx={{
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    borderRadius: contentSettings.context == 'edit' ? '0 1px 1px 0' : 1,
                }}
            >
                {contentSettings.context == 'edit' && (
                    <BlockTopControls
                        editPanel={editPanel}
                        actions={actions}
                        add={componentControls}
                        block={block}
                        datasetFields={datasetFields}
                    />
                )}
                <Box
                    data-test={`report-block-${block.component.internalName}`}
                    className={
                        block.component.settings.is_collapsed_ind == 'Y' && contentSettings.context == 'edit'
                            ? 'd-none'
                            : ''
                    }
                >
                    {component}
                </Box>
            </Box>
        </Box>
    );
}

function NotConfirmedComponent({
    block,
    actions,
    editPanel,
}: {
    block: BlockType<ComponentSettings>;
    actions: ReportContentActions;
    editPanel: any;
}) {
    useEffect(() => {
        actions.openBlockEditor(block.uid, editPanel);
    }, []);

    return <div>Component not confirmed</div>;
}

class ComponentErrorBoundary extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <div>Report Component is broken =(</div>;
        }
        return this.props.children;
    }
}
