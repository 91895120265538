import {
    ChartBasicSeriesTypeRaw,
    ChartSeriesDataType,
    ChartSeriesType,
    ChartSeriesTypeRaw,
    DATA_X_MULTIPLIER,
    MetricChartDataType,
    MetricChartOptionsType,
    MetricChartState,
} from './index';
import { Chart as HChart } from 'highcharts';
import { ElementRowData, ElementType } from 'components/element-viewer';
import { getNegativeSeriesColor } from 'components/metric/index';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export interface ChartSeriesProcessParams {
    elementInfo: ElementType;
    series: ChartSeriesTypeRaw;
    projectionData: Array<any>;
    targetSeriesData: Array<ChartBasicSeriesTypeRaw>;
    metricChartOptions: MetricChartOptionsType;
    chartState: MetricChartState;
    t: any; // i18 translation
    chart: HChart;
    theme: Theme;
}

export function processMetricSeries(
    chartData: MetricChartDataType,
    chartState: MetricChartState,
    elementRow?: ElementRowData,
    isThumbnail?: boolean,
): Array<ChartSeriesType> {
    const series = chartData.seriesData;
    return series.map((s, i) => {
        let metricDisplayValueForPoint = !isThumbnail && s.metricDisplayValueForPoint;
        if (elementRow?.metricDisplayValueForPoint && i == 0) {
            metricDisplayValueForPoint = !isThumbnail && elementRow.metricDisplayValueForPoint;
        }
        const id = 'chart_series_' + (s.id ?? i);

        // Zones for NULL values
        const zones: any = [];
        const hasZones = chartData.chartSettings.data_gap_line_type != 'none';
        let prevPoint = { x: null, y: null };

        let type = elementRow?.metricChartDisplayType ?? s.metricChartDisplayType ?? 'line';
        type = type == 'bar' ? 'column' : type;
        if (elementRow?.metricChartLineStyle == 'smooth' && type == 'line') {
            type = 'spline';
        }

        // Change color of negative points for bar chart
        let changeNegativeColor =
            type == 'column' &&
            (elementRow?.metricChangeBarColorOnValueInd ?? s.metricChangeBarColorOnValueInd ?? false);
        let negativeColor: string = '';

        let seriesMaximum: number | null = null;
        let seriesMinimum: number | null = null;

        const result: ChartSeriesType = {
            type: type,
            connectNulls: hasZones,
            name: s.name ?? id,
            id: id,
            seriesType: 'main',
            showInNavigator: true,
            dataLabels: {
                enabled: !isThumbnail && metricDisplayValueForPoint == 'Y',
                formatter: function () {
                    //@ts-ignore
                    return this.point?.metadata?.value_formatted ?? this.y;
                    // return self.formatValue({ value: this.y }, 1 == yAxisNumber ? 'right' : 'left');
                },
            },
            metricUnusualValueStdDev: s.metricUnusualValueStdDev,
            data: s.data
                // .sort((a, b) => a.x - b.x)
                .map((row) => {
                    seriesMaximum = null === seriesMaximum ? row.y : Math.max(seriesMaximum, row.y);
                    seriesMinimum = null === seriesMinimum ? row.y : Math.min(seriesMinimum, row.y);
                    // Filter Min\Max allowed values for chart
                    if (
                        (elementRow?.metricMaxValueToChart && row.y > Number(elementRow.metricMaxValueToChart)) ||
                        (elementRow?.metricMinValueToChart && row.y < Number(elementRow.metricMinValueToChart))
                    ) {
                        return null;
                    }

                    if (hasZones) {
                        if (row.y == null && prevPoint.y != null && prevPoint.x != null) {
                            // Collect data about NULL values
                            zones.push({
                                value: prevPoint.x * DATA_X_MULTIPLIER,
                                dashStyle: s.dashType,
                            });
                        } else if (row.y != null && prevPoint.y == null) {
                            zones.push({
                                value: row.x * DATA_X_MULTIPLIER,
                                dashStyle: 'Dash',
                            });
                        }
                        prevPoint = row;
                    }

                    const point: any = {
                        x: row.x * DATA_X_MULTIPLIER,
                        y: row.y,
                        metadata: row.mydata ?? null,
                    };

                    // Change color of negative points for bar chart
                    if (changeNegativeColor && row.y < 0) {
                        if (negativeColor == '') {
                            negativeColor =
                                chartData.chartSettings.bar_negative_value_color ?? getNegativeSeriesColor(i);
                        }

                        point.color = negativeColor;
                        point.states = { hover: { color: negativeColor } };
                    }
                    return point;
                })
                .filter((point) => point != null),
            visible: chartState.legend ? (chartState.legend[id]?.visible ?? true) : true,
        };
        if (hasZones) {
            // result.zones = zones;
            // result.zoneAxis = 'x';
        }
        result.max = seriesMaximum == null ? 0 : seriesMaximum;
        result.min = seriesMinimum == null ? 0 : seriesMinimum;

        return result;
    });
}

export function buildMovingAverageSeries({
    series,
    metricChartOptions,
    t,
    chartState,
    theme,
}: ChartSeriesProcessParams): ChartSeriesType {
    const id = 'chart_series_moving_average';
    return {
        type: 'line',
        name: t('chart_label_last_moving_average', { amount: metricChartOptions.movingAveragePeriod }),
        id: 'chart_series_moving_average',
        seriesType: 'movingAverage',
        color: theme.componentSettings.highstockChart.movingAverage,
        enableMouseTracking: false,
        data: series.data.map((row) => {
            return {
                x: row.x * DATA_X_MULTIPLIER,
                y: row.mydata.raw_moving_avg_value ?? null,
                metadata: row.mydata ?? null,
            };
        }),
        visible: chartState.legend ? (chartState.legend[id]?.visible ?? true) : true,
    };
}

export function buildStatisticalSeries({
    series,
    metricChartOptions,
    t,
    chartState,
    theme,
}: ChartSeriesProcessParams): ChartSeriesType {
    const id = 'chart_label_standard_deviation';
    const result: ChartSeriesType = {
        name: t('chart_label_standard_deviation', { amount: metricChartOptions.metricUnusualValueStdDev }),
        id: id,
        seriesType: 'statistical',
        fillColor: theme.componentSettings.highstockChart.statisticalFill,
        color: theme.componentSettings.highstockChart.statistical,
        type: 'arearange',
        enableMouseTracking: false,
        zIndex: -1,
        data: series.data
            .filter((row) => row?.mydata?.raw_standard_deviation_value != null)
            .map((row) => {
                const dev = row.mydata.raw_standard_deviation_value * (series.metricUnusualValueStdDev ?? 0);
                return {
                    x: row.x * DATA_X_MULTIPLIER,
                    low: row.mydata.raw_moving_avg_value - dev,
                    high: row.mydata.raw_moving_avg_value + dev,
                    metadata: row.mydata ?? null,
                };
            }),
        visible: chartState.legend ? (chartState.legend[id]?.visible ?? true) : true,
    };
    return result;
}

export function getStoplightSeries({
    series,
    t,
    chart,
    chartState,
    theme,
    elementInfo,
}: ChartSeriesProcessParams): Array<ChartSeriesType> {
    const seriesBaseName = (series.name ?? t('chart_label_stoplight')) + ' ';
    let dataMax = 0,
        dataMin = 0;

    var tmp = chart.yAxis[0].getExtremes();
    if (dataMax < tmp.dataMax) {
        dataMax = tmp.dataMax;
    }

    if (series.metricMoreIsBetterInd == 'RANGE') {
        dataMin = dataMax;
        tmp = chart.yAxis[0].getExtremes();
        if (dataMin > tmp.dataMin) {
            dataMin = tmp.dataMin;
        }
    }

    series.data.forEach((row) => {
        if (dataMax < row.mydata['raw_stoplight_bad_threshold_value']) {
            dataMax = row.mydata['raw_stoplight_bad_threshold_value'];
        }
        if (dataMax < row.mydata['raw_stoplight_good_threshold_value']) {
            dataMax = row.mydata['raw_stoplight_good_threshold_value'];
        }
        if (dataMax < row.y) {
            dataMax = row.y;
        }
    });

    if (series.metricMoreIsBetterInd == 'RANGE') {
        series.data.forEach((row) => {
            if (dataMin > row.mydata['raw_stoplight_bad_threshold_value']) {
                dataMin = row.mydata['raw_stoplight_bad_threshold_value'];
            }
            if (dataMin > row.mydata['raw_stoplight_good_threshold_value']) {
                dataMin = row.mydata['raw_stoplight_good_threshold_value'];
            }
        });
    }
    const idRed = 'stoplight_red_series_' + series.id;
    let lowColor =
        series.metricMoreIsBetterInd == 'RANGE'
            ? theme.componentSettings.highstockChart.stoplightGrayAlpha
            : theme.componentSettings.highstockChart.stoplightGray;
    if (elementInfo.row.metricStoplightColorForLowValues) {
        lowColor = alpha(elementInfo.row.metricStoplightColorForLowValues, 0.4);
    }
    const redSeries: ChartSeriesType = {
        id: idRed,
        name: seriesBaseName + t('color_red'),
        seriesType: 'stoplight',
        type: series.metricMoreIsBetterInd == 'RANGE' ? 'arearange' : 'area',
        threshold: series.metricMoreIsBetterInd == 'N' ? dataMax : null,
        data: [],
        enableMouseTracking: false,
        color: lowColor,
        zIndex: -1,
        visible: chartState.legend ? (chartState.legend[idRed]?.visible ?? true) : true,
    };

    const idGreen = 'stoplight_green_series_' + series.id;
    let highColor =
        series.metricMoreIsBetterInd == 'RANGE'
            ? theme.componentSettings.highstockChart.stoplightGreenAlpha
            : theme.componentSettings.highstockChart.stoplightGreen;
    if (elementInfo.row.metricStoplightColorForHighValues) {
        highColor = alpha(elementInfo.row.metricStoplightColorForHighValues, 0.4);
    }

    const greenSeries: ChartSeriesType = {
        id: idGreen,
        name: seriesBaseName + t('color_green'),
        seriesType: 'stoplight',
        type: series.metricMoreIsBetterInd == 'RANGE' ? 'arearange' : 'area',
        threshold: series.metricMoreIsBetterInd == 'Y' ? dataMax : null,
        data: [],
        enableMouseTracking: false,
        color: highColor,
        zIndex: -1,
        visible: chartState.legend ? (chartState.legend[idGreen]?.visible ?? true) : true,
    };

    series.data.forEach((row) => {
        if ('undefined' == typeof row.mydata['raw_stoplight_bad_threshold_value']) {
            return;
        }

        const pointRed: ChartSeriesDataType = { x: row.x * DATA_X_MULTIPLIER };
        const pointGreen: ChartSeriesDataType = { x: row.x * DATA_X_MULTIPLIER };

        if (series.metricMoreIsBetterInd == 'Y') {
            pointRed.y = row.mydata['raw_stoplight_bad_threshold_value'];
            pointGreen.y = row.mydata['raw_stoplight_good_threshold_value'];
        } else if (series.metricMoreIsBetterInd == 'N') {
            pointRed.y = row.mydata['raw_stoplight_good_threshold_value'];
            pointGreen.y = row.mydata['raw_stoplight_bad_threshold_value'];
        } else if (series.metricMoreIsBetterInd == 'RANGE') {
            pointRed.low = dataMin;
            pointRed.high = dataMax;
            pointGreen.low = row.mydata['raw_stoplight_good_threshold_value'];
            pointGreen.high = row.mydata['raw_stoplight_bad_threshold_value'];
        }
        if (series.metricMoreIsBetterInd != 'N/A') {
            redSeries.data.push(pointRed);
            greenSeries.data.push(pointGreen);
        }
    });

    return [redSeries, greenSeries];
    // TODO: refactor next block:

    //    var ext = cv.highchart.chart.xAxis[0].getExtremes();
    // var ext = self.chart.xAxis[0].getExtremes();
    // Forever alone point
    // if (d.data.length == 1) {
    //     tmpRed.data[1] = {
    //         x: ext.max,
    //         y: tmpRed.data[0].y,
    //     };
    //     tmpRed.data[0].x = ext.min;
    //
    //     tmpGreen.data[1] = {
    //         x: ext.max,
    //         y: tmpGreen.data[0].y,
    //     };
    //     tmpGreen.data[0].x = ext.min;
    // }

    // if (d.data.length == 1) {
    //     setTimeout(function () {
    //         self.chart.xAxis[0].setExtremes(ext.min, ext.max);
    //     }, 100);
    // }
}

export function getProjectionSeries({
    series,
    projectionData,
    t,
    chart,
    chartState,
    theme,
}: ChartSeriesProcessParams): ChartSeriesType {
    var extremes = chart.xAxis[0].getExtremes(),
        max = extremes.max,
        dataSeries = chart.get('chart_series_' + series.id);

    // // this.projectionMaxExtremes = extremes;
    // if (!dataSeries?.options?.data?.length) {
    //     this.hideChartLoading();
    //     return;
    // }

    const id = 'projection_series' + series.id;
    const result: ChartSeriesType = {
        id: id,
        name: t('chart_label_projection'),
        seriesType: 'projection',
        // pointPlacement: 'on',
        // type: dataSeries.type,
        dashStyle: 'Dot',
        color: theme.componentSettings.highstockChart.projection,
        // linkedTo: 'dataSeries_' + this.mainSeriesId,
        data: projectionData.map(function ($v) {
            $v.x *= DATA_X_MULTIPLIER;
            // $v.y *= 1.0;

            // if (max < $v.x) max = $v.x;

            return $v;
        }),
        // point: {},
        marker: {
            enabled: true,
            radius: 3,
            symbol: 'circle',
        },
        visible: chartState.legend ? (chartState.legend[id]?.visible ?? true) : true,
        // zIndex: 0,
        // yAxis: dataSeries.options.yAxis,
        // point: {
        //     events: {
        //         mouseOver: function () {
        //             self.showPointTooltip(this);
        //         },
        //         mouseOut: function () {
        //             self.hidePointTooltip(this);
        //         },
        //     },
        // },
    };

    // TODO: need to implement ?
    // if ('column' != tmp.type && tmp.data.length) {
    //     var lastDataSeriesPoint = dataSeries.options.data[dataSeries.options.data.length - 1];
    //     if (
    //         'undefined' != typeof lastDataSeriesPoint.mydata &&
    //         'undefined' != typeof lastDataSeriesPoint.mydata.isGhost &&
    //         true == lastDataSeriesPoint.mydata.isGhost
    //     ) {
    //         lastDataSeriesPoint = dataSeries.options.data[dataSeries.options.data.length - 2];
    //     }
    //
    //     tmp.data.unshift({
    //         x: lastDataSeriesPoint.x,
    //         y: lastDataSeriesPoint.y,
    //         marker: {
    //             enabled: false,
    //         },
    //     });
    // }

    // this.addChartSeries(tmp);
    // if (this.state.get('isRangeChanged') !== true && max != extremes.max) {
    //     this.chart.xAxis[0].setExtremes(extremes.min - 1, max + 1);
    // }
    // this.hideChartLoading();
    // self.componentLoaded('projection');
    return result;
}

export function getTargetSeries({
    series,
    targetSeriesData,
    chartState,
    theme,
}: ChartSeriesProcessParams): Array<ChartSeriesType> {
    return targetSeriesData.map((target) => {
        const id = 'target_series_' + series.id + '_' + target.id;
        return {
            id: id,
            name: target.name ?? id,
            seriesType: 'target',
            color: target.color ?? theme.componentSettings.highstockChart.targetLine,
            // type: lineType,
            dashStyle: target.dashType,
            data: target.data,
            enableMouseTracking: false,
            // zIndex: 0,
            visible: chartState.legend ? (chartState.legend[id]?.visible ?? true) : true,
        };
    });
}

export const findPointsInPeriod = function (
    chart: HChart,
    data: Array<{ x: number; y?: number }>,
    startDate: any,
    endDate: any,
) {
    var points = [
            { x: startDate, y: 0 },
            { x: endDate, y: 0 },
        ],
        maxValue = null,
        xAxis = chart.xAxis[0];

    // console.log(points);
    if (xAxis == null) {
        return points;
    }
    for (var i in data) {
        if (!data.hasOwnProperty(i)) {
            continue;
        }

        var point = data[i];
        //@ts-ignore
        if (point.x < xAxis?.min || point.x > xAxis?.max) continue;

        if (point.x >= startDate && point.x <= endDate) {
            if (null == maxValue) {
                maxValue = point.y;
            } else {
                maxValue = Math.max(maxValue, point.y ?? 0);
            }
        }
    }

    if (null != maxValue) {
        points[0].y = maxValue;
        points[1].y = maxValue;
    }

    return points;
};
