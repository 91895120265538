import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Chip, Tooltip } from '@mui/material';
import PreviewAttachImagePopup from 'components/wall/parts/PreviewAttachImagePopup';
import IconMi from 'components/common/icon/IconMi';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';

export interface PartItemWallSharedFilesProps {
    data: any;
    elementType: string;
    listMode?: boolean;
}

export default function PartItemWallSharedFiles(props: PartItemWallSharedFilesProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const [showPreviewImagePopup, setShowPreviewImagePopup] = useState<string>('');
    const { data, elementType, listMode } = props;
    const navigate = useNavigate();

    if (!(data.image_filename || data.shared_files)) return null;

    const isImage = /.*?\.(jpg|jpeg|png|gif|bmp)$/i;
    const isWord = /.*?\.(doc|docx)$/i;
    const isPdf = /.*?\.(pdf)$/i;
    const isExcel = /.*?\.(xls|xlsx|csv)$/i;
    const isGif = /.*?\.(gif)$/i;
    let files: any = {};

    if (data.shared_files) {
        try {
            files = JSON.parse(data.shared_files);
        } catch ($e) {
            files = {};
        }
    }

    if (data.image_filename) {
        files[0] = { id: 0, name: data.image_filename };
    }

    return (
        <>
            {Object.values(files)
                .filter((item: any) => item?.name)
                .map((file: any) => {
                    const propKey = `user_${elementType}_id`;
                    let urlParams: any = {
                        type: elementType,
                        propKey: data[propKey],
                        filename: encodeURIComponent(file.name),
                    };
                    if (file.id != 0) {
                        urlParams.shared_file = file.id;
                    }
                    if (file.name.match(isImage)) {
                        const fullImageUrl = `/data/dashboard/wall/image?${qs.stringify(urlParams)}`;

                        if (!file.name.match(isGif)) {
                            urlParams.thumb = elementType;
                        }

                        const previewImageUrl = `/data/dashboard/wall/image?${qs.stringify(urlParams)}`;

                        return (
                            <Box
                                className={`wall_${elementType}_image_td`}
                                sx={{ display: 'inline-block', mr: !listMode ? 1 : undefined }}
                                key={file.id + '-' + file.name}
                            >
                                <Tooltip
                                    title={
                                        <Box>
                                            {data.image_caption ?? ''}
                                            <Box>{t('shared_files.enlarge_tooltip')}</Box>
                                        </Box>
                                    }
                                >
                                    {listMode ? (
                                        <Chip
                                            sx={{
                                                mr: 0.5,
                                                mb: 0.25,
                                                '.MuiChip-label': {
                                                    pl: 0.25,
                                                },
                                            }}
                                            label={file.name}
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowPreviewImagePopup(fullImageUrl);
                                            }}
                                            variant="outlined"
                                            icon={<IconMi icon="file-image-o" />}
                                        />
                                    ) : (
                                        <Box
                                            component={'a'}
                                            href="#"
                                            className={`wall_${elementType}_image_a`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowPreviewImagePopup(fullImageUrl);
                                            }}
                                            sx={{ color: 'inherit' }}
                                        >
                                            <Box
                                                component="img"
                                                id={`image_user_${elementType}_popup_${data[propKey]}`}
                                                alt=""
                                                className={`wall_${elementType}_image`}
                                                sx={{ maxWidth: file.name.match(isGif) ? '550px' : 'none' }}
                                                src={previewImageUrl}
                                            />
                                            <Box className={`wall_${elementType}_file_caption`}>
                                                <span className="additional_label_text">
                                                    {t('shared_files.show_larger_label')}:
                                                </span>
                                                <i>{file.name}</i>
                                            </Box>
                                        </Box>
                                    )}
                                </Tooltip>
                            </Box>
                        );
                    } else if (file.name.match(isExcel) || file.name.match(isWord) || file.name.match(isPdf)) {
                        const fileUrl = `/data/dashboard/wall/file?${qs.stringify(urlParams)}`;

                        return (
                            <Box
                                className={`wall_${elementType}_file`}
                                sx={{ display: 'inline-block' }}
                                key={file.id + '-' + file.name}
                            >
                                <Tooltip title={t('shared_files.download_file_tooltip')}>
                                    {listMode ? (
                                        <Chip
                                            sx={{
                                                mr: 0.5,
                                                '.MuiChip-label': {
                                                    pl: 0.25,
                                                },
                                            }}
                                            label={file.name}
                                            color="primary"
                                            onClick={(e) => {
                                                window.location.href = fileUrl;
                                            }}
                                            variant="outlined"
                                            icon={<IconMi icon="download" />}
                                        />
                                    ) : (
                                        <Box component="a" href={fileUrl} sx={{ color: 'inherit' }}>
                                            <IconMi icon={'file'} sx={{ mr: 0.5 }} />
                                            <span className={`wall_${elementType}_file_caption`}>
                                                <span className="additional_label_text">
                                                    {t('shared_files.download_label')}:{' '}
                                                </span>
                                                <i>{file.name}</i>
                                            </span>
                                        </Box>
                                    )}
                                </Tooltip>
                            </Box>
                        );
                    }
                    return null;
                })}

            {showPreviewImagePopup && (
                <PreviewAttachImagePopup
                    onClose={() => {
                        setShowPreviewImagePopup('');
                    }}
                    imageUrl={showPreviewImagePopup}
                />
            )}
        </>
    );
}
