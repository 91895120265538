import { FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormControlReferenceSelectionProps, ReferenceInfoResponse, ReferenceRow } from './index';
import { useQuery } from '@tanstack/react-query';
import { referenceAPI } from 'api/reference';
import React, { useEffect, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import ReferenceSelectionForm from './ReferenceSelectionForm';
import { Box, Button, FormLabel, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import style from './ReferenceSelection.style';
import useBundleTranslation from 'i18n';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import { FormComponentBuilder } from 'components/common/form/layout';

export default function ReferenceSelection({
    controlProps,
}: FormElementControlPropsType<FormControlReferenceSelectionProps>) {
    const { t } = useBundleTranslation(['components/common/reference_selection']);
    const dispatch = useDispatch();

    const [defaultProfileId, setDefaultProfileId] = useState<number>(0);

    const [collectionId, setCollectionId] = useState(
        Number(controlProps.form.hookFormGetValues('plugin_connection_profile_collection_id') ?? 0),
    );

    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'plugin_connection_profile_collection_id') {
                let cId = value['plugin_connection_profile_collection_id'];
                setCollectionId(Number(cId));
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    // Get selected reference info
    let queryKey = ['reference', controlProps.profileId, controlProps.value, collectionId, controlProps.collectionMode];

    const { data: infoRequestData } = useQuery<ReferenceInfoResponse, Error>({
        queryKey: queryKey,
        queryFn: () =>
            referenceAPI.getReferenceInfo(
                controlProps.profileId,
                Number(controlProps.value),
                controlProps.collectionMode == 'Y' ? collectionId : 0,
            ),
    });

    // Build input title
    const [title, setTitle] = useState<string>(t('select_report'));
    const [selectedReference, setSelectedReference] = useState<ReferenceRow | null>(null);
    useEffect(() => {
        if (typeof infoRequestData?.reference == 'undefined') {
            return;
        }
        if (infoRequestData.reference == false) {
            setSelectedReference(null);
        } else {
            setSelectedReference(infoRequestData.reference);
        }
    }, [infoRequestData?.reference]);

    useEffect(() => {
        let title = '';
        if (selectedReference == null) {
            title = t('select') + ' ' + controlProps.reportLabel;
        } else {
            selectedReference.parents.forEach((parent) => {
                title = parent.name + ' / ' + title;
            });
        }

        setTitle(title);
        // Update component Shared State
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { ...selectedReference, title: title + selectedReference?.name },
            }),
        );
    }, [selectedReference?.external_report_reference_id, controlProps.reportLabel]);

    // Form popup
    const [editPopupActive, setEditPopupActive] = useState<boolean>(false);
    const handleShowEditPopup = () => setEditPopupActive(true);
    const handleHideEditPopup = () => setEditPopupActive(false);
    const popupSettings: PopupSettings = {
        title: t('select') + ' ' + controlProps.reportLabel,
        needTranslation: false,
        noButtons: true,
    };

    const [valueWasChangedByUser, setValueWasChangedByUser] = useState(false);
    const handleReferenceSelect = (referenceId: number, collectionId: number) => {
        controlProps.onChange(referenceId);
        controlProps.form.hookFormSetValue('plugin_connection_profile_collection_id', collectionId);
        setValueWasChangedByUser(true);
        setEditPopupActive(false);
    };

    useEffect(() => {
        if (Number(controlProps.profileId) != controlProps.profileId) {
            return;
        }
        if (
            valueWasChangedByUser ||
            (defaultProfileId != 0 && controlProps.profileId != 0 && defaultProfileId != controlProps.profileId)
        ) {
            controlProps.onChange(0);
        }

        if (defaultProfileId != 0 || controlProps.profileId == 0) {
            return;
        }
        setDefaultProfileId(controlProps.profileId);
    }, [controlProps.profileId]);

    return (
        <>
            <FormLabel style={{ display: 'block' }}>{controlProps.label}</FormLabel>
            <Stack direction="row" onClick={handleShowEditPopup} sx={style.wrapper}>
                <Box data-test={controlProps.name + '-input'} sx={style.field}>
                    <IconMi icon="external-report" sx={style.itemIcon} />
                    <Box className="pathHolder" sx={style.pathHolder}>
                        {title}
                        {selectedReference && <Box component="strong">{selectedReference.name}</Box>}
                    </Box>
                </Box>
                <Button
                    data-test={controlProps.name + '-edit-button'}
                    startIcon={<IconMi icon="gear" />}
                    sx={style.btn}
                    className={'min-width--reset'}
                >
                    {t('edit_btn')}
                </Button>
            </Stack>
            <Popup settings={popupSettings} open={editPopupActive} onHide={handleHideEditPopup}>
                <ReferenceSelectionForm
                    onSelectReference={handleReferenceSelect}
                    profileId={controlProps.profileId}
                    referenceId={Number(controlProps.value)}
                    capability={controlProps.capability}
                />
            </Popup>
        </>
    );
}

export class ReferenceSelectionBuilder extends FormComponentBuilder {
    prepareProps(): FormControlReferenceSelectionProps {
        return {
            ...this.controlProps,
            profileId: this.elementProps.componentProps.profileId ?? 0,
            reportLabel: this.elementProps.componentProps.reportLabel ?? '',
            capability: this.elementProps.componentProps.capability ?? 'DATA',
            collectionMode: this.elementProps.componentProps.collectionMode ?? 'N',
        };
    }
}
