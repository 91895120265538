import { Button, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useMemo } from 'react';
import Group from './condition/Group';
import { ColumnData, GroupRule, RulesConfig } from '../index';
import useBundleTranslation from 'i18n';

function ConditionBlock({
    filter,
    fields,
    builderDirty,
    applyChanges,
    onCurrentFilterUpdate,
    compactView,
    showGroupApply,
}: {
    filter: RulesConfig;
    fields: ColumnData[];
    builderDirty: boolean;
    applyChanges: () => void;
    onCurrentFilterUpdate: (newFilter: RulesConfig) => void;
    compactView?: boolean;
    showGroupApply?: boolean;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const labelMain = filter.history ? t('condition.define_filters_changes') : t('condition.define_filters');
    const labelNew = filter.history
        ? t('condition.define_filters_for.main') +
          (filter.trackNewRows ? ` ${t('condition.define_filters_for.part_new')}` : '') +
          (filter.trackNewRows && filter.trackRemovedRows ? ` ${t('condition.define_filters_for.part_and')}` : '') +
          (filter.trackRemovedRows ? ` ${t('condition.define_filters_for.part_removed')}` : '')
        : '';

    const showChangesBlock = (filter.history && filter.trackChangedRows) || !filter.history;
    const showNewBlock = filter.history && (filter.trackNewRows || filter.trackRemovedRows);

    const currentFields = useMemo(
        () =>
            fields.map((field) => {
                const newField = { ...field };
                const key = field.derived ? field.id : field.id.replace(' ', '_');

                newField.key = `${key}-current`;

                return newField;
            }),
        [fields],
    );

    const priorFields = useMemo(() => {
        const newFields = [...currentFields];

        if (filter.history) {
            currentFields.forEach((field) => {
                const priorField = { ...field };
                const key = field.derived ? field.id : field.id.replace(' ', '_');
                priorField.label = `Prior ${priorField.label}`;
                priorField.key = `${key}-prior`;
                priorField.current = false;
                newFields.push(priorField);
            });
        }

        return newFields;
    }, [currentFields, filter.history]);

    const onGroupUpdate = (newRule: GroupRule, isNew: boolean = false) => {
        const newFilter = { ...filter };

        if (isNew) {
            newFilter.group_new = newRule;
        } else {
            newFilter.group = newRule;
        }

        onCurrentFilterUpdate(newFilter);
    };

    const applyButton = (
        <Button
            data-test={'apply-button'}
            variant={'contained'}
            color={'success'}
            sx={{ flexShrink: 0 }}
            startIcon={<IconMi icon={'check-square'} />}
            disabled={!builderDirty}
            onClick={applyChanges}
        >
            {t('condition.define_filters_apply_btn')}
        </Button>
    );
    if (!(currentFields.length > 0 && priorFields.length > 0)) {
        return null;
    }

    return (
        <Stack direction={'column'} spacing={2} sx={{ py: 4, px: 3 }}>
            {showChangesBlock && (
                <>
                    {!compactView && (
                        <Stack
                            data-test={'header-row'}
                            direction="row"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            <Typography variant="heading" width={'100%'}>
                                {labelMain}
                            </Typography>
                            {applyButton}
                        </Stack>
                    )}
                    {filter.group && (
                        <Group
                            compactView={compactView}
                            groupRule={filter.group}
                            groupIndex={0}
                            fields={priorFields}
                            key={`root-group-current`}
                            onUpdate={onGroupUpdate}
                            type={'current'}
                            isRoot
                            applyButton={applyButton}
                            showGroupApply={showGroupApply}
                        />
                    )}
                </>
            )}
            {filter.history && showNewBlock && (
                <>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                        <Typography variant="heading" width={'100%'}>
                            {labelNew}
                        </Typography>
                        {!showChangesBlock && applyButton}
                    </Stack>
                    {filter.group_new && (
                        <Group
                            groupRule={filter.group_new}
                            fields={currentFields}
                            key={`root-group-new`}
                            groupIndex={1}
                            onUpdate={onGroupUpdate}
                            type={'new'}
                            isRoot
                        />
                    )}
                </>
            )}
            {!showChangesBlock && !(filter.history && showNewBlock) && (
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                    <Typography variant="heading" width={'100%'}></Typography>

                    <Button
                        variant={'contained'}
                        color={'success'}
                        sx={{ flexShrink: 0 }}
                        startIcon={<IconMi icon={'check-square'} />}
                        disabled={!builderDirty}
                        onClick={applyChanges}
                    >
                        {t('condition.define_filters_apply_btn')}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
}

export default ConditionBlock;
