import { QBReportFieldData } from 'components/plugin-query-builder/data/ReportFieldClass';

export interface QBHelperActList {
    isValidTimestamps: (value: string) => boolean;
    escapeHtml: (string: string) => string;
    ucfirst: (string: string) => string;
    plural: (count: number, singular: string, plural: string) => string;
    escapeSelector: (string: string) => string;
    ucwords: (string: string) => string;
}

export interface FieldDate {
    value: string;
    year: string;
    month: string;
    day: string;
    hour: string;
    format: string;
}

export interface FilterElement {
    column: string;
    condition: string;
    value: string | null;
    type?: string;
}

export interface QBReportActList {
    updateData: (newData: any) => void;
    initData: (refresh?: boolean) => any;
    hasField: (field: string, ci?: boolean) => boolean;
    getField: (field: string) => QBReportFieldData | undefined;
    hasAlias: (alias: string, field?: string) => boolean;
    setAliasValue: (field: string, alias: string) => boolean;
    addDate: (dateData: FieldDate, name: string) => QBReportFieldData | undefined;
    hasDate: (name: string) => boolean;
    getDate: (name: string) => QBReportFieldData | undefined;
    isValidDate: (dateData: FieldDate, name?: string) => boolean;
    removeDate: (name: string) => void;
    addExpression: (expression: string, name: string) => QBReportFieldData | undefined;
    hasExpression: (name: string) => boolean;
    getExpression: (name: string) => QBReportFieldData | undefined;
    isValidExpression: (expression: string) => boolean;
    removeExpression: (name: string) => void;
    setTypeOverride: (field: string, type: string) => boolean;
}

export interface QBReportData {
    connection: any;
    fields: any;
    aliases: any;
    overrides: any;
    prompts: any;
    segments: any[];
    dateSequence: number;
    exprSequence: number;
    iterations: any;
    usedColumns: any[];
}

export interface QBQueryActList {
    updateData: (newData: any) => void;
    hasDimensions: () => boolean;
    addDimension: (dimension: string, index: number) => void;
    hasDimension: (dimension: string) => boolean;
    getDimension: (dimension: string) => object | undefined;
    removeDimension: (dimension: string) => void;
    createMetric: (field: string, operation: string) => object;
    hasMetrics: () => boolean;
    addMetric: (metric: any) => void;
    hasMetric: (metric: any) => boolean;
    getMetric: (metric: any) => object | undefined;
    removeMetric: (metric: any) => void;
    hasMetricByFieldName: (fieldName: string) => boolean;
    removeMetricByFieldName: (fieldName: string) => boolean;
    parseFields: (spec: string) => any[];
    parseSorting: (spec: string) => any[];
    addConstructedDate: (
        value: string,
        year: string,
        month: string,
        day: string,
        hour: string,
        name: string,
        format?: string,
    ) => void;
    hasConstructedDate: (name: string) => boolean;
    getConstructedDate: (name: string) => object | undefined;
    removeConstructedDate: (name: string) => void;
    addExpression: (expression: string, name: string) => void;
    hasExpression: (name: string) => boolean;
    getExpression: (name: string) => object | undefined;
    removeExpression: (name: string) => void;
    addParam: (name: string, value: any) => void;
    addFilter: (filterData: FilterElement) => void;
    removeFilter: (filter: any) => any[];
    setTableauFilter: (column: string, value: string) => void;
    addSort: (column: string, direction?: string) => void;
    removeSort: (column: string) => void;
    setQuery: (query: string) => void;
    quoteField: (field: string) => string;
    quoteFilterValue: (value: any) => string;
    removeReportFieldData: (name: string) => void;
    getQuery: () => string;
}

export interface QBQueryData {
    command: string;
    dimensions: any;
    metrics: any;
    separator: string;
    trimRegExp: any;
    bracketRegExp: any;
    quoteRegExp: any;
    dblquoteRegExp: any;
    numericRegExp: any;
    variableRegExp: any;
    dates: any;
    exprs: any;
    filters: any[];
    sort: any[];
    params: any;
    tableauFilter: any;
}

export const isPlainObject = (data: any) => {
    return data && typeof data === 'object' && !Array.isArray(data);
};

export const isValidField = (field: any) => {
    return (
        isPlainObject(field) &&
        typeof field.name === 'string' &&
        typeof field.type === 'string' &&
        field.name.length > 0 &&
        field.type.length > 0
    );
};
