import useBundleTranslation from 'i18n';
import React, { useEffect, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { IImportResult, IImportResultData } from './index';
import { GridData } from 'components/common/grid';
import StaticInfo from 'components/common/StaticInfo';
import MITable from 'components/common/grid/MITable';

export default function ImportResultPopup({
    popupOpen,
    onHide,
    onImportAgain,
    importResultData,
}: {
    popupOpen: boolean;
    onHide: () => void;
    onImportAgain: () => void;
    importResultData: IImportResultData | null;
}) {
    const { t } = useBundleTranslation('components/common/import');

    if (!importResultData) {
        return null;
    }

    const [gridData, setGridData] = useState<GridData>([]);

    const processResults = (results: IImportResult[], data: GridData, entityName: string) => {
        if (results && Array.isArray(results) && results.length > 0) {
            results.forEach((result, index) => {
                data.push({
                    num: String(data.length + 1),
                    name: `${t(entityName)}: ${result.name}`,
                    message: {
                        component: 'text',
                        config: {
                            text: result.message,
                            tooltip: result.message,
                        },
                    },
                });
            });
        }
    };

    const getHintMessage = () => {
        const collectMessagePart = importResultData.collect_data ? '.' : `, ${t('data_will_be_collected')}`;
        let message = '';

        if (importResultData.failed > 0 && importResultData.success > 0) {
            message = `${t('some_imported', {
                real_elements_success: importResultData.real_elements_success,
            })}${collectMessagePart}`;
        } else {
            message = `${importResultData.success !== importResultData.total ? t('failure') + ' ' : ''}${t('imported', {
                real_elements_success: importResultData.real_elements_success,
            })}${collectMessagePart}`;
        }

        return message;
    };

    const messageType =
        importResultData.failed > 0 || importResultData.success !== importResultData.total ? 'error' : 'success';

    useEffect(() => {
        const data: GridData = [];

        processResults(importResultData.bursts, data, 'bursts');
        processResults(importResultData.brands, data, 'brands');
        processResults(importResultData.groups, data, 'groups');
        processResults(importResultData.business_units, data, 'business_units');
        processResults(importResultData.custom_fields, data, 'custom_fields');
        processResults(importResultData.datasets, data, 'datasets');
        processResults(importResultData.digest_templates, data, 'digest_templates');
        processResults(importResultData.elements, data, 'elements');
        processResults(importResultData.folders, data, 'folders');
        processResults(importResultData.portal_pages, data, 'portal_pages');
        processResults(importResultData.portal_pages_entity, data, 'portal_pages_entity');
        processResults(importResultData.privilege_sets, data, 'privilege_sets');
        processResults(importResultData.segments, data, 'segments');

        setGridData(data);
    }, [importResultData]);

    const popupSettings: PopupSettings = {
        title: t('import_results'),
        noOk: importResultData.failed <= 0,
        textOK: t('import_again'),
        textCancel: t('done'),
    };

    return (
        <Popup onHide={onHide} open={popupOpen} settings={popupSettings} onConfirm={onImportAgain}>
            <div>
                <StaticInfo type={messageType} label={getHintMessage()} />
                <br />
                <MITable
                    gridName={'import_results'}
                    autoHeight
                    data={gridData}
                    isLoading={false}
                    columns={[
                        { title: '#', name: 'num' },
                        { title: t('name'), name: 'name' },
                        { title: t('message'), name: 'message' },
                    ]}
                    columnsWidth={{
                        num: 50,
                        name: 200,
                    }}
                    massActionsConfig={{ enabled: false }}
                />
            </div>
        </Popup>
    );
}
