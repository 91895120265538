import { lazy } from 'react';
import { RouteItemType } from './index';
import TokenTest from 'app/dev/TokenTest';
const FormPage = lazy(() => import('components/common/FormPage'));
const DevUiLibrary = lazy(() => import('app/devui'));
const DevIconsLibrary = lazy(() => import('app/devui/icons'));
const DevCssLibrary = lazy(() => import('app/devui/css'));

export const devRoutes: Array<RouteItemType> = [
    {
        path: ['/data/editor/service/validate-google-oauth2'],
        name: 'extcontent.name',
        component: TokenTest,
    },
    {
        path: ['/dev/form', '/dev/form/id/:elementId'],
        name: 'Development - Form Example',
        component: FormPage,
        props: { settingsUrl: '/data/dev/form', uid: 'devExampleForm' },
    },
    {
        path: ['/dev/form/complex', '/dev/form/complex/id/:elementId'],
        name: 'Development - Complex Form Example',
        component: FormPage,
        props: { settingsUrl: '/data/dev/form/complex', uid: 'devExampleComplexForm' },
    },
    {
        path: ['/dev/grid', '/dev/grid'],
        name: 'Development - Grid',
        component: FormPage,
        props: { settingsUrl: '/data/dev/grid', uid: 'devExampleGrid' },
    },
    {
        path: ['/dev/ui'],
        name: 'Development - UI Area',
        component: DevUiLibrary,
    },
    {
        path: ['/dev/icons'],
        name: 'Development - Icons Library',
        component: DevIconsLibrary,
        props: { pageMenu: 'devExample' },
    },
    {
        path: ['/dev/css'],
        name: 'Development - Css Library',
        component: DevCssLibrary,
        props: { pageMenu: 'devExample' },
    },
    {
        path: ['/dev/shared'],
        name: 'Development - Shared Components',
        component: FormPage,
        props: { settingsUrl: '/data/dev/shared', uid: 'devSharedComponents' },
    },
];
