import { GridDataRow } from 'components/common/grid';
import React from 'react';
import { IconButton, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';

function DerivedControl({
    row,
    onDerivedAction,
}: {
    row: GridDataRow;
    onDerivedAction: (action: 'edit' | 'delete', fieldId: string) => void;
}) {
    const rowId = row.id ? String(row.id) : '';

    return (
        <Stack direction={'row'} spacing={1} sx={{ py: 0.5 }}>
            <IconButton
                aria-label="edit"
                size={'small'}
                onClick={() => {
                    onDerivedAction('edit', rowId);
                }}
            >
                <IconMi icon="gear" fontSize={'16'} />
            </IconButton>
            <IconButton
                aria-label="edit"
                size={'small'}
                onClick={() => {
                    onDerivedAction('delete', rowId);
                }}
            >
                <IconMi icon="trash" fontSize={'16'} />
            </IconButton>
        </Stack>
    );
}

export default DerivedControl;
