import React, { Component } from 'react';
import { FormElementProps } from 'components/common/form/index';

export default class FormElementErrorBoundary extends Component<any, any> {
    elementProps: FormElementProps;

    constructor(props: { elementProps: FormElementProps }) {
        super(props);
        this.state = { hasError: false };
        this.elementProps = props.elementProps;
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            console.log('Broken Component:');
            console.log(this.elementProps);
            return <div>Component is broken: [{this.elementProps.component.name}] =(</div>;
        }
        return this.props.children;
    }
}
