import React, { useContext, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentSettingsJct = ReportContentNS.ComponentSettingsJct;
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import FieldsList, { FieldSortingContext } from 'components/report-content/components/jct/edit-panel/FieldsList';
import { arrayMoveImmutable } from 'array-move';
import ComponentSettingsJctField = ReportContentNS.ComponentSettingsJctField;
import FieldsSortOrder from 'components/report-content/components/jct/edit-panel/FieldsSortOrder';
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import FieldForm from 'components/report-content/components/jct/edit-panel/FieldForm';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsJct> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
];
const componentNumericFormField: Array<keyof ComponentSettingsJct> = ['height'];

const FormPref = '__dp_';

export default function JctEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsJct> & { block: BlockType<ComponentSettingsJct> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);

    // Prepare data for Form
    const defaultState: AssocArray<any> = {};
    //Add Template Props to Form
    component.settings.properties.templateProperties.forEach((p) => (defaultState[`${FormPref}${p.name}`] = p.value));
    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);
    const [sortFields, setSortFields] = useState<Array<ComponentSettingsDatasetSortField>>(
        component.settings.sortFields,
    );
    const handleChangeColumnSortSettings = (sortFields: Array<ComponentSettingsDatasetSortField>) => {
        setSortFields(sortFields.slice());
    };

    const [editField, setEditField] = useState<ComponentSettingsJctField | null>(null);
    const handleFieldFormApply = (field: ComponentSettingsJctField) => {
        const fieldIndex = fieldsState.findIndex((fs) => fs.reference_name_escaped == field.reference_name_escaped);
        if (fieldIndex != -1) {
            const list = fieldsState.slice();
            list[fieldIndex] = { ...list[fieldIndex], ...field };
            setFieldsState(list);
        }
        setEditField(null);
    };

    const [fieldsState, setFieldsState] = useState<Array<ComponentSettingsJctField>>(component.settings.fields ?? []);
    const handleSelectField = (field: ComponentSettingsJctField) => {
        setEditField({
            ...field,
        });
    };

    const handleFieldsSort = (oldIndex: number, newIndex: number) => {
        setFieldsState(
            arrayMoveImmutable(fieldsState, oldIndex, newIndex).map((filter, i) => ({
                ...filter,
                column_sequence: i,
            })),
        );
    };
    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsJct = {
            ...component.settings,
            fields: fieldsState.slice(),
            sortFields: sortFields.slice(),
            properties: {
                templateProperties: component.settings.properties.templateProperties.map((p) => ({
                    name: p.name,
                    value: elementProps.form.hookFormGetValues(FormPref + p.name),
                })),
            },
        } as ComponentSettingsJct;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const resultSettings = {
            ...newSettings,
        } as ComponentSettingsJct;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            {editField == null && (
                <Stack height={1}>
                    {/*Main Panel*/}
                    <Box flexShrink={0}>
                        <BlockEditPanelHeader
                            title={component.settings.component_name}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                    <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                        <Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: { component: 'FormText', name: 'title', label: t('title') },
                                })}
                            />
                        </Box>
                        <Divider />
                        <FieldSortingContext.Provider
                            value={{
                                elementProps: elementProps,
                                selectField: handleSelectField,
                                onSortEnd: handleFieldsSort,
                            }}
                        >
                            <FieldsList fields={fieldsState} header={t('columns_header')} />
                        </FieldSortingContext.Provider>
                        {/*Field SortOrder Section*/}

                        <FieldsSortOrder
                            onChangeColumnSortSettings={handleChangeColumnSortSettings}
                            fields={fieldsState}
                            sortFields={sortFields}
                        />
                        <Divider />
                        <Box>
                            <FormLabel>{t('jct.template_vars')}</FormLabel>
                            <Stack direction={'column'} spacing={1}>
                                {component.settings.properties.templateProperties.map((p, i) => {
                                    return (
                                        <Stack key={p.name} alignItems={'center'} direction={'row'} spacing={1}>
                                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0, width: '200px' }}>
                                                {p.name}
                                            </Box>
                                            <Box sx={{ width: '365px' }}>
                                                <ReactHookFormController
                                                    elementProps={prepareFormElementProps({
                                                        ...elementProps,
                                                        component: {
                                                            component: 'FormText',
                                                            name: FormPref + p.name,
                                                            label: '',
                                                        },
                                                    })}
                                                />
                                            </Box>
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        </Box>
                        <Divider />
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                            <Box sx={{ width: '72px' }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        inputFilter: 'int',
                                        component: { component: 'FormText', name: 'height', label: '' },
                                    })}
                                />
                            </Box>
                        </Stack>
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'show_in_viewer'}
                                label={t('show_in_viewer')}
                            />
                        </Box>
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'include_in_email'}
                                label={t('include_in_email')}
                            />
                        </Box>
                        <IncludeAsAttachment componentName={'jct'} elementProps={elementProps} t={t} />
                        <Box>
                            <GetEmbedCode
                                contentSettings={contentSettings}
                                height={actualHeight}
                                blockUID={block.uid}
                                t={t}
                            />
                        </Box>
                    </Stack>
                    <Box flexShrink={0}>
                        <BlockEditPanelControls
                            onApply={handleApply}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                </Stack>
            )}
            {editField != null && (
                <>
                    {/*Field Edit Panel*/}
                    <FieldForm
                        field={editField}
                        onApply={handleFieldFormApply}
                        onCancel={() => {
                            setEditField(null);
                        }}
                        title={t('manage_filters.edit_title')}
                    />
                </>
            )}
        </>
    );
}
