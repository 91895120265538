import { createContext, useContext } from 'react';
import { HomeContextType } from './index.functions';
export const HomeContext = createContext<HomeContextType | undefined>({} as HomeContextType);

export function useHomeContext() {
    const context = useContext(HomeContext);
    if (!context) {
        throw new Error('useHomeContext must be used within a HomeContextProvider');
    }
    return context;
}
