import React from 'react';
import { IFormTabComponentRecord } from 'components/common/form/formTools';
import { FormLayoutType, FormType, RawFormElementProps } from 'components/common/form/index';
import { IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';

export interface ITabContextProps {
    elementProps: RawFormElementProps;
    componentsMap: Array<IFormTabComponentRecord>;
}

export interface ITabProps extends ITabContextProps {
    tabName: string;
    isTabActive: boolean;
}

export interface IFormLayoutContext {
    formLayout: FormLayoutType;
    formType: FormType;
}

export const FormLayoutContext = React.createContext<IFormLayoutContext>({
    formLayout: 'page',
    formType: 'new',
});

export function customTabProps(layoutProps: IFormLayoutProps, tabName: string): ITabProps {
    return {
        componentsMap: layoutProps.componentsMap,
        elementProps: layoutProps.elementProps,
        tabName: tabName,
        isTabActive: layoutProps.tabs.formTabs.find((tab) => tab.name == tabName)?.isActive ?? false,
    };
}
