import React, { useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import { ChartTooltipProps } from 'components/metric/chart/tooltip/RegularSeriesTooltip';
import { ChartPointAnnotation } from 'components/metric/chart/series/getAnnotationSeries';
import useAddComment from 'components/metric/chart/tooltip/useAddComment';
import AddComment from 'components/metric/chart/tooltip/AddComment';
import DeleteItemWall from 'components/metric/chart/tooltip/DeleteItemWall';
import useWallContext, { wallDeleteAnnotation } from 'components/wall/hooks/useWallContext';
import EditItemWall from 'components/metric/chart/tooltip/EditItemWall';
import { alpha, Box, Divider } from '@mui/material';
import { createPortal } from 'react-dom';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

export interface ChartAnnotationTooltipProps extends ChartTooltipProps {
    point: ChartPointAnnotation;
}

export default function AnnotationTooltip({
    point,
    t,
    isSelected,
    onClose,
    elementInfo,
    segmentValueId,
}: // deleteAnnotation,
ChartAnnotationTooltipProps) {
    const metadata = point.metadata;
    const { handleAddCommentClick, setEditAnnotationId } = useAddComment(
        'user_annotation',
        String(point?.metadata?.key),
        onClose,
    );

    const { triggerOnUpdate, wallActions } = useWallContext(false);

    const [isLoading, setIsLoading] = useState(false);
    const handleDeleteClick = () => {
        setIsLoading(true);
        wallDeleteAnnotation(
            elementInfo.row.elementId,
            segmentValueId,
            0,
            Number(point?.metadata?.key),
            elementInfo.row.type == 'metric' || elementInfo.row.type == 'multi-metric chart',
        )
            .then((response) => {
                if (response.data?.status == 'OK') {
                    wallActions.onDeleteAnnotation(Number(point?.metadata?.key));
                }
            })
            .finally(() => {
                setIsLoading(false);
                triggerOnUpdate({});
            });
    };

    const handleEditClick = () => {
        setEditAnnotationId(Number(metadata.key));
    };

    return (
        <Box
            sx={{
                py: 1,
                px: 1.5,
            }}
        >
            {isLoading && createPortal(<LoadingPlaceholder />, document.body)}
            <Box sx={{ fontWeight: 600, pr: isSelected ? 1.5 : undefined }}>
                {t('label_value_on_time', {
                    value: metadata.valueFormatted,
                    time: metadata.annotationMeasurementTimeFormatted,
                })}
            </Box>
            {isSelected && (
                <Box
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        p: 0.625,
                        right: 0,
                        top: 0,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <IconMi icon={'times'} />
                </Box>
            )}
            <Divider sx={{ my: 1 }} />
            <Box sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.64) }}>{metadata.userName}</Box>
            <Box sx={{ pt: 0.5 }}>{metadata.text}</Box>

            {isSelected && (
                <>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ flexGrow: 1, ml: -0.5 }}>
                            <AddComment onClick={handleAddCommentClick} />
                        </Box>
                        <Box sx={{ flexShrink: 0 }}>
                            <EditItemWall
                                onClick={() => {
                                    handleEditClick();
                                }}
                            />
                            <DeleteItemWall
                                onClick={() => {
                                    handleDeleteClick();
                                }}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
}
