import { alpha } from '@mui/material/styles';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

interface IInfoPanelProps {
    setShowMetricBuilder: (value: boolean) => void;
    collected: string;
    viewName: string;
}

export default function InfoPanel({ setShowMetricBuilder, viewName, collected }: IInfoPanelProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    return (
        <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
            sx={{
                borderBottom: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                pl: 3,
                flexShrink: 0,
            }}
        >
            <Stack direction={'row'} alignItems="center">
                <Tabs
                    value={false}
                    onChange={(event, value) => {
                        if (value === 'back') {
                            setShowMetricBuilder(false);
                        }
                    }}
                >
                    <Tab
                        icon={<IconMi icon={'chevron-left'} sx={{ fontSize: 16 }} />}
                        iconPosition="start"
                        component={Box}
                        value={'back'}
                        label={<Box>{t('instance_info.filter_data')}</Box>}
                    />
                </Tabs>
                <Box
                    sx={{
                        width: '1px',
                        height: '16px',
                        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        flexShrink: 0,
                    }}
                />
                <Box
                    sx={{
                        height: '46px',
                        borderBottom: '1px solid',
                        padding: '12px',
                        flexShrink: 0,
                        borderColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.main,
                        fontWeight: 500,
                    }}
                    alignContent={'center'}
                >
                    {viewName}
                </Box>
            </Stack>
            <Stack
                direction={'row'}
                spacing={0.75}
                sx={{
                    padding: '12px',
                }}
            >
                <Typography fontWeight={600}>{t('instance_info.dataset_collected')}</Typography>
                <Typography>{collected}</Typography>
            </Stack>
        </Stack>
    );
}
