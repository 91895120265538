import { Components } from '@mui/material/styles/components';

import { MuiButtonConfig } from './mui-components/mui-button';
import { MuiButtonGroupConfig } from './mui-components/mui-button-group';
import { MuiInputBaseStyle } from './mui-components/mui-input-base';
import { MuiTypography } from './mui-components/mui-typography';
import { MuiCssBaseLine } from './mui-components/mui-css-base-line';
import { FormControlLabelConfig } from './mui-components/mui-form-control-label';
import { MuiCheckboxConfig } from './mui-components/mui-checkbox';
import { MuiRadioConfig } from './mui-components/mui-radio';
import { MuiTextFieldConfig } from './mui-components/mui-text-field';
import { MuiInputLabelConfig } from './mui-components/mui-input-label';
import { MuiInputConfig } from './mui-components/mui-input';
import { MuiTabsConfig } from './mui-components/mui-tabs';
import { MuiTabConfig } from './mui-components/mui-tab';
import { MuiToolbarConfig } from './mui-components/mui-toolbar';
import { MuiBreadcrumbsConfig } from './mui-components/mui-breadcrumbs';
import { MuiToggleButtonConfig } from './mui-components/mui-toggle-button';
import { MuiToggleButtonGroupConfig } from './mui-components/mui-toggle-button-group';
import { MuiTablePaginationConfig } from './mui-components/mui-table-pagination';
import { MuiFormLabelConfig } from './mui-components/mui-form-label';
import { MuiFormGroupConfig } from './mui-components/mui-form-group';
import { MuiSwitchConfig } from './mui-components/mui-switch';
import { MuiChipConfig } from './mui-components/mui-chip';
import { MuiIconButtonConfig } from './mui-components/mui-icon-button';
import { MuiSliderConfig } from './mui-components/mui-slider';
import { MuiInputAdornmentConfig } from './mui-components/mui-input-adornment';
import { MuiAlertConfig } from './mui-components/mui-alert';
import { MuiTableCellConfig } from './mui-components/mui-table-cell';
import { MuiSvgIconConfig } from './mui-components/mui-svg-icon';
import { MuiFormHelperText } from './mui-components/mui-form-helper-text';
import { MuiDividerConfig } from 'theme/core/mui-components/mui-divider';
import { MuiTooltipConfig } from 'theme/core/mui-components/mui-tooltip';
import { MuiPaperConfig } from 'theme/core/mui-components/mui-paper';
import { MuiDialogContentConfig } from 'theme/core/mui-components/mui-dialog-content';

export const designComponents = {
    MuiTypography: MuiTypography,
    MuiCssBaseline: MuiCssBaseLine,
    MuiButton: MuiButtonConfig,
    MuiButtonGroup: MuiButtonGroupConfig,
    MuiTextField: MuiTextFieldConfig,
    MuiInputLabel: MuiInputLabelConfig,
    MuiInput: MuiInputConfig,
    MuiAlert: MuiAlertConfig,
    MuiInputAdornment: MuiInputAdornmentConfig,
    MuiFormControlLabel: FormControlLabelConfig,
    MuiCheckbox: MuiCheckboxConfig,
    MuiRadio: MuiRadioConfig,
    MuiTabs: MuiTabsConfig,
    MuiTab: MuiTabConfig,
    MuiToolbar: MuiToolbarConfig,
    MuiBreadcrumbs: MuiBreadcrumbsConfig,
    MuiToggleButton: MuiToggleButtonConfig,
    MuiToggleButtonGroup: MuiToggleButtonGroupConfig,
    MuiTablePagination: MuiTablePaginationConfig,
    MuiFormLabel: MuiFormLabelConfig,
    MuiFormHelperText: MuiFormHelperText,
    MuiFormGroup: MuiFormGroupConfig,
    MuiSwitch: MuiSwitchConfig,
    MuiChip: MuiChipConfig,
    MuiIconButton: MuiIconButtonConfig,
    MuiSlider: MuiSliderConfig,
    MuiTableCell: MuiTableCellConfig,
    MuiTooltip: MuiTooltipConfig,
    MuiPaper: MuiPaperConfig,
    MuiDialogContent: MuiDialogContentConfig,

    MuiInputBase: MuiInputBaseStyle,
    MuiSvgIcon: MuiSvgIconConfig,
    MuiDivider: MuiDividerConfig,
} as Components;
