import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import { ElementViewerDataType } from 'components/element-viewer';
import { DashboardElementType } from 'tools/types';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import IconMi from 'components/common/icon/IconMi';
import CertificationData from 'components/common/CertificationData';

export default function TopPanel({
    viewerData,
    tileName,
    openElement,
    bookmarksContext,
}: {
    viewerData?: ElementViewerDataType;
    tileName: string;
    openElement: (type: DashboardElementType, elementId: number, segmentValueId: number) => void;
    bookmarksContext?: IBookmarksContext;
}) {
    const element = viewerData?.elementData;

    if (element === undefined) {
        return null;
    }

    const onNameClick = () => {
        if (!element.row.type) {
            return;
        }
        return openElement(element.row.type, element.row.elementId, viewerData?.segmentValueId ?? 0);
    };

    const certificationIcon = element.certification.enabled ? (
        <Tooltip
            placement="top"
            slotProps={{
                tooltip: {
                    sx: { maxWidth: 'none' },
                },
                popper: {
                    sx: {
                        zIndex: (theme) => theme.zIndex.appBar + 1,
                    },
                },
            }}
            title={
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    <CertificationData
                        certificationData={{
                            color: element.certification.color,
                            name: element.certification?.name,
                            lastUpdatedBy: element.certification?.lastUpdatedBy,
                            lastUpdatedTime: element.certification?.lastUpdatedTime?.split(' ')?.[0],
                        }}
                    />
                </Box>
            }
        >
            <Box className={'certification-icon'} sx={{ display: 'inline-block' }}>
                <IconMi
                    icon="certified"
                    sx={{
                        fontSize: '16px',
                        color: element.certification.color,
                    }}
                />
            </Box>
        </Tooltip>
    ) : null;

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography fontSize={18} color={'primary.main'} sx={{ fontWeight: 600 }}>
                <Box
                    data-test={'preview-tile-name'}
                    component={'span'}
                    dangerouslySetInnerHTML={{ __html: tileName }}
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={onNameClick}
                />
                {certificationIcon}
            </Typography>
            <Stack direction="row" alignItems="center">
                <ControlsPanel
                    bookmarksContext={bookmarksContext}
                    context="preview"
                    element={element}
                    segmentValueId={viewerData?.segmentValueId ?? 0}
                    onFavoritesChange={() => {}}
                    alertStatus={viewerData?.alertStatus}
                />
            </Stack>
        </Stack>
    );
}
