import { ModeType } from 'components/common/form/data-fetch-command/substitution/index';
import { FormRendererAPIType } from 'components/common/form/index';

export default function useDatasetHasBackfield(mode: ModeType, editorForm: FormRendererAPIType, isManual: boolean) {
    if (mode != 'collect') {
        return false;
    }

    const mtSource = editorForm.hookFormGetValues('measurement_time_source');
    const method = editorForm.hookFormGetValues('data_fetch_method');
    const columnUsed = 'dataset' == method && 'ds_column' == mtSource;

    return (
        editorForm.hookFormGetValues('save_historical_instances_ind') == 'Y' &&
        (editorForm.hookFormGetValues('backfill_ind') == 'Y' || isManual || columnUsed)
    );
}
