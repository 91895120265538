import { alpha, Theme } from '@mui/material';

export default {
    arlilLine: {
        padding: 0.5,
        minHeight: '66px',
        display: 'flex',
        alignItems: 'center',
    },
    arliLineInfo: {
        width: '100%',
    },
    arliLineInfoLabel: {
        color: 'text.secondary',
    },
    arliLineOptions: {},

    arliLineInfoDimLinks: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    arliLineOptionsNotLink: {
        color: 'primary.main',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    arliLineInfoDimText: {
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
        },
    },
    arliLineUsersCell: {
        pr: 2,
        width: '36px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    arliLineUsers: {
        display: 'inline-flex',
        alignItems: 'center',
        color: 'primary.main',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        cursor: 'default',
    },
    arliLineUsersActive: {
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    arliLineEditCell: {
        pr: 2,
        width: '18px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    arliLineEdit: {
        display: 'inline-flex',
        alignItems: 'center',
        color: 'primary.main',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    arliLineDimensionCell: {
        pr: 2,
        width: '92px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    arlilLineDimension: {
        display: 'inline-flex',
        alignItems: 'center',
        color: 'primary.main',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    arliLineEnvelopeCell: {
        pr: 2,
        width: '18px',
        boxSizing: 'content-box',
        flexShrink: 0,
    },
    arliLineEnvelope: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: 'primary.main',
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        },
    },
    arliLineEnvelopeActive: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: 'primary.main',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'background.default',
            left: '-8px',
            right: '-8px',
            top: '-8px',
            bottom: '-8px',
            boxShadow: (theme: Theme) => `2px 0px 8px ${alpha(theme.palette.text.primary, 0.15)}`,
        },
        '.MuiSvgIcon-root': {
            position: 'relative',
        },
    },
    arliNotReceiveMsg: {
        textAlign: 'center',
        p: 0.5,
        color: 'text.secondary',
    },
    arliNotReceiveMsgInner: {
        border: '1px solid',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        backgroundColor: 'background.default',
        p: 0.25,
    },
    arliNotReceiveMsgLink: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
};
