import { LineageData, LineageEdge, LineageNode, NodeInfo, NodesInfoObject } from './index';
import { IdType } from 'vis-network/declarations/network/Network';

export class LinageDataProcessor {
    processedNodes: LineageNode[] = [];
    nodesInfo: NodesInfoObject = {};
    filteredEdges: LineageEdge[] = [];

    clearData() {
        this.filteredEdges = [];
        this.processedNodes = [];
        this.nodesInfo = {};
    }

    getData() {
        return {
            processedNodes: this.processedNodes,
            filteredEdges: this.filteredEdges,
            nodesInfo: this.nodesInfo,
        };
    }

    prepareData(rawData: LineageData) {
        this.clearData();

        this.filteredEdges = rawData.edges.filter(function (edge, index, self) {
            return index === self.findIndex((t) => t.from === edge.from && t.to === edge.to);
        });

        this.orderNodes(rawData.nodes, this.filteredEdges);
        this.processEdges(this.filteredEdges);
    }

    orderNodes(nodes: LineageNode[], edges: LineageEdge[]) {
        let nodesByLevel: any = [];

        nodes.forEach((node) => {
            if (!(node.level in nodesByLevel)) {
                nodesByLevel[node.level] = [];
            }

            nodesByLevel[node.level].push(node);
        });

        let orderedNodesByLevel = Object.keys(nodesByLevel).sort();

        orderedNodesByLevel.forEach((level) => {
            if (nodesByLevel.hasOwnProperty(level)) {
                let nodesInLevel = nodesByLevel[level];

                nodesInLevel.forEach((node: LineageNode) => {
                    let existInResult = this.processedNodes.find(function (element) {
                        return element.id === node.id;
                    });

                    if (existInResult === undefined) {
                        this.processedNodes.push(node);
                    }

                    this.processInitChildNodes(node.id, nodes, edges);
                });
            }
        });

        if (nodes.length !== this.processedNodes.length) {
            nodes.forEach((node) => {
                let existInResult = nodes.find(function (element) {
                    return element.id === node.id;
                });

                if (existInResult === undefined) {
                    this.processedNodes.push(node);
                }

                this.processInitChildNodes(node.id, nodes, edges);
            });
        }
    }

    processEdges(edges: LineageEdge[]) {
        edges.forEach((edge) => {
            let parentInfo: NodeInfo = {
                    childCount: {},
                    parentIds: [],
                },
                childCount = 1;

            if (this.nodesInfo.hasOwnProperty(edge.from)) {
                parentInfo = this.nodesInfo[edge.from];
                if (edge.childGroup in parentInfo.childCount) {
                    childCount = parentInfo.childCount[edge.childGroup] + 1;
                }
            }

            parentInfo.childCount[edge.childGroup] = childCount;
            this.nodesInfo[edge.from] = parentInfo;

            let childInfo: NodeInfo = {
                childCount: {},
                parentIds: [edge.from],
            };

            if (edge.to in this.nodesInfo) {
                childInfo = this.nodesInfo[edge.to];
                childInfo.parentIds.push(edge.from);
            }

            this.nodesInfo[edge.to] = childInfo;
        });
    }

    processInitChildNodes(initNodeId: IdType, nodes: LineageNode[], edges: LineageEdge[]) {
        let related = this.getChildNodes(initNodeId, edges);

        related.forEach((nodeId) => {
            let existInResult = this.processedNodes.find(function (element) {
                return element.id === nodeId;
            });

            if (existInResult === undefined) {
                let childNode = nodes.find(function (element) {
                    return element.id === nodeId;
                });

                if (childNode) {
                    this.processedNodes.push(childNode);
                }
            }

            this.processInitChildNodes(nodeId, nodes, edges);
        });
    }

    getChildNodes(nodeId: IdType, edges: LineageEdge[]) {
        let childNodes: IdType[] = [];

        edges.forEach((edge) => {
            if (edge.from === nodeId) {
                childNodes.push(edge.to);
            }
        });

        return childNodes;
    }
}
