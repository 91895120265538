import { FormElementProps } from 'components/common/form/index';
import { FormComponentValue } from 'components/common/form/layout/control';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getElementFieldValue } from 'components/common/form/formTools';
import { DependencyRule, FormComponentDependency } from 'components/common/form/dependency/index';
import checkRule from 'components/common/form/dependency/checkRule';

export function applyComponentValuesCondition(
    elementProps: FormElementProps,
    componentValues: Array<FormComponentValue>,
    formsContext: FormsContextType,
) {
    if (!elementProps.component.deps?.length) {
        return componentValues;
    }

    const result = componentValues.map((v) => ({ ...v }));
    elementProps.component.deps
        .filter((dep) => dep.scope == 'value')
        .forEach((dep: FormComponentDependency) => {
            let rulesValid = true;
            dep.rules.forEach((rule: DependencyRule) => {
                const fieldValue = getElementFieldValue(elementProps.form, rule.field, formsContext);
                rulesValid = rulesValid && checkRule(rule, fieldValue, elementProps.form, formsContext);
            });
            if (rulesValid) {
                for (const [value, mod] of Object.entries<FormComponentValue>(dep.effect)) {
                    let index = result.findIndex((v) => v.value == value);
                    if (index == -1) {
                        continue;
                    }
                    result[index] = { ...result[index], ...mod };
                    result[index].props = { ...result[index].props, ...mod.props };
                }
            }
        });
    return result;
}
