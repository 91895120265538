import React from 'react';
import { applyAutoStretch } from 'components/report-content/components/dataset/index';
import { ReportContentNS } from 'components/report-content/index';

import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import IconMi from 'components/common/icon/IconMi';
import { Button } from '@mui/material';
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import useBundleTranslation from 'i18n';

export default function ResetPivotColumnsSize({
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsPivot>) {
    const { t } = useBundleTranslation();
    const handleClick = () => {
        updateBlockSettings(applyAutoStretch(component.settings, component.datasetRendererBlockComponentId));
    };

    return (
        <Button
            disabled={component.settings.autoStretch}
            variant="text"
            startIcon={<IconMi icon="columns" />}
            onClick={handleClick}
        >
            {t('reset_columns')}
        </Button>
    );
}
