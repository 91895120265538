export const MuiInputAdornmentConfig = {
    styleOverrides: {
        positionStart: ({ theme }: { theme: any }) => {
            return {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(-0.5),
            };
        },
        positionEnd: ({ theme }: { theme: any }) => {
            return {
                marginLeft: theme.spacing(-0.5),
                marginRight: theme.spacing(1),
            };
        },
    },
};
