import { alpha, Theme } from '@mui/material';

export default {
    charactersLeftWrapper: {
        //border from Input
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.16)}`,
        borderRadius: 1,
        '.MuiInput-root': {
            border: 0,
        },
    },
    charactersLeftHolder: {
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.04),
        color: 'text.secondary',
        textAlign: 'right',
        p: '4px 12px',
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
    },
};
