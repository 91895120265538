import React, { ReactNode, useEffect, useState } from 'react';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import FormComponent from 'components/common/form/Form';
import { FormDataAPIType } from 'components/common/form';
import useWallContext from 'components/wall/hooks/useWallContext';

export default function CollaborationAnnotation({
    annotationId,
    children,
    startTime,
    elementId,
    segmentValueId,
    metricInstanceId,
    afterSave,
}: {
    elementId: number;
    segmentValueId: number;
    annotationId: number;
    children: ReactNode;
    metricInstanceId: number;
    startTime?: string;
    afterSave: () => any;
}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div>
            {isOpen && (
                <CollaborationAnnotationForm
                    startTime={startTime}
                    onHide={() => setIsOpen(false)}
                    annotationId={annotationId}
                    elementId={elementId}
                    segmentValueId={segmentValueId}
                    metricInstanceId={metricInstanceId}
                    afterSave={afterSave}
                />
            )}
            <span onClick={() => setIsOpen(true)} className="annotate-btn-wrap">
                {children}
            </span>
        </div>
    );
}

export function CollaborationAnnotationForm({
    annotationId,
    onHide,
    afterSave,
    startTime,
    elementId,
    segmentValueId,
    metricInstanceId,
}: {
    annotationId: number;
    onHide: any;
    afterSave: () => any;
    startTime?: string;
    metricInstanceId: number;
    elementId: number;
    segmentValueId: number;
}) {
    const { t } = useBundleTranslation();
    const popupSettings: PopupSettings = { title: t('add_annotation') };

    const [form, setForm] = useState<FormDataAPIType | null>(null);
    useEffect(() => {
        if (form == null) {
            return;
        }
        if (typeof startTime != 'undefined') {
            form.form.hookFormSetValue('meas_time', startTime);
        }
        if (metricInstanceId != 0) {
            form.form.hookFormSetValue('meas_id', metricInstanceId);
        }
    }, [form]);

    return (
        <FormComponent
            setFormRef={(f) => {
                if (form == null) {
                    setForm(f);
                }
            }}
            pk={'element-annotation'}
            uid={'element-annotation'}
            settingsUrl={`/data/chart/annotation/${annotationId}/form?element=${elementId}&segment=${segmentValueId}&meas_id=${metricInstanceId}`}
            popupType={'new'}
            popupSettings={popupSettings}
            isPopup
            onHide={onHide}
            afterSave={() => afterSave()}
        />
    );
}
