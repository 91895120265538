import { Theme, alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';

export const MuiAlertConfig = {
    defaultProps: {
        variant: 'contained',
        type: 'info',
        iconMapping: {
            error: React.createElement(IconMi, { icon: 'alert' }, null),
            info: React.createElement(IconMi, { icon: 'info-circle' }, null),
            warning: React.createElement(IconMi, { icon: 'info-circle' }, null),
            success: React.createElement(IconMi, { icon: 'success' }, null),
        },
    },
    variants: [
        //Blank
        {
            props: { variant: 'blank' },
            style: ({ theme }: { theme: any }) => {
                return {
                    padding: 0,
                };
            },
        },
        //Contained
        {
            props: { variant: 'standard', severity: 'info' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.04)),
                    color: theme.palette.text.primary,
                };
            },
        },
        {
            props: { variant: 'standard', severity: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.08)),
                    color: theme.palette.error.main,
                };
            },
        },
        {
            props: { variant: 'standard', severity: 'warning' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: useBlendColors(alpha(theme.palette.warning.main, 0.08)),
                    color: theme.palette.warning.main,
                };
            },
        },
        {
            props: { variant: 'standard', severity: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.08)),
                    color: theme.palette.success.main,
                };
            },
        },
    ],
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
            return { padding: '5px 12px', borderRadius: 0 };
        },
        message: ({ theme }: { theme: Theme }) => {
            return {
                padding: 0,
                '.mui-icon': {
                    width: '14px',
                    opacity: 0.6,
                    marginBottom: '-3px',
                },
                p: {
                    margin: 0,
                },
            };
        },
        icon: ({ theme }: { theme: Theme }) => {
            return {
                padding: 0,
                fontSize: '16px',
                marginRight: '8px',
                color: 'inherit !important',
            };
        },
    },
    /*
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
            return {
                '&.MuiAlert-root': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'pre-wrap',
                },

                '.MuiAlert-message': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: theme.palette.text.primary,

                    '.mui-icon': {
                        width: '14px',
                        opacity: 0.6,
                        marginBottom: '-3px',
                    },
                },

                '&.MuiAlert-standardSuccess .MuiAlert-message': {
                    color: theme.palette.success.main,
                    fontWeight: 600,
                },
                '&.MuiAlert-standardError .MuiAlert-message': {
                    color: theme.palette.error.main,
                    fontWeight: 600,
                },
                '.MuiAlert-action': {
                    paddingTop: 0,
                    paddingRight: 10,

                    '> a': {
                        color: theme.palette.primary.main,
                    },
                },
            };
        },
    },
    */
};
