import React, { useEffect, useState } from 'react';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import LightTooltip from 'components/common/tooltip/LightTooltip';
import { notificationAPI } from 'api/notification/notification';
import AsyncPopup from 'components/common/popup/AsyncPopup';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { FormComponentBuilder } from 'components/common/form/layout';

export interface FormControlTokenlessUsersProps extends FormControlProps {
    baseUrl: string;
    dependencies: string[];
}

function TokenlessUsers({ controlProps }: FormElementControlPropsType<FormControlTokenlessUsersProps>) {
    const { t } = useBundleTranslation('app/notification/tokenless-users');

    const [number, setNumber] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const learnMoreLink = `https://help.metricinsights.com/m/Working_with_Microsoft_PowerBI/l/1549301-why-can-t-i-see-images-from-power-bi-in-my-burst`;

    const loadData = () => {
        notificationAPI.getTokenlessStatus(controlProps.baseUrl).then((response) => {
            setNumber(response.number);
        });
    };

    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name && controlProps.dependencies.includes(name)) {
                loadData();
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    useEffect(() => {
        loadData();
    }, []);

    if (number < 1) {
        return null;
    }

    const showUsersPopup = () => {
        setShowPopup(true);
    };

    const hideUsersPopup = () => {
        setShowPopup(false);
    };

    const popupSettings: PopupSettings = {
        title: t('popup_title'),
        noButtons: true,
    };

    const learMore = (
        <a href={learnMoreLink} target={'_blank'}>
            {t('learn_more')}
        </a>
    );

    const tooltipBody = (
        <Stack>
            <Typography>
                <a style={{ cursor: 'pointer' }} onClick={showUsersPopup}>{`${number} ${t(
                    number > 1 ? 'tooltip.users' : 'tooltip.user',
                )}`}</a>
                {t('tooltip.warning_text')}
                {learMore}
            </Typography>
        </Stack>
    );

    return (
        <>
            <Stack direction={'row'} alignItems={'center'}>
                <LightTooltip title={tooltipBody} arrow placement="left-start">
                    <Alert
                        severity={'error'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'pre-wrap',
                            border: 'none !important',
                        }}
                    >
                        {t('warning_text')}
                    </Alert>
                </LightTooltip>
                {learMore}
            </Stack>
            {showPopup && (
                <AsyncPopup
                    settingsUrl={`${controlProps.baseUrl}/index`}
                    onHide={hideUsersPopup}
                    onConfirm={hideUsersPopup}
                    popupSettings={popupSettings}
                />
            )}
        </>
    );
}

export default TokenlessUsers;

export class FormTokenlessUsersBuilder extends FormComponentBuilder {
    prepareProps(): FormControlTokenlessUsersProps {
        return {
            ...this.controlProps,
            baseUrl: this.elementProps.componentProps?.baseUrl ?? '',
            dependencies: this.elementProps.componentProps?.dependencies ?? [],
        };
    }
}
