import { FormDataAPIType } from 'components/common/form';
import React from 'react';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { ReportContentNS } from 'components/report-content';
import ComponentSettingsChartMeasureField = ReportContentNS.ComponentSettingsChartMeasureField;
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { Box, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';

export default function MeasureFieldForm({
    field,
    onApply,
    onCancel,
    title,
}: {
    field: ComponentSettingsChartMeasureField;
    onApply: (field: ComponentSettingsChartMeasureField) => void;
    onCancel: () => void;
    title: string;
}) {
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        column_name: field.column_name,
        y_axis: field.y_axis,
    });
    const { t } = useBundleTranslation(['components/report-content']);

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader title={title} onCancel={() => onCancel()} item={field.column_name} />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormText',
                                label: t('line_chart.display_name'),
                                name: 'column_name',
                            },
                        })}
                    />
                </Box>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Box flexShrink={0}>Y-Axis: Chart on</Box>
                    <ReactHookFormController
                        componentValues={[
                            { label: t('line_chart.left'), value: 'left' },
                            { label: t('line_chart.right'), value: 'right' },
                        ]}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormRadioGroup',
                                label: t('line_chart.y_axis_chart_on'),
                                name: 'y_axis',
                            },
                        })}
                    />
                </Stack>
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        onApply({
                            ...field,
                            ...elementProps.form.hookFormGetValues(),
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
