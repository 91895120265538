import { BookmarkType } from './BookmarkDropdown';
import Filter, { FilterType, useApplyFilterValueMap } from 'components/external-reference/Filter';
import React, { useEffect, useState } from 'react';
import { applyBookmarkToFilters, getBookmarkReferenceId, getNewBookmarkObj, setBookmarkReferenceId } from './Bookmark';
import { Stack, Box, Button, Input } from '@mui/material';
import useBundleTranslation from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import ReactSelect from 'components/common/react-select/ReactSelect';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { ManagerContextType } from 'components/external-reference/bookmark/BookmarManager';

export default function BookmarkForm({
    bookmark,
    filters,
    siblingTabs,
    onSave,
    onCancel,
    managerContextType,
    elementId,
}: {
    bookmark: BookmarkType;
    filters: Array<FilterType>;
    siblingTabs: Array<SiblingTabType>;
    onSave: any;
    onCancel: any;
    managerContextType: ManagerContextType;
    elementId: number;
}) {
    const { t } = useBundleTranslation(['components/external-reference/bookmark']);

    const [bookmarkName, setBookmarkName] = useState<string>(bookmark.name ?? '');
    const handleFilterChange = function (filterId: number, newValue: any) {
        const list = editableFilters.slice();
        // Convert string|number to array
        if (!Array.isArray(newValue)) {
            newValue = newValue == '' ? [] : [newValue];
        }
        editableFilters!.find((f) => f.filterId == filterId)!.selectedValues = newValue;

        setEditableFilters(useApplyFilterValueMap(list));
    };

    const filtersList: Array<FilterType> = [];
    filters.forEach((filter) => {
        const f = Object.assign({}, filter);
        f.filterId > 0 ? filtersList.push(f) : filtersList.unshift(f);
    });
    const [editableFilters, setEditableFilters] = useState<Array<FilterType>>(
        applyBookmarkToFilters(filtersList, bookmark),
    );

    useEffect(() => {
        setEditableFilters(applyBookmarkToFilters(editableFilters, bookmark));
    }, [bookmark]);

    const handleSave = function () {
        const result = getNewBookmarkObj(bookmark);
        result.filtersList = editableFilters.map((filter) => ({
            filterId: filter.filterId,
            values: filter.values.filter((v) => filter.selectedValues.includes(v.value_id)),
            selectedValues: filter.selectedValues,
            filterInput: filter.filterInput,
        }));
        result.name = cForm.form.hookFormGetValues('customName') == 'Y' ? bookmarkName : '';
        onSave(result);
    };

    const handleTabChange = (value: string) => {
        const siblingTab = siblingTabs.find((t) => t.referenceId == Number(value));
        if (!siblingTab) {
            return;
        }
        setBookmarkReferenceId(bookmark, siblingTab);
        setSelectedReferenceId(siblingTab.referenceId);
    };
    const tabsValues = siblingTabs.map((t) => ({
        label: t.title,
        value: String(t.referenceId),
    }));

    const [selectedReferenceId, setSelectedReferenceId] = useState<number>(getBookmarkReferenceId(bookmark));

    const cForm = useCustomSimplifiedForm({ customName: bookmarkName.length > 0 ? 'Y' : 'N' });
    const isCustomName = cForm.form.hookFormWatch('customName') == 'Y';
    const nameLabel = t('custom_bookmark_name', { type: t(managerContextType) });
    return (
        <Stack spacing={2}>
            {editableFilters!
                .filter((filter: FilterType) => !filter.replacedBySegment)
                .map((filter: FilterType) => (
                    <Box key={filter.filterId}>
                        {filter.title}
                        <Filter
                            elementId={elementId}
                            context="bookmark"
                            onFilterChange={handleFilterChange}
                            filter={filter}
                        />
                    </Box>
                ))}
            {tabsValues.length > 0 && (
                <Box>
                    {bookmark.bookmarkType != 'reference' && t('tab_page')}
                    <ReactSelect value={selectedReferenceId} update={handleTabChange} data={tabsValues} />
                </Box>
            )}
            {managerContextType != 'reference' && (
                <Box>
                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...cForm,
                                component: {
                                    component: 'FormCheckbox',
                                    name: 'customName',
                                    label: t('set') + ' ' + nameLabel,
                                },
                            })}
                        />
                    </Box>
                    {isCustomName && (
                        <Box>
                            <Input
                                fullWidth
                                type="text"
                                value={bookmarkName}
                                onChange={(e) => setBookmarkName(e.target.value)}
                            />
                        </Box>
                    )}
                </Box>
            )}
            <Box>
                <Button onClick={handleSave} startIcon={<FontAwesomeIcon icon={faSave} />}>
                    {t('form:button_save')}
                </Button>
                <Button sx={{ ml: 1 }} variant="outlined" color="neutral" onClick={onCancel}>
                    {t('form:button_cancel')}
                </Button>
            </Box>
        </Stack>
    );
}
