import React, { useContext } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { SetChartStateContext } from 'components/metric/MetricViewer';
import { instance } from 'api/api';
import { ActSubmit } from 'components/common/form/act';

export default function ManageOverlayForm(props: IFormLayoutProps) {
    const setState = useContext(SetChartStateContext);

    const handleSubmit = (data: any) => {
        setState((prevState: any) => {
            return {
                ...prevState,
                left_mask: props.hookForm.form.hookFormGetValues('left_axis_mask'),
                right_mask: props.hookForm.form.hookFormGetValues('right_mask_mask'),
            };
        });

        return instance
            .post('/data/chart/overlay/manage/save-manage-overlays-state', {
                for_all_users: props.hookForm.form.hookFormGetValues('for_all_users'),
                for_all_dimensions: props.hookForm.form.hookFormGetValues('for_all_dimensions'),
                default_for_all_users_ind: props.hookForm.form.hookFormGetValues('default_for_all_users_ind'),
                is_default_checked: props.hookForm.form.hookFormGetValues('is_default_checked'),
                element: props.hookForm.form.hookFormGetValues('element_id'),
                segment: props.hookForm.form.hookFormGetValues('segment_id'),
                uco: props.hookForm.form.hookFormGetValues('uco'),
            })
            .then((response) => {
                props.asPopupProps.onHide();
            });
        // return props.onSubmit(data).then((response) => {});
    };

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                return handleSubmit(data);
            }
        },
    );

    return <FormLayoutComposer props={props} />;
}
