import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { Popup } from 'components/common/popup/Popup';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { FormRendererAPIType } from 'components/common/form';
import { instance } from 'api/api';
import { useNavigate } from 'react-router-dom';
import FiltersFormWrapper from 'components/common/grid/cell/burst-filter-cell/FiltersFormWrapper';

interface BurstFilterCellProps extends CustomCellProps {
    elementId: number;
    segmentValueId: number;
    externalId: number;
    listId: number;
    isSubscription: string;
    itemId: number;
    hasFilters: boolean;
    multipleRefsLabel?: string;
}

export default function BurstFilterCell({
    elementId,
    segmentValueId,
    externalId,
    listId,
    isSubscription,
    itemId,
    hasFilters,
    multipleRefsLabel,
}: BurstFilterCellProps) {
    const { t } = useBundleTranslation(['components/notification/burst']);
    const [open, setOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleClick = () => {
        if (isSubscription == 'Y') {
            navigate(`/extreport/${elementId}#show_alert_wizard`);
            return;
        }

        setOpen(true);
    };

    const [form, setForm] = useState<FormRendererAPIType>();
    const [isLoading, setIsLoading] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    useEffect(() => {
        const subscription = form?.hookFormWatch((value, { name, type }) => {
            if (!name) {
                return;
            }
            setIsDirty(true);
        });
        return () => subscription?.unsubscribe();
    }, [form?.hookFormWatch]);

    const label = t(multipleRefsLabel ? 'settings' : 'filters');
    return (
        <>
            <Typography sx={{ py: 1 }}>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': { color: 'primary.main' },
                    }}
                    onClick={handleClick}
                >
                    <IconMi icon={multipleRefsLabel ? 'my-settings' : 'filter'} sx={{ fontSize: '16px', mr: 0.5 }} />
                    {label}
                </Box>
            </Typography>
            <Popup
                settings={{
                    noCancel: true,
                    title: t('filter.form_desc'),
                }}
                okDisabled={!isDirty}
                open={open}
                onHide={() => {
                    setOpen(false);
                }}
                onConfirm={() => {
                    setIsLoading(true);
                    if (!form) {
                        return;
                    }
                    const formData = new FormData();
                    const data = form.hookFormGetValues();
                    for (let key in data) {
                        formData.append(key, data[key]);
                    }
                    instance
                        .post(
                            `/data/notification/distribution-list-item/${elementId}/${segmentValueId}/${externalId}/${listId}/form`,
                            formData,
                        )
                        .then((response) => {
                            setIsLoading(false);
                            setIsDirty(false);
                        });
                }}
            >
                <>
                    {isLoading && <LoadingPlaceholder />}
                    <FiltersFormWrapper
                        setForm={(form) => setForm(form)}
                        elementId={elementId}
                        segmentValueId={segmentValueId}
                        externalId={externalId}
                        listId={listId}
                        hasFilters={hasFilters}
                        multipleRefsLabel={multipleRefsLabel}
                        itemId={itemId}
                    />
                </>
            </Popup>
        </>
    );
}
