import { lazy } from 'react';
import { RouteItemType } from 'routes/index';
const Page = lazy(() => import('components/common/Page'));
const FormPage = lazy(() => import('components/common/FormPage'));
const EngagementPage = lazy(() => import('app/admin/engagement'));
const AdminLogPage = lazy(() => import('app/admin/log/index'));
const AdminPages = lazy(() => import('app/admin/admin-page/index'));

export const adminRoutes: Array<RouteItemType> = [
    ...[
        /*
        Save help links
        {
            path: ['/admin/'],
            props: { helpLink: 'https://help.metricinsights.com/m/10033/l/104358-accessing-admin-editors-and-functions' },
        },
        {
            path: ['/admin/element'],
            props: { helpLink: 'https://help.metricinsights.com/m/10477/l/104511-setting-permissions-from-element-editors' },
        },
        {
            path: ['/admin/slideshow'],
            index + editor
            props: { helpLink: 'https://help.metricinsights.com/m/Pushing_Information_to_Users/l/117421-define-a-slide-show' },
        },
        */
        {
            path: ['/admin/'],
            name: 'admin.name',
            component: AdminPages,
        },
        {
            path: ['/admin/custom-attribute'],
            name: 'custom_attribute.name',
            component: Page,
            props: { settingsUrl: '/data/admin/custom-attribute/index' },
        },
        {
            path: ['/admin/custom-attribute/:customAttributeId'],
            name: 'custom_attribute.editor.name',
            component: FormPage,
            props: {
                uid: 'customAttributeForm',
                pk: 'customAttributeId',
                settingsUrl: '/data/admin/custom-attribute/:customAttributeId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'custom_attribute.name',
                            href: '/admin/custom-attribute',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/custom-script'],
            name: 'custom_script.name',
            component: Page,
            props: { settingsUrl: '/data/admin/custom-script/index' },
        },
        {
            path: ['/admin/custom-script/:customScriptId'],
            name: 'custom_script.editor.name',
            component: FormPage,
            props: {
                uid: 'customScriptForm',
                pk: 'customScriptId',
                settingsUrl: '/data/admin/custom-script/:customScriptId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'custom_script.name',
                            href: '/admin/custom-script',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/folder-trash/'],
            name: 'folder_trash.name',
            component: Page,
            props: { settingsUrl: '/data/admin/folder-trash/index' },
        },
        {
            path: ['/editor/business-unit/'],
            name: 'business_unit.name',
            component: Page,
            props: { settingsUrl: '/data/editor/business-unit/index', pageMenu: 'users' },
        },
        {
            path: ['/editor/business-unit/:businessUnitId'],
            name: 'business_unit.editor.name',
            component: FormPage,
            props: {
                uid: 'businessUnitForm',
                pk: 'businessUnitId',
                settingsUrl: '/data/editor/business-unit/:businessUnitId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'business_unit.name',
                            href: '/editor/business-unit',
                        },
                    ],
                },
            },
        },
        {
            path: ['/editor/group'],
            name: 'group.name',
            component: Page,
            props: {
                settingsUrl: '/data/editor/group/index',
                pageMenu: 'users',
                helpLink:
                    'https://help.metricinsights.com/m/Controlling_Access_to_Metric_Insights/l/104512-create-a-new-user-account-and-assign-to-group',
            },
        },
        {
            path: ['/editor/group/:groupId'],
            name: 'group.editor.name',
            component: FormPage,
            props: {
                uid: 'groupForm',
                pk: 'groupId',
                settingsUrl: '/data/editor/group/:groupId/form',
                pageTitle: { value: 'group.editor.name', instanceDataKey: 'noInstanceDataKey' },
                breadcrumbs: {
                    list: [
                        {
                            label: 'group.name',
                            href: '/editor/group',
                        },
                    ],
                },
                helpLink:
                    'https://help.metricinsights.com/m/Controlling_Access_to_Metric_Insights/l/104512-create-a-new-user-account-and-assign-to-group',
            },
        },
        {
            path: ['/editor/privilege-set'],
            name: 'privilege_set.name',
            component: Page,
            props: { settingsUrl: '/data/editor/privilege-set/index', pageMenu: 'users' },
        },
        {
            path: ['/editor/privilege-set/:privilegeSetId'],
            name: 'privilege_set.editor.name',
            component: FormPage,
            props: {
                uid: 'privilegeSetForm',
                pk: 'privilegeSetId',
                settingsUrl: '/data/editor/privilege-set/:privilegeSetId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'privilege_set.name',
                            href: '/editor/privilege-set',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/system-variables'],
            name: 'system_variables.name',
            component: Page,
            props: { settingsUrl: '/data/admin/system-variables/index' },
        },
        {
            path: ['/admin/setting'],
            name: 'settings',
            component: FormPage,
            props: {
                uid: 'admin_setting',
                settingsUrl: '/data/admin/setting/form',
            },
        },
        {
            path: ['/admin/setting/home'],
            name: 'settings',
            component: FormPage,
            props: {
                uid: 'admin_setting_form',
                settingsUrl: '/data/admin/setting/home/form',
            },
        },
        {
            path: ['/admin/setting/legacy-script'],
            name: 'legacy_scripts.name',
            component: FormPage,
            props: {
                uid: 'admin_setting_legacy_script',
                settingsUrl: '/data/admin/setting/legacy-script/form',
            },
        },
        {
            path: ['/editor/brand'],
            name: 'brand.name',
            component: Page,
            props: { settingsUrl: '/data/editor/brand/index' },
        },
        {
            path: ['/editor/brand/:brandId'],
            name: 'brand.editor.name',
            component: FormPage,
            props: {
                uid: 'brandForm',
                pk: 'brandId',
                settingsUrl: '/data/editor/brand/:brandId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'brand.name',
                            href: '/editor/brand',
                        },
                    ],
                },
            },
        },
        {
            path: ['/editor/brand-theme'],
            name: 'brand_theme.name',
            component: Page,
            props: { settingsUrl: '/data/editor/brand-theme/index' },
        },
        {
            path: ['/editor/brand-theme/:brandThemeId'],
            name: 'brand_theme.editor.name',
            component: FormPage,
            props: {
                uid: 'brandThemeForm',
                pk: 'brandThemeId',
                settingsUrl: '/data/editor/brand-theme/:brandThemeId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'brand_theme.name',
                            href: '/editor/brand-theme',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/certification-level'],
            name: 'certification_level.name',
            component: Page,
            props: { settingsUrl: '/data/admin/certification-level/index', pageMenu: 'certificationLevels' },
        },
        {
            path: ['/admin/certification-level/sync'],
            name: 'certification_level.sync.name',
            component: Page,
            props: { settingsUrl: '/data/admin/certification-level/sync/index', pageMenu: 'certificationLevels' },
        },
        {
            path: ['/admin/certification-level/sync/:id'],
            name: 'certification_level.sync.editor.name',
            component: FormPage,
            props: {
                uid: 'certificationLevelSyncForm',
                pk: 'id',
                settingsUrl: '/data/admin/certification-level/sync/:id/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'certification_level.sync.name',
                            href: '/admin/certification-level/sync',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/storage'],
            name: 'storage.name',
            component: Page,
            props: { settingsUrl: '/data/admin/storage/index', pageMenu: 'collectionStorage' },
        },
        {
            path: ['/admin/storage/:datasetStorageId'],
            name: 'storage.editor.name',
            component: FormPage,
            props: {
                uid: 'storageForm',
                pk: 'datasetStorageId',
                settingsUrl: '/data/admin/storage/:datasetStorageId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'storage.name',
                            href: '/admin/storage',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/search'],
            name: 'search.editor.name',
            component: FormPage,
            props: {
                uid: 'searchForm',
                pk: 'searchSettingsId',
                settingsUrl: '/data/admin/search/1/form',
                //TODO: fix breadcrumbs
                breadcrumbs: {
                    list: [
                        {
                            label: 'search.name',
                            href: '/admin/search',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/search/dataset/:searchDatasetMetadataId'],
            //TODO: insert search dataset title
            name: 'search.dataset.name',
            component: FormPage,
            props: {
                uid: 'searchDatasetForm',
                pk: 'searchDatasetMetadataId',
                settingsUrl: '/data/admin/search/dataset/:searchDatasetMetadataId/form',
                breadcrumbs: {
                    //TODO: fix breadcrumbs
                    list: [
                        {
                            label: 'search.name',
                            href: '/admin/search',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/engagement'],
            name: 'engagement.name',
            component: EngagementPage,
            props: {
                breadcrumbs: {
                    list: [
                        {
                            label: 'engagement.name',
                            href: '/admin/engagement',
                        },
                    ],
                },
                pageMenu: 'publishing',
            },
        },
        {
            path: ['/admin/intermediate-page'],
            name: 'intermediate_page.name',
            component: Page,
            props: { settingsUrl: '/data/admin/intermediate-page/index' },
        },
        {
            path: ['/admin/intermediate-page/:intermediatePageId'],
            name: 'intermediate_page.editor.name',
            component: FormPage,
            props: {
                uid: 'storageForm',
                pk: 'intermediatePageId',
                settingsUrl: '/data/admin/intermediate-page/:intermediatePageId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'intermediate_page.editor.name',
                            href: '/admin/intermediate-page',
                        },
                    ],
                },
            },
        },
        {
            path: ['/editor/privilege'],
            name: 'privilege.name',
            component: Page,
            props: { settingsUrl: '/data/editor/privilege/index' },
        },
        {
            path: ['/admin/rating-prompt'],
            name: 'rating_prompt.name',
            component: Page,
            props: { settingsUrl: '/data/admin/rating-prompt/index' },
        },
        {
            path: ['/admin/rating-prompt/:ratingPromptId'],
            name: 'rating_prompt.editor.name',
            component: FormPage,
            props: {
                uid: 'ratingPromptForm',
                pk: 'ratingPromptId',
                settingsUrl: '/data/admin/rating-prompt/:ratingPromptId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'rating_prompt.name',
                            href: '/admin/rating-prompt',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/content'],
            name: 'content.name',
            component: Page,
            props: { settingsUrl: '/data/admin/content/index', pageMenu: 'importExport' },
        },
        {
            path: ['/admin/content/export'],
            name: 'content.export.name',
            component: FormPage,
            props: {
                uid: 'ratingPromptForm',
                pk: 'ratingPromptId',
                settingsUrl: '/data/admin/content/export/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'content.name',
                            href: '/admin/content',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/content/export/location'],
            name: 'content.export.location.name',
            component: Page,
            props: { settingsUrl: '/data/admin/content/export/location/index', pageMenu: 'importExport' },
        },
        {
            path: ['/admin/content/export/location/:exportLocationId'],
            name: 'content.export.location.editor.name',
            component: FormPage,
            props: {
                uid: 'ExportLocationForm',
                pk: 'exportLocationId',
                settingsUrl: '/data/admin/content/export/location/:exportLocationId/form',
                breadcrumbs: {
                    list: [
                        {
                            label: 'content.export.location.name',
                            href: '/admin/content/export/location',
                        },
                    ],
                },
            },
        },
        {
            path: ['/admin/import-export-history'],
            name: 'import_export_history.name',
            component: Page,
            props: { settingsUrl: '/data/admin/import-export-history/index', pageMenu: 'importExport' },
        },

        {
            path: ['/admin/plugin-config'],
            name: 'plugin_config.name',
            component: Page,
            props: { settingsUrl: '/data/admin/plugin-config/index' },
        },
        {
            path: ['/admin/cleanup/'],
            name: 'cleanup.name',
            component: Page,
            props: { settingsUrl: '/data/admin/cleanup/index' },
        },
        {
            path: ['/admin/log'],
            name: 'admin.log',
            component: AdminLogPage,
            props: {
                breadcrumbs: {
                    list: [
                        {
                            label: 'monitor.name',
                            href: '/monitor#errors',
                        },
                        {
                            label: 'admin.log',
                        },
                    ],
                },
                pageTitle: { value: 'admin.title' },
            },
        },
        {
            path: ['/admin/vocabulary/language'],
            name: 'language',
            component: Page,
            props: { settingsUrl: '/data/admin/vocabulary/language/index' },
        },
        {
            path: ['/admin/vocabulary/path'],
            name: 'path',
            component: Page,
            props: { settingsUrl: '/data/admin/vocabulary/path/index' },
        },
        {
            path: ['/admin/vocabulary/token'],
            name: 'token',
            component: Page,
            props: { settingsUrl: '/data/admin/vocabulary/token/index' },
        },
        {
            path: ['/admin/vocabulary'],
            name: 'vocabulary.name',
            component: Page,
            props: { settingsUrl: '/data/admin/vocabulary/index' },
        },
        {
            path: ['/admin/trash'],
            name: 'trash.name',
            component: Page,
            props: { settingsUrl: '/data/admin/trash/index' },
        },
        {
            path: ['/admin/owner'],
            name: 'owner.editor.name',
            component: FormPage,
            props: {
                uid: 'ownerForm',
                pk: 'ownerId',
                settingsUrl: '/data/admin/owner/1/form',
                breadcrumbs: {
                    instanceDataKey: 'noInstanceDataKey',
                },
            },
        },
    ],
];
