import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export default {
    component: {
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.0426),
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 12px',
        borderTopLeftRadius: (theme: Theme) => theme.shape.borderRadius,
        borderBottomLeftRadius: (theme: Theme) => theme.shape.borderRadius,
        border: (theme: Theme) => `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
        borderRight: 0,
        marginRight: '-3px',
        minWidth: 0,
        flexShrink: 0,
        '&>div': {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
};
