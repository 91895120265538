import { instance } from './api';
import { SearchFiltersType } from 'app/search/global/type';
import { APIResponse, YNValues } from 'tools/types';

export interface FormSearchItem {
    label: string;
    redirectUrl?: string;
    id: number;
}

export interface FormSearchResults {
    status: string;
    items: FormSearchItem[];
}

export interface RateItem {
    [key: string]: number;
}

export interface RatesResults {
    [key: string]: RateItem;
}

export interface IndexStatus {
    index_queue: string[];
    index_status: string;
    last_index_status: string;
    last_index_success_time: string;
}

export interface TaleImage {
    user_id: number;
    thumb_data: any;
    category_id: number;
    element_id: number;
    thumb_src: string;
    element_type: string;
    thumb_metric?: string;
    favorite_id: number;
    segment_value_id: number;
    hpt_element_id: null | string;
    hpt_element_type: null | string;
    measurement_interval_id: string;
    report_index_preview_type: string;
    report_dynamic_layout_ind: 'Y' | 'N';
    report_no_units_label: null | string;
    uses_dataset_access_map_ind: null | string;
    user_dashboard_element_instance_id: number;
    external_report_trusted_collection_ind: string;
    result_row_calculation_disabled_by_governor_ind: 'Y' | 'N';
}

export interface GlobalSuggestionsResults {
    recent_searches: string[];
    status: string;
    suggestions: {
        count: number;
        score: number;
        word: string;
        next_words: string[];
    }[];
}

export interface SearchRow {
    id: number;
    name?: string;
    info: SearchRowInfo;
    reasons: {
        name: string;
        table: string;
    }[];
    score: number;
    second_id: number;
    type: string;
    words: string[];
    external_link?: string;
}

export interface SearchRowInfo {
    name?: string;
    type?: string;
    element_id?: string;
    dataset_id?: string;
    notification_schedule_distribution_id?: string;
    portal_page_id?: string;
    description?: string;
    created_time?: string;
    report_rows: null;
    segment_value_id?: number;
    business_owner?: string;
    total_view_count?: string;
    technical_owner?: string;
    external_content_type_name?: string;
    category_name?: string;
    icon_name?: string;
    reporting_tool_name: string;
    description_markdown: string;
    last_certified_time?: string;
    last_certified_by_name?: string;
    certified_ind: 'Y' | 'N';
    certification_level_id?: number;
    description_markdown_ind: 'Y' | 'N';
    is_empty_instance_ind: 'Y' | 'N';
    use_custom_no_access_image_ind: 'Y' | 'N';
    can_be_used_as_access_map_ind: 'Y' | 'N';
    user_dashboard_element_instance_id: number | string;
    columns?: string;
    elements?: string;
    internal_name?: string;
    url?: string;
    topics: SearchTopics;
    custom_fields: {
        cfs_id: number;
        label: string;
        fields: {
            id: number;
            name: string;
            supportRestriction: boolean;
            type: string;
            values: string[] | { [key: number]: string };
        }[];
    }[];
    document_id?: string;
    document_type_name?: string;
    file_name?: string;
    source_type?: string;
    external_url?: string;
    icon_url?: string;
    external_link?: string;
    plugin_connection_profile_id?: number;
    external_report_reference_id?: number;
    external_report_display?: string;
    engagement?: number;
    is_discoverable?: number;
}

export interface SearchTopics {
    [key: string]: {
        fa_icon: string | null;
        icon: string | null;
        icon_type: string | null;
        topic_type_name: string;
        use_icon: string | null;

        topics: {
            topic_id: number | null;
            name: string;
        }[];
    };
}

export interface ExternalLinkResponse {
    url: string;
}

export interface GlobalSearchResults {
    page: number;
    quantity: number;
    index_status: string;
    perPageCount: number;
    rows: SearchRow[];
    search_log_id: number;
    showOwners: boolean;
    status: string;
}

export type TabItem = {
    id: number;
    count: number;
    icon: string;
    type: string;
    label: string;
    value: string;
};

export interface GlobalSearchFilterValues {
    categories: { [key: string]: number };
    tags: { [key: string]: number };
    owners: { [key: string]: number };
    tabs: {
        dashboard_element: number;
        plugin: { [key: string]: number };
        all: number;
        notification_schedule_distribution: number;
        dataset_data: { [key: string]: number };
        categories: { [key: number]: number };
        dataset: number;
        external_content: { [key: number]: number };
    };
    engagement?: {
        min: number;
        max: number;
    };
    custom_field: { [key: string]: string[] };
    discoverable: boolean;
    enabledCertificationLevels: boolean;
    certificationLevels: [
        {
            label: string;
            value: string;
        },
    ];
    tag_names: string[];
    openLinksInNewTab: YNValues;
}

export interface TagItem {
    icon: {
        fa_icon: string;
        icon_type: string;
        icon?: string;
    } | null;
    id: number;
    text: string;
}

export interface CategoryItem {
    id: number;
    text: string;
}

export interface SearchTuningReason {
    field: string;
    name: string;
    value: string;
    table: string;
    rank: number;
    field_rate: number;
    reason_score: number;
    record_id: number;
    second_id: number;
    table_rate: number;
    result_score: number;
    rated_score?: number;
    f_rate?: number;
    t_rate?: number;
    calculated_score: number;
    words: {
        [key: string]: number;
    };
}

export interface SearchTuningResultItem {
    id: number;
    name: string;
    type: string;
    score: number;
    words: string[];
    total: number;
    second_id: number;
    reasons: SearchTuningReason[];
    search_entity_id: number;
}

export interface SearchTuningResultsData {
    time: number;
    quantity: number;
    index_queue: any[];
    index_status: string;
    last_index_status: string;
    data: SearchTuningResultItem[];
    last_index_success_time: string;
}

export interface SaveRatesParams {
    fields: [string, string, number][];
    tables: [string, string, number][];
}

export interface OwnerItem extends CategoryItem {}

export interface CustomFieldItem {
    id: number;
    text: string;
    type: string;
}

export interface CustomFieldSectionItem {
    cfs_id: number;
    fields: CustomFieldItem[];
    label: string;
}

export interface LogClickData {
    type: string;
    item_id: number;
    second_id: number;
    icon_url: string;
    external_link: string;
    search_log_id: number;
    search_text: string;
}

export interface BuildUrlData {
    profile: number;
    ext_report: number;
}

export interface BuildUrlResponse {
    status: string;
    uri: string;
    has_tail: YNValues;
    file_name: string;
}

export interface UserTab {
    title: string;
    type: string;
    uid: string;
    uploaded_icon?: string;
}

export interface ExternalIcons {
    [key: string]: string;
}

export interface IExternalTool {
    external_page_ind: YNValues;
    icon: string | false;
    icon_id: number | null;
    search_external_tool_id: number;
    title: string;
    url: string;
}

export const imageBlockSize = 3;
export const maxCreatedInLastDays = 180;

const getFormData = (filters: SearchFiltersType = {}) => {
    const formData = new FormData();

    if (filters && Object.keys(filters).length > 0) {
        Object.entries(filters).forEach(([key, value]) => {
            if (typeof value === 'string' || typeof value === 'number') {
                formData.append(key, String(value));
            }

            if (Array.isArray(value)) {
                formData.append(key, value.join(','));

                return;
            }

            if (typeof value === 'object' && value !== null) {
                const keys = Object.keys(value);

                keys.forEach((objectKey) => {
                    const objectValue = value[objectKey];

                    if (Array.isArray(objectValue)) {
                        objectValue.forEach((objectValueData) => {
                            formData.append(`${key}[${objectKey}][]`, objectValueData);
                        });
                    }
                });

                return;
            }
        });
    }

    return formData;
};

export const searchAPI = {
    async loadTileImages(ids: string[]): Promise<TaleImage[]> {
        const formData = new FormData();

        ids.forEach((id) => {
            formData.append('ids[]', id);
        });

        const response = await instance.post('/data/home/index/load-tile-images', formData);
        return response.data.data || [];
    },

    async globalSearch(filters: SearchFiltersType = {}): Promise<GlobalSearchResults> {
        const response = await instance.post('/data/search/global/get-global-search', getFormData(filters), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response.data;
    },

    async getExternalIcons(): Promise<ExternalIcons> {
        const response = await instance.get('/data/search/global/get-external-icons');

        return response.data.data;
    },

    async getUserTabs(): Promise<UserTab[]> {
        const response = await instance.get('/data/search/global/get-tabs');

        return response.data.data;
    },

    async getFilterValues(filters: SearchFiltersType = {}): Promise<GlobalSearchFilterValues> {
        const response = await instance.post('/data/search/global/get-filter-values', getFormData(filters));

        return response.data.data;
    },

    async loadSuggestions(): Promise<GlobalSuggestionsResults> {
        const response = await instance.get('/data/search/global/get-suggestions');
        return response.data;
    },

    async getTagsFilter(): Promise<TagItem[]> {
        const response = await instance.get(`/data/search/global/get-filter-select?data=tag`);
        return response.data.data;
    },

    async getCategoriesFilter(search: string = '', ids: string = ''): Promise<CategoryItem[]> {
        const response = await instance.get(
            `/data/search/global/get-filter-select?data=category&category_id=${ids}&search=${search}`,
        );
        return response.data.data;
    },

    async getCustomFields(): Promise<CustomFieldSectionItem[]> {
        const response = await instance.get(`/data/search/global/get-filter-select?data=custom_field`);
        return response.data.data;
    },

    async getIndexStatus(): Promise<IndexStatus> {
        const response = await instance.get('/data/search/global/get-index-status');
        return response.data.data;
    },

    async getOwnersFilter(search: string = '', ids: string = ''): Promise<OwnerItem[]> {
        const response = await instance.get(
            `/data/search/global/get-filter-select?data=owner&owner_id=${ids}&search=${search}`,
        );
        return response.data.data;
    },

    async formSearch(url: string, pattern: string): Promise<FormSearchResults> {
        const response = await instance.get(url, { params: { pattern } });
        return response.data;
    },

    async getFieldRates(): Promise<RatesResults> {
        const response = await instance.get('/data/search/tuning/get-field-rates');
        return response.data?.data ?? {};
    },

    async getTableRates(): Promise<RatesResults> {
        const response = await instance.get('/data/search/tuning/get-table-rates');
        return response.data?.data ?? {};
    },

    async tuningSearch(q: string): Promise<SearchTuningResultsData> {
        const response = await instance.get('/data/search/tuning/search', {
            params: {
                search_v2: 'Y',
                q,
            },
        });
        return response.data?.data ?? {};
    },

    async resetSearchRates(): Promise<string> {
        const response = await instance.post('/data/search/tuning/reset-rates');
        return response.data?.status;
    },

    async updateIndexes(): Promise<string> {
        const response = await instance.put('/data/search/tuning/update-index');
        return response.data?.status;
    },

    async saveSearchRates(values: SaveRatesParams): Promise<string> {
        const response = await instance.post('/data/search/tuning/post-rates', { values });
        return response.data?.status;
    },

    async logClick(data: LogClickData): Promise<APIResponse<any>> {
        const response = await instance.post(`/data/search/global/log-click`, data);
        return response.data.data;
    },

    async buildUrl(data: BuildUrlData): Promise<BuildUrlResponse> {
        const response = await instance.post(`/data/search/global/build-url`, data);
        return response.data;
    },

    async getExternalTools(): Promise<IExternalTool[]> {
        const response = await instance.get(`/data/search/global/get-external-tools`);
        return response.data.data;
    },
};
