import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiInputConfig = {
    defaultProps: {
        size: 'medium',
        disableUnderline: true,
        minRows: 3,
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                border: `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.default,
                '.MuiInput-input::placeholder': {
                    color: alpha(theme.palette.text.primary, 0.64),
                    opacity: 1,
                },
                '&:hover': {
                    borderColor: alpha(theme.palette.primary.main, 0.8),
                },
                '&.Mui-focused': {
                    borderColor: theme.palette.primary.main,
                    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.24)}`,
                    '.MuiInput-input::placeholder': {
                        opacity: 0,
                    },
                },
                '&.Mui-error': {
                    borderColor: alpha(theme.palette.error.main, 0.4),
                    '.MuiInput-input': {
                        color: theme.palette.error.main,
                    },
                    '&:hover': {
                        borderColor: theme.palette.error.main,
                    },
                    '&.Mui-focused': {
                        borderColor: theme.palette.error.main,
                        boxShadow: `0 0 0 2px ${alpha(theme.palette.error.main, 0.24)}`,
                    },
                },
                '&.Mui-disabled': {
                    backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                    '.MuiInput-input': {
                        color: alpha(theme.palette.text.primary, 0.64),
                    },
                },
                'label + &': {
                    marginTop: 0,
                },
            };
        },
        input: ({ theme }: { theme: any }) => {
            return {
                height: 26,
                boxSizing: 'border-box',
                padding: '0 12px',
                color: theme.palette.primary.main,
                '&::placeholder': {
                    color: theme.palette.text.secondary,
                },
                '&.Mui-disabled': {
                    color: theme.palette.text.primary,
                    WebkitTextFillColor: 'currentColor',
                },
            };
        },
        multiline: () => {
            return {
                '& > .MuiInput-input': {
                    paddingTop: '6px',
                    paddingBottom: '6px',
                },
            };
        },
    },
};
