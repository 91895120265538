import { Box, Button, FormLabel, Stack, Typography } from '@mui/material';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import GridComponent from 'components/common/grid/GridComponent';

export interface VersionIdentifierProps extends FormControlProps {
    gridSettingsUrl: string;
}

export default function VersionIdentifier({ controlProps }: FormElementControlPropsType<VersionIdentifierProps>) {
    const { t } = useBundleTranslation(['app/editor/page']);

    const [showPopup, setShowPopup] = useState(false);

    const onClick = () => {
        setShowPopup(true);
    };

    const popupSettings: PopupSettings = {
        title: t('version_history'),
        textOK: t('version_history_close'),
        noCancel: true,
    };

    return (
        <>
            <Stack direction={'column'} key={controlProps.uid + '-change-template'}>
                <FormLabel>{t(controlProps.label as string)}</FormLabel>
                <Box>
                    {controlProps.value && (
                        <Button
                            variant={'light'}
                            onClick={onClick}
                            sx={{
                                fontFamily: (theme) => theme.font.monospace,
                            }}
                        >
                            {controlProps.value}
                        </Button>
                    )}
                </Box>
            </Stack>
            {showPopup && (
                <Popup
                    settings={popupSettings}
                    open={showPopup}
                    onHide={() => {
                        setShowPopup(false);
                    }}
                >
                    <Stack direction={'column'} spacing={2}>
                        <GridComponent
                            uid={`editor-history-${controlProps.gridSettingsUrl}`}
                            settingsUrl={controlProps.gridSettingsUrl}
                            formGrid
                        />
                        <Typography>{t('version_history_hint')}</Typography>
                    </Stack>
                </Popup>
            )}
        </>
    );
}

export class VersionIdentifierBuilder extends FormComponentBuilder {
    prepareProps(): VersionIdentifierProps {
        return {
            ...this.controlProps,
            gridSettingsUrl: this.elementProps.componentProps?.gridSettingsUrl ?? '',
        };
    }
}
