import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React from 'react';

export interface IConfirmPopupPropsSettings {
    popupSettings: PopupSettings;
    onConfirm: () => void;
    onHide: () => void;
    message: string;
}
export interface IConfirmPopupProps {
    isOpen: boolean;
    settings: IConfirmPopupPropsSettings;
}

export default function ConfirmPopup({ isOpen, settings }: IConfirmPopupProps) {
    const { popupSettings, onConfirm = () => {}, onHide = () => {}, message } = settings;

    return (
        <Popup settings={popupSettings} open={isOpen} onConfirm={onConfirm} onHide={onHide} maxWidth={'popupSm'}>
            {message}
        </Popup>
    );
}
