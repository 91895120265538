import { TextField, Box, Button } from '@mui/material';
import { FormElementControlPropsType, FormControlURLFieldProps } from './index';
import useBundleTranslation from 'i18n';

import styles from './FormUrlField.styles';

export default function FormUrlField({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlURLFieldProps>) {
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);

    const redirectToUrl = () => {
        if (!controlProps.value) {
            alert(t('url_empty_msg'));
        } else {
            window.open(controlProps.value, '_blank');
        }
    };

    return (
        <Box sx={styles.root}>
            <TextField
                sx={styles.input}
                name={controlProps.name}
                value={controlProps.value}
                label={t(controlProps.label as string)}
                onChange={controlProps.onChange}
                placeholder={controlProps.placeholder}
            />
            <Button
                data-test={controlProps.name + '-test-url'}
                onClick={redirectToUrl}
                sx={styles.action}
                variant="contained"
            >
                {t('url_field_button_text')}
            </Button>
        </Box>
    );
}
