import { lazy } from 'react';

import { RouteItemType } from 'routes';
const MessagePage = lazy(() => import('components/common/page/MessagePage'));

export const serviceRoutes: Array<RouteItemType> = [
    {
        path: ['/service/external/unsubscribe'],
        name: 'service.unsubscribe',
        component: MessagePage,
        props: {
            pageTitle: { value: 'info' },
            settingsUrl: '/data/service/external/unsubscribe',
        },
    },
    {
        path: ['/service/external/followdiscussion'],
        name: 'service.followdiscussion',
        component: MessagePage,
        props: {
            pageTitle: { value: 'info' },
            settingsUrl: '/data/service/external/followdiscussion',
        },
    },
    {
        path: ['/service/external/followuser'],
        name: 'service.followuser',
        component: MessagePage,
        props: {
            pageTitle: { value: 'info' },
            settingsUrl: '/data/service/external/followuser',
        },
    },
];
