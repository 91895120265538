import { Alert, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Popup } from 'components/common/popup/Popup';
import { IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { FormLayoutContext } from 'components/common/form/layout/tab';
import FormTabsContent from 'components/common/form/layout/tab/FormTabsContent';
import { getFormId } from 'components/common/form/formTools';

interface IFormPopupLayoutProps
    extends Omit<IFormLayoutProps, 'confirmPopupProps' | 'subFormPopup' | 'panelSettings' | 'sharedComponents'> {}

export default function FormPopupLayout({
    asPopupProps,
    elementProps,
    tabs,
    componentsMap,
    hookForm,
    customTabs,
    uid,
    isNew,
}: IFormPopupLayoutProps) {
    const submit = useRef(
        hookForm.form.hookFormHandleSubmit(function (data) {
            return hookForm.form.formAct.act.submit.exec(data);
        }),
    );

    // Exception for grid form
    if (uid == 'grid_popup_categoryGrid') {
        uid = 'category';
    }

    const id = getFormId(uid, isNew);

    const formRef = useRef<HTMLFormElement>(null);
    useEffect(() => {
        if (!formRef.current) {
            return;
        }
        const focusableElement: HTMLElement | null = formRef.current.querySelector('[data-focusable]');
        if (focusableElement) {
            focusableElement.querySelector('input')?.focus();
        }
    }, [formRef.current]);

    const isDisabledPopupConfirmButton = hookForm.form.hookFormWatch('disable_popup_confirm_button');

    const onConfirm = () => {
        hookForm.form.formAct.act.submit.exec(hookForm.form.hookFormGetValues());
    };

    return (
        <Popup
            settings={{ ...asPopupProps.popupSettings, uid: 'p_' + uid }}
            onHide={asPopupProps.onHide}
            onConfirm={onConfirm}
            open={asPopupProps.isOpen}
            maxWidth={asPopupProps.popupSettings.maxWidth}
            translationNS={elementProps.translationNS}
            okDisabled={isDisabledPopupConfirmButton === 'Y'}
        >
            {hookForm.form.hookFormState.errors.general &&
                componentsMap.findIndex((c) => c.componentName == 'ImportErrorStack') == -1 && (
                    <Alert icon={false} severity="error" sx={{ padding: '8px 10px !important', marginBottom: '10px' }}>
                        {hookForm.form.hookFormState.errors.general?.message as string}
                    </Alert>
                )}
            <form ref={formRef} id={id} onSubmit={submit.current} className={'form-popup'}>
                <Stack className={'form-popup__content'}>
                    <FormLayoutContext.Provider value={{ formLayout: 'popup', formType: asPopupProps.popupType }}>
                        <FormTabsContent
                            componentsMap={componentsMap}
                            formTabs={tabs.formTabs}
                            elementProps={elementProps}
                            customTabs={customTabs}
                        />
                    </FormLayoutContext.Provider>
                </Stack>
            </form>
        </Popup>
    );
}
