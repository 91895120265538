import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default {
    wrapper: {
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.04),
        padding: 1.5,
        borderRadius: 1,
    },
};
