import {
    ChartCompareLineSeriesTypeRaw,
    ChartSeriesType,
    DATA_X_MULTIPLIER,
    MetricChartCompareLineType,
    MetricChartStateLegend,
} from 'components/metric/chart/index';
import { AssocArray } from 'tools/types';

export function getCompareLineSeries(
    rawSeries: Array<ChartCompareLineSeriesTypeRaw>,
    compareLinesState: Array<MetricChartCompareLineType>,
    legendState: AssocArray<MetricChartStateLegend>,
): Array<ChartSeriesType> {
    return compareLinesState
        .map((state) => {
            const seriesData = rawSeries.find((s) => s.id == String(state.id));
            if (!seriesData) {
                return null;
            }

            const id = 'compare_line_' + seriesData.id;
            return {
                seriesType: 'compareLine',
                data: seriesData.data.map((point) => ({ y: point.y, x: point.x * DATA_X_MULTIPLIER })),
                type: 'line',
                id: id,
                name: seriesData.name,
                dashStyle: state.dashStyle,
                color: state.color,
                zIndex: state.zIndex,
                marker: state.marker,
                // states: {
                //     hover: {
                //         lineWidthPlus: 0,
                //         enabled: false,
                //     },
                // },
                visible: legendState ? (legendState[id]?.visible ?? true) : true,
            };
        })
        .filter((series) => series != null) as Array<ChartSeriesType>;
}
