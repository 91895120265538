import { FormElementControlPropsType, FormControlDatePickerProps } from './index';
import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, Stack } from '@mui/material';
import Moment from 'moment';
import IconMi from 'components/common/icon/IconMi';
import Components, { ComponentKey } from 'components/common/ComponentIndex';
import { FormComponentBuilder } from 'components/common/form/layout/index';
import DatePicker from 'components/common/date-picker/DatePicker';

export default function FormDatePicker({ controlProps }: FormElementControlPropsType<FormControlDatePickerProps>) {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const dateFormat = controlProps.showTimeSelect
        ? 'yyyy-MM-dd HH:mm' + (controlProps.noSeconds ? '' : ':ss')
        : 'yyyy-MM-dd';

    const inputProps = {
        startAdornment:
            controlProps.iconPosition !== 'end' ? (
                <InputAdornment position="start">
                    <IconMi icon="calendar" fontSize="16" />
                </InputAdornment>
            ) : undefined,
        endAdornment:
            controlProps.iconPosition === 'end' ? (
                <InputAdornment position="end">
                    <IconMi icon="calendar" fontSize="16" />
                </InputAdornment>
            ) : undefined,
        readOnly: controlProps.readOnly,
    };

    const onDateChange = (date: Date | null) => {
        setStartDate(date);
        controlProps.onChange(date ? Moment(date).format(dateFormat.replaceAll('dd', 'DD')) : null);
    };

    useEffect(() => {
        if (controlProps.value) {
            setStartDate(new Date(controlProps.value));
        }
    }, [controlProps.value]);

    const hasHelper = typeof controlProps.helper != 'undefined';

    return (
        <Stack direction="row" width="100%" sx={{ width: controlProps.width }}>
            <DatePicker
                maxDate={controlProps.maxDate}
                minDate={controlProps.minDate}
                minTime={
                    controlProps.minDate &&
                    Moment(controlProps.minDate).format('yyyy-MM-DD') == Moment(startDate).format('yyyy-MM-DD')
                        ? controlProps.minDate
                        : new Date(new Date().setHours(0, 0, 0, 0))
                }
                maxTime={
                    controlProps.maxDate &&
                    Moment(controlProps.maxDate).format('yyyy-MM-DD') == Moment(startDate).format('yyyy-MM-DD')
                        ? controlProps.maxDate
                        : new Date(new Date().setHours(23, 59, 0, 0))
                }
                selected={startDate}
                onChange={onDateChange}
                placeholderText={controlProps.placeholder}
                showTimeSelect={controlProps.showTimeSelect}
                timeIntervals={controlProps.timeIntervals}
                fixedHeight
                disabled={controlProps.disabled}
                dateFormat={dateFormat}
                showPopperArrow={false}
                customInput={<TextField fullWidth InputProps={inputProps} />}
                // value={controlProps.value}
                data-test={controlProps.uid}
                disabledKeyboardNavigation
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.stopPropagation();
                    }
                }}
            />
            {hasHelper &&
                React.createElement(Components[controlProps.helper.name as ComponentKey] as React.FC, {
                    // @ts-ignore
                    value: controlProps.value,
                    afterSave: () => {},
                    helperProps: controlProps.helper,
                    controlProps: controlProps,
                })}
            <input name={controlProps.name} type={'hidden'} />
        </Stack>
    );
}

export class FormDatePickerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlDatePickerProps {
        let maxDate = undefined;
        if (this.elementProps.componentProps?.maxDate) {
            maxDate = new Date(this.elementProps.componentProps.maxDate);
        }
        let minDate = undefined;
        if (this.elementProps.componentProps?.minDate) {
            minDate = new Date(this.elementProps.componentProps.minDate);
        }

        return {
            ...this.controlProps,
            placeholder: this.elementProps.componentProps?.placeholder ?? '',
            showTimeSelect: this.elementProps.componentProps?.showTimeSelect ?? '',
            timeIntervals: this.elementProps.componentProps?.timeIntervals,
            disabled: this.elementProps.componentProps?.disabled ?? false,
            noSeconds: this.elementProps.componentProps?.noSeconds ?? false,
            iconPosition: this.elementProps.componentProps?.iconPosition ?? 'start',
            maxDate: maxDate,
            minDate: minDate,
        };
    }
}
