import React from 'react';
import { Button, Grid } from '@mui/material';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';

export default function UnselectAllButton({
    uid,
    setCheckedKeys,
    id,
}: {
    uid: string;
    setCheckedKeys: (keys: number[]) => void;
    id: string;
}) {
    const { t } = useBundleTranslation(['components/common/grid/grid']);

    return (
        <Grid item key={'unselect-all'}>
            <Button
                onClick={() => {
                    setCheckedKeys([]);
                }}
                key={uid}
                startIcon={<IconMi icon="uncheck" />}
                variant="outlined"
                color="neutral"
                data-test={id}
            >
                {t('unselect_all_button')}
            </Button>
        </Grid>
    );
}
