import React from 'react';
import MITable from './MITable';
import { GridProps } from './index';
import AsyncGridComponent from './AsyncGridComponent';

function GridComponent(props: GridProps) {
    if (props.settings) {
        return (
            <MITable
                data={props.data.rows}
                columns={props.settings.columns}
                massActionsConfig={{ enabled: false }}
                t={props.t}
                gridName={props.uid}
            />
        );
    } else {
        return (
            <AsyncGridComponent
                hideButtons={props.hideButtons}
                reloadTrigger={props?.reloadTrigger ?? 0}
                uid={props.uid}
                settingsUrl={props.settingsUrl ?? ''}
                formGrid={props.formGrid ?? false}
                t={props.t}
                setFormDirty={props.setFormDirty}
                onDataStoreChange={props.onDataStoreChange}
                relatedFields={props.relatedFields}
                removeHeaderIndent={props.removeHeaderIndent}
                gridDescription={props.gridDescription}
                height={props.height}
                form={props.form}
            />
        );
    }
}

export default GridComponent;
