import { ISubstitutionFormProps } from 'components/common/form/data-fetch-command/substitution/index';
import useBundleTranslation from 'i18n';
import { getElementFieldValue } from 'components/common/form/formTools';
import React, { useEffect } from 'react';
import Moment from 'moment';
import { Container, FormControl, FormLabel } from '@mui/material';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import SubstitutionSegmentValueField from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionSegmentValueField';
import SubstitutionDateTimeValueField from 'components/common/form/data-fetch-command/substitution/substitution-form/SubstitutionDateTimeValueField';
import useDatasetHasBackfield from 'components/common/form/data-fetch-command/substitution/hooks/useDatasetHasBackfield';

export default function SubstitutionDatasetForm(props: ISubstitutionFormProps) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const segmentId = props.editorForm.hookFormGetValues('segment_id');
    const isBindParam = getElementFieldValue(props.editorForm, `segment_id.bind_param`) ?? '' > '';

    const isUpdateInstances = props.formApi.form.hookFormWatch('update_instances') == 'Y';
    const dateType = props.formApi.form.hookFormWatch('update_mode');
    useEffect(() => {
        //@ts-ignore
        const defaults = window.datasetCollection ?? {};
        props.formApi.form.hookFormSetValue('update_instances', defaults.update_instances ?? 'N');
        props.formApi.form.hookFormSetValue('update_mode', defaults.update_mode ?? 'date');
        const defaultDate = Moment(new Date()).format('yyyy-MM-DD');
        props.formApi.form.hookFormSetValue('effective_date', defaults.effective_date ?? defaultDate);
        props.formApi.form.hookFormSetValue('effective_date_from', defaults.effective_date_from ?? defaultDate);
        props.formApi.form.hookFormSetValue('effective_date_to', defaults.effective_date_to ?? defaultDate);
    }, []);

    useEffect(() => {
        const subscription = props.formApi.form.hookFormWatch((value, { name, type }) => {
            if (typeof name == 'undefined') {
                return;
            }
            if (
                [
                    'update_instances',
                    'update_mode',
                    'effective_date',
                    'effective_date_from',
                    'effective_date_to',
                ].includes(name)
            ) {
                //@ts-ignore
                window.datasetCollection[name] = value[name];
            }
        });
        return () => subscription?.unsubscribe();
    }, [props.formApi.form.hookFormWatch]);

    const measurement = props.editorForm.hookFormGetValues('measurement_interval_id');

    if (useDatasetHasBackfield(props.mode, props.editorForm, props.dataSource == 'manual')) {
        return (
            <div>
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    {t('validation.dataset.do_update')}
                    <TemplateFormComponent
                        elementProps={props.formApi}
                        component={'FormRadioGroup'}
                        componentValues={[
                            { label: 'Yes', value: 'Y' },
                            { label: 'No', value: 'N' },
                        ]}
                        name={'update_instances'}
                    />
                    <br />
                </Container>
                <span className={isUpdateInstances ? '' : 'd-none'}>
                    <Container maxWidth={'sm'} component="main" disableGutters={true}>
                        {t('validation.dataset.instances')}
                        <TemplateFormComponent
                            elementProps={props.formApi}
                            component={'FormRadioGroup'}
                            componentValues={[
                                { label: t('validation.dataset.t_date'), value: 'date' },
                                { label: t('validation.dataset.t_range'), value: 'range' },
                            ]}
                            name={'update_mode'}
                        />
                        <br />
                    </Container>
                    <Container
                        className={dateType == 'range' ? 'd-none' : ''}
                        maxWidth={'sm'}
                        component="main"
                        disableGutters={true}
                    >
                        {t('validation.dataset.all_instances')}
                        <FormControl fullWidth>
                            <FormLabel>{t('validation.dataset.since')}</FormLabel>
                            <TemplateFormComponent
                                component={'FormDatePicker'}
                                elementProps={props.formApi}
                                name={'effective_date'}
                            />
                        </FormControl>
                        <br />
                    </Container>
                    <Container
                        className={dateType == 'date' ? 'd-none' : ''}
                        maxWidth={'sm'}
                        component="main"
                        disableGutters={true}
                    >
                        {t('validation.dataset.all_instances')}
                        <FormControl fullWidth>
                            <FormLabel>From</FormLabel>
                            <TemplateFormComponent
                                component={'FormDatePicker'}
                                elementProps={props.formApi}
                                name={'effective_date_from'}
                            />
                        </FormControl>
                        <br />
                    </Container>
                    <Container
                        className={dateType == 'date' ? 'd-none' : ''}
                        maxWidth={'sm'}
                        component="main"
                        disableGutters={true}
                    >
                        <FormControl fullWidth>
                            <FormLabel>{t('validation.dataset.to')}</FormLabel>
                            <TemplateFormComponent
                                component={'FormDatePicker'}
                                elementProps={props.formApi}
                                name={'effective_date_to'}
                            />
                            <br />
                        </FormControl>
                    </Container>
                </span>
                {t('validation.dataset.tip')}
            </div>
        );
    }

    return (
        <div>
            {segmentId > 0 && isBindParam && <SubstitutionSegmentValueField {...props} segmentId={segmentId} />}
            <SubstitutionDateTimeValueField showTimeSelect={measurement < 3} {...props} />
        </div>
    );
}
