import { FormComponentValue, getComponentKey } from 'components/common/form/layout/control';
import Components, { ComponentKey, getFormComponentFieldType } from 'components/common/ComponentIndex';
import { logWarning } from 'tools/tools';
import { FormElementProps, RawFormComponentType } from 'components/common/form';

export interface WrapFormElementProps {
    elementProps: FormElementProps;
    componentValues?: Array<FormComponentValue>;
    children?: any;
}

const controlWrappersMap = {
    FormCheckbox: 'FormWrapperEmpty',
    GridComponent: 'FormWrapperEmpty',
    FormText: 'FormWrapperEmpty',
    FormUrlField: 'FormWrapperEmpty',
    FormTinyMCE: 'FormWrapperEmpty',
    FormCompareField: 'FormWrapperEmpty',
    FormCompareFieldBetween: 'FormWrapperEmpty',
    FormCompareInput: 'FormWrapperGroup',
    FormSwitch: 'FormWrapperEmpty',
    FormStaticInfo: 'FormWrapperEmpty',
    FormTimeField: 'FormWrapperEmpty',
    FormDateRange: 'FormWrapperEmpty',
    FormTimeSelect: 'FormWrapperEmpty',
    FormDateSelect: 'FormWrapperEmpty',
    PublicImagePreview: 'FormWrapperEmpty',
    UserSelection: 'FormWrapperEmpty',
    FilterUsageRefreshButton: 'FormWrapperEmpty',
    ReferenceExternalFiltersManager: 'FormWrapperEmpty',
    ExternalContentCustomUrl: 'FormWrapperEmpty',
    ExternalReportCustomUrl: 'FormWrapperEmpty',
    EmbedCodePopup: 'FormWrapperEmpty',
    KpiRuleInfo: 'FormWrapperEmpty',
    KpiRuleAssignWorkflowLink: 'FormWrapperEmpty',
    KpiRuleUserMapAccess: 'FormWrapperEmpty',
    CodeEditor: 'FormWrapperEmpty',
    FilterDateDefault: 'FormWrapperEmpty',
} as {
    [key: string]: ComponentKey;
};

/**
 * Get Wrapper for Component
 * If wrapper set to false no wrapper will be rendered
 * @param componentType
 */
export function getComponentWrapperKey(componentType: RawFormComponentType): ComponentKey | false {
    const componentKey = getComponentKey(componentType);
    const componentFieldType = getFormComponentFieldType(componentKey);

    if (componentType.wrapper === false) {
        return false;
    }

    if (componentFieldType == 'other') {
        if (typeof controlWrappersMap[componentKey] != 'undefined') {
            return controlWrappersMap[componentKey];
        }
        return false;
    }

    if (typeof componentType.wrapper == 'undefined' || typeof Components[componentType.wrapper] == 'undefined') {
        if (componentType.wrapper) {
            logWarning('"wrapper" property of Form Field contains wrong Wrapper component: ' + componentType.wrapper);
        }

        if (typeof controlWrappersMap[componentKey] != 'undefined') {
            return controlWrappersMap[componentKey];
        }
        return componentFieldType == 'control' ? 'FormWrapperElementLabel' : 'FormWrapperEmpty';
        //return 'FormWrapperEmpty';
    }
    return componentType.wrapper as ComponentKey;
}
