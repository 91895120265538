import { alpha, Box, Chip, Stack, Tooltip } from '@mui/material';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import React, { useContext } from 'react';
import { EditPanelComponentType } from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/index';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getElementFieldValue } from 'components/common/form/formTools';

export default function IncludeAsAttachment({
    elementProps,
    t,
    componentName,
}: EditPanelComponentType & { componentName: string }) {
    const fInclude = elementProps.form.hookFormWatch('include_in_email_attachment') == 'Y';

    const formsContext = useContext<FormsContextType>(FormsContext);
    const type =
        getElementFieldValue(elementProps.form, '$form[report].include_content_into_digest_as', formsContext) ?? '';

    let wrongFormats = ['csv', 'csvf'];
    switch (componentName) {
        case 'dataset':
            wrongFormats = ['pptx'];
            break;
        case 'pivot':
            wrongFormats.push('pptx');
            break;
    }
    const showWarn = wrongFormats.indexOf(type) != -1;

    return (
        <Box>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box
                    sx={{
                        '.MuiFormControlLabel-root': {
                            mr: 0,
                        },
                    }}
                >
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email_attachment'}
                        label={t('include_in_email_attachment')}
                    />
                </Box>
                {fInclude && (
                    <>
                        <Box
                            sx={{
                                width: '1px',
                                height: '16px',
                                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                            }}
                        />
                        <Box>{t('include_in_email_file_type')}</Box>
                        <Tooltip title={t('change_via_report_distribution_tab')}>
                            <Chip label={t(`download_format.${type}`)} />
                        </Tooltip>
                    </>
                )}
            </Stack>
            {fInclude && showWarn && (
                <Box className="color-error">
                    {t('download_format_warning', {
                        format: t(`download_format.${type}`),
                        component: t(`titles.${componentName}`),
                    })}
                </Box>
            )}
        </Box>
    );
}
