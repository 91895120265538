import { FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';
import useBundleTranslation from 'i18n';
import { AssocArray } from 'tools/types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IValidationResponse } from 'components/common/form/data-fetch-command/validation';
import { useNavigate, useParams } from 'react-router-dom';
import { processSettingsUrl } from 'components/common/form/formTools';
import pollingService from 'tools/pollingService';
import { DataFetchCommandNS } from 'components/common/form/data-fetch-command/index';
import prepareRequestData = DataFetchCommandNS.prepareRequestData;
import { BodyLoadingPlaceholder } from 'components/common/loading-placeholder/LoadingPlaceholder';
import getSuccessMessage from 'components/common/form/data-fetch-command/validation/getSuccessMessage';
import DataFetchCommandSubstitution from 'components/common/form/data-fetch-command/substitution/DataFetchCommandSubstitution';
import { useRequiredFields } from 'components/common/form/data-fetch-command/substitution/hooks/useRequiredFields';
import useDatasetHasBackfield from 'components/common/form/data-fetch-command/substitution/hooks/useDatasetHasBackfield';
import { instance } from 'api/api';
import {
    DataValidateCollectContext,
    GenericCollectionResult,
    ProcessCollectionResultPromise,
} from 'components/common/form/data-validate-collect/useDataValidateCollect';
import { safeNavigate } from 'components/common/form/hooks/usePageForm';

export default function DataFetchCommandCollection({
    children,
    controlProps,
    onResult,
    matchedPatterns,
    lastCollectionData = {},
    setLastCollectionData,
    hasData,
    validationSuccess,
}: {
    children: any;
    controlProps: FormControlDataFetchCommandProps;
    onResult: <T extends IValidationResponse>(
        isSuccess: boolean,
        message: string,
        data: T,
    ) => Promise<ProcessCollectionResultPromise>;
    matchedPatterns: Array<string>;
    lastCollectionData: AssocArray<string>;
    setLastCollectionData: (data: AssocArray<string>) => void;
    hasData: boolean;
    validationSuccess: boolean;
}) {
    const urlParams = useParams();
    const url = processSettingsUrl(controlProps.collectionUrl, Object.keys(urlParams), urlParams);

    const shouldOpenViewer = useRef(false);
    const shouldBuildReport = useRef(false);

    const navigate = useNavigate();
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const dataValidateCollectContext = useContext(DataValidateCollectContext);

    let [collectionStatus, setCollectionStatus] = useState<[boolean, number]>([false, 0]);
    const requiredFields = useRequiredFields(matchedPatterns, lastCollectionData);
    const onCollectionSuccess = async function <T extends IValidationResponse>(data: T) {
        setCollectionStatus([false, 0]);
        await onResult(true, getSuccessMessage(controlProps.type, data, t, 'collect'), data);
        // Navigate user to Viewer
        // TODO: should this cod run on ANY 'view' click action?
        if (shouldOpenViewer.current) {
            // Save form if dirty
            const saveResult = await controlProps.form.formAct.act.submit.exec(controlProps.form.hookFormGetValues());
            if (!saveResult || saveResult?.data?.status == 'ERROR') {
                return Promise.reject({ status: false, data: 'save failed' });
            }
            if (controlProps.type == 'dataset') {
                // Try to build report
                if (shouldBuildReport.current) {
                    const datasetId = controlProps.form.hookFormGetValues('dataset_id');
                    instance.get(`data/dataset/${datasetId}/create-report`).then((response) => {
                        if (response.data?.status == 'OK' && response.data?.redirectUrl) {
                            safeNavigate(navigate, response.data.redirectUrl);
                        } else if (response.data?.message) {
                            alert(response.data?.message);
                            return Promise.reject({ status: false, data: response.data });
                        }
                    });
                } else {
                    safeNavigate(navigate, '/dataset/' + controlProps.form.hookFormGetValues('dataset_id'));
                }
            }
            if (controlProps.type == 'metric') {
                safeNavigate(navigate, '/chart/' + controlProps.form.hookFormGetValues('element_id'));
            }
        }
        return Promise.resolve({ status: true, data: data });
    };

    const onCollectionError = function (data: any) {
        setCollectionStatus([false, 0]);
        return onResult(false, String(data.message), data);
    };
    const [isCollectionCalled, setIsCollectionCalled] = useState<boolean>(false);
    // Reference to resolve for Promise after user fill substitution and finish validation
    const substitutionResolve = useRef(null);
    const handleCollectionClick = async (
        ignoreLastCollectionData: boolean,
        openViewer: boolean,
        buildReport: boolean,
    ): Promise<any> => {
        shouldOpenViewer.current = openViewer;
        shouldBuildReport.current = buildReport;
        // Run Validate if needed
        if (
            dataValidateCollectContext?.legacyHandleValidate?.current &&
            (controlProps.type == 'dataset' ||
                (controlProps.type == 'metric' && controlProps.form.hookFormGetValues('data_source') != 'aggregate')) &&
            !validationSuccess
        ) {
            const validation = await dataValidateCollectContext?.legacyHandleValidate.current(ignoreLastCollectionData);
            if (!validation.status) {
                return Promise.reject({ status: false, data: 'validation failed' });
            }
        }
        // Save form if dirty
        const saveResult = await controlProps.form.formAct.act.submit.exec(controlProps.form.hookFormGetValues());
        if (!saveResult || saveResult?.data?.status == 'ERROR') {
            return Promise.reject({ status: false, data: 'save failed' });
        }
        // After success save or if form is not dirty
        if (
            matchedPatterns.length == 0 ||
            (controlProps.type == 'dataset' &&
                !useDatasetHasBackfield('collect', controlProps.form, controlProps.dataSource == 'manual'))
        ) {
            return handleStartCollection(lastCollectionData);
        } else {
            setIsCollectionCalled(true);
            return new Promise((resolve) => {
                //@ts-ignore
                substitutionResolve.current = resolve;
            });
        }
    };

    useEffect(() => {
        // Register function call in formContext
        if (dataValidateCollectContext) {
            if (!dataValidateCollectContext.legacyHandleCollect.current) {
                //@ts-ignore
                dataValidateCollectContext.legacyHandleCollect.current = handleCollectionClick;
            }
        }
        return () => {
            // Register function call in formContext
            if (dataValidateCollectContext) {
                //@ts-ignore
                dataValidateCollectContext.legacyHandleCollect.current = undefined;
            }
        };
    }, []);

    const handleStartCollection = (args: any) => {
        setIsCollectionCalled(false);
        return collectionRun(args);
    };

    const handleCancelCollection = (pplId: number) => {
        if (cancelCollectionRef.current) {
            cancelCollectionRef.current(pplId);
            setCollectionStatus([false, 0]);
        }
    };

    const cancelCollectionRef = useRef<null | Function>(null);
    const collectionRun = (substitutions: AssocArray<string>): Promise<GenericCollectionResult> => {
        return new Promise((resolve, reject) => {
            setLastCollectionData(substitutions);
            const { create, cancel } = pollingService({
                onSuccess: async (data: IValidationResponse) => {
                    await onCollectionSuccess(data);
                    resolve({ status: true, data: data });
                },
                onError: async (data: any) => {
                    await onCollectionError(data);
                    resolve({ status: false, data: data });
                },
                onPplIdChange: (id) => setCollectionStatus([true, id]),
                requestData: prepareRequestData(substitutions, controlProps, url),
            });
            create();
            cancelCollectionRef.current = cancel;
            setCollectionStatus([true, 0]);
        });
    };

    return (
        <span>
            <BodyLoadingPlaceholder dataTest={'data-fetch-command-loading-placeholder'} open={collectionStatus[0]}>
                <span onClick={() => handleCancelCollection(collectionStatus[1])}>Cancel</span>
            </BodyLoadingPlaceholder>
            {isCollectionCalled && (
                <DataFetchCommandSubstitution
                    mode={'collect'}
                    matchedPatterns={matchedPatterns}
                    defaults={lastCollectionData}
                    requiredFields={requiredFields}
                    start={(...args) => {
                        const result = handleStartCollection(...args);
                        if (substitutionResolve.current) {
                            return result.then((data) => {
                                setTimeout(() => {
                                    //@ts-ignore
                                    substitutionResolve.current(data);
                                }, 100);
                            });
                        }
                        return result;
                    }}
                    cancel={() => setIsCollectionCalled(false)}
                    type={controlProps.type}
                    editorForm={controlProps.form}
                    dataSource={controlProps.dataSource}
                    hasData={hasData}
                />
            )}
            <span
                onClick={async () => {
                    try {
                        return await handleCollectionClick(true, false, false);
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {children}
            </span>
        </span>
    );
}
