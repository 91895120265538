import { instance } from './api';
import { APIResponse } from 'tools/types';
import { ElementInFavoritesType } from 'components/element-viewer';

export interface UpdateFavoritesRequestData {
    ids: number[];
    scope: string;
    name: string;
}

export interface NeedConfirmResponseData {
    status: 'OK' | 'ERROR';
    data: {
        needConfirm: boolean;
    };
    added?: {
        element_id: number;
        segment_value_id: number;
        favorite_id: number;
        last_updated_by: string;
    };
    removed?: {
        element_id: number;
        segment_value_id: number;
        favorite_id: number;
        last_updated_by: string;
    };
}

export const favoritesAPI = {
    async getElementFavoritesList(
        elementId: number,
        segmentId: number,
        selected: boolean,
    ): Promise<Array<ElementInFavoritesType>> {
        const response = await instance.get<APIResponse<Array<ElementInFavoritesType>>>(
            `/data/element/get-favorites/${elementId}/${segmentId}/selected/` + (selected ? 'Y' : 'N'),
        );
        if (response.data.status == 'OK') {
            return response.data.data;
        }
        return [];
    },
    async addElementToFavorites(elementId: number, segmentId: number, data: UpdateFavoritesRequestData): Promise<any> {
        const response = await instance.post<APIResponse<any>>(
            `/data/element/save-favorites/${elementId}/${segmentId}`,
            data,
        );
        return response.data;
    },
    async verifyNeedToConfirm(elementId: number, segmentId: number): Promise<NeedConfirmResponseData> {
        const response = await instance.get(`/data/element/need-confirm/${elementId}/${segmentId}`);

        return response.data;
    },
};
