// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import * as All from './IconMiData';
import { IconMiNameList } from './IconMiData';

export default function IconMi(props: any) {
    const iconDef = IconMiNameList.includes(props.icon);
    if (iconDef) {
        //Convert name from type 'chevron-first' to camel case from import ChevronFirst
        const iconName = props.icon
            .split('-')
            .map((word: string) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join('');
        return (
            <SvgIcon
                {...props}
                sx={props.sx}
                className={(props.className ?? '') + ' svg-icon-mi'}
                component={All[iconName]}
                inheritViewBox
            />
        );
    }
    return null;
}
