import { FormComponentBuilder } from 'components/common/form/layout';
import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
} from 'components/common/form/layout/control';

import React, { useEffect, useRef, useState } from 'react';
import useBundleTranslation from 'i18n';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { getElementFieldValue, prepareFormElementProps } from 'components/common/form/formTools';
import { alpha, Button, FormControl, FormLabel, Stack, Theme, Typography } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { instance } from 'api/api';
import { AxiosResponse } from 'axios';
import pollingService, { APIResponsePooling } from 'tools/pollingService';
import IconMi from 'components/common/icon/IconMi';
import { InfoPopupWrapper, useInfoPopup } from 'components/common/popup/InfoPopup';
import { useFormState } from 'react-hook-form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';

export interface DatasetDataStorageProps extends FormControlProps {}

export default function DatasetDataStorage({
    controlProps,
    elementProps,
}: FormElementControlPropsType<DatasetDataStorageProps>) {
    const { t } = useBundleTranslation(['components/common/dataset_data_storage']);
    const popupForm = useCustomSimplifiedForm();

    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'dataset_storage_id') {
                const v = getElementFieldValue(controlProps.form, `dataset_storage_id.$option.label`) ?? '';
                controlProps.form.hookFormSetValue('dataset_data_storage_input', v);
                const register = controlProps.form.getElementValuesRegister('dataset_storage_id');
                if (register) {
                    const list = register.valuesRef.current.filter((v) => v.value != value[name]);
                    setStoragesList(list);
                    if (list.length) {
                        popupForm.form.hookFormSetValue('dataset_data_storage_select', list[0].value);
                    }
                }
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    const { isDirty } = useFormState({ control: controlProps.form.hookFormControl });
    const isDirtyRef = useRef(isDirty);
    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    const infoPopupSuccess = useInfoPopup(() => {
        if (isDirtyRef.current) {
            controlProps.form.formSave().then((response) => {
                if (response?.data?.status == 'OK') {
                    window.location.reload();
                }
            });
        } else {
            window.location.reload();
        }
    }, t('success'));
    const infoPopupError = useInfoPopup();

    const onSuccess = (data: any) => {
        infoPopupSuccess.setIsOpen(true);
        setIsLoading(false);
    };
    const onError = (data: any) => {
        infoPopupError.setContent(data?.message ?? t('error'));
        infoPopupError.setIsOpen(true);
        setIsLoading(false);
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleConfirm = () => {
        setIsLoading(true);
        const id = controlProps.form.hookFormGetValues('dataset_id');
        const storage = popupForm.form.hookFormGetValues('dataset_data_storage_select');

        instance
            .post(`data/editor/dataset/${id}/move`, { storage: storage })
            .then((response: AxiosResponse<APIResponsePooling<{ ppl_id: number; message?: string }>>) => {
                if (response.data.status == 'ERROR') {
                    alert(response.data.data.message);
                    setIsLoading(false);
                } else {
                    const { run } = pollingService<any, any>({
                        onSuccess,
                        onError,
                        requestData: {},
                    });
                    run(response.data.data.ppl_id);
                }
            })
            .catch(() => setIsLoading(false));
    };

    const [isOpen, setIsOpen] = useState(false);

    const [storagesList, setStoragesList] = useState<Array<FormComponentValue>>([]);

    return (
        <>
            <InfoPopupWrapper infoPopup={infoPopupSuccess} />
            <InfoPopupWrapper infoPopup={infoPopupError} />
            <FormControl
                sx={{ display: storagesList.length < 1 ? 'none' : 'block', '.MuiStack-root': { width: '100%' } }}
                onClick={() => setIsOpen(true)}
                fullWidth
            >
                <FormLabel className={controlProps.labelClass}>{t(controlProps.label as string)}</FormLabel>
                <Stack
                    direction={'row'}
                    sx={{
                        '.MuiInput-root': {
                            borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.64),
                            borderRight: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            input: {
                                pl: 1,
                                r: 1.5,
                            },
                            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
                        },
                    }}
                >
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            inputFilter: undefined,
                            component: {
                                uid: 'dataset_data_storage_input',
                                name: 'dataset_data_storage_input',
                                component: 'FormText',
                                props: {
                                    readOnly: true,
                                    tooltip: {
                                        input: t('main_tooltip'),
                                    },
                                },
                            },
                            componentProps: {
                                label: '',
                            },
                        })}
                    />
                    <Button
                        variant="contained"
                        startIcon={<IconMi icon="gear" />}
                        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                        {t('edit')}
                    </Button>
                </Stack>
            </FormControl>
            <Popup
                settings={{
                    title: t('move_dataset'),
                    textOK: t('process'),
                    needTranslation: false,
                }}
                open={isOpen}
                onHide={() => setIsOpen(false)}
                onConfirm={() => handleConfirm()}
            >
                {isLoading && (
                    <LoadingPlaceholder>
                        <Typography>{t('moving')}</Typography>
                    </LoadingPlaceholder>
                )}
                <ReactHookFormController
                    componentValues={storagesList}
                    elementProps={prepareFormElementProps({
                        ...popupForm,
                        inputFilter: undefined,
                        component: {
                            name: 'dataset_data_storage_select',
                            component: 'FormSelect',
                            props: {},
                        },
                    })}
                />
            </Popup>
        </>
    );
}

export class DatasetDataStorageBuilder extends FormComponentBuilder {
    prepareProps(): DatasetDataStorageProps {
        return {
            ...this.controlProps,
        };
    }
}
