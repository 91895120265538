import { FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormControlSelectMapProps, SelectMapData } from './index';
import ControlDataWrapper from 'components/common/form/ControlDataWrapper';
import { FormComponentBuilder } from 'components/common/form/layout';

export default function SelectMap({ controlProps }: FormElementControlPropsType<FormControlSelectMapProps>) {
    const prepareControlProps = function (data: SelectMapData) {
        return {
            ...controlProps,
            async: false,
            controlComplexData: data,
        };
    };

    return ControlDataWrapper<FormControlSelectMapProps, SelectMapData>({
        componentKey: 'SelectMapControl',
        controlProps: controlProps,
        prepareControlProps: prepareControlProps,
    });
}

export class SelectMapBuilder extends FormComponentBuilder {
    prepareProps(
        value: any,
        onChange: (...event: any[]) => void,
        afterChange: (event: any) => void,
    ): FormControlSelectMapProps {
        return {
            ...this.controlProps,
            async: this.elementProps.componentProps.async,
            controlComplexData: this.elementProps.componentProps.data,
            settingsUrl: this.elementProps.componentProps.settingsUrl,
            overrideFirstColumnType: this.elementProps.componentProps.overrideFirstColumnType,
            overrideSecondColumnType: this.elementProps.componentProps.overrideSecondColumnType,
            afterChange: afterChange,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            },
        };
    }
}
