import { ImageDataType } from 'components/element-image';
import useBundleTranslation from 'i18n';
import { RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import React, { useEffect, useMemo, useState } from 'react';
import { Crop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { formAPI } from 'api/form';
import { Link, Box, Button, Grid, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import IconMi from 'components/common/icon/IconMi';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import ImageWrapper from 'components/element-image/editor/form/ImageWrapper';

export default function SetupImageForm({
    imageData,
    onHide,
    imageBase64,
    settingsUrl,
    setupImgEnabled,
    recollectImage,
}: {
    imageData: ImageDataType;
    onHide: (afterSubmit: boolean) => void;
    imageBase64: string;
    settingsUrl: string;
    setupImgEnabled: boolean;
    recollectImage: (width: number, height: number) => void;
}) {
    const { t } = useBundleTranslation();

    const elementProps = useCustomSimplifiedForm({ ...imageData });

    const fieldsData = useMemo<any>(() => {
        const heightLabel = t('Height');
        const widthLabel = t('Width');
        // Original
        return {
            width: {
                inputFilter: 'int',
                label: widthLabel,
                component: {
                    component: 'FormText',
                    name: 'width',
                    label: '',
                    props: { readOnly: !setupImgEnabled },
                } as RawFormComponentType,
            },
            height: {
                inputFilter: 'int',
                label: heightLabel,
                component: {
                    component: 'FormText',
                    name: 'height',
                    label: '',
                    props: { readOnly: !setupImgEnabled },
                } as RawFormComponentType,
            },
            // Scale
            scaleTo: {
                component: { component: 'FormCheckbox', label: t('Scale to'), name: 'scale' } as RawFormComponentType,
            },
            scaleWidth: {
                inputFilter: 'int',
                label: widthLabel,
                component: { component: 'FormText', name: 'scaleWidth', label: '' } as RawFormComponentType,
            },
            scaleHeight: {
                inputFilter: 'int',
                label: heightLabel,
                component: { component: 'FormText', name: 'scaleHeight', label: '' } as RawFormComponentType,
            },
            // Crop
            crop: {
                component: { component: 'FormCheckbox', label: t('Crop'), name: 'crop' } as RawFormComponentType,
            },
            cropWidth: {
                inputFilter: 'int',
                label: widthLabel,
                component: { component: 'FormText', name: 'cropWidth', label: '' } as RawFormComponentType,
            },
            cropHeight: {
                inputFilter: 'int',
                label: heightLabel,
                component: { component: 'FormText', name: 'cropHeight', label: '' } as RawFormComponentType,
            },
            cropX: {
                inputFilter: 'int',
                label: 'X',
                component: { component: 'FormText', name: 'cropX', label: '' } as RawFormComponentType,
            },
            cropY: {
                inputFilter: 'int',
                label: 'Y',
                component: { component: 'FormText', name: 'cropY', label: '' } as RawFormComponentType,
            },
        };
    }, [imageData]);

    const handleCropChange = function (cropData: Crop) {
        elementProps.form.hookFormSetValue('cropHeight', Math.round(cropData.height));
        elementProps.form.hookFormSetValue('cropWidth', Math.round(cropData.width));
        elementProps.form.hookFormSetValue('cropX', Math.round(cropData.x));
        elementProps.form.hookFormSetValue('cropY', Math.round(cropData.y));
    };

    const watchFields = elementProps.form.hookFormWatch(['cropHeight', 'cropWidth', 'cropX', 'cropY', 'crop']);

    const buildCropData = function (): Crop {
        return {
            height: Number(elementProps.form.hookFormGetValues('cropHeight')),
            width: Number(elementProps.form.hookFormGetValues('cropWidth')),
            x: Number(elementProps.form.hookFormGetValues('cropX')),
            y: Number(elementProps.form.hookFormGetValues('cropY')),
            unit: 'px',
        };
    };

    useEffect(() => {
        const newState = buildCropData();
        if (JSON.stringify(cropData) != JSON.stringify(newState)) {
            setCropData(newState);
        }
    }, [watchFields]);

    const [cropData, setCropData] = useState<Crop>(buildCropData());

    const handleSubmit = (data: any) => {
        if (data.crop == 'Y') {
            if (data.width < data.cropWidth + data.cropX) {
                data.cropWidth -= data.cropWidth + data.cropX - data.width;
                if (data.cropWidth < 0) {
                    data.crop == 'N';
                }
            }
            if (data.height < data.cropHeight + data.cropY) {
                data.cropHeight -= data.cropHeight + data.cropY - data.height;
                if (data.cropHeight < 0) {
                    data.crop == 'N';
                }
            }
        }
        console.log(data);
        return formAPI.saveFormData(settingsUrl, data).then((response: any) => {
            if ('ERROR' === response.data.status) {
                alert(response.data.message);
            } else {
                onHide(true);
            }
        });
    };
    const handleCancel = () => {
        onHide(false);
    };

    const labelStyles = {
        padding: '0 3px 0 12px',
        width: '58px',
    };

    // Get current Image size form img element
    const handleImageSizeChange = (width: number, height: number) => {
        elementProps.form.hookFormSetValue('width', width);
        elementProps.form.hookFormSetValue('height', height);
    };

    return (
        <Grid container columnSpacing={3}>
            <Grid item xs={8}>
                {Boolean(imageBase64.length) && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                        }}
                    >
                        <ImageWrapper
                            isCropActive={elementProps.form.hookFormGetValues('crop') == 'Y'}
                            onChange={handleCropChange}
                            cropData={cropData}
                            src={imageBase64}
                            onImageSizeChange={handleImageSizeChange}
                            isBase64={true}
                        />
                    </Stack>
                )}
            </Grid>
            <Grid item xs={4}>
                <form onSubmit={elementProps.form.hookFormHandleSubmit(handleSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ mb: '-6px' }}>
                            {t('Original Size')}
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.width.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.width,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.height.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.height,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    pt: 1,
                                    pb: 3,
                                    borderBottom: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        recollectImage(
                                            Number(elementProps.form.hookFormGetValues('width')),
                                            Number(elementProps.form.hookFormGetValues('height')),
                                        )
                                    }
                                    disabled={!setupImgEnabled}
                                    variant="light"
                                    startIcon={<IconMi icon="save" />}
                                >
                                    Recollect Image
                                </Button>
                                <Link
                                    target="_blank"
                                    href="https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/1411988-understanding-setup-image"
                                >
                                    Read Mode
                                </Link>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mb: -1, pt: 1 }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.scaleTo,
                                    })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.scaleWidth.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.scaleWidth,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.scaleHeight.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.scaleHeight,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mb: -1, pt: 1 }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.crop,
                                    })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.cropX.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.cropX,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.cropY.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.cropY,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.cropWidth.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.cropWidth,
                                    })}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row">
                                <StaticAddon sx={labelStyles}>{fieldsData.cropHeight.label}</StaticAddon>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...fieldsData.cropHeight,
                                    })}
                                />
                            </Stack>
                        </Grid>

                        <Grid item container xs={12}>
                            <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
                                <Button
                                    disabled={!Boolean(imageBase64.length)}
                                    type={'submit'}
                                    variant="contained"
                                    sx={{ minWidth: '80px' }}
                                >
                                    {t('save')}
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    color="neutral"
                                    variant="outlined"
                                    sx={{ minWidth: '80px' }}
                                >
                                    {t('cancel')}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}
