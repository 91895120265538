import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { TagCellProps } from 'components/common/grid/';
import { getIcon } from 'components/common/grid/cell/index';
import useBundleTranslation from 'i18n';

export default function TagCell({ modification, value, items, defaultColor = 'primary' }: TagCellProps) {
    const { t } = useBundleTranslation();

    let itemsArr: any[];

    if (modification == 'yes-no') {
        const transformAlias: any = { Y: 'yes', N: 'no' };
        let color = 'default';
        let text = '';
        const aliasValue = value ? transformAlias[value] : undefined;
        if (aliasValue) {
            text = t(aliasValue);
            color = value == 'Y' ? 'success' : defaultColor;
        }
        if ('' == text) text = t(value ?? '');
        itemsArr = [{ text: text, color: color }];
    } else {
        itemsArr = Array.isArray(items) ? items : [];
    }

    return (
        <Box sx={{ py: 1 }}>
            {itemsArr
                .filter((tag) => tag.text)
                .map((tag, index) => {
                    const iconEl = getIcon(tag.icon) ?? undefined;
                    const color = tag.color ?? defaultColor;
                    return <Chip label={tag.text} color={color as any} icon={iconEl} key={index + tag.text} />;
                })}
        </Box>
    );
}
