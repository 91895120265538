import React, { useState, useContext } from 'react';
import useBundleTranslation from 'i18n';
import { Box, IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';

export interface QueryBuilderFilterGridRowProps {
    data: any;
    editItem: () => void;
}

export default function QueryBuilderFilterGridRow(props: QueryBuilderFilterGridRowProps) {
    const { data, editItem = () => {} } = props;
    const { queryAct, helperAct, configData } = useContext(PluginQBContext);

    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);

    let filterCondition = data.condition;
    let filterdVal = helperAct.escapeHtml(data.value);
    let filterFetchType = data.type;

    //todo translations
    if (filterCondition === 'is null') {
        filterCondition = 'is';
        filterdVal = 'empty';
    } else if (filterCondition === 'is not null') {
        filterCondition = 'is not';
        filterdVal = 'empty';
    }

    return (
        <Box sx={styles.rowWrapper}>
            {configData.prefiltering && <Box className={'cell cell--text cell--fetch-type'}>{filterFetchType}</Box>}
            <Box className={'cell cell--text cell--main'}>{helperAct.escapeHtml(data.column)}</Box>
            <Box className={'cell cell--text data--operator'}>{filterCondition}</Box>
            <Box className={'cell cell--text data--value'}>{filterdVal}</Box>
            <Box className={'cell cell--actions'}>
                <Tooltip title={t('filter_popup.edit_icon_tooltip')}>
                    <IconButton onClick={editItem}>
                        <IconMi icon="gear" fontSize="16" />
                    </IconButton>
                </Tooltip>

                <Tooltip title={t('filter_popup.remove_icon_tooltip')}>
                    <IconButton
                        onClick={() => {
                            queryAct.removeFilter(data);
                        }}
                    >
                        <IconMi icon="times" fontSize="16" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
}
