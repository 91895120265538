import { createSlice } from '@reduxjs/toolkit';

interface helpLinkSliceState {
    link: string;
}

export const helpLinkSlice = createSlice({
    name: 'helpLink',
    initialState: {
        link: '',
    } as helpLinkSliceState,
    reducers: {
        setHelpLink: (state, action) => {
            state.link = action.payload ?? '';
        },
    },
});

export const { setHelpLink } = helpLinkSlice.actions;

export default helpLinkSlice.reducer;
