import styles from './index.styles';
import { Box, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TopicsInfo } from 'api/editor/topics';
import IconMi from 'components/common/icon/IconMi';
import { debounce } from 'lodash';
import useBundleTranslation from 'i18n';

export default function GlossaryPopupLeftPanel({
    topicsInfo,
    selectedTopic,
    setSelectedTopic,
}: {
    topicsInfo: TopicsInfo;
    selectedTopic: number;
    setSelectedTopic: (topicId: number) => void;
}) {
    const { t } = useBundleTranslation('components/glossary-term/glossary');
    const glossaryTerms = useMemo(() => Object.keys(topicsInfo), [topicsInfo]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchPattern, setSearchPattern] = useState<string>('');
    const [searchResultEmpty, setSearchResultEmpty] = useState<boolean>(false);
    const [view, setView] = useState<string>('tree-view');
    const [collapseState, setCollapseState] = useState<string>('list-expand');
    const [collapseSectionsList, setCollapseSectionsList] = useState<any>({});

    const useDebounce = (callback: () => void) => {
        const ref = useRef<any>();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 300);
        }, []);

        return debouncedCallback;
    };

    const onChangeSearch = () => {
        setSearchPattern(searchValue.toLowerCase());
        setSearchResultEmpty(true);
    };

    const debouncedOnChange = useDebounce(onChangeSearch);

    useEffect(() => {
        debouncedOnChange();
    }, [searchValue]);

    const changeCollapseState = (value: string) => {
        setCollapseState(value);
        if (value == 'list-expand') {
            setCollapseSectionsList({});
        } else if (value == 'list-collapse') {
            const newCollapseSectionsList: any = {};
            glossaryTerms.forEach((section) => {
                newCollapseSectionsList[section] = true;
            });
            setCollapseSectionsList(newCollapseSectionsList);
        }
    };

    const toggleCollapse = (sectionKey: string) => {
        const newCollapseSectionsList = { ...collapseSectionsList };
        newCollapseSectionsList[sectionKey] = !newCollapseSectionsList?.[sectionKey];
        setCollapseSectionsList(newCollapseSectionsList);

        if (Object.keys(newCollapseSectionsList).length == glossaryTerms.length) {
            let newCollapsedState = 'partial';
            const isExistCollapsedItem = Object.keys(newCollapseSectionsList).some(
                (key) => newCollapseSectionsList?.[key],
            );
            const isExistExpandedItem = Object.keys(newCollapseSectionsList).some(
                (key) => !newCollapseSectionsList?.[key],
            );

            if (isExistCollapsedItem && !isExistExpandedItem) newCollapsedState = 'list-collapse';
            if (!isExistCollapsedItem && isExistExpandedItem) newCollapsedState = 'list-expand';
            setCollapseState(newCollapsedState);
        } else {
            const isExistCollapsedItem = Object.keys(newCollapseSectionsList).some(
                (key) => newCollapseSectionsList[key],
            );
            setCollapseState(isExistCollapsedItem ? 'partial' : 'list-expand');
        }
    };

    return (
        <Stack sx={styles.leftPanelWrapper}>
            <Stack direction={'row'} spacing={1.5} sx={styles.leftPanelControlsWrapper}>
                <Box flexGrow={1}>
                    <TextField
                        value={searchValue}
                        onChange={(event) => {
                            setSearchValue(event.target.value);
                        }}
                        fullWidth
                        placeholder={'Search...'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                                    <IconMi icon="search" fontSize={'16'} />
                                </InputAdornment>
                            ),
                            endAdornment: searchValue ? (
                                <InputAdornment
                                    onMouseDown={() => {
                                        setSearchValue('');
                                    }}
                                    position="end"
                                    sx={{ color: 'primary.main', cursor: 'pointer', '&:hover': { opacity: 0.75 } }}
                                >
                                    <IconMi icon="times" fontSize="16" />
                                </InputAdornment>
                            ) : undefined,
                        }}
                    />
                </Box>
                <ToggleButtonGroup
                    color="primary"
                    value={view}
                    exclusive
                    onChange={(event, value) => {
                        if (value != null) setView(value);
                    }}
                    sx={{ flexShrink: 0 }}
                >
                    <ToggleButton value={'tree-view'} sx={{ padding: '5px' }}>
                        <IconMi icon={'tree-view'} fontSize={'16'} />
                    </ToggleButton>
                    <ToggleButton value={'list-view'} sx={{ padding: '5px' }}>
                        <IconMi icon={'tag-list-view'} fontSize={'16'} />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    color="primary"
                    value={collapseState}
                    exclusive
                    onChange={(event, value) => {
                        if (value != null) {
                            changeCollapseState(value);
                        }
                    }}
                    sx={{ flexShrink: 0 }}
                >
                    <ToggleButton value={'list-expand'} sx={{ padding: '5px' }}>
                        <IconMi icon={'list-expand'} fontSize={'16'} />
                    </ToggleButton>
                    <ToggleButton value={'list-collapse'} sx={{ padding: '5px' }}>
                        <IconMi icon={'list-collapse'} fontSize={'16'} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Stack key={'glossary-terms-header'} sx={styles.leftPanelItemsWrapper} className={'view-mode--' + view}>
                {glossaryTerms.map((section, index) => {
                    const sectionInfo = topicsInfo[section];
                    const isSelectedSection = sectionInfo.topics.find((term) => selectedTopic === term.topic_id);
                    const itemsWithSearchPattern =
                        !searchPattern ||
                        sectionInfo.topics.find((term) => term.name.toLowerCase().includes(searchPattern));

                    if (!itemsWithSearchPattern) return null;
                    if (searchResultEmpty) setSearchResultEmpty(false);
                    const isCollapsed = collapseState == 'list-collapse' || collapseSectionsList[section];

                    return (
                        <Box key={section} className={'section'}>
                            <Stack
                                direction={'row'}
                                className={'section__header' + (isSelectedSection ? ' selected' : '')}
                                onClick={() => {
                                    toggleCollapse(section);
                                }}
                            >
                                {sectionInfo.use_icon !== 'none' && (
                                    <Stack direction={'row'} className={'section__icon-wrapper'}>
                                        {sectionInfo.use_icon === 'fa' && (
                                            <IconHandler
                                                sx={{ fontSize: 14 }}
                                                icon={{ type: 'fa', value: sectionInfo.fa_icon }}
                                            />
                                        )}
                                        {sectionInfo.use_icon === 'uploaded' && sectionInfo.icon && (
                                            <img
                                                src={`data:${sectionInfo.icon_type};base64, ${sectionInfo.icon}`}
                                                alt={`tag-icon-${section}`}
                                                width={14}
                                                height={14}
                                            />
                                        )}
                                    </Stack>
                                )}
                                <Box className={'section__label'}>{sectionInfo.topic_type_name}</Box>
                                <Stack direction={'row'} className={'section__toggle'}>
                                    <IconMi sx={{ fontSize: 16 }} icon={isCollapsed ? 'chevron-up' : 'chevron-down'} />
                                </Stack>
                            </Stack>
                            <Stack className={'section__items' + (isCollapsed ? ' collapsed' : '')}>
                                {sectionInfo.topics.map((term) => {
                                    const isPassSearchPattern =
                                        !searchPattern || term.name.toLowerCase().includes(searchPattern);
                                    if (!isPassSearchPattern) return null;

                                    return (
                                        <Box className={'item-wrapper'} key={term.topic_id}>
                                            <Box
                                                className={
                                                    'item-holder' + (selectedTopic === term.topic_id ? ' selected' : '')
                                                }
                                            >
                                                <Box
                                                    key={term.topic_id}
                                                    className={'item-element'}
                                                    onClick={() => {
                                                        setSelectedTopic(term.topic_id);
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: !searchPattern
                                                            ? term.name
                                                            : term.name.replaceAll(searchPattern, (match) => {
                                                                  return '<span class="highlight">' + match + '</span>';
                                                              }),
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Box>
                    );
                })}
                {searchResultEmpty && <Box sx={{ p: 2, textAlign: 'center' }}>{t('no_data_found_placeholder')}</Box>}
            </Stack>
        </Stack>
    );
}
