import React, { useContext, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Stack } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { getFieldOptionsForSelect, PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';

export interface QueryBuilderSortPopupProps {
    onClose: (event: any) => void;
    onApply: (data: any) => void;
    editElement?: any;
}

export default function QueryBuilderSortPopup(props: QueryBuilderSortPopupProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { onClose = () => {}, onApply = () => {}, editElement } = props;
    const { reportData, queryData, helperAct, pluginConfig } = useContext(PluginQBContext);
    const isAdd = !editElement;
    const [directionVal, setDirectionVal] = useState<string>(isAdd ? '' : editElement.direction);
    const [columnVal, setColumnVal] = useState<string>(isAdd ? '' : editElement.column);

    const directionOptions = ['asc', 'desc'].map((item) => {
        return {
            value: item,
            label: item,
        };
    });

    const columnOptions: any = getFieldOptionsForSelect(reportData.fields, helperAct, pluginConfig).map(
        (optionItem: any) => {
            if (!optionItem.options) {
                const availablePickSelf = !isAdd && editElement.column == optionItem.value;
                return {
                    ...optionItem,
                    disabled:
                        queryData.sort.findIndex((sort: any) => sort.column == optionItem.value) !== -1 &&
                        !availablePickSelf,
                };
            } else {
                return {
                    ...optionItem,
                    options: optionItem.options.map((optionItem: any) => {
                        const availablePickSelf = !isAdd && editElement.column == optionItem.value;
                        return {
                            ...optionItem,
                            disabled:
                                queryData.sort.findIndex((sort: any) => sort.column == optionItem.value) !== -1 &&
                                !availablePickSelf,
                        };
                    }),
                };
            }
        },
    );

    return (
        <Popup
            settings={{
                title: isAdd ? t('sort_popup.add.title') : t('sort_popup.edit.title'),
                textOK: isAdd ? t('sort_popup.add.ob_btn') : t('sort_popup.edit.ob_btn'),
                maxWidth: 'popupLg',
            }}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                //I think it's not actual
                //alert('The same sort has already been added. Please, select another field.');
                if (!columnVal) {
                    alert(t('sort_popup.alert_select_column'));
                } else if (!directionVal) {
                    alert(t('sort_popup.alert_select_direction'));
                } else {
                    onApply({ column: columnVal, direction: directionVal });
                }
            }}
        >
            <Stack direction={'row'} spacing={2}>
                <Box sx={{ width: '250px' }}>
                    <Box>{t('sort_popup.sort_on_label')}</Box>

                    <ReactSelect
                        data={columnOptions}
                        value={columnVal}
                        update={(val) => {
                            setColumnVal(val);
                        }}
                    />
                </Box>
                <Box sx={{ width: '250px' }}>
                    <Box>{t('sort_popup.direction_label')}</Box>
                    <ReactSelect
                        data={directionOptions}
                        value={directionVal}
                        update={(val) => {
                            setDirectionVal(val);
                        }}
                    />
                </Box>
            </Stack>
        </Popup>
    );
}
