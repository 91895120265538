import { RawFormElementProps } from 'components/common/form/index';
import { FormTabType } from 'components/common/form/tab';
import { AssocArray } from 'tools/types';
import React, { useContext, useEffect, useState } from 'react';
import { FormComponentValue } from 'components/common/form/layout/control';
import { JSONTree } from 'react-json-tree';
import {
    getElementFieldValue,
    prepareFormComponentType,
    prepareFormElementProps,
} from 'components/common/form/formTools';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import checkRule from 'components/common/form/dependency/checkRule';
import { processEffect } from 'components/common/form/dependency/processEffect';

export function FormDebugWindow({
    elementProps,
    formTabs,
}: {
    elementProps: RawFormElementProps;
    formTabs: Array<FormTabType>;
}) {
    // Force to update
    const [refresh, setRefresh] = useState<number>(0);
    useEffect(() => {
        const subscription = elementProps.form?.hookFormWatch((value, { name, type }) => {
            setRefresh((prevState) => prevState + 1);
        });
        return () => subscription?.unsubscribe();
    }, [elementProps.form?.hookFormWatch]);

    const formsContext = useContext<FormsContextType>(FormsContext);

    if (!elementProps.form?.formDidMount) {
        return <div></div>;
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: '20px',
                right: 0,
                width: 500,
                marginTop: 90,
                overflowY: 'scroll',
                backgroundColor: '#fff',
                zIndex: 100,
            }}
        >
            {formTabs.map((tab) => {
                return tab.components
                    .filter((component) => prepareFormComponentType(component).deps?.length)
                    .map((component, componentIndex) => {
                        const fullProps = prepareFormElementProps({
                            ...elementProps,
                            component: component,
                        });

                        let rulesList: Array<any> = [];
                        if (fullProps.component?.deps?.length) {
                            rulesList = fullProps.component.deps.map((dep, depIndex) => {
                                let applyRule = true;

                                const rulesList = dep.rules.map((rule, ruleIndex) => {
                                    const fieldValue = getElementFieldValue(fullProps.form, rule.field, formsContext);
                                    const isRulePerformed =
                                        fieldValue == null
                                            ? false
                                            : checkRule(rule, fieldValue, fullProps.form, formsContext);
                                    applyRule = applyRule && isRulePerformed;
                                    return (
                                        <div
                                            key={ruleIndex}
                                            style={{
                                                color: isRulePerformed ? 'rgb(134, 183, 151)' : '#D34839',
                                            }}
                                        >
                                            ["{rule.field}" {rule.cond} "{rule.data}"] ({fieldValue})
                                        </div>
                                    );
                                });

                                let effectToApply: AssocArray<any> = {};
                                if (applyRule) {
                                    if (dep.scope == 'control') {
                                        effectToApply = processEffect(fullProps, dep, formsContext);
                                    } else {
                                        for (const [value, mod] of Object.entries<FormComponentValue>(dep.effect)) {
                                            effectToApply[value] = mod;
                                        }
                                    }
                                }
                                let style = {
                                    border: '1px solid',
                                    marginTop: 3,
                                    borderColor: applyRule ? 'rgb(134, 183, 151)' : '#D34839',
                                };

                                const jsonData: any = {};
                                jsonData[dep.scope] = effectToApply;
                                return (
                                    <div style={style} key={depIndex}>
                                        {rulesList}
                                        {applyRule && (
                                            <div>
                                                <JSONTree
                                                    theme={{
                                                        base00: '#333',
                                                    }}
                                                    hideRoot
                                                    data={jsonData}
                                                    shouldExpandNodeInitially={() => true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            });
                        }

                        return (
                            <div style={{ border: '1px solid', marginTop: 4, padding: 4 }} key={componentIndex}>
                                <span style={{ fontWeight: 'bold' }}>{fullProps.component.name}</span>
                                <div style={{ fontWeight: 'bold' }}>{rulesList}</div>
                            </div>
                        );
                    });
            })}
        </div>
    );
}
