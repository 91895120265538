import { Box, Typography } from '@mui/material';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import styles from '../styles';
import useBundleTranslation from 'i18n';

export default function LastUpdated({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const { t } = useBundleTranslation(['app/home', 'main']);

    if (datasetInfo.last_updated && datasetInfo.last_updated == '0000-00-00 00:00:00') {
        return null;
    }

    return datasetInfo.last_updated ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography>{`${t('info_panel.last_updated')}: ${datasetInfo.last_updated}`}</Typography>
        </Box>
    ) : null;
}
