import { alpha, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { getCellContent } from './index';
import { gridAPI } from 'api/grid';
import LightTooltip from 'components/common/tooltip/LightTooltip';

export default function AsyncTooltipCell({ text, icon, href, rawHtml, align, subText }: CustomCellProps) {
    const [tooltip, setTooltip] = useState('');
    const [isRawHtml, setRawHtml] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const onMouseOver = () => {
        setShowLoader(true);
    };

    const onMouseOut = () => {
        setShowLoader(false);
    };

    useEffect(() => {
        if (showLoader && tooltip === '') {
            loadTooltip();
        }
    }, [showLoader]);

    const loadTooltip = () => {
        if (href && href > '') {
            setLoading(true);

            gridAPI
                .loadAsyncTooltip(href)
                .then((response) => {
                    setRawHtml(response.rawHtml);
                    setTooltip(response.tooltip);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getTooltipContent = () => {
        if (isRawHtml) {
            return <span dangerouslySetInnerHTML={{ __html: tooltip }} />;
        }

        return tooltip;
    };

    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {getCellContent(text, icon, rawHtml, align, subText)}
            {showLoader && (
                <LightTooltip title={getTooltipContent()} arrow placement="bottom-start" open={showLoader && !loading}>
                    <Skeleton
                        variant="circular"
                        width={15}
                        height={15}
                        animation={loading ? 'pulse' : false}
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.85),
                            position: 'absolute',
                            right: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </LightTooltip>
            )}
        </Stack>
    );
}
