import { FormGroup, Grid, Typography } from '@mui/material';
import { WrapFormElementProps } from './index';
import useBundleTranslation from 'i18n';

export default function FormWrapperGroup({ elementProps, children }: WrapFormElementProps) {
    const { t } = useBundleTranslation([elementProps.translationNS ?? 'main']);
    const label = elementProps.componentProps.translate
        ? t(elementProps.componentProps.label as string)
        : elementProps.componentProps.label;

    return (
        <Grid item xs={elementProps.componentProps.xs} className={'form-wrapper-group'}>
            <FormGroup>
                {elementProps.componentProps.label != '' && <Typography variant={'body1'}>{label}</Typography>}
                {children}
            </FormGroup>
        </Grid>
    );
}
