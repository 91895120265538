import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React, { useContext } from 'react';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import IconMi from 'components/common/icon/IconMi';
import { ReportContentNS } from 'components/report-content';
import { prepareFormElementProps } from 'components/common/form/formTools';
import RendererFilter = ReportContentNS.RendererFilter;
import { getFieldOptionForFormName } from 'components/report-content/components/manage-filters/edit-panel/index';
import { alpha, Box, IconButton, Stack } from '@mui/material';

export interface FieldSortingContextType {
    elementProps: FormDataAPIType;
    selectField: (field: RendererFilter) => void;
    onSortEnd: (oldIndex: number, newIndex: number) => void;
}
export const FieldSortingContext = React.createContext<FieldSortingContextType | null>(null);

const DragHandle = SortableHandle(() => <IconMi icon={'drag-and-drop-grid'} fontSize="16" sx={{ mr: 2 }} />);

const SortableItem = SortableElement(({ value: field }: { value: RendererFilter }) => {
    const checkBoxControl = {
        component: 'FormCheckbox',
    } as RawFormComponentType;
    const context = useContext<FieldSortingContextType | null>(FieldSortingContext);
    if (context == null) {
        return null;
    }

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
                zIndex: 2205, //above backdrop
                borderBottom: '1px solid',
                borderBottomColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
            key={field.reference_name_escaped}
        >
            <DragHandle />
            <Box flexGrow={1}>
                <ReactHookFormController
                    elementProps={prepareFormElementProps({
                        ...context.elementProps,
                        component: {
                            ...checkBoxControl,
                            label: field.column_name,
                            name: getFieldOptionForFormName(field, 'filter_column'),
                        },
                    })}
                />
            </Box>
            <IconButton onClick={() => context.selectField(field)}>
                <IconMi icon="gear" fontSize="16" />
            </IconButton>
        </Stack>
    );
});

const SortableList = SortableContainer(({ items, header }: { items: Array<RendererFilter>; header?: string }) => {
    return (
        <Stack>
            {header && (
                <Box
                    sx={{
                        color: (theme) => alpha(theme.palette.text.primary, 0.8),
                        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        px: 1,
                        py: '7px',
                    }}
                >
                    {header}
                </Box>
            )}
            {items.map((field, index) => (
                // @ts-ignore
                <SortableItem key={`item-${field.reference_name_escaped}`} index={index} value={field} />
            ))}
        </Stack>
    );
});

export default function FieldsList({ fields, header }: { fields: Array<RendererFilter>; header?: string }) {
    const context = useContext<FieldSortingContextType | null>(FieldSortingContext);
    if (context == null) {
        return null;
    }
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        context.onSortEnd(oldIndex, newIndex);
    };
    return (
        // @ts-ignore
        <SortableList axis={'xy'} lockAxis={'y'} useDragHandle items={fields} onSortEnd={onSortEnd} header={header} />
    );
}
