import { lazy } from 'react';
import { RouteItemType } from 'routes/index';
const Page = lazy(() => import('components/common/Page'));
const FormPage = lazy(() => import('components/common/FormPage'));

export const notificationRoutes: Array<RouteItemType> = [
    {
        path: ['/notification/burst'],
        name: 'burst.name',
        component: Page,
        props: { settingsUrl: '/data/notification/burst/index' },
    },
    {
        path: ['/notification/burst/:burstId'],
        name: 'burst.editor.name',
        component: FormPage,
        props: {
            uid: 'burstForm',
            pk: 'burstId',
            settingsUrl: '/data/notification/burst/:burstId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'burst.name',
                        href: '/notification/burst',
                    },
                ],
            },
        },
    },
    {
        path: ['/notification/favorite'],
        name: 'favorite.name',
        component: Page,
        props: { settingsUrl: '/data/notification/favorite/index' },
    },
    {
        path: ['/notification/favorite/:favoriteId'],
        name: 'favorite.editor.name',
        component: FormPage,
        props: {
            uid: 'favoriteForm',
            pk: 'favoriteId',
            settingsUrl: '/data/notification/favorite/:favoriteId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'favorite.name',
                        href: '/notification/favorite',
                    },
                ],
            },
        },
    },
    {
        path: ['/notification/folder'],
        name: 'folder.name',
        component: Page,
        props: { settingsUrl: '/data/notification/folder/index' },
    },
    {
        path: ['/notification/folder/:folderId'],
        name: 'folder.editor.name',
        component: FormPage,
        props: {
            uid: 'folderForm',
            pk: 'folderId',
            settingsUrl: '/data/notification/folder/:folderId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'folder.name',
                        href: '/notification/folder',
                    },
                ],
            },
        },
    },
    {
        path: ['/notification/alert-subscription'],
        name: 'alert_subscription.name',
        component: Page,
        props: { settingsUrl: '/data/notification/alert-subscription/index', pageMenu: 'notification' },
    },
    {
        path: ['/notification/receiving'],
        name: 'notifications.name',
        component: Page,
        props: { settingsUrl: '/data/notification/receiving/index', pageMenu: 'notification' },
    },
    {
        path: ['/notification/receiving/:subscriptionId'],
        name: 'notifications.editor.name',
        component: FormPage,
        props: {
            uid: 'notificationForm',
            pk: 'subscriptionId',
            settingsUrl: '/data/notification/:subscriptionId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'notifications.name',
                        href: '/notification/receiving',
                    },
                ],
            },
        },
    },
    {
        path: ['/notification/favorite-notification'],
        name: 'favorites.name',
        component: Page,
        props: { settingsUrl: '/data/notification/favorite-notification/index', pageMenu: 'notification' },
    },
];
