import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import React, { useEffect } from 'react';
import useBundleTranslation from 'i18n';
import uniq from 'lodash/uniq';
import { GlobalVariable } from 'app/editor/page-template';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { FormTabContent } from 'components/common/form/layout/tab/FormTabContent';
import { Stack } from '@mui/material';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';

export default function PortalPageTemplateForm(props: IFormLayoutProps) {
    const { t } = useBundleTranslation(['app/editor/page/template']);

    const codeTab = <CodeTab {...customTabProps(props, 'code')} />;

    const htmlCode = props.hookForm.form.hookFormWatch('html_code');
    const tags = props.hookForm.form.hookFormWatch('tags');
    const gtags = props.hookForm.form.hookFormWatch('gtags');

    useEffect(() => {
        const htmlTags = htmlCode.match(/\[[A-Za-z0-9_\s\-]+\]/g);
        const missedTags: string[] = [];
        const notFoundTags: string[] = [];

        if (htmlTags && htmlTags.length) {
            for (let i = 0; i < htmlTags.length; i++) {
                let found = false;
                for (let j = 0; j < tags.length; j++) {
                    if (htmlTags[i] == '[' + tags[j].name + ']') {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    missedTags.push(htmlTags[i]);
                }
            }
        }

        for (let j = 0; j < tags.length; j++) {
            let found = false;
            if (htmlTags && htmlTags.length) {
                for (let i = 0; i < htmlTags.length; i++) {
                    if (htmlTags[i] == '[' + tags[j].name + ']') {
                        found = true;
                        break;
                    }
                }
            }

            if (!found && tags[j].tag_type != 'global_tag') notFoundTags.push('[' + tags[j].name + ']');
        }

        let resultMessage = '';
        if (missedTags.length || notFoundTags.length) {
            const filteredMissedTags = uniq(missedTags).filter((missed) => {
                return (
                    gtags.find((tag: GlobalVariable) => {
                        return '[' + tag.name + ']' == missed;
                    }) == null
                );
            });

            const notFoundFiltered = uniq(notFoundTags);

            const missedTagsMessage =
                filteredMissedTags.length > 0 ? `${t('missed_tags')} ${filteredMissedTags.join(', ')} <br />` : '';
            const missedVariablesMessage =
                notFoundFiltered.length > 0 ? `${t('missed_variables')} ${notFoundFiltered.join(', ')}` : '';

            resultMessage = missedTagsMessage + missedVariablesMessage;
        }

        props.hookForm.form.hookFormSetValue('page_template_tags_warning', resultMessage);
    }, [htmlCode, tags]);

    const customProps = {
        ...props,
        customTabs: { code: codeTab },
    };

    return <FormLayoutComposer props={customProps} />;
}

function CodeTab(tabProps: ITabProps) {
    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);

    return (
        <FormTabContent {...tabProps}>
            <Stack
                alignItems="center"
                className={'default-components scroll-content-container scroll-content-container--form'}
                sx={{
                    overflow: 'none',
                    flexGrow: 1,
                    width: '100%',
                    pb: 3,
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                }}
            >
                <FormElementComponent {...getElementProps('tags_warning')} />
                <FormElementComponent {...getElementProps('html_code')} />
            </Stack>
        </FormTabContent>
    );
}
