import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { ChartTooltipProps } from 'components/metric/chart/tooltip/RegularSeriesTooltip';
import { ChartPointAlert } from 'components/metric/chart/series/getAlertSeries';
import useAddComment from 'components/metric/chart/tooltip/useAddComment';
import AddComment from 'components/metric/chart/tooltip/AddComment';
import { Box, Divider } from '@mui/material';

export interface ChartAlertTooltipProps extends ChartTooltipProps {
    point: ChartPointAlert;
}

export default function AlertTooltip({ point, t, isSelected, onClose }: ChartAlertTooltipProps) {
    const { handleAddCommentClick } = useAddComment('alert_event', String(point?.metadata?.key), onClose);

    return (
        <Box
            sx={{
                py: 1,
                px: 1.5,
            }}
        >
            <Box
                sx={{ fontWeight: 600, pr: isSelected ? 1.5 : undefined }}
                dangerouslySetInnerHTML={{ __html: point.text }}
            />
            {isSelected && (
                <Box
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        p: 0.625,
                        right: 0,
                        top: 0,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <IconMi icon={'times'} />
                </Box>
            )}

            <Box sx={{ pt: 0.5 }} dangerouslySetInnerHTML={{ __html: point.text }} />
            {isSelected && (
                <Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ ml: -0.5 }}>
                        <AddComment onClick={handleAddCommentClick} />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
