import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { burstAPI } from 'api/api';
import { ActSubmit } from 'components/common/form/act';

export default function BurstForm(props: IFormLayoutProps) {
    const nsDistributionId = props.hookForm.form.hookFormWatch('notification_schedule_distribution_id');
    const contentType = props.hookForm.form.hookFormWatch('content_type');
    const unsubscribedUsersGrid = props.hookForm.form.hookFormWatch('burstRecipientUnsubscribedUsersGrid');

    const [prevUnsubscribedUsersGrid, setPrevUnsubscribedUsersGrid] = useState([]);

    const syncEnableState = () => {
        burstAPI
            .getEnabledState(nsDistributionId)
            .then((response) => {
                if ('OK' == response.data?.status) {
                    props.hookForm.form.hookFormSetValue('enabled_ind', response.data.data);
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        syncEnableState();
    }, [contentType]);

    const setCurrentNS = () => {
        props.hookForm.form.hookFormSetValue(
            'burst_status_ns',
            props.hookForm.form.hookFormGetValues('notification_schedule_id'),
        );
    };

    useEffect(() => {
        if (!props.hookForm.form.formDidMount) {
            return;
        }
        setCurrentNS();
    }, [props.hookForm.form.formDidMount]);

    useEffect(() => {
        if (unsubscribedUsersGrid?.length === 0 && prevUnsubscribedUsersGrid?.length > 0) {
            props.hookForm.form.hookFormSetValue('send_owner_distribution_ind', 'Y');
        }

        if (unsubscribedUsersGrid) {
            setPrevUnsubscribedUsersGrid(unsubscribedUsersGrid);
        }
    }, [unsubscribedUsersGrid]);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                return super.exec(data).then((response) => {
                    syncEnableState();
                    setCurrentNS();
                    return response;
                });
            }
        },
    );

    return <FormLayoutComposer props={props} />;
}
