import React, { forwardRef, PropsWithChildren, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button, Chip, Link, Stack, TextField, Theme, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import ShareWithPopup from 'components/wall/parts/ShareWithPopup';
import { useForm } from 'react-hook-form';
import { FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';

export interface wallPostboxParams {
    uid: string;
    wallAddNote?: boolean;
    isExpertCommentary?: boolean;
    type: string;
    reqParams: any;
    containerId: string;
    onClickPost: (text: string, share: string[], formData: any) => void;
    onChangeVal: (text: string) => void;
    onClickCancel?: () => void;
    onFocusAction?: () => void;
    rowCount?: number;
    element_id: number;
    segment_value_id: number;
    collaborativeAttachments: boolean;
    notificationsDisabled: boolean;
    initialText?: string;
    filesData?: string;
}

interface wallPostboxTemplates {
    template?: any;
    areaTemplate?: any;
    commentTemplate?: any;
    noteTemplate?: any;
    fileNameTemplate?: any;
    selectedUsersTemplate?: any;
}

export interface WallPostboxProps {
    params: wallPostboxParams;
    templates?: wallPostboxTemplates;
}

export interface wallPostboxData {
    uid: string;
    wallAddNote: boolean;
    isExpertCommentary: boolean;
    type: string;
    reqParams: any;
    container: string;
}

const WallPostbox: React.FC<PropsWithChildren<WallPostboxProps>> = (props: WallPostboxProps, ref) => {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { templates, params } = props;

    const rowCount = params.rowCount ?? 5;

    useEffect(() => {
        setTextVal(params.initialText ?? '');
    }, [params.initialText]);

    const [textVal, setTextVal] = useState<string>('');

    const [wallPostboxData, setWallPostboxData] = useState<wallPostboxData>({
        type: params.type,
        uid: params.uid,
        reqParams: params.reqParams,
        container: params.containerId, //$('#'+params.containerId);
        wallAddNote: !!params.wallAddNote,
        isExpertCommentary: params.isExpertCommentary == true,
    });

    const [lastFileId, setLastFileId] = useState<number>(0);
    const [attachedFilesCounter, setAttachedFilesCounter] = useState<number>(1);
    const [attachedFilesData, setAttachedFilesData] = useState<object>({});

    const [shareWithData, setShareWithData] = useState<any[]>([]);
    const [showShareWithPopup, setShowShareWithPopup] = useState<boolean>(false);

    useEffect(() => {
        let lastId = 0;
        let filesObj: any = {};
        if (params.filesData) {
            const files = JSON.parse(params.filesData);
            files.forEach((item: any) => {
                if (item.id > lastId) lastId = item.id;
                filesObj[item.id] = {
                    id: item.id,
                    name: item.name,
                    removed: false,
                    previousLoad: true,
                };
            });
        }
        setLastFileId(Number(lastId));
        setAttachedFilesData(filesObj);
    }, [params.filesData]);

    const updateWallPostboxData = (newData: any) => {
        setWallPostboxData((previousWallData) => ({ ...previousWallData, ...newData }));
        //setWallData({ ...wallData, ...newData });
    };

    const isTablet = false; //ToDo Assign some data

    const changeTextVal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextVal(event.target.value);
        params.onChangeVal(event.target.value);
    };

    const removeShareItem = (index: number) => {
        const newData = [...[], ...shareWithData];
        newData.splice(index, 1);
        setShareWithData(newData);
    };

    //Configuration upload Field
    const { setValue: hookFormSetValue, control, getValues: hookFormGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFormGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    const removeAttachedFile = (index: number) => {
        const newData: any = { ...{}, ...attachedFilesData };
        newData[index].removed = true;
        setAttachedFilesData(newData);
    };

    return (
        <Box
            id={`wall_postbox_note_${wallPostboxData.uid}`}
            className={`wall_postbox_note ${wallPostboxData.wallAddNote ? 'wall_post_box_static_form' : ''}`}
            sx={
                {
                    /*display: !wallPostboxData.wallAddNote ? 'none' : undefined*/
                }
            }
        >
            <Box className="wall_postbox_table_OLD">
                <Box className="wall_postbox_table_posttextarea_OLD markdown_available_block" sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        placeholder={wallPostboxData.wallAddNote ? t('postbox.add_comment_placeholder') : undefined}
                        id={`wall_postbox_note_${wallPostboxData.uid}_textarea`}
                        className={`wall_postbox_note_textarea_OLD ${wallPostboxData.wallAddNote ? 'unactive' : ''}`}
                        name="text"
                        multiline
                        minRows={rowCount}
                        maxRows={rowCount}
                        onFocus={params.onFocusAction}
                        autoFocus={!wallPostboxData.wallAddNote}
                        value={textVal}
                        onChange={changeTextVal}
                        inputRef={ref}
                        data-test={'wall_postbox_textarea'}
                    />
                </Box>
                <Stack
                    className={`wall_postbox_form_elements ${wallPostboxData.wallAddNote ? 'displayNone' : undefined}`}
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    {false && <Box className="wall_postbox_post_td_OLD pull-left mr-10" />}

                    <Button
                        id={`wall_post_note_${wallPostboxData.uid}`}
                        variant={
                            !wallPostboxData.wallAddNote && !wallPostboxData.isExpertCommentary
                                ? 'outlined'
                                : 'contained'
                        }
                        className="wall_add_note_btn wall_post_note"
                        onClick={() => {
                            if (textVal.trim()) {
                                const formData = new FormData();
                                Object.values(attachedFilesData).forEach((item: any) => {
                                    const key = `shared_file[${item.id}]`;
                                    if (!item.previousLoad) {
                                        formData.append(key, hookFormGetValues(key)[0]);
                                    }
                                    if (item.removed) formData.append('shared_file_removed[]', item.id);
                                });

                                params.onClickPost(textVal.trim(), shareWithData, formData);
                                setShareWithData([]);
                                setAttachedFilesData({});
                                setLastFileId(0);
                                setAttachedFilesCounter(1);
                                setTextVal('');
                            } else {
                                const type = wallPostboxData.isExpertCommentary
                                    ? 'expert_analysis'
                                    : wallPostboxData.type;
                                alert(t('postbox.empty_post_field_msg', { type: t(`postbox.type.${type}`) }));
                            }
                        }}
                        startIcon={<IconMi icon="success" />}
                        data-test={
                            wallPostboxData.isExpertCommentary ? 'add_expert_analysis_button' : 'wall_post_button'
                        }
                    >
                        {wallPostboxData.isExpertCommentary
                            ? t('postbox.add_expert_analysis_btn')
                            : wallPostboxData.wallAddNote
                              ? t('postbox.add_note_btn')
                              : t('postbox.add_comment_btn')}
                    </Button>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className="wall_postbox_share_btn_td_OLD wall_postbox_attached_file_td_OLD"
                    >
                        <Box
                            className="ver_page_sep"
                            sx={{
                                height: '16px',
                                width: '1px',
                                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                marginLeft: 0.5,
                                marginRight: 0.5,
                            }}
                        ></Box>

                        {!isTablet && params.collaborativeAttachments && (
                            <>
                                {[...Array(attachedFilesCounter)].map((fileField, index) => {
                                    const fieldNumber = lastFileId + index + 1;
                                    return (
                                        <Tooltip title={t('postbox.attach_file')} key={index}>
                                            <Box
                                                className="wall_postbox_attach_file_OLD"
                                                component="span"
                                                key={fieldNumber}
                                                sx={{
                                                    display: index + 1 < attachedFilesCounter ? 'none' : undefined,
                                                }}
                                            >
                                                <ReactHookFormController
                                                    key={index}
                                                    elementProps={prepareFormElementProps({
                                                        form: formActions,
                                                        component: {
                                                            component: 'UploadFile',
                                                            name: `shared_file[${fieldNumber}]`,
                                                            label: '',
                                                            props: {
                                                                //id: `wall_postbox_note_${wallPostboxData.uid}_attach_button`,
                                                                view: 'button',
                                                                hidePreview: true,
                                                                buttonText: '',
                                                                buttonStartIcon: { type: 'mi', value: 'paperclip' },
                                                                showRemoveAction: false,
                                                                onFilesChange: (files: FileList) => {
                                                                    setAttachedFilesData({
                                                                        ...attachedFilesData,
                                                                        [fieldNumber]: {
                                                                            id: fieldNumber,
                                                                            name: files[0].name,
                                                                            removed: false,
                                                                        },
                                                                    });
                                                                    setAttachedFilesCounter(attachedFilesCounter + 1);
                                                                },
                                                            },
                                                        } as RawFormComponentType,
                                                    })}
                                                />
                                            </Box>
                                        </Tooltip>
                                    );
                                })}
                            </>
                        )}
                        <Tooltip title={t('postbox.share_this_comment')}>
                            <Button
                                data-test={'shared-file-with'}
                                id={`wall_postbox_note_${wallPostboxData.uid}_share_button`}
                                className="wall_postbox_share_btn wall_postbox_note_share_btn min-width--icon"
                                sx={{ display: wallPostboxData.isExpertCommentary ? 'none' : undefined }}
                                variant="light"
                                onClick={() => {
                                    setShowShareWithPopup(true);
                                }}
                            >
                                <IconMi fontSize={'16'} icon="share" />
                            </Button>
                        </Tooltip>

                        {wallPostboxData.isExpertCommentary && (
                            <>
                                <Box component="span" sx={{ ml: 1 }}>
                                    {t('postbox.add_expert_analysis_or_label')}
                                </Box>
                                <Box
                                    component="span"
                                    className="cancel_link wall_add_note_cancel_btn"
                                    id={`wall_post_note_${wallPostboxData.uid}_cancel`}
                                    onClick={params.onClickCancel}
                                    sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                >
                                    {t('postbox.add_expert_analysis_cancel_link')}
                                </Box>
                            </>
                        )}
                    </Stack>

                    <Box sx={{ ml: 'auto !important' }}>
                        <Tooltip title={t('postbox.markdown_link_tooltip')} disableInteractive>
                            <Link
                                data-test={'text-formatting'}
                                href="http://help.metricinsights.com/m/Adding_Context_to_Metrics/l/274731-how-to-add-links-and-formatting-to-notes-comments-and-annotations"
                                className="markdown-label"
                                target="_blank"
                            >
                                {t('postbox.markdown_link')}
                                <i className="icon mi-md ml-5" />
                            </Link>
                        </Tooltip>
                    </Box>
                </Stack>
                <Box className="pull-left">
                    {!isTablet && (
                        <>
                            {Object.values(attachedFilesData).filter((item) => !item?.removed).length > 0 && (
                                <Box className="selected_elements_list wall_postbox_attached_file_ul">
                                    {Object.values(attachedFilesData)
                                        .filter((item) => !item?.removed)
                                        .map((attachedFile, index) => {
                                            return (
                                                <Chip
                                                    key={attachedFile.id}
                                                    label={attachedFile.name}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        removeAttachedFile(attachedFile.id);
                                                    }}
                                                    onDelete={() => {
                                                        removeAttachedFile(attachedFile.id);
                                                    }}
                                                    deleteIcon={
                                                        <IconMi icon={'times'} sx={{ fontSize: '12px !important' }} />
                                                    }
                                                    color="primary"
                                                    sx={{ m: 0.5 }}
                                                    icon={<IconMi icon="paperclip" />}
                                                />
                                            );
                                        })}
                                </Box>
                            )}
                        </>
                    )}

                    {params.notificationsDisabled ? (
                        <span className="wall_comment_span_OLD">{t('postbox.notifications_disabled_msg')}</span>
                    ) : (
                        <>
                            {shareWithData.length > 0 && (
                                <Box className="email_autocomplete_selected">
                                    {shareWithData.map((shareWithItem, index) => {
                                        return (
                                            <Chip
                                                key={shareWithItem}
                                                label={shareWithItem}
                                                variant="outlined"
                                                onClick={() => {
                                                    removeShareItem(index);
                                                }}
                                                onDelete={() => {
                                                    removeShareItem(index);
                                                }}
                                                deleteIcon={
                                                    <IconMi icon={'times'} sx={{ fontSize: '12px !important' }} />
                                                }
                                                icon={<IconMi icon="user" />}
                                                color="primary"
                                                sx={{ m: 0.5 }}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                <Box className="wall_postbox_share_tr_OLD" sx={{ display: 'none' }}>
                    <Box component="table" sx={{ width: '100%' }}>
                        <Box component="tbody">
                            <Box component="tr">
                                <Box component="td" sx={{ width: '710px' }}>
                                    <Box
                                        component="a"
                                        className="email_select_open_a"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        {t('postbox.share_with_link')}
                                    </Box>
                                </Box>
                                <Box component="td">
                                    <textarea
                                        id={`wall_postbox_note_${wallPostboxData.uid}_share_field`}
                                        rows={2}
                                        className="wall_postbox_share_fiel_OLD"
                                        name="share"
                                        placeholder={t('postbox.share_with_placeholder')}
                                        data-test={'wall_postbox_textarea'}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {showShareWithPopup && (
                <ShareWithPopup
                    data={shareWithData}
                    linkData={{
                        elementId: params.element_id,
                        segmentValueId: params.segment_value_id,
                    }}
                    onClose={() => {
                        setShowShareWithPopup(false);
                    }}
                    onConfirm={(values: any) => {
                        setShareWithData(values);
                        setShowShareWithPopup(false);
                    }}
                />
            )}
        </Box>
    );
};

//  @ts-ignore
export default forwardRef(WallPostbox);
