import { alpha, Box, Button, MenuItem, Stack } from '@mui/material';
import React, { Suspense, useEffect, useRef } from 'react';
import DropdownIcon from 'components/element-viewer/controls-panel-icon/DropdownIcon';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';

interface ActionMenuProps {
    items: ActionMenuListProps[];
}
interface ActionMenuListProps {
    key: string;
    component: any;
    separatorBefore?: boolean;
}

export default function ActionMenu(props: ActionMenuProps) {
    const { t } = useBundleTranslation('components/common/form/form');

    return (
        <Suspense
            fallback={
                <Button
                    sx={{ ml: 1.25 }}
                    variant="outlined"
                    color="neutral"
                    endIcon={<IconMi icon={'chevron-down'} fontSize="16" />}
                >
                    {t('actions')}
                </Button>
            }
        >
            <Box sx={{ ml: 1.25 }}>
                <DropdownIcon
                    icon="chevron-down"
                    label={t('actions')}
                    isButtonView
                    menuSx={{
                        py: 0.5,
                        maxWidth: '262px',
                    }}
                    placement={'bottom-end'}
                >
                    {(props.items ?? []).map((menuItem, index) => {
                        return (
                            <Box key={index}>
                                {menuItem.separatorBefore && (
                                    <Box
                                        sx={{
                                            width: 1,
                                            height: '1px',
                                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                            my: 0.5,
                                        }}
                                    />
                                )}
                                <MenuItem data-test={'form_top_panel_button_' + menuItem.key} sx={{ padding: '0px' }}>
                                    {menuItem.component}
                                </MenuItem>
                            </Box>
                        );
                    })}
                </DropdownIcon>
            </Box>
        </Suspense>
    );
}

interface ActionMenuItemProps {
    icon?: string;
    label: string;
    desc: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
}
export function ActionMenuItem(props: ActionMenuItemProps) {
    const { label, desc, icon, onClick = () => {}, disabled } = props;

    const currentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (currentRef.current == null) {
            return;
        }
        const parent = currentRef.current.closest('li');
        if (parent == null) {
            return;
        }
        if (disabled) {
            parent.classList.add('action-menu-item--disabled');
        } else {
            parent.classList.remove('action-menu-item--disabled');
        }
    }, [disabled, currentRef.current]);

    return (
        <Stack
            ref={currentRef}
            direction={'row'}
            onClick={onClick}
            sx={{
                whiteSpace: 'normal',
                px: 1.5,
                py: 1,
                width: '100%',
                color: disabled ? (theme) => alpha(theme.palette.text.primary, 0.6) : undefined,
                pointerEvents: disabled ? 'none' : undefined,
            }}
        >
            <Box sx={{ width: (theme) => theme.size, flexShrink: 0 }}>
                {icon && <IconMi icon={icon} fontSize="16" sx={{ mr: 1 }} />}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box>{label}</Box>
                {desc && (
                    <Box
                        sx={{
                            color: (theme) => alpha(theme.palette.text.primary, disabled ? 0.4 : 0.8),
                            fontSize: '11px',
                        }}
                    >
                        {desc}
                    </Box>
                )}
            </Box>
        </Stack>
    );
}
