import { Box, Stack, Theme, Typography } from '@mui/material';
import { MetricItem } from '../index';
import { alpha } from '@mui/material/styles';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';

interface IMetricListProps {
    metrics: MetricItem[];
    onMetricChange: (metricUid: string) => void;
    activeMetricUid: string;
    onMetricRemove: (metricUid: string) => void;
}

export default function MetricList({ metrics, onMetricChange, onMetricRemove, activeMetricUid }: IMetricListProps) {
    return (
        <Stack sx={{}}>
            {metrics.map((metric, i) => (
                <ListItem
                    metric={metric}
                    onMetricChange={onMetricChange}
                    active={metric.uid === activeMetricUid}
                    onMetricRemove={onMetricRemove}
                />
            ))}
        </Stack>
    );
}

interface IListItemProps {
    metric: MetricItem;
    onMetricChange: (metricUid: string) => void;
    active: boolean;
    onMetricRemove: (metricUid: string) => void;
}

function ListItem({ metric, onMetricChange, active, onMetricRemove }: IListItemProps) {
    return (
        <Stack
            direction={'row'}
            sx={{
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => (active ? 'transparent' : alpha(theme.palette.text.primary, 0.08)),
                backgroundColor: active ? (theme: Theme) => `${alpha(theme.palette.primary.main, 0.08)}` : '',
                borderLeft: '1px solid',
                borderLeftColor: (theme: Theme) => (active ? theme.palette.primary.main : 'transparent'),
                paddingLeft: '24px',
                paddingRight: '8px',
                minHeight: '32px',
            }}
            justifyContent={'space-between'}
            alignItems={'center'}
            data-test={`metric-item-${metric.uid}`}
        >
            <Box sx={{ cursor: 'pointer', width: '90%' }} onClick={() => onMetricChange(metric.uid)}>
                <Typography fontSize={'12px'} color={active ? 'primary.main' : 'text.primary'}>
                    {metric.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    cursor: 'pointer',
                    color: active ? 'primary.main' : 'text.primary',
                    height: '16px',
                    width: '16px',
                }}
                onClick={() => onMetricRemove(metric.uid)}
                data-test={`metric-item-remove-button-${metric.uid}`}
            >
                <IconMi icon="times" fontSize={'16'} />
            </Box>
        </Stack>
    );
}
