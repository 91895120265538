import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

interface TransferListSearchProps {
    onChange: (event: any) => void;
    onReset: (event: any) => void;
    value: any;
}

export default function TransferListSearch(props: TransferListSearchProps) {
    const { t } = useBundleTranslation();
    const { onChange = () => {}, onReset = () => {}, value } = props;

    return (
        <TextField
            sx={{
                '.MuiInput-root': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
            }}
            onChange={onChange}
            value={value}
            placeholder={t('search')}
            InputProps={{
                endAdornment: value ? (
                    <InputAdornment
                        position="end"
                        onClick={onReset}
                        sx={{ color: 'primary.main', '&:hover': { opacity: 0.85, cursor: 'pointer' } }}
                    >
                        <IconMi icon="times" fontSize={'16'} sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                ) : undefined,
                startAdornment: (
                    <InputAdornment position="start">
                        <IconMi icon="search" fontSize={'16'} sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                ),
            }}
        />
    );
}
