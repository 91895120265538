import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Stack, Typography, Divider } from '@mui/material';
import MITable from 'components/common/grid/MITable';
import { ColumnType, GridData } from 'components/common/grid';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';
import { useQuery } from '@tanstack/react-query';
import { gridAPI } from 'api/grid';
import { NumberSize, Resizable, ResizeCallback } from 're-resizable';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import useBundleTranslation from 'i18n';
import styles from 'app/editor/dataset/DataPreview.styles';
import { IValidationResponseReportSuccess } from 'components/common/form/data-fetch-command/validation';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { Direction } from 're-resizable/lib/resizer';
import { SortType } from 'rsuite-table/lib/@types/common';
import IconMi from 'components/common/icon/IconMi';

export interface DataPreviewProps {
    show: boolean;
    setShow?: (show: boolean) => void;
    defaultHeight: number | string;
    onResize: ResizeCallback;
    gridConfigUrl: string;
    pkId: string;
    uid: string;
    showLimitSelector?: boolean;
    showRestrictData?: boolean;
    showCloseButton?: boolean;
    closeButtonType?: 'default' | 'button';
    previewData?: IValidationResponseReportSuccess;
    dataPayload?: any;
    infiniteScroll?: boolean;
    onSortColumn?: (dataKey: string, sortType?: SortType) => void;
    orderBy?: string;
    order?: SortType;
    emptyGridMessage?: string;
    gridTitle?: string;
}

function ResizeHandle() {
    return (
        <Stack sx={styles.resizeHandleHolder}>
            <Box sx={styles.resizeHandleDots}>
                <IconMi icon={'more'} sx={{ fontSize: '20px' }} />
            </Box>
        </Stack>
    );
}

const selectOptions: FormComponentValue[] = [
    {
        label: 'All',
        value: 'all',
        hidden: false,
        disabled: false,
        props: {},
    },
    {
        label: '100',
        value: '100',
        hidden: false,
        disabled: false,
        props: {},
    },
    {
        label: '500',
        value: '500',
        hidden: false,
        disabled: false,
        props: {},
    },
    {
        label: '1000',
        value: '1000',
        hidden: false,
        disabled: false,
        props: {},
    },
    {
        label: '5000',
        value: '5000',
        hidden: false,
        disabled: false,
        props: {},
    },
    {
        label: '10000',
        value: '10000',
        hidden: false,
        disabled: false,
        props: {},
    },
];

export default function DataPreview({
    show,
    setShow,
    defaultHeight,
    onResize,
    gridConfigUrl,
    showLimitSelector,
    showRestrictData,
    showCloseButton,
    pkId,
    uid,
    previewData,
    dataPayload,
    infiniteScroll,
    onSortColumn,
    orderBy,
    order,
    emptyGridMessage,
    gridTitle,
    closeButtonType = 'default',
}: DataPreviewProps) {
    const [limit, setLimit] = useState('100');
    const [rowsCount, setRowsCount] = useState(0);
    const [gridData, setGridData] = useState<GridData>([]);
    const [columns, setColumns] = useState<ColumnType[]>([]);
    const [applyAccessMap, setApplyAccessMap] = useState(false);
    const [drawerSize, setDrawerSize] = useState(350);
    const [currentPage, setCurrentPage] = useState(0);
    const [loadingNewPage, setLoadingNewPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoadMsg, setErrorLoadMsg] = useState<string>('');

    const pageSize = 500;
    const limitNumber = Number(limit);

    const queryKey = `${uid}-grid-${gridConfigUrl}-${pkId}-${limit}-${applyAccessMap}`;
    const trackScroll = infiniteScroll && (isNaN(limitNumber) || limitNumber > pageSize);

    const queryPostParams = trackScroll
        ? {
              cond: dataPayload,
              applyAccessMap: applyAccessMap ? 'Y' : 'N',
              'page-size': pageSize,
              'page-from': 0,
              'page-to': 1,
          }
        : {
              limit: limit,
              cond: dataPayload,
              applyAccessMap: applyAccessMap ? 'Y' : 'N',
          };

    const { data, refetch } = useQuery<any, Error>({
        queryKey: [queryKey],
        queryFn: () => {
            if (typeof previewData == 'undefined' && dataPayload !== '' && gridConfigUrl > '') {
                setIsLoading(true);
                setErrorLoadMsg('');
                return gridAPI.load(gridConfigUrl, queryPostParams);
            }

            return false;
        },
    });

    const { t } = useBundleTranslation(['app/editor/dataset']);
    const gridHeight = drawerSize - 62; //52px - height of top panel of resize block + 10px to fit scrollbar

    useEffect(() => {
        if (data && data.data?.status === 'ERROR' && data.data?.message) {
            setErrorLoadMsg(data.data?.message);
            setIsLoading(false);
        }
        if (data && typeof data.data?.settings?.columns != 'undefined') {
            setColumns(data.data.settings.columns);
            setGridData(data.data.data.rows);
            setRowsCount(data.data.data.results);
            if (data.data.data.results < 100) setLimit('all');
            setIsLoading(false);
        }
        if (typeof previewData != 'undefined') {
            setColumns(previewData.data.header);
            setGridData(
                previewData.data.data.map((row) => {
                    const result: any = {};
                    previewData.data.header.forEach((h, i) => {
                        result[h.column_name] = row[i];
                    });
                    return result;
                }),
            );
        }
    }, [data, previewData]);

    useEffect(() => {
        if (dataPayload) {
            refetch();
        }
    }, [dataPayload]);

    const handleLimitUpdate = (limit: string) => {
        setCurrentPage(0);
        setLimit(limit);
    };

    const handleScroll = (scrollX: number, scrollY: number) => {
        if (trackScroll && !isLoading && gridData.length > 0) {
            const contextHeight = gridData.length * 32;
            const top = Math.abs(scrollY);

            if (contextHeight - top - gridHeight < 500) {
                loadMore();
            }
        }
    };

    const loadMore = () => {
        if (trackScroll && !loadingNewPage) {
            const gridRowsLimit = isNaN(limitNumber) ? rowsCount : limitNumber;

            if (gridRowsLimit > gridData.length) {
                setCurrentPage(currentPage + 1);
                setLoadingNewPage(true);
            }
        }
    };

    useEffect(() => {
        if (trackScroll && currentPage > 0 && loadingNewPage) {
            const postParams = {
                cond: dataPayload,
                applyAccessMap: applyAccessMap ? 'Y' : 'N',
                'page-size': pageSize,
                'page-from': currentPage,
                'page-to': currentPage + 1,
            };

            gridAPI.load(gridConfigUrl, postParams).then((response: any) => {
                if (response && response.data && response.data.data && response.data.data.rows) {
                    setGridData([...gridData, ...response.data.data.rows]);
                    setLoadingNewPage(false);
                }
            });
        }
    }, [currentPage]);

    return show ? (
        <Box sx={styles.wrapper}>
            <Resizable
                defaultSize={{
                    width: '100%',
                    height: defaultHeight,
                }}
                minHeight={200}
                maxHeight="75vh"
                onResize={(
                    event: MouseEvent | TouchEvent,
                    direction: Direction,
                    elementRef: HTMLElement,
                    delta: NumberSize,
                ) => {
                    setDrawerSize(elementRef.clientHeight);

                    onResize(event, direction, elementRef, delta);
                }}
                handleComponent={{
                    top: <ResizeHandle />,
                }}
                handleStyles={{
                    top: styles.resizeHandle,
                }}
                enable={{
                    top: true,
                    right: false,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
            >
                {showCloseButton && setShow && closeButtonType == 'default' && (
                    <IconButton
                        sx={styles.closeBtn}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <IconMi icon={'times'} sx={{ fontSize: '12px !important' }} />
                    </IconButton>
                )}
                <Stack sx={styles.contentHolder}>
                    <Stack direction="row" sx={styles.headerWrap} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant="heading" sx={{ borderBottom: 'none', pb: 0 }}>
                                {gridTitle ?? t('grid_heading')}
                            </Typography>
                        </Stack>
                        {showRestrictData && (
                            <Stack
                                direction="row"
                                sx={styles.userMapRestrictionHolder}
                                onClick={() => {
                                    setApplyAccessMap(!applyAccessMap);
                                }}
                            >
                                <Box>
                                    <Checkbox
                                        data-test={'data-preview-restrcit-data'}
                                        checked={applyAccessMap}
                                        onChange={() => {
                                            setApplyAccessMap(!applyAccessMap);
                                        }}
                                        sx={{ py: '5px' }}
                                    />
                                </Box>
                                <Box>{t('restrict_with_user_map')}</Box>
                            </Stack>
                        )}
                        <Stack direction={'row'} spacing={1.5}>
                            {showLimitSelector && (
                                <Stack direction={'row'} alignItems={'center'} sx={styles.showAmountHolder}>
                                    <StaticAddon sx={{ alignSelf: 'stretch' }}>{t('show_amount_label')}</StaticAddon>
                                    <Box sx={{ width: '144px' }}>
                                        <ReactSelect
                                            data={selectOptions}
                                            value={[limit]}
                                            update={handleLimitUpdate}
                                            onMenuOpen={() => {
                                                document.body.classList.add('data-preview-panel-active');
                                            }}
                                            onMenuClose={() => {
                                                document.body.classList.remove('data-preview-panel-active');
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ pl: 1.5 }}>{t('show_amount_of', { count: rowsCount })}</Box>
                                </Stack>
                            )}
                            {showCloseButton && setShow && closeButtonType == 'button' && (
                                <>
                                    {showLimitSelector && (
                                        <Divider orientation="vertical" sx={{ height: 16, alignSelf: 'center' }} />
                                    )}
                                    <Button
                                        onClick={() => {
                                            setShow(false);
                                        }}
                                        variant="light"
                                        startIcon={<IconMi icon="times" />}
                                    >
                                        {t('close_btn_label')}
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Box sx={{ '.rs-table': { pb: 0 } }}>
                        {isLoading ? (
                            <Box sx={{ textAlign: 'center' }}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : (
                            <MITable
                                gridName={'dataset_preview'}
                                height={gridHeight}
                                data={gridData}
                                columns={columns}
                                massActionsConfig={{ enabled: false }}
                                handleScroll={handleScroll}
                                shouldUpdateScroll={false}
                                onSortColumn={onSortColumn}
                                orderBy={orderBy}
                                order={order}
                                emptyGridMessage={errorLoadMsg != '' ? errorLoadMsg : emptyGridMessage}
                            />
                        )}
                    </Box>
                </Stack>
            </Resizable>
        </Box>
    ) : null;
}
