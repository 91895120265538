import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormComponentValue, FormControlToggleButtonGroupProps, FormElementControlPropsType } from './index';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
export default function FormToggleButtonGroup({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlToggleButtonGroupProps>) {
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);

    const value =
        controlProps.value && controlProps.value > ''
            ? controlProps.value
            : controlProps.defaultValue && controlProps.defaultValue > ''
              ? controlProps.defaultValue
              : '';

    return (
        <ToggleButtonGroup
            sx={{ width: controlProps.width ?? undefined }}
            color="primary"
            value={value}
            exclusive
            onChange={(e, v) => {
                if (v === null) {
                    return;
                }
                controlProps.onChange(v);
            }}
            aria-label="Platform"
            orientation={controlProps.isRow ? 'horizontal' : 'vertical'}
            data-test={controlProps.uid}
            disabled={controlProps.disabled}
            fullWidth={controlProps.fullWidth ?? false}
        >
            {controlProps.data
                .filter((v) => !v.hidden)
                .map((v: FormComponentValue) => (
                    <ToggleButton
                        data-test={`${controlProps.uid}_${v.value}`}
                        key={v.value}
                        value={v.value}
                        disabled={v.disabled}
                    >
                        {controlProps?.icons?.[v.value] && (
                            <IconMi icon={controlProps.icons[v.value]} fontSize="16" sx={{ mr: 1 }} />
                        )}
                        {controlProps.translate ? t(String(v.label)) : v.label}
                    </ToggleButton>
                ))}
        </ToggleButtonGroup>
    );
}
