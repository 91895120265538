import React, { useEffect, useState } from 'react';
import { alpha, Grid } from '@mui/material';
import FieldsSelector from './fields-selector';
import ConditionBlock from './ConditionBlock';
import { Aggregations, ColumnData, DerivedColumnData, DerivedFieldData, FilterData, RulesConfig } from '../index';
import { GridData } from 'components/common/grid';
import { TrackTabUpdateDTO } from './helpers';
import DerivedPopup from './derived-field/DerivedPopup';
import { unset } from 'lodash';

function QueryBuilder({
    columnsData,
    selectedFilterData,
    filter,
    onCurrentFilterUpdate,
    datasetId,
    multiInstancesMode,
    builderDirty,
    json,
    applyChanges,
    onLeftTabChange,
}: {
    columnsData: ColumnData[];
    selectedFilterData: FilterData | null;
    filter: RulesConfig;
    onCurrentFilterUpdate: (newFilter: RulesConfig) => void;
    datasetId: number;
    multiInstancesMode: boolean;
    builderDirty: boolean;
    json: string;
    applyChanges: () => void;
    onLeftTabChange: (tab: number) => void;
}) {
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [numericFieldsLabels, setNumericFieldsLabels] = useState<string[]>([]);
    const [numericColumns, setNumericColumns] = useState<GridData>([]);
    const [textColumns, setTextColumns] = useState<GridData>([]);
    const [names, setNames] = useState<string[]>([]);
    const [nonNumericFields, setNonNumericFields] = useState<ColumnData[]>([]);
    const [numericFields, setNumericFields] = useState<ColumnData[]>([]);
    const [fields, setFields] = useState<ColumnData[]>([]);
    const [dateFields, setDateFields] = useState<ColumnData[]>([]);
    const [showEditDerived, setShowEditDerived] = useState(false);
    const [selectedDerived, setSelectedDerived] = useState<DerivedFieldData | null>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                applyChanges();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [builderDirty, json]);

    useEffect(() => {
        const tmpNumericFields: ColumnData[] = [];
        const tmpDateFields: ColumnData[] = [];
        const textFields: ColumnData[] = [];
        const fieldNames: string[] = [];

        columnsData.forEach((data) => {
            if (data.value_type === 'text') {
                textFields.push(data);
            }
            if (data.value_type === 'datetime') {
                tmpDateFields.push(data);
            }
            if (data.value_type === 'numeric') {
                tmpNumericFields.push(data);
            }

            fieldNames.push(data.label);
        });

        setNonNumericFields(textFields);
        setDateFields(tmpDateFields);
        setNumericFields(tmpNumericFields);
        setNames(fieldNames);
    }, [columnsData]);

    useEffect(() => {
        if (filter) {
            const selected: string[] = [];

            if (!filter.resultFields) {
                filter.resultFields = [];
            }

            if (!filter.joinFields) {
                filter.joinFields = [];
            }

            if (!filter.resultFieldAggregations) {
                filter.resultFieldAggregations = {};
            }

            if (!filter.derivedFields) {
                filter.derivedFields = [];
            }

            if (!filter.group) {
                filter.group = {
                    operator: 'AND',
                    rules: [],
                };
            }

            if (!filter.group_new) {
                filter.group_new = {
                    operator: 'AND',
                    rules: [],
                };
            }

            setNumericColumns((oldValues) => {
                return oldValues.map((dataRow) => {
                    const index = (dataRow['id'] as String) ?? '';
                    //@ts-ignore
                    const aggrValue = filter.resultFieldAggregations[index];

                    if (aggrValue) {
                        dataRow['aggregation'] = aggrValue;
                    } else {
                        dataRow['aggregation'] = '';
                    }

                    return dataRow;
                });
            });

            setNumericFields((prevState) => {
                const newState = [...prevState];

                filter.derivedFields.forEach((column) => {
                    const field: ColumnData = {
                        column_name: column.column_name,
                        ref_column_name: column.ref_column_name,
                        current: true,
                        field: column.name,
                        id: column.id,
                        name: column.name,
                        sortable: true,
                        derived: true,
                        aggregation: false,
                        value_type: 'numeric',
                        label: column.column_name,
                        numeric_type: null,
                        key: column.name,
                    };

                    if (
                        !newState.find((numField) => {
                            return numField.id === field.id;
                        })
                    ) {
                        newState.push(field);
                    }
                });

                return newState;
            });

            columnsData.forEach((data) => {
                if (filter.resultFields.includes(data.id)) {
                    selected.push(data.id);
                }
            });

            filter.derivedFields.forEach((data) => {
                if (filter.resultFields.includes(data.id)) {
                    selected.push(data.id);
                }
            });

            filter.joinFields.forEach((field) => {
                if (field && !selected.includes(field.id)) {
                    selected.push(field.id);
                }
            });

            setSelectedColumns(selected);
        }
    }, [filter]);

    useEffect(() => {
        if (fields.length > 0) {
            const newOrder = getFieldsOrder(filter.resultFields);

            if (newOrder.length > 0 && JSON.stringify(newOrder) !== JSON.stringify(filter.fieldsOrder)) {
                const newFilter = { ...filter };
                newFilter.fieldsOrder = newOrder;
                onCurrentFilterUpdate(newFilter);
            }
        }
    }, [fields]);

    useEffect(() => {
        const processedFields = [...nonNumericFields, ...dateFields, ...numericFields];

        for (let resultFieldAggregationsKey in filter.resultFieldAggregations) {
            const fieldIndex = processedFields.findIndex((item) => {
                return item.id === resultFieldAggregationsKey;
            });

            if (fieldIndex > -1) {
                if (filter.resultFieldAggregations[resultFieldAggregationsKey] > '') {
                    processedFields[fieldIndex].label =
                        filter.resultFieldAggregations[resultFieldAggregationsKey] +
                        ' of ' +
                        processedFields[fieldIndex].name;
                } else {
                    processedFields[fieldIndex].label = processedFields[fieldIndex].name;
                }
            }
        }

        setFields(processedFields);
    }, [nonNumericFields, numericFields]);

    useEffect(() => {
        const joinFieldsIds: string[] = filter.joinFields
            .filter((field) => field && field.id)
            .map((field) => {
                return field.id;
            });

        const processedDate = dateFields.map((field) => {
            return {
                id: field.id,
                label: field.label,
                name: field.name,
                unselectable: joinFieldsIds.includes(field.id) && filter.history ? 'true' : '',
                aggregationLabel: {
                    component: 'aggregationLabel',
                    config: {},
                },
                type: field.value_type,
                aggregationSelector: {
                    component: 'aggregation',
                    config: {},
                },
                aggregation: filter.resultFieldAggregations[field.id] ?? '',
            };
        });

        const processedNumeric = numericFields.map((field) => {
            return {
                id: field.id,
                label: field.label,
                name: field.name,
                unselectable: joinFieldsIds.includes(field.id) && filter.history ? 'true' : '',
                aggregationLabel: {
                    component: 'aggregationLabel',
                    config: {},
                },
                type: field.value_type,
                aggregationSelector: {
                    component: 'aggregation',
                    config: {},
                },
                derived: field.derived,
                derivedControl: {
                    component: 'derivedControl',
                    config: {},
                },
                aggregation: filter.resultFieldAggregations ? (filter.resultFieldAggregations[field.id] ?? '') : '',
            };
        });

        setNumericColumns([...processedDate, ...processedNumeric]);
    }, [numericFields, dateFields, filter.joinFields, filter.history]);

    useEffect(() => {
        const joinFieldsIds: string[] = filter.joinFields
            .filter((field) => field && field.id)
            .map((field) => {
                return field.id;
            });

        setTextColumns(
            nonNumericFields.map((field) => {
                return {
                    id: field.id,
                    label: field.label,
                    name: field.name,
                    unselectable: joinFieldsIds.includes(field.id) && filter.history ? 'true' : '',
                };
            }),
        );
    }, [nonNumericFields, filter.joinFields, filter.history]);

    useEffect(() => {
        const newFieldsLabels: string[] = [];

        fields.forEach((field) => {
            if (!field.derived && field.value_type === 'numeric') {
                newFieldsLabels.push(`[${field.label}]`);
            }
        });

        setNumericFieldsLabels(newFieldsLabels);
    }, [fields]);

    const onSelectedChange = (keys: string[], checked: boolean) => {
        const newFilter = { ...filter };

        const initResultFields = columnsData.map((column) => column.id);
        let selectedFields = [...newFilter.resultFields];

        if (checked) {
            selectedFields.push(...keys);
        } else {
            const resultFields: string[] = [];

            selectedFields.forEach((field) => {
                if (!keys.includes(field)) {
                    resultFields.push(field);
                }
            });

            selectedFields = resultFields;
        }

        const newSelectedFields = initResultFields.filter((field) => {
            return selectedFields.includes(field);
        });

        newFilter.derivedFields.forEach((field) => {
            if (!checked && keys.includes(field.id)) {
                return;
            }

            if (selectedFields.includes(field.id)) {
                newSelectedFields.push(field.id);
            }
        });
        newFilter.resultFields = newSelectedFields;

        onCurrentFilterUpdate(newFilter);
    };

    const onAggregationChange = (fieldId: string, value: string, oldValue: string) => {
        const newFilter = { ...filter };

        newFilter.resultFieldAggregations[fieldId] = value as Aggregations;
        newFilter.fieldsOrder = getFieldsOrder(newFilter.resultFields);

        newFilter.derivedFields = newFilter.derivedFields.map((field) => {
            if (field.info?.relatedColumns && field.info?.relatedColumns.length > 0) {
                const relatedIndex = field.info.relatedColumns.findIndex((related) => {
                    return related.field === fieldId;
                });

                if (relatedIndex > -1) {
                    const prevFieldName = oldValue > '' ? `[${oldValue} of ${fieldId}]` : `[${fieldId}]`;
                    const newFieldName = value > '' ? `[${value} of ${fieldId}]` : `[${fieldId}]`;

                    field.expression = field.expression.replaceAll(prevFieldName, newFieldName);
                }
            }

            return field;
        });

        onCurrentFilterUpdate(newFilter);
    };

    const onTrackDataUpdate = (value: TrackTabUpdateDTO) => {
        if (value.joinFields.length > 0) {
            const newFilter = { ...filter };

            newFilter.trackNewRows = value.selectedTrackOptions.includes('new');
            newFilter.trackChangedRows = value.selectedTrackOptions.includes('changed');
            newFilter.trackRemovedRows = value.selectedTrackOptions.includes('removed');
            newFilter.joinFields = value.joinFields;

            if (
                newFilter.trackNewRows !== filter.trackNewRows ||
                newFilter.trackChangedRows !== filter.trackChangedRows ||
                newFilter.trackRemovedRows !== filter.trackRemovedRows ||
                JSON.stringify(newFilter.joinFields) !== JSON.stringify(filter.joinFields)
            ) {
                onCurrentFilterUpdate(newFilter);
            }
        }
    };

    const getFieldsOrder = (resultFields: string[]) => {
        return resultFields.map((fieldId) => {
            const fieldColumnData = fields.find((column) => {
                return column.id === fieldId;
            });

            if (fieldColumnData) {
                return fieldColumnData.label;
            }

            return fieldId;
        });
    };

    const onCreateDerived = (data: DerivedFieldData) => {
        const column: DerivedColumnData = {
            id: data.name,
            name: data.name,
            column_name: data.name,
            ref_column_name: data.name,
            expression: data.expression,
            display_mask_id: data.display_mask_id,
            info: data.info,
        };
        const field: ColumnData = {
            column_name: column.column_name,
            ref_column_name: column.ref_column_name,
            current: true,
            field: column.name,
            id: column.id,
            name: column.name,
            sortable: true,
            derived: true,
            aggregation: false,
            value_type: 'numeric',
            label: column.column_name,
            numeric_type: null,
            key: column.name,
        };

        setNumericFields((prevState) => {
            const newState = [...prevState];
            newState.push(field);
            return newState;
        });

        const newFilter = { ...filter };
        newFilter.derivedFields.push(column);
        newFilter.resultFieldAggregations[column.id] = '';
        newFilter.resultFields.push(column.id);
        newFilter.fieldsOrder = getFieldsOrder(newFilter.resultFields);

        onCurrentFilterUpdate(newFilter);

        setSelectedColumns((prevState) => {
            const newState = [...prevState];
            newState.push(field.id);

            return newState;
        });
    };

    const onDerivedAction = (action: 'edit' | 'delete', fieldId: string) => {
        if (action === 'delete') {
            removeDerivedField(fieldId);
        }
        if (action === 'edit') {
            const fieldData = filter.derivedFields.find((field) => {
                return field.id === fieldId;
            });

            if (fieldData) {
                const derivedField: DerivedFieldData = {
                    id: fieldData.id,
                    expression: fieldData.expression,
                    name: fieldData.name,
                    display_mask_id: fieldData.display_mask_id,
                };
                setSelectedDerived(derivedField);
                setShowEditDerived(true);
            }
        }
    };

    const removeDerivedField = (fieldId: string) => {
        setNumericFields((prevState) => {
            return prevState.filter((field) => {
                return field.id !== fieldId;
            });
        });

        const newFilter = { ...filter };
        newFilter.derivedFields = newFilter.derivedFields.filter((field) => {
            return field.id !== fieldId;
        });
        newFilter.resultFieldAggregations[fieldId] = '';
        newFilter.resultFields.filter((resultField) => {
            return resultField !== fieldId;
        });
        newFilter.fieldsOrder.filter((orderField) => {
            return orderField !== fieldId;
        });
        onCurrentFilterUpdate(newFilter);
    };

    const updateDerivedField = (data: DerivedFieldData) => {
        if (selectedDerived) {
            const newFilter = { ...filter };

            newFilter.derivedFields = newFilter.derivedFields.map((derField) => {
                if (derField.id === selectedDerived.id) {
                    const newValue = { ...derField };

                    newValue.id = data.name;
                    newValue.name = data.name;
                    newValue.column_name = data.name;
                    newValue.ref_column_name = data.name;
                    newValue.info = data.info;
                    newValue.expression = data.expression;
                    newValue.display_mask_id = data.display_mask_id;

                    return newValue;
                }

                return derField;
            });

            newFilter.resultFieldAggregations[data.name] = newFilter.resultFieldAggregations[selectedDerived.id];
            unset(newFilter.resultFieldAggregations, selectedDerived.id);

            const newResultFields = newFilter.resultFields.filter((resultField) => {
                return resultField !== selectedDerived.id;
            });
            newResultFields.push(data.name);
            newFilter.resultFields = newResultFields;

            const newFieldsOrder = newFilter.fieldsOrder.filter((orderField) => {
                return orderField !== selectedDerived.id;
            });
            newFieldsOrder.push(data.name);
            newFilter.fieldsOrder = newFieldsOrder;

            newFilter.group.rules = newFilter.group.rules.map((rule) => {
                if (rule.field === selectedDerived.id) {
                    const newRule = { ...rule };
                    newRule.field = data.name;
                    newRule.label = data.name;
                    newRule.label_new = data.name;
                    newRule.key = newRule.key.replace(selectedDerived.id, data.name);
                    return newRule;
                }
                if (rule.field_second === selectedDerived.id) {
                    const newRule = { ...rule };
                    newRule.field_second = data.name;
                    newRule.key_second = newRule.key_second.replace(selectedDerived.id, data.name);
                    return newRule;
                }

                return rule;
            });

            onCurrentFilterUpdate(newFilter);

            setNumericFields((prevState) => {
                return prevState.map((field) => {
                    if (field.id === selectedDerived.id) {
                        const newValue = { ...field };
                        newValue.id = data.name;
                        newValue.name = data.name;
                        newValue.label = data.name;
                        newValue.label_new = data.name;
                        newValue.column_name = data.name;
                        newValue.ref_column_name = data.name;
                        newValue.field = data.name;

                        return newValue;
                    }

                    return field;
                });
            });

            setSelectedColumns((prevState) => {
                return prevState.map((column) => {
                    if (column === selectedDerived.id) {
                        return data.id;
                    }

                    return column;
                });
            });
        }
    };

    return (
        <Grid container alignItems="stretch" direction="row" sx={{ overflow: 'auto', flexGrow: 1 }}>
            <Grid
                item
                xs={4}
                sx={{
                    borderRight: 'solid 1px',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                }}
            >
                <FieldsSelector
                    textColumnsData={textColumns}
                    numericColumnsData={numericColumns}
                    selectedColumns={selectedColumns}
                    onSelectedChange={onSelectedChange}
                    onAggregationChange={onAggregationChange}
                    datasetId={datasetId}
                    onCreateDerived={onCreateDerived}
                    onDerivedAction={onDerivedAction}
                    names={names}
                    json={json}
                    fieldsLabels={numericFieldsLabels}
                    multiInstancesMode={multiInstancesMode}
                    filter={filter}
                    onTrackDataUpdate={onTrackDataUpdate}
                    fieldIds={fields.map((field) => {
                        return {
                            id: field.id,
                            name: field.name,
                            column_name: field.column_name,
                            ref_column_name: field.ref_column_name,
                        };
                    })}
                    onLeftTabChange={onLeftTabChange}
                />
            </Grid>
            <Grid item xs={8}>
                <ConditionBlock
                    filter={filter}
                    fields={fields}
                    builderDirty={builderDirty}
                    applyChanges={applyChanges}
                    onCurrentFilterUpdate={onCurrentFilterUpdate}
                />
            </Grid>
            {showEditDerived && selectedDerived && (
                <DerivedPopup
                    show={showEditDerived}
                    setShowPopup={setShowEditDerived}
                    onConfirmPopup={updateDerivedField}
                    names={names}
                    json={json}
                    datasetId={datasetId}
                    derivedField={selectedDerived}
                    fieldsLabels={numericFieldsLabels}
                />
            )}
        </Grid>
    );
}

export default QueryBuilder;
