import React from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Link, Theme, Tooltip } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { useForm } from 'react-hook-form';
import { FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import Alert from '@mui/material/Alert';

export interface NotifyOtherPopupProps {
    text?: any;
    type: string;
    elementId: number;
    segmentValueId: number;
    onClose: () => void;
    onConfirm: (email: any, comment: string) => void;
    errorMsg?: string;
}

export default function NotifyOtherPopup(props: NotifyOtherPopupProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { text, type, elementId, segmentValueId, onClose = () => {}, onConfirm = () => {}, errorMsg } = props;

    //Configuration upload Field
    const { setValue: hookFormSetValue, control, getValues: hookFromGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFromGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    const sendToListControlProps = {
        form: formActions,
        component: {
            component: 'SendToList',
            name: 'emails',
            label: t(`notify_other.share_with_label`),
            props: {
                dataUrl: '/data/notification/favorites/get-users-data?elementId=:elementId&segmentId=:segmentId',
                dataPageSize: 100,
                settingsUrl: '/data/notification/favorites/users?elementId=:elementId&segmentId=:segmentId',
                urlParams: {
                    elementId: elementId,
                    segmentId: segmentValueId,
                },
            },
        } as RawFormComponentType,
    };

    const commentFieldControlProps = {
        form: formActions,
        component: {
            component: 'FormTextarea',
            name: 'message',
            label: t(`notify_other.comment_label`),
            props: {},
        } as RawFormComponentType,
    };

    let titleType = 'default';
    switch (type) {
        case 'note':
        case 'alert':
        case 'event':
        case 'annotation':
        case 'comment':
            titleType = type;
            break;
    }

    return (
        <Popup
            settings={{
                title: t(`notify_other.title.${titleType}`),
                textOK: t(`notify_other.ok_btn`),
            }}
            maxWidth="popupMd"
            open={true}
            onHide={onClose}
            onConfirm={() => {
                onConfirm(hookFromGetValues('emails'), hookFromGetValues('message'));
            }}
        >
            {errorMsg && (
                <Box sx={{ mb: 2 }}>
                    <Alert variant="standard" severity="error">
                        {errorMsg}
                    </Alert>
                </Box>
            )}

            {text && (
                <Box
                    sx={{
                        mb: (theme) => theme.size.pxValue(theme.size.componentFormIndent),
                        pb: 0.5,
                        borderBottom: '1px solid',
                        borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        '& p': { margin: 0 },
                    }}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            )}
            <Box>
                <ReactHookFormController elementProps={prepareFormElementProps(sendToListControlProps)} />
            </Box>
            <Box
                sx={{
                    mt: (theme) => theme.size.pxValue(theme.size.componentFormIndent),
                    '.MuiInput-root': { width: '100%' },
                }}
            >
                <Box>{t(`notify_other.comment_label`)}</Box>
                <ReactHookFormController elementProps={prepareFormElementProps(commentFieldControlProps)} />
                <Box sx={{ textAlign: 'right' }}>
                    <Tooltip title={t('notify_other.markdown_link_tooltip')} disableInteractive>
                        <Link
                            href="http://help.metricinsights.com/m/Adding_Context_to_Metrics/l/274731-how-to-add-links-and-formatting-to-notes-comments-and-annotations"
                            className="markdown-label"
                            target="_blank"
                            sx={{ color: 'inherit' }}
                        >
                            {t('notify_other.markdown_link')}
                            <i className="icon mi-md ml-5" />
                        </Link>
                    </Tooltip>
                </Box>
            </Box>
        </Popup>
    );
}
