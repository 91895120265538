import React, { useState } from 'react';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { Backdrop, CircularProgress, Button } from '@mui/material';
import FormComponent from 'components/common/form/Form';
import IconMi from 'components/common/icon/IconMi';
import { downloadFile } from 'api/api';

export default function ImportUsersPopup({
    handleHidePopup,
    reloadGridData,
    url,
}: {
    handleHidePopup: () => void;
    reloadGridData: () => void;
    url: string;
}) {
    const { t } = useBundleTranslation(['app/editor/user', 'app/editor/user/import']);

    const [open, setOpen] = useState(true);
    const [loadingMaskShow, setLoadingMaskShow] = useState(false);

    const handleDownload = async () => {
        downloadFile('/files/mi-users.csv', true);
    };

    const formPopupSettings: PopupSettings = {
        title: t('import_users_title'),
        maxWidth: 'popupMd',
        iconOK: 'import',
        colorOk: 'success',
        textOK: t('import_button'),
        actionsPostContent: (
            <Button
                sx={{ ml: 'auto !important' }}
                variant={'text'}
                startIcon={<IconMi icon={'file-csv'} />}
                onClick={handleDownload}
            >
                {t('text_download')}
            </Button>
        ),
    };

    const handleHide = () => {
        setOpen(false);
        handleHidePopup();
    };

    const afterSave = () => {
        reloadGridData();
        handleHide();
    };

    return (
        <>
            {open && (
                <FormComponent
                    isPopup
                    popupType={'edit'}
                    pk={url}
                    uid={url}
                    settingsUrl={url}
                    onHide={handleHide}
                    popupSettings={formPopupSettings}
                    afterSave={afterSave}
                    getMethod
                    translationNS={'app/editor/user/import'}
                />
            )}
            <Backdrop
                sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingMaskShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
