import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import useBundleTranslation from 'i18n';

export default function FullScreenButton({ fullScreenHandle }: { fullScreenHandle: FullScreenHandle }) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);

    return (
        <span data-test={'top-full-screen-icon'}>
            <Tooltip title={t('full_screen_btn_tooltip')}>
                <IconButton
                    sx={{ p: '6px' }}
                    onClick={(e: any) => {
                        e.preventDefault();
                        fullScreenHandle.enter();
                    }}
                >
                    <IconMi icon="full-screen" fontSize="16" />
                </IconButton>
            </Tooltip>
        </span>
    );
}
