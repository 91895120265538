import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;

export function getDataQueries(settings: ComponentSettingsTextBlock) {
    const dataQueries = settings.dataQueries;

    if (settings.measureFields && settings.measureFields.length > 0) {
        dataQueries.values = { resultFields: [], resultFieldAggregations: {} };

        for (let i = 0; i < settings.measureFields.length; i++) {
            dataQueries.values.resultFields.push(settings.measureFields[i].reference_name);
            dataQueries.values.resultFieldAggregations[settings.measureFields[i].reference_name] =
                settings.measureFields[i].aggregation_function;
        }
    }

    if (settings.html && settings.html.match(/\[Snapshot\sDate\]/gi)) {
        dataQueries.measurement_time = true;
    }

    if (settings.html && settings.html.match(/\[Row\sCount\]/gi))
        dataQueries.row_count = {
            resultFields: ['cnt'],
            return_count_only: 'Y',
            apply_display_masks_external: 'N',
        };
    return dataQueries;
}
