import { alpha } from '@mui/material';

export const MuiTabConfig = {
    defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                fontWeight: 500,
                color: theme.palette.text.primary,
                minHeight: 45,
                minWidth: 0,
                padding: 12,
                '.MuiTabs-root:not(.tabs--one-item) &.Mui-selected': {
                    backgroundColor: alpha(theme.componentSettings.tab.primaryColor, 0.08),
                },
                '&:focus': {
                    outline: 'none',
                },
                '&:hover': {
                    textDecoration: 'none',
                },
                '&:not(.Mui-selected):hover': {
                    color: theme.componentSettings.tab.primaryColor,
                },
                '&.Mui-selected': {
                    color: theme.componentSettings.tab.primaryColor,
                },
                '.MuiTabs-root.form-tabs &.d-none': {
                    //Hide tab (refuse tabs scroll)
                    width: 0,
                    display: 'inline-flex !important',
                    padding: 0,
                    visibility: 'hidden',
                },
            };
        },
    },
};
