import { prepareFormComponentValues } from 'components/common/form/layout/control';
import React, { useEffect, useLayoutEffect } from 'react';
import { Box, Stack, IconButton, Input, FormControl } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { ColumnFieldType, ColumnName, getSMFilterColumnValues, RowList, RowListColumn, RowValues } from './index';
import { alpha } from '@mui/material/styles';
import IconMi from 'components/common/icon/IconMi';

export default function SelectMapRow({
    rowList,
    actualValues,
    onSelectChange,
    onDataChange,
    onRemoveRow,
    handleGearClick,
    hasSecondNested,
}: {
    rowList: RowList;
    onSelectChange: (value: any, column: ColumnName) => void;
    onDataChange: (data: any) => void;
    onRemoveRow: () => void;
    handleGearClick?: (data: any, setData: (data: any) => void) => void;
    actualValues: Array<RowValues>;
    hasSecondNested: boolean;
}) {
    const handleFirstSelectChange = function (value: any) {
        onSelectChange(value, 'first');
    };

    const handleSecondSelectChange = function (value: any) {
        onSelectChange(value, 'second');
    };

    const handleSecondNestedSelectChange = function (value: any) {
        onSelectChange(value, 'secondNested');
    };

    useEffect(() => {
        if (rowList.firstColumn.type == 'text') {
            return;
        }
        const valueExists = rowList.firstColumn.values.findIndex((row) => row.value == rowList.rowValues.firstColumn);
        if (valueExists == -1) {
            handleFirstSelectChange('');
        }
    }, [rowList.firstColumn.values]);

    useEffect(() => {
        if (rowList.secondColumn.type == 'text') {
            return;
        }
        const valueExists = rowList.secondColumn.values.findIndex((row) => row.value == rowList.rowValues.secondColumn);
        if (valueExists == -1) {
            handleSecondSelectChange('');
        }
    }, [rowList.secondColumn.values]);

    const nestedValues = (() => {
        if (!hasSecondNested) {
            return [];
        }
        const currentRow = rowList.secondColumn.values.find((v) => v.value == rowList.rowValues.secondColumn);
        if (currentRow?.props?.nestedValues) {
            return getSMFilterColumnValues(
                prepareFormComponentValues(currentRow?.props?.nestedValues),
                actualValues.filter((av) => av.secondColumn == rowList.rowValues.secondColumn),
                'both',
                'secondNested',
                rowList.rowValues.secondColumnNested ?? '',
            );
        }
        return [];
    })();

    useLayoutEffect(() => {
        if (!hasSecondNested || !nestedValues.length) {
            return;
        }

        if (rowList.rowValues.secondColumnNested == undefined) {
            onSelectChange(nestedValues[0].value, 'secondNested');
        }
    }, [nestedValues]);

    return (
        <Stack
            width={1}
            direction="row"
            sx={{
                py: 1,
                borderBottom: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            }}
        >
            <Stack direction="row" flexGrow={1}>
                <ValueControl
                    dataAttr={'map-dataset-column'}
                    column={rowList.firstColumn}
                    value={rowList.rowValues.firstColumn}
                    handleChange={handleFirstSelectChange}
                />
                <Stack direction="row" alignItems="center" flexShrink={0} sx={{ px: 1 }}>
                    <Box
                        sx={{
                            fontSize: 16,
                            color: 'text.primary',
                            padding: 0,
                            minHeight: (theme) => theme.size.defaultHeight,
                        }}
                    >
                        =
                    </Box>
                </Stack>
                <ValueControl
                    dataAttr={'map-user-map-column'}
                    column={rowList.secondColumn}
                    value={rowList.rowValues.secondColumn}
                    handleChange={handleSecondSelectChange}
                />
                {hasSecondNested && (
                    <Box sx={{ pl: 1.5 }} width={1}>
                        {nestedValues.length > 0 && (
                            <ReactSelect
                                data={nestedValues}
                                dataTest={'select-map-sub-column'}
                                value={rowList.rowValues.secondColumnNested ?? ''}
                                update={handleSecondNestedSelectChange}
                            />
                        )}
                    </Box>
                )}
            </Stack>
            <Box flexShrink={0} sx={{ pl: 1 }}>
                {handleGearClick && (
                    <IconButton
                        onClick={() => handleGearClick(rowList.rowValues.data, onDataChange)}
                        sx={{
                            color: 'text.primary',
                            padding: 0.75,
                            minHeight: (theme) => theme.size.defaultHeight,
                        }}
                    >
                        <IconMi icon="gear" />
                    </IconButton>
                )}
                <IconButton
                    onClick={onRemoveRow}
                    sx={{ color: 'text.primary', padding: 0.75, minHeight: (theme) => theme.size.defaultHeight }}
                >
                    <IconMi icon="times" />
                </IconButton>
            </Box>
        </Stack>
    );
}

function ValueControl({
    column,
    value,
    handleChange,
    dataAttr,
}: {
    column: RowListColumn;
    value: string;
    handleChange: (value: any, type: ColumnFieldType) => void;
    dataAttr: string;
}) {
    return (
        <Box width={1}>
            {column.type == 'text' ? (
                <FormControl fullWidth>
                    <Input
                        data-test={`input-${dataAttr}`}
                        value={value}
                        onChange={(e) => handleChange(e.target.value, column.type)}
                    />
                </FormControl>
            ) : (
                <ReactSelect
                    dataTest={`select-${dataAttr}`}
                    data={column.values}
                    value={value}
                    update={(v) => handleChange(v, column.type)}
                />
            )}
        </Box>
    );
}
