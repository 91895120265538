import { TFunction } from 'i18next';
import _isObject from 'lodash/isObject';

type ImportErrorType = {
    [key: string]: string | ImportErrorType;
};

export const importErrorHandler = (messages: { [key: string]: ImportErrorType } = {}, setError: any, t: TFunction) => {
    for (const [key, message] of Object.entries(messages)) {
        const errors: string[] = [];
        for (const [errorKey, error] of Object.entries({ ...message, line_token: '' })) {
            if (error) {
                const errorMessage = error as any;

                const stringError = (_isObject(errorMessage) ? Object.values(errorMessage)?.[0] : errorMessage) || '';

                if (errorMessage) {
                    errors.push(stringError.replaceAll('{{label}}', t(errorKey)));
                }
            }
        }

        setError(key, {
            type: 'server',
            message: t('Line') + ` ${key}: ` + `${message.line_token} ${errors.join('; ')}`,
        });
    }
};
