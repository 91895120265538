import { instance } from '../api';
import { APIResponse } from '../../tools/types';

const baseUrl = `/data/editor/template`;

export interface TemplateResponse {
    template_metadata_id: string;
    name: string;
}

export interface CreateTemplateResponse {
    id: string;
}

export const templateAPI = {
    async getTemplates(sourceId: string, type: string): Promise<TemplateResponse[]> {
        const response = await instance.get(`${baseUrl}/popup/source-id/${sourceId}/type/${type}`);

        return response.data.data;
    },
    async createFromSource(sourceId: string, type: string): Promise<APIResponse<CreateTemplateResponse>> {
        const response = await instance.post(`${baseUrl}/create/source-id/${sourceId}/type/${type}`);

        return response.data;
    },
    async updateFromSource(
        sourceId: string,
        type: string,
        templateId: string,
    ): Promise<APIResponse<CreateTemplateResponse>> {
        const response = await instance.post(
            `${baseUrl}/update-from-source/id/${templateId}/source-id/${sourceId}/type/${type}`,
        );

        return response.data;
    },
};
