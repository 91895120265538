import { alpha, IconButton, Stack, Theme, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { FolderData } from 'api/editor/element';

export default function ElementFolderItem({
    elementFolder,
    onFolderDelete,
    readOnly,
}: {
    elementFolder: FolderData;
    onFolderDelete: (folderData: FolderData) => void;
    readOnly: boolean;
}) {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
                borderBottom: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                height: '33px',
                paddingLeft: '7px',
            }}
        >
            <Stack
                direction={'row'}
                sx={{ height: '33px', width: '85%' }}
                alignItems={'center'}
                justifyContent={'flex-start'}
                spacing={elementFolder.folderPathParts.length > 0 ? 0.5 : ''}
            >
                <Typography
                    component={'p'}
                    sx={{
                        direction: 'rtl',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {elementFolder.folderPathParts.join(' / ')}
                </Typography>
                <Typography fontWeight={600} sx={{ whiteSpace: 'nowrap' }}>
                    {elementFolder.folderPathParts.length > 0
                        ? `/ ${elementFolder.folderName}`
                        : elementFolder.folderName}
                </Typography>
            </Stack>
            {!readOnly && (
                <IconButton size={'small'} onClick={() => onFolderDelete(elementFolder)}>
                    <IconMi icon="times" fontSize="16" />
                </IconButton>
            )}
        </Stack>
    );
}
