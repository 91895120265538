export function svgElementFromString(str: string) {
    // let svg = strToSvg(str);
    // let svgString = new XMLSerializer().serializeToString(svg);
    const base64 = btoa(unescape(encodeURIComponent(str)));
    return `url(data:image/svg+xml;base64,${base64})`;
}

export function strToSvg(str: string) {
    const div = document.createElement('DIV');
    div.innerHTML = str;
    const svg = div.querySelector('svg');

    if (!svg) {
        throw Error('<svg> tag not found');
    }

    return svg;
}
