import { OwnerConfig } from 'store/auth';

const isOwnerVisible = (config: OwnerConfig, entityName: string): boolean => {
    const entityConfig = config.entities.find((entity) => entity.name === entityName);

    if (entityConfig) {
        return config.enabled && entityConfig.enabled;
    }

    return false;
};

export default isOwnerVisible;
