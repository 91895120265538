import { AssocArray } from 'tools/types';

type DependencyScopeType = 'control' | 'value';
type DependencyRuleConditionType = 'ends with' | 'starts with' | '=' | '' | '>' | '>=' | '<' | '<=' | 'grid empty';

export interface DependencyRule {
    field: string;
    cond: DependencyRuleConditionType;
    data: string | Array<string>;
}
export interface FormComponentDependency {
    scope: DependencyScopeType;
    rules: Array<DependencyRule>;
    effect: AssocArray<any>;
}

export function getRuleFieldName(field: string) {
    return field.split('.')[0];
}

// Replace $fieldName.prop?.prop? to its value
export const FieldPropsPreg = /^\$(([a-zA-Z0-9_]+)(\.([a-zA-Z0-9_$]+))?(\.([a-zA-Z0-9_]+))?)/g;
export const OtherFormFieldPropsPreg = /^\$form\[([a-zA-Z0-9_]+)\]\./;
