import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { licenseKeyAPI } from 'api/liceseKey';
import { Popup } from 'components/common/popup/Popup';
import { Alert, Box, FormControl, FormLabel, TextField, alpha } from '@mui/material';
import { useIsAdmin } from 'hooks/useUserPrivilege';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

interface LicensePopupProps {
    response: any;
    onHide: () => void;
}
export function LicensePopup(props: LicensePopupProps) {
    const { t } = useBundleTranslation(['components/common/license_popup']);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [type, setType] = useState<string>('update_license');
    const [licenseVal, setLicenseVal] = useState<string>('');
    const [popupSettings, setPopupSettings] = useState<any>({
        title: '',
        okBtn: '',
        text: '',
    });

    const isAdmin = useIsAdmin();

    useEffect(() => {
        let keyType = 'update_license';
        let textVariable = '';

        if (!props.response?.success && !isAdmin) {
            keyType = 'not_success';
        } else if (props.response.data?.expired == 1 && !isAdmin) {
            keyType = 'expired';
        } else if (props.response.data?.trial) {
            keyType = 'trial';
            const expiresDays = props.response.data?.expiresDays == 0 ? 1 : props.response.data?.expiresDays;
            const dayLabel = t(`day`, { count: props.response.data?.expires == 1 ? 1 : 2 });
            textVariable = expiresDays + '' + dayLabel;
        }

        setPopupSettings({
            title: t(`${keyType}.title`),
            okBtn: t(`${keyType}.okBtn`),
            text: t(`${keyType}.text`, { daysInfo: textVariable }),
        });
        setType(keyType);
    }, []);

    return (
        <Popup
            maxWidth={'popupMd'}
            open={true}
            settings={{
                title: popupSettings.title,
                textOK: popupSettings.okBtn,
            }}
            onHide={() => {
                props.onHide();
            }}
            onConfirm={() => {
                if (isAdmin && type != 'trial') {
                    setErrorMsg('');
                    setIsLoading(true);
                    licenseKeyAPI
                        .updateLicense(licenseVal)
                        .then((response) => {
                            if (response.data?.status == 'ERROR') {
                                setErrorMsg(response.data?.message);
                            } else {
                                props.onHide();
                            }
                            setIsLoading(false);
                        })
                        .catch(() => {});
                } else {
                    props.onHide();
                }
            }}
        >
            {isLoading && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                        color: (theme) => alpha(theme.palette.text.primary, 0.4),
                    }}
                />
            )}
            {errorMsg && (
                <Box sx={{ mb: 1.5 }}>
                    <Alert severity={'error'} variant={'standard'}>
                        {errorMsg}
                    </Alert>
                </Box>
            )}
            <FormControl fullWidth>
                <FormLabel>{popupSettings.text}</FormLabel>
                {isAdmin && type != 'trial' && (
                    <TextField
                        value={licenseVal}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLicenseVal(event.target.value);
                        }}
                    />
                )}
            </FormControl>
        </Popup>
    );
}
