import { FormComponentValue } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import { instance } from 'api/api';
import { prepareFormData } from 'tools/tools';
import React, { useEffect, useRef } from 'react';
import { Popup } from 'components/common/popup/Popup';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { Trans } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { APIResponse, AssocArray } from 'tools/types';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box } from '@mui/material';

export interface FileUploadResponse {
    filename: string;
}

export default function UploadCustomImagePopup({
    onHide,
    onConfirm,
    segmentValuesList,
    segmentId,
    elementId,
    onFilePostResponse,
    generic,
    uploadUrl,
}: {
    onHide: () => void;
    onConfirm: () => void;
    segmentValuesList: Array<FormComponentValue>;
    segmentId: number;
    elementId: number;
    onFilePostResponse: (response: APIResponse<FileUploadResponse>) => void;
    generic: boolean;
    uploadUrl: string;
}) {
    const { t } = useBundleTranslation(['components/common/element_image']);
    const defaultValues: AssocArray<any> = {
        sv_id: segmentValuesList.length ? segmentValuesList[0].value : 0,
    };

    if (generic) {
        defaultValues.generic = 'Y';
    }

    const elementProps = useCustomSimplifiedForm(defaultValues);

    const onSubmit = (data: any) => {
        return instance
            .post(uploadUrl, prepareFormData(data, elementProps.form), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response: AxiosResponse<APIResponse<FileUploadResponse>>) => {
                onFilePostResponse(response.data);
                onConfirm();
            });
    };

    useEffect(() => {
        //@ts-ignore
        elementProps.form.addElementValuesRegister(segmentValueProps, null, null, null);
        //@ts-ignore
        elementProps.form.addElementValuesRegister(uploadImageProps, null, null, null);
    }, []);

    const segmentValueProps = prepareFormElementProps({
        ...elementProps,
        component: {
            uid: 'segment_value_id',
            name: 'segment_value_id',
            component: 'FormSelect',
            props: { addonLabel: t('dimension') },
        },
    });

    const uploadImageProps = prepareFormElementProps({
        ...elementProps,
        component: {
            uid: 'image_file',
            name: 'image_file',
            component: 'UploadImage',
            props: { maxFileSizeMB: 5 },
        },
    });

    const submitRef = useRef(null);

    return (
        <Popup
            settings={{
                title: t('upload_image'),
                textOK: t('save'),
            }}
            onHide={onHide}
            open={true}
            onConfirm={() => {
                //@ts-ignore
                submitRef.current.click();
            }}
        >
            <form onSubmit={elementProps.form.hookFormHandleSubmit(onSubmit)}>
                {segmentId > 0 && (
                    <ReactHookFormController componentValues={segmentValuesList} elementProps={segmentValueProps} />
                )}

                <ReactHookFormController elementProps={uploadImageProps} />
                <Box sx={{ mt: 2 }}>
                    <Trans t={t} i18nKey="upload_image_restriction" components={{ br: <br /> }} />
                </Box>
                <button style={{ display: 'none' }} ref={submitRef} type={'submit'}></button>
            </form>
        </Popup>
    );
}
