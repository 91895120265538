import React from 'react';
import { RenderParams } from '@minoru/react-dnd-treeview/dist/types';
import { Box, IconButton, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { NodeData } from './index';
import { NodeModel } from '@minoru/react-dnd-treeview';

export interface TreeNodeProps<N, T> {
    node: N;
    params: RenderParams;
    additional: T;
}

//todo: implement logic for icons fetching
export function getFolderIcon(params: RenderParams) {
    let iconName = 'folder';

    if (params.hasChild) {
        iconName = `${iconName}-${params.isOpen ? 'minus' : 'plus'}`;
    }

    return <IconMi icon={iconName} fontSize="16" />;
}

export function getElementIcon() {
    return <IconMi icon="bar-chart" fontSize="16" />;
}

export const DefaultNode = ({ node, params }: TreeNodeProps<NodeModel<NodeData>, null>) => {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            style={{ minHeight: '28px' }}
            spacing={1}
            className="tree-mi__item-content"
        >
            <Stack direction="row" alignItems="center" spacing="6px" flexGrow={1}>
                {node.droppable && node.data?.type === 'folder' && (
                    <IconButton sx={{ p: 0 }} onClick={params.onToggle}>
                        {getFolderIcon(params)}
                    </IconButton>
                )}
                {node.data?.type === 'element' && getElementIcon()}
                <Box component="span">{node.text}</Box>
            </Stack>
        </Stack>
    );
};
