import { instance } from 'api/api';
import { APIResponse, YNValues } from 'tools/types';
import { ReferenceInfoResponse } from 'components/external-reference-selection';
import { ExternalFilterEntityType } from 'app/editor/external-filters';

interface ReferenceExternalFiltersData {
    external_filter_autoload_ind: YNValues;
}
interface ReferenceFiltersList {
    filters: Array<ExternalFilterEntityType>;
}

export const editorExternalReferenceAPI = {
    async setAutoLoad(
        connectionProfileId: number,
        referenceId: number,
        value: boolean,
    ): Promise<APIResponse<ReferenceInfoResponse>> {
        const YNValue = value ? 'Y' : 'N';
        const response = await instance.post<APIResponse<ReferenceInfoResponse>>(
            `/data/editor/reference/profile/${connectionProfileId}/reference/${referenceId}/value/${YNValue}/set-autoload-value`,
        );
        return response.data;
    },

    async refreshFilters(
        connectionProfileId: number,
        referenceId: number,
    ): Promise<APIResponse<ReferenceInfoResponse>> {
        const response = await instance.post<APIResponse<ReferenceInfoResponse>>(
            `/data/editor/reference/profile/${connectionProfileId}/reference/${referenceId}/refresh-auto-filters`,
        );
        return response.data;
    },

    async refreshFilterValues(
        connectionProfileId: number,
        referenceId: number,
        filterId: number = 0,
    ): Promise<APIResponse<ReferenceInfoResponse>> {
        const response = await instance.post<APIResponse<ReferenceInfoResponse>>(
            `/data/editor/external-reference/profile/${connectionProfileId}/reference/${referenceId}/filter/${filterId}/refresh-auto-filter-values`,
        );
        return response.data;
    },

    async getExternalAutoloadInd(connectionProfileId: number, referenceId: number): Promise<YNValues> {
        // const url = '/data/mock/editor/external-reference-filter/external-autoload-ind';
        const url = `/data/editor/reference/profile/${connectionProfileId}/reference/${referenceId}/get-autoload-value`;
        const response = await instance.get<APIResponse<ReferenceExternalFiltersData>>(url);
        if (response.data.status == 'OK') {
            return response.data.data.external_filter_autoload_ind;
        }
        return 'N';
    },

    async getFilters(elementId: number, referenceId: number): Promise<Array<ExternalFilterEntityType>> {
        const url = `/data/editor/external-reference-filter/get-filters/reference/${referenceId}/element/${elementId}`;
        const response = await instance.get<APIResponse<ReferenceFiltersList>>(url);
        return response.data.data.filters;
    },
};
