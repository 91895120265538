import IconMi from 'components/common/icon/IconMi';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import DerivedPopup from './DerivedPopup';
import { DerivedFieldData } from '../../index';

function DerivedField({
    onCreateDerived,
    datasetId,
    names,
    json,
    fieldsLabels,
}: {
    onCreateDerived: (data: DerivedFieldData) => void;
    datasetId: number;
    names: string[];
    fieldsLabels: string[];
    json: string;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const [showPopup, setShowPopup] = useState(false);

    return (
        <>
            <Button
                data-test={'add-derived-field-button'}
                variant={'light'}
                sx={{ flexShrink: 0 }}
                startIcon={<IconMi icon={'new'} />}
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                {t('fields_selector.derived_field')}
            </Button>
            {showPopup && (
                <DerivedPopup
                    show={showPopup}
                    setShowPopup={setShowPopup}
                    onConfirmPopup={onCreateDerived}
                    datasetId={datasetId}
                    names={names}
                    json={json}
                    fieldsLabels={fieldsLabels}
                />
            )}
        </>
    );
}

export default DerivedField;
