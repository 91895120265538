import { IconButton, Grid, Tooltip, Box, alpha } from '@mui/material';
import React, { useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { createPortal } from 'react-dom';

export default function PreviewCell({ value }: CustomCellProps) {
    const t = useBundleTranslation('main')['t'];
    const [open, setOpen] = useState<boolean>(false);
    //@ts-ignore
    const [elementId, segmentValueId]: [string, string] = value?.split('_');
    const iconRef = React.useRef<HTMLElement>(null);
    const [offset, setOffset] = useState([0, 0]);
    const handleOpen = () => {
        setOpen(true);
        if (iconRef.current) {
            const box = iconRef.current?.getBoundingClientRect();
            setOffset([box.top, box.left]);
        }
    };

    return (
        <Grid container sx={{ height: 1, alignItems: 'flex-end', justifyContent: 'right', paddingRight: '12px' }}>
            {open &&
                createPortal(
                    <Box
                        sx={{
                            width: '470px',
                            height: '260px',
                            position: 'absolute',
                            backgroundColor: (theme) => theme.palette.background.default,
                            color: (theme) => theme.palette.text.primary,
                            zIndex: 99999,
                            top: offset[0] + 20,
                            left: offset[1] + 20,
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute',
                                textAlign: 'center',
                                width: '100%',
                                top: '115px',
                                zIndex: -1,
                            }}
                        >
                            {t('loading___')}
                        </span>
                        <img
                            width={'100%'}
                            height={'100%'}
                            src={`/data/content/index/preview/element/${elementId}/segment/${segmentValueId}`}
                        />
                    </Box>,
                    document.body,
                )}
            <IconButton
                onMouseEnter={() => handleOpen()}
                onMouseLeave={() => setOpen(false)}
                size={'small'}
                sx={{ color: 'text.primary' }}
            >
                <span ref={iconRef}>
                    <IconMi icon={'view'} fontSize={'16'} />
                </span>
            </IconButton>
        </Grid>
    );
}
