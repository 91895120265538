import { MutableRefObject, useState } from 'react';
import { AssocArray } from 'tools/types';
import { FormElementProps, RegisteredComponentType } from 'components/common/form/index';
import { FormComponentValue } from 'components/common/form/layout/control';
import { logWarning } from 'tools/tools';

export default function useFormElementRegister() {
    // On FormElement mount will store refs to element computed Props and Values
    const [elementsRegister, setElementsRegister] = useState<AssocArray<RegisteredComponentType>>({});
    const addElementValuesRegister = (
        elementProps: FormElementProps,
        processedValuesRef: MutableRefObject<Array<FormComponentValue>>,
        componentPropsRef: MutableRefObject<any>,
        componentStatePropsRef: MutableRefObject<any>,
    ) => {
        const elementName = elementProps?.component?.name;
        if (typeof elementName == 'undefined' || !elementName) {
            return;
        }
        const newState: AssocArray<RegisteredComponentType> = { ...elementsRegister };
        const newObject = {
            name: elementName,
            elementProps: elementProps,
            valuesRef: processedValuesRef,
            componentPropsRef: componentPropsRef,
            componentStatePropsRef: componentStatePropsRef,
        };
        newState[elementName] = { ...newObject };
        elementsRegister[elementName] = { ...newObject };
        setElementsRegister(newState);
    };
    const getElementValuesRegister = (fieldName: string): RegisteredComponentType | null => {
        if (typeof elementsRegister[fieldName] == 'undefined') {
            logWarning(`Form: Try to get ValueProperty of unknown field ${fieldName}`);
            return null;
        }
        return elementsRegister[fieldName];
    };

    return { addElementValuesRegister, getElementValuesRegister };
}
