import { instance } from './api';
import { APIResponse, YNValues } from '../tools/types';

const MY_NOTIFICATION_URL = `/data/notification/favorites`;

export interface GetNotificationStatus {
    emailNotificationStatus: YNValues;
    emailsPopupHintData: {
        default: string | null;
        notification: string | null;
        digest: string | null;
        immediate: string | null;
        immediate_sms: string | null;
        expired: string | null;
    };
}

interface GetNotificationStatusResponse {
    status: 'OK' | 'ERROR';
    data: GetNotificationStatus;
}

export const myNotificationAPI = {
    async getStatusInfo(): Promise<GetNotificationStatusResponse> {
        const url = MY_NOTIFICATION_URL + '/get-email-notification-status-info/';
        const response = await instance.get(url);

        return response.data;
    },
    async changeStatus(status: YNValues): Promise<APIResponse<any>> {
        const url = MY_NOTIFICATION_URL + '/change-email-notification-status/' + status;
        const response = await instance.get(url);

        return response.data;
    },
};
