import { createSlice } from '@reduxjs/toolkit';

export interface breadcrumbItem {
    href?: string;
    label: string;
    translate?: boolean;
}

interface breadcrumbsSliceState {
    items: breadcrumbItem[] | null;
    currentElement: breadcrumbItem | null;
    instanceDataKey: string | null;
}

export const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState: {
        items: [],
        currentElement: null,
        instanceDataKey: null,
    } as breadcrumbsSliceState,
    reducers: {
        setBreadcrumbs: (state, action) => {
            state.items = action.payload;
        },
        setBreadcrumbsCurrentElement: (state, action) => {
            state.currentElement = action.payload ? { label: action.payload } : null;
        },
        resetBreadcrumbs: (state) => {
            state.items = [];
            state.currentElement = null;
        },
        removeBreadcrumbs: (state) => {
            state.items = null;
            state.currentElement = null;
        },
        setBreadcrumbsInstanceKey: (state, action) => {
            state.instanceDataKey = action.payload;
        },
    },
});

export const {
    setBreadcrumbs,
    resetBreadcrumbs,
    removeBreadcrumbs,
    setBreadcrumbsCurrentElement,
    setBreadcrumbsInstanceKey,
} = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
