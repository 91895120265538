import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadFile, { FormControlUploadFileProps } from './UploadFile';
import { FormElementControlPropsType } from 'components/common/form/layout/control';

export interface FormControlUploadImageProps extends FormControlUploadFileProps {
    useBase64Preview?: boolean;
}

export default function UploadImage({ controlProps: props }: FormElementControlPropsType<FormControlUploadImageProps>) {
    const {
        hidePreview = false,
        previewType = 'image',
        accept = 'image/*',
        sx,
        useBase64Preview,
        previewsList = [],
        onChange = () => {},
        previewsPlaceholder,
    } = props;

    const [previewImages, setPreviewImages] = useState<string[]>([]);
    const [files, setFiles] = useState<any>();

    const buildPreview = () => {
        if (files?.length >= 0) {
            const previewUrls = [];
            for (let i = 0; i < files.length; i++) {
                previewUrls.push(URL.createObjectURL(files[i]));
            }
            if (previewsPlaceholder && !previewUrls.length) {
                setPreviewImages([previewsPlaceholder]);
            } else {
                setPreviewImages(previewUrls);
            }

            return false;
        } else {
            let defaultValue = props.form.hookFormGetValues(props.name);
            if (
                !defaultValue ||
                !Array.isArray(defaultValue) ||
                (Array.isArray(defaultValue) && defaultValue.length === 0)
            ) {
                defaultValue = previewsList;
            }
            if (defaultValue.length > 0) {
                setPreviewImages(defaultValue);
            } else {
                if (previewsPlaceholder) setPreviewImages([previewsPlaceholder]);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFiles(event?.target?.files ?? []);
        onChange(event);
    };

    useEffect(() => {
        if (!hidePreview) buildPreview();
    }, [hidePreview, files]);

    useEffect(() => {
        if (useBase64Preview && props.value) {
            setPreviewImages([`data:image/png;base64,${props.value}`]);
        }
    }, []);

    const elementProps = { ...props };
    elementProps.onChange = handleChange;
    elementProps.sx = {};

    return (
        <Stack sx={sx} direction="row" className={'upload-image-holder'}>
            {!hidePreview && previewType === 'image' && previewImages.length > 0 && (
                <Stack direction="row" spacing={0.5} sx={{ mr: '8px' }} className={'preview-holder'}>
                    {previewImages.map((imageSrc, index) => {
                        return (
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '28px',
                                    height: '28px',
                                    img: {
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    },
                                }}
                                className="upload-image-preview-holder"
                            >
                                <img
                                    src={imageSrc}
                                    alt=""
                                    className="upload-image-preview"
                                    key={`${index}_${files?.[index]?.name ?? ''}`}
                                />
                            </Box>
                        );
                    })}
                </Stack>
            )}

            <UploadFile controlProps={elementProps} />
        </Stack>
    );
}
