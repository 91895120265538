import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import { escapeHtml } from 'components/report-content/utils/tools';
import { ReportTooltipProps } from 'components/report-content/tooltip';
import DrillToTooltipPart, { TooltipDrillData } from 'components/report-content/tooltip/DrillToTooltipPart';
import { Box, Stack } from '@mui/material';
import styles from 'app/home/tile/components/info-panel/styles';
import ReportChartPoint = ReportContentNS.ReportChartPoint;

interface BubbleChartPoint extends ReportChartPoint {
    x_f?: string;
    y_f?: string;
    v_f?: string;
}

export default function BubbleChartTooltip({
    point,
    settings,
    contentSettings,
    closeComponent,
}: ReportTooltipProps<ComponentSettingsBubbleChart>) {
    const bubblePoint: BubbleChartPoint = point as BubbleChartPoint;
    if (!bubblePoint?.x_f) {
        return null;
    }

    const drillData: Array<TooltipDrillData> = [
        {
            field: settings.name_field.reference_name_escaped,
            value: point.name,
        },
        {
            field: settings.y_field.reference_name_escaped,
            value: String(point.y),
        },
        {
            field: 'snapshot_date',
            value: contentSettings.measurement_time ?? '',
        },
    ];

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                justifyContent={'space-between'}
                sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0, paddingTop: 0, height: 29 }}
            >
                <Stack direction={'row'}>
                    <Box dangerouslySetInnerHTML={{ __html: point.name }}></Box>
                </Stack>
                <Box>{closeComponent}</Box>
            </Stack>
            <Stack direction="column" sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0 }}>
                <Stack direction={'row'}>
                    <Box>{escapeHtml(settings.x_field.column_name)}:</Box>
                    <Box>{bubblePoint.x_f}</Box>
                </Stack>
                <Stack direction={'row'}>
                    <Box>{escapeHtml(settings.y_field.column_name)}:</Box>
                    <Box>{bubblePoint.y_f}</Box>
                </Stack>
                <Stack direction={'row'}>
                    <Box>{escapeHtml(settings.value_field.column_name)}:</Box>
                    <Box>{bubblePoint.v_f}</Box>
                </Stack>
            </Stack>
            <DrillToTooltipPart contentSettings={contentSettings} data={drillData} settings={settings} />
        </Stack>
    );
}
