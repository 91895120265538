import { AssocArray } from 'tools/types';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import { escapeHtml } from 'components/report-content/utils/tools';

export interface PieChartSeriesRow {
    name: string;
    color?: string;
    y: number;
    key: {
        default_color?: string;
        name_f: string;
        y_f: number;
    };
    value_position: number;
}

export default function prepareDataForSeries(
    settings: ComponentSettingsPieChart,
    data: AssocArray<any>,
    getPaletteColor: (name: string, index: number, getDefaultColor: boolean) => string | undefined,
) {
    const result: Array<PieChartSeriesRow> = [];
    for (const [k, v] of Object.entries(data)) {
        const y = parseFloat(v[settings.measureFields[0].reference_name]);
        // @ts-ignore
        result.push({
            name: escapeHtml(v[settings.group_field]),
            // color: self.getPaletteColor(data[j][component.settings.group_field], j, paletteColors),
            color: getPaletteColor(v[settings.group_field], Number(k), false),
            y: isNaN(y) ? 0 : y,
            key: {
                default_color: getPaletteColor(v[settings.group_field], Number(k), true),
                name_f: v[settings.group_field + '-formatted'] ?? escapeHtml(v[settings.group_field]),
                y_f:
                    v[settings.measureFields[0].reference_name + '-formatted'] ??
                    escapeHtml(v[settings.measureFields[0].reference_name]),
            },
            value_position: Number(k),
        });
    }
    return result;
}
