import { CustomCellProps } from 'components/common/grid';
import { Grid, IconButton, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import LightTooltip from 'components/common/tooltip/LightTooltip';
import Highlighter from 'react-highlight-words';

export default function ContentCell({ value, filtersValue }: CustomCellProps) {
    const wordsToHighlight =
        filtersValue && filtersValue['pattern'] ? String(filtersValue['pattern']).split(/ |,/) : [];

    const content = value ? (
        <Typography>
            <Highlighter searchWords={wordsToHighlight} textToHighlight={value.replace(/\&nbsp;/g, ' ')} />
        </Typography>
    ) : null;

    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'end' }}>
            <LightTooltip title={content} arrow>
                <IconButton size={'small'} sx={{ color: 'primary.main', '&:hover': { opacity: 0.75 } }}>
                    <IconMi icon={'info-circle'} />
                </IconButton>
            </LightTooltip>
        </Grid>
    );
}
