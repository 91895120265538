import { Box } from '@mui/material';
import { FormElementControlPropsType, FormControlProps } from './index';
import FormHidden from './FormHidden';
import useBundleTranslation from 'i18n';
import React from 'react';
import { FormComponentBuilder } from 'components/common/form/layout/index';

export interface FormControlDescriptionProps extends FormControlProps {}

export default function FormDescription({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlDescriptionProps>) {
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);
    const label = controlProps.translate ? t(controlProps.label as string) : controlProps.label;

    return (
        <>
            <FormHidden controlProps={controlProps} />
            <Box className={controlProps.labelClass}>{label}</Box>
            <Box className={controlProps.controlClass} dangerouslySetInnerHTML={{ __html: controlProps.value }}></Box>
        </>
    );
}

export class FormDescriptionManagerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlDescriptionProps {
        return {
            ...this.controlProps,
        };
    }
}
