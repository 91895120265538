import { FilterType } from 'components/external-reference/Filter';
import { BookmarkFilterType, BookmarkType } from './BookmarkDropdown';
import { Fragment } from 'react';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';

export function applyBookmarkToFilters(filters: Array<FilterType>, bookmark: BookmarkType): Array<FilterType> {
    const result = filters.slice().map((f) => {
        const filter = Object.assign({}, f);
        filter.selectedValues = [];
        return filter;
    });
    bookmark.filtersList.forEach((bookmarkFilter) => {
        const filter = result.find((filter) => filter.filterId == bookmarkFilter.filterId);
        if (!filter) {
            return;
        }
        filter.selectedValues = bookmarkFilter.selectedValues;
    });
    return result;
}

export function processFiltersForBookmark(filters: Array<FilterType>): Array<BookmarkFilterType> {
    return filters.map((filter) => ({
        filterId: filter.filterId,
        values: filter.values.filter((v) => filter.selectedValues.includes(v.value_id)),
        selectedValues: filter.selectedValues,
        filterInput: filter.filterInput,
    }));
}

export function buildBookmarkName(
    bookmark: BookmarkType,
    filters: Array<FilterType>,
    siblingTabs: Array<SiblingTabType>,
    ignoreCustomName: boolean = false,
) {
    const findFilterById = function (filterId: number): FilterType | undefined {
        return filters.find((filter: any) => filter.filterId == filterId);
    };

    const isEmpty = function (bookmark: BookmarkType): boolean {
        let result = true;
        bookmark.filtersList.forEach(function (bookmarkFilter) {
            const filter = findFilterById(bookmarkFilter.filterId);
            if (!filter) {
                return;
            }
            if (bookmarkFilter.selectedValues.length && !filter.replacedBySegment) {
                result = false;
            }
        });
        return result;
    };

    if (bookmark.name && !ignoreCustomName) {
        return bookmark.name;
    }

    let result: any = [];
    if (bookmark.bookmarkData) {
        bookmark.bookmarkData.forEach(function (row) {
            if (row.dataClass == 'TabReference') {
                if (siblingTabs.length) {
                    result.push(
                        <Fragment key={'reference_' + row.data.referenceId}>
                            {bookmark.bookmarkType != 'reference' && <strong>Tab: </strong>}
                            <span>{row.data.title}</span>
                        </Fragment>,
                    );
                }
            }
        });
    }

    if (isEmpty(bookmark) && bookmark.bookmarkType != 'reference') {
        const noFiltersText = 'No Filters Applied';
        if (result.length == 0) {
            return noFiltersText;
        }
        result.push('; ' + noFiltersText);
    }

    bookmark.filtersList.forEach(function (bookmarkFilter) {
        const filter = findFilterById(bookmarkFilter.filterId);
        if (!filter) {
            return;
        }

        if (
            (bookmarkFilter.values.length == 0 &&
                bookmarkFilter.filterInput != 'text' &&
                bookmarkFilter.filterInput != 'date') ||
            filter.replacedBySegment
        ) {
            return;
        }

        let title = '';
        if (bookmarkFilter.filterInput == 'date') {
            if (
                bookmarkFilter.selectedValues.length > 0 &&
                //@ts-ignore
                typeof bookmarkFilter.selectedValues[0]?.label != 'undefined'
            ) {
                //@ts-ignore
                title = bookmarkFilter.selectedValues[0]?.label ?? '';
            } else if (bookmarkFilter.values.length > 0) {
                //@ts-ignore
                title = bookmarkFilter.values[0]?.display_name ?? '';
            }
        } else {
            title =
                bookmarkFilter.filterInput == 'text'
                    ? bookmarkFilter.selectedValues.join()
                    : bookmarkFilter.values.map((v) => v.display_value ?? v.value).join(', ');
        }

        if (title.length == 0) {
            return;
        }

        result.push(
            <Fragment key={filter.filterId}>
                <strong> {filter.title} : </strong>
                <span>{title}</span>
            </Fragment>,
        );
    });
    return result;
}

export function getNewBookmarkObj(bookmark?: BookmarkType): BookmarkType {
    const result = {
        id: 0,
        name: '',
        displayOrder: 0,
        segmentValueId: 0,
        parentSegmentValueId: 0,
        segmentId: 0,
        filtersList: [],
        bookmarkData: [],
        type: 'bookmark',
    } as BookmarkType;

    if (bookmark) {
        Object.assign(result, bookmark);
        result.filtersList = bookmark.filtersList.map((filter) => Object.assign({}, filter));
        result.bookmarkData = structuredClone(bookmark.bookmarkData);
    }

    return result;
}

export function getBookmarkReferenceId(bookmark: BookmarkType) {
    const referenceRow = bookmark.bookmarkData.find((d) => d.dataClass == 'TabReference');
    return referenceRow ? referenceRow.data.referenceId : 0;
}

export function setBookmarkReferenceId(bookmark: BookmarkType, siblingTab: SiblingTabType | false) {
    if (!siblingTab) {
        bookmark.bookmarkData = [];
    } else {
        bookmark.bookmarkData[0] = {
            data: siblingTab,
            dataClass: 'TabReference',
        };
    }
}
