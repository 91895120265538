import { FormLabel, IconButton, Input, Stack } from '@mui/material';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FormComponentBuilder } from 'components/common/form/layout';
import IconMi from 'components/common/icon/IconMi';

export default function InternalName({ controlProps }: FormElementControlPropsType<FormControlProps>) {
    const { t } = useBundleTranslation(['app/editor/page']);

    const [isManualValue, setIsManualValue] = useState(true);
    const [nameValue, setNameValue] = useState(controlProps.form.hookFormGetValues('name'));
    const [internalNameValue, setInternalNameValue] = useState(controlProps.form.hookFormGetValues('internal_name'));

    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name === 'name') {
                setNameValue(value['name']);
            }
            if (name === 'internal_name') {
                setInternalNameValue(value['internal_name']);
            }
        });

        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    useEffect(() => {
        const calculatedInternalName = convertToInternalName(nameValue);

        setIsManualValue(internalNameValue !== calculatedInternalName);
    }, []);

    const convertToInternalName = (name: string) => {
        return name.toLowerCase().replace(/ /g, '_');
    };

    useEffect(() => {
        if (!isManualValue) {
            controlProps.form.hookFormSetValue('internal_name', convertToInternalName(nameValue), {
                shouldDirty: true,
            });
        }
    }, [nameValue]);

    const onRevert = () => {
        controlProps.form.hookFormSetValue('internal_name', convertToInternalName(nameValue), {
            shouldDirty: true,
        });
        setIsManualValue(false);
    };

    return (
        <>
            <Stack direction={'column'} key={controlProps.uid + '-change-template'}>
                <FormLabel>{t(controlProps.label as string)}</FormLabel>
                <Stack direction={'row'} spacing={1}>
                    <Input
                        data-test={`page-internal-name`}
                        value={internalNameValue}
                        onChange={(event) => {
                            controlProps.form.hookFormSetValue('internal_name', event.target.value, {
                                shouldDirty: true,
                            });
                            setIsManualValue(true);
                        }}
                        fullWidth
                    />
                    {isManualValue && (
                        <IconButton size={'small'} onClick={onRevert}>
                            <IconMi icon="refresh-cw" fontSize="16" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </>
    );
}

export class InternalNameBuilder extends FormComponentBuilder {
    prepareProps(): FormControlProps {
        return {
            ...this.controlProps,
        };
    }
}
