import { Chart as HChart, PointOptionsObject } from 'highcharts';
import { SeriesOptionsType } from 'highcharts/highstock';
import { ChartPoint, ChartSeriesType, DATA_X_MULTIPLIER } from 'components/metric/chart/index';

import { AdditionalToDrawType, drawNumberOnIcon, getSVGIconsGroup } from 'components/metric/chart/draw/drawRanges';

import { ChartPointAnnotation } from 'components/metric/chart/series/getAnnotationSeries';
import { findPointsInPeriod } from 'components/metric/chart/seriesBuilder';
import { ChartSVGPointEvents } from 'components/metric/chart/MetricChartWrapper';

export const SVG_GROUP_ANNOTATION_ICONS = 'dGroupAnnotationIcons';

export default function chartRedrawAnnotations(
    chart: HChart,
    mainSeriesRef: any,
    additional: AdditionalToDrawType,
    svgIconsEvents: ChartSVGPointEvents,
): AdditionalToDrawType {
    const annotationSeries = chart.series.find((s) => {
        return (s.userOptions as SeriesOptionsType & ChartSeriesType).seriesType == 'annotation';
    });

    if (!annotationSeries || !mainSeriesRef.current) {
        return additional;
    }

    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_ANNOTATION_ICONS);
    if (!annotationSeries.visible) {
        //@ts-ignore
        iconsGroup.classList.add('d-none');
        return additional;
    }
    //@ts-ignore
    iconsGroup.classList.remove('d-none');

    const actualAnnotationsList = annotationSeries.data.map((point) => {
        const options = point.options as PointOptionsObject & ChartPointAnnotation;

        let minIconNumber = -1;
        additional.points.forEach((p) => {
            if (p.x != point.x) {
                return;
            }
            const i = (p.point.options as PointOptionsObject & ChartPoint)?.iconNumber ?? -1;
            if (i > minIconNumber) {
                minIconNumber = i;
            }
        });

        return {
            ...point,
            metadata: options?.metadata ?? null,
            iconSvg: options.iconSvg,
            iconNumber: minIconNumber + 1 + options.iconNumber,
        } as ChartPointAnnotation;
    });

    actualAnnotationsList.forEach((annotationPoint) => {
        let position = (annotationPoint.plotX ?? 0) - 2;
        //@ts-ignore
        const plotOffset = chart?.axisOffset?.[3] ?? 0;
        // console.log(annotationPoint.options);
        if (annotationPoint.rangeEnd == 0) {
            const y = (annotationPoint?.plotY ?? 0) - annotationPoint.iconNumber * 20;
            if (Number(annotationPoint.plotX ?? 0) < 0) {
                position = -10000;
            }
            manageAnnotationSVG(chart, annotationPoint, position + plotOffset, y, svgIconsEvents);
            additional.points.push({
                type: 'annotation',
                x: annotationPoint.x,
                y: chart.yAxis[0].toValue(y),
                point: annotationPoint,
            });
        } else {
            const dataPoints = mainSeriesRef.current[0].data.map((d: ChartPoint) => {
                let y = d.y ?? 0;
                additional.ranges.forEach((range) => {
                    if (range.x <= d.x && range.x1 >= d.x) {
                        y = range.y > y ? range.y : y;
                    }
                });
                return { ...d, y: y };
            });

            const eventEnd = annotationPoint.rangeEnd * DATA_X_MULTIPLIER;
            const points = findPointsInPeriod(chart, dataPoints, annotationPoint.x, eventEnd);

            const newY = chart.yAxis[0].toPixels(Math.max(points[0].y, points[1].y), false) - 12;

            additional.ranges.push({
                type: 'annotation',
                x: annotationPoint.x,
                x1: annotationPoint.rangeEnd * DATA_X_MULTIPLIER,
                y: chart.yAxis[0].toValue(newY),
                point: annotationPoint,
                leftBorder: true,
                rightBorder: true,
            });
        }
    });

    return additional;
}

export const manageAnnotationSVG = (
    chart: HChart,
    annotationPoint: ChartPointAnnotation,
    plotX: number,
    plotY: number,
    svgIconsEvents?: ChartSVGPointEvents,
) => {
    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_ANNOTATION_ICONS);
    const pointId = annotationPoint.pointId;
    let svg = document.getElementById(pointId);
    if (isNaN(plotY)) {
        plotY = 0;
    }

    const getEventPoint = (svg: HTMLElement): ChartPointAnnotation => {
        const x = Number(svg.getAttribute('x'));
        const y = Number(svg.getAttribute('y')) - 18;
        return { ...annotationPoint, plotX: x, plotY: y } as ChartPointAnnotation;
    };

    if (!svg) {
        svg = annotationPoint.iconSvg.cloneNode(true) as HTMLElement;
        //@ts-ignore
        iconsGroup.append(svg);
        svg.setAttribute('id', pointId);
        svg.append(drawNumberOnIcon(chart, annotationPoint.metadata.title, -3));
        if (svgIconsEvents) {
            svg.addEventListener('mouseover', function (event: any) {
                svgIconsEvents.mouseOver('annotation', getEventPoint(this));
            });
            svg.addEventListener('click', function () {
                svgIconsEvents.click('annotation', getEventPoint(this));
            });
        }
    }

    svg.setAttribute('x', String(plotX + 2));
    svg.setAttribute('y', String(plotY));
};
