import React, { useContext, useEffect } from 'react';
import { FormRendererAPIType } from 'components/common/form/index';
import { AssocArray } from 'tools/types';

export type FormsContextType = AssocArray<FormRendererAPIType>;

export const FormsContext = React.createContext<FormsContextType>({});

export default function useFormContext(formUid: string, form: FormRendererAPIType) {
    const formsContext = useContext<FormsContextType>(FormsContext);
    useEffect(() => {
        formsContext[formUid] = form;
        return () => {
            delete formsContext[formUid];
        };
    }, []);

    return formsContext;
}
