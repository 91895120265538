import { instance } from 'api/api';
import { APIResponse } from 'tools/types';

export interface ElementColumn {
    column_name: string;
    data_type?: string;
}

export const editorSourceReportAPI = {
    async getSourceReportElements(src: string): Promise<[string, string][]> {
        const response = await instance.get<APIResponse<[string, string][]>>('/data/editor/list-tables', {
            params: { src: src },
        });
        if (response.data.status == 'ERROR') {
            return [];
        }
        return response.data.data;
    },
    async getSourceReportColumns(src: string, tableName: string): Promise<Array<ElementColumn>> {
        const response = await instance.get<APIResponse<Array<ElementColumn>>>('/data/editor/list-table-columns', {
            params: { src: src, table: tableName },
        });

        if (response.data.status == 'ERROR') {
            return [];
        }
        return response.data.data;
    },
};
