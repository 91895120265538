import { alpha, Theme } from '@mui/material';

export default {
    preview: {
        position: 'relative',
        marginBottom: '20px',
    },
    previewImage: {
        marginTop: '5px',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
        overflow: 'hidden',
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        img: {
            maxWidth: '100%',
            display: 'block',
        },
    },
    thumbnail: {
        position: 'absolute',
        right: '-190px',
        top: 0,
    },
    actions: {
        marginTop: '20px',
    },
    restriction: {
        paddingTop: '4px',
    },
    sliderBox: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '80%',
    },
    sliderValue: {
        fontWeight: 'bold',
        paddingLeft: '10px',
    },
};
