import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { Popup } from 'components/common/popup/Popup';
import { openInNewTab } from 'tools/tools';
import { getCellWithTooltip, getIcon } from './index';

export default function LinkCell({
    text,
    href,
    tooltip,
    icon,
    confirmation,
    popupConfig,
    newTab,
    t,
    rawTooltipHtml,
    align,
    rawHtml,
}: CustomCellProps) {
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const processedText = rawHtml && text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text;

    const openHref = () => {
        if (confirmation) {
            setOpen(false);
        }
        if (href) {
            if (newTab) {
                openInNewTab(href);

                return;
            }

            navigate(href);
        }
    };

    const onLinkClick = () => {
        if (confirmation) {
            setOpen(true);

            return;
        }

        openHref();
    };

    if (href) {
        const link = confirmation ? (
            <Box>
                <Button variant={'text'} onClick={onLinkClick} startIcon={getIcon(icon)}>
                    {processedText}
                </Button>
                <Popup
                    settings={popupConfig ?? {}}
                    t={t}
                    open={open}
                    onHide={() => setOpen(false)}
                    onConfirm={openHref}
                >
                    <Typography>{confirmation}</Typography>
                </Popup>
            </Box>
        ) : newTab ? (
            <Typography noWrap align={align} sx={{ py: 1 }}>
                <Box
                    component="a"
                    href={href}
                    target={'_blank'}
                    sx={{ display: 'inline-flex', alignItems: 'center', maxWidth: '100%' }}
                >
                    {getIcon(icon, text ? { mr: 1.5 } : undefined)}
                    <Box
                        component={'span'}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {processedText}
                    </Box>
                </Box>
            </Typography>
        ) : (
            <Typography noWrap align={align} sx={{ py: 1 }}>
                <Box component={Link} to={href} sx={{ display: 'inline-flex', alignItems: 'center', maxWidth: '100%' }}>
                    {getIcon(icon, text ? { mr: 1.5 } : undefined)}
                    <Box
                        component={'span'}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {processedText}
                    </Box>
                </Box>
            </Typography>
        );

        if (tooltip) {
            return getCellWithTooltip(link, tooltip, rawTooltipHtml, t);
        }

        return link;
    }

    return (
        <Typography noWrap sx={{ py: 1 }}>
            {processedText}
        </Typography>
    );
}
