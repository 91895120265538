import useBundleTranslation from 'i18n';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import React, { useContext, useState } from 'react';
import { sortOrderDirections } from 'components/report-content/components/manage-filters/edit-panel/FieldForm';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import ComponentSettingsPivotMeasureField = ReportContentNS.ComponentSettingsPivotMeasureField;
import SimpleFormattingRulesList from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/SimpleFormattingRulesList';
import useHandleEditPanelError from 'components/report-content/hooks/useHandleEditPanelError';

export default function PivotFieldForm({
    type,
    editField,
    onApply,
    onCancel,
    isLast,
}: {
    type: 'row' | 'column' | 'measure';
    editField: ComponentSettingsPivotField | ComponentSettingsPivotMeasureField;
    onApply: (field: ComponentSettingsPivotField | ComponentSettingsPivotMeasureField) => void;
    onCancel: () => void;
    isLast: boolean;
}) {
    const [field, setField] = useState<ComponentSettingsPivotField | ComponentSettingsPivotMeasureField>(
        structuredClone(editField),
    );
    const { t } = useBundleTranslation(['components/report-content']);
    const { editPanel, setErrorMessage, errorBlock } = useHandleEditPanelError();

    const defaultSort = field.sortDirection ? field.sortDirection : 'ASC';
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        column_name: field.column_name,
        display_mask_id: field.display_mask_id ?? 0,
        sortDirection: defaultSort,
        compute_result_total_ind: field.compute_result_total_ind ?? 'N',
        sub_total_label: field.sub_total_label ?? `[${field.column_name}] Total`,
        //@ts-ignore
        sub_total_placement: field?.sub_total_placement ?? 'top',
    });

    const [formattingRules, setFormattingRules] = useState(field.formattingRules ?? []);

    const editPanelContext = useContext(EditPanelContext);
    const displayMasks = editPanelContext?.displayMasks ?? [];

    const showSubTotal = elementProps.form.hookFormWatch('compute_result_total_ind') == 'Y';
    const currentName = elementProps.form.hookFormWatch('column_name');

    const [headerSettings, setHeaderSettings] = useState(editField.headerSettings ?? {});
    const [subtotalSettings, setSubtotalSettings] = useState(editField.subtotalSettings ?? {});

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('table')}
                    onCancel={() => onCancel()}
                    item={field?.column_name ?? field?.field}
                />
            </Box>
            {/*@ts-ignore*/}
            <Stack ref={editPanel} sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                {errorBlock}
                {type != 'measure' && (
                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: { component: 'FormText', name: 'column_name', label: t('display_name') },
                            })}
                        />
                    </Box>
                )}
                {(field.value_type == 'numeric' || field.value_type == 'datetime') && (
                    <Box>
                        <FormLabel>{t('display_mask')}</FormLabel>
                        <ReactHookFormController
                            componentValues={[{ label: 'Default', value: '0' }].concat(
                                displayMasks
                                    .filter((d) =>
                                        field.value_type == 'numeric'
                                            ? d.mask_type == 'numeric'
                                            : d.mask_type == 'date',
                                    )
                                    .map((d) => ({
                                        label: d.display_example,
                                        value: d.display_mask_id,
                                    })),
                            )}
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    name: 'display_mask_id',
                                    component: 'FormSelect',
                                },
                            })}
                        />
                    </Box>
                )}
                {type != 'measure' && (
                    <>
                        <Box>
                            <FormLabel>{t('sort_order.label')}</FormLabel>
                            <ReactHookFormController
                                componentValues={sortOrderDirections
                                    .filter((f) => f.valueType == field.value_type)
                                    .map((f) => ({ label: t('manage_filters.' + f.label), value: f.order }))}
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        name: 'sortDirection',
                                        component: 'FormSelect',
                                    },
                                })}
                            />
                        </Box>
                        <Divider />
                    </>
                )}
                {type == 'row' && (
                    <>
                        <Box>
                            <FormLabel>{t('dataset.label_header_format')}</FormLabel>
                            <FieldTextFormattingLine
                                field={headerSettings}
                                onChange={(fieldFormatting) => setHeaderSettings(fieldFormatting)}
                                hasAlign
                                hasWrap
                            />
                        </Box>
                        <Divider />
                    </>
                )}
                <Box>
                    <FormLabel>{t(type == 'row' ? 'pivot.value_format' : 'pivot.column_format')}</FormLabel>
                    <FieldTextFormattingLine
                        field={field}
                        onChange={(fieldFormatting) => setField({ ...field, ...fieldFormatting })}
                        hasAlign
                        hasWrap={type == 'row'}
                    />
                </Box>
                {!isLast && (
                    <>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Box sx={{ width: '112px' }}>
                                <EditPanelCheckBox
                                    elementProps={elementProps}
                                    name={'compute_result_total_ind'}
                                    label={t('pivot.sub_total_label')}
                                />
                            </Box>
                            {type == 'row' && (
                                <Box className={!showSubTotal ? 'd-none' : ''} sx={{ width: '140px', flexShrink: 0 }}>
                                    <ReactHookFormController
                                        componentValues={[
                                            {
                                                label: 'bottom of group',
                                                value: 'bottom',
                                            },
                                            {
                                                label: 'top of group',
                                                value: 'top',
                                            },
                                        ]}
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            ...{
                                                component: {
                                                    component: 'FormSelect',
                                                    name: 'sub_total_placement',
                                                } as RawFormComponentType,
                                            },
                                        })}
                                    />
                                </Box>
                            )}
                        </Stack>

                        <Stack
                            className={!showSubTotal ? 'd-none' : ''}
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <Box sx={{ width: '112px' }}>{t('label')}</Box>
                            <Box sx={{ width: '140px', flexShrink: 0 }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        component: {
                                            component: 'FormText',
                                            name: 'sub_total_label',
                                            label: '',
                                        },
                                    })}
                                />
                            </Box>
                            <Box sx={{ maxWidth: '180px' }}>{t('pivot.use_pattern', { v: currentName })}</Box>
                        </Stack>
                        <Stack
                            className={!showSubTotal ? 'd-none' : ''}
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <Box sx={{ width: '112px' }}>{t('dataset.totals_label_format')}</Box>
                            <Box>
                                <FieldTextFormattingLine
                                    field={subtotalSettings}
                                    onChange={(fieldFormatting) => setSubtotalSettings(fieldFormatting)}
                                    hasAlign
                                    hasWrap
                                />
                            </Box>
                        </Stack>
                    </>
                )}
                {(field.value_type == 'numeric' || field.value_type == 'text') && (
                    <Box>
                        <FormLabel>{t('conditional_formatting.label')}</FormLabel>
                        <SimpleFormattingRulesList
                            fieldValueType={type == 'measure' ? 'numeric' : editField.value_type}
                            rules={formattingRules}
                            onUpdate={(newRules) => {
                                if (JSON.stringify(formattingRules) != JSON.stringify(newRules)) {
                                    setFormattingRules(newRules);
                                }
                            }}
                        />
                    </Box>
                )}
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        const data = elementProps.form.hookFormGetValues();
                        const label: string = elementProps.form.hookFormGetValues('column_name') ?? '';
                        if (label.trim() == '') {
                            setErrorMessage(t('fn_empty'));
                            return;
                        }
                        onApply({
                            ...field,
                            ...data,
                            formattingRules: formattingRules.slice(),
                            headerSettings: headerSettings,
                            subtotalSettings: subtotalSettings,
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
