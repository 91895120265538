import { RawFormComponentType, FormDataAPIType } from 'components/common/form';
import React from 'react';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { DatasetFieldValueType, ReportContentNS } from 'components/report-content';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import RendererFilter = ReportContentNS.RendererFilter;
import RendererFilterType = ReportContentNS.RendererFilterType;
import { Box, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';

interface ISortOrderDirections {
    order: 'ASC' | 'DESC';
    label: string;
    valueType: DatasetFieldValueType;
}

export const sortOrderDirections: Array<ISortOrderDirections> = [
    { order: 'ASC', label: 'label_a_to_z', valueType: 'text' },
    { order: 'DESC', label: 'label_z_to_a', valueType: 'text' },
    { order: 'ASC', label: 'label_ascending', valueType: 'numeric' },
    { order: 'DESC', label: 'label_descending', valueType: 'numeric' },
    { order: 'ASC', label: 'label_ascending', valueType: 'datetime' },
    { order: 'DESC', label: 'label_descending', valueType: 'datetime' },
];

interface IFilterType {
    type: RendererFilterType;
    label: string;
    valueType: DatasetFieldValueType;
}
const filterTypes: Array<IFilterType> = [
    { type: 'dropdown', label: 'label_dropdown', valueType: 'text' },
    { type: 'multiselect', label: 'label_multiselect', valueType: 'text' },
    { type: 'dropdown', label: 'label_dropdown', valueType: 'numeric' },
    { type: 'multiselect', label: 'label_multiselect', valueType: 'numeric' },
    { type: 'range', label: 'label_range', valueType: 'numeric' },
    { type: 'dropdown', label: 'label_dropdown', valueType: 'datetime' },
    { type: 'multiselect', label: 'label_multiselect', valueType: 'datetime' },
    { type: 'range', label: 'label_range', valueType: 'datetime' },
    { type: 'charting_interval', label: 'label_charting_interval', valueType: 'datetime' },
];

export default function FieldForm({
    field,
    onApply,
    onCancel,
    title,
}: {
    field: RendererFilter;
    onApply: (field: RendererFilter) => void;
    onCancel: () => void;
    title?: string;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        filter_label: field.filter_label,
        include_all_value: field.include_all_value ?? 'Y',
        show_date_only: field.show_date_only ?? 'N',
        filterType: field.filterType ?? 'dropdown',
        sortDirection: field.sortDirection,
    });

    const fieldType: RendererFilterType = elementProps.form.hookFormWatch('filterType');
    const valueType = field.value_type;

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={title}
                    onCancel={() => onCancel()}
                    item={field.column_name ?? field.reference_name}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                {/*ToDo Show labels as on design*/}
                <TemplateFormComponent
                    elementProps={elementProps}
                    component={'FormText'}
                    name={'filter_label'}
                    props={{ label: 'label' }}
                />

                <Box>
                    <FormLabel>{t('manage_filters.label_filter_type')}</FormLabel>
                    <TemplateFormComponent
                        elementProps={{ ...elementProps }}
                        componentValues={filterTypes
                            .filter((f) => f.valueType == valueType)
                            .map((f) => ({ label: t('manage_filters.' + f.label), value: f.type }))}
                        component={'FormSelect'}
                        name={'filterType'}
                    />
                </Box>

                <span className={fieldType == 'dropdown' || fieldType == 'multiselect' ? '' : 'd-none'}>
                    <FormLabel>{t('sort')}</FormLabel>
                    <TemplateFormComponent
                        elementProps={elementProps}
                        componentValues={sortOrderDirections
                            .filter((f) => f.valueType == valueType)
                            .map((f) => ({ label: t('manage_filters.' + f.label), value: f.order }))}
                        component={'FormSelect'}
                        name={'sortDirection'}
                    />
                </span>

                <span className={fieldType == 'dropdown' ? '' : 'd-none'}>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_all_value'}
                        label={t('manage_filters.include_all_value')}
                    />
                </span>

                <span className={valueType == 'datetime' && fieldType == 'range' ? '' : 'd-none'}>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_date_only'}
                        label={t('manage_filters.show_date_only')}
                    />
                </span>
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={() => {
                        onApply({
                            ...field,
                            filter_label: elementProps.form.hookFormGetValues('filter_label'),
                            include_all_value: elementProps.form.hookFormGetValues('include_all_value'),
                            show_date_only: elementProps.form.hookFormGetValues('show_date_only'),
                            filterType: elementProps.form.hookFormGetValues('filterType'),
                            sortDirection: elementProps.form.hookFormGetValues('sortDirection'),
                        });
                    }}
                    onCancel={() => onCancel()}
                    isTypeSave
                />
            </Box>
        </Stack>
    );
}
