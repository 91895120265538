import useBundleTranslation from 'i18n';
import { FormControlTextProps } from 'components/common/form/layout/control';
import { FormRendererAPIType } from 'components/common/form';
import FormText from 'components/common/form/layout/control/FormText';
import React from 'react';
import { FormControl, InputAdornment } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';

export default function CheckBoxSearch({
    onChange,
    value,
    placeholder,
}: {
    onChange: (event: any) => void;
    value: string;
    placeholder?: string;
}) {
    const { t } = useBundleTranslation(['components/common/check_box_list']);
    const searchControlProps: FormControlTextProps = {
        form: {} as FormRendererAPIType,
        name: 'checkbox_search',
        onChange: onChange,
        placeholder: placeholder ?? t('search'),
        value: value,
        startAdornment: (
            <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                {value === '' ? (
                    <IconMi icon="search" fontSize="16" />
                ) : (
                    <IconMi
                        onClick={() => {
                            onChange('');
                        }}
                        icon="times"
                        fontSize={'16'}
                        sx={{ fontSize: '16px', '&:hover': { cursor: 'pointer', opacity: 0.75 } }}
                    />
                )}
            </InputAdornment>
        ),
    };
    return (
        <FormControl fullWidth>
            <FormText controlProps={searchControlProps} />
        </FormControl>
    );
}
