import { alpha, Theme } from '@mui/material';

export default {
    flexStartWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
        width: '244px',
    },
    noIconWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px 0px 0px 24px',
        width: '249px',
    },
    certifiedMainText: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '18px',
        display: 'inline',
    },
    tagsChip: {
        borderRadius: '3px',
        margin: '3px',
        maxWidth: '125px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    custom_fields: {
        item: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        container: {
            width: '225px',
            maxWidth: '225px',
        },
    },
    divider: {
        borderBottom: 'solid 1px',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        width: '263px',
        marginTop: '4px',
        marginBottom: '8px',
    },
};
