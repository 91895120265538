import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React, { useContext } from 'react';
import IconMi from 'components/common/icon/IconMi';

import { ReportContentNS } from 'components/report-content';
import ComponentSettingsChartMeasureField = ReportContentNS.ComponentSettingsChartMeasureField;
import AllowedMeasureFieldAggregations = ReportContentNS.AllowedMeasureFieldAggregations;
import AllowedMeasureFieldDisplayTypes = ReportContentNS.AllowedMeasureFieldDisplayTypes;
import { getMeasureFieldOptionForFormUId, MeasureFieldSortingContext, MeasureFieldSortingContextType } from './index';
import IconHandler from 'components/common/icon/IconHandler';
import { Box, Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import EditPanelColorPicker from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelColorPicker';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useBundleTranslation from 'i18n';

const DragHandle = SortableHandle(() => (
    <Box sx={{ display: 'inline-block' }}>
        <Button variant={'outlined'} color={'neutral'} sx={{ px: 0.5, minWidth: '24px', pointerEvents: 'none' }}>
            <IconMi icon={'arrows-alt-v'} fontSize="16" />
        </Button>
    </Box>
));

const SortableItem = SortableElement(
    ({
        value: field,
        fields,
    }: {
        value: ComponentSettingsChartMeasureField;
        fields: Array<ComponentSettingsChartMeasureField>;
    }) => {
        const { t } = useBundleTranslation(['components/report-content']);
        const context = useContext<MeasureFieldSortingContextType | null>(MeasureFieldSortingContext);
        if (context == null) {
            return null;
        }

        const fieldFormDisplayType = getMeasureFieldOptionForFormUId(field, 'display_type');
        const fieldFormColor = getMeasureFieldOptionForFormUId(field, 'color');
        const fieldFormAggregation = getMeasureFieldOptionForFormUId(field, 'aggregation_function');

        // Aggregation
        const handleAggregationUpdate = (value: string) => {
            context?.elementProps.form.hookFormSetValue(fieldFormAggregation, value);
        };
        const allowedAggregations = AllowedMeasureFieldAggregations.filter((f) => f.value_type == field.value_type);
        const fieldDisplayType = context.elementProps.form.hookFormWatch(fieldFormDisplayType);
        let aggregation = context?.elementProps.form.hookFormWatch(fieldFormAggregation);
        if (allowedAggregations.findIndex((a) => a.name == aggregation) == -1) {
            handleAggregationUpdate(allowedAggregations[0].name);
        }

        const fieldsListForSelect = context?.datasetFields
            .filter((datasetFilter) => {
                const fieldFount = fields.find((f) => f.reference_name == datasetFilter.reference_name);
                if (!fieldFount) {
                    return true;
                }
                return fieldFount.reference_name == field.reference_name;
            })
            .map((datasetFilter) => {
                const field = fields.find((f) => f.reference_name == datasetFilter.reference_name);
                if (!field) {
                    return datasetFilter;
                }
                return { ...datasetFilter, column_name: field.column_name };
            });

        const handleFieldUpdate = (fieldName: string) => {
            if (field.reference_name == fieldName) {
                return;
            }

            const datasetField = context?.datasetFields.find((f) => f.reference_name == fieldName);
            if (!datasetField) {
                return;
            }

            const newField: ComponentSettingsChartMeasureField = {
                ...field,
                column_name: datasetField.column_name,
                dataset_column_id: datasetField.dataset_column_id,
                dataset_filter_column_id: datasetField.dataset_filter_column_id,
                dataset_id: datasetField.dataset_id,
                reference_name: datasetField.reference_name,
                value_type: datasetField.value_type,
                display_mask_id: String(datasetField.display_mask_id),
                label: datasetField.reference_name,
            };
            context?.onUpdateMeasureField(newField);
        };

        const amountOfMeasures = context?.datasetFields.length - fieldsListForSelect.length + 1;

        return (
            <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                    zIndex: 2205, //above backdrop
                }}
                key={field.uId}
                spacing={1}
            >
                <DragHandle />

                <ToggleButtonGroup
                    sx={{ alignSelf: 'center' }}
                    value={fieldDisplayType}
                    exclusive
                    onChange={(event, value) => {
                        if (value != null) {
                            context?.elementProps.form.hookFormSetValue(fieldFormDisplayType, value);
                        }
                    }}
                    data-test={`measure-toggle-group-${field.uId}`}
                >
                    {AllowedMeasureFieldDisplayTypes.map((type) => (
                        <ToggleButton
                            key={type.name}
                            value={type.name}
                            sx={{
                                position: 'relative',
                                minWidth: (theme) => theme.size.pxValue(theme.size.defaultHeight),
                            }}
                            data-test={`measure-toggle-button-${field.uId}-${type.name}`}
                        >
                            <Tooltip title={t('line_chart.chart_icon_' + type.name)}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        inset: '0 0 0 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <IconHandler icon={type.icon} sx={{ fontSize: 16 }} />
                                </Box>
                            </Tooltip>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <EditPanelColorPicker
                    elementProps={context?.elementProps}
                    name={fieldFormColor}
                    icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
                />

                <Box sx={{ width: '100%' }} data-test={`measure-select-aggregation-${field.uId}`}>
                    <ReactSelect
                        data={allowedAggregations.map((f) => ({
                            label: f.label,
                            value: f.name,
                        }))}
                        value={aggregation}
                        update={(value) => handleAggregationUpdate(value)}
                    />
                </Box>
                <Box>of</Box>
                <Box sx={{ width: '100%' }} data-test={`measure-select-reference-${field.uId}`}>
                    <ReactSelect
                        data={fieldsListForSelect.map((f) => ({
                            label: f.column_name,
                            value: f.reference_name,
                        }))}
                        value={field.reference_name}
                        update={(value) => handleFieldUpdate(value)}
                    />
                </Box>

                <Box flexShrink={0}>
                    <IconButton onClick={() => context.selectField(field)} data-test={`measure-edit-${field.uId}`}>
                        <IconMi icon="gear" fontSize="16" />
                    </IconButton>
                    {amountOfMeasures > 1 && (
                        <IconButton
                            onClick={() => context.removeField(field)}
                            data-test={`measure-remove-${field.uId}`}
                        >
                            <IconMi icon="times" fontSize="16" />
                        </IconButton>
                    )}
                </Box>
            </Stack>
        );
    },
);

const SortableList = SortableContainer(({ items }: { items: Array<ComponentSettingsChartMeasureField> }) => {
    return (
        <Stack spacing={1}>
            {items.map((field, index) => (
                // @ts-ignore
                <SortableItem fields={items} key={`item-${field.uId}`} index={index} value={field} />
            ))}
        </Stack>
    );
});

export default function MeasureFieldsList({ fields }: { fields: Array<ComponentSettingsChartMeasureField> }) {
    const context = useContext<MeasureFieldSortingContextType | null>(MeasureFieldSortingContext);
    if (context == null) {
        return null;
    }
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        context.onSortEnd(oldIndex, newIndex);
    };
    // @ts-ignore
    return <SortableList axis={'xy'} lockAxis={'y'} useDragHandle items={fields} onSortEnd={onSortEnd} />;
}
