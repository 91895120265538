import { Stack, Tabs, Tab, Typography, Box } from '@mui/material';
import React from 'react';
import { GridData } from 'components/common/grid';
import useBundleTranslation from 'i18n';
import DerivedField from '../derived-field';
import { DerivedFieldData, JoinFieldConfig, RulesConfig } from 'components/dataset-viewer';
import TrackChangesTab from './TrackChangesTab';
import SimpleFieldsSelectorTable from './SimpleFieldsSelectorTable';
import NumericFieldsSelectorTable from './FieldsSelectorTable';
import { TrackTabUpdateDTO } from '../helpers';

function FieldsSelector({
    textColumnsData,
    numericColumnsData,
    selectedColumns,
    onSelectedChange,
    onAggregationChange,
    datasetId,
    onCreateDerived,
    names,
    json,
    onDerivedAction,
    fieldsLabels,
    multiInstancesMode,
    filter,
    onTrackDataUpdate,
    fieldIds,
    onLeftTabChange,
}: {
    textColumnsData: GridData;
    numericColumnsData: GridData;
    selectedColumns: string[];
    onSelectedChange: (keys: string[], checked: boolean) => void;
    onAggregationChange: (fieldId: string, value: string, oldValue: string) => void;
    datasetId: number;
    onCreateDerived: (data: DerivedFieldData) => void;
    names: string[];
    json: string;
    onDerivedAction: (action: 'edit' | 'delete', fieldId: string) => void;
    fieldsLabels: string[];
    multiInstancesMode: boolean;
    filter: RulesConfig;
    onTrackDataUpdate: (value: TrackTabUpdateDTO) => void;
    fieldIds: JoinFieldConfig[];
    onLeftTabChange: (tab: number) => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onLeftTabChange(newValue);
    };

    const tabIndex = filter.leftTab === 'fields' ? 0 : 1;

    return (
        <Stack direction={'column'} spacing={2} sx={{ py: 4, px: 3 }}>
            {multiInstancesMode ? (
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'visible',
                    }}
                >
                    <Tabs value={tabIndex} onChange={handleChange} sx={{ flexGrow: 1, mb: '-1px' }}>
                        <Tab label={'Select Fields'} key={'fields'} />
                        <Tab label={'Track Changes'} key={'trackChanges'} />
                    </Tabs>
                    {tabIndex === 0 && (
                        <DerivedField
                            onCreateDerived={onCreateDerived}
                            datasetId={datasetId}
                            names={names}
                            json={json}
                            fieldsLabels={fieldsLabels}
                        />
                    )}
                </Box>
            ) : (
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                    <Typography variant="heading" width={'100%'}>
                        {t('fields_selector.select_fields')}
                    </Typography>
                    <DerivedField
                        onCreateDerived={onCreateDerived}
                        datasetId={datasetId}
                        names={names}
                        json={json}
                        fieldsLabels={fieldsLabels}
                    />
                </Stack>
            )}
            {multiInstancesMode && tabIndex === 1 ? (
                <TrackChangesTab
                    showHistory={filter.history}
                    filter={filter}
                    onTrackDataUpdate={onTrackDataUpdate}
                    fieldIds={fieldIds}
                />
            ) : (
                <Box sx={{ overflow: 'auto' }}>
                    <SimpleFieldsSelectorTable
                        data={textColumnsData}
                        selectedColumns={selectedColumns}
                        onSelectedChange={onSelectedChange}
                        type={'text'}
                    />
                    <NumericFieldsSelectorTable
                        data={numericColumnsData}
                        selectedColumns={selectedColumns}
                        onSelectedChange={onSelectedChange}
                        onAggregationChange={onAggregationChange}
                        onDerivedAction={onDerivedAction}
                        filter={filter}
                    />
                </Box>
            )}
        </Stack>
    );
}

export default FieldsSelector;
