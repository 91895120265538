export function combineCellStyle(styleRules: any) {
    return {
        display: 'inline-block',
        borderLeft: '1px rgba(204, 204, 204, 0.09) solid',
        //@ts-ignore
        // width: cell.field.columnSize + 'px',
        overflow: 'hidden',
        verticalAlign: 'top',
        flexShrink: 0,
        lineHeight: '1.22em',
        minHeight: '1.89em',
        padding: '0.335em 5px',
        ...styleRules,
    };
}
