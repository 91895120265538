import {
    alpha,
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { DatasetDataResponse, MetricTemplate, RulesConfig } from '../index';
import { FormComponentValue } from 'components/common/form/layout/control';
import TemplateSelector from './TemplateSelector';
import { MetricItem, SegmentReuseResponse } from './index';
import { uid } from 'tools/tools';
import { datasetViewAPI } from 'api/viewer/dataset';
import { Popup } from 'components/common/popup/Popup';
import parse from 'html-react-parser';

const calculateOptions: FormComponentValue[] = [
    {
        label: 'do not aggregate',
        value: 'None',
    },
    {
        label: 'Sum',
        value: 'SUM',
    },
    {
        label: 'Count',
        value: 'COUNT',
    },
    {
        label: 'Count Distinct',
        value: 'COUNT DISTINCT',
    },
    {
        label: 'Average',
        value: 'AVG',
    },
    {
        label: 'Minimum',
        value: 'MIN',
    },
    {
        label: 'Maximum',
        value: 'MAX',
    },
];

interface IAddMetricPanelProps {
    datasetData: DatasetDataResponse;
    filter: RulesConfig;
    onMetricAdd: (metric: MetricItem) => void;
}

export default function AddMetricPanel({ datasetData, filter, onMetricAdd }: IAddMetricPanelProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [measurementInterval, setMeasurementInterval] = useState('');
    const [calculate, setCalculate] = useState('SUM');
    const [segment, setSegment] = useState('');
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [manualName, setManualName] = useState(false);
    const [value, setValue] = useState('');
    const [segmentReuse, setSegmentReuse] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [templateId, setTemplateId] = useState(0);
    const [reuseCheckResult, setReuseCheckResult] = useState<SegmentReuseResponse | null>(null);
    const [dataIsValid, setDataIsValid] = useState(true);

    const measuredData = useMemo(() => {
        const selectData: FormComponentValue[] = [];

        if (datasetData.measurementIntervals) {
            datasetData.measurementIntervals.forEach((item) => {
                selectData.push({
                    label: item.label,
                    value: String(item.key),
                });
            });
        }

        return selectData;
    }, [datasetData.measurementIntervals]);

    const valueData = useMemo(() => {
        const selectData: FormComponentValue[] = [];

        const numericColumns = datasetData.columnsData.filter((column) => {
            let result = filter.resultFields.includes(column.id);

            if (result) {
                result = column.value_type === 'numeric' || ['COUNT', 'COUNT DISTINCT'].includes(calculate);
            }

            if (result) {
                result = column.id !== segment && date !== column.id;
            }

            return result;
        });

        numericColumns.forEach((column) => {
            selectData.push({
                label: column.label,
                value: `${column.id}-current`,
                props: {
                    dataKey: column.id,
                },
            });
        });

        if (filter.history) {
            numericColumns.forEach((column) => {
                selectData.push({
                    label: `Prior ${column.label}`,
                    value: `${column.id}-prior`,
                    props: {
                        dataKey: column.id,
                    },
                });
            });
        }

        if (value === '' && selectData.length > 0) {
            setValue(selectData[0].value);
        }

        return selectData;
    }, [filter.resultFields, datasetData.columnsData, segment, date, calculate]);

    const dateData = useMemo(() => {
        const selectData: FormComponentValue[] = [];

        datasetData.columnsData
            .filter((column) => filter.resultFields.includes(column.id) && column.value_type === 'datetime')
            .forEach((column) => {
                selectData.push({
                    label: column.label,
                    value: `${column.id}-current`,
                });
            });

        if (date === '' && selectData.length > 0) {
            setDate(selectData[0].value);
        }

        if (datasetData.datasetData.save_historical_instances_ind === 'Y') {
            selectData.unshift({
                label: 'Snapshot Date',
                value: 'Snapshot Date',
            });
        }

        return selectData;
    }, [filter.resultFields, datasetData.columnsData]);

    const segmentData = useMemo(() => {
        const selectData: FormComponentValue[] = [
            {
                label: '',
                value: '',
            },
        ];

        datasetData.columnsData
            .filter(
                (column) =>
                    filter.resultFields.includes(column.id) &&
                    column.value_type !== 'datetime' &&
                    column.numeric_type !== 'float' &&
                    column.id !== value,
            )
            .forEach((column) => {
                selectData.push({
                    label: column.label,
                    value: `${column.id}-current`,
                });
            });

        return selectData;
    }, [filter.resultFields, datasetData.columnsData, value]);

    const segmentReuseData = useMemo(() => {
        const selectData: FormComponentValue[] = [
            {
                label: 'None - create new Dimension',
                value: '',
            },
        ];

        datasetData.segments.forEach(({ segment_id, name }) => {
            selectData.push({
                label: name,
                value: String(segment_id),
            });
        });

        return selectData;
    }, [datasetData.segments]);

    useEffect(() => {
        if (segmentReuse > '' && segment > '') {
            datasetViewAPI
                .validateSegmentReuse(datasetData.originalDatasetId, {
                    segment_column: segment,
                    segment_reuse: segmentReuse,
                })
                .then((response) => {
                    if (response.status === 'ERROR') {
                        setReuseCheckResult(response);
                    } else {
                        setReuseCheckResult(null);
                    }
                });
        }
    }, [segmentReuse, segment]);

    useEffect(() => {
        if (measuredData.length > 0) {
            setMeasurementInterval(measuredData[0].value);
        }
    }, [measuredData]);

    useEffect(() => {
        if (!manualName) {
            const intervalInfo = datasetData.measurementIntervals?.find(
                (item) => String(item.key) === measurementInterval,
            );
            if (intervalInfo) {
                const valueRecord = valueData.find((record) => value === record.value);
                if (valueRecord) {
                    const newName = `${intervalInfo.label} ${valueRecord.props['dataKey']}`;
                    setName(newName);
                }
            }
        }

        const defaultTemplate = datasetData.metricTemplates.find((template) => {
            return String(template.measurement_interval_id) == measurementInterval && template.is_default_ind === 'Y';
        });

        if (defaultTemplate) {
            setTemplateId(defaultTemplate.template_metadata_id);
            setTemplateName(defaultTemplate.name);
        } else {
            const template = datasetData.metricTemplates.find((template) => {
                return String(template.measurement_interval_id) == measurementInterval;
            });

            if (template) {
                setTemplateId(template.template_metadata_id);
                setTemplateName(template.name);
            } else {
                const firstTemplate = datasetData.metricTemplates[0];

                if (firstTemplate) {
                    setTemplateId(firstTemplate.template_metadata_id);
                    setTemplateName(firstTemplate.name);
                }
            }
        }
    }, [measurementInterval, value]);

    const isDataValid = () => {
        let valid = true;

        if (name === '') {
            valid = false;
        }

        if (date === '') {
            valid = false;
        }

        if (value === '') {
            valid = false;
        }

        setDataIsValid(valid);
        return valid;
    };

    const onAddMetric = () => {
        if (isDataValid()) {
            const newMetric: MetricItem = {
                uid: uid(),
                name: name,
                measurement_interval_id: measurementInterval,
                value_column: value,
                date_column: date,
                segment_column: segment,
                segment_reuse: segmentReuse,
                metric_calculate: calculate,
                template: templateId,
            };

            onMetricAdd(newMetric);
            setName('');
        }
    };

    const onTemplateChange = (newTemplate: MetricTemplate) => {
        setTemplateName(newTemplate.name);
        setTemplateId(newTemplate.template_metadata_id);
    };

    return (
        <Stack
            sx={{
                width: '20%',
                boxShadow: (theme) => `0px 2px 8px 0px ${alpha(theme.palette.text.primary, 0.08)}`,
                backgroundColor: (theme) => theme.palette.background.default,
                overflow: 'auto',
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    padding: '15px 16px',
                    borderBottom: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                }}
            >
                <Typography width={'100%'} color={'primary.main'} fontWeight={600} fontSize={14}>
                    {t('add_metric.header')}
                </Typography>
            </Box>
            <Stack spacing={1.5} sx={{ px: 3, py: 2 }}>
                <FormControl>
                    <FormLabel>{t('add_metric.measured')}</FormLabel>
                    <Box data-test={`metric-add-measured`}>
                        <ReactSelect data={measuredData} value={measurementInterval} update={setMeasurementInterval} />
                    </Box>
                </FormControl>
                <FormControl>
                    <FormLabel>{t('add_metric.value')}</FormLabel>
                    <Box data-test={`metric-add-value`}>
                        <ReactSelect data={valueData} value={value} update={setValue} />
                    </Box>
                    {!dataIsValid && value === '' && (
                        <FormHelperText sx={{ color: 'error.main', marginLeft: 0 }}>
                            {t('add_metric.select_error')}
                        </FormHelperText>
                    )}
                </FormControl>
                <TemplateSelector
                    selectedTemplate={templateName}
                    templates={datasetData.metricTemplates.filter((mTemplate) => {
                        return String(mTemplate.measurement_interval_id) == measurementInterval;
                    })}
                    onChangeTemplate={onTemplateChange}
                />
                <FormControl error={date === '' && !dataIsValid}>
                    <FormLabel>{t('add_metric.date')}</FormLabel>
                    <Box data-test={`metric-add-date`}>
                        <ReactSelect
                            data={dateData}
                            value={date}
                            update={(value) => {
                                setDate(value);
                                setDataIsValid(true);
                            }}
                        />
                    </Box>
                    {!dataIsValid && date === '' && (
                        <FormHelperText sx={{ color: 'error.main', marginLeft: 0 }}>
                            {t('add_metric.select_error')}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl>
                    <FormLabel>{t('add_metric.aggregate')}</FormLabel>
                    <Box data-test={`metric-add-aggregate`}>
                        <ReactSelect data={calculateOptions} value={calculate} update={setCalculate} />
                    </Box>
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDataIsValid(true);
                            setName(event.target.value);
                            setManualName(true);
                        }}
                        error={!dataIsValid && name === ''}
                        helperText={!dataIsValid && name === '' ? t('add_metric.name_error') : ''}
                        label={t('add_metric.name')}
                        data-test={`metric-add-name`}
                    />
                </FormControl>
                {/*<Stack direction={'row'} justifyContent={'center'}>*/}
                {/*    <FormControl>*/}
                {/*        <FormLabel>{t('add_metric.calculate')}</FormLabel>*/}
                {/*        <ToggleButtonGroup value={'new'} exclusive onChange={() => {}}>*/}
                {/*            <ToggleButton value="new">{t('add_metric.calculate_new')}</ToggleButton>*/}
                {/*            <ToggleButton value="all">{t('add_metric.calculate_all')}</ToggleButton>*/}
                {/*        </ToggleButtonGroup>*/}
                {/*    </FormControl>*/}
                {/*</Stack>*/}
                <FormControl>
                    <FormLabel>{`${t('add_metric.dimension')} (${t('add_metric.optional')})`}</FormLabel>
                    <Box data-test={`metric-add-segment`}>
                        <ReactSelect data={segmentData} value={segment} update={setSegment} />
                    </Box>
                </FormControl>
                {segment > '' && (
                    <FormControl>
                        <FormLabel>{t('add_metric.map_to_dimension')}</FormLabel>
                        <Box data-test={`metric-add-segment-reuse`}>
                            <ReactSelect data={segmentReuseData} value={segmentReuse} update={setSegmentReuse} />
                        </Box>
                    </FormControl>
                )}
                <Box>
                    <Button
                        data-test={'add-metric'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={onAddMetric}
                        disabled={!dataIsValid}
                    >
                        {t('add_metric.header')}
                    </Button>
                </Box>
            </Stack>
            {reuseCheckResult && (
                <Popup
                    settings={{
                        title: t('popup_warning_title'),
                        noCancel: true,
                        textOK: t('ok'),
                    }}
                    open={reuseCheckResult !== null}
                    onHide={() => setReuseCheckResult(null)}
                    maxWidth={'popupSm'}
                >
                    <Box sx={{ wordWrap: 'break-word', wordBreak: 'all' }}>{parse(reuseCheckResult.message)}</Box>
                </Popup>
            )}
        </Stack>
    );
}
