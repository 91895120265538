import { TFunction } from 'i18next';
import { ElementDownloadLink, ElementType } from 'components/element-viewer/index';
import useBundleTranslation from 'i18n';
import { DownloadFormatType, DownloadLinkElement } from 'components/common/DownloadLink';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import DropdownIcon from 'components/element-viewer/controls-panel-icon/DropdownIcon';
import React from 'react';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';
import { useExternalReportNativeFilters } from 'components/element-viewer/ControlsPanel';

function downloadFormatToLink(format: DownloadFormatType, t: TFunction) {
    const map = {
        xlsx: { label: 'Excel', icon: 'file-excel' },
        pptx: { label: 'PowerPoint', icon: 'file-powerpoint' },
        pdf: { label: 'PDF', icon: 'file-pdf' },
        csv: { label: t('download.label_csv_raw'), icon: 'file' },
        csvf: { label: t('download.label_csv_formatted'), icon: 'file' },
    };

    return { ...map[format], key: format, url: '' };

    // $tip   = $downloadDataFormat == 'csvf' ? 'ext:qtip="'.sprintf($translator->tt('label_applies_display_mask_formatting_to_data', 'Applies Display Mask %sformatting to data.'), '<br/>').'"' : '';
}

export default function DownloadIcon({
    element,
    segmentValueId,
    uco,
    filters,
    useNativeFilters,
}: {
    element: ElementType;
    segmentValueId: number;
    uco?: number;
    filters?: string;
    useNativeFilters?: boolean;
}) {
    const { t } = useBundleTranslation(['components/common/element', 'components/common/viewer_controls_panel']);
    const downloadDisabled = useConfigConstantValue('DISABLE_DOWNLOADS');
    const allowedDownload = useIsAdminOrHasPrivilege('PRIV_ALLOW_DOWNLOAD');
    const filtersToGet = useExternalReportNativeFilters(filters, useNativeFilters);

    if (downloadDisabled && !allowedDownload) {
        return null;
    }

    const MenuDownloadLink = function ({ link }: { link: ElementDownloadLink }) {
        // TODO: fix icons
        return (
            <span>
                {link.key == 'external' ? (
                    <Box
                        component={'a'}
                        href={link.url}
                        sx={{
                            color: 'inherit',
                            '&:hover': { textDecoration: 'none' },
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IconMi icon={link.icon} fontSize="16" sx={{ mr: 1 }} /> {link.label}
                    </Box>
                ) : (
                    <DownloadLinkElement
                        elementId={element.row.elementId}
                        segmentValueId={segmentValueId}
                        format={link.key}
                        uco={uco}
                        filters={filtersToGet}
                        useNativeFilters={useNativeFilters}
                    >
                        <IconMi icon={link.icon} fontSize="16" sx={{ mr: 1 }} />
                        {link.label}
                    </DownloadLinkElement>
                )}
            </span>
        );
    };

    if (element.customFieldsRestrictions.downloadPrint) {
        const tooltipTitle = (
            <span
                dangerouslySetInnerHTML={{
                    __html: t('cf_restriction', {
                        type: t('download.download'),
                        name: element.customFieldsRestrictions.downloadPrint.name,
                        values: element.customFieldsRestrictions.downloadPrint.values.join(', '),
                    }),
                }}
            />
        );
        return (
            <Tooltip title={tooltipTitle}>
                <span>
                    <IconButton disabled sx={{ p: '6px' }}>
                        <IconMi icon="download" fontSize="16" />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    const links = element.download.links.concat(
        element.download?.formats?.map((format: DownloadFormatType) => downloadFormatToLink(format, t)) ?? [],
    );

    if (!links.length) {
        //t('label_no_downloads_available')
        return (
            <Tooltip title={t('download_empty_btn_tooltip')}>
                <span>
                    <IconButton disabled sx={{ p: '6px' }}>
                        <IconMi icon="download" fontSize="16" />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <span data-test={'top-download-icon'}>
            <DropdownIcon icon="download" tooltip={t('download_btn_tooltip')}>
                {links.map((link: ElementDownloadLink) => (
                    <MenuItem key={link.key}>
                        <MenuDownloadLink link={link} />
                    </MenuItem>
                ))}
            </DropdownIcon>
        </span>
    );
}
