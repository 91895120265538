import { alpha } from '@mui/material';

export const designOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 552, //600
            md: 900,
            lg: 1128, //1200
            xl: 1536,
            popupSm: 408,
            popupMd: 600,
            popupLg: 792,
            popupXl: 1176,
        },
    },
    palette: {
        mode: 'light',
        text: {
            primary: '#222222',
            secondary: '#666666', //Need to refactor (remove or set by opacity)
            disabled: '#E4E4E4', //Need to refactor (remove or set by opacity)
            opposite: '#fff', //on color background
        },
        primary: {
            main: '#075B7E', //Old #37809F
            contrastText: '#fff',
        },
        error: {
            main: '#AC2B2B', //Old CF5548
            contrastText: '#fff',
        },
        warning: {
            main: '#FFB000',
            contrastText: '#fff',
        },
        success: {
            main: '#077E45', //Old #60A177
            contrastText: '#fff',
        },
        warningLow: {
            main: '#FFE65A',
            contrastText: '#222',
        },
        neutral: {
            main: '#666666',
            contrastText: '#ffffff',
        },
        divider: 'rgba(34, 34, 34, 0.08)', //Need to refactor (remove or set by main.text)
        background: {
            default: '#ffffff',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 3,
    },
    font: {
        default: 'Inter, Arial, Helvetica, sans-serif',
        monospace: '"JetBrains Mono", monospace',
    },
    size: {
        pxValue: (px: number) => px + 'px',
        defaultHeight: 28,
        navbarHeight: 40,
        tabsHeight: 46,
        lineHeight: 1.5, //default line-Height
        containerIndentX: 16,
        componentFormIndent: 16,
    },
    typography: {
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        fontSize: 12,
        heading: {
            fontSize: 14,
            fontWeight: 600,
            paddingBottom: '5px',
            letterSpacing: '0.12px',
            lineHeight: 1.21,
        },
        body1: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        button: {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 'normal',
            textTransform: 'none',
        },
        label: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    },
    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        drawer: 1200,
        tabsPanel: 1250,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
        appBar: 1600,
    },
    //ToDo maybe use from default colors by default
    componentSettings: {
        tile: {
            titleColor: '#222222',
            sectionTitleColor: '#075B7E',
            headerBgColor: '#FFFFFF',
            stoplight: {
                goodColor: '#077E45',
                badColor: '#AC2B2B',
                neutralColor: '#FFFFFF',
            },
        },
        button: {
            borderRadius: 3,
        },
        primaryButton: {
            primaryColor: '#075B7E',
            textColor: '#fff',
        },
        topbar: {
            backgroundColor: '#075B7E',
            textColor: '#fff',
        },
        tab: {
            primaryColor: '#075B7E',
        },
        highstockChart: {
            projection: '#6cb1ba',
            targetLine: '#000',
            defaultOverlay: '#00CCFF',
            annotation: '#075B7E',
            closedAlert: '#444444',
            movingAverage: 'rgb(124,0,0)',
            statistical: 'rgb(206, 160, 160)',
            statisticalFill: 'rgba(206, 160, 160, 0.4)',
            stoplightGrayAlpha: 'rgba(244, 224, 224, 1)',
            stoplightGreenAlpha: 'rgba(198, 224, 207, 1)',
            stoplightGray: 'rgb(244, 224, 224)',
            stoplightGreen: 'rgb(198, 224, 207)',
        },
    },
};
