import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { lazy, useState } from 'react';
import { LineageCellProps } from 'components/common/grid/';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
const Lineage = lazy(() => import('components/lineage/Lineage'));

export default function LineageCell({ id, type, sx = {} }: LineageCellProps) {
    const { t } = useBundleTranslation(['components/lineage/lineage']);

    const [showLineage, setShowLineage] = useState(false);

    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title={t('lineage')}>
                <IconButton
                    aria-label="edit"
                    size={'small'}
                    onClick={() => {
                        setShowLineage(true);
                    }}
                    sx={{ color: 'primary.main', '&:hover': { opacity: 0.75 } }}
                >
                    <IconMi icon={'lineage-diagram'} />
                </IconButton>
            </Tooltip>
            <Lineage
                showLineage={showLineage}
                onHide={() => {
                    setShowLineage(false);
                }}
                id={id}
                type={type}
            />
        </Grid>
    );
}
