import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { FormLayoutContext, ITabProps } from 'components/common/form/layout/tab/index';

export function FormTabContent({ isTabActive, children }: ITabProps & { children: any }) {
    const layoutContext = useContext(FormLayoutContext);
    if (layoutContext.formLayout == 'popup') {
        return <>{children}</>;
    }

    const contentVisible = isTabActive || layoutContext.formType == 'new';

    return (
        <Stack
            display={!contentVisible ? 'none' : undefined}
            alignItems="center"
            className={`tab-content ${contentVisible ? 'active-tab-content' : ''}`}
            sx={{ overflow: 'hidden', height: '100%' }}
        >
            {children}
        </Stack>
    );
}
