import {
    ChartBasicSeriesTypeRaw,
    ChartPoint,
    ChartSeriesType,
    ChartSeriesTypeRaw,
    DATA_X_MULTIPLIER,
    getChartIcon,
    MetricChartStateLegend,
} from 'components/metric/chart/index';
import { Chart as HChart } from 'highcharts';
import { strToSvg, svgElementFromString } from 'components/metric/chart/svgTools';
import { AssocArray } from 'tools/types';
import { ElementRowData } from 'components/element-viewer';
import i18n from 'i18next';
import { Theme } from '@mui/material';

type ChartPointAlertNewsType = 'good' | 'bad' | 'neutral';
export interface ChartPointAlert extends ChartPoint {
    newsType: ChartPointAlertNewsType;
    marker: any;
    iconNumber: number;
    pointId: string;
    iconSvg: SVGSVGElement;
    text: string;
}

export function getAlertSeries(
    series: Array<ChartSeriesTypeRaw>,
    alertSeries: Array<ChartBasicSeriesTypeRaw>,
    legendState: AssocArray<MetricChartStateLegend>,
    chart: HChart,
    theme: Theme,
    elementRow?: ElementRowData,
): Array<ChartSeriesType> {
    if (!chart?.series || chart.series.length == 0) {
        return [];
    }

    const data: Array<ChartPoint> = [];

    const buildAlertPoint = (
        seriesPoint: ChartPoint,
        alertPoint: ChartPointAlert,
        iconNumber: number,
    ): ChartPointAlert => {
        // TODO:
        const toPixels = chart.yAxis[0].toPixels(Number(seriesPoint.y), false) + (-20 - iconNumber * 38) * 0;

        let icon = getChartIcon('alertNChartIcon', theme);
        switch (alertPoint.newsType) {
            case 'good':
                icon = getChartIcon('alertGChartIcon', theme);
                break;
            case 'bad':
                icon = getChartIcon('alertBChartIcon', theme);
        }
        if (alertPoint.metadata?.workflow_status_state == 'closed') {
            icon = getChartIcon('alertClosedChartIcon', theme);
        }

        return {
            x: seriesPoint.x * DATA_X_MULTIPLIER,
            y: chart.yAxis[0].toValue(toPixels),
            marker: {
                enabled: false,
            },
            metadata: alertPoint.metadata,
            iconNumber: iconNumber,
            pointId: `alert_${seriesPoint.x}_${iconNumber}`,
            iconSvg: strToSvg(icon),
            text: alertPoint.text,
        } as ChartPointAlert;
    };

    const iconsDateMap: AssocArray<number> = {};
    alertSeries.forEach((alert) => {
        const chartSeries = series.find((s) => s.id == alert.id);
        if (!chartSeries) {
            return;
        }

        const chartSeriesData = chartSeries.data.filter((row) => {
            if (!elementRow) {
                return true;
            }
            return !(
                (elementRow.metricMaxValueToChart && row.y > Number(elementRow.metricMaxValueToChart)) ||
                (elementRow.metricMinValueToChart && row.y < Number(elementRow.metricMinValueToChart))
            );
        });

        alert.data.forEach((alertPoint) => {
            alertPoint.metadata = alertPoint.mydata ?? null;

            const point = chartSeriesData.find((seriesPoint) => seriesPoint.x == alertPoint.x);
            if (point) {
                if (typeof iconsDateMap[point.x] == 'undefined') {
                    iconsDateMap[point.x] = 0;
                }
                data.push(buildAlertPoint(point, alertPoint, iconsDateMap[point.x]));
                iconsDateMap[point.x]++;
            }
        });
    });

    const id = 'alerts';
    return [
        {
            seriesType: 'alert',
            data: data,
            type: 'scatter',
            id: 'alerts',
            name: i18n.t('alerts'),
            dashStyle: 'Dot',
            color: 'red',
            zIndex: -1,
            //@ts-ignore
            lineWidth: 0,
            marker: {
                enabled: true,
                symbol: svgElementFromString(getChartIcon('alertGChartIcon', theme)),
            },
            states: {
                hover: {
                    lineWidthPlus: 0,
                    enabled: false,
                },
            },
            visible: legendState ? (legendState[id]?.visible ?? true) : true,
        },
    ];
}
