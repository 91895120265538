import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        position: 'fixed',
        maxWidth: '375px',
        width: '100%',
        border: 'solid 1px',
        boxShadow: 'rgb(0 0 0 / 7%) 3px 3px 10px 0',
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        zIndex: (theme: Theme) => theme.zIndex.drawer + 999,
        bottom: '-10px',
        right: '20px',
        padding: '0',
        lastChild: {
            paddingBottom: '0',
        },
    },
    rating: {
        wrapper: {
            padding: '10px',
            border: 'solid 1px',
            borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
    },
};
