import React, { useContext, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import {
    getFieldOptionsForSelect,
    getFilterConditionVariants,
    PluginQBContext,
} from 'components/plugin-query-builder/PluginQueryBuilder';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';

export interface QueryBuilderFilterPopupProps {
    onClose: () => void;
    onApply: () => void;
    editElement?: any;
}

export default function QueryBuilderFilterPopup(props: QueryBuilderFilterPopupProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { onClose = () => {}, onApply = () => {}, editElement } = props;
    const { reportData, reportAct, queryData, queryAct, configData, helperAct, pluginConfig } =
        useContext(PluginQBContext);

    const isAdd = !editElement;

    const [conditionVal, setConditionVal] = useState<string>(isAdd ? '' : editElement.condition);
    const [valueData, setValueData] = useState<string>('');
    const [fieldVal, setFieldVal] = useState<string>(isAdd ? '' : editElement.column);
    const [dateValType, setDateValType] = useState<string>('pattern');
    const [filterTypeVal, setFilterTypeVal] = useState<string>(
        isAdd ? 'after' : editElement.type === 'before fetch' ? 'before' : 'after',
    );

    const [fieldBeforeVal, setFieldBeforeVal] = useState<string>(isAdd ? '' : editElement.column);
    const [valueBeforeData, setValueBeforeData] = useState<string>(isAdd ? '' : editElement.value);

    //tableauFilterColumns
    const [beforeFilterColumns, setBeforeFilterColumns] = useState<any[]>([]);

    const conditionVariants = getFilterConditionVariants(pluginConfig);

    let conditionOptions: any[] = [];
    Object.values(conditionVariants).forEach((conditionSettings: any) => {
        const isAvailableForFieldType =
            !pluginConfig.checkFilterConditionByFieldType || //turn off check like qlik, cogos ad etc
            !conditionSettings.filedType || //has no condition, available for all fields
            conditionSettings.filedType.includes(reportData.fields[fieldVal]?.type); //pass condition
        if (isAvailableForFieldType) {
            conditionOptions.push({
                value: conditionSettings.value,
                label: conditionSettings.label ? t(conditionSettings.label) : conditionSettings.value,
            });
        }
    });

    const fieldsOptions: any = getFieldOptionsForSelect(reportData.fields, helperAct, pluginConfig);

    const getCalendarData = (data: string): string => {
        if (!data.match(/\$[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) {
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        }
        return data;
    };
    const elementProps = useCustomSimplifiedForm({
        ruleDataCalendar: '',
    });

    useEffect(() => {
        if (!isAdd) {
            if (reportData.fields[fieldVal].type !== 'DATE') {
                setValueData(helperAct.escapeHtml(editElement.value));
            } else {
                if (configData.pattern && editElement.value == helperAct.escapeHtml(configData.pattern)) {
                    setValueData(helperAct.escapeHtml(configData.pattern));
                    setDateValType('pattern');
                } else {
                    elementProps.form.hookFormSetValue('ruleDataCalendar', helperAct.escapeHtml(editElement.value));
                    setDateValType('date');
                    setValueData('');
                }
            }
        }
        const tableauFilterColumns: any[] = [];
        Object.keys(reportData.fields).forEach((fieldName) => {
            const fieldItem = reportData.fields[fieldName];
            if (fieldItem.isDimension) {
                tableauFilterColumns.push(helperAct.escapeHtml(fieldItem.name));
            }
            /*if (page.editor.query.tableauFilter && page.editor.query.tableauFilter.column === reportFields[field].name) {
                    option = '<option value="' + reportFields[field].name + '" selected="selected">' + reportFields[field].name + '</option>'
                }*/
        });
        setBeforeFilterColumns(tableauFilterColumns);
    }, []);

    //queryData.tableauFilter = prefilteringIsAddded
    const isAvailableBeforeFilter = configData.prefiltering && (!queryData.tableauFilter || !isAdd);

    const filterTypeOptions = [...['after'], ...(isAvailableBeforeFilter ? ['before'] : [])].map((type: any) => {
        return {
            value: type,
            label: type,
        };
    });

    //tableauFilterColumns
    const fieldsBeforeOptions = beforeFilterColumns.map((name: any) => {
        return {
            value: name,
            label: name,
        };
    });

    return (
        <Popup
            settings={{
                title: isAdd ? t('filter_popup.add.title') : t('filter_popup.edit.title'),
                textOK: isAdd ? t('filter_popup.add.ob_btn') : t('filter_popup.edit.ob_btn'),
                maxWidth: 'popupLg',
            }}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                if (filterTypeVal == 'after') {
                    if (!fieldVal) {
                        alert(t('filter_popup.alert_select_column'));
                        return false;
                    }

                    if (!conditionVal) {
                        alert(t('filter_popup.alert_select_condition'));
                        return false;
                    }

                    let value: any = valueData;
                    let condition: any = conditionVal;

                    if (reportData.fields[fieldVal].type === 'DATE') {
                        if (configData.pattern && dateValType == 'pattern') {
                            value = helperAct.escapeHtml(configData.pattern);
                        } else {
                            value = elementProps.form.hookFormGetValues('ruleDataCalendar');
                            if (!value) {
                                alert(t('filter_popup.alert_choose_date'));
                                return false;
                            }
                        }
                    }
                    if (['is null', 'is not null'].includes(conditionVal)) {
                        value = null;
                    }

                    if (!isAdd && !(editElement.condition != conditionVal || editElement.value != value)) {
                        //Edit without changes. Close popup
                        onClose();
                        return false;
                    }

                    const isStrictDuplicate =
                        queryData.filters.findIndex((item: any) => {
                            return item.column == fieldVal && item.condition == conditionVal && item.value == value;
                        }) !== -1;

                    if (isStrictDuplicate) {
                        if (isAdd) {
                            alert(
                                pluginConfig.isNonStrictCheckFilterDuplicates
                                    ? t('filter_popup.alert_duplicate_non_strict')
                                    : t('filter_popup.alert_duplicate'),
                            );
                            return false;
                        } else {
                            alert(t('filter_popup.alert_duplicate_edit'));
                            return false;
                        }
                    }

                    const isEqualCondition = ['==', 'in'].includes(conditionVal);
                    //Non Strict duplicates
                    let duplicates = pluginConfig.isNonStrictCheckFilterDuplicates
                        ? queryData.filters
                              .filter((item: any) => {
                                  //skip current Edit Element
                                  if (isAdd) {
                                      return true;
                                  } else {
                                      return !(
                                          item.column === editElement.column &&
                                          item.condition === editElement.condition &&
                                          item.value === editElement.value
                                      );
                                  }
                              })
                              .filter((item: any) => {
                                  const conditionToCheck =
                                      !pluginConfig.removeFilterConditionIn && isEqualCondition
                                          ? ['==', 'in']
                                          : [conditionVal];
                                  return item.column == fieldVal && conditionToCheck.includes(item.condition);
                              })
                        : [];

                    let newFilters = [...queryData.filters];
                    if (!pluginConfig.removeFilterConditionIn && duplicates.length > 0) {
                        if (
                            isEqualCondition &&
                            reportAct.hasField(fieldVal) &&
                            reportData.fields[fieldVal].type !== 'DATE'
                        ) {
                            let values = duplicates.map((filter: any) => filter.value);
                            if (isAdd) {
                                values.push(value);
                            } else {
                                values.unshift(value);
                            }

                            const uniqueArray = (array: string[]) => {
                                return array.filter((value, index, arr) => {
                                    return arr.indexOf(value) === index;
                                });
                            };
                            value = uniqueArray(values.join(', ').split(/\s*,\s*/)).join(', ');
                            condition = 'in';
                            duplicates.forEach((filter: any) => {
                                newFilters = queryAct.removeFilter(filter);
                            });
                            duplicates = [];
                        }
                    }

                    if (isAdd) {
                        if (duplicates.length > 0) {
                            alert(t('filter_popup.alert_duplicate_non_strict'));
                            return false;
                        }
                    } else {
                        if (duplicates.length > 0) {
                            alert(t('filter_popup.alert_duplicate_edit'));
                            return false;
                        }
                    }

                    const newFilterData = {
                        type: 'after fetch',
                        column: fieldVal,
                        condition: condition,
                        value: value,
                    };
                    if (isAdd) {
                        queryAct.addFilter(newFilterData);
                    } else {
                        const editFilterIndex = newFilters.findIndex((item: any) => {
                            return (
                                item.column === editElement.column &&
                                item.condition === editElement.condition &&
                                item.value === editElement.value
                            );
                        });

                        newFilters.splice(editFilterIndex, 1, newFilterData);
                        queryAct.updateData({ filters: newFilters });
                    }
                } else if (filterTypeVal == 'before') {
                    const newFilterBeforeData = {
                        type: 'before fetch',
                        column: fieldBeforeVal,
                        condition: '',
                        value: valueBeforeData,
                    };
                    if (isAdd) {
                        queryAct.addFilter(newFilterBeforeData);
                    } else {
                        let newFilters = [...queryData.filters];
                        const editFilterIndex = newFilters.findIndex((item: any) => {
                            return (
                                item.column === editElement.column &&
                                item.condition === editElement.condition &&
                                item.value === editElement.value
                            );
                        });

                        newFilters.splice(editFilterIndex, 1, newFilterBeforeData);
                        queryAct.updateData({ filters: newFilters });
                    }
                }

                onApply();
            }}
        >
            <Box>
                {isAdd && isAvailableBeforeFilter && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Box>{t('filter_popup.label_apply_filter')}</Box>
                        <Box>
                            <ReactSelect
                                data={filterTypeOptions}
                                value={filterTypeVal}
                                update={(type) => {
                                    setFilterTypeVal(type);
                                }}
                            />
                        </Box>
                        <Box>{t('filter_popup.label_apply_filter_suffix', { title: configData.title })}</Box>
                    </Stack>
                )}
                {filterTypeVal === 'after' && (
                    <Stack direction={'row'} spacing={2}>
                        <Box sx={{ width: '250px' }}>
                            <Box>{t('filter_popup.label_filter_on')}</Box>

                            {isAdd ? (
                                <ReactSelect
                                    data={fieldsOptions}
                                    value={fieldVal}
                                    update={(name) => {
                                        setDateValType(configData.pattern ? 'pattern' : 'date');
                                        elementProps.form.hookFormSetValue('ruleDataCalendar', '');
                                        setValueData('');
                                        setFieldVal(name);
                                    }}
                                />
                            ) : (
                                <Tooltip title={editElement.column}>
                                    <Box
                                        sx={{
                                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                            height: (theme) => theme.size.defaultHeight,
                                            display: 'flex',
                                            alignItems: 'center',
                                            px: 1,
                                        }}
                                    >
                                        {editElement.column}
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                        <Box sx={{ width: '130px' }}>
                            <Box>{t('filter_popup.label_condition')}</Box>
                            <ReactSelect
                                data={conditionOptions}
                                value={conditionVal}
                                update={(val) => {
                                    if (val == 'is null' || val == 'is not null') {
                                        setValueData('');
                                    }
                                    setConditionVal(val);
                                }}
                            />
                        </Box>
                        {conditionVal !== 'is null' && conditionVal !== 'is not null' && (
                            <Box>
                                <Box>{t('filter_popup.label_value')}</Box>
                                {reportData.fields[fieldVal]?.type === 'DATE' ? (
                                    <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                        {configData.pattern && (
                                            <RadioGroup
                                                sx={{ flexWrap: 'nowrap', mr: -2 }}
                                                row
                                                name="row-radio-buttons-group"
                                                value={dateValType}
                                                onChange={(event) => {
                                                    setDateValType((event.target as HTMLInputElement).value);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="pattern"
                                                    control={<Radio />}
                                                    label={helperAct.escapeHtml(configData.pattern)}
                                                />
                                                <FormControlLabel value="date" control={<Radio />} label="" />
                                            </RadioGroup>
                                        )}
                                        <ReactHookFormController
                                            elementProps={prepareFormElementProps({
                                                ...elementProps,
                                                component: {
                                                    component: 'FormDatePicker',
                                                    name: 'ruleDataCalendar',
                                                    props: {
                                                        placeholder: t('query_builder_popup.date_placeholder'),
                                                        showTimeSelect: configData.interval === 'minute',
                                                        timeIntervals: 1,
                                                    },
                                                },
                                            })}
                                        />
                                    </Box>
                                ) : (
                                    <TextField
                                        value={valueData}
                                        onChange={(event) => {
                                            setValueData(event.target.value);
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                    </Stack>
                )}
                {filterTypeVal === 'before' && (
                    <Stack direction={'row'} spacing={2}>
                        <Box sx={{ width: '250px' }}>
                            <Box>{t('filter_popup.label_filter_on')}</Box>

                            {isAdd ? (
                                <ReactSelect
                                    data={fieldsBeforeOptions}
                                    value={fieldBeforeVal}
                                    update={(name) => {
                                        setValueBeforeData('');
                                        setFieldBeforeVal(name);
                                    }}
                                />
                            ) : (
                                <Tooltip title={editElement.column}>
                                    <Box
                                        sx={{
                                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                            height: (theme) => theme.size.defaultHeight,
                                            display: 'flex',
                                            alignItems: 'center',
                                            px: 1,
                                        }}
                                    >
                                        {editElement.column}
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                        <Box>
                            <Box>{t('filter_popup.label_value')}</Box>
                            <TextField
                                value={valueBeforeData}
                                onChange={(event) => {
                                    setValueBeforeData(event.target.value);
                                }}
                            />
                        </Box>
                    </Stack>
                )}
            </Box>
        </Popup>
    );
}
