import {
    FormComponentValue,
    FormControlAsyncProps,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';

export type ColumnFieldType = 'request' | 'manual' | 'text';

export interface SelectMapSource {
    type: ColumnFieldType;
    url: string;
    manualData: Array<FormComponentValue>;
}

export interface SelectMapColumn {
    source: SelectMapSource;
    label: string;
}

export interface RowValues {
    firstColumn: string;
    secondColumn: string;
    secondColumnNested?: string;
    data: any;
}

export interface RowListColumn {
    type: ColumnFieldType;
    values: Array<FormComponentValue>;
}
export interface RowList {
    rowValues: RowValues;
    firstColumn: RowListColumn;
    secondColumn: RowListColumn;
}

export type UniqueRule = 'none' | 'first' | 'second' | 'both';

export interface SelectMapData {
    handleGearClick?: (data: any, setData: (data: any) => void) => void;
    firstColumn: SelectMapColumn;
    secondColumn: SelectMapColumn;
    actualValues: Array<RowValues>;
    uniqueRule: UniqueRule;
    hasEmptyValue?: boolean;
    autoAddFirstRow?: boolean;
}

export interface FormControlSelectMapProps extends FormControlAsyncProps<SelectMapData> {
    afterChange?: (value: any) => void;
    overrideFirstColumnType?: ColumnFieldType;
    overrideSecondColumnType?: ColumnFieldType;
}

export type ColumnName = 'first' | 'second' | 'secondNested';

export function getSMFilterColumnValues(
    columnValues: Array<FormComponentValue>,
    actualValues: Array<RowValues>,
    uniqueRule: UniqueRule,
    columnName: ColumnName,
    selectedValue: string | false,
): Array<FormComponentValue> {
    const isUnique = uniqueRule == 'both' || columnName == uniqueRule;

    const selectedList = actualValues.map((v) => {
        switch (columnName) {
            case 'first':
                return v.firstColumn;
            case 'second':
                return v.secondColumn;
            case 'secondNested':
                return v.secondColumnNested;
        }
    });

    // + (v.secondColumnNested ?? '')

    return columnValues.filter((cv) => {
        let cValue = cv.value;

        if (selectedValue === false && cValue === '') {
            return false;
        }
        if (cValue == selectedValue || cValue === '') {
            return true;
        }

        if (!(isUnique && selectedList.includes(cValue))) {
            return true;
        }

        if (columnName == 'second' && cv?.props?.nestedValues) {
            const actualNestedValues = actualValues
                .filter((v) => v.secondColumn == cValue)
                .map((v) => v.secondColumnNested);

            return prepareFormComponentValues(cv.props.nestedValues).length > actualNestedValues.length;
        } else {
            return false;
        }
    });
}
