import React, { useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { Box, Typography } from '@mui/material';
import { AsyncActionData, gridAPI } from 'api/grid';
import { getCellContent, getCellWithTooltip } from './index';
import { APIResponse } from 'tools/types';
import { openInNewTab } from 'tools/tools';
import { useNavigate } from 'react-router-dom';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { downloadAPI } from 'api/api';

export default function AsyncActionCell({
    text,
    tooltip,
    subText,
    icon,
    href,
    reloadGridData,
    setShowLoader,
    rawHtml,
    align,
    rawTooltipHtml,
    t,
    setErrorMessage,
    confirmation,
    popupConfig,
    download,
}: CustomCellProps) {
    const navigate = useNavigate();
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    if (undefined === t) t = useBundleTranslation('main')['t'];

    const onClick = () => {
        if (confirmation && confirmation > '') {
            setOpenConfirmation(true);
            return;
        }
        doAction();
    };

    const doAction = () => {
        if (href && reloadGridData && setShowLoader) {
            if (setErrorMessage) {
                setErrorMessage('');
            }

            if (download === true) {
                downloadAPI.export.getDownload(href);

                return;
            }

            setShowLoader(true);
            gridAPI
                .asyncAction(href)
                .then((response: APIResponse<AsyncActionData>) => {
                    if (response.status === 'OK') {
                        const responseData = response.data;

                        if (responseData.reloadGridData) {
                            reloadGridData();
                        }

                        if (responseData.showConfirmation) {
                            alert(responseData.message);
                        }

                        if (responseData.href) {
                            if (responseData.newTab) {
                                openInNewTab(responseData.href);
                            } else {
                                navigate(responseData.href);
                            }
                        }
                    }
                    if (response.status === 'ERROR') {
                        if (setErrorMessage) {
                            const defaultMessage = t ? t('error') : 'Error';
                            setErrorMessage(response.message ?? defaultMessage);
                        }
                    }
                })
                .finally(() => {
                    setShowLoader(false);
                });
        }
    };

    let mainText = (
        <Box component={'a'} onClick={onClick} sx={{ cursor: 'pointer' }}>
            {getCellContent(text, icon, rawHtml, align, subText)}
        </Box>
    );

    if (tooltip) {
        mainText = getCellWithTooltip(mainText, tooltip, rawTooltipHtml, t);
    }
    const popupSettings: PopupSettings = {
        title: popupConfig?.title ?? 'confirmation',
        textOK: popupConfig?.textOK ?? 'ok',
    };
    return (
        <>
            {mainText}
            {openConfirmation && (
                <Popup
                    settings={popupSettings}
                    open={openConfirmation}
                    t={t}
                    onHide={() => setOpenConfirmation(false)}
                    onConfirm={() => {
                        setOpenConfirmation(false);
                        doAction();
                    }}
                    maxWidth={'popupSm'}
                >
                    <Typography>{t(confirmation ?? '', { name: 'itemName' })}</Typography>
                </Popup>
            )}
        </>
    );
}
