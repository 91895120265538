import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { processUrlBackwardCompatibility } from 'components/backward-compatibility-router/index';
import Error404Page from 'app/error/Error404Page';

export default function RedirectPublicPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();
    const [newUrl, setNewUrl] = useState<string | null>(null);

    useEffect(() => {
        let entityKey = params.entityType ?? '';
        if (!entityKey) {
            if (location.pathname.includes('/auth/index/login')) {
                entityKey = 'login';
            }
            if (location.pathname.includes('/auth/index/signed-out')) {
                entityKey = 'signed-out';
            }
            if (location.pathname.includes('/auth/index/change')) {
                entityKey = 'change-pass';
            }
            if (location.pathname.includes('/auth/index/resetpass')) {
                entityKey = 'reset-pass';
            }
            if (location.pathname.includes('/auth/index/deny')) {
                entityKey = 'deny';
            }
        }

        const processedUrl = processUrlBackwardCompatibility('public', entityKey, location, params);
        setNewUrl(processedUrl);
        if (processedUrl) {
            navigate(processedUrl);
            return;
        }
    }, []);

    if (newUrl === null) return null;

    return <Error404Page />;
}
