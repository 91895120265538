import { CustomCellProps } from 'components/common/grid';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import React from 'react';

export default function CategoryNameCell({ text, href }: CustomCellProps) {
    if (!href) {
        return text;
    }

    const parts = text?.split(' / ');

    if (parts === undefined) {
        return (
            <Typography noWrap sx={{ py: 1 }}>
                <Box component={Link} to={href} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    {text}
                </Box>
            </Typography>
        );
    }

    const lastIndex = parts.length - 1;
    const nextToLastIndex = parts.length - 2;

    if (parts.length < 4) {
        return (
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Box>
                    <Typography noWrap sx={{ py: 1 }}>
                        {parts.map((part, index) => {
                            if (index === lastIndex) {
                                return (
                                    <Box
                                        key={index}
                                        component={Link}
                                        to={href}
                                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                                    >
                                        {part}
                                    </Box>
                                );
                            } else {
                                return `${part} / `;
                            }
                        })}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (parts.length >= 4) {
        return (
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Box>
                    <Typography noWrap sx={{ py: 1 }}>
                        {`${parts[0]} / .. / ${parts[nextToLastIndex]} / `}
                        <Box component={Link} to={href} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            {parts[lastIndex]}
                        </Box>
                    </Typography>
                </Box>
            </Box>
        );
    }
}
