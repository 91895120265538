export default {
    root: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    action: {
        width: '90px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    input: {
        width: 'calc(100% - 90px)',

        '.MuiInput-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
};
