import { getElementIcon, getFolderIcon, TreeNodeProps } from '../common/tree/DefaultNode';
import { TreeNode } from './index';
import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import { RenderParams } from '@minoru/react-dnd-treeview/dist/types';
import Highlighter from 'react-highlight-words';
import style from './ReferenceSelectionTreeNode.style';

interface NodeActionType {
    onClick: (node: TreeNode) => void;
    onToggle: (node: TreeNode, newState: boolean) => void;
    openState: Set<number | string>;
}

export default function ReferenceSelectionTreeNode({
    node,
    onExpandIconClick,
    onNodeClick,
    searchPattern,
    isSelected,
}: {
    node: TreeNode;
    onExpandIconClick: any;
    onNodeClick: any;
    searchPattern?: string;
    isSelected?: boolean;
}) {
    const params = {
        isOpen: Boolean(node?.state?.expanded ?? false),
    } as RenderParams;

    const isSelectable =
        node.data?.type === 'reference' || (node.data?.type == 'rollup' && node.data.linked_ref_id > 0);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            style={{ minHeight: '28px' }}
            spacing={1}
            className={`tree-mi__item-content ${isSelectable ? 'tree-mi__item-content-element' : ''}
            ${isSelected ? ' tree-mi__item-selected' : ''}
            `}
            sx={style}
        >
            <Stack
                data-test={'tree_' + (node.data?.type == 'rollup' ? 'folder_' : 'item_') + node.id}
                direction="row"
                alignItems="center"
                spacing="6px"
                flexGrow={1}
            >
                {node.data?.type == 'rollup' && (
                    <IconButton onClick={() => onExpandIconClick(node)} sx={{ p: 0 }}>
                        {getFolderIcon(params)}
                    </IconButton>
                )}
                {node.data?.type == 'reference' && getElementIcon()}
                <Box onClick={() => onNodeClick(node)} component="span">
                    {searchPattern?.length ? (
                        <Highlighter autoEscape searchWords={[searchPattern.trim()]} textToHighlight={node.name} />
                    ) : (
                        node.name
                    )}
                </Box>
            </Stack>
        </Stack>
    );
}
