import React from 'react';
import { Box } from '@mui/material';

export default function EmailLine({
    email,
    ownerName,
    label,
    elementName,
}: {
    email: string;
    ownerName: string;
    elementName: string;
    label?: string;
}) {
    if (!ownerName) {
        return <></>;
    }

    const labelPart = label?.length ? label + ': ' : '';

    return (
        <>
            <Box component={'span'} className={'fixed-label'}>
                {labelPart}
            </Box>
            <Box component={'span'} className={'fixed-value'}>
                {email && elementName ? (
                    <a href={`mailto:${email}?subject=${elementName}`}>{ownerName}</a>
                ) : (
                    <>{ownerName}</>
                )}
            </Box>
        </>
    );
}
