export const MuiSvgIconConfig = {
    defaultProps: {
        fontSize: 'inherit',
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                '&.svg-icon-mi.MuiSvgIcon-fontSize12': {
                    fontSize: '12px',
                },
                '&.svg-icon-mi.MuiSvgIcon-fontSize14': {
                    fontSize: '14px',
                },
                '&.svg-icon-mi.MuiSvgIcon-fontSize15': {
                    fontSize: '15px',
                },
                '&.svg-icon-mi.MuiSvgIcon-fontSize16, .MuiButton-startIcon>&.MuiSvgIcon-fontSize16': {
                    fontSize: '16px',
                },
            };
        },
    },
};
