import React, { useRef, useState } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ReportChartPoint = ReportContentNS.ReportChartPoint;
import { Box } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';

export function useDatasetReportTooltip() {
    // Used for Chart Tooltip
    const [hoveredChartPoint, setHoveredChartPoint] = useState<ReportChartPoint | null>(null);
    const [selectedChartPoint, setSelectedChartPoint] = useState<ReportChartPoint | null>(null);
    const [pointTooltipSeriesIndex, setPointTooltipSeriesIndex] = useState<number>(0);
    const tooltipIndex = useRef(0);

    const handlePointClick = function (point: ReportChartPoint, seriesIndex: number) {
        setSelectedChartPoint(point);
        setPointTooltipSeriesIndex(seriesIndex);
    };

    const handlePointMouseOver = function (point: ReportChartPoint, seriesIndex: number) {
        if (selectedChartPoint) {
            return;
        }
        setHoveredChartPoint(point);
        tooltipIndex.current++;
        setPointTooltipSeriesIndex(seriesIndex);
    };

    const handlePointMouseOut = function () {
        const index = tooltipIndex.current;
        setTimeout(() => {
            if (selectedChartPoint || index != tooltipIndex.current) {
                return;
            }
            setHoveredChartPoint(null);
        }, 10);
    };

    const handlePointClose = function () {
        setSelectedChartPoint(null);
        setHoveredChartPoint(null);
    };

    const pointToShow = selectedChartPoint ? selectedChartPoint : hoveredChartPoint;

    const getTooltipPointEvents = () => ({
        events: {
            mouseOver: function () {
                // @ts-ignore
                handlePointMouseOver(this, 0);
            },
            mouseOut: () => handlePointMouseOut(),
            click: function () {
                // @ts-ignore
                handlePointClick(this, 0);
            },
        },
    });

    const closePointComponent = (
        <Box onClick={handlePointClose} sx={{ cursor: 'pointer' }}>
            <IconMi icon="times" fontSize="16" />
        </Box>
    );

    const setIsHoveredOnTooltip = () => {
        tooltipIndex.current++;
    };

    const isPointSelect = selectedChartPoint != null;

    return { pointToShow, getTooltipPointEvents, closePointComponent, isPointSelect, setIsHoveredOnTooltip };
}
