import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { APIResponse, YNValues } from 'tools/types';
import { instance } from 'api/api';

export default function useHasImageToRemove(componentName: string, hasImageUrl: string) {
    const [hasImageToRemove, setHasImageToRemove] = useState(false);
    const { data, refetch } = useQuery<AxiosResponse<APIResponse<{ has_image: YNValues }>>, Error>({
        queryKey: [componentName, 'hasImage'],
        queryFn: () => instance.get(hasImageUrl),
    });
    useEffect(() => {
        if (!data) {
            return;
        }
        setHasImageToRemove(data?.data?.data?.has_image == 'Y');
    }, [data]);

    return { hasImageToRemove, setHasImageToRemove, refetch };
}
