import React from 'react';
import { alpha, Select } from '@mui/material';
import { optionItem } from 'components/common/transfer-list/TransferList';
import { useBlendColors } from 'hooks/useDesign';

interface TransferListSelectProps {
    onChange: (event: any) => void;
    onDoubleClick: (event: any) => void;
    value: string[];
    options: optionItem[];
}

export default function TransferListSelect(props: TransferListSelectProps) {
    const { onChange = () => {}, onDoubleClick = () => {}, value, options } = props;

    return (
        <Select
            sx={{
                height: 176,
                alignItems: 'normal',
                mt: '-1px',
                select: {
                    padding: '0 !important',
                },
                option: {
                    padding: '4px 8px',
                },
                fieldset: {
                    borderRadius: 1,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderTop: 0,
                },
                '.Mui-focused fieldset, fieldset': {
                    border: (theme) => `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))} !important`,
                },
            }}
            multiple
            native
            value={value}
            // @ts-ignore Typings are not considering `native`
            onChange={onChange}
        >
            {options.map((item) => (
                <option key={item.value} value={item.value} disabled={item.disabled} onDoubleClick={onDoubleClick}>
                    {item.label}
                </option>
            ))}
        </Select>
    );
}
