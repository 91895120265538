import { CustomCellProps } from 'components/common/grid';
import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getCellContent } from 'components/common/grid/cell';
import { useFormState } from 'react-hook-form';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import FormComponent from 'components/common/form/Form';
import { instance } from 'api/api';
import pollingService from 'tools/pollingService';
import useBundleTranslation from 'i18n';

export default function Refetch({
    text,
    reloadGridData,
    setShowLoader,
    form,
    href,
    popupComponentProps,
}: CustomCellProps) {
    const { t } = useBundleTranslation(['app/editor/element/metric/refetch']);

    const { isDirty } = useFormState({ control: form?.hookFormControl });
    const navigate = useNavigate();
    const urlParams = useParams();

    const metricId = urlParams.elementId;
    const dataFetchMethod = form?.hookFormWatch('data_fetch_method');
    const segmentValueId = popupComponentProps?.segmentValueId ?? '0';

    const [showConfirm, setShowConfirm] = useState(false);
    const [showRefetchConfirm, setShowRefetchConfirm] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [refetchData, setRefetchData] = useState<{
        segment_id: string;
        pattern_subst: string;
        records: number;
    } | null>(null);

    const confirmPopupSettings: PopupSettings = {
        title: t('confirmation'),
    };

    const refetchConfirmPopupSettings: PopupSettings = {
        title: t('confirmation'),
        textOK: t('confirm_button'),
    };

    const formPopupConfig: PopupSettings = {
        title: t('parameters_substitution'),
        maxWidth: 'popupSm',
    };

    const onClick = () => {
        if (form && isDirty) {
            setShowConfirm(true);
            return;
        }

        if (dataFetchMethod === 'manual') {
            navigate(`/editor/element/metric/${metricId}/manual`);
            return;
        }

        setShowForm(true);
    };

    const onConfirm = () => {
        if (form) {
            if (setShowLoader) {
                setShowLoader(true);
            }
            form.formSave()
                .then((response) => {
                    if (response?.data?.status == 'OK') {
                        onClick();
                    }
                })
                .finally(() => {
                    if (setShowLoader) {
                        setShowLoader(false);
                    }
                    setShowConfirm(false);
                });
        }
    };

    const handleHideFormPopup = () => {
        setShowForm(false);
    };

    const afterFormSave = (response: any) => {
        if (response?.data?.status == 'OK' && segmentValueId !== '0') {
            const records = response?.data?.records ?? 0;

            setRefetchData({ segment_id: segmentValueId, pattern_subst: response?.data.from, records });

            if (records > 0) {
                setShowRefetchConfirm(true);
            } else {
                onRefetchCall();
            }
        }
    };

    const onError = (data: { message: string }) => {
        if (setShowLoader) {
            setShowLoader(false);
        }
        alert(data.message);
    };

    const onSuccess = () => {
        if (setShowLoader) {
            setShowLoader(false);
        }
        if (reloadGridData) {
            reloadGridData();
        }
    };

    const onRefetchCall = () => {
        if (setShowLoader) {
            setShowLoader(true);
        }
        instance
            .post(`/data/editor/metric/${metricId}/collect`, refetchData)
            .then((response) => {
                if (response.data.status == 'QUEUE') {
                    const { run } = pollingService<{ message: string }, {}>({
                        onSuccess,
                        onError,
                        requestData: {},
                    });

                    run(response.data.data.ppl_id);
                } else {
                    alert(response.data.message);
                }
            })
            .finally(() => {
                setShowRefetchConfirm(false);
            });
    };

    return (
        <Box sx={{ position: 'absolute' }}>
            <Box component="a" onClick={onClick} sx={{ cursor: 'pointer' }}>
                {getCellContent(text)}
            </Box>
            {showConfirm && (
                <Popup
                    maxWidth={'popupSm'}
                    settings={confirmPopupSettings}
                    open={showConfirm}
                    onHide={() => setShowConfirm(false)}
                    onConfirm={onConfirm}
                >
                    <Typography>{t('save_confirm')}</Typography>
                </Popup>
            )}
            {showRefetchConfirm && (
                <Popup
                    maxWidth={'popupSm'}
                    settings={refetchConfirmPopupSettings}
                    open={showRefetchConfirm}
                    onHide={() => setShowRefetchConfirm(false)}
                    onConfirm={onRefetchCall}
                >
                    <Stack>
                        <Typography>
                            {t('refetch_confirm', {
                                pattern_subst: refetchData?.pattern_subst,
                                records: refetchData?.records,
                            })}
                        </Typography>
                        <Typography>{t('proceed')}</Typography>
                    </Stack>
                </Popup>
            )}
            {showForm && href && (
                <FormComponent
                    isPopup
                    popupType={'edit'}
                    pk={href}
                    uid={href}
                    settingsUrl={href}
                    onHide={handleHideFormPopup}
                    popupSettings={formPopupConfig}
                    afterSave={afterFormSave}
                />
            )}
        </Box>
    );
}
