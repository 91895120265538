import { createSlice } from '@reduxjs/toolkit';

interface pageMenuSliceState {
    name: string | null;
    subName?: string | null;
    options: {
        current: string | null;
        placeholder: string | null;
        list: any | null;
        view?: 'select' | 'tab';
    };
}

export const pageMenuSlice = createSlice({
    name: 'pageMenu',
    initialState: {
        name: null,
        subName: null,
        options: {
            current: null,
            list: null,
            placeholder: null,
            view: 'select',
        },
    } as pageMenuSliceState,
    reducers: {
        setPageMenu: (state, action) => {
            state.name = action.payload ?? null;
        },
        setPageMenuSubItem: (state, action) => {
            state.subName = action.payload ?? null;
        },
        setPageMenuOptions: (state, action) => {
            state.options.current = action.payload?.current ?? null;
            state.options.list = action.payload?.list ?? null;
            state.options.view = action.payload?.view ?? 'select';
            state.subName = action.payload?.placeholder ?? null;
        },
        setPageMenuOptionsCurrent: (state, action) => {
            state.options.current = action.payload ?? null;
        },
    },
});

export const { setPageMenu, setPageMenuSubItem, setPageMenuOptions, setPageMenuOptionsCurrent } = pageMenuSlice.actions;

export default pageMenuSlice.reducer;
