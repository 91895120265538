import { FormRendererAPIType } from 'components/common/form/index';
import React, { useRef } from 'react';

export interface GenericValidationResult {
    status: boolean;
    data: any;
}

export interface GenericCollectionResult {
    status: boolean;
    data: any;
}

export interface ProcessValidationResultPromise {
    status: boolean;
    data: any;
}

export interface ProcessCollectionResultPromise {
    status: boolean;
    data: any;
}

export type LegacyHandleValidateType = (ignoreLastValidationData: boolean) => Promise<GenericValidationResult>;
export type LegacyHandleCollectType = (
    ignoreLastCollectionData: boolean,
    openViewer: boolean,
    buildReport: boolean,
) => Promise<GenericCollectionResult>;

export function useDataValidateCollect(form: FormRendererAPIType) {
    const legacyHandleValidate = useRef<LegacyHandleValidateType>(null);
    const legacyHandleCollect = useRef<LegacyHandleCollectType>(null);
    return { legacyHandleValidate, legacyHandleCollect };
}

export const DataValidateCollectContext = React.createContext<ReturnType<typeof useDataValidateCollect> | null>(null);
