import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { useNavigate } from 'react-router-dom';
import { alertRuleAPI } from 'api/alert-wizard';

interface metricOption {
    value: number | string;
    label: string;
}

interface popupMainData {
    element: {
        element_id: number | string;
        name: string;
    };
    metric_elements: metricOption[];
}

export interface AddAlertMetric4ReportPopupProps {
    onClose: (event: any) => void;
    onApply: (selectedMetric: string) => void;
    reportId: number;
    userId?: number;
}
//AlertRuleList2.openAddAlertMetric4Report
//engine/application/notification/views/scripts/alertrule/getalertmetric4report.phtml
export default function AddAlertMetric4ReportPopup(props: AddAlertMetric4ReportPopupProps) {
    const { onClose = () => {}, onApply = () => {}, reportId, userId } = props;

    const { t } = useBundleTranslation(['components/alert-rule-popup/add_alert_metric_for_report_popup']);
    const [mainData, setMainData] = useState<popupMainData>();
    const [selectedMetric, setSelectedMetric] = useState<string>(''); //metric_element_id
    const navigate = useNavigate();

    //form->report_element_id->setValue($this->element['element_id'])
    //action POST: notification/alertrule/addMertic4Report/id/${reportId}
    useEffect(() => {
        alertRuleAPI
            .addAlertMetric4ReportData(reportId, userId)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setMainData(data);
                    setSelectedMetric(data?.metric_elements?.[0]?.value ?? '');
                }
            })
            .catch(() => {});
    }, []);

    if (!mainData) return null;

    const selectOptions = (mainData?.metric_elements || []).map((item) => {
        return {
            value: String(item.value),
            label: item.label,
        };
    });

    selectOptions.push({
        value: 'add',
        label: t('option_add_label', { name: mainData.element.name }),
    });

    return (
        <Popup
            settings={{
                title: t('popup_title'),
                textOK: t('apply_btn'),
            }}
            maxWidth="popupMd"
            open={true}
            onHide={onClose}
            onConfirm={() => {
                onApply(selectedMetric);
            }}
        >
            <Box>{t('field_label')}</Box>
            <Box>
                <ReactSelect
                    data={selectOptions}
                    value={selectedMetric}
                    update={(val) => {
                        setSelectedMetric(val);
                        if ('add' == val) {
                            // ToDo: change link to new
                            navigate(`/editor/metric/draw/requester/${mainData?.element.element_id}/`);
                        }
                    }}
                />
            </Box>
        </Popup>
    );
}
