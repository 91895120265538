import React from 'react';
import { AssocArray } from 'tools/types';
import { ReportTooltipProps } from 'components/report-content/tooltip';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsRangeChart = ReportContentNS.ComponentSettingsRangeChart;
import ReportChartPoint = ReportContentNS.ReportChartPoint;
import { Box, Stack } from '@mui/material';
import styles from 'app/home/tile/components/info-panel/styles';
import DrillToTooltipPart, { TooltipDrillData } from 'components/report-content/tooltip/DrillToTooltipPart';

interface RangeChartPoint extends ReportChartPoint {
    formattedValues?: AssocArray<string>;
}

export default function RangeChartTooltip({
    point,
    settings,
    contentSettings,
    closeComponent,
}: ReportTooltipProps<ComponentSettingsRangeChart> & { point: RangeChartPoint }) {
    const drillData: Array<TooltipDrillData> = [
        {
            field: settings.x_field.reference_name,
            //@ts-ignore
            value: point.category ?? '',
        },
        {
            field: 'snapshot_date',
            value: contentSettings.measurement_time ?? '',
        },
    ];

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                justifyContent={'space-between'}
                sx={{ ...styles.sectionWrapper, paddingLeft: 0, paddingRight: 0, paddingTop: 0, height: 29 }}
            >
                <Stack direction={'row'}>
                    <Box>
                        {(point.formattedValues?.[String(point.low)] ?? '') +
                            ' - ' +
                            (point.formattedValues?.[String(point.high)] ?? '')}
                    </Box>
                </Stack>
                <Box>{closeComponent}</Box>
            </Stack>
            <DrillToTooltipPart contentSettings={contentSettings} data={drillData} settings={settings} />
        </Stack>
    );
}
