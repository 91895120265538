import { ElementTopicSection } from 'components/element-viewer/element-topics';
import IconHandler from 'components/common/icon/IconHandler';
import React from 'react';

export default function ElementTopicSectionIcon({ section, styles }: { section: ElementTopicSection; styles: any }) {
    const icon = section.isDefault
        ? {
              type: 'mi',
              value: 'tags',
          }
        : {
              type: section.useIcon == 'uploaded' ? 'upload' : section.useIcon,
              value: section.useIcon == 'uploaded' ? `data:${section.icon};base64, ${section.icon}` : section.faIcon,
          };

    if (section.useIcon == 'none' && !section.isDefault) return null;

    return <IconHandler sx={{ ...styles.icon }} icon={icon} />;
}
