import { MenuItem, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';

export interface ActionMenuItemProps {
    itemKey: string;
    action: () => void;
    icon: string;
    text: string;
}

function ActionMenuItem({ itemKey, action, icon, text }: ActionMenuItemProps) {
    return (
        <MenuItem sx={{ color: 'primary.main' }} key={`menu-item-${itemKey}`} onClick={action}>
            <Stack
                data-test={`${itemKey}-button`}
                direction={'row'}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
            >
                <IconMi icon={icon} fontSize="14" />
                <Typography>{text}</Typography>
            </Stack>
        </MenuItem>
    );
}

export default ActionMenuItem;
