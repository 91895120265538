import { useSelector } from 'react-redux';

export const useConfigConstantValue = (constantName: string) => {
    const globalConfigConstants = useSelector((state: any) => state.auth?.configuration?.constants);
    return globalConfigConstants?.[constantName];
};

export const useIsConfigConstantExist = (constantName: string) => {
    const globalConfigConstants = useSelector((state: any) => state.auth?.configuration?.constants);
    return Boolean(globalConfigConstants && globalConfigConstants.hasOwnProperty(constantName));
};

export const useIsConfigConstantEqual = (constantName: string, compareValue: any) => {
    const constVal = useConfigConstantValue(constantName);
    const isExist = useIsConfigConstantExist(constantName);
    return isExist && constVal === compareValue;
};
