import qs from 'query-string';
import { instance } from 'api/api';

export const wallAPI = {
    loadData(data: any) {
        const url = `/data/dashboard/wall/load`;
        return instance.post(url, data);
    },
    checkNotificationEnabled() {
        const url = `/data/dashboard/wall/check-notification-enabled`;
        return instance.get(url);
    },
    followDiscussion(data: any) {
        const url = `/data/dashboard/wall/follow-discussion`;
        return instance.post(url, data);
    },
    followUser(data: any) {
        const url = `/data/dashboard/wall/follow-user`;
        return instance.post(url, data);
    },
    insightful(type: string, data: any) {
        //type: annotation | note | comment
        const url = `/data/dashboard/wall/insightful/${type}`;
        return instance.post(url, data);
    },
    deleteComment(data: any) {
        const url = `/data/dashboard/comment/delete`;
        return instance.post(url, data);
    },
    deleteNote(data: any) {
        const url = `/data/dashboard/note/delete`;
        return instance.post(url, data);
    },
    /*--*/ deleteAnnotation(data: {
        element: number;
        segment: number;
        instance: number;
        ann_id: number;
        isMetric: boolean;
    }) {
        let url = ``;

        const params = {
            element: data.element,
            segment: data.segment,
            interval: data.instance,
            ann_id: data.ann_id,
        };

        if (data.isMetric) {
            //url = `chart/annotation/delete/element/${data.element}/segment/${data.segment}/interval/${data.instance}/ann_id/${data.ann_id}/ajax/Y/`;
            url = `data/chart/annotation/${data.ann_id}/form`;
            return instance.delete(url, { params: params });
        } else {
            //ToDo Link for report
            url = `report/annotation/delete/element/${data.element}/segment/${data.segment}/instance/${data.instance}/user_annotation_id/${data.ann_id}/ajax/Y/`;
            return instance.post(url, params);
        }
    },
    /*--*/ groupDeleteAnnotationData(data: {
        element: number;
        segment: number;
        instance: number;
        ann_id: number;
        group_id: number;
    }) {
        const params = {
            element: data.element,
            segment: data.segment,
            interval: data.instance,
            ann_id: data.ann_id,
            ann_group_id: data.group_id,
        };

        //let url = `chart/annotation/groupdeletepopup/element/${data.element}/segment/${data.segment}/interval/${data.instance}/ann_id/${data.ann_id}/ann_group_id/${data.group_id}/ajax/Y`;
        let url = `/data/chart/annotation/group-delete`;
        return instance.get(url + '?' + qs.stringify(params));
    },
    async getInsightfulUsers(data: { user_comment_id?: number; user_note_id?: number; user_annotation_id?: number }) {
        const url = `/data/dashboard/wall/insightful/users`;
        return await instance.post(url, data);
    },
    notifyOther(data: any) {
        const url = `/data/notification/notify-other/insert`;
        return instance.post(url, data);
    },
    expertNoteHistoryData(data: any) {
        const url = `/data/dashboard/note/history`;
        return instance.get(url + '?' + qs.stringify(data));
    },
    addNote(data: any) {
        const url = `/data/dashboard/note/insert`;
        return instance.post(url, data);
    },
    addComment(data: any) {
        const url = `/data/dashboard/comment/insert`;
        return instance.post(url, data);
    },
    deleteEvent(eventId: number) {
        return instance.delete(`/data/chart/event/${eventId}/form`);
    },
};
