import { IconButton, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gridAPI } from 'api/grid';
import { ToggleCellProps } from 'components/common/grid/';
import { getCellWithTooltip, getIcon } from './index';

export default function ToggleCell({
    href,
    reloadGridData,
    enabledIcon,
    disabledIcon,
    checked,
    tooltip,
    disabled,
    rawTooltipHtml,
    color = 'success',
    t,
    data,
    setGridDataChanged,
}: ToggleCellProps) {
    const [isChecked, setIsChecked] = useState<boolean>(checked === 'Y');

    useEffect(() => {
        setIsChecked(checked === 'Y');
    }, [checked, href]);
    //todo: seems if we always use async grid update we do not need this type of cell. this can be covered by AsyncActionCell
    const getToggleIcon = () => {
        const iconData = checked === 'Y' ? enabledIcon : disabledIcon;
        return getIcon(iconData);
    };

    const onClick = () => {
        if (href) {
            setIsChecked(!isChecked);
            const newCheckedState = checked === 'Y' ? 'N' : 'Y';
            gridAPI
                .updateGridOrder(href, {
                    checked: newCheckedState,
                })
                .then(() => {
                    if (reloadGridData) {
                        reloadGridData();
                    }
                    if (setGridDataChanged) {
                        setGridDataChanged(true);
                    }
                });
        }
    };

    let dataTest = undefined;
    if (data?.id) {
        dataTest = `toggle-cell-${data.id}`;
    }

    const iconComponent = (
        <>
            {!enabledIcon ? (
                <Switch
                    data-test={dataTest}
                    key={href}
                    color={color as any}
                    onClick={onClick}
                    checked={isChecked}
                    disabled={disabled ?? false}
                />
            ) : (
                <IconButton aria-label="toggle" size={'small'} onClick={onClick} disabled={disabled ?? false}>
                    {getToggleIcon()}
                </IconButton>
            )}
        </>
    );

    let component = iconComponent;

    if (tooltip) {
        component = getCellWithTooltip(iconComponent, tooltip, rawTooltipHtml, t);
    }

    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'start' }}>
            {component}
        </Grid>
    );
}
