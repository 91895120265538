import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useMemo, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { Box } from '@mui/material';
import ComponentSettingsJct = ReportContentNS.ComponentSettingsJct;
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from 'api/api';
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';
import { APIResponse } from 'tools/types';
export default function Jct({ contentSettings, component }: ComponentUpdateProps<ComponentSettingsJct>) {
    const [iframeLoaded, setIframeLoaded] = useState(false);
    useComponentReady(iframeLoaded, 2000);
    const [upsReady, setUpsReady] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const generator = urlSearchParams.get('generator');

    const [queryEnabled, setQueryEnabled] = useState(false);
    useEffect(() => {
        setTimeout(() => setQueryEnabled(true), 100);
    }, []);

    const queryClient = useQueryClient();
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}`;
    const removeInitialData = () => queryClient.removeQueries({ queryKey: [initialQueryKey] });
    const { data: ups } = useQuery<number, Error>({
        queryKey: [initialQueryKey],
        queryFn: async () => {
            if (contentSettings.context == 'edit') {
                const result = await instance.post<APIResponse<{ id: number }>>(
                    `/data/report/js-chart-settings/${contentSettings.elementId}`,
                    {
                        uid: component.settings.uid,
                        settings: JSON.stringify(component.settings),
                    },
                );
                return result && result.data.status == 'OK' ? result.data.data.id : 0;
            } else {
                return 0;
            }
        },
        enabled: queryEnabled,
    });
    useEffect(() => {
        removeInitialData();
    }, [JSON.stringify(component.settings.sortFields), JSON.stringify(component.settings.fields)]);

    useEffect(() => {
        if (typeof ups == 'undefined') {
            return;
        }
        setUpsReady(true);
    }, [ups]);

    const { filtersString } = useReportContentContext(() => {});
    const filters = JSON.stringify(filtersString == null ? {} : filtersString);
    const properties = useMemo(() => {
        return {
            applyAccessMap: contentSettings.applyAccessMap,
            context: contentSettings.context,
            measurement_time: contentSettings.measurement_time,
            pageSize: contentSettings.pageSize,
            templateProperties: component.settings.properties?.templateProperties ?? '[]',
        };
    }, [
        JSON.stringify(component.settings.properties?.templateProperties ?? '[]'),
        JSON.stringify(contentSettings),
        ups,
    ]);

    const getUrl =
        (generator ? 'generator=Y&' : '') +
        `height=${component.settings.height}&properties=${encodeURIComponent(
            JSON.stringify(properties),
        )}&filters=${filters}` +
        (ups ? `&ups=${ups}` : `&uid=${component.settings.uid}`);

    if (!upsReady) {
        return null;
    }

    return (
        <Box
            sx={{ '.tox-toolbar__group': { padding: '0 5px 0 6px !important' } }}
            style={{
                overflow: 'hidden',
                maxHeight: contentSettings.context == 'edit' ? component.settings.height + 'px' : undefined,
            }}
        >
            <iframe
                onLoad={() => setIframeLoaded(true)}
                width={'100%'}
                height={component.settings.height}
                src={
                    (process.env.REACT_APP_BACKEND_API_BASE_URL ?? '') +
                    `/data/report/js-chart/${contentSettings.elementId}?${getUrl}`
                }
            />
        </Box>
    );
}
