import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { formAPI } from 'api/form';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormProps } from './index';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import FormRenderer from './FormRenderer';
import { processSettingsUrl } from 'components/common/form/formTools';
import NetworkError from 'components/common/error/NetworkError';

export default function FormComponent(props: FormProps) {
    const [searchParams] = useSearchParams();
    const urlParams = props.urlParams ?? useParams();
    let settingsUrl = processSettingsUrl(props.settingsUrl, props.pk, urlParams);

    let id = undefined !== urlParams[props.pk] ? urlParams[props.pk]!.toString() : '';

    if (id == 'new' || (props.isPopup && props.popupType == 'new')) {
        id = '0';
    }

    let formLoadDataUrl = settingsUrl;
    if (props.passSearchParams && !props.postData) {
        const searchString = searchParams.toString();
        const isExistGetParams = formLoadDataUrl.includes('?');
        formLoadDataUrl = formLoadDataUrl + (searchString ? (isExistGetParams ? '&' : '?') + searchString : '');
    }

    const { status, data, error, isFetching, refetch } = useQuery<any, Error>({
        queryKey: [props.uid, id, formLoadDataUrl],
        queryFn: () => {
            if (props.postData) {
                return formAPI.loadByPost(formLoadDataUrl, props.postData, props.getMethod);
            }

            return formAPI.load(formLoadDataUrl);
        },
        gcTime: 0,
    });

    let popupSettings = props.popupSettings ?? {};

    const setDefaultFormPopupActionSettings = (type: any) => {
        popupSettings = {
            ...(data?.data?.settings?.actionSettings?.[type]?.popupConfig ?? {}),
            ...popupSettings,
        };
    };

    if (id == 'new' || (props.isPopup && props.popupType == 'new')) {
        id = '0';
        setDefaultFormPopupActionSettings('add');
    }
    if (props.isPopup && props.popupType == 'duplicate') {
        setDefaultFormPopupActionSettings('duplicate');
    }

    const formKey = props.uid + props.settingsUrl + id;

    const isLoading =
        status === 'pending' ||
        (data?.data?.status == 'ERROR' &&
            ['need_redirect', 'Unauthorized', 'NotFound'].includes(data?.data?.error ?? ''));

    return (
        <>
            {isLoading ? (
                <LoadingPlaceholder />
            ) : status === 'error' ? (
                <>{error!.message === 'Network Error' ? <NetworkError /> : <span>Error: {error!.message}</span>}</>
            ) : (
                <FormRenderer
                    key={formKey}
                    {...props}
                    entityId={id}
                    refetch={refetch}
                    settingsUrl={settingsUrl}
                    popupSettings={popupSettings}
                    originalSettingsUrl={props.settingsUrl}
                    formData={data.data.data}
                    formSettings={data.data.settings}
                    translationNS={props.translationNS}
                />
            )}
        </>
    );
}
